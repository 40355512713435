import { Component } from "react";
import Button from "../../components/Button";
import MobileViewButton from "../../components/Button/MobileViewButton";

class Welcome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRegion: [],
            selectedCountry: [],
            gdprAcceptance: 'yes',
            history: this.props.history,
            products: [],
            contentPreference: '',
            documentPreference: [],
            selectedAreas: [],
            selectedClasses: [],
            areaId: [],
            classId: [],
            productId: [],
            profession: '',
        }
        //console.log(this.props);
    }
    componentDidMount() {
        this.setState({
            gdprAcceptance: this.props.gdprAcceptance,
            profession: this.props.profession,
            products: this.props.products,
            contentPreference: this.props.contentPreference,
            documentPreference: this.props.documentPreference,
            selectedAreas: this.props.selectedAreas,
            selectedClasses: this.props.selectedClasses
        });
        if (this.props.selectedRegion.length > 0) {
            //console.log(typeof(this.props.selectedRegion))
            this.setState({
                selectedRegion: this.props.selectedRegion
            })
        }
        if (this.props.selectedCountry.length > 0) {
            this.setState({
                selectedCountry: this.props.selectedCountry
            })
        }
        if (this.props.areaId.length > 0) {
            this.setState({
                areaId: this.props.areaId
            });
        }
        if (this.props.classId.length > 0) {
            this.setState({
                classId: this.props.classId
            });
        }
        if (this.props.productId.length > 0) {
            this.setState({
                productId: this.props.productId
            });
        }
    }
    handleUserContinue = () => {
        //console.log(this.state.history);
        this.props.onClickContinueToLocation(this.state.selectedRegion, this.state.selectedCountry, this.state.history, this.state.gdprAcceptance, this.state.profession, this.state.products, this.state.contentPreference, this.state.documentPreference, this.state.selectedAreas, this.state.selectedClasses, this.state.areaId, this.state.classId, this.state.productId);
    }
    render() {
        return (
            <div className="registrationInnerWrapper">
                <div className="welcomePageTitle">
                </div>
                <div className="welcome-select-content">
                    <div className="welcomeContent">
                        <div className="welcomeMainHeader">Welcome to iMedical Search</div>
                        <div className="welcomeSubHeader" style={{ fontSize: "20px" }}>Personalize your search experience!</div>
                        <p>To make your search experience as efficient as possible, you can select your content preferences on the following screens. This will ensure that you receive the most relevant information for your specific needs and interests. You will also be able to receive personalized updates when new content is available.</p>
                        <br />
                        <p style={{ fontWeight: "700" }}>Set up your user profile.</p>
                        <p>Design your own search experience by selecting your content preferences on the following screens.</p>
                        <ul>
                            <li><b>Location(s)</b> – Choose the region and country [or countries] where you have product responsibilities. Please note that unless there is a firewall, selecting a location will not prevent you from accessing content associated with other geographic areas; it will only ensure that content that is most relevant to you will be displayed higher up in your search results.</li><br />
                            <li><b>Therapeutic area(s) and product(s)</b> – Choose the therapeutic areas and specific products of interest to you, and indicate whether you want to see only your preferred products in your search results or all results (with the results for your preferred products displayed higher up in your search results).</li><br />
                            <li><b>Document types</b> – Select the types of content you’re most interested in.</li>
                        </ul>
                        <br />
                        <p>Your content preferences will be saved in your user profile, so your search results return the most relevant information to you. Based on these preferences you'll also get recommendations and updates tailored to you within your personalized home page.</p>
                    </div>
                    {this.props.customWidth < 600 ?
                        <div className="gsf-popup-button-wrapper-mobile">
                            <div className="welcomeNxtBtn-mobile">
                                <MobileViewButton text='Continue' customColor='#2EB000' action={this.handleUserContinue} className="continueButton" />
                            </div>
                        </div>
                        :
                        <div className="buttonContainer" style={{ clear: "both", paddingRight: "50px" }}>
                            <div className="welcomeNxtBtn">
                                <Button text='Continue' customColor='#2EB000' action={this.handleUserContinue} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
export default Welcome;