/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import NlpDocumentPreview from '../Preview/NlpDocumentPreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApiServices from "../../utils/apiServices";
import trackingService from "../../services/trackingService";
import { Link } from "react-router-dom";

const ReactLike = 'Like';
const ReactUnlike = 'Unlike';
const ReactNone = 'None';
let timer;
class RecommendationDocuments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPreviewModel: false,
            showPreview: false,
            highlightVals: [],
            reaction: ReactNone,
            isPreview: false,
            showInfo: false,
            customWidth: props.customWidth,
            customHeight: props.customHeight,
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.customWidth != nextProps.customWidth || this.state.customHeight != nextProps.customHeight) {
            this.setState({
                customWidth: nextProps.customWidth,
                customHeight: nextProps.customHeight
            })
        }
    }
    onPreviewClick = () => {
        let { documentInfo } = this.props;
        let highlightVals = [];
        this.setState({ isPreview: true });
        if (documentInfo.section_output && Array.isArray(documentInfo.section_output)) {
            //---------------------------------Start section_output-------------------------------
            highlightVals = documentInfo.section_output.filter((x, i) => (i < 2)).map((highlightVal) => {
                let temp = [];
                const div = document.createElement('div');
                let _pageNumberList = (highlightVal.PageNumberList && highlightVal.PageNumberList.length > 0) ? highlightVal.PageNumberList.slice(-2) : [];
                let lastIndex = _pageNumberList.length - 1;
                let _pageNumber = (_pageNumberList.length > 0) ? _pageNumberList[lastIndex] : documentInfo.PageNumber;
                let _sectionData = highlightVal.section_text
                if (_sectionData) {
                    _sectionData = _sectionData.replace(/<br>·/g, '<br>');
                }
                div.innerHTML = _sectionData;
                const spans = div.getElementsByTagName('span');
                if (spans && spans.length > 0) {
                    for (let span = 0; span < spans.length; span++) {
                        temp.push(spans[span].innerHTML);
                    }
                    return {
                        highlightVal: temp.join('').replace(/<(?:.|\n)*?>/gm, ' ').replace(/<(?:.|\n)*?>/gm, ' ').replace(/\s{2,}/g, ' ').trim(),
                        ansHighlight: temp.join('').replace(/<\/?(h6)\b[^<>]*>/g, "").trim(),
                        PageNumber: _pageNumber,
                        PageNumberList: (_pageNumberList.length > 0) ? _pageNumberList : [],
                    }
                }
                else {
                    return {
                        highlightVal: _sectionData.replace(/<(?:.|\n)*?>/gm, ' ').replace(/<(?:.|\n)*?>/gm, ' ').replace(/\s{2,}/g, ' ').trim(),
                        ansHighlight: _sectionData.replace(/<\/?(h6)\b[^<>]*>/g, "").trim(),
                        PageNumber: _pageNumber,
                        PageNumberList: (_pageNumberList.length > 0) ? _pageNumberList : [],
                    }
                }

            })
            //---------------------------------End section_output--------------------------------
        } else {
            //---------------------------------End section_text--------------------------------

            let temp = [];
            const div = document.createElement('div');
            let _sectionData = documentInfo.section_text;
            if (_sectionData) {
                _sectionData = _sectionData.replace(/<br>·/g, '<br>');
            }
            div.innerHTML = _sectionData;
            let _pageNumberList = (documentInfo.PageNumberList && documentInfo.PageNumberList.length > 0) ? documentInfo.PageNumberList.slice(-2) : [];
            let lastIndex = _pageNumberList.length - 1;
            let _pageNumber = (_pageNumberList.length > 0) ? _pageNumberList[lastIndex] : documentInfo.PageNumber;
            const spans = div.getElementsByTagName('span');
            if (spans && spans.length > 0) {
                for (let span = 0; span < spans.length; span++) {
                    temp.push(spans[span].innerHTML);
                }
                highlightVals.push({
                    highlightVal: temp.join('').replace(/<(?:.|\n)*?>/gm, ' ').replace(/<(?:.|\n)*?>/gm, ' ').replace(/\s{2,}/g, ' ').trim(),
                    ansHighlight: temp.join('').replace(/<\/?(h6)\b[^<>]*>/g, "").trim(),
                    PageNumber: _pageNumber,
                    PageNumberList: (_pageNumberList.length > 0) ? _pageNumberList : []
                })
            }
            else {
                highlightVals.push({
                    highlightVal: _sectionData.replace(/<(?:.|\n)*?>/gm, ' ').replace(/<(?:.|\n)*?>/gm, ' ').replace(/\s{2,}/g, ' ').trim(),
                    ansHighlight: _sectionData.replace(/<\/?(h6)\b[^<>]*>/g, "").trim(),
                    PageNumber: _pageNumber,
                    PageNumberList: (_pageNumberList.length > 0) ? _pageNumberList : []
                })
            }
            //---------------------------------End section_text--------------------------------
        }
        document.body.classList.add('custome-modal-open');
        this.setState({ showPreviewModel: true, highlightVals: highlightVals },
            () => {
                this.setState({ showPreview: true })
            })
    }
    handlePreviewClose = async () => {

        this.setState({ showPreviewModel: false, showPreview: false },
            async () => {
                setTimeout(() => {
                    document.body.classList.remove('custome-modal-open');
                }, 1000);
            })
    }
    handleOnClickReaction = async (_reaction) => {
        this.setState({ reaction: _reaction }, async () => {
            let { feedbackID } = this.props;
            let { documentInfo } = this.props;
            let { searchID } = documentInfo;
            let service = new ApiServices();
            await service.getAssistantfeedback(feedbackID).then(async (response) => {
                if (response.length === 0) {
                    await this.pushMainAnswer();
                } else {
                    let reaction = 2;
                    if (_reaction === ReactLike) {
                        reaction = 0;
                    } else if (_reaction === ReactUnlike) {
                        reaction = 1;
                    }
                    await service.updateRecommendationsReaction(feedbackID, searchID, reaction)
                }
            }).catch((error) => {
                console.log(error)
            }).finally(() => {
            });
        });
    }
    pushMainAnswer = async () => {
        let { docTitle, documentNumber, downloadUrl, sectionAnswers, searchID } = this.props.mainAnswerDocumentInfo;
        let { question, sessionID, feedbackID } = this.props;
        let wwid = JSON.parse(localStorage.getItem('wwid'));
        let service = new ApiServices();
        if (sectionAnswers && sectionAnswers.length > 0) {
            let sectionAnswer = sectionAnswers[0];
            let mainAnswer = "", sectionanswer = "";
            if (sectionAnswer && sectionAnswer.sectionAnswer) {
                mainAnswer = sectionAnswer.sectionAnswer.trim();
                sectionanswer = sectionAnswer.SectionData.trim();
                await service.insertUpdateAssistantFeedback(
                    feedbackID,
                    question,
                    mainAnswer,
                    documentNumber,
                    docTitle,
                    downloadUrl,
                    ReactNone,
                    ReactNone,
                    searchID,
                    sectionanswer,
                    sessionID,
                    wwid
                ).then(async (response) => {
                    let { documentInfo } = this.props;
                    let { searchID } = documentInfo;
                    let { reaction } = this.state;
                    await this.props.pushRecommendations(searchID, reaction);
                });
            }
        }
    }
    renderModelPopup() {
        const { showPreviewModel, showPreview, highlightVals } = this.state;
        let { documentInfo, originalRecommendations, sessionID, feedbackID, question } = this.props;
        return (<Modal
            show={showPreviewModel}
            size="xl"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="answering-popup-transition"
            className="z-index-preview"
            backdropClassName="z-index-preview-backdrop"
            scrollable={false}>
            <Modal.Body>
                <div style={{ overflow: "auto" }}>
                    <NlpDocumentPreview highlightVals={highlightVals} nlpflag={true}
                        docTitle={documentInfo.docTitle} sessionID={sessionID} lastFeedbackID={feedbackID} downloadUrl={documentInfo.downloadUrl}
                        lastDocumentInfo={documentInfo}
                        lastQuestion={question}
                        searchID={documentInfo.searchID} searchKeyword={""}
                        synonyms={[]} showPreview={showPreview} handleClose={this.handlePreviewClose}
                        recommendationDocuments={originalRecommendations} customWidth={this.state.customWidth} customHeight={this.state.customHeight} />
                </div>
            </Modal.Body>
        </Modal>)
    }
    snippetLongString = (snippet) => {
        if (snippet.length > 32) {
            let text = snippet.substring(0, 32)//cuts to 70
            // let last = text.lastIndexOf(" ")//gets last space (to avoid cutting the middle of a word)
            // text = text.substring(0, 70)//cuts from last space (to avoid cutting the middle of a word)
            text = text + `...`//adds (...) at the end to show that it's cut
            return text
        }
        return snippet;
    }
    indexData = (docTitle, searchID, url, docNumber) => {
        const ts = new trackingService();
        let { question, sessionID } = this.props;
        let assistantinfo = {
            "question": question,
            "sessionid": sessionID,
            "isGetAnyAns": true,
            "isSuccess": true
        }
        let newUrl = window.origin + url;
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'),
            localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'),
            localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'),
            localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'),
            localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'),
            `Preview Answering Recommendations Document`, '',
            window.location.href,
            '',
            '',
            docTitle, '', docNumber, searchID, newUrl, '', '', `Preview Answering Recommendations Document`, '', '', '', '', '', '', '', '', assistantinfo);
    }
    indexDownloadData = (event) => {
        var docTitle = [event.target.getAttribute("data-title")];
        var searchID = [event.target.getAttribute("data-docid")];
        var url = [event.target.getAttribute("data-docurl")];
        var docNumber = [event.target.getAttribute("data-docNumber")];
        let { question, sessionID } = this.props;
        let assistantinfo = {
            "question": question,
            "sessionid": sessionID,
            "isGetAnyAns": true,
            "isSuccess": true
        }
        const ts = new trackingService();
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'),
            localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'),
            localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'),
            localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'),
            localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'),
            `View OR Download Answering Recommendations Document`, '',
            window.location.href,
            '',
            '',
            docTitle, '', docNumber, searchID, url, '', '', `View OR Download Answering Recommendations Document`, '', '', '', '', '', '', '', '', assistantinfo);
    };
    onMouseEnterInfo = () => {
        timer = setTimeout(() => { this.setState({ showInfo: true }) }, 1500)
    }
    onMouseLeaveInfo = () => {
        clearTimeout(timer)
        this.setState({ showInfo: false })
    }
    convertToHtml = (item, i) => {
        if (i === 1) {
            return (<><br /><br /><br /><br /><div dangerouslySetInnerHTML={{ __html: item }}>
            </div></>)
        }
        else {
            return (<><div dangerouslySetInnerHTML={{ __html: item }}>
            </div></>)
        }
    }
    GethighlightVals = () => {
        let { documentInfo } = this.props;
        let temp = [];
        if (documentInfo.section_output && Array.isArray(documentInfo.section_output)) {
            documentInfo.section_output.filter((x, i) => (i < 2)).map((highlightVal, i) => {
                let _sectionData = highlightVal.section_text
                if (_sectionData) {
                    _sectionData = _sectionData.replace(/<br>·/g, '<br>');
                }
                temp.push(this.convertToHtml(_sectionData.replace(/(\<br[\s]*\/?\>[\s]*)+/g, '<br>'), i))
                return (<></>)
            })
        }
        else {
            let _sectionData = documentInfo.section_text
            if (_sectionData) {
                _sectionData = _sectionData.replace(/<br>·/g, '<br>');
            }
            temp.push(this.convertToHtml(_sectionData.replace(/(\<br[\s]*\/?\>[\s]*)+/g, '<br>')))
        }
        return (temp.map((value) => { return value }))
    }
    render() {
        let { documentInfo, documentIndex, totalDocuments } = this.props;
        const { reaction, isPreview } = this.state;
        let downloadUrl = documentInfo.downloadUrl;
        if (documentInfo.enclosureDocID && documentInfo.enclosureDocID.trim()) {
            downloadUrl += `&enclosureID=${parseInt(documentInfo.enclosureDocID)}`
        }
        return (<React.Fragment key={`rd-document-info-title-${documentIndex}`}>
            <div onMouseEnter={this.onMouseEnterInfo} onMouseLeave={this.onMouseLeaveInfo}>
                <Link
                    className="rd-document-docTitle"
                    id="docdata"
                    to={{ pathname: downloadUrl }}
                    target="_blank"
                    onClick={this.indexDownloadData}
                    data-title={documentInfo.docTitle}
                    data-docid={documentInfo.searchID}
                    data-docurl={downloadUrl}
                    data-docNumber={documentInfo.documentNumber}
                    style={{ color: isPreview && "#5f1b5f" }}>
                    {documentIndex}. {documentInfo.docTitle}
                </Link>
                {/* <a target="_blank" className="rd-document-docTitle" href={downloadUrl} style={{ color: isPreview && "#5f1b5f" }}>
                </a> */}
                {this.state.showInfo && (
                    <div className="delay-container">
                        <div className="info-icon-wrapper">
                            <span className="tooltiptext tooltip-bottom" style={{ width: "420px", marginTop: "11px", borderRadius: "10px", lineHeight: "1.2", position: "absolute", marginRight: "auto", marginLeft: "10px", backgroundColor: "#ededed", color: "#333", border: "thin solid black" }}>
                                {this.GethighlightVals()}
                            </span>
                        </div>
                    </div>
                )}
            </div>

            <div className="rd-document-info" key={`rd-document-info-${documentIndex}`} >
                <div className="rd-document-name">
                    <div className="rd-document-number-reaction">
                        <span className="rd-document-number">{documentInfo.documentNumber}</span>
                        <div className="imscb-recommendation-container-right-reaction">
                            <button title="Like" class={reaction === ReactLike ? "btn btn-like-green" : "btn"} onClick={e => {
                                reaction === ReactLike ? this.handleOnClickReaction(ReactNone) : this.handleOnClickReaction(ReactLike)
                            }}><FontAwesomeIcon fixedWidth icon="thumbs-up" /></button>
                            <button title="Dislike" class={reaction === ReactUnlike ? "btn btn-unlike-red" : "btn"} onClick={e => {
                                reaction === ReactUnlike ? this.handleOnClickReaction(ReactNone) : this.handleOnClickReaction(ReactUnlike)
                            }}><FontAwesomeIcon fixedWidth icon="thumbs-down" /></button>

                        </div>
                    </div>
                </div>
                <div className="rd-document-preview-panel">
                    <button
                        className="rd-document-preview-button"
                        onClick={(e) => { this.onPreviewClick(); this.indexData(documentInfo.docTitle, documentInfo.searchID, downloadUrl, documentInfo.documentNumber); }}>Preview</button>

                </div>
            </div>
            {totalDocuments !== documentIndex && <hr></hr>}
            {this.renderModelPopup()}
        </React.Fragment>
        )
    }
}
export default RecommendationDocuments;