import { Component } from "react";
import HeaderSearch from "../../components/header/HeaderSearch";
import SearchFilter from "../FilterComponent/SearchFilter";
import Footer from "../../components/footer/Footer";
import queryString from 'query-string';
import processFilters from '../FilterComponent/ProcessFilter'
import Services from "../../utils/services";
import ApiServices from "../../utils/apiServices";
import { Answers } from "../../utils/utilityServices";
import GenerateArrayStrings from "../../components/GenerateArrayStrings";
import { imsLoader, collapseFilterWhite, filterIconWhite, expandFilterWhite } from "../../assets";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import WNFYSection from "../../components/WNFY/WNFYSection";
import ContentShortcut from "../../components/ContentShortcuts/ContentShortcutLayout";
import NavigationMenu from "../../components/Navigation/NavigationMenu";
import WOAVSection from "../../components/WOAV/WOAVSection";
import "./RecommendedHome.css";
import ResponsiveFilterComponent from "../ResponsiveFilterComponent/ResponsiveFilterComponent";
import RecentlyViewedSection from '../../components/History/RecentlyViewedSection'
import QueryHistorySection from '../../components/History/QueryHistorySection'
import { UtilityServices } from '../../utils/utilityServices'
import ChatBotSection from "../../components/ChatBot/ChatBotSection";
import moment from 'moment';

class RecommendedHome extends Component {
    userid;
    userExists;
    constructor(props) {
        super(props);
        this.state = {
            allFilters: {},
            selectedFilters: [],
            appliedFilters: [], //Filters that are applied for while search is happening
            username: '',
            userExists: false,
            searchKeyword: '',
            isProcessing: true,
            isLoaded: false,
            funcLoaded: false,
            document: [], filters: {}, selectedParentFilterArr: [], showFilters: true,
            showAnDCSModal: false,
            showSRnCSModal: false,
            showEnTCSModal: false,
            showKMLCSModal: false,
            showKCLCSModal: false,
            showLDCSModal: false,
            showSCDCSModal: false,
            width: window.innerWidth,
            height: window.innerHeight,
            showCRPCSModal: false,
            showTAnDCSModal: false,
            showEPCSModal: false,
            showMIRCSModal: false,
            recentlyUsedFilters: [],
            fromRecentlyAddedUpdated: 0,
            toRecentlyAddedUpdated: 6,
        }
    }
    async componentWillMount() {
        if (this.props.location && this.props.location.search &&
            (this.props.location.search.includes('code=') || this.props.location.search.includes('state='))) {
            this.props.history.replace("/");
        }
    }
    async componentDidMount() {
        document.title = 'iMedical Search';
        window.scrollTo(0, 0);
        /* if(this.state.width<1000){
            this.setState({
                showFilters : false
            })
        } */
        this.userid = JSON.parse(localStorage.getItem('username'));
        this.wwid = JSON.parse(localStorage.getItem('wwid'));
        this.userExists = localStorage.getItem('userExists');
        let service = new Services();
        let apiService = new ApiServices();
        service.logService(`Home page successfully mounted for ${this.userid.toLowerCase()}`, 'info');
        if (this.userExists) {
            this.setState({
                userExists: this.userExists
            })
            /*Get Approval Status of user and assign localStorage Values*/
            let approvedState = await apiService.getUserStatus(this.wwid);
            let apiRes = await apiService.getUserApprovedRegionRole(this.wwid);
            if (approvedState.status === 'Accepted') {
                localStorage.setItem('region', approvedState.regions);
                localStorage.setItem('function', JSON.stringify(approvedState.profession));
                this.setState({
                    funcLoaded: true
                })
            }
            else {
                if (apiRes) {
                    localStorage.setItem('region', apiRes.region);
                    localStorage.setItem('function', JSON.stringify(apiRes.function));
                }
                else {
                    localStorage.setItem('region', approvedState.regions);
                    localStorage.setItem('function', JSON.stringify('Commercial'));
                }
                this.setState({
                    funcLoaded: true
                })
            }
            /*Get the localStorage values to getAllFilters POST Call*/
            /* if(localStorage.getItem('contentpreference') == null){ */
            let res = await apiService.getUserPreferenceFromUserDetails(this.wwid);
            localStorage.setItem('contentpreference', JSON.stringify(res.contentprefernce));
            /* localStorage.setItem('region',res.regions); */
            localStorage.setItem('userproducts', res.products);
            localStorage.setItem('doctypepref', res.docs);
            /* } */
            let reqBody = {
                "function": localStorage.getItem('function').replace(/"/g, ''),
                "doctypepref": localStorage.getItem('doctypepref'),
                "userproducts": localStorage.getItem('userproducts'),
                "region": localStorage.getItem('region'),
                "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                "contentpreference": localStorage.getItem('contentpreference').replace(/"/g, '')
            }
            let response = await service.getAllFilters(reqBody)
            if (response) {
                let processedFilters = processFilters(response.data.body)
                this.setState({
                    document: response.data.body.hits.hits,
                    isPopupModalOpen: false,
                    documentCount: response.data.body.hits.total.value,
                    allFilters: processedFilters,
                    filters: processedFilters,
                    isProcessing: false,
                    isLoaded: true
                    //metadata: response.data.body.hits.total.value.toString(),
                    //wwid: wwid
                })
            }
            this.setState({ recentlyUsedFilters: JSON.parse(localStorage.getItem('recentlyUsedFilters')) })
            window.addEventListener('resize', this.updateDimensions);
        }
        else {
            console.log('Access Denied');
            this.props.history.push('/');
        }
        //let params = queryString.parse(this.props.location.search);
        //console.log(this.props);
        localStorage.removeItem('selectedResults')
        localStorage.removeItem('ItemsPerPage')
        localStorage.removeItem('ActivePage')
        localStorage.removeItem('markedResultsUrl')
        sessionStorage.clear();
    }
    componentWillUnmount() {
        let service = new Services();
        window.removeEventListener('resize', this.updateDimensions);
        service.logService(`Home page successfully unmounted for ${this.userid.toLowerCase()}`, 'info')
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight }, () => {
            /*  if(this.state.width < 1200){
                 this.setState({
                     showFilters : false
                 })
             }
             else{
                 this.setState({
                     showFilters : true
                 }) 
             } */
        });
    }
    handleIsProcessing = (isLoaded) => {
        this.setState({
            isLoaded: isLoaded
        })
    }
    handleAllFilters = (allfilters, appliedfilters, selectedFilters) => {
        //console.log(selectedFilters)
        this.setState({
            allFilters: allfilters,
            appliedFilters: appliedfilters,
            selectedFilters: selectedFilters
        })

    }
    handleOnChangeInfilter = (allfilters, appliedfilters, selectedFilters) => {
        //console.log(selectedFilters)
        this.handleAllFilters(allfilters, appliedfilters, selectedFilters);
        let params = queryString.parse(this.props.location.search);
        var filters = appliedfilters;
        const newUrl = this.buildUrl(params.query, params.q, params.f1, filters);
        this.props.history.push({ pathname: '/results', search: newUrl, state: { selectedFilters: selectedFilters, allFilters: allfilters, appliedFilters: appliedfilters } });
        //this.props.history.push({pathname:"/registration",state:{ fromDisclaimer : true}});
    }
    handleChangeInSearch = (query) => {
        //console.log(query)
        this.setState({
            searchKeyword: query
        })
    }
    generateArrayString = (array) => {
        let arrStr = ''
        //console.log(array)
        array.map((item, index) => {
            if (index === array.length - 1) {
                arrStr += item
            }
            else {
                arrStr += item + ";"
            }
            return null
        })
        //console.log(arrStr)
        return arrStr
    }

    recentlyAddedUpdatedbuildUrl = (searchKeyword, dropdownValue, fieldedVal, filters, isClearSearch, dateRange) => {
        let filterStr = this.generateArrayString(filters)
        let url;
        if (searchKeyword === undefined || dropdownValue === undefined) {
            url = `?query=&q=All Fields&filters=${filterStr}`;
        }
        else if (dropdownValue === '' || dropdownValue === null) {
            url = `?query=${searchKeyword}&q=All Fields&filters=${filterStr}`
        }
        else {
            url = `?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}&f1=${fieldedVal}&fval1=${searchKeyword}&fcount=1`;
        }

        if ((dateRange && dateRange.fromDate) || (dateRange && dateRange.toDate)) {
            url = url + `&isDateRange=Yes&rangeField=modifiedDate`
            if (dateRange.fromDate) {
                url = url + `&fromRange=${dateRange.fromDate}`
            }
            if (dateRange.toDate) {
                url = url + `&toRange=${dateRange.toDate}`
            }
        }
        return url;
    }
    gotoPageRecentlyAddedUpdated = (yearSelection) => {
        this.setState({
            fromRecentlyAddedUpdated: yearSelection.min,
            toRecentlyAddedUpdated: yearSelection.max
        }, () => {
            let dateRange = this.getToDateAndFromDate();
            let params = queryString.parse(this.props.location.search);
            let appliedFilters = this.state.appliedFilters.filter(x => !x.startsWith('Recently Added_Updated'));
            let displayYear = UtilityServices.getSelectedYearDisplay(yearSelection);

            if (displayYear && displayYear !== "All") {
                appliedFilters.push(`Recently Added_Updated/${displayYear}`)
            }
            var filters = appliedFilters;
            let selectedFacets = this.generateSelectParentArray(appliedFilters);
            const newUrl = this.recentlyAddedUpdatedbuildUrl(params.query, params.q, params.f1, filters, false, dateRange);
            this.props.history.push({
                pathname: '/results', search: newUrl, state: {
                    selectedFilters: selectedFacets,
                    allFilters: this.state.allFilters,
                    appliedFilters: appliedFilters
                }
            });
            this.setState({
                currentPage: "1",
                selectedParentFilterArr: selectedFacets,
                collapseFilter: true
            }, () => {
                this.runSearchFromQueryParams(this.state.allFilters, appliedFilters, this.state.searchKeyword);
            })
        })
    }
    generateSelectParentArray = (appliedfilters) => {
        let parentFacet = [];
        appliedfilters.forEach(filter => {
            const filterParts = filter.split('/')
            const filterTop = filterParts[0]
            parentFacet.push(filterTop)
        });
        return parentFacet;
    }
    getToDateAndFromDate = () => {
        let { fromRecentlyAddedUpdated, toRecentlyAddedUpdated } = this.state;
        let dateRange = { toDate: null, fromDate: null };

        if (fromRecentlyAddedUpdated === 1) {
            // dateRange.fromDate = moment()
            //   .subtract(12, "month")
            //   .startOf("date")
            //   .format("YYYY-MM-DD");
            dateRange.fromDate = moment()
                .subtract(365, "days")
                .startOf("date")
                .format("YYYY-MM-DD");
        } else if (fromRecentlyAddedUpdated === 2) {
            // dateRange.fromDate = moment()
            //   .subtract(6, "month")
            //   .startOf("date")
            //   .format("YYYY-MM-DD");
            dateRange.fromDate = moment()
                .subtract(180, "days")
                .startOf("date")
                .format("YYYY-MM-DD");
        } else if (fromRecentlyAddedUpdated === 3) {
            // dateRange.fromDate = moment()
            //   .subtract(1, "month")
            //   .startOf("date")
            //   .format("YYYY-MM-DD");
            dateRange.fromDate = moment()
                .subtract(30, "days")
                .startOf("date")
                .format("YYYY-MM-DD");
        } else if (fromRecentlyAddedUpdated === 4) {
            dateRange.fromDate = moment()
                .subtract(7, "days")
                .startOf("date")
                .format("YYYY-MM-DD");
        } else if (fromRecentlyAddedUpdated === 5) {
            dateRange.fromDate = moment()
                .subtract(1, "days")
                .startOf("date")
                .format("YYYY-MM-DD");
        }

        if (toRecentlyAddedUpdated === 1) {
            // dateRange.toDate = moment()
            //   .subtract(12, "month")
            //   .endOf("date")
            //   .format("YYYY-MM-DD");
            dateRange.toDate = moment()
                .subtract(365, "days")
                .endOf("date")
                .format("YYYY-MM-DD");
        } else if (toRecentlyAddedUpdated === 2) {
            // dateRange.toDate = moment()
            //   .subtract(6, "month")
            //   .endOf("date")
            //   .format("YYYY-MM-DD");
            dateRange.toDate = moment()
                .subtract(180, "days")
                .endOf("date")
                .format("YYYY-MM-DD");
        } else if (toRecentlyAddedUpdated === 3) {
            dateRange.toDate = moment()
                .subtract(30, "days")
                .endOf("date")
                .format("YYYY-MM-DD");
        } else if (toRecentlyAddedUpdated === 4) {
            // dateRange.toDate = moment()
            //   .subtract(7, "days")
            //   .endOf("date")
            //   .format("YYYY-MM-DD");
            dateRange.toDate = moment()
                .subtract(7, "days")
                .endOf("date")
                .format("YYYY-MM-DD");
        } else if (toRecentlyAddedUpdated === 5) {
            dateRange.toDate = moment()
                .subtract(1, "days")
                .endOf("date")
                .format("YYYY-MM-DD");
        }

        return dateRange;
    }
    buildUrl = (searchKeyword, dropdownValue, fieldedVal, filters) => {
        let filterStr = GenerateArrayStrings(filters)
        filterStr = encodeURIComponent(filterStr);
        if (searchKeyword === undefined || dropdownValue === undefined) {
            return `?query=&q=All Fields&filters=${filterStr}`;
        }
        else if (dropdownValue === '' || dropdownValue === null) {
            return `?query=${searchKeyword}&q=All Fields&filters=${filterStr}`
        }
        else {
            return `?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}&f1=${fieldedVal}&fval1=${searchKeyword}&fcount=1`;
        }

    }
    handelAnDCardDetailModal(isClicked) {
        this.setState({ showAnDCSModal: isClicked })
    }
    handelSRnCSCardDetailModal(isClicked) {
        this.setState({ showSRnCSModal: isClicked })
    }
    handelEnTCardDetailModal(isClicked) {
        this.setState({ showEnTCSModal: isClicked })
    }
    handelKMLCardDetailModal(isClicked) {
        this.setState({ showKMLCSModal: isClicked })
    }
    handelKCLCardDetailModal(isClicked) {
        this.setState({ showKCLCSModal: isClicked })
    }
    handelLDCardDetailModal(isClicked) {
        this.setState({ showLDCSModal: isClicked })
    }
    handelSCDCardDetailModal(isClicked) {
        this.setState({ showSCDCSModal: isClicked })
    }
    handelCRPCardDetailModal(isClicked) {
        this.setState({ showCRPCSModal: isClicked })
    }
    handelTAnDCardDetailModal(isClicked) {
        this.setState({ showTAnDCSModal: isClicked })
    }
    handelEPCardDetailModal(isClicked) {
        this.setState({ showEPCSModal: isClicked })
    }
    handelMIRCardDetailModal(isClicked) {
        this.setState({ showMIRCSModal: isClicked })
    }
    // handelCardDetailModal(isClicked) {
    //     this.setState({ showCSModal: isClicked })
    // }
    handelcloseCSModal = () => {
        this.setState({
            showAnDCSModal: false,
            showSRnCSModal: false,
            showEnTCSModal: false,
            showKMLCSModal: false,
            showKCLCSModal: false,
            showLDCSModal: false,
            showSCDCSModal: false,
            showCRPCSModal: false,
            showTAnDCSModal: false,
            showEPCSModal: false,
            showMIRCSModal: false
        })
        // this.setState({ showCSModal: false })
    }
    render() {
        let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'));
        if (this.state.width < 600) {
            return (
                <ErrorBoundary>
                    {this.state.userExists ? <>
                        <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                        <div className="header-wrapper">
                            <HeaderSearch history={this.props.history} dropdownValue='All Fields' searchKeyword=''
                                appliedFilters={this.state.appliedFilters} handleChangeInSearch={this.handleChangeInSearch} customWidth={this.state.width} customHeight={this.state.height} />
                        </div>
                        <div className="recommended-contents-wrapper">
                            <ResponsiveFilterComponent pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} allFilters={this.state.allFilters} filters={this.state.filters} appliedFilters={this.state.appliedFilters} handleAllfilters={this.handleAllFilters} handleOnChangeInfilter={this.handleOnChangeInfilter} selectedFilters={this.state.selectedFilters} selectedParentFilterArr={this.state.selectedParentFilterArr} querytext={this.state.searchKeyword} isProcessing={this.state.isProcessing} recentlyUsedFilters={this.state.recentlyUsedFilters}
                                handleIsProcessing={this.handleIsProcessing}
                                fromRecentlyAddedUpdated={this.state.fromRecentlyAddedUpdated} toRecentlyAddedUpdated={this.state.toRecentlyAddedUpdated} gotoPageRecentlyAddedUpdated={this.gotoPageRecentlyAddedUpdated} />
                            {this.state.funcLoaded &&
                                <>
                                    <WNFYSection customWidth={this.state.width} customHeight={this.state.height} />
                                    <WOAVSection customWidth={this.state.width} customHeight={this.state.height} />
                                    <RecentlyViewedSection customWidth={this.state.width} customHeight={this.state.height} />
                                    <QueryHistorySection customWidth={this.state.width} customHeight={this.state.height} />
                                    {JSON.parse(localStorage.getItem('function')) !== "Commercial" &&
                                        <ContentShortcut showFilters={this.state.showFilters}
                                            showAnDCSModal={this.state.showAnDCSModal}
                                            showSRnCSModal={this.state.showSRnCSModal}
                                            showEnTCSModal={this.state.showEnTCSModal}
                                            showKMLCSModal={this.state.showKMLCSModal}
                                            showKCLCSModal={this.state.showKCLCSModal}
                                            showLDCSModal={this.state.showLDCSModal}
                                            showSCDCSModal={this.state.showSCDCSModal}
                                            showCRPCSModal={this.state.showCRPCSModal}
                                            showTAnDCSModal={this.state.showTAnDCSModal}
                                            showEPCSModal={this.state.showEPCSModal}
                                            showMIRCSModal={this.state.showMIRCSModal}
                                            handelAnDCardDetailModal={(e) => this.handelAnDCardDetailModal(e)}
                                            handelSRnCSCardDetailModal={(e) => this.handelSRnCSCardDetailModal(e)}
                                            handelEnTCardDetailModal={(e) => this.handelEnTCardDetailModal(e)}
                                            handelKMLCardDetailModal={(e) => this.handelKMLCardDetailModal(e)}
                                            handelKCLCardDetailModal={(e) => this.handelKCLCardDetailModal(e)}
                                            handelLDCardDetailModal={(e) => this.handelLDCardDetailModal(e)}
                                            handelSCDCardDetailModal={(e) => this.handelSCDCardDetailModal(e)}
                                            handelCRPCardDetailModal={(e) => this.handelCRPCardDetailModal(e)}
                                            handelTAnDCardDetailModal={(e) => this.handelTAnDCardDetailModal(e)}
                                            handelEPCardDetailModal={(e) => this.handelEPCardDetailModal(e)}
                                            handelMIRCardDetailModal={(e) => this.handelMIRCardDetailModal(e)}
                                            customWidth={this.state.width} customHeight={this.state.height}
                                    // showCSModal={this.state.showCSModal}
                                    /* handelCardDetailModal={(e)=>this.handelCardDetailModal(e)} */ />
                                    }
                                </>
                            }
                        </div>
                        <div className="cs-modalOuter" style={{ display: (this.state.showAnDCSModal || this.state.showSRnCSModal || this.state.showEnTCSModal || this.state.showKMLCSModal || this.state.showKCLCSModal || this.state.showLDCSModal || this.state.showSCDCSModal || this.state.showCRPCSModal || this.state.showTAnDCSModal || this.state.showEPCSModal || this.state.showMIRCSModal) ? null : "none" }} onClick={this.handelcloseCSModal}></div>
                        <div style={{ margin: "0% 1%", marginTop: "auto" }}>
                            <Footer customWidth={this.state.width} customHeight={this.state.height} />
                        </div>
                         </>
                        :
                        <div style={{ textAlign: "center", padding: "10%" }}>
                            <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
                        </div>
                    }
                </ErrorBoundary>)
        }
        else if (this.state.width >= 600 && this.state.width <= 1000) {
            return (
                <ErrorBoundary>
                    {this.state.userExists ? <>
                        <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                        <div className="header-wrapper">
                            <HeaderSearch history={this.props.history} dropdownValue='All Fields' searchKeyword=''
                                appliedFilters={this.state.appliedFilters} handleChangeInSearch={this.handleChangeInSearch} customWidth={this.state.width} customHeight={this.state.height} />
                        </div>
                        <div className="recommended-contents-wrapper">
                            <ResponsiveFilterComponent pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} allFilters={this.state.allFilters} filters={this.state.filters} appliedFilters={this.state.appliedFilters} handleAllfilters={this.handleAllFilters} handleOnChangeInfilter={this.handleOnChangeInfilter} selectedFilters={this.state.selectedFilters} selectedParentFilterArr={this.state.selectedParentFilterArr} querytext={this.state.searchKeyword} isProcessing={this.state.isProcessing} recentlyUsedFilters={this.state.recentlyUsedFilters}
                                handleIsProcessing={this.handleIsProcessing}
                                fromRecentlyAddedUpdated={this.state.fromRecentlyAddedUpdated} toRecentlyAddedUpdated={this.state.toRecentlyAddedUpdated} gotoPageRecentlyAddedUpdated={this.gotoPageRecentlyAddedUpdated} />
                            {this.state.funcLoaded &&
                                <>
                                    <WNFYSection customWidth={this.state.width} customHeight={this.state.height} />
                                    <WOAVSection customWidth={this.state.width} customHeight={this.state.height} />
                                    <div className="rv-qh-outer-wrapper-tablet">
                                        <RecentlyViewedSection customWidth={this.state.width} customHeight={this.state.height} />
                                        <QueryHistorySection customWidth={this.state.width} customHeight={this.state.height} />
                                    </div>
                                    {JSON.parse(localStorage.getItem('function')) !== "Commercial" &&
                                        <ContentShortcut showFilters={this.state.showFilters}
                                            showAnDCSModal={this.state.showAnDCSModal}
                                            showSRnCSModal={this.state.showSRnCSModal}
                                            showEnTCSModal={this.state.showEnTCSModal}
                                            showKMLCSModal={this.state.showKMLCSModal}
                                            showKCLCSModal={this.state.showKCLCSModal}
                                            showLDCSModal={this.state.showLDCSModal}
                                            showSCDCSModal={this.state.showSCDCSModal}
                                            showCRPCSModal={this.state.showCRPCSModal}
                                            showTAnDCSModal={this.state.showTAnDCSModal}
                                            showEPCSModal={this.state.showEPCSModal}
                                            showMIRCSModal={this.state.showMIRCSModal}
                                            handelAnDCardDetailModal={(e) => this.handelAnDCardDetailModal(e)}
                                            handelSRnCSCardDetailModal={(e) => this.handelSRnCSCardDetailModal(e)}
                                            handelEnTCardDetailModal={(e) => this.handelEnTCardDetailModal(e)}
                                            handelKMLCardDetailModal={(e) => this.handelKMLCardDetailModal(e)}
                                            handelKCLCardDetailModal={(e) => this.handelKCLCardDetailModal(e)}
                                            handelLDCardDetailModal={(e) => this.handelLDCardDetailModal(e)}
                                            handelSCDCardDetailModal={(e) => this.handelSCDCardDetailModal(e)}
                                            handelCRPCardDetailModal={(e) => this.handelCRPCardDetailModal(e)}
                                            handelTAnDCardDetailModal={(e) => this.handelTAnDCardDetailModal(e)}
                                            handelEPCardDetailModal={(e) => this.handelEPCardDetailModal(e)}
                                            handelMIRCardDetailModal={(e) => this.handelMIRCardDetailModal(e)}
                                            customWidth={this.state.width} customHeight={this.state.height}
                                // showCSModal={this.state.showCSModal}
                                /* handelCardDetailModal={(e)=>this.handelCardDetailModal(e)} */ />
                                    }

                                </>
                            }
                        </div>
                        <div className="cs-modalOuter" style={{ display: (this.state.showAnDCSModal || this.state.showSRnCSModal || this.state.showEnTCSModal || this.state.showKMLCSModal || this.state.showKCLCSModal || this.state.showLDCSModal || this.state.showSCDCSModal || this.state.showCRPCSModal || this.state.showTAnDCSModal || this.state.showEPCSModal || this.state.showMIRCSModal) ? null : "none" }} onClick={this.handelcloseCSModal}></div>
                        <div style={{ margin: "0% 1%", marginTop: "auto" }}>
                            <Footer customWidth={this.state.width} customHeight={this.state.height} />
                        </div>
                        {/*(userAccessGroup.includes(Answers)) && <ChatBotSection customWidth={this.state.width}
                            customHeight={this.state.height} />*/}
                    </>
                        :
                        <div style={{ textAlign: "center", padding: "10%" }}>
                            <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
                        </div>
                    }
                </ErrorBoundary>)
        }
        else if (this.state.width > 1000) {
            return (
                <div>
                    <ErrorBoundary>
                        {this.state.userExists ? <>
                            <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                            <div className="header-wrapper">
                                <HeaderSearch history={this.props.history} dropdownValue='All Fields' searchKeyword=''
                                    appliedFilters={this.state.appliedFilters} handleChangeInSearch={this.handleChangeInSearch} customWidth={this.state.width} customHeight={this.state.height} />
                            </div>
                            <div className="recommended-contents-wrapper" style={{ minHeight: this.state.height <= 560 && "220vh" }}>
                                <div className={this.state.showFilters ? "filterMenuWrapper filterMenuWrapperExpanded" : "filterMenuWrapper"} style={{ float: "left" }}>
                                    {this.state.showFilters ?
                                        <>
                                            <div className="filterMenuHeader " style={{ background: '#2a498a' }}>
                                                <span className="filter-menu-icon-wrapper"><img src={filterIconWhite} alt="" /></span>
                                                <span style={{ color: "white" }}>Filters</span>
                                                <span style={{ float: "right" }} onClick={e => this.setState({ showFilters: !this.state.showFilters })}><img src={collapseFilterWhite} alt="" /></span>
                                            </div>
                                            <div className="filter-menu-content">
                                                {this.state.isLoaded ?
                                                    <SearchFilter allFilters={this.state.allFilters}
                                                        filters={this.state.filters}
                                                        appliedFilters={this.state.appliedFilters}
                                                        handleAllfilters={this.handleAllFilters}
                                                        handleOnChangeInfilter={this.handleOnChangeInfilter}
                                                        selectedFilters={this.state.selectedFilters}
                                                        selectedParentFilterArr={this.state.selectedParentFilterArr}
                                                        querytext={this.state.searchKeyword}
                                                        isProcessing={this.state.isProcessing}
                                                        handleIsProcessing={this.handleIsProcessing}
                                                        customWidth={this.state.width}
                                                        recentlyUsedFilters={this.state.recentlyUsedFilters}
                                                        fromRecentlyAddedUpdated={this.state.fromRecentlyAddedUpdated}
                                                        toRecentlyAddedUpdated={this.state.toRecentlyAddedUpdated}
                                                        gotoPageRecentlyAddedUpdated={this.gotoPageRecentlyAddedUpdated} />
                                                    :
                                                    <div style={{ textAlign: "center", padding: "10%" }}>
                                                        <img src={imsLoader} alt="Loading Filters" style={{ width: "20%" }} />
                                                    </div>}
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="result-filter-expand-wrapper" onClick={e => this.setState({ showFilters: !this.state.showFilters })}><img src={expandFilterWhite} alt="" style={{ background: '#2a498a' }} /></div>
                                        </>
                                    }
                                </div>
                                <div className={this.state.showFilters ? "content-shortcut-wrapper content-shortcut-wrapper-collapsed" : "content-shortcut-wrapper"}>
                                    {/* <GlobalProduct/> */}
                                    {this.state.funcLoaded &&
                                        <>
                                            <div className="WNFY-WOAV-RV-QH-outer-wrapper">
                                                <div className="WNFY-WOAV-outer-wrapper">
                                                    <WNFYSection customWidth={this.state.width} customHeight={this.state.height} />
                                                    <WOAVSection customWidth={this.state.width} customHeight={this.state.height} />
                                                </div>
                                            </div>
                                            {JSON.parse(localStorage.getItem('function')) !== "Commercial" &&
                                                <ContentShortcut showFilters={this.state.showFilters}
                                                    showAnDCSModal={this.state.showAnDCSModal}
                                                    showSRnCSModal={this.state.showSRnCSModal}
                                                    showEnTCSModal={this.state.showEnTCSModal}
                                                    showKMLCSModal={this.state.showKMLCSModal}
                                                    showKCLCSModal={this.state.showKCLCSModal}
                                                    showLDCSModal={this.state.showLDCSModal}
                                                    showSCDCSModal={this.state.showSCDCSModal}
                                                    showCRPCSModal={this.state.showCRPCSModal}
                                                    showTAnDCSModal={this.state.showTAnDCSModal}
                                                    showEPCSModal={this.state.showEPCSModal}
                                                    showMIRCSModal={this.state.showMIRCSModal}
                                                    handelAnDCardDetailModal={(e) => this.handelAnDCardDetailModal(e)}
                                                    handelSRnCSCardDetailModal={(e) => this.handelSRnCSCardDetailModal(e)}
                                                    handelEnTCardDetailModal={(e) => this.handelEnTCardDetailModal(e)}
                                                    handelKMLCardDetailModal={(e) => this.handelKMLCardDetailModal(e)}
                                                    handelKCLCardDetailModal={(e) => this.handelKCLCardDetailModal(e)}
                                                    handelLDCardDetailModal={(e) => this.handelLDCardDetailModal(e)}
                                                    handelSCDCardDetailModal={(e) => this.handelSCDCardDetailModal(e)}
                                                    handelCRPCardDetailModal={(e) => this.handelCRPCardDetailModal(e)}
                                                    handelTAnDCardDetailModal={(e) => this.handelTAnDCardDetailModal(e)}
                                                    handelEPCardDetailModal={(e) => this.handelEPCardDetailModal(e)}
                                                    handelMIRCardDetailModal={(e) => this.handelMIRCardDetailModal(e)}
                                                    customWidth={this.state.width} customHeight={this.state.height}
                                    // showCSModal={this.state.showCSModal}
                                    /* handelCardDetailModal={(e)=>this.handelCardDetailModal(e)} */ />
                                            }

                                        </>
                                    }
                                </div>
                            </div>
                            <div className="cs-modalOuter" style={{ display: (this.state.showAnDCSModal || this.state.showSRnCSModal || this.state.showEnTCSModal || this.state.showKMLCSModal || this.state.showKCLCSModal || this.state.showLDCSModal || this.state.showSCDCSModal || this.state.showCRPCSModal || this.state.showTAnDCSModal || this.state.showEPCSModal || this.state.showMIRCSModal) ? null : "none" }} onClick={this.handelcloseCSModal}></div>
                            <div style={{ margin: "0% 1%", marginTop: "auto" }}>
                                <Footer customWidth={this.state.width} customHeight={this.state.height} />
                            </div>
                            {/* {(userAccessGroup.includes(Answers)) && <ChatBotSection customWidth={this.state.width}}
                                { customHeight={this.state.height} />} */}
                        </>
                            :
                            <div style={{ textAlign: "center", padding: "10%" }}>
                                <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
                            </div>
                        }
                    </ErrorBoundary>
                </div>
            )
        }
    }
}
export default RecommendedHome;