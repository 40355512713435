import { Modal } from "react-bootstrap";
import { useState } from "react";
import Button from "../Button";

const convertToHtml = (data) => {
    return <div dangerouslySetInnerHTML={{ __html: data }}></div>
}
export default function AlertMessagePopUpModal(props) {
    const {
        color,
        content,
        showModal,
        handleClose
    } = props
    const [showPop, setShow] = useState(showModal)
    return (
        <Modal
            size="md sm xs"
            show={showPop}
            keyboard={false}
            dialogClassName="popTimeOut"
            className="z-index-alertMessage"
            backdropClassName="z-index-alertMessage-backdrop"
        >
            <div style={{ borderLeft: `5px solid ${color}` }}>
                <Modal.Body>
                    <div>
                        {convertToHtml(content)}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button text="OK" customColor={color} action={() => { setShow(false); handleClose(false) }} />
                </Modal.Footer>
            </div>
        </Modal>
    )
}