import React, { Component } from "react";
import { IMSLogo, crossIcon, magnifyingGlass } from '../../assets'
import { Link } from 'react-router-dom';
import trackingService from "../../services/trackingService";
import GenerateArrayStrings from "../GenerateArrayStrings";
import GlobalFeatures from "../GlobalSearchFeatures/GlobalFeatures";
import Button from "../Button";

class PubMedHeaderSearch extends Component {
  constructor(props) {
    super(props);
    /* this.inputBoxRef = React.createRef(); */
    this.state = {
      openPopUp: false,
      showNav: false,
      infoPopUp: false,
      dropdownValue: 'All Fields',
      searchKeyword: '',
      appliedFilters: [], //Filters that are applied for while search is happening
      showOptions: false,
      showSuggestions: false,
      keyBoardEvent: false,
      keyEventName: '',
      fieldedValue: ''
    }
  }
  componentDidMount() {
    //console.log(this.props.searchKeyword);
    if (this.props.dropdownValue !== '' && this.props.searchKeyword !== '') {
      this.setState({
        dropdownValue: this.props.dropdownValue,
        searchKeyword: this.props.searchKeyword,
        appliedFilters: this.props.appliedFilters
      })
    }
    //window.addEventListener('blur',this.handleCloseSuggestions)
  }
  componentDidUpdate(prevprops) {
    if (this.props.isClearSearch && this.props.searchKeyword !== prevprops.searchKeyword) {
      this.setState({
        searchKeyword: this.props.searchKeyword,
        dropdownValue: this.props.dropdownValue
      })
    }
  }
  componentWillUnmount() {
    //window.removeEventListener('blur',this.handleCloseSuggestions)
  }
  handleNav = () => {
    this.setState({
      showNav: !this.state.showNav
    })
  }
  handleClose = () => {
    this.setState({
      showNav: false
    })
  }
  handleCategoryDropdown = () => {
    this.setState({
      showOptions: !this.state.showOptions
    })
  }
  handleChangeInDropdown = (event) => {
    //console.log(event.target.attributes.keyValue.value);
    let fieldedVal;
    event.preventDefault();
    if (event.target.attributes.keyvalue.value === 'All Fields') {
      fieldedVal = ''
    }
    else if (event.target.attributes.keyvalue.value === 'Author') {
      fieldedVal = 'authors'
    }
    else if (event.target.attributes.keyvalue.value === 'Congress') {
      fieldedVal = 'fullCnSf'
    }
    else if (event.target.attributes.keyvalue.value === 'Document Type') {
      fieldedVal = 'docTypeSynonyms'
    }
    else if (event.target.attributes.keyvalue.value === 'Title') {
      fieldedVal = 'docTitle'
    }
    else if (event.target.attributes.keyvalue.value === 'Publication Year') {
      fieldedVal = 'strYear'
    }
    else if (event.target.attributes.keyvalue.value === 'Journal Name') {
      fieldedVal = 'journalName'
    }
    //const newUrl = this.buildUrl(this.state.searchKeyword,fieldedVal,this.props.appliedFilters)
    this.setState({
      dropdownValue: event.target.attributes.keyvalue.value,
      showOptions: !this.state.showOptions,
      fieldedValue: fieldedVal
    })
  }
  handleChangeInSearch = (event) => {
    /* let filters = this.props.location.search
    console.log(filters) */
    this.setState({
      searchKeyword: event.target.value,
      showSuggestions: true
    })
  }
  handleCancelSearchKeyword = () => {
    this.setState({
      searchKeyword: '',
      showSuggestions: false
    })
  }
  buildUrl = (searchKeyword, dropdownValue, fieldedVal, filters) => {
    searchKeyword=encodeURIComponent(searchKeyword);
    //console.log(this.props.location.search)
    let filterStr = GenerateArrayStrings(filters)
    if (searchKeyword === undefined || dropdownValue === undefined || filters === undefined) {
      return `/pubMedResults?query=&q=&filters=`;
    }
    else if (dropdownValue === 'All Fields') {
      return `/pubMedResults?query=${searchKeyword}&q=All Fields&filters=${filterStr}`
    }
    else if (searchKeyword === '') {
      return `/pubMedResults?query=&q=All Fields&filters=${filterStr}`
    }
    else {
      return `/pubMedResults?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}&f1=${fieldedVal}&fval1=${searchKeyword}&fcount=1`;
    }
  }
  handleKeyDown = (event) => {
    //console.log(this.props.appliedFilters)
    const newUrl = this.buildUrl(event.target.value, this.state.dropdownValue, this.state.fieldedValue, this.props.appliedFilters);
    if (['Enter'].includes(event.key)) {
      this.setState({
        showSuggestions: false
      });
      this.props.handleChangeInSearch(this.state.searchKeyword);
      this.props.history.push(newUrl);
      const ts = new trackingService();
      ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), 'Conducted PubMed Search', '', window.location.href, this.state.searchKeyword, this.props.appliedFilters);
    }
    if (['ArrowDown'].includes(event.key) || ['ArrowUp'].includes(event.key)) {
      //console.log('Arrow Action')
      this.setState({
        keyBoardEvent: true,
        keyEventName: event.key
      })
    }
  }
  handleOnClickSearchIcon = () => {
    /* let filters = this.props.location.search */
    //console.log(this.state)
    if (this.state.searchKeyword === '') {
      this.setState({
        dropdownValue: 'All Fields'
      })
    }
    const newUrl = this.buildUrl(this.state.searchKeyword, this.state.dropdownValue, this.state.fieldedValue, this.props.appliedFilters);
    this.props.handleChangeInSearch(this.state.searchKeyword);
    this.props.history.push(newUrl);
    const ts = new trackingService();
    ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), 'Conducted PubMed Search', '', window.location.href, this.state.searchKeyword, this.props.appliedFilters);
    this.setState({
      keyBoardEvent: false,
      keyEventName: '',
      showSuggestions: false,
    })
  }
  handleOnChange = (query, fieldParam) => {
    let fieldVal, dropdownValue;
    if (fieldParam === 'all Authors') {
      fieldVal = 'authors'
      dropdownValue = 'Author'
    }
    else if (fieldParam === 'all Congresses') {
      fieldVal = 'fullCnSf'
      dropdownValue = 'Congress'
    }
    else if (fieldParam === 'all Titles') {
      fieldVal = 'docTitle'
      dropdownValue = 'Title'
    }
    else if (fieldParam === 'all Document Types') {
      fieldVal = 'docTypeSynonyms'
      dropdownValue = 'Document Type'
    }
    else if (fieldParam === 'all Journals') {
      fieldVal = 'journalName'
      dropdownValue = 'Journal Name'
    }
    else if (fieldParam === 'all Publication Years') {
      fieldVal = 'strYear'
      dropdownValue = 'Publication Year'
    }
    else if (fieldParam === null) {
      fieldVal = this.state.fieldedValue
      dropdownValue = this.state.dropdownValue
    }
    else {
      fieldVal = ''
      dropdownValue = 'All Fields'
    }
    this.setState({
      searchKeyword: query,
      showSuggestions: false,
      fieldedValue: fieldVal,
      dropdownValue: dropdownValue
    }, () => {
      this.handleOnClickSearchIcon()
    })
  }
  handleCollapseAllGlobal = () => {
    this.props.handleCollapseAllGlobal();
  }
  handleSelectAll = () => {
    this.props.handleSelectAll();
  }
  handleCategoryClose = () => {
    this.setState({
      showOptions: false
    })
  }
  handleCloseSuggestions = (event) => {
    this.setState({
      showSuggestions: false
    })
    /* if(this.inputBoxRef && !this.inputBoxRef.current.contains(event.target)){
        this.setState({
            showSuggestions : false
        })
    } */
  }
  render() {
    if (this.props.customWidth < 600) {
      return (
        <div className="header-wrapper-mobileView">
          <div className="searchInput-container">
            <input className="input-search-box-mobileView" type="text" placeholder="What are you looking for?" title="What are you looking for?" value={this.state.searchKeyword} onChange={this.handleChangeInSearch} onKeyDown={this.handleKeyDown} autoComplete="off" />
            <span
              className="icon-wrapper">
              {this.state.searchKeyword && (
                <img
                  src={crossIcon}
                  className="input-search-cancel-icon"
                  alt="clear"
                  title="Click here to clear the search terms"
                  onClick={this.handleCancelSearchKeyword}
                />
              )}
              <span className="search-icon-wrapper-mobileView" onClick={this.handleOnClickSearchIcon}>
                <img
                  src={magnifyingGlass}
                  className="magnifyingGlass-mobileView"
                  alt="search"
                  title="Search"
                // onClick={this.handleOnClickSearchIcon}
                />
              </span>
            </span>
          </div>
          <hr className="header-sepration-line" />
        </div>
      );

    }
    else if (this.props.customWidth < 1000) {
      return (
        <div className="headerWithBannerWrapper">
          <div
            className="bannerImage"
            style={{ padding: "0 0 0%", margin: "0 0 1%" }}
          >
            <Link to="/">
              <span>
                <img src={IMSLogo} alt="" style={{ paddingTop: "1.5%", marginBottom:"0.5%" }} />
                {/* <img src={iMSLogoTabletView} alt="" style={{ paddingTop: "2%" }} /> */}
              </span>
            </Link>
            <div className="search-input-wrapper-tabletView" style={{ height: "37px" }}>
              <div className="input-box-wrapper-tabletView">
                <input
                  name="searchBar"
                  placeholder="What are you looking for?"
                  className="input-search-box-tabletView"
                  title="What are you looking for?"
                  value={this.state.searchKeyword}
                  onChange={this.handleChangeInSearch}
                  onKeyDown={this.handleKeyDown}
                  autoComplete="off"
                />
                {/* <span
                            className="icon-wrapper" style={{
                              width: this.state.searchKeyword.length > 0 ?  "112px" "110px" : "67px"
                            }}
                          > */}
                {/* {this.state.searchKeyword && (
                              <>
                                <img
                                  src={crossIcon}
                                  className="input-search-cancel-icon"
                                  alt="clear"
                                  title="Click here to clear the search terms"
                                  onClick={this.handleCancelSearchKeyword}
                                />
                                <div class="vline"></div>
                              </>
                            )} */}
                {/* <img
                            src={searchIcon}
                            className="search-icon-wrapper"
                            alt="search"
                            title="Search"
                            onClick={this.handleOnClickSearchIcon}
                          /> */}
                {/* <button title="Search" className="search-button-wrapper" onClick={this.handleOnClickSearchIcon}>
                              Search
                            </button> */}

                {/* </span> */}
                <span
                  className="icon-wrapper">
                  {/* {this.state.searchKeyword && ( */}
                  {/* <> */}
                  {this.state.searchKeyword &&
                    <img
                      src={crossIcon}
                      className="input-search-cancel-icon"
                      alt="clear"
                      title="Click here to clear the search terms"
                      onClick={this.handleCancelSearchKeyword}
                    />
                  }
                  <span className="search-icon-wrapper-mobileView" onClick={this.handleOnClickSearchIcon}>
                    <img
                      src={magnifyingGlass}
                      className="magnifyingGlass-mobileView"
                      alt="search"
                      title="Search"
                    // onClick={this.handleOnClickSearchIcon}
                    />
                  </span>
                  {/* </>
                    )} */}
                </span>
              </div>
            </div>
            <div className="search-input-below-wrapper-without-item"></div>
          </div>
        </div>
      );

    }
    else {
      return (
        <div className="headerWithBannerWrapper">
          <div className="bannerImage" style={{ padding: "0 0 0%", margin: "0 0 1%" }}>
            <Link to="/">
              <span><img src={IMSLogo} alt="" style={{ paddingTop: "1.5%", marginBottom:"0.5%" }} /></span>
            </Link>
            <div className="search-input-wrapper" style={{ height: "44px" }}>
              <div className="input-box-wrapper">
                <input name="searchBar" placeholder="What are you looking for?" className="input-search-box" title="What are you looking for?" value={this.state.searchKeyword} onChange={this.handleChangeInSearch} onKeyDown={this.handleKeyDown} autoComplete="off" />

                <span className="icon-wrapper">
                  {this.state.searchKeyword &&
                    <img
                      src={crossIcon}
                      className="input-search-cancel-icon"
                      alt="clear"
                      title="Click here to clear the search terms"
                      onClick={this.handleCancelSearchKeyword}
                    />
                  }
                  <span className="search-icon-wrapper-webView" onClick={this.handleOnClickSearchIcon}>
                    <img
                      src={magnifyingGlass}
                      className="magnifyingGlass-mobileView"
                      alt="search"
                      title="Search"
                    />
                  </span>
                </span>
                {/* <img src={searchIcon} className="search-icon-wrapper" alt="search" title="search" onClick={this.handleOnClickSearchIcon} />
                                {this.state.searchKeyword &&
                                <img src={crossIcn} className="input-search-cancel-icon" alt="clear" title="Click here to clear the search terms" onClick={this.handleCancelSearchKeyword}/>
                                }
                                <FontAwesomeIcon 
                                className='search-icon-wrapper'
                                icon="search"
                                fixedWidth
                                color='#979797'
                                title="Search" 
                                onClick={this.handleOnClickSearchIcon}
                                /> */}

              </div>
              <div className="clear-search-button-wrapper">
                <Button
                  text="Clear Search"
                  customColor="#E39F00"
                  action={() => {
                    this.props.handleClearSearch();
                    this.setState({
                      dropdownValue: "All Fields",
                      searchKeyword: '',
                      appliedFilters: [],
                    });
                  }}
                />
              </div>
            </div>
            <div className="globalSearch-wrapper">
              <GlobalFeatures customWidth={this.props.customWidth} customHeight={this.props.customHeight} handelViewSelected={this.handelViewSelected} handleCollapseAllGlobal={this.handleCollapseAllGlobal} globalCollapseAll={this.props.globalCollapseAll} handleSelectAll={this.handleSelectAll} globalSelectAll={this.props.globalSelectAll} selectedResults={this.props.selectedResults} querytext={this.state.searchKeyword} appliedFilters={this.props.appliedFilters} documentsUrl={this.props.documentsUrl} markedDocumentsUrl={this.props.markedDocumentsUrl} itemsPerPage={this.props.itemsPerPage} />
            </div>
          </div>

        </div>
      )
    }
  }
}
export default PubMedHeaderSearch;