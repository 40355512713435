/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable jsx-a11y/alt-text */
import { desArrow, infoIcn, imsLoader } from '../../assets'
import { useState } from 'react';
import NotebookListPopUp from '../PopUp/NotebookListPopUp'

const formattedStringArr = (array, fn) => {
    var result = [];
    for (var i = 0; i < array.length; i++) {
        var mapping = fn(array[i]);
        result = result.concat(mapping);
    }
    return result;
}
const divider = () => {
    return (
        <div className="document-descriptor-divider"></div>
    )
}
const textFormatter = (data) => {
    
    const replacedString = data.replaceAll("%%##%%##", '%%##').replaceAll("%%##", '\n');
    //console.log(newString)
    let updatedText = formattedStringArr(replacedString.split('\n'), function (part) {
        return [part, <div>{divider()}</div>];
    });
    updatedText.pop()
    return (
        <div>
            {updatedText}
        </div>
    )
}
export default function (props) {

    const [isOpen, setOpenDescriptor] = useState(false);
    const [showNotebookList, setShowNotebookList] = useState(false);
    const [summarizeText, setSummarizeText] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);

    const { summaryText, sourceItem, customWidth } = props
    const handleCloseNotebookList = () => {
        setShowNotebookList(false);
    }
    const handleOpenDescriptor = async () => {
        setOpenDescriptor(!isOpen);
        if (!isOpen && !summarizeText) {

            setIsProcessing(true);
            const { nlpSummary } = sourceItem
            setSummarizeText(nlpSummary.trim())
            setIsProcessing(false);
            // let url = 'https://awsaidnval000f.jnj.com:5059/nlp/api/summarize_api2'
            // let config = {
            //     method: 'POST',
            //     url: url,
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     data: {
            //         "orignalText": summaryText
            //     }
            // }
            // await axios(config).then((response) => {
            //     setSummarizeText(response.data.summarizeText)
            // }).catch((error) => {
            //     console.log(error)
            // }).finally(() => {
            //     setIsProcessing(false);
            // });
        }

    }
    return (
        <div className="document-descriptor-wrapper">
            <div className="document-descriptor-header-wrapper" onClick={e => handleOpenDescriptor()}>
                <img src={desArrow} className={isOpen ? "document-descriptor-img-rotate" : "document-descriptor-img"} onClick={e => handleOpenDescriptor()} />
                <span> Summary</span>
                <img src={infoIcn} className="keyTakeaways-info-icn" title="AI generated summary of the document." />
            </div>
            {isOpen && <>
                 <div className="document-descriptor-text-wrapper">
                    {isProcessing ? <div style={{ textAlign: "center", padding: "2%" }}>
                        <img src={imsLoader} alt="Loading Filters" style={{ width: "10%" }} />
                    </div> :
                        <>{textFormatter(summarizeText)}</>}
                    {!isProcessing && <div className="notebook-notbook-btn">
                        <button className="btnNotebook" type="button" onClick={(e) => setShowNotebookList(true)}  >Add to Notebook</button>
                    </div>}
                </div>
            </>
            }
            {
                showNotebookList &&
                <NotebookListPopUp showModal={showNotebookList} sourceItem={sourceItem} summeryText={summaryText} summarizeText={summarizeText} handleClose={handleCloseNotebookList} width={customWidth}  commandType='Summarize'/>
            }
        </div >
    )
}