/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import Footer from "../../components/footer/Footer";
import { IMSLogo, imsLoader } from "../../assets"
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import trackingService from "../../services/trackingService"
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import { LocationOrigin } from "../../utils/utilityServices";
import { useHistory } from "react-router-dom";
const indexData = (url, domain, ref, docVer, docID) => {
    const reference = ref.charAt(0).toUpperCase() + ref.slice(1);
    const ts = new trackingService();
    if (domain === 'RIMdocs' || domain === 'RIMdocs_LL') {
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), 'View PubMed Document External', `${reference} PubMed Document`, window.location.href, '', '', '', '', '', '', url, '', '', `${reference} PubMed Document`, '', '', 'PubMed', '', '', '');
    }
    else {
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), 'View PubMed Document External', `${reference} PubMed Document`, window.location.href, '', '', '', '', '', '', url, '', '', `${reference} PubMed Document`, '', '', 'PubMed', '', '', '');
    }
}

export default function DocURLPubMed(props) {
    const [isProcessing, setProcessing] = useState(false);
    const [pmid, setPmid] = useState();
    const [isDownloadUrlAvailable, setIsDownloadUrlAvailable] = useState(false);
    const [width] = useState(window.innerWidth);
    const [height] = useState(window.innerHeight);
    let history = useHistory();
    useEffect(() => {
        let userExists = localStorage.getItem('userExists');
        if (userExists) {
            runSearch();
        }
        else {
            console.log('Access Denied');
            history.push('/');
        }
    }, []);

    const runSearch = () => {
        setProcessing(true)
        let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'));
        var url = LocationOrigin + process.env.REACT_APP_IMS_API_PREFIX + 'qp/runSearch'
        var config;
        const queryParams = new URLSearchParams(props.location.search)
        const pmidQueryParams = queryParams.get('pmid')
        const ref = queryParams.get('ref')
        setPmid(pmidQueryParams);
        config = {
            method: 'POST',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                "query": '',
                "filters": '',
                "function": localStorage.getItem('function').replace(/"/g, ''),
                "doctypepref": '',
                "userproducts": localStorage.getItem('userproducts'),
                "region": localStorage.getItem('region'),
                "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                "contentpreference": localStorage.getItem('contentpreference').replace(/"/g, ''),
                "from": 0,
                "size": 25,
                "f1": "pmID",
                "fval1": pmidQueryParams,
                "fcount": 1,
                accessGroup: (userAccessGroup.includes("MISEHCP")) ? "MISE" : ""
            }
        }

        axios(config)
            .then((response) => {
                if (response.data.body.hits.total.value > 0) {
                    if (response.data.body.hits.hits[0]._source) {
                        let downloadUrl = '';
                        let sourceItem = response.data.body.hits.hits[0]._source
                        if (sourceItem.downloadUrl) {
                            if (sourceItem.downloadUrl.toLowerCase().includes('fileName='.toLocaleLowerCase())) {
                                var sourceItemURL = new URL(`${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}${sourceItem.downloadUrl.replace('/api/', '')}`);
                                var search_params = sourceItemURL.searchParams;
                                search_params.set('fileName', sourceItem.docTitle);
                                sourceItemURL.search = search_params.toString();
                                downloadUrl = `${sourceItemURL.toString().replace(`${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}`, '')}`
                            } else {
                                downloadUrl = `${sourceItem.downloadUrl.replace('/api/', '')}&fileName=${sourceItem.docTitle}`
                            }
                        } else {
                            if (sourceItem.domain === 'SKM') {
                                downloadUrl = `dcs-spo/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&fileName=${sourceItem.docTitle}`
                            }
                            else if (sourceItem.domain === 'RIMdocs') {
                                downloadUrl = `download/RIMdocs?strID=${sourceItem.docID}`
                            }
                            else if (sourceItem.domain === 'SRD') {
                                if (sourceItem.fileExtension !== 'pdf') {
                                    downloadUrl = `dcs-vv/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&docVer=${sourceItem.majorVersionNumber}&fileName=${sourceItem.docTitle}`
                                }
                                else {
                                    downloadUrl = `dcs-vv/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&fileName=${sourceItem.docTitle}`
                                }
                                if (sourceItem.a_status === "Approved Needs Review") {
                                    downloadUrl = downloadUrl + `&docState=anr`
                                }
                            }
                            else if (sourceItem.domain === 'RIMdocs_LL') {
                                downloadUrl = `download/RIMdocs?strID=${sourceItem.docID}`
                            }
                            else if (sourceItem.domain === 'MLR') {
                                if (sourceItem.fileExtension !== 'pdf') {
                                    downloadUrl = `dcs-vv/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&docVer=${sourceItem.majorVersionNumber}&fileName=${sourceItem.docTitle}`
                                }
                                else {
                                    downloadUrl = `dcs-vv/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&fileName=${sourceItem.docTitle}`
                                }
                                if (sourceItem.status === "Approved Needs Review") {
                                    downloadUrl = downloadUrl + `&docState=anr`
                                }
                            }
                            else if (sourceItem.domain === "SR" || sourceItem.domain === "SRAP" || sourceItem.domain === "GCSR" || sourceItem.domain === "SFM") {

                                if (sourceItem.fileExtension !== 'pdf') {
                                    downloadUrl = `dcs-vv/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&docVer=${sourceItem.majorVersionNumber}&fileName=${sourceItem.docTitle}`
                                }
                                else {
                                    downloadUrl = `dcs-vv/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&fileName=${sourceItem.docTitle}`
                                }
                                if (sourceItem.status === "Approved Needs Review") {
                                    downloadUrl = downloadUrl + `&docState=anr`
                                }
                            }
                            else if (sourceItem.domain === "iMR") {
                                if (sourceItem.fileExtension !== 'pdf') {
                                    downloadUrl = `dcs-vv/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&docVer=${sourceItem.majorVersionNumber}&fileName=${sourceItem.docTitle}`
                                }
                                else {
                                    downloadUrl = `dcs-vv/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&fileName=${sourceItem.docTitle}`
                                }
                                if (sourceItem.status === "Approved Needs Review") {
                                    downloadUrl = downloadUrl + `&docState=anr`
                                }

                            }
                            else if (sourceItem.domain === "CTGov") {

                                downloadUrl = `https://clinicaltrials.gov/ct2/show/${sourceItem.docID}`;
                            }
                            else if (sourceItem.domain === "IROs") {

                                downloadUrl = `dcs-spo/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&fileName=${sourceItem.docTitle}`
                            }
                            else if (sourceItem.domain === "MIAs" && sourceItem.downloadUrl) {
                                downloadUrl = `${sourceItem.downloadUrl.replace('/api/', '')}`
                            }
                            else {
                                if (sourceItem.fileExtension !== 'pdf') {
                                    downloadUrl = `dcs-vv/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&docVer=${sourceItem.majorVersionNumber}&fileName=${sourceItem.docTitle}`
                                }
                                else {
                                    downloadUrl = `dcs-vv/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&fileName=${sourceItem.docTitle}`
                                }
                                if (sourceItem.status === "Approved Needs Review") {
                                    downloadUrl = downloadUrl + `&docState=anr`
                                }

                            }
                        }
                        if (downloadUrl) {
                            setIsDownloadUrlAvailable(true);
                            let windowlocation = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}${downloadUrl}`;
                            if (ref) {
                                let { domain, docVer, docID } = sourceItem;
                                indexData(`/api/${downloadUrl}`, domain, ref, docVer, docID)
                            }
                            window.location.href = windowlocation;
                        }

                    }
                }
            })
            .catch(function (error) {
                //console.log(error);
            })
            .finally(async () => {
                setProcessing(false)
            })

    }
    return (
        <>
            <ErrorBoundary>
                <div className="document-tab-outer-wrapper">
                    <div className="document-tab-wrapper">
                        <div className="document-header-wrapper">
                            <a>
                                <img src={IMSLogo} alt="" title="iMedical Search" />
                            </a>
                        </div>
                        {isProcessing ? <div style={{ textAlign: "center", padding: "10%" }}>
                            <img src={imsLoader} alt="Loading Filters" style={{ width: "10%" }} />
                        </div> : <div className="document-tab-inner-wrapper">
                            {isDownloadUrlAvailable ? <div className="document-tab-content-upper-card">
                                <span style={{ color: "#1076bc", fontSize: "20px" }}><b>Thank you.</b> Your document will soon be available here.</span><br />
                            </div> : <div className="document-tab-content-upper-card">
                                <span style={{ color: "#1076bc", fontSize: "20px" }}>Sorry! The full text is not available from iMedical Search sources.</span><br />
                            </div>}
                            <div className="pubMed-document-links">
                                <ol>
                                    <li>Click <a href={`https://pubmed.ncbi.nlm.nih.gov/${pmid}`} style={{ cursor: "pointer", color: "#1076bc" }} target="_blank"><b>here</b></a> to view the PubMed record on <b>PubMed</b> (Full document may be available via <b>PubMed Central</b>)  </li>
                                    <li>Click <a href={`https://www.rightfind.com/vlib/rfe.aspx?redir=%2fvlib%2forder%2fMultiCitation.aspx%3fCALLING_SYSTEM%3dPubMed%26BACK_URL%3dhttps%253a%252f%252fwww.ncbi.nlm.nih.gov%252fpubmed%253fDb%253dpubmed%26PMID_1%3d${pmid}`} style={{ cursor: "pointer", color: "#1076bc" }} target="_blank"><b>here</b></a> to order document via <b>RightFind</b> (E-mail <a href="mailto:jnjhelp@copyright.com.com" style={{ cursor: "pointer", color: "#1076bc" }}><b>jnjhelp@copyright.com</b></a> for a RightFind account if needed)</li>
                                </ol>
                            </div>
                            <div>
                                <span style={{ fontSize: "12px" }}>
                                    Want to go to iMedical Search (iMS) Home Page? <Link to="/" style={{ cursor: "pointer", color: "#1076bc" }} target="_blank"> Click here </Link>to proceed.
                                </span><br />
                                {/* <span style={{fontSize:"12px"}}>You may close this tab after your document is downloaded.</span> */}
                            </div>
                            <div className="document-tab-disclaimer-text">
                                <b style={{ color: "red" }}>Disclaimer:</b> The purpose of iMS is to provide Scientific Affairs, Medical Affairs, and R&D personnel worldwide the ability to easily access and share key scientific content <b><i>internally.</i></b> External discussion, presentation, or dissemination of this content as well as use for validated processes must be compliant with local laws, regulations, and company policies and procedures.<br /> <b>Please Note:</b> iMS connects directly to the primary sources of content (e.g. RIMdocs, Veeva Vault) and crawls these content sources once nightly to ensure that you’re accessing only <b>current Approved Effective</b> versions of the documents.
                            </div>
                        </div>}

                    </div>
                    <div style={{ margin: "0% 1.5%" }}>
                        <Footer
                            customWidth={width}
                            customHeight={height} />
                    </div>
                </div>
            </ErrorBoundary>
        </>
    )
}