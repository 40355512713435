import PopUpModal from "./PopUpModal";
import { saveReadingListSuccess } from "../../assets";
export default function SuccessReadingList(props){
    const{ display,handleClose,data,width }=props
    var privacyHtml = `<div>${data}</div>`
if(width<=1000){
    return(
        <PopUpModal color="#6CC24A" height="15vh" showModal={display} header="Success" content={privacyHtml} icon={saveReadingListSuccess} handleClose={(status)=>handleClose(status)} btnText="Close" btnPosition="center" btnColor="#0074B0"/>
    )
}
else{
    return(
        <PopUpModal color="#6CC24A" height="10vh" showModal={display} header="Success" content={privacyHtml} icon={saveReadingListSuccess} handleClose={(status)=>handleClose(status)} btnText="Close" btnPosition="center" btnColor="#0074B0"/>
    )
}
}