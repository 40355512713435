/* eslint-disable import/no-anonymous-default-export */
import { desArrow, infoIcn } from '../../assets'
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';

export default function (props) {
    const [isOpen, setOpenDescriptor] = useState(false);
    const [greenlights, setGreenlights] = useState([]);

    useEffect(() => {
        const {
            greenlightVersionDocID
        } = props
        let docs = [];
        let greenlightVersionDocs = greenlightVersionDocID.split('$$');
        greenlightVersionDocs.map(x => {
            let greenlightVersion = x.split('@@');
            let _doc = { version: greenlightVersion[0], objectId: greenlightVersion[1], objectName: "", greenlightApprovedDate: "" }
            if (greenlightVersion.length > 2) {
                _doc.objectName = greenlightVersion[2]
            }
            if (greenlightVersion.length > 3 && greenlightVersion[3]) {

                _doc.greenlightApprovedDate = moment(greenlightVersion[3]).format("MM/DD/YYYY")
            }
            docs.push(_doc);
        })
        //docs = docs.sort((a, b) => parseFloat(b.version) - parseFloat(a.version));
        docs = docs.sort((a, b) => moment(b.greenlightApprovedDate) - moment(a.greenlightApprovedDate));
        setGreenlights(docs);
    }, [])
    return (
        <div className="document-descriptor-wrapper">
            <div className="document-descriptor-header-wrapper" onClick={e => setOpenDescriptor(!isOpen)}>
                <img src={desArrow} alt='desArrow' className={isOpen ? "document-descriptor-img-rotate" : "document-descriptor-img"} onClick={e => setOpenDescriptor(!isOpen)} />
                <span> Greenlight Versions</span>
                <img src={infoIcn} alt='infoIcn' className="keyTakeaways-info-icn" title="Greenlight Version History" />
            </div>
            {isOpen &&
                <div className="document-descriptor-text-wrapper greenlight-table-wrapper">
                    <table cellPadding={5} cellSpacing={0} >
                        <thead>
                            <tr>
                                {/* <th>System Version Number</th> */}
                                <th>Object Name</th>
                                <th>Greenlight Approved Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {greenlights.map(x => {
                                return (
                                    <tr>
                                        {/* <td><Link to={`/DocURL?ID=${x.objectId}&ref=Download&cs=RIMdocs&orderedBy=&orderedFor=&greenLight=true`} target="_blank">{x.version}</Link></td> */}
                                        <td><Link to={`/DocURL?ID=${x.objectId}&ref=Download&cs=RIMdocs&orderedBy=&orderedFor=&greenLight=true`} target="_blank">{x.objectName}</Link></td>
                                        <td>{x.greenlightApprovedDate}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}