import { Modal } from "react-bootstrap";
import { useState } from "react";
import { crossIconBlack } from "../../assets";
import './ExpandAllFieldsContent.css'
import { sanitize } from 'dompurify';
export default function ExpandAllFieldsContentPopUpModal(props) {
    const {
        resultsText,
        notesText,
        sourceText,
        showModal,
        lastCommandType,
        handleSaveContent
    } = props
    const [showPop, setShow] = useState(showModal)
    const [notesTextValue, setNotesTextValue] = useState(notesText)
    const [sourceTextValue, setSourceTextValue] = useState(sourceText)

    const handleSourceText = (e) => {
        setSourceTextValue(e.target.value)
    }
    const handleNotesText = (e) => {

        setNotesTextValue(e.target.value)
    }
    return (
        <Modal
            size="lg"
            show={showPop}
            keyboard={false}
            dialogClassName="popTimeOut expand-all-fields-ContentPopUp"
            className="z-index-alertMessage"
            backdropClassName="z-index-expand-all-fields-ContentPopUp">
            <div style={{ padding: "25px" }}>
                <div className="expand-all-content-cancel">
                    <button onClick={(e) => { setShow(false); handleSaveContent(sourceTextValue, notesTextValue) }} >
                        <img src={crossIconBlack} alt="" />
                    </button>
                </div>
                <div className="expand-all-fields-wrapper">
                    <div className="sourcetext-fields-wrapper">
                        <div className="sourcetext-fields-label">
                            <span>Source Text</span>
                        </div>
                        <div className="sourcetext-fields-textarea">
                            <textarea value={sourceTextValue} placeholder="Enter the source text here." className="SourceTextarea" onChange={(e) => handleSourceText(e)} />
                        </div>
                    </div>
                    <div className="result-fields-wrapper">
                        <div className="result-fields-label">
                            <span>Results</span>
                        </div>
                        <div className="result-fields-textarea">
                            {(lastCommandType !== 'CreateTable' && lastCommandType !== 'UserInstructions') && <textarea value={resultsText} className="ResultsTextarea" readOnly={true} />}
                            {(lastCommandType === 'CreateTable' || lastCommandType === 'UserInstructions') && <div className="message-text message-text-expand-all-fields" dangerouslySetInnerHTML={{ __html: sanitize(resultsText) }} />}
                        </div>
                    </div>
                    <div className="notes-fields-wrapper">
                        <div className="notes-fields-label">
                            <span>Notes</span>
                        </div>
                        <div className="notes-fields-textarea">
                            <textarea value={notesTextValue} placeholder="Enter your custom notes here." className="NotesTextarea" onChange={(e) => handleNotesText(e)} />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}