import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { chatbotHeaderIcon, crossIcon } from '../../assets';
import GenAIAskQuestion from "./GenAIAskQuestion"
import GenAIClose from "./GenAIClose";
import { GenAIAnsweringContext } from './GenAIAnsweringContextProvider';
import GenAIAnsweringDisclaimer from './GenAIAnsweringDisclaimer'
import ApiServices from "../../utils/apiServices";

class GenAIHome extends Component {
    static contextType = GenAIAnsweringContext;
    constructor(props) {
        super(props);
        let _showDisclaimerPopup = true;
        let strShowDisclaimerPopup = localStorage.getItem('showGenAIAnsweringDisclaimer');
        if (strShowDisclaimerPopup) {
            _showDisclaimerPopup = !JSON.parse(strShowDisclaimerPopup);
        }
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            showDisclaimerPopup: _showDisclaimerPopup,
        }
    }
    componentDidMount = async () => {
        await this.context.getLatestAnswerThread();
    }
    handleDisclaimerClose = async () => {
        let strShowDisclaimerPopup = localStorage.getItem('showGenAIAnsweringDisclaimer');
        if (strShowDisclaimerPopup && !JSON.parse(strShowDisclaimerPopup)) {
            let service = new ApiServices();
            let wwid = JSON.parse(localStorage.getItem("wwid"));
            await service.UpdateDisclaimerStatus(wwid, 'isshowgenaiansweringdisclaimer', true);
        }
        this.setState({ showDisclaimerPopup: false });
        localStorage.setItem('showGenAIAnsweringDisclaimer', JSON.stringify(true));
    }
    render() {
        let { showDisclaimerPopup } = this.state;
        if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.props.customWidth > 1000) {
            return (
                <div className="gen-ai-answering-main">
                    <GenAIClose />
                    <div className="gen-ai-answering-main-section">
                        <div className="gen-ai-answering-main-img">
                            <img className="bm-agent-image" src={chatbotHeaderIcon} alt="iMS profile image" style={{ backgroundColor: '#ffffff' }} />
                        </div>
                        <GenAIAskQuestion isOpenInModelPopup={false} customWidth={this.state.customWidth} customHeight={this.state.customHeight} key='GenAIHome' customeKey='GenAIHome' />
                    </div>
                    <div className="gen-ai-answering-main-footer">
                        <div className="gen-ai-answering-main-footer-title">
                            <span>Important: Validating GenAI Search Results in iMedical GenAI Answers</span>
                        </div>
                        <div className="gen-ai-answering-main-footer-content">
                            <p>
                                iMedical GenAI Answers searches content sources available in iMedical Search. The source content is updated daily and only includes approved effective materials.
                            </p>
                            <p>
                                GenAI Answers responses are intended for <i>INTERNAL USE ONLY</i>. Information contained in some content is confidential,  All existing processes and procedures regarding external communication, including Medical information discovered in GenAI Answers, continues to apply and must be strictly followed.
                            </p>
                            <p>
                                All users are advised to validate summarized results using the citations provided with the referenced sources.
                            </p>
                            <p>
                                GenAI is capable of providing convincing summaries that may not be complete or contain reasoning that a human would interpret differently. It is your responsibility to check the content, the points, observations and conclusions provided by iMedical GenAI Answers.
                            </p>
                            <p><a className="authoring-model-body-content-link" onClick={() => { this.setState({ showDisclaimerPopup: true }) }} >Gen Ai Adherence Policy</a></p>
                        </div>
                    </div>
                    {showDisclaimerPopup && <GenAIAnsweringDisclaimer showPopup={showDisclaimerPopup} handleDisclaimerClose={this.handleDisclaimerClose} />}
                </div>)
        }
    }

}
export default withRouter(GenAIHome);