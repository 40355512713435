/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Footer from "../../components/footer/Footer";
import { IMSLogo, imsLoader } from "../../assets"
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import trackingService from "../../services/trackingService"
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import { LocationOrigin, DomainMapping } from "../../utils/utilityServices";
import { useHistory } from "react-router-dom";
import ApiServices from "../../utils/apiServices";


const indexData = (url, domain, ref, docVer, docID, orderedBy = '', orderedFor = '') => {
    const reference = ref.charAt(0).toUpperCase() + ref.slice(1);
    const ts = new trackingService();
    if (domain === 'RIMdocs' || domain === 'RIMdocs_LL') {
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), 'View Document External', `${reference} Document`, window.location.href, '', '', '', docVer, '', '', url, '', '', `${reference} Document`, '', '', domain, '', '', '', orderedBy, orderedFor);
    }
    else {
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), 'View Document External', `${reference} Document`, window.location.href, '', '', '', docVer, docID, '', url, '', '', `${reference} Document`, '', '', domain, '', '', '', orderedBy, orderedFor);
    }
}

export default function DocURL(props) {
    //console.log(props)
    const [isProcessing, setProcessing] = useState(true);
    const [width] = useState(window.innerWidth);
    const [height] = useState(window.innerHeight);
    const [isValidUser, setIsValidUser] = useState(true);
    const [isDocumentAvailable, setIsDocumentAvailable] = useState(true);

    const queryParams = new URLSearchParams(props.location.search)
    const docID = queryParams.get('ID')
    const connID = queryParams.get('connID')
    const csID = queryParams.get('csID')
    const fileType = queryParams.get('fileType')
    const domain = queryParams.get('cs')
    const docState = queryParams.get('docState')
    const ref = queryParams.get('ref')
    const fileName = queryParams.get('fileName')
    let orderedBy = queryParams.get('orderedBy')
    let orderedFor = queryParams.get('orderedFor')
    let enclosureID = queryParams.get('enclosureID')
    let greenLight = queryParams.get('greenLight')
    if (!orderedBy) orderedBy = '';
    if (!orderedFor) orderedFor = '';
    let url;
    let history = useHistory();
    useEffect(() => {
        let userExists = localStorage.getItem('userExists');
        const fetchUserDetails = async () => {
            let wwid = JSON.parse(localStorage.getItem('wwid'));
            let service = new ApiServices();
            let apiRes = await service.getUserApprovedRegionRole(wwid);
            let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'));
            if (greenLight && (apiRes.function === 'Medical' || userAccessGroup.includes("MISE"))) {
                downloadDocument('');
            } else {
                verifiedDOcumentForDownload(apiRes.function);
            }
            // if (apiRes.function === 'Medical') {

            //     downloadDocument();
            // } else {
            //     verifiedDOcumentForDownload();
            // }
        }
        if (userExists) {
            fetchUserDetails();
        }
        else {
            console.log('Access Denied');
            history.push('/');
        }

    }, []);
    const downloadDocument = (majorVersionNumber) => {
        if (domain === 'SKM') {
            runSearch(majorVersionNumber);
        } else {
            setProcessing(false);
            if (domain === 'RIMdocs' || domain === 'RIMdocs_LL') {
                url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}download/RIMdocs?strID=${docID}`
                if (greenLight) {
                    url += `&greenLight=true`
                }
                // url =`${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}download/RIMdocs?strID=${fileName}`
            }
            else if (domain === 'SKM' || domain === "MIAs" || domain === "MIAsReferences" || domain === "PERC" || domain === "IROs") {
                url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}dcs-spo/download?connID=${connID}&csID=${csID}&docID=${docID}&fileType=${fileType}&fileName=${fileName}`
            }
            else if (domain === "CTGov") {
                url = `https://clinicaltrials.gov/ct2/show/${docID}`;
                // url = `https://clinicaltrials.gov/ct2/show/${fileName}`;
            }
            else {
                if (fileType !== 'pdf') {
                    url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}dcs-vv/download?connID=${connID}&csID=${csID}&docID=${docID}&fileType=${fileType}&docVer=${majorVersionNumber}&fileName=${fileName}`
                }
                else {
                    url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}dcs-vv/download?connID=${connID}&csID=${csID}&docID=${docID}&fileType=${fileType}&fileName=${fileName}`
                }
                if (docState) {
                    url = url + `&docState=anr`
                }
            }
            if (ref) {
                indexData(url, domain, ref, majorVersionNumber, docID, orderedBy, orderedFor)
            }
            if (enclosureID) {
                url += `&enclosureID=${enclosureID}`
            }
            window.location.href = url;
        }
    }
    const runSearch = (majorVersionNumber) => {
        setProcessing(true)
        var url = LocationOrigin + process.env.REACT_APP_IMS_API_PREFIX + 'qp/runSearchNLP'
        var config;
        const queryParams = new URLSearchParams(props.location.search)
        const docID = queryParams.get('ID')
        const connID = queryParams.get('connID')
        const csID = queryParams.get('csID')
        const fileType = queryParams.get('fileType')
        const domain = queryParams.get('cs')
        const ref = queryParams.get('ref')
        const fileName = queryParams.get('fileName')
        let orderedBy = queryParams.get('orderedBy')
        let orderedFor = queryParams.get('orderedFor')
        let enclosureID = queryParams.get('enclosureID')
        if (!orderedBy) orderedBy = '';
        if (!orderedFor) orderedFor = '';
        let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'));
        let downloadUrl = '';
        config = {
            method: 'POST',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                "query": '',
                "filters": '',
                "function": localStorage.getItem('function').replace(/"/g, ''),
                "doctypepref": '',
                "userproducts": localStorage.getItem('userproducts'),
                "region": localStorage.getItem('region'),
                "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                "contentpreference": localStorage.getItem('contentpreference').replace(/"/g, ''),
                "from": 0,
                "size": 25,
                "f1": "docID",
                "fval1": docID,
                "f2": "domain",
                "fval2": domain,
                "fcount": 2,
                accessGroup: userAccessGroup.includes("Answers") ? "MISE" : "",
                matchPhrase: true,
            }
        }
        axios(config)
            .then((response) => {
                if (response.data.body.hits.total.value > 0 && response.data.body.hits.hits[0]._source) {
                    let sourceItem = response.data.body.hits.hits[0]._source
                    if (sourceItem && sourceItem.fileExtension === "aspx") {
                        downloadUrl = sourceItem.linkURL ? sourceItem.linkURL : sourceItem.spoWebURL;
                    }
                }
            })
            .catch(function (error) {
                //console.log(error);
            })
            .finally(async () => {
                setProcessing(false)
                if (!downloadUrl) {
                    downloadUrl = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}dcs-spo/download?connID=${connID}&csID=${csID}&docID=${docID}&fileType=${fileType}&fileName=${fileName}`
                }
                if (ref) {
                    indexData(url, domain, ref, majorVersionNumber, docID, orderedBy, orderedFor)
                }
                if (enclosureID) {
                    downloadUrl += `&enclosureID=${enclosureID}`
                }
                window.location.href = downloadUrl;
            })

    }
    const verifiedDOcumentForDownload = (_userFunction) => {
        setProcessing(true)
        var url = LocationOrigin + process.env.REACT_APP_IMS_API_PREFIX + 'qp/runSearchNLP'
        var config;
        const queryParams = new URLSearchParams(props.location.search)
        const docID = queryParams.get('ID')
        const csID = queryParams.get('csID')
        const domain = queryParams.get('cs')
        let orderedBy = queryParams.get('orderedBy')
        let orderedFor = queryParams.get('orderedFor')
        if (!orderedBy) orderedBy = '';
        if (!orderedFor) orderedFor = '';
        let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'));
        let _domain = csID ? DomainMapping[csID] : domain;
        config = {
            method: 'POST',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                "query": '',
                "filters": '',
                //"function": localStorage.getItem('function').replace(/"/g, ''),
                "function": "Medical",
                "doctypepref": '',
                "userproducts": localStorage.getItem('userproducts'),
                //"region": localStorage.getItem('region'),
                "region": "Global;North America-United States;North America-Canada;EMEA;EMEA - MEWA, Israel & CIS;AP-Japan;AP-Non-Japan;LATAM",
                "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                //"contentpreference": localStorage.getItem('contentpreference').replace(/"/g, ''),
                "contentpreference": "Yes",
                "from": 0,
                "size": 25,
                "f1": "docID",
                "fval1": docID,
                "f2": "domain",
                "fval2": _domain,
                "fcount": 2,
                "source": "function;majorVersionNumber;regions",
                accessGroup: userAccessGroup.includes("Answers") ? "MISE" : "",
                matchPhrase: true,
            }
        }
        axios(config)
            .then((response) => {
                if (_userFunction === 'Medical') {
                    if (response.data.body.hits.total.value > 0 && response.data.body.hits.hits[0]._source) {
                        let sourceItem = response.data.body.hits.hits[0]._source;
                        let userRegions = [];
                        let documentRegions = sourceItem.regions.map(x => x.toLowerCase());
                        if (localStorage.getItem('region')) {
                            userRegions = localStorage.getItem('region').split(';').map(x => x.toLowerCase());
                            if (documentRegions.some(x => x === "us") && userRegions.some(x => x === "north america-united states")) {
                                userRegions.push("us")
                            }
                            if (documentRegions.some(x => x === "ca") && userRegions.some(x => x === "north america-canada")) {
                                userRegions.push("ca")
                            }
                            if (documentRegions.some(x => x === "nonemea") && userRegions.some(x => x === "emea - mewa, israel & cis")) {
                                userRegions.push("nonemea")
                            }
                            if (documentRegions.some(x => x === "apj") && userRegions.some(x => x === "ap-japan")) {
                                userRegions.push("apj")
                            }
                            if (documentRegions.some(x => x === "apnj") && userRegions.some(x => x === "ap-non-japan")) {
                                userRegions.push("apnj")
                            }
                        }
                        if (userRegions.some(item => documentRegions.includes(item))) {
                            let sourceItem = response.data.body.hits.hits[0]._source
                            // if ((userAccessGroup.includes("MISEHCP") && sourceItem.function.includes("MISE"))
                            //     || !sourceItem.function.includes("MISE"))
                            if ((userAccessGroup.includes("AllContent") && sourceItem.function.includes("Non-Commercial"))
                                || (userAccessGroup.includes("MISEHCP") && sourceItem.function.includes("MISE"))
                                || (!userAccessGroup.includes("Answers") && sourceItem.function.includes("Non-Commercial"))) {
                                downloadDocument(sourceItem.majorVersionNumber);
                            } else {
                                setIsValidUser(false);
                                setProcessing(false);
                            }
                        } else {
                            setIsDocumentAvailable(false);
                            setProcessing(false);
                        }
                    } else {
                        setIsDocumentAvailable(false);
                        setProcessing(false);
                    }
                } else {
                    if (response.data.body.hits.total.value > 0 && response.data.body.hits.hits[0]._source) {
                        let sourceItem = response.data.body.hits.hits[0]._source
                        if (sourceItem.function.includes("Commercial") || (sourceItem.function.includes("MISE") && userAccessGroup.includes("MISEHCP"))) {
                            downloadDocument(sourceItem.majorVersionNumber);
                        } else {
                            setIsValidUser(false);
                            setProcessing(false);
                        }
                    } else {
                        setIsDocumentAvailable(false);
                        setProcessing(false);
                    }
                }

            })
            .catch(function (error) {
                setIsValidUser(false);
                setProcessing(false);
            })
    }
    return (
        <>
            <ErrorBoundary>
                <div className="document-tab-outer-wrapper">
                    <div className="document-tab-wrapper">
                        <div className="document-header-wrapper">
                            <a>
                                <img src={IMSLogo} alt="" title="iMedical Search" />
                            </a>
                        </div>
                        {isProcessing ? <div style={{ textAlign: "center", padding: "10%" }}>
                            <img src={imsLoader} alt="Loading Filters" style={{ width: "10%" }} />
                        </div> : <>
                            {
                                isDocumentAvailable ? <>
                                    {isValidUser ? <div className="document-tab-inner-wrapper">
                                        <div className="document-tab-content-upper-card">
                                            <span style={{ color: "#1076bc", fontSize: "20px" }}><b>Thank you.</b> Your document will soon be available here.</span><br />
                                        </div>
                                        <div>
                                            <span style={{ fontSize: "12px" }}>
                                                Click <Link to="/" style={{ cursor: "pointer", color: "#1076bc" }} target="_blank">here</Link> to go to iMedical Search - Home page.
                                            </span><br />
                                            <span style={{ fontSize: "12px" }}>You may close this tab after your document is downloaded.</span>
                                        </div>
                                        <div className="document-tab-disclaimer-text">
                                            <b style={{ color: "red" }}>Disclaimer:</b> The purpose of iMS is to provide Scientific Affairs, Medical Affairs, and R&D personnel worldwide the ability to easily access and share key scientific content <b><i>internally.</i></b> External discussion, presentation, or dissemination of this content as well as use for validated processes must be compliant with local laws, regulations, and company policies and procedures.<br /> <b>Please Note:</b> iMS connects directly to the primary sources of content (e.g. RIMdocs, Veeva Vault) and crawls these content sources once nightly to ensure that you’re accessing only <b>current Approved Effective</b> versions of the documents.
                                        </div>
                                    </div> :
                                        <div className="document-tab-inner-wrapper">
                                            <div className="document-tab-content-upper-card">
                                                <span style={{ color: "#1076bc", fontSize: "20px" }}>You are not authorized to download this document.</span><br />
                                            </div>
                                            <div className="document-tab-disclaimer-text">
                                                <b style={{ color: "red" }}>Disclaimer:</b> The purpose of iMS is to provide Scientific Affairs, Medical Affairs, and R&D personnel worldwide the ability to easily access and share key scientific content <b><i>internally.</i></b> External discussion, presentation, or dissemination of this content as well as use for validated processes must be compliant with local laws, regulations, and company policies and procedures.<br /> <b>Please Note:</b> iMS connects directly to the primary sources of content (e.g. RIMdocs, Veeva Vault) and crawls these content sources once nightly to ensure that you’re accessing only <b>current Approved Effective</b> versions of the documents.
                                            </div>
                                        </div>}</> :
                                    <div className="document-tab-inner-wrapper">
                                        <div className="document-tab-content-upper-card">
                                            <span style={{ color: "#1076bc", fontSize: "20px" }}>The document you are trying to access is no longer available in iMedical Search.</span><br />
                                        </div>
                                        <div className="document-tab-disclaimer-text">
                                            <b style={{ color: "red" }}>Disclaimer:</b> The purpose of iMS is to provide Scientific Affairs, Medical Affairs, and R&D personnel worldwide the ability to easily access and share key scientific content <b><i>internally.</i></b> External discussion, presentation, or dissemination of this content as well as use for validated processes must be compliant with local laws, regulations, and company policies and procedures.<br /> <b>Please Note:</b> iMS connects directly to the primary sources of content (e.g. RIMdocs, Veeva Vault) and crawls these content sources once nightly to ensure that you’re accessing only <b>current Approved Effective</b> versions of the documents.
                                        </div>
                                    </div>

                            }

                        </>}
                    </div>
                    <div style={{ margin: "0% 1.5%" }}>
                        <Footer
                            customWidth={width}
                            customHeight={height} />
                    </div>
                </div>
            </ErrorBoundary>
        </>
    )
}