import { useState } from "react";
export default function MobileViewButton(props) {
    const {
        customColor,
        text,
        action
    } = props;
    // const [color, setColor] = useState(customColor);
    // const [bgColor, setBgColor] = useState("#fff")
    const [bgColor] = useState("#fff")
    const styles = {
        color: customColor,//color,
        border: `1px solid ${customColor}`,
        background: bgColor,
        width: "100%",
        borderRadius: "5px",
        height: "35px"
    }
    return (
        <button style={styles} onClick={(e) => action()} /* onMouseEnter={() => { setColor('#fff'); setBgColor(customColor) }} onMouseLeave={() => { setColor(customColor); setBgColor('#fff') }} */>{text}</button>
    )
}