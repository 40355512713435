import { Modal } from "react-bootstrap";
import Button from "../Button";
// import MobileViewButton from "../Button/MobileViewButton";
import { Component } from "react";
// import './ProductAlertsPopUp.css'

class PoweredByDDIGModal extends Component {
    render() {
        // if (this.props.customWidth < 1000) {
        //     return (<Modal
        //         show={this.props.showProductAlerts}
        //         size="lg md sm xs"
        //         centered
        //         aria-labelledby="contained-modal-title-vcenter"
        //         dialogClassName="popup-transition-mob-tab"
        //         scrollable={true}
        //     >
        //         <div>
        //                 <div style={{ borderLeft: `6px solid #0074B0`, height: '100%' }}>
        //                     <div className="paModalWrapper" /* style={{ padding: "0 5%" }} */>
        //                         <Modal.Header className="productsAlertsHeader" /* style={{ justifyContent: "center", borderBottomColor: "#0074B0" }} */>
        //                             <span className="prodAlertHeadingText">Subscribe to Product Alerts</span>
        //                         </Modal.Header>
        //                         <Modal.Body scrollable={true} style={{ minHeight: "25vh" }}>
        //                             <div>
        //                                 <table className="productAlertsTable">
        //                                     <thead className="prodAlertsTableHeading">
        //                                         <tr>
        //                                             <th>Product</th>
        //                                             <th>Alert Frequency</th>
        //                                             <th>Subscribe</th>
        //                                         </tr>
        //                                     </thead>
        //                                     <tbody>
        //                                         {
        //                                             this.state.productsName.map((name, index) => {
        //                                                 return (
        //                                                     <tr className="prodAlertsData">
        //                                                         <td>
        //                                                             {/* <span>{name}</span> */}
        //                                                             <span>{name.product_name}</span>
        //                                                         </td>
        //                                                         <td>
        //                                                             <select name={`prod${name.product_id}`} id={`prod${index + 1}`} onChange={(e) => this.handleAlertFrequency(e, index)} value={this.state.frequencyValue[index]}>
        //                                                                 <option value="Daily">Daily</option>
        //                                                                 <option value="Weekly">Weekly</option>
        //                                                                 <option value="Monthly">Monthly</option>
        //                                                             </select>
        //                                                         </td>
        //                                                         <td>
        //                                                             <span>
        //                                                                 <input type="checkbox" class="k-checkbox" style={{ margin: 0 }} onChange={(e) => this.handleAlerts(e, index)} id={`prodsubscrib${name.product_id}`} checked={this.state.isSubscribe[index]} />
        //                                                             </span>
        //                                                         </td>
        //                                                     </tr>
        //                                                 )
        //                                             })
        //                                         }
        //                                     </tbody>
        //                                 </table>
        //                             </div>
        //                         </Modal.Body>
        //                         <div className="prodAlertsButtonContainer" /* style={{ textAlign: "center", padding: "3% 0" }} */>
        //                             <Button text="Close" customColor="#0074B0" action={() => { this.setState({ showPop: false }); this.props.handleClose(false) }} />
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //     </Modal>
        //     )
        // }
        // else {
            return (
                <Modal
                    show={this.props.showModal}
                    size="lg md sm xs"
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogClassName="popup-transition-prodAlerts"
                    // scrollable={true}
                >
                    <div>
                        <div style={{ borderLeft: `6px solid #0074B0`, height: '100%' }}>
                            <div className="paModalWrapper" /* style={{ padding: "0 5%" }} */>
                                <Modal.Body scrollable={true} style={{ minHeight: "25vh" }}>
                                    <div>
                                    The Clinical Studies Data Panel is powered by the Master Data Management (MDM) Hub, which is owned by the Drug Development Information Governance (DDIG) Team in GCDO. The data represents master data in the Janssen organization aimed to support Drug Development. The information provided is intended for Janssen internal usage only and is in compliance with J&J Information Asset Protection Policies (IAAP). If you have any questions or concerns about the data, please submit a request via the ‘REPORT DATA ISSUES’ button on the <a href="https://jnj.sharepoint.com/sites/PHM-GCSP-RND/ddig/Pages/Home.aspx">DDIG portal home page</a>.
                                    </div>
                                </Modal.Body>
                                <div className="prodAlertsButtonContainer" style={{ textAlign: "center"/* , padding: "3% 0"  */}}>
                                    <Button text="Okay" customColor="#0074B0" action={() => { this.props.handleClose(false) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )
        // }
    }

}
export default PoweredByDDIGModal;