import { Component } from "react";
import { Modal } from "react-bootstrap";
import { popCancelBttn } from "../../assets";
import Button from "../Button";
import './GlobalSearchFeaturesPopUp.css'
import './PinToReadingListPopUp.css'
import ApiServices from "../../utils/apiServices";
import { snippetLongString } from "../../utils/utilityServices";
import AlertMessagePopUpModal from "./AlertMessagePopUpModal";
class RenameReadingListPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rlName: '',
            rlID: '',
            showSuccess: false,
            showPop: false,
            error: '',
            alertMessageModelColor: "",
            alertMessageContent: "",
            alertMessageShowModal: false,
            alertMessageIsError: false
        }
    }
    componentDidMount() {
        this.setState({
            showPop: this.props.showModal,
            rlName: this.props.rlName,
            rlID: this.props.rlID
        });
    }
    successAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#00c12c",
            alertMessageShowModal: true,
        })
    }
    errorAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#de1327",
            alertMessageShowModal: true,
            alertMessageIsError: true
        })
    }
    closeAlertMessage = () => {
        const { alertMessageIsError, showPop } = this.state;
        this.setState({
            alertMessageContent: "",
            alertMessageModelColor: "",
            showPop: alertMessageIsError ? showPop : false,
            alertMessageShowModal: false,
            alertMessageIsError: false,
        })
        if (!alertMessageIsError) {
            this.props.handleClose(false)
        }
    }
    handleOnClickView = async (rlID, rName) => {

        let ids = "";
        let service = new ApiServices();
        let res = await service.getDocIds(rlID);
        if (res.length > 0) {
            ids = res.map(item=>item.doc_id).filter(x=>x).join(";");
            // res.map((item) => {
            //     ids += `${item.doc_id};`;
            //     return null;
            // });
        }
        localStorage.setItem("readListDocIds", ids);
        localStorage.setItem("rLID", rlID);
        localStorage.setItem("rName", rName);
        let url = `/viewReadingList?query=&q=All%20Fields&filters=&f1=searchID&fval1=${ids}&fcount=1&rName=${encodeURIComponent(
            rName
        )}&rID=${rlID}`;
        if (ids !== "") {
            window.open(url, "_blank");
        } else {
            this.errorAlertMessage("You do not have any items pinned to this reading list.");
        }


        // let ids = '';
        // let service = new ApiServices
        // let res = await service.getDocIds(rlID);
        // if (res.length > 0) {
        //     res.map((item) => { ids += `${item.doc_id};`; return null })
        // }
        // let url = `/results?query=&q=All%20Fields&filters=&f1=searchID&fval1=${ids}&fcount=1`
        // window.open(url, '_blank');
    }
    handleSuccessClose = (status) => {
        this.setState({
            showSuccess: status
        })
    }
    handleSave = async () => {
        if (this.handleValidation()) {
            let service = new ApiServices();
            let res = await service.updateReadingListName(this.state.rlID, this.state.rlName);
            if (res) {
                this.successAlertMessage('Reading List Name saved successfully');
            }
        }
    }
    handleInput = (e) => {
        e.target.value = snippetLongString(e.target.value);
        this.setState({
            rlName: e.target.value
        })
    }
    handleValidation() {
        let errors = {};
        let formIsValid = true;
        let readingList = this.props.apiResponse.filter(x => x.readinglist_id !== this.state.rlID).map(data => data.readinglist_name.toLowerCase())
        if (this.state.rlName.length <= 0) {
            formIsValid = false;
            errors["pinReadingList"] = `Please enter a valid name to proceed.`;
        }
        if (readingList.includes(this.state.rlName.toLowerCase())) {
            formIsValid = false;
            errors["pinReadingList"] = `You already have a reading list with entered title. Please enter a new name.`;
        }
        this.setState({ error: errors });
        return formIsValid;
    }
    render() {
        const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
        if (this.props.width <= 1000) {
            return (
                <>
                    <Modal
                        show={this.state.showPop}
                        size="lg md sm xs"
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        dialogClassName="popup-transition"
                        scrollable={true}
                    >
                        <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
                            <div>
                                <Modal.Header className="gsfModalHeader" style={{ justifyContent: "left", padding: "0.5rem 0" }}>
                                    <div style={{ textAlign: "center", width: "100%", paddingTop: "1%" }}>
                                        <Modal.Title>
                                            <div className="actionItem-headerPart">
                                                Rename Reading List
                                            </div>
                                        </Modal.Title>
                                    </div>
                                </Modal.Header>
                                <Modal.Body scrollable={true} style={{ minHeight: "25vh" }}>
                                    <table className="reading-list-view-wrapper">
                                        <tr><th>Reading List Name</th><th style={{ textAlign: "center" }}>Actions</th></tr>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "60%" }}><input type="text" value={this.state.rlName} onChange={this.handleInput} /><br /><div className="errorInvalidName">{this.state.error["pinReadingList"]}</div></td>
                                                <td style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", width: "100%" }}><span onClick={e => this.handleOnClickView(this.props.rlID, this.props.rlName)} title="View" style={{ marginRight: "5px" }} className="reading-list-action-text">View</span><span className="action-items-separate-divider"></span><span title="Save" onClick={e => this.handleSave()} className="reading-list-action-text">Save</span></td>
                                            </tr>
                                            <span className="length-info">Name with a maximum of 100 characters.</span>
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <div className="reading-list-button-tablet-wrapper">
                                    <Button text="Cancel" customColor="#0074B0" action={() => {
                                        this.setState({ showPop: false });
                                        this.props.handleClose(false);
                                    }} />
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
                </>
            )
        }
        else {
            return (
                <>
                    <Modal
                        show={this.state.showPop}
                        size="lg md sm xs"
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        dialogClassName="popup-transition"
                        scrollable={true}
                    >
                        <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
                            <div className="popup-cancel-btn-wrapper">
                                <img
                                    src={popCancelBttn}
                                    alt=""
                                    onClick={e => {
                                        this.setState({ showPop: false });
                                        this.props.handleClose(false);
                                    }}
                                />
                            </div>
                            <div>
                                <Modal.Header className="gsfModalHeader" style={{ justifyContent: "left" }}>
                                    <div style={{ textAlign: "center", width: "30%", paddingTop: "1%" }}>
                                        <Modal.Title>
                                            <div className="actionItem-headerPart">
                                                Rename Reading List
                                            </div>
                                        </Modal.Title>
                                    </div>
                                </Modal.Header>
                                <Modal.Body scrollable={true} style={{ minHeight: "25vh" }}>
                                    <table className="reading-list-view-wrapper">
                                        <tr><th>Reading List Name</th><th style={{ textAlign: "center" }}>Actions</th></tr>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "60%" }}><input type="text" value={this.state.rlName} onChange={this.handleInput} /><br /><div className="errorInvalidName">{this.state.error["pinReadingList"]}</div></td>
                                                <td style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", width: "100%" }}><span onClick={e => this.handleOnClickView(this.props.rlID, this.props.rlName)} title="View" style={{ marginRight: "5px" }} className="reading-list-action-text">View</span><span className="action-items-separate-divider"></span><span title="Save" onClick={e => this.handleSave()} className="reading-list-action-text">Save</span></td>
                                            </tr>
                                            <span className="length-info">Name with a maximum of 100 characters.</span>
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <div className="reading-list-button-wrapper">
                                    <Button text="Cancel" customColor="#0074B0" action={() => {
                                        this.setState({ showPop: false });
                                        this.props.handleClose(false);
                                    }} />
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
                </>
            )
        }
    }
}
export default RenameReadingListPopup;