/* eslint-disable import/no-anonymous-default-export */
import { Modal } from "react-bootstrap";
import { useState } from "react";
import { imsLogo, popCancelBttn, emailBlueIcon, emailWhiteIcon, downloadBlueIcon, downloadWhiteIcon, copyIconBlue, copyIconWhite } from "../../assets"
import trackingService from "../../services/trackingService";
import OpenWindow from "../OpenWindow";
import Button from "../Button";
import MobileViewButton from "../Button/MobileViewButton";
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal";

const URLConstructor = (item, shareOption) => {
    let url
    let docTitle = encodeURIComponent(item.docTitle)
    if (window.location.pathname !== '/pubMedResults') {
        let { orderedBy, orderedFor } = item;
        if (!orderedBy) orderedBy = '';
        if (!orderedFor) orderedFor = '';
        if (item.domain === 'RIMdocs' || item.domain === 'RIMdocs_LL' || item.domain === 'CTGov') {
            url = `/DocURL?ID=${item.docID}&ref=${shareOption}&cs=${item.domain}&orderedBy=${orderedBy}&orderedFor=${orderedFor}`
            // url = `/DocURL?ID=${docTitle}&ref=${shareOption}&cs=${item.domain}`
        }
        else if (item.domain === 'SKM' || item.domain === "MIAs" || item.domain === "MIAsReferences") {
            url = `/DocURL?ID=${item.docID}&ref=${shareOption}&cs=${item.domain}&connID=${item.connID}&csID=${item.csID}&fileType=${item.fileExtension}&fileName=${docTitle}&orderedBy=${orderedBy}&orderedFor=${orderedFor}`
        }
        else {
            url = `/DocURL?ID=${item.docID}&ref=${shareOption}&cs=${item.domain}&connID=${item.connID}&csID=${item.csID}&fileType=${item.fileExtension}&docVer=${item.majorVersionNumber}&fileName=${docTitle}&orderedBy=${orderedBy}&orderedFor=${orderedFor}`
            if (item.a_status === "Approved Needs Review" || item.status === "Approved Needs Review") {
                url = url + `&docState=anr`
            }
        }
        if (item.enclosureDocID) {
            url += `&enclosureID=${item.enclosureDocID}`
          }
    }
    else {
        url = `/DocURLPubMed?sid=PMS&pmid=${item.PMID}&ref=${shareOption}`
    }
    return url
}
const indexdata = (item, url, appliedFilters, searchKeyword, pageNumber, pageSize, shareOption, csPath, csProduct) => {
    let ts = new trackingService();
    let newUrl = window.origin + url
    if (window.location.pathname !== '/pubMedResults') {
        let { orderedBy, orderedFor } = item;
        if (!orderedBy) orderedBy = '';
        if (!orderedFor) orderedFor = '';

        if (item.domain === 'RIMdocs' || item.domain === 'RIMdocs_LL') {
            ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), `${shareOption} Document`, '', window.location.href, searchKeyword, appliedFilters, item.docTitle, item.r_version_label, item.jj_number, item.searchID, newUrl, item.product, item.docType, `${shareOption} Document`, csPath, csProduct, item.domain, pageNumber, pageSize, item.createdDate, orderedBy, orderedFor);
        }
        else if (item.domain === 'SKM') {
            ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), `${shareOption} Document`, '', window.location.href, searchKeyword, appliedFilters, item.docTitle, '', item.domain, item.searchID, newUrl, item.product, item.docType, `${shareOption} Document`, csPath, csProduct, item.domain, pageNumber, pageSize, item.createdDate, orderedBy, orderedFor);
        }
        else if (item.domain === 'CTGov') {
            ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), `${shareOption} Document`, '', window.location.href, searchKeyword, appliedFilters, item.docTitle, '', item.docID, item.searchID, newUrl, item.product, item.docType, `${shareOption} Document`, csPath, csProduct, item.domain, pageNumber, pageSize, item.createdDate, orderedBy, orderedFor);
        }
        else {
            ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), `${shareOption} Document`, '', window.location.href, searchKeyword, appliedFilters, item.docTitle, item.majorVersionNumber, item.documentNumber, item.searchID, newUrl, item.product, item.docType, `${shareOption} Document`, csPath, csProduct, item.domain, pageNumber, pageSize, item.createdDate, orderedBy, orderedFor);
        }
    }
    else {
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), `${shareOption} Document`, '', window.location.href, searchKeyword, appliedFilters, item.title, item.majorVersionNumber, item.documentNumber, item.searchID, newUrl, item.product, item.docType, `${shareOption} Document`, csPath, csProduct, 'PubMed', pageNumber, pageSize, item.createdDate);
    }
}
const handleDownload = (item, appliedFilters, searchKeyword, pageNumber, pageSize, csPath, csproduct) => {
    let url
    url = URLConstructor(item, 'download')
    window.location.pathname !== '/pubMedResults' ? indexdata(item, url, appliedFilters, searchKeyword, pageNumber, pageSize, 'Download', csPath !== null ? csPath : '', csproduct !== null ? csproduct : '') : indexdata(item, url, appliedFilters, searchKeyword, pageNumber, pageSize, 'Download PubMed', csPath !== null ? csPath : '', csproduct !== null ? csproduct : '')
    OpenWindow(url)
}

const handleEmail = (item, appliedFilters, searchKeyword, pageNumber, pageSize) => {
    let url
    url = URLConstructor(item, 'email')
    window.location.pathname !== '/pubMedResults' ? indexdata(item, url, appliedFilters, searchKeyword, pageNumber, pageSize, 'Email', '', '') : indexdata(item, url, appliedFilters, searchKeyword, pageNumber, pageSize, 'Email PubMed', '', '')
    // let downloadurl = url.replaceAll('&', '%26')
    let downloadurl = url.replace(/ /g, '%20')
    let mailUrl = window.location.origin + downloadurl
    let encodeMailUrl = encodeURIComponent(mailUrl.trim());
    let title;
    // let title = item.docTitle
    if (window.location.pathname !== '/pubMedResults') {
        title = item.docTitle
        if (item.docTitle.includes('&')) {
            title = item.docTitle.replaceAll('&', '%26')
        }
    }
    else {
        title = item.title
        if (item.title.includes('&')) {
            title = item.title.replaceAll('&', '%26')
        }
    }
    // if (item.docTitle.includes('&')) {
    //     title = item.docTitle.replaceAll('&', '%26')
    // }
    let mailbody = `Title: ${title}%0D%0DUrl: ${encodeMailUrl}%0D%0DFor access to iMedical Search, please log onto ${window.location.origin}.`
    window.open(`mailto:?Subject=iMedical Search Document: ${title}&body=${mailbody}`)
}
export default function (props) {
    const {
        isOpen,
        item,
        handleClose,
        appliedFilters,
        searchKeyword,
        pageNumber,
        pageSize,
        csPath,
        csproduct,
        customWidth
    } = props
    const [isModalOpen, setModalState] = useState(isOpen)
    const [alertMessageModelColor, setAlertMessageModelColor] = useState("")
    const [alertMessageContent, setAlertMessageContent] = useState("")
    const [alertMessageShowModal, setAlertMessageShowModal] = useState(false)
    const successAlertMessage = (message) => {
        setAlertMessageContent(message)
        setAlertMessageModelColor("#00c12c")
        setAlertMessageShowModal(true)
    }
    const closeAlertMessage = () => {
        setAlertMessageContent("")
        setAlertMessageModelColor("")
        setAlertMessageShowModal(false)
    }
    const handleCopyUrl = (item, appliedFilters, searchKeyword, pageNumber, pageSize) => {

        let url
        url = URLConstructor(item, 'share')
        window.location.pathname !== '/pubMedResults' ? indexdata(item, url, appliedFilters, searchKeyword, pageNumber, pageSize, 'Copy to clipboard', '', '') : indexdata(item, url, appliedFilters, searchKeyword, pageNumber, pageSize, 'Copy to clipboard PubMed', '', '')
        navigator.clipboard.writeText(window.location.origin + url).then(() => {
            successAlertMessage('URL Copied to clipboard.')
        })
    }
    if (customWidth < 600) {
        return (
            <Modal
                show={isModalOpen}
                size="lg md sm xs"
                centered
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="popup-transition-share-mob"
            >
                <div style={{ borderLeft: "7px solid #1DA1E5" }}>
                    <div>
                        <Modal.Header style={{ justifyContent: "center", borderBottomColor: "rgba(29, 161, 229, 0.45)" }}>
                            <div style={{ textAlign: "center" }}>
                                <Modal.Title>
                                    <div>
                                        <img src={imsLogo} alt="" width={150} height={25} />
                                    </div>
                                    <span className="citation-share-pop-header" style={{ color: "#0074B0", display: "block" }} title="Title of the document">{item.docTitle}</span>
                                </Modal.Title>
                            </div>
                        </Modal.Header>
                        <Modal.Body style={{ padding: "1rem 0" }}>
                            <div>
                                <div className="view-selected-top-bottom-container-mobile" style={{ width: "100%", alignItems: "center", padding: "2%" }}>
                                    <div className="features-buttons-mobile" title="Click here to Copy the URL of the document" onClick={(evt) => handleCopyUrl(item, appliedFilters, searchKeyword, pageNumber, pageSize)} style={{ width: "100%", textAlign: "center", margin: "2%" }}>
                                        <img src={copyIconBlue} alt="print-icon" width={18} height={21} className="vs-img" />
                                        <img src={copyIconWhite} alt="save-icon" width={18} height={21} className="vs-img-hov" />
                                        <span>Copy</span>
                                    </div>
                                    <div className="features-buttons-mobile" title="Click here to Share the document" onClick={(evt) => handleEmail(item, appliedFilters, searchKeyword, pageNumber, pageSize)} style={{ width: "100%", textAlign: "center", margin: "2%" }}>
                                        <img src={emailBlueIcon} alt="email-icon" width={20} height={20} className="vs-img" />
                                        <img src={emailWhiteIcon} alt="save-icon" width={20} height={20} className="vs-img-hov" />
                                        <span>Email</span>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <div style={{ textAlign: "center", padding: "3%" }}>
                            <MobileViewButton text="Close" customColor="#0074B0" action={(evt) => { setModalState(false); handleClose(false) }} />
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
    else if (customWidth < 1000) {
        return (
            <Modal
                show={isModalOpen}
                size="lg md sm xs"
                centered
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="popup-transition-share-tab"
            >
                <div style={{ borderLeft: "7px solid #1DA1E5" }}>
                    <div>
                        <Modal.Header style={{ justifyContent: "center", borderBottomColor: "rgba(29, 161, 229, 0.45)" }}>
                            <div style={{ textAlign: "center" }}>
                                <Modal.Title>
                                    <div>
                                        <img src={imsLogo} alt="" width={150} height={25} />
                                    </div>
                                    <span className="citation-share-pop-header" style={{ color: "#0074B0", display: "block" }} title="Title of the document">{item.docTitle}</span>
                                </Modal.Title>
                            </div>
                        </Modal.Header>
                        <Modal.Body className="share-popup-modalBody-mob-tab">
                            <div>
                                <div className="buttons-container" style={{ width: "100%", alignItems: "center" }}>
                                    <div className="features-buttons" title="Click here to Copy the URL of the document" onClick={(evt) => handleCopyUrl(item, appliedFilters, searchKeyword, pageNumber, pageSize)}>
                                        <img src={copyIconBlue} alt="print-icon" width={18} height={21} className="vs-img" />
                                        <img src={copyIconWhite} alt="save-icon" width={18} height={21} className="vs-img-hov" />
                                        <span>Copy</span>
                                    </div>
                                    <div className="links-sepration-blue" style={{ height: "23px" }}></div>
                                    <div className="features-buttons" title="Click here to Share the document" onClick={(evt) => handleEmail(item, appliedFilters, searchKeyword, pageNumber, pageSize)}>
                                        <img src={emailBlueIcon} alt="email-icon" width={20} height={20} className="vs-img" />
                                        <img src={emailWhiteIcon} alt="save-icon" width={20} height={20} className="vs-img-hov" />
                                        <span>Email</span>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <div style={{ textAlign: "center", padding: "3% 0" }}>
                            <Button text="Close" customColor="#0074B0" action={(evt) => { setModalState(false); handleClose(false) }} />
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
    else {
        return (
            <>
                <Modal
                    show={isModalOpen}
                    size="lg md sm xs"
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogClassName="popup-transition"
                // scrollable={true}
                >
                    <div style={{ borderLeft: "7px solid #1DA1E5" }}>
                        <div className="popup-cancel-btn-wrapper">
                            <img src={popCancelBttn} alt="" onClick={(evt) => { setModalState(false); handleClose(false) }} />
                        </div>
                        <div>
                            <Modal.Header style={{ justifyContent: "center", borderBottomColor: "rgba(29, 161, 229, 0.45)" }}>
                                <div style={{ textAlign: "center", padding: "0 5%" }}>
                                    <Modal.Title>
                                        <div>
                                            <img src={imsLogo} alt="" width={150} height={25} />
                                        </div>
                                        <span className="citation-share-pop-header" style={{ color: "#0074B0", display: "block" }} title="Title of the document">{item.docTitle}</span>
                                    </Modal.Title>
                                </div>
                            </Modal.Header>
                            <Modal.Body /* style={{ minHeight: "15vh" }} */ /* scrollable={true} style={{height:height}} */>
                                <div style={{ padding: "1%" }}>
                                    <div className="buttons-container" style={{ width: "100%", alignItems: "center" }}>
                                        <div className="features-buttons" title="Click here to Copy the URL of the document" onClick={(evt) => handleCopyUrl(item, appliedFilters, searchKeyword, pageNumber, pageSize)}>
                                            <img src={copyIconBlue} alt="print-icon" width={18} height={21} className="vs-img" />
                                            <img src={copyIconWhite} alt="save-icon" width={18} height={21} className="vs-img-hov" />
                                            <span>Copy</span>
                                        </div>
                                        <div className="links-sepration-blue" style={{ height: "23px" }}></div>
                                        <div className="features-buttons" title="Click here to Download the document" onClick={(evt) => handleDownload(item, appliedFilters, searchKeyword, pageNumber, pageSize, csPath, csproduct)}>
                                            <img src={downloadBlueIcon} alt="download-icon" width={18} height={18} className="vs-img" />
                                            <img src={downloadWhiteIcon} alt="save-icon" width={18} height={18} className="vs-img-hov" />
                                            <span>Download</span>
                                        </div>
                                        <div className="links-sepration-blue" style={{ height: "23px" }}></div>
                                        <div className="features-buttons" title="Click here to Share the document" onClick={(evt) => handleEmail(item, appliedFilters, searchKeyword, pageNumber, pageSize)}>
                                            <img src={emailBlueIcon} alt="email-icon" width={20} height={20} className="vs-img" />
                                            <img src={emailWhiteIcon} alt="save-icon" width={20} height={20} className="vs-img-hov" />
                                            <span>Email</span>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <div style={{ textAlign: "center", padding: "3% 0" }}>
                                <Button text="Close" customColor="#0074B0" action={(evt) => { setModalState(false); handleClose(false) }} />
                            </div>
                        </div>
                    </div>
                </Modal>
                {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => closeAlertMessage()} />}
            </>
        )
    }
}