/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
// import { Link } from "react-router-dom";
import './ResponsiveFilterComponent.css'
import { filterIcon, collapseFilter, ActionsArrowIcon, imsLoader } from '../../assets';
import SearchFilter from '../FilterComponent/SearchFilter';
// import OpenWindow from "../../components/OpenWindow";
import GlobalFeatures from "../../components/GlobalSearchFeatures/GlobalFeatures";
// import trackingService from "../../services/trackingService";
export default function ResponsiveFilterComponent(props) {
    const { customWidth,
        customHeight,
        pathName,
        allFilters,
        filters,
        appliedFilters,
        selectedFilters,
        selectedParentFilterArr,
        searchKeyword,
        handleAllFilters,
        handleOnChangeInfilter,
        isProcessing,
        handleIsProcessing,
        handleChangeInSearch,
        documentCount,
        response,
        globalCollapseAll,
        // handleCollapseAllGlobal,
        collapseFilterRes,
        globalSelectAll,
        csPath,
        csproduct,
        // handleGlobalBookmark,
        handleOnClickReadingList,
        appliedFiltersForOnlyiMS,
        filterYears,
        fromYear,
        toYear,
        handleYearSelection,
        handlePageChange,
        recentlyUsedFilters,
        fromRecentlyAddedUpdated,
        toRecentlyAddedUpdated,
        gotoPageRecentlyAddedUpdated,
        handleOnClickNotebook
    } = props;
    const [showFilters, setShowFilters] = useState(false)
    // const [showQuickLinks, setShowQuickLinks] = useState(false);
    const [showActionItems, setShowActionItems] = useState(false);
    // const handelQuickLinks = () => {
    //     setShowQuickLinks(!showQuickLinks)
    // }
    // const handelQuickLinksClose = () => {
    //     setShowQuickLinks(false)
    // }
    const handelActionItems = () => {
        setShowActionItems(!showActionItems)
    }
    const handelActionItemsClose = () => {
        setShowActionItems(false)
    }
    // const handleOpenContentTitle = () => {
    //     let url = window.location.origin + "/covid";
    //     const ts = new trackingService();
    //     ts.indexTrackingData(
    //         localStorage.getItem("userdisplayName"),
    //         localStorage.getItem("wwid"),
    //         localStorage.getItem("username"),
    //         localStorage.getItem("function"),
    //         localStorage.getItem("region"),
    //         localStorage.getItem("userproducts"),
    //         localStorage.getItem("doctypepref"),
    //         localStorage.getItem("sid"),
    //         localStorage.getItem("deviceType"),
    //         localStorage.getItem("deviceOS"),
    //         localStorage.getItem("browserName"),
    //         localStorage.getItem("browserVersion"),
    //         localStorage.getItem("jnjDepartment"),
    //         localStorage.getItem('companyname'),
    //         "Expanded Covid-19 Content by Product tile",
    //         "",
    //         window.location.href,
    //         //   this.state.searchKeyword,
    //         //   this.props.appliedFilters
    //     );
    //     OpenWindow(url);
    // };
    const handelViewSelected = () => {
        props.handelSelectedCitation()
    }
    const handleCollapseAllGlobalFeature = () => {
        props.handleCollapseAllGlobal()
    }
    const handleSelectAllGlobalFeature = () => {
        props.handleSelectAll()
    }
    const handleEmailPopUp = (status) => {
        props.handleGlobalEmailPopUp(status)
    }
    const handleNotifyMePopUp = (status) => {
        props.handleGlobalNotifyMePopUp(status)
    }
    const handleBookmark = (status) => {
        props.handleGlobalBookmark(status)
    }
    const handleReadingList = (status) => {
        handleOnClickReadingList(status)
    }
    const handleNotebook = (status) => {
        handleOnClickNotebook(status)
    }
    useEffect(() => {
        if (collapseFilterRes) {
            setShowFilters(false)
        }
    })
    if (pathName === '/' || pathName === '/recommended') {
        return (
            <>
                {customWidth < 600 ?
                    <div className="recommended-home-filter-wrapper-mobile">
                        <div className="recommended-home-filter-button-mobile" onClick={e => setShowFilters(!showFilters)}>
                            <img src={filterIcon} width="15" height="15" alt="" />
                            <span>Filters</span>
                            {showFilters ?
                                <img src={collapseFilter} width="20" height="15" alt="" /> :
                                <img src={collapseFilter} width="20" height="15" alt="" style={{ transform: "rotate(180deg)" }} />}
                        </div>
                        {showFilters &&
                            <div className="filter-menu-content filter-menu-content-mobile">
                                <SearchFilter allFilters={allFilters}
                                    filters={filters}
                                    appliedFilters={appliedFilters}
                                    handleAllfilters={handleAllFilters}
                                    handleOnChangeInfilter={handleOnChangeInfilter}
                                    selectedFilters={selectedFilters}
                                    selectedParentFilterArr={selectedParentFilterArr}
                                    querytext={searchKeyword}
                                    isProcessing={isProcessing}
                                    handleIsProcessing={handleIsProcessing}
                                    recentlyUsedFilters={recentlyUsedFilters}
                                    customWidth={customWidth}
                                    fromRecentlyAddedUpdated={fromRecentlyAddedUpdated}
                                    toRecentlyAddedUpdated={toRecentlyAddedUpdated}
                                    gotoPageRecentlyAddedUpdated={gotoPageRecentlyAddedUpdated} />
                            </div>
                        }
                    </div>
                    :
                    <div className="recommended-home-filter-wrapper-tablet">
                        <div className="recommended-home-filter-menu-wrapper-tablet">
                            <div className="recommended-home-filter-button-tablet" onClick={e => setShowFilters(!showFilters)}>
                                <img src={filterIcon} width="15" height="15" alt="" />
                                <span>Filters</span>
                                {showFilters ?
                                    <img src={collapseFilter} width="20" height="15" alt="" /> :
                                    <img src={collapseFilter} width="20" height="15" alt="" style={{ transform: "rotate(180deg)" }} />
                                }
                            </div>
                            {showFilters &&
                                <div className="filter-menu-content filter-menu-content-tablet">
                                    <SearchFilter allFilters={allFilters}
                                        filters={filters}
                                        appliedFilters={appliedFilters}
                                        handleAllfilters={handleAllFilters}
                                        handleOnChangeInfilter={handleOnChangeInfilter}
                                        selectedFilters={selectedFilters}
                                        selectedParentFilterArr={selectedParentFilterArr}
                                        querytext={searchKeyword}
                                        isProcessing={isProcessing}
                                        handleIsProcessing={handleIsProcessing}
                                        recentlyUsedFilters={recentlyUsedFilters}
                                        customWidth={customWidth}
                                        fromRecentlyAddedUpdated={fromRecentlyAddedUpdated}
                                        toRecentlyAddedUpdated={toRecentlyAddedUpdated}
                                        gotoPageRecentlyAddedUpdated={gotoPageRecentlyAddedUpdated} />
                                </div>
                            }
                        </div>
                        {/* <div className="recommended-home-quick-action-menu-wrapper" onMouseLeave={handelQuickLinksClose} onClick={handelQuickLinks}>
                            <div className="recommended-home-quick-action-button-tablet" >Quick Actions
                                <img src={ActionsArrowIcon} alt="ActionsArrowIcon" className='quickActions-dropdownIcon-tablet' style={{ transform: showQuickLinks ? "matrix(-1, 0, 0, -1, 0, 0)" : null }} onClick={handelQuickLinks} /></div>
                            {showQuickLinks &&
                                <ul className='quickLinksList'>
                                    <li className='quickLinksItem'>
                                        <Link
                                            className='quickLinkText'
                                            target="_blank"
                                            to="/results?query=&q=All Fields&filters=Document Type/Scientific Response Documents;Drugs - JNJ/Antiinfective Drugs and Vaccines/Vaccines/Antiviral Vaccines COVID-19/Ad26.COV2.S&f1=tempJNJDrugs&fval1=Ad26.COV2.S&fcount=1"
                                        >
                                            Janssen COVID-19 Vaccine Scientific Responses
                                        </Link>
                                    </li>
                                    <li className='quickLinksItem'>
                                        <Link
                                            className='quickLinkText'
                                            target="_blank"
                                            to="/results?query=&q=All Fields&filters=Document Type/Scientific Response Documents;Disease States/Infectious Diseases/Coronavirus"
                                        >
                                            All COVID-19 Scientific Responses
                                        </Link>
                                    </li>
                                    <li className='quickLinksItem'>
                                        <Link
                                            className='quickLinkText'
                                            target="_blank"
                                            to="/results?query=&q=All Fields&filters=Drugs - JNJ/Antiinfective Drugs and Vaccines/Vaccines/Antiviral Vaccines COVID-19/Ad26.COV2.S;Document Type/Labeling Documents - Core, EU, US"
                                        >
                                            Janssen COVID-19 Vaccine Labels
                                        </Link>
                                    </li>
                                    <li>
                                        <a
                                            className='quickLinkText'
                                            href="javascript:void(0)"
                                            onClick={e => handleOpenContentTitle()}
                                        >
                                            COVID-19 Content by Product
                                        </a>
                                    </li>
                                </ul>
                            }
                        </div> */}
                    </div>
                }
            </>
        )
    }
    else {
        return (
            <>
                {customWidth < 600 ?
                    <div className="results-page-filter-wrapper-mobile">
                        <div className="recommended-home-filter-button-mobile" onClick={e => setShowFilters(!showFilters)}>
                            <img src={filterIcon} width="15" height="15" alt="" />
                            <span>Filters</span>
                            {showFilters ?
                                <img src={collapseFilter} width="20" height="15" alt="" /> :
                                <img src={collapseFilter} width="20" height="15" alt="" style={{ transform: "rotate(180deg)" }} />}
                        </div>
                        {showFilters &&
                            <div className="filter-menu-content filter-menu-content-mobile" style={{ width: "98%" }}>
                                {!isProcessing ?
                                    <SearchFilter allFilters={allFilters}
                                        filters={filters}
                                        appliedFilters={appliedFilters}
                                        handleOnChangeInfilter={handleOnChangeInfilter}
                                        selectedFilters={selectedFilters}
                                        selectedParentFilterArr={selectedParentFilterArr}
                                        querytext={searchKeyword}
                                        isProcessing={isProcessing}
                                        handleChangeInSearch={handleChangeInSearch}
                                        documentCount={documentCount}
                                        response={response}
                                        customWidth={customWidth}
                                        csPath={csPath}
                                        csproduct={csproduct}
                                        appliedFiltersForOnlyiMS={appliedFiltersForOnlyiMS}
                                        filterYears={filterYears}
                                        fromYear={fromYear}
                                        toYear={toYear}
                                        handleYearSelection={handleYearSelection}
                                        handlePageChange={handlePageChange}
                                        recentlyUsedFilters={recentlyUsedFilters}
                                        fromRecentlyAddedUpdated={fromRecentlyAddedUpdated}
                                        toRecentlyAddedUpdated={toRecentlyAddedUpdated}
                                        gotoPageRecentlyAddedUpdated={gotoPageRecentlyAddedUpdated}
                                    />
                                    :
                                    <div style={{ textAlign: "center", padding: "10%" }}>
                                        <img src={imsLoader} alt="Loading Filters" style={{ width: "10%" }} />
                                    </div>
                                }
                            </div>
                        }
                        <div className="results-page-action-button-mobile" onMouseLeave={handelActionItemsClose} onClick={handelActionItems}>
                            <span style={{ paddingLeft: "3%" }}>Action Items</span>
                            <img src={ActionsArrowIcon} alt="ActionsArrowIcon" className='quickActions-dropdownIcon-mobile' style={{ transform: showActionItems ? "matrix(-1, 0, 0, -1, 0, 0)" : null }} onClick={handelActionItems} />
                            {
                                showActionItems &&
                                <GlobalFeatures customWidth={customWidth} customHeight={customHeight} handelViewSelected={handelViewSelected} handleCollapseAllGlobal={handleCollapseAllGlobalFeature} globalCollapseAll={globalCollapseAll} globalSelectAll={globalSelectAll} handleSelectAll={handleSelectAllGlobalFeature} handleEmailPopUp={handleEmailPopUp} handleNotifyMePopUp={handleNotifyMePopUp} handleBookmark={handleBookmark} handleReadingList={handleReadingList} handleNotebook={handleNotebook} />
                            }
                        </div>
                    </div>
                    :
                    <div className="recommended-home-filter-wrapper-tablet">
                        <div className="results-home-filter-menu-wrapper-tablet">
                            <div className="results-page-filter-button-tablet" onClick={e => setShowFilters(!showFilters)}>
                                <img src={filterIcon} width="15" height="15" alt="" />
                                <span>Filters</span>
                                {showFilters ?
                                    <img src={collapseFilter} width="20" height="15" alt="" /> :
                                    <img src={collapseFilter} width="20" height="15" alt="" style={{ transform: "rotate(180deg)" }} />}
                            </div>
                            {showFilters &&
                                <div className="filter-menu-content filter-menu-content-tablet">
                                    {!isProcessing ?
                                        <SearchFilter allFilters={allFilters}
                                            filters={filters}
                                            appliedFilters={appliedFilters}
                                            handleOnChangeInfilter={handleOnChangeInfilter}
                                            selectedFilters={selectedFilters}
                                            selectedParentFilterArr={selectedParentFilterArr}
                                            querytext={searchKeyword}
                                            isProcessing={isProcessing}
                                            handleChangeInSearch={handleChangeInSearch}
                                            documentCount={documentCount}
                                            response={response}
                                            customWidth={customWidth}
                                            csPath={csPath}
                                            csproduct={csproduct}
                                            appliedFiltersForOnlyiMS={appliedFiltersForOnlyiMS}
                                            filterYears={filterYears}
                                            fromYear={fromYear}
                                            toYear={toYear}
                                            handleYearSelection={handleYearSelection}
                                            handlePageChange={handlePageChange}
                                            recentlyUsedFilters={recentlyUsedFilters}
                                            fromRecentlyAddedUpdated={fromRecentlyAddedUpdated}
                                            toRecentlyAddedUpdated={toRecentlyAddedUpdated}
                                            gotoPageRecentlyAddedUpdated={gotoPageRecentlyAddedUpdated} />
                                        :
                                        <div style={{ textAlign: "center", padding: "10%" }}>
                                            <img src={imsLoader} alt="Loading Filters" style={{ width: "10%" }} />
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        <div className="results-page-action-button-tablet" onMouseLeave={handelActionItemsClose} onClick={handelActionItems}>Action Items
                            <img src={ActionsArrowIcon} alt="ActionsArrowIcon" className='quickActions-dropdownIcon-tablet' style={{ transform: showActionItems ? "matrix(-1, 0, 0, -1, 0, 0)" : null }} onClick={handelActionItems} />
                            {
                                showActionItems &&
                                <GlobalFeatures customWidth={customWidth} customHeight={customHeight} handelViewSelected={handelViewSelected} handleCollapseAllGlobal={handleCollapseAllGlobalFeature} globalCollapseAll={globalCollapseAll} handleSelectAll={handleSelectAllGlobalFeature} globalSelectAll={globalSelectAll} handleEmailPopUp={handleEmailPopUp} handleNotifyMePopUp={handleNotifyMePopUp} handleBookmark={handleBookmark} handleReadingList={handleReadingList} handleNotebook={handleNotebook} />
                            }
                        </div>
                    </div>
                }
            </>
        )
    }
}