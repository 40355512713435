import { Modal } from "react-bootstrap";
import { useState } from "react";
import Button from "../Button";
import './GlobalSearchFeaturesPopUp.css'

export default function SavePopUpModal(props) {
    const {
        color,
        showModal,
        handleClose,
        onViewClick,
        onClickDownload
    } = props
    const [showPop, setShow] = useState(showModal)
    return (
        
            <Modal
                show={showPop}
                size="lg md sm xs"
                centered
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="savePopUp"
                scrollable={true}
            >
                <div>
                    <div style={{ padding: "0 5%" }}>
                        <Modal.Header >
                            <div style={{ textAlign: "left" }}>
                                <Modal.Title>
                                    <span className="saveModal-heading" style={{ color: color }}>Select your preference to view the results</span>
                                </Modal.Title>
                            </div>
                        </Modal.Header>
                        {/* <Modal.Body scrollable={true} style={{height:height}}>
                    <div>
                        {convertToHtml(content)}
                    </div>
                </Modal.Body> */}
                        <div className="saveModal-buttonsContainer">
                            <Button text="View" customColor="#2EB000" action={()=>{setShow(false);handleClose(false);onViewClick()}} />
                            <Button text="Download" customColor="#2EB000" action={() => { setShow(false); handleClose(false);onClickDownload() }} />
                            <Button text="Cancel" customColor="#0074B0" action={() => { setShow(false); handleClose(false) }} />
                        </div>
                    </div>
                </div>
            </Modal>
    )
}