import React,{ Component } from "react";
import Footer from "../../components/footer/Footer"; 
import Header from "../../components/header/Header"
import Button from "../../components/Button";
import { IMSLogo } from "../../assets";
class Disclaimer extends Component{
    constructor(props){
        super(props);
        this.state ={defaultChecked : false,pageLoad:true,displayError:false,width: window.innerWidth,
            height: window.innerHeight};
    }
    componentDidMount(){
        document.title ='iMedical Search - User Agreement';
        window.addEventListener('resize', this.updateDimensions);
      }
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }      
      updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight,defaultChecked : false,displayError:false })
      }  
    handleChange =(event) =>{
            this.setState({defaultChecked:!this.state.defaultChecked,pageLoad:false,displayError:false});
    }
    handleSubmit =()=>{
        if(this.state.defaultChecked){
            let loc_check= JSON.parse(localStorage.getItem('legalEntityCategory'))
            //console.log(loc_check);
            if(loc_check === "007543" || loc_check === "007557" || loc_check === "008421" || loc_check === "008422" || loc_check === "008431" || loc_check === "008409"){
                this.props.history.push({pathname:"/register-role",state:{ fromDisclaimer : true}});
            }
            else{
                this.props.history.push({pathname:"/registration",state:{ fromDisclaimer : true}});
            }
        }
        else{
            this.setState({displayError:true});
            this.props.history.push({pathname:"/"});
        }
    }
    render(){
        if (this.state.width <= 600) {
            return(
                <div className="disclaimerOuterWrapper">
                            <div className="navigation-menu-wrapper-mobile">
                                <span className="navigation-menu-inner-wrapper-mobile">
                                <span style={{paddingTop:"1%",width:"95%"}}>
                                    <img src={IMSLogo} height="45" alt="" />
                                </span>
                                </span>
                            </div>
                            <div className="disclaimerWrapper">
                                <div className="disclaimerTitleTablet">
                                    iMedical Search User Agreement
                                </div>
                                <div className="disclaimerBody disclaimerBodyTablet">
                                    <p>Welcome to iMedical Search, an integrated global platform connecting you to high-value, valid and up-to-date scientific information from across the Janssen Pharmaceutical Companies of Johnson & Johnson. iMedical Search is brought to you by the Knowledge Management Team in Janssen Scientific Affairs and the Scientific Knowledge Management Team in EMEA.</p>
                                    <p>To begin your search experience, please agree to the following terms and conditions.</p>
                                    By accessing and using iMedical Search, you agree to:<br/><br/>
                                    <ul>
                                        <li>Use all content responsibly and appropriately. Documents intended for internal use (or labelled as confidential) are NOT to be shared externally under ANY circumstance. External discussion or dissemination of documents intended for this purpose should be by authorised personnel only. Literature content (and specifically publications) are authorised for internal J&J use only; additional copyright approval is required prior to use outside of J&J.</li><br/>
                                        <li>Ensure that all local review and approval processes are undertaken prior to using any of the content externally with customers. Proactive content sharing must be consistent with approved product labelling and be in line with local regulations and requirements. Content associated with unapproved products and indications may be provided by medical affairs/medical information in response to a specific unsolicited request for information, in line with specific procedures established by J&J and applicable local law.</li><br/>
                                        <li>Please <a href="mailto:ims@its.jnj.com" style={{textDecoration:"none"}}>contact us</a> with any performance or content issues you may identify. User feedback is welcome and will be used to help improve the overall search experience.</li>
                                    </ul>
                                    <p>
                                    <span ><input type="checkbox" value="yes" defaultChecked={this.props.defaultChecked} onChange={ this.handleChange } style={{margin:"1%"}}/></span><span style={{width:"93%",float:"right"}}>Check here to indicate that you have read and agree to these terms and conditions.</span> 
                                    </p>
                                    {(!this.state.defaultChecked && !this.state.pageLoad) || this.state.displayError? <div >
                                    <p className="errorLabel">Please check the box to continue.</p>
                                    </div>: null}
                                </div>
                                <div className="disclaimer-btn-mobile">
                                    <button onClick={(e) => this.handleSubmit()}>Continue</button>
                                </div>         
                        </div>
                        <div style={{margin:"0% 1.5%"}}>
                            <Footer customWidth={this.state.width} customHeight={this.state.height}/>
                        </div>
                    
                </div>
            )
        }
        else if (this.state.width > 600 && this.state.width <= 1000) {
            return(
                <div className="disclaimerOuterWrapper">
                            <Header/>
                            <div className="disclaimerWrapper">
                                <div className="disclaimerTitleTablet">
                                    iMedical Search User Agreement
                                </div>
                                <div className="disclaimerBody disclaimerBodyTablet">
                                    <p>Welcome to iMedical Search, an integrated global platform connecting you to high-value, valid and up-to-date scientific information from across the Janssen Pharmaceutical Companies of Johnson & Johnson. iMedical Search is brought to you by the Knowledge Management Team in Janssen Scientific Affairs and the Scientific Knowledge Management Team in EMEA.</p>
                                    <p>To begin your search experience, please agree to the following terms and conditions.</p>
                                    By accessing and using iMedical Search, you agree to:<br/><br/>
                                    <ul>
                                        <li>Use all content responsibly and appropriately. Documents intended for internal use (or labelled as confidential) are NOT to be shared externally under ANY circumstance. External discussion or dissemination of documents intended for this purpose should be by authorised personnel only. Literature content (and specifically publications) are authorised for internal J&J use only; additional copyright approval is required prior to use outside of J&J.</li><br/>
                                        <li>Ensure that all local review and approval processes are undertaken prior to using any of the content externally with customers. Proactive content sharing must be consistent with approved product labelling and be in line with local regulations and requirements. Content associated with unapproved products and indications may be provided by medical affairs/medical information in response to a specific unsolicited request for information, in line with specific procedures established by J&J and applicable local law.</li><br/>
                                        <li>Please <a href="mailto:ims@its.jnj.com" style={{textDecoration:"none"}}>contact us</a> with any performance or content issues you may identify. User feedback is welcome and will be used to help improve the overall search experience.</li>
                                    </ul>
                                    <p>
                                        <input type="checkbox" value="yes" defaultChecked={this.props.defaultChecked} onChange={ this.handleChange } style={{margin:"1%"}}/>Check here to indicate that you have read and agree to these terms and conditions.
                                    </p>
                                    {(!this.state.defaultChecked && !this.state.pageLoad) || this.state.displayError? <div >
                                    <p className="errorLabel">Please check the box to continue.</p>
                                    </div>: null}
                                </div>
                                <div className="disclaimerBtn">
                                    <Button text='Continue' customColor='#2EB000' action={this.handleSubmit}/>
                                </div>
                        </div>
                        <div style={{margin:"0% 1.5%"}}>
                            <Footer customWidth={this.state.width} customHeight={this.state.height}/>
                        </div>
                    
                </div>
            )
        }
        else{
            return(
                <div className="disclaimerOuterWrapper">
                            <Header/>
                            <div className="disclaimerWrapper">
                                <div className="disclaimerTitle">
                                    iMedical Search User Agreement
                                </div>
                                <div className="disclaimerBody">
                                    <p>Welcome to iMedical Search, an integrated global platform connecting you to high-value, valid and up-to-date scientific information from across the Janssen Pharmaceutical Companies of Johnson & Johnson. iMedical Search is brought to you by the Knowledge Management Team in Janssen Scientific Affairs and the Scientific Knowledge Management Team in EMEA.</p>
                                    <p>To begin your search experience, please agree to the following terms and conditions.</p>
                                    By accessing and using iMedical Search, you agree to:<br/><br/>
                                    <ul>
                                        <li>Use all content responsibly and appropriately. Documents intended for internal use (or labelled as confidential) are NOT to be shared externally under ANY circumstance. External discussion or dissemination of documents intended for this purpose should be by authorised personnel only. Literature content (and specifically publications) are authorised for internal J&J use only; additional copyright approval is required prior to use outside of J&J.</li><br/>
                                        <li>Ensure that all local review and approval processes are undertaken prior to using any of the content externally with customers. Proactive content sharing must be consistent with approved product labelling and be in line with local regulations and requirements. Content associated with unapproved products and indications may be provided by medical affairs/medical information in response to a specific unsolicited request for information, in line with specific procedures established by J&J and applicable local law.</li><br/>
                                        <li>Please <a href="mailto:ims@its.jnj.com" style={{textDecoration:"none"}}>contact us</a> with any performance or content issues you may identify. User feedback is welcome and will be used to help improve the overall search experience.</li>
                                    </ul>
                                    <p>
                                        <input type="checkbox" value="yes" defaultChecked={this.props.defaultChecked} onChange={ this.handleChange } style={{margin:"1%"}}/>Check here to indicate that you have read and agree to these terms and conditions.
                                    </p>
                                    {(!this.state.defaultChecked && !this.state.pageLoad) || this.state.displayError? <div >
                                    <p className="errorLabel">Please check the box to continue.</p>
                                    </div>: null}
                                </div>
                                <div className="disclaimerBtn">
                                    {/* <input type="submit" className="disclaimerBtn" onClick={this.handleSubmit}value="Continue"/> */}
                                    <Button text='Continue' customColor='#2EB000' action={this.handleSubmit}/>
                                </div>
                        </div>
                        <div style={{margin:"0% 1.5%"}}>
                            <Footer customWidth={this.state.width} customHeight={this.state.height}/>
                        </div>
                    
                </div>
            );
        }
    }
}
export default Disclaimer;