import React from 'react';
import Chart from 'react-apexcharts'
class DocumentPreviewChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            series: props.series,
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    events: {
                        click: this.handelClickOn
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: '30px',
                    },
                },
                stroke: {
                    width: 0,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: props.categories,
                    labels: {
                        formatter: function (val) {
                            return ""
                            // return val + "K"
                        }
                    }
                },
                yaxis: {
                    title: {
                        text: undefined
                    },
                },
                fill: {
                    opacity: 1,
                    colors: props.colors
                },
                dataLabels: {
                    enabled: false,
                },
                // tooltip: {
                //     enabled: false,
                // }
            }
        };
    }
    handelClickOn = (event, chartContext, config) => {
        let { categories, handleStackBarOnClick } = this.props;
        if (config.dataPointIndex > -1 && categories.length > config.dataPointIndex) {
            let pageNumber = parseInt((categories[config.dataPointIndex]).replace('p', ''));
            handleStackBarOnClick(pageNumber);
        }
    }

    render() {
        let { options, series } = this.state;
        let { categories, customWidth } = this.props
        let height = categories.length * 50 > 180 ? categories.length * 50 : 180;
        let width = customWidth ? (customWidth < 1000) ? 300 : 490 : 490;
        return (<div id="chart">
            {(options && series) && <Chart options={options} series={series} type="bar" width={(width)} height={(height)} />}
        </div>);
    }
}

export default DocumentPreviewChart