/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { IMSLogo, imsLoader } from "../../assets"
import {Link} from "react-router-dom";
import { useEffect } from "react";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import ApiServices from "../../utils/apiServices";
import * as FileSaver from 'file-saver';
import moment from 'moment';

// const indexData =(url,domain,ref,docVer,docID)=>{
//     const reference = ref.charAt(0).toUpperCase() + ref.slice(1);
//     const ts = new trackingService();
//     if(domain === 'RIMdocs' || domain === 'RIMdocs_LL'){
//         ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'),localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), 'View Document External', `${reference} Document`, window.location.href, '', '', '', docVer, '','' ,url, '', '', `${reference} Document`, '', '', domain, '', '', '');   
//     }   
//     else{
//         ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'),localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), 'View Document External', `${reference} Document`, window.location.href, '', '', '', docVer, docID,'' ,url, '', '', `${reference} Document`, '', '', domain, '', '', '');   
//     }  
// }

export default function DownloadDocs(props){
    //console.log(props)
    // const [isProcessing , setProcessing] = useState(false);
    // const queryParams = new URLSearchParams(props.location.search)
    // const docID = queryParams.get('ID')
    // const connID = queryParams.get('connID')
    // const csID = queryParams.get('csID')
    // const fileType = queryParams.get('fileType')
    // const docVer = queryParams.get('docVer')
    // const domain = queryParams.get('cs')
    // const docState = queryParams.get('docState')
    // const ref = queryParams.get('ref')
    // let url;
    // //let userid=localStorage.getItem('username').toLowerCase().replace(/"/g,'').split('@')[0]
    // if(domain === 'RIMdocs' || domain === 'RIMdocs_LL'){
    //     url =`${process.env.REACT_APP_IMS_API_PREFIX}download/RIMdocs?strID=${docID}`
    // }
    // else if(domain === 'SKM'){
    //     url = `${process.env.REACT_APP_IMS_API_PREFIX}dcs-spo/download?docID=${docID}&connID=${connID}&csID=${csID}&fileType=${fileType}`
    // }
    // else if(domain === "CTGov"){
    //     url = `https://clinicaltrials.gov/ct2/show/${docID}`;
    // }
    // else{
    //     if(fileType !== 'pdf'){
    //         url = `${process.env.REACT_APP_IMS_API_PREFIX}dcs-vv/download?docID=${docID}&connID=${connID}&csID=${csID}&fileType=${fileType}&docVer=${docVer}`
    //     }
    //     else{
    //         url = `${process.env.REACT_APP_IMS_API_PREFIX}dcs-vv/download?docID=${docID}&connID=${connID}&csID=${csID}&fileType=${fileType}`  
    //     }
    //     if(docState){
    //         url=url+`&docState=anr`
    //     }
    // }
    // if(ref){
    //     indexData(url,domain,ref,docVer,docID)
    // }
    // window.location.href = url;
    useEffect(async()=>{
        let res;
        let body = {
            urls: window.citationType === 'Marked Results' ? window.selectedResults : window.displayResults
        }
        let displayName = localStorage.getItem('username').toLowerCase().replace(/"/g,'').split('@')[0]
        //let currentDate = new Date().toString("dMMMyyyy");
        let currentDate = moment().format("DMMMYYYY");
        let uniqueId = Math.floor(100000 + Math.random() * 900000)
        const fileName = `${displayName}_${currentDate + uniqueId}.zip`;
        let service = new ApiServices();
        await service.downloadZipFile(body)
        .then((response) => {
            res = response.data
            if (res) {
                props.history.push('/Finish')
            }
            const data = new Blob([res], { type: 'application/zip' });
            FileSaver.saveAs(data, fileName);
        }).catch((error) => {
            console.log(error)
        })
    },[])
    return(
        <>
        <ErrorBoundary> 
        <div className="document-tab-outer-wrapper">
            <div className="document-tab-wrapper">
                <div className="document-header-wrapper" style={{paddingBottom:0}}>
                    <a>
                    <img src={IMSLogo} alt="" title="iMedical Search"/>
                    </a>
                </div>
                <img src={imsLoader} alt="" width={100} height={100}/>
                <div className="document-tab-inner-wrapper">
                    <div className="document-tab-content-upper-card" style={{borderBottom: "none", paddingBottom:"20px",margin: "0 32%"}}>
                        <span style={{color:"#1076bc",fontSize:"20px"}}><b>Thank you.</b>
                        <hr />
                         Your document will soon be available here.</span><br/>
                    </div>
                    <ul style={{textAlign:"left",margin:"1% 31%"}}>
                        <li>While we process your request, you can still continue with your work keeping this tab open.</li><br/>
                        <li>Once the files are ready, either you'll be prompted for save location or will be saved in default location - depending on your browser settings.</li><br/>
                        <li>Please close this tab after files are downloaded.</li>
                    </ul>
                    <div>
                        <span style={{fontSize:"14px"}}>
                            Click <Link to="/" style={{cursor:"pointer",color:"#1076bc"}} target="_blank">here</Link> to go to iMedical Search - Home page.If you have any query or concern related to the application, please contact us <a href="mailto:ims@its.jnj.com" rel="noreferrer" style={{cursor:"pointer",color:"#1076bc"}} target="_blank">here</a>.
                        </span><br/>
                        {/* <span style={{fontSize:"12px"}}>You may close this tab after your document is downloaded.</span> */}
                    </div>
                </div>
            </div>
            {/* <div style={{margin:"0% 1.5%"}}>
                <Footer/>
            </div> */}
         </div>  
        </ErrorBoundary> 
         </>
    )
}