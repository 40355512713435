import { Component } from "react";
import { Modal } from "react-bootstrap";
import { popCancelBttn } from "../../assets";
import Button from "../Button";
import './GlobalSearchFeaturesPopUp.css'
import './PinToReadingListPopUp.css'
import DublicateBookmarkPopUp from "./DublicateBookmarkPopUp";
import ApiServices from "../../utils/apiServices";
import { snippetLongString } from "../../utils/utilityServices"
import AlertMessagePopUpModal from "./AlertMessagePopUpModal";
class RenameBookmarkPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bookmarkName: '',
            bookmarkId: '',
            showPop: false,
            showDublicateModal: false,
            error: {},
            alertMessageModelColor: "",
            alertMessageContent: "",
            alertMessageShowModal: false,
        }
    }
    componentDidMount() {
        this.setState({
            showPop: this.props.showModal,
            bookmarkName: this.props.bookmarkName,
            bookmarkId: this.props.bookmarkId
        });
    }
    successAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#00c12c",
            alertMessageShowModal: true
        })
    }
    closeAlertMessage = () => {
        this.setState({
            alertMessageContent: "",
            alertMessageModelColor: "",
            showPop: false,
            alertMessageShowModal: false
        })
        this.props.handleClose(false)
    }

    handleOnClickView = () => {
        let url = `${window.location.origin}/results${this.props.searchProperty}`
        window.open(url, '_blank');
    }
    handleSave = async () => {
        let service = new ApiServices();
        if (this.handleValidation()) {
            let res = await service.updateBookmark(this.state.bookmarkName, this.state.bookmarkId);
            if (res) {
                this.successAlertMessage('Bookmark Name saved successfully')
            }
        }
    }
    handleInput = (e) => {
        e.target.value = snippetLongString(e.target.value);
        this.setState({
            bookmarkName: e.target.value
        })
    }
    handleValidation() {
        let errors = {};
        let formIsValid = true;
        let bookmark = this.props.apiResponse.filter(x => x.bookmark_id !== this.state.bookmarkId).map(data => data.bookmark_name.toLowerCase())
        if (this.state.bookmarkName.length <= 0) {
            formIsValid = false;
            errors["bookmarkName"] = 'Please enter value.';
        }
        if (bookmark.includes(this.state.bookmarkName.toLowerCase())) {
            formIsValid = false;
            // this.setState({ showDublicateModal: true });
            errors["bookmarkName"] = `Bookmark name must be unique. Please give a different name other than '${this.state.bookmarkName}'.`;
        }
        this.setState({ error: errors });
        return formIsValid;
    }
    render() {
        const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
        if (this.props.customWidth <= 1000) {
            return (
                <>
                    <Modal
                        show={this.state.showPop}
                        size="lg md sm xs"
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        dialogClassName="popup-transition"
                        className="z-index-rename"
                        backdropClassName="z-index-rename-backdrop"
                        scrollable={true}
                    >
                        <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
                            <div>
                                <Modal.Header className="gsfModalHeader" style={{ justifyContent: "left", padding: "0.5rem 0" }}>
                                    <div style={{ textAlign: "center", width: "100%", paddingTop: "1%" }}>
                                        <Modal.Title>
                                            <div className="actionItem-headerPart">
                                                Rename Bookmark
                                            </div>
                                        </Modal.Title>
                                    </div>
                                </Modal.Header>
                                <Modal.Body scrollable={true} style={{ minHeight: "25vh" }}>
                                    <table className="manage-personal-alerts-wrapper">
                                        <tr><th>Bookmark Name</th><th style={{ textAlign: "center" }}>Actions</th></tr>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "60%" }}><input type="text" value={this.state.bookmarkName} onChange={this.handleInput} /><br /><div className="errorInvalidName">{this.state.error["bookmarkName"]}</div></td>
                                                <td style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", width: "100%" }}><span onClick={this.handleOnClickView} title="View" style={{ marginRight: "5px" }} className="reading-list-action-text">View</span><span className="action-items-separate-divider"></span><span title="Save" onClick={e => this.handleSave()} className="reading-list-action-text">Save</span></td>
                                            </tr>
                                            <span className="length-info">Name with a maximum of 100 characters.</span>
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <div className="reading-list-button-tablet-wrapper">
                                    <Button text="Cancel" customColor="#0074B0" action={() => {
                                        this.setState({ showPop: false });
                                        this.props.handleClose(false);
                                    }} />
                                </div>
                            </div>
                        </div>
                        {this.state.showDublicateModal &&
                            <>
                                <DublicateBookmarkPopUp showModal={this.state.showDublicateModal} handleClose={(status) => { this.setState({ showDublicateModal: status }) }} color="#0074B0" bookmarkName={this.state.bookmarkName} />
                                <div className="savemodal-outer-wrapper"></div>
                            </>
                        }
                    </Modal>
                    {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
                </>
            )
        }
        else {

            return (<>
                <Modal
                    show={this.state.showPop}
                    size="lg md sm xs"
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogClassName="popup-transition"
                    className="z-index-rename"
                    backdropClassName="z-index-rename-backdrop"
                    scrollable={true}
                >
                    <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
                        <div className="popup-cancel-btn-wrapper">
                            <img
                                src={popCancelBttn}
                                alt=""
                                onClick={e => {
                                    this.setState({ showPop: false });
                                    this.props.handleClose(false);
                                }}
                            />
                        </div>
                        <div>
                            <Modal.Header className="gsfModalHeader" style={{ justifyContent: "left" }}>
                                <div style={{ textAlign: "center", width: "30%", paddingTop: "1%" }}>
                                    <Modal.Title>
                                        <div className="actionItem-headerPart">
                                            Rename Bookmark
                                        </div>
                                    </Modal.Title>
                                </div>
                            </Modal.Header>
                            <Modal.Body scrollable={true} style={{ minHeight: "25vh" }}>
                                <table className="manage-personal-alerts-wrapper" /* className="reading-list-view-wrapper" */>
                                    <tr><th>Bookmark Name</th><th style={{ textAlign: "center" }}>Actions</th></tr>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "60%" }}><input type="text" value={this.state.bookmarkName} onChange={this.handleInput} /><br /><div className="errorInvalidName">{this.state.error["bookmarkName"]}</div></td>
                                            <td style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", width: "100%" }}><span onClick={this.handleOnClickView} title="View" style={{ marginRight: "5px" }} className="reading-list-action-text">View</span><span className="action-items-separate-divider"></span><span title="Save" onClick={e => this.handleSave()} className="reading-list-action-text">Save</span></td>
                                        </tr>
                                        <span className="length-info">Name with a maximum of 100 characters.</span>
                                    </tbody>
                                </table>
                            </Modal.Body>
                            <div className="reading-list-button-wrapper">
                                <Button text="Cancel" customColor="#0074B0" action={() => {
                                    this.setState({ showPop: false });
                                    this.props.handleClose(false);
                                }} />
                            </div>
                        </div>
                    </div>
                    {this.state.showDublicateModal &&
                        <>
                            <DublicateBookmarkPopUp showModal={this.state.showDublicateModal} handleClose={(status) => { this.setState({ showDublicateModal: status }) }} color="#0074B0" bookmarkName={this.state.bookmarkName} />
                            <div className="savemodal-outer-wrapper"></div>
                        </>
                    }
                </Modal>
                {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
            </>
            )
        }
    }
}
export default RenameBookmarkPopup;