import { Modal } from "react-bootstrap";
import './GlobalSearchFeaturesPopUp.css'
import { imsLoader } from "../../assets";

export default function DownloadProcessingPopUpModal(props) {
    const {
        showModal,
        color    
    } = props
    return (
        
            <Modal
                show={showModal}
                size="lg md sm xs"
                centered
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="savePopUp"
                scrollable={true}
            >
                
                    <div style={{ padding: "0 5%" }}>
                            <div style={{ fontSize:"14px",textAlign:"center" }}>
                                <Modal.Body>
                                    <img src={imsLoader} alt="" width={100} height={100}/>
                                    <div className="saveModal-heading" style={{ color: color }}>Please wait while your zip file is getting generated.</div>
                                </Modal.Body>
                            </div>
                    </div>
                
            </Modal>
    )
}