/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { IMSLogo } from "../../assets"
import {Link} from "react-router-dom";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";

// const indexData =(url,domain,ref,docVer,docID)=>{
//     const reference = ref.charAt(0).toUpperCase() + ref.slice(1);
//     const ts = new trackingService();
//     if(domain === 'RIMdocs' || domain === 'RIMdocs_LL'){
//         ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'),localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), 'View Document External', `${reference} Document`, window.location.href, '', '', '', docVer, '','' ,url, '', '', `${reference} Document`, '', '', domain, '', '', '');   
//     }   
//     else{
//         ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'),localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), 'View Document External', `${reference} Document`, window.location.href, '', '', '', docVer, docID,'' ,url, '', '', `${reference} Document`, '', '', domain, '', '', '');   
//     }  
// }

export default function DownloadFinish(props){
    return(
        <>
        <ErrorBoundary> 
        <div className="document-tab-outer-wrapper">
            <div className="document-tab-wrapper">
                <div className="document-header-wrapper" style={{marginBottom:0}}>
                    <a>
                    <img src={IMSLogo} alt="" title="iMedical Search"/>
                    </a>
                </div>
                <div className="document-tab-inner-wrapper">
                    <div className="document-tab-content-upper-card" style={{borderBottom: "none", paddingBottom:"20px",margin: "0 32%"}}>
                        <span style={{color:"#1076bc",fontSize:"20px"}}><b>Thank you.</b>
                        <hr />
                        Your documents are now downloaded. Please close this tab.</span><br/>
                    </div>
                    <div>
                        <span style={{fontSize:"14px"}}>
                            Click <Link to="/" style={{cursor:"pointer",color:"#1076bc"}} target="_blank">here</Link> to go to iMedical Search - Home page.If you have any query or concern related to the application, please contact us <a href="mailto:ims@its.jnj.com" style={{cursor:"pointer",color:"#1076bc"}} target="_blank">here</a>.
                        </span><br/>
                        {/* <span style={{fontSize:"12px"}}>You may close this tab after your document is downloaded.</span> */}
                    </div>
                </div>
            </div>
            {/* <div style={{margin:"0% 1.5%"}}>
                <Footer/>
            </div> */}
         </div>  
        </ErrorBoundary> 
         </>
    )
}