import { Modal } from "react-bootstrap";
import { Component } from "react";
import { imsLoader } from '../../assets/index';
import Button from "../Button";
import ApiServices from "../../utils/apiServices";
import './ProductAlertsPopUp.css'
import trackingService from "../../services/trackingService";
const CCDSUSLabelingFilter = {
    "CCDS": `?query=&q=All Fields&filters=${encodeURIComponent('Document Type/Labeling Documents - Core, EU, US/Core Labeling/Company Core Data Sheets')}`,
    "US Labeling": `?query=&q=All Fields&filters=${encodeURIComponent('Document Type/Labeling Documents - Core, EU, US/US Labeling')}`
}
class CCDSUSLabelingAlertsPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productsName: [],
            frequencyValue: [], //'Daily'
            isSubscribe: [],
            productsAlerts: [],
            isLoaded: false,
        }
    }
    async componentDidMount() {
        // let username = JSON.parse(localStorage.getItem('username')).split('@')[0];
        let service = new ApiServices();
        let apiRes = await service.getAllAlertsCCDSAndUSLabeling();
        let feqArr = [];
        for (let index = 0; index < apiRes.length; index++) {
            feqArr.push('Daily')
        }
        this.setState({
            productsName: apiRes,
            frequencyValue: feqArr,
            // isLoaded: true
        })
    }
    async componentWillReceiveProps(prevProp) {
        let service = new ApiServices();
        let username = JSON.parse(localStorage.getItem('username')).toLowerCase();
        if (prevProp.showCCDSAndUSLabelingAlerts !== this.props.showCCDSAndUSLabelingAlerts) {
            let productsAlerts = await service.getProductsAlertsById(username);
            let feqArr = [];
            let isSubscribe = [];
            if (productsAlerts?.length !== this.state.productsAlerts.length) {
                let apiRes = await service.getAllAlertsCCDSAndUSLabeling();
                if (productsAlerts?.length > 0) {
                    for (let index = 0; index < productsAlerts.length; index++) {
                        if (productsAlerts[index].alert_enabled === 1 && productsAlerts[index].isproduct_alert) {
                            let productIndex = apiRes.findIndex(x => x.product_name === productsAlerts[index].search_keyword)
                            feqArr[productIndex] = productsAlerts[index].alert_frequency;
                            isSubscribe[productIndex] = true
                        }
                    }
                    for (let i = 0; i < apiRes.length; i++) {
                        if (!feqArr[i]) {
                            feqArr[i] = 'Daily'
                        }
                    }
                    this.setState({
                        frequencyValue: feqArr,
                        isSubscribe: isSubscribe,
                        productsAlerts: productsAlerts,
                        isLoaded: true
                    })
                }
            }
        }
    }
    handleAlertFrequency = async (e, index) => {
        let service = new ApiServices();
        let action = this.state.frequencyValue;
        action[index] = e.target.value;
        this.setState({ frequencyValue: action });

        let isProductSubscribe = this.state.isSubscribe[index];
        if (isProductSubscribe) {
            let search_name = this.state.productsName[index].product_name;
            let username = JSON.parse(localStorage.getItem('username')).toLowerCase();
            await service.updatePersonalAlerts(
                1,
                action[index],
                username,
                search_name
            );
        }
    }
    handleAlerts = async (e, index) => {
        let service = new ApiServices();
        let wwid = JSON.parse(localStorage.getItem('wwid'))
        let username = JSON.parse(localStorage.getItem('username')).toLowerCase();
        let productName = this.state.productsName[index].product_name
        let productid = this.state.productsName[index].product_id
        let alertFeq = this.state.frequencyValue[index]
        let currentDate = new Date()
        let checkStatus = this.state.isSubscribe
        checkStatus[index] = e.target.checked;
        let action = this.state.frequencyValue;
        this.setState({ isSubscribe: checkStatus });
        let searchProprety = CCDSUSLabelingFilter[productName];
        if (wwid === 0) {
            var jjedsres = await service.getJJEDSdetails(username.split('@')[0]);
            wwid = jjedsres.uid;
        }
        if (e.target.checked) {
            await service.createAlert(wwid, productName, productName, searchProprety, alertFeq, username, this.dateFormate(currentDate), productid, 2)
            this.handelAddProductAlert(productName)
        }
        else {
            action[index] = 'Daily';
            this.setState({ frequencyValue: action });
            await service.unsubscribeProductAlerts(username, productid)
        }
    }
    dateFormate = (date) => {
        return `${date.getFullYear()}-${(date.getMonth() + 1).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        })}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()}`
    }
    handelAddProductAlert = (product) => {
        const ts = new trackingService();
        ts.indexTrackingData(
            localStorage.getItem("userdisplayName"),
            localStorage.getItem("wwid"),
            localStorage.getItem("username"),
            localStorage.getItem("function"),
            localStorage.getItem("region"),
            localStorage.getItem("userproducts"),
            localStorage.getItem("doctypepref"),
            localStorage.getItem("sid"),
            localStorage.getItem("deviceType"),
            localStorage.getItem("deviceOS"),
            localStorage.getItem("browserName"),
            localStorage.getItem("browserVersion"),
            localStorage.getItem("jnjDepartment"),
            localStorage.getItem('companyname'),
            `Create Alert`,
            "",
            product,
            "",
            ""
        );
    }
    render() {
        let { isLoaded } = this.state;
        if (this.props.customWidth < 1000) {
            return (<Modal
                show={this.props.showCCDSAndUSLabelingAlerts}
                size="lg md sm xs"
                centered
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="popup-transition-mob-tab"
                scrollable={true}
            >
                <div>
                    <div style={{ borderLeft: `6px solid #0074B0`, height: '100%' }}>
                        <div className="paModalWrapper" /* style={{ padding: "0 5%" }} */>
                            <Modal.Header className="productsAlertsHeader" /* style={{ justifyContent: "center", borderBottomColor: "#0074B0" }} */>
                                <span className="prodAlertHeadingText">Subscribe to CCDS and US Labeling Alerts</span>
                            </Modal.Header>
                            <Modal.Body scrollable={true} style={{ minHeight: "25vh" }}>
                                <div>
                                    {isLoaded ? <table className="productAlertsTable">
                                        <thead className="prodAlertsTableHeading">
                                            <tr>
                                                <th>Product</th>
                                                <th>Alert Frequency</th>
                                                <th>Subscribe</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.productsName.map((name, index) => {
                                                    return (
                                                        <tr className="prodAlertsData">
                                                            <td>
                                                                <span>{name.product_name}</span>
                                                            </td>
                                                            <td>
                                                                <select name={`prod${name.product_id}`} id={`prod${index + 1}`} onChange={(e) => this.handleAlertFrequency(e, index)} value={this.state.frequencyValue[index]}>
                                                                    <option value="Daily">Daily</option>
                                                                    <option value="Weekly">Weekly</option>
                                                                    <option value="Monthly">Monthly</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <span>
                                                                    <input type="checkbox" class="k-checkbox" style={{ margin: 0 }} onChange={(e) => this.handleAlerts(e, index)} id={`prodsubscrib${name.product_id}`} checked={this.state.isSubscribe[index]} />
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table> : <div className="prod-alerts-data-loader">  <img src={imsLoader} alt="" width={100} height={100} /></div>}
                                </div>
                            </Modal.Body>
                            <div className="prodAlertsButtonContainer" /* style={{ textAlign: "center", padding: "3% 0" }} */>
                                <Button text="Close" customColor="#0074B0" action={() => { this.setState({ showPop: false }); this.props.handleClose(false) }} />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            )
        }
        else {
            return (
                <Modal
                    show={this.props.showCCDSAndUSLabelingAlerts}
                    size="lg md sm xs"
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogClassName="popup-transition-prodAlerts"
                    scrollable={true}
                >
                    <div>
                        <div style={{ borderLeft: `6px solid #0074B0`, height: '100%' }}>
                            <div className="paModalWrapper" /* style={{ padding: "0 5%" }} */>
                                <Modal.Header className="productsAlertsHeader" /* style={{ justifyContent: "center", borderBottomColor: "#0074B0" }} */>
                                    <span className="prodAlertHeadingText">Subscribe to CCDS and US Labeling Alerts</span>
                                </Modal.Header>
                                <Modal.Body scrollable={true} style={{ minHeight: "25vh" }}>
                                    <div>
                                        {isLoaded ? <table className="productAlertsTable">
                                            <thead className="prodAlertsTableHeading">
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Alert Frequency</th>
                                                    <th>Subscribe</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.productsName.map((name, index) => {
                                                        return (
                                                            <tr className="prodAlertsData">
                                                                <td>
                                                                    <span>{name.product_name}</span>
                                                                </td>
                                                                <td>
                                                                    <select name={`prod${name.product_id}`} id={`prod${index + 1}`} onChange={(e) => this.handleAlertFrequency(e, index)} value={this.state.frequencyValue[index]}>
                                                                        <option value="Daily">Daily</option>
                                                                        <option value="Weekly">Weekly</option>
                                                                        <option value="Monthly">Monthly</option>
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <span>
                                                                        <input type="checkbox" class="k-checkbox" style={{ margin: 0 }} onChange={(e) => this.handleAlerts(e, index)} id={`prodsubscrib${name.product_id}`} checked={this.state.isSubscribe[index]} />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table> : <div className="prod-alerts-data-loader"> <img src={imsLoader} alt="" width={100} height={100} /></div>}

                                    </div>
                                </Modal.Body>
                                <div className="prodAlertsButtonContainer" /* style={{ textAlign: "center", padding: "3% 0" }} */>
                                    <Button text="Close" customColor="#0074B0" action={() => { this.setState({ showPop: false }); this.props.handleClose(false) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )
        }
    }

}
export default CCDSUSLabelingAlertsPopUp;