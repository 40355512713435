import { Modal } from "react-bootstrap";
import { popCancelBttn, PlayButton,PauseButton, Speaker, RedStop, RecordingIcon, PlusIconBlue } from "../../assets";
import { useEffect, useRef, useState } from "react";
import './AliasModal.css';
import Box from '@mui/material/Box';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Button from "../Button";
import axios from "axios";
import { useAudioRecorder } from 'react-audio-voice-recorder';
import sampleAudio from '../../assets/SampleAudioTest.mp3';
import Services from "../../utils/services";
import { useReactMediaRecorder } from "react-media-recorder";

const AliasModal = (props) => {
  const [phoneme, setPhoneme] = useState('');
  //const [isPhoneme, setIsPhoneme] = useState(true);
  const [isSpeaking, setIsSpeaking] = useState(true);
  const [textselected, setTextselected] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [wordLexicon, setWordLexicon] = useState('');
  const [lexiconError, setLexiconError] = useState(false);
  const [textBoxGraphme , setTextBoxGrapheme] = useState("");
  const audioSrc = useRef (new Audio(sampleAudio));
  const [isRecording, setIsRecording] = useState(false);
  const [manyPhonemes,setManyPhonemes] = useState([]);
  const [isPlaying , setIsPlaying] = useState(false);
  const [audio, setAudio] = useState();
  const intervalRef = useRef();
  
  useEffect(()=>{
    audioSrc.current = new Audio(audio);
    },[audio]); 

  /*const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
    mediaRecorder,
  } = useAudioRecorder();*/

  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({mediaRecorderOptions:{ mimeType: "audio/wav",
      bitsPerSecond: 256000,
      audioBitsPerSecond: 256000,
      audioBitrateMode: "constant",
     }});

  useEffect(() => {
    setTextselected(props.textselected)
  }, [props.textselected]);

  useEffect(()=>{
    setWordLexicon(props.prevGrapheme)
  },[props.prevGrapheme]);
  
  /* const timeout = () => {
     const timeout = setTimeout(()=>{
       handleAudioStop();
       SpeechRecognition.stopListening();
     },10000);
   }*/
  const fetchPhoneme = async(url) =>{
    let response;
    let data;
    let blob = await fetch(url).then(r => r.blob());
    //console.log(blob)
   
    var reader = new FileReader();
    reader.readAsArrayBuffer(blob);
    reader.onloadend = (event) => {
    // The contents of the BLOB are in reader.result:
  //console.log(reader.result)
    //data = reader.result;
}
    await axios.post("https://predev-ims.jnj.com/api/tts/phoneme",blob
    ).then(
      (res)=>{
        response = res.data.phonemes[0]
        setManyPhonemes(res.data.phonemes)
      }).catch(
        (error)=>
      console.log(error)
    );
    setPhoneme(response)
    /*const aTag = document.createElement('a');
    aTag.href = url;
    aTag.setAttribute('download','untitled.wav');
    document.body.append(aTag);
    aTag.click();
    aTag.remove();*/
  }
  useEffect(()=>{
    if(mediaBlobUrl){
    fetchPhoneme(mediaBlobUrl);
    }
  },[mediaBlobUrl])

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();


  useEffect(() => {
    setPhoneme('')
  },[])

  let timeout;

  /*useEffect(()=>{
     timeout = setTimeout(()=>{
      //SpeechRecognition.stopListening();
      alert("1000ms")
    },10000);
  },[listening])*/

  /*useEffect(()=>{
    setIsMikeOn(false)
  },[(!listening)])*/

  const fetchAudio = async (row) =>{
    let payload;
    //let text1 = text.replace(textselected, `<phoneme alphabet="ipa" ph="${phonemeValue}">${textselected}</phoneme>`)
            payload =
             {
                "ssml": `<speak version=\"1.0\" xmlns=\"http://www.w3.org/2001/10/synthesis\" xml:lang=\"en-US\">\n                        <voice name="en-US-AvaNeural">\n                           
                <phoneme alphabet="ipa" ph="${row}"></phoneme></voice>\n                    </speak>`,
                
              }
              await axios.post(
                `https://predev-ims.jnj.com/api/tts/ssml`,
                payload,
                {
                  responseType: "blob",
                }
              ).then((response) => {
                const data = new Blob([response.data], { type: 'audio/wav' });
               const blobUrl = URL.createObjectURL(data);
                setAudio(blobUrl);
                //setLoader(false)
               }).catch((error) => {
                console.log(error)
            })
}
const startTimer = () => {
  // Clear any timers already running
  clearInterval(intervalRef.current);

  intervalRef.current = setInterval(() => {
      if (audioSrc.current.ended) {
          setIsPlaying(false)
          clearTimeout(intervalRef.current)
      }
  }, [1000]);
};
const handlePlay = async(row) =>{
  await fetchAudio(row);
  setIsPlaying(true);
   audioSrc.current.play();
   startTimer();      
}

  const handleConfirm = async () => {
    if (props.fromLexicon) {  
      if (wordLexicon.length > 0 && phoneme.length > 0) {
        setLexiconError(false)
        let res;
        let dbQuery;
        if(props.prevPhoneme.length == 0){
        dbQuery = `DO $$ DECLARE phoneme_id INT; BEGIN INSERT INTO "iMS".phm_ims_ttsphoneme (grapheme,phoneme) Values('${wordLexicon}','${phoneme}') RETURNING id INTO phoneme_id; INSERT INTO "iMS".phm_ims_ttslexiconphonemerel VALUES (${props.textselected},phoneme_id); END $$;`;
        }
        else{
          dbQuery =`Update "iMS". phm_ims_ttsphoneme set grapheme='${wordLexicon}' , phoneme = '${phoneme}' where id = ${props.textselected}`
        }
        res = await axios.get(`https://stage-ims.jnj.com/api/db/runquery?dbquery=${dbQuery}`);

        //let res = await service.updateLexicon(selectedLexi?.id, props.grapheme, props.phoneme)
        if (res) {
          props.handleClose();
        }
      }
      else {
        setLexiconError(true)
      }
    }
    else {
      if ( props.textselected.length > 0 && phoneme.length > 0){
      setLexiconError(false)
      props.setPhonemeValue(phoneme)
      props.setIsPhoneme(true)
      props.handleClose();
      }
      else{
      setLexiconError(true)
      }
    }
  }
  return (
    <Modal
      show={true}
      size="lg md sm xs"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="popup-transition"
      scrollable={false}
    >
      <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
        <div className="popup-cancel-btn-wrapper">
          <img
            src={popCancelBttn}
            alt=""
            onClick={e => {
              props.handleClose()
            }}
          />
        </div>
      </div>
      <Modal.Header><Modal.Title>Phoneme</Modal.Title></Modal.Header>
      <Modal.Body>
        <div className="selectedWords">
          {(!props.fromLexicon) ? <><label classname="label-1">Selected words</label>
            <div>{textselected}</div></> : <>
            <input type="text" placeholder="Enter the word" value={wordLexicon} onChange={(e) => setWordLexicon(e.target.value)} />
          </>}
        </div>
        <div className="label-1">Phoneme</div>
        <div>{props.prevPhoneme}</div>
        <div className="phoneme-text-container">
          <div className="ms-TextField-fieldGroup fieldGroup-563">
            <input type="text" placeholder="Drag or click the phonemes from the library"
              value={phoneme}
              style={{
                width: "33.5rem",
                border: "none"
              }} />
            <button onClick={() => { setPhoneme('') 
            }}><img src={popCancelBttn} /></button>
          </div>
          <div className="phoneme-preview-audio-button audio-button">
            <button
              type="button"
              className="circlebutton"
              onClick={() => {
                if(!isPlaying){
                  handlePlay(phoneme)
                }
               }}
              aria-label="Play"
            >
              <span className="flexContainer-177">
                {(!isPlaying)?<img src={PlayButton} />:<img src ={PauseButton}/>}
              </span>
            </button>
          </div>
        </div>
        <div>
          <div style={{ marginTop: "1.25rem" }}>
            <label className={(isSpeaking) ? "label1 underline" : "labe1"}
              style={{ marginLeft: "15px" }}
              onClick={() => {
                setIsSpeaking(true)
              }}>Recognize By Speaking</label>
          </div>
          {(isSpeaking) &&
            <Box className="box-container">
              <button className="spaekerClick"
                style={{ marginTop: "0.5rem" ,
                  backgroundColor:"#FFFF"
                }}
                onClick={() => {
                  /*if (!listening) {
                    resetTranscript();
                    SpeechRecognition.startListening({ continuous: true });
                  }
                  else if (listening) {
                    SpeechRecognition.stopListening();
                  }*/
                 if(!isRecording){
                  startRecording()
                  setIsRecording(true)
                 }
                 else{
                  stopRecording()
                  setIsRecording(false)
                 }
            
                }}>{(!isRecording) ? <img src={Speaker} /> : <img src={RedStop} />}</button>
              {(!isRecording) ? <><div className="Sp-content">Press to start speaking</div>
                <div className="Sp-content">Your recording will be used to generate phonemes.</div></>
                : <>
                  <div className="Sp-content"><img src={RecordingIcon} /></div>
                  <div className="Sp-content">You recording time must be between 1s and 10s (inclusive).</div>
                  <div className="Sp-content">Recording ... Press button to stop</div></>}
            </Box>}
          {(lexiconError) ? <span className="error-check">Grapheme/Phoneme not selected</span> 
          :<span className="error-check"></span>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {(!props.fromLexicon) && <button style={{ marginRight: "70px" }}
        onClick={() => {
          props.handleClose()
          props.setAddLexiModal(true)
          props.setPhonemeValue(phoneme)
        }}>
          <span style={{ marginRight: "5px" }}>
          <img src={PlusIconBlue} /></span>
          <span>Add to lexicon</span></button>}
        <Button text="Confirm" customColor='#0074B0' action={() => {
          handleConfirm()
        }} />
        <Button text="Cancel" customColor="#0074B0" action={() => {
          props.handleClose()
        }} />
      </Modal.Footer>
    </Modal>
  )
}
export default AliasModal;