/* eslint-disable array-callback-return */
import { Component } from "react";
import { Link } from "react-router-dom";
import { rightSideArrow, popCancelBttn, iIcon } from "../../assets";
import config from "../../config/config";
import trackingService from "../../services/trackingService";
import "./ContentShortcuts.css";

const convertToHtml = (data) => {
  return <div dangerouslySetInnerHTML={{ __html: data }}></div>;
};
class SubmissionRelatedandClinicalSafetyDocumentsTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCSModal: false,
      products: [],
      arrayOfDocTypes: [],
      selectedProduct: "",
      showInfo: false,
      showMoreProducts: false,
      startIndex: 0,
    };
  }
  async componentDidMount() {
    // let service = new ApiServices();
    // let email = JSON.parse(localStorage.getItem('username')).toLowerCase();
    // let isProductsAvailable;
    // let res = await service.getUserProductAndWwid(email);
    // if (res) {
    //     isProductsAvailable = res[0].products
    // }
    // if (isProductsAvailable !== null && isProductsAvailable?.length > 0) {
    //     this.setState({
    //         products: isProductsAvailable.split(';'),
    //         isLoaded: true
    //     }, () => {
    //         if (this.state.selectedProduct === '') {
    //             this.setState({
    //                 selectedProduct: this.state.products[0]
    //             })
    //         }
    //     })
    // }
    // else {
    //     this.setState({
    //         isLoaded: true
    //     })
    // }
    this.setState({
      arrayOfDocTypes: config.srcsDocuments,
    });
  }
  componentWillReceiveProps() {
    if (this.props.products?.length !== this.state.products.length) {
      if (
        this.props.products?.length > 0 &&
        this.props.selectedProduct.length > 0
      ) {
        this.setState({
          products: this.props.products,
          selectedProduct: this.props.selectedProduct,
        });
      }
    }
  }
  componentDidUpdate = () => {
    if (this.props.showSRnCSModal !== this.state.showCSModal) {
      this.setState({ showCSModal: this.props.showSRnCSModal });
    }
  };
  getWidth = (diff) => {
    let calWidth;
    switch (diff) {
      case 1:
        calWidth = 20;
        break;
      case 2:
        calWidth = 30;
        break;
      case 3:
        calWidth = 40;
        break;
      case 4:
        calWidth = 60;
        break;
      case 5:
        calWidth = 90;
        break;
      case 6:
        calWidth = 100;
        break;
      default:
        calWidth = 100;
        break;
    }
    return calWidth;
  };
  selectProduct = (product, startIdx, endIdx) => {
    // var diff = endIdx - startIdx;
    // var calcwidth = this.getWidth(product.length - startIdx);
    if (product.length > 0) {
      return (
        <>
          {startIdx > 0 && (
            <div className="wnfy-select-nav-button-wrapper">
              <div
                className="wnfy-result-navigate-arrow"
                onClick={(e) => this.handleOnClickLeft()}
              >
                <img
                  src={rightSideArrow}
                  alt=""
                  style={{ transform: "rotate(180deg)" }}
                />
              </div>
            </div>
          )}
          {/* {diff < 6 &&
                        <div style={{ flex: "auto" }}></div>
                    } */}
          <div
            className="cs-modal-product-wrapper" /*  style={{ width: `${calcwidth}%` }} */
          >
            {product.map((item, index) => {
              if (index >= startIdx && index <= endIdx) {
                return (
                  <div
                    className={
                      item === this.state.selectedProduct
                        ? "cs-select-product-wrapper cs-product-selected"
                        : "cs-select-product-wrapper"
                    }
                    onClick={this.handleSelectProduct}
                    key={index}
                    id={`${item}`}
                  >
                    <div id={`${item}`}>{item}</div>
                  </div>
                );
              }
            })}
          </div>
          {/* {diff < 6 &&
                        <div style={{ flex: "auto" }}></div>
                    } */}
          {startIdx < product.length && endIdx < product.length - 1 && (
            <div className="wnfy-select-nav-button-wrapper">
              <div
                className="wnfy-result-navigate-arrow"
                onClick={(e) => this.handleOnClickRight()}
              >
                <img src={rightSideArrow} alt="" />
              </div>
            </div>
          )}
        </>
      );
    }
  };
  handleSelectProduct = (event) => {
    event.preventDefault();
    if (event) {
      this.setState({
        selectedProduct: event.target.id,
      });
    }
  };
  handleOnClickRight = () => {
    if (this.props.customWidth <= 1360 && this.props.customWidth >= 1200) {
      this.setState({ startIndex: this.state.startIndex + 6 });
    } else if (
      this.props.customWidth <= 1200 &&
      this.props.customWidth >= 1000
    ) {
      this.setState({ startIndex: this.state.startIndex + 5 });
    } else if (
      this.props.customWidth <= 1000 &&
      this.props.customWidth >= 750
    ) {
      this.setState({ startIndex: this.state.startIndex + 4 });
    } else if (this.props.customWidth <= 750 && this.props.customWidth >= 550) {
      this.setState({ startIndex: this.state.startIndex + 3 });
    } else if (this.props.customWidth <= 550 && this.props.customWidth >= 450) {
      this.setState({ startIndex: this.state.startIndex + 2 });
    } else if (this.props.customWidth <= 450) {
      this.setState({ startIndex: this.state.startIndex + 1 });
    } else {
      this.setState({ startIndex: this.state.startIndex + 7 });
    }
    // this.setState({
    //     startIndex: this.state.startIndex + 7
    // })
  };
  handleOnClickLeft = () => {
    if (this.props.customWidth <= 1360 && this.props.customWidth >= 1200) {
      this.setState({ startIndex: this.state.startIndex - 6 });
    } else if (
      this.props.customWidth <= 1200 &&
      this.props.customWidth >= 1000
    ) {
      this.setState({ startIndex: this.state.startIndex - 5 });
    } else if (
      this.props.customWidth <= 1000 &&
      this.props.customWidth >= 750
    ) {
      this.setState({ startIndex: this.state.startIndex - 4 });
    } else if (this.props.customWidth <= 750 && this.props.customWidth >= 550) {
      this.setState({ startIndex: this.state.startIndex - 3 });
    } else if (this.props.customWidth <= 550 && this.props.customWidth >= 450) {
      this.setState({ startIndex: this.state.startIndex - 2 });
    } else if (this.props.customWidth <= 450) {
      this.setState({ startIndex: this.state.startIndex - 1 });
    } else {
      this.setState({ startIndex: this.state.startIndex - 7 });
    }
    // this.setState({
    //     startIndex: this.state.startIndex - 7
    // })
  };
  productList = () => {
    let startIdx = this.state.startIndex;
    // let endIdx = startIdx + 6;
    let endIdx;
    if (this.props.customWidth <= 1360 && this.props.customWidth >= 1200) {
      endIdx = startIdx + 5;
    } else if (
      this.props.customWidth <= 1200 &&
      this.props.customWidth >= 1000
    ) {
      endIdx = startIdx + 4;
    } else if (
      this.props.customWidth <= 1000 &&
      this.props.customWidth >= 750
    ) {
      endIdx = startIdx + 3;
    } else if (this.props.customWidth <= 750 && this.props.customWidth >= 550) {
      endIdx = startIdx + 2;
    } else if (this.props.customWidth <= 550 && this.props.customWidth >= 450) {
      endIdx = startIdx + 1;
    } else if (this.props.customWidth <= 450) {
      endIdx = startIdx + 0;
    } else {
      endIdx = startIdx + 6;
    }
    return (
      <div className="cs-product-wrapper">
        <div className="cs-product-inner-wrapper">
          {this.selectProduct(this.state.products, startIdx, endIdx)}
        </div>
      </div>
    );
  };
  generateListOfDocTypes = (arrayOfDoc) => {
    if (arrayOfDoc.length > 0) {
      return arrayOfDoc.map((items, index) => {
        let url;
        if (this.state.selectedProduct !== "") {
          url =
            `/results?query=${this.state.selectedProduct}&q=All%20Fields&filters=` +
            items.filter +
            `&f1=tempJNJDrugs&fval1=${this.state.selectedProduct}&fcount=1&cspath=Submission-Related and Clinical Safety Documents-${items.title}&csproduct=${this.state.selectedProduct}`;
        } else {
          url =
            `/results?query=${this.state.selectedProduct}&q=All%20Fields&filters=` +
            items.filter;
        }
        return (
          <li key={index} className="cs-modalListing">
            <Link
              to={url}
              target="_blank"
              className="cs-modalLink"
              onClick={() =>
                this.handelDocsTypesLinks(items.filter, items.title)
              }
            >
              {items.title}
            </Link>
          </li>
        );
      });
    }
  };
  handelDocsTypesLinks = (filter, title) => {
    const ts = new trackingService();
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      localStorage.getItem("companyname"),
      // "Link visited from Submission-Related and Clinical Safety Documents tile",
      `Visited ${this.state.selectedProduct} for ${title}`,
      "",
      window.location.href,
      this.state.selectedProduct,
      filter,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      `Submission-Related and Clinical Safety Documents-${title}`,
      this.state.selectedProduct
    );
  };
  onMouseEnterInfo = () => {
    this.setState({
      showInfo: true,
    });
  };
  onMouseLeaveInfo = () => {
    this.setState({
      showInfo: false,
    });
  };
  showDetails = () => {
    const ts = new trackingService();
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      localStorage.getItem("companyname"),
      "Expanded Submission-Related and Clinical Safety Documents tile under content shortcuts",
      "",
      window.location.href
      //   this.state.searchKeyword,
      //   this.props.appliedFilters
    );
    this.setState({ showCSModal: !this.state.showCSModal }, () => {
      this.props.handelSRnCSCardDetail(this.state.showCSModal);
    });
  };
  handleCloseModal = () => {
    this.setState({ showCSModal: false }, () => {
      this.props.handelSRnCSCardDetail(this.state.showCSModal);
    });
  };
  render() {
    var privacyHtml = `
        <div class="cs-modalNote">
            <p>PLEASE NOTE: One or more of the categories listed may retrieve zero results for the product you selected. This is because 1) content within those categories has not yet been made available for that product, or 2) the content is restricted/firewalled due to your Location and/or Function.</p>
        </div>`;
    return (
      <div className="contentShortcutInnerWapper">
        <div
          className="cardContainer"
          onClick={this.showDetails}
          style={{
            borderTop: this.state.showCSModal ? "5px solid #0074B0" : "none",
          }}
        >
          <div
            className="cardInnerWapper"
            style={this.state.showCSModal ? { borderBottom: "none" } : {}}
          >
            <div
              className="card-leftPart cs-middle-r2-tile-"
              style={
                this.props.customWidth >= 1000
                  ? { textAlign: "center", position: "absolute", width: "31%" }
                  : {}
              }
            >
              <small>
              <span className="cs-name-bold">Submission-Related and Clinical Safety Documents</span>
                <br /> (CSRs, Protocols, IBs, RMPs, etc.)
              </small>
            </div>
            <div
              className="card-iconsPart"
              style={
                this.props.customWidth >= 1000 ? { marginLeft: "auto" } : {}
              }
            >
              <img
                src={iIcon}
                alt="i-icon"
                width={18}
                height={18}
                onMouseEnter={this.onMouseEnterInfo}
                onMouseLeave={this.onMouseLeaveInfo}
                className="cs-tile-i-icon"
              />
              <div className="card-rightPart">
                <img
                  src={rightSideArrow}
                  alt="down-arrow"
                  className="cs-downArrow"
                  style={{
                    transform: this.state.showCSModal ? "rotate(270deg)" : null,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.showInfo && (
          <div className="cs-tile-wrapper">
            <div className="cs-tooltip-uparrow"></div>
            <span className="tooltiptext cs-tooltip-bottom">
              This tile provides direct links to key submission-related
              documents (e.g., CSRs, Protocols, IBs, etc.) directly related to
              your product preferences.
            </span>
          </div>
        )}
        {this.state.showCSModal && (
          <div
            className="cs-modalInner"
            style={this.props.customWidth > 1000 ? { marginLeft: "11px" } : {}}
          >
            <div>
              <div class="popup-cancel-btn-wrapper">
                <img
                  src={popCancelBttn}
                  alt=""
                  onClick={this.handleCloseModal}
                />
              </div>
              <div>
                <div
                  className="cs-modalHeader"
                  style={{
                    justifyContent: "center",
                    display: this.state.products.length <= 0 && "none",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <div class="modal-title h4">
                      <small className="cs-modalTitle">Select Product</small>
                      {this.state.products.length > 0 && this.productList()}
                    </div>
                  </div>
                </div>
                <div
                  class="modal-body"
                  style={{ width: this.state.products.length <= 0 && "95%" }}
                >
                  <div>{convertToHtml(privacyHtml)}</div>
                  <div className="cs-modalList">
                    <ul style={{ listStyle: "none" }}>
                      {this.generateListOfDocTypes(this.state.arrayOfDocTypes)}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SubmissionRelatedandClinicalSafetyDocumentsTile;
