import { Component } from "react";
import { rightSideArrow, imsLoader, rightArrowBlue } from "../../assets";
import axios from "axios";
import { Link } from "react-router-dom";
import "./History.css";
import { LocationOrigin } from "../../utils/utilityServices";
import trackingService from "../../services/trackingService";

class RecentlyViewedSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recentlyViewedShowContent: false,
            isProcessing: true,
            recentlyViewedResult: [],
        }
    }

    async componentDidMount() {
        await this.fetchRecentlyViewed();
        let outer = document.querySelector('#rvHeaderSection')
        if (this.props.customWidth >= 600) {
            outer.addEventListener('click', event => {
                if (event.target.id === "rvHeaderSection") {
                    this.setState({ recentlyViewedShowContent: !this.state.recentlyViewedShowContent })
                }
            });
            this.setState({ recentlyViewedShowContent: true })
        }
        else {
            this.setState({ recentlyViewedShowContent: false })
        }
    }
    async fetchRecentlyViewed() {
        let wwid = JSON.parse(localStorage.getItem('wwid'))
        var url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}analytics/rv?wwid=${wwid}`
        if (this.props.customWidth > 1000) {
            url += `&size=10`
        }
        let config = {
            method: 'GET',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios(config)
            .then((response) => {
                this.setState({
                    recentlyViewedResult: response.data,
                })
            })
            .catch(function (error) {
                //console.log(error);
            })
            .finally(() => {
                this.setState({ isProcessing: false })
            })
    }
    indexData = (item) => {
        var title = item.title;
        var products = item.docproducts;
        var docnumber = item.docnumber
        var docversion = item.version;
        var contentsource = item.contentsource;
        var docID = item.docid;
        var docType = item.doctype;
        var docUrl = item.docurl;
        var doclastModified = item.lastmodified;
        if (JSON.stringify(doclastModified) === "[null]") {
            doclastModified = "";
        }
        // let params = new URLSearchParams(window.location.search)
        // let csPath = params.get('cspath');
        // let csproduct = params.get('csproduct');
        const ts = new trackingService();
        ts.indexTrackingData(
            localStorage.getItem("userdisplayName"),
            localStorage.getItem("wwid"),
            localStorage.getItem("username"),
            localStorage.getItem("function"),
            localStorage.getItem("region"),
            localStorage.getItem("userproducts"),
            localStorage.getItem("doctypepref"),
            localStorage.getItem("sid"),
            localStorage.getItem("deviceType"),
            localStorage.getItem("deviceOS"),
            localStorage.getItem("browserName"),
            localStorage.getItem("browserVersion"),
            localStorage.getItem("jnjDepartment"),
            localStorage.getItem("companyname"),
            "View OR Download Document",
            "Direct",
            "Recently Viewed Component",
            '',
            '',
            title,
            docversion,
            docnumber,
            docID,
            docUrl,
            products,
            docType,
            "View Document",
            "",
            "",
            contentsource,
            item.pagenumber,
            item.pagesize,
            doclastModified
        );
    };
    RenderRecentlyViewedList = () => {
        const { recentlyViewedResult } = this.state;
        if (recentlyViewedResult.length > 0) {
            return (
                <ul className="resultHistorysul">
                    {
                        recentlyViewedResult.map((item, index) => {
                            return (
                                <li key={`RecentlyViewed-${index}`} className="resultHistorysli">
                                    {(item.docurl && item.docurl.startsWith('https://jnj.sharepoint.com/')) ? <Link title={item.title} id={`resultHistorysli-${index}`} to={{ pathname: item.docurl }} target="_blank" style={{ display: "-webkit-box", WebkitLineClamp: "1", WebkitBoxOrient: "vertical", overflow: "hidden", width: "100%", minHeight: "20px" }} onClick={() => this.indexData(item)}>
                                        {item.title}
                                    </Link>
                                        :
                                        <Link title={item.title} id={`resultHistorysli-${index}`} to={item.docurl} target="_blank" style={{ display: "-webkit-box", WebkitLineClamp: "1", WebkitBoxOrient: "vertical", overflow: "hidden", width: "100%", minHeight: "20px" }} onClick={() => this.indexData(item)}>
                                            {item.title}
                                        </Link>}</li>
                            )
                        })
                    }
                </ul>
            );
        } else {
            return (
                <ul className="resultHistorysul">
                    <li className="resultHistorysli">It seems you haven't viewed any documents recently.</li>
                </ul>
            )
        }
    }
    handleRVnQHMobile = () => {
        this.setState({ recentlyViewedShowContent: !this.state.recentlyViewedShowContent })
    }
    render() {
        if (this.props.customWidth < 600) {
            return (
                <div className="wnfy-outer-wrapper">
                    <div className="wnfy-inner-wrapper">
                        <div className="rv-qh-header-section-mobile" onClick={this.handleRVnQHMobile} /* onClick={e => { this.setState({ recentlyViewedShowContent: !this.state.recentlyViewedShowContent }) }} */>
                            <span>
                                <div className="wnfy-main-header">Recently Viewed &nbsp;&nbsp;</div>
                            </span>
                            <span className="wnfy-expand-wrapper">
                                {this.state.recentlyViewedShowContent ?
                                    <img src={rightArrowBlue} alt="" width={10} height={10} style={{ transform: "rotate(-90deg)" }} />
                                    :
                                    <img src={rightArrowBlue} alt="" width={10} height={10} style={{ transform: "rotate(90deg)" }} />
                                }
                            </span>
                        </div>
                        <>
                            {this.state.recentlyViewedShowContent &&
                                <div className="rv-qh-content-section">
                                    {!this.state.isProcessing ?
                                        this.RenderRecentlyViewedList() :
                                        <span className="wnfy-content-loader">
                                            <img src={imsLoader} width="25" height="25" alt="" />
                                        </span>
                                    }
                                </div>
                            }
                        </>
                    </div>
                </div>
            )
        } else if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
            return (
                <>
                    <div className="rv-outer-wrapper">
                        <div className="rv-qh-inner-wrapper">
                            <div id="rvHeaderSection" className="rv-qh-header-section" /* onClick={e => { this.setState({ recentlyViewedShowContent: !this.state.recentlyViewedShowContent }) }} */>
                                <span className="wnfy-main-header">Recently Viewed &nbsp;&nbsp;</span>
                                <span className='rv-view-more-wrapper'>
                                    {!this.state.recentlyViewedShowContent ?
                                        <img src={rightSideArrow} alt="" width={11} height={14} style={{ transform: "rotate(90deg)" }} onClick={e => { this.setState({ recentlyViewedShowContent: true }) }} />
                                        :
                                        <img src={rightSideArrow} alt="" width={11} height={14} style={{ transform: "rotate(-90deg)" }} onClick={e => { this.setState({ recentlyViewedShowContent: false }) }} />
                                    }
                                </span>
                            </div>
                            {this.state.recentlyViewedShowContent &&
                                <div className="rv-qh-content-section">
                                    {!this.state.isProcessing ?
                                        this.RenderRecentlyViewedList() :
                                        <span className="wnfy-content-loader">
                                            <img src={imsLoader} width="25" height="25" alt="" />
                                        </span>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </>
            )
        }
        else if (this.props.customWidth > 1000) {
            return (
                <>
                    <div className="rv-outer-wrapper">
                        <div className="rv-qh-inner-wrapper">
                            <div id="rvHeaderSection" className="rv-qh-header-section-desktop" /* onClick={e => { this.setState({ recentlyViewedShowContent: !this.state.recentlyViewedShowContent }) }} */>
                                <span className="wnfy-main-header-desktop">Recently Viewed &nbsp;&nbsp;</span>
                                <span className='rv-view-more-wrapper'>
                                    {/* {!this.state.recentlyViewedShowContent ?
                                        <img src={rightSideArrow} alt="" width={11} height={14} style={{ transform: "rotate(90deg)" }} onClick={e => { this.setState({ recentlyViewedShowContent: true }) }} />
                                        :
                                        <img src={rightSideArrow} alt="" width={11} height={14} style={{ transform: "rotate(-90deg)" }} onClick={e => { this.setState({ recentlyViewedShowContent: false }) }} />
                                    } */}
                                </span>
                            </div>
                            {this.state.recentlyViewedShowContent &&
                                <div className="rv-qh-content-section">
                                    {!this.state.isProcessing ?
                                        this.RenderRecentlyViewedList() :
                                        <span className="wnfy-content-loader">
                                            <img src={imsLoader} width="25" height="25" alt="" />
                                        </span>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </>
            )
        }
    }
}
export default RecentlyViewedSection;