import { Component } from "react";
import { Modal } from "react-bootstrap";
import { popCancelBttn } from "../../assets";
import Button from "../Button";
import './GlobalSearchFeaturesPopUp.css'
import './PinToReadingListPopUp.css'
import ApiServices from "../../utils/apiServices";
import { snippetLongString } from "../../utils/utilityServices";
import AlertMessagePopUpModal from "./AlertMessagePopUpModal";
class RenameCommandNamePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cName: '',
            cID: '',
            showSuccess: false,
            showPop: false,
            error: '',
            alertMessageModelColor: "",
            alertMessageContent: "",
            alertMessageShowModal: false,
            alertMessageIsError: false
        }
    }
    componentDidMount() {
        this.setState({
            showPop: this.props.showModal,
            cName: this.props.cName,
            cID: this.props.cID
        });
    }
    successAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#00c12c",
            alertMessageShowModal: true,
        })
    }
    errorAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#de1327",
            alertMessageShowModal: true,
            alertMessageIsError: true
        })
    }
    closeAlertMessage = () => {
        const { alertMessageIsError, showPop } = this.state;
        this.setState({
            alertMessageContent: "",
            alertMessageModelColor: "",
            showPop: alertMessageIsError ? showPop : false,
            alertMessageShowModal: false,
            alertMessageIsError: false,
        })
        if (!alertMessageIsError) {
            this.props.handleClose(false)
        }
    }

    handleSuccessClose = (status) => {
        this.setState({
            showSuccess: status
        })
    }
    handleSave = async () => {
        if (this.handleValidation()) {
            let service = new ApiServices();
            let res = await service.updateCommandName(this.state.cID, this.state.cName);
            if (res) {
                this.successAlertMessage('Command name saved successfully.');
            }
        }
    }
    handleInput = (e) => {
        e.target.value = snippetLongString(e.target.value);
        this.setState({
            cName: e.target.value
        })
    }
    handleValidation() {
        let errors = {};
        let formIsValid = true;
        let commandlist = this.props.apiResponse.filter(x => x.id !== this.state.cID).map(data => data.command_name.toLowerCase())
        if (this.state.cName.length <= 0) {
            formIsValid = false;
            errors["pinCommandList"] = `Please enter a valid name to proceed.`;
        }
        if (commandlist.includes(this.state.cName.toLowerCase())) {
            formIsValid = false;
            errors["pinCommandList"] = `You already have a Command with entered title. Please enter a new name.`;
        }
        this.setState({ error: errors });
        return formIsValid;
    }
    render() {
        const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
        if (this.props.width <= 1000) {
            return (
                <>
                    <Modal
                        show={this.state.showPop}
                        size="lg md sm xs"
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        dialogClassName="popup-transition"
                        className="z-index-rename"
                        backdropClassName="z-index-rename-backdrop"
                        scrollable={true}
                    >
                        <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
                            <div>
                                <Modal.Header className="gsfModalHeader" style={{ justifyContent: "left", padding: "0.5rem 0" }}>
                                    <div style={{ textAlign: "center", width: "100%", paddingTop: "1%" }}>
                                        <Modal.Title>
                                            <div className="actionItem-headerPart">
                                                Rename Command
                                            </div>
                                        </Modal.Title>
                                    </div>
                                </Modal.Header>
                                <Modal.Body scrollable={true} style={{ minHeight: "25vh" }}>
                                    <table className="reading-list-view-wrapper">
                                        <tr><th>Command Name</th><th style={{ textAlign: "center" }}>Actions</th></tr>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "60%" }}><input type="text" value={this.state.cName} onChange={this.handleInput} /><br /><div className="errorInvalidName">{this.state.error["pinCommandList"]}</div></td>
                                                <td style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", width: "100%" }}><span onClick={e => this.handleOnClickView(this.props.cID, this.props.cName)} title="View" style={{ marginRight: "5px" }} className="reading-list-action-text">View</span><span className="action-items-separate-divider"></span><span title="Save" onClick={e => this.handleSave()} className="reading-list-action-text">Save</span></td>
                                            </tr>
                                            <span className="length-info">Name with a maximum of 100 characters.</span>
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <div className="reading-list-button-tablet-wrapper">
                                    <Button text="Cancel" customColor="#0074B0" action={() => {
                                        this.setState({ showPop: false });
                                        this.props.handleClose(false);
                                    }} />
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
                </>
            )
        }
        else {
            return (
                <>
                    <Modal
                        show={this.state.showPop}
                        size="lg md sm xs"
                        centered
                        aria-labelledby="contained-modal-title-vcenter"
                        dialogClassName="popup-transition"
                        className="z-index-rename"
                        backdropClassName="z-index-rename-backdrop"
                        scrollable={true}
                    >
                        <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
                            <div className="popup-cancel-btn-wrapper">
                                <img
                                    src={popCancelBttn}
                                    alt=""
                                    onClick={e => {
                                        this.setState({ showPop: false });
                                        this.props.handleClose(false);
                                    }}
                                />
                            </div>
                            <div>
                                <Modal.Header className="gsfModalHeader" style={{ justifyContent: "left" }}>
                                    <div style={{ textAlign: "center", width: "30%", paddingTop: "1%" }}>
                                        <Modal.Title>
                                            <div className="actionItem-headerPart">
                                                Rename Command
                                            </div>
                                        </Modal.Title>
                                    </div>
                                </Modal.Header>
                                <Modal.Body scrollable={true} style={{ minHeight: "25vh" }}>
                                    <table className="reading-list-view-wrapper">
                                        <tr><th>Command Name</th><th style={{ textAlign: "center" }}>Actions</th></tr>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "60%" }}><input type="text" value={this.state.cName} onChange={this.handleInput} /><br /><div className="errorInvalidName">{this.state.error["pinCommandList"]}</div></td>
                                                <td style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", width: "100%" }}>
                                                   
                                                <span title="Save" onClick={e => this.handleSave()} className="reading-list-action-text">Save</span></td>
                                            </tr>
                                            <span className="length-info">Name with a maximum of 100 characters.</span>
                                        </tbody>
                                    </table>
                                </Modal.Body>
                                <div className="reading-list-button-wrapper">
                                    <Button text="Cancel" customColor="#0074B0" action={() => {
                                        this.setState({ showPop: false });
                                        this.props.handleClose(false);
                                    }} />
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
                </>
            )
        }
    }
}
export default RenameCommandNamePopup;