/* eslint-disable react/jsx-no-target-blank */
import { Component } from "react";
import HeaderWithBanner from "../../components/header/HeaderWithBanner";
import Footer from '../../components/footer/Footer';
import { imsLoader } from '../../assets'
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import NavigationMenu from "../../components/Navigation/NavigationMenu";
import Services from "../../utils/services";
import GenerateArrayStrings, { getVarType } from "../../components/GenerateArrayStrings"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator'
import '../../../node_modules/react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import '../../../node_modules/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import './ExcelView.css';
import { ExportCSV } from "../../components/ExportToCsv";
import { withRouter } from 'react-router-dom';
import moment from 'moment';

class ExcelView extends Component {
    userid;
    userExists;
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            userExists: false,
            width: window.innerWidth,
            height: window.innerHeight,
            selectedResults: [],
            selectedRawDataResults: [],
            columns: [
                {
                    dataField: 'Sl No',
                    text: 'Sl No.'
                },
                {
                    dataField: 'Authors',
                    text: 'Authors'
                },
                {
                    dataField: 'Owner',
                    text: 'Owner'
                },
                {
                    dataField: 'Title',
                    text: 'Title'
                },
                {
                    dataField: 'Link',
                    text: 'Link to Document',
                    formatter: (cell, row, rowIndex, extraData) => (
                        <div>
                            <a href={row.Link} target="_blank" style={{ textDecoration: "none", color: "#1076bc" }}> Link </a>
                        </div>
                    )
                },
                {
                    dataField: 'Document Type',
                    text: 'Document Type'
                },
                {
                    dataField: 'Document Number',
                    text: 'Document Number'
                },
                {
                    dataField: 'Product',
                    text: 'Product'
                },
                {
                    dataField: 'MSL Therapeutic Area',
                    text: 'MSL Therapeutic Area'
                },
                {
                    dataField: 'Source',
                    text: 'Source'
                },
                {
                    dataField: 'Congress',
                    text: 'Congress'
                },
                {
                    dataField: 'Protocol ID',
                    text: 'Protocol ID'
                },
                {
                    dataField: 'Language',
                    text: 'Language'
                },
                {
                    dataField: 'Phase Of Study',
                    text: 'Phase Of Study'
                },
                {
                    dataField: 'Country',
                    text: 'Country'
                },
                {
                    dataField: 'Version',
                    text: 'Version'
                },
                {
                    dataField: 'Date',
                    text: 'Date'
                },
                {
                    dataField: 'Last Updated',
                    text: 'Last Updated'
                },
                {
                    dataField: 'Issued Date',
                    text: 'Issued Date'
                },
                {
                    dataField: 'Modified Date',
                    text: 'Modified Date'
                },
                {
                    dataField: 'PubMed ID',
                    text: 'PubMed ID'
                },
                {
                    dataField: 'Content Source',
                    text: 'Content Source'
                },
                {
                    dataField: 'Key Takeaways',
                    text: 'Key Takeaways'
                },
                {
                    dataField: 'Clinical Trial Name',
                    text: 'Clinical Trial Name'
                }
            ]
        }
    }
    componentDidMount() {
        this.userid = JSON.parse(localStorage.getItem('username'));
        this.userExists = localStorage.getItem('userExists');
        if (this.userExists) {
            this.setState({
                userExists: this.userExists
            })
            let citationType = "", _selectedResults = [];
            if (JSON.parse(localStorage.getItem('ExcelViewSaveResult'))) {
                citationType = JSON.parse(localStorage.getItem('ExcelViewSaveResult'));
            } else if (window.citationType) {
                citationType = window.citationType;
            }

            if (JSON.parse(localStorage.getItem('ExcelViewSelectedResults'))) {
                _selectedResults = JSON.parse(localStorage.getItem('ExcelViewSelectedResults'));
            } else if (window.selectedResults) {
                _selectedResults = window.selectedResults;
            }
            if (citationType === 'Marked Results') {
                this.generateCustomResultsMap(_selectedResults)
            }
            else {
                JSON.parse(localStorage.getItem('displayedResults')) &&
                    this.generateCustomResultsMap(JSON.parse(localStorage.getItem('displayedResults')))
            }
            window.addEventListener('resize', this.updateDimensions);
        }
        else {
            console.log('Access Denied');
            this.props.history.push('/');
        }
        // const { selectedResults } = this.props.match.params;

    }
    URLConstructor = (item) => {
        let url = window.origin
        if (item.domain === 'RIMdocs' || item.domain === 'RIMdocs_LL' || item.domain === 'CTGov') {
            url += `/DocURL?ID=${item.docID}&ref=excel&cs=${item.domain}`
            // url+=`/DocURL?ID=${item.docTitle}&ref=excel&cs=${item.domain}`
        }
        else if (item.domain === 'SKM' || item.domain === 'IROs' || item.domain === "MIAs") {
            url += `/DocURL?ID=${item.docID}&ref=excel&cs=${item.domain}&connID=${item.connID}&csID=${item.csID}&fileType=${item.fileExtension}&fileName=${item.docTitle}`
        }
        else {
            url += `/DocURL?ID=${item.docID}&ref=excel&cs=${item.domain}&connID=${item.connID}&csID=${item.csID}&fileType=${item.fileExtension}&docVer=${item.majorVersionNumber}&fileName=${item.docTitle}`
            if (item.a_status === "Approved Needs Review" || item.status === "Approved Needs Review") {
                url = url + `&docState=anr`
            }
        }
        return url
    }
    generateCustomResultsMap = (results) => {
        var modifiedResults = [];
        results.map((item, index) => {
            modifiedResults.push({
                'Sl No': index + 1,
                // 'Sl No' : index+1,
                Authors: (item.detail.authors && item.detail.authors.length > 0) ? getVarType(item.detail.authors) === 'Array' ? item.detail.authors.join("; ") : item.detail.authors : "",
                Owner: item.detail.owner === "Migration Specialist" ? "" : item.detail.owner,
                Title: item.detail.docTitle,
                'Link': this.URLConstructor(item.detail),
                'Document Type': item.detail.docType !== undefined && item.detail.docType.length > 0 ? GenerateArrayStrings(item.detail.docType) : [],
                'Document Number': item.detail.documentNumber,
                Product: item.detail.product,
                // 'MSL Therapeutic Area': item.detail.mslTherapeuticArea !== undefined &&  item.detail.mslTherapeuticArea.length > 0 ? GenerateArrayStrings(item.detail.mslTherapeuticArea) : [],
                'MSL Therapeutic Area': item.detail.mslTherapeuticArea !== undefined && item.detail.mslTherapeuticArea.length > 0 ? item.detail.mslTherapeuticArea : '',
                Source: item.detail.domain,
                Congress: item.detail.congressName !== undefined && item.detail.congressName.length > 0 ? GenerateArrayStrings(item.detail.congressName) : [],
                'Protocol ID': item.detail.protocolIDs !== undefined && item.detail.protocolIDs.length > 0 ? GenerateArrayStrings(item.detail.protocolIDs) : [],
                'Phase Of Study': item.detail.phaseOfStudy !== undefined && item.detail.phaseOfStudy.length > 0 ? GenerateArrayStrings(item.detail.phaseOfStudy) : [],
                Language: item.detail.languages,
                Country: item.detail.countries !== undefined && item.detail.countries.length > 0 ? GenerateArrayStrings(item.detail.countries) : [],
                Version: item.detail.majorVersionNumber,
                Date: item.detail.year,
                'Last Updated': item.detail.lastUpdated !== undefined ? moment(item.detail.lastUpdated).format("D-MMM-YYYY") : "",
                'Issued Date': item.detail.approvedDate !== undefined ? moment(item.detail.approvedDate).format("D-MMM-YYYY") : "",
                'Modified Date': item.detail.modifiedDate !== undefined ? moment(item.detail.modifiedDate).format("D-MMM-YYYY") : "",
                'PubMed ID': item.detail.pmID,
                'Content Source': item.detail.csName,
                'Key Takeaways': item.detail.keyTakeaways,
                'Clinical Trial Name': item.detail.ctNames !== undefined && item.detail.ctNames.length > 0 ? item.detail.ctNames.join("; ") : "",
            })
            return null;
        })
        this.setState({
            selectedResults: modifiedResults,
            selectedRawDataResults: results
        })
    }
    componentWillUnmount() {
        let login = new Services();
        login.logService(`Excel View PAge successfully unmounted for ${this.userid.toLowerCase()}`, 'info');
        window.removeEventListener('resize', this.updateDimensions);
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight })
    }
    customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} Results
        </span>
    );
    generateDownloadTimestamp = () => {
        const now = new Date()
        return now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate() + "_" + now.getHours() + "-" + now.getMinutes() + "-" + now.getSeconds()
    }
    render() {
        const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: this.customTotal,
            disablePageTitle: true,
            totalSize: this.state.selectedResults.length,
            sizePerPageList: [{
                text: '5', value: 5
            }, {
                text: '10', value: 10
            }, {
                text: 'All', value: this.state.selectedResults.length
            }] // A numeric array is also available. the purpose of above example is custom the text
        };
        return (
            <div>
                <ErrorBoundary>
                    {this.state.userExists ? <>
                        <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                        <div className="header-wrapper">
                            <HeaderWithBanner />
                        </div>
                        <div className="admin-data-table-wrapper excel-data-table-wrapper">
                            <div className="excel-data-function-button-wrapper">
                                <ExportCSV csvData={this.state.selectedResults} selectedResults={this.state.selectedRawDataResults} fileName={"iMS_Results_Download_" + this.generateDownloadTimestamp()} />
                            </div>
                            <div>
                                <BootstrapTable
                                    keyField='index'
                                    data={this.state.selectedResults}
                                    columns={this.state.columns}
                                    bordered={true}
                                    pagination={paginationFactory(options)} wrapperClasses="table-responsive" rowClasses="text-nowrap">
                                </BootstrapTable>
                            </div>
                        </div>
                        <div style={{ margin: "0 1.5%" }}>
                            <Footer customWidth={this.state.width} customHeight={this.state.height} />
                        </div></>
                        :
                        <div style={{ textAlign: "center", padding: "10%" }}>
                            <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
                        </div>
                    }
                </ErrorBoundary>
            </div>

        )
    }
}
export default withRouter(ExcelView);