/* eslint-disable array-callback-return */
import { Component } from "react";
import HeaderWithBanner from "../../components/header/HeaderWithBanner";
import Footer from "../../components/footer/Footer";
import {
  imsLoader,
  dropdownArrowBlack,
  addIconWithBlueBorder,
  rightArrowBlue,
  crossIcon,
} from "../../assets";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import NavigationMenu from "../../components/Navigation/NavigationMenu";
import "./FieldedSearch.css";
import Services from "../../utils/services";
import Button from "../../components/Button";
import trackingService from "../../services/trackingService";
import moment from "moment";

class FieldedSearch extends Component {
  userid;
  userExists;
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      userExists: false,
      searchTerm: "",
      dropdownVal1: "Full Text",
      dropdownVal2: "Author",
      dropdownVal3: "Title",
      dropdownVal4: "Major Topics",
      fieldedVal1: "",
      fieldedVal2: "",
      fieldedVal3: "",
      fieldedVal4: "",
      booleanVal1: "And",
      booleanVal2: "And",
      booleanVal3: "And",
      showBooleanDropdown1: false,
      showBooleanDropdown2: false,
      showBooleanDropdown3: false,
      showField1: false,
      showField2: false,
      showField3: false,
      showField4: false,
      showField: false,
      showPublicationYearTo: false,
      showPublicationYearFrom: false,
      showCongressName: false,
      showDocumentType: false,
      showJournalName: false,
      showGenericName: false,
      showProtocolNumber: false,
      pyValFrom: "",
      pyValTo: "",
      pnVal: "",
      jnVal: "",
      dtVal: "",
      gnVal: "",
      cnVal: "",
      dropdownParams: ["py", "pn", "jn", "dt", "gn", "cn"],
      dropdownValArr: [],
      count: 1,
      width: window.innerWidth,
      height: window.innerHeight,
      showLimitsInMobile: false,
    };
  }
  async componentDidMount() {
    document.title = "Fielded Search";
    this.userid = JSON.parse(localStorage.getItem("username"));
    this.userExists = localStorage.getItem("userExists");
    let service = new Services();
    service.logService(
      `Advanced search page successfully mounted for ${this.userid.toLowerCase()}`,
      "info"
    );
    var dropdownVal = [];
    if (this.userExists) {
      this.setState({
        userExists: this.userExists,
      });
      for (let i = 0; i < this.state.dropdownParams.length; i++) {
        if (this.state.dropdownParams[i] === "py") {
          let res = this.getYearLimiDropdown();
          dropdownVal[i] = {
            id: this.state.dropdownParams[i] + "Arr",
            value: res,
          };
        } else {
          let res = await service.getLimiDropdown(this.state.dropdownParams[i]);
          dropdownVal[i] = {
            id: this.state.dropdownParams[i] + "Arr",
            value: res,
          };
        }
      }
      this.setState({
        dropdownValArr: dropdownVal,
      });
      window.addEventListener("resize", this.updateDimensions);
    } else {
      console.log("Access Denied");
      this.props.history.push("/");
    }
  }
  getYearLimiDropdown() {
    let toYear = moment().add(2, "years").year();
    let years = [];
    for (let i = toYear; i >= 1935; i--) {
      years.push(i);
    }
    return years;
  }
  componentWillUnmount() {
    let service = new Services();
    window.removeEventListener("resize", this.updateDimensions);
    service.logService(
      `Advanced search page successfully unmounted for ${this.userid.toLowerCase()}`,
      "info"
    );
  }
  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  booleanOperatorList = () => {
    return (
      <ul className="fielded-search-boolean-list-wrapper">
        <li keyvalue="And">And</li>
        <li keyvalue="Or">Or</li>
      </ul>
    );
  };
  fieldDropdownList = () => {
    return (
      <ul className="fielded-search-boolean-list-wrapper">
        <li keyvalue="Full Text">Full Text</li>
        <li keyvalue="Author">Author</li>
        <li keyvalue="Title">Title</li>
        <li keyvalue="Major Topics">Major Topics</li>
      </ul>
    );
  };
  congressNameDropdownList = (cnArr) => {
    if (this.state.cnVal.length > 0) {
      cnArr = cnArr.filter((item) =>
        item.toLowerCase().startsWith(this.state.cnVal.toLowerCase())
      );
      return (
        <ul
          className="fielded-search-limits-list-wrapper" /* style={{ width: this.state.width <= 1000 && "63%" }} */
        >
          {cnArr && cnArr.length > 0 ? (
            cnArr.map((item, index) => {
              if (
                item.toLowerCase().startsWith(this.state.cnVal.toLowerCase())
              ) {
                return (
                  <li
                    keyvalue={item}
                    onClick={(e) => {
                      this.setState({ cnVal: item, showCongressName: false });
                    }}
                    key={`cnid-${index}`}
                  >
                    {item}
                  </li>
                );
              }
            })
          ) : (
            <li style={{ color: "gray" }}>
              No results found. Clear the terms to see everything.
            </li>
          )}
        </ul>
      );
    } else {
      return (
        <ul
          className="fielded-search-limits-list-wrapper" /* style={{ width: this.state.width <= 1000 && "63%" }} */
        >
          {cnArr && cnArr.length > 0 ? (
            cnArr.map((item, index) => {
              return (
                <li
                  keyvalue={item}
                  onClick={(e) => {
                    this.setState({ cnVal: item, showCongressName: false });
                  }}
                  key={`cnid-${index}`}
                >
                  {item}
                </li>
              );
            })
          ) : (
            <li style={{ color: "gray" }}>
              No results found. Clear the terms to see everything.
            </li>
          )}
        </ul>
      );
    }
  };
  documentTypeDropdownList = (dtArr) => {
    if (this.state.dtVal.length > 0) {
      dtArr = dtArr.filter((item) =>
        item.toLowerCase().startsWith(this.state.dtVal.toLowerCase())
      );
      return (
        <ul
          className="fielded-search-limits-list-wrapper" /* style={{ width: this.state.width <= 1000 && "63%" }} */
        >
          {dtArr && dtArr.length > 0 ? (
            dtArr.map((item, index) => {
              if (
                item.toLowerCase().startsWith(this.state.dtVal.toLowerCase())
              ) {
                return (
                  <li
                    keyvalue={item}
                    onClick={(e) => {
                      this.setState({ dtVal: item, showDocumentType: false });
                    }}
                    key={`dtid-${index}`}
                  >
                    {item}
                  </li>
                );
              }
            })
          ) : (
            <li style={{ color: "gray" }}>
              No results found. Clear the terms to see everything.
            </li>
          )}
        </ul>
      );
    } else {
      return (
        <ul
          className="fielded-search-limits-list-wrapper" /* style={{ width: this.state.width <= 1000 && "63%" }} */
        >
          {dtArr && dtArr.length > 0 ? (
            dtArr.map((item, index) => {
              return (
                <li
                  keyvalue={item}
                  onClick={(e) => {
                    this.setState({ dtVal: item, showDocumentType: false });
                  }}
                  key={`dtid-${index}`}
                >
                  {item}
                </li>
              );
            })
          ) : (
            <li style={{ color: "gray" }}>
              No results found. Clear the terms to see everything.
            </li>
          )}
        </ul>
      );
    }
  };
  journalNameDropdownList = (jnArr) => {
    if (this.state.jnVal.length > 0) {
      jnArr = jnArr.filter((item) =>
        item.toLowerCase().startsWith(this.state.jnVal.toLowerCase())
      );
      return (
        <ul
          className="fielded-search-limits-list-wrapper" /* style={{ width: this.state.width <= 1000 && "63%" }} */
        >
          {jnArr && jnArr.length > 0 ? (
            jnArr.map((item, index) => {
              if (
                item.toLowerCase().startsWith(this.state.jnVal.toLowerCase())
              ) {
                return (
                  <li
                    keyvalue={item}
                    onClick={(e) => {
                      this.setState({ jnVal: item, showJournalName: false });
                    }}
                    key={`jnid-${index}`}
                  >
                    {item}
                  </li>
                );
              }
            })
          ) : (
            <li style={{ color: "gray" }}>
              No results found. Clear the terms to see everything.
            </li>
          )}
        </ul>
      );
    } else {
      return (
        <ul
          className="fielded-search-limits-list-wrapper" /* style={{ width: this.state.width <= 1000 && "63%" }} */
        >
          {jnArr && jnArr.length > 0 ? (
            jnArr.map((item, index) => {
              return (
                <li
                  keyvalue={item}
                  onClick={(e) => {
                    this.setState({ jnVal: item, showJournalName: false });
                  }}
                  key={`jnid-${index}`}
                >
                  {item}
                </li>
              );
            })
          ) : (
            <li style={{ color: "gray" }}>
              No results found. Clear the terms to see everything.
            </li>
          )}
        </ul>
      );
    }
  };
  genericNameDropdownList = (gnArr) => {
    if (this.state.gnVal.length > 0) {
      gnArr = gnArr.filter((item) =>
        item.toLowerCase().startsWith(this.state.gnVal.toLowerCase())
      );
      return (
        <ul
          className="fielded-search-limits-list-wrapper" /* style={{ width: this.state.width <= 1000 && "63%" }} */
        >
          {gnArr && gnArr.length > 0 ? (
            gnArr.map((item, index) => {
              if (
                item.toLowerCase().startsWith(this.state.gnVal.toLowerCase())
              ) {
                return (
                  <li
                    keyvalue={item}
                    onClick={(e) => {
                      this.setState({ gnVal: item, showGenericName: false });
                    }}
                    key={`gnid-${index}`}
                  >
                    {item}
                  </li>
                );
              }
            })
          ) : (
            <li style={{ color: "gray" }}>
              No results found. Clear the terms to see everything.
            </li>
          )}
        </ul>
      );
    } else {
      return (
        <ul
          className="fielded-search-limits-list-wrapper" /* style={{ width: this.state.width <= 1000 && "63%" }} */
        >
          {gnArr && gnArr.length > 0 ? (
            gnArr.map((item, index) => {
              return (
                <li
                  keyvalue={item}
                  onClick={(e) => {
                    this.setState({ gnVal: item, showGenericName: false });
                  }}
                  key={`gnid-${index}`}
                >
                  {item}
                </li>
              );
            })
          ) : (
            <li style={{ color: "gray" }}>
              No results found. Clear the terms to see everything.
            </li>
          )}
        </ul>
      );
    }
  };
  protocolNumberDropdownList = (pnArr) => {
    if (this.state.pnVal.length > 0) {
      pnArr = pnArr.filter((item) =>
        item.toLowerCase().startsWith(this.state.pnVal.toLowerCase())
      );
      return (
        <ul
          className={
            this.state.width > 1000
              ? "fielded-search-limits-list-wrapper fielded-search-limits-list-wrapper-protocol"
              : "fielded-search-limits-list-wrapper"
          } /* style={{ width: this.state.width <= 1000 && "63%" }} */
        >
          {pnArr && pnArr.length > 0 ? (
            pnArr.map((item, index) => {
              if (
                item.toLowerCase().startsWith(this.state.pnVal.toLowerCase())
              ) {
                return (
                  <li
                    keyvalue={item}
                    onClick={(e) => {
                      this.setState({ pnVal: item, showProtocolNumber: false });
                    }}
                    key={`pnid-${index}`}
                  >
                    {item}
                  </li>
                );
              }
            })
          ) : (
            <li style={{ color: "gray" }}>
              No results found. Clear the terms to see everything.
            </li>
          )}
        </ul>
      );
    } else {
      return (
        <ul
          className={
            this.state.width > 1000
              ? "fielded-search-limits-list-wrapper fielded-search-limits-list-wrapper-protocol"
              : "fielded-search-limits-list-wrapper"
          } /* style={{ width: this.state.width <= 1000 && "63%" }} */
        >
          {pnArr && pnArr.length > 0 ? (
            pnArr.map((item, index) => {
              return (
                <li
                  keyvalue={item}
                  onClick={(e) => {
                    this.setState({ pnVal: item, showProtocolNumber: false });
                  }}
                  key={`pnid-${index}`}
                >
                  {item}
                </li>
              );
            })
          ) : (
            <li style={{ color: "gray" }}>
              No results found. Clear the terms to see everything.
            </li>
          )}
        </ul>
      );
    }
  };
  publicationYearDropdownListFrom = (pyArr) => {
    if (this.state.width <= 600) {
      if (this.state.pyValFrom.length > 0) {
        //pyArr = pyArr.filter((item) => item.toLowerCase().startsWith(this.state.pyValFrom.toLowerCase()));
        return (
          <ul className="fielded-search-py-limits-list-wrapper-mobile">
            <li
              keyvalue=""
              style={{ height: "15px" }}
              onClick={(e) => {
                this.setState({
                  pyValFrom: "",
                  showPublicationYearFrom: false,
                });
              }}
            ></li>
            {pyArr && pyArr.length > 0 ? (
              pyArr.map((item, index) => {
                //if (item.startsWith(this.state.pyValFrom)) {
                return (
                  <li
                    keyvalue={item}
                    onClick={(e) => {
                      this.setState({
                        pyValFrom: item,
                        showPublicationYearFrom: false,
                      });
                    }}
                    key={`pyFromid-${index}`}
                  >
                    {item}
                  </li>
                );
                //}
              })
            ) : (
              <li style={{ color: "gray" }}>
                No results found. Clear the terms to see everything.
              </li>
            )}
          </ul>
        );
      } else {
        return (
          <ul className="fielded-search-py-limits-list-wrapper-mobile">
            <li
              keyvalue=""
              style={{ height: "15px" }}
              onClick={(e) => {
                this.setState({
                  pyValFrom: "",
                  showPublicationYearFrom: false,
                });
              }}
            ></li>
            {pyArr && pyArr.length > 0 ? (
              pyArr.map((item, index) => {
                return (
                  <li
                    keyvalue={item}
                    onClick={(e) => {
                      this.setState({
                        pyValFrom: item,
                        showPublicationYearFrom: false,
                      });
                    }}
                    key={`pyFromid-${index}`}
                  >
                    {item}
                  </li>
                );
              })
            ) : (
              <li style={{ color: "gray" }}>
                No results found. Clear the terms to see everything.
              </li>
            )}
          </ul>
        );
      }
    } else {
      if (this.state.pyValFrom.length > 0) {
        //pyArr = pyArr.filter((item) => item.toLowerCase().startsWith(this.state.pyValFrom.toLowerCase()));
        return (
          <ul
            className="fielded-search-py-limits-list-wrapper"
            style={{
              width: this.state.width <= 1000 && "19%",
              marginLeft: this.state.width <= 1000 && "5%",
            }}
          >
            <li
              keyvalue=""
              style={{ height: "15px" }}
              onClick={(e) => {
                this.setState({
                  pyValFrom: "",
                  showPublicationYearFrom: false,
                });
              }}
            ></li>
            {pyArr && pyArr.length > 0 ? (
              pyArr.map((item, index) => {
                //if (item.startsWith(this.state.pyValFrom)) {
                return (
                  <li
                    keyvalue={item}
                    onClick={(e) => {
                      this.setState({
                        pyValFrom: item,
                        showPublicationYearFrom: false,
                      });
                    }}
                    key={`pyFromid-${index}`}
                  >
                    {item}
                  </li>
                );
                //}
              })
            ) : (
              <li style={{ color: "gray" }}>
                No results found. Clear the terms to see everything.
              </li>
            )}
          </ul>
        );
      } else {
        return (
          <ul
            className="fielded-search-py-limits-list-wrapper"
            style={{
              width: this.state.width <= 1000 && "19%",
              marginLeft: this.state.width <= 1000 && "5%",
            }}
          >
            <li
              keyvalue=""
              style={{ height: "15px" }}
              onClick={(e) => {
                this.setState({
                  pyValFrom: "",
                  showPublicationYearFrom: false,
                });
              }}
            ></li>
            {pyArr && pyArr.length > 0 ? (
              pyArr.map((item, index) => {
                return (
                  <li
                    keyvalue={item}
                    onClick={(e) => {
                      this.setState({
                        pyValFrom: item,
                        showPublicationYearFrom: false,
                      });
                    }}
                    key={`pyFromid-${index}`}
                  >
                    {item}
                  </li>
                );
              })
            ) : (
              <li style={{ color: "gray" }}>
                No results found. Clear the terms to see everything.
              </li>
            )}
          </ul>
        );
      }
    }
  };
  publicationYearDropdownListTo = (pyArr) => {
    if (this.state.width <= 600) {
      if (this.state.pyValTo.length > 0) {
        //pyArr = pyArr.filter((item) => item.toLowerCase().startsWith(this.state.pyValTo.toLowerCase()));
        return (
          <ul className="fielded-search-py-limits-list-wrapper-mobile">
            <li
              keyvalue=""
              style={{ height: "15px" }}
              onClick={(e) => {
                this.setState({ pyValTo: "", showPublicationYearTo: false });
              }}
            ></li>
            {pyArr && pyArr.length > 0 ? (
              pyArr.map((item, index) => {
                //item.startsWith(this.state.pyValTo) &&
                if (item >= this.state.pyValFrom) {
                  return (
                    <li
                      keyvalue={item}
                      onClick={(e) => {
                        this.setState({
                          pyValTo: item,
                          showPublicationYearTo: false,
                        });
                      }}
                      key={`pyToid-${index}`}
                    >
                      {item}
                    </li>
                  );
                }
              })
            ) : (
              <li style={{ color: "gray" }}>
                No results found. Clear the terms to see everything.
              </li>
            )}
          </ul>
        );
      } else {
        return (
          <ul className="fielded-search-py-limits-list-wrapper-mobile">
            <li
              keyvalue=""
              style={{ height: "15px" }}
              onClick={(e) => {
                this.setState({ pyValTo: "", showPublicationYearTo: false });
              }}
            ></li>
            {pyArr && pyArr.length > 0 ? (
              pyArr.map((item, index) => {
                if (item >= this.state.pyValFrom) {
                  return (
                    <li
                      keyvalue={item}
                      onClick={(e) => {
                        this.setState({
                          pyValTo: item,
                          showPublicationYearTo: false,
                        });
                      }}
                      key={`pyToid-${index}`}
                    >
                      {item}
                    </li>
                  );
                }
              })
            ) : (
              <li style={{ color: "gray" }}>
                No results found. Clear the terms to see everything.
              </li>
            )}
          </ul>
        );
      }
    } else {
      if (this.state.pyValTo.length > 0) {
        //pyArr = pyArr.filter((item) => item.toLowerCase().startsWith(this.state.pyValTo.toLowerCase()));
        return (
          <ul
            className="fielded-search-py-limits-list-wrapper"
            style={{
              width: this.state.width <= 1000 && "19.5%",
              marginLeft: this.state.width <= 1000 ? "3.5%" : "3%",
            }}
          >
            <li
              keyvalue=""
              style={{ height: "15px" }}
              onClick={(e) => {
                this.setState({ pyValTo: "", showPublicationYearTo: false });
              }}
            ></li>
            {pyArr && pyArr.length > 0 ? (
              pyArr.map((item, index) => {
                //item.startsWith(this.state.pyValTo) &&
                if (item >= this.state.pyValFrom) {
                  return (
                    <li
                      keyvalue={item}
                      onClick={(e) => {
                        this.setState({
                          pyValTo: item,
                          showPublicationYearTo: false,
                        });
                      }}
                      key={`pyToid-${index}`}
                    >
                      {item}
                    </li>
                  );
                }
              })
            ) : (
              <li style={{ color: "gray" }}>
                No results found. Clear the terms to see everything.
              </li>
            )}
          </ul>
        );
      } else {
        return (
          <ul
            className="fielded-search-py-limits-list-wrapper"
            style={{
              width: this.state.width <= 1000 && "19.5%",
              marginLeft: this.state.width <= 1000 ? "3.5%" : "2%",
            }}
          >
            <li
              keyvalue=""
              style={{ height: "15px" }}
              onClick={(e) => {
                this.setState({ pyValTo: "", showPublicationYearTo: false });
              }}
            ></li>
            {pyArr && pyArr.length > 0 ? (
              pyArr.map((item, index) => {
                if (item >= this.state.pyValFrom) {
                  return (
                    <li
                      keyvalue={item}
                      onClick={(e) => {
                        this.setState({
                          pyValTo: item,
                          showPublicationYearTo: false,
                        });
                      }}
                      key={`pyToid-${index}`}
                    >
                      {item}
                    </li>
                  );
                }
              })
            ) : (
              <li style={{ color: "gray" }}>
                No results found. Clear the terms to see everything.
              </li>
            )}
          </ul>
        );
      }
    }
  };
  handleCongressClick = (event) => {
    this.setState({
      cnVal: event.target.value,
      showCongressName: true,
    });
  };
  handleDocumentTypeClick = (event) => {
    this.setState({
      dtVal: event.target.value,
      showDocumentType: true,
    });
  };

  handleJournalNameClick = (event) => {
    this.setState({
      jnVal: event.target.value,
      showJournalName: true,
    });
  };
  handleProtocolNumberClick = (event) => {
    this.setState({
      pnVal: event.target.value,
      showProtocolNumber: true,
    });
  };
  handleGenericNameClick = (event) => {
    this.setState({
      gnVal: event.target.value,
      showGenericName: true,
    });
  };
  handlePublicationYearFrom = (event) => {
    if (!isNaN(event.target.value)) {
      this.setState({
        pyValFrom: event.target.value,
        showPublicationYearFrom: true,
      });
    }
  };
  handlePublicationYearTo = (event) => {
    if (!isNaN(event.target.value)) {
      this.setState({
        pyValTo: event.target.value,
        showPublicationYearTo: true,
      });
    }
  };
  handleOnChangeFieldValueOne = (event) => {
    this.setState({
      fieldedVal1: event.target.value,
    });
  };
  handleOnChangeFieldValueTwo = (event) => {
    this.setState({
      fieldedVal2: event.target.value,
    });
  };
  handleOnChangeFieldValueThree = (event) => {
    this.setState({
      fieldedVal3: event.target.value,
    });
  };
  handleOnChangeFieldValueFour = (event) => {
    this.setState({
      fieldedVal4: event.target.value,
    });
  };
  conversionOfFieldType = (item) => {
    if (item === "Title") return "docTitle";
    else if (item === "Major Topics") return "majorTopicsSynonyms";
    else if (item === "Author") return "authors";
    else return "full text";
  };

  conversionOfFieldTypeFSLimits = (item) => {
    if (item === "docType" || item === "docTypeSynonyms")
      return "Document Type";
    else if (item === "protocolIDs" || item === "harmonizedProtocolIDs") return "Protocol Number";
    else if (item === "genericName") return "Generic Name";
    else if (item === "congressName" || item === "fullCnSf")
      return "Congress Name";
    else if (item === "strYear") return "Publication Year";
    else if (item === "authors") return "Author";
    else if (item === "docTitle") return "Title";
    else return "Journal Name";
  };

  onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  createSearchCriteriaString = () => {
    let { pyValFrom, pyValTo } = this.state;
    const params = this.state.fieldedVal1;
    const authors = this.state.fieldedVal2;
    const docTitle = this.state.fieldedVal3;
    const majorTopicsSynonyms = this.state.fieldedVal4;
    // Publication Year
    let toRange = pyValTo;
    let fromRange = pyValFrom;
    let PublicationYear = "";
    if (toRange) {
      toRange = parseInt(toRange);
    }
    if (fromRange) {
      fromRange = parseInt(fromRange);
    }
    let limistArr = [];
    let strYear = limistArr.find((x) => x.key === "strYear");
    if (strYear) {
      let strYearValue = parseInt(strYear.value);
      if (
        toRange &&
        fromRange &&
        fromRange <= strYearValue &&
        toRange >= strYearValue
      ) {
        PublicationYear = `${fromRange} To ${toRange}`;
      } else if (
        toRange &&
        fromRange &&
        (fromRange > strYearValue || toRange < strYearValue)
      ) {
        PublicationYear = `${fromRange} To ${toRange} AND ${strYearValue}`;
      } else if (toRange && strYearValue) {
        PublicationYear = `${toRange} and earlier AND ${strYearValue}`;
      } else if (fromRange && strYearValue) {
        PublicationYear = `${fromRange} and later AND ${strYearValue}`;
      } else if (strYearValue) {
        PublicationYear = `${strYearValue}`;
      }
    } else if (toRange && fromRange) {
      PublicationYear = `${fromRange} To ${toRange}`;
    } else if (toRange) {
      PublicationYear = `${toRange} and earlier`;
    } else if (fromRange) {
      PublicationYear = `${fromRange} and later`;
    }
    limistArr = limistArr.filter((x) => x.key !== "strYear");
    ///////

    let { cnVal, dtVal, jnVal, gnVal, pnVal } = this.state;
    cnVal = this.getDropdownValue("cnArr", cnVal);
    dtVal = this.getDropdownValue("dtArr", dtVal);
    jnVal = this.getDropdownValue("jnArr", jnVal);
    gnVal = this.getDropdownValue("gnArr", gnVal);
    pnVal = this.getDropdownValue("pnArr", pnVal);
    let limistArrObj = [];
    if (cnVal !== "") {
      limistArrObj.push({
        limitkey: "fullCnSf",
        limitValue: cnVal,
      });
    }
    if (dtVal !== "") {
      limistArrObj.push({
        limitkey: "docType",
        limitValue: dtVal,
      });
    }
    if (jnVal !== "") {
      limistArrObj.push({
        limitkey: "journalName",
        limitValue: jnVal,
      });
    }
    if (gnVal !== "") {
      limistArrObj.push({
        limitkey: "genericName",
        limitValue: gnVal,
      });
    }
    if (pnVal !== "") {
      limistArrObj.push({
        limitkey: "harmonizedProtocolIDs",
        limitValue: pnVal,
      });
    }
    let fcount = limistArrObj.length;
    for (let i = 0; i < fcount; i++) {
      let key = limistArrObj[i].limitkey;
      let value = limistArrObj[i].limitValue;
      limistArr[i] = { key: key, value: value };
    }
    let str = "";
    str = params ? `(${params})` : "";

    str = str + (docTitle ? ` (Title: ${docTitle})` : "");
    str = str + (authors ? ` (Authors: ${authors})` : "");
    str =
      str +
      (majorTopicsSynonyms ? ` (Major Topics: ${majorTopicsSynonyms})` : "");
    str =
      str + (PublicationYear ? ` (Publication Year: ${PublicationYear})` : "");
    if (fcount > 0 && limistArr.length > 0) {
      limistArr.map((item, index) => {
        str =
          str +
          (item.key
            ? ` (${this.conversionOfFieldTypeFSLimits(item.key)}: ${
                item.value
              })`
            : "");
      });
    }
    return str;
  };

  handleSubmit = () => {
    let query = "";
    let addFieldsType = "";
    let orFieldsType = "";
    let docTitle = "";
    let authors = "";
    let majorTopicsSynonyms = "";
    let obj = [];
    let obj1 = {
      fieldType: this.conversionOfFieldType(this.state.dropdownVal1),
      fieldVal: this.state.fieldedVal1,
      booleanOp: "And",
    };
    let obj2 = {
      fieldType: this.conversionOfFieldType(this.state.dropdownVal2),
      fieldVal: this.state.fieldedVal2,
      booleanOp: this.state.booleanVal1,
    };
    let obj3 = {
      fieldType: this.conversionOfFieldType(this.state.dropdownVal3),
      fieldVal: this.state.fieldedVal3,
      booleanOp: this.state.booleanVal2,
    };
    let obj4 = {
      fieldType: this.conversionOfFieldType(this.state.dropdownVal4),
      fieldVal: this.state.fieldedVal4,
      booleanOp: this.state.booleanVal3,
    };
    obj.push(obj1);
    obj.push(obj2);
    obj.push(obj3);
    obj.push(obj4);
    /*Find query text and AND OR fields*/
    let fullTextobj = obj.filter(
      (item) => item.fieldVal && item.fieldType === "full text"
    );
    let docTitleobj = obj.filter(
      (item) => item.fieldVal && item.fieldType === "docTitle"
    );
    let authorsobj = obj.filter(
      (item) => item.fieldVal && item.fieldType === "authors"
    );
    let majorTopicsSynonymsobj = obj.filter(
      (item) => item.fieldVal && item.fieldType === "majorTopicsSynonyms"
    );
    let addFieldsArr = obj.filter(
      (item) =>
        item.booleanOp === "And" &&
        item.fieldType !== "full text" &&
        item.fieldVal !== ""
    );
    let orFieldsArr = obj.filter(
      (item) =>
        item.booleanOp === "Or" &&
        item.fieldType !== "full text" &&
        item.fieldVal !== ""
    );
    let nonEmptyObjs = obj
      .filter((x) => x.fieldVal)
      .map((x) => {
        return {
          ...x,
          fieldType: x.fieldType.replace("full text", "fullText"),
        };
      });
    // eslint-disable-next-line array-callback-return
    let andFields = [],
      orFields = [];
    nonEmptyObjs.map((item, index) => {
      if (index === 0) {
        let nextAndIndex = nonEmptyObjs.findIndex(
          (x, i) => x.booleanOp === "And" && i > index
        );
        let nextOrIndex = nonEmptyObjs.findIndex(
          (x, i) => x.booleanOp === "Or" && i > index
        );

        if (
          nextAndIndex === -1 &&
          nextOrIndex === -1 &&
          item.booleanOp === "And"
        ) {
          andFields.push(item);
        } else if (
          nextAndIndex === -1 &&
          nextOrIndex === -1 &&
          item.booleanOp === "Or"
        ) {
          orFields.push(item);
        } else if (nextAndIndex > -1 && nextOrIndex === -1) {
          andFields.push(item);
        } else if (nextAndIndex === -1 && nextOrIndex > -1) {
          orFields.push(item);
        } else if (nextAndIndex < nextOrIndex) {
          andFields.push(item);
        } else {
          orFields.push(item);
        }
      } else if (
        item.fieldType !== "fullText" ||
        (item.fieldType === "fullText" &&
          andFields.findIndex((x, i) => x.fieldType === "fullText") === -1 &&
          orFields.findIndex((x, i) => x.fieldType === "fullText") === -1)
      ) {
        if (item.booleanOp === "And") {
          andFields.push(item);
        } else if (item.booleanOp === "Or") {
          orFields.push(item);
        }
      }
    });

    if (addFieldsArr.length > 1) {
      addFieldsArr.map((item, index) => {
        if (!addFieldsType.includes(item.fieldType)) {
          if (index === addFieldsArr.length - 1 && addFieldsArr.length > 1) {
            addFieldsType += item.fieldType;
          } else {
            addFieldsType += item.fieldType + ";";
          }
        }
        return null;
      });
    } else if (addFieldsArr.length === 1) {
      addFieldsType += addFieldsArr[0].fieldType;
    }
    if (orFieldsArr.length > 1) {
      orFieldsArr.map((item, index) => {
        if (!orFieldsType.includes(item.fieldType)) {
          if (index === orFieldsArr.length - 1) {
            orFieldsType += item.fieldType;
          } else {
            orFieldsType += item.fieldType + ";";
          }
        }
        return null;
      });
    } else if (orFieldsArr.length === 1) {
      orFieldsType += orFieldsArr[0].fieldType;
    }
    if (docTitleobj.length > 1) {
      docTitleobj.map((item, index) => {
        if (index === docTitleobj.length - 1) {
          docTitle +=
            item.booleanOp.toUpperCase() +
            " " +
            encodeURIComponent(item.fieldVal);
        } else if (index === 0) {
          docTitle += encodeURIComponent(item.fieldVal) + " ";
        } else {
          docTitle +=
            item.booleanOp.toUpperCase() +
            " " +
            encodeURIComponent(item.fieldVal) +
            " ";
        }
        return null;
      });
    } else if (docTitleobj.length === 1) {
      docTitle += encodeURIComponent(docTitleobj[0].fieldVal);
    }
    if (authorsobj.length > 1) {
      authorsobj.map((item, index) => {
        if (index === authorsobj.length - 1) {
          authors +=
            item.booleanOp.toUpperCase() +
            " " +
            encodeURIComponent(item.fieldVal);
        } else if (index === 0) {
          authors += encodeURIComponent(item.fieldVal) + " ";
        } else {
          authors +=
            item.booleanOp.toUpperCase() +
            " " +
            encodeURIComponent(item.fieldVal) +
            " ";
        }
        return null;
      });
    } else if (authorsobj.length === 1) {
      authors += encodeURIComponent(authorsobj[0].fieldVal);
    }
    if (majorTopicsSynonymsobj.length > 1) {
      majorTopicsSynonymsobj.map((item, index) => {
        if (index === majorTopicsSynonymsobj.length - 1) {
          majorTopicsSynonyms +=
            item.booleanOp.toUpperCase() +
            " " +
            encodeURIComponent(item.fieldVal);
        } else if (index === 0) {
          majorTopicsSynonyms += encodeURIComponent(item.fieldVal) + " ";
        } else {
          majorTopicsSynonyms +=
            item.booleanOp.toUpperCase() +
            " " +
            encodeURIComponent(item.fieldVal) +
            " ";
        }
        return null;
      });
    } else if (majorTopicsSynonymsobj.length === 1) {
      majorTopicsSynonyms += encodeURIComponent(
        majorTopicsSynonymsobj[0].fieldVal
      );
    }
    if (fullTextobj.length > 1) {
      fullTextobj.map((item, index) => {
        if (index === fullTextobj.length - 1) {
          query +=
            item.booleanOp.toUpperCase() +
            " " +
            encodeURIComponent(item.fieldVal);
        } else if (index === 0) {
          query += encodeURIComponent(item.fieldVal) + " ";
        } else {
          query +=
            item.booleanOp.toUpperCase() +
            " " +
            encodeURIComponent(item.fieldVal) +
            " ";
        }
        return null;
      });
    } else if (fullTextobj.length === 1) {
      query += encodeURIComponent(fullTextobj[0].fieldVal);
    }

    let url = `/results?query=${query}&q=All%20Fields&filters=`;
    // if (addFieldsType !== "") {
    //   url += `&andFields=${addFieldsType}`;
    // }
    // if (orFieldsType !== "") {
    //   url += `&orFields=${orFieldsType}`;
    // }

    andFields = andFields.filter(this.onlyUnique);
    orFields = orFields.filter(this.onlyUnique);
    if (andFields && andFields.length > 0) {
      url += `&andFields=${andFields.map((x) => x.fieldType).join(";")}`;
    }
    if (orFields && orFields.length > 0) {
      url += `&orFields=${orFields.map((x) => x.fieldType).join(";")}`;
    }
    if (docTitle !== "") {
      url += `&docTitle=${docTitle}`;
    }
    if (authors !== "") {
      url += `&authors=${authors}`;
    }
    if (majorTopicsSynonyms !== "") {
      url += `&majorTopicsSynonyms=${majorTopicsSynonyms}`;
    }
    /*Check the limits field and assign */
    let limitObj = [];
    let fieldDetails = "";
    let { cnVal, dtVal, jnVal, gnVal, pnVal } = this.state;
    cnVal = this.getDropdownValue("cnArr", cnVal);
    dtVal = this.getDropdownValue("dtArr", dtVal);
    jnVal = this.getDropdownValue("jnArr", jnVal);
    gnVal = this.getDropdownValue("gnArr", gnVal);
    pnVal = this.getDropdownValue("pnArr", pnVal);

    limitObj.push({
      limitkey: "fullCnSf",
      limitValue: encodeURIComponent(cnVal),
    });
    limitObj.push({
      limitkey: "docType",
      limitValue: encodeURIComponent(dtVal),
    });
    limitObj.push({
      limitkey: "journalName",
      limitValue: encodeURIComponent(jnVal),
    });
    limitObj.push({
      limitkey: "genericName",
      limitValue: encodeURIComponent(gnVal),
    });
    limitObj.push({
      limitkey: "harmonizedProtocolIDs",
      limitValue: encodeURIComponent(pnVal),
    });
    let limitsArr = limitObj.filter((item) => item.limitValue !== "");
    let fcount = limitsArr.length;
    if (fcount > 0) {
      for (let i = 0; i < fcount; i++) {
        fieldDetails += `&f${i + 1}=${limitsArr[i].limitkey}&fval${i + 1}=${
          limitsArr[i].limitValue
        }`;
      }
      url += fieldDetails + `&fcount=${fcount}`;
    }
    let { pyValFrom, pyValTo, dropdownValArr } = this.state;

    pyValFrom = this.getDropdownValue("pyArr", pyValFrom);
    pyValTo = this.getDropdownValue("pyArr", pyValTo);

    let dropdownArr = dropdownValArr.find((x) => x.id === "pyArr");
    if (dropdownArr) {
      let selectedValue = dropdownArr.value.find(
        (x) =>
          x.toString().toLowerCase() === pyValTo.toString().toLowerCase() &&
          x >= pyValFrom
      );
      if (selectedValue) {
        pyValTo = selectedValue;
      } else {
        pyValTo = "";
      }
    }

    if (pyValFrom) {
      url += `&fromYear=${pyValFrom}`;
    }
    if (pyValTo) {
      url += `&toYear=${pyValTo}`;
    }
    url += `&FS=true`;
    url += query ? `&sbQuery=${query}` : `&sbQuery=`;
    let str = this.createSearchCriteriaString();
    const ts = new trackingService();
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      localStorage.getItem("companyname"),
      "Conducted Search",
      "",
      url,
      str,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
    //console.log(url)
    /* console.log('addFields: '+addFieldsType);
        console.log('orFields: '+orFieldsType);
        console.log('query: '+query);
        console.log('doctitle: '+docTitle);
        console.log('authors: '+authors);
        console.log('majorTopics: '+majorTopicsSynonyms); */

    this.props.history.push(url);
  };
  handleClear = () => {
    this.setState({
      searchTerm: "",
      dropdownVal1: "Full Text",
      dropdownVal2: "Author",
      dropdownVal3: "Title",
      dropdownVal4: "Major Topics",
      fieldedVal1: "",
      fieldedVal2: "",
      fieldedVal3: "",
      fieldedVal4: "",
      booleanVal1: "And",
      booleanVal2: "And",
      booleanVal3: "And",
      showBooleanDropdown1: false,
      showBooleanDropdown2: false,
      showBooleanDropdown3: false,
      showField1: false,
      showField2: false,
      showField3: false,
      showField4: false,
      showField: false,
      showPublicationYearTo: false,
      showPublicationYearFrom: false,
      showCongressName: false,
      showDocumentType: false,
      showJournalName: false,
      showGenericName: false,
      showProtocolNumber: false,
      pyValFrom: "",
      pyValTo: "",
      pnVal: "",
      jnVal: "",
      dtVal: "",
      gnVal: "",
      cnVal: "",
      count: 1,
      showLimitsInMobile: false,
    });
  };
  getDropdownValue = (dropdownValId, val) => {
    let { dropdownValArr } = this.state;

    if (!dropdownValArr) {
      dropdownValArr = [];
    }
    let value = "";
    let dropdownArr = dropdownValArr.find((x) => x.id === dropdownValId);
    if (dropdownArr && dropdownArr.value) {
      let selectedValue = dropdownArr.value.find(
        (x) => x.toString().toLowerCase() === val.toString().toLowerCase()
      );
      if (selectedValue) {
        value = selectedValue;
      } else {
        value = "";
      }
    }
    return value;
  };

  onDropDownClose = (key) => {
    //let { cnVal, dtVal, jnVal, gnVal, pnVal } = this.state;

    if (key === "cnVal") {
      let { cnVal } = this.state;
      cnVal = this.getDropdownValue("cnArr", cnVal);
      this.setState({ cnVal: cnVal });
    } else if (key === "dtVal") {
      let { dtVal } = this.state;
      dtVal = this.getDropdownValue("dtArr", dtVal);
      this.setState({ dtVal: dtVal });
    } else if (key === "jnVal") {
      let { jnVal } = this.state;
      jnVal = this.getDropdownValue("jnArr", jnVal);
      this.setState({ jnVal: jnVal });
    } else if (key === "pnVal") {
      let { pnVal } = this.state;
      pnVal = this.getDropdownValue("pnArr", pnVal);
      this.setState({ pnVal: pnVal });
    } else if (key === "gnVal") {
      let { gnVal } = this.state;
      gnVal = this.getDropdownValue("gnArr", gnVal);
      this.setState({ gnVal: gnVal });
    } else if (key === "pyValFrom") {
      let { pyValFrom } = this.state;
      pyValFrom = this.getDropdownValue("pyArr", pyValFrom);
      this.setState({ pyValFrom: pyValFrom });
    } else if (key === "pyValTo") {
      let { pyValTo, pyValFrom, dropdownValArr } = this.state;
      let dropdownArr = dropdownValArr.find((x) => x.id === "pyArr");
      if (dropdownArr) {
        let selectedValue = dropdownArr.value.find(
          (x) =>
            x.toString().toLowerCase() === pyValTo.toString().toLowerCase() &&
            x >= pyValFrom
        );
        if (selectedValue) {
          pyValTo = selectedValue;
        } else {
          pyValTo = "";
        }
      }
      this.setState({ pyValTo: pyValTo });
    }
  };
  render = () => {
    if (this.state.width <= 600) {
      return (
        <ErrorBoundary>
          {this.state.userExists ? (
            <>
              <NavigationMenu
                pathName={window.location.pathname}
                customWidth={this.state.width}
                customHeight={this.state.height}
              />
              <div className="fielded-Search-wrapper">
                <div className="fielded-search-first-div-mobile">
                  <div className="fielded-search-title">Fielded Search</div>
                  <div className="field-values-container-tablet">
                    <div className="field-values-container-wrapper-tablet">
                      <div style={{ fontSize: "20px" }}>Search :</div>
                    </div>
                    <input
                      type="text"
                      placeholder="What are you looking for?"
                      title="What are you looking for?"
                      className="field-value-input-box field-value-input-box-mobile"
                      value={this.state.fieldedVal1}
                      onChange={this.handleOnChangeFieldValueOne}
                    />
                    <div
                      className="field-value-dropdown-wrapper field-value-dropdown-wrapper-mobile"
                      onClick={(e) =>
                        this.setState({ showField1: !this.state.showField1 })
                      }
                    >
                      <div className="field-value-dropdown-inner-wrapper">
                        <span className="fielded-search-select-wrapper">
                          <select
                            onChange={(e) =>
                              this.setState({ dropdownVal1: e.target.value })
                            }
                            value={this.state.dropdownVal1}
                          >
                            <option>Full Text</option>
                            <option>Author</option>
                            <option>Title</option>
                            <option>Major Topics</option>
                          </select>
                        </span>
                      </div>
                    </div>
                    <div
                      className="field-value-dropdown-wrapper field-value-dropdown-wrapper-mobile"
                      onClick={(e) =>
                        this.setState({
                          showBooleanDropdown1:
                            !this.state.showBooleanDropdown1,
                        })
                      }
                    >
                      <div className="field-value-dropdown-inner-wrapper">
                        <span className="fielded-search-select-wrapper">
                          <select
                            onChange={(e) =>
                              this.setState({ booleanVal1: e.target.value })
                            }
                            value={this.state.booleanVal1}
                          >
                            <option>And</option>
                            <option>Or</option>
                          </select>
                        </span>
                      </div>
                    </div>
                  </div>
                  {this.state.count > 1 && (
                    <div className="field-values-container-tablet">
                      <input
                        type="text"
                        placeholder="What are you looking for?"
                        title="What are you looking for?"
                        className="field-value-input-box field-value-input-box-mobile"
                        value={this.state.fieldedVal2}
                        onChange={this.handleOnChangeFieldValueTwo}
                      />
                      <div
                        className="field-value-dropdown-wrapper field-value-dropdown-wrapper-mobile"
                        onClick={(e) =>
                          this.setState({ showField2: !this.state.showField2 })
                        }
                      >
                        <div className="field-value-dropdown-inner-wrapper">
                          <span className="fielded-search-select-wrapper">
                            <select
                              onChange={(e) =>
                                this.setState({ dropdownVal2: e.target.value })
                              }
                              value={this.state.dropdownVal2}
                            >
                              <option>Full Text</option>
                              <option>Author</option>
                              <option>Title</option>
                              <option>Major Topics</option>
                            </select>
                          </span>
                        </div>
                      </div>
                      <div
                        className="field-value-dropdown-wrapper field-value-dropdown-wrapper-mobile"
                        onClick={(e) =>
                          this.setState({
                            showBooleanDropdown2:
                              !this.state.showBooleanDropdown2,
                          })
                        }
                      >
                        <div className="field-value-dropdown-inner-wrapper">
                          <span className="fielded-search-select-wrapper">
                            <select
                              onChange={(e) =>
                                this.setState({ booleanVal2: e.target.value })
                              }
                              value={this.state.booleanVal2}
                            >
                              <option>And</option>
                              <option>Or</option>
                            </select>
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.count > 2 && (
                    <div className="field-values-container-tablet">
                      <input
                        type="text"
                        placeholder="What are you looking for?"
                        title="What are you looking for?"
                        className="field-value-input-box field-value-input-box-mobile"
                        value={this.state.fieldedVal3}
                        onChange={this.handleOnChangeFieldValueThree}
                      />
                      <div
                        className="field-value-dropdown-wrapper field-value-dropdown-wrapper-mobile"
                        onClick={(e) =>
                          this.setState({ showField3: !this.state.showField3 })
                        }
                      >
                        <div className="field-value-dropdown-inner-wrapper">
                          <span className="fielded-search-select-wrapper">
                            <select
                              onChange={(e) =>
                                this.setState({ dropdownVal3: e.target.value })
                              }
                              value={this.state.dropdownVal3}
                            >
                              <option>Full Text</option>
                              <option>Author</option>
                              <option>Title</option>
                              <option>Major Topics</option>
                            </select>
                          </span>
                        </div>
                      </div>
                      <div
                        className="field-value-dropdown-wrapper field-value-dropdown-wrapper-mobile"
                        onClick={(e) =>
                          this.setState({
                            showBooleanDropdown3:
                              !this.state.showBooleanDropdown3,
                          })
                        }
                      >
                        <div className="field-value-dropdown-inner-wrapper">
                          <span className="fielded-search-select-wrapper">
                            <select
                              onChange={(e) =>
                                this.setState({ booleanVal3: e.target.value })
                              }
                              value={this.state.booleanVal3}
                            >
                              <option>And</option>
                              <option>Or</option>
                            </select>
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.count > 3 && (
                    <div className="field-values-container-tablet">
                      <input
                        type="text"
                        placeholder="What are you looking for?"
                        title="What are you looking for?"
                        className="field-value-input-box field-value-input-box-mobile"
                        value={this.state.fieldedVal4}
                        onChange={this.handleOnChangeFieldValueFour}
                      />
                      <div
                        className="field-value-dropdown-wrapper field-value-dropdown-wrapper-mobile"
                        onClick={(e) =>
                          this.setState({ showField4: !this.state.showField4 })
                        }
                      >
                        <div className="field-value-dropdown-inner-wrapper">
                          <span className="fielded-search-select-wrapper">
                            <select
                              onChange={(e) =>
                                this.setState({ dropdownVal4: e.target.value })
                              }
                              value={this.state.dropdownVal4}
                            >
                              <option>Full Text</option>
                              <option>Author</option>
                              <option>Title</option>
                              <option>Major Topics</option>
                            </select>
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.count < 4 && (
                    <div
                      className="add-field-button-wrapper"
                      onClick={(e) => {
                        if (this.state.count <= 4) {
                          this.setState({ count: this.state.count + 1 });
                        }
                      }}
                    >
                      <span>Add</span>
                      <img
                        src={addIconWithBlueBorder}
                        alt=""
                        style={{
                          height: "15px",
                          width: "15px",
                          margin: "0 4px 5px",
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="fielded-search-second-div-mobile">
                  <div
                    style={{
                      fontSize: "20px",
                      width: "99%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>Limits :</span>
                    <span
                      style={{
                        cursor: "pointer",
                        border: "1px solid #197DA9",
                        background: "#fff",
                        padding: "0px 10px",
                        borderRadius: "5px",
                      }}
                      onClick={(e) =>
                        this.setState({
                          showLimitsInMobile: !this.state.showLimitsInMobile,
                        })
                      }
                    >
                      {this.state.showLimitsInMobile ? (
                        <img
                          src={rightArrowBlue}
                          alt=""
                          width={10}
                          height={10}
                          style={{ transform: "rotate(-90deg)" }}
                          title="Collapse Limits"
                        />
                      ) : (
                        <img
                          src={rightArrowBlue}
                          alt=""
                          width={10}
                          height={10}
                          style={{ transform: "rotate(90deg)" }}
                          title="Expand Limits"
                        />
                      )}
                    </span>
                  </div>
                  {this.state.showLimitsInMobile && (
                    <>
                      {this.state.dropdownValArr.length > 0 ? (
                        <>
                          <div className="fielded-search-limits-wrapper fielded-search-limits-wrapper-mobile">
                            Publication Year
                            <div
                              className="publication-limit-dropdown-wrapper"
                              style={{ width: "100%", marginTop: "10px" }}
                            >
                              <div
                                className="publication-year-limit-wrapper"
                                onMouseLeave={(e) => {
                                  this.setState({
                                    showPublicationYearFrom: false,
                                  });
                                  this.onDropDownClose("pyValFrom");
                                }}
                                onFocus={(e) =>
                                  this.setState({
                                    showPublicationYearFrom: true,
                                  })
                                }
                              >
                                <div className="limit-dropdown-inner-wrapper">
                                  <div className="limit-py-text-wrapper">
                                    From{" "}
                                  </div>
                                  <div className="publication-year-mobile">
                                    <input
                                      type="text"
                                      placeholder="From"
                                      disabled="true"
                                      title="Type Here"
                                      className="limit-input-text-box publication-year-input"
                                      value={this.state.pyValFrom}
                                      onChange={this.handlePublicationYearFrom}
                                    />
                                    <img
                                      src={dropdownArrowBlack}
                                      alt=""
                                      width="25"
                                      height="30"
                                      style={{
                                        position: "absolute",
                                        right: 0,
                                        top: "5px",
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          showPublicationYearFrom:
                                            !this.state.showPublicationYearFrom,
                                        })
                                      }
                                    />
                                    {this.state.showPublicationYearFrom &&
                                      this.state.dropdownValArr[0].value && (
                                        <>
                                          {this.publicationYearDropdownListFrom(
                                            this.state.dropdownValArr[0].value
                                          )}
                                        </>
                                      )}
                                  </div>
                                </div>
                              </div>
                              <div
                                className="publication-year-limit-wrapper"
                                onMouseLeave={(e) => {
                                  this.setState({
                                    showPublicationYearTo: false,
                                  });
                                  this.onDropDownClose("pyValTo");
                                }}
                                onFocus={(e) =>
                                  this.setState({ showPublicationYearTo: true })
                                }
                              >
                                <div className="limit-dropdown-inner-wrapper">
                                  <div className="limit-py-text-wrapper">
                                    To{" "}
                                  </div>
                                  <div className="publication-year-mobile">
                                    <input
                                      type="text"
                                      placeholder="To"
                                      disabled="true"
                                      title="Type Here"
                                      className="limit-input-text-box publication-year-input"
                                      value={this.state.pyValTo}
                                      onChange={this.handlePublicationYearTo}
                                    />
                                    <img
                                      src={dropdownArrowBlack}
                                      alt=""
                                      width="25"
                                      height="30"
                                      style={{
                                        position: "absolute",
                                        right: 0,
                                        top: "5px",
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          showPublicationYearTo:
                                            !this.state.showPublicationYearTo,
                                        })
                                      }
                                    />
                                    {this.state.showPublicationYearTo &&
                                      this.state.dropdownValArr[0].value && (
                                        <>
                                          {this.publicationYearDropdownListTo(
                                            this.state.dropdownValArr[0].value
                                          )}
                                        </>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="fielded-search-limits-wrapper fielded-search-limits-wrapper-mobile">
                            <div
                              className="limit-dropdown-wrapper limit-dropdown-wrapper-mobile"
                              onMouseLeave={(e) => {
                                this.setState({ showCongressName: false });
                                this.onDropDownClose("cnVal");
                              }}
                              onFocus={(e) =>
                                this.setState({ showCongressName: true })
                              }
                            >
                              <div className="limit-dropdown-inner-wrapper">
                                <input
                                  type="text"
                                  placeholder="Congress Name"
                                  title="Congress Name"
                                  style={{ paddingRight: "42px" }}
                                  className="limit-input-text-box"
                                  value={this.state.cnVal}
                                  onChange={this.handleCongressClick}
                                />
                                <img
                                  src={dropdownArrowBlack}
                                  alt=""
                                  width="25"
                                  height="27"
                                  style={{ position: "absolute" }}
                                  onClick={() =>
                                    this.setState({
                                      showCongressName:
                                        !this.state.showCongressName,
                                    })
                                  }
                                />
                                {this.state.cnVal && (
                                  <img
                                    src={crossIcon}
                                    alt=""
                                    width={12}
                                    height={12}
                                    style={{
                                      position: "absolute",
                                      marginRight: "25px",
                                    }}
                                    onClick={() => this.setState({ cnVal: "" })}
                                  />
                                )}
                              </div>
                              {this.state.showCongressName &&
                                this.state.dropdownValArr[5].value && (
                                  <>
                                    {this.congressNameDropdownList(
                                      this.state.dropdownValArr[5].value
                                    )}
                                  </>
                                )}
                            </div>
                          </div>
                          <div className="fielded-search-limits-wrapper fielded-search-limits-wrapper-mobile">
                            <div
                              className="limit-dropdown-wrapper limit-dropdown-wrapper-mobile"
                              onMouseLeave={(e) => {
                                this.setState({ showDocumentType: false });
                                this.onDropDownClose("dtVal");
                              }}
                              onFocus={(e) =>
                                this.setState({ showDocumentType: true })
                              }
                            >
                              <div className="limit-dropdown-inner-wrapper">
                                <input
                                  type="text"
                                  placeholder="Document Type"
                                  title="Document Type"
                                  style={{ paddingRight: "42px" }}
                                  className="limit-input-text-box"
                                  value={this.state.dtVal}
                                  onChange={this.handleDocumentTypeClick}
                                />
                                <img
                                  src={dropdownArrowBlack}
                                  alt=""
                                  width="25"
                                  height="27"
                                  style={{ position: "absolute" }}
                                  onClick={() =>
                                    this.setState({
                                      showDocumentType:
                                        !this.state.showDocumentType,
                                    })
                                  }
                                />
                                {this.state.dtVal && (
                                  <img
                                    src={crossIcon}
                                    alt=""
                                    width={12}
                                    height={12}
                                    style={{
                                      position: "absolute",
                                      marginRight: "25px",
                                    }}
                                    onClick={() => this.setState({ dtVal: "" })}
                                  />
                                )}
                              </div>
                              {this.state.showDocumentType &&
                                this.state.dropdownValArr[3].value && (
                                  <>
                                    {this.documentTypeDropdownList(
                                      this.state.dropdownValArr[3].value
                                    )}
                                  </>
                                )}
                            </div>
                          </div>
                          <div className="fielded-search-limits-wrapper fielded-search-limits-wrapper-mobile">
                            <div
                              className="limit-dropdown-wrapper limit-dropdown-wrapper-mobile"
                              onMouseLeave={(e) => {
                                this.setState({ showJournalName: false });
                                this.onDropDownClose("jnVal");
                              }}
                              onFocus={(e) =>
                                this.setState({ showJournalName: true })
                              }
                            >
                              <div className="limit-dropdown-inner-wrapper">
                                <input
                                  type="text"
                                  placeholder="Journal Name"
                                  title="Journal Name"
                                  style={{ paddingRight: "42px" }}
                                  className="limit-input-text-box"
                                  value={this.state.jnVal}
                                  onChange={this.handleJournalNameClick}
                                />
                                <img
                                  src={dropdownArrowBlack}
                                  alt=""
                                  width="25"
                                  height="27"
                                  style={{ position: "absolute" }}
                                  onClick={() =>
                                    this.setState({
                                      showJournalName:
                                        !this.state.showJournalName,
                                    })
                                  }
                                />
                                {this.state.jnVal && (
                                  <img
                                    src={crossIcon}
                                    alt=""
                                    width={12}
                                    height={12}
                                    style={{
                                      position: "absolute",
                                      marginRight: "25px",
                                    }}
                                    onClick={() => this.setState({ jnVal: "" })}
                                  />
                                )}
                              </div>
                              {this.state.showJournalName &&
                                this.state.dropdownValArr[2].value && (
                                  <>
                                    {this.journalNameDropdownList(
                                      this.state.dropdownValArr[2].value
                                    )}
                                  </>
                                )}
                            </div>
                          </div>
                          <div className="fielded-search-limits-wrapper fielded-search-limits-wrapper-mobile">
                            <div
                              className="limit-dropdown-wrapper limit-dropdown-wrapper-mobile"
                              onMouseLeave={(e) => {
                                this.setState({ showGenericName: false });
                                this.onDropDownClose("gnVal");
                              }}
                              onFocus={(e) =>
                                this.setState({ showGenericName: true })
                              }
                            >
                              <div className="limit-dropdown-inner-wrapper">
                                <input
                                  type="text"
                                  placeholder="Generic Name"
                                  title="Generic Name"
                                  style={{ paddingRight: "42px" }}
                                  className="limit-input-text-box"
                                  value={this.state.gnVal}
                                  onChange={this.handleGenericNameClick}
                                />
                                <img
                                  src={dropdownArrowBlack}
                                  alt=""
                                  width="25"
                                  height="27"
                                  style={{ position: "absolute" }}
                                  onClick={() =>
                                    this.setState({
                                      showGenericName:
                                        !this.state.showGenericName,
                                    })
                                  }
                                />
                                {this.state.gnVal && (
                                  <img
                                    src={crossIcon}
                                    alt=""
                                    width={12}
                                    height={12}
                                    style={{
                                      position: "absolute",
                                      marginRight: "25px",
                                    }}
                                    onClick={() => this.setState({ gnVal: "" })}
                                  />
                                )}
                              </div>
                              {this.state.showGenericName &&
                                this.state.dropdownValArr[4].value && (
                                  <>
                                    {this.genericNameDropdownList(
                                      this.state.dropdownValArr[4].value
                                    )}
                                  </>
                                )}
                            </div>
                          </div>
                          <div className="fielded-search-limits-wrapper fielded-search-limits-wrapper-mobile">
                            <div
                              className="limit-dropdown-wrapper limit-dropdown-wrapper-mobile"
                              onMouseLeave={(e) => {
                                this.setState({ showProtocolNumber: false });
                                this.onDropDownClose("pnVal");
                              }}
                              onFocus={(e) =>
                                this.setState({ showProtocolNumber: true })
                              }
                            >
                              <div className="limit-dropdown-inner-wrapper">
                                <input
                                  type="text"
                                  placeholder="Protocol Number"
                                  title="Protocol Number"
                                  style={{ paddingRight: "42px" }}
                                  className="limit-input-text-box"
                                  value={this.state.pnVal}
                                  onChange={this.handleProtocolNumberClick}
                                />
                                <img
                                  src={dropdownArrowBlack}
                                  alt=""
                                  width="25"
                                  height="27"
                                  style={{ position: "absolute" }}
                                  onClick={() =>
                                    this.setState({
                                      showProtocolNumber:
                                        !this.state.showProtocolNumber,
                                    })
                                  }
                                />
                                {this.state.pnVal && (
                                  <img
                                    src={crossIcon}
                                    alt=""
                                    width={12}
                                    height={12}
                                    style={{
                                      position: "absolute",
                                      marginRight: "25px",
                                    }}
                                    onClick={() => this.setState({ pnVal: "" })}
                                  />
                                )}
                              </div>
                              {this.state.showProtocolNumber &&
                                this.state.dropdownValArr[1].value && (
                                  <>
                                    {this.protocolNumberDropdownList(
                                      this.state.dropdownValArr[1].value
                                    )}
                                  </>
                                )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div style={{ textAlign: "center", padding: "10%" }}>
                          <img
                            src={imsLoader}
                            alt="Loading Data"
                            style={{ width: "10%" }}
                          />
                        </div>
                      )}
                    </>
                  )}
                  <div className="fielded-search-button-wrapper-mobile">
                    <div
                      className="field-cancel-button-mobile"
                      onClick={(e) => {
                        this.props.history.push("/");
                      }}
                    >
                      Cancel
                    </div>
                    <div
                      className="field-clear-button-mobile"
                      onClick={(e) => {
                        this.handleClear();
                      }}
                    >
                      Clear Form
                    </div>
                    <div
                      className="field-search-button-mobile"
                      onClick={(e) => {
                        this.handleSubmit();
                      }}
                    >
                      Search
                    </div>
                    {/* <div className="fielded-search-button-inner-wrapper fs-button-inner-wrapper-tablet">
                                <span style={{margin:"0 20px"}}>
                                    <Button text='Cancel' customColor='#0074B0' action={() => { this.props.history.push('/') }}/>
                                </span>
                                <span style={{margin:"0 20px"}}>
                                    <Button text='Search' customColor='#2EB000' action={()=>{ this.handleSubmit()}}/>
                                </span>
                                </div> */}
                  </div>
                </div>
                <div style={{ margin: "0 1.5%" }}>
                  <Footer
                    customWidth={this.state.width}
                    customHeight={this.state.height}
                  />
                </div>
              </div>
            </>
          ) : (
            <div style={{ textAlign: "center", padding: "10%" }}>
              <img
                src={imsLoader}
                alt="Loading Data"
                style={{ width: "20%" }}
              />
            </div>
          )}
        </ErrorBoundary>
      );
    } else if (this.state.width >= 600 && this.state.width <= 1000) {
      return (
        <div>
          <ErrorBoundary>
            {this.state.userExists ? (
              <>
                <NavigationMenu
                  pathName={window.location.pathname}
                  customWidth={this.state.width}
                  customHeight={this.state.height}
                />
                <div className="header-wrapper">
                  <HeaderWithBanner />
                </div>
                <div className="fielded-Search-wrapper">
                  <div className="fielded-search-first-div-tablet">
                    <div className="fielded-search-title">Fielded Search</div>
                    <div className="field-values-container-tablet">
                      <div className="field-values-container-wrapper-tablet">
                        <div style={{ fontSize: "20px", width: "14%" }}>
                          Search :
                        </div>
                        <input
                          type="text"
                          placeholder="What are you looking for?"
                          title="What are you looking for?"
                          className="field-value-input-box field-value-input-box-tablet"
                          value={this.state.fieldedVal1}
                          onChange={this.handleOnChangeFieldValueOne}
                        />
                        <div
                          className="field-value-dropdown-wrapper field-value-dropdown-wrapper-tab-large"
                          onClick={(e) =>
                            this.setState({
                              showField1: !this.state.showField1,
                            })
                          }
                        >
                          <div className="field-value-dropdown-inner-wrapper">
                            <span className="fielded-search-select-wrapper">
                              <select
                                onChange={(e) =>
                                  this.setState({
                                    dropdownVal1: e.target.value,
                                  })
                                }
                                value={this.state.dropdownVal1}
                              >
                                <option>Full Text</option>
                                <option>Author</option>
                                <option>Title</option>
                                <option>Major Topics</option>
                              </select>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="field-values-container-wrapper-tablet">
                        <div
                          className="field-value-dropdown-wrapper-tablet"
                          onClick={(e) =>
                            this.setState({
                              showBooleanDropdown1:
                                !this.state.showBooleanDropdown1,
                            })
                          }
                        >
                          <div className="field-value-dropdown-inner-wrapper">
                            <span className="fielded-search-select-wrapper">
                              <select
                                onChange={(e) =>
                                  this.setState({ booleanVal1: e.target.value })
                                }
                                value={this.state.booleanVal1}
                              >
                                <option>And</option>
                                <option>Or</option>
                              </select>
                            </span>
                          </div>
                        </div>
                        <input
                          type="text"
                          placeholder="Type Here"
                          title="Type Here"
                          className="field-value-input-box field-value-input-box-tablet"
                          value={this.state.fieldedVal2}
                          onChange={this.handleOnChangeFieldValueTwo}
                        />
                        <div
                          className="field-value-dropdown-wrapper field-value-dropdown-wrapper-tab-large"
                          onClick={(e) =>
                            this.setState({
                              showField2: !this.state.showField2,
                            })
                          }
                        >
                          <div className="field-value-dropdown-inner-wrapper">
                            <span className="fielded-search-select-wrapper">
                              <select
                                onChange={(e) =>
                                  this.setState({
                                    dropdownVal2: e.target.value,
                                  })
                                }
                                value={this.state.dropdownVal2}
                              >
                                <option>Full Text</option>
                                <option>Author</option>
                                <option>Title</option>
                                <option>Major Topics</option>
                              </select>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="field-values-container-wrapper-tablet">
                        <div
                          className="field-value-dropdown-wrapper-tablet"
                          onClick={(e) =>
                            this.setState({
                              showBooleanDropdown2:
                                !this.state.showBooleanDropdown2,
                            })
                          }
                        >
                          <div className="field-value-dropdown-inner-wrapper">
                            <span className="fielded-search-select-wrapper">
                              <select
                                onChange={(e) =>
                                  this.setState({ booleanVal2: e.target.value })
                                }
                                value={this.state.booleanVal2}
                              >
                                <option>And</option>
                                <option>Or</option>
                              </select>
                            </span>
                          </div>
                        </div>
                        <input
                          type="text"
                          placeholder="Type Here"
                          title="Type Here"
                          className="field-value-input-box field-value-input-box-tablet"
                          value={this.state.fieldedVal3}
                          onChange={this.handleOnChangeFieldValueThree}
                        />
                        <div
                          className="field-value-dropdown-wrapper field-value-dropdown-wrapper-tab-large"
                          onClick={(e) =>
                            this.setState({
                              showField3: !this.state.showField3,
                            })
                          }
                        >
                          <div className="field-value-dropdown-inner-wrapper">
                            <span className="fielded-search-select-wrapper">
                              <select
                                onChange={(e) =>
                                  this.setState({
                                    dropdownVal3: e.target.value,
                                  })
                                }
                                value={this.state.dropdownVal3}
                              >
                                <option>Full Text</option>
                                <option>Author</option>
                                <option>Title</option>
                                <option>Major Topics</option>
                              </select>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="field-values-container-wrapper-tablet">
                        <div
                          className="field-value-dropdown-wrapper-tablet"
                          onClick={(e) =>
                            this.setState({
                              showBooleanDropdown3:
                                !this.state.showBooleanDropdown3,
                            })
                          }
                        >
                          <div className="field-value-dropdown-inner-wrapper">
                            <span className="fielded-search-select-wrapper">
                              <select
                                onChange={(e) =>
                                  this.setState({ booleanVal3: e.target.value })
                                }
                                value={this.state.booleanVal3}
                              >
                                <option>And</option>
                                <option>Or</option>
                              </select>
                            </span>
                          </div>
                        </div>
                        <input
                          type="text"
                          placeholder="Type Here"
                          title="Type Here"
                          className="field-value-input-box field-value-input-box-tablet"
                          value={this.state.fieldedVal4}
                          onChange={this.handleOnChangeFieldValueFour}
                        />
                        <div
                          className="field-value-dropdown-wrapper field-value-dropdown-wrapper-tab-large"
                          onClick={(e) =>
                            this.setState({
                              showField4: !this.state.showField4,
                            })
                          }
                        >
                          <div className="field-value-dropdown-inner-wrapper">
                            <span className="fielded-search-select-wrapper">
                              <select
                                onChange={(e) =>
                                  this.setState({
                                    dropdownVal4: e.target.value,
                                  })
                                }
                                value={this.state.dropdownVal4}
                              >
                                <option>Full Text</option>
                                <option>Author</option>
                                <option>Title</option>
                                <option>Major Topics</option>
                              </select>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fielded-search-second-div fs-second-div-tablet">
                    {this.state.dropdownValArr.length > 0 ? (
                      <>
                        <div style={{ fontSize: "20px", width: "13%" }}>
                          Limits :
                        </div>
                        <div className="fielded-search-limits-wrapper fielded-search-limits-wrapper-tablet">
                          Publication Year
                          <div className="publication-limit-dropdown-wrapper">
                            <div
                              className="publication-year-limit-wrapper"
                              onMouseLeave={(e) => {
                                this.setState({
                                  showPublicationYearFrom: false,
                                });
                                this.onDropDownClose("pyValFrom");
                              }}
                              onFocus={(e) =>
                                this.setState({ showPublicationYearFrom: true })
                              }
                            >
                              <div className="limit-dropdown-inner-wrapper">
                                <div className="limit-py-text-wrapper">
                                  From{" "}
                                </div>
                                <input
                                  type="text"
                                  placeholder="From"
                                  onClick={() =>
                                    this.setState({
                                      showPublicationYearFrom:
                                        !this.state.showPublicationYearFrom,
                                    })
                                  }
                                  disabled="true"
                                  title="Type Here"
                                  className="limit-input-text-box publication-year-input"
                                  value={this.state.pyValFrom}
                                  onChange={this.handlePublicationYearFrom}
                                />
                                <img
                                  src={dropdownArrowBlack}
                                  alt=""
                                  width="25"
                                  height="30"
                                  style={{ position: "absolute" }}
                                  onClick={() =>
                                    this.setState({
                                      showPublicationYearFrom:
                                        !this.state.showPublicationYearFrom,
                                    })
                                  }
                                />
                              </div>
                              {this.state.showPublicationYearFrom &&
                                this.state.dropdownValArr[0].value && (
                                  <>
                                    {this.publicationYearDropdownListFrom(
                                      this.state.dropdownValArr[0].value
                                    )}
                                  </>
                                )}
                            </div>
                            <div
                              className="publication-year-limit-wrapper"
                              onMouseLeave={(e) => {
                                this.setState({ showPublicationYearTo: false });
                                this.onDropDownClose("pyValTo");
                              }}
                              onFocus={(e) =>
                                this.setState({ showPublicationYearTo: true })
                              }
                            >
                              <div className="limit-dropdown-inner-wrapper">
                                <div className="limit-py-text-wrapper">To </div>
                                <input
                                  type="text"
                                  placeholder="To"
                                  disabled="true"
                                  title="Type Here"
                                  className="limit-input-text-box publication-year-input"
                                  value={this.state.pyValTo}
                                  onChange={this.handlePublicationYearTo}
                                />
                                <img
                                  src={dropdownArrowBlack}
                                  alt=""
                                  width="25"
                                  height="30"
                                  style={{ position: "absolute" }}
                                  onClick={() =>
                                    this.setState({
                                      showPublicationYearTo:
                                        !this.state.showPublicationYearTo,
                                    })
                                  }
                                />
                              </div>
                              {this.state.showPublicationYearTo &&
                                this.state.dropdownValArr[0].value && (
                                  <>
                                    {this.publicationYearDropdownListTo(
                                      this.state.dropdownValArr[0].value
                                    )}
                                  </>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="fielded-search-limits-wrapper fielded-search-limits-wrapper-tablet">
                          Congress Name
                          <div
                            className="limit-dropdown-wrapper"
                            onMouseLeave={(e) => {
                              this.setState({ showCongressName: false });
                              this.onDropDownClose("cnVal");
                            }}
                            onFocus={(e) =>
                              this.setState({ showCongressName: true })
                            }
                          >
                            <div className="limit-dropdown-inner-wrapper">
                              <input
                                type="text"
                                placeholder="Type Here"
                                title="Type Here"
                                className="limit-input-text-box"
                                value={this.state.cnVal}
                                onChange={this.handleCongressClick}
                              />
                              <img
                                src={dropdownArrowBlack}
                                alt=""
                                width="25"
                                height="27"
                                style={{ position: "absolute" }}
                                onClick={() =>
                                  this.setState({
                                    showCongressName:
                                      !this.state.showCongressName,
                                  })
                                }
                              />
                              {this.state.cnVal && (
                                <img
                                  src={crossIcon}
                                  alt=""
                                  width={12}
                                  height={12}
                                  style={{
                                    position: "absolute",
                                    marginRight: "25px",
                                  }}
                                  onClick={() => this.setState({ cnVal: "" })}
                                />
                              )}
                            </div>
                            {this.state.showCongressName &&
                              this.state.dropdownValArr[5].value && (
                                <>
                                  {this.congressNameDropdownList(
                                    this.state.dropdownValArr[5].value
                                  )}
                                </>
                              )}
                          </div>
                        </div>
                        <div className="fielded-search-limits-wrapper fielded-search-limits-wrapper-tablet">
                          Document Type
                          <div
                            className="limit-dropdown-wrapper"
                            onMouseLeave={(e) => {
                              this.setState({ showDocumentType: false });
                              this.onDropDownClose("dtVal");
                            }}
                            onFocus={(e) =>
                              this.setState({ showDocumentType: true })
                            }
                          >
                            <div className="limit-dropdown-inner-wrapper">
                              <input
                                type="text"
                                placeholder="Type Here"
                                title="Type Here"
                                className="limit-input-text-box"
                                value={this.state.dtVal}
                                onChange={this.handleDocumentTypeClick}
                              />
                              <img
                                src={dropdownArrowBlack}
                                alt=""
                                width="25"
                                height="27"
                                style={{ position: "absolute" }}
                                onClick={() =>
                                  this.setState({
                                    showDocumentType:
                                      !this.state.showDocumentType,
                                  })
                                }
                              />
                              {this.state.dtVal && (
                                <img
                                  src={crossIcon}
                                  alt=""
                                  width={12}
                                  height={12}
                                  style={{
                                    position: "absolute",
                                    marginRight: "25px",
                                  }}
                                  onClick={() => this.setState({ dtVal: "" })}
                                />
                              )}
                            </div>
                            {this.state.showDocumentType &&
                              this.state.dropdownValArr[3].value && (
                                <>
                                  {this.documentTypeDropdownList(
                                    this.state.dropdownValArr[3].value
                                  )}
                                </>
                              )}
                          </div>
                        </div>
                        <div className="fielded-search-limits-wrapper fielded-search-limits-wrapper-tablet">
                          Journal Name
                          <div
                            className="limit-dropdown-wrapper"
                            onMouseLeave={(e) => {
                              this.setState({ showJournalName: false });
                              this.onDropDownClose("jnVal");
                            }}
                            onFocus={(e) =>
                              this.setState({ showJournalName: true })
                            }
                          >
                            <div className="limit-dropdown-inner-wrapper">
                              <input
                                type="text"
                                placeholder="Type Here"
                                title="Type Here"
                                className="limit-input-text-box"
                                value={this.state.jnVal}
                                onChange={this.handleJournalNameClick}
                              />
                              <img
                                src={dropdownArrowBlack}
                                alt=""
                                width="25"
                                height="27"
                                style={{ position: "absolute" }}
                                onClick={() =>
                                  this.setState({
                                    showJournalName:
                                      !this.state.showJournalName,
                                  })
                                }
                              />
                              {this.state.jnVal && (
                                <img
                                  src={crossIcon}
                                  alt=""
                                  width={12}
                                  height={12}
                                  style={{
                                    position: "absolute",
                                    marginRight: "25px",
                                  }}
                                  onClick={() => this.setState({ jnVal: "" })}
                                />
                              )}
                            </div>
                            {this.state.showJournalName &&
                              this.state.dropdownValArr[2].value && (
                                <>
                                  {this.journalNameDropdownList(
                                    this.state.dropdownValArr[2].value
                                  )}
                                </>
                              )}
                          </div>
                        </div>
                        <div className="fielded-search-limits-wrapper fielded-search-limits-wrapper-tablet">
                          Generic Name
                          <div
                            className="limit-dropdown-wrapper"
                            onMouseLeave={(e) => {
                              this.setState({ showGenericName: false });
                              this.onDropDownClose("gnVal");
                            }}
                            onFocus={(e) =>
                              this.setState({ showGenericName: true })
                            }
                          >
                            <div className="limit-dropdown-inner-wrapper">
                              <input
                                type="text"
                                placeholder="Type Here"
                                title="Type Here"
                                className="limit-input-text-box"
                                value={this.state.gnVal}
                                onChange={this.handleGenericNameClick}
                              />
                              <img
                                src={dropdownArrowBlack}
                                alt=""
                                width="25"
                                height="27"
                                style={{ position: "absolute" }}
                                onClick={() =>
                                  this.setState({
                                    showGenericName:
                                      !this.state.showGenericName,
                                  })
                                }
                              />
                              {this.state.gnVal && (
                                <img
                                  src={crossIcon}
                                  alt=""
                                  width={12}
                                  height={12}
                                  style={{
                                    position: "absolute",
                                    marginRight: "25px",
                                  }}
                                  onClick={() => this.setState({ gnVal: "" })}
                                />
                              )}
                            </div>
                            {this.state.showGenericName &&
                              this.state.dropdownValArr[4].value && (
                                <>
                                  {this.genericNameDropdownList(
                                    this.state.dropdownValArr[4].value
                                  )}
                                </>
                              )}
                          </div>
                        </div>
                        <div className="fielded-search-limits-wrapper fielded-search-limits-wrapper-tablet">
                          Protocol Number
                          <div
                            className="limit-dropdown-wrapper"
                            onMouseLeave={(e) => {
                              this.setState({ showProtocolNumber: false });
                              this.onDropDownClose("pnVal");
                            }}
                            onFocus={(e) =>
                              this.setState({ showProtocolNumber: true })
                            }
                          >
                            <div className="limit-dropdown-inner-wrapper">
                              <input
                                type="text"
                                placeholder="Type Here"
                                title="Type Here"
                                className="limit-input-text-box"
                                value={this.state.pnVal}
                                onChange={this.handleProtocolNumberClick}
                              />
                              <img
                                src={dropdownArrowBlack}
                                alt=""
                                width="25"
                                height="27"
                                style={{ position: "absolute" }}
                                onClick={() =>
                                  this.setState({
                                    showProtocolNumber:
                                      !this.state.showProtocolNumber,
                                  })
                                }
                              />
                              {this.state.pnVal && (
                                <img
                                  src={crossIcon}
                                  alt=""
                                  width={12}
                                  height={12}
                                  style={{
                                    position: "absolute",
                                    marginRight: "25px",
                                  }}
                                  onClick={() => this.setState({ pnVal: "" })}
                                />
                              )}
                            </div>
                            {this.state.showProtocolNumber &&
                              this.state.dropdownValArr[1].value && (
                                <>
                                  {this.protocolNumberDropdownList(
                                    this.state.dropdownValArr[1].value
                                  )}
                                </>
                              )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div style={{ textAlign: "center", padding: "10%" }}>
                        <img
                          src={imsLoader}
                          alt="Loading Data"
                          style={{ width: "10%" }}
                        />
                      </div>
                    )}
                    <div
                      className="fielded-search-button-wrapper"
                      style={{ width: this.state.width <= 100 && "100%" }}
                    >
                      <div className="fielded-search-button-inner-wrapper fs-button-inner-wrapper-tablet">
                        <span style={{ margin: "0 20px" }}>
                          <Button
                            text="Cancel"
                            customColor="#0074B0"
                            action={() => {
                              this.props.history.push("/");
                            }}
                          />
                        </span>
                        <span style={{ margin: "0 20px" }}>
                          <Button
                            text="Clear Form"
                            customColor="#f44336"
                            action={() => {
                              this.handleClear();
                            }}
                          />
                        </span>
                        <span style={{ margin: "0 20px" }}>
                          <Button
                            text="Search"
                            customColor="#2EB000"
                            action={() => {
                              this.handleSubmit();
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ margin: "0 1.5%" }}>
                  <Footer
                    customWidth={this.state.width}
                    customHeight={this.state.height}
                  />
                </div>
              </>
            ) : (
              <div style={{ textAlign: "center", padding: "10%" }}>
                <img
                  src={imsLoader}
                  alt="Loading Data"
                  style={{ width: "20%" }}
                />
              </div>
            )}
          </ErrorBoundary>
        </div>
      );
    } else {
      return (
        <div>
          <ErrorBoundary>
            {this.state.userExists ? (
              <>
                <NavigationMenu
                  pathName={window.location.pathname}
                  customWidth={this.state.width}
                  customHeight={this.state.height}
                />
                <div className="header-wrapper">
                  <HeaderWithBanner />
                </div>
                <div className="fielded-Search-wrapper">
                  <div className="fielded-search-first-div">
                    <div className="fielded-search-title">Fielded Search</div>
                    <div className="field-values-container">
                      <div className="field-values-container-wrapper">
                        <div style={{ fontSize: "20px", width: "13%" }}>
                          Search :
                        </div>
                        <input
                          type="text"
                          placeholder="What are you looking for?"
                          title="What are you looking for?"
                          className="field-value-input-box"
                          value={this.state.fieldedVal1}
                          onChange={this.handleOnChangeFieldValueOne}
                        />
                        <div
                          className="field-value-dropdown-wrapper"
                          onClick={(e) =>
                            this.setState({
                              showField1: !this.state.showField1,
                            })
                          }
                        >
                          <div className="field-value-dropdown-inner-wrapper">
                            <span className="fielded-search-select-wrapper">
                              <select
                                onChange={(e) =>
                                  this.setState({
                                    dropdownVal1: e.target.value,
                                  })
                                }
                                value={this.state.dropdownVal1}
                              >
                                <option>Full Text</option>
                                <option>Author</option>
                                <option>Title</option>
                                <option>Major Topics</option>
                              </select>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="field-values-container-wrapper">
                        <div
                          className="field-value-dropdown-wrapper"
                          onClick={(e) =>
                            this.setState({
                              showBooleanDropdown1:
                                !this.state.showBooleanDropdown1,
                            })
                          }
                        >
                          <div className="field-value-dropdown-inner-wrapper">
                            <span className="fielded-search-select-wrapper">
                              <select
                                onChange={(e) =>
                                  this.setState({ booleanVal1: e.target.value })
                                }
                                value={this.state.booleanVal1}
                              >
                                <option>And</option>
                                <option>Or</option>
                              </select>
                            </span>
                          </div>
                        </div>
                        <input
                          type="text"
                          placeholder="Type Here"
                          title="Type Here"
                          className="field-value-input-box"
                          value={this.state.fieldedVal2}
                          onChange={this.handleOnChangeFieldValueTwo}
                        />
                        <div
                          className="field-value-dropdown-wrapper"
                          onClick={(e) =>
                            this.setState({
                              showField2: !this.state.showField2,
                            })
                          }
                        >
                          <div className="field-value-dropdown-inner-wrapper">
                            <span className="fielded-search-select-wrapper">
                              <select
                                onChange={(e) =>
                                  this.setState({
                                    dropdownVal2: e.target.value,
                                  })
                                }
                                value={this.state.dropdownVal2}
                              >
                                <option>Full Text</option>
                                <option>Author</option>
                                <option>Title</option>
                                <option>Major Topics</option>
                              </select>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="field-values-container-wrapper">
                        <div
                          className="field-value-dropdown-wrapper"
                          onClick={(e) =>
                            this.setState({
                              showBooleanDropdown2:
                                !this.state.showBooleanDropdown2,
                            })
                          }
                        >
                          <div className="field-value-dropdown-inner-wrapper">
                            <span className="fielded-search-select-wrapper">
                              <select
                                onChange={(e) =>
                                  this.setState({ booleanVal2: e.target.value })
                                }
                                value={this.state.booleanVal2}
                              >
                                <option>And</option>
                                <option>Or</option>
                              </select>
                            </span>
                          </div>
                        </div>
                        <input
                          type="text"
                          placeholder="Type Here"
                          title="Type Here"
                          className="field-value-input-box"
                          value={this.state.fieldedVal3}
                          onChange={this.handleOnChangeFieldValueThree}
                        />
                        <div
                          className="field-value-dropdown-wrapper"
                          onClick={(e) =>
                            this.setState({
                              showField3: !this.state.showField3,
                            })
                          }
                        >
                          <div className="field-value-dropdown-inner-wrapper">
                            <span className="fielded-search-select-wrapper">
                              <select
                                onChange={(e) =>
                                  this.setState({
                                    dropdownVal3: e.target.value,
                                  })
                                }
                                value={this.state.dropdownVal3}
                              >
                                <option>Full Text</option>
                                <option>Author</option>
                                <option>Title</option>
                                <option>Major Topics</option>
                              </select>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="field-values-container-wrapper">
                        <div
                          className="field-value-dropdown-wrapper"
                          onClick={(e) =>
                            this.setState({
                              showBooleanDropdown3:
                                !this.state.showBooleanDropdown3,
                            })
                          }
                        >
                          <div className="field-value-dropdown-inner-wrapper">
                            <span className="fielded-search-select-wrapper">
                              <select
                                onChange={(e) =>
                                  this.setState({ booleanVal3: e.target.value })
                                }
                                value={this.state.booleanVal3}
                              >
                                <option>And</option>
                                <option>Or</option>
                              </select>
                            </span>
                          </div>
                        </div>
                        <input
                          type="text"
                          placeholder="Type Here"
                          title="Type Here"
                          className="field-value-input-box"
                          value={this.state.fieldedVal4}
                          onChange={this.handleOnChangeFieldValueFour}
                        />
                        <div
                          className="field-value-dropdown-wrapper"
                          onClick={(e) =>
                            this.setState({
                              showField4: !this.state.showField4,
                            })
                          }
                        >
                          <div className="field-value-dropdown-inner-wrapper">
                            <span className="fielded-search-select-wrapper">
                              <select
                                onChange={(e) =>
                                  this.setState({
                                    dropdownVal4: e.target.value,
                                  })
                                }
                                value={this.state.dropdownVal4}
                              >
                                <option>Full Text</option>
                                <option>Author</option>
                                <option>Title</option>
                                <option>Major Topics</option>
                              </select>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fielded-search-second-div">
                    {this.state.dropdownValArr.length > 0 ? (
                      <>
                        <div style={{ fontSize: "20px", width: "13%" }}>
                          Limits :
                        </div>
                        <div className="fielded-search-limits-wrapper">
                          Publication Year
                          <div className="publication-limit-dropdown-wrapper">
                            <div
                              className="publication-year-limit-wrapper"
                              onMouseLeave={(e) => {
                                this.setState({
                                  showPublicationYearFrom: false,
                                });
                                this.onDropDownClose("pyValFrom");
                              }}
                              onFocus={(e) =>
                                this.setState({ showPublicationYearFrom: true })
                              }
                            >
                              <div
                                className="limit-dropdown-inner-wrapper"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.setState({
                                    showPublicationYearFrom:
                                      !this.state.showPublicationYearFrom,
                                  })
                                }
                              >
                                <div className="limit-py-text-wrapper">
                                  From{" "}
                                </div>
                                <input
                                  type="text"
                                  placeholder="From"
                                  disabled="true"
                                  title="Type Here"
                                  className="limit-input-text-box publication-year-input"
                                  value={this.state.pyValFrom}
                                  onChange={this.handlePublicationYearFrom}
                                />
                                <img
                                  src={dropdownArrowBlack}
                                  alt=""
                                  width="25"
                                  height="30"
                                  style={{ position: "absolute" }}
                                  onClick={() =>
                                    this.setState({
                                      showPublicationYearFrom:
                                        !this.state.showPublicationYearFrom,
                                    })
                                  }
                                />
                              </div>
                              {this.state.showPublicationYearFrom &&
                                this.state.dropdownValArr[0].value && (
                                  <>
                                    {this.publicationYearDropdownListFrom(
                                      this.state.dropdownValArr[0].value
                                    )}
                                  </>
                                )}
                            </div>
                            <div
                              className="publication-year-limit-wrapper"
                              onMouseLeave={(e) => {
                                this.setState({ showPublicationYearTo: false });
                                this.onDropDownClose("pyValTo");
                              }}
                              onFocus={(e) =>
                                this.setState({ showPublicationYearTo: true })
                              }
                            >
                              <div
                                className="limit-dropdown-inner-wrapper"
                                onClick={() =>
                                  this.setState({
                                    showPublicationYearTo:
                                      !this.state.showPublicationYearTo,
                                  })
                                }
                              >
                                <div className="limit-py-text-wrapper">To </div>
                                <input
                                  type="text"
                                  placeholder="To"
                                  disabled="true"
                                  title="Type Here"
                                  className="limit-input-text-box publication-year-input"
                                  value={this.state.pyValTo}
                                  onChange={this.handlePublicationYearTo}
                                />
                                <img
                                  src={dropdownArrowBlack}
                                  alt=""
                                  width="25"
                                  height="30"
                                  style={{ position: "absolute" }}
                                  onClick={() =>
                                    this.setState({
                                      showPublicationYearTo:
                                        !this.state.showPublicationYearTo,
                                    })
                                  }
                                />
                              </div>
                              {this.state.showPublicationYearTo &&
                                this.state.dropdownValArr[0].value && (
                                  <>
                                    {this.publicationYearDropdownListTo(
                                      this.state.dropdownValArr[0].value
                                    )}
                                  </>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="fielded-search-limits-wrapper">
                          Congress Name
                          <div
                            className="limit-dropdown-wrapper"
                            onMouseLeave={(e) => {
                              this.setState({ showCongressName: false });
                              this.onDropDownClose("cnVal");
                            }}
                            onFocus={(e) =>
                              this.setState({ showCongressName: true })
                            }
                          >
                            <div className="limit-dropdown-inner-wrapper">
                              <input
                                type="text"
                                placeholder="Type Here"
                                title="Type Here"
                                className="limit-input-text-box"
                                value={this.state.cnVal}
                                onChange={this.handleCongressClick}
                              />
                              <img
                                src={dropdownArrowBlack}
                                alt=""
                                width="25"
                                height="27"
                                style={{ position: "absolute" }}
                                onClick={() =>
                                  this.setState({
                                    showCongressName:
                                      !this.state.showCongressName,
                                  })
                                }
                              />
                              {this.state.cnVal && (
                                <img
                                  src={crossIcon}
                                  alt=""
                                  width={12}
                                  height={12}
                                  style={{
                                    position: "absolute",
                                    marginRight: "25px",
                                  }}
                                  onClick={() => this.setState({ cnVal: "" })}
                                />
                              )}
                            </div>
                            {this.state.showCongressName &&
                              this.state.dropdownValArr[5].value && (
                                <>
                                  {this.congressNameDropdownList(
                                    this.state.dropdownValArr[5].value
                                  )}
                                </>
                              )}
                          </div>
                        </div>
                        <div className="fielded-search-limits-wrapper">
                          Document Type
                          <div
                            className="limit-dropdown-wrapper"
                            onMouseLeave={(e) => {
                              this.setState({ showDocumentType: false });
                              this.onDropDownClose("dtVal");
                            }}
                            onFocus={(e) =>
                              this.setState({ showDocumentType: true })
                            }
                          >
                            <div className="limit-dropdown-inner-wrapper">
                              <input
                                type="text"
                                placeholder="Type Here"
                                title="Type Here"
                                className="limit-input-text-box"
                                value={this.state.dtVal}
                                onChange={this.handleDocumentTypeClick}
                              />
                              <img
                                src={dropdownArrowBlack}
                                alt=""
                                width="25"
                                height="27"
                                style={{ position: "absolute" }}
                                onClick={() =>
                                  this.setState({
                                    showDocumentType:
                                      !this.state.showDocumentType,
                                  })
                                }
                              />
                              {this.state.dtVal && (
                                <img
                                  src={crossIcon}
                                  alt=""
                                  width={12}
                                  height={12}
                                  style={{
                                    position: "absolute",
                                    marginRight: "25px",
                                  }}
                                  onClick={() => this.setState({ dtVal: "" })}
                                />
                              )}
                            </div>
                            {this.state.showDocumentType &&
                              this.state.dropdownValArr[3].value && (
                                <>
                                  {this.documentTypeDropdownList(
                                    this.state.dropdownValArr[3].value
                                  )}
                                </>
                              )}
                          </div>
                        </div>
                        <div className="fielded-search-limits-wrapper">
                          Journal Name
                          <div
                            className="limit-dropdown-wrapper"
                            onMouseLeave={(e) => {
                              this.setState({ showJournalName: false });
                              this.onDropDownClose("jnVal");
                            }}
                            onFocus={(e) =>
                              this.setState({ showJournalName: true })
                            }
                          >
                            <div className="limit-dropdown-inner-wrapper">
                              <input
                                type="text"
                                placeholder="Type Here"
                                title="Type Here"
                                className="limit-input-text-box"
                                value={this.state.jnVal}
                                onChange={this.handleJournalNameClick}
                              />
                              <img
                                src={dropdownArrowBlack}
                                alt=""
                                width="25"
                                height="27"
                                style={{ position: "absolute" }}
                                onClick={() =>
                                  this.setState({
                                    showJournalName:
                                      !this.state.showJournalName,
                                  })
                                }
                              />
                              {this.state.jnVal && (
                                <img
                                  src={crossIcon}
                                  alt=""
                                  width={12}
                                  height={12}
                                  style={{
                                    position: "absolute",
                                    marginRight: "25px",
                                  }}
                                  onClick={() => this.setState({ jnVal: "" })}
                                />
                              )}
                            </div>
                            {this.state.showJournalName &&
                              this.state.dropdownValArr[2].value && (
                                <>
                                  {this.journalNameDropdownList(
                                    this.state.dropdownValArr[2].value
                                  )}
                                </>
                              )}
                          </div>
                        </div>
                        <div className="fielded-search-limits-wrapper">
                          Generic Name
                          <div
                            className="limit-dropdown-wrapper"
                            onMouseLeave={(e) => {
                              this.setState({ showGenericName: false });
                              this.onDropDownClose("gnVal");
                            }}
                            onFocus={(e) =>
                              this.setState({ showGenericName: true })
                            }
                          >
                            <div className="limit-dropdown-inner-wrapper">
                              <input
                                type="text"
                                placeholder="Type Here"
                                title="Type Here"
                                className="limit-input-text-box"
                                value={this.state.gnVal}
                                onChange={this.handleGenericNameClick}
                              />
                              <img
                                src={dropdownArrowBlack}
                                alt=""
                                width="25"
                                height="27"
                                style={{ position: "absolute" }}
                                onClick={() =>
                                  this.setState({
                                    showGenericName:
                                      !this.state.showGenericName,
                                  })
                                }
                              />
                              {this.state.gnVal && (
                                <img
                                  src={crossIcon}
                                  alt=""
                                  width={12}
                                  height={12}
                                  style={{
                                    position: "absolute",
                                    marginRight: "25px",
                                  }}
                                  onClick={() => this.setState({ gnVal: "" })}
                                />
                              )}
                            </div>
                            {this.state.showGenericName &&
                              this.state.dropdownValArr[4].value && (
                                <>
                                  {this.genericNameDropdownList(
                                    this.state.dropdownValArr[4].value
                                  )}
                                </>
                              )}
                          </div>
                        </div>
                        <div className="fielded-search-limits-wrapper">
                          Protocol Number
                          <div
                            className="limit-dropdown-wrapper"
                            onMouseLeave={(e) => {
                              this.setState({ showProtocolNumber: false });
                              this.onDropDownClose("pnVal");
                            }}
                            onFocus={(e) =>
                              this.setState({ showProtocolNumber: true })
                            }
                          >
                            <div className="limit-dropdown-inner-wrapper">
                              <input
                                type="text"
                                placeholder="Type Here"
                                title="Type Here"
                                className="limit-input-text-box"
                                value={this.state.pnVal}
                                onChange={this.handleProtocolNumberClick}
                              />
                              <img
                                src={dropdownArrowBlack}
                                alt=""
                                width="25"
                                height="27"
                                style={{ position: "absolute" }}
                                onClick={() =>
                                  this.setState({
                                    showProtocolNumber:
                                      !this.state.showProtocolNumber,
                                  })
                                }
                              />
                              {this.state.pnVal && (
                                <img
                                  src={crossIcon}
                                  alt=""
                                  width={12}
                                  height={12}
                                  style={{
                                    position: "absolute",
                                    marginRight: "25px",
                                  }}
                                  onClick={() => this.setState({ pnVal: "" })}
                                />
                              )}
                            </div>
                            {this.state.showProtocolNumber &&
                              this.state.dropdownValArr[1].value && (
                                <>
                                  {this.protocolNumberDropdownList(
                                    this.state.dropdownValArr[1].value
                                  )}
                                </>
                              )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div style={{ textAlign: "center", padding: "10%" }}>
                        <img
                          src={imsLoader}
                          alt="Loading Data"
                          style={{ width: "10%" }}
                        />
                      </div>
                    )}
                    <div className="fielded-search-button-wrapper">
                      <div className="fielded-search-button-inner-wrapper">
                        <span style={{ margin: "0 20px" }}>
                          <Button
                            text="Cancel"
                            customColor="#0074B0"
                            action={() => {
                              this.props.history.push("/");
                            }}
                          />
                        </span>
                        <span style={{ margin: "0 20px" }}>
                          <Button
                            text="Clear Form"
                            customColor="#f44336"
                            action={() => {
                              this.handleClear();
                            }}
                          />
                        </span>
                        <span style={{ margin: "0 20px" }}>
                          <Button
                            text="Search"
                            customColor="#2EB000"
                            action={() => {
                              this.handleSubmit();
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ margin: "0 1.5%" }}>
                  <Footer
                    customWidth={this.state.width}
                    customHeight={this.state.height}
                  />
                </div>
              </>
            ) : (
              <div style={{ textAlign: "center", padding: "10%" }}>
                <img
                  src={imsLoader}
                  alt="Loading Data"
                  style={{ width: "20%" }}
                />
              </div>
            )}
          </ErrorBoundary>
        </div>
      );
    }
  };
}
export default FieldedSearch;
