/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import { imsLoader, paginationLeftArrowMobile, paginationRightArrowMobile, crossIcon, magnifyingGlass, paginationDisabledRightArrowMobile, paginationDisabledLeftArrowMobile } from "../../assets";
import ApiServices from "../../utils/apiServices";
import { UtilityServices, DocumentPreviewOrderBy, DocumentPreviewSearch, LocationOrigin } from "../../utils/utilityServices";
import './DocumentPreview.css'
import DocumentPreviewChart from './DocumentPreviewChart';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const colorCode = UtilityServices.getDocumentPreviewKeywordColorCode();
const excludeKeywords = UtilityServices.getExcludeKeywords();


const onLoad = () => <div className="doc-preview-loader-wrapper"><img src={imsLoader} width={100} height={100} alt="Loader" /></div>;
const onNoItem = () => <div className="doc-preview-loader-wrapper">No Preview Available</div>
class DocumentPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numPages: 1,
            pageNumber: 1,
            isLoaded: true,
            documentSearchKeyword: props.searchKeyword ? props.searchKeyword : "",
            searchKeyword: '',
            searchKeywords: [],
            pageContent: [],
            pageCountResult: [],
            colors: [],
            series: [],
            categories: [],
            updateKey: false,
            strKeyword: '',
            orderBy: DocumentPreviewOrderBy.Hits,
            isShowSearchPanel: true,
            sliderValue: 49,
            scale: 1.0,
            synonyms: props.synonyms ? Object.assign([], props.synonyms) : [],
            isNotFount: false,
            fileExtension: "pdf",
            exportText: '',
        }
    }
    async componentDidMount() {
        if (this.props.fileName) {
            let fileExtension = this.props.fileName.split(".").pop();
            this.setState({
                fileExtension: fileExtension ? fileExtension : this.state.fileExtension
            })
        }
        let apiService = new ApiServices();
        let res = await apiService.getPreview(this.props.searchID);
        if (res) {
            this.setState({
                numPages: parseInt(res.page_count),
                isLoaded: true,
            }, async () => { await this.getSearchKeywordCountForPreview() });
        }
    }
    async getSearchKeywordCountForPreview() {
        let apiService = new ApiServices();
        let resSearchKeywordCount = await apiService.getSearchKeywordCountForPreview(this.props.searchID);
        if (resSearchKeywordCount) {
            this.setState({
                pageContent: resSearchKeywordCount
            }, async () => {
                await this.handleSynonymsAndKeywords();
            });
        }
    }

    //#region handle events
    handleOnClick = (status) => {
        if (status === 'increment' && parseInt(this.state.pageNumber) < this.state.numPages) {
            this.setState({
                pageNumber: this.state.pageNumber + 1
            })
        }
        else if (status === 'decrement' && parseInt(this.state.pageNumber) > 1) {
            this.setState({
                pageNumber: this.state.pageNumber - 1
            })
        }
    }

    handlePreviewClose = () => {
        this.props.handleClose(false)
    }

    handleChangeInSearch = (e) => {
        this.setState({
            searchKeyword: e.target.value,
            isNotFount: false
        })
    }

    handleKeyDown = async (e) => {
        if (["Enter"].includes(e.key)) {
            this.handleSearchKeywords();
        }
    }
    handleSynonymsAndKeywords = async () => {
        let { searchKeywords, synonyms, documentSearchKeyword } = this.state;
        synonyms.map((synonym) => {
            let searchWordCount = 0;
            this.state.pageContent.map((page, pageIndex) => {
                let pageText = page.page_text.replace(/[\r\n]/g, " ").replace(/\s+/g, " ");;
                searchWordCount += this.countOccurences(pageText.toLowerCase(), synonym.toLowerCase());
            })
            let isAvailableForAddinKeywords = searchKeywords.findIndex(i => i.keyword.toLowerCase().includes(synonym.toLowerCase())) === -1;

            if (searchWordCount > 0 && isAvailableForAddinKeywords) {
                let remainingColor = colorCode.filter(x => !searchKeywords.map(x => x.color).includes(x));
                let color = '#0d6efd'
                if (remainingColor.length > 0) {
                    color = remainingColor[0];
                }
                searchKeywords.push({ keyword: synonym, color: color, isSynonym: true });
            }
        })

        if (searchKeywords.length === 0 && documentSearchKeyword) {
            let searchKeywordArray = documentSearchKeyword.split(' ');
            searchKeywordArray.map(async (skwItem, skwIndex) => {
                if (skwItem &&
                    !excludeKeywords.includes(skwItem.toLowerCase())
                    &&
                    searchKeywords.findIndex(c => c.keyword.toLowerCase().includes(skwItem.toLowerCase())) === -1) {
                    let remainingColor = colorCode.filter(x => !searchKeywords.map(x => x.color).includes(x));
                    let color = '#0d6efd'
                    if (remainingColor.length > 0) {
                        color = remainingColor[0];
                    }
                    searchKeywords.push({ keyword: skwItem, color: color, isSynonym: false });

                }
            })
        }
        this.setState({
            searchKeywords: searchKeywords,
            searchKeyword: ''
        }, async () => { await this.onSearchKeyword() });
    }
    handleSearchKeywords = async () => {
        let { searchKeywords, searchKeyword, synonyms } = this.state;
        if (searchKeyword) {
            // let searchKeywordArray = searchKeyword.split(' ');
            // searchKeywordArray.map(async (skwItem, skwIndex) => {
            //     if (skwItem &&
            //         !excludeKeywords.includes(skwItem.toLowerCase())
            //         &&
            //         searchKeywords.findIndex(c => c.keyword.toLowerCase().includes(skwItem.toLowerCase())) === -1) {
            //         let remainingColor = colorCode.filter(x => !searchKeywords.map(x => x.color).includes(x));
            //         let color = '#0d6efd'
            //         if (remainingColor.length > 0) {
            //             color = remainingColor[0];
            //         }
            //         searchKeywords.push({ keyword: skwItem, color: color, isSynonym: false });

            //     }
            // })
            synonyms.push(this.removeExtraSpace(searchKeyword));
            this.setState({
                searchKeywords: searchKeywords,
                synonyms: synonyms,
                //searchKeyword: ''
            }, async () => {
                await this.handleSynonymsAndKeywords();
                //await this.onSearchKeyword();
            });
        }
    }

    handleOnClickSearchIcon = async (e) => {
        this.setState({
            isNotFount: false
        })

        this.handleSearchKeywords();
    }

    handleCancelSearchKeyword = (e) => {
        this.setState({
            searchKeyword: ''
        })
    }

    handleKewordRemove = async (keyword) => {
        let { searchKeywords, synonyms } = this.state;
        searchKeywords = searchKeywords.filter(c => c.keyword !== keyword);
        synonyms = synonyms.filter(c => c !== keyword);
        this.setState({
            searchKeywords: searchKeywords,
            synonyms: synonyms
        }, async () => { await this.onSearchKeyword() });
    }

    onOrderByValueChange = async (event) => {
        this.setState({
            orderBy: event.target.value
        }, async () => { await this.onSearchKeyword() });
    }

    handleSearchAndCollapseOnClick = async (clickBtnFor) => {
        if (clickBtnFor === DocumentPreviewSearch.Search) {
            this.setState({
                isShowSearchPanel: !this.state.isShowSearchPanel
            });
        }
        else {
            this.setState({
                isShowSearchPanel: false
            });
        }
    }

    handleStackBarOnClick = (pageNumber) => {
        this.setState({
            pageNumber: pageNumber
        })
    }

    handleZoomSliderOnChange = (value) => {
        let scale = (value / 10) * 0.2;
        this.setState({
            sliderValue: value,
            scale: scale
        })
    }
    onRangeValueChange = (event) => {
        let sliderValue = parseInt(event.target.value);
        let scale = (sliderValue / 10) * 0.2;
        this.setState({
            sliderValue: sliderValue,
            scale: scale
        });
    }

    handleZoomButtonOnClick = (status) => {
        let { sliderValue } = this.state;

        if (status === 'increment' && sliderValue === 49) {
            sliderValue += 11;
        }
        else if (status === 'decrement' && sliderValue === 49) {
            sliderValue -= 9;
        }
        else if (status === 'increment' && sliderValue === 35) {
            sliderValue += 5;
        }
        else if (status === 'decrement' && sliderValue === 35) {
            sliderValue -= 5;
        }
        else if (status === 'increment' && sliderValue !== 200) {

            sliderValue += 10;
        }
        else if (status === 'decrement' && sliderValue !== 10) {
            sliderValue -= 10;
        }
        let scale = (sliderValue / 10) * 0.2;
        this.setState({
            sliderValue: sliderValue,
            scale: scale
        })

    };

    handleFitToPageWidthButtonOnClick = (status) => {
        this.setState({
            sliderValue: 49,
            scale: 1
        })

    }
    handleFitFullPageButtonOnClick = (status) => {
        this.setState({
            sliderValue: 35,
            scale: 0.70
        })
    }
    //#endregion 

    //#region utility methods
    onSearchKeyword = async () => {
        const { orderBy } = this.state;
        let { searchKeywords, searchKeyword } = this.state;
        let pageCountResult = [];
        let isNotFount = false;
        this.state.pageContent.map((page, pageIndex) => {
            let pageText = page.page_text.replace(/[\r\n]/g, " ").replace(/\s+/g, " ");;
            let pageTextSplitArray = pageText.split(' ');
            let count = 0;
            let result = {};

            searchKeywords.map((searchWord, searchWordIndex) => {
                let searchWordCount = 0;
                if (searchWord.isSynonym) {
                    searchWordCount = this.countOccurences(pageText.toLowerCase(), searchWord.keyword.toLowerCase());
                    count += searchWordCount;
                } else {
                    pageTextSplitArray.map((i, index) => {
                        if (i.toLowerCase().includes(searchWord.keyword.toLowerCase())) {
                            count++;
                            searchWordCount++;
                        }
                    })
                }
                result[searchWord.keyword] = searchWordCount;
            })
            result = { ...result, pageNumber: page.page_number, totalCount: count }
            pageCountResult.push(result)
        })
        if (orderBy === DocumentPreviewOrderBy.Hits) {
            pageCountResult = pageCountResult.sort((a, b) => parseFloat(b.totalCount) - parseFloat(a.totalCount));
        } else {
            pageCountResult = pageCountResult.sort((a, b) => parseFloat(a.pageNumber) - parseFloat(b.pageNumber));
        }

        searchKeywords = searchKeywords.filter((searchWord, searchWordIndex) => {
            let searchWordCountArray = pageCountResult.map(x => x[searchWord.keyword]);
            const sum = searchWordCountArray.reduce((partialSum, a) => partialSum + a, 0);
            return sum !== 0;
        })
        const categories = pageCountResult.filter(x => x.totalCount > 0).map(c => `p${c.pageNumber}`);
        let series = [];
        searchKeywords.map((searchWord, searchWordIndex) => {
            let data = pageCountResult.filter(x => x.totalCount > 0).map(x => x[searchWord.keyword]);
            series.push({ name: searchWord.keyword, data: data })
        })
        const colors = searchKeywords.map(c => c.color);
        let strKeyword = searchKeywords.map(c => `${encodeURIComponent(c.keyword)}ǂ$${c.color.replace("#", "")}`).join("±@");
        if (searchKeyword) {
            let searchKeywordArray = searchKeyword.split(' ');
            isNotFount = !searchKeywordArray.some(x => searchKeywords.map((searchWord) => searchWord.keyword).includes(x))

        }


        await this.setState({
            pageCountResult: pageCountResult,
            categories: categories,
            series: series,
            colors: colors,
            searchKeywords: searchKeywords,
            updateKey: !this.state.updateKey,
            strKeyword: strKeyword,
            isNotFount: isNotFount,
            searchKeyword: ""
        })

    }
    removeExtraSpace = s => s.trim().split(/ +/).join(" ");
    snippetLongString = (snippet) => {
        if (snippet.length > 70) {
            let text = snippet.substring(0, 70)//cuts to 70
            let last = text.lastIndexOf(" ")//gets last space (to avoid cutting the middle of a word)
            text = text.substring(0, last)//cuts from last space (to avoid cutting the middle of a word)
            text = text + ` ...`//adds (...) at the end to show that it's cut
            return text
        }
        return snippet;
    }
    snippetLongStringForKeyword = (snippet) => {
        if (snippet.length > 15) {
            let text = snippet.substring(0, 15)//cuts to 70
            text = text + `...`//adds (...) at the end to show that it's cut
            return text
        }
        return snippet;
    }
    countOccurences = (string, word) => {
        string = string.trim();
        const wordArray = word.trim().split(/\s+/);
        if (wordArray.length === 1) {
            let stringArray = string.trim().split(/[ ,]+/).filter(x => x === word);
            return stringArray.length;
        } else {
                let isStartwith = string.startsWith(`${word} `) ? 1 : 0;
                let isEndsWith = string.endsWith(` ${word}`) ? 1 : 0;
                return string.split(` ${word} `).length - 1 + isStartwith + isEndsWith;
            }
        }
    //#endregion

    //#region render methods
    renderSearchKeyworkResult = () => {
        const { pageCountResult, searchKeywords } = this.state;
        return searchKeywords.map((searchWord, searchWordIndex) => {
            let searchWordCountArray = pageCountResult.map(x => x[searchWord.keyword]);
            const sum = searchWordCountArray.reduce((partialSum, a) => partialSum + a, 0);
            return (<>
                <div className="doc-preview-keword-title" key={`doc-preview-keword-title${searchWordIndex}`}>
                    <span onClick={e => this.handleKewordRemove(searchWord.keyword)} style={{ cursor: "pointer" }}><img src={crossIcon} width={10} height={10} alt="Cross" /> </span>
                    <span title={searchWord.keyword.toLowerCase()} style={{ flex: "1", marginLeft: "5px" }}>{this.snippetLongStringForKeyword(searchWord.keyword.toLowerCase())} ({sum})</span>
                    <div className="doc-preview-keword-color-box" style={{ background: `${searchWord.color}` }} />
                </div>
            </>);
        })
    }

    renderSearchKeyworkChart = () => {
        const { searchKeywords, categories, series, colors, updateKey } = this.state;
        return (
            <>{(categories && series && colors && searchKeywords.length > 0 && categories.length > 0) &&
                <DocumentPreviewChart key={`DocumentPreviewChart-${updateKey}`}
                    categories={categories} series={series} colors={colors} handleStackBarOnClick={this.handleStackBarOnClick} />}</>
        )
    }
    //#endregion
    setDocPreviewInnerWrapperHeight = () => {
        const { fileExtension } = this.state;
        if (fileExtension === "pptx" || fileExtension === "ppt") {
            return { height: "390px" }
        } else {
            return { height: "475px" };
        }
    }

    handleCopy = () => {
        document.execCommand('copy', true);
        navigator.clipboard.readText()
            .then(
                cliptext => {
                    let _exportText = this.state.exportText ? `${this.state.exportText} ${cliptext}` : cliptext;
                    this.setState({ exportText: _exportText })
                }, err => console.log(err));
    }

    onDocumentClick = (event, page) => {
        const textLayers = document.querySelectorAll("div.react-pdf__Page__textContent > span");
        // textLayers.forEach(layer => {
        //     if (layer.innerHTML && !layer.innerHTML.trim().endsWith('-')) {
        //         layer.innerHTML = `${layer.innerHTML} `;
        //     }
        // });
        for (let i = 0; i < textLayers.length; i++) {
            let layer = textLayers[i];
            let currentLayerTop = parseFloat(textLayers[i].style.top);
            let previousLayerTop = 0;
            if (i - 1 >= 0) {
                let previousLayer = textLayers[i - 1];
                previousLayerTop = parseFloat(previousLayer.style.top);
                if (currentLayerTop !== previousLayerTop && !previousLayer.innerHTML.trim().endsWith('-')) {
                    layer.innerHTML = ` ${layer.innerHTML}`;
                }
            }
        }
    }
    render() {
        const { pageCountResult, strKeyword, orderBy, isShowSearchPanel, sliderValue, scale, isNotFount, fileExtension } = this.state;
        return (
            <div className="doc-preview-wrapper" style={{ width: !isShowSearchPanel && "54.55%", height: (fileExtension === "pptx" || fileExtension === "ppt") && "500px", top: (fileExtension === "pptx" || fileExtension === "ppt") && "100px" }}>
                {isShowSearchPanel && <div className="doc-preview-wrapper-left">
                    <div className="doc-preview-input-box-title-wrapper"><span style={{ flex: "1" }}>Search inside</span>
                        <span onClick={e => this.handleSearchAndCollapseOnClick(DocumentPreviewSearch.Collapse)} style={{ cursor: "pointer", float: "right" }}> <button className="documentViewerbutton collapse-button" ></button></span></div>
                    <div className="doc-preview-input-box-wrapper">

                        <input name="searchBar" placeholder="Search..." className="doc-preview-input-search-box" value={this.state.searchKeyword} onChange={this.handleChangeInSearch} onKeyDown={this.handleKeyDown} />
                        <span className="doc-preview-icon-wrapper">
                            {this.state.searchKeyword &&
                                <img
                                    src={crossIcon}
                                    className="doc-preview-input-search-cancel-icon"
                                    alt="clear"
                                    title="Click here to clear the search terms"
                                    onClick={this.handleCancelSearchKeyword}
                                />
                            }
                            <span className="doc-preview-search-icon-wrapper-webView" onClick={this.handleOnClickSearchIcon}>
                                <img
                                    src={magnifyingGlass}
                                    className="magnifyingGlass-mobileView"
                                    alt="search"
                                    title="Search"
                                />
                            </span>
                        </span>
                        {isNotFount && <span className="doc-preview-warning-msg">There is no result found.</span>}
                    </div>

                    <div className="doc-preview-page-count-result">
                        {pageCountResult && <>
                            <div className="doc-preview-search-results-title"><span style={{ flex: "1" }}>Search results:</span></div>
                            <div className="doc-preview-page-count-results-wrapper" style={{ maxHeight: (fileExtension === "pptx" || fileExtension === "ppt") ? "350px" : "440px" }}>
                                <div className="doc-preview-page-count-results">
                                    {this.renderSearchKeyworkResult()}
                                    <div className="doc-preview-page-count-chart">
                                        <p className="doc-preview-search-results-split"></p>
                                        <div className="doc-preview-OrderBy-wrapper">
                                            <label>Order by: </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value={DocumentPreviewOrderBy.Hits}
                                                    checked={orderBy === DocumentPreviewOrderBy.Hits}
                                                    onChange={this.onOrderByValueChange}
                                                />
                                                {DocumentPreviewOrderBy.Hits}
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value={DocumentPreviewOrderBy.Pages}
                                                    checked={orderBy === DocumentPreviewOrderBy.Pages}
                                                    onChange={this.onOrderByValueChange}
                                                />
                                                {DocumentPreviewOrderBy.Pages}
                                            </label>
                                        </div>
                                        {false && <div>
                                            <button className="btnExportText" type="button" onClick={e => this.handleCopy()}>Export Text</button>
                                            <textarea className="txtExportText" title={this.state.exportText} readOnly value={this.state.exportText} name="exportText" />
                                            <button className="btnExportText" type="button" onClick={e => { this.setState({ exportText: '' }) }}>Clear Text</button>
                                        </div>}

                                        {this.renderSearchKeyworkChart()}
                                    </div>
                                </div>
                            </div>
                        </>}


                    </div>
                </div>}

                <div className="doc-preview-wrapper-right" style={{ width: !isShowSearchPanel && "100%" }}>
                    {this.props.showPreview &&
                        <>
                            <div className="doc-preview-title-wrapper"><span style={{ flex: "1" }}>{this.snippetLongString(this.props.docTitle)}</span><span onClick={e => this.handlePreviewClose()} style={{ cursor: "pointer" }}><img src={crossIcon} width={10} height={10} alt="Cross" /></span></div>
                            <div className={(fileExtension && (fileExtension === "pptx" || fileExtension === "ppt")) ? "doc-preview-inner-wrapper-ppt" : "doc-preview-inner-wrapper"}
                            //style={this.setDocPreviewInnerWrapperHeight()}
                            >
                                {/* width="515" height="275" */}
                                <Document file={`${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}PreviewService/Preview?strID=${this.props.searchID}&strPageNum=${this.state.pageNumber}&strKeyword=${strKeyword}`} loading={onLoad} error={onNoItem} >
                                    {fileExtension && (fileExtension === "pptx" || fileExtension === "ppt") ?
                                        <Page pageNumber={1} scale={scale} width="670" height="200" renderMode="canvas" onRenderSuccess={this.onDocumentClick} /> :
                                        <Page pageNumber={1} scale={scale} width="700" height="200" renderMode="canvas" onRenderSuccess={this.onDocumentClick} />}
                                </Document>
                            </div>
                            {this.state.numPages >= 1 &&
                                <div className="doc-preview-controle-wrapper">
                                    <div className="doc-preview-pagination-wrapper">
                                        {this.state.pageNumber === 1 ?
                                            <img src={paginationDisabledLeftArrowMobile} alt="" style={{ cursor: "not-allowed" }} width={17} height={17} /> :
                                            <img src={paginationLeftArrowMobile} alt="" onClick={e => this.handleOnClick('decrement')} width={17} height={17} />
                                        }
                                        <span>Page {this.state.pageNumber} of {this.state.numPages}</span>
                                        {this.state.pageNumber === this.state.numPages ?
                                            <img src={paginationDisabledRightArrowMobile} alt="" style={{ cursor: "not-allowed" }} width={17} height={17} /> :
                                            <img src={paginationRightArrowMobile} alt="" onClick={e => this.handleOnClick('increment')} width={17} height={17} />
                                        }

                                    </div>
                                    <div className="doc-preview-zoom-wrapper">
                                        <button className="documentViewerbutton showSearch" title="Search Within Preview" onClick={e => this.handleSearchAndCollapseOnClick(DocumentPreviewSearch.Search)}></button>
                                        <button className="documentViewerbutton zoomFitWidth" title="Fit to Page Width" onClick={e => this.handleFitToPageWidthButtonOnClick('decrement')}></button>
                                        <button className="documentViewerbutton zoomFitPage" title="Fit Full Page" onClick={e => this.handleFitFullPageButtonOnClick('decrement')}></button>

                                        <span className="documentzoomPercentagebutton">{sliderValue}%</span>
                                        <button className="documentViewerbutton zoomOut" onClick={e => this.handleZoomButtonOnClick('decrement')}></button>
                                        <div className="doc-preview-zoom-inner">
                                            <input type="range" value={sliderValue} onChange={e => this.onRangeValueChange(e)} min="10" max="200" step="10" className="slider" id="myRange" />
                                        </div>
                                        <button className="documentViewerbutton zoomIn" onClick={e => this.handleZoomButtonOnClick('increment')}></button>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        )
    }
}
export default DocumentPreview;