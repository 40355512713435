import { Link } from 'react-router-dom';
import "./History.css";
import trackingService from "../../services/trackingService";
function RecentlyViewed(props) {
    const RenderList = () => {
        const { Result } = props;
        const indexData = (item) => {
            var title = item.title;
            var products = item.docproducts;
            var docnumber = item.docnumber
            var docversion = item.version;
            var contentsource = item.contentsource;
            var docID = item.docid;
            var docType = item.doctype;
            var docUrl = item.docurl;
            var doclastModified = item.lastmodified;
            if (JSON.stringify(doclastModified) === "[null]") {
                doclastModified = "";
            }
            // let params = new URLSearchParams(window.location.search)
            // let csPath = params.get('cspath');
            // let csproduct = params.get('csproduct');
            const ts = new trackingService();
            ts.indexTrackingData(
                localStorage.getItem("userdisplayName"),
                localStorage.getItem("wwid"),
                localStorage.getItem("username"),
                localStorage.getItem("function"),
                localStorage.getItem("region"),
                localStorage.getItem("userproducts"),
                localStorage.getItem("doctypepref"),
                localStorage.getItem("sid"),
                localStorage.getItem("deviceType"),
                localStorage.getItem("deviceOS"),
                localStorage.getItem("browserName"),
                localStorage.getItem("browserVersion"),
                localStorage.getItem("jnjDepartment"),
                localStorage.getItem("companyname"),
                "View OR Download Document",
                "Direct",
                "Recently Viewed Component",
                '',
                '',
                title,
                docversion,
                docnumber,
                docID,
                docUrl,
                products,
                docType,
                "View Document",
                "",
                "",
                contentsource,
                item.pagenumber,
                item.pagesize,
                doclastModified
            );
        };
        if (Result.length > 0) {
            return (
                <ul className='ul-recently-viewed'>
                    {
                        Result.map((item, index) => {
                            return (
                                <li key={`RecentlyViewed-${index}`} className="resultHistorysDiv">
                                    {(item.docurl && item.docurl.startsWith('https://jnj.sharepoint.com/')) ?
                                        <Link id={`PopupRecentlyViewed-${index}`} title={item.title}  to={{ pathname: item.docurl }} target="_blank" style={{ display: "-webkit-box", WebkitLineClamp: "1", WebkitBoxOrient: "vertical", overflow: "hidden", width: "100%", minHeight: "20px" }} onClick={() => indexData(item)}>
                                            {item.title}
                                        </Link>
                                        :
                                        <Link id={`PopupRecentlyViewed-${index}`} title={item.title} to={item.docurl} target="_blank" style={{ display: "-webkit-box", WebkitLineClamp: "1", WebkitBoxOrient: "vertical", overflow: "hidden", width: "100%", minHeight: "20px" }} onClick={() => indexData(item)}>
                                            {item.title}
                                        </Link>}
                                </li>
                            )
                        })
                    }
                </ul>
            );
        } else {
            return (
                <ul>
                    <li>It seems you haven't viewed any documents recently.</li>
                </ul>
            )
        }
    }

    return (<>
        <b style={{ color: '#333' }}> Recently Viewed: </b>
        {RenderList()}
    </>);
}

export default RecentlyViewed;