import React, { Component } from 'react';
import { IMSLogo } from '../../assets';
import { LocationOrigin } from "../../utils/utilityServices";
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openPopUp: false,
            showNav: false
        }
    }
    handleOnHover = () => {
        this.setState({
            openPopUp: true
        })
    }
    handleOnHoverLeave = () => {
        this.setState({
            openPopUp: false
        })
    }
    handleNav = () => {
        this.setState({
            showNav: !this.state.showNav
        })
    }
    handleClose = () => {
        this.setState({
            showNav: false
        })
    }
    render() {
        let src=`${LocationOrigin}${IMSLogo}`;
        return (
            <div className="headerWithBannerWrapper">
                <div className="bannerImage">
                    <div className="headerTitle">
                        <img src={src} alt="iMedical Search" style={{ width: "20%", paddingTop: "2%" }} />
                    </div>
                </div>
            </div>
        );
    }
}
export default Header;