/* eslint-disable import/no-anonymous-default-export */
import OpenWindow from "../OpenWindow";
import {infoIcn} from "../../assets"

const handleOpenWindow=(pubMedId)=>{
    let url = `https://www.rightfind.com/vlib/order/orderform.aspx?ClientId=60&qlu=${pubMedId}`
    OpenWindow(url)
}
export default function(props){
    const{
        pubMedId
    }=props
    return(
        <div className="citation-right-find-descriptor-wrapper">
            <div className="citation-right-find-descriptor-header-wrapper" onClick={e=>handleOpenWindow(pubMedId)}>
              <span style={{verticalAlign:"middle",paddingRight:"15px"}}>Order from RightFind for additional usages </span>	
              <img src={infoIcn} alt="infoIcn" className="order-info-icn" title="You have the digital rights to use the full document associated with this citation for any internal purpose. For other usages, including External Reactive Use, Proactive Use, Transmission to a Regulatory Agency, or Special Orders of charts, tables, etc., click on this option to order via RightFind and select the appropriate usage option."/>
             </div>
        </div>
    )
}