import { Component } from "react";
import { functionIcon, functionIconBlack } from '../../assets';
import Button from '../../components/Button';
import MobileViewButton from "../../components/Button/MobileViewButton";
import AlertMessagePopUpModal from "../../components/PopUp/AlertMessagePopUpModal";

class Functions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCountry: [],
            selectedRegion: [],
            gdprAcceptance: '',
            profession: '',
            products: [],
            contentPreference: '',
            documentPreference: [],
            selectedAreas: [],
            selectedClasses: [],
            areaId: [],
            classId: [],
            productId: [],
            history: this.props.history,
            alertMessageModelColor: "",
            alertMessageContent: "",
            alertMessageShowModal: false,
        }
        //console.log(this.props);
    }
    componentDidMount() {
        this.setState({
            selectedCountry: this.props.selectedCountry,
            selectedRegion: this.props.selectedRegion,
            gdprAcceptance: this.props.gdprAcceptance,
            profession: this.props.profession,
            products: this.props.products,
            contentPreference: this.props.contentPreference,
            documentPreference: this.props.documentPreference,
            selectedAreas: this.props.selectedAreas,
            selectedClasses: this.props.selectedClasses,
            areaId: this.props.areaId,
            classId: this.props.classId,
            productId: this.props.productId
        })
        //console.log(this.state);
    }
    errorAlertMessage = (message) => {
        this.setState({
          alertMessageContent: message,
          alertMessageModelColor: "#de1327",
          alertMessageShowModal: true
        })
      }
      closeAlertMessage = () => {
        this.setState({
          alertMessageContent: "",
          alertMessageModelColor: "",
          alertMessageShowModal: false
        })
      }
    handleFuncNxt = () => {
        //console.log(this.state);
        if (this.state.profession === '' || this.state.profession === null) {
            this.errorAlertMessage('Please select your Role to proceed.');
        }
        else {
            this.props.onClickNextOfFunc(this.state.selectedRegion, this.state.selectedCountry, this.state.gdprAcceptance, this.state.profession, this.state.products, this.state.contentPreference, this.state.documentPreference, this.state.selectedAreas, this.state.selectedClasses, this.state.history, this.state.areaId, this.state.classId, this.state.productId);
        }

    }
    handleFuncPrv = () => {
        //console.log(this.state);
        this.props.onClickPreviousToLoc(this.state.selectedRegion, this.state.selectedCountry, this.state.gdprAcceptance, this.state.profession, this.state.products, this.state.contentPreference, this.state.documentPreference, this.state.selectedAreas, this.state.selectedClasses, this.state.history, this.state.areaId, this.state.classId, this.state.productId);
    }
    handleOnChange = (event) => {
        this.setState({
            profession: event.target.value
        }, this.handleChangeInFuncValue);
    }
    handleChangeInFuncValue = () => {
        this.props.onChangeInFunc(this.state.profession)
    }
    render() {
        const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
        return (
            <>
            <div className="registrationInnerWrapper">
                {/* <div className="welcomePageTitle">
                </div> */}
                <div className="functionSelectContent">
                    <div className="functionTitlePart">
                        <img src={this.props.customWidth < 1000 ? functionIconBlack : functionIcon} alt="function-iocn" />
                        <div className="functionTitle">Select Your Role</div>
                    </div>
                    {/* <div className="registrationSub-heading">
                    <b className="locationTextTitle">Streamline your searches</b>
                    </div>
                    <br /> */}
                    <div style={{ color: "#F80000" }}>
                        <p><b>PLEASE NOTE: </b>Upon completion of the registration process, you will have immediate access to publically accessible content (i.e., medical literature, labeling documents, and ClinicalTrials.gov records).</p>
                        <p>Access to Submission-Related Documents, Slide Decks for Presentation/Dissemination, and Scientific Reponse Documents requires approval. If you select the <b>Medical/Scientific</b> option, you will receive an e-mail notifying you whether or not you've been approved for access.</p>
                    </div><br />
                    <div>
                        <p>
                            <input type="radio" className="" name="profMedical" value="Medical" onChange={this.handleOnChange} checked={this.state.profession === 'Medical'} class="k-radio" />
                            <span className="" style={{ margin: "0px 1px 0px 10px", verticalAlign: "middle" }}><strong>Medical/Scientific</strong></span><br />
                            <span className="functionTabChoiceTxt">Please select if you have a medical or scientific role (e.g., Scientific and Medical Affairs, Regulatory, etc.).</span><br />

                            <input type="radio" className="" name="profCommercial" value="Commercial" onChange={this.handleOnChange} checked={this.state.profession === 'Commercial'} class="k-radio" />
                            <span className="" style={{ margin: "0px 1px 0px 10px", verticalAlign: "middle" }}><strong>Commercial</strong></span><br />
                            <span className="functionTabChoiceTxt">Please select if your role is commercial (e.g., Marketing, Sales, etc.).</span><br />

                            {/* <input type="radio" className="" name="profR&D" value="R&D" onChange={this.handleOnChange} checked={this.state.profession === 'R&D'} class="k-radio" />
                            <span className="" style={{ margin: "0px 1px 0px 10px", verticalAlign: "middle" }}><strong>R&D</strong></span><br />
                            <span className="functionTabChoiceTxt">Please select if you are from R&D.</span> */}
                        </p>
                    </div>
                    {/* <div><br /></div> */}
                    {this.props.customWidth < 600 ?
                        <div className="gsf-popup-button-wrapper-mobile">
                            <div className="welcomeNxtBtn-mobile">
                                <MobileViewButton text='Continue' customColor='#2EB000' action={this.handleFuncNxt} className="continueButton" />
                            </div>
                            <div className="welcomeNxtBtn-mobile">
                                <MobileViewButton text='Previous' customColor='#FF8000' action={this.handleFuncPrv} />
                            </div>
                            <div className="welcomeNxtBtn-mobile">
                                <MobileViewButton text='Cancel' customColor='#0074B0' action={() => { this.props.history.push('/') }} />
                            </div>
                        </div>
                        :
                        <div className="buttonContainer">
                            <div className="welcomeNxtBtn">
                                <Button text='Continue' customColor='#2EB000' action={this.handleFuncNxt} className="continueButton" />
                            </div>
                            <div className="welcomeNxtBtn">
                                <Button text='Previous' customColor='#FF8000' action={this.handleFuncPrv} />
                            </div>
                            <div className="welcomeNxtBtn">
                                <Button text='Cancel' customColor='#0074B0' action={() => { this.props.history.push('/') }} />
                            </div>
                        </div>
                    }
                </div>
            </div>
            {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={()=>this.closeAlertMessage()} />}
            </>
        );
    }

}
export default Functions;