import {
    faChevronDown,
    faChevronRight,
    faChevronLeft,
    faCaretRight,
    faCaretDown,
    faTimes,
    faSearch,
    faBars,
    faHome,
    faInfoCircle,
    faBell,
    faPlus,
    faMinus,
    faThumbsUp,
    faThumbsDown
  } from '@fortawesome/free-solid-svg-icons'
  import { library } from '@fortawesome/fontawesome-svg-core'
  
  library.add(
    faChevronDown,
    faChevronRight,
    faChevronLeft,
    faCaretRight,
    faCaretDown,
    faTimes,
    faSearch,
    faBars,
    faHome,
    faInfoCircle,
    faBell,
    faPlus,
    faMinus,
    faThumbsUp,
    faThumbsDown
  )
  