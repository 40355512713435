/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import { imsLoader, paginationLeftArrowMobile, paginationRightArrowMobile, crossIcon, magnifyingGlass, paginationDisabledRightArrowMobile, paginationDisabledLeftArrowMobile, viewMoreArrowWhite, sideArrowWhite, viewMoreArrowGray } from "../../assets";
import ApiServices from "../../utils/apiServices";
import { UtilityServices, DocumentPreviewOrderBy, DocumentPreviewSearch, LocationOrigin } from "../../utils/utilityServices";
import './DocumentPreview.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import DocumentPreviewChart from './DocumentPreviewChart';
import DocumentPreviewRecommendations from './../../components/ChatBot/DocumentPreviewRecommendations';
import axios from "axios";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const colorCode = UtilityServices.getDocumentPreviewKeywordColorCode();
const excludeKeywords = UtilityServices.getExcludeKeywords();


const onLoad = () => <div className="doc-preview-loader-wrapper"><img src={imsLoader} width={100} height={100} alt="Loader" /></div>;
const onNoItem = () => <div className="doc-preview-loader-wrapper">No Preview Available</div>
class NlpDocumentPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numPages: 1,
            pageNumber: 1,
            isLoaded: true,
            highlightVal: props.highlightVal,
            documentSearchKeyword: props.searchKeyword ? props.searchKeyword : '',
            searchKeyword: '',
            searchKeywords: [],
            pageContent: [],
            pageCountResult: [],
            colors: [],
            series: [],
            categories: [],
            nlpflag: props.nlpflag,
            updateKey: false,
            strKeyword: '',
            orderBy: DocumentPreviewOrderBy.Hits,
            isShowSearchPanel: true,
            sliderValue: 40,
            scale: 0.80,
            //scale: (this.getScaleBasedOnScreen(40) / 10) * 0.2,
            synonyms: props.synonyms ? Object.assign([], props.synonyms) : [],
            //synonyms:[props.highlightVal],
            isNotFount: false,
            fileExtension: "pdf",
            isProcessing: true,
            base64: '',
            highlightVals: [],
            originalHighlightVals: [],
            answerSectionIndex: -1,
            isHighlight: true,
            txtPageNumber: 1,
            showChart: false,
            isShowMore: false,
            searchID: this.props.searchID,
            docTitle: this.props.docTitle,
            downloadUrl: this.props.downloadUrl,
            customWidth: props.customWidth ? props.customWidth : window.innerWidth,
            customHeight: props.customHeight ? props.customHeight : window.innerHeight,
        }
        this.getScaleBasedOnScreen = this.getScaleBasedOnScreen.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.customWidth !== nextProps.customWidth || this.state.customHeight !== nextProps.customHeight) {
            this.setState({
                customWidth: nextProps.customWidth,
                customHeight: nextProps.customHeight
            })
        }
    }
    async componentDidMount() {
        this.setState({
            scale: (this.getScaleBasedOnScreen(40) / 10) * 0.2,
        })
        if (this.props.fileName) {
            let fileExtension = this.props.fileName.split(".").pop();
            this.setState({
                fileExtension: fileExtension ? fileExtension : this.state.fileExtension
            })
        }
        if (this.props.highlightVals && this.props.highlightVals.length > 0) {
            this.setState({
                pageNumber: this.props.highlightVals[0].PageNumber,
                txtPageNumber: this.props.highlightVals[0].PageNumber,
                originalHighlightVals: this.props.highlightVals
            })
        } else if (this.props.pageNumber) {
            this.setState({
                pageNumber: this.props.pageNumber,
                txtPageNumber: this.props.pageNumber,
            })
        }
        let apiService = new ApiServices();
        let res = await apiService.getPreview(this.state.searchID);
        if (res) {
            this.setState({
                numPages: parseInt(res.page_count),
                isLoaded: true,
            }, async () => { await this.getSearchKeywordCountForPreview() });
        }
    }
    async getSearchKeywordCountForPreview() {
        let apiService = new ApiServices();
        let resSearchKeywordCount = await apiService.getSearchKeywordCountForPreview(this.state.searchID);
        if (resSearchKeywordCount) {
            this.setState({
                pageContent: resSearchKeywordCount
            }, async () => {
                this.redirectHighlightPageForPreview();
                await this.handleSynonymsAndKeywords();
            });
        }
    }
    redirectHighlightPageForPreview() {
        try {
            let { pageContent, originalHighlightVals } = this.state;
            let { highlightVal, highlightVals } = this.props;
            let highlightPreviewCountWithPage = []
            if ((originalHighlightVals && originalHighlightVals.length > 0)) {

                let _highlightVals = []
                originalHighlightVals.map((highlight) => {
                    highlightPreviewCountWithPage = [];
                    if (highlight.PageNumber === 'No') {
                        let splitedHighlightValue = highlight.highlightVal.split(/\W+/);
                        pageContent.map((page, pageIndex) => {
                            let pageText = page.page_text.replace(/[\r\n]/g, " ").replace(/\s+/g, " ");;
                            let splitedPageText = pageText.split(/\W+/);
                            const filteredArray = splitedHighlightValue.filter(value => splitedPageText.includes(value));
                            const containsAll = splitedHighlightValue.every(element => {
                                return splitedPageText.includes(element);
                            });
                            highlightPreviewCountWithPage.push({ pageNumber: page.page_number, count: filteredArray.length, previewItem: filteredArray, isContainsAll: containsAll })
                        })

                        if (highlightPreviewCountWithPage.length > 0) {
                            if (highlightPreviewCountWithPage.some(x => x.isContainsAll === true)) {
                                highlightPreviewCountWithPage = highlightPreviewCountWithPage.filter(x => x.isContainsAll === true)
                                    .sort((a, b) => parseFloat(b.count) - parseFloat(a.count))
                            } else {
                                highlightPreviewCountWithPage = highlightPreviewCountWithPage
                                    .sort((a, b) => parseFloat(b.count) - parseFloat(a.count))
                            }
                            _highlightVals.push({ highlightVal: highlight.highlightVal, PageNumber: parseInt(highlightPreviewCountWithPage[0].pageNumber) });
                        }
                    } else {
                        _highlightVals.push(highlight);
                    }
                    this.setState({
                        pageNumber: _highlightVals[0].PageNumber,
                        txtPageNumber: _highlightVals[0].PageNumber,
                        highlightVals: _highlightVals
                    });
                })
            } else {
                let splitedHighlightValue = highlightVal.split(/\W+/);
                pageContent.map((page, pageIndex) => {
                    let pageText = page.page_text.replace(/[\r\n]/g, " ").replace(/\s+/g, " ");;
                    let splitedPageText = pageText.split(/\W+/);
                    const filteredArray = splitedHighlightValue.filter(value => splitedPageText.includes(value));
                    const containsAll = splitedHighlightValue.every(element => {
                        return splitedPageText.includes(element);
                    });
                    highlightPreviewCountWithPage.push({ pageNumber: page.page_number, count: filteredArray.length, previewItem: filteredArray, isContainsAll: containsAll })

                })
                if (highlightPreviewCountWithPage.length > 0) {
                    if (highlightPreviewCountWithPage.some(x => x.isContainsAll === true)) {
                        highlightPreviewCountWithPage = highlightPreviewCountWithPage.filter(x => x.isContainsAll === true)
                            .sort((a, b) => parseFloat(b.count) - parseFloat(a.count))
                    } else {
                        highlightPreviewCountWithPage = highlightPreviewCountWithPage
                            .sort((a, b) => parseFloat(b.count) - parseFloat(a.count))
                    }
                    this.setState({
                        pageNumber: parseInt(highlightPreviewCountWithPage[0].pageNumber),
                        txtPageNumber: parseInt(highlightPreviewCountWithPage[0].pageNumber),
                        //isProcessing: false
                    });
                } else {
                    this.setState({
                        //isProcessing: false
                    });
                }
            }
        } catch (error) {
            this.setState({
                isProcessing: false
            });
        }



        //pageCountResult = pageCountResult.sort((a, b) => parseFloat(b.totalCount) - parseFloat(a.totalCount));

    }
    //#region handle events
    handleOnClick = (status) => {
        if (status === 'increment' && parseInt(this.state.pageNumber) < this.state.numPages) {
            this.setState({
                pageNumber: this.state.pageNumber + 1,
                txtPageNumber: this.state.pageNumber + 1,
                isProcessing: true,
                answerSectionIndex: -1
            }, async () => {
                await this.onSearchKeyword()
                //await this.fetchDocument() 
            })
        }
        else if (status === 'decrement' && parseInt(this.state.pageNumber) > 1) {
            this.setState({
                pageNumber: this.state.pageNumber - 1,
                txtPageNumber: this.state.pageNumber - 1,
                isProcessing: true,
                answerSectionIndex: -1
            }, async () => {
                await this.onSearchKeyword()
                //await this.fetchDocument()
            })
        }
    }

    handlePreviewClose = () => {
        this.props.handleClose(false)
    }

    handleChangeInSearch = (e) => {
        this.setState({
            searchKeyword: e.target.value,
            isNotFount: false
        })
    }

    handleKeyDown = async (e) => {
        if (["Enter"].includes(e.key)) {
            this.setState({
                isProcessing: true
            })
            this.handleSearchKeywords();
        }
    }
    handleSynonymsAndKeywords = async () => {
        let { searchKeywords, synonyms, documentSearchKeyword } = this.state;
        synonyms.map((synonym) => {
            let searchWordCount = 0;
            this.state.pageContent.map((page, pageIndex) => {
                let pageText = page.page_text.replace(/[\r\n]/g, " ").replace(/\s+/g, " ");
                searchWordCount += this.countOccurences(pageText.toLowerCase(), synonym.toLowerCase());
            })
            let isAvailableForAddinKeywords = searchKeywords.findIndex(i => i.keyword.toLowerCase().includes(synonym.toLowerCase())) === -1;

            if (searchWordCount > 0 && isAvailableForAddinKeywords) {
                let remainingColor = colorCode.filter(x => !searchKeywords.map(x => x.color).includes(x));
                // if(this.props.nlpflag==true)
                // {
                //     remainingColor[0]='#ffff00'
                // }
                // console.log("COLOR_TO_SEE",remainingColor)
                let color = '#0d6efd'
                if (remainingColor.length > 0) {
                    color = remainingColor[0];
                }
                searchKeywords.push({ keyword: synonym, color: color, isSynonym: true });
            }
        })

        if (searchKeywords.length === 0 && documentSearchKeyword) {
            let searchKeywordArray = documentSearchKeyword.split(' ');
            searchKeywordArray.map(async (skwItem, skwIndex) => {
                if (skwItem &&
                    !excludeKeywords.includes(skwItem.toLowerCase())
                    &&
                    searchKeywords.findIndex(c => c.keyword.toLowerCase().includes(skwItem.toLowerCase())) === -1) {
                    let remainingColor = colorCode.filter(x => !searchKeywords.map(x => x.color).includes(x));
                    let color = '#0d6efd'
                    if (remainingColor.length > 0) {
                        color = remainingColor[0];
                    }
                    searchKeywords.push({ keyword: skwItem, color: color, isSynonym: false });

                }
            })
        }
        this.setState({
            searchKeywords: searchKeywords,
            searchKeyword: ''
        }, async () => { await this.onSearchKeyword() });
    }

    handleSearchKeywords = async () => {
        let { searchKeywords, searchKeyword, synonyms } = this.state;
        if (searchKeyword) {
            // let searchKeywordArray = searchKeyword.split(' ');
            // searchKeywordArray.map(async (skwItem, skwIndex) => {
            //     if (skwItem &&
            //         !excludeKeywords.includes(skwItem.toLowerCase())
            //         &&
            //         searchKeywords.findIndex(c => c.keyword.toLowerCase().includes(skwItem.toLowerCase())) === -1) {
            //         let remainingColor = colorCode.filter(x => !searchKeywords.map(x => x.color).includes(x));
            //         let color = '#0d6efd'
            //         if (remainingColor.length > 0) {
            //             color = remainingColor[0];
            //         }
            //         searchKeywords.push({ keyword: skwItem, color: color, isSynonym: false });

            //     }
            // })
            synonyms.push(this.removeExtraSpace(searchKeyword));
            this.setState({
                searchKeywords: searchKeywords,
                synonyms: synonyms,
                //searchKeyword: ''
            }, async () => {
                await this.handleSynonymsAndKeywords();
                //await this.onSearchKeyword()
            });
        } else {
            this.setState({
                isProcessing: false,
            })
        }
    }

    handleOnClickSearchIcon = async (e) => {
        this.setState({
            isNotFount: false,
            isProcessing: true,
        })

        this.handleSearchKeywords();
    }

    handleCancelSearchKeyword = (e) => {
        this.setState({
            searchKeyword: ''
        })
    }

    handleKewordRemove = async (keyword) => {
        let { searchKeywords, synonyms } = this.state;
        searchKeywords = searchKeywords.filter(c => c.keyword !== keyword);
        synonyms = synonyms.filter(c => c !== keyword);
        this.setState({
            searchKeywords: searchKeywords,
            isProcessing: true,
            synonyms: synonyms,
        }, async () => { await this.onSearchKeyword() });
    }

    onOrderByValueChange = async (event) => {
        this.setState({
            orderBy: event.target.value
        }, async () => { await this.onSearchKeyword() });
    }

    handleSearchAndCollapseOnClick = async (clickBtnFor) => {
        if (clickBtnFor === DocumentPreviewSearch.Search) {
            this.setState({
                isShowSearchPanel: !this.state.isShowSearchPanel
            });
        }
        else {
            this.setState({
                isShowSearchPanel: false
            });
        }
    }

    handleStackBarOnClick = (pageNumber) => {
        this.setState({
            pageNumber: pageNumber,
            txtPageNumber: pageNumber,
            answerSectionIndex: -1,
            isProcessing: true,
        }, async () => { await this.onSearchKeyword() })
    }
    handlePageNumberOnClick = async (pageNumber, sectionIndex) => {
        if (pageNumber !== this.state.pageNumber || sectionIndex !== this.state.answerSectionIndex) {
            this.setState({
                pageNumber: pageNumber,
                txtPageNumber: pageNumber,
                isProcessing: true,
                answerSectionIndex: sectionIndex
            }, async () => { await this.onSearchKeyword() });
        }
    }
    getScaleBasedOnScreen = (value) => {
        let { customWidth } = this.state;
        if (customWidth < 700) {
            return value + (value * 0.0);
        }
        else if (customWidth >= 701 && customWidth <= 725) {
            return value + (value * 0.13);
        }
        else if (customWidth >= 726 && customWidth <= 750) {
            return value + (value * 0.17);
        }
        else if (customWidth >= 751 && customWidth <= 800) {
            return value + (value * 0.22);
        }
        else if (customWidth >= 801 && customWidth <= 900) {
            return value + (value * 0.29);
        }
        else if (customWidth >= 901 && customWidth <= 1000) {
            return value + (value * 0.45);
        }
        else if (customWidth >= 1001 && customWidth <= 1100) {
            return value + (value * 0.0);
        }
        else if (customWidth >= 1101 && customWidth <= 1200) {
            return value + (value * 0.1);
        }
        else if (customWidth >= 1201 && customWidth <= 1300) {
            return value + (value * 0.2);
        }
        else if (customWidth >= 1301 && customWidth <= 1400) {
            return value + (value * 0.3);
        }
        else if (customWidth >= 1401 && customWidth <= 1500) {
            return value + (value * 0.4);
        }
        else if (customWidth >= 1501 && customWidth <= 1600) {
            return value + (value * 0.5);
        }
        else if (customWidth >= 1601 && customWidth <= 1700) {
            return value + (value * 0.6);
        }
        else if (customWidth >= 1701 && customWidth <= 1800) {
            return value + (value * 0.7);
        }
        else if (customWidth >= 1801 && customWidth <= 1900) {
            return value + (value * 0.8);
        }
        else if (customWidth >= 1901 && customWidth <= 2000) {
            return value + (value * 0.9);
        }
        else if (customWidth >= 2001) {
            return value + (value * ((customWidth - 1000) / 1000));
        }
    }

    handleZoomSliderOnChange = (value) => {

        // let scale = (value / 10) * 0.2;
        let scale = (this.getScaleBasedOnScreen(value) / 10) * 0.2;
        //console.log("old scale: ",scale,"New scale: ",(this.getScaleBasedOnScreen(value)/ 10)*0.2)
        this.setState({
            sliderValue: value,
            scale: scale
        })
    }
    onRangeValueChange = (event) => {
        let sliderValue = parseInt(event.target.value);
        let scale = (this.getScaleBasedOnScreen(sliderValue) / 10) * 0.2;
        //    let scale = (sliderValue / 10) * 0.2;
        this.setState({
            sliderValue: sliderValue,
            scale: scale
        });
    }

    handleZoomButtonOnClick = (status) => {
        let { sliderValue } = this.state;

        if (status === 'increment' && sliderValue === 49) {
            sliderValue += 11;
        }
        else if (status === 'decrement' && sliderValue === 49) {
            sliderValue -= 9;
        }
        else if (status === 'increment' && sliderValue === 35) {
            sliderValue += 5;
        }
        else if (status === 'decrement' && sliderValue === 35) {
            sliderValue -= 5;
        }
        else if (status === 'increment' && sliderValue !== 200) {

            sliderValue += 10;
        }
        else if (status === 'decrement' && sliderValue !== 10) {
            sliderValue -= 10;
        }
        let scale = (this.getScaleBasedOnScreen(sliderValue) / 10) * 0.2;
        //let scale = (sliderValue / 10) * 0.2;
        //console.log("old scale: ", scale, "New scale: ", (this.getScaleBasedOnScreen(sliderValue) / 10) * 0.2)
        this.setState({
            sliderValue: sliderValue,
            scale: scale
        })

    };

    handleFitToPageWidthButtonOnClick = (status) => {
        this.setState({
            sliderValue: 49,
            scale: 1
        })

    }
    handleFitFullPageButtonOnClick = (status) => {
        this.setState({
            sliderValue: 35,
            scale: 0.70
        })
    }
    //#endregion 

    //#region utility methods
    onSearchKeyword = async () => {
        const { orderBy, answerSectionIndex } = this.state;
        let { searchKeywords, searchKeyword, isHighlight } = this.state;
        let pageCountResult = [];
        let isNotFount = false;
        this.state.pageContent.map((page, pageIndex) => {
            let pageText = page.page_text.replace(/[\r\n]/g, " ").replace(/\s+/g, " ");;
            let pageTextSplitArray = pageText.split(' ');
            let count = 0;
            let result = {};

            searchKeywords.map((searchWord, searchWordIndex) => {
                let searchWordCount = 0;
                if (searchWord.isSynonym) {
                    searchWordCount = this.countOccurences(pageText.toLowerCase(), searchWord.keyword.toLowerCase());
                    count += searchWordCount;
                } else {
                    pageTextSplitArray.map((i, index) => {
                        if (i.toLowerCase().includes(searchWord.keyword.toLowerCase())) {
                            count++;
                            searchWordCount++;
                        }
                    })
                }
                result[searchWord.keyword] = searchWordCount;
            })
            result = { ...result, pageNumber: page.page_number, totalCount: count }
            pageCountResult.push(result)
        })
        if (orderBy === DocumentPreviewOrderBy.Hits) {
            pageCountResult = pageCountResult.sort((a, b) => parseFloat(b.totalCount) - parseFloat(a.totalCount));
        } else {
            pageCountResult = pageCountResult.sort((a, b) => parseFloat(a.pageNumber) - parseFloat(b.pageNumber));
        }

        searchKeywords = searchKeywords.filter((searchWord, searchWordIndex) => {
            let searchWordCountArray = pageCountResult.map(x => x[searchWord.keyword]);
            const sum = searchWordCountArray.reduce((partialSum, a) => partialSum + a, 0);
            return sum !== 0;
        })
        const categories = pageCountResult.filter(x => x.totalCount > 0).map(c => `p${c.pageNumber}`);
        let series = [];
        searchKeywords.map((searchWord, searchWordIndex) => {
            let data = pageCountResult.filter(x => x.totalCount > 0).map(x => x[searchWord.keyword]);
            series.push({ name: searchWord.keyword, data: data })
        })
        const colors = searchKeywords.map(c => c.color);
        let strKeyword = searchKeywords.map(c => `${c.keyword}ǂ$${c.color.replace("#", "")}`).join("±@");
        if (searchKeyword) {
            let searchKeywordArray = searchKeyword.split(' ');
            isNotFount = !searchKeywordArray.some(x => searchKeywords.map((searchWord) => searchWord.keyword).includes(x))

        }

        if (isHighlight && this.state.highlightVals && this.state.highlightVals.length > 0) {
            var highlightItems = this.state.highlightVals.filter(x => (x.PageNumberList.length === 0 && (x.PageNumber === this.state.pageNumber || x.PageNumber === (this.state.pageNumber - 1))) || (x.PageNumberList.length > 0 && x.PageNumberList.includes(this.state.pageNumber)));
            if (answerSectionIndex !== -1 && this.state.highlightVals.length > answerSectionIndex) {
                highlightItems = [];
                highlightItems.push(this.state.highlightVals[answerSectionIndex]);
            } else if (answerSectionIndex === -1 && highlightItems && highlightItems.length > 0) {
                highlightItems = highlightItems.filter((item, index) => index === 0);
            }
            if (highlightItems && highlightItems.length > 0) {
                var re = new RegExp('&nbsp;', "g");
                // let splitedHighlightValue = singleItem.highlightVal.replace(re, " ").replace(/&gt;/g, '>').replace(/&lt;/g, '<').split('.');
                // splitedHighlightValue = splitedHighlightValue.filter(x => x.trim());
                // strKeyword += strKeyword ? `±@${splitedHighlightValue.map(x => `${x.trim()}ǂ$FFFF00`).join("±@")}` : `${splitedHighlightValue.map(x => `${x.trim()}ǂ$FFFF00`).join("±@")}`;
                let finalHighlighItems = [];
                highlightItems.map((singleItem) => {
                    let splitedHighlightValue = singleItem.ansHighlight.replace(re, " ").replace(/&gt;/g, '>').replace(/&lt;/g, '<').replace(/&amp;/g, '&').split('<br>');
                    splitedHighlightValue = splitedHighlightValue.filter(x => x.trim());
                    let hyphenSplitedHighlightValue = splitedHighlightValue.filter(x => x.includes('-'));
                    hyphenSplitedHighlightValue.map((splitItem) => {
                        splitedHighlightValue.push(splitItem.replace(/-/g, '‐'))
                    })
                    splitedHighlightValue.map((splitItem) => {
                        finalHighlighItems = finalHighlighItems.concat(splitItem.split(/[•●]+/).filter(x => x.trim()));
                    })
                    // let splitedHighlightValue = singleItem.highlightVal.replace(re, " ").replace(/&gt;/g, '>').replace(/&lt;/g, '<').replace(/&amp;/g, '&');
                    // splitedHighlightValue = splitedHighlightValue.trim();
                    // strKeyword += strKeyword ? `±@${splitedHighlightValue}ǂ$FFFF00` : `${splitedHighlightValue}ǂ$FFFF00`;
                });
                strKeyword += strKeyword ? `±@${finalHighlighItems.map(x => `${x.trim()}ǂ$FFFF00`).join("±@")}` : `${finalHighlighItems.map(x => `${x.trim()}ǂ$FFFF00`).join("±@")}`;
            }
        }
        else if (isHighlight && this.props.highlightVal) {
            //let splitedHighlightValue = this.props.highlightVal.split(/\W+/);
            //strKeyword += strKeyword ? `±@${splitedHighlightValue.map(x=>`${x}ǂ$FFFF00`).join("±@")}` : `${splitedHighlightValue.map(x=>`${x}ǂ$FFFF00`).join("±@")}`;
            var renbsp = new RegExp('&nbsp;', "g");
            //return str.replace(re, " ");
            //strKeyword += strKeyword ? `±@${this.props.highlightVal.replace(re, " ").trim()}ǂ$FFFF00` : `${this.props.highlightVal.replace(re, " ").trim()}ǂ$FFFF00`;


            let splitedHighlightValue = this.props.highlightVal.replace(renbsp, " ").replace(/&gt;/g, '>').replace(/&lt;/g, '<');
            splitedHighlightValue = splitedHighlightValue.trim();

            // let splitedHighlightValue = this.props.highlightVal.replace(re, " ").split(' ');
            // splitedHighlightValue = splitedHighlightValue.filter(x => x.trim());
            // let splitinTwoWord = [];
            // for (let i = 0; i < splitedHighlightValue.length;i=i + 2) {
            //     if (i + 1 < splitedHighlightValue.length) {
            //         splitinTwoWord.push(`${splitedHighlightValue[i]} ${splitedHighlightValue[i + 1]}`)
            //     } else {
            //         splitinTwoWord.push(`${splitedHighlightValue[i]}`)
            //     }
            // }
            // splitedHighlightValue = splitinTwoWord;
            // splitedHighlightValue = splitedHighlightValue.filter(this.onlyUnique);
            strKeyword += strKeyword ? `±@${splitedHighlightValue}ǂ$FFFF00` : `${splitedHighlightValue}ǂ$FFFF00`;
            //strKeyword += strKeyword ? `±@${splitedHighlightValue.map(x => `${x.trim()}ǂ$FFFF00`).join("±@")}` : `${splitedHighlightValue.map(x => `${x.trim()}ǂ$FFFF00`).join("±@")}`;
        }


        await this.setState({
            pageCountResult: pageCountResult,
            categories: categories,
            series: series,
            colors: colors,
            searchKeywords: searchKeywords,
            updateKey: !this.state.updateKey,
            strKeyword: strKeyword,
            isNotFount: isNotFount,
            searchKeyword: ""
        }, async () => { await this.fetchDocument() })
    }
    onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
    }
    fetchDocument = async () => {
        const { pageNumber, strKeyword, searchID, isHighlight } = this.state;
        let url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}PreviewService/Preview`
        let payload = {
            strID: searchID,
            strPageNum: pageNumber,
            strKeyword: isHighlight ? strKeyword : ""
        };
        await axios.post(
            url,
            payload
            , {
                responseType: "blob",
            }
        ).then((response) => {
            const data = new Blob([response.data], { type: 'application/pdf' });
            this.setState({ base64: data, isProcessing: false })
        }).catch((error) => {
            console.log(error);
            this.setState({ base64: "", isProcessing: false })
        });
    }

    snippetLongString = (snippet) => {
        if (snippet.length > 65) {
            let text = snippet.substring(0, 65)//cuts to 70
            let last = text.lastIndexOf(" ")//gets last space (to avoid cutting the middle of a word)
            text = text.substring(0, last)//cuts from last space (to avoid cutting the middle of a word)
            text = text + ` ...`//adds (...) at the end to show that it's cut
            return text
        }
        return snippet;
    }
    removeExtraSpace = s => s.trim().split(/ +/).join(" ");
    snippetLongStringForKeyword = (snippet, snippetLength = 65) => {
        if (snippet.length > snippetLength) {
            let text = snippet.substring(0, snippetLength)//cuts to 70
            text = text + `...`//adds (...) at the end to show that it's cut
            return text
        }
        return snippet;
    }
    countOccurences = (string, word) => {
        string = string.trim();
        const wordArray = word.trim().split(/\s+/);
        if (wordArray.length === 1) {
            let stringArray = string.trim().split(/[ ,]+/).filter(x => x === word);
            return stringArray.length;
        } else {
            let isStartwith = string.startsWith(`${word} `) ? 1 : 0;
            let isEndsWith = string.endsWith(` ${word}`) ? 1 : 0;
            return string.split(` ${word} `).length - 1 + isStartwith + isEndsWith;
        }
    }
    //#endregion

    //#region render methods
    renderSearchKeyworkForNlpAns = () => {
        const { highlightVals } = this.state;
        return highlightVals.map((searchWord, searchWordIndex) => {
            let _pageNumber = searchWord.PageNumber;
            if (searchWord.PageNumberList && searchWord.PageNumberList.length > 0) {
                _pageNumber = searchWord.PageNumberList.join(',');
            }
            return (<>
                <div className="doc-preview-keword-title" style={{ cursor: "pointer" }} key={`doc-preview-keword-title${searchWordIndex}`} onClick={e => this.handlePageNumberOnClick(searchWord.PageNumber, searchWordIndex)}>
                    <span style={{ cursor: "pointer" }}>p{_pageNumber} </span>
                    <span title={searchWord.highlightVal.toLowerCase()} style={{ flex: "1", marginLeft: "10px" }}>{this.snippetLongStringForKeyword(searchWord.highlightVal.toLowerCase(), 65)}</span>
                    <div className="doc-preview-keword-color-box" style={{ background: '#FFFF00' }} />
                </div>
            </>);
        })
    }
    renderSearchKeyworkResult = () => {
        const { pageCountResult, searchKeywords } = this.state;
        return searchKeywords.map((searchWord, searchWordIndex) => {
            let searchWordCountArray = pageCountResult.map(x => x[searchWord.keyword]);
            const sum = searchWordCountArray.reduce((partialSum, a) => partialSum + a, 0);
            return (<>
                <div className="doc-preview-keword-title" key={`doc-preview-keword-title${searchWordIndex}`}>
                    <span onClick={e => this.handleKewordRemove(searchWord.keyword)} style={{ cursor: "pointer" }}><img src={crossIcon} width={10} height={10} alt="Cross" /> </span>
                    <span title={searchWord.keyword.toLowerCase()} style={{ flex: "1", marginLeft: "5px" }}>{this.snippetLongStringForKeyword(searchWord.keyword.toLowerCase())} ({sum})</span>
                    <div className="doc-preview-keword-color-box" style={{ background: `${searchWord.color}` }} />
                </div>
            </>);
        })
    }

    renderSearchKeyworkChart = () => {
        const { searchKeywords, categories, series, colors, updateKey, customWidth, customHeight } = this.state;
        return (
            <>{(categories && series && colors && searchKeywords.length > 0 && categories.length > 0) ?
                <DocumentPreviewChart key={`DocumentPreviewChart-${updateKey}`} customWidth={customWidth} customHeight={customHeight}
                    categories={categories} series={series} colors={colors} handleStackBarOnClick={this.handleStackBarOnClick} /> :
                <div style={{ textAlign: "center" }}><span><b>No Hit Available.</b> </span></div>
            }</>
        )
    }
    //#endregion
    setDocPreviewInnerWrapperHeight = () => {
        const { fileExtension } = this.state;
        if (fileExtension === "pptx" || fileExtension === "ppt") {
            return { height: "390px" }
        } else {
            return { height: "475px" };
        }
    }
    onDocumentClick = (event, page) => {
        const textLayers = document.querySelectorAll("div.react-pdf__Page__textContent > span");
        // textLayers.forEach(layer => {
        //     if (layer.innerHTML && !layer.innerHTML.trim().endsWith('-')) {
        //         layer.innerHTML = `${layer.innerHTML} `;
        //     }
        // });
        for (let i = 0; i < textLayers.length; i++) {
            let layer = textLayers[i];
            let currentLayerTop = parseFloat(textLayers[i].style.top);
            let previousLayerTop = 0;
            if (i - 1 >= 0) {
                let previousLayer = textLayers[i - 1];
                previousLayerTop = parseFloat(previousLayer.style.top);
                if (currentLayerTop !== previousLayerTop && !previousLayer.innerHTML.trim().endsWith('-')) {
                    layer.innerHTML = ` ${layer.innerHTML}`;
                }
            }
        }
    }
    handleClickHighlight = () => {
        let { isHighlight } = this.state;
        this.setState({
            isHighlight: !isHighlight,
            isProcessing: true
        }, async () => {
            await this.onSearchKeyword()
        });

    }
    handleKeyPress = (event) => {
        if (event.key === "Enter") {
            this.handleGotoPageClick();
        }
        else if (event.key && isNaN(event.key)) {
            this.setState({ txtPageNumber: this.state.pageNumber })
            return false;
        }
    }
    handlePageNumberChange = (event) => {
        if (isNaN(event.target.value)) {
            this.setState({ txtPageNumber: this.state.pageNumber })
        } else {
            this.setState({ txtPageNumber: event.target.value ? parseInt(event.target.value) : event.target.value })
        }
    }
    handleGotoPageClick = () => {
        let { txtPageNumber, numPages, pageNumber } = this.state;
        if (txtPageNumber === pageNumber) {
            txtPageNumber = txtPageNumber + 1;
        }

        if (!txtPageNumber) {
            this.setState({
                txtPageNumber: this.state.pageNumber, isProcessing: true
            }, async () => {
                await this.onSearchKeyword()
            })
        }
        else if (txtPageNumber <= 0) {
            this.setState({
                pageNumber: 1,
                txtPageNumber: 1,
                isProcessing: true
            }, async () => {
                await this.onSearchKeyword()
            })
        }
        else if (txtPageNumber <= numPages) {
            this.setState({
                pageNumber: txtPageNumber,
                txtPageNumber: txtPageNumber,
                isProcessing: true
            }, async () => {
                await this.onSearchKeyword()
            })
        } else {
            this.setState({
                pageNumber: numPages,
                txtPageNumber: numPages,
                isProcessing: true
            }, async () => {
                await this.onSearchKeyword()
            })
        }
    }
    handleIsShowMore = (_isShowMore) => {
        this.setState({ isShowMore: _isShowMore }, () => {
            this.RecommendationEnd.scrollIntoView({ behavior: "smooth" });
        });
    }
    handlePreviewClick = async (previewDetail) => {
        this.setState({
            pageNumber: previewDetail.highlightVals[0].PageNumber,
            txtPageNumber: previewDetail.highlightVals[0].PageNumber,
            originalHighlightVals: previewDetail.highlightVals,
            docTitle: previewDetail.docTitle,
            searchID: previewDetail.searchID,
            downloadUrl: previewDetail.downloadUrl,
            isProcessing: true,
        }, async () => {
            let apiService = new ApiServices();
            let res = await apiService.getPreview(previewDetail.searchID);
            if (res) {
                this.setState({
                    numPages: parseInt(res.page_count),
                    isLoaded: true,
                }, async () => { await this.getSearchKeywordCountForPreview() });
            }
        })
    }
    renderRecommendation = () => {
        let { isShowMore } = this.state;
        let { recommendationDocuments, sessionID, lastQuestion, lastDocumentInfo, lastFeedbackID } = this.props
        let totalDocuments = (isShowMore || recommendationDocuments.length < 7) ? recommendationDocuments.length : 7;
        return (<div className="rd-document-info-wrapper rd-document-margin-info-wrapper">
            <span style={{ flex: "1" }}>Recommendations:</span>
            <p className="doc-preview-chart-results-split"></p>
            <div className="rd-document-info-wrapper-items">
                {recommendationDocuments.filter((x, i) => (isShowMore || i < 7)).map((document, documentIndex) => {
                    return (<DocumentPreviewRecommendations key={`recommendation-documents-${documentIndex}`} documentInfo={document}
                        documentIndex={documentIndex + 1} totalDocuments={totalDocuments}
                        feedbackID={lastFeedbackID} customWidth={this.state.customWidth} customHeight={this.state.customHeight}
                        mainAnswerDocumentInfo={lastDocumentInfo} question={lastQuestion} sessionID={sessionID}
                        pushRecommendations={this.pushRecommendations} handlePreviewClick={this.handlePreviewClick} />);
                })}
                {recommendationDocuments.length > 7 && <>{isShowMore ?
                    <>
                        <span className='cbm-show-more-less' onClick={() => this.handleIsShowMore(false)}>
                            Show Less
                        </span>
                    </>
                    :
                    <>
                        <span className='cbm-show-more-less' onClick={() => this.handleIsShowMore(true)}>
                            Show More
                        </span>
                    </>
                }
                </>
                }
            </div>
            <div style={{ float: "left", clear: "both" }}
                ref={(el) => { this.RecommendationEnd = el; }}>
            </div>
        </div>)

    }

    render() {
        const { pageCountResult, orderBy, isShowSearchPanel, sliderValue, scale, isNotFount, fileExtension, isProcessing, isHighlight, txtPageNumber } = this.state;
        const { searchKeywords, categories, series, colors, updateKey, customWidth, downloadUrl } = this.state;
        let { base64 } = this.state
        let { recommendationDocuments } = this.props
        if (customWidth >= 600 && customWidth <= 1000) {
            return (<div className="answering-doc-preview-wrapper">
                {<div className={isShowSearchPanel ? "answering-doc-preview-wrapper-tablet-left answering-doc-preview-wrapper-tablet-open" : "answering-doc-preview-wrapper-tablet-left answering-doc-preview-wrapper-tablet-close"}>
                    <div className="doc-preview-input-box-title-wrapper"><span style={{ flex: "1" }}>Search inside</span>
                        <span onClick={e => this.handleSearchAndCollapseOnClick(DocumentPreviewSearch.Collapse)} style={{ cursor: "pointer", float: "right" }} title={isShowSearchPanel ? "Full Screen View" : "Exit Full Screen View"}> <button className="documentViewerbutton collapse-button" ></button></span></div>
                    <div className="doc-preview-input-box-wrapper">
                        <input name="searchBar" placeholder="Search..." className="doc-preview-input-search-box" value={this.state.searchKeyword} onChange={this.handleChangeInSearch} onKeyDown={this.handleKeyDown} />
                        <span className="doc-preview-icon-wrapper">
                            {this.state.searchKeyword &&
                                <img
                                    src={crossIcon}
                                    className="doc-preview-input-search-cancel-icon"
                                    alt="clear"
                                    title="Click here to clear the search terms"
                                    onClick={this.handleCancelSearchKeyword}
                                />
                            }
                            <span className="doc-preview-search-icon-wrapper-webView" onClick={this.handleOnClickSearchIcon}>
                                <img
                                    src={magnifyingGlass}
                                    className="magnifyingGlass-mobileView"
                                    alt="search"
                                    title="Search"
                                />
                            </span>
                        </span>
                        {isNotFount && <span className="doc-preview-warning-msg">There is no result found.</span>}
                    </div>
                    <div className="doc-preview-page-count-result">
                        {pageCountResult && <>
                            <div className="doc-preview-search-results-title doc-preview-with-checkbox">
                                <span style={{ flex: "1" }}>Search results:</span>
                                <div>
                                    <input type="checkbox" name="Labels" class="k-checkbox" style={{ margin: 0 }} onChange={e => this.handleClickHighlight()} checked={isHighlight} />
                                    <span for="Labels" className="cbm-tablet-Highlight" style={{ paddingLeft: "5px !important" }} onClick={() => this.handleClickHighlight()} >Highlight</span>
                                </div>
                            </div>
                            <div>{this.renderSearchKeyworkForNlpAns()}
                                {this.renderSearchKeyworkResult()}
                                {(categories && series && colors && searchKeywords.length > 0 && categories.length > 0) && <div className="doc-preview-page-count-chart">
                                    <p className="doc-preview-search-results-split"></p>
                                    <div className="nlp-dp-chart-outer-wrapper">
                                        <div className="nlp-dp-chart-inner-wrapper">
                                            <div id="rvHeaderSection" className="nlp-dp-chart-inner-wrapper-desktop" onClick={e => { this.setState({ showChart: !this.state.showChart }) }} >
                                                <span className="nlp-dp-chart-main-header-desktop">Hits</span>
                                                <span className="nlp-dp-chart-main-header-desktop"> <img
                                                    src={sideArrowWhite}
                                                    alt="down-arrow"
                                                    className="cs-downArrow"
                                                    style={{
                                                        transform: this.state.showChart ? "rotate(270deg)" : null,
                                                    }}
                                                    width={14}
                                                /></span>
                                            </div>
                                            {this.state.showChart &&
                                                <>
                                                    <div className="doc-preview-OrderBy-wrapper" style={{ marginTop: "5px" }}>
                                                        <label>Order by: </label>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                value={DocumentPreviewOrderBy.Hits}
                                                                checked={orderBy === DocumentPreviewOrderBy.Hits}
                                                                onChange={this.onOrderByValueChange}
                                                            />
                                                            {DocumentPreviewOrderBy.Hits}
                                                        </label>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                value={DocumentPreviewOrderBy.Pages}
                                                                checked={orderBy === DocumentPreviewOrderBy.Pages}
                                                                onChange={this.onOrderByValueChange}
                                                            />
                                                            {DocumentPreviewOrderBy.Pages}
                                                        </label>
                                                    </div>
                                                    <div className={(categories && series && colors && searchKeywords.length > 0 && categories.length > 0) ? "nlp-dp-chart-content-section" : "nlp-dp-no-chart-content-section"} >
                                                        {this.renderSearchKeyworkChart()}
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>}</div>
                            {(recommendationDocuments && recommendationDocuments.length > 0)
                                &&
                                <div className="doc-preview-page-count-results-wrapper doc-preview-page-count-results-wrapper-max-height">
                                    <div className="doc-preview-page-count-results" style={{ marginRight: "5px" }}>
                                        {this.renderRecommendation()}
                                    </div>
                                </div>
                            }
                        </>}
                    </div>
                </div>}
                <div className="answering-doc-preview-wrapper-tablet-right" style={{ width: !isShowSearchPanel && "100%" }}>
                    {
                        this.props.showPreview &&
                        <>
                            <div className="doc-preview-title-wrapper">
                                <a target="_blank" href={downloadUrl} title={this.state.docTitle} className="doc-preview-title-wrapper-span" style={{ flex: "1" }}>{this.state.docTitle}</a>
                                <span>
                                    <button onClick={e => this.handleSearchAndCollapseOnClick(DocumentPreviewSearch.Search)} style={{ cursor: "pointer" }}><img src={viewMoreArrowGray} style={{ transform: isShowSearchPanel ? "rotate(180deg)" : null, }} width={10} height={10} alt="Cross" /></button>
                                    <button onClick={e => this.handlePreviewClose()} style={{ cursor: "pointer", marginLeft: "10px" }}><img src={crossIcon} width={10} height={10} alt="Cross" /></button>
                                </span>
                            </div>
                            {(isProcessing) ? <div style={{ textAlign: "center", padding: "10%" }} className="nlp-doc-preview-loader-wrapper">
                                <b>Loading a document</b>
                                <br />
                                <img src={imsLoader} alt="Loading Filters" width={100} height={100} />
                            </div> :
                                <>
                                    <div className="doc-preview-inner-wrapper-answering"
                                    //style={this.setDocPreviewInnerWrapperHeight()}
                                    >
                                        {/* width="515" height="275" */}
                                        {/* <Document file={`${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}PreviewService/Preview?strID=${this.props.searchID}&strPageNum=${this.state.pageNumber}&strKeyword=${strKeyword}`} loading={onLoad} error={onNoItem} > */}
                                        <Document file={base64} loading={onLoad} error={onNoItem} >
                                            {fileExtension && (fileExtension === "pptx" || fileExtension === "ppt") ?
                                                <Page pageNumber={1} scale={scale} width="700" height="200" renderMode="canvas" onRenderSuccess={this.onDocumentClick} /> :
                                                <Page pageNumber={1} scale={scale} width="700" height="200" renderMode="canvas" onRenderSuccess={this.onDocumentClick} />}
                                        </Document>
                                    </div>
                                    {
                                        this.state.numPages >= 1 &&
                                        <div className="doc-preview-controle-wrapper">
                                            <div className="doc-preview-pagination-wrapper">
                                                {this.state.pageNumber === 1 ?
                                                    <img src={paginationDisabledLeftArrowMobile} alt="" style={{ cursor: "not-allowed" }} width={17} height={17} /> :
                                                    <img src={paginationLeftArrowMobile} alt="" onClick={e => this.handleOnClick('decrement')} width={17} height={17} />
                                                }
                                                <span>Page {this.state.pageNumber} of {this.state.numPages}</span>
                                                {this.state.pageNumber === this.state.numPages ?
                                                    <img src={paginationDisabledRightArrowMobile} alt="" style={{ cursor: "not-allowed" }} width={17} height={17} /> :
                                                    <img src={paginationRightArrowMobile} alt="" onClick={e => this.handleOnClick('increment')} width={17} height={17} />
                                                }
                                                <div className="pagenumber-input-box-wrapper">
                                                    <input type="number" min="1" max={this.state.numPages} onChange={this.handlePageNumberChange} onKeyPress={this.handleKeyPress} className="txtPageNumber" title="Go to Page" value={txtPageNumber} />
                                                    <span className="pagenumber-icon-wrapper">
                                                        <span className="pagenumber-icon-wrapper-webView" onClick={this.handleGotoPageClick}>
                                                            <img
                                                                src={viewMoreArrowWhite}
                                                                style={{ width: '50%' }}
                                                                alt="Skip to Page"
                                                                title="Skip to Page"
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="doc-preview-zoom-wrapper">
                                                <button className="documentViewerbutton showSearch" title={isShowSearchPanel ? "Full Screen View" : "Exit Full Screen View"} onClick={e => this.handleSearchAndCollapseOnClick(DocumentPreviewSearch.Search)}></button>
                                                <button className="documentViewerbutton zoomFitWidth" title="Fit to Page Width" onClick={e => this.handleFitToPageWidthButtonOnClick('decrement')}></button>
                                                <button className="documentViewerbutton zoomFitPage" title="Fit Full Page" onClick={e => this.handleFitFullPageButtonOnClick('decrement')}></button>
                                                <span className="documentzoomPercentagebutton">{sliderValue}%</span>
                                                <button className="documentViewerbutton zoomOut" onClick={e => this.handleZoomButtonOnClick('decrement')}></button>
                                                <div className="doc-preview-zoom-inner">
                                                    <input type="range" value={sliderValue} onChange={e => this.onRangeValueChange(e)} min="10" max="200" step="10" className="slider" id="myRange" />
                                                </div>
                                                <button className="documentViewerbutton zoomIn" onClick={e => this.handleZoomButtonOnClick('increment')}></button>
                                            </div>
                                        </div>
                                    }
                                </>}
                        </>
                    }
                </div>
            </div>)
        }
        else if (customWidth > 1000) {
            return (<div className="answering-doc-preview-wrapper">
                {isShowSearchPanel && <div className="answering-doc-preview-wrapper-left">
                    <div className="doc-preview-input-box-title-wrapper"><span style={{ flex: "1" }}>Search inside</span>
                        <span onClick={e => this.handleSearchAndCollapseOnClick(DocumentPreviewSearch.Collapse)} style={{ cursor: "pointer", float: "right" }} title={isShowSearchPanel ? "Full Screen View" : "Exit Full Screen View"}> <button className="documentViewerbutton collapse-button" ></button></span></div>
                    <div className="doc-preview-input-box-wrapper">
                        <input name="searchBar" placeholder="Search..." className="doc-preview-input-search-box" value={this.state.searchKeyword} onChange={this.handleChangeInSearch} onKeyDown={this.handleKeyDown} />
                        <span className="doc-preview-icon-wrapper">
                            {this.state.searchKeyword &&
                                <img
                                    src={crossIcon}
                                    className="doc-preview-input-search-cancel-icon"
                                    alt="clear"
                                    title="Click here to clear the search terms"
                                    onClick={this.handleCancelSearchKeyword}
                                />
                            }
                            <span className="doc-preview-search-icon-wrapper-webView" onClick={this.handleOnClickSearchIcon}>
                                <img
                                    src={magnifyingGlass}
                                    className="magnifyingGlass-mobileView"
                                    alt="search"
                                    title="Search"
                                />
                            </span>
                        </span>
                        {isNotFount && <span className="doc-preview-warning-msg">There is no result found.</span>}
                    </div>
                    <div className="doc-preview-page-count-result">
                        {pageCountResult && <>
                            <div className="doc-preview-search-results-title doc-preview-with-checkbox">
                                <span style={{ flex: "1" }}>Search results:</span>
                                <div>
                                    <input type="checkbox" name="Labels" class="k-checkbox" style={{ margin: 0 }} onChange={e => this.handleClickHighlight()} checked={isHighlight} />
                                    <span for="Labels" className="cbm-tablet-Highlight" style={{ paddingLeft: "5px !important" }} onClick={() => this.handleClickHighlight()} >Highlight</span>
                                </div>
                            </div>
                            <div>{this.renderSearchKeyworkForNlpAns()}
                                {this.renderSearchKeyworkResult()}
                                {(categories && series && colors && searchKeywords.length > 0 && categories.length > 0) && <div className="doc-preview-page-count-chart">
                                    <p className="doc-preview-search-results-split"></p>
                                    <div className="nlp-dp-chart-outer-wrapper">
                                        <div className="nlp-dp-chart-inner-wrapper">
                                            <div id="rvHeaderSection" className="nlp-dp-chart-inner-wrapper-desktop" onClick={e => { this.setState({ showChart: !this.state.showChart }) }} >
                                                <span className="nlp-dp-chart-main-header-desktop">Hits</span>
                                                <span className="nlp-dp-chart-main-header-desktop"> <img
                                                    src={sideArrowWhite}
                                                    alt="down-arrow"
                                                    className="cs-downArrow"
                                                    style={{
                                                        transform: this.state.showChart ? "rotate(270deg)" : null,
                                                    }}
                                                    width={14}
                                                /></span>
                                            </div>
                                            {this.state.showChart &&
                                                <>
                                                    <div className="doc-preview-OrderBy-wrapper" style={{ marginTop: "5px" }}>
                                                        <label>Order by: </label>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                value={DocumentPreviewOrderBy.Hits}
                                                                checked={orderBy === DocumentPreviewOrderBy.Hits}
                                                                onChange={this.onOrderByValueChange}
                                                            />
                                                            {DocumentPreviewOrderBy.Hits}
                                                        </label>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                value={DocumentPreviewOrderBy.Pages}
                                                                checked={orderBy === DocumentPreviewOrderBy.Pages}
                                                                onChange={this.onOrderByValueChange}
                                                            />
                                                            {DocumentPreviewOrderBy.Pages}
                                                        </label>
                                                    </div>
                                                    <div className={(categories && series && colors && searchKeywords.length > 0 && categories.length > 0) ? "nlp-dp-chart-content-section" : "nlp-dp-no-chart-content-section"} >
                                                        {this.renderSearchKeyworkChart()}
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>}</div>
                            {(recommendationDocuments && recommendationDocuments.length > 0) &&
                                <div className="doc-preview-page-count-results-wrapper doc-preview-page-count-results-wrapper-max-height">
                                    <div className="doc-preview-page-count-results" style={{ marginRight: "5px" }}>
                                        {this.renderRecommendation()}
                                    </div>
                                </div>
                            }
                        </>}
                    </div>
                </div>}
                <div className="answering-doc-preview-wrapper-right" style={{ width: !isShowSearchPanel && "100%" }}>
                    {
                        this.props.showPreview &&
                        <>
                            {/* {this.snippetLongString(this.state.docTitle)} */}
                            <div className="doc-preview-title-wrapper"><a target="_blank" href={downloadUrl} title={this.state.docTitle} className="doc-preview-title-wrapper-span" style={{ flex: "1" }}>{this.state.docTitle}</a>
                                <span onClick={e => this.handlePreviewClose()} style={{ cursor: "pointer" }}><img src={crossIcon} width={10} height={10} alt="Cross" /></span></div>
                            {(isProcessing) ? <div style={{ textAlign: "center", padding: "10%" }} className="nlp-doc-preview-loader-wrapper">
                                <b>Loading a document</b>
                                <br />
                                <img src={imsLoader} alt="Loading Filters" width={100} height={100} />
                            </div> :
                                <>
                                    <div className="doc-preview-inner-wrapper-answering"
                                    //style={this.setDocPreviewInnerWrapperHeight()}
                                    >
                                        {/* width="515" height="275" */}
                                        {/* <Document file={`${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}PreviewService/Preview?strID=${this.props.searchID}&strPageNum=${this.state.pageNumber}&strKeyword=${strKeyword}`} loading={onLoad} error={onNoItem} > */}
                                        <Document file={base64} loading={onLoad} error={onNoItem} >
                                            {fileExtension && (fileExtension === "pptx" || fileExtension === "ppt") ?
                                                <Page pageNumber={1} scale={scale} width="700" height="200" renderMode="canvas" onRenderSuccess={this.onDocumentClick} /> :
                                                <Page pageNumber={1} scale={scale} width="700" height="200" renderMode="canvas" onRenderSuccess={this.onDocumentClick} />}
                                        </Document>
                                    </div>
                                    {
                                        this.state.numPages >= 1 &&
                                        <div className="doc-preview-controle-wrapper">
                                            <div className="doc-preview-pagination-wrapper">
                                                {this.state.pageNumber === 1 ?
                                                    <img src={paginationDisabledLeftArrowMobile} alt="" style={{ cursor: "not-allowed" }} width={17} height={17} /> :
                                                    <img src={paginationLeftArrowMobile} alt="" onClick={e => this.handleOnClick('decrement')} width={17} height={17} />
                                                }
                                                <span>Page {this.state.pageNumber} of {this.state.numPages}</span>
                                                {this.state.pageNumber === this.state.numPages ?
                                                    <img src={paginationDisabledRightArrowMobile} alt="" style={{ cursor: "not-allowed" }} width={17} height={17} /> :
                                                    <img src={paginationRightArrowMobile} alt="" onClick={e => this.handleOnClick('increment')} width={17} height={17} />
                                                }
                                                <div className="pagenumber-input-box-wrapper">
                                                    <input type="number" min="1" max={this.state.numPages} onChange={this.handlePageNumberChange} onKeyPress={this.handleKeyPress} className="txtPageNumber" title="Go to Page" value={txtPageNumber} />
                                                    <span className="pagenumber-icon-wrapper">
                                                        <span className="pagenumber-icon-wrapper-webView" onClick={this.handleGotoPageClick}>
                                                            <img
                                                                src={viewMoreArrowWhite}
                                                                style={{ width: '50%' }}
                                                                alt="Skip to Page"
                                                                title="Skip to Page"
                                                            />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="doc-preview-zoom-wrapper">
                                                <button className="documentViewerbutton showSearch" title={isShowSearchPanel ? "Full Screen View" : "Exit Full Screen View"} onClick={e => this.handleSearchAndCollapseOnClick(DocumentPreviewSearch.Search)}></button>
                                                <button className="documentViewerbutton zoomFitWidth" title="Fit to Page Width" onClick={e => this.handleFitToPageWidthButtonOnClick('decrement')}></button>
                                                <button className="documentViewerbutton zoomFitPage" title="Fit Full Page" onClick={e => this.handleFitFullPageButtonOnClick('decrement')}></button>
                                                <span className="documentzoomPercentagebutton">{sliderValue}%</span>
                                                <button className="documentViewerbutton zoomOut" onClick={e => this.handleZoomButtonOnClick('decrement')}></button>
                                                <div className="doc-preview-zoom-inner">
                                                    <input type="range" value={sliderValue} onChange={e => this.onRangeValueChange(e)} min="10" max="200" step="10" className="slider" id="myRange" />
                                                </div>
                                                <button className="documentViewerbutton zoomIn" onClick={e => this.handleZoomButtonOnClick('increment')}></button>
                                            </div>
                                        </div>
                                    }
                                </>}
                        </>
                    }
                </div>
            </div>
            )
        }
    }
}
export default NlpDocumentPreview;