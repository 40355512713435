import { Component } from "react";
import Footer from "../../components/footer/Footer";
import HeaderWithBanner from "../../components/header/HeaderWithBanner";
import { listIcon } from "../../assets";
import NavigationMenu from "../../components/Navigation/NavigationMenu";
import './FooterLinks.css';

class GetHelp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = 'iMedical Search - Access Training Materials';
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight })
    }
    render() {
        if (this.state.width <= 600) {
            return (
                <>
                    <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                    <div className="helpPageWrapper mobile-helpPageWrapper">
                        <div className="helpContentWrapper mobile-helpContentWrapper">
                            <div className="upperCard">
                                <h3>
                                    Other Questions about​ iMedical Search (i​MS)​?
                                </h3>
                                <p className="helpContentInnerWrapper">
                                    Get​ help by emailing <a href="mailto:RA-JANUS-iMedical@ITS.JNJ.com" title="email the support mailbox">iMedical Search</a>​. This email account is monitored five days a week, 8 hours a day. Once your email is acknowledged, an IRIS ticket will be created if your issue requires technical support from the global team. For non-technical issues such as "how to" questions regarding how to conduct more effective searches, your ​message will be forwarded to a regional liaison for further action.
                                </p>
                                <div className="trainingHeading tablet-trainingHeading">
                                    <h3>
                                        <span>
                                            Training and Other Reference Materials
                                        </span>
                                    </h3>
                                </div>
                                <div className="mobile-helpContentList">
                                    <div className="linked-items">
                                        <a href="https://jnj.sharepoint.com/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMKS%20Overview.pptx?d=waab16ebbdfb34c328b970d75012c8da3" target="_blank" rel="noreferrer">
                                            <span className="contentlisting">iMedical Search (iMS) Overview</span>
                                        </a>
                                    </div>
                                    <div className="linked-items">
                                        <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMS_Product%20Preferences.pptx?d=w71a7f74994704868baf0628846081737&amp;csf=1&amp;e=ZkSurK" target="_blank" rel="noreferrer">
                                            <span className="contentlisting">Viewing/Editing Product Preferences</span>
                                        </a>
                                    </div>
                                    <div className="linked-items">
                                        <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/Instructions%20for%20accessing%20PubMed%20via%20iMedical%20Search.pptx?d=wd470fb5ecaff4ff9a9b6544b02889dd9&amp;csf=1&amp;web=1&amp;e=iAwsPR" target="_blank" rel="noreferrer">
                                            <span className="contentlisting">Instructions for Accessing PubMed Content via iMedical Search</span>
                                        </a>
                                    </div>
                                    <div className="linked-items">
                                        <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMS_Setting%20Up%20Personal%20Alerts.pptx?d=w100e67141726406084ff788dad6d3208&amp;csf=1&amp;e=ShCCB4" target="_blank" rel="noreferrer">
                                            <span className="contentlisting">Setting up personal alerts</span>
                                        </a>
                                    </div>
                                    <div className="linked-items">
                                        <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMS_Pre-Packaged%20Product%20Alerts.pptx?d=w553921201f5540b78e5e173df0740765&amp;csf=1&amp;e=Bjht9r" target="_blank" rel="noreferrer">
                                            <span className="contentlisting">Subscribing to “pre-packaged” product alerts</span>
                                        </a>
                                    </div>
                                    <div className="linked-items">
                                        <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/Setting%20up%20Regional%20Alerts%20Bookmarks.pptx?d=w1d3df33cf2264e4099e80124ed23f451&csf=1&web=1&e=YU4c91" target="_blank" rel="noreferrer">
                                            <span className="contentlisting">Setting up regional alerts and bookmarks</span>
                                        </a>
                                    </div>
                                    <div className="linked-items">
                                        <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMS_Exporting%20to%20Excel.pptx?d=w9fc0a0086cd8494db1d67d59a85625ab&amp;csf=1&amp;e=pFR8Tg" target="_blank" rel="noreferrer">
                                            <span className="contentlisting">Exporting to Excel</span>
                                        </a>
                                    </div>
                                    <div className="linked-items">
                                        <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMS_Bookmark.pptx?d=w493ef4493f26424b8b5d0cea129580af&amp;csf=1&amp;e=fRfT3z" target="_blank" rel="noreferrer">
                                            <span className="contentlisting">Bookmarking searches</span>
                                        </a>
                                    </div>
                                    <div className="linked-items">
                                        <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMS_Reading%20List.pptx?d=wa1c2a3b39a3d4a0792e5e3ac9387841d&amp;csf=1&amp;e=s9cK8c" target="_blank" rel="noreferrer">
                                            <span className="contentlisting">Creating Reading Lists</span>
                                        </a>
                                    </div>
                                    <div className="linked-items">
                                        <a href="https://jnj.sharepoint.com/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMKS%20Congress%20Name%20Abbreviations%20and%20Corresponding%20Full%20Names.xlsx?d=w046511efbdbf4e01a952e056529c1b11" target="_blank" rel="noreferrer">
                                            <span className="contentlisting">Congress Name Abbreviations and Corresponding Full Names</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h1>
                                <br />
                                <br />
                            </h1>
                        </div>
                        <Footer customWidth={this.state.width} customHeight={this.state.height} />
                    </div>
                </>
            )
        }
        else if (this.state.width <= 1000 && this.state.width > 600) {
            return (
                <>
                    <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                    <HeaderWithBanner />
                    <div className="helpPageWrapper tablet-helpPageWrapper">
                        <div className="helpContentWrapper tablet-helpContentWrapper">
                            <div className="upperCard">
                                <h3>
                                    Other Questions about​ iMedical Search (i​MS)​?
                                </h3>
                                <p className="helpContentInnerWrapper">
                                    Get​ help by emailing <a href="mailto:RA-JANUS-iMedical@ITS.JNJ.com" title="email the support mailbox">iMedical Search</a>​. This email account is monitored five days a week, 8 hours a day. Once your email is acknowledged, an IRIS ticket will be created if your issue requires technical support from the global team. For non-technical issues such as "how to" questions regarding how to conduct more effective searches, your ​message will be forwarded to a regional liaison for further action.
                                </p>
                                <div className="trainingHeading tablet-trainingHeading">
                                    <h3>
                                        <span>
                                            Training and Other Reference Materials
                                        </span>
                                    </h3>
                                </div>
                                <div className="helpContentList">
                                    <ul style={{ listStyleType: "none" }} className="listing">
                                        <li className="listingContent">
                                            <img src={listIcon} alt="List" className="listIcon" />
                                            <a href="https://jnj.sharepoint.com/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMKS%20Overview.pptx?d=waab16ebbdfb34c328b970d75012c8da3" target="_blank" rel="noreferrer">
                                                <span className="contentlisting">iMedical Search (iMS) Overview</span></a>
                                        </li>
                                        <li className="listingContent">
                                            <img src={listIcon} alt="List" className="listIcon" />
                                            <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMS_Product%20Preferences.pptx?d=w71a7f74994704868baf0628846081737&amp;csf=1&amp;e=ZkSurK" target="_blank" rel="noreferrer"><span className="contentlisting">Viewing/Editing Product Preferences</span></a>
                                        </li>
                                        <li className="listingContent">
                                            <img src={listIcon} alt="List" className="listIcon" />
                                            <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/Instructions%20for%20accessing%20PubMed%20via%20iMedical%20Search.pptx?d=wd470fb5ecaff4ff9a9b6544b02889dd9&amp;csf=1&amp;web=1&amp;e=iAwsPR" target="_blank" rel="noreferrer"><span className="contentlisting">Instructions for Accessing PubMed Content via iMedical Search</span></a>
                                        </li>
                                        <li className="listingContent">
                                            <img src={listIcon} alt="List" className="listIcon" />
                                            <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMS_Setting%20Up%20Personal%20Alerts.pptx?d=w100e67141726406084ff788dad6d3208&amp;csf=1&amp;e=ShCCB4" target="_blank" rel="noreferrer"><span className="contentlisting">Setting up personal alerts</span></a>
                                        </li>
                                        <li className="listingContent">
                                            <img src={listIcon} alt="List" className="listIcon" />
                                            <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMS_Pre-Packaged%20Product%20Alerts.pptx?d=w553921201f5540b78e5e173df0740765&amp;csf=1&amp;e=Bjht9r" target="_blank" rel="noreferrer"><span className="contentlisting">Subscribing to “pre-packaged” product alerts</span></a>
                                        </li>
                                        <li className="listingContent">
                                            <img src={listIcon} alt="List" className="listIcon" />
                                            <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/Setting%20up%20Regional%20Alerts%20Bookmarks.pptx?d=w1d3df33cf2264e4099e80124ed23f451&csf=1&web=1&e=YU4c91" target="_blank" rel="noreferrer"><span className="contentlisting">Setting up regional alerts and bookmarks</span></a>
                                        </li>
                                        <li className="listingContent">
                                            <img src={listIcon} alt="List" className="listIcon" />
                                            <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMS_Exporting%20to%20Excel.pptx?d=w9fc0a0086cd8494db1d67d59a85625ab&amp;csf=1&amp;e=pFR8Tg" target="_blank" rel="noreferrer"><span className="contentlisting">Exporting to Excel</span></a>
                                        </li>
                                        <li className="listingContent">
                                            <img src={listIcon} alt="List" className="listIcon" />
                                            <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMS_Bookmark.pptx?d=w493ef4493f26424b8b5d0cea129580af&amp;csf=1&amp;e=fRfT3z" target="_blank" rel="noreferrer"><span className="contentlisting">Bookmarking searches</span></a>
                                        </li>
                                        <li className="listingContent">
                                            <img src={listIcon} alt="List" className="listIcon" />
                                            <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMS_Reading%20List.pptx?d=wa1c2a3b39a3d4a0792e5e3ac9387841d&amp;csf=1&amp;e=s9cK8c" target="_blank" rel="noreferrer"><span className="contentlisting">Creating Reading Lists</span></a>
                                        </li>
                                        <li className="listingContent">
                                            <img src={listIcon} alt="List" className="listIcon" />
                                            <a href="https://jnj.sharepoint.com/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMKS%20Congress%20Name%20Abbreviations%20and%20Corresponding%20Full%20Names.xlsx?d=w046511efbdbf4e01a952e056529c1b11" target="_blank" rel="noreferrer"><span className="contentlisting">Congress Name Abbreviations and Corresponding Full Names</span></a>
                                        </li>
                                    </ul>
                                    <br />
                                </div>
                            </div>
                            <h1>
                                <br />
                                <br />
                            </h1>
                        </div>
                        <Footer customWidth={this.state.width} customHeight={this.state.height} />
                    </div>
                </>
            )
        }
        else {
            return (
                <>
                    <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                    <HeaderWithBanner />
                    <div className="helpPageWrapper">
                        <div className="helpContentWrapper">
                            <div className="upperCard">
                                <h3>
                                    Other Questions about​ iMedical Search (i​MS)​?
                                </h3>
                                <p className="helpContentInnerWrapper">
                                    Get​ help by emailing <a href="mailto:RA-JANUS-iMedical@ITS.JNJ.com" title="email the support mailbox">iMedical Search</a>​. This email account is monitored five days a week, 8 hours a day. Once your email is acknowledged, an IRIS ticket will be created if your issue requires technical support from the global team. For non-technical issues such as "how to" questions regarding how to conduct more effective searches, your ​message will be forwarded to a regional liaison for further action.
                                </p>
                            </div>
                            <div className="lowerCard">
                                <div className="trainingHeading">
                                    <h3>
                                        <span>
                                            Training and Other Reference Materials
                                        </span>
                                    </h3>
                                </div>
                                <div className="helpContentList">
                                    <ul style={{ listStyleType: "none" }} className="listing">
                                        <li className="listingContent">
                                            <img src={listIcon} alt="List" className="listIcon" />
                                            <a href="https://jnj.sharepoint.com/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMKS%20Overview.pptx?d=waab16ebbdfb34c328b970d75012c8da3" target="_blank" rel="noreferrer">
                                                <span className="contentlisting">iMedical Search (iMS) Overview</span></a>
                                        </li>
                                        <li className="listingContent">
                                            <img src={listIcon} alt="List" className="listIcon" />
                                            <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMS_Product%20Preferences.pptx?d=w71a7f74994704868baf0628846081737&amp;csf=1&amp;e=ZkSurK" target="_blank" rel="noreferrer"><span className="contentlisting">Viewing/Editing Product Preferences</span></a>
                                        </li>
                                        <li className="listingContent">
                                            <img src={listIcon} alt="List" className="listIcon" />
                                            <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/Instructions%20for%20accessing%20PubMed%20via%20iMedical%20Search.pptx?d=wd470fb5ecaff4ff9a9b6544b02889dd9&amp;csf=1&amp;web=1&amp;e=iAwsPR" target="_blank" rel="noreferrer"><span className="contentlisting">Instructions for Accessing PubMed Content via iMedical Search</span></a>
                                        </li>
                                        <li className="listingContent">
                                            <img src={listIcon} alt="List" className="listIcon" />
                                            <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMS_Setting%20Up%20Personal%20Alerts.pptx?d=w100e67141726406084ff788dad6d3208&amp;csf=1&amp;e=ShCCB4" target="_blank" rel="noreferrer"><span className="contentlisting">Setting up personal alerts</span></a>
                                        </li>
                                        <li className="listingContent">
                                            <img src={listIcon} alt="List" className="listIcon" />
                                            <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMS_Pre-Packaged%20Product%20Alerts.pptx?d=w553921201f5540b78e5e173df0740765&amp;csf=1&amp;e=Bjht9r" target="_blank" rel="noreferrer"><span className="contentlisting">Subscribing to “pre-packaged” product alerts</span></a>
                                        </li>
                                        <li className="listingContent">
                                            <img src={listIcon} alt="List" className="listIcon" />
                                            <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/Setting%20up%20Regional%20Alerts%20Bookmarks.pptx?d=w1d3df33cf2264e4099e80124ed23f451&csf=1&web=1&e=YU4c91" target="_blank" rel="noreferrer"><span className="contentlisting">Setting up regional alerts and bookmarks</span></a>
                                        </li>
                                        <li className="listingContent">
                                            <img src={listIcon} alt="List" className="listIcon" />
                                            <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMS_Exporting%20to%20Excel.pptx?d=w9fc0a0086cd8494db1d67d59a85625ab&amp;csf=1&amp;e=pFR8Tg" target="_blank" rel="noreferrer"><span className="contentlisting">Exporting to Excel</span></a>
                                        </li>
                                        <li className="listingContent">
                                            <img src={listIcon} alt="List" className="listIcon" />
                                            <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMS_Bookmark.pptx?d=w493ef4493f26424b8b5d0cea129580af&amp;csf=1&amp;e=fRfT3z" target="_blank" rel="noreferrer"><span className="contentlisting">Bookmarking searches</span></a>
                                        </li>
                                        <li className="listingContent">
                                            <img src={listIcon} alt="List" className="listIcon" />
                                            <a href="https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMS_Reading%20List.pptx?d=wa1c2a3b39a3d4a0792e5e3ac9387841d&amp;csf=1&amp;e=s9cK8c" target="_blank" rel="noreferrer"><span className="contentlisting">Creating Reading Lists</span></a>
                                        </li>
                                        <li className="listingContent">
                                            <img src={listIcon} alt="List" className="listIcon" />
                                            <a href="https://jnj.sharepoint.com/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMKS%20Congress%20Name%20Abbreviations%20and%20Corresponding%20Full%20Names.xlsx?d=w046511efbdbf4e01a952e056529c1b11" target="_blank" rel="noreferrer"><span className="contentlisting">Congress Name Abbreviations and Corresponding Full Names</span></a>
                                        </li>
                                    </ul>
                                    <br />
                                </div>
                            </div>
                            <h1>
                                <br />
                                <br />
                            </h1>
                        </div>
                        <Footer customWidth={this.state.width} customHeight={this.state.height} />
                    </div>
                </>
            )
        }
    }
}
export default GetHelp