import { useEffect, useState } from "react";

const CellEdit = (props) =>{
    const [value ,setValue] = useState('');
    const [folderId, setFolderId] = useState();
    const [fileid , setFileid] = useState();
    const [isFolder, setIsFolder] = useState(false);
    useEffect (()=>{
        if(props.row.docid > -1){
            setFileid(props.row.docid)
        }
        else{
            setFolderId(props.row.folderid)
            setIsFolder(true)
        }
        setValue(props.row.tag)
    },[props.row])

    const handleTag = (e) =>{
    setValue(e.target.value)
    }
    return(
        <div className="nbv-notes-edit">
                <div className="nbv-notes-cell-txt">
                    <input
                        value={value}
                        onChange={(e) => handleTag(e)}
                    ></input>
                </div>
                <div className="nbv-notes-edit-cell-btn">
                    <a
                        onClick={(e) => {
                            if(isFolder){
                            props.handleSaveEdit(value,props.prevValue,isFolder,folderId)
                        }
                    else{
                        props.handleSaveEdit(value,props.prevValue,isFolder,fileid)
                    }}}
                        title="Save"
                        className="nbv-notes-cell-action-text"
                    >
                        Save
                    </a>
                    <span className="action-items-separate-divider"></span>
                    <a
                        onClick={(e) => {props.handleCancelEdit()}}
                        title="Cancel"
                        className="nbv-notes-cell-action-cancel"
                    >
                        Cancel
                    </a>
                </div>
            </div>
    )
}
export default CellEdit;