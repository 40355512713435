/* eslint-disable array-callback-return */
import { Component } from "react";
import {
  rightSideArrow,
  popCancelBttn,
  magnifyingGlass,
  iIcon,
  crossIcon,
} from "../../assets";
import trackingService from "../../services/trackingService";
import "./ContentShortcuts.css";
import { withRouter } from "react-router-dom";
import {
  CSEPSelectedProduct,
  CSEPDocumentType,
} from "../../utils/utilityServices";
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal";

const convertToHtml = (data) => {
  return <div dangerouslySetInnerHTML={{ __html: data }}></div>;
};
class EstablishedProductsTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCSModal: false,
      showInfo: false,
      searchKeyword: "",
      keyBoardEvent: false,
      keyEventName: "",
      productSelectStatus: [],
      selectAll: false,
      documentTypeSelectStatus: [],
      selectAllDoc: false,
      alertMessageModelColor: "",
      alertMessageContent: "",
      alertMessageShowModal: false,
    };
  }
  componentDidMount = () => {
    let productSelectStatus = [];
    let documentTypeSelectStatus = [];
    for (let index = 0; index < CSEPSelectedProduct.length; index++) {
      productSelectStatus.push(false);
    }
    for (let index = 0; index < CSEPDocumentType.length; index++) {
      documentTypeSelectStatus.push(false);
    }
    this.setState({
      productSelectStatus: productSelectStatus,
      documentTypeSelectStatus: documentTypeSelectStatus,
    });
  };
  componentDidUpdate = () => {
    if (this.props.showEPCSModal !== this.state.showCSModal) {
      this.setState({ showCSModal: this.props.showEPCSModal });
    }
  };
  handleChangeInSearch = (event) => {
    this.setState({
      searchKeyword: event.target.value,
    });
  };
  handleCancelSearchKeyword = () => {
    this.setState({
      searchKeyword: "",
    });
  };
  errorAlertMessage = (message) => {
    this.setState({
      alertMessageContent: message,
      alertMessageModelColor: "#de1327",
      alertMessageShowModal: true
    })
  }
  closeAlertMessage = () => {
    this.setState({
      alertMessageContent: "",
      alertMessageModelColor: "",
      alertMessageShowModal: false
    })
  }
  handleOnClickSearchIcon = () => {
    // const newUrl = this.buildUrl(
    //     this.state.searchKeyword,
    //     'Drugs - JNJ/Established Products'
    //     // this.props.appliedFilters
    // );
    // this.props.handleChangeInSearch(this.state.searchKeyword);
    let newUrl;
    let selectedFilters;
    let selectedProducts = this.getSelectedProducts();
    let selectedDocTypes = this.getSelectedDocTypes();
    let csProductVal = selectedProducts.map((data) => data).join(";");
    let csPathVal = selectedDocTypes.map((data) => data).join(";");
    if (
      this.state.searchKeyword.length <= 0 &&
      selectedProducts.length <= 0 &&
      selectedDocTypes.length <= 0
    ) {
      this.errorAlertMessage(
        "Please enter a keyword to search for or select atleast one filter from Product/Document Type!"
      );
    } else {
      if (
        this.state.searchKeyword.length > 0 &&
        selectedProducts.length <= 0 &&
        selectedDocTypes.length <= 0
      ) {
        newUrl = `/results?query=${this.state.searchKeyword}&q=All Fields&filters=Drugs - JNJ/Established Products`;
      } else if (
        selectedProducts.length > 0 &&
        this.state.searchKeyword.length > 0 &&
        selectedDocTypes.length <= 0
      ) {
        if (this.state.selectAll) {
          selectedFilters = "Drugs - JNJ/Established Products";
        } else {
          selectedFilters = selectedProducts
            .map((data) => `Drugs - JNJ/Established Products/${data}`)
            .join(";");
        }
        newUrl = `/results?query=${this.state.searchKeyword}&q=All Fields&filters=${selectedFilters}`;
      } else if (
        selectedProducts.length > 0 &&
        this.state.searchKeyword.length <= 0 &&
        selectedDocTypes.length <= 0
      ) {
        if (this.state.selectAll) {
          selectedFilters = "Drugs - JNJ/Established Products";
        } else {
          selectedFilters = selectedProducts
            .map((data) => `Drugs - JNJ/Established Products/${data}`)
            .join(";");
        }
        newUrl = `/results?query=&q=All Fields&filters=${selectedFilters}`;
      } else if (
        selectedProducts.length <= 0 &&
        this.state.searchKeyword.length <= 0 &&
        selectedDocTypes.length > 0
      ) {
        selectedFilters = selectedDocTypes
          .map((data) => `Document Type/${data}`)
          .join(";");
        newUrl = `/results?query=&q=All Fields&filters=Drugs - JNJ/Established Products;${selectedFilters}`;
      } else if (
        selectedProducts.length <= 0 &&
        this.state.searchKeyword.length > 0 &&
        selectedDocTypes.length > 0
      ) {
        selectedFilters = selectedDocTypes
          .map((data) => `Document Type/${data}`)
          .join(";");
        newUrl = `/results?query=${this.state.searchKeyword}&q=All Fields&filters=Drugs - JNJ/Established Products;${selectedFilters}`;
      } else if (
        selectedProducts.length > 0 &&
        this.state.searchKeyword.length <= 0 &&
        selectedDocTypes.length > 0
      ) {
        let productFilter;
        let docTypeFilter;
        if (this.state.selectAll) {
          productFilter = "Drugs - JNJ/Established Products";
        } else {
          productFilter = selectedProducts
            .map((data) => `Drugs - JNJ/Established Products/${data}`)
            .join(";");
        }
        docTypeFilter = selectedDocTypes
          .map((data) => `Document Type/${data}`)
          .join(";");
        selectedFilters = productFilter.concat(";", docTypeFilter);
        newUrl = `/results?query=&q=All Fields&filters=${selectedFilters}`;
      } else {
        let productFilter;
        let docTypeFilter;
        if (this.state.selectAll) {
          productFilter = "Drugs - JNJ/Established Products";
        } else {
          productFilter = selectedProducts
            .map((data) => `Drugs - JNJ/Established Products/${data}`)
            .join(";");
        }
        docTypeFilter = selectedDocTypes
          .map((data) => `Document Type/${data}`)
          .join(";");
        selectedFilters = productFilter.concat(";", docTypeFilter);
        newUrl = `/results?query=${this.state.searchKeyword}&q=All Fields&filters=${selectedFilters}`;
      }
      //this.props.history.push(newUrl);
      window.open(newUrl, "_blank");
      const ts = new trackingService();
      ts.indexTrackingData(
        localStorage.getItem("userdisplayName"),
        localStorage.getItem("wwid"),
        localStorage.getItem("username"),
        localStorage.getItem("function"),
        localStorage.getItem("region"),
        localStorage.getItem("userproducts"),
        localStorage.getItem("doctypepref"),
        localStorage.getItem("sid"),
        localStorage.getItem("deviceType"),
        localStorage.getItem("deviceOS"),
        localStorage.getItem("browserName"),
        localStorage.getItem("browserVersion"),
        localStorage.getItem("jnjDepartment"),
        localStorage.getItem("companyname"),
        "Conducted Established Products Search",
        "",
        window.location.href,
        this.state.searchKeyword,
        selectedProducts.length <= 0 &&
          this.state.searchKeyword.length <= 0 &&
          selectedDocTypes.length > 0
          ? `Drugs - JNJ/Established Products;${selectedFilters}`
          : selectedFilters
          ? selectedFilters
          : "Drugs - JNJ/Established Products", //this.props.appliedFilters,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        `Established Products - ${csPathVal}`,
        csProductVal.length > 0
          ? this.state.selectAll
            ? "All Products"
            : csProductVal
          : ""
      );
      this.setState({
        keyBoardEvent: false,
        keyEventName: "",
      });
    }
  };
  handleKeyDown = (event) => {
    // const newUrl = this.buildUrl(
    //   event.target.value,
    //   this.state.dropdownValue,
    //   this.state.fieldedValue,
    //   this.props.appliedFilters
    // );
    // const newUrl = `/results?query=${this.state.searchKeyword}&q=All Fields&filters=Drugs - JNJ/Established Products`;
    let newUrl;
    let selectedFilters;
    let selectedProducts = this.getSelectedProducts();
    let selectedDocTypes = this.getSelectedDocTypes();
    let csProductVal = selectedProducts.map((data) => data).join(";");
    let csPathVal = selectedDocTypes.map((data) => data).join(";");
    if (
      this.state.searchKeyword.length > 0 &&
      selectedProducts.length <= 0 &&
      selectedDocTypes.length <= 0
    ) {
      newUrl = `/results?query=${this.state.searchKeyword}&q=All Fields&filters=Drugs - JNJ/Established Products`;
    } else if (
      selectedProducts.length > 0 &&
      this.state.searchKeyword.length > 0 &&
      selectedDocTypes.length <= 0
    ) {
      if (this.state.selectAll) {
        selectedFilters = "Drugs - JNJ/Established Products";
      } else {
        selectedFilters = selectedProducts
          .map((data) => `Drugs - JNJ/Established Products/${data}`)
          .join(";");
      }
      newUrl = `/results?query=${this.state.searchKeyword}&q=All Fields&filters=${selectedFilters}`;
    } else if (
      selectedProducts.length > 0 &&
      this.state.searchKeyword.length <= 0 &&
      selectedDocTypes.length <= 0
    ) {
      if (this.state.selectAll) {
        selectedFilters = "Drugs - JNJ/Established Products";
      } else {
        selectedFilters = selectedProducts
          .map((data) => `Drugs - JNJ/Established Products/${data}`)
          .join(";");
      }
      newUrl = `/results?query=&q=All Fields&filters=${selectedFilters}`;
    } else if (
      selectedProducts.length <= 0 &&
      this.state.searchKeyword.length <= 0 &&
      selectedDocTypes.length > 0
    ) {
      selectedFilters = selectedDocTypes
        .map((data) => `Document Type/${data}`)
        .join(";");
      newUrl = `/results?query=&q=All Fields&filters=Drugs - JNJ/Established Products;${selectedFilters}`;
    } else if (
      selectedProducts.length <= 0 &&
      this.state.searchKeyword.length > 0 &&
      selectedDocTypes.length > 0
    ) {
      selectedFilters = selectedDocTypes
        .map((data) => `Document Type/${data}`)
        .join(";");
      newUrl = `/results?query=${this.state.searchKeyword}&q=All Fields&filters=Drugs - JNJ/Established Products;${selectedFilters}`;
    } else if (
      selectedProducts.length > 0 &&
      this.state.searchKeyword.length <= 0 &&
      selectedDocTypes.length > 0
    ) {
      let productFilter;
      let docTypeFilter;
      if (this.state.selectAll) {
        productFilter = "Drugs - JNJ/Established Products";
      } else {
        productFilter = selectedProducts
          .map((data) => `Drugs - JNJ/Established Products/${data}`)
          .join(";");
      }
      docTypeFilter = selectedDocTypes
        .map((data) => `Document Type/${data}`)
        .join(";");
      selectedFilters = productFilter.concat(";", docTypeFilter);
      newUrl = `/results?query=&q=All Fields&filters=${selectedFilters}`;
    } else {
      let productFilter;
      let docTypeFilter;
      if (this.state.selectAll) {
        productFilter = "Drugs - JNJ/Established Products";
      } else {
        productFilter = selectedProducts
          .map((data) => `Drugs - JNJ/Established Products/${data}`)
          .join(";");
      }
      docTypeFilter = selectedDocTypes
        .map((data) => `Document Type/${data}`)
        .join(";");
      selectedFilters = productFilter.concat(";", docTypeFilter);
      newUrl = `/results?query=${this.state.searchKeyword}&q=All Fields&filters=${selectedFilters}`;
    }
    if (["Enter"].includes(event.key)) {
      //   this.props.handleChangeInSearch(this.state.searchKeyword);
      //this.props.history.push(newUrl);
        window.open(newUrl, "_blank");
      const ts = new trackingService();
      ts.indexTrackingData(
        localStorage.getItem("userdisplayName"),
        localStorage.getItem("wwid"),
        localStorage.getItem("username"),
        localStorage.getItem("function"),
        localStorage.getItem("region"),
        localStorage.getItem("userproducts"),
        localStorage.getItem("doctypepref"),
        localStorage.getItem("sid"),
        localStorage.getItem("deviceType"),
        localStorage.getItem("deviceOS"),
        localStorage.getItem("browserName"),
        localStorage.getItem("browserVersion"),
        localStorage.getItem("jnjDepartment"),
        localStorage.getItem("companyname"),
        "Conducted Established Products Search",
        "",
        window.location.href,
        this.state.searchKeyword,
        selectedProducts.length <= 0 &&
          this.state.searchKeyword.length <= 0 &&
          selectedDocTypes.length > 0
          ? `Drugs - JNJ/Established Products;${selectedFilters}`
          : selectedFilters
          ? selectedFilters
          : "Drugs - JNJ/Established Products", //this.props.appliedFilters,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        `Established Products - ${csPathVal}`,
        csProductVal.length > 0
          ? this.state.selectAll
            ? "All Products"
            : csProductVal
          : ""
      );
    }
    if (["ArrowDown"].includes(event.key) || ["ArrowUp"].includes(event.key)) {
      //console.log('Arrow Action')
      this.setState({
        keyBoardEvent: true,
        keyEventName: event.key,
      });
    }
  };
  getSelectedProducts() {
    let selectedProducts = [];
    this.state.productSelectStatus.map((val, key) => {
      if (val) {
        selectedProducts.push(CSEPSelectedProduct[key]);
      }
    });
    return selectedProducts;
  }
  getSelectedDocTypes() {
    let selectedDocTypes = [];
    this.state.documentTypeSelectStatus.map((val, key) => {
      if (val) {
        selectedDocTypes.push(CSEPDocumentType[key]);
      }
    });
    return selectedDocTypes;
  }
  showDetails = () => {
    const ts = new trackingService();
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      localStorage.getItem("companyname"),
      "Expanded Established Products tile under content shortcuts",
      "",
      window.location.href
      //   this.state.searchKeyword,
      //   this.props.appliedFilters
    );
    let productSelectStatus = [];
    let documentTypeSelectStatus = [];
    for (let index = 0; index < CSEPSelectedProduct.length; index++) {
      productSelectStatus.push(false);
    }
    for (let index = 0; index < CSEPDocumentType.length; index++) {
      documentTypeSelectStatus.push(false);
    }
    this.setState(
      {
        showCSModal: !this.state.showCSModal,
        productSelectStatus: productSelectStatus,
        documentTypeSelectStatus: documentTypeSelectStatus,
        selectAll: false,
        selectAllDoc: false,
      },
      () => {
        this.props.handelEPCardDetail(this.state.showCSModal);
      }
    );
  };
  onMouseEnterInfo = () => {
    this.setState({
      showInfo: true,
    });
  };
  onMouseLeaveInfo = () => {
    this.setState({
      showInfo: false,
    });
  };
  handleCloseModal = () => {
    this.setState({ showCSModal: false, searchKeyword:"" }, () => {
      this.props.handelEPCardDetail(this.state.showCSModal);
    });
  };
  render() {
    const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
    var privacyHtml = `
        <div class="cs-modalNote">
            <p>PLEASE NOTE: One or more of the categories listed may retrieve zero results for the product you selected. This is because 1) content within those categories has not yet been made available for that product, or 2) the content is restricted/firewalled due to your Location and/or Function.</p>
        </div>`;
    return (
      <>
      <div className="contentShortcutInnerWapper">
        <div
          className="cardContainer"
          onClick={this.showDetails}
          style={{
            borderTop: this.state.showCSModal ? "5px solid #0074B0" : "none",
          }}
        >
          <div
            className="cardInnerWapper"
            style={this.state.showCSModal ? { borderBottom: "none" } : {}}
          >
            <div
              className="card-leftPart cs-right-r4-tile-"
              style={
                this.props.customWidth >= 1000
                  ? { textAlign: "center", position: "absolute", width: "31%" }
                  : {}
              }
            >
              <small>
              <span className="cs-name-bold">Established Products</span> <br /> (Direct Access to EP Content by
                Product)
              </small>
            </div>
            <div
              className="card-iconsPart"
              style={
                this.props.customWidth >= 1000 ? { marginLeft: "auto" } : {}
              }
            >
              <img
                src={iIcon}
                alt="i-icon"
                width={18}
                height={18}
                onMouseEnter={this.onMouseEnterInfo}
                onMouseLeave={this.onMouseLeaveInfo}
                className="cs-tile-i-icon"
              />
              <div
                className="card-rightPart"
                // onClick={this.showDetails}
              >
                <img
                  src={rightSideArrow}
                  alt="down-arrow"
                  className="cs-downArrow"
                  style={{
                    transform: this.state.showCSModal ? "rotate(270deg)" : null,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.showInfo && (
          <div className="cs-tile-wrapper">
            <div className="cs-tooltip-uparrow"></div>
            <span className="tooltiptext cs-tooltip-bottom">
              This tile provides direct access to the Established Products
              content.
            </span>
          </div>
        )}
        {this.state.showCSModal && (
          <div
            className="cs-modalInner"
            style={
              this.props.customWidth >= 1000
                ? { position: "absolute", width: "97.9%" }
                : {}
            }
          >
            <div>
              <div class="popup-cancel-btn-wrapper">
                <img
                  src={popCancelBttn}
                  alt=""
                  onClick={this.handleCloseModal}
                />
              </div>
              <div>
                <div
                  className="cs-modalHeader"
                  style={{
                    justifyContent: "center",
                    borderBottom: "none",
                    margin: " 4% 0 0 0",
                  }}
                >
                  <div className="cs-header-wrapper" style={{ height: "44px" }}>
                    <div className="cs-input-box-wrapper">
                      <input
                        name="searchBar"
                        className="cs-input-search-box"
                        title="EP Search Box"
                        value={this.state.searchKeyword}
                        onChange={this.handleChangeInSearch}
                        onKeyDown={this.handleKeyDown}
                      />
                      <span className="cs-icon-wrapper">
                        {this.state.searchKeyword && (
                          <img
                            src={crossIcon}
                            className="input-search-cancel-icon"
                            alt="clear"
                            title="Click here to clear the search terms"
                            onClick={this.handleCancelSearchKeyword}
                          />
                        )}
                      </span>
                    </div>
                    <span
                      className="cs-search-icon-wrapper-webView"
                      onClick={this.handleOnClickSearchIcon}
                    >
                      <img
                        src={magnifyingGlass}
                        className="cs-magnifyingGlass"
                        alt="search"
                        title="Search"
                      />
                    </span>
                  </div>
                </div>
                <div class="modal-body">
                  <div className="cs-ep-notes">
                    {convertToHtml(privacyHtml)}
                  </div>
                  <div className="cs-select-preference-wrapper">
                    <div className="cs-fieldset-wrapper">
                      <div className="cs-fldep">
                        <span className="eplegend">Select Product</span>
                        <ul className="cs-preference-lists">
                          <li>
                            <label
                              key={Math.random()}
                              onClick={() => {
                                let a = this.state.productSelectStatus;
                                a = a.map((x) => !this.state.selectAll);
                                this.setState({
                                  productSelectStatus: a,
                                  selectAll: !this.state.selectAll,
                                });
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={this.state.selectAll}
                                onChange={() => {}}
                              />
                              Select All
                            </label>
                          </li>
                          {CSEPSelectedProduct.length > 0 &&
                            CSEPSelectedProduct.map((data, index) => {
                              return (
                                <li>
                                  <label
                                    key={data + index + Math.random()}
                                    onClick={() => {
                                      let a = this.state.productSelectStatus;
                                      a[index] =
                                        !this.state.productSelectStatus[index];
                                      this.setState({
                                        selectAll: false,
                                        productSelectStatus: a,
                                      });
                                      let checkSelectStatus = a.filter(
                                        (item) => item
                                      );
                                      if (
                                        checkSelectStatus.length ===
                                        CSEPSelectedProduct.length
                                      ) {
                                        this.setState({ selectAll: true });
                                      }
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={
                                        this.state.productSelectStatus[index]
                                      }
                                      onChange={() => {}}
                                    />
                                    {data}
                                  </label>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                    <div className="cs-fieldset-wrapper">
                      <div className="cs-fldep">
                        <span className="eplegend">Document Type</span>
                        <ul className="cs-preference-lists">
                          <li>
                            <label
                              key={Math.random()}
                              onClick={() => {
                                let a = this.state.documentTypeSelectStatus;
                                a = a.map((x) => !this.state.selectAllDoc);
                                this.setState({
                                  documentTypeSelectStatus: a,
                                  selectAllDoc: !this.state.selectAllDoc,
                                });
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={this.state.selectAllDoc}
                                onChange={() => {}}
                              />
                              Select All
                            </label>
                          </li>
                          {CSEPDocumentType.length > 0 &&
                            CSEPDocumentType.map((data, index) => {
                              return (
                                <li>
                                  <label
                                    key={data + index + Math.random()}
                                    onClick={() => {
                                      let a =
                                        this.state.documentTypeSelectStatus;
                                      a[index] =
                                        !this.state.documentTypeSelectStatus[
                                          index
                                        ];
                                      this.setState({
                                        selectAllDoc: false,
                                        documentTypeSelectStatus: a,
                                      });
                                      let checkSelectStatus = a.filter(
                                        (item) => item
                                      );
                                      if (
                                        checkSelectStatus.length ===
                                        CSEPDocumentType.length
                                      ) {
                                        this.setState({ selectAllDoc: true });
                                      }
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={
                                        this.state.documentTypeSelectStatus[
                                          index
                                        ]
                                      }
                                      onChange={() => {}}
                                    />
                                    {data}
                                  </label>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* {this.state.showCSModal && <PopUpModal showModal={this.state.showCSModal} content={privacyHtml} handleClose={(status)=>this.setState({showCSModal:status})} />} */}
      </div>
      {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={()=>this.closeAlertMessage()} />}
      </>
    );
  }
}

export default withRouter(EstablishedProductsTile);
