import { Modal } from "react-bootstrap";
// import { popCancelBttn, exclaimIcn, imsLoader, readingListViewIcon, readingListEditIcon, readingListDeleteIcon } from "../../assets";
import { Component } from "react";
import Button from "../Button";
import ApiServices from "../../utils/apiServices";
import './ProductAlertsPopUp.css'
import trackingService from "../../services/trackingService";

class ProductAlertsPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productsName: [],
            frequencyValue: [], //'Daily'
            isSubscribe: [],
            productsAlerts: []
            // showPop: false,
            // apiResponse :[],
            // isLoaded : false,
            // showRenamePop : false,
            // itemtoBeRenamedName:'',
            // itemtoBeRenamedID:''
        }
    }
    async componentDidMount() {
        // let username = JSON.parse(localStorage.getItem('username')).split('@')[0];
        let service = new ApiServices();
        let apiRes = await service.getAllAlertsProducts();
        // let productsAlerts = await service.getProductsAlertsById(username);
        // console.log(productsAlerts,username)
        let feqArr = [];
        // let isSubscribe = [];
        // if (productsAlerts.length > 0) {
        //    for (let index = 0; index < productsAlerts.length; index++) {
        //        if (productsAlerts[index].alert_enabled === 1 && productsAlerts[index].isproduct_alert) {
        //            feqArr[productsAlerts[index].product_id-1] = productsAlerts[index].alert_frequency;
        //            isSubscribe[productsAlerts[index].product_id-1] = true
        //        }
        //    }
        // }
        for (let index = 0; index < apiRes.length; index++) {
            feqArr.push('Daily')
            // isSubscribe.push(false)
        }
        this.setState({
            productsName: apiRes,
            frequencyValue: feqArr,
            // isSubscribe: isSubscribe
            // isLoaded: true
        })
    }
    async componentWillReceiveProps(prevProp) {
        // let username = JSON.parse(localStorage.getItem('username')).split('@')[0];
        // let wwid = JSON.parse(localStorage.getItem('wwid'))
        let service = new ApiServices();
        let username = JSON.parse(localStorage.getItem('username')).toLowerCase();
        if (prevProp.showProductAlerts !== this.props.showProductAlerts) {
            let productsAlerts = await service.getProductsAlertsById(username);
            let feqArr = [];
            let isSubscribe = [];
            if (productsAlerts?.length !== this.state.productsAlerts.length) {
                let apiRes = await service.getAllAlertsProducts();
                if (productsAlerts?.length > 0) {
                    for (let index = 0; index < productsAlerts.length; index++) {
                        if (productsAlerts[index].alert_enabled === 1 && productsAlerts[index].isproduct_alert) {
                            let productIndex = apiRes.findIndex(x => x.product_name === productsAlerts[index].search_keyword)
                            //feqArr[productsAlerts[index].product_id - 1] = productsAlerts[index].alert_frequency;
                            //isSubscribe[productsAlerts[index].product_id - 1] = true
                            feqArr[productIndex] = productsAlerts[index].alert_frequency;
                            isSubscribe[productIndex] = true
                        }
                    }
                    for (let i = 0; i < apiRes.length; i++) {
                        if (!feqArr[i]) {
                            feqArr[i] = 'Daily'
                        }
                    }
                    this.setState({
                        frequencyValue: feqArr,
                        isSubscribe: isSubscribe,
                        productsAlerts: productsAlerts
                        // isLoaded: true
                    })
                }
            }
        }
    }
    handleAlertFrequency = async (e, index) => {
        let service = new ApiServices();
        let action = this.state.frequencyValue;
        action[index] = e.target.value;
        this.setState({ frequencyValue: action });

        let isProductSubscribe = this.state.isSubscribe[index];
        if (isProductSubscribe) {
            let search_name = this.state.productsName[index].product_name;
            let username = JSON.parse(localStorage.getItem('username')).toLowerCase();
            await service.updatePersonalAlerts(
                1,
                action[index],
                username,
                search_name
            );
        }
    }
    handleAlerts = async (e, index) => {
        let service = new ApiServices();
        // let uid = window.crypto.getRandomValues(new Uint32Array(1))[0];
        // let uid = this.randomIntFromInterval(100, 2147483647)
        let wwid = JSON.parse(localStorage.getItem('wwid'))
        let username = JSON.parse(localStorage.getItem('username')).toLowerCase();
        let productName = this.state.productsName[index].product_name
        let productid = this.state.productsName[index].product_id
        let alertFeq = this.state.frequencyValue[index]
        let currentDate = new Date()
        let checkStatus = this.state.isSubscribe
        checkStatus[index] = e.target.checked;
        let action = this.state.frequencyValue;
        this.setState({ isSubscribe: checkStatus });
        if (wwid === 0) {
            var jjedsres = await service.getJJEDSdetails(username.split('@')[0]);
            wwid = jjedsres.uid;
        }
        if (e.target.checked) {
            await service.createAlert(wwid, productName, productName, '', alertFeq, username, this.dateFormate(currentDate), productid, 1)
            this.handelAddProductAlert(productName)
        }
        else {
            action[index] = 'Daily';
            this.setState({ frequencyValue: action });
            await service.unsubscribeProductAlerts(username, productid)
        }
    }
    dateFormate = (date) => {
        return `${date.getFullYear()}-${(date.getMonth() + 1).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        })}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()}`
    }
    handelAddProductAlert = (product) => {
        const ts = new trackingService();
        ts.indexTrackingData(
            localStorage.getItem("userdisplayName"),
            localStorage.getItem("wwid"),
            localStorage.getItem("username"),
            localStorage.getItem("function"),
            localStorage.getItem("region"),
            localStorage.getItem("userproducts"),
            localStorage.getItem("doctypepref"),
            localStorage.getItem("sid"),
            localStorage.getItem("deviceType"),
            localStorage.getItem("deviceOS"),
            localStorage.getItem("browserName"),
            localStorage.getItem("browserVersion"),
            localStorage.getItem("jnjDepartment"),
            localStorage.getItem('companyname'),
            `Create Alert`,
            "",
            product,
            "",
            ""
        );
    }
    render() {
        if (this.props.customWidth < 1000) {
            return (<Modal
                show={this.props.showProductAlerts}
                size="lg md sm xs"
                centered
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="popup-transition-mob-tab"
                scrollable={true}
            >
                <div>
                    <div style={{ borderLeft: `6px solid #0074B0`, height: '100%' }}>
                        <div className="paModalWrapper" /* style={{ padding: "0 5%" }} */>
                            <Modal.Header className="productsAlertsHeader" /* style={{ justifyContent: "center", borderBottomColor: "#0074B0" }} */>
                                <span className="prodAlertHeadingText">Subscribe to Product Alerts</span>
                            </Modal.Header>
                            <Modal.Body scrollable={true} style={{ minHeight: "25vh" }}>
                                <div>
                                    <table className="productAlertsTable">
                                        <thead className="prodAlertsTableHeading">
                                            <tr>
                                                <th>Product</th>
                                                <th>Alert Frequency</th>
                                                <th>Subscribe</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.productsName.map((name, index) => {
                                                    return (
                                                        <tr className="prodAlertsData">
                                                            <td>
                                                                {/* <span>{name}</span> */}
                                                                <span>{name.product_name}</span>
                                                            </td>
                                                            <td>
                                                                <select name={`prod${name.product_id}`} id={`prod${index + 1}`} onChange={(e) => this.handleAlertFrequency(e, index)} value={this.state.frequencyValue[index]}>
                                                                    <option value="Daily">Daily</option>
                                                                    <option value="Weekly">Weekly</option>
                                                                    <option value="Monthly">Monthly</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <span>
                                                                    <input type="checkbox" class="k-checkbox" style={{ margin: 0 }} onChange={(e) => this.handleAlerts(e, index)} id={`prodsubscrib${name.product_id}`} checked={this.state.isSubscribe[index]} />
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Modal.Body>
                            <div className="prodAlertsButtonContainer" /* style={{ textAlign: "center", padding: "3% 0" }} */>
                                <Button text="Close" customColor="#0074B0" action={() => { this.setState({ showPop: false }); this.props.handleClose(false) }} />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            )
        }
        // else if(this.props.width<=1000 && this.props.width>600){
        //     return(<Modal
        //         show={this.state.showPop}
        //         size="lg md sm xs"
        //         centered
        //         aria-labelledby="contained-modal-title-vcenter"
        //         dialogClassName="popup-transition"
        //         scrollable={true}
        //         >
        //         <div style={{borderLeft:`5px solid #0074B0`}}>
        //             <div className="popup-cancel-btn-wrapper">
        //                 <img src={popCancelBttn} alt="" onClick={(e)=>{this.setState({showPop:false});this.props.handleClose(false)}}/>
        //             </div>
        //             <div style={{padding:"0 5%"}}>
        //                 <Modal.Header style={{justifyContent:"center",borderBottomColor:"#0074B0"}}>
        //                     <div style={{textAlign:"center"}}>
        //                     <Modal.Title>
        //                         <div>
        //                             <img src={exclaimIcn} alt="" className="popup-header-icon-wrapper"/>
        //                         </div>
        //                         <span style={{color:"#0074B0"}}>No Reading Lists</span>   
        //                     </Modal.Title>
        //                     </div>
        //                 </Modal.Header>
        //                 <Modal.Body scrollable={true} style={{minHeight:"35vh"}}>
        //                     <div>
        //                     It looks like you don't have any reading lists created yet. Please use 'Pin to Reading List' from the desired result/select a few and then click on 'Reading List' icon.
        //                     </div>
        //                 </Modal.Body>
        //                 <div style={{textAlign:"center",padding:"3% 0"}}>
        //                     <Button text="Close" customColor="#0074B0" action={()=>{this.setState({showPop:false});this.props.handleClose(false)}}/>
        //                 </div>
        //             </div>
        //         </div>
        //         </Modal>
        //         )
        // }
        else {
            return (
                <Modal
                    show={this.props.showProductAlerts}
                    size="lg md sm xs"
                    centered
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogClassName="popup-transition-prodAlerts"
                    scrollable={true}
                >
                    <div>
                        <div style={{ borderLeft: `6px solid #0074B0`, height: '100%' }}>
                            <div className="paModalWrapper" /* style={{ padding: "0 5%" }} */>
                                <Modal.Header className="productsAlertsHeader" /* style={{ justifyContent: "center", borderBottomColor: "#0074B0" }} */>
                                    <span className="prodAlertHeadingText">Subscribe to Product Alerts</span>
                                </Modal.Header>
                                <Modal.Body scrollable={true} style={{ minHeight: "25vh" }}>
                                    <div>
                                        <table className="productAlertsTable">
                                            <thead className="prodAlertsTableHeading">
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Alert Frequency</th>
                                                    <th>Subscribe</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.productsName.map((name, index) => {
                                                        return (
                                                            <tr className="prodAlertsData">
                                                                <td>
                                                                    {/* <span>{name}</span> */}
                                                                    <span>{name.product_name}</span>
                                                                </td>
                                                                <td>
                                                                    <select name={`prod${name.product_id}`} id={`prod${index + 1}`} onChange={(e) => this.handleAlertFrequency(e, index)} value={this.state.frequencyValue[index]}>
                                                                        <option value="Daily">Daily</option>
                                                                        <option value="Weekly">Weekly</option>
                                                                        <option value="Monthly">Monthly</option>
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <span>
                                                                        <input type="checkbox" class="k-checkbox" style={{ margin: 0 }} onChange={(e) => this.handleAlerts(e, index)} id={`prodsubscrib${name.product_id}`} checked={this.state.isSubscribe[index]} />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            {/* <tbody>
                                            <tr>
                                                <td>
                                                    <span>Apalutamide</span>
                                                </td>
                                                <td>
                                                    <select name="prod1" id="prod1">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className="prodAlertsEvevRow">
                                                <td>
                                                    <span>BALVERSA</span>
                                                </td>
                                                <td>
                                                    <select name="prod2" id="prod2">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>CILTA-CEL</span>
                                                </td>
                                                <td>
                                                    <select name="prod3" id="prod3">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className="prodAlertsEvevRow">
                                                <td>
                                                    <span>Darzalex</span>
                                                </td>
                                                <td>
                                                    <select name="prod4" id="prod4">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Edurant</span>
                                                </td>
                                                <td>
                                                    <select name="prod5" id="prod5">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className="prodAlertsEvevRow">
                                                <td>
                                                    <span>Esketamine</span>
                                                </td>
                                                <td>
                                                    <select name="prod6" id="prod6">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Guselkumab [Tremfya]</span>
                                                </td>
                                                <td>
                                                    <select name="prod7" id="prod7">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className="prodAlertsEvevRow">
                                                <td>
                                                    <span>Imbruvica</span>
                                                </td>
                                                <td>
                                                    <select name="prod8" id="prod8">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Intelence</span>
                                                </td>
                                                <td>
                                                    <select name="prod9" id="prod9">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className="prodAlertsEvevRow">
                                                <td>
                                                    <span>Invega Sustenna/Xeplion</span>
                                                </td>
                                                <td>
                                                    <select name="prod10" id="prod10">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Invega Trinza/Trevicta</span>
                                                </td>
                                                <td>
                                                    <select name="prod11" id="prod11">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className="prodAlertsEvevRow">
                                                <td>
                                                    <span>Invokana</span>
                                                </td>
                                                <td>
                                                    <select name="prod12" id="prod12">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Janssen COVID-19 VACCINE</span>
                                                </td>
                                                <td>
                                                    <select name="prod13" id="prod13">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className="prodAlertsEvevRow">
                                                <td>
                                                    <span>Niraparib [Zejula]</span>
                                                </td>
                                                <td>
                                                    <select name="prod14" id="prod14">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>OPSUMIT</span>
                                                </td>
                                                <td>
                                                    <select name="prod15" id="prod15">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className="prodAlertsEvevRow">
                                                <td>
                                                    <span>PONVORY</span>
                                                </td>
                                                <td>
                                                    <select name="prod16" id="prod16">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Prezcobix</span>
                                                </td>
                                                <td>
                                                    <select name="prod17" id="prod17">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className="prodAlertsEvevRow">
                                                <td>
                                                    <span>Prezista</span>
                                                </td>
                                                <td>
                                                    <select name="prod18" id="prod18">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Remicade</span>
                                                </td>
                                                <td>
                                                    <select name="prod19" id="prod19">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className="prodAlertsEvevRow">
                                                <td>
                                                    <span>RYBREVANT</span>
                                                </td>
                                                <td>
                                                    <select name="prod20" id="prod20">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Simponi</span>
                                                </td>
                                                <td>
                                                    <select name="prod21" id="prod21">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className="prodAlertsEvevRow">
                                                <td>
                                                    <span>SIMPONI ARIA</span>
                                                </td>
                                                <td>
                                                    <select name="prod22" id="prod22">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Stelara</span>
                                                </td>
                                                <td>
                                                    <select name="prod23" id="prod23">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className="prodAlertsEvevRow">
                                                <td>
                                                    <span>Symtuza</span>
                                                </td>
                                                <td>
                                                    <select name="prod24" id="prod24">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>UPTRAVI</span>
                                                </td>
                                                <td>
                                                    <select name="prod25" id="prod25">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className="prodAlertsEvevRow">
                                                <td>
                                                    <span>Xarelto</span>
                                                </td>
                                                <td>
                                                    <select name="prod26" id="prod26">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Yondelis</span>
                                                </td>
                                                <td>
                                                    <select name="prod27" id="prod27">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className="prodAlertsEvevRow">
                                                <td>
                                                    <span>Zytiga</span>
                                                </td>
                                                <td>
                                                    <select name="prod28" id="prod28">
                                                        <option value="Daily">Daily</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <span>
                                                        <input type="checkbox" />
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody> */}
                                        </table>
                                    </div>
                                </Modal.Body>
                                <div className="prodAlertsButtonContainer" /* style={{ textAlign: "center", padding: "3% 0" }} */>
                                    <Button text="Close" customColor="#0074B0" action={() => { this.setState({ showPop: false }); this.props.handleClose(false) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )
        }
    }

}
export default ProductAlertsPopup;


/* 'Apalutamide', 'BALVERSA', 'CILTA-CEL', 'Darzalex', 'Edurant', 'Esketamine', 'Guselkumab [Tremfya]', 'Imbruvica', 'Intelence', 'Invega Sustenna/Xeplion', 'Invega Trinza/Trevicta', 'Invokana', 'Janssen COVID-19 VACCINE', 'Niraparib [Zejula]', 'OPSUMIT', 'PONVORY', 'Prezcobix', 'Prezista', 'Remicade', 'RYBREVANT', 'Simponi', 'SIMPONI ARIA', 'Stelara', 'Symtuza', 'UPTRAVI', 'Xarelto', 'Yondelis', 'Zytiga' */