/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { dropdownArrow, filterExpandIcnTab, avatarBlueIcn, crossIcon, avatarWhiteIcn, IMSLogo, mainMenuExpMobile, mainMenuCollMobile, rightSideArrow } from "../../assets";
import DisclaimerPop from "../PopUp/DisclaimerPop";
import PrivacyStatementPopUp from "../PopUp/PrivacyStatementPopUp";
import HistoryPopUp from "../PopUp/HistoryPopUp";
import BookmarkPopUp from "../PopUp/BookmarkpopUp";
import ReadingListPopup from "../PopUp/ReadingListPopup";
import './Navigation.css';

export default function NavigationMenu(props){
    const {customWidth} = props
    const [display,setDisplay] = useState(false);
    const [displayMainMenu,setMainMenuDisplay] = useState(false);
    const [alertDisplay,setAlertdisplay] = useState(false);
    const [showDisclaimer,setDisclaimer] = useState(false);
    const [showPrivacy,setPrivacy] = useState(false);
    const [showHistory,setHistory] = useState(false);
    const [showBookmark,setBookmark] = useState(false);
    const [showReadingList,setReadingList] = useState(false);
    /* const [showRecentlyViewed,setRecentlyViewed] = useState(false); */
    const [compWidth,setWidth] = useState(0);
    /* const [dimensions, setDimensions] = useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      }) */
    const { pathName }=props;
    const isRussian = JSON.parse(localStorage.getItem('russianuser'));
    const username = JSON.parse(localStorage.getItem('userdisplayName'));
    var width;
    useEffect(()=>{
        if(document.getElementById('username-wrapper')){
            width = document.getElementById('username-wrapper').offsetWidth;
            setWidth(width)
        }
        /* function handleResize() {
            setDimensions({
              height: window.innerHeight,
              width: window.innerWidth
            })
          
      }
      window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    } */
    });
    function handleAlertDisplayClose(){
        setAlertdisplay(false)
    };
    function handelProfileDisplayClose(){
        setDisplay(false)
    }
    if (window.location.pathname !== "/results" && window.location.pathname !== "/pubMedResults") {
        window.supportingDocsSearchStrategyDescription = undefined;
        window.isSupportingDocs = undefined;
    }
    if(customWidth<600){
        return(
            <div className="navigation-menu-wrapper-mobile">
                   <span className="navigation-menu-inner-wrapper-mobile">
                   <span style={{paddingTop:"1%",width:"95%"}}>
                     <img src={IMSLogo} height="45" alt="" onClick={e=>window.open('/recommended','_self')}/>
                   </span>
                   <span>
                   <span>
                   {!displayMainMenu ?
                      <img src={mainMenuExpMobile} alt="" onClick={e=>setMainMenuDisplay(true)}/>
                    :
                      <img src={mainMenuCollMobile} alt="" onClick={e=>setMainMenuDisplay(false)}/>
                   }
                   </span>
                   </span>
                   </span>
                   <span style={{padding:"0 5px"}}>
                   {displayMainMenu &&
                   <div className="navigation-sub-menu-wrapper-mobile">
                        <div className="navigation-sub-menu-main-header-mobile">
                            <b style={{fontSize:"16px"}}>Menu</b>
                            <img src={mainMenuCollMobile} alt="" onClick={e=>setMainMenuDisplay(false)}/>
                        </div>
                        <div className="navigation-sub-menu-username-mobile">
                            <img src={avatarBlueIcn} width="35" height="30" alt=""/>
                            <b>{username}</b>
                        </div>
                       <ul>
                       <li>
                            <Link to="/recommended" >Home</Link>   
                        </li>
                        <li>
                        {isRussian ?
                        <Link  to="/register-role">View/Edit Preferences</Link>
                        :<Link  to="/registration" >View/Edit Preferences</Link>}
                        </li>
                        <li>
                            <Link to="/advancedsearch">Fielded Search</Link>
                        </li>
                        <li>
                            <Link to="/ManageAlerts" target="_blank"><span>Alerts</span></Link>
                        </li>
                        <li>
                            <Link to="/Get-Help" target="_blank">Access Training Materials</Link>
                        </li>
                        <li>
                            <Link  to="/Contact-Us" target="_blank">Contact Us</Link>
                        </li>
                        <li onClick={(e)=>{setBookmark(true);setDisplay(false)}}>Bookmark</li>
                        <li onClick={(e)=>{setReadingList(true);setDisplay(false)}}>Reading List</li>
                        <li onClick={(e)=>{setDisclaimer(true);setDisplay(false)}}>Disclaimer</li>
                        <li onClick={(e)=>{setPrivacy(true);setDisplay(false)}}>View Privacy Policy</li>
                       </ul>
                   </div>
                    }
                    {showBookmark &&
                    <BookmarkPopUp showModal={showBookmark} handleClose={(status)=>setBookmark(status)} isfromUserProfile={true}/>
                    }
                    {showReadingList &&
                    <ReadingListPopup showModal={showReadingList} handleClose={(status)=>setReadingList(status)} width={customWidth} selectedResults={[]}/>
                    }
                    {showDisclaimer &&
                    <DisclaimerPop display={showDisclaimer} handleClose={(status)=>{setDisclaimer(status)}}/>
                    }
                    {showPrivacy &&
                    <PrivacyStatementPopUp display ={showPrivacy} handleClose={(status)=>{setPrivacy(status)}}/>
                    }
                   </span>
            </div>
        )
    }
    else if(customWidth>=600 && customWidth<1000){
        return(
            <div className="navigation-menu-wrapper-smaller-resolution">
                <span>
                <span style={{padding:"0 10px"}}>
                {!displayMainMenu ?
                <img src={filterExpandIcnTab} alt="" onClick={e=>setMainMenuDisplay(true)} width="15" height="15"/>
                :
                <img src={crossIcon} alt="" onClick={e=>setMainMenuDisplay(false)} width="12" height="12"/>
                }
                </span>
                <>
                    {displayMainMenu &&
                    <div className="navigation-main-sub-menu-wrapper">
                        <ul style={{padding:"0.5% 0.5%"}}>
                        <li style={{display:"flex",alignItems:"center",borderBottom:"1px solid #D9D9D9"}}>
                            <img src={avatarBlueIcn} alt="" width="40" height="45"/><span><b>{username}</b></span>
                        </li>
                        <li>
                            <Link to="/recommended" >Home</Link>   
                        </li>
                        <li>
                        {isRussian ?
                        <Link  to="/register-role">View/Edit Preferences</Link>
                        :<Link  to="/registration" >View/Edit Preferences</Link>}
                        </li>
                        <li className="nav-alerts-menu-wrapper-tablet">
                            <span>
                                <Link to="/ManageAlerts" target="_blank"><span>Alerts</span></Link>
                            </span>
                            <span onClick={e=>setAlertdisplay(!alertDisplay)}>
                            {!alertDisplay ?
                                <img src={rightSideArrow} alt="rightSideArrow" style={{transform:"rotate(90deg)"}} width="12" height="12"/>
                            :
                                <img src={rightSideArrow} alt="rightSideArrow" style={{transform:"rotate(-90deg)"}} width="12" height="12"/>
                            }
                            </span>
                        </li>
                        {alertDisplay &&
                        <ol className="navigation-alerts-sub-menu-wrapper-tablet">
                            <li onClick={(e)=>{setAlertdisplay(false); window.open("/ManageAlerts")}}>Manage alerts</li>
                            <li onClick={(e)=>{window.open("https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMS_Setting%20Up%20Personal%20Alerts.pptx?d=w100e67141726406084ff788dad6d3208&csf=1&e=ShCCB4","_blank");setAlertdisplay(false);}}>Instructions for Setting Up Alerts</li>
                        </ol>
                        }
                        <li>
                            <Link to="/Get-Help" target="_blank">Access Training Materials</Link>
                        </li>
                        <li>
                            <Link  to="/Contact-Us" target="_blank">Contact Us</Link>
                        </li>
                        </ul>
                    </div>
                    }
                </>
                </span>
                <span onMouseLeave={handelProfileDisplayClose}>
                <span style={{padding:"0 5px"}}>
                    <img src={avatarWhiteIcn} alt="" width="35" height="30" onClick={e=>setDisplay(!display)}/>
                </span>
                {display &&
                    <div className="navigation-sub-menu-wrapper-tablet">
                    <div style={{textAlign:"center",padding:"1% 0",boxShadow:"0px 3px 6px #00000029",background:"#fff 0% 0%  no-repeat padding-box",height:"36px"}}>
                        <b>{username}</b>
                        <div style={{position:"absolute",width:"100%"}}><img src={avatarBlueIcn} alt="" width="35" height="30"/></div>
                    </div>
                    <ul>
                        <li onClick={(e)=>{
                            if(isRussian){
                                window.location.href=`${window.location.origin}/register-role`;
                            }
                            else{
                                window.location.href=`${window.location.origin}/registration`;
                            }
                            setDisplay(false)}}>View/Edit Preferences</li>
                        <li onClick={(e)=>{setDisplay(false);window.open("/ManageAlerts")}}>Alerts</li>
                        <li onClick={(e)=>{setHistory(true);setDisplay(false)}}>History</li>
                        {/* <li onClick={(e)=>{setRecentlyViewed(true);setDisplay(false)}}>Recently Viewed</li> */}
                        <li onClick={(e)=>{setBookmark(true);setDisplay(false)}}>Bookmark</li>
                        <li onClick={(e)=>{setReadingList(true);setDisplay(false)}}>Reading List</li>
                        <li onClick={(e)=>{setDisclaimer(true);setDisplay(false)}}>Disclaimer</li>
                        <li onClick={(e)=>{setPrivacy(true);setDisplay(false)}}>View Privacy Policy</li>
                    </ul>
                    </div>
                }
                </span>
                {showDisclaimer &&
                <DisclaimerPop display={showDisclaimer} handleClose={(status)=>{setDisclaimer(status)}}/>
                }
                {showPrivacy &&
                <PrivacyStatementPopUp display ={showPrivacy} handleClose={(status)=>{setPrivacy(status)}}/>
                }
                {showHistory &&
                <HistoryPopUp display={showHistory} handleClose={(status)=>{setHistory(status)}}/>
                }
                {showBookmark && 
                <BookmarkPopUp showModal={showBookmark} handleClose={(status)=>{setBookmark(status)}} isfromUserProfile={true}/>
                }
                {showReadingList && 
                <ReadingListPopup showModal={showReadingList} handleClose={(status)=>{setReadingList(status)}} width={customWidth} selectedResults={[]}/* selectedResults={selectedResults} *//>
                }
            </div>
        )
    }
    else {
        return(
            <div className="navigation-menu-wrapper">
                <ul className="navigation-main-menu-wrapper">
                    <li>
                       <Link to="/recommended" >Home</Link>
                       {pathName === '/' || pathName === '/recommended' ?
                            <div className="navigation-active-indicator"></div>
                            :
                            <div className="navigation-inactive-indicator"></div>
                        }
                    </li>
                    <div className="navigation-divider"></div>
                    <li>
                        {isRussian ?
                        <Link  to="/register-role">View/Edit Preferences</Link>
                        :<Link  to="/registration" >View/Edit Preferences</Link>}
                        {pathName === '/registration' || pathName === '/register-role' ?
                            <div className="navigation-active-indicator"></div>
                            :
                            <div className="navigation-inactive-indicator"></div>
                        }
                    </li>
                    <div className="navigation-divider"></div>
                    <li onMouseLeave={handleAlertDisplayClose}>
                        <div className="navigation-menu-alerts-sub-wrapper">
                            <div className="navigation-menu-alerts-sub-inner-wrapper">
                            <Link to="/ManageAlerts" target="_blank"><span>Alerts</span></Link>
                            <span className="navigation-username-image-wrapper" onClick={(e)=>setAlertdisplay(!alertDisplay)}>
                            {alertDisplay ?
                                <img src={dropdownArrow} alt="" style={{transform:"rotate(180deg)"}} />
                            :
                                <img src={dropdownArrow} alt=""/>
                            }
                            </span>
                            </div>
                        </div>
                        {alertDisplay &&
                        <ul className="navigation-alerts-sub-menu-wrapper">
                            <li onClick={(e)=>{setAlertdisplay(false);window.open("/ManageAlerts")}}>Manage alerts</li>
                            <li onClick={(e)=>{window.open("https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMS_Setting%20Up%20Personal%20Alerts.pptx?d=w100e67141726406084ff788dad6d3208&csf=1&e=ShCCB4","_blank");setAlertdisplay(false);}}>Instructions for Setting Up Alerts</li>
                        </ul>
                        }
                        {pathName === '/ManageAlerts' ?
                            <div className="navigation-active-indicator"></div>
                            :
                            <div className="navigation-inactive-indicator"></div>
                        }
                    </li>
                    <div className="navigation-divider"></div>
                    <li> 
                        <Link to="/Get-Help" target="_blank">Access Training Materials</Link>
                        {pathName === '/Get-Help' ?
                            <div className="navigation-active-indicator"></div>
                            :
                            <div className="navigation-inactive-indicator"></div>
                        }
                    </li>
                    <div className="navigation-divider"></div>
                    <li>
                        <Link  to="/Contact-Us" target="_blank">Contact Us</Link>
                        {pathName === '/Contact-Us' ?
                            <div className="navigation-active-indicator"></div>
                            :
                            <div className="navigation-inactive-indicator"></div>
                        }
                    </li>
                    <div className="navigation-divider"></div>
                    <li onMouseLeave={handelProfileDisplayClose}>
                        <div className="navigation-username-wrapper" id="username-wrapper">
                            <div className="navigation-username-inner-wrapper" onClick={e=>setDisplay(!display)}>
                                <span>{username}</span>
                                <span className="navigation-username-image-wrapper">
                                {display ?
                                    <img src={dropdownArrow} alt="" style={{transform:"rotate(180deg)"}}/>
                                :
                                    <img src={dropdownArrow} alt=""/>
                                }
                                </span>
                            </div>
                        </div>
                        {display &&
                        <ul className="navigation-sub-menu-wrapper" style={{width:compWidth}}>
                            <li onClick={(e)=>{
                                if(isRussian){
                                    window.location.href=`${window.location.origin}/register-role`;
                                }
                                else{
                                    window.location.href=`${window.location.origin}/registration`;
                                }
                                setDisplay(false)}}>View/Edit Preferences</li>
                            <li onClick={(e)=>{setDisplay(false);window.open("/ManageAlerts")}}>Alerts</li>
                            <li onClick={(e)=>{setHistory(true);setDisplay(false)}}>History</li>
                            {/* <li onClick={(e)=>{setRecentlyViewed(true);setDisplay(false)}}>Recently Viewed</li> */}
                            <li onClick={(e)=>{setBookmark(true);setDisplay(false)}}>Bookmark</li>
                            <li onClick={(e)=>{setReadingList(true);setDisplay(false)}}>Reading List</li>
                            <li onClick={(e)=>{setDisclaimer(true);setDisplay(false)}}>Disclaimer</li>
                            <li onClick={(e)=>{setPrivacy(true);setDisplay(false)}}>View Privacy Policy</li>
                        </ul>
                        }
                        <div className="navigation-inactive-indicator"></div>
                    </li>
                </ul>
                {showDisclaimer &&
                <DisclaimerPop display={showDisclaimer} handleClose={(status)=>{setDisclaimer(status)}}/>
                }
                {showPrivacy &&
                <PrivacyStatementPopUp display ={showPrivacy} handleClose={(status)=>{setPrivacy(status)}}/>
                }
                {showHistory &&
                <HistoryPopUp display={showHistory} handleClose={(status)=>{setHistory(status)}}/>
                }
                {/* showRecentlyViewed &&
                <RecentlyPopUp display={showRecentlyViewed} handleClose={(status)=>{setRecentlyViewed(status)}}/>
                */}
                {showBookmark &&
                <BookmarkPopUp showModal={showBookmark} handleClose={(status)=>setBookmark(status)} isfromUserProfile={true}/>
                }
                {showReadingList &&
                <ReadingListPopup showModal={showReadingList} handleClose={(status)=>setReadingList(status)} width={customWidth} selectedResults={[]}/>
                }
            </div>
        )
    }
}