import axios from "axios";
import { Component } from "react";
import { v4 as uuidv4 } from 'uuid';
import { LocationOrigin } from "./utilityServices";
import Cookies from "universal-cookie";
const _cookies = new Cookies();
export default class Services extends Component {
    getService = async (data, dbquery) => {
        let res;
        await axios.get(
            `${LocationOrigin}${process.env.REACT_APP_IMS_API_URL}?dbquery=${dbquery}`,
            {
                headers:
                {
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => {
            res = response.data.rows
            //this.logService(`API call successful for dbquery:${dbquery}`,'info')
        }).catch((error) => {
            //console.log(error)
            this.logService(`API call failed for dbquery:${dbquery}`, 'error')
        })
        return res
    }
    getAllFilters = async (reqBody) => {
        let url = LocationOrigin + process.env.REACT_APP_IMS_QP_API + 'getAllFilters'
        let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'));
        reqBody.accessGroup = (userAccessGroup.includes("MISEHCP")) ? "MISE" : "";
        let res
        var config = {
            method: 'POST',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: reqBody
        };
        await axios(config)
            .then((response) => {
                res = response
            }).catch((error) => {
                //console.log('Error in get all Filters')
                this.logService(`API call error for getAllFilters: ${error}`, 'error')
            })
        return res;
    }
    getJJEDSservice = async (userid) => {
        let res
        await axios.get(
            LocationOrigin + process.env.REACT_APP_IMS_API_PREFIX + `jjeds/${userid}`,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    'x-api-key': `${process.env.REACT_APP_IMS_API_KEY}`
                }
            }
        ).then((response) => {
            //console.log(res.data)
            res = response.data
        }).catch((error) => {
            //console.log(error)
            this.logService(`API call error for jjeds: ${error}`, 'error')
        })
        return res;
    }
    getSuggestions = async (param) => {
        let res;
        await axios.get(`${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}qp/getSuggestions?query=${param}`
        ).then((response) => {
            res = response
        }).catch((error) => {
            //console.log(error)
            this.logService(`API call error for suggestions: ${error}`, 'error')
        })
        return res;
    }
    getLimiDropdown = async (param) => {
        let res;
        await axios.get(`${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}fs/${param}`
        ).then((response) => {
            res = response.data
        }).catch((error) => {
            this.logService(`API call error for ${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}fs/${param}: ${error}`, 'error')
        })
        return res;
    }
    postService = async (url, data) => {
        let res
        await axios.post(url,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            }
        ).then((response) => {
            res = response
        }).catch((error) => {
            //console.log(error);
            this.logService(`POST API call error for url: ${url}`, 'error')
        })
        return res
    }
    postServiceForDb = async (query) => {
        let res
        await axios.post(LocationOrigin + process.env.REACT_APP_IMS_API_URL,
            {
                dbquery: query
            }
        ).then((response) => {
            res = response
        }).catch((error) => {
            //console.log(error);
            this.logService(`DB API failed for : ${query}`, 'error')
        })
        return res
    }
    putService = async (query) => {
        let res
        await axios.put(LocationOrigin + process.env.REACT_APP_IMS_API_URL,
            {
                dbquery: query
            }
        ).then((response) => {
            res = response
        }).catch((error) => {
            //console.log(error)
            this.logService(`DB UPDATE API failed for : ${query}`, 'error')
        })
        return res
    }
    getSynonyms = async (param) => {
        let res = [];
        await axios.get(`${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}fs/getsynonym?keyword=${param}`
        ).then((response) => {
            res = response.data;
        }).catch((error) => {
            this.logService(`API call error for ${LocationOrigin} ${process.env.REACT_APP_IMS_API_PREFIX}fs/getsynonym?keyword=${param}: ${error}`, 'error')
        })
        return res;
    }
    logServiceForLoginIssus = (data) => {
        try {
            let url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}logger/logapi`
            let id = uuidv4();
            let sid = _cookies.get("sid");
            let isSidExpired = _cookies.get("isSidExpired");
            let logLocalStorage = { ...localStorage }
            logLocalStorage.displayedResults = "";
            logLocalStorage.AccessToken = "";
            logLocalStorage.RefreshToken = "";
            logLocalStorage.cookieSid = sid;
            logLocalStorage.cookieIsSidExpired = isSidExpired;
            logLocalStorage.data = data
            let req = {
                'id': `UI-id-${id}`,
                'service': 'User Interface',
                'message': data,// JSON.stringify(logLocalStorage),
                'log': 'info'
            }
            axios.post(url,
                {
                    data: req
                }
            ).then((response) => {
                //console.log(response);
            }).catch((error) => {
                console.log(error);
            })
        } catch (error) {
            console.log(error)
        }
    }
    logService = (data, logType) => {
        let url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}logger/logapi`
        let id = uuidv4();
        let req = {
            'id': `UI-id-${id}`,
            'service': 'User Interface',
            'message': data,
            'log': logType
        }
        axios.post(url,
            {
                data: req
            }
        ).then((response) => {
            //console.log(response);
        }).catch((error) => {
            console.log(error);
        })
    }
}
