import { Component } from "react";
import { Link } from "react-router-dom";
import { rightSideArrow, popCancelBttn, csIcon, iIcon } from "../../assets";
import config from "../../config/config";
import trackingService from "../../services/trackingService";
import "./ContentShortcuts.css";

class TAandDiseaseDashboardsTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCSModal: false,
      arrayOfTAnDDashboards: [],
      showInfo: false,
    };
  }
  componentDidMount = () => {
    this.setState({
      arrayOfTAnDDashboards: config.tandDashboards,
    });
  };
  componentDidUpdate = () => {
    if (this.props.showTAnDCSModal !== this.state.showCSModal) {
      this.setState({ showCSModal: this.props.showTAnDCSModal });
    }
  };
  generateListOfCNSDisease = (dashboardArray) => {
    if (dashboardArray.length > 0) {
      return dashboardArray
        .filter((data) => data.area === "CNS")
        .map((items, index) => {
          return (
            <li key={index} className="cs-modalListing">
              <Link
                to={{ pathname: items.link }}
                target="_blank"
                className="cs-modalLink"
              >
                {items.title}
              </Link>
            </li>
          );
        });
    }
  };
  generateListOfCVMDisease = (dashboardArray) => {
    if (dashboardArray.length > 0) {
      return dashboardArray
        .filter((data) => data.area === "CVM")
        .map((items, index) => {
          return (
            <li key={index} className="cs-modalListing">
              <Link
                to={{ pathname: items.link }}
                target="_blank"
                className="cs-modalLink"
              >
                {items.title}
              </Link>
            </li>
          );
        });
    }
  };
  generateListOfInfectiousDisease = (dashboardArray) => {
    if (dashboardArray.length > 0) {
      return dashboardArray
        .filter((data) => data.area === "Infectious Disease")
        .map((items, index) => {
          return (
            <li key={index} className="cs-modalListing">
              <Link
                to={{ pathname: items.link }}
                target="_blank"
                className="cs-modalLink"
              >
                {items.title}
              </Link>
            </li>
          );
        });
    }
  };
  generateListOfImmunologyDisease = (dashboardArray) => {
    if (dashboardArray.length > 0) {
      return dashboardArray
        .filter((data) => data.area === "Immunology")
        .map((items, index) => {
          return (
            <li key={index} className="cs-modalListing">
              <Link
                to={{ pathname: items.link }}
                target="_blank"
                className="cs-modalLink"
              >
                {items.title}
              </Link>
            </li>
          );
        });
    }
  };
  generateListOfOncologyDisease = (dashboardArray) => {
    if (dashboardArray.length > 0) {
      return dashboardArray
        .filter((data) => data.area === "Oncology")
        .map((items, index) => {
          return (
            <li key={index} className="cs-modalListing">
              <Link
                to={{ pathname: items.link }}
                target="_blank"
                className="cs-modalLink"
              >
                {items.title}
              </Link>
            </li>
          );
        });
    }
  };
  generateListOfTADashboards = (dashboardArray) => {
    if (dashboardArray.length > 0) {
      return dashboardArray
        .filter((data) => data.area === "TA Dashboards")
        .map((items, index) => {
          return (
            <li key={index} className="cs-modalListing">
              <Link
                to={{ pathname: items.link }}
                target="_blank"
                className="cs-modalLink"
              >
                {items.title}
              </Link>
            </li>
          );
        });
    }
  };
  onMouseEnterInfo = () => {
    this.setState({
      showInfo: true,
    });
  };
  onMouseLeaveInfo = () => {
    this.setState({
      showInfo: false,
    });
  };
  showDetails = () => {
    const ts = new trackingService();
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      localStorage.getItem("companyname"),
      "Expanded TA and Disease Dashboards tile under content shortcuts",
      "",
      window.location.href
      //   this.state.searchKeyword,
      //   this.props.appliedFilters
    );
    this.setState({ showCSModal: !this.state.showCSModal }, () => {
      this.props.handelTAnDCardDetail(this.state.showCSModal);
    });
  };
  handleCloseModal = () => {
    this.setState({ showCSModal: false }, () => {
      this.props.handelTAnDCardDetail(this.state.showCSModal);
    });
  };
  render() {
    return (
      <div className="contentShortcutInnerWapper">
        <div
          className="cardContainer"
          onClick={this.showDetails}
          style={{
            borderTop: this.state.showCSModal ? "5px solid #0074B0" : "none",
          }}
        >
          <div
            className="cardInnerWapper"
            style={this.state.showCSModal ? { borderBottom: "none" } : {}}
          >
            <div
              className="card-leftPart cs-right-r2-tile-"
              style={
                this.props.customWidth >= 1000
                  ? { textAlign: "center", position: "absolute", width: "31%" }
                  : {}
              }
            >
              <small><span className="cs-name-bold">TA and Disease Dashboards</span></small>
              <br />
              <img
                src={csIcon}
                alt="TA-Disease"
                height={24}
                width={175}
                className="cs-signalImg"
              />
            </div>
            <div
              className="card-iconsPart"
              style={
                this.props.customWidth >= 1000 ? { marginLeft: "auto" } : {}
              }
            >
              <img
                src={iIcon}
                alt="i-icon"
                width={18}
                height={18}
                onMouseEnter={this.onMouseEnterInfo}
                onMouseLeave={this.onMouseLeaveInfo}
                className="cs-tile-i-icon"
              />
              <div className="card-rightPart">
                <img
                  src={rightSideArrow}
                  alt="down-arrow"
                  className="cs-downArrow"
                  style={{
                    transform: this.state.showCSModal ? "rotate(270deg)" : null,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.showInfo && (
          <div className="cs-tile-wrapper">
            <div className="cs-tooltip-uparrow"></div>
            <span className="tooltiptext cs-tooltip-bottom">
              This tile provides direct links to the TA and Disease dashboards
              on the Signal Competitive Intelligence Platform. PLEASE NOTE: All
              therapeutic Areas are represented. These are not based upon your
              Product Preferences.
            </span>
          </div>
        )}
        {this.state.showCSModal && (
          <div
            className="cs-modalInner"
            style={
              this.props.customWidth >= 1000
                ? { position: "absolute", width: "97.9%" }
                : {}
            }
          >
            <div>
              <div class="popup-cancel-btn-wrapper">
                <img
                  src={popCancelBttn}
                  alt=""
                  onClick={this.handleCloseModal}
                />
              </div>
              <div>
                <div
                  className="cs-modalHeader"
                  style={{ justifyContent: "center" }}
                >
                  <div style={{ textAlign: "center" }}>
                    <div
                      class="modal-title h4"
                      style={{ lineHeight: "1", width: "95%" }}
                    >
                      <small
                        className="cs-modalTitle"
                        style={{ fontSize: "16px" }}
                      >
                        TA and Disease Dashboards By Global Business
                        Intelligence (GBI) and US BI Teams
                        <br />
                        (All are GBI Teams except where noted)
                      </small>
                    </div>
                  </div>
                </div>
                <div class="modal-body">
                  <div className="tandBodyContainer">
                    <div className="ddPart">
                      <span>Disease Dashboards</span>
                      <br />
                      <br />
                      <div className="ddContainer">
                        <div className="ddLeftPart">
                          <span>CNS</span>
                          <div className="cs-modalList">
                            <ul style={{ listStyle: "none" }}>
                              {this.generateListOfCNSDisease(
                                this.state.arrayOfTAnDDashboards
                              )}
                            </ul>
                          </div>
                          <br />
                          <span>CVM</span>
                          <div className="cs-modalList">
                            <ul style={{ listStyle: "none" }}>
                              {this.generateListOfCVMDisease(
                                this.state.arrayOfTAnDDashboards
                              )}
                            </ul>
                          </div>
                          <br />
                          <span>Infectious Disease</span>
                          <div className="cs-modalList">
                            <ul style={{ listStyle: "none" }}>
                              {this.generateListOfInfectiousDisease(
                                this.state.arrayOfTAnDDashboards
                              )}
                            </ul>
                          </div>
                          <br />
                        </div>
                        <div className="ddRightPart">
                          <span>Immunology</span>
                          <div className="cs-modalList">
                            <ul style={{ listStyle: "none" }}>
                              {this.generateListOfImmunologyDisease(
                                this.state.arrayOfTAnDDashboards
                              )}
                            </ul>
                          </div>
                          <br />
                          <span>Oncology</span>
                          <div className="cs-modalList">
                            <ul style={{ listStyle: "none" }}>
                              {this.generateListOfOncologyDisease(
                                this.state.arrayOfTAnDDashboards
                              )}
                            </ul>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="tadPart">
                      <span>TA Dashboards</span>
                      <br />
                      <br />
                      <div className="cs-modalList">
                        <ul style={{ listStyle: "none", textAlign: "left" }}>
                          {this.generateListOfTADashboards(
                            this.state.arrayOfTAnDDashboards
                          )}
                        </ul>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default TAandDiseaseDashboardsTile;
