import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { paginationLeftArrowMobile, paginationRightArrowMobile, paginationDotIconwMobile, paginationDisabledLeftArrowMobile, paginationDisabledRightArrowMobile } from "../../assets";

/**
 * Helper function for page numbers array
 * @param from
 * @param to
 * @param step
 * @returns {Array}
 */

const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
}

// Pagination "Regions"
const maxPerRegion = 5

// How many items before and after the currentPage to show in a center region
const neighborhood = 2 // floor(maxPerRegion / 2)

const generatePageNumbers = (totalPages, currentPage) => {
    // All pages fit within one region
    if (totalPages <= maxPerRegion) {
        return {
            left: false,
            right: false,
            pages: range(1, totalPages)
        }
    }

    // The current page is within the first region
    if (currentPage <= maxPerRegion) {
        return {
            left: false,
            right: true,
            pages: range(1, maxPerRegion)
        }
    }

    // The current page is within the last region
    if (currentPage >= (totalPages - maxPerRegion) + 1) {
        return {
            left: true,
            right: false,
            pages: range((totalPages - maxPerRegion) + 1, totalPages)
        }
    }

    // The current page after the first region but before the last region
    return {
        left: true,
        right: true,
        pages: range(currentPage - neighborhood, currentPage + neighborhood)
    }
}

// MOBILE VIEW PAGINATION LOGIC
// Pagination "Regions"
const maxPerRegionMobile = 3

// How many items before and after the currentPage to show in a center region
const neighborhoodMobile = 1 // floor(maxPerRegion / 2)

const generatePageNumbersMobile = (totalPages, currentPage) => {
    // All pages fit within one region
    if (totalPages <= maxPerRegionMobile) {
        return {
            left: false,
            right: false,
            pages: range(1, totalPages)
        }
    }

    // The current page is within the first region
    if (currentPage <= maxPerRegionMobile) {
        return {
            left: false,
            right: true,
            pages: range(1, maxPerRegionMobile)
        }
    }

    // The current page is within the last region
    if (currentPage >= (totalPages - maxPerRegionMobile) + 1) {
        return {
            left: true,
            right: false,
            pages: range((totalPages - maxPerRegionMobile) + 1, totalPages)
        }
    }

    // The current page after the first region but before the last region
    return {
        left: true,
        right: true,
        pages: range(currentPage - neighborhoodMobile, currentPage + neighborhoodMobile)
        // pages: range(currentPage - neighborhoodMobile, currentPage + 2)
    }
}

/**
 * Display pagination and header info
 */
const renderPagination = (totalPages, currentPage, pageChange, customWidth) => {
    const pagination = generatePageNumbers(totalPages, currentPage)
    const paginationMobile = generatePageNumbersMobile(totalPages, currentPage)
    if (customWidth < 600) {
        return (<div className="pagination-container">
            {
                paginationMobile.pages.includes(1) ?
                    <img src={paginationDisabledLeftArrowMobile} alt="left-arrow" width={17} height={17} className="paginationArrow-mobile" style={{ cursor: "default", marginRight: currentPage === 1 ? "1%" : "0%" }} />
                    :
                    <img src={paginationLeftArrowMobile} alt="left-arrow" width={17} height={17} className="paginationArrow-mobile" onClick={() => pageChange(paginationMobile.pages[0] - 1)} /* onClick={() => pageChange(paginationMobile.pages[0] - 1)} */ /* onClick={_e => pageChange(currentPage - 1)} */ />
                // <button
                //     className={currentPage <= 1 ? "anchor clickable" : "anchor clickable disabled"}
                //     disabled={currentPage <= 1}
                //     onClick={_e => pageChange(currentPage - 1)}>
                //     <FontAwesomeIcon className="arrow left" fixedWidth icon="chevron-left" /><span> Back</span>
                // </button>
            }
            {paginationMobile.left ?
                <>
                    <button
                        className='inactivePage-mobile'
                        // style={{cursor:"default"}}
                        // key={index}
                        // className={`page clickable ${currentPage == page ? 'current-page' : ''}`}
                        onClick={() => pageChange(1)}
                    >
                        {1}
                    </button>
                    <img src={paginationDotIconwMobile} alt="dot-icon" />
                </>
                // <button
                //     className="page ellipses clickable"
                //     onClick={() => pageChange(paginationMobile.pages[0] - 1)}>
                //     &#8230;
                // </button>
                : ""}
            {paginationMobile.pages.map((page, index) => {
                return (
                    <button
                        key={index}
                        className={(currentPage === page) ? "activePage-mobile" : "inactivePage-mobile"}
                        // className={`page clickable ${currentPage == page ? 'current-page' : ''}`}
                        onClick={() => pageChange(page)}>
                        {page}
                    </button>
                );
            })}
            {paginationMobile.right ?
                <>
                    <img src={paginationDotIconwMobile} alt="dot-icon" />
                    <button
                        className='inactivePage-mobile'
                        // style={{cursor:"default"}}
                        // key={index}
                        // className={`page clickable ${currentPage == page ? 'current-page' : ''}`}
                        onClick={() => pageChange(totalPages)}
                    >
                        {totalPages}
                    </button>
                </>
                // <button
                //     className="page ellipses clickable"
                //     onClick={() => pageChange(paginationMobile.pages[paginationMobile.pages.length - 1] + 1)}>
                //     &#8230;
                // </button>
                : ""}



            {
                paginationMobile.pages.includes(totalPages) ?
                    <img src={paginationDisabledRightArrowMobile} alt="right-arrow" width={17} height={17} className="paginationArrow-mobile" style={{ cursor: "default", marginLeft: currentPage === totalPages ? "1%" : "0%" }} />
                    :
                    <img src={paginationRightArrowMobile} alt="right-arrow" width={17} height={17} className="paginationArrow-mobile" onClick={() => pageChange(paginationMobile.pages[paginationMobile.pages.length - 1] + 1)}/* onClick={() => pageChange(paginationMobile.pages[paginationMobile.pages.length - 1] + 1)} *//*  onClick={_e => pageChange(currentPage + 1)} */ />
            }

            {/* <button
                className={currentPage >= totalPages ? "anchor clickable" : "anchor clickable disabled"}
                disabled={currentPage >= totalPages}
                onClick={_e => pageChange(currentPage + 1)}>
                <span>Next</span>
                <FontAwesomeIcon className="arrow right" fixedWidth icon="chevron-right" />
            </button> */}

        </div>)
    }
    else {
        return (<div className="pagination-container">
            {
                <button
                    className={currentPage <= 1 ? "anchor clickable" : "anchor clickable disabled"}
                    disabled={currentPage <= 1}
                    onClick={_e => pageChange(currentPage - 1)}>
                    <FontAwesomeIcon className="arrow left" fixedWidth icon="chevron-left" /><span> Back</span>
                </button>}
            {pagination.left ?
                <button
                    className="page ellipses clickable"
                    onClick={() => pageChange(pagination.pages[0] - 1)}>
                    &#8230;
                </button>
                : ""}
            {pagination.pages.map((page, index) => {
                return (
                    <button
                        key={index}
                        className={`page clickable ${currentPage === page ? 'current-page' : ''}`}
                        onClick={() => pageChange(page)}>
                        {page}
                    </button>
                );
            })}
            {pagination.right ?
                <button
                    className="page ellipses clickable"
                    onClick={() => pageChange(pagination.pages[pagination.pages.length - 1] + 1)}>
                    &#8230;
                </button>
                : ""}

            <button
                className={currentPage >= totalPages ? "anchor clickable" : "anchor clickable disabled"}
                disabled={currentPage >= totalPages}
                onClick={_e => pageChange(parseInt(currentPage) + 1)}>
                <span>Next</span>
                <FontAwesomeIcon className="arrow right" fixedWidth icon="chevron-right" />
            </button>

        </div>)
    }
}

export default function Pagination(props) {
    const {
        metadata,
        itemsPerPage,
        page,
        isProcessing,
        handlePageChange,
        customWidth
    } = props
    const totalPages = Math.ceil(metadata / itemsPerPage)
    if (isProcessing || totalPages < 1) {
        return ""
    }
    return (
        <div className="pagination-region">
            {renderPagination(totalPages, page, handlePageChange, customWidth)}
            {customWidth >= 600 && <div style={{ color: "#777", textAlign: "center", padding: "1%" }}>About {metadata.toLocaleString('en-us')} results</div>}
        </div>
    )
}

Pagination.propTypes = {
    metadata: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.string.isRequired,
    isProcessing: PropTypes.bool,
    handlePageChange: PropTypes.func
}
