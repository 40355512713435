/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/no-redundant-roles */
import { Component } from "react";
import { chatBot } from "../../assets";
import { withRouter } from 'react-router-dom';
import "./ChatBot.css";
import ChatBotMessenger from './ChatBotMessenger'
import { GenAIAnsweringContext } from '../GenAIAnswering2.0/GenAIAnsweringContextProvider';

class ChatBotSection extends Component {
  static contextType = GenAIAnsweringContext
  constructor(props) {
    super(props);
    this.state = {
      isMouseEnterPreviewMessage: false,
      isClosePreviewMessage: false,
      isOpenChatBotMessenger: false,
      customWidth: props.customWidth,
      customHeight: props.customHeight,
    };
  }

  async componentDidMount() {

  }
  componentWillReceiveProps(nextProps) {
    if (this.state.customWidth !== nextProps.customWidth || this.state.customHeight !== nextProps.customHeight) {
      this.setState({
        customWidth: nextProps.customWidth,
        customHeight: nextProps.customHeight
      })
    }
  }
  CloseChatBotMessenger = () => {
    this.setState({ isOpenChatBotMessenger: false })
  }
  OpenChatBotMessenger = () => {
    this.context.updateRedirectFrom(`${window.location.pathname}${window.location.search}`);
    //this.props.history.push('/genAIAnswering');
    this.setState({ isOpenChatBotMessenger: true })
  }

  render() {
    const { isMouseEnterPreviewMessage, isClosePreviewMessage, isOpenChatBotMessenger } = this.state;
    if (this.props.customWidth < 600) {
      return (<></>
      );
    } else if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
      return (
        <>
          <div style={isOpenChatBotMessenger ? { display: "block" } : { display: "none" }} ><ChatBotMessenger customWidth={this.state.customWidth} customHeight={this.state.customHeight} CloseChatBotMessenger={this.CloseChatBotMessenger} /></div>
          <div className="chatBotSectionInnerWrapper" style={isOpenChatBotMessenger ? { display: "none" } : { display: "block" }}>
            {!isClosePreviewMessage && <div className="chatBot-msg-preview-visual-indicator-container vis"
              onMouseEnter={(e) => {
                this.setState({ isMouseEnterPreviewMessage: true });
              }} onMouseLeave={(e) => {
                this.setState({ isMouseEnterPreviewMessage: false });
              }}>
              {(isMouseEnterPreviewMessage) &&
                <div className="chatBot-close-btn-container" onClick={(e) => {
                  this.setState({ isClosePreviewMessage: true });
                }}>
                  <div className="chatBot-close-btn">
                    <span className="chatBot-close-icon-svg">
                      <svg viewBox="0 0 64 64" width="8" xmlns="http://www.w3.org/2000/svg" height="8">
                        <path fill="#fff" d="M28.941 31.786L.613 60.114a2.014 2.014 0 1 0 2.848 2.849l28.541-28.541 28.541 28.541c.394.394.909.59 1.424.59a2.014 2.014 0 0 0 1.424-3.439L35.064 31.786 63.41 3.438A2.014 2.014 0 1 0 60.562.589L32.003 29.15 3.441.59A2.015 2.015 0 0 0 .593 3.439l28.348 28.347z"></path>
                      </svg>
                    </span>
                    <span className="chatBot-close-text">Close</span>
                  </div>
                </div>}

              <div className="chatBot-msg-preview-visual-indicator-text  applozic-launcher">
                <span className="message-preview-text">Hello there, can I help you<br />find a document or answer a question?</span>
              </div>
            </div>}

            <div className="chat-bot-icon-wrapper" onClick={e => { this.OpenChatBotMessenger(e) }}>
              <img src={chatBot} alt="chatBot" className="chat-bot-icon" />
            </div>
          </div>
        </>
      );
    } else if (this.props.customWidth > 1000) {
      return (

        <>
          <div style={isOpenChatBotMessenger ? { display: "block" } : { display: "none" }} ><ChatBotMessenger customWidth={this.state.customWidth}
            customHeight={this.state.customHeight} CloseChatBotMessenger={this.CloseChatBotMessenger} /></div>
          <div className="chatBotSectionInnerWrapper" style={isOpenChatBotMessenger ? { display: "none" } : { display: "block" }}>
            {!isClosePreviewMessage && <div className="chatBot-msg-preview-visual-indicator-container vis"
              onMouseEnter={(e) => {
                this.setState({ isMouseEnterPreviewMessage: true });
              }} onMouseLeave={(e) => {
                this.setState({ isMouseEnterPreviewMessage: false });
              }}>
              {(isMouseEnterPreviewMessage) &&
                <div className="chatBot-close-btn-container" onClick={(e) => {
                  this.setState({ isClosePreviewMessage: true });
                }}>
                  <div className="chatBot-close-btn">
                    <span className="chatBot-close-icon-svg">
                      <svg viewBox="0 0 64 64" width="8" xmlns="http://www.w3.org/2000/svg" height="8">
                        <path fill="#fff" d="M28.941 31.786L.613 60.114a2.014 2.014 0 1 0 2.848 2.849l28.541-28.541 28.541 28.541c.394.394.909.59 1.424.59a2.014 2.014 0 0 0 1.424-3.439L35.064 31.786 63.41 3.438A2.014 2.014 0 1 0 60.562.589L32.003 29.15 3.441.59A2.015 2.015 0 0 0 .593 3.439l28.348 28.347z"></path>
                      </svg>
                    </span>
                    <span className="chatBot-close-text">Close</span>
                  </div>
                </div>}

              <div className="chatBot-msg-preview-visual-indicator-text  applozic-launcher">
                <span className="message-preview-text">Hello there, can I help you<br />find a document or answer a question?</span>
              </div>
            </div>}

            <div className="chat-bot-icon-wrapper" onClick={e => { this.OpenChatBotMessenger(e) }}>
              <img src={chatBot} alt="chatBot" className="chat-bot-icon" />
            </div>
          </div>

        </>
      );
    }
  }
}
export default withRouter(ChatBotSection);
