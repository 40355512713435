/* eslint-disable array-callback-return */
import { Modal } from "react-bootstrap";
import {
  popCancelBttn,
  imsLoader
} from "../../assets";
import { v4 as uuidv4 } from "uuid";
import { Component } from "react";
import Button from "../Button";
import "./GlobalSearchFeaturesPopUp.css";
import "./PinToReadingListPopUp.css";
import SuccessReadingList from "./SucessReadingList";
import ApiServices from "../../utils/apiServices";
import RenameReadingListPopup from "./RenameReadingListPopup";
import { snippetLongString } from "../../utils/utilityServices";
import AlertMessagePopUpModal from "./AlertMessagePopUpModal";
import ConfirmMessagePopUpModal from "./ConfirmMessagePopUpModal"
import moment from 'moment';

class PinToReadingListPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPop: false,
      isPin: true,
      isManage: false,
      apiResponse: [],
      readingListName: "",
      readingListID: "",
      docID: "",
      isLoaded: false,
      showSuccess: false,
      selectedReadingLists: [],
      showRenamePop: false,
      itemtoBeRenamedName: "",
      itemtoBeRenamedID: "",
      successMessage: "",
      error: "",
      nodeName: "",
      alertMessageModelColor: "",
      alertMessageContent: "",
      alertMessageShowModal: false,
      isInsertSuccess: false,
      confirmMessageContent: '',
      confirmMessageShowModal: false,
      confirmMessageId: -1,
    };
  }
  async componentDidMount() {
    let username = JSON.parse(localStorage.getItem("username")).toLowerCase();
    let service = new ApiServices();
    this.setState({
      showPop: this.props.showModal,
      docID: this.props.docID,
    });
    let apiRes = await service.getReadingLists(username);
    this.setState(
      {
        apiResponse: apiRes,
        // isLoaded: true,
      },
      async () => {
        await this.getReadingListsWithDocIds();
      }
    );
  }

  showConfirmMessage = (message, id) => {
    this.setState({
      confirmMessageContent: message,
      confirmMessageShowModal: true,
      confirmMessageId: id
    })
  }

  confirmHandler = async () => {
    const { confirmMessageId } = this.state;
    let username = JSON.parse(localStorage.getItem('username'))
    if (username) {
      username = username.toLowerCase();
    }
    let service = new ApiServices();

    await service.deleteReadingLists(confirmMessageId, username);
    this.setState(
      {
        isLoaded: false,
        confirmMessageContent: "",
        confirmMessageShowModal: false,
        confirmMessageId: -1
      },
      async () => {
        let { selectedReadingLists } = this.state
        let apiRes = await service.getReadingLists(username);
        let readinglistIds = apiRes.map(x => x.readinglist_id);
        selectedReadingLists = selectedReadingLists.filter(x => readinglistIds.includes(x.readinglist_id));
        this.setState({
          apiResponse: apiRes,
          isLoaded: true,
          selectedReadingLists: selectedReadingLists
        });
      }
    );
  }


  closeConfirmMessage = () => {
    this.setState({
      confirmMessageContent: "",
      confirmMessageShowModal: false,
      confirmMessageIndex: -1
    })
  }
  errorAlertMessage = (message) => {
    this.setState({
      alertMessageContent: message,
      alertMessageModelColor: "#de1327",
      alertMessageShowModal: true
    })
  }

  closeAlertMessage = () => {
    this.setState({
      alertMessageContent: "",
      alertMessageModelColor: "",
      alertMessageShowModal: false
    })
  }
  getReadingListsWithDocIds = async () => {
    let { apiResponse } = this.state;
    let username = JSON.parse(localStorage.getItem("username")).toLowerCase();
    let service = new ApiServices();
    let apiRes = await service.getReadingListsWithDocIds(username);
    let doneArr = [];
    if (apiRes) {
      apiRes.map((item) => {
        let _docCount = item.doc_id.split(";").length;
        if (!doneArr.find((x) => x === item.readinglist_id)) {
          _docCount += 1;

          let _selectedReadingList = apiResponse.find(
            (x) => x.readinglist_id === item.readinglist_id
          );

          _selectedReadingList.docCount =
            _selectedReadingList.docCount === undefined
              ? _docCount
              : _selectedReadingList.docCount + _docCount;

          _selectedReadingList.doc_id = item.doc_id;
        } else {
          let _selReadList = apiResponse.find(
            (x) => x.readinglist_id === item.readinglist_id
          );

          _selReadList.doc_id =
            _selReadList.doc_id !== undefined
              ? _selReadList.doc_id + ";" + item.doc_id
              : item.doc_id;

          _selReadList.doc_id = Array.from(
            new Set(_selReadList.doc_id.split(";"))
          );

          _selReadList.docCount = _selReadList.doc_id.length + 1;
        }

        doneArr.push(item.readinglist_id);
      });
      this.setState({
        apiResponse: apiResponse,
        isLoaded: true,
      });
    }
  };
  handleInput = (e) => {
    e.target.value = snippetLongString(e.target.value);
    this.setState({
      readingListName: e.target.value,
    });
  };
  handleCheckBox = (e, itemCounts) => {
    if (itemCounts <= 200 || itemCounts === undefined) {
      let arr = this.state.selectedReadingLists;
      if (e.target.checked) {
        arr.push(e.target.value);
      } else {
        var idx = arr.indexOf(e.target.value);
        if (idx !== -1) {
          arr.splice(idx, 1);
        }
      }
      this.setState({
        selectedReadingLists: arr,
      });
    } else {
      this.errorAlertMessage("A user may only add a maximum of 200 items in a reading list.");
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };
  onclickProceed = async () => {
    let username = JSON.parse(localStorage.getItem("username")).toLowerCase();
    let service = new ApiServices();
    if (this.handleValidation()) {
      if (this.state.readingListName !== "") {
        let listID = uuidv4();
        await service.insertUpdateReadingList(
          this.state.readingListName,
          `RL_${listID}`,
          username,
          this.state.docID
        );
        //console.log("ID:"+`RL_${listID}`+" Name:"+this.state.readingListName+" Doc ID:"+this.state.docID)
        this.setState({
          successMessage:
            "The result/selection was pinned successfully to new Reading List.",
        });
      }
      if (this.state.selectedReadingLists.length > 0) {
        for (const itr of this.state.selectedReadingLists) {
          let rlName = this.state.apiResponse.find(
            (e) => e.readinglist_id === itr
          );
          //console.log(rlName.readinglist_name)
          await service.insertUpdateReadingList(
            rlName.readinglist_name,
            itr,
            username,
            this.state.docID
          );
        }
        this.setState({
          successMessage:
            "The result/selection was pinned successfully to selected existing Reading List(s).",
        });
      }
      if (
        this.state.readingListName !== "" &&
        this.state.selectedReadingLists.length > 0
      ) {
        this.setState({
          successMessage:
            "The result/selection was pinned successfully to selected existing Reading List(s) and the new reading list.",
        });
      }
      this.setState({
        showSuccess: true,
        isInsertSuccess: true
      });
    }
  };
  handleOnClickView = async (rlID, rName) => {
    let ids = "";
    let service = new ApiServices();
    let res = await service.getDocIds(rlID);
    if (res.length > 0) {
      ids = res.map(item => item.doc_id).filter(x => x).join(";");
      // res.map((item) => {
      //   ids += `${item.doc_id};`;
      //   return null;
      // });
    }
    localStorage.setItem("readListDocIds", ids);
    localStorage.setItem("rLID", rlID);
    localStorage.setItem("rName", rName);
    let url = `/viewReadingList?query=&q=All%20Fields&filters=&f1=searchID&fval1=${ids}&fcount=1&rName=${rName}&rID=${rlID}`;
    if (ids !== "") {
      window.open(url, "_blank");
    } else {
      this.errorAlertMessage("You do not have any items pinned to this reading list.");
    }
  };
  handleRename = (id, name) => {
    this.setState({
      showRenamePop: true,
      itemtoBeRenamedName: name,
      itemtoBeRenamedID: id,
    });
  };
  handleRenameClose = (status) => {
    this.setState(
      {
        showRenamePop: status,
        isLoaded: false,
      },
      async () => {
        let username = JSON.parse(
          localStorage.getItem("username")
        ).toLowerCase();
        let service = new ApiServices();
        let apiRes = await service.getReadingLists(username);
        this.setState({
          apiResponse: apiRes,
          isLoaded: true,
        });
      }
    );
  };
  handleRemove = async (id) => {
    this.showConfirmMessage("Are you sure you want to delete this reading list ?", id)
  };
  handleSuccessClose = (status) => {
    this.setState({
      showSuccess: status,
      //showPop: false,
      isManage: true,
      isPin: false,
      isLoaded: false
    },
      async () => {
        let username = JSON.parse(
          localStorage.getItem("username")
        ).toLowerCase();
        let service = new ApiServices();
        let apiRes = await service.getReadingLists(username);
        this.setState(
          {
            apiResponse: apiRes,
          },
          async () => {
            await this.getReadingListsWithDocIds();
          }
        );
      });
    //this.props.handleClose(false);
  };
  handleOnFocusInput = (e) => {
    this.setState({ nodeName: e.target.nodeName });
  };
  handleValidation() {
    let errors = {};
    let formIsValid = true;
    let readingList = this.state.apiResponse.map((data) =>
      data.readinglist_name.toLowerCase()
    );
    if (
      this.state.readingListName.length <= 0 &&
      this.state.selectedReadingLists.length <= 0 &&
      this.state.nodeName !== "INPUT"
    ) {
      formIsValid = false;
      this.errorAlertMessage("Please enter either New Reading List name or select any Existing reading list to proceed.")
      // errors["pinReadingList"] = 'Please enter a valid name to proceed.';
    }
    if (
      this.state.readingListName.length <= 0 &&
      this.state.nodeName === "INPUT" &&
      this.state.selectedReadingLists.length <= 0
    ) {
      formIsValid = false;
      errors["pinReadingList"] = `Please enter a valid name to proceed.`;
    }
    if (readingList.includes(this.state.readingListName.toLowerCase())) {
      formIsValid = false;
      errors[
        "pinReadingList"
      ] = `You already have a reading list with entered title. Please enter a new title.`;
    }
    this.setState({ error: errors });
    return formIsValid;
  }
  render() {
    const { alertMessageModelColor, alertMessageContent, alertMessageShowModal, isInsertSuccess } = this.state;
    const { confirmMessageContent, confirmMessageShowModal } = this.state;
    if (this.props.width <= 600) {
      return (
        <>
          <Modal
            show={this.state.showPop}
            size="lg md sm xs"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="popup-transition"
            scrollable={true}
          >
            <div style={{ borderTop: `5px solid #1DA1E5` }}>
              {/* <div className="popup-cancel-btn-wrapper">
                <img
                  src={popCancelBttn}
                  alt=""
                  onClick={e => {
                    this.setState({ showPop: false });
                    this.props.handleClose(false);
                  }}
                />
              </div> */}
              <div>
                <Modal.Header
                  className="gsfModalHeader"
                  style={{ justifyContent: "left", padding: "0.5rem 0" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      paddingTop: "1%",
                    }}
                  >
                    <Modal.Title>
                      <div className="actionItem-headerPart">
                        {!isInsertSuccess && (
                          <div
                            onClick={(e) => {
                              this.setState({ isPin: true, isManage: false });
                            }}
                            className={
                              this.state.isPin
                                ? "active-pin-features-button"
                                : "pin-features-buttons"
                            }
                            style={{ margin: "1% 0" }}
                          >
                            <span>Pin to Reading List</span>
                          </div>
                        )}
                        {/* <div className="action-button-saperation-line"></div> */}
                        <div
                          onClick={(e) => {
                            this.setState({ isPin: false, isManage: true });
                          }}
                          className={
                            this.state.isManage
                              ? "active-pin-features-button"
                              : "pin-features-buttons"
                          }
                          style={{ margin: "1% 0" }}
                        >
                          <span>View/Manage Reading Lists</span>
                        </div>
                      </div>
                    </Modal.Title>
                  </div>
                </Modal.Header>
                <Modal.Body scrollable={true} style={{ height: "45vh" }}>
                  {this.state.isPin && (
                    <div>
                      {this.state.isLoaded ? (
                        <>
                          <span style={{ color: "black" }}>
                            My Reading Lists
                          </span>{" "}
                          :
                          {this.state.apiResponse.length > 0 ? (
                            <span>
                              <ul className="reading-list-wrapper">
                                {this.state.apiResponse.map((item) => {
                                  return (
                                    <li key={item.readinglist_id}>
                                      <span>
                                        <input
                                          // disabled={item.docCount > 200}
                                          title={
                                            item.docCount > 200
                                              ? "A user may only add a maximum of 200 items in a reading list."
                                              : ""
                                          }
                                          type="checkbox"
                                          value={item.readinglist_id}
                                          className="reading-list-checkbox-wrapper"
                                          onChange={e => this.handleCheckBox(e, item.docCount)}
                                        />
                                      </span>
                                      {item.readinglist_name}
                                    </li>
                                  );
                                })}
                              </ul>
                            </span>
                          ) : (
                            <span>
                              You currently do not have any reading lists created.
                              <br />
                            </span>
                          )}
                          <br />
                          <div>
                            Create A New Reading List By Entering A Title Below:
                          </div>
                          <br />
                          <input
                            type="text"
                            placeholder="Type here"
                            value={this.state.readingListName}
                            className="reading-list-input-box"
                            onChange={this.handleInput}
                            onFocus={this.handleOnFocusInput}
                          />
                          <br />
                          <span className="length-info">Name with a maximum of 100 characters.</span>
                          <div className="errorInvalidName">
                            {this.state.error["pinReadingList"]}
                          </div>
                          <br />
                        </>
                      ) : (
                        <div style={{ textAlign: "center", padding: "10%" }}>
                          <img
                            src={imsLoader}
                            alt="Loading Data"
                            style={{ width: "10%" }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {this.state.isManage && (
                    <div>
                      {this.state.isLoaded ? (
                        <>
                          {this.state.apiResponse.length > 0 ? (
                            <span>
                              <table className="reading-list-view-wrapper">
                                <tr>
                                  <th style={{ color: "#333" }}>
                                    Reading List Name
                                  </th>
                                  <th
                                    style={{ textAlign: "center", color: "#333" }}
                                  >
                                    Actions
                                  </th>
                                </tr>
                                <tbody>
                                  {this.state.apiResponse.map((item) => {
                                    return (
                                      <tr key={item.readinglist_id}>
                                        <td>{item.readinglist_name}</td>
                                        <td
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            width: "100%",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <span
                                            onClick={(e) =>
                                              this.handleOnClickView(
                                                item.readinglist_id,
                                                item.readinglist_name
                                              )
                                            }
                                            title="View"
                                            className="reading-list-action-text"
                                          >
                                            View
                                          </span>
                                          <span
                                            onClick={(e) =>
                                              this.handleRename(
                                                item.readinglist_id,
                                                item.readinglist_name
                                              )
                                            }
                                            title="Edit"
                                            className="reading-list-action-text"
                                          >
                                            Edit
                                          </span>
                                          <span
                                            title="Remove"
                                            className="reading-list-action-text"
                                            onClick={(e) =>
                                              this.handleRemove(
                                                item.readinglist_id
                                              )
                                            }
                                          >
                                            Remove
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </span>
                          ) : (
                            <span>
                              {" "}
                              You currently do not have any reading lists created.
                            </span>
                          )}
                        </>
                      ) : (
                        <div style={{ textAlign: "center", padding: "10%" }}>
                          <img
                            src={imsLoader}
                            alt="Loading Data"
                            style={{ width: "10%" }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Modal.Body>
                {this.state.isPin ? (
                  <div className="pin-popup-button-wrapper">
                    {/* <div style={{margin:"1% 0"}}> */}
                    <button
                      className="pin-pop-up-button-cancel"
                      onClick={(e) => {
                        this.setState({ showPop: false });
                        this.props.handleClose(false);
                      }}
                    >
                      Cancel
                    </button>
                    {/* <Button text="Cancel" customColor="#0074B0" action={() => { this.setState({ showPop: false });this.props.handleClose(false); }} /> */}
                    {/* </div>
                    <div style={{margin:"1% 0"}}> */}
                    <button
                      className="pin-pop-up-button-proceed"
                      onClick={(e) => {
                        this.onclickProceed();
                      }}
                    >
                      Pin
                    </button>
                    {/* <Button text="Pin" customColor="#2EB000" action={this.onclickProceed} /> */}
                    {/* </div> */}
                  </div>
                ) : (
                  <div className="pin-popup-button-wrapper">
                    <Button
                      text="Cancel"
                      customColor="#0074B0"
                      action={() => {
                        this.setState({ showPop: false });
                        this.props.handleClose(false);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            {this.state.showSuccess && (
              <>
                <SuccessReadingList
                  display={this.state.showSuccess}
                  handleClose={(status) => this.handleSuccessClose(status)}
                  data={this.state.successMessage}
                  width={this.props.width}
                />
                <div className="savemodal-outer-wrapper"></div>
              </>
            )}
            {this.state.showRenamePop && (
              <>
                <RenameReadingListPopup
                  showModal={this.state.showRenamePop}
                  handleClose={(status) => this.handleRenameClose(status)}
                  rlName={this.state.itemtoBeRenamedName}
                  rlID={this.state.itemtoBeRenamedID}
                  width={this.props.width}
                  apiResponse={this.state.apiResponse}
                />
                <div className="savemodal-outer-wrapper"></div>
              </>
            )}
          </Modal>
          {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
          {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
        </>
      );
    } else if (this.props.width > 600 && this.props.width <= 1000) {
      return (
        <>
          <Modal
            show={this.state.showPop}
            size="lg md sm xs"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="popup-transition"
            scrollable={true}
          >
            <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
              {/* <div className="popup-cancel-btn-wrapper">
                <img
                  src={popCancelBttn}
                  alt=""
                  onClick={e => {
                    this.setState({ showPop: false });
                    this.props.handleClose(false);
                  }}
                />
              </div> */}
              <div>
                <Modal.Header
                  className="gsfModalHeader"
                  style={{ justifyContent: "left", padding: "0.5rem 0" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      paddingTop: "1%",
                    }}
                  >
                    <Modal.Title>
                      <div className="actionItem-headerPart">
                        {!isInsertSuccess && (<>
                          <div
                            onClick={(e) => {
                              this.setState({ isPin: true, isManage: false });
                            }}
                            className={
                              this.state.isPin
                                ? "active-pin-features-button"
                                : "pin-features-buttons"
                            }
                            style={{ margin: "1% 0" }}
                          >
                            <span>Pin to Reading List</span>
                          </div>
                          <div className="action-button-saperation-line"></div>
                        </>
                        )}
                        <div
                          onClick={(e) => {
                            this.setState({ isPin: false, isManage: true });
                          }}
                          className={
                            this.state.isManage
                              ? "active-pin-features-button"
                              : "pin-features-buttons"
                          }
                          style={{ margin: "1% 0" }}
                        >
                          <span>View/Manage Reading Lists</span>
                        </div>
                      </div>
                    </Modal.Title>
                  </div>
                </Modal.Header>
                <Modal.Body scrollable={true} style={{ height: "45vh" }}>
                  {this.state.isPin && (
                    <div>
                      {this.state.isLoaded ? (
                        <>
                          <span style={{ color: "black" }}>
                            My Reading Lists
                          </span>{" "}
                          :
                          {this.state.apiResponse.length > 0 ? (
                            <span>
                              <ul className="reading-list-wrapper">
                                {this.state.apiResponse.map((item) => {
                                  return (
                                    <li key={item.readinglist_id}>
                                      <span>
                                        <input
                                          // disabled={item.docCount > 200}
                                          title={
                                            item.docCount > 200
                                              ? "A user may only add a maximum of 200 items in a reading list."
                                              : ""
                                          }
                                          type="checkbox"
                                          value={item.readinglist_id}
                                          className="reading-list-checkbox-wrapper"
                                          onChange={e => this.handleCheckBox(e, item.docCount)}
                                        />
                                      </span>
                                      {item.readinglist_name}
                                    </li>
                                  );
                                })}
                              </ul>
                            </span>
                          ) : (
                            <span>
                              You currently do not have any reading lists created.
                              <br />
                            </span>
                          )}
                          <br />
                          <div>
                            Create A New Reading List By Entering A Title Below:
                          </div>
                          <br />
                          <input
                            type="text"
                            placeholder="Type here"
                            value={this.state.readingListName}
                            className="reading-list-input-box"
                            onChange={this.handleInput}
                            onFocus={this.handleOnFocusInput}
                          />
                          <br />
                          <span className="length-info">Name with a maximum of 100 characters.</span>
                          <div className="errorInvalidName">
                            {this.state.error["pinReadingList"]}
                          </div>
                          <br />
                        </>
                      ) : (
                        <div style={{ textAlign: "center", padding: "10%" }}>
                          <img
                            src={imsLoader}
                            alt="Loading Data"
                            style={{ width: "10%" }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {this.state.isManage && (
                    <div>
                      {this.state.isLoaded ? (
                        <>
                          {this.state.apiResponse.length > 0 ? (
                            <span>
                              <table className="reading-list-view-wrapper">
                                <tr>
                                  <th>Reading List Name</th>
                                  <th style={{ textAlign: "center" }}>Date</th>
                                  <th style={{ textAlign: "center" }}>Actions</th>
                                </tr>
                                <tbody>
                                  {this.state.apiResponse.map((item) => {
                                    return (
                                      <tr key={item.readinglist_id}>
                                        <td>{item.readinglist_name}</td>
                                        <td style={{ textAlign: "center" }}>
                                          {moment(item.added_on).format("D-MMM-YYYY")}
                                        </td>
                                        <td
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            width: "100%",
                                          }}
                                        >
                                          <span
                                            onClick={(e) =>
                                              this.handleOnClickView(
                                                item.readinglist_id,
                                                item.readinglist_name
                                              )
                                            }
                                            title="View"
                                            className="reading-list-action-text"
                                          >
                                            View
                                          </span>
                                          <span className="action-items-separate-divider"></span>
                                          <span
                                            onClick={(e) =>
                                              this.handleRename(
                                                item.readinglist_id,
                                                item.readinglist_name
                                              )
                                            }
                                            title="Edit"
                                            className="reading-list-action-text"
                                          >
                                            Edit
                                          </span>
                                          <span className="action-items-separate-divider"></span>
                                          <span
                                            title="Remove"
                                            className="reading-list-action-text"
                                            onClick={(e) =>
                                              this.handleRemove(
                                                item.readinglist_id
                                              )
                                            }
                                          >
                                            Remove
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </span>
                          ) : (
                            <span>
                              {" "}
                              You currently do not have any reading lists created.
                            </span>
                          )}
                        </>
                      ) : (
                        <div style={{ textAlign: "center", padding: "10%" }}>
                          <img
                            src={imsLoader}
                            alt="Loading Data"
                            style={{ width: "10%" }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Modal.Body>
                {this.state.isPin ? (
                  <div
                    className="gsf-popup-button-wrapper"
                    style={{ width: "40%" }}
                  >
                    <Button
                      text="Cancel"
                      customColor="#0074B0"
                      action={() => {
                        this.setState({ showPop: false });
                        this.props.handleClose(false);
                      }}
                    />
                    <Button
                      text="Pin"
                      customColor="#2EB000"
                      action={this.onclickProceed}
                    />
                  </div>
                ) : (
                  <div className="reading-list-button-tablet-wrapper">
                    <Button
                      text="Cancel"
                      customColor="#0074B0"
                      action={() => {
                        this.setState({ showPop: false });
                        this.props.handleClose(false);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            {this.state.showSuccess && (
              <>
                <SuccessReadingList
                  display={this.state.showSuccess}
                  handleClose={(status) => this.handleSuccessClose(status)}
                  data={this.state.successMessage}
                  width={this.props.width}
                />
                <div className="savemodal-outer-wrapper"></div>
              </>
            )}
            {this.state.showRenamePop && (
              <>
                <RenameReadingListPopup
                  showModal={this.state.showRenamePop}
                  handleClose={(status) => this.handleRenameClose(status)}
                  rlName={this.state.itemtoBeRenamedName}
                  rlID={this.state.itemtoBeRenamedID}
                  width={this.props.width}
                  apiResponse={this.state.apiResponse}
                />
                <div className="savemodal-outer-wrapper"></div>
              </>
            )}
          </Modal>
          {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
          {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
        </>
      );
    } else {
      return (
        <>
          <Modal
            show={this.state.showPop}
            size="lg md sm xs"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="popup-transition"
            scrollable={true}
          >
            <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
              <div className="popup-cancel-btn-wrapper">
                <img
                  src={popCancelBttn}
                  alt=""
                  onClick={(e) => {
                    this.setState({ showPop: false });
                    this.props.handleClose(false);
                  }}
                />
              </div>
              <div>
                <Modal.Header
                  className="gsfModalHeader"
                  style={{ justifyContent: "left" }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      width: "60%",
                      paddingTop: "1%",
                    }}
                  >
                    <Modal.Title>
                      <div className="actionItem-headerPart" style={isInsertSuccess ? { justifyContent: "flex-start" } : {}}>
                        {!isInsertSuccess && (<>
                          <div
                            onClick={(e) => {
                              this.setState({ isPin: true, isManage: false });
                            }}
                            className={
                              this.state.isPin
                                ? "active-features-button"
                                : "features-buttons"
                            }
                          >
                            <span>Pin to Reading List</span>
                          </div>
                          <div className="action-button-saperation-line"></div>
                        </>
                        )}

                        <div
                          onClick={(e) => {
                            this.setState({ isPin: false, isManage: true });
                          }}
                          className={
                            this.state.isManage
                              ? "active-features-button"
                              : "features-buttons"
                          }
                        >
                          <span>View/Manage Reading Lists</span>
                        </div>
                      </div>
                    </Modal.Title>
                  </div>
                </Modal.Header>
                <Modal.Body scrollable={true} style={{ height: "35vh" }}>
                  {this.state.isPin && (
                    <div>
                      {this.state.isLoaded ? (
                        <>
                          <span style={{ color: "black" }}>
                            My Reading Lists
                          </span>{" "}
                          :
                          {this.state.apiResponse.length > 0 ? (
                            <span>
                              <ul className="reading-list-wrapper">
                                {this.state.apiResponse.map((item) => {
                                  return (
                                    <li key={item.readinglist_id}>
                                      <span>
                                        <input
                                          // disabled={item.docCount > 200}
                                          title={
                                            item.docCount > 200
                                              ? "A user may only add a maximum of 200 items in a reading list."
                                              : ""
                                          }
                                          type="checkbox"
                                          value={item.readinglist_id}
                                          className="reading-list-checkbox-wrapper"
                                          onChange={e => this.handleCheckBox(e, item.docCount)}
                                        />
                                      </span>
                                      {item.readinglist_name}
                                    </li>
                                  );
                                })}
                              </ul>
                            </span>
                          ) : (
                            <span>
                              You currently do not have any reading lists created.
                              <br />
                            </span>
                          )}
                          <br />
                          <div>
                            Create A New Reading List By Entering A Title Below:
                          </div>
                          <input
                            type="text"
                            placeholder="Type here"
                            value={this.state.readingListName}
                            className="reading-list-input-box"
                            onFocus={this.handleOnFocusInput}
                            onChange={this.handleInput}
                          />
                          <br />
                          <span className="length-info">Name with a maximum of 100 characters.</span>
                          <div className="errorInvalidName">
                            {this.state.error["pinReadingList"]}
                          </div>
                          <br />
                        </>
                      ) : (
                        <div style={{ textAlign: "center", padding: "10%" }}>
                          <img
                            src={imsLoader}
                            alt="Loading Data"
                            style={{ width: "10%" }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {this.state.isManage && (
                    <div>
                      {this.state.isLoaded ? (
                        <>
                          {this.state.apiResponse.length > 0 ? (
                            <span>
                              <table className="reading-list-view-wrapper">
                                <tr>
                                  <th>Reading List Name</th>
                                  <th style={{ textAlign: "center" }}>Date</th>
                                  <th style={{ textAlign: "center" }}>Actions</th>
                                </tr>
                                <tbody>
                                  {this.state.apiResponse.map((item) => {
                                    return (
                                      <tr key={item.readinglist_id}>
                                        <td>{item.readinglist_name}</td>
                                        <td style={{ textAlign: "center" }}>
                                          {moment(item.added_on).format("D-MMM-YYYY")}
                                        </td>
                                        <td
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            width: "100%",
                                          }}
                                        >
                                          <span
                                            onClick={(e) =>
                                              this.handleOnClickView(
                                                item.readinglist_id,
                                                item.readinglist_name
                                              )
                                            }
                                            title="View"
                                            className="reading-list-action-text"
                                          >
                                            View
                                          </span>
                                          <span className="action-items-separate-divider"></span>
                                          <span
                                            onClick={(e) =>
                                              this.handleRename(
                                                item.readinglist_id,
                                                item.readinglist_name
                                              )
                                            }
                                            title="Edit"
                                            className="reading-list-action-text"
                                          >
                                            Edit
                                          </span>
                                          <span className="action-items-separate-divider"></span>
                                          <span
                                            title="Remove"
                                            className="reading-list-action-text"
                                            onClick={(e) =>
                                              this.handleRemove(
                                                item.readinglist_id
                                              )
                                            }
                                          >
                                            Remove
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </span>
                          ) : (
                            <span>
                              {" "}
                              You currently do not have any reading lists created.
                            </span>
                          )}
                        </>
                      ) : (
                        <div style={{ textAlign: "center", padding: "10%" }}>
                          <img
                            src={imsLoader}
                            alt="Loading Data"
                            style={{ width: "10%" }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Modal.Body>
                {this.state.isPin ? (
                  <div className="gsf-popup-button-wrapper">
                    <Button
                      text="Cancel"
                      customColor="#0074B0"
                      action={() => {
                        this.setState({ showPop: false });
                        this.props.handleClose(false);
                      }}
                    />

                    <Button
                      text="Pin"
                      customColor="#2EB000"
                      action={this.onclickProceed}
                    />
                  </div>
                ) : (
                  <div className="reading-list-button-wrapper">
                    <Button
                      text="Cancel"
                      customColor="#0074B0"
                      action={() => {
                        this.setState({ showPop: false });
                        this.props.handleClose(false);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            {this.state.showSuccess && (
              <>
                <SuccessReadingList
                  display={this.state.showSuccess}
                  handleClose={(status) => this.handleSuccessClose(status)}
                  data={this.state.successMessage}
                  width={this.props.width}
                />
                <div className="savemodal-outer-wrapper"></div>
              </>
            )}
            {this.state.showRenamePop && (
              <>
                <RenameReadingListPopup
                  showModal={this.state.showRenamePop}
                  handleClose={(status) => this.handleRenameClose(status)}
                  rlName={this.state.itemtoBeRenamedName}
                  rlID={this.state.itemtoBeRenamedID}
                  width={this.props.width}
                  apiResponse={this.state.apiResponse}
                />
                <div className="savemodal-outer-wrapper"></div>
              </>
            )}
          </Modal>
          {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
          {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
        </>
      );
    }
  }
}
export default PinToReadingListPopUp;
