/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react'


function NlpSectionAnswerCard(props) {
    const [isShowMore, setIsShowMore] = useState(false)
    useEffect(() => {
        setIsShowMore(false);
    }, [])
    const snippetLongString = (snippet) => {
        if (snippet.length > 300) {
            let text = "";
            let spanIndex = snippet.indexOf('<span class=');
            if (spanIndex !== -1) {
                text = snippet.substring(spanIndex, (spanIndex + 300))//cuts to spanIndex+300
                let last = text.lastIndexOf(" ")//gets last space (to avoid cutting the middle of a word)
                text = text.substring(0, last)//cuts from last space (to avoid cutting the middle of a word)
            } else {
                text = snippet.substring(0, 300)//cuts to 70
                let last = text.lastIndexOf(" ")//gets last space (to avoid cutting the middle of a word)
                text = text.substring(0, last)//cuts from last space (to avoid cutting the middle of a word)
            }
            //text = text + ` ...`//adds (...) at the end to show that it's cut
            return text
        }
        return snippet;
    }
    const convertToHtml = (item) => {
        let snipeerHtml = ""
        if (!isShowMore) {
            snipeerHtml = snippetLongString(item);
        } else {
            snipeerHtml = item;
        }
        // return (<div dangerouslySetInnerHTML={{ __html: item }}></div>)
        return (<div className='npl-document-content-wrapper'><div dangerouslySetInnerHTML={{ __html: snipeerHtml }}></div>
            {(item.length > 300) && <>{isShowMore ? <span className='nlp-show-more-less' onClick={() => setIsShowMore(!isShowMore)}>
                Show Less
            </span> : <span className='nlp-show-more-less' onClick={() => setIsShowMore(!isShowMore)}>
                Show More
            </span>}</>}

        </div>)
    }
    return(<div className="nlp-for-first-line">{convertToHtml(props.sectionAnswer.replace(/(\<br[\s]*\/?\>[\s]*)+/g, '<br>'))}</div>)

}
export default NlpSectionAnswerCard;