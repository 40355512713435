/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { dropdownArrow, filterExpandIcnTab, avatarBlueIcn, crossIcon, avatarWhiteIcn, IMSLogo, mainMenuExpMobile, mainMenuCollMobile } from "../../assets";
import DisclaimerPop from "../PopUp/DisclaimerPop";
import PrivacyStatementPopUp from "../PopUp/PrivacyStatementPopUp";
import HistoryPopUp from "../PopUp/HistoryPopUp";
import './Navigation.css';

export default function PubMedNavigationMenu(props) {
    const { customWidth } = props
    const [display, setDisplay] = useState(false);
    const [displayMainMenu, setMainMenuDisplay] = useState(false);
    const [alertDisplay, setAlertdisplay] = useState(false);
    const [showDisclaimer, setDisclaimer] = useState(false);
    const [showPrivacy, setPrivacy] = useState(false);
    const [showHistory, setHistory] = useState(false);
    /* const [showRecentlyViewed,setRecentlyViewed] = useState(false); */
    const [compWidth, setWidth] = useState(0);
    /* const [dimensions, setDimensions] = useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      }) */
    const { pathName } = props;
    const username = JSON.parse(localStorage.getItem('userdisplayName'));
    var width
    useEffect(() => {
        if (document.getElementById('username-wrapper')) {
            width = document.getElementById('username-wrapper').offsetWidth;
            setWidth(width)
        }
        /* function handleResize() {
            setDimensions({
              height: window.innerHeight,
              width: window.innerWidth
            })
          
      }
      window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    } */
    });
    // function handleAlertDisplayClose() {
    //     setAlertdisplay(false)
    // };
    function handelProfileDisplayClose() {
        setDisplay(false)
    }
    if (customWidth < 600) {
        return (
            <div className="navigation-menu-wrapper-mobile">
                <span className="navigation-menu-inner-wrapper-mobile">
                    <span style={{ paddingTop: "1%", width: "95%" }}>
                        <img src={IMSLogo} height="45" alt="" onClick={e => window.open('/recommended', '_self')} />
                    </span>
                    <span>
                        <span>
                            {!displayMainMenu ?
                                <img src={mainMenuExpMobile} alt="" onClick={e => setMainMenuDisplay(true)} />
                                :
                                <img src={mainMenuCollMobile} alt="" onClick={e => setMainMenuDisplay(false)} />
                            }
                        </span>
                    </span>
                </span>
                <span style={{ padding: "0 5px" }}>
                    {displayMainMenu &&
                        <div className="navigation-sub-menu-wrapper-mobile">
                            <div className="navigation-sub-menu-main-header-mobile">
                                <b style={{ fontSize: "16px" }}>Menu</b>
                                <img src={mainMenuCollMobile} alt="" onClick={e => setMainMenuDisplay(false)} />
                            </div>
                            <div className="navigation-sub-menu-username-mobile">
                                <img src={avatarBlueIcn} width="35" height="30" alt="" />
                                <b>{username}</b>
                            </div>
                            <ul>
                                <li>
                                    <Link to="/recommended" >Home</Link>
                                </li>
                                <li>
                                    <Link to="/Get-Help" target="_blank">Access Training Materials</Link>
                                </li>
                                <li>
                                    <Link to="/Contact-Us" target="_blank">Contact Us</Link>
                                </li>
                                <li onClick={(e) => { setDisclaimer(true); setDisplay(false) }}>Disclaimer</li>
                                <li onClick={(e) => { setPrivacy(true); setDisplay(false) }}>View Privacy Policy</li>
                            </ul>
                        </div>
                    }
                    {showDisclaimer &&
                        <DisclaimerPop display={showDisclaimer} handleClose={(status) => { setDisclaimer(status) }} />
                    }
                    {showPrivacy &&
                        <PrivacyStatementPopUp display={showPrivacy} handleClose={(status) => { setPrivacy(status) }} />
                    }
                </span>
            </div>
        )
    }
    else if (customWidth >= 600 && customWidth < 1000) {
        return (
            <div className="navigation-menu-wrapper-smaller-resolution">
                <span>
                    <span style={{ padding: "0 10px" }}>
                        {!displayMainMenu ?
                            <img src={filterExpandIcnTab} alt="" onClick={e => setMainMenuDisplay(true)} width="15" height="15" />
                            :
                            <img src={crossIcon} alt="" onClick={e => setMainMenuDisplay(false)} width="12" height="12" />
                        }
                    </span>
                    <>
                        {displayMainMenu &&
                            <div className="navigation-main-sub-menu-wrapper">
                                <ul style={{ padding: "0.5% 0.5%" }}>
                                    <li style={{ display: "flex", alignItems: "center", borderBottom: "1px solid #D9D9D9" }}>
                                        <img src={avatarBlueIcn} alt="" width="40" height="45" /><span><b>{username}</b></span>
                                    </li>
                                    <li>
                                        <Link to="/recommended" >Home</Link>
                                    </li>
                                    {alertDisplay &&
                                        <ol className="navigation-alerts-sub-menu-wrapper-tablet">
                                            <li onClick={(e) => { setAlertdisplay(false); window.open("/ManageAlerts") }}>Manage alerts</li>
                                            <li onClick={(e) => { window.open("https://jnj.sharepoint.com/:p:/r/teams/iMedicalKnowledgeSearch/Announcements/iMKSTraining_Other/iMS_Setting%20Up%20Personal%20Alerts.pptx?d=w100e67141726406084ff788dad6d3208&csf=1&e=ShCCB4", "_blank"); setAlertdisplay(false); }}>Instructions for Setting Up Alerts</li>
                                        </ol>
                                    }
                                    <li>
                                        <Link to="/Get-Help" target="_blank">Access Training Materials</Link>
                                    </li>
                                    <li>
                                        <Link to="/Contact-Us" target="_blank">Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                        }
                    </>
                </span>
                <span onMouseLeave={handelProfileDisplayClose}>
                    <span style={{ padding: "0 5px" }}>
                        <img src={avatarWhiteIcn} alt="" width="35" height="30" onClick={e => setDisplay(!display)} />
                    </span>
                    {display &&
                        <div className="navigation-sub-menu-wrapper-tablet">
                            <div style={{ textAlign: "center", padding: "1% 0", boxShadow: "0px 3px 6px #00000029", background: "#fff 0% 0%  no-repeat padding-box", height: "36px" }}>
                                <b>{username}</b>
                                <div style={{ position: "absolute", width: "100%" }}><img src={avatarBlueIcn} alt="" width="35" height="30" /></div>
                            </div>
                            <ul>
                                <li onClick={(e) => { setDisclaimer(true); setDisplay(false) }}>Disclaimer</li>
                                <li onClick={(e) => { setPrivacy(true); setDisplay(false) }}>View Privacy Policy</li>
                            </ul>
                        </div>
                    }
                </span>
                {showDisclaimer &&
                    <DisclaimerPop display={showDisclaimer} handleClose={(status) => { setDisclaimer(status) }} />
                }
                {showPrivacy &&
                    <PrivacyStatementPopUp display={showPrivacy} handleClose={(status) => { setPrivacy(status) }} />
                }
                {showHistory &&
                    <HistoryPopUp display={showHistory} handleClose={(status) => { setHistory(status) }} />
                }
            </div>
        )
    }
    else {
        return (
            <div className="navigation-menu-wrapper">
                <ul className="navigation-main-menu-wrapper">
                    <li>
                        <Link to="/recommended" >Home</Link>
                        {pathName === '/' || pathName === '/recommended' ?
                            <div className="navigation-active-indicator"></div>
                            :
                            <div className="navigation-inactive-indicator"></div>
                        }
                    </li>
                    <div className="navigation-divider"></div>
                    <li>
                        <Link to="/Get-Help" target="_blank">Access Training Materials</Link>
                        {pathName === '/Get-Help' ?
                            <div className="navigation-active-indicator"></div>
                            :
                            <div className="navigation-inactive-indicator"></div>
                        }
                    </li>
                    <div className="navigation-divider"></div>
                    <li>
                        <Link to="/Contact-Us" target="_blank">Contact Us</Link>
                        {pathName === '/Contact-Us' ?
                            <div className="navigation-active-indicator"></div>
                            :
                            <div className="navigation-inactive-indicator"></div>
                        }
                    </li>
                    {/* <div className="navigation-divider"></div> */}
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li onMouseLeave={handelProfileDisplayClose}>
                        <div className="navigation-username-wrapper" id="username-wrapper">
                            <div className="navigation-username-inner-wrapper" onClick={e => setDisplay(!display)}>
                                <span>{username}</span>
                                <span className="navigation-username-image-wrapper">
                                    {display ?
                                        <img src={dropdownArrow} alt="" style={{ transform: "rotate(180deg)" }} />
                                        :
                                        <img src={dropdownArrow} alt="" />
                                    }
                                </span>
                            </div>
                        </div>
                        {display &&
                            <ul className="navigation-sub-menu-wrapper" style={{ width: compWidth }}>
                                {/* <li onClick={(e)=>{setRecentlyViewed(true);setDisplay(false)}}>Recently Viewed</li> */}
                                <li onClick={(e) => { setDisclaimer(true); setDisplay(false) }}>Disclaimer</li>
                                <li onClick={(e) => { setPrivacy(true); setDisplay(false) }}>View Privacy Policy</li>
                            </ul>
                        }
                        <div className="navigation-inactive-indicator"></div>
                    </li>
                </ul>
                {showDisclaimer &&
                    <DisclaimerPop display={showDisclaimer} handleClose={(status) => { setDisclaimer(status) }} />
                }
                {showPrivacy &&
                    <PrivacyStatementPopUp display={showPrivacy} handleClose={(status) => { setPrivacy(status) }} />
                }
                {showHistory &&
                    <HistoryPopUp display={showHistory} handleClose={(status) => { setHistory(status) }} />
                }
                {/* showRecentlyViewed &&
                <RecentlyPopUp display={showRecentlyViewed} handleClose={(status)=>{setRecentlyViewed(status)}}/>
                */}
            </div>
        )
    }
}