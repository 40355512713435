/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { quickActionShareMobileIcon, shareIcon, downloadIcon, quickActionIcon, supportingDocumentMobTabIcon, supportingDocumentWebIcon, quickActionDropDown } from "../../assets"
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import trackingService from "../../services/trackingService";
import GenerateArrayStrings from "../../components/GenerateArrayStrings";
import SharePopup from "../../components/SharePopup";
import OpenWindow from "../../components/OpenWindow";
import '../../components/ActionBar/ActionBar.css';

const indexData = (item, url, appliedFilters, searchKeyword, pageNumber, pageSize, shareoption, csPath, csProduct) => {
    let newUrl = window.origin + url;
    const ts = new trackingService();
    let { orderedBy, orderedFor } = item;
    if (!orderedBy) orderedBy = '';
    if (!orderedFor) orderedFor = '';
    if (item.domain === 'RIMdocs' || item.domain === 'RIMdocs_LL') {
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), `${shareoption} Document`, '', window.location.href, searchKeyword, appliedFilters, item.docTitle, item.r_version_label, item.jj_number, item.searchID, newUrl, item.tempJNJDrugs, item.docType, `${shareoption} Document`, csPath, csProduct, item.domain, pageNumber, pageSize, item.createdDate, orderedBy, orderedFor);
    }
    else if (item.domain === 'SKM') {
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), `${shareoption} Document`, '', window.location.href, searchKeyword, appliedFilters, item.docTitle, '', item.domain, item.searchID, newUrl, item.tempJNJDrugs, item.docType, `${shareoption} Document`, csPath, csProduct, item.domain, pageNumber, pageSize, item.createdDate, orderedBy, orderedFor);
    }
    else if (item.domain === 'CTGov') {
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), `${shareoption} Document`, '', window.location.href, searchKeyword, appliedFilters, item.docTitle, '', item.docID, item.searchID, newUrl, item.tempJNJDrugs, item.docType, `${shareoption} Document`, csPath, csProduct, item.domain, pageNumber, pageSize, item.createdDate, orderedBy, orderedFor);
    }
    else {
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), `${shareoption} Document`, '', window.location.href, searchKeyword, appliedFilters, item.docTitle, item.majorVersionNumber, item.documentNumber, item.searchID, newUrl, item.tempJNJDrugs, item.docType, `${shareoption} Document`, csPath, csProduct, item.domain, pageNumber, pageSize, item.createdDate, orderedBy, orderedFor);
    }
}
const handleSupportingDocs = (url) => {
    OpenWindow(url)
}
const handleMIAsDocs = (refurl) => {
    let url = `/ReferencesUsed?query=${refurl}`;
    window.open(url, '', `width=${window.innerWidth},height=${window.innerHeight},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no`)
}
export default function MIAsReferencesActionBar(props) {
    const {
        item,
        appliedFilters,
        searchKeyword,
        pageNumber,
        pageSize,
        customWidth,
        collapsed,
        csPath,
        csproduct,
    } = props
    const [isOpen, setOpenState] = useState(false)
    const [qacollapsed, setqaCollapsed] = useState(false)
    const [showActions, setShowActions] = useState(false)
    let url, supportingDocUrl, docIds, referencesUrl
    if (item.supportingDocIDs) {
        docIds = GenerateArrayStrings(item.supportingDocIDs)
        supportingDocUrl = `?query=&q=All%20Fields&filters=&f1=docID&fval1=${docIds}&fcount=1`
    }
    let { orderedBy, orderedFor } = item;
    if (!orderedBy) orderedBy = '';
    if (!orderedFor) orderedFor = '';
    url = `/DocURL?ID=${item.docID}&cs=${item.domain}&connID=${item.connID}&csID=${item.csID}&fileType=${item.fileExtension}&fileName=${item.docTitle}&orderedBy=${orderedBy}&orderedFor=${orderedFor}`
    useEffect(() => {
        // if (collapsed) {
        //     setqaCollapsed(true)
        // }
        // else {
        //     if (!qacollapsed) {
        //         setqaCollapsed(!qacollapsed)
        //     }
        //     else{
        //         setqaCollapsed(false)
        //     }
        //     // setqaCollapsed(false)
        // }
        if (collapsed) {
            setqaCollapsed(true)
        }
        else {
            setqaCollapsed(false)
        }
    }, [collapsed])
    function handleShowActions() {
        setShowActions(false)
    }
    function handleCollapsedQuickAction() {
        setqaCollapsed(!qacollapsed)
    }
    function onDownloadClick() {
        indexData(item, url, appliedFilters, searchKeyword, pageNumber, pageSize, 'Download', csPath !== null ? csPath : '', csproduct !== null ? csproduct : '')
    }
    if (customWidth < 1000) {
        return (
            <div className="action-items-mobile-wrapper" >
                <img src={quickActionIcon} alt="" width="35" height="35" onClick={e => setShowActions(!showActions)} />
                {showActions &&
                    <div className="action-items-mobile-inner-wrapper" onMouseLeave={handleShowActions}>
                        {item.supportingDocIDs !== undefined && item.supportingDocIDs.length > 0 &&
                            <>
                                <div className="action-items-mobile-items-wrapper" onClick={evt => handleSupportingDocs(supportingDocUrl)}><img src={supportingDocumentMobTabIcon} alt="" width="30" height="30" /><span className="action-item-text-mobile">Supporting Documents</span></div>
                                <div className="quick-action-divider-mobile"></div>
                            </>
                        }
                        <div className="action-items-mobile-items-wrapper" onClick={(evt) => { setOpenState(!isOpen); indexData(item, url, appliedFilters, searchKeyword, pageNumber, pageSize, 'Share', '', '') }}><img src={quickActionShareMobileIcon} alt="" width="30" height="30" /><span className="action-item-text-mobile">Share</span></div>
                    </div>
                }
                {isOpen &&
                    <div>
                        <SharePopup isOpen={isOpen} item={item} appliedFilters={appliedFilters} searchKeyword={searchKeyword} pageNumber={pageNumber} pageSize={pageSize} handleClose={(close) => { setOpenState(close) }} csPath={csPath} csproduct={csproduct} customWidth={customWidth}></SharePopup>
                    </div>
                }
            </div>
        )
    }
    else {
        return (
            <div className="action-items-wrapper">
                <div className="action-items-inner-wrapper-header" onClick={handleCollapsedQuickAction}>
                    <div className="action-items-main-text">
                        Quick Actions &nbsp;
                        <img src={quickActionDropDown} alt="dropdownIcon" style={{ transform: !qacollapsed ? "matrix(-1, 0, 0, -1, 0, 0)" : null, margin: "0px 0px 1.5%" }} />
                    </div>
                </div>
                <div className="quick-action-divider"></div>
                {!qacollapsed &&
                    <>
                        {item.supportingDocIDs !== undefined && item.supportingDocIDs.length > 0 &&
                            <>
                                <div className="action-items-inner-wrapper">
                                    <a href="javascript:void(0)" onClick={evt => handleSupportingDocs(supportingDocUrl)} className="quick-action-item">
                                        <img src={supportingDocumentWebIcon} alt="" title="Supporting Documents" />
                                        <div className="action-items-supportingDocument-title">
                                            Supporting Documents
                                        </div>
                                    </a>
                                </div>
                                <div className="quick-action-divider"></div>
                            </>
                        }
                        {item.domain === "MIAs" &&
                            <>
                                <div className="action-items-inner-wrapper">
                                    <a href="javascript:void(0)" onClick={evt => handleMIAsDocs(referencesUrl)} className="quick-action-item">
                                        <img src={supportingDocumentWebIcon} alt="" title="References Used" />
                                        <div className="action-items-supportingDocument-title">
                                            References Used
                                        </div>
                                    </a>
                                </div>
                                <div className="quick-action-divider"></div>
                            </>
                        }
                        <div className="action-items-inner-wrapper">
                            <a href="javascript:void(0)" onClick={(evt) => { setOpenState(!isOpen); indexData(item, url, appliedFilters, searchKeyword, pageNumber, pageSize, 'Share', '', '') }} className="quick-action-item">
                                <img src={shareIcon} alt="" title="Share" />
                                <div className="action-items-title">
                                    Share
                                </div>
                            </a>
                        </div>
                        <div className="quick-action-divider"></div>
                        <div className="action-items-inner-wrapper">
                            <Link target="_blank" to={url} onClick={onDownloadClick} className="quick-action-item">
                                <img src={downloadIcon} alt="" title="Download" />
                                <div className="action-items-title">
                                    Download
                                </div>
                            </Link>
                        </div>
                        {isOpen &&
                            <div>
                                <SharePopup isOpen={isOpen} item={item} appliedFilters={appliedFilters} searchKeyword={searchKeyword} pageNumber={pageNumber} pageSize={pageSize} handleClose={(close) => { setOpenState(close) }} csPath={csPath} csproduct={csproduct} customWidth={customWidth}></SharePopup>
                            </div>
                        }
                    </>
                }
            </div>

        )
    }
}