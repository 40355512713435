import { useEffect, useState } from "react";
import {
    collapseFilterWhite,
    expandFilterWhite,
    fieldedSrchIcon,
    DownloadButton,
    saveBlueIcon,
    arrowIconBlue,
    PlusIconBlue,
    deleteIcon,
    RenameIcon,
    ExportIcon,
    UploadIcon,
    TaskIcon,
    FileIcon,
} from "../../assets";
import "./TSS.css";
import TSSMyFile from "./TSSMyFile";
import TSSMyTask from "./TSSMyTask";
import CreateFolderPopUp from "./CreateFolderPopUp";
import AudioContent from "./AudioContent";
import UploadPopUp from "./UploadPopUp";
import RenamePopUp from "./RenamePopUp";
import ApiServices from "../../utils/apiServices";
import DeletePopUp from "./DeletePopUp";
import TTSSidebar from "./TTSSidebar";
import { useHistory } from "react-router-dom";
import ExportPopUp from './ExportPopUp';

const TTSHome = () => {

    const [resFolder, setResFolder] = useState({});
    const [resFiles, setResFiles] = useState({});
    const [showMenu, setShowMenu] = useState('true');
    const [menuselected, setMenu] = useState('My_Files');
    const [displayNew, setDisplayNew] = useState(false);
    const [displayUoload, setDisplayUpload] = useState(false);
    const [newFolderClick, setNewFolderClick] = useState(false);
    const [newTextFileClick, setNewTextFileClick] = useState(false);
    const [uploadTextFileClick, setUploadTextFileClick] = useState(false);
    const [rightPannel, setRightPannel] = useState(true);
    const [manageFile, setManageFile] = useState(true);
    const [selected,setSelected] = useState ([]);
    const [selectedFolder,setSelectedFolder] = useState({})
    const [renameSelected,setRenameSelected]= useState(false);
    const [deleteSelected,setDeleteSelected] = useState(false);
    const [refresh,setRefresh] = useState(false);
    const [downloadPopUp , setDownloadPopUp] = useState(false)
    let history = useHistory();
    
    useEffect( ()=>{
        dataFetch();
        setRenameSelected(false)
    },[])
     async function dataFetch (){
            const service = new ApiServices();
            let apiresponse1 = await service.getAllFolderDetails();
            let apiresponse2 = await service.getAllDocDetails();
            setResFiles(apiresponse2);
            setResFolder(apiresponse1)
           }
    return (
        <>
            <div className="TTS-home-contents-wrapper" >
               <TTSSidebar />
                {(newTextFileClick || !manageFile) ? <AudioContent setManageFile={() => setManageFile(false)} />
                    :
                    <div className="right-panel"  >
                        <div className="WNFY-WOAV-RV-QH-outer-wrapper">
                            <div className="WNFY-WOAV-outer-wrapper">
                                {(rightPannel) && <>
                                    <h1 className="intro-text">Manage Files
                                    </h1>
                                    <div className="TSS-container-header">
                                        <div>
                                            <button className={(displayNew) ? "TSS-button-style-selected" : "TSS-button-style-active"} onClick={(e) => setDisplayNew(!displayNew)}
                                                onMouseLeave={() => setDisplayNew(false)}
                                                disabled={(selected.length <= 1) ? false : true}>
                                                <span className="TSS-container-header-span">
                                                    <svg width="20px" height="20px" viewBox="0 -0.5 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <g id="Dribbble-Light-Preview" transform="translate(-379.000000, -240.000000)" fill="#3276c3">
                                                                <g id="icons" transform="translate(56.000000, 160.000000)">
                                                                    <polygon id="plus-[#1512]" points="344 89 344 91 334.55 91 334.55 100 332.45 100 332.45 91 323 91 323 89 332.45 89 332.45 80 334.55 80 334.55 89">
                                                                    </polygon>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                    <span className="TSS-label-text"
                                                    >New</span>
                                                    {(displayNew) ?
                                                        <svg width="16px" height="14px" style={{ marginTop: "2px" }} viewBox="0 0 1024.00 1024.00" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#3276c3" transform="rotate(180)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="45.056"></g><g id="SVGRepo_iconCarrier"><path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="#3276c3"></path></g></svg>
                                                        // <img src={arrowIconBlue} alt="" style={{ transform: "rotate(180deg)" }} />
                                                        :
                                                        <svg width="16px" height="14px" style={{ marginTop: "2px" }} viewBox="0 0 1024.00 1024.00" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#3276c3"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="#3276c3"></path></g></svg>
                                                        // <img src={arrowIconBlue} />
                                                    }
                                                </span>
                                                {(displayNew) && <ul className="TSS-sub-wrapper-menu">
                                                    <li onClick={() => setNewFolderClick(true)}>Folder</li>
                                                    <li onClick={() => { history.push(`/TTSAudioContentPage/${null}`)}}>Text File</li>
                                                </ul>}
                                            </button>
                                        </div>
                                        <div>
                                            <button className={(displayUoload) ? "TSS-button-style-selected" :
                                                "TSS-button-style-active"} onClick={() => setDisplayUpload(true)}
                                                onMouseLeave={() => setDisplayUpload(false)}
                                                disabled={(selected.length <= 1) ? false : true}>
                                                <span
                                                    className="TSS-container-header-span">
                                                    <img src={UploadIcon} />
                                                    <span className="TSS-label-text"
                                                    >Upload</span>
                                                    {(displayUoload) ?
                                                        <svg width="16px" height="14px" style={{ marginTop: "2px" }} viewBox="0 0 1024.00 1024.00" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#3276c3" transform="rotate(180)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="45.056"></g><g id="SVGRepo_iconCarrier"><path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="#3276c3"></path></g></svg>
                                                        // <img src={arrowIconBlue} alt="" style={{ transform: "rotate(180deg)" }} />
                                                        :
                                                        <svg width="16px" height="14px" style={{ marginTop: "2px" }} viewBox="0 0 1024.00 1024.00" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#3276c3"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="#3276c3"></path></g></svg>
                                                        // <img src={arrowIconBlue} />
                                                    }
                                                </span>
                                                {(displayUoload) && <ul className="TSS-sub-wrapper-menu">
                                                    <li onClick={() => setUploadTextFileClick(true)}>Text File</li>
                                                    <li onClick ={() => history.push('/TSSMyTask')}>Lexion File</li>
                                                </ul>}
                                            </button>
                                        </div>
                                        <div>
                                            <button className={(selected.length > 0) ? "TSS-button-style-active" : "TSS-button-style-deactive"}
                                                disabled={(selected.length > 0) ? false : true}
                                                onClick ={()=>setDownloadPopUp(true)}>
                                                <span className="TSS-container-header-span">
                                                    <svg fill={(selected.length > 0) ? "rgb(50, 118, 195)" : "#B4B4B4"} viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px">
                                                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier"
                                                            stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier">
                                                            <path d="m0 1016.081 409.186 409.073 79.85-79.736-272.867-272.979h1136.415V959.611H216.169l272.866-272.866-79.85-79.85L0 1016.082ZM1465.592 305.32l315.445 315.445h-315.445V305.32Zm402.184 242.372-329.224-329.11C1507.042 187.07 1463.334 169 1418.835 169h-743.83v677.647h112.94V281.941h564.706v451.765h451.765v903.53H787.946V1185.47H675.003v564.705h1242.353V667.522c0-44.498-18.07-88.207-49.581-119.83Z"
                                                                fill-rule="evenodd"></path>
                                                        </g></svg>
                                                    <span className="TSS-label-text"
                                                    >Export</span>
                                                </span>
                                            </button>
                                        </div>
                                        <div>
                                            <button className={(selected.length > 0) ? "TSS-button-style-active" : "TSS-button-style-deactive"}
                                                disabled={(selected.length > 0) ? false : true}
                                                onClick={()=>{setDownloadPopUp(true)}}>
                                                <span className="TSS-container-header-span">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.849" height="16" viewBox="0 0 17.849 16" >
                                                        <g id="Group_29291" data-name="Group 29291" transform="translate(-760.576 -308.146)">
                                                            <path id="Path_11275" data-name="Path 11275" d="M9.867,13.2a.444.444,0,0,1-.349-.186l-3.384-4.4C5.841,8.234,6.058,7.6,6.483,7.6H8.256V3a.92.92,0,0,1,.806-1h1.611a.92.92,0,0,1,.806,1V7.6h1.773c.425,0,.643.634.349,1.014l-3.384,4.4A.444.444,0,0,1,9.867,13.2Z" transform="translate(759.854 306.146)" fill={(selected.length > 0) ? "#0074b0" : "#B4B4B4"} />
                                                            <path id="Path_11276" data-name="Path 11276" d="M16.547,20.718H1.3A1.25,1.25,0,0,1,0,19.529v-.34A1.25,1.25,0,0,1,1.3,18H16.547a1.25,1.25,0,0,1,1.3,1.189v.34A1.25,1.25,0,0,1,16.547,20.718Z" transform="translate(760.576 303.429)" fill={(selected.length > 0) ? "#0074b0" : "#B4B4B4"} />
                                                        </g>
                                                    </svg>
                                                    <span className="TSS-label-text"
                                                    >Download</span>
                                                </span>
                                            </button>
                                        </div>
                                        <div>
                                            <button className={(selected.length === 1) ? "TSS-button-style-active" : "TSS-button-style-deactive"}
                                                disabled={(selected.length === 1) ? false : true}>
                                                <span
                                                    className="TSS-container-header-span">
                                                    <svg viewBox="0 0 28 28" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg" width="20px" height="20px">
                                                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                        <g id="SVGRepo_iconCarrier">
                                                            <path d="M11.75 2C11.3358 2 11 2.33579 11 2.75C11 3.16421 11.3358 3.5 11.75 3.5H13.25V24.5H11.75C11.3358 24.5 11 24.8358 11 25.25C11 25.6642 11.3358 26 11.75 26H16.25C16.6642 26 17 25.6642 17 25.25C17 24.8358 16.6642 24.5 16.25 24.5H14.75V3.5H16.25C16.6642 3.5 17 3.16421 17 2.75C17 2.33579 16.6642 2 16.25 2H11.75Z" fill={(selected.length > 0) ? "#3276c3" : "#B4B4B4"}></path>
                                                            <path d="M6.25 6.01958H12.25V7.51958H6.25C5.2835 7.51958 4.5 8.30308 4.5 9.26958V18.7696C4.5 19.7361 5.2835 20.5196 6.25 20.5196H12.25V22.0196H6.25C4.45507 22.0196 3 20.5645 3 18.7696V9.26958C3 7.47465 4.45507 6.01958 6.25 6.01958Z" fill={(selected.length > 0) ? "rgb(50, 118, 195)" : "#B4B4B4"}></path>
                                                            <path d="M21.75 20.5196H15.75V22.0196H21.75C23.5449 22.0196 25 20.5645 25 18.7696V9.26958C25 7.47465 23.5449 6.01958 21.75 6.01958H15.75V7.51958H21.75C22.7165 7.51958 23.5 8.30308 23.5 9.26958V18.7696C23.5 19.7361 22.7165 20.5196 21.75 20.5196Z"
                                                                fill={(selected.length > 0) ? "rgb(50, 118, 195)" : "#B4B4B4"}></path> </g>
                                                    </svg>
                                                    <span className="TSS-label-text"
                                                        onClick={() => setRenameSelected(true)}
                                                    >Rename</span>
                                                </span>
                                            </button>
                                        </div>
                                        <div>
                                            <button className={(selected.length > 0) ? "TSS-button-style-active" : "TSS-button-style-deactive"}
                                                disabled={(selected.length > 0) ? false : true}>
                                                <span className="TSS-container-header-span">
                                                    <svg id="delete" xmlns="http://www.w3.org/2000/svg" width="11.274" height="16.156" viewBox="0 0 11.274 16.156" fill={(selected.length > 0) ? "#0074b0" : "#B4B4B4"}>
                                                        <g id="Group_23297" data-name="Group 23297" transform="translate(0)">
                                                            <g id="Group_23296" data-name="Group 23296" transform="translate(0)">
                                                                <path id="Path_11289" data-name="Path 11289" d="M64,95a1.61,1.61,0,0,0,1.611,1.611h6.443A1.61,1.61,0,0,0,73.664,95V85.333H64Z" transform="translate(-63.195 -81.201)" fill="none" stroke={(selected.length > 0) ? "#0074b0" : "#B4B4B4"} stroke-width="1.5" />
                                                                <path id="Path_11290" data-name="Path 11290" d="M51.123.805,50.317,0H46.291l-.805.805H42.667V2.416H53.941V.805Z" transform="translate(-42.667)" fill={(selected.length > 0) ? "#0074b0" : "#B4B4B4"} />
                                                            </g>
                                                        </g>
                                                    </svg>

                                                    <span className="TSS-label-text"
                                                        onClick={() => setDeleteSelected(true)}
                                                    >Delete</span>
                                                </span>
                                            </button>
                                        </div>
                                        <div>
                                            <button className={(selected.length > 0) ? "TSS-button-style-active" : "TSS-button-style-deactive"}
                                                disabled={(selected.length === 1) ? false : true}>
                                                <span
                                                    style={{
                                                        display: "flex",
                                                    }}>
                                                    <span
                                                        className="TSS-label-text">...</span>
                                                </span>
                                            </button>
                                        </div>
                                    </div></>}
                                <div className="main-content-container" style={{ padding: "10px", width: "75%" }}>
                                    {(menuselected === 'My_Files' && manageFile) ? <TSSMyFile
                                        setRightPannel={() => setRightPannel(false)}
                                        manageFileProp={manageFile}
                                        setManageFile={() => setManageFile(false)}
                                        setSelected={setSelected}
                                        resFiles ={resFiles}
                                        resFolder = {resFolder}
                                        selectedFolder = {setSelectedFolder}
                                        refresh = {refresh}
                                        setRefresh = {setRefresh}
                                         /> : <TSSMyTask />}
                                </div>
                            </div>
                        </div>
                    </div>}
                {(newFolderClick) &&
                    <CreateFolderPopUp 
                    showModal={newFolderClick} 
                    handleClose={() => setNewFolderClick(false)} 
                    selectedID = {(selected.length === 0)?null : selected[0]}
                    selectedFolder = {(selectedFolder.folderid)} 
                    setRefresh = {setRefresh}
                    />}
                {(uploadTextFileClick)&& <UploadPopUp 
                showModal = {uploadTextFileClick} 
                handleClose={()=> setUploadTextFileClick(false)}
                selectedID = {(selected.length === 0)?null : selected[0]}
                selectedFolder = {(selectedFolder.folderid)} 
                setRefresh = {setRefresh}/>}
                {(renameSelected) && <RenamePopUp  
                showModal = {renameSelected} 
                selectedID = {selected[0]} 
                setSelectedID = {() => setSelected([]) }
                handleClose={() => setRenameSelected(false)}
                setRefresh = {setRefresh}/>}
                {(deleteSelected) && <DeletePopUp showModal={deleteSelected}
                selectedID = {selected}
                setSelectedID = {()=> setSelected([])}
                handleClose={() => setDeleteSelected(false)}
                setRefresh = {setRefresh}/>}
                {(downloadPopUp) && <ExportPopUp
                showModal={downloadPopUp}
                handleClose={()=>setDownloadPopUp(false)}
                url ={null}
                filename={selected[0]}/> }
            </div>

        </>
    )
}
export default TTSHome;