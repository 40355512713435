import React, { Component } from "react";
import {
  viewMoreArrow,
  rightSideArrowWhite,
  iIconWhite,
  rightArrowBlue,
} from "../../assets";
import "./ContentShortcuts.css";
import ApiServices from "../../utils/apiServices";
import AdminstrationAndDosages from "./AdministrationAndDosagesTile";
import SubmissionRelatedandClinicalSafetyDocumentsTile from "./SubmissionRelatedandClinicalSafetyDocumentsTile";
import EducationandTrainingDocumentsTile from "./EducationandTrainingDocumentsTile";
import KeyMedicalLiteratureDocumentsTile from "./KeyMedicalLiteratureDocumentsTile";
import KeyCongressLiteratureDocumentsTile from "./KeyCongressLiteratureDocumentsTile";
import LabelingDocumentsTile from "./LabelingDocumentsTile";
import ScientificCommunicationDocumentsTile from "./ScientificCommunicationDocumentsTile";
import ClinicalStudiesKnowledgePanelTile from "./ClinicalStudiesKnowledgePanelTile";
import CopyReviewPiecesTile from "./CopyReviewPiecesTile";
import TAandDiseaseDashboardsTile from "./TAandDiseaseDashboardsTile";
import EstablishedProductsTile from "./EstablishedProductsTile";
import MedicalInsightsReportsTile from "./MedicalInsightsReportsTile";

class ContentShortcutLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContent: false,
      showMoreCards: true,
      showInfo: false,
      products: [],
      selectedProduct: "",
      showAnDCardDetails: false,
      showSRnCSCardDetail: false,
      showEnTCardDetail: false,
      showKMLCardDetail: false,
      showKCLCardDetail: false,
      showLDCardDetail: false,
      showSCDCardDetail: false,
      showCRPCardDetail: false,
      showTAnDCardDetail: false,
      showEPCardDetail: false,
      showMIRCardDetail: false,
      // showCardDetails: false,
      // isFiltersShow: true
    };
  }
  async componentDidMount() {
    let service = new ApiServices();
    let wwid = JSON.parse(localStorage.getItem('wwid'));
    let isProductsAvailable;
    let res = await service.getUserProductAndWwid(wwid);
    if (res) {
      isProductsAvailable = res[0].products;
    }
    if (isProductsAvailable !== null && isProductsAvailable?.length > 0) {
      this.setState(
        {
          products: isProductsAvailable.split(";"),
          isLoaded: true,
        },
        () => {
          if (this.state.selectedProduct === "") {
            this.setState({
              selectedProduct: this.state.products[0],
            });
          }
        }
      );
    } else {
      this.setState({
        isLoaded: true,
      });
    }
    if (this.props.customWidth < 600) {
      this.setState({
        showContent: false,
      });
    } else {
      this.setState({
        showContent: true,
      });
    }
    let outer = document.querySelector(".contentShortcutHeader");
    if (this.props.customWidth >= 600) {
      outer.addEventListener("click", (event) => {
        if (event.target.className === "contentShortcutHeader") {
          this.setState({ showContent: !this.state.showContent });
        }
      });
    }
  }
  componentDidUpdate = () => {
    if (
      this.props.showAnDCSModal ||
      this.props.showSRnCSModal ||
      this.props.showEnTCSModal ||
      this.props.showKMLCSModal ||
      this.props.showKCLCSModal ||
      this.props.showLDCSModal ||
      this.props.showSCDCSModal ||
      this.props.showCRPCSModal ||
      this.props.showTAnDCSModal ||
      this.props.showEPCSModal ||
      this.props.showMIRCSModal
    ) {
      if (this.props.customWidth < 1350 && this.props.customWidth >= 1144) {
        document.getElementById("csContainer").style.marginBottom = "95%";
        // document.getElementById('csContainer').style.marginBottom = "120%";
      }
      if (this.props.customWidth < 1144 && this.props.customWidth > 780) {
        document.getElementById("csContainer").style.marginBottom = "155%";
        // document.getElementById('csContainer').style.marginBottom = "270%";
      }
      if (this.props.customWidth < 780 && this.props.customWidth > 600) {
        document.getElementById("csContainer").style.marginBottom = "230%";
      }
      // if (this.props.customWidth < 600 && this.props.customWidth > 550) {
      //     document.getElementById('csContainer').style.marginBottom = "310%";
      //     // document.getElementById('csContainer').style.marginBottom = "450%";
      // }
      // if (this.props.customWidth < 550 && this.props.customWidth > 480) {
      //     document.getElementById('csContainer').style.marginBottom = "375%";
      // }
      // if (this.props.customWidth < 480 && this.props.customWidth > 350) {
      //     document.getElementById('csContainer').style.marginBottom = "555%";
      // }
      // if (this.props.customWidth < 350 && this.props.customWidth > 265) {
      //     document.getElementById('csContainer').style.marginBottom = "890%";
      // }
      // if (this.props.customWidth < 265) {
      //     document.getElementById('csContainer').style.marginBottom = "1500%";
      // }
    }
    // console.log(this.props.customWidth, window.innerHeight)
  };
  onMouseEnterInfo = () => {
    this.setState({
      showInfo: true,
    });
  };
  onMouseLeaveInfo = () => {
    this.setState({
      showInfo: false,
    });
  };
  handelViewMore = () => {
    this.setState({ showMoreCards: true });
  };
  handelAnDCardDetail(isClicked) {
    this.setState({ showAnDCardDetails: isClicked }, () => {
      this.props.handelAnDCardDetailModal(this.state.showAnDCardDetails);
    });
  }
  handelSRnCSCardDetail(isClicked) {
    this.setState({ showSRnCSCardDetail: isClicked }, () => {
      this.props.handelSRnCSCardDetailModal(this.state.showSRnCSCardDetail);
    });
  }
  handelEnTCardDetail(isClicked) {
    this.setState({ showEnTCardDetail: isClicked }, () => {
      this.props.handelEnTCardDetailModal(this.state.showEnTCardDetail);
    });
  }
  handelKMLCardDetail(isClicked) {
    this.setState({ showKMLCardDetail: isClicked }, () => {
      this.props.handelKMLCardDetailModal(this.state.showKMLCardDetail);
    });
  }
  handelKCLCardDetail(isClicked) {
    this.setState({ showKCLCardDetail: isClicked }, () => {
      this.props.handelKCLCardDetailModal(this.state.showKCLCardDetail);
    });
  }
  handelLDCardDetail(isClicked) {
    this.setState({ showLDCardDetail: isClicked }, () => {
      this.props.handelLDCardDetailModal(this.state.showLDCardDetail);
    });
  }
  handelSCDCardDetail(isClicked) {
    this.setState({ showSCDCardDetail: isClicked }, () => {
      this.props.handelSCDCardDetailModal(this.state.showSCDCardDetail);
    });
  }
  handelCRPCardDetail(isClicked) {
    this.setState({ showCRPCardDetail: isClicked }, () => {
      this.props.handelCRPCardDetailModal(this.state.showCRPCardDetail);
    });
  }
  handelTAnDCardDetail(isClicked) {
    this.setState({ showTAnDCardDetail: isClicked }, () => {
      this.props.handelTAnDCardDetailModal(this.state.showTAnDCardDetail);
    });
  }
  handelEPCardDetail(isClicked) {
    this.setState({ showEPCardDetail: isClicked }, () => {
      this.props.handelEPCardDetailModal(this.state.showEPCardDetail);
    });
  }
  handelMIRCardDetail(isClicked) {
    this.setState({ showMIRCardDetail: isClicked }, () => {
      this.props.handelMIRCardDetailModal(this.state.showMIRCardDetail);
    });
  }
  // handelCardDetail(isClicked) {
  //     this.setState({ showCardDetails: isClicked }, () => {
  //         this .props.handelCardDetailModal(this.state.showCardDetails)
  //     })
  // }
  render() {
    if (this.props.customWidth < 600) {
      return (
        <div
          className="contentShortcutWrapper"
          /* id='csContainer' */ style={{
            marginBottom:
              this.props.showAnDCSModal ||
              this.props.showSRnCSModal ||
              this.props.showEnTCSModal ||
              this.props.showKMLCSModal ||
              this.props.showKCLCSModal ||
              this.props.showLDCSModal ||
              this.props.showSCDCSModal ||
              this.props.showCRPCSModal ||
              this.props.showTAnDCSModal ||
              this.props.showEPCSModal ||
              this.props.showMIRCSModal
                ? "85%"
                : null,
          }}
        >
          <div>
            <div
              className="contentShortcutHeader-mobleView"
              onClick={(e) => {
                this.setState({ showContent: !this.state.showContent });
              }}
            >
              <span>
                <div className="wnfy-main-header">
                  Content Shortcuts &nbsp;&nbsp;
                </div>
              </span>
              <div className="headerRightPart-mobileView">
                {this.state.showContent ? (
                  <img
                    src={rightArrowBlue}
                    alt=""
                    width={10}
                    height={10}
                    style={{ transform: "rotate(-90deg)" }}
                    onClick={(e) => {
                      this.setState({ showContent: false });
                    }}
                  />
                ) : (
                  <img
                    src={rightArrowBlue}
                    alt=""
                    width={10}
                    height={10}
                    style={{ transform: "rotate(90deg)" }}
                    onClick={(e) => {
                      this.setState({ showContent: true });
                    }}
                  />
                )}
              </div>
            </div>
            {this.state.showInfo && (
              <div className="info-icon-wrapper">
                <span className="tooltiptext tooltip-bottom">
                  The tiles in this section are designed to provide you with
                  quick "shortcuts" to key collections of content, enabling you
                  to zero in on relevant collections without having to formulate
                  a search strategy to find this content.
                </span>
              </div>
            )}
            {this.state.showContent && (
              <div className="contentShortcutTiles">
                <table className="cs-tileTable">
                  <tbody className="cs-tBody">
                    <tr className="cs-tileRow">
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showSCDCardDetail &&
                            this.props.showSCDCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <ScientificCommunicationDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelSCDCardDetail={(e) =>
                            this.handelSCDCardDetail(e)
                          }
                          showSCDCSModal={this.props.showSCDCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                    </tr>
                    <tr className="cs-tileRow">
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showKMLCardDetail &&
                            this.props.showKMLCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <KeyMedicalLiteratureDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelKMLCardDetail={(e) =>
                            this.handelKMLCardDetail(e)
                          }
                          showKMLCSModal={this.props.showKMLCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                    </tr>
                    <tr className="cs-tileRow">
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showKCLCardDetail &&
                            this.props.showKCLCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <KeyCongressLiteratureDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelKCLCardDetail={(e) =>
                            this.handelKCLCardDetail(e)
                          }
                          showKCLCSModal={this.props.showKCLCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                    </tr>
                    <tr className="cs-tileRow">
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showCRPCardDetail &&
                            this.props.showCRPCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <CopyReviewPiecesTile
                          isFiltersShow={this.props.showFilters}
                          handelCRPCardDetail={(e) =>
                            this.handelCRPCardDetail(e)
                          }
                          showCRPCSModal={this.props.showCRPCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                    </tr>
                    <tr className="cs-tileRow">
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showLDCardDetail &&
                            this.props.showLDCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <LabelingDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelLDCardDetail={(e) => this.handelLDCardDetail(e)}
                          showLDCSModal={this.props.showLDCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                    </tr>
                    <tr className="cs-tileRow">
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showSRnCSCardDetail &&
                            this.props.showSRnCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <SubmissionRelatedandClinicalSafetyDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelSRnCSCardDetail={(e) =>
                            this.handelSRnCSCardDetail(e)
                          }
                          showSRnCSModal={this.props.showSRnCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                    </tr>
                    {this.state.showMoreCards && (
                      <>
                        <tr className="cs-tileRow">
                          <td className="cardOuterSpacing">
                            <ClinicalStudiesKnowledgePanelTile
                              customWidth={this.props.customWidth}
                            />
                          </td>
                        </tr>
                        <tr className="cs-tileRow">
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showAnDCardDetails &&
                                this.props.showAnDCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <AdminstrationAndDosages
                              isFiltersShow={this.props.showFilters}
                              showAnDCSModal={this.props.showAnDCSModal}
                              handelAnDCardDetail={(e) =>
                                this.handelAnDCardDetail(e)
                              }
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                              products={this.state.products}
                              selectedProduct={this.state.selectedProduct}
                            />
                          </td>
                        </tr>
                        <tr className="cs-tileRow">
                          {" "}
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showMIRCardDetail &&
                                this.props.showMIRCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <MedicalInsightsReportsTile
                              isFiltersShow={this.props.showFilters}
                              handelMIRCardDetail={(e) =>
                                this.handelMIRCardDetail(e)
                              }
                              showMIRCSModal={this.props.showMIRCSModal}
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                            />
                          </td>
                        </tr>
                        <tr className="cs-tileRow">
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showTAnDCardDetail &&
                                this.props.showTAnDCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <TAandDiseaseDashboardsTile
                              isFiltersShow={this.props.showFilters}
                              handelTAnDCardDetail={(e) =>
                                this.handelTAnDCardDetail(e)
                              }
                              showTAnDCSModal={this.props.showTAnDCSModal}
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                            />
                          </td>
                        </tr>
                        <tr className="cs-tileRow">
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showEnTCardDetail &&
                                this.props.showEnTCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <EducationandTrainingDocumentsTile
                              isFiltersShow={this.props.showFilters}
                              handelEnTCardDetail={(e) =>
                                this.handelEnTCardDetail(e)
                              }
                              showEnTCSModal={this.props.showEnTCSModal}
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                              products={this.state.products}
                              selectedProduct={this.state.selectedProduct}
                            />
                          </td>
                        </tr>
                        <tr className="cs-tileRow">
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showEPCardDetail &&
                                this.props.showEPCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <EstablishedProductsTile
                              isFiltersShow={this.props.showFilters}
                              handelEPCardDetail={(e) =>
                                this.handelEPCardDetail(e)
                              }
                              showEPCSModal={this.props.showEPCSModal}
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                            />
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                <div
                  className="viewMoreContainer-mobile"
                  style={{ visibility: "hidden" }}
                >
                  <div className="wnfy-footer-section">
                    <span
                      className="wnfy-view-more-header-text"
                      onClick={this.handelViewMore}
                    >
                      View More
                    </span>
                    <img
                      src={viewMoreArrow}
                      alt="right-arrow"
                      width={11}
                      height={14}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else if(this.props.customWidth>=600 && this.props.customWidth<=1000){
      return (
        <div
          className="contentShortcutWrapper"
          id="csContainer"
          style={{
            marginBottom:
              this.props.showAnDCSModal ||
              this.props.showSRnCSModal ||
              this.props.showEnTCSModal ||
              this.props.showKMLCSModal ||
              this.props.showKCLCSModal ||
              this.props.showLDCSModal ||
              this.props.showSCDCSModal ||
              this.props.showCRPCSModal ||
              this.props.showTAnDCSModal ||
              this.props.showEPCSModal ||
              this.props.showMIRCSModal
                ? "85%"
                : null,
          }}
        >
          <div
            className="contentShortcutHeader"
            /*  onClick={e => { this.setState({ showContent: !this.state.showContent }) }} */
          >
            <div className="headerLeftPart">
              <span>
                Content Shortcuts
              </span>
            </div>
            <div className="headerRightPart">
              <div
                className="viewMoreContainer"
                style={{ visibility: "hidden" }}
                onClick={(e) => {
                  this.setState({ showMoreCards: true });
                }}
              >
                <span className="wnfy-view-more-header-text">View More</span>
                <img
                  src={viewMoreArrow}
                  alt="right-arrow"
                  width={11}
                  height={14}
                />
              </div>
              <img
                src={iIconWhite}
                alt="i-icon"
                width={18}
                height={18}
                onMouseEnter={this.onMouseEnterInfo}
                onMouseLeave={this.onMouseLeaveInfo}
              />
              {!this.state.showContent ? (
                <img
                  src={rightSideArrowWhite}
                  alt=""
                  width={11}
                  height={14}
                  style={{ transform: "rotate(90deg)" }}
                  onClick={(e) => {
                    this.setState({ showContent: true });
                  }}
                />
              ) : (
                <img
                  src={rightSideArrowWhite}
                  alt=""
                  width={11}
                  height={14}
                  style={{ transform: "rotate(-90deg)" }}
                  onClick={(e) => {
                    this.setState({ showContent: false });
                  }}
                />
              )}
            </div>
          </div>
          {this.state.showInfo && (
            <div className="info-icon-wrapper">
              <span className="tooltiptext tooltip-bottom">
                The tiles in this section are designed to provide you with quick
                "shortcuts" to key collections of content, enabling you to zero
                in on relevant collections without having to formulate a search
                strategy to find this content.
              </span>
            </div>
          )}
          {this.state.showContent && (
            <div className="contentShortcutTiles">
              {this.props.customWidth < 1000 ? (
                <table className="cs-tileTable">
                  <tbody className="cs-tBody">
                    <tr className="cs-tileRow">
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showSCDCardDetail &&
                            this.props.showSCDCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <ScientificCommunicationDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelSCDCardDetail={(e) =>
                            this.handelSCDCardDetail(e)
                          }
                          showSCDCSModal={this.props.showSCDCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showKMLCardDetail &&
                            this.props.showKMLCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <KeyMedicalLiteratureDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelKMLCardDetail={(e) =>
                            this.handelKMLCardDetail(e)
                          }
                          showKMLCSModal={this.props.showKMLCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                    </tr>
                    <tr className="cs-tileRow">
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showKCLCardDetail &&
                            this.props.showKCLCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <KeyCongressLiteratureDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelKCLCardDetail={(e) =>
                            this.handelKCLCardDetail(e)
                          }
                          showKCLCSModal={this.props.showKCLCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showCRPCardDetail &&
                            this.props.showCRPCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <CopyReviewPiecesTile
                          isFiltersShow={this.props.showFilters}
                          handelCRPCardDetail={(e) =>
                            this.handelCRPCardDetail(e)
                          }
                          showCRPCSModal={this.props.showCRPCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                    </tr>
                    <tr className="cs-tileRow">
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showLDCardDetail &&
                            this.props.showLDCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <LabelingDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelLDCardDetail={(e) => this.handelLDCardDetail(e)}
                          showLDCSModal={this.props.showLDCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showSRnCSCardDetail &&
                            this.props.showSRnCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <SubmissionRelatedandClinicalSafetyDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelSRnCSCardDetail={(e) =>
                            this.handelSRnCSCardDetail(e)
                          }
                          showSRnCSModal={this.props.showSRnCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                    </tr>
                    {this.state.showMoreCards && (
                      <>
                        <tr className="cs-tileRow">
                          <td className="cardOuterSpacing">
                            <ClinicalStudiesKnowledgePanelTile
                              customWidth={this.props.customWidth}
                            />
                          </td>
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showAnDCardDetails &&
                                this.props.showAnDCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <AdminstrationAndDosages
                              isFiltersShow={this.props.showFilters}
                              showAnDCSModal={this.props.showAnDCSModal}
                              handelAnDCardDetail={(e) =>
                                this.handelAnDCardDetail(e)
                              }
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                              products={this.state.products}
                              selectedProduct={this.state.selectedProduct}
                            />
                          </td>
                        </tr>
                        <tr className="cs-tileRow">
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showMIRCardDetail &&
                                this.props.showMIRCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <MedicalInsightsReportsTile
                              isFiltersShow={this.props.showFilters}
                              handelMIRCardDetail={(e) =>
                                this.handelMIRCardDetail(e)
                              }
                              showMIRCSModal={this.props.showMIRCSModal}
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                            />
                          </td>
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showTAnDCardDetail &&
                                this.props.showTAnDCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <TAandDiseaseDashboardsTile
                              isFiltersShow={this.props.showFilters}
                              handelTAnDCardDetail={(e) =>
                                this.handelTAnDCardDetail(e)
                              }
                              showTAnDCSModal={this.props.showTAnDCSModal}
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                            />
                          </td>
                        </tr>
                        <tr className="cs-tileRow">
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showEnTCardDetail &&
                                this.props.showEnTCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <EducationandTrainingDocumentsTile
                              isFiltersShow={this.props.showFilters}
                              handelEnTCardDetail={(e) =>
                                this.handelEnTCardDetail(e)
                              }
                              showEnTCSModal={this.props.showEnTCSModal}
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                              products={this.state.products}
                              selectedProduct={this.state.selectedProduct}
                            />
                          </td>
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showEPCardDetail &&
                                this.props.showEPCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <EstablishedProductsTile
                              isFiltersShow={this.props.showFilters}
                              handelEPCardDetail={(e) =>
                                this.handelEPCardDetail(e)
                              }
                              showEPCSModal={this.props.showEPCSModal}
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                            />
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              ) : (
                <table className="cs-tileTable">
                  <tbody className="cs-tBody">
                    <tr className="cs-tileRow">
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showSCDCardDetail &&
                            this.props.showSCDCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <ScientificCommunicationDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelSCDCardDetail={(e) =>
                            this.handelSCDCardDetail(e)
                          }
                          showSCDCSModal={this.props.showSCDCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showLDCardDetail &&
                            this.props.showLDCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <LabelingDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelLDCardDetail={(e) => this.handelLDCardDetail(e)}
                          showLDCSModal={this.props.showLDCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showMIRCardDetail &&
                            this.props.showMIRCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <MedicalInsightsReportsTile
                          isFiltersShow={this.props.showFilters}
                          handelMIRCardDetail={(e) =>
                            this.handelMIRCardDetail(e)
                          }
                          showMIRCSModal={this.props.showMIRCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                        />
                      </td>
                    </tr>
                    <tr className="cs-tileRow">
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showKMLCardDetail &&
                            this.props.showKMLCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <KeyMedicalLiteratureDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelKMLCardDetail={(e) =>
                            this.handelKMLCardDetail(e)
                          }
                          showKMLCSModal={this.props.showKMLCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showSRnCSCardDetail &&
                            this.props.showSRnCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <SubmissionRelatedandClinicalSafetyDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelSRnCSCardDetail={(e) =>
                            this.handelSRnCSCardDetail(e)
                          }
                          showSRnCSModal={this.props.showSRnCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showTAnDCardDetail &&
                            this.props.showTAnDCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <TAandDiseaseDashboardsTile
                          isFiltersShow={this.props.showFilters}
                          handelTAnDCardDetail={(e) =>
                            this.handelTAnDCardDetail(e)
                          }
                          showTAnDCSModal={this.props.showTAnDCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                        />
                      </td>
                    </tr>
                    {this.state.showMoreCards && (
                      <>
                        <tr className="cs-tileRow">
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showKCLCardDetail &&
                                this.props.showKCLCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <KeyCongressLiteratureDocumentsTile
                              isFiltersShow={this.props.showFilters}
                              handelKCLCardDetail={(e) =>
                                this.handelKCLCardDetail(e)
                              }
                              showKCLCSModal={this.props.showKCLCSModal}
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                              products={this.state.products}
                              selectedProduct={this.state.selectedProduct}
                            />
                          </td>
                          <td className="cardOuterSpacing">
                            <ClinicalStudiesKnowledgePanelTile
                              customWidth={this.props.customWidth}
                            />
                          </td>
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showEnTCardDetail &&
                                this.props.showEnTCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <EducationandTrainingDocumentsTile
                              isFiltersShow={this.props.showFilters}
                              handelEnTCardDetail={(e) =>
                                this.handelEnTCardDetail(e)
                              }
                              showEnTCSModal={this.props.showEnTCSModal}
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                              products={this.state.products}
                              selectedProduct={this.state.selectedProduct}
                            />
                          </td>
                        </tr>
                        <tr className="cs-tileRow">
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showCRPCardDetail &&
                                this.props.showCRPCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <CopyReviewPiecesTile
                              isFiltersShow={this.props.showFilters}
                              handelCRPCardDetail={(e) =>
                                this.handelCRPCardDetail(e)
                              }
                              showCRPCSModal={this.props.showCRPCSModal}
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                              products={this.state.products}
                              selectedProduct={this.state.selectedProduct}
                            />
                          </td>
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showAnDCardDetails &&
                                this.props.showAnDCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <AdminstrationAndDosages
                              isFiltersShow={this.props.showFilters}
                              showAnDCSModal={this.props.showAnDCSModal}
                              handelAnDCardDetail={(e) =>
                                this.handelAnDCardDetail(e)
                              }
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                              products={this.state.products}
                              selectedProduct={this.state.selectedProduct}
                            />
                          </td>
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showEPCardDetail &&
                                this.props.showEPCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <EstablishedProductsTile
                              isFiltersShow={this.props.showFilters}
                              handelEPCardDetail={(e) =>
                                this.handelEPCardDetail(e)
                              }
                              showEPCSModal={this.props.showEPCSModal}
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                            />
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              )}
              {/* <table className='cs-tileTable'>
                            <tbody className='cs-tBody'>
                                <tr className='cs-tileRow'>
                                    <td className='cardOuterSpacing' style={{zIndex: (this.state.showAnDCardDetails && this.props.showAnDCSModal) ? "10000" : null}}>
                                        <AdminstrationAndDosages
                                        isFiltersShow={this.props.showFilters}
                                        showAnDCSModal={this.props.showAnDCSModal}
                                        handelAnDCardDetail={(e)=>this.handelAnDCardDetail(e)}
                                        />
                                    </td>
                                    <td className='cardOuterSpacing' style={{zIndex: (this.state.showSRnCSCardDetail && this.props.showSRnCSModal) ? "10000" : null}}>
                                        <SubmissionRelatedandClinicalSafetyDocumentsTile isFiltersShow={this.props.showFilters} handelSRnCSCardDetail={(e)=>this.handelSRnCSCardDetail(e)} showSRnCSModal={this.props.showSRnCSModal} />
                                    </td>
                                    <td className='cardOuterSpacing' style={{zIndex: (this.state.showEnTCardDetail && this.props.showEnTCSModal) ? "10000" : null}}>
                                        <EducationandTrainingDocumentsTile isFiltersShow={this.props.showFilters} handelEnTCardDetail={(e)=>this.handelEnTCardDetail(e)} showEnTCSModal={this.props.showEnTCSModal} />
                                    </td>
                                </tr>
                                <tr className='cs-tileRow'>
                                    <td className='cardOuterSpacing' style={{zIndex: (this.state.showKMLCardDetail && this.props.showKMLCSModal) ? "10000" : null}}>
                                        <KeyMedicalLiteratureDocumentsTile isFiltersShow={this.props.showFilters} handelKMLCardDetail={(e)=>this.handelKMLCardDetail(e)} showKMLCSModal={this.props.showKMLCSModal} />
                                    </td>
                                    <td className='cardOuterSpacing' style={{zIndex: (this.state.showKCLCardDetail && this.props.showKCLCSModal) ? "10000" : null}}>
                                        <KeyCongressLiteratureDocumentsTile isFiltersShow={this.props.showFilters} handelKCLCardDetail={(e)=>this.handelKCLCardDetail(e)} showKCLCSModal={this.props.showKCLCSModal} />
                                    </td>
                                    <td className='cardOuterSpacing' style={{zIndex: (this.state.showLDCardDetail && this.props.showLDCSModal) ? "10000" : null}}>
                                        <LabelingDocumentsTile isFiltersShow={this.props.showFilters} handelLDCardDetail={(e)=>this.handelLDCardDetail(e)} showLDCSModal={this.props.showLDCSModal} />
                                    </td>
                                </tr>
                                {this.state.showMoreCards &&
                                    <>
                                        <tr className='cs-tileRow'>
                                            <td className='cardOuterSpacing' style={{zIndex: (this.state.showSCDCardDetail && this.props.showSCDCSModal) ? "10000" : null}}>
                                                <ScientificCommunicationDocumentsTile isFiltersShow={this.props.showFilters} handelSCDCardDetail={(e)=>this.handelSCDCardDetail(e)} showSCDCSModal={this.props.showSCDCSModal} />
                                            </td>
                                            <td className='cardOuterSpacing'>
                                                <ClinicalStudiesKnowledgePanelTile />
                                            </td>
                                            <td className='cardOuterSpacing' style={{zIndex: (this.state.showCRPCardDetail && this.props.showCRPCSModal) ? "10000" : null}}>
                                                <CopyReviewPiecesTile isFiltersShow={this.props.showFilters} handelCRPCardDetail={(e)=>this.handelCRPCardDetail(e)} showCRPCSModal={this.props.showCRPCSModal} />
                                            </td>
                                        </tr>
                                        <tr className='cs-tileRow'>
                                            <td className='cardOuterSpacing' style={{zIndex: (this.state.showTAnDCardDetail && this.props.showTAnDCSModal) ? "10000" : null}}>
                                                <TAandDiseaseDashboardsTile isFiltersShow={this.props.showFilters} handelTAnDCardDetail={(e)=>this.handelTAnDCardDetail(e)} showTAnDCSModal={this.props.showTAnDCSModal} />
                                            </td>
                                            <td className='cardOuterSpacing'>
                                                <EstablishedProductsTile />
                                            </td>
                                            <td className='cardOuterSpacing'></td>
                                        </tr>
                                    </>
                                }
                            </tbody>
                        </table> */}

              {/* {this.state.showCardDetails && <ContentShortcutsPopUp />} */}
              {/* {this.state.showCardDetails} */}
              {/* {
                            this.state.showCardDetails &&
                            <div className='cardContainer'>
                                <div className='cs-detailsCard'></div>
                            </div>
                        } */}
            </div>
          )}
        </div>
      );
    } else if(this.props.customWidth>1000) {
      return (
        <div
          className="contentShortcutWrapper-desktop"
          id="csContainer"
          style={{
            marginBottom:
              this.props.showAnDCSModal ||
              this.props.showSRnCSModal ||
              this.props.showEnTCSModal ||
              this.props.showKMLCSModal ||
              this.props.showKCLCSModal ||
              this.props.showLDCSModal ||
              this.props.showSCDCSModal ||
              this.props.showCRPCSModal ||
              this.props.showTAnDCSModal ||
              this.props.showEPCSModal ||
              this.props.showMIRCSModal
                ? "85%"
                : null,
          }}
        >
          <div
            className="contentShortcutHeader" 
            style={{backgroundColor:"#2a498a",color:"white",fontSize:"18px"}}
            /*  onClick={e => { this.setState({ showContent: !this.state.showContent }) }} */
          >
            <div className="headerRightPart ContentShortcutsRightPart" style={{visibility:"hidden"}}>
              <img
                src={iIconWhite}
                alt="i-icon"
                width={18}
                height={18}
                onMouseEnter={this.onMouseEnterInfo}
                onMouseLeave={this.onMouseLeaveInfo}
              />
            </div>
            Content Shortcuts
            <div className="headerRightPart ContentShortcutsRightPart">
              {/* <div
                className="viewMoreContainer"
                style={{ visibility: "hidden" }}
                onClick={(e) => {
                  this.setState({ showMoreCards: true });
                }}
              >
                <span className="wnfy-view-more-header-text">View More</span>
                <img
                  src={viewMoreArrow}
                  alt="right-arrow"
                  width={11}
                  height={14}
                />
              </div> */}
              <img
                src={iIconWhite}
                alt="i-icon"
                width={18}
                height={18}
                onMouseEnter={this.onMouseEnterInfo}
                onMouseLeave={this.onMouseLeaveInfo}
              />
               {/* {!this.state.showContent ? (
                <img
                  src={rightSideArrowWhite}
                  alt=""
                  width={11}
                  height={14}
                  style={{ transform: "rotate(90deg)" }}
                  onClick={(e) => {
                    this.setState({ showContent: true });
                  }}
                />
              ) : (
                <img
                  src={rightSideArrowWhite}
                  alt=""
                  width={11}
                  height={14}
                  style={{ transform: "rotate(-90deg)" }}
                  onClick={(e) => {
                    this.setState({ showContent: false });
                  }}
                />
              )}  */}
            </div>
          </div>
          {this.state.showInfo && (
            <div className="info-icon-wrapper">
              <span className="tooltiptext tooltip-bottom">
                The tiles in this section are designed to provide you with quick
                "shortcuts" to key collections of content, enabling you to zero
                in on relevant collections without having to formulate a search
                strategy to find this content.
              </span>
            </div>
          )}
          {this.state.showContent && (
            <div className="contentShortcutTiles">
              {this.props.customWidth < 1000 ? (
                <table className="cs-tileTable">
                  <tbody className="cs-tBody">
                    <tr className="cs-tileRow">
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showSCDCardDetail &&
                            this.props.showSCDCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <ScientificCommunicationDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelSCDCardDetail={(e) =>
                            this.handelSCDCardDetail(e)
                          }
                          showSCDCSModal={this.props.showSCDCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showKMLCardDetail &&
                            this.props.showKMLCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <KeyMedicalLiteratureDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelKMLCardDetail={(e) =>
                            this.handelKMLCardDetail(e)
                          }
                          showKMLCSModal={this.props.showKMLCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                    </tr>
                    <tr className="cs-tileRow">
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showKCLCardDetail &&
                            this.props.showKCLCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <KeyCongressLiteratureDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelKCLCardDetail={(e) =>
                            this.handelKCLCardDetail(e)
                          }
                          showKCLCSModal={this.props.showKCLCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showCRPCardDetail &&
                            this.props.showCRPCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <CopyReviewPiecesTile
                          isFiltersShow={this.props.showFilters}
                          handelCRPCardDetail={(e) =>
                            this.handelCRPCardDetail(e)
                          }
                          showCRPCSModal={this.props.showCRPCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                    </tr>
                    <tr className="cs-tileRow">
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showLDCardDetail &&
                            this.props.showLDCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <LabelingDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelLDCardDetail={(e) => this.handelLDCardDetail(e)}
                          showLDCSModal={this.props.showLDCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showSRnCSCardDetail &&
                            this.props.showSRnCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <SubmissionRelatedandClinicalSafetyDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelSRnCSCardDetail={(e) =>
                            this.handelSRnCSCardDetail(e)
                          }
                          showSRnCSModal={this.props.showSRnCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                    </tr>
                    {this.state.showMoreCards && (
                      <>
                        <tr className="cs-tileRow">
                          <td className="cardOuterSpacing">
                            <ClinicalStudiesKnowledgePanelTile customWidth={this.props.customWidth}
                          customHeight={this.props.height} />
                          </td>
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showAnDCardDetails &&
                                this.props.showAnDCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <AdminstrationAndDosages
                              isFiltersShow={this.props.showFilters}
                              showAnDCSModal={this.props.showAnDCSModal}
                              handelAnDCardDetail={(e) =>
                                this.handelAnDCardDetail(e)
                              }
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                              products={this.state.products}
                              selectedProduct={this.state.selectedProduct}
                            />
                          </td>
                        </tr>
                        <tr className="cs-tileRow">
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showMIRCardDetail &&
                                this.props.showMIRCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <MedicalInsightsReportsTile
                              isFiltersShow={this.props.showFilters}
                              handelMIRCardDetail={(e) =>
                                this.handelMIRCardDetail(e)
                              }
                              showMIRCSModal={this.props.showMIRCSModal}
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                            />
                          </td>
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showTAnDCardDetail &&
                                this.props.showTAnDCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <TAandDiseaseDashboardsTile
                              isFiltersShow={this.props.showFilters}
                              handelTAnDCardDetail={(e) =>
                                this.handelTAnDCardDetail(e)
                              }
                              showTAnDCSModal={this.props.showTAnDCSModal}
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                            />
                          </td>
                        </tr>
                        <tr className="cs-tileRow">
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showEnTCardDetail &&
                                this.props.showEnTCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <EducationandTrainingDocumentsTile
                              isFiltersShow={this.props.showFilters}
                              handelEnTCardDetail={(e) =>
                                this.handelEnTCardDetail(e)
                              }
                              showEnTCSModal={this.props.showEnTCSModal}
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                              products={this.state.products}
                              selectedProduct={this.state.selectedProduct}
                            />
                          </td>
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showEPCardDetail &&
                                this.props.showEPCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <EstablishedProductsTile
                              isFiltersShow={this.props.showFilters}
                              handelEPCardDetail={(e) =>
                                this.handelEPCardDetail(e)
                              }
                              showEPCSModal={this.props.showEPCSModal}
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                            />
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              ) : (
                <table className="cs-tileTable">
                  <tbody className="cs-tBody">
                    <tr className="cs-tileRow">
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showSCDCardDetail &&
                            this.props.showSCDCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <ScientificCommunicationDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelSCDCardDetail={(e) =>
                            this.handelSCDCardDetail(e)
                          }
                          showSCDCSModal={this.props.showSCDCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showLDCardDetail &&
                            this.props.showLDCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <LabelingDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelLDCardDetail={(e) => this.handelLDCardDetail(e)}
                          showLDCSModal={this.props.showLDCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showMIRCardDetail &&
                            this.props.showMIRCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <MedicalInsightsReportsTile
                          isFiltersShow={this.props.showFilters}
                          handelMIRCardDetail={(e) =>
                            this.handelMIRCardDetail(e)
                          }
                          showMIRCSModal={this.props.showMIRCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                        />
                      </td>
                    </tr>
                    <tr className="cs-tileRow">
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showKMLCardDetail &&
                            this.props.showKMLCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <KeyMedicalLiteratureDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelKMLCardDetail={(e) =>
                            this.handelKMLCardDetail(e)
                          }
                          showKMLCSModal={this.props.showKMLCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showSRnCSCardDetail &&
                            this.props.showSRnCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <SubmissionRelatedandClinicalSafetyDocumentsTile
                          isFiltersShow={this.props.showFilters}
                          handelSRnCSCardDetail={(e) =>
                            this.handelSRnCSCardDetail(e)
                          }
                          showSRnCSModal={this.props.showSRnCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                          products={this.state.products}
                          selectedProduct={this.state.selectedProduct}
                        />
                      </td>
                      <td
                        className="cardOuterSpacing"
                        style={{
                          zIndex:
                            this.state.showTAnDCardDetail &&
                            this.props.showTAnDCSModal
                              ? "10000"
                              : null,
                        }}
                      >
                        <TAandDiseaseDashboardsTile
                          isFiltersShow={this.props.showFilters}
                          handelTAnDCardDetail={(e) =>
                            this.handelTAnDCardDetail(e)
                          }
                          showTAnDCSModal={this.props.showTAnDCSModal}
                          customWidth={this.props.customWidth}
                          customHeight={this.props.height}
                        />
                      </td>
                    </tr>
                    {this.state.showMoreCards && (
                      <>
                        <tr className="cs-tileRow">
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showKCLCardDetail &&
                                this.props.showKCLCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <KeyCongressLiteratureDocumentsTile
                              isFiltersShow={this.props.showFilters}
                              handelKCLCardDetail={(e) =>
                                this.handelKCLCardDetail(e)
                              }
                              showKCLCSModal={this.props.showKCLCSModal}
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                              products={this.state.products}
                              selectedProduct={this.state.selectedProduct}
                            />
                          </td>
                          <td className="cardOuterSpacing">
                            <ClinicalStudiesKnowledgePanelTile customWidth={this.props.customWidth}
                          customHeight={this.props.height} />
                          </td>
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showEnTCardDetail &&
                                this.props.showEnTCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <EducationandTrainingDocumentsTile
                              isFiltersShow={this.props.showFilters}
                              handelEnTCardDetail={(e) =>
                                this.handelEnTCardDetail(e)
                              }
                              showEnTCSModal={this.props.showEnTCSModal}
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                              products={this.state.products}
                              selectedProduct={this.state.selectedProduct}
                            />
                          </td>
                        </tr>
                        <tr className="cs-tileRow">
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showCRPCardDetail &&
                                this.props.showCRPCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <CopyReviewPiecesTile
                              isFiltersShow={this.props.showFilters}
                              handelCRPCardDetail={(e) =>
                                this.handelCRPCardDetail(e)
                              }
                              showCRPCSModal={this.props.showCRPCSModal}
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                              products={this.state.products}
                              selectedProduct={this.state.selectedProduct}
                            />
                          </td>
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showAnDCardDetails &&
                                this.props.showAnDCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <AdminstrationAndDosages
                              isFiltersShow={this.props.showFilters}
                              showAnDCSModal={this.props.showAnDCSModal}
                              handelAnDCardDetail={(e) =>
                                this.handelAnDCardDetail(e)
                              }
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                              products={this.state.products}
                              selectedProduct={this.state.selectedProduct}
                            />
                          </td>
                          <td
                            className="cardOuterSpacing"
                            style={{
                              zIndex:
                                this.state.showEPCardDetail &&
                                this.props.showEPCSModal
                                  ? "10000"
                                  : null,
                            }}
                          >
                            <EstablishedProductsTile
                              isFiltersShow={this.props.showFilters}
                              handelEPCardDetail={(e) =>
                                this.handelEPCardDetail(e)
                              }
                              showEPCSModal={this.props.showEPCSModal}
                              customWidth={this.props.customWidth}
                              customHeight={this.props.height}
                            />
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              )}
              {/* <table className='cs-tileTable'>
                            <tbody className='cs-tBody'>
                                <tr className='cs-tileRow'>
                                    <td className='cardOuterSpacing' style={{zIndex: (this.state.showAnDCardDetails && this.props.showAnDCSModal) ? "10000" : null}}>
                                        <AdminstrationAndDosages
                                        isFiltersShow={this.props.showFilters}
                                        showAnDCSModal={this.props.showAnDCSModal}
                                        handelAnDCardDetail={(e)=>this.handelAnDCardDetail(e)}
                                        />
                                    </td>
                                    <td className='cardOuterSpacing' style={{zIndex: (this.state.showSRnCSCardDetail && this.props.showSRnCSModal) ? "10000" : null}}>
                                        <SubmissionRelatedandClinicalSafetyDocumentsTile isFiltersShow={this.props.showFilters} handelSRnCSCardDetail={(e)=>this.handelSRnCSCardDetail(e)} showSRnCSModal={this.props.showSRnCSModal} />
                                    </td>
                                    <td className='cardOuterSpacing' style={{zIndex: (this.state.showEnTCardDetail && this.props.showEnTCSModal) ? "10000" : null}}>
                                        <EducationandTrainingDocumentsTile isFiltersShow={this.props.showFilters} handelEnTCardDetail={(e)=>this.handelEnTCardDetail(e)} showEnTCSModal={this.props.showEnTCSModal} />
                                    </td>
                                </tr>
                                <tr className='cs-tileRow'>
                                    <td className='cardOuterSpacing' style={{zIndex: (this.state.showKMLCardDetail && this.props.showKMLCSModal) ? "10000" : null}}>
                                        <KeyMedicalLiteratureDocumentsTile isFiltersShow={this.props.showFilters} handelKMLCardDetail={(e)=>this.handelKMLCardDetail(e)} showKMLCSModal={this.props.showKMLCSModal} />
                                    </td>
                                    <td className='cardOuterSpacing' style={{zIndex: (this.state.showKCLCardDetail && this.props.showKCLCSModal) ? "10000" : null}}>
                                        <KeyCongressLiteratureDocumentsTile isFiltersShow={this.props.showFilters} handelKCLCardDetail={(e)=>this.handelKCLCardDetail(e)} showKCLCSModal={this.props.showKCLCSModal} />
                                    </td>
                                    <td className='cardOuterSpacing' style={{zIndex: (this.state.showLDCardDetail && this.props.showLDCSModal) ? "10000" : null}}>
                                        <LabelingDocumentsTile isFiltersShow={this.props.showFilters} handelLDCardDetail={(e)=>this.handelLDCardDetail(e)} showLDCSModal={this.props.showLDCSModal} />
                                    </td>
                                </tr>
                                {this.state.showMoreCards &&
                                    <>
                                        <tr className='cs-tileRow'>
                                            <td className='cardOuterSpacing' style={{zIndex: (this.state.showSCDCardDetail && this.props.showSCDCSModal) ? "10000" : null}}>
                                                <ScientificCommunicationDocumentsTile isFiltersShow={this.props.showFilters} handelSCDCardDetail={(e)=>this.handelSCDCardDetail(e)} showSCDCSModal={this.props.showSCDCSModal} />
                                            </td>
                                            <td className='cardOuterSpacing'>
                                                <ClinicalStudiesKnowledgePanelTile />
                                            </td>
                                            <td className='cardOuterSpacing' style={{zIndex: (this.state.showCRPCardDetail && this.props.showCRPCSModal) ? "10000" : null}}>
                                                <CopyReviewPiecesTile isFiltersShow={this.props.showFilters} handelCRPCardDetail={(e)=>this.handelCRPCardDetail(e)} showCRPCSModal={this.props.showCRPCSModal} />
                                            </td>
                                        </tr>
                                        <tr className='cs-tileRow'>
                                            <td className='cardOuterSpacing' style={{zIndex: (this.state.showTAnDCardDetail && this.props.showTAnDCSModal) ? "10000" : null}}>
                                                <TAandDiseaseDashboardsTile isFiltersShow={this.props.showFilters} handelTAnDCardDetail={(e)=>this.handelTAnDCardDetail(e)} showTAnDCSModal={this.props.showTAnDCSModal} />
                                            </td>
                                            <td className='cardOuterSpacing'>
                                                <EstablishedProductsTile />
                                            </td>
                                            <td className='cardOuterSpacing'></td>
                                        </tr>
                                    </>
                                }
                            </tbody>
                        </table> */}

              {/* {this.state.showCardDetails && <ContentShortcutsPopUp />} */}
              {/* {this.state.showCardDetails} */}
              {/* {
                            this.state.showCardDetails &&
                            <div className='cardContainer'>
                                <div className='cs-detailsCard'></div>
                            </div>
                        } */}
            </div>
          )}
        </div>
      );
    }
  }
}

export default ContentShortcutLayout;
