import { Component } from "react";
import { imsLoader } from '../../assets'
import ApiServices from '../../utils/apiServices';
import Header from "../../components/header/Header";
import Footer from '../../components/footer/Footer';

class HtmlPreview extends Component {
    // userid;
    // userExists;
    expiredLinkHtml = "<div style='margin-top:20px'><h4>Link you visited is expired.</h4><p>Shared link in iMS is available for 60 days.</p></div></div>"
    expiryDate;
    constructor(props) {
        super(props);
        this.state = {
            // userExists: false,
            width: window.innerWidth,
            height: window.innerHeight,
            previewData: ``,
            apiResponse: 0,
            isExpired: false
        }
    }
    async componentWillMount() {
        let query = new URLSearchParams(this.props.location.search);
        let id = query.get('id')
        let service = new ApiServices();
        let res = await service.getEmailDocument(id);
        // console.log(res, id)
        // console.log(res)
        let combineArray = [];
        for (let i = 0; i < res.length; i++) {
            // combineArray.push(res[i].htmldocument.data)
            combineArray = combineArray.concat(res[i].htmldocument.data)
            // console.log(res[i].htmldocument.data)
        }
        // console.log(combineArray)
        const byteArray = combineArray
        // const byteArray = res[0].htmldocument.data
        // let expiryDate = new Date(new Date().setMonth(new Date().getMonth() + 2))
        var date = new Date(res[0]?.date_added.split('_')[0]);
        var newDate = new Date(date.setMonth(date.getMonth()));
        // var newDate = new Date(date.setMonth(date.getMonth() + 1));
        // this.expiryDate=new Date(new Date(newDate).setDate(new Date(newDate).getDate() + 2))
        this.expiryDate = new Date(new Date(newDate).setMonth(new Date(newDate).getMonth() + 2))
        // this.setState({apiResponse: res.length})
        if(res.length <= 0){
            this.setState({ previewData: this.expiredLinkHtml, isExpired:true })
        }
        for (let i = 0; i < res.length; i++) {
            // const byteArray = res[i].htmldocument.data
            // CHECK IF LINK IS EXPIRED OR NOT
            // if(res[0].date_added.split('_')[0] === this.generateDownloadTimestamp(expiryDate)){
            // if(res[i].date_added.split('_')[0] >= this.generateDownloadTimestamp(expiryDate)){
            if (new Date() >= this.expiryDate) {
                this.setState({ previewData: this.expiredLinkHtml })
            }
            // BYTE ARRAY TO SCRING CONVERSION
            // else if (res[0].htmldocument.data.length > 0) {
            else if (res[i].htmldocument.data.length > 0) {
                function arrayBufferToString(buffer) {
                    var byteArray = new Uint8Array(buffer);
                    var str = "", cc = 0, numBytes = 0;
                    for (var i = 0, len = byteArray.length; i < len; ++i) {
                        var v = byteArray[i];
                        if (numBytes > 0) {
                            //2 bit determining that this is a tailing byte + 6 bit of payload
                            if ((cc & 192) === 192) {
                                //processing tailing-bytes
                                cc = (cc << 6) | (v & 63);
                                // }else{
                                //     throw new Error("this is no tailing-byte");
                            }
                        } else if (v < 128) {
                            //single-byte
                            numBytes = 1;
                            cc = v;
                            // }else if(v < 192){
                            //     //these are tailing-bytes
                            //     throw new Error("invalid byte, this is a tailing-byte")
                        } else if (v < 224) {
                            //3 bits of header + 5bits of payload
                            numBytes = 2;
                            cc = v & 31;
                        } else if (v < 240) {
                            //4 bits of header + 4bit of payload
                            numBytes = 3;
                            cc = v & 15;
                            // } else {
                            //     //UTF-8 theoretically supports up to 8 bytes containing up to 42bit of payload
                            //     //but JS can only handle 16bit.
                            //     throw new Error("invalid encoding, value out of range")
                        }

                        if (--numBytes === 0) {
                            str += String.fromCharCode(cc);
                        }
                    }
                    if (numBytes) {
                        throw new Error("the bytes don't sum up");
                    }
                    return str;
                }
                let byteArrayToString = arrayBufferToString(byteArray)
                // var uint8array = new TextEncoder("utf-8").encode(byteArrayToString);
                // var string1 = new TextDecoder().decode(uint8array);
                let stringToArray = byteArrayToString.split(", 0,")
                let arrayToInt = stringToArray.map(i => parseInt(i))
                // console.log(byteArrayToString,uint8array ,string1,stringToArray, arrayToInt)
                // console.log(arrayBufferToString(arrayToInt).replace(/\\"/g, '"') )

                this.setState({ previewData: arrayBufferToString(arrayToInt).replace(/\\"/g, '"') })
            }
        }


        // document.title = 'iMS Email';
        // this.userid = JSON.parse(localStorage.getItem('username'));
        // this.userExists = localStorage.getItem('userExists');
        // let service = new Services();
        // service.logService(`Html iMS Email page successfully mounted for ${this.userid.toLowerCase()}`, 'info')
        // if (this.userExists) {
        //     this.setState({
        //         userExists: this.userExists
        //     })
        //     window.addEventListener('resize', this.updateDimensions);
        // }
        // else {
        //     console.log('Access Denied');
        //     this.props.history.push('/');
        // }
    }
    // componentWillUnmount() {
    //     let service = new Services();
    //     window.removeEventListener('resize', this.updateDimensions);
    //     service.logService(`Html iMS Email page successfully unmounted for ${this.userid.toLowerCase()}`, 'info')
    // }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight })
    }
    generateDownloadTimestamp = (now) => {
        // const now = new Date()
        return now.getFullYear() + "-" + (now.getMonth() + 1).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        }) + "-" + now.getDate() //+ "_" + now.getHours() + "-" + now.getMinutes() + "-" + now.getSeconds()
    }
    render() {
        if (new Date() >= this.expiryDate || this.state.isExpired) {
            return (
                <div className='download-results-page-wrapper'><Header />
                    <div> <div dangerouslySetInnerHTML={{ __html: this.state.previewData }}></div></div>
                    {/* <div> <div dangerouslySetInnerHTML={{ __html: this.expiredLinkHtml }}></div></div> */}
                    <div style={{ margin: "0 1.5%", background: "#ffffff" }} class="fixed-bottom">
                        <Footer customWidth={this.state.width} customHeight={this.state.height} />
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    {
                        this.state.previewData.length > 0 ?
                            <div dangerouslySetInnerHTML={{ __html: this.state.previewData }}></div>
                            :
                            <div style={{ textAlign: "center", padding: "10%" }}>
                                <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
                            </div>
                    }
                </div>
            )
        }
    }
}

export default HtmlPreview;