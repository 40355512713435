import { Modal } from "react-bootstrap";
import { useState } from "react";
import Button from "../Button";

const convertToHtml = (data) => {
    return <div dangerouslySetInnerHTML={{ __html: data }}></div>
}
export default function ConfirmMessagePopUpModal(props) {
    const {
        content,
        showModal,
        handleClose,
        handleConfirm,
        btnText
    } = props

    const [showPop, setShow] = useState(showModal)
    return (
        <Modal
            size="md sm xs"
            show={showPop}
            keyboard={false}
            dialogClassName="popTimeOut"
            className="z-index-alertMessage"
            backdropClassName="z-index-alertMessage-backdrop"
            key={`ConfirmMessage${showModal}`}
        >
            <div style={{ borderLeft: `5px solid #0074b0` }}>
                <Modal.Body>
                    <div>
                        {convertToHtml(content)}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button text={btnText ? btnText : "OK"} customColor={`#0074b0`} action={() => { setShow(false); handleConfirm(false) }} />
                    <Button
                        text="Cancel"
                        customColor="#ff8000"
                        action={() => { setShow(false); handleClose(false) }}
                    />
                </Modal.Footer>
            </div>
        </Modal>
    )
}