import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { footerLogo } from '../../assets';
import PrivacyStatementPopUp from '../PopUp/PrivacyStatementPopUp';
import './Footer.css';
import moment from "moment";

export default function Footer(props) {
    const { customWidth } = props;
    const [showPrivacy, setPrivacy] = useState(false)
    const currentYear = moment().year();
    if (customWidth < 870) {
        return (
            <div className="footercontent-mobile-wrapper">
                <div className="footerImg-mobile">
                    <img src={footerLogo} alt="iMedical Search" />
                </div>
                <div className="footerLinks footerLinks-mobile"><Link to="/">Home</Link> | <Link to="/Get-Help" target="_blank">Get Help</Link> | <Link to="/Contact-Us" target="_blank">Contact Us</Link> | <span className="footer-privacy-text" onClick={e => { setPrivacy(true) }}>Privacy Policy</span>
                </div>
                <div className="footerText footerText-mobile">
                    Copyright 2007 - {currentYear} iMedical Search. All Rights Reserved.
                </div>
                {showPrivacy &&
                    <PrivacyStatementPopUp display={showPrivacy} handleClose={(status) => { setPrivacy(status) }} />
                }
            </div>
        )
    }
    else if (customWidth >= 870 && customWidth <= 1000) {
        return (
            <div className="footercontent footercontent-tablet-wrapper">
                <span className="footerImg">
                    <img src={footerLogo} alt="iMedical Search" />
                </span>
                <span className="footerText footerText-tablet-wrapper">
                    Copyright 2007 - {currentYear} iMedical Search. All Rights Reserved.
                </span>
                <span className="footerLinks footerLinks-tablet-wrapper"><Link to="/">Home</Link> | <Link to="/Get-Help" target="_blank">Get Help</Link> | <Link to="/Contact-Us" target="_blank">Contact Us</Link> | <span className="footer-privacy-text" onClick={e => { setPrivacy(true) }}>Privacy Policy</span></span>
                {showPrivacy &&
                    <PrivacyStatementPopUp display={showPrivacy} handleClose={(status) => { setPrivacy(status) }} />
                }
            </div>
        )
    }
    else {
        return (
            <div className="footercontent">
                <span className="footerImg">
                    <img src={footerLogo} alt="iMedical Search" />
                </span>
                <span className="footerLinks"><Link to="/">Home</Link> | <Link to="/Get-Help" target="_blank">Get Help</Link> | <Link to="/Contact-Us" target="_blank">Contact Us</Link> | <span className="footer-privacy-text" onClick={e => { setPrivacy(true) }}>Privacy Policy</span></span>
                <span className="footerText">
                    <div className="footerText1"> Copyright 2007 - {currentYear} iMedical Search. All Rights Reserved. <br /></div>
                </span>
                {showPrivacy &&
                    <PrivacyStatementPopUp display={showPrivacy} handleClose={(status) => { setPrivacy(status) }} />
                }
            </div>
        )
    }
}