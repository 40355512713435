import './HomeTilesProductWrapper.css';
import { useState } from 'react';
import { ActionsArrowIcon } from '../../assets';
import GlobalFeatures from '../GlobalSearchFeatures/GlobalFeatures';

export default function HomeTilesProductWrapper(props){
    const {
        customWidth,
        products,
        handleSelectProduct,
        selectedProduct,
        globalCollapseAll,
        globalSelectAll
    } = props;
    const [showActionItems, setShowActionItems] = useState(false);
    const [showProducts, setShowProducts] = useState(false);
    const handelViewSelected = () => {
        props.handelSelectedCitation()
    }
    const handleCollapseAllGlobalFeature =()=>{
        props.handleCollapseAllGlobal()
    }
    const handleSelectAllGlobalFeature =()=>{
        props.handleSelectAll()
    }
    const handleEmailPopUp=(status)=>{
        props.handleGlobalEmailPopUp(status)
    }
    const handleReadingList=(status)=>{
        props.handleOnClickReadingList(status)
    }
    if(customWidth < 600){
        return(
            <div className="wnfy-global-features-wrapper-mobile">
                <div className="wnfy-dropdown-button-wrapper-mobile" onMouseLeave={e=>setShowActionItems(false)} onClick={e=>setShowActionItems(!showActionItems)}>
                <div className="wnfy-action-items-button-wrapper-mobile" onClick={e=>setShowActionItems(!showActionItems)}>
                    <span style={{width:"95%"}}>Action Items</span>
                    {!showActionItems ? 
                    <img src={ActionsArrowIcon} width="15" height="15" alt="" />
                    :
                    <img src={ActionsArrowIcon} width="15" height="15" alt="" style={{transform:"rotate(180deg)"}}/>
                    }
                </div>
                {showActionItems &&
                    <GlobalFeatures customWidth={customWidth} handelViewSelected={handelViewSelected} handleCollapseAllGlobal={handleCollapseAllGlobalFeature} globalCollapseAll={globalCollapseAll}
                    globalSelectAll={globalSelectAll} handleSelectAll={handleSelectAllGlobalFeature} handleEmailPopUp={handleEmailPopUp} handleReadingList={handleReadingList}/>
                }
                </div>
                {products.length > 0 &&
                <div className="wnfy-dropdown-button-wrapper-mobile" onMouseLeave={e=>setShowProducts(false)}>
                    <div className="wnfy-action-items-button-wrapper-mobile" onClick={e=>setShowProducts(!showProducts)}>
                        <span style={{width:"95%"}}>{selectedProduct}</span>
                        {!showProducts ? 
                            <img src={ActionsArrowIcon} width="15" height="15" alt=""/>
                            :
                            <img src={ActionsArrowIcon} width="15" height="15" alt="" style={{transform:"rotate(180deg)"}}/>
                        }
                    </div>
                    {showProducts && 
                    <div className="wnfy-products-list-wrapper-mobile">
                        {products.map((item,index)=>{
                            return(
                            <>
                            {item !== selectedProduct &&
                                <div key={index} className="wnfy-products-item-text" onClick={e=>{handleSelectProduct(e,item);setShowProducts(false)}}><span id={item}>{item}</span>
                            </div>
                            } 
                            </>   
                            )
                        })
                        }
                    </div>
                    }
                </div>
                }
            </div>
        )
    }
    else{
        return(
            <div className="wnfy-global-features-wrapper-tablet">
                <div className="wnfy-dropdown-button-wrapper-tablet" onMouseLeave={e=>setShowActionItems(false)} onClick={e=>setShowActionItems(!showActionItems)}>
                <div className="wnfy-action-items-button-wrapper-tablet" onClick={e=>setShowActionItems(!showActionItems)}>
                    <span style={{width:"90%"}}>Action Items</span>
                    {!showActionItems ? 
                    <img src={ActionsArrowIcon} width="15" height="15" alt="" onClick={e=>setShowActionItems(!showActionItems)}/>
                    :
                    <img src={ActionsArrowIcon} width="15" height="15" alt="" style={{transform:"rotate(180deg)"}} onClick={e=>setShowActionItems(!showActionItems)}/>
                    }  
                </div>
                {showActionItems &&
                    <GlobalFeatures customWidth={customWidth} handelViewSelected={handelViewSelected} handleCollapseAllGlobal={handleCollapseAllGlobalFeature} globalCollapseAll={globalCollapseAll} globalSelectAll={globalSelectAll} handleSelectAll={handleSelectAllGlobalFeature} handleEmailPopUp={handleEmailPopUp} handleReadingList={handleReadingList}/>
                }
                </div>
                {products.length > 0 &&
                <div className="wnfy-dropdown-button-wrapper-tablet" onMouseLeave={e=>setShowProducts(false)}>
                    <div className="wnfy-products-button-wrapper-tablet" onClick={e=>setShowProducts(!showProducts)}>
                    <span style={{width:"80%"}}>{selectedProduct}</span>
                    {!showProducts ? 
                        <img src={ActionsArrowIcon} width="15" height="15" alt="" />
                        :
                        <img src={ActionsArrowIcon} width="15" height="15" alt="" style={{transform:"rotate(180deg)"}}/>
                    }
                    </div>
                    {showProducts && 
                    <div className="wnfy-products-list-wrapper-tablet">
                        {products.map((item,index)=>{
                            return(
                            <>
                            {item !== selectedProduct &&
                                <div key={index} className="wnfy-products-item-text" onClick={e=>{handleSelectProduct(e,item);setShowProducts(false)}}><span id={item}>{item}</span>
                            </div>
                            } 
                            </>   
                            )
                        })
                        }
                    </div>
                    }
                </div>
                }
            </div>
        )
    }
}