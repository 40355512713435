import {recentlyAddedUpdated} from '../../utils/utilityServices';
export default function processFilters(response) {
    //console.log(response);
    const facetOrder = {
      'Recently Added_Updated': 1,
      'Document Type': 2,
      'Document Subtypes': 3,
      'Copy Review Piece Key Fields': 4,
      'Drugs - JNJ': 5,
      'Drugs - Competitor': 6,
      'Drug Dosing And Administration': 7,
      'Disease States': 8,
      'Real World Evidence': 9,
      'Congress Names': 10,
      'Publication Year': 11,
      'Clinical Trials - JNJ': 12,
      'Phase of Study': 13,
      'Content Source': 14,
      'Regions':15,
      'Languages':16
    }

    if (
      !response ||
      !response.aggregations ||
      !response.aggregations.categories ||
      !response.aggregations.categories.buckets
    ) {
      // No-op, stop here
      return
    }

   
    let tmpFilters = [...response.aggregations.categories.buckets];
    tmpFilters=tmpFilters.concat(recentlyAddedUpdated);
    // First, ensure the filters are sorted alphabetically
    tmpFilters.sort((a, b) => {
      if (a.key < b.key) {
        return -1
      }

      if (a.key > b.key) {
        return 1
      }

      return 0
    })

    // Convert the filters into a nested tree
     var filters = tmpFilters.reduce((result, { key, doc_count: count }) => {
      let tmp = result
      const parts = key.split('/')

      const lastPart = parts.pop()

      // Traverse to the part of the tree where this filter lives (creating any
      // parents as necessary)
      parts.forEach(part => {
        if (tmp[part] === undefined) {
          tmp[part] = {
            label: part,
            count: 0,
            children: {}
          }
        }

        tmp = tmp[part].children
      })

      // Add the filter to the tree
      if (tmp[lastPart]) {
        tmp[lastPart].count = count
      } else {
        const partData = {
          key,
          label: lastPart,
          count,
          children: {}
        }

        if (parts.length <= 0) {
          partData.order = facetOrder[key] || 100
        }

        tmp[lastPart] = partData
      }
      /* this.setState({ filters: result },()=>{
          //console.log(this.state.document,this.state.documentCount)
          this.props.handleAllfilters(this.state.filters,this.state.appliedFilters,this.state.selectedFilters,this.state.document,this.state.documentCount,this.state.query)
          this.props.handleIsProcessing(true)
      }) */
      //console.log(result)
      return result
    }, {})
    return filters
  };

  export  function processPubMedFilters(response) {
    //console.log(response);
    const facetOrder = {
      'Drugs - JNJ': 1,
      'Drugs - Competitor': 2,
      'Drug Dosing And Administration': 3,
      'Disease States': 4,
      'Real World Evidence': 5,
      'Language': 6
    }

    if (
      !response ||
      !response.aggregations ||
      !response.aggregations.categories ||
      !response.aggregations.categories.buckets
    ) {
      // No-op, stop here
      return
    }

    const tmpFilters = [...response.aggregations.categories.buckets]

    // First, ensure the filters are sorted alphabetically
    tmpFilters.sort((a, b) => {
      if (a.key < b.key) {
        return -1
      }

      if (a.key > b.key) {
        return 1
      }

      return 0
    })

    // Convert the filters into a nested tree
     var filters = tmpFilters.reduce((result, { key, doc_count: count }) => {
      let tmp = result
      const parts = key.split('/')

      const lastPart = parts.pop()

      // Traverse to the part of the tree where this filter lives (creating any
      // parents as necessary)
      parts.forEach(part => {
        if (tmp[part] === undefined) {
          tmp[part] = {
            label: part,
            count: 0,
            children: {}
          }
        }

        tmp = tmp[part].children
      })

      // Add the filter to the tree
      if (tmp[lastPart]) {
        tmp[lastPart].count = count
      } else {
        const partData = {
          key,
          label: lastPart,
          count,
          children: {}
        }

        if (parts.length <= 0) {
          partData.order = facetOrder[key] || 100
        }

        tmp[lastPart] = partData
      }
      /* this.setState({ filters: result },()=>{
          //console.log(this.state.document,this.state.documentCount)
          this.props.handleAllfilters(this.state.filters,this.state.appliedFilters,this.state.selectedFilters,this.state.document,this.state.documentCount,this.state.query)
          this.props.handleIsProcessing(true)
      }) */
      //console.log(result)
      return result
    }, {})
    return filters
  };