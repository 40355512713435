import { Component } from "react";
import ApiServices from "../../../utils/apiServices";
import { imsLoader } from "../../../assets";
import { sortBy } from 'underscore'

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allProducts: [],
      availableProducts: [],
      availableClasses: [],
      selectedArea: "",
      selectedAreas: [],
      selectedClassId: "",
      selectedClass: "",
      selectedClasses: [],
      ischecked: false,
      selectedProductId: "",
      selectedProduct: "",
      selectedProducts: [],
      areaId: [],
      classId: [],
      productId: [],
      isLoaded: false
    };
    //console.log(this.props);
    //console.log(this.state);
  }
  async componentDidMount() {
    //console.log(this.props);
    const service = new ApiServices();
    var res = await service.getAllProducts();
    //console.log(res);
    if (res) {
      this.setState({
        allProducts: res,
        availableProducts: this.state.availableProducts,
        availableClasses: this.state.availableClasses,
        selectedArea: this.props.selectedArea,
        isLoaded: true
      });
      if (this.props.selectedAreas.length > 0) {
        this.setState({
          selectedAreas: this.props.selectedAreas
        });
      }
      if (this.props.selectedClasses.length > 0) {
        this.setState({
          selectedClasses: this.props.selectedClasses
        });
      }
      if (this.props.products.length > 0) {
        this.setState({
          selectedProducts: this.props.products
        });
      }
      if (this.props.areaId.length > 0) {
        this.setState({
          areaId: this.props.areaId
        });
      }
      if (this.props.classId.length > 0) {
        this.setState({
          classId: this.props.classId
        });
      }
      if (this.props.productId.length > 0) {
        this.setState({
          productId: this.props.productId
        });
      }
      this.mappingClassesAndProducts(this.state.allProducts);
    }
  }
  mappingClassesAndProducts = allProducts => {
    //console.log(allProducts)
    var classes = [];
    var products = [];
    allProducts.map((item, index) => {
      classes.push({
        id: item.classid,
        name: item.classname,
        taid: item.taid,
        taname: item.taname,
        productid: item.productid,
        productname: item.productname
      });
      products.push({
        id: item.productid,
        name: item.productname,
        classid: item.classid,
        classname: item.classname,
        areaName: item.taname,
        areaid: item.taid
      });
      return null;
    });
    this.setState({
      availableClasses: classes,
      availableProducts: products
    });
    //console.log(this.state)
  };
  handleOnChangeInClassProducts = () => {
    //console.log(this.state.selectedAreas,this.state.selectedClasses,this.state.selectedProducts);
    this.props.onChangeSelectAreas(
      this.state.selectedAreas,
      this.state.selectedClasses,
      this.state.selectedProducts,
      this.state.areaId,
      this.state.classId,
      this.state.productId
    );
  };
  classes = (availableClasses, area) => {
    if (availableClasses.length > 0) {
      return availableClasses.map((item, index) => {
        // console.log(item);
        if (this.state.selectedClasses.includes(item.name)) {
          //console.log(this.state.selectedClasses.includes(item.name));
          // if (this.props.customWidth < 1000) {
          return (
            <div className="class-product-section" id={`${item.id}section`}>
              <div className="classSection">
                {/* <div className="arrowContainer">
                    <div className="rightPartArrow"></div>
                    <div className="downTriangleArrow"></div>
                  </div> */}
                {/* <img src={rightArrow} alt="arrow" /> */}
                <input
                  type="checkbox"
                  id={`id-${item.id}`}
                  value={item.name}
                  areaName={item.taname}
                  areaid={item.taid}
                  productid={item.productid}
                  productname={item.productname}
                  name="classCheckBox"
                  className="cname"
                  key={item.id + Math.random()}
                  onChange={this.handleClassSelect}
                  checked={!this.state.ischecked}
                  class="k-checkbox"
                  style={{ margin: "0px" }}
                />
                <span>{item.name}</span>
              </div>
              {
                (item.productid && item.productname) &&
                <div className="product-section" id={`${item.id}prds`}>
                  {/* <div className="topCircle"></div> */}
                  {this.allproducts(this.state.availableProducts, item.id, area)}
                </div>
              }
            </div>
          );
          // }
          // else {
          //   return (
          //     <div className="class-product-section" id={`${item.id}section`}>
          //       <div className="classSection">
          //         {/* <div className="arrowContainer">
          //         <div className="rightPartArrow"></div>
          //         <div className="downTriangleArrow"></div>
          //       </div> */}
          //         {/* <img src={rightArrow} alt="arrow" /> */}
          //         <input
          //           type="checkbox"
          //           id={`id-${item.id}`}
          //           value={item.name}
          //           areaName={item.taname}
          //           areaid={item.taid}
          //           productid={item.productid}
          //           productname={item.productname}
          //           name="classCheckBox"
          //           className="cname"
          //           key={item.id + Math.random()}
          //           onChange={this.handleClassSelect}
          //           checked={!this.state.ischecked}
          //           class="k-checkbox"
          //           style={{ margin: "0px" }}
          //         />
          //         <span>{item.name}</span>
          //       </div>
          //       <div className="product-section" id={`${item.id}prds`}>
          //         <div className="topCircle"></div>
          //         {this.allproducts(this.state.availableProducts, item.id, area)}
          //       </div>
          //     </div>
          //   );
          // }
        } else {
          // if (this.props.customWidth < 1000) {
          return (
            <div className="class-product-section" id={`${item.id}section`}>
              <div className="classSection">
                {/* <div className="arrowContainer">
                    <div className="rightPartArrow"></div>
                    <div className="downTriangleArrow"></div>
                  </div> */}
                {/* <img src={rightArrow} alt="arrow"  /> */}
                <input
                  type="checkbox"
                  id={`id-${item.id}`}
                  value={item.name}
                  areaName={item.taname}
                  areaid={item.taid}
                  productid={item.productid}
                  productname={item.productname}
                  name="classCheckBox"
                  className="cname"
                  key={item.id + Math.random()}
                  onChange={this.handleClassSelect}
                  checked={this.state.ischecked}
                  class="k-checkbox"
                  style={{ margin: "0px" }}
                />
                <span>{item.name}</span>
              </div>
              {
                (item.productid && item.productname) &&
                <div className="product-section" id={`${item.id}prds`}>
                  {/* <div className="topCircle"></div> */}
                  {this.allproducts(this.state.availableProducts, item.id, area)}
                </div>
              }
            </div>
          );
          // }
          // else {
          //   return (
          //     <div className="class-product-section" id={`${item.id}section`}>
          //       <div className="classSection">
          //         {/* <div className="arrowContainer">
          //         <div className="rightPartArrow"></div>
          //         <div className="downTriangleArrow"></div>
          //       </div> */}
          //         {/* <img src={rightArrow} alt="arrow"  /> */}
          //         <input
          //           type="checkbox"
          //           id={`id-${item.id}`}
          //           value={item.name}
          //           areaName={item.taname}
          //           areaid={item.taid}
          //           productid={item.productid}
          //           productname={item.productname}
          //           name="classCheckBox"
          //           className="cname"
          //           key={item.id + Math.random()}
          //           onChange={this.handleClassSelect}
          //           checked={this.state.ischecked}
          //           class="k-checkbox"
          //           style={{ margin: "0px" }}
          //         />
          //         <span>{item.name}</span>
          //       </div>
          //       <div className="product-section" id={`${item.id}prds`}>
          //         <div className="topCircle"></div>
          //         {this.allproducts(this.state.availableProducts, item.id, area)}
          //       </div>
          //     </div>
          //   );
          // }
        }
      });
    }
  };
  handleClassSelect = event => {
    if (event.target.checked) {
      if (
        !this.state.selectedAreas.includes(
          event.target.attributes.areaName.value
        )
      ) {
        this.setState(
          {
            selectedClasses: this.state.selectedClasses.concat([
              event.target.value
            ]),
            selectedClassId: event.target.id.replace("id-", ""),
            classId: this.state.classId.concat([
              event.target.id.replace("id-", "")
            ]),
            selectedClass: event.target.value,
            selectedAreas: this.state.selectedAreas.concat([
              event.target.attributes.areaName.value
            ]),
            areaId: this.state.areaId.concat([
              event.target.attributes.areaid.value
            ])
          },
          () => {
            this.handleOnChangeInClassProducts();
            var classId = this.state.selectedClassId;
            var products = this.state.availableProducts;
            var selectedproduct = [];
            var selectedproductid = [];
            if (products.length > 0) {
              products.map(item => {
                if (item.id) {
                  if (item.id.indexOf(classId) !== -1) {
                    var id = `id-${item.id}`;
                    document.getElementById(id).checked = true;
                    selectedproduct.push(item.name);
                    if (!this.state.productId.includes(item.id)) {
                      selectedproductid.push(item.id);
                    }
                  }
                }
                return null;
              });
            }
            this.setState(
              {
                selectedProducts: this.state.selectedProducts.concat(selectedproduct),
                productId: this.state.productId.concat(selectedproductid)
              },
              this.handleOnChangeInClassProducts
            );
          }
        );
      } else {
        this.setState(
          {
            selectedClasses: this.state.selectedClasses.concat([
              event.target.value
            ]),
            selectedClassId: event.target.id.replace("id-", ""),
            classId: this.state.classId.concat([
              event.target.id.replace("id-", "")
            ]),
            selectedClass: event.target.value
            /* areaId:this.state.areaId.concat([event.target.attributes.areaid.value]) */
          },
          () => {
            this.handleOnChangeInClassProducts();
            var classId = this.state.selectedClassId;
            var products = this.state.availableProducts;
            var selectedproduct = [];
            var selectedproductid = [];
            if (products.length > 0) {
              products.map(item => {
                if (item.id) {
                  if (item.id.indexOf(classId) !== -1) {
                    var id = `id-${item.id}`;
                    document.getElementById(id).checked = true;
                    selectedproduct.push(item.name);
                    if (!this.state.productId.includes(item.id)) {
                      selectedproductid.push(item.id);
                    }
                  }
                }
                return null;
              });
            }

            //console.log(selectedproduct);
            this.setState(
              {
                selectedProducts: this.state.selectedProducts.concat(selectedproduct),
                productId: this.state.productId.concat(selectedproductid)
              },
              this.handleOnChangeInClassProducts
            );
          }
        );
      }

      //console.log(this.state.selectedProducts);
    } else {
      //console.log(this.state.selectedClasses,this.state.selectedAreas,this.state.areaId,this.state.classId);
      var arr = [...this.state.selectedClasses];
      var arr3 = [...this.state.selectedAreas];
      var arr4 = [...this.state.areaId];
      var arr5 = [...this.state.classId];
      var idx = arr.indexOf(event.target.value);
      var idx4 = arr4.indexOf(event.target.attributes.areaid.value);
      var idx5 = arr5.indexOf(event.target.id.replace("id-", ""));
      var idx3 = arr3.indexOf(event.target.attributes.areaName.value);
      var temparr5 = [];
      if (idx !== -1) {
        arr.splice(idx, 1);
      }
      if (idx5 !== -1) {
        arr5.splice(idx5, 1);
      }

      if (idx4 !== -1) {
        arr5.map(cls => {
          if (!temparr5.includes(cls[0])) {
            temparr5.push(cls[0]);
          }
          return null
        });
        arr4 = temparr5;
        if (!arr4.includes(event.target.attributes.areaid.value)) {
          idx3 = arr3.indexOf(event.target.attributes.areaName.value)
          if (idx3 !== -1) {
            arr3.splice(idx3, 1);
          }
        }

      }
      // if (idx3 !== -1) {
      //   arr3.splice(idx3, 1);
      // }
      this.setState(
        {
          selectedClasses: arr,
          selectedClass: "",
          selectedClassId: "",
          classId: arr5,
          selectedAreas: arr3,
          areaId: arr4
        },
        () => {
          this.handleOnChangeInClassProducts();
          var classId = event.target.id.replace("id-", "");
          var products = this.state.availableProducts;
          var unselectedproduct = [...this.state.selectedProducts];
          var arr7 = [...this.state.productId];
          if (products.length > 0) {
            products.map(item => {
              if (item.id) {
                if (item.id.indexOf(classId) !== -1) {
                  var id = `id-${item.id}`;
                  document.getElementById(id).checked = false;
                  var index = unselectedproduct.indexOf(item.name);
                  if (index !== -1) {
                    unselectedproduct.splice(index, 1);
                  }
                  // var idx7 = arr7.indexOf(event.target.attributes.productid.value);
                  var idx7 = arr7.indexOf(item.id);
                  if (idx7 !== -1) {
                    arr7.splice(idx7, 1);
                  }
                }
              }
              return null
            });
          }
          this.setState(
            {
              selectedProducts: unselectedproduct,
              productId: arr7
            }, this.handleOnChangeInClassProducts)
        }
      );
    }
  };
  allproducts = (allproducts, classId, area) => {
    //console.log(allproducts);
    var products = allproducts.filter(function (productList) {
      return productList.classid === classId && productList.areaName === area;
    });
    products = sortBy(products, 'name');
    if (products.length > 0) {
      return products.map((item, index) => {
        if (this.state.productId.includes(item.id)) {
          // if (this.state.selectedProducts.includes(item.name)) {
          // if (this.props.customWidth < 1000) {
          return (
            <div className="prd-val">
              {/* <img src={rightArrow} alt="arrow" className="productsRightArrow" /> */}
              <input
                type="checkbox"
                id={`id-${item.id}`}
                value={item.name}
                name="prdCheckBox"
                classid={item.classid}
                areaname={item.areaName}
                areaid={item.areaid}
                className="prdname"
                key={item.id + Math.random()}
                onChange={this.handleProductSelect}
                checked={!this.state.ischecked}
                classChecked={item.classname}
                class="k-checkbox"
                style={{ margin: "0 0 -8px 15px" }}
              ></input>
              <div className="sub-productsName"><span>{item.name}</span></div>
            </div>
          );
          // }
          // else {
          //   return (
          //     <div className="prd-val">
          //       <img src={rightArrow} alt="arrow" className="productsRightArrow" />
          //       <input
          //         type="checkbox"
          //         id={`id-${item.id}`}
          //         value={item.name}
          //         name="prdCheckBox"
          //         classid={item.classid}
          //         areaname={item.areaName}
          //         areaid={item.areaid}
          //         className="prdname"
          //         key={item.id + Math.random()}
          //         onChange={this.handleProductSelect}
          //         checked={!this.ischecked}
          //         classChecked={item.classname}
          //         class="k-checkbox"
          //         style={{ margin: "0 0 -8px 15px" }}
          //       ></input>
          //       <div className="sub-productsName"><span>{item.name}</span></div>
          //     </div>
          //   );
          // }
        } else {
          // if (this.props.customWidth < 1000) {
          return (
            <div className="prd-val">
              {/* <img src={rightArrow} alt="arrow" className="productsRightArrow" /> */}
              <input
                type="checkbox"
                id={`id-${item.id}`}
                value={item.name}
                classid={item.classid}
                areaname={item.areaName}
                areaid={item.areaid}
                name="prdCheckBox"
                className="prdname"
                key={item.id + Math.random()}
                onChange={this.handleProductSelect}
                checked={this.state.ischecked}
                classChecked={item.classname}
                class="k-checkbox"
                style={{ margin: "0 0 -8px 15px" }}
              ></input>
              <div className="sub-productsName"><span>{item.name}</span></div>
            </div>
          );
          // }
          // else {
          //   return (
          //     <div className="prd-val">
          //       <img src={rightArrow} alt="arrow" className="productsRightArrow" />
          //       <input
          //         type="checkbox"
          //         id={`id-${item.id}`}
          //         value={item.name}
          //         classid={item.classid}
          //         areaname={item.areaName}
          //         areaid={item.areaid}
          //         name="prdCheckBox"
          //         className="prdname"
          //         key={item.id + Math.random()}
          //         onChange={this.handleProductSelect}
          //         checked={this.ischecked}
          //         classChecked={item.classname}
          //         class="k-checkbox"
          //         style={{ margin: "0 0 -8px 15px" }}
          //       ></input>
          //       <div className="sub-productsName"><span>{item.name}</span></div>
          //     </div>
          //   );
          // }
        }
      });
    }
  };
  handleProductSelect = event => {
    if (event.target.checked) {
      if (!this.state.selectedClasses.includes(event.target.attributes.classChecked.value)) {
        //console.log(event.target.attributes.classid.value);
        this.setState(
          {
            selectedProducts: this.state.selectedProducts.concat([
              event.target.value
            ]),
            selectedClasses: this.state.selectedClasses.concat([
              event.target.attributes.classChecked.value
            ]),
            selectedProduct: event.target.value,
            selectedProductId: event.target.id.replace("id-", ""),
            classId: this.state.classId.concat([
              event.target.attributes.classid.value
            ]),
            productId: this.state.productId.concat([
              event.target.id.replace("id-", "")
            ])
          }, () => {
            this.handleOnChangeInClassProducts();
            if (!this.state.areaId.includes(event.target.attributes.areaid.value)) {
              this.setState({
                areaId: this.state.areaId.concat([event.target.attributes.areaid.value]),
                selectedAreas: this.state.selectedAreas.concat([event.target.attributes.areaname.value])
              }, this.handleOnChangeInClassProducts)
            }
          }

        );
      } else {
        this.setState(
          {
            selectedProducts: this.state.selectedProducts.concat([
              event.target.value
            ]),
            selectedProduct: event.target.value,
            selectedProductId: event.target.id.replace("id-", ""),
            /* classId:this.state.classId.concat([event.target.attributes.classid.value]), */
            productId: this.state.productId.concat([
              event.target.id.replace("id-", "")
            ])
          },
          this.handleOnChangeInClassProducts
        );
      }
    } else {
      /* console.log(
        this.state.selectedProducts,
        this.state.selectedClasses,
        this.state.classId,
        this.state.productId,
        this.state.selectedAreas,
        this.state.areaId
      ); */
      let { allProducts } = this.state;
      var arr = [...this.state.selectedProducts];
      var arr2 = [...this.state.selectedClasses];
      var arr3 = [...this.state.classId];
      var arr4 = [...this.state.productId];
      var arr5 = [...this.state.selectedAreas];
      var arr6 = [...this.state.areaId];
      var idx = arr.indexOf(event.target.value);
      var idx3 = arr3.indexOf(event.target.attributes.classid.value);
      var idx4 = arr4.indexOf(event.target.id.replace("id-", ""));
      var temparr4 = [];
      var temparr6 = [];
      if (idx !== -1) {
        arr.splice(idx, 1);
      }
      /* if (idx3 != -1) {
        arr3.splice(idx3, 1);
      } */
      if (idx4 !== -1) {
        arr4.splice(idx4, 1);
      }
      //console.log(arr4);
      if (idx3 !== -1) {
        arr4.map((item) => {
          //console.log(item)
          //console.log(item.substring(0,4));
          if (temparr4.indexOf(item.substring(0, 4)) === -1) {
            temparr4.push(item.substring(0, 4));
          }
          return null;
        })
        arr3.map((item) => {
          let filterProducts = allProducts.filter(x => x.classid === item);
          if (filterProducts.length === 1
            && filterProducts[0].classid !== event.target.attributes.classid.value
            && temparr4.indexOf(item.substring(0, 4)) === -1) {
            temparr4.push(item);
          }
          return null;
        })
        arr3 = temparr4;
        //console.log(arr3);
        //console.log(arr3.includes(event.target.attributes.classid.value));
        if (!arr3.includes(event.target.attributes.classid.value)) {
          var idx2 = arr2.indexOf(event.target.attributes.classChecked.value);
          if (idx2 !== -1) {
            arr2.splice(idx2, 1);
          }
        }
        arr3.map((item) => {
          if (!temparr6.includes(item[0])) {
            temparr6.push(item[0])
          }
          return null;
        })
        arr6 = temparr6;
        //console.log(arr6.includes(event.target.attributes.areaid.value));
        if (!arr6.includes(event.target.attributes.areaid.value)) {
          var idx5 = arr5.indexOf(event.target.attributes.areaname.value)
          if (idx5 !== -1) {
            arr5.splice(idx5, 1)
          }
        }
      }
      this.setState(
        {
          selectedProducts: arr,
          selectedProduct: "",
          selectedProductId: "",
          selectedClasses: arr2,
          classId: arr3,
          productId: arr4,
          areaId: arr6,
          selectedAreas: arr5
        },
        this.handleOnChangeInClassProducts
      );
    }
  };
  render() {
    var unfilteredClasses = this.state.availableClasses;
    var area = this.props.selectedArea;
    //console.log(area);
    var allClasses = unfilteredClasses.filter(
      (ele, ind) =>
        ind ===
        unfilteredClasses.findIndex(
          elem => elem.taname === area && elem.id === ele.id
        )
    );
    // if (this.props.customWidth < 1000) {
    //   return (
    //     <div>
    //       {this.state.isLoaded ?
    //         <div className="productWrapper">
    //           <div className="productText">
    //             <strong>
    //               Select your product(s) of interest (choose as many as you like):
    //             </strong>
    //             <br />
    //             <br />
    //             <div className="productListWrapper">
    //               <div className="productPartDiv" id="productPartDiv">
    //                 {/* <div className="topCircle"></div> */}
    //                 {this.classes(allClasses, area)}
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         : <div style={{ textAlign: "center" }}>
    //           <img src={imsLoader} alt="Loading Data" style={{ width: "7%" }} />
    //         </div>}
    //     </div>
    //   );
    // }
    // else {
    return (
      <div>
        {this.state.isLoaded ?
          <div className="productWrapper">
            <div className="productText">
              <strong className="productText-tablet">
                Select your product(s) of interest (choose as many as you like):
              </strong>
              <br />
              <br />
              <div className="productListWrapper">
                <div className="productPartDiv" id="productPartDiv">
                  {/* <div className="topCircle"></div> */}
                  {this.classes(allClasses, area)}
                </div>
              </div>
            </div>
          </div>
          : <div style={{ textAlign: "center" }}>
            <img src={imsLoader} alt="Loading Data" style={{ width: "7%" }} />
          </div>}
      </div>
    );
    // }
  }
}
export default ProductDetails;
