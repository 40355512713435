import { Component } from "react";
import { Link } from "react-router-dom";
import { IMSLogo, ActionsArrowIcon } from "../../assets";
import GlobalFeatures from "../GlobalSearchFeatures/GlobalFeatures";

class MIAsReferencesHeader extends Component {
    isRussian;
    constructor(props) {
        super(props);
        this.state = {
            openPopUp: false,
            showNav: false,
            showActionItems: false
        }
        this.isRussian = JSON.parse(localStorage.getItem('russianuser'));
    }
    handleOnHover = () => {
        this.setState({
            openPopUp: true
        })
    }
    handleOnHoverLeave = () => {
        this.setState({
            openPopUp: false
        })
    }
    handleNav = () => {
        this.setState({
            showNav: !this.state.showNav
        })
    }
    handleClose = () => {
        this.setState({
            showNav: false
        })
    }
    handelViewSelected = () => {
        this.props.handelSelectedCitation()
    }
    handleCollapseAllGlobal = () => {
        this.props.handleCollapseAllGlobal();
    }
    handleSelectAll = () => {
        this.props.handleSelectAll();
    }
    handleEmailPopUp = (status) => {
        this.props.handleGlobalEmailPopUp(status)
    }
    handleReadingList = (status) => {
        this.props.handleOnClickReadingList(status)
    }
    handleBookmark = (status) => {
        this.props.handleGlobalBookmark(status)
    }
    handleNotifyMePopUp = (status) => {
        this.props.handleGlobalNotifyMePopUp(status)
    }
    render() {
        if (this.props.customWidth < 600) {
            return (
                <div className="headerWithBannerWrapper">
                    {/* <div className="bannerImage-MIAs">
                        <div className="headerTitle">
                            <Link to="/">
                                <img src={IMSLogo} alt="iMedical Search" style={{ width: "20%", paddingTop: "2%" }} />
                            </Link>
                        </div>
                    </div> */}
                    <div className="wnfy-global-features-wrapper-mobile" style={{ height: "auto" }}>
                        <div className="wnfy-dropdown-button-wrapper-mobile" onMouseLeave={e => this.setState(false)} onClick={e => this.setState({ showActionItems: !this.state.showActionItems })}>
                            <div className="wnfy-action-items-button-wrapper-mobile" onClick={e => this.setState({ showActionItems: !this.state.showActionItems })}>
                                <span style={{ width: "90%" }}>Action Items</span>
                                {!this.state.showActionItems ?
                                    <img src={ActionsArrowIcon} width="15" height="15" alt="" onClick={e => this.setState({ showActionItems: !this.state.showActionItems })} />
                                    :
                                    <img src={ActionsArrowIcon} width="15" height="15" alt="" style={{ transform: "rotate(180deg)" }} onClick={e => this.setState({ showActionItems: !this.state.showActionItems })} />
                                }
                            </div>
                            {this.state.showActionItems &&
                                <GlobalFeatures customWidth={this.props.customWidth} handelViewSelected={this.handelViewSelected} handleCollapseAllGlobal={this.handleCollapseAllGlobal} globalCollapseAll={this.props.globalCollapseAll} globalSelectAll={this.props.globalSelectAll} handleSelectAll={this.handleSelectAll} handleEmailPopUp={this.handleEmailPopUp} handleReadingList={this.handleReadingList} handleBookmark={this.handleBookmark} handleNotifyMePopUp={this.handleNotifyMePopUp} />
                            }
                        </div>
                    </div>
                </div>
            )
        }
        else if (this.props.customWidth < 1000) {
            return (
                <div className="headerWithBannerWrapper">
                    <div className="bannerImage-MIAs">
                        <div className="headerTitle">
                            <Link to="/">
                                <img src={IMSLogo} alt="iMedical Search" style={{ width: "20%", paddingTop: "0%", verticalAlign: "baseline" }} />
                            </Link>
                        </div>
                    </div>
                    <div className="wnfy-global-features-wrapper-tablet">
                        <div className="wnfy-dropdown-button-wrapper-tablet" onMouseLeave={e => this.setState({showActionItems: false})} onClick={e => this.setState({ showActionItems: !this.state.showActionItems })}>
                        <div className="wnfy-action-items-button-wrapper-tablet" onClick={e => this.setState({ showActionItems: !this.state.showActionItems })}>
                            <span style={{ width: "90%" }}>Action Items</span>
                            {!this.state.showActionItems ?
                                <img src={ActionsArrowIcon} width="15" height="15" alt="" onClick={e => this.setState({ showActionItems: !this.state.showActionItems })} />
                                :
                                <img src={ActionsArrowIcon} width="15" height="15" alt="" style={{ transform: "rotate(180deg)" }} onClick={e => this.setState({ showActionItems: !this.state.showActionItems })} />
                            }
                        </div>
                        {this.state.showActionItems &&
                            <GlobalFeatures customWidth={this.props.customWidth} handelViewSelected={this.handelViewSelected} handleCollapseAllGlobal={this.handleCollapseAllGlobal} globalCollapseAll={this.props.globalCollapseAll} globalSelectAll={this.props.globalSelectAll} handleSelectAll={this.handleSelectAll} handleEmailPopUp={this.handleEmailPopUp} handleReadingList={this.handleReadingList} handleBookmark={this.handleBookmark} handleNotifyMePopUp={this.handleNotifyMePopUp} />
                        }
                    </div>
                </div>
                </div >
            )
        }
        else {
            return (
                <div className="headerWithBannerWrapper">
                    <div className="bannerImage-MIAs">
                        <div className="headerTitle">
                            <Link to="/">
                                <img src={IMSLogo} alt="iMedical Search" style={{ width: "20%", paddingTop: "2%" }} />
                            </Link>
                        </div>
                        <div className="globalSearch-wrapper">
                            <GlobalFeatures customWidth={this.props.customWidth} customHeight={this.props.customHeight} handelViewSelected={this.handelViewSelected} handleCollapseAllGlobal={this.handleCollapseAllGlobal} globalCollapseAll={this.props.globalCollapseAll} handleSelectAll={this.handleSelectAll} globalSelectAll={this.props.globalSelectAll} selectedResults={this.props.selectedResults} /* querytext={this.state.searchKeyword} appliedFilters={this.props.appliedFilters} */ documentsUrl={this.props.documentsUrl} markedDocumentsUrl={this.props.markedDocumentsUrl} itemsPerPage={this.props.itemsPerPage} />
                        </div>
                    </div>
                </div>
            )
        }
    }
}
export default MIAsReferencesHeader;