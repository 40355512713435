/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Footer from "../../components/footer/Footer";
import { IMSLogo, imsLoader } from "../../assets"
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import trackingService from "../../services/trackingService"
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import { LocationOrigin } from "../../utils/utilityServices";
import { useHistory } from "react-router-dom";

const indexData = (url, domain, docVer, docID, orderedBy = '', orderedFor = '') => {

    const ts = new trackingService();
    if (domain === 'RIMdocs' || domain === 'RIMdocs_LL') {
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), 'View Document External', `Old iMS Document`, window.location.href, '', '', '', docVer, '', '', url, '', '', `Old iMS Document`, '', '', domain, '', '', '', orderedBy, orderedFor);
    }
    else {
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), 'View Document External', `Old iMS Document`, window.location.href, '', '', '', docVer, docID, '', url, '', '', `Old iMS Document`, '', '', domain, '', '', '', orderedBy, orderedFor);
    }
}

export default function GetContentDocURL(props) {
    const [isProcessing, setProcessing] = useState(false);
    const [isDownloadUrlAvailable, setIsDownloadUrlAvailable] = useState(false);
    const [width] = useState(window.innerWidth);
    const [height] = useState(window.innerHeight);
    let history = useHistory();
    useEffect(() => {
        let userExists = localStorage.getItem('userExists');
        if (userExists) {
            let searchEncodeURI = decodeURIComponent(props.location.search);
            searchEncodeURI = decodeURIComponent(searchEncodeURI);
            searchEncodeURI = decodeURIComponent(searchEncodeURI);
            searchEncodeURI = decodeURIComponent(searchEncodeURI);
            const queryParams = new URLSearchParams(searchEncodeURI)

            const contentType = queryParams.get('contentType');
            if (contentType && contentType === "RIMD") {
                const objectID = queryParams.get('objectID');
                let windowlocation = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}download/RIMdocs?strID=${objectID}`;
                setIsDownloadUrlAvailable(true);
                window.location.href = windowlocation;

            } else {
                runSearch();
            }
        }
        else {
            console.log('Access Denied');
            history.push('/');
        }
    }, []);
    const runSearch = () => {
        setProcessing(true)
        let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'));
        var url = LocationOrigin + process.env.REACT_APP_IMS_API_PREFIX + 'qp/runSearch'
        var config;
        let searchEncodeURI = decodeURIComponent(props.location.search);
        searchEncodeURI = decodeURIComponent(searchEncodeURI);
        searchEncodeURI = decodeURIComponent(searchEncodeURI);
        searchEncodeURI = decodeURIComponent(searchEncodeURI);
        const queryParams = new URLSearchParams(searchEncodeURI)
        const docID = queryParams.get('id')
        if (docID) {
            config = {
                method: 'POST',
                url: url,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    "query": '',
                    "filters": '',
                    "function": localStorage.getItem('function').replace(/"/g, ''),
                    "doctypepref": '',
                    "userproducts": localStorage.getItem('userproducts'),
                    "region": localStorage.getItem('region'),
                    "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                    "contentpreference": localStorage.getItem('contentpreference').replace(/"/g, ''),
                    "from": 0,
                    "size": 25,
                    "f1": "docID",
                    "fval1": docID,
                    "fcount": 1,
                    accessGroup: (userAccessGroup.includes("MISEHCP")) ? "MISE" : "",
                }
            }

            axios(config)
                .then((response) => {
                    if (response.data.body.hits.total.value > 0) {
                        if (response.data.body.hits.hits[0]._source) {
                            let downloadUrl = '';
                            let sourceItem = response.data.body.hits.hits[0]._source
                            if (sourceItem.domain === 'SKM' && sourceItem.fileExtension === "aspx") {
                                setIsDownloadUrlAvailable(true);
                                let windowlocation = sourceItem.linkURL ? sourceItem.linkURL : sourceItem.spoWebURL;
                                let { domain, docVer, docID, orderedBy, orderedFor } = sourceItem;
                                indexData(windowlocation, domain, docVer, docID, orderedBy, orderedFor)
                                window.location.href = windowlocation;
                            } else {
                                if (sourceItem.downloadUrl) {
                                    if (sourceItem.downloadUrl.toLowerCase().includes('fileName='.toLocaleLowerCase())) {
                                        var sourceItemURL = new URL(`${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}${sourceItem.downloadUrl.replace('/api/', '')}`);
                                        var search_params = sourceItemURL.searchParams;
                                        search_params.set('fileName', sourceItem.docTitle);
                                        sourceItemURL.search = search_params.toString();
                                        downloadUrl = `${sourceItemURL.toString().replace(`${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}`, '')}`
                                    } else {
                                        downloadUrl = `${sourceItem.downloadUrl.replace('/api/', '')}&fileName=${sourceItem.docTitle}`
                                    }

                                } else {
                                    if (sourceItem.domain === 'SKM') {
                                        downloadUrl = `dcs-spo/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&fileName=${sourceItem.docTitle}`
                                    }
                                    else if (sourceItem.domain === 'RIMdocs') {
                                        downloadUrl = `download/RIMdocs?strID=${sourceItem.docID}`
                                    }
                                    else if (sourceItem.domain === 'SRD') {
                                        if (sourceItem.fileExtension !== 'pdf') {
                                            downloadUrl = `dcs-vv/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&docVer=${sourceItem.majorVersionNumber}&fileName=${sourceItem.docTitle}`
                                        }
                                        else {
                                            downloadUrl = `dcs-vv/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&fileName=${sourceItem.docTitle}`
                                        }
                                        if (sourceItem.a_status === "Approved Needs Review") {
                                            downloadUrl = downloadUrl + `&docState=anr`
                                        }
                                    }
                                    else if (sourceItem.domain === 'RIMdocs_LL') {
                                        downloadUrl = `download/RIMdocs?strID=${sourceItem.docID}`
                                    }
                                    else if (sourceItem.domain === 'MLR') {
                                        if (sourceItem.fileExtension !== 'pdf') {
                                            downloadUrl = `dcs-vv/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&docVer=${sourceItem.majorVersionNumber}&fileName=${sourceItem.docTitle}`
                                        }
                                        else {
                                            downloadUrl = `dcs-vv/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&fileName=${sourceItem.docTitle}`
                                        }
                                        if (sourceItem.status === "Approved Needs Review") {
                                            downloadUrl = downloadUrl + `&docState=anr`
                                        }
                                    }
                                    else if (sourceItem.domain === "SR" || sourceItem.domain === "SRAP" || sourceItem.domain === "GCSR" || sourceItem.domain === "SFM") {

                                        if (sourceItem.fileExtension !== 'pdf') {
                                            downloadUrl = `dcs-vv/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&docVer=${sourceItem.majorVersionNumber}&fileName=${sourceItem.docTitle}`
                                        }
                                        else {
                                            downloadUrl = `dcs-vv/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&fileName=${sourceItem.docTitle}`
                                        }
                                        if (sourceItem.status === "Approved Needs Review") {
                                            downloadUrl = downloadUrl + `&docState=anr`
                                        }
                                    }
                                    else if (sourceItem.domain === "iMR") {
                                        if (sourceItem.fileExtension !== 'pdf') {
                                            downloadUrl = `dcs-vv/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&docVer=${sourceItem.majorVersionNumber}&fileName=${sourceItem.docTitle}`
                                        }
                                        else {
                                            downloadUrl = `dcs-vv/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&fileName=${sourceItem.docTitle}`
                                        }
                                        if (sourceItem.status === "Approved Needs Review") {
                                            downloadUrl = downloadUrl + `&docState=anr`
                                        }

                                    }
                                    else if (sourceItem.domain === "CTGov") {

                                        downloadUrl = `https://clinicaltrials.gov/ct2/show/${sourceItem.docID}`;
                                    }
                                    else if (sourceItem.domain === "IROs") {

                                        downloadUrl = `dcs-spo/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&fileName=${sourceItem.docTitle}`
                                    }
                                    else if (sourceItem.domain === "MIAs" && sourceItem.downloadUrl) {
                                        downloadUrl = `${sourceItem.downloadUrl.replace('/api/', '')}`
                                    }
                                    else {
                                        if (sourceItem.fileExtension !== 'pdf') {
                                            downloadUrl = `dcs-vv/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&docVer=${sourceItem.majorVersionNumber}&fileName=${sourceItem.docTitle}`
                                        }
                                        else {
                                            downloadUrl = `dcs-vv/download?connID=${sourceItem.connID}&csID=${sourceItem.csID}&docID=${sourceItem.docID}&fileType=${sourceItem.fileExtension}&fileName=${sourceItem.docTitle}`
                                        }
                                        if (sourceItem.status === "Approved Needs Review") {
                                            downloadUrl = downloadUrl + `&docState=anr`
                                        }

                                    }
                                }
                                if (downloadUrl) {
                                    setIsDownloadUrlAvailable(true);
                                    let windowlocation = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}${downloadUrl}`;
                                    let { domain, docVer, docID, orderedBy, orderedFor } = sourceItem;
                                    indexData(`/api/${downloadUrl}`, domain, docVer, docID, orderedBy, orderedFor)
                                    window.location.href = windowlocation;
                                }
                            }
                        }
                    }
                })
                .catch(function (error) {
                    //console.log(error);
                })
                .finally(async () => {
                    setProcessing(false)
                })
        } else {
            setProcessing(false)
        }
    }


    return (
        <>
            <ErrorBoundary>
                <div className="document-tab-outer-wrapper">
                    <div className="document-tab-wrapper">
                        <div className="document-header-wrapper">
                            <a>
                                <img src={IMSLogo} alt="" title="iMedical Search" />
                            </a>
                        </div>
                        {isProcessing ? <div style={{ textAlign: "center", padding: "10%" }}>
                            <img src={imsLoader} alt="Loading Filters" style={{ width: "10%" }} />
                        </div> : <div className="document-tab-inner-wrapper">
                            {isDownloadUrlAvailable ? <div className="document-tab-content-upper-card">
                                <span style={{ color: "#1076bc", fontSize: "20px" }}><b>Thank you.</b> Your document will soon be available here.</span><br />
                            </div> : <div className="document-tab-content-upper-card">
                                <span style={{ color: "#1076bc", fontSize: "20px" }}>Sorry! The full text is not available from iMedical Search sources.</span><br />
                            </div>}
                            <div>
                                <span style={{ fontSize: "12px" }}>
                                    Want to go to iMedical Search (iMS) Home Page? <Link to="/" style={{ cursor: "pointer", color: "#1076bc" }} target="_blank"> Click here </Link>to proceed.
                                </span><br />
                                {/* <span style={{fontSize:"12px"}}>You may close this tab after your document is downloaded.</span> */}
                            </div>
                            <div className="document-tab-disclaimer-text">
                                <b style={{ color: "red" }}>Disclaimer:</b> The purpose of iMS is to provide Scientific Affairs, Medical Affairs, and R&D personnel worldwide the ability to easily access and share key scientific content <b><i>internally.</i></b> External discussion, presentation, or dissemination of this content as well as use for validated processes must be compliant with local laws, regulations, and company policies and procedures.<br /> <b>Please Note:</b> iMS connects directly to the primary sources of content (e.g. RIMdocs, Veeva Vault) and crawls these content sources once nightly to ensure that you’re accessing only <b>current Approved Effective</b> versions of the documents.
                            </div>
                        </div>}

                        <div className="document-tab-inner-wrapper">
                            <div className="document-tab-content-upper-card">
                                <span style={{ color: "#1076bc", fontSize: "20px" }}><b>Thank you.</b> Your document will soon be available here.</span><br />
                            </div>
                            <div>
                                <span style={{ fontSize: "12px" }}>
                                    Click <Link to="/" style={{ cursor: "pointer", color: "#1076bc" }} target="_blank">here</Link> to go to iMedical Search - Home page.
                                </span><br />
                                <span style={{ fontSize: "12px" }}>You may close this tab after your document is downloaded.</span>
                            </div>
                            <div className="document-tab-disclaimer-text">
                                <b style={{ color: "red" }}>Disclaimer:</b> The purpose of iMS is to provide Scientific Affairs, Medical Affairs, and R&D personnel worldwide the ability to easily access and share key scientific content <b><i>internally.</i></b> External discussion, presentation, or dissemination of this content as well as use for validated processes must be compliant with local laws, regulations, and company policies and procedures.<br /> <b>Please Note:</b> iMS connects directly to the primary sources of content (e.g. RIMdocs, Veeva Vault) and crawls these content sources once nightly to ensure that you’re accessing only <b>current Approved Effective</b> versions of the documents.
                            </div>
                        </div>
                    </div>
                    <div style={{ margin: "0% 1.5%" }}>
                        <Footer
                            customWidth={width}
                            customheight={height} />
                    </div>
                </div>
            </ErrorBoundary>
        </>
    )
}