import InputRange from 'react-input-range'
import PropTypes from 'prop-types'
import React from 'react'
import {UtilityServices} from './../../utils/utilityServices'

export default function RecentlyAddedUpdatedRangeSlider(props) {
  const {
    handleRecentlyAddedUpdated,
    rangeMax,
    rangeMin,
    yearSelection,
  } = props

  const handleSelection = (vals) => {
    handleRecentlyAddedUpdated(vals)
  }

  const modifiedStyles = {
    "marginLeft": "10px",
    "marginRight": "10px",
  }

  const renderSelectedYearDisplay=(yearSelection)=>{
    return (<span>{UtilityServices.getSelectedYearDisplay(yearSelection)}</span>);
  }
  
  return (
    <>
      <div className="input-range__track background-track" />
      <div className="input-range-container" style={modifiedStyles}>
        <InputRange
          allowSameValues={true}
          formatLabel={value => `${value}`}
          maxValue={rangeMax}
          minValue={rangeMin}
          onChange={value => handleSelection(value)}
          step={1}
          value={yearSelection}
          draggableTrack={true}
          
        />
         
      </div>
      <div className="input-range-container-marker-wrapper" style={{paddingLeft:"7px", paddingRight:"7px"}}>
        <div className={(yearSelection.min<=0 && yearSelection.max>=0)? "input-range-container-marker": "input-range-container-marker-unselected"}></div>
        <div className={(yearSelection.min<=1 && yearSelection.max>=1)? "input-range-container-marker": "input-range-container-marker-unselected"}></div>
        <div className={(yearSelection.min<=2 && yearSelection.max>=2)? "input-range-container-marker": "input-range-container-marker-unselected"}></div>
        <div className={(yearSelection.min<=3 && yearSelection.max>=3)? "input-range-container-marker": "input-range-container-marker-unselected"}></div>
        <div className={(yearSelection.min<=4 && yearSelection.max>=4)? "input-range-container-marker": "input-range-container-marker-unselected"}></div>
        <div className={(yearSelection.min<=5 && yearSelection.max>=5)? "input-range-container-marker": "input-range-container-marker-unselected"}></div>
        <div className={(yearSelection.min<=6 && yearSelection.max>=6)? "input-range-container-marker": "input-range-container-marker-unselected"}></div>
      </div>
      <div className="year-display-container">
        <div className="selected-year-range-display">
          {renderSelectedYearDisplay(yearSelection)}
        </div>
      </div>
    </>
  )
}

RecentlyAddedUpdatedRangeSlider.propTypes = {
  handleYearSelection: PropTypes.func.isRequired,
  rangeMax: PropTypes.number.isRequired,
  rangeMin: PropTypes.number.isRequired,
  yearSelection: PropTypes.object.isRequired,
}
