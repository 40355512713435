import React, { Component, createRef } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

class ReactDocViewer extends Component {

    constructor(props) {
        super(props);

        this.state = { file: null, previewURL: '', docs: [], selectedDocs: [] }
    }
    async fileInputChange(event) {
        this.setState({ isFileProcessing: true }, () => {
            const files = event.target.files;
            const singleFile = files[0];
            var url = URL.createObjectURL(singleFile);
            this.setState({
                pdfFile: singleFile, previewURL: url, docs: [{
                    uri: URL.createObjectURL(singleFile),
                    fileName: singleFile.name,
                }],
                selectedDocs: Array.from(files)
            });
        });
    }

    render() {

        let { previewURL, file, docs, selectedDocs, iFrameUrl } = this.state;
        return (<>
            <input type="file" name="pdfUploadFile" id="pdfUploadFile" onChange={e => { this.fileInputChange(e) }} />
            {/* {iFrameUrl && <iframe title="l" src={iFrameUrl} style={{ maxHeight: 'auto', maxWidth: 'auto'}} />} */}

            {previewURL && <DocViewer
                // documents={docs}
                documents={selectedDocs.map((file) => ({
                    uri: window.URL.createObjectURL(file),
                    fileName: file.name,
                }))}
                style={{ height: "600px" }}
                pluginRenderers={DocViewerRenderers}
            />}

        </>)
    }
}

export default ReactDocViewer;