import { Modal } from "react-bootstrap";
import { useState } from "react";
import { popCancelBttn } from "../../assets";

export default function ExpandContentPopUpModal(props) {
    const {
        content,
        showModal,
        handleSaveContent,
        placeholder
    } = props
    const [showPop, setShow] = useState(showModal)
    const [txtValue, setTxtValue] = useState(content)
    const handleTextarea = (e) => {
        setTxtValue(e.target.value)
    }
    return (
        <Modal
            size="lg"
            show={showPop}
            keyboard={false}
            dialogClassName="popTimeOut expandContentPopUp"
            className="z-index-alertMessage"
            backdropClassName="z-index-alertMessage-backdrop"
        >
            <div style={{ padding: "25px" }}>
                <div className="expandContentCancel">
                    <img src={popCancelBttn} alt="" onClick={(e) => { setShow(false); handleSaveContent(txtValue) }} />
                </div>
                <textarea value={txtValue} placeholder={placeholder ?  placeholder  : ""} className="contentTextarea" onChange={(e) => handleTextarea(e)} />
            </div>
        </Modal>
    )
}