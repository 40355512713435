import { Component } from "react";
import AuthoringGPT from "../../components/Authoring/AuthoringGPT";

class Authoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
        }
    }
    componentDidMount() {
        let userExists = localStorage.getItem('userExists');
        if (!userExists) {
            console.log('Access Denied');
            this.props.history.push('/');
        }
        let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'));
        if (!userAccessGroup.includes("Authoring")) {
            console.log('Access Denied');
            this.props.history.push('/');
        }

    }

    render() {
        return (
            <AuthoringGPT customWidth={this.state.width} customHeight={this.state.height} />
        )
    }
}
export default Authoring    