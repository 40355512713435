import React, { useState } from 'react'
import { popCancelBttn, resetIcon, expandAppliedFilterMobile } from '../../assets';
import './FilterDisplay.css';

const formattedStringArr = (array, fn) => {
    var result = [];
    for (var i = 0; i < array.length; i++) {
        var mapping = fn(array[i]);
        if (i === array.length - 1) {
            result = result.concat(array[i]);
        }
        else {
            result = result.concat(mapping);
        }
    }
    return result;
}
const divider = () => {
    return (
        <span className="subfilter-divider"></span>
    )
}
const renderSubFilters = (facet, handleRemoveAppliedFilter) => (subFilter, key) => <>
    <span key={key} className="subfilter">
        <span className="subfilter-text" title={subFilter}>{formattedStringArr(subFilter.replaceAll('/', '|').split('|'), function (part) {
            return [part, <span>{divider()}</span>];
        })}</span>
        <img src={popCancelBttn} alt='' className='remove-filter clickable' title={`Remove ${subFilter}`} onClick={() => { handleRemoveAppliedFilter(facet + '/' + subFilter) }} />
    </span>
</>
export default function FilterDisplay(props) {
    const [showFilters, setShow] = useState(false);
    const {
        appliedFilters,
        handleClearFilters,
        handleRemoveAppliedFilter,
        customWidth
    } = props;
    
    //const service = new FilterService()
    //const reducedFilters = service.condenseFilterSet(allFilters,appliedFilters)
    const displayFilters = {}
    appliedFilters.forEach(filter => {
        const filterParts = filter.split('/')
        const filterTop = filterParts.shift()
        displayFilters[filterTop] = displayFilters[filterTop] || []
        displayFilters[filterTop].push(filterParts.join('/'))
    })
    //console.log(reducedFilters)
    //console.log(displayFilters)
    if (customWidth <= 600) {
        return (
            <div className="filter-selection-display-wrapper-mobile">
                <div className="applied-filter-header-section-mobile">
                    <div className="applied-filter-header-text-mobile">Applied Filters</div>
                    <div className="applied-filters-header-icons-wrapper">
                        <div onClick={e => setShow(!showFilters)}>
                            {!showFilters ?
                                <img src={expandAppliedFilterMobile} alt="" width="22" height="22" />
                                :
                                <img src={expandAppliedFilterMobile} alt="" width="22" height="22" style={{ transform: "rotate(180deg)" }} />
                            }

                        </div>
                        <div
                            onClick={e => handleClearFilters()}
                            className="clear-all-button-mobile" title="Remove All Filters">
                            <img src={resetIcon} alt="" style={{ paddingBottom: "3%" }} />
                        </div>
                        {/* <div onClick={e=>handleClearFilters()}>
                    <img src={cancelRedIcon} alt="" width="22" height="22"/>
                </div> */}
                    </div>
                </div>
                {showFilters &&
                    <div className="filter-display-item-wrapper filter-display-item-wrapper-mobile">
                        {Object.entries(displayFilters).map(([facet, refiners], index) =>
                            <div key={index} className="facet-item-wrapper">
                                <span className="facet-inner-wrapper">
                                {facet === 'Recently Added_Updated' ?
                                 <span className="parentFilter parentFilter-mobile">Recently Added/Updated</span> :
                                    facet === 'Document Subtypes' ?
                                        <span className="parentFilter parentFilter-mobile">{facet} (Submission-Related Documents Only)</span> :
                                        <span className="parentFilter parentFilter-mobile">{facet}</span>}
                                    <span className='subfilter-wrapper'>
                                        {refiners
                                            .map(renderSubFilters(facet, handleRemoveAppliedFilter))
                                            .reduce((elements, element) =>
                                                elements === undefined
                                                    ? element
                                                    : <>{elements}<span className='filter-divider'></span>{element}</>
                                            )
                                        }
                                    </span>
                                </span>
                            </div>
                        )}
                    </div>
                }
            </div>
        )
    }
    else {
        return (
            <div className="filter-selection-display-wrapper">
                <div className="applied-filter-header-section">
                    <span className="applied-filter-header-text">Applied Filters</span>
                    <span className="filter-display-remove-button-wrapper">
                        <button
                            onClick={handleClearFilters}
                            className="clear-all-button" title="Remove All Filters">
                            Reset Selection <img src={resetIcon} alt="" style={{ paddingBottom: "3%" }} />
                        </button>
                    </span>
                </div>
                <div className="filter-display-item-wrapper">
                    {Object.entries(displayFilters).map(([facet, refiners], index) =>
                        <div key={index} className="facet-item-wrapper">
                            <span className="facet-inner-wrapper">
                                {facet === 'Recently Added_Updated' ?
                                    <span className="parentFilter">Recently Added/Updated</span> :
                                    facet === 'Document Subtypes' ?
                                        <span className="parentFilter">{facet} (Submission-Related Documents Only)</span> :
                                        <span className="parentFilter">{facet}</span>}
                                <span className='subfilter-wrapper'>
                                    {refiners
                                        .map(renderSubFilters(facet, handleRemoveAppliedFilter))
                                        .reduce((elements, element) =>
                                            elements === undefined
                                                ? element
                                                : <>{elements}<span className='filter-divider'></span>{element}</>
                                        )
                                    }
                                </span>
                            </span>
                        </div>
                    )}
                </div>
            </div>
        )
    }
};