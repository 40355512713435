/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import { Component } from "react";
import ProductDetails from "./ProductDetails";
import ApiServices from "../../../utils/apiServices";
import { imsLoader, rightSideArrow } from "../../../assets";

class TherapeuraticArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            availableAreas: [],
            selectedAreas: [],
            selectedClasses: [],
            products: [],
            selectedArea: '',
            radioState: '',
            areaId: [],
            classId: [],
            productId: [],
            isLoaded: false,
            ischecked: true,
            tastateValue: "false",
        }
        //console.log(this.state);
    }
    async componentDidMount() {
        const service = new ApiServices();
        var res = await service.getAllTAreas()
        //console.log(res);
        if (res) {
            this.setState({
                availableAreas: res,
                isLoaded: true
            })
            if (this.props.selectedAreas.length > 0) {
                this.setState({
                    selectedAreas: this.props.selectedAreas
                })
            }
            if (this.props.selectedClasses.length > 0) {
                this.setState({
                    selectedClasses: this.props.selectedClasses
                })
            }
            if (this.props.products.length > 0) {
                this.setState({
                    products: this.props.products
                })
            }
            if (this.props.areaId.length > 0) {
                this.setState({
                    areaId: this.props.areaId
                })
            }
            if (this.props.classId.length > 0) {
                this.setState({
                    classId: this.props.classId
                })
            }
            if (this.props.productId.length > 0) {
                this.setState({
                    productId: this.props.productId
                })
            }
        }
    }
    handleChangeInTAreas = (event) => {
        this.handleIconChange(event);
    }
    handleIconChange = (event) => {

        // if (event.target.checked === true) {
        //     this.setState({
        //         selectedArea: event.target.value,
        //         radioState: event.target.value
        //     });
        // }
        // else {
        //     this.setState({
        //         selectedArea: '',
        //         radioState: ''
        //     });
        // }
        // OLD CODE
        if (event.target.attributes.tastate.value === "false") {
            this.setState({
                // selectedArea : event.target.value,
                // radioState: event.target.value,
                // tastateValue: "true"
                selectedArea: event.target.alt,
                radioState: event.target.alt
            });
            document.getElementById(event.target.id).attributes.tastate.value = "true"
        }
        else {
            this.setState({
                selectedArea: '',
                radioState: '',
                // tastateValue: "false"
            });
            document.getElementById(event.target.id).attributes.tastate.value = "false"
        }
    }
    findArrindex = (array, substring) => array.findIndex(element => {
        if (element.includes(substring)) {
            return true;
        }
    });
    tAreas = (areasListData) => {
        //console.log(areasListData);
        if (areasListData.length > 0) {
            if (this.props.customWidth < 600) {
                return (
                    areasListData.map((item, index) => {
                        return (
                            <div className="ta-wrapper-responsive" key={index}>
                                <div className="ta-val" >
                                    <input type="radio" id={`taRadi-id-${item.taid}`} value={item.taname} name="taRadioButton" key={item.taname + Math.random()} onChange={this.handleChangeInTAreas} checked={this.state.radioState === item.taname} tastate="false" />
                                    <a id={`sid-${item.taid}`} tastate="false" onClick={this.handleIconChange}>
                                        <img id={`imgid-${item.taid}`} width="10px" src={rightSideArrow} alt={item.taname} tastate="false" style={{ margin: "0 2px 3px 2px" }} />
                                    </a>
                                    <span className={this.state.selectedAreas.includes(item.taname) || this.findArrindex(this.state.areaId, `${item.taid}`) !== -1 || this.findArrindex(this.state.classId, `${item.taid}T`) !== -1 || this.findArrindex(this.state.productId, `${item.taid}T`) !== -1 ? 'item-checked' : ''}>{item.taname}</span>
                                </div>
                                {/* {(this.state.selectedArea && this.state.selectedArea===item.taname) ?
                                <ProductDetails selectedArea={this.state.selectedArea}
                                    selectedAreas={this.state.selectedAreas}
                                    selectedClasses={this.state.selectedClasses}
                                    products={this.state.products}
                                    areaId={this.state.areaId}
                                    classId={this.state.classId}
                                    productId={this.state.productId}
                                    onChangeSelectAreas={this.handleOnChangeArea}
                                    customWidth={this.props.customWidth}
                                    customHeight={this.props.customHeight}
                                    key={`ta-wrapper-responsive-ProductDetails-${item.taname}${Math.random()}`} />
                                : null} */}
                            </div>
                        )
                    })
                )
            }
            else {
                return (
                    areasListData.map((item, index) => {
                        ;
                        return (
                            <div className="ta-val" key={index}>
                                {/* <img src={rightArrow} alt="arrow" className="locationRightArrow" />
                                <input type="checkbox" id={`id-${item.taid}`} value={item.taname} name="taRadioButton" key={item.taname + Math.random()}
                                    // onChange={this.handleChangeInTAreas} 
                                    tastate="false"
                                    // tastate={this.state.tastateValue}
                                    // checked="checked"
                                    checked={this.state.radioState === item.taname}  
                                    // checked={!this.state.ischecked}
                                    // defaultChecked={!this.state.ischecked}
                                    class="k-checkbox" onChange={this.handleIconChange} style={{ margin: "0 0 -7px 15px" }} />
                                <div id="prod" className={this.state.selectedAreas.includes(item.taname) ? 'item-checked' : ''}>{item.taname}</div> */}

                                <input type="radio" id={`id-${item.taid}`} value={item.taname} name="taRadioButton" key={item.taname + Math.random()} onChange={this.handleChangeInTAreas} checked={this.state.radioState === item.taname} tastate="false" />
                                {/* <input type="checkbox" id={`id-${item.taid}`} value={item.taname} name="taRadioButton" key={item.taname+Math.random()}  onChange={this.handleChangeInTAreas} checked={this.state.radioState === item.taname}  class="k-checkbox"/> */}
                                <a id={`sid-${item.taid}`} tastate="false" onClick={this.handleIconChange}>
                                    <img id={`imgid-${item.taid}`} width="10px" src={rightSideArrow} alt={item.taname} tastate="false" style={{ margin: "0 2px 3px 2px" }} />
                                </a>
                                <span className={this.state.selectedAreas.includes(item.taname) || this.findArrindex(this.state.areaId, `${item.taid}`) !== -1 || this.findArrindex(this.state.classId, `${item.taid}T`) !== -1 || this.findArrindex(this.state.productId, `${item.taid}T`) !== -1 ? 'item-checked' : ''}>{item.taname}</span>
                            </div>
                        )
                    })
                )
            }
        }
    }
    handleOnChangeArea = (selectedAreas, selectedClasses, products, areaId, classId, productId) => {
        this.setState({
            selectedAreas: selectedAreas,
            selectedClasses: selectedClasses,
            products: products,
            areaId: areaId,
            classId: classId,
            productId: productId
        }, this.props.onSelectAreas(selectedAreas, selectedClasses, products, areaId, classId, productId));
        //console.log(this.state.selectedAreas,this.state.selectedClasses,this.state.products);
    }
    render() {
        if (this.props.customWidth < 600) {
            return (
                <div className="therapeuraticAreaContainer">
                    {this.state.isLoaded ?
                        <div>
                            <div className="therapeuraticAreaInnerWrapper">
                                {this.tAreas(this.state.availableAreas)}
                            </div>
                            {this.state.selectedArea !== '' ?
                                <ProductDetails selectedArea={this.state.selectedArea}
                                    selectedAreas={this.state.selectedAreas}
                                    selectedClasses={this.state.selectedClasses}
                                    products={this.state.products}
                                    areaId={this.state.areaId}
                                    classId={this.state.classId}
                                    productId={this.state.productId}
                                    onChangeSelectAreas={this.handleOnChangeArea}
                                    customWidth={this.props.customWidth}
                                    customHeight={this.props.customHeight} />
                                : null}
                        </div>
                        : <div style={{ textAlign: "center" }}>
                            <img src={imsLoader} alt="Loading Data" style={{ width: "10%" }} />
                        </div>}
                </div>
            )
        } else {
            return (
                <div className="therapeuraticAreaContainer">
                    {this.state.isLoaded ?
                        <div>
                            <div className="therapeuraticAreaInnerWrapper">
                                {/* <div className="topCircle"></div> */}
                                {this.tAreas(this.state.availableAreas)}
                            </div>
                            {this.state.selectedArea !== '' ?
                                <ProductDetails selectedArea={this.state.selectedArea}
                                    selectedAreas={this.state.selectedAreas}
                                    selectedClasses={this.state.selectedClasses}
                                    products={this.state.products}
                                    areaId={this.state.areaId}
                                    classId={this.state.classId}
                                    productId={this.state.productId}
                                    onChangeSelectAreas={this.handleOnChangeArea}
                                    customWidth={this.props.customWidth}
                                    customHeight={this.props.customHeight} />
                                : null}
                        </div>
                        : <div style={{ textAlign: "center" }}>
                            <img src={imsLoader} alt="Loading Data" style={{ width: "10%" }} />
                        </div>}

                </div>
            )
        }

    }
}
export default TherapeuraticArea;