/* eslint-disable array-callback-return */
import { Component } from "react";
import { Link } from "react-router-dom";
import { rightSideArrow, popCancelBttn, iIcon } from "../../assets";
import config from "../../config/config";
import trackingService from "../../services/trackingService";
import "./ContentShortcuts.css";

class MedicalInsightsReportsTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCSModal: false,
      arrayOfInsightsReports: [],
      showInfo: false,
    };
  }
  async componentDidMount() {
    this.setState({
      arrayOfInsightsReports: config.iMedicalInsightsReports,
    });
  }
  componentDidUpdate = () => {
    if (this.props.showMIRCSModal !== this.state.showCSModal) {
      this.setState({ showCSModal: this.props.showMIRCSModal });
    }
  };
  generateLeftSideLists = (arrayOfList) => {
    if (arrayOfList.length > 0) {
      return arrayOfList.map((items, index) => {
        let url = `/results?query=${items.title}&sbQuery=${
          items.title
        }&q=All%20Fields&filters=&f1=taIRO&fval1=${items.title.replace(
          "/",
          "_"
        )}&f2=domain&fval2=IROs&fcount=2&cspath=iMedical Insights Reports-${
          items.title
        }&csproduct=''`;
        if (items.partition === "leftPart") {
          return (
            <li key={index} className="cs-mir-modalListing">
              <img
                src={iIcon}
                alt="i-icon"
                width={18}
                height={18}
                title={items.infoTitle}
                className="cs-list-i-icon"
              />
              <Link
                to={url}
                target="_blank"
                className="cs-modalLink"
                onClick={() => this.handelInsightsReportsLinks(items.title)}
              >
                {items.title}
              </Link>
            </li>
          );
        }
      });
    }
  };
  generateRightSideLists = (arrayOfList) => {
    if (arrayOfList.length > 0) {
      return arrayOfList.map((items, index) => {
        let url = `/results?query=${items.title}&sbQuery=${items.title}&q=All%20Fields&filters=&f1=taIRO&fval1=${items.title}&f2=domain&fval2=IROs&fcount=2&cspath=iMedical Insights Reports-${items.title}&csproduct=''`;
        if (items.partition === "rightPart") {
          return (
            <li key={index} className="cs-mir-modalListing">
              <img
                src={iIcon}
                alt="i-icon"
                width={18}
                height={18}
                title={items.infoTitle}
                className="cs-tile-i-icon"
              />
              <Link
                to={url}
                target="_blank"
                className="cs-modalLink"
                onClick={() => this.handelInsightsReportsLinks(items.title)}
              >
                {items.title}
              </Link>
            </li>
          );
        }
      });
    }
  };
  handelInsightsReportsLinks = (title) => {
    const ts = new trackingService();
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      localStorage.getItem("companyname"),
      `Visited ${title}`,
      "",
      window.location.href,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      `iMedical Insights Reports-${title}`,
      ""
    );
  };
  onMouseEnterInfo = () => {
    this.setState({
      showInfo: true,
    });
  };
  onMouseLeaveInfo = () => {
    this.setState({
      showInfo: false,
    });
  };
  showDetails = () => {
    const ts = new trackingService();
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      localStorage.getItem("companyname"),
      "Expanded iMedical Insights Reports tile under content shortcuts",
      "",
      window.location.href
    );
    this.setState({ showCSModal: !this.state.showCSModal }, () => {
      this.props.handelMIRCardDetail(this.state.showCSModal);
    });
  };
  handleCloseModal = () => {
    this.setState({ showCSModal: false }, () => {
      this.props.handelMIRCardDetail(this.state.showCSModal);
    });
  };
  render() {
    // var privacyHtml = `
    //     <div class="cs-modalContent">
    //         <p>Integrated readouts: IROs are analysis of voice of customer data that uncover signal, themes, and actionable insights.</p>
    //     </div> `;
    return (
      <div className="contentShortcutInnerWapper">
        <div
          className="cardContainer"
          onClick={this.showDetails}
          style={{
            borderTop: this.state.showCSModal ? "5px solid #0074B0" : "none",
          }}
        >
          <div
            className="cardInnerWapper"
            style={this.state.showCSModal ? { borderBottom: "none" } : {}}
          >
            <div
              className="card-leftPart cs-right-r1-tile-"
              style={
                this.props.customWidth >= 1000
                  ? { textAlign: "center", position: "absolute", width: "31%" }
                  : {}
              }
            >
              <small>
              <span className="cs-name-bold">iMedical Insights Reports</span> <br /> (Integrated Read Outs)
              </small>
            </div>
            <div
              className="card-iconsPart"
              style={
                this.props.customWidth >= 1000 ? { marginLeft: "auto" } : {}
              }
            >
              <img
                src={iIcon}
                alt="i-icon"
                width={18}
                height={18}
                onMouseEnter={this.onMouseEnterInfo}
                onMouseLeave={this.onMouseLeaveInfo}
                className="cs-tile-i-icon"
              />
              <div className="card-rightPart">
                <img
                  src={rightSideArrow}
                  alt="down-arrow"
                  className="cs-downArrow"
                  style={{
                    transform: this.state.showCSModal ? "rotate(270deg)" : null,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.showInfo && (
          <div className="cs-tile-wrapper">
            <div className="cs-tooltip-uparrow"></div>
            <span className="tooltiptext cs-tooltip-bottom">
              This tile provides direct links to iMedical Insights Reports.
            </span>
          </div>
        )}
        {this.state.showCSModal && (
          <div
            className="cs-modalInner"
            style={
              this.props.customWidth >= 1000
                ? { position: "absolute", width: "97.9%" }
                : {}
            }
          >
            <div>
              <div class="popup-cancel-btn-wrapper">
                <img
                  src={popCancelBttn}
                  alt=""
                  onClick={this.handleCloseModal}
                />
              </div>
              <div>
                <div
                  className="cs-modalHeader"
                  style={{ justifyContent: "center" }}
                >
                  <div style={{ textAlign: "center", width: "95%" }}>
                    <div class="modal-title h4">
                      <small
                        className="cs-modalTitle"
                        style={{ fontSize: "16px" }}
                      >
                        Integrated readouts: IROs are analysis of voice of
                        customer data that uncover signal, themes, and
                        actionable insights.
                      </small>
                    </div>
                  </div>
                </div>
                <div class="modal-body">
                  <div className="iMedicalInsightsReports">
                    <div className="cs-imir-modalList">
                      <ul style={{ listStyle: "none" }}>
                        {this.generateLeftSideLists(
                          this.state.arrayOfInsightsReports
                        )}
                      </ul>
                    </div>
                    <div
                      className="cs-imir-modalList"
                      style={{
                        marginTop: this.props.customWidth < 600 && "-15px",
                      }}
                    >
                      <ul style={{ listStyle: "none" }}>
                        {this.generateRightSideLists(
                          this.state.arrayOfInsightsReports
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MedicalInsightsReportsTile;
