import { useEffect, useState } from "react";
export default function Button(props) {
    const {
        customColor,
        text,
        action,
        isDisabled
    } = props;
    useEffect(() => {
        setColor(customColor); 
    }, [customColor]) 
    const [color, setColor] = useState(customColor);
    const [bgColor, setBgColor] = useState("#fff")
    const styles = {
        color: color,
        border: `1px solid ${customColor}`,
        background: bgColor,
        minWidth: "70px",
        borderRadius: "5px",
        cursor:isDisabled?"none":"pointer"
    }
    return (
        <button style={styles} onClick={(e) => action()} disabled={isDisabled} onMouseEnter={() => { 
            if(!isDisabled){
                setColor('#fff'); 
                setBgColor(customColor);
            }}} onMouseLeave={() => { 
                if(!isDisabled){
                setColor(customColor); 
                setBgColor('#fff');
              }}}>{text}</button>
    )
}