/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import ErrorAlert from './ErrorAlert';
import UnauthorizedPage from './UnauthorizedPage';
import Cookies from 'universal-cookie';
// import Loader from '../Components/Loader';
import pkceChallenge from 'pkce-challenge';
import {
  authorizationUrl,
  clientId,
  redirectUri,
  oauthServerUrl,
  scope,
  // client_secret
} from './settings-code';
import { imsLoader } from "./../assets/index";
const _cookies = new Cookies();

let isAuthenticated = false; //variable to check if the user is authenticated
let UserData; //variable to hold all user data
let UserGeoData;
export default function AuthorizationCodeExample(props) {
  const [user, setUser] = useState(null); //stores the user data
  const [userGeo, setUserGeo] = useState(null); //stores the user data
  const [error, setError] = useState(null); //error flag
  const [showUnauthorizedPage, setShowUnauthorizedPage] = useState(false); //flag to show unauthorized page
  const name = useRef(null); //stores the code returned by fedlogin  
  const authState = useRef(null); //stores the state returned by fed login
  const rerenders = useRef(0);


  var url = new URL(window.location.href); //decalres a new URL object
  name.current = url.searchParams.get("code"); //reads url to store the code
  authState.current = url.searchParams.get("state"); //reads url to store the state

  useEffect(() => { //runs every time the state changes
    rerenders.current += 1;  //calculates the number of re-renders
  })

  if (rerenders.current > 12 && !showUnauthorizedPage) { //checks if the number to re-renders are exceeding a threshold
    setShowUnauthorizedPage(true); //changes the state of showUnauthorizedPage
  }

  function getCode() { //function to retireve code from fedloging
    if (document.referrer && document.referrer.length > 0) {
      let siteUrl = window.location.origin;
      if (!document.referrer.startsWith(siteUrl) && !document.referrer.startsWith('https://fedlogin.jnj.com')) {
        localStorage.setItem(`documentReferrer`, document.referrer);
        var timeout = 1000 * 28800;
        _cookies.set(`documentReferrer`, encodeURIComponent(document.referrer), {expires: new Date(Date.now() + timeout) });
      }
    }
    if (window.location.pathname !== '/') {
      localStorage.setItem('returnURL', window.location.href);
    }
    let challenge = pkceChallenge(86); //returns a challenge object that has the code_challenge and code_verifier
    localStorage.setItem('code_verifier', challenge.code_verifier);
    //generating the search string and redirecting the user to fedlogin page
    let search = '?client_id=' + clientId + '&scope=' + scope + '&redirect_uri=' + encodeURI(redirectUri) + '&response_type=code&code_challenge=' + challenge.code_challenge + '&code_challenge_method=S256&state=' + challenge.code_verifier;
    window.location.href = authorizationUrl + search;
  }

  function getUserDataWithAccessToken() { //function to retireve access token from fedlogin
    let details = { //forming the payload
      grant_type: 'authorization_code',
      client_id: clientId,
      redirect_uri: redirectUri,
      code: localStorage.getItem('code'),
      code_verifier: localStorage.getItem("code_verifier"),
      //client_secret:client_secret
    };
    var formBody = []; //initializing the form body
    for (var property in details) { //forming the payload in a key=value format
      var encodedKey = property;
      var encodedValue = details[property];
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&"); //joins the formbody with '&' to generate the final formbody
    fetch(`${oauthServerUrl}as/token.oauth2`, { //fetching the token from fedlogin
      method: 'POST',
      mode: 'cors',
      //credentials: 'include',
      body: formBody,
      headers: {
        'Authorization': `Basic aW1lZGljYWxzZWFjcmgzOlBIZlQwbUdxUE4zSE1vbDVNT25GSVdtemdxSzVwU1dDdkZKbDh5UkN2eUR4YUhDa2xRS0VOUjc2akZ6U3FFYjQ=`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then(res => res.json())
      .then((data) => {
        JSON.stringify(data);
        //console.log(data);
        localStorage.setItem('AccessToken', data.access_token);
        localStorage.setItem('ExpiresIn', setExpireTime(data.expires_in)); //calls setExpireTime() with expires_in as the parameter
        localStorage.setItem('RefreshToken', data.refresh_token);
        localStorage.removeItem('code');
        localStorage.removeItem('code_verifier');
        getGeoInfo();
        getUserData(data.access_token); //calls getUserData() with the received access token as the parameter
      })
  }

  function getUserDataWithRefreshToken() { //function to retrieve access token from refresh token
    let details = { //forming the payload
      grant_type: 'refresh_token',
      refresh_token: localStorage.getItem('RefreshToken'),
    };
    var formBody = []; //initializing the form body
    for (var property in details) { //forming the payload in a key=value format
      var encodedKey = property;
      var encodedValue = details[property];
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&"); //joins the formbody with '&' to generate the final formbody
    fetch(`${oauthServerUrl}as/token.oauth2`, { //fetch call to get the access token from refresh token
      method: 'POST',
      body: formBody,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    }).then(res => {
      //checking if refresh token is tampered, expired or malformed
      if (res.ok) {  //if access token is received
        res.json()
          .then((data) => {
            localStorage.setItem('AccessToken', data.access_token);
            localStorage.setItem('ExpiresIn', setExpireTime(data.expires_in));
            localStorage.setItem('RefreshToken', data.refresh_token);
            getGeoInfo();
            getUserData(data.access_token); //calls getUserData() with the received access token as the parameter
            return data.access_token;
          })
      } else { //if access token is not received, clears all auth related data in the browser storage and calls getCode() 
        localStorage.clear();
        localStorage.removeItem('RefreshToken');
        getCode(); //calls getCode() to receive code from fedlogin
      }
    })

  }

  function getUserData(accessToken) { //function to retrieve user information from access token
    fetch(`${oauthServerUrl}idp/userinfo.openid`, { //fetch call to retrieve user information
      method: 'GET',
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    })
      .then(res => {
        //checking if access token is tampered, expired or malformed
        if (res.ok) { //if user data is received successfully
          res.json()
            .then((data) => {
              _cookies.remove("sid");
              _cookies.remove("isSidExpired");
              localStorage.removeItem("sid");
              localStorage.removeItem("jnjDepartment");
              localStorage.removeItem("userdisplayName");
              localStorage.removeItem("wwid");
              localStorage.removeItem("function");
              localStorage.removeItem("userExists");
              localStorage.removeItem("russianuser");
              localStorage.removeItem("doctypepref");
              localStorage.removeItem("contentpreference");
              localStorage.removeItem("companyname");
              localStorage.removeItem("userproducts");
              localStorage.removeItem("region");
              localStorage.removeItem("username");
              localStorage.removeItem("datatracking");
              localStorage.removeItem("accessgroup");
              setUser(data); //set the user data
              localStorage.setItem('wwid', JSON.stringify(data.sub));
              if (data && data.name) {
                localStorage.setItem(
                  "userdisplayName",
                  JSON.stringify(data.name)
                );
              }
            })
            .catch(setError);
        } else { //if user data is not received, check if refresh token is available
          if (localStorage.getItem('RefreshToken') == null || localStorage.getItem('RefreshToken') === "undefined") {
            //if refresh token is not available, clear the browser storage and getcode()
            localStorage.clear();
            localStorage.removeItem('RefreshToken');
            getCode(); //calls getCode() to receive code from fedlogin
          } else {
            getUserDataWithRefreshToken(); //calls getUserDataWithRefreshToken() to get access token from refresh token
          }
        }
      })
  }
  function getGeoInfo() {

    fetch(`https://ipapi.co/json/`, { //fetch call to retrieve user geo information
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
    })
      .then(res => {
        if (res.ok) { //if user data is received successfully
          res.json()
            .then((data) => {
              setUserGeo(data)
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
};

  function setExpireTime(expiryTime) { //function to set expiry time for access token
    let d = new Date(); //declaring a date object
    let newExpiryTime = d.getTime() + 1000 * expiryTime //setting new expiry time
    return newExpiryTime;
  }

  function isTokenExpired() { //function to check if the access token is expired
    let d = new Date(); //declaring a date object
    let expiryTime = localStorage.getItem('ExpiresIn');
    return expiryTime < d.getTime(); //returns true if token is expired
  }

  let isAuthenticationRequired = false; //initializing a variable to check if code generation is required or the user is already authenticated

  useEffect(() => { //runs in the componentDidMount lifecyle stage of the component 
    //check if access token is not available
    if (localStorage.getItem('AccessToken') === null || localStorage.getItem('AccessToken') === "undefined") {
      if (localStorage.getItem('RefreshToken') === null || localStorage.getItem('RefreshToken') === "undefined") {
        isAuthenticationRequired = true;
      } else {
        getUserDataWithRefreshToken();
      }
    } else { //if access token is available
      if (!isTokenExpired()) { //check if the access token is not expired
        getGeoInfo();
        getUserData(localStorage.getItem('AccessToken')); //calls getUserData() with the existing access token as the parameter
      } else { //if access token is expired, check if refresh token is available
        if (localStorage.getItem('RefreshToken') !== "undefined") { //if refresh token is available
          getUserDataWithRefreshToken(); //calls getUserDataWithRefreshToken()
        } else {
          isAuthenticationRequired = true;
          getCode();//calls getCode()
        }
      }
    }

    if (isAuthenticationRequired) { //check if authentication is required 
      let state = url.searchParams.get("state");
      let codeVerifier = localStorage.getItem("code_verifier");
      if (state === codeVerifier && codeVerifier !== null) { //if code_verifier is present
        localStorage.setItem('code', name.current);
        getUserDataWithAccessToken(); //calls getUserDataWithAccessToken()
      } else {
        getCode(); //calls getCode()
      }
    }
  }, [])

  //if user data is successfully retrieved
  if (user) {
    if (user.email) {
      isAuthenticated = true;
      UserData = user;
      if (localStorage.getItem('returnURL')) {
        let returnURL = localStorage.getItem('returnURL');
        if (returnURL) {
          localStorage.removeItem('returnURL');
          window.location.href = returnURL;
        }
      }
    }
  }
  if(userGeo){
    UserGeoData=userGeo;
  }

  return (
    <>
      {error && <div>Error<ErrorAlert error={error} /></div>}
      {showUnauthorizedPage ? <UnauthorizedPage /> : user ? (user.email && props.children) :
        <div style={{ textAlign: "center" }}>
          <p><b>Please wait while the site is loading...</b></p>
          <div style={{ textAlign: "center", padding: "10%" }}>
            <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
          </div>
        </div>}
    </>
  );
}

export { isAuthenticated, UserData,UserGeoData }
