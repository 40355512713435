import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Button from "../Button";
import { imsLoader, popCancelBttn } from "../../assets"
import QueryHistory from '../History/QueryHistory'
import RecentlyViewed from '../History/RecentlyViewed'
import axios from 'axios';
import { LocationOrigin } from "../../utils/utilityServices";

export default function HistoryPopUp(props) {
    const { display, handleClose } = props;
    const [recentlyViewedResult, setRecentlyViewedResult] = useState([]);
    const [queryHistoryResult, setQueryHistoryResult] = useState([]);
    const [isProcessing, setProcessing] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            await fetchQueryHistory();
            await fetchRecentlyViewed();
          }
          fetchData()
    }, [])
    // useEffect(() => {
    //     setProcessing(true);
    //     let wwid = JSON.parse(localStorage.getItem('wwid'))
    //     let _recentlyViewed = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}analytics/rv?wwid=${wwid}`
    //     let _queryHistory = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}analytics/qh?wwid=${wwid}`
    //     const requestRecentlyViewed = axios.get(_recentlyViewed);
    //     const requestQueryHistory = axios.get(_queryHistory);
    //     axios.all([requestRecentlyViewed, requestQueryHistory]).then(axios.spread((...responses) => {
    //         const responseRecentlyViewed = responses[0]
    //         const responseQueryHistory = responses[1]
    //         if (responseRecentlyViewed.status === 200) {
    //             setRecentlyViewedResult(responseRecentlyViewed.data);
    //         }
    //         if (responseQueryHistory.status === 200) {
    //             setQueryHistoryResult(responseQueryHistory.data);
    //         }

    //         // use/access the results 
    //     })).catch(errors => {
    //         // react on errors.
    //     }).finally(async () => {
    //         setProcessing(false)
    //     })
    // }, [])

   const  fetchQueryHistory= async() => {
        setProcessing(true);
        let wwid = JSON.parse(localStorage.getItem('wwid'))
        var url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}analytics/qh?wwid=${wwid}&size=50`
        let config = {
            method: 'GET',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios(config)
            .then((response) => {
                 setQueryHistoryResult(response.data);
            })
            .catch(function (error) {
                //console.log(error);
            })
            .finally(() => {
                setProcessing(false)
            })
    }


    const fetchRecentlyViewed=async()=> {
        setProcessing(true);
        let wwid = JSON.parse(localStorage.getItem('wwid'))
        var url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}analytics/rv?wwid=${wwid}&size=50`
        let config = {
            method: 'GET',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios(config)
            .then((response) => {
                setRecentlyViewedResult(response.data);
            })
            .catch(function (error) {
                //console.log(error);
            })
            .finally(() => {
                setProcessing(false)
            })
    }


    return (
        <>
            <Modal
                show={display}
                size="lg md sm xs"
                centered
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="popup-transition popup-transition-qh-rv"
                scrollable={true}
            >
                <div style={{ borderLeft: `5px solid #6CC24A` }}>
                    <div className="popup-cancel-btn-wrapper">
                        <img src={popCancelBttn} alt="" onClick={(e) => { handleClose(false) }} />
                    </div>
                    <div style={{ padding: "0 5%" }}>
                        <Modal.Header style={{ justifyContent: "center", borderBottomColor: "#6CC24A" }}>
                            <div style={{ textAlign: "center" }}>
                                <Modal.Title>
                                    <span style={{ color: "#6CC24A" }}>History</span>
                                </Modal.Title>
                            </div>
                        </Modal.Header>
                        <Modal.Body scrollable={true}>
                            <div>
                                {isProcessing ?
                                    <div style={{ textAlign: "center", padding: "10%" }}>
                                        <img src={imsLoader} alt="Loading Filters" style={{ width: "10%" }} />
                                    </div> :
                                    <>
                                        <RecentlyViewed Result={recentlyViewedResult} />
                                        <QueryHistory Result={queryHistoryResult} />
                                    </>
                                }
                            </div>
                        </Modal.Body>
                        <div style={{ textAlign: "center", padding: "1% 0" }}>
                            <Button text="Close" customColor="#1DA1E5" action={() => { handleClose(false) }} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}