/* eslint-disable no-useless-constructor */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component, useEffect } from "react";
// import {
//   MsalProvider,
//   MsalContext,
//   AuthenticatedTemplate,
//   UnauthenticatedTemplate,
//   useMsal,
// } from "@azure/msal-react";
// import { PublicClientApplication } from "@azure/msal-browser";
import { v4 as uuidv4 } from "uuid";
import "./App.css";
import "./fontAwesome";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// import { loginRequest, msalConfig } from "../authConfig";
import {
  deviceType,
  osName,
  browserName,
  fullBrowserVersion,
  // isMobile,
  // isSafari,
} from "react-device-detect";
import Routes from "../Route";
import trackingService from "../services/trackingService";
import ApiServices from "../utils/apiServices";
import Cookies from "universal-cookie";
// import Button from "../components/Button";
import GenAIAnsweringContextProvider from './GenAIAnswering2.0/GenAIAnsweringContextProvider';

import
// Authorization, 
{
  isAuthenticated,
  UserData
} from "../auth/Authorization";
// import configDetails from "./../config/config"
import Services from "../utils/services";

// const pca = new PublicClientApplication(msalConfig);
const _cookies = new Cookies();
class App extends Component {
  constructor(props) {
    super(props);
    //this.handleWindowClose = this.handleWindowClose.bind(this);
  }
  componentDidMount() {
    this.initPreviewCall();
    //let mail=JSON.parse(localStorage.getItem('username')).toLowerCase();
    window.onload = this.handleWindowClose(true);
    let self = this;
    window.onunload = async function (ev) {
      if (ev) {
        ev.preventDefault();
        ev.returnValue = false;
        await self.handleWindowClose(false);
      }
    };
    //window.addEventListener('visibilitychange',(ev)=>{ this.logDataOnWindowClose(mail,false)}, false);
    // Added for back button press
    window.addEventListener("popstate", (event) => {
      if (event.state) {
        event.stopPropagation();
        window.location.replace(window.location.href);
      } else {
        window.location.replace(window.location.href);
      }
    });
    ////
  }
  initPreviewCall = () => {
    let service = new ApiServices();
    service.initPreviewCall();
    service.getSearchKeywordCountForPreview('cc88e690f466a1fecdedcc51f5dbca32');
  }
  handleWindowClose = (status) => {
    //console.log("I am triggred");
    //debugger;
    if (localStorage.getItem("username") !== null) {
      let mail = JSON.parse(localStorage.getItem("username")).toLowerCase();
      let service = new ApiServices();
      service.updateActiveStateinDb(mail, status);
    }
  };
  render() {
    return (
      // <MsalProvider instance={pca}>
      //   <AuthenticatedTemplate>
      //     <WelcomeUser></WelcomeUser>
      //     <div className="App">
      //       <Routes />
      //     </div>
      //   </AuthenticatedTemplate>
      //   <UnauthenticatedTemplate>
      //   <Authorization>
      //   {isAuthenticated && <AuthUsers />}
      //     </Authorization>
      //   </UnauthenticatedTemplate>
      // </MsalProvider>

      // <Authorization>
      <>
        {isAuthenticated && (
          <>
            <WelcomeUser></WelcomeUser>
            <GenAIAnsweringContextProvider>
              <div className="App">
                <Routes />
              </div>
            </GenAIAnsweringContextProvider>
          </>
        )}
      </>
      // </Authorization>
    );
  }
}
// function handleWindowClose(username, status) {
//   let mail = username.toLowerCase();
//   let service = new ApiServices();
//   service.updateActiveStateinDb(mail, status);
// }
async function jjeds() {
  let services = new ApiServices();
  let jjeds = await services.getJJEDSdetails(UserData.sub);
  return jjeds;
}
function WelcomeUser() {
  // const { accounts } = useMsal();
  // const username = accounts[0].username;

  const username = UserData.email;
  //console.log(username)
  var guid = uuidv4();
  // const sid = JSON.parse(localStorage.getItem("sid"));
  let siteUrl = window.location.origin;
  const ts = new trackingService();
  let service = new Services();
  let apiServices = new ApiServices();
  var device = "";
  if (deviceType === "browser") {
    device = "Desktop";
  }

  localStorage.setItem("username", JSON.stringify(username));
  //  localStorage.setItem('username',JSON.stringify('ims@its.jnj.com'));
  localStorage.setItem("deviceType", JSON.stringify(device));
  localStorage.setItem("deviceOS", JSON.stringify(osName));
  localStorage.setItem("browserName", JSON.stringify(browserName));
  localStorage.setItem("browserVersion", JSON.stringify(fullBrowserVersion));
  //console.log('Entry Point ' + document.referrer);
  useEffect(() => {
    userValidation().then(() => {
      service.logServiceForLoginIssus(`Called userValidation  API successfully for User: ${username.toLowerCase()}`);
    }).catch((error) => {
      service.logServiceForLoginIssus(`Error in userValidation API, error=${JSON.stringify(error)}, Call userValidation API for User: ${username.toLowerCase()}`);
      console.log(error);
    });

    let isCameFromSameSite = true;
    let documentReferrer = '';

    if (_cookies.get('documentReferrer')) {
      documentReferrer = _cookies.get('documentReferrer');
      documentReferrer = decodeURIComponent(documentReferrer);
      if (documentReferrer) {
        isCameFromSameSite = documentReferrer.startsWith(siteUrl)
      }
    }

    if (document.referrer && document.referrer.length > 0) {

      if ((document.referrer.startsWith(siteUrl) || document.referrer.startsWith('https://fedlogin.jnj.com')) && isCameFromSameSite) {
        // user came from an internal link
        console.log("Came from Internal Site");
        let sid = _cookies.get("sid");
        let isSidExpired = _cookies.get("isSidExpired");
        if (!sid || !isSidExpired) {
          var timeout = 1000 * 28800;
          _cookies.set("sid", JSON.stringify(guid), { path: "/" });
          _cookies.set("isSidExpired", JSON.stringify('NO'), { path: "/", expires: new Date(Date.now() + timeout) });
          Promise.resolve(jjeds()).then((jjeds) => {
            service.logServiceForLoginIssus(`'Came from Internal Site', 'if (!sid || !isSidExpired)' condition true, Call jjeds API for User: ${username.toLowerCase()}`);
            localStorage.setItem("sid", JSON.stringify(guid));
            if (jjeds && jjeds.jnjmsupn) {
              localStorage.setItem("username", JSON.stringify(jjeds.jnjmsupn.toLowerCase()));
            }
            if (!(localStorage.getItem("userdisplayName")) && jjeds && jjeds.jnjdisplayname) {
              localStorage.setItem("userdisplayName", JSON.stringify(jjeds.jnjdisplayname));
            }
            else if (!(localStorage.getItem("userdisplayName")) && jjeds && jjeds.displayname) {
              localStorage.setItem("userdisplayName", JSON.stringify(jjeds.displayname));
            }
            if (!(localStorage.getItem("wwid")) && jjeds && jjeds.uid) {
              localStorage.setItem("wwid", JSON.stringify(jjeds.uid));
            }
            if (!(localStorage.getItem("jnjDepartment")) && jjeds && jjeds.jnjdepartmentdescription) {
              localStorage.setItem(
                "jnjDepartment",
                JSON.stringify(jjeds.jnjdepartmentdescription)
              );
              apiServices.UpdateCurrentdepartment(jjeds.jnjdepartmentdescription, jjeds.uid)
            } else {
              localStorage.setItem(
                "jnjDepartment",
                JSON.stringify("NA")
              );
            }
            // For User Entry Tracking
            if (localStorage.getItem("userdisplayName") &&
              localStorage.getItem("wwid") &&
              localStorage.getItem("jnjDepartment")
            ) {
              service.logServiceForLoginIssus(`'Came from Internal Site', 'if (localStorage.getItem("userdisplayName") && localStorage.getItem("wwid") && localStorage.getItem("jnjDepartment"))' condition True, Call jjeds API for User: ${username.toLowerCase()}`);
              ts.indexTrackingData(
                localStorage.getItem("userdisplayName"),
                localStorage.getItem("wwid"),
                localStorage.getItem("username"),
                localStorage.getItem("function"),
                localStorage.getItem("region"),
                localStorage.getItem("userproducts"),
                localStorage.getItem("doctypepref"),
                localStorage.getItem("sid"),
                localStorage.getItem("deviceType"),
                localStorage.getItem("deviceOS"),
                localStorage.getItem("browserName"),
                localStorage.getItem("browserVersion"),
                localStorage.getItem("jnjDepartment"),
                localStorage.getItem("companyname"),
                "User Entry Point",
                "Direct Entry",
                "Direct"
              );
            } else {
              service.logServiceForLoginIssus(`'Came from Internal Site', 'if (localStorage.getItem("userdisplayName") && localStorage.getItem("wwid") && localStorage.getItem("jnjDepartment"))' condition false, Call jjeds API for User: ${username.toLowerCase()}`);
            }
          }).catch((error) => {
            service.logServiceForLoginIssus(`'Came from Internal Site', 'error in jjeds API, error=${JSON.stringify(error)}, Call jjeds API for User: ${username.toLowerCase()}`);
            console.log(error);
          }).finally(() => {
            _cookies.remove('documentReferrer');
          })
          //handleWindowClose(username,true);
        } else {
          service.logServiceForLoginIssus(`'Came from Internal Site', 'if (!sid || !isSidExpired)' condition false, Call jjeds API for User: ${username.toLowerCase()}`);
          console.log("User Entry Point");
          // For User Entry Tracking
          // ts.indexTrackingData(
          //   localStorage.getItem("userdisplayName"),
          //   localStorage.getItem("wwid"),
          //   localStorage.getItem("username"),
          //   localStorage.getItem("function"),
          //   localStorage.getItem("region"),
          //   localStorage.getItem("userproducts"),
          //   localStorage.getItem("doctypepref"),
          //   localStorage.getItem("sid"),
          //   localStorage.getItem("deviceType"),
          //   localStorage.getItem("deviceOS"),
          //   localStorage.getItem("browserName"),
          //   localStorage.getItem("browserVersion"),
          //   localStorage.getItem("jnjDepartment"),
          //   localStorage.getItem("companyname"),
          //   "User Entry Point",
          //   "Direct Entry",
          //   "Direct"
          // );
        }
      } else {
        Promise.resolve(jjeds()).then((jjeds) => {
          service.logServiceForLoginIssus(`'Came from External Site', Call jjeds API for User: ${username.toLowerCase()}`);
          var timeoutExpires = 1000 * 28800;
          _cookies.set("sid", JSON.stringify(guid), { path: "/" });
          _cookies.set("isSidExpired", JSON.stringify('NO'), { path: "/", expires: new Date(Date.now() + timeoutExpires) });
          localStorage.setItem("sid", JSON.stringify(guid));
          if (jjeds && jjeds.jnjmsupn) {
            localStorage.setItem("username", JSON.stringify(jjeds.jnjmsupn.toLowerCase()));
          }
          if (!(localStorage.getItem("userdisplayName")) && jjeds && jjeds.jnjdisplayname) {
            localStorage.setItem("userdisplayName", JSON.stringify(jjeds.jnjdisplayname));
          }
          else if (!(localStorage.getItem("userdisplayName")) && jjeds && jjeds.displayname) {
            localStorage.setItem("userdisplayName", JSON.stringify(jjeds.displayname));
          }
          if (!(localStorage.getItem("wwid")) && jjeds && jjeds.uid) {
            localStorage.setItem("wwid", JSON.stringify(jjeds.uid));
          }
          if (!(localStorage.getItem("jnjDepartment")) && jjeds && jjeds.jnjdepartmentdescription) {
            localStorage.setItem(
              "jnjDepartment",
              JSON.stringify(jjeds.jnjdepartmentdescription)
            );
            apiServices.UpdateCurrentdepartment(jjeds.jnjdepartmentdescription, jjeds.uid)
          } else {
            localStorage.setItem(
              "jnjDepartment",
              JSON.stringify("NA")
            );
          }

          // user came from a bookmark or an external link
          console.log("Came from External Site");
          if (_cookies.get('documentReferrer')) {
            documentReferrer = _cookies.get('documentReferrer');
            documentReferrer = decodeURIComponent(documentReferrer);
          }
          if (!documentReferrer) {
            documentReferrer = document.referrer
          }
          ts.indexTrackingData(
            localStorage.getItem("userdisplayName"),
            localStorage.getItem("wwid"),
            localStorage.getItem("username"),
            localStorage.getItem("function"),
            localStorage.getItem("region"),
            localStorage.getItem("userproducts"),
            localStorage.getItem("doctypepref"),
            localStorage.getItem("sid"),
            localStorage.getItem("deviceType"),
            localStorage.getItem("deviceOS"),
            localStorage.getItem("browserName"),
            localStorage.getItem("browserVersion"),
            localStorage.getItem("jnjDepartment"),
            localStorage.getItem("companyname"),
            "User Entry Point",
            "External Entry",
            documentReferrer
          );
        }).catch((error) => {
          service.logServiceForLoginIssus(`'Came from External Site', 'error in jjeds API, error=${JSON.stringify(error)}, Call jjeds API for User: ${username.toLowerCase()}`);
          console.log(error);
        }).finally(() => {
          _cookies.remove('documentReferrer');
        })
      }
      //window.history.goBack(1);
    } else {
      service.logServiceForLoginIssus(`'if (document.referrer && document.referrer.length > 0)' condition false for User: ${username.toLowerCase()}`);
      console.log("Came from Internal Site");
      let sid = _cookies.get("sid");
      let isSidExpired = _cookies.get("isSidExpired");
      if (!sid || !isSidExpired) {
        var timeoutSidExpired = 1000 * 28800;
        _cookies.set("sid", JSON.stringify(guid), { path: "/" });
        _cookies.set("isSidExpired", JSON.stringify('NO'), { path: "/", expires: new Date(Date.now() + timeoutSidExpired) });
        Promise.resolve(jjeds()).then((jjeds) => {
          service.logServiceForLoginIssus(`'Came from Internal Site', 'if (!sid || !isSidExpired)' condition true, Call jjeds API for User: ${username.toLowerCase()}`);
          localStorage.setItem("sid", JSON.stringify(guid));
          if (jjeds && jjeds.jnjmsupn) {
            localStorage.setItem("username", JSON.stringify(jjeds.jnjmsupn.toLowerCase()));
          }
          if (!(localStorage.getItem("userdisplayName")) && jjeds && jjeds.jnjdisplayname) {
            localStorage.setItem("userdisplayName", JSON.stringify(jjeds.jnjdisplayname));
          }
          else if (!(localStorage.getItem("userdisplayName")) && jjeds && jjeds.displayname) {
            localStorage.setItem("userdisplayName", JSON.stringify(jjeds.displayname));
          }
          if (!(localStorage.getItem("wwid")) && jjeds && jjeds.uid) {
            localStorage.setItem("wwid", JSON.stringify(jjeds.uid));
          }
          if (!(localStorage.getItem("jnjDepartment")) && jjeds && jjeds.jnjdepartmentdescription) {
            localStorage.setItem(
              "jnjDepartment",
              JSON.stringify(jjeds.jnjdepartmentdescription)
            );
            apiServices.UpdateCurrentdepartment(jjeds.jnjdepartmentdescription, jjeds.uid)
          } else {
            localStorage.setItem(
              "jnjDepartment",
              JSON.stringify("NA")
            );
          }
          // For User Entry Tracking
          if (localStorage.getItem("userdisplayName") &&
            localStorage.getItem("wwid") &&
            localStorage.getItem("jnjDepartment")
          ) {
            service.logServiceForLoginIssus(`'Came from Internal Site', 'if (localStorage.getItem("userdisplayName") && localStorage.getItem("wwid") && localStorage.getItem("jnjDepartment"))' condition True, Call jjeds API for User: ${username.toLowerCase()}`);
            ts.indexTrackingData(
              localStorage.getItem("userdisplayName"),
              localStorage.getItem("wwid"),
              localStorage.getItem("username"),
              localStorage.getItem("function"),
              localStorage.getItem("region"),
              localStorage.getItem("userproducts"),
              localStorage.getItem("doctypepref"),
              localStorage.getItem("sid"),
              localStorage.getItem("deviceType"),
              localStorage.getItem("deviceOS"),
              localStorage.getItem("browserName"),
              localStorage.getItem("browserVersion"),
              localStorage.getItem("jnjDepartment"),
              localStorage.getItem("companyname"),
              "User Entry Point",
              "Direct Entry",
              "Direct"
            );
          } else {
            service.logServiceForLoginIssus(`'Came from Internal Site', 'if (localStorage.getItem("userdisplayName") && localStorage.getItem("wwid") && localStorage.getItem("jnjDepartment"))' condition false, Call jjeds API for User: ${username.toLowerCase()}`);
          }
        }).catch((error) => {
          service.logServiceForLoginIssus(`'Came from Internal Site', 'error in jjeds API, error=${JSON.stringify(error)}, Call jjeds API for User: ${username.toLowerCase()}`);
          console.log(error);
        })
        //handleWindowClose(username,true);
      } else {
        service.logServiceForLoginIssus(`if (document.referrer && document.referrer.length > 0)' condition false and 'if (!sid || !isSidExpired)' condition false, Call jjeds API for User: ${username.toLowerCase()}`);
      }
    }
  }, [guid, service, siteUrl, ts, username])

  const userValidation = async () => {
    const services = new ApiServices();
    let wwid = UserData.sub;
    var response = await services.getUserDetails(wwid);
    if (response) {
      localStorage.setItem('userExists', JSON.stringify(true));
      localStorage.setItem('region', response.region);
      localStorage.setItem('function', JSON.stringify(response.function));
      localStorage.setItem('userproducts', response.products);
      localStorage.setItem('doctypepref', response.docs);
      localStorage.setItem('russianuser', JSON.stringify(response.russianuser));
      localStorage.setItem('contentpreference', JSON.stringify(response.contentprefernce));
      localStorage.setItem('datatracking', JSON.stringify(response.datatracking));
      localStorage.setItem('showPreviewAuthoringDisclaimer', JSON.stringify(response.isshowpreviewauthoringdisclaimer));
      localStorage.setItem('showGlobalAuthoringDisclaimer', JSON.stringify(response.isshowglobalauthoringdisclaimer));
      localStorage.setItem('showGenAIAnsweringDisclaimer', JSON.stringify(response.isshowgenaiansweringdisclaimer));
      if (response.accessgroup) {
        localStorage.setItem('accessgroup', JSON.stringify(response.accessgroup));
      } else {
        localStorage.setItem('accessgroup', JSON.stringify(''));
      }
    }
  }
  return null;
}

// class AuthUsers extends Component {
//   static contextType = MsalContext;
//   loginMSAL() {
//     const isAuth = this.context.accounts.length > 0;
//     if (!isAuth) {
//       pca.loginRedirect(loginRequest);
//     }
//   }
//   componentDidMount() {
//     if ((!isMobile || !isSafari)) {
//       this.loginMSAL();
//     }
//   }
//   copyToclipboard(copyUrl) {
//     navigator.clipboard.writeText(copyUrl);
//     alert('URL Copied to clipboard.');
//   }
//   redirectOnAppStore(appStoreUrl) {
//     window.location.href = appStoreUrl;
//   }

//   render() {
//     const isAuthenticated = this.context.accounts.length > 0;
//     if (isAuthenticated) {
//       return (
//         <span>
//           There are currently {this.context.accounts.length} users signed in!
//         </span>
//       );
//     } else {
//       return (((!isMobile || !isSafari)) ?
//         <p>Not signed in. Redirecting to the Organization Signin Page</p> :
//         <div className="safari-message-div" style={{ textAlign: "center", }}><p><b>iMS is not able to open on this version of Safari. Please open this URL <a href={window.location.origin}>{window.location.origin}</a> in your chrome browser.</b></p>
//           <Button
//             text="Copy Link"
//             customColor="#0088c3"
//             action={() => { this.copyToclipboard(window.location.origin); }}
//           />
//           <br /><br />
//           <a className="download-google-chrome" target="_blank" href="https://apps.apple.com/in/app/google-chrome/id535886823">Download/Open Chrome</a>
//           {/* <Button
//           text="Download Chrome"
//           customColor="#E39F00"
//           action={() => { this.redirectOnAppStore(`https://apps.apple.com/in/app/google-chrome/id535886823`); }}
//         /> */}
//         </div>);
//     }
//   }
// }
export default App;
