import { Component } from "react";
import { rightSideArrow, iIcon } from "../../assets";
import trackingService from "../../services/trackingService";
import "./ContentShortcuts.css";

class ClinicalStudiesKnowledgePanelTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfo: false,
    };
  }
  async componentDidMount() {
    //     let data = new KnowledgePanelData();
    //     // let products = 'STELARA';
    //     // let products = 'xarelto';
    //     let products = 'methylphenidate';
    //     // let products = '01-146';
    //     // let products = '629330';
    // //    let cskpData = await data.GetKnowledgePanelData('xarelto and sterala')
    // let x = await data.getKnowledgePanelData(products)
    //    console.log(x)
  }
  handleCSKP = () => {
    let url = `/CSKP`;
    const ts = new trackingService();
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      localStorage.getItem("companyname"),
      "Expanded Clinical Studies Knowledge Panel tile under content shortcuts",
      "",
      window.location.href
      //   this.state.searchKeyword,
      //   this.props.appliedFilters
    );
    window.open(
      url,
      "",
      `width=${window.innerWidth},height=${window.innerHeight},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no`
    );
  };
  onMouseEnterInfo = () => {
    this.setState({
      showInfo: true,
    });
  };
  onMouseLeaveInfo = () => {
    this.setState({
      showInfo: false,
    });
  };
  render() {
    return (
      <div className="contentShortcutInnerWapper">
        <div
          className="cardContainer"
          onClick={
            this.handleCSKP
          } /* style={{ borderTop: this.state.showCSModal ? "5px solid #0074B0" : "none" }} */
        >
          <div
            className="cardInnerWapper"
            style={this.state.showCSModal ? { borderBottom: "none" } : {}}
          >
            <div
              className="card-leftPart cs-middle-r3-tile-"
              style={
                this.props.customWidth >= 1000
                  ? { textAlign: "center", position: "absolute", width: "31%" }
                  : {}
              }
            >
              <small>
              <span className="cs-name-bold">Clinical Studies Knowledge Panel</span> <br /> (Quick access to data
                about JNJ studies and corresponding scientific evidence)
              </small>
            </div>
            <div
              className="card-iconsPart"
              style={
                this.props.customWidth >= 1000 ? { marginLeft: "auto" } : {}
              }
            >
              <img
                src={iIcon}
                alt="i-icon"
                width={18}
                height={18}
                onMouseEnter={this.onMouseEnterInfo}
                onMouseLeave={this.onMouseLeaveInfo}
                className="cs-tile-i-icon"
              />
              <div className="card-rightPart">
                <img
                  src={rightSideArrow}
                  alt="down-arrow"
                  className="cs-downArrow" /* style={{ transform: this.state.showCSModal ? "rotate(270deg)" : null }} */
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.showInfo && (
          <div className="cs-tile-wrapper">
            <div className="cs-tooltip-uparrow"></div>
            <span className="tooltiptext cs-tooltip-bottom">
              This tile contains data about active interventional studies based
              upon your product preferences. Clicking on the Protocol IDs in the
              tables will show you all of the evidence on iMedical Search that’s
              directly related to that study.
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default ClinicalStudiesKnowledgePanelTile;
