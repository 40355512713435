/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable array-callback-return */
import { Component } from "react";
import { Link } from 'react-router-dom';
import ApiServices from "../../utils/apiServices";
import { rightSideArrow, imsLoader, excelIcon, cskpArrowDown, cskpArrowLeft, arrowIconBlue } from "../../assets";
import Footer from "../footer/Footer";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import Button from "../Button";
import PoweredByDDIGModal from "./PoweredByDDIGModal";
import BootstrapTable from 'react-bootstrap-table-next';
import * as FileSaver from 'file-saver';
import DocumentProcessing from './../PopUp/DocumentProcessing';
import trackingService from "../../services/trackingService";

export default class KnowledgePanelData extends Component {
    services = new ApiServices();
    multipleINNNameStatus = false;
    multipleINNNames = "";
    jnjinnsearchterm = "";
    actualsearchterm = "";
    buttonsearchterm = "";
    strBrandJnj = "";
    strbrandinn = "";
    flag = 0;
    columnNameFirst = "";
    protFlag = 0;
    protocolId = "";
    idpnum = "";
    innName = "";
    jnjnum = "";
    isFirst = true;
    constructor(props) {
        super(props);
        this.service = new ApiServices();
        this.state = {
            products: [],
            selectedProduct: '',
            width: window.innerWidth,
            height: window.innerHeight,
            startIndex: 0,
            searchTypeRes: [],
            showDDIGModal: false,
            isWhatSearchClinical: false,
            clinicalStudyIDPData: [],
            clinicalColumns: [
                {
                    dataField: 'JNJ_NUMBER',
                    text: 'JNJ NUMBER'
                },
                {
                    dataField: 'INN_NAME',
                    text: 'INN NAME'
                },
                {
                    dataField: 'PROTOCOL_ID',
                    text: 'PROTOCOL ID',
                    formatter: (cell, row, rowIndex, extraData) => (
                        <div>
                            <a href={`${window.origin}/results?query=&q=&filters=&f1=protocolIDs&fval1=${row.PROTOCOL_ID}&fcount=1`} target="_blank" style={{ textDecoration: "none", color: "#1076bc", cursor: "pointer" }}> {row.PROTOCOL_ID} </a>
                        </div>
                    )
                },
                {
                    dataField: 'Clinical_Trial',
                    text: 'Clinical Trial'
                },
                {
                    dataField: 'IDP_NUMBER',
                    text: 'IDP NUMBER'
                },
                {
                    dataField: 'PROJECT_STATUS',
                    text: 'PROJECT STATUS'
                },
                {
                    dataField: 'STUDY_PHASE',
                    text: 'STUDY PHASE'
                },
                {
                    dataField: 'PROTOCOL_TITLE',
                    text: 'PROTOCOL TITLE'
                },
                {
                    dataField: 'STUDY_STATUS',
                    text: 'STUDY STATUS'
                },
                {
                    dataField: 'INTERVENTIONAL_STUDY',
                    text: 'INTERVENTIONAL STUDY'
                },
                {
                    dataField: 'SPONSOR_TYPE',
                    text: 'SPONSOR TYPE'
                },
                {
                    dataField: 'ALTERNATE_PROTOCOL_ID',
                    text: 'ALTERNATE PROTOCOL ID'
                },
                {
                    dataField: 'ALTERNATE_PROTOCOL_TYPE',
                    text: 'ALTERNATE PROTOCOL TYPE'
                }
            ],
            isWhatSearchCRS: false,
            crsData: [],
            crsColumns: [
                {
                    dataField: 'JNJ_NUMBER',
                    text: 'JNJ NUMBER'
                },
                {
                    dataField: 'INN_NAME',
                    text: 'INN NAME'
                },
                {
                    dataField: 'PROTOCOL_ID',
                    text: 'PROTOCOL ID',
                    formatter: (cell, row, rowIndex, extraData) => (
                        <div>
                            <a href={`${window.origin}/results?query=&q=&filters=&f1=protocolIDs&fval1=${row.PROTOCOL_ID}&fcount=1`} target="_blank" style={{ textDecoration: "none", color: "#1076bc", cursor: "pointer" }}> {row.PROTOCOL_ID} </a>
                        </div>
                    )
                },
                {
                    dataField: 'Clinical_Trial',
                    text: 'Clinical Trial'
                },
                {
                    dataField: 'IDP_NUMBER',
                    text: 'IDP NUMBER'
                },
                {
                    dataField: 'STUDY_SITE_STATUS',
                    text: 'STUDY SITE STATUS'
                },
                {
                    dataField: 'HEALTHCARE_FACILITY',
                    text: 'HEALTHCARE FACILITY'
                },
                {
                    dataField: 'INVESTIGATOR',
                    text: 'INVESTIGATOR'
                },
                {
                    dataField: 'COUNTRY_NAME',
                    text: 'COUNTRY NAME'
                }
            ],
            isWhatSearchCompound: false,
            compoundData: [],
            compoundColumns: [
                {
                    dataField: 'JNJ_NUMBER',
                    text: 'JNJ NUMBER'
                },
                {
                    dataField: 'INN_NAME',
                    text: 'INN NAME'
                },
                {
                    dataField: 'IDP_NUMBER',
                    text: 'IDP NUMBER'
                },
                {
                    dataField: 'PROJECT_NAME',
                    text: 'PROJECT NAME'
                },
                {
                    dataField: 'PRIMARY_INDICATION',
                    text: 'PRIMARY INDICATION'
                },
                {
                    dataField: 'INDUSTRY_TA_DESCRIPTION',
                    text: 'INDUSTRY TA DESCRIPTION'
                },
                {
                    dataField: 'PROJECT_PHASE',
                    text: 'PROJECT PHASE'
                },
                {
                    dataField: 'PROJECT_STATUS',
                    text: 'PROJECT STATUS'
                }
            ],
            docProcessing: false,
            evdiencePanelData: [],
            isPhaseShow: [],
            protocolNameData: [],
            protocolNumberData: [],
            isProtocolClick: false,
            isLoaded: false,
            selectedPhase: '',
            isCSKPDropdownOpen: false,
            isDataLoading: false
        }
    }
    async componentDidMount() {
        if (window.location.pathname === '/CSKP') {
            let wwid = JSON.parse(localStorage.getItem('wwid'));
            let isProductsAvailable;
            let res = await this.services.getUserProductAndWwid(wwid);
            let findSearchTypeRes;
            if (res) {
                isProductsAvailable = res[0].products
            }
            if (isProductsAvailable !== null && isProductsAvailable?.length > 0) {
                this.setState({
                    products: isProductsAvailable.split(';'),
                    // isLoaded: true
                }, () => {
                    if (this.state.selectedProduct === '') {
                        this.setState({
                            selectedProduct: this.state.products[0]
                        }, async () => {
                            await this.csepData(this.state.products[0])
                            findSearchTypeRes = await this.services.findsearchtype(this.state.products[0]);
                            this.setState({ searchTypeRes: findSearchTypeRes }, async () => {
                                await this.getKnowledgePanelData(this.state.selectedProduct);
                                this.setState({ isLoaded: true })
                            })
                        })
                    }
                })
            }
            else {
                this.setState({
                    isLoaded: true
                })
            }
            window.addEventListener('resize', this.updateDimensions);
        }
        else {
            let findSearchTypeRes = this.props.searchTypeRes;
            this.setState({ searchTypeRes: findSearchTypeRes }, async () => {
                await this.getKnowledgePanelData(this.props.searchKeyword);
            })
        }
    }
    getKnowledgePanelData = async (searchterm) => {
        // async getKnowledgePanelData(searchterm) {
        // const service = new ApiServices();
        let firstTable;
        let isbrandtable;
        let i = 0;
        if (searchterm !== '' || searchterm.length > 0) {
            let exceptionsArray = ["a", "an", "and", "any", "at", "from", "into", "of", "on", "or", "the", "to"];
            if (searchterm.toUpperCase().includes(' AND ')) {
                i = 1;
            }
            if (searchterm.toUpperCase().includes(' NEAR ')) {
                i = 1;
            }
            if (exceptionsArray.includes(searchterm.toLowerCase())) {
                i = 1;
            }
            if (i !== 1) {
                if (searchterm.includes(") OR (")) {
                    let searchSplit = searchterm.split(") OR (")
                    let searchSplit1 = searchSplit[0];
                    let searchSplit2 = searchSplit[1];
                    searchSplit1 = searchSplit1.replace("(", "").trim();
                    searchSplit2 = searchSplit2.replace(")", "").trim();
                    let searchjointterm = searchSplit1 + "@@@" + searchSplit2;
                    searchterm = searchjointterm;
                }
                let res = this.state.searchTypeRes
                if (res.length > 0) {
                    firstTable = res[0];
                    isbrandtable = res[1];
                    firstTable = res.filter(x => x.SEARCH_TERM === null && x.IS_BRAND === null);
                    //Enhance
                    if (res.length > 2) {
                        this.multipleINNNameStatus = true;
                        for (let index = 0; index < firstTable.length; index++) {
                            const element = firstTable[index];
                            if (Object.values(element)[2].toString().length > 0) {
                                this.multipleINNNames += Object.values(element)[2].toString() + "$";
                                this.multipleINNNameStatus = true;
                            }
                            else if (!this.multipleINNNames.includes("$") && Object.values(element)[2].toString().length === 0) {
                                this.multipleINNNameStatus = false;
                            }
                        }
                    }
                    //End of Enhance
                    let isbrand = 0;
                    isbrandtable = res.filter(x => x.SEARCH_TERM !== null && x.IS_BRAND !== null);
                    if (isbrandtable.length > 0) {
                        for (let index = 0; index < isbrandtable.length; index++) {
                            const element = isbrandtable[index];
                            searchterm = Object.values(element)[4].toString();
                            isbrand = parseInt(Object.values(element)[5]);
                            break;
                        }
                    }
                    if (isbrand === 0) {
                        if ((firstTable.length > 0)) {
                            this.jnjinnsearchterm = searchterm;
                            this.actualsearchterm = searchterm;
                            this.buttonsearchterm = searchterm;
                        }
                    }
                    else {
                        if ((firstTable.length > 0)) {
                            for (let index = 0; index < firstTable.length; index++) {
                                const element = firstTable[index];
                                searchterm = Object.values(element)[6].toString();
                                this.strBrandJnj = Object.values(element)[7].toString();
                                this.strbrandinn = Object.values(element)[6].toString();
                                this.buttonsearchterm = this.strbrandinn;
                                this.flag = 1;
                                break;
                            }
                        }
                        this.jnjinnsearchterm = searchterm;
                    }
                    for (let index = 0; index < firstTable.length; index++) {
                        const element = firstTable[index];
                        for (const property in element) {
                            if (element[property] !== null) {
                                let columnvalue = element[property].toString().trim().toLowerCase()
                                    ;
                                if (columnvalue === searchterm.toLowerCase()) {
                                    this.columnNameFirst = property.replace('_', ' ');
                                    break;
                                }
                            }
                        }
                        break;
                    }
                    if (this.columnNameFirst === "PROTOCOL ID") {
                        this.protFlag = 1;
                        for (let index = 0; index < firstTable.length; index++) {
                            const element = firstTable[index];
                            for (const property in element) {
                                if (element[property] !== null) {
                                    this.protocolId = Object.values(element)[0].toString()
                                    this.idpnum = Object.values(element)[1].toString()
                                    this.innName = Object.values(element)[2].toString()
                                    this.jnjnum = Object.values(element)[3].toString()
                                }
                                break;
                            }
                            break;
                        }
                        //for populating excels
                        if (this.idpnum === "" && this.innName === "" && this.jnjnum === "") {
                            searchterm = this.protocolId;
                            this.columnName = "PROTOCOL ID";
                        }
                        else if (this.idpnum !== "") {
                            searchterm = this.idpnum;
                            this.columnName = "IDP NUMBER";
                        }
                        else if (this.innName !== "" || this.jnjnum !== "") {
                            if (this.innName !== "") {
                                searchterm = this.innName;
                                this.columnName = "INN NAME";
                            }
                            else {
                                searchterm = this.jnjnum;
                                this.columnName = "JNJ NUMBER";
                            }
                        }
                    }
                    else {
                        searchterm = this.jnjinnsearchterm;
                        let checkifinnorjnj = this.isDigitsOnly(searchterm);
                        if (checkifinnorjnj) {
                            this.columnName = "JNJ NUMBER";
                        }
                        else {
                            this.columnName = "INN NAME";
                        }
                    }
                    //for populating labels
                    let labelsearch = "";
                    if (this.protocolId.toLowerCase() === searchterm.toLowerCase() || this.idpnum.toLowerCase() === searchterm.toLowerCase()) {

                        if (this.innName.toLowerCase() !== "" || this.jnjnum.toLowerCase() !== "") {
                            if (this.innName.toLowerCase() !== "") {
                                labelsearch = this.innName;
                            }
                            else {
                                labelsearch = this.jnjnum;
                            }
                        }
                        else {
                            if (this.protocolId.toLowerCase() === searchterm.toLowerCase()) {
                                labelsearch = this.protocolId;
                            }
                            else {
                                labelsearch = this.idpnum;
                            }
                        }
                    }
                    else {
                        labelsearch = searchterm;
                    }
                    let labelres = await this.services.labelpopulate(searchterm, labelsearch);
                    let tradeMarkTable; // TABLE 4
                    let industryDesTable; // TABLE 3
                    let primaryIndicationTable; // TABLE 5
                    let usBrandTable; // TABLE 7
                    // let labelinglabel;
                    //NEW CODE CHANGE
                    if (labelres.length > 0) {
                        tradeMarkTable = labelres.filter(x => x.ALL_TRADEMARKS !== null);
                        industryDesTable = labelres.filter(x => x.INDUSTRY_TA_DESCRIPTIONS !== null);
                        primaryIndicationTable = labelres.filter(x => x.PRIMARY_INDICATION !== null);
                        usBrandTable = labelres.filter(x => x.US_BRANDS !== null);
                        // labelinglabel = labelres.filter(x => x.LABELING_DOCUMENTS !== null || x.SCIENTIFIC_DOCUMENTS !== null);
                        //END OF NEW CODE CHANGE
                        if (industryDesTable.length > 0) {
                            // let therapeutic = "";
                            for (let index = 0; index < industryDesTable.length; index++) {
                                const element = industryDesTable[index];
                                Object.values(element).map(item => {
                                    if (item !== null) {
                                        return (
                                            <div>
                                                <span>Therapeutic Area:</span>
                                                <span>{item.toString()}</span>
                                            </div>
                                        )
                                    }
                                })
                                // if (this.isFirst) {
                                //     therapeutic = (
                                //         <div>
                                //             <span>Therapeutic Area:</span>
                                //             <span>{Object.values(element)[0].toString()}</span>
                                //         </div>
                                //     )
                                //     this.isFirst = false;
                                // }
                            }
                        }
                        if (usBrandTable.length > 0) {
                            for (let index = 0; index < usBrandTable.length; index++) {
                                const element = usBrandTable[index];
                                Object.values(element).map(item => {
                                    if (item !== null) {
                                        return (
                                            <div>
                                                <span>U.S Trademark:</span>
                                                <span>{item.toString()}</span>
                                            </div>
                                        )
                                    }
                                })
                            }
                        }
                        if (tradeMarkTable.length > 0) {
                            for (let index = 0; index < tradeMarkTable.length; index++) {
                                // const element = tradeMarkTable[index];
                                // let alltrademark = Object.values(element)[0].toString();
                                // let splitComma = alltrademark.split(',')[0]
                                // Object.values(element).map(item=>{
                                // return (
                                //     <div>
                                //         <span>All Trademarks:</span>
                                //         <span>{`${splitComma}+...`}</span>
                                //     </div>
                                // )
                                //// })
                            }
                        }
                        if (primaryIndicationTable.length > 0) {
                            for (let index = 0; index < primaryIndicationTable.length; index++) {
                                const element = primaryIndicationTable[index];
                                Object.values(element).map(item => {
                                    if (item !== null) {
                                        return (
                                            <div>
                                                <span>All Indications under Investigation:</span>
                                                <span>{item.toString()}</span>
                                            </div>
                                        )
                                    }
                                })
                            }
                        }
                    }
                }
            }
        }
    }
    isDigitsOnly(str) {

        for (const c in str) {
            if (str[c] < '0' || str[c] > '9')
                return false;
        }
        return true;
    }
    getWidth = (diff) => {
        let calWidth;
        switch (diff) {
            case 1: calWidth = 20;
                break;
            case 2: calWidth = 30;
                break;
            case 3: calWidth = 40;
                break;
            case 4: calWidth = 60;
                break;
            case 5: calWidth = 90;
                break;
            case 6: calWidth = 100;
                break;
            default: calWidth = 100;
                break;
        }
        return calWidth
    }
    selectProduct = (product, startIdx, endIdx) => {
        if (product.length > 0) {
            return (
                <>
                    {startIdx > 0 &&
                        <div className="wnfy-select-nav-button-wrapper"><div className="wnfy-result-navigate-arrow" onClick={e => this.handleOnClickLeft()}><img src={rightSideArrow} alt="" style={{ transform: "rotate(180deg)" }} /></div></div>
                    }
                    {/* {diff < 6 &&
                        <div style={{ flex: "auto" }}></div>
                    } */}
                    <div className="cs-modal-product-wrapper" /* style={{ width: `${calcwidth}%` }} */>
                        {product.map((item, index) => {
                            if (index >= startIdx && index <= endIdx) {
                                return (

                                    <div className={item === this.state.selectedProduct ? "cs-select-product-wrapper cs-product-selected" : "cs-select-product-wrapper"} onClick={this.handleSelectProduct} key={index} id={`${item}`}>
                                        <div id={`${item}`}>{item}</div>
                                    </div>

                                )
                            }
                        })
                        }
                    </div>
                    {/* {diff < 6 &&
                        <div style={{ flex: "auto" }}></div>
                    } */}
                    {startIdx < product.length && endIdx < product.length - 1 &&
                        <div className="wnfy-select-nav-button-wrapper" ><div className="wnfy-result-navigate-arrow" onClick={e => this.handleOnClickRight()}><img src={rightSideArrow} alt="" /></div></div>
                    }
                </>
            )
        }
    }
    handleSelectProduct = (event) => {
        event.preventDefault();
        if (event) {
            this.setState({
                selectedProduct: event.target.id,
                isPhaseShow: [],
                protocolNameData: [],
                protocolNumberData: [],
                isProtocolClick: false,
                isLoaded: false,
                isWhatSearchCRS: false,
                isWhatSearchClinical: false,
                isWhatSearchCompound: false,
                compoundData: [],
                crsData: [],
                clinicalStudyIDPData: []
            }, async () => {
                if (this.state.selectedProduct.length > 0) {
                    await this.csepData(this.state.selectedProduct)
                    let findSearchTypeRes = await this.services.findsearchtype(this.state.selectedProduct);
                    this.setState({ searchTypeRes: findSearchTypeRes }, async () => {
                        await this.getKnowledgePanelData(this.state.selectedProduct);
                        this.setState({ isLoaded: true })
                    })
                }
            })
        }
    }
    nonNullValues(obj) {
        return Object.fromEntries(
            Object.entries(obj).filter(([key, value]) => value !== null)
        )
    }
    findClinical = async (searchterm) => {
        // let findClinicalRes = await this.services.findclinicalandcrsandcompound(searchterm, 'Clinical');
        // this.setState({ clinicalStudyIDPData: findClinicalRes.map(this.nonNullValues) })
        let findClinicalRes;
        this.setState({ isDataLoading: true })
        await this.services.findclinicalandcrsandcompound(searchterm, 'Clinical').then((res) => {
            findClinicalRes = res;
            this.setState({ isDataLoading: false, clinicalStudyIDPData: res.map(this.nonNullValues) })
        }).catch(() => this.setState({ isDataLoading: false }));
        return findClinicalRes;
    }
    findCRS = async (searchterm) => {
        // let findClinicalRes = await this.services.findclinicalandcrsandcompound(searchterm, 'CRS');
        // this.setState({ crsData: findClinicalRes.map(this.nonNullValues) })
        let findClinicalRes;
        this.setState({ isDataLoading: true })
        await this.services.findclinicalandcrsandcompound(searchterm, 'CRS').then((res) => {
            findClinicalRes = res;
            this.setState({ isDataLoading: false, crsData: res.map(this.nonNullValues) })
        }).catch(() => this.setState({ isDataLoading: false }));
        return findClinicalRes;
    }
    findCompound = async (searchterm) => {
        this.setState({ isDataLoading: true })
        // let findClinicalRes = await this.services.findclinicalandcrsandcompound(searchterm, 'Compounds');
        let findClinicalRes;
        await this.services.findclinicalandcrsandcompound(searchterm, 'Compounds').then((res) => {
            findClinicalRes = res;
            this.setState({ isDataLoading: false, compoundData: res.map(this.nonNullValues) })
        }).catch(() => this.setState({ isDataLoading: false }));
        // this.setState({ compoundData: findClinicalRes.map(this.nonNullValues) })
        return findClinicalRes;
    }
    handleOnClickRight = () => {
        if (this.state.width <= 1360 && this.state.width >= 1200) {
            this.setState({ startIndex: this.state.startIndex + 6 })
        }
        else if (this.state.width <= 1200 && this.state.width >= 1000) {
            this.setState({ startIndex: this.state.startIndex + 5 })
        }
        else if (this.state.width <= 1000 && this.state.width >= 750) {
            this.setState({ startIndex: this.state.startIndex + 4 })
        }
        else if (this.state.width <= 750 && this.state.width >= 550) {
            this.setState({ startIndex: this.state.startIndex + 3 })
        }
        else if (this.state.width <= 550 && this.state.width >= 450) {
            this.setState({ startIndex: this.state.startIndex + 2 })
        }
        else if (this.state.width <= 450) {
            this.setState({ startIndex: this.state.startIndex + 1 })
        }
        else {
            this.setState({ startIndex: this.state.startIndex + 7 })
        }
        // this.setState({
        //     startIndex: this.state.startIndex + 7
        // })
    }
    handleOnClickLeft = () => {
        if (this.state.width <= 1360 && this.state.width >= 1200) {
            this.setState({ startIndex: this.state.startIndex - 6 })
        }
        else if (this.state.width <= 1200 && this.state.width >= 1000) {
            this.setState({ startIndex: this.state.startIndex - 5 })
        }
        else if (this.state.width <= 1000 && this.state.width >= 750) {
            this.setState({ startIndex: this.state.startIndex - 4 })
        }
        else if (this.state.width <= 750 && this.state.width >= 550) {
            this.setState({ startIndex: this.state.startIndex - 3 })
        }
        else if (this.state.width <= 550 && this.state.width >= 450) {
            this.setState({ startIndex: this.state.startIndex - 2 })
        }
        else if (this.state.width <= 450) {
            this.setState({ startIndex: this.state.startIndex - 1 })
        }
        else {
            this.setState({ startIndex: this.state.startIndex - 7 })
        }
        // this.setState({
        //     startIndex: this.state.startIndex - 7
        // })
    }
    productList = () => {
        let startIdx = this.state.startIndex;
        // let endIdx = startIdx + 6;
        let endIdx;
        if (this.state.width <= 1360 && this.state.width >= 1200) {
            endIdx = startIdx + 5;
        }
        else if (this.state.width <= 1200 && this.state.width >= 1000) {
            endIdx = startIdx + 4;
        }
        else if (this.state.width <= 1000 && this.state.width >= 750) {
            endIdx = startIdx + 3;
        }
        else if (this.state.width <= 750 && this.state.width >= 550) {
            endIdx = startIdx + 2;
        }
        else if (this.state.width <= 550 && this.state.width >= 450) {
            endIdx = startIdx + 1;
        }
        else if (this.state.width <= 450) {
            endIdx = startIdx + 0;
        }
        else {
            endIdx = startIdx + 6;
        }
        return (
            <div className="cs-product-wrapper">
                <div className="cs-product-inner-wrapper">
                    {this.selectProduct(this.state.products, startIdx, endIdx)}
                </div>
            </div>
        )
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    showClinicalStudyIDP = () => {
        this.handelDataPanelLinks('List of Clinical Studies for each IDP')
        this.setState({ isWhatSearchClinical: !this.state.isWhatSearchClinical, isDataLoading: true }, () => {
            if (this.state.isWhatSearchClinical) {
                this.findClinical(this.buttonsearchterm)
                this.setState({ isWhatSearchCRS: false, isWhatSearchCompound: false })
            }
        })
    }
    showClinicalResearchSite = () => {
        this.handelDataPanelLinks('Clinical Research Sites')
        this.setState({ isWhatSearchCRS: !this.state.isWhatSearchCRS, isDataLoading: true }, () => {
            if (this.state.isWhatSearchCRS) {
                this.findCRS(this.buttonsearchterm)
                this.setState({ isWhatSearchCompound: false, isWhatSearchClinical: false })
            }
        })
    }
    showCompoundIDP = () => {
        this.handelDataPanelLinks('List of Internal Development Projects (IDPs)');
        this.setState({ isWhatSearchCompound: !this.state.isWhatSearchCompound, isDataLoading: true }, () => {
            if (this.state.isWhatSearchCompound) {
                this.findCompound(this.buttonsearchterm)
                this.setState({ isWhatSearchCRS: false, isWhatSearchClinical: false })
            }
        })
    }
    clinicalStudyIDPData = (clinicalStudyIDPData) => {
        if (clinicalStudyIDPData.length > 0) {
            return (
                <div>
                    <BootstrapTable
                        keyField='index'
                        data={clinicalStudyIDPData}
                        columns={this.state.clinicalColumns}
                        bordered={true}
                        classes="cskp-clinical-table"
                        wrapperClasses="table-responsive" rowClasses="text-nowrap">
                    </BootstrapTable>

                </div>
            )
        }
        else {
            if (this.state.isDataLoading) {
                return (
                    <div style={{ textAlign: "center", padding: "10%" }}>
                        <img src={imsLoader} alt="Loading Data" style={{ width: "10%" }} />
                    </div>
                )
            }
            else {
                return (
                    <div>
                        Currently there are no List of Clinical Studies for each IDP data available for this product
                    </div>
                )
            }
        }
    }
    clinicalResearchSiteData = (crsData) => {
        if (crsData.length > 0) {
            return (
                <div>
                    <BootstrapTable
                        keyField='index'
                        data={crsData}
                        columns={this.state.crsColumns}
                        bordered={true}
                        classes="cskp-crs-table"
                        wrapperClasses="table-responsive" rowClasses="text-nowrap">
                    </BootstrapTable>

                </div>
            )
        }
        else {
            if (this.state.isDataLoading) {
                return (
                    <div style={{ textAlign: "center", padding: "10%" }}>
                        <img src={imsLoader} alt="Loading Data" style={{ width: "10%" }} />
                    </div>
                )
            }
            else {
                return (
                    <div>
                        Currently there are no Clinical Research Sites data available for this product
                    </div>
                )
            }
        }
    }
    compoundIDPData = (compoundData) => {
        if (compoundData.length > 0) {
            return (
                <div>
                    <BootstrapTable
                        keyField='index'
                        data={compoundData}
                        columns={this.state.compoundColumns}
                        bordered={true}
                        classes="cskp-crs-table"
                        wrapperClasses="table-responsive" rowClasses="text-nowrap">
                    </BootstrapTable>

                </div>
            )
        }
        else {
            if (this.state.isDataLoading) {
                return (
                    <div style={{ textAlign: "center", padding: "10%" }}>
                        <img src={imsLoader} alt="Loading Data" style={{ width: "10%" }} />
                    </div>
                )
            }
            else {
                return (
                    <div>
                        Currently there are no List of Internal Development Projects (IDPs) data available for this product
                    </div>
                )
            }
        }
    }
    generateDownloadTimestamp = () => {
        const now = new Date()
        return now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate() + "_" + now.getHours() + "-" + now.getMinutes() + "-" + now.getSeconds()
    }
    generateCSV = async (type) => {
        switch (type) {
            case "Compounds":
                let csvCompoundsData = [];
                let compoundsFileName = "CompoundsinProjectsReport" + this.generateDownloadTimestamp();
                let compoundData = await this.findCompound(this.buttonsearchterm)
                compoundData.map((item, index) => {
                    csvCompoundsData.push({
                        "JNJ_NUMBER": item.JNJ_NUMBER,
                        "INN_NAME": item.INN_NAME,
                        "IDP_NUMBER": item.IDP_NUMBER,
                        "PROJECT_NAME": item.PROJECT_NAME,
                        "PRIMARY_INDICATION": item.PRIMARY_INDICATION,
                        "INDUSTRY_TA_DESCRIPTION": item.INDUSTRY_TA_DESCRIPTION,
                        "PROJECT_PHASE": item.PROJECT_PHASE,
                        "PROJECT_STATUS": item.PROJECT_STATUS
                    })
                    return null;
                })
                let columnsCompounds = [
                    { header: "JNJ NUMBER", key: "JNJ_NUMBER", width: 13 },
                    { header: "INN NAME", key: "INN_NAME", width: 13 },
                    { header: "IDP NUMBER", key: "IDP_NUMBER", width: 12 },
                    { header: "PROJECT NAME", key: "PROJECT_NAME", width: 18 },
                    { header: "PRIMARY INDICATION", key: "PRIMARY_INDICATION", width: 40 },
                    { header: "INDUSTRY TA DESCRIPTION", key: "INDUSTRY_TA_DESCRIPTION", width: 32 },
                    { header: "PROJECT PHASE", key: "PROJECT_PHASE", width: 16 },
                    { header: "PROJECT STATUS", key: "PROJECT_STATUS", width: 16 }
                ];
                this.handelDataPanelExcel('List of Internal Development Projects (IDPs)');
                // let service = new ApiServices();
                this.setState({ docProcessing: true }, async () => {
                    await this.services.prepareExcelDocument(csvCompoundsData, columnsCompounds, 'Compounds in Projects in scope').then((res) => {
                        const data = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        FileSaver.saveAs(data, compoundsFileName);
                        this.setState({ docProcessing: false })
                    }).catch((error) => {
                        console.log(error);
                        this.setState({ docProcessing: false })
                    });
                })
                break;
            case "Clinical":
                let csvClinicalData = [];
                let clinicalFileName = "ClinicalStudiesReport" + this.generateDownloadTimestamp();

                let clinicalData = await this.findClinical(this.buttonsearchterm)
                clinicalData.map((item, index) => {
                    csvClinicalData.push({
                        "JNJ_NUMBER": item.JNJ_NUMBER,
                        "INN_NAME": item.INN_NAME,
                        "PROTOCOL_ID": {
                            text: item.PROTOCOL_ID,
                            hyperlink: `${window.origin}/results?query=&q=&filters=&f1=protocolIDs&fval1=${item.PROTOCOL_ID}&fcount=1`
                        }, //item.PROTOCOL_ID,
                        "Clinical_Trial": item.Clinical_Trial !== undefined ? item.Clinical_Trial : "",
                        "IDP_NUMBER": item.IDP_NUMBER,
                        "PROJECT_STATUS": item.PROJECT_STATUS,
                        "STUDY_PHASE": item.STUDY_PHASE,
                        "PROTOCOL_TITLE": item.PROTOCOL_TITLE,
                        "STUDY_STATUS": item.STUDY_STATUS,
                        "INTERVENTIONAL_STUDY": item.INTERVENTIONAL_STUDY,
                        "SPONSOR_TYPE": item.SPONSOR_TYPE,
                        "ALTERNATE_PROTOCOL_ID": item.ALTERNATE_PROTOCOL_ID,
                        "ALTERNATE_PROTOCOL_TYPE": item.ALTERNATE_PROTOCOL_TYPE
                    })
                    return null;
                })
                let columnsClinical = [
                    { header: "JNJ NUMBER", key: "JNJ_NUMBER", width: 13 },
                    { header: "INN NAME", key: "INN_NAME", width: 13 },
                    { header: "PROTOCOL ID", key: "PROTOCOL_ID", width: 18 },
                    { header: "Clinical Trial", key: "Clinical_Trial", width: 12 },
                    { header: "IDP NUMBER", key: "IDP_NUMBER", width: 13 },
                    { header: "PROJECT STATUS", key: "PROJECT_STATUS", width: 17 },
                    { header: "STUDY PHASE", key: "STUDY_PHASE", width: 18 },
                    { header: "PROTOCOL TITLE", key: "PROTOCOL_TITLE", width: 200 },
                    { header: "STUDY STATUS", key: "STUDY_STATUS", width: 16 },
                    { header: "INTERVENTIONAL STUDY", key: "INTERVENTIONAL_STUDY", width: 23 },
                    { header: "SPONSOR TYPE", key: "SPONSOR_TYPE", width: 18 },
                    { header: "ALTERNATE PROTOCOL ID", key: "ALTERNATE_PROTOCOL_ID", width: 25 },
                    { header: "ALTERNATE PROTOCOL TYPE", key: "ALTERNATE_PROTOCOL_TYPE", width: 27 }
                ];
                this.handelDataPanelExcel('List of Clinical Studies for each IDP')
                // let service = new ApiServices();
                this.setState({ docProcessing: true }, async () => {
                    await this.services.prepareExcelDocument(csvClinicalData, columnsClinical, 'Clinical studies in scope').then((res) => {
                        const data = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        FileSaver.saveAs(data, clinicalFileName);
                        this.setState({ docProcessing: false })
                    }).catch((error) => {
                        console.log(error);
                        this.setState({ docProcessing: false })
                    });
                })
                break;
            case "CRS":
                let csvCRSData = [];
                let crsFileName = "CRSReport" + this.generateDownloadTimestamp();

                let crsData = await this.findCRS(this.buttonsearchterm)
                crsData.map((item, index) => {
                    csvCRSData.push({
                        "JNJ_NUMBER": item.JNJ_NUMBER,
                        "INN_NAME": item.INN_NAME,
                        "PROTOCOL_ID": {
                            text: item.PROTOCOL_ID,
                            hyperlink: `${window.origin}/results?query=&q=&filters=&f1=protocolIDs&fval1=${item.PROTOCOL_ID}&fcount=1`
                        }, //item.PROTOCOL_ID,
                        "Clinical_Trial": item.Clinical_Trial !== undefined ? item.Clinical_Trial : "",
                        "IDP_NUMBER": item.IDP_NUMBER,
                        "STUDY_SITE_STATUS": item.STUDY_SITE_STATUS,
                        "HEALTHCARE_FACILITY": item.HEALTHCARE_FACILITY,
                        "INVESTIGATOR": item.INVESTIGATOR,
                        "COUNTRY_NAME": item.COUNTRY_NAME
                    })
                    return null;
                })
                let columnsCRS = [
                    { header: "JNJ NUMBER", key: "JNJ_NUMBER", width: 13 },
                    { header: "INN NAME", key: "INN_NAME", width: 13 },
                    { header: "PROTOCOL ID", key: "PROTOCOL_ID", width: 18 },
                    { header: "Clinical Trial", key: "Clinical_Trial", width: 12 },
                    { header: "IDP NUMBER", key: "IDP_NUMBER", width: 13 },
                    { header: "STUDY SITE STATUS", key: "STUDY_SITE_STATUS", width: 19 },
                    { header: "HEALTHCARE FACILITY", key: "HEALTHCARE_FACILITY", width: 105 },
                    { header: "INVESTIGATOR", key: "INVESTIGATOR", width: 32 },
                    { header: "COUNTRY NAME", key: "COUNTRY_NAME", width: 56 }
                ];
                this.handelDataPanelExcel('Clinical Research Sites')
                // let service = new ApiServices();
                this.setState({ docProcessing: true }, async () => {
                    await this.services.prepareExcelDocument(csvCRSData, columnsCRS, 'CRS for studies in scope').then((res) => {
                        const data = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        FileSaver.saveAs(data, crsFileName);
                        this.setState({ docProcessing: false })
                    }).catch((error) => {
                        console.log(error);
                        this.setState({ docProcessing: false })
                    });
                })
                break;
            case "All":
                let excelDatas = [];
                let csvCompoundsDataDownloadAll = [];
                let compoundDataDownloadAll = await this.findCompound(this.buttonsearchterm)
                compoundDataDownloadAll.map((item, index) => {
                    csvCompoundsDataDownloadAll.push({
                        "JNJ_NUMBER": item.JNJ_NUMBER,
                        "INN_NAME": item.INN_NAME,
                        "IDP_NUMBER": item.IDP_NUMBER,
                        "PROJECT_NAME": item.PROJECT_NAME,
                        "PRIMARY_INDICATION": item.PRIMARY_INDICATION,
                        "INDUSTRY_TA_DESCRIPTION": item.INDUSTRY_TA_DESCRIPTION,
                        "PROJECT_PHASE": item.PROJECT_PHASE,
                        "PROJECT_STATUS": item.PROJECT_STATUS
                    })
                    return null;
                })
                let columnsCompoundsDownloadAll = [
                    { header: "JNJ NUMBER", key: "JNJ_NUMBER", width: 13 },
                    { header: "INN NAME", key: "INN_NAME", width: 13 },
                    { header: "IDP NUMBER", key: "IDP_NUMBER", width: 12 },
                    { header: "PROJECT NAME", key: "PROJECT_NAME", width: 18 },
                    { header: "PRIMARY INDICATION", key: "PRIMARY_INDICATION", width: 40 },
                    { header: "INDUSTRY TA DESCRIPTION", key: "INDUSTRY_TA_DESCRIPTION", width: 32 },
                    { header: "PROJECT PHASE", key: "PROJECT_PHASE", width: 16 },
                    { header: "PROJECT STATUS", key: "PROJECT_STATUS", width: 16 }
                ];
                let csvClinicalDataDownloadAll = [];
                let clinicalDataDownloadAll = await this.findClinical(this.buttonsearchterm)
                clinicalDataDownloadAll.map((item, index) => {
                    csvClinicalDataDownloadAll.push({
                        "JNJ_NUMBER": item.JNJ_NUMBER,
                        "INN_NAME": item.INN_NAME,
                        "PROTOCOL_ID": {
                            text: item.PROTOCOL_ID,
                            hyperlink: `${window.origin}/results?query=&q=&filters=&f1=protocolIDs&fval1=${item.PROTOCOL_ID}&fcount=1`
                        }, //item.PROTOCOL_ID,
                        "Clinical_Trial": item.Clinical_Trial !== undefined ? item.Clinical_Trial : "",
                        "IDP_NUMBER": item.IDP_NUMBER,
                        "PROJECT_STATUS": item.PROJECT_STATUS,
                        "STUDY_PHASE": item.STUDY_PHASE,
                        "PROTOCOL_TITLE": item.PROTOCOL_TITLE,
                        "STUDY_STATUS": item.STUDY_STATUS,
                        "INTERVENTIONAL_STUDY": item.INTERVENTIONAL_STUDY,
                        "SPONSOR_TYPE": item.SPONSOR_TYPE,
                        "ALTERNATE_PROTOCOL_ID": item.ALTERNATE_PROTOCOL_ID,
                        "ALTERNATE_PROTOCOL_TYPE": item.ALTERNATE_PROTOCOL_TYPE
                    })
                    return null;
                })
                let columnsClinicalDownloadAll = [
                    { header: "JNJ NUMBER", key: "JNJ_NUMBER", width: 13 },
                    { header: "INN NAME", key: "INN_NAME", width: 13 },
                    { header: "PROTOCOL ID", key: "PROTOCOL_ID", width: 18 },
                    { header: "Clinical Trial", key: "Clinical_Trial", width: 12 },
                    { header: "IDP NUMBER", key: "IDP_NUMBER", width: 13 },
                    { header: "PROJECT STATUS", key: "PROJECT_STATUS", width: 17 },
                    { header: "STUDY PHASE", key: "STUDY_PHASE", width: 18 },
                    { header: "PROTOCOL TITLE", key: "PROTOCOL_TITLE", width: 200 },
                    { header: "STUDY STATUS", key: "STUDY_STATUS", width: 16 },
                    { header: "INTERVENTIONAL STUDY", key: "INTERVENTIONAL_STUDY", width: 23 },
                    { header: "SPONSOR TYPE", key: "SPONSOR_TYPE", width: 18 },
                    { header: "ALTERNATE PROTOCOL ID", key: "ALTERNATE_PROTOCOL_ID", width: 25 },
                    { header: "ALTERNATE PROTOCOL TYPE", key: "ALTERNATE_PROTOCOL_TYPE", width: 27 }
                ];
                let csvCRSDataDownloadAll = [];
                let crsDataDownloadAll = await this.findCRS(this.buttonsearchterm)
                crsDataDownloadAll.map((item, index) => {
                    csvCRSDataDownloadAll.push({
                        "JNJ_NUMBER": item.JNJ_NUMBER,
                        "INN_NAME": item.INN_NAME,
                        "PROTOCOL_ID": {
                            text: item.PROTOCOL_ID,
                            hyperlink: `${window.origin}/results?query=&q=&filters=&f1=protocolIDs&fval1=${item.PROTOCOL_ID}&fcount=1`
                        }, //item.PROTOCOL_ID,
                        "Clinical_Trial": item.Clinical_Trial !== undefined ? item.Clinical_Trial : "",
                        "IDP_NUMBER": item.IDP_NUMBER,
                        "STUDY_SITE_STATUS": item.STUDY_SITE_STATUS,
                        "HEALTHCARE_FACILITY": item.HEALTHCARE_FACILITY,
                        "INVESTIGATOR": item.INVESTIGATOR,
                        "COUNTRY_NAME": item.COUNTRY_NAME
                    })
                    return null;
                })
                let columnsCRSDownloadAll = [
                    { header: "JNJ NUMBER", key: "JNJ_NUMBER", width: 13 },
                    { header: "INN NAME", key: "INN_NAME", width: 13 },
                    { header: "PROTOCOL ID", key: "PROTOCOL_ID", width: 18 },
                    { header: "Clinical Trial", key: "Clinical_Trial", width: 12 },
                    { header: "IDP NUMBER", key: "IDP_NUMBER", width: 13 },
                    { header: "STUDY SITE STATUS", key: "STUDY_SITE_STATUS", width: 19 },
                    { header: "HEALTHCARE FACILITY", key: "HEALTHCARE_FACILITY", width: 105 },
                    { header: "INVESTIGATOR", key: "INVESTIGATOR", width: 32 },
                    { header: "COUNTRY NAME", key: "COUNTRY_NAME", width: 56 }
                ];

                this.handelAllLists();
                excelDatas.push({ csvData: csvClinicalDataDownloadAll, columns: columnsClinicalDownloadAll, worksheetName: 'Clinical studies in scope' })
                excelDatas.push({ csvData: csvCRSDataDownloadAll, columns: columnsCRSDownloadAll, worksheetName: 'CRS for studies in scope' })
                excelDatas.push({ csvData: csvCompoundsDataDownloadAll, columns: columnsCompoundsDownloadAll, worksheetName: 'Compounds in Projects in scope' })
                let fileNameAllLists = "AllLists_" + this.generateDownloadTimestamp()
                this.setState({ docProcessing: true }, async () => {
                    await this.services.prepareMultipleWorksheetsExcelDocument(excelDatas).then((res) => {
                        const data = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        FileSaver.saveAs(data, fileNameAllLists);
                        this.setState({ docProcessing: false })
                    }).catch((error) => {
                        console.log(error);
                        this.setState({ docProcessing: false })
                    });
                })
                break;
            default:
                break;
        }
    }
    csepData = async (selectedProduct) => {
        let res = await this.services.clinicalStudiesEvidencePanelData(selectedProduct);
        this.setState({ evdiencePanelData: res })
        return res
    }
    csepPhases = () => {
        let phases = [];
        this.state.evdiencePanelData.map(data => {
            phases.push(data.phaseofstudy)
        })
        phases = phases.filter((v, i, a) => a.findIndex(t => (t === v)) === i);
        return phases.sort();
    }
    phaseofstudy = () => {
        let phases = this.csepPhases();
        if (phases.length > 0) {
            return (
                <>
                    {
                        phases.map((data, index) => {
                            return (
                                <>
                                    <div key={index + Math.random().toString()} className="csep-phases">
                                        {
                                            this.state.isPhaseShow[index] ?
                                                <img src={cskpArrowDown} alt="down-arrow" onClick={() => this.isPhaseClicked(index)} />
                                                :
                                                <img src={cskpArrowLeft} alt="left-arrow" onClick={() => this.isPhaseClicked(index)} />
                                        }
                                        <div className="csep-phases-text" onClick={() => this.isPhaseClicked(index)}>{data}</div>
                                    </div>
                                    {
                                        this.state.isPhaseShow[index] &&
                                        <ul className="csep-sub-phases">
                                            <li className="csep-protocol-text" onClick={() => this.clickedProtocolName(data)}>By Protocol Name</li>
                                            <li className="csep-protocol-text" onClick={() => this.clickedProtocolNumber(data)}>By Protocol Number</li>
                                        </ul>
                                    }
                                </>
                            )
                        })
                    }
                </>
            )
        }
    }
    isPhaseClicked = (index) => {
        let show = this.state.isPhaseShow;
        let phases = this.csepPhases();
        for (let i = 0; i < phases.length; i++) {
            show[i] = i === index ? !this.state.isPhaseShow[index] : false;
            if (!show[i]) {
                this.setState({ protocolNameData: [], protocolNumberData: [] })
            }
        }
        this.setState({ isPhaseShow: show, isProtocolClick: false })
    }
    clickedProtocolName = (data) => {
        let protocolNameData = [];
        let result = this.state.evdiencePanelData.filter(item => item.phaseofstudy === data);
        result.map(item => {
            if (item.protocolname) {
                protocolNameData.push(item.protocolname)
            }
        })
        this.setState({ protocolNameData: protocolNameData, protocolNumberData: [], isProtocolClick: true, selectedPhase: data })
    }
    clickedProtocolNumber = (data) => {
        let protocolNumberData = [];
        let result = this.state.evdiencePanelData.filter(item => item.phaseofstudy === data);
        result.map(item => {
            if (item.protocolnumber) {
                protocolNumberData.push(item.protocolnumber)
            }
        })
        this.setState({ protocolNumberData: protocolNumberData, protocolNameData: [], isProtocolClick: true, selectedPhase: data })
    }
    byProtocolNameData = (nameResult) => {
        if (nameResult.length > 0) {
            return (
                <>
                    {
                        nameResult.map((data, index) => {
                            let url = `/results?query=${this.state.selectedProduct}&q=All%20Fields&filters=Phase of Study/` + this.state.selectedPhase + `&f1=tempJNJDrugs&fval1=${this.state.selectedProduct}&f2=ctNames&fval2=${data}&fcount=2&cspath=By Protocol Name-${data}&csproduct=${this.state.selectedProduct}`
                            return (
                                <div key={index + Math.random().toString()} className="csep-protocols">
                                    <div><Link to={url} target="_blank" className='cs-modalLink' onClick={() => this.handelProtocolNameLinks(data)}>{data}</Link></div>
                                </div>
                            )
                        })
                    }
                </>
            )
        }
    }
    byProtocolNumberData = (numberResults) => {
        if (numberResults.length > 0) {
            return (
                <>
                    {
                        numberResults.map((data, index) => {
                            let url = `/results?query=${this.state.selectedProduct}&q=All%20Fields&filters=Phase of Study/` + this.state.selectedPhase + `&f1=tempJNJDrugs&fval1=${this.state.selectedProduct}&f2=protocolIDs&fval2=${data}&fcount=2&cspath=By Protocol Number-${data}&csproduct=${this.state.selectedProduct}`
                            return (
                                <div key={index + Math.random().toString()} className="csep-protocols">
                                    <div><Link to={url} target="_blank" className='cs-modalLink' onClick={() => this.handelProtocolNumberLinks(data)}>{data}</Link></div>
                                </div>
                            )
                        })
                    }
                </>
            )
        }
    }
    handelProtocolNameLinks = (title) => {
        const ts = new trackingService();
        ts.indexTrackingData(
            localStorage.getItem("userdisplayName"),
            localStorage.getItem("wwid"),
            localStorage.getItem("username"),
            localStorage.getItem("function"),
            localStorage.getItem("region"),
            localStorage.getItem("userproducts"),
            localStorage.getItem("doctypepref"),
            localStorage.getItem("sid"),
            localStorage.getItem("deviceType"),
            localStorage.getItem("deviceOS"),
            localStorage.getItem("browserName"),
            localStorage.getItem("browserVersion"),
            localStorage.getItem("jnjDepartment"),
            localStorage.getItem('companyname'),
            `Visited ${this.state.selectedProduct} for ${this.state.selectedPhase} by protocol name ${title}`,
            "",
            window.location.href,
            this.state.selectedProduct,
            "Phase of Study/" + this.state.selectedPhase,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            `By Protocol Name-${title}`,
            this.state.selectedProduct
        );
    }
    handelProtocolNumberLinks = (title) => {
        const ts = new trackingService();
        ts.indexTrackingData(
            localStorage.getItem("userdisplayName"),
            localStorage.getItem("wwid"),
            localStorage.getItem("username"),
            localStorage.getItem("function"),
            localStorage.getItem("region"),
            localStorage.getItem("userproducts"),
            localStorage.getItem("doctypepref"),
            localStorage.getItem("sid"),
            localStorage.getItem("deviceType"),
            localStorage.getItem("deviceOS"),
            localStorage.getItem("browserName"),
            localStorage.getItem("browserVersion"),
            localStorage.getItem("jnjDepartment"),
            localStorage.getItem('companyname'),
            `Visited ${this.state.selectedProduct} for ${this.state.selectedPhase} by protocol number ${title}`,
            "",
            window.location.href,
            this.state.selectedProduct,
            "Phase of Study/" + this.state.selectedPhase,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            `By Protocol Number-${title}`,
            this.state.selectedProduct
        );
    }
    handelDataPanelLinks = (title) => {
        const ts = new trackingService();
        ts.indexTrackingData(
            localStorage.getItem("userdisplayName"),
            localStorage.getItem("wwid"),
            localStorage.getItem("username"),
            localStorage.getItem("function"),
            localStorage.getItem("region"),
            localStorage.getItem("userproducts"),
            localStorage.getItem("doctypepref"),
            localStorage.getItem("sid"),
            localStorage.getItem("deviceType"),
            localStorage.getItem("deviceOS"),
            localStorage.getItem("browserName"),
            localStorage.getItem("browserVersion"),
            localStorage.getItem("jnjDepartment"),
            localStorage.getItem('companyname'),
            `Displays ${title} for ${this.state.selectedProduct}`,
            "",
            window.location.href,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            `Clinical Studies Data Panel-${title}`,
            this.state.selectedProduct
        );
    }
    handelDataPanelExcel = (title) => {
        const ts = new trackingService();
        ts.indexTrackingData(
            localStorage.getItem("userdisplayName"),
            localStorage.getItem("wwid"),
            localStorage.getItem("username"),
            localStorage.getItem("function"),
            localStorage.getItem("region"),
            localStorage.getItem("userproducts"),
            localStorage.getItem("doctypepref"),
            localStorage.getItem("sid"),
            localStorage.getItem("deviceType"),
            localStorage.getItem("deviceOS"),
            localStorage.getItem("browserName"),
            localStorage.getItem("browserVersion"),
            localStorage.getItem("jnjDepartment"),
            localStorage.getItem('companyname'),
            window.location.pathname === '/CSKP' ? `Download excel file for ${title} for ${this.state.selectedProduct}` : `Lite version for Download excel file for ${title} for ${this.props.searchKeyword}`,
            "",
            window.location.href,
            window.location.pathname === '/CSKP' ? '' : this.props.searchKeyword,
            window.location.pathname === '/CSKP' ? '' : this.props.appliedFilters,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            window.location.pathname === '/CSKP' ? `Download excel file for ${title}` : '',
            window.location.pathname === '/CSKP' ? this.state.selectedProduct : ''
        );
    }
    handelAllLists = () => {
        const ts = new trackingService();
        ts.indexTrackingData(
            localStorage.getItem("userdisplayName"),
            localStorage.getItem("wwid"),
            localStorage.getItem("username"),
            localStorage.getItem("function"),
            localStorage.getItem("region"),
            localStorage.getItem("userproducts"),
            localStorage.getItem("doctypepref"),
            localStorage.getItem("sid"),
            localStorage.getItem("deviceType"),
            localStorage.getItem("deviceOS"),
            localStorage.getItem("browserName"),
            localStorage.getItem("browserVersion"),
            localStorage.getItem("jnjDepartment"),
            localStorage.getItem('companyname'),
            window.location.pathname === '/CSKP' ? `Download All lists for ${this.state.selectedProduct}` : `Download All lists in Clinical Studies Knowledge Panel Lite for ${this.props.searchKeyword}`,
            "",
            window.location.href,
            window.location.pathname === '/CSKP' ? '' : this.props.searchKeyword,
            window.location.pathname === '/CSKP' ? '' : this.props.appliedFilters,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            window.location.pathname === '/CSKP' ? `Download All lists` : '',
            window.location.pathname === '/CSKP' ? this.state.selectedProduct : ''
        );
    }
    handelCSKPDropdown = () => {
        this.setState({ isCSKPDropdownOpen: !this.state.isCSKPDropdownOpen })
    }
    onMouseLeaveInfo = () => {
        this.setState({
            isCSKPDropdownOpen: false
        })
    }
    render() {
        const { searchKeyword } = this.props;
        if (window.location.pathname === '/CSKP') {
            return (
                <ErrorBoundary>
                    {
                        this.state.isLoaded ?
                            <div className="cskp-wrapper">
                                <h6 className="cskp-heading">Details about Janssen active, internal Clinical Studies</h6>
                                <div className="cs-modalHeader" style={{ justifyContent: "center", display: this.state.products.length <= 0 && "none", padding: 0, paddingBottom: "1rem" }}>
                                    <div style={{ textAlign: "center" }}>
                                        <div class="modal-title h4">
                                            <small className="cs-modalTitle">Your Product Preferences</small>
                                            {this.state.products.length > 0 && this.productList()}
                                        </div>
                                    </div>
                                </div>
                                {this.state.products.length <= 0 &&
                                    <div className="wnfy-no-product-content-section" style={{ padding: "1% 0" }}>
                                        No product preference selected. Please select some product in order to see the Clinical Studies Knowledge Panel details for the same.
                                    </div>
                                }
                                {
                                    (this.state.products.length > 0 && this.state.selectedProduct.length > 0) &&
                                    <>
                                        {this.state.evdiencePanelData.length > 0 ?
                                            <>
                                                <div className="csep-container">
                                                    <h5>Clinical Studies Evidence Panel</h5>
                                                    <span className="csep-content">To retrieve scientific evidence directly related to the clinical studies of interest, 1) select your <b>product</b> from the list above, 2) expand the <b>phase of study</b> in the left panel below to select either <b>by Protocol Name</b> or <b>by Protocol Number.</b> The corresponding protocol names or numbers will appear in the right panel. Select the one of interest, then a new window will open listing all scientific evidence on iMS for that study.</span>
                                                </div>
                                                <div className="csep-protocol-section">
                                                    <div className="csep-leftPart">
                                                        {this.phaseofstudy()}
                                                    </div>
                                                    <div className="csep-rightPart">
                                                        {
                                                            this.state.isProtocolClick ?
                                                                this.state.protocolNameData.length > 0 ?
                                                                    this.byProtocolNameData(this.state.protocolNameData)
                                                                    :
                                                                    this.state.protocolNumberData.length > 0 ?
                                                                        this.byProtocolNumberData(this.state.protocolNumberData)
                                                                        :
                                                                        <p className="csep-noData-content">Currently there are no trials by name available for this product.</p>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                                <hr />
                                            </>
                                            :
                                            <>
                                                <div className="csep-container">
                                                    <h5>Clinical Studies Evidence Panel</h5>
                                                    <span className="csep-content">Currently there are no trials available for this product.</span>
                                                </div>
                                                <hr />
                                            </>
                                        }
                                        <div className="csep-container">
                                            <h5 style={{ display: "initial" }}>Clinical Studies Data Panel</h5>
                                            {(this.state.selectedProduct.length > 0 && this.state.searchTypeRes.length > 0) &&
                                                <span className="ddig-button-container">
                                                    <Button text="Powered by DDIG" customColor="#0074B0" action={() => { this.setState({ showDDIGModal: true }) }} />
                                                </span>}
                                            {
                                                (this.state.selectedProduct.length > 0 && this.state.searchTypeRes.length > 0) ?
                                                    <>

                                                        <span className="csep-content">To view data about the clinical studies of interest, expand one of the options listed below to view the data and/or export it to Excel. If all options are of interest, you can export all lists into an Excel spreadsheet by clicking on the <b>Download All Lists</b> button.</span><br />

                                                        <div className="csdp-dataLink">
                                                            <div onClick={this.showCompoundIDP} className="cskp-link-container">
                                                                {
                                                                    this.state.isWhatSearchCompound ?
                                                                        <img src={cskpArrowDown} alt="down-arrow" />
                                                                        :
                                                                        <img src={cskpArrowLeft} alt="left-arrow" />
                                                                }
                                                                <span className="csdp-links">List of Internal Development Projects (IDPs)</span>
                                                            </div>
                                                            <img src={excelIcon} alt="Excel Icon" onClick={() => this.generateCSV("Compounds")} />
                                                        </div>
                                                        {this.state.isWhatSearchCompound && this.compoundIDPData(this.state.compoundData)}

                                                        <div className="csdp-dataLink">
                                                            <div onClick={this.showClinicalStudyIDP} className="cskp-link-container">
                                                                {
                                                                    this.state.isWhatSearchClinical ?
                                                                        <img src={cskpArrowDown} alt="down-arrow" />
                                                                        :
                                                                        <img src={cskpArrowLeft} alt="left-arrow" />
                                                                }
                                                                <span className="csdp-links">List of Clinical Studies for each IDP</span>
                                                            </div>
                                                            <img src={excelIcon} alt="Excel Icon" onClick={() => this.generateCSV("Clinical")} />
                                                        </div>
                                                        {this.state.isWhatSearchClinical && this.clinicalStudyIDPData(this.state.clinicalStudyIDPData)}

                                                        <div className="csdp-dataLink">
                                                            <div onClick={this.showClinicalResearchSite} className="cskp-link-container">
                                                                {
                                                                    this.state.isWhatSearchCRS ?
                                                                        <img src={cskpArrowDown} alt="down-arrow" />
                                                                        :
                                                                        <img src={cskpArrowLeft} alt="left-arrow" />
                                                                }
                                                                <span className="csdp-links">Clinical Research Sites</span>
                                                            </div>
                                                            <img src={excelIcon} alt="Excel Icon" onClick={() => this.generateCSV("CRS")} />
                                                        </div>
                                                        {this.state.isWhatSearchCRS && this.clinicalResearchSiteData(this.state.crsData)}

                                                        <div className="csdp-dataLink">
                                                            <Button text="Download all Lists" customColor="#0074B0" action={() => this.generateCSV("All")} />
                                                        </div>
                                                    </>
                                                    :
                                                    <span className="csep-content">This product is not present in MDM Hub Database.</span>
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                            :
                            <div style={{ textAlign: "center", padding: "10%" }}>
                                <img src={imsLoader} alt="Loading Data" style={{ width: "10%" }} />
                            </div>
                    }
                    {this.state.showDDIGModal && <PoweredByDDIGModal showModal={this.state.showDDIGModal} customWidth={this.state.width} handleClose={(status) => this.setState({ showDDIGModal: status })} />}
                    {this.state.docProcessing &&
                        <>
                            <DocumentProcessing showModal={this.state.docProcessing} color="#0074B0" />
                            <div className="savemodal-outer-wrapper"></div>
                        </>
                    }
                    <div style={{ margin: "0% 1%" }} class={(this.state.evdiencePanelData.length <= 0 && this.state.searchTypeRes.length <= 0) && "fixed-bottom"}>
                        <Footer customWidth={this.state.width} customHeight={this.state.height} />
                    </div>
                </ErrorBoundary>
            )
        }
        else {
            return (
                <>
                    {
                        (this.state.searchTypeRes.length > 0 || this.buttonsearchterm.length > 0) ?
                            <div onMouseLeave={this.onMouseLeaveInfo}>
                                <div onClick={this.handelCSKPDropdown} className="cskp-dropdown-component dropdown-text">
                                    <span className="cskp-text">Clinical Studies Knowledge Panel</span>
                                    <img src={arrowIconBlue} alt="dropdown-icon" />
                                </div>
                                {
                                    (this.state.isCSKPDropdownOpen && this.buttonsearchterm.length > 0) &&
                                    <div className="cskp-dropdown-component cskp-dropdown-wrapper">
                                        <span className="searchterm-name">{searchKeyword}</span>
                                        <ul>
                                            <li onClick={() => this.generateCSV("Compounds")} className="cskp-lite-links">List of Internal Development Projects (IDPs)</li>
                                            <li onClick={() => this.generateCSV("Clinical")} className="cskp-lite-links">List of Clinical Studies for each IDP</li>
                                            <li onClick={() => this.generateCSV("CRS")} className="cskp-lite-links">Clinical Research Sites</li>
                                            <li onClick={() => this.generateCSV("All")} className="cskp-lite-links">Download All Lists</li>
                                        </ul>
                                    </div>
                                }
                                {this.state.docProcessing &&
                                    <>
                                        <DocumentProcessing showModal={this.state.docProcessing} color="#0074B0" />
                                        <div className="savemodal-outer-wrapper"></div>
                                    </>
                                }
                            </div>
                            :
                            null
                    }
                </>
            )
        }
    }
}