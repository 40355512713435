import { useState, useEffect } from "react";
import styled from "styled-components";


// Inside ToggleSwitch.tsx module
const StyledLabel = styled.label`  
cursor: pointer;  
text-indent: -9999px;  
width: 40px;  
height: 20px;  
background: ${({ checked }) => (checked ? "#34c759" : "#adadad")};  
display: block;  
border-radius: 100px;  
position: relative;
&:after {    
content: "";    
position: absolute;    
left: ${({ checked }) => (checked ? "3px" : "calc(55% - 0.5px)")};    
top: 2.2px;    
width: 15px;    
height: 15px;    
background: #fff;    
border-radius: 100px;    
transition: 0.3s;  
}`;
// Creating ToggleSwitch.tsx module

export default function ToggleSwitch(props) {
    const [switchState, setSwitchState] = useState(props.isComprehensive);
    useEffect(() => {
        setSwitchState(props.isComprehensive)
    }, [props.isComprehensive])
    function handleOnChange(e) {
        props.handleOnChangeToggleSwitch(!switchState);
        setSwitchState(!switchState);
    }
    return (
        <StyledLabel htmlFor={`checkbox-${props.customKey}`} checked={switchState} key={`styled-label-${props.customKey}`}>
            <input
                id={`checkbox-${props.customKey}`}
                type="checkbox"
                value={switchState}
                checked={switchState}
                onChange={handleOnChange}  key={`switch-${props.customKey}`}/>
        </StyledLabel>
    );
}