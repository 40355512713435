/* eslint-disable array-callback-return */
import { Component } from "react";
import HeaderSearch from "../../components/header/HeaderSearch";
import Footer from "../../components/footer/Footer";
import DocumentCard from "../DocumentResults/DocumentCard";
import queryString from "query-string";
import SearchFilter from "../FilterComponent/SearchFilter";
import axios from "axios";
import FilterService from "../../services/FilterService";
import PageItems from "../../components/Paginate/PageItems";
import Pagination from "../../components/Paginate/Pagination";
import processFilters from "../FilterComponent/ProcessFilter";
import FilterDisplay from "../../components/FilterDisplay/FilterDisplay";
import Services from "../../utils/services";
import ApiServices from "../../utils/apiServices";
import {
  imsLoader,
  filterIconWhite,
  collapseFilterWhite,
  expandFilterWhite,
  clearSearchMobView
} from "../../assets";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import NavigationMenu from "../../components/Navigation/NavigationMenu";
import "./Results.css";
import ResponsiveFilterComponent from "../ResponsiveFilterComponent/ResponsiveFilterComponent";
import GlobalSearchFeaturePopUpModal from "../../components/PopUp/GlobalSearchFeaturesPopUpModal";
import ReadingListPopup from "../../components/PopUp/ReadingListPopup";
import BookmarkPopUp from "../../components/PopUp/BookmarkpopUp";
import SuccessReadingList from "../../components/PopUp/SucessReadingList";
import AlertMessagePopUpModal from "../../components/PopUp/AlertMessagePopUpModal";
import { LocationOrigin } from "../../utils/utilityServices";

var category;
class ReadingList extends Component {
  userid;
  userExists;
  globalCollapseAll = false;
  documentsUrl = [];
  markedDocumentsUrl = [];
  constructor(props) {
    super(props);
    this.state = {
      response: [],
      username: "",
      userExists: false,
      collapsed: false,
      searchKeyword: "",
      dropdownValue: "",
      allFilters: {},
      documents: [],
      documentCount: 0,
      appliedFilters: [], //Filters that are applied for while search is happening
      selectedFilters: [],
      itemsPerPage: "25",
      currentPage: "1",
      presentDocRendered: "0",
      isLoaded: false,
      isProcessing: true,
      filters: {},
      f1: "",
      fval1: "",
      fieldedSearchArr: {},
      selectedParentFilterArr: [],
      showMajorTopicSearch: false,
      majorTopicSearchState: true,
      showFilters: true,
      width: window.innerWidth,
      height: window.innerHeight,
      citationSelectStatus: [],
      // selectedDocumentIndex: 0,
      isClearSearch: false,
      showResultType: false,
      resultDropdownValue: "iMS Search Results",
      selectedResults: [],
      prevUrl: "",
      globalCollapseAll: false,
      collapseFilter: false,
      globalSelectAll: false,
      displayedResults: [],
      globalEmailPopUp: false,
      csPath: "",
      csproduct: "",
      readingListPopup: false,
      bookmarkPopUp: false,
      successMessage:
        "The result was successfully removed from Reading list(s).",
      showSuccess: false,
      sortByValue: "Relevance",
      sortBy: "",
      sortField: "",
      recentlyUsedFilters: [],
      collapsedSearchIDs: [],
      alertMessageModelColor: "",
      alertMessageContent: "",
      alertMessageShowModal: false,
    };
  }
  async componentDidMount() {
    document.title = "Search";
    this.userid = JSON.parse(localStorage.getItem("username"));
    this.wwid = JSON.parse(localStorage.getItem('wwid'));
    this.userExists = localStorage.getItem("userExists");
    var itemsPerPage = localStorage.getItem("ItemsPerPage");
    var activePage = localStorage.getItem("ActivePage");
    let service = new Services();
    let apiService = new ApiServices();
    service.logService(
      `Results page successfully mounted for ${this.userid.toLowerCase()}`,
      "info"
    );
    if (this.userExists) {
      this.setState({
        userExists: this.userExists,
      });
      /*To detect if user is coming from View Selected Page with some selected data*/
      if (sessionStorage.getItem("selectedResultsDetails") === null) {
        this.setState({
          globalSelectAll: false,
        });
      } else {
        if (
          JSON.parse(sessionStorage.getItem("selectedResultsDetails")).length >
          0
        ) {
          this.setState({
            globalSelectAll: true,
          });
        } else {
          this.setState({
            globalSelectAll: false,
          });
        }
      }
      if (itemsPerPage !== null) {
        this.setState({
          itemsPerPage: itemsPerPage,
        });
      }
      if (activePage !== null) {
        this.setState({
          currentPage: activePage,
        });
      }
      if (this.props.location.search === "") {
        this.props.history.replace("/results?query=&q=All%20Fields&filters=");
      }
      /*Get Approval Status of user and assign localStorage Values*/
      let approvedState = await apiService.getUserStatus(
        this.wwid
      );
      let apiRes = await apiService.getUserApprovedRegionRole(
        this.wwid
      );
      if (approvedState.status === "Accepted") {
        localStorage.setItem("region", approvedState.regions);
        localStorage.setItem(
          "function",
          JSON.stringify(approvedState.profession)
        );
      } else {
        if (apiRes) {
          localStorage.setItem("region", apiRes.region);
          localStorage.setItem("function", JSON.stringify(apiRes.function));
        } else {
          localStorage.setItem("region", approvedState.regions);
          localStorage.setItem("function", JSON.stringify("Commercial"));
        }
      }
      /*Get the localStorage values to getAllFilters POST Call*/
      if (localStorage.getItem("contentpreference") == null) {
        let res = await apiService.getContentPreference(
          this.userid.toLowerCase()
        );
        localStorage.setItem(
          "contentpreference",
          JSON.stringify(res.contentprefernce)
        );
      }
      let reqBody = {
        function: localStorage.getItem("function").replace(/"/g, ""),
        doctypepref: localStorage.getItem("doctypepref"),
        userproducts: localStorage.getItem("userproducts"),
        region: localStorage.getItem("region"),
        username: localStorage
          .getItem("username")
          .toLowerCase()
          .replace(/"/g, ""),
        contentpreference: localStorage
          .getItem("contentpreference")
          .replace(/"/g, ""),
      };
      let response = await service.getAllFilters(reqBody);
      if (response) {
        this.setState({ allFilters: {} });
        let processedFilters = processFilters(response.data.body);
        this.setState({
          allFilters: processedFilters,
          isProcessing: false,
        });
      }
      this.setState({
        recentlyUsedFilters: JSON.parse(
          localStorage.getItem("recentlyUsedFilters")
        ),
      });
      const queryParams = new URLSearchParams(this.props.location.search);
      const params = queryParams.get("query");
      const filters = queryParams.get("filters");
      const f1 = queryParams.get("f1");
      const fieldedSearch = queryParams.get("FS");
      let csPath = queryParams.get("cspath");
      let csproduct = queryParams.get("csproduct");
      if (fieldedSearch) {
        this.runFieldedSearch();
      } else {
        /* if(params!== "")
            {
                this.setState({
                    showMajorTopicSearch : true
                })
            } */
        if (params !== "" && f1 === "majorTopicsSynonyms") {
          this.setState({
            showMajorTopicSearch: true,
          });
        } else if (
          (params !== "" && f1 === undefined) ||
          f1 === null ||
          f1 === ""
        ) {
          this.setState({
            showMajorTopicSearch: true,
          });
        } else if (params !== "" && f1 !== "majorTopicsSynonyms") {
          this.setState({
            showMajorTopicSearch: false,
          });
        }
        if (f1 === "majorTopicsSynonyms") {
          this.setState({
            majorTopicSearchState: false,
            showMajorTopicSearch: true,
          });
        }
        /*Check for appliedFilter*/
        if (this.props.location.state) {
          this.setState(
            {
              appliedFilters: this.props.location.state.appliedFilters,
              /* f1:f1,
                        fval1:fval1 */
            },
            () => {
              this.setState({
                selectedParentFilterArr: this.generateSelectParentArray(
                  this.state.appliedFilters
                ),
              });
              if (params !== "" || params !== undefined || params !== null) {
                this.runSearch(params, this.state.appliedFilters);
              }
            }
          );
        } else if (filters !== "") {
          this.setState(
            {
              appliedFilters: filters.split(";"),
              /* f1:f1,
                        fval1:fval1 */
            },
            () => {
              this.setState({
                selectedParentFilterArr: this.generateSelectParentArray(
                  this.state.appliedFilters
                ),
              });
              if (params !== "" || params !== undefined || params !== null) {
                this.runSearch(params, this.state.appliedFilters);
              }
            }
          );
        } else {
          this.setState(
            {
              appliedFilters: [],
              /* f1:f1,
                        fval1:fval1 */
            },
            () => {
              this.setState({
                selectedParentFilterArr: this.generateSelectParentArray(
                  this.state.appliedFilters
                ),
              });
              if (params !== "" || params !== undefined || params !== null) {
                this.runSearch(params, this.state.appliedFilters);
              }
            }
          );
        }
        /*Check for selectedFilter*/
        if (this.props.location.state) {
          this.setState({
            selectedFilters: this.props.location.state.selectedFilters,
          });
        } else if (filters !== "") {
          this.setState({
            selectedFilters: filters.split(";"),
          });
        } else {
          this.setState({
            selectedFilters: [],
          });
        }
      }
      //console.log(csPath,csproduct)
      this.setState({
        searchKeyword: params.query,
        dropdownValue: params.q,
        csPath: csPath,
        csproduct: csproduct,
      });
      window.addEventListener("resize", this.updateDimensions);
    } else {
      console.log("Access Denied");
      this.props.history.push("/");
    }

    this.setState({ prevUrl: window.location.search });

    // VIEW SELECTED LOGIC
    let data = this.state.selectedResults;
    let citationSelectStatus = this.state.citationSelectStatus;
    let markedUrl = this.markedDocumentsUrl;
    var sessiondata = sessionStorage.getItem("selectedResultsDetails");
    // for (let index = 0; index < this.state.documentCount; index++) {
    if (localStorage.getItem("selectedResults") === null) {
      // console.log(this.props.location.search,this.state.prevUrl,window.location.search)
      if (window.location.search !== this.state.prevUrl) {
        data = [];
        citationSelectStatus = [];
        markedUrl = [];
      }
    }
    if (
      localStorage.getItem("selectedResults") !== null &&
      sessiondata !== null
    ) {
      if (this.props.location.search !== this.state.prevUrl) {
        data = [];
        citationSelectStatus = [];
        localStorage.removeItem("selectedResults");
        localStorage.removeItem("ItemsPerPage");
        localStorage.removeItem("ActivePage");
        localStorage.removeItem("markedResultsUrl");
        sessionStorage.clear();
      } else {
        localStorage
          .getItem("selectedResults")
          .split(",")
          .map((x, key) => {
            let i = parseInt(x);
            citationSelectStatus[i - 1] = true;
            if (citationSelectStatus[i - 1]) {
              data = JSON.parse(sessiondata);
              markedUrl = JSON.parse(localStorage.getItem("markedResultsUrl"));
            } else {
              data.splice(i - 1, 1);
              markedUrl.splice(i - 1, 1);
            }
          });
      }
    }
    // }
    // console.log(data,citationSelectStatus)
    this.markedDocumentsUrl = markedUrl;
    this.setState({
      citationSelectStatus: citationSelectStatus,
      selectedResults: data,
    });
  }
  componentWillUnmount() {
    let service = new Services();
    window.removeEventListener("resize", this.updateDimensions);
    service.logService(
      `Reading List page successfully unmounted for ${this.userid.toLowerCase()}`,
      "info"
    );
  }
  updateDimensions = () => {
    this.setState({ width: window.outerWidth, height: window.outerHeight });
  };
  runFieldedSearch = () => {
    this.setState({ isProcessing: true });
    var url =
      LocationOrigin +
      process.env.REACT_APP_IMS_API_PREFIX +
      "qp/fieldedSearch";
    //let filterStr = this.generateArrayString(filterSelected);
    var config;
    //let query = querytext;
    let isBoolean = false;
    const queryParams = new URLSearchParams(this.props.location.search);
    /*Check for filededSearch values*/
    const params = queryParams.get("query");
    const fcount = queryParams.get("fcount");
    const andFields = queryParams.get("andFields");
    const orFields = queryParams.get("orFields");
    const docTitle = queryParams.get("docTitle");
    const authors = queryParams.get("authors");
    const majorTopicsSynonyms = queryParams.get("majorTopicsSynonyms");
    //const f1 = queryParams.get('f1')
    //const sbQuery = queryParams.get('sbQuery');
    //const isDateRange = queryParams.get('isDateRange');
    /*Check for sbQuery*/
    /* if (sbQuery !== null) {
            query = sbQuery
        } */
    /*Check for boolean operator in Query String Start*/
    isBoolean = queryParams.get("isBoolean");
    let booleanCounter = (params.match(/ OR | AND | NOT | NEAR /g) || [])
      .length;
    if (isBoolean !== null || booleanCounter >= 4) {
      isBoolean = true;
    } else if (booleanCounter >= 1 && params.split(" ").length <= 10) {
      isBoolean = true;
    } else {
      isBoolean = false;
    }
    let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'));
    /*Check for boolean operator in Query String End*/
    let val,
      fromRange,
      toRange;
    if (fcount > 0) {
      let arr = {
        query: params,
        function: localStorage.getItem("function").replace(/"/g, ""),
        doctypepref: localStorage.getItem("doctypepref"),
        userproducts: localStorage.getItem("userproducts"),
        region: localStorage.getItem("region"),
        username: localStorage
          .getItem("username")
          .toLowerCase()
          .replace(/"/g, ""),
        contentpreference: localStorage
          .getItem("contentpreference")
          .replace(/"/g, ""),
        from:
          this.state.currentPage * this.state.itemsPerPage -
          this.state.itemsPerPage,
        size: parseInt(this.state.itemsPerPage),
        fcount: parseInt(fcount),
        boolean: isBoolean,
        accessGroup: (userAccessGroup.includes("MISEHCP")) ? "MISE" : "",
      };
      for (let i = 1; i <= fcount; i++) {
        let key = queryParams.get(`f${i}`);
        let value = queryParams.get(`fval${i}`);
        arr["f" + i] = key;
        arr["fval" + i] = value;
        /* if (key === 'congressName' || key === 'protocolIDs' || key === 'docTypeSynonyms') {
                    checkFieldVal = true
                } */
      }
      /* if (checkFieldVal) {
                arr["matchPhrase"] = true
            } */
      // Making matchPhrase always true
      arr["matchPhrase"] = true;
      val = arr;
      config = {
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: val,
      };
    } else {
      config = {
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          query: params,
          function: localStorage.getItem("function").replace(/"/g, ""),
          doctypepref: localStorage.getItem("doctypepref"),
          userproducts: localStorage.getItem("userproducts"),
          region: localStorage.getItem("region"),
          username: localStorage
            .getItem("username")
            .toLowerCase()
            .replace(/"/g, ""),
          contentpreference: localStorage
            .getItem("contentpreference")
            .replace(/"/g, ""),
          from:
            this.state.currentPage * this.state.itemsPerPage -
            this.state.itemsPerPage,
          size: parseInt(this.state.itemsPerPage),
          boolean: isBoolean,
          accessGroup: (userAccessGroup.includes("MISEHCP")) ? "MISE" : ""
        },
      };
    }
    /* if (isDateRange !== null) {
            let rangeField = queryParams.get('rangeField'); */
    toRange = queryParams.get("toYear");
    fromRange = queryParams.get("fromYear");
    //config.data["rangeField"] = rangeField;
    //config.data["isDateRange"] = true
    if (toRange !== null) {
      config.data["toYear"] = toRange;
    }
    if (fromRange !== null) {
      config.data["fromYear"] = fromRange;
    }
    if (andFields !== null) {
      config.data["andFields"] = andFields;
    }
    if (orFields !== null) {
      config.data["orFields"] = orFields;
    }
    if (docTitle !== null) {
      config.data["docTitle"] = docTitle;
    }
    if (authors !== null) {
      config.data["authors"] = authors;
    }
    if (majorTopicsSynonyms !== null) {
      config.data["majorTopicsSynonyms"] = majorTopicsSynonyms;
    }
    /* } */
    // let citationSelectStatus = [];
    axios(config)
      .then((response) => {
        var processed = processFilters(response.data.body);
        this.setState(
          {
            documentCount: response.data.body.hits.total.value,
            response: response.data.body.hits.hits,
            filters: processed,
            isProcessing: false,
            isLoaded: true,
            collapseFilter: false,
          },
          () => {
            this.handleAllFilters(
              this.state.filters,
              this.state.appliedFilters,
              this.state.selectedFilters,
              this.state.response,
              this.state.documentCount,
              params
            );
          }
        );
      })
      .catch(function (error) {
        //console.log(error);
      })
      .finally(() => {
        this.setState({ isProcessing: false });
      });
  };
  handleDocCount = (presentCount) => {
    this.setState({
      presentDocRendered: presentCount,
    });
  };
  handleNextClick = () => {
    if (
      this.state.currentPage <
      Math.ceil(this.state.documentCount / this.state.itemsPerPage)
    ) {
      // if (this.state.currentPage <= 1) {
      this.setState(
        {
          currentPage: parseInt(this.state.currentPage) + 1,
        },
        () => {
          this.handlePageChange(this.state.currentPage);
        }
      );
    }
  };
  handlePreviousClick = () => {
    if (this.state.currentPage >= 2) {
      this.setState(
        {
          currentPage: this.state.currentPage - 1,
        },
        () => {
          this.handlePageChange(this.state.currentPage);
        }
      );
    }
  };
  navigateToLastPage = () => {
    this.setState(
      {
        currentPage: Math.ceil(
          this.state.documentCount / this.state.itemsPerPage
        ),
      },
      () => {
        this.handlePageChange(this.state.currentPage);
      }
    );
  };
  navigateToFirstPage = () => {
    this.setState(
      {
        currentPage: 1,
      },
      () => {
        this.handlePageChange(this.state.currentPage);
      }
    );
  };
  handleChangeSelectSortBy = (e) => {
    if (e.target.value === "Date,Newest") {
      this.setState({ sortBy: "desc", sortField: "modifiedDate" });
    } else if (e.target.value === "Date,Oldest") {
      this.setState({ sortBy: "asc", sortField: "modifiedDate" });
    } else if (
      e.target.value === "Document Type, Ascending" ||
      e.target.value === "DocType, Asc"
    ) {
      this.setState({ sortBy: "asc", sortField: "docType" });
    } else if (
      e.target.value === "Document Type, Descending" ||
      e.target.value === "DocType, Desc"
    ) {
      this.setState({ sortBy: "desc", sortField: "docType" });
    } else {
      this.setState({ sortBy: "", sortField: "" });
    }
    this.setState(
      {
        sortByValue: e.target.value,
        citationSelectStatus: [],
        selectedResults: [],
        globalSelectAll: false,
        globalCollapseAll: false,
        collapsedSearchIDs: [],
      },
      () => {
        this.runSearchFromQueryParams(
          this.state.allFilters,
          this.state.appliedFilters,
          this.state.searchKeyword
        );
      }
    );
  };
  resultListCount = (itemsPerPage, currentPage) => {
    var endIndex, startIndex;
    startIndex = currentPage * itemsPerPage - itemsPerPage;
    endIndex = currentPage * itemsPerPage;
    // var count = startIndex;
    // const totalPages = Math.ceil(this.state.documentCount / itemsPerPage);
    // const previousOff = currentPage == 1;
    // const nextOff = currentPage == totalPages;
    if (this.state.width < 600) {
      return (
        <div
          className="results-count-wrapper"
          style={{
            justifyContent:
              this.state.response.length > 0 ? "space-between" : "flex-end",
          }}
        >
          {
            this.state.response.length > 0 && (
              <Pagination
                metadata={this.state.documentCount}
                handlePageChange={this.handlePageChange}
                itemsPerPage={this.state.itemsPerPage}
                page={this.state.currentPage}
                customWidth={this.state.width}
                customHeight={this.state.height}
              />
            )
            // <div className="resultListCount-container">
            //     {this.state.documentCount > 0 &&
            //         <span className="paginationContainer-mobile">
            //             <span className="startEndIndexText-mobile" onClick={this.navigateToFirstPage}>
            //                 {previousOff ? null : 1}
            //             </span>
            //             {
            //                 previousOff ? <img src={prdtNavButton} alt="leftArrowIcon" className="paginationArrowIcon-mobile" style={{ transform: "matrix(-1, 0, 0, -1, 0, 0)" }} /> : <img src={leftArrowMobileIcon} alt="leftArrowIcon" className="paginationArrowIcon-mobile" onClick={this.handlePreviousClick} style={{ cursor: "pointer" }} />
            //             }
            //             <span className="currentResultText-mobile">
            //                 {currentPage}
            //             </span>
            //             {
            //                 nextOff ? <img src={prdtNavButton} alt="rightArrowIcon" className="paginationArrowIcon-mobile" /> : <img src={rightArrowMobileIcon} alt="rightArrowIcon" className="paginationArrowIcon-mobile" onClick={this.handleNextClick} style={{ cursor: "pointer" }} />
            //             }
            //             <span className="startEndIndexText-mobile" onClick={this.navigateToLastPage}>
            //                 {nextOff ? null : totalPages}
            //             </span>
            //         </span>
            //     }
            // </div>
          }
          <div
            className="clearSearchContainer"
            onClick={this.handleClearSearch}
          >
            <img
              src={clearSearchMobView}
              alt="clearSearch"
              className="clearSearchIcon" /* onClick={this.handleClearSearch} */
            />
            {/* <span className="clearSearchText">Clear Search</span> */}
          </div>
        </div>
      );
    } else {
      return (
        <div className="results-count-wrapper">
          {/* {
                        this.state.width < 1000 &&
                        <div className="clearSearchContainer" onClick={this.handleClearSearch}>
                            <img src={clearSearchMobView} alt="clearSearch" className="clearSearchIcon" />
                            <span className="clearSearchText">Clear Search</span>
                        </div>
                    } */}
          {this.state.response.length > 0 && (
            <div className="resultListCount-container">
              {this.state.documentCount > 0 && (
                <span
                  className="showingResultsCountContainer" /* style={{ display: "flex", width: "200px" }} */
                >
                  {endIndex <= this.state.documentCount ? (
                    <div className="results-number-show-wrapper">
                      Showing {startIndex + 1}-{endIndex} of{" "}
                      {this.state.documentCount}{" "}
                      {this.state.width > 1000 && "Results"}
                    </div>
                  ) : (
                    <div className="results-number-show-wrapper">
                      Showing {startIndex + 1}-{this.state.documentCount} of{" "}
                      {this.state.documentCount}{" "}
                      {this.state.width > 1000 && "Results"}
                    </div>
                  )}
                </span>
              )}
              <span
                style={{
                  marginRight: "0.5%",
                  display: "flex",
                  width: this.state.width <= 630 ? "160px" : "180px",
                }}
              >
                <PageItems
                  handleChangeSelectItems={this.handleChangeSelectItems}
                  itemsPerPage={this.state.itemsPerPage}
                  customWidth={this.state.width}
                />
              </span>
              {this.state.width >= 1000 ? (
                <div className="sort-by-wrapper">
                  <span className="sort-by-text">Sort By:</span>
                  <select
                    className="sort-by-select-wrapper"
                    onChange={this.handleChangeSelectSortBy}
                    defaultValue={this.state.sortByValue}
                  >
                    <option>Relevance</option>
                    <option>Date,Newest</option>
                    <option>Date,Oldest</option>
                    <option>Document Type, Ascending</option>
                    <option>Document Type, Descending</option>
                  </select>
                </div>
              ) : (
                this.state.width >= 600 && (
                  <div className="sort-by-wrapper">
                    <span className="sort-by-text">Sort By:</span>
                    <select
                      className="sort-by-select-wrapper"
                      onChange={this.handleChangeSelectSortBy}
                      defaultValue={this.state.sortByValue}
                    >
                      <option>Relevance</option>
                      <option>Date,Newest</option>
                      <option>Date,Oldest</option>
                      <option>DocType, Asc</option>
                      <option>DocType, Desc</option>
                    </select>
                  </div>
                )
              )}
            </div>
          )}
          {this.state.width < 1000 && (
            <div
              className="clearSearchContainer"
              onClick={this.handleClearSearch}
            >
              <img
                src={clearSearchMobView}
                alt="clearSearch"
                className="clearSearchIcon"
              />
              {this.state.width >= 805 && (
                <span className="clearSearchText">Clear Search</span>
              )}
            </div>
          )}
        </div>
      );
    }
  };
  getSelectedCitationDetails = () => {
    //console.log(this.state.citationSelectStatus)
    //console.log(this.state.selectedResults.sort(function (a, b) { return a.index - b.index }))
    if (this.state.selectedResults.length > 0) {
      let selectedResultsDetails = this.state.selectedResults;
      sessionStorage.setItem(
        "selectedResultsDetails",
        JSON.stringify(selectedResultsDetails)
      );
      let selectedResultsIndex = this.state.selectedResults.map((x) => x.index);
      localStorage.setItem("selectedResults", selectedResultsIndex);
      localStorage.setItem(
        "markedResultsUrl",
        JSON.stringify(this.markedDocumentsUrl)
      );
      localStorage.setItem("ItemsPerPage", this.state.itemsPerPage);
      localStorage.setItem("ActivePage", this.state.currentPage);
      this.props.history.push({
        pathname: "/viewmarked",
        state: { selectedResults: this.state.selectedResults },
      });
    } else {
      this.errorAlertMessage("There are no documents selected");
    }
  };
  handleCitationSelection = (index, item, itemTeaser, key) => {
    let a = this.state.citationSelectStatus;
    a[index - 1] = !this.state.citationSelectStatus[index - 1];
    let data = this.state.selectedResults;
    let urls = this.markedDocumentsUrl;
    if (this.state.citationSelectStatus[index - 1]) {
      data.push({
        detail: item,
        itemTeaser: itemTeaser,
        index: index,
      });
      urls.push(this.documentsUrl[key]);
    } else {
      const i = data.map((x) => x.index).indexOf(index);
      if (i > -1) {
        data.splice(i, 1); // 2nd parameter means remove one item only
        urls.splice(i, 1);
      }
    }
    this.markedDocumentsUrl = urls;
    this.setState({
      citationSelectStatus: a,
      selectedResults: data,
    });
    let checkSelectStatus = a.filter((item) => item);
    if (checkSelectStatus.length === parseInt(this.state.itemsPerPage)) {
      this.setState({ globalSelectAll: true });
    } else if (checkSelectStatus.length === this.state.response.length) {
      this.setState({ globalSelectAll: true });
    } else {
      this.setState({ globalSelectAll: false });
    }
  };
  handleCitationSelectionGlobal = (index, item, itemTeaser, key) => {
    let a = this.state.citationSelectStatus;
    //console.log(a, index)
    a[index - 1] = true;
    let data = this.state.selectedResults;
    let urls = this.markedDocumentsUrl;
    if (this.state.citationSelectStatus[index - 1]) {
      //console.log("selectAll-1")
      data.push({
        detail: item,
        itemTeaser: itemTeaser,
        index: index,
      });
      urls.push(this.documentsUrl[key - 1]);
    } else {
      //console.log("selectAll-2")
      const i = data.map((x) => x.index).indexOf(index);
      if (i > -1) {
        data.splice(i, 1); // 2nd parameter means remove one item only
        urls.splice(i, 1);
      }
    }
    // console.log(a,data);
    this.markedDocumentsUrl = urls;
    this.setState({
      citationSelectStatus: a,
      selectedResults: data,
    });
  };
  handleCitationUnSelection = (index, item, itemTeaser) => {
    let a = this.state.citationSelectStatus;
    a[index - 1] = false;
    let data = this.state.selectedResults;
    let urls = this.markedDocumentsUrl;
    /* if (this.state.citationSelectStatus[index - 1]) {
            data.push({
                detail: item,
                itemTeaser: itemTeaser,
                index: index,
            })
        }
        else { */
    const i = data.map((x) => x.index).indexOf(index);
    if (i > -1) {
      data.splice(i, 1); // 2nd parameter means remove one item only
      urls.splice(i, 1);
    }
    /* } */
    // console.log(a,data)
    // let selectedResultsDetails = data;
    // sessionStorage.setItem("selectedResultsDetails", JSON.stringify(selectedResultsDetails));
    // let selectedResultsIndex = data.map(x => x.index)
    // localStorage.setItem('selectedResults', selectedResultsIndex)
    this.markedDocumentsUrl = urls;
    this.setState({
      citationSelectStatus: a,
      selectedResults: data,
    });
  };
  citationUrl = (url, index, item, displayedResultsUrl) => {
    if (item.domain === "CTGov") {
      displayedResultsUrl[index] = url;
    } else {
      displayedResultsUrl[index] = window.location.origin + url;
      // displayedResultsUrl[index] = "https://predev-ims.jnj.com" + url;
    }
    this.documentsUrl = displayedResultsUrl;
  };
  manageCollapseAndExpand = (iscollapsed, searchID) => {
    let { collapsedSearchIDs, response } = this.state;
    let responseSearchIDs = response.map((x) => x._source.searchID);
    if (iscollapsed) {
      collapsedSearchIDs.push(searchID);
    } else {
      collapsedSearchIDs = collapsedSearchIDs.filter((x) => x !== searchID);
    }
    let tempGlobalCollapseAll = responseSearchIDs.every((x) =>
      collapsedSearchIDs.includes(x)
    );
    this.setState({
      collapsedSearchIDs: collapsedSearchIDs,
      globalCollapseAll: tempGlobalCollapseAll,
    });
  };
  resultList = (results, itemsPerPage, currentPage) => {
    let { collapsedSearchIDs } = this.state;
    // window.scrollTo({ behavior: 'smooth', top: '0px' });
    var startIndex;
    startIndex = currentPage * itemsPerPage - itemsPerPage;
    // endIndex = currentPage * itemsPerPage;
    var count = startIndex;
    let displayedResults = [];
    let displayedResultsUrl = [];
    if (results.length > 0) {
      return (
        <>
          {results.map((item, index) => {
            count++;
            //console.log(item._source)
            if (
              item.highlight === undefined ||
              item.highlight?.body[0] === "Empty File" ||
              item.highlight?.body[0] === "Not an empty file"
            ) {
              displayedResults.push({
                detail: item._source,
                itemTeaser: [],
                index: index + 1,
              });
              // item.highlight = { 'body': ['Test body until indexed value is available'] }
            } else {
              displayedResults.push({
                detail: item._source,
                itemTeaser: item.highlight.body,
                index: index + 1,
              });
            }
            let responseSearchIDs = item._source.searchID;
            let globalCollapseAll = collapsedSearchIDs.some(
              (x) => x === responseSearchIDs
            );
            return (
              <>
                <div key={`result-id-${index}`} id={`result-id-${index}`}>
                  {
                    <div className="resultDetailsDiv">
                      <DocumentCard
                        item={item._source}
                        count={count}
                        appliedFilters={this.state.appliedFilters}
                        searchKeyword={this.state.searchKeyword}
                        pageNumber={this.state.currentPage}
                        pageSize={this.state.itemsPerPage}
                        itemTeaser={
                          item.highlight === undefined ||
                            item.highlight?.body[0] === "Empty File" ||
                            item.highlight?.body[0] === "Not an empty file"
                            ? []
                            : item.highlight.body
                        }
                        handleCitationSelection={(i, item, itemTeaser) =>
                          this.handleCitationSelection(
                            i,
                            item,
                            itemTeaser,
                            index
                          )
                        }
                        isChecked={this.state.citationSelectStatus[count - 1]}
                        customWidth={this.state.width}
                        //globalCollapseAll={this.state.globalCollapseAll}
                        globalCollapseAll={globalCollapseAll}
                        csPath={this.state.csPath}
                        csproduct={this.state.csproduct}
                        handleUnpin={this.handleUnpin}
                        citationUrl={(url) =>
                          this.citationUrl(
                            url,
                            index,
                            item._source,
                            displayedResultsUrl
                          )
                        }
                        manageCollapseAndExpand={this.manageCollapseAndExpand}
                      />
                      <div className="resultsDivFooter"></div>
                    </div>
                  }
                </div>
              </>
            );
          })}
          {localStorage.setItem(
            "displayedResults",
            JSON.stringify(displayedResults)
          )}
        </>
      );
    } else {
      let params = new URLSearchParams(this.props.location.search);
      let fval =
        params.get("fval1") !== "" ? params.get("fval1").split(";") : [];
      if (fval.length === 0) {
        return (
          <div className="results-no-search-content">
            <span>There is nothing pinned in this reading list.</span>
          </div>
        );
      } else {
        return (
          <div className="results-no-search-content">
            <span>Nothing in reading list matches your search</span>
            <br />
            <br />
            <span>Suggestions</span>
            <li>Make sure all words are spelled correctly</li>
            <li>Try different search terms</li>
            <li>Try more general search terms</li>
            <li>Try fewer search terms</li>
          </div>
        );
      }
    }
    //this.setState({displayedResults:displayedResults})
  };
  handleAllFilters = (
    filters,
    appliedfilters,
    selectedFilters,
    response,
    docCount,
    query
  ) => {
    this.setState({
      filters: filters,
      appliedFilters: appliedfilters,
      selectedFilters: selectedFilters,
      response: response,
      documentCount: docCount,
      searchKeyword: query,
    });
  };
  handleChangeInSearch = (query) => {
    this.setState(
      {
        searchKeyword: query,
        currentPage: "1",
        globalSelectAll: false,
        globalCollapseAll: false,
        collapsedSearchIDs: [],
      },
      () => {
        this.runSearchFromQueryParams(
          this.state.allFilters,
          this.state.appliedFilters,
          query
        );
      }
    );
  };
  handlePageChange = (currentPage) => {
    window.scrollTo({ behavior: "smooth", top: "0px" });
    let checkSelectStatus = this.state.citationSelectStatus.filter(
      (item) => item
    );
    if (
      this.state.globalSelectAll &&
      (checkSelectStatus.length.toString() === this.state.itemsPerPage ||
        this.state.citationSelectStatus.length === this.state.documentCount)
    ) {
      this.setState({ citationSelectStatus: [], selectedResults: [] });
    }
    this.setState(
      {
        currentPage: currentPage,
        response: [],
        isProcessing: true,
        isLoaded: false,
        globalCollapseAll: false,
        globalSelectAll: false,
        collapsedSearchIDs: [],
      },
      () => {
        let setSelectStatus = false;
        let startIndex =
          this.state.currentPage * this.state.itemsPerPage -
          this.state.itemsPerPage;
        let endIndex = this.state.currentPage * this.state.itemsPerPage;
        let citationStatusForCurrentPage =
          this.state.citationSelectStatus.slice(startIndex, endIndex);
        //console.log(startIndex,endIndex,citationStatusForCurrentPage)
        setSelectStatus = citationStatusForCurrentPage.some((e) => e === true);
        //console.log(setSelectStatus)
        if (setSelectStatus) {
          this.setState({
            globalSelectAll: true,
          });
        } else {
          this.setState({
            globalSelectAll: false,
          });
        }
        this.runSearchFromQueryParams(
          this.state.allFilters,
          this.state.appliedFilters,
          this.state.searchKeyword
        );
      }
    );
  };
  generateSelectParentArray = (appliedfilters) => {
    let parentFacet = [];
    appliedfilters.forEach((filter) => {
      const filterParts = filter.split("/");
      const filterTop = filterParts[0];
      parentFacet.push(filterTop);
    });
    return parentFacet;
  };
  handleOnChangeInfilter = (
    filters,
    appliedfilters,
    selectedFilters,
    response,
    doc,
    query
  ) => {
    let params = queryString.parse(this.props.location.search);
    //var filters = appliedfilters;
    filters = appliedfilters;
    let selectedFacets = this.generateSelectParentArray(appliedfilters);
    const newUrl = this.buildUrl(
      params.query,
      params.q,
      params.f1,
      filters,
      false
    );
    this.props.history.push({
      pathname: "/viewReadingList",
      search: newUrl,
      state: {
        selectedFilters: selectedFilters,
        allFilters: this.state.allFilters,
        appliedFilters: appliedfilters,
      },
    });
    this.setState(
      {
        currentPage: "1",
        selectedParentFilterArr: selectedFacets,
        collapseFilter: true,
        selectedFilters: selectedFilters,
        globalSelectAll: false,
      },
      () => {
        this.runSearchFromQueryParams(
          this.state.allFilters,
          appliedfilters,
          this.state.searchKeyword
        );
      }
    );
  };
  handleChangeSelectItems = (items) => {
    //console.log(this.state.citationSelectStatus);
    let checkSelectStatus = this.state.citationSelectStatus.filter(
      (item) => item
    );
    if (
      this.state.globalSelectAll &&
      (checkSelectStatus.length.toString() === this.state.itemsPerPage ||
        this.state.citationSelectStatus.length === this.state.documentCount)
    ) {
      this.setState({ citationSelectStatus: [], selectedResults: [] });
    }
    this.setState(
      {
        itemsPerPage: items,
        globalCollapseAll: false,
        globalSelectAll: false,
        collapsedSearchIDs: [],
        selectedResults: [],
        citationSelectStatus: [],
      },
      () => {
        let totalPages = Math.ceil(
          this.state.documentCount / this.state.itemsPerPage
        );
        if (totalPages < this.state.currentPage) {
          this.setState(
            {
              currentPage: totalPages,
            },
            () => {
              let setSelectStatus = false;
              let startIndex =
                this.state.currentPage * this.state.itemsPerPage -
                this.state.itemsPerPage;
              let endIndex = this.state.currentPage * this.state.itemsPerPage;
              let citationStatusForCurrentPage =
                this.state.citationSelectStatus.slice(startIndex, endIndex);
              //console.log(startIndex,endIndex,citationStatusForCurrentPage)
              setSelectStatus = citationStatusForCurrentPage.some(
                (e) => e === true
              );
              //console.log(setSelectStatus)
              if (setSelectStatus) {
                this.setState({
                  globalSelectAll: true,
                });
              } else {
                this.setState({
                  globalSelectAll: false,
                });
              }
              this.runSearchFromQueryParams(
                this.state.allFilters,
                this.state.appliedFilters,
                this.state.searchKeyword
              );
            }
          );
        } else {
          this.runSearchFromQueryParams(
            this.state.allFilters,
            this.state.appliedFilters,
            this.state.searchKeyword
          );
        }
      }
    );
  };
  buildFieldedUrl = (searchKeyword, filters) => {
    let filterStr = this.generateArrayString(filters);
    let queryParams = new URLSearchParams(this.props.location.search);
    let params = queryParams.get("query");
    let q = queryParams.get("q");
    let fcount = queryParams.get("fcount");
    let andFields = queryParams.get("andFields");
    let orFields = queryParams.get("orFields");
    let docTitle = queryParams.get("docTitle");
    let authors = queryParams.get("authors");
    let majorTopicsSynonyms = queryParams.get("majorTopicsSynonyms");
    let fromYear = queryParams.get("fromYear");
    let toYear = queryParams.get("toYear");
    let url = `?query=${params}&q=${q}&filters=${filterStr}`;
    if (fcount > 0) {
      for (let i = 1; i <= fcount; i++) {
        let key = queryParams.get(`f${i}`);
        let value = queryParams.get(`fval${i}`);
        url += `&f${i}=${key}&fval${i}=${value}`;
      }
      url += `&fcount=${fcount}`;
    }
    if (andFields !== "" && andFields !== null) {
      url += `&andFields=${andFields}`;
    }
    if (orFields !== "" && orFields !== null) {
      url += `&orFields=${orFields}`;
    }
    if (docTitle !== "" && docTitle !== null) {
      url += `&docTitle=${docTitle}`;
    }
    if (authors !== "" && authors !== null) {
      url += `&authors=${authors}`;
    }
    if (majorTopicsSynonyms !== "" && majorTopicsSynonyms !== null) {
      url += `&majorTopicsSynonyms=${majorTopicsSynonyms}`;
    }
    if (fromYear !== "" && fromYear !== null) {
      url += `&fromYear=${fromYear}`;
    }
    if (toYear !== "" && toYear !== null) {
      url += `&toYear=${toYear}`;
    }
    url += `&FS=true`;
    return url;
  };
  buildUrl = (
    searchKeyword,
    dropdownValue,
    fieldedVal,
    filters,
    isClearSearch
  ) => {
    //let filterStr = this.generateArrayString(filters);
    let params = new URLSearchParams(this.props.location.search);
    let q = params.get("q");
    let filterGiven = params.get("filters");
    let f1 = params.get("f1");
    let fval1 = params.get("fval1");
    let fcount = params.get("fcount");
    let rName = params.get("rName");
    let rID = params.get("rID");
    return `?query=${searchKeyword}&q=${q}&filters=${filterGiven}&f1=${f1}&fval1=${fval1}&fcount=${fcount}&rName=${rName}&rID=${rID}`;
    // let sbQuery = params.get("sbQuery");
    // let isDateRange = params.get("isDateRange");
    // let fieldedSearch = params.get("FS");
    // let csPath = params.get('cspath');
    // let csproduct = params.get('csproduct');
    // let fcount = params.get("fcount");
    // let url;
    // if (fieldedSearch) {
    //   url = this.buildFieldedUrl(searchKeyword, filters);
    // } else {
    //   if (fieldedVal === "") {
    //     url = `?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}`;
    //   } else if (
    //     (dropdownValue === "All Fields" && fieldedVal === "") ||
    //     fieldedVal === undefined
    //   ) {
    //     url = `?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}`;
    //   } else {
    //     url = `?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}&f1=${fieldedVal}&fval1=${searchKeyword}&fcount=1`;
    //   }
    //   if (sbQuery !== null && searchKeyword !== "") {
    //     url = url + `&sbQuery=${sbQuery}`;
    //   }
    //   if (csPath !== null && csproduct !== null && searchKeyword !== '') {
    //       url = url + `&cspath=${csPath}&csproduct=${csproduct}`
    //   }
    //   if (isDateRange !== null) {
    //     let rangeField = params.get("rangeField");
    //     let toRange = params.get("toRange");
    //     let fromRange = params.get("fromRange");
    //     url = url + `&isDateRange=true&rangeField=${rangeField}`;
    //     if (toRange !== null) {
    //       url += `&toRange=${toRange}`;
    //     }
    //     if (fromRange !== null) {
    //       url += `&fromRange=${fromRange}`;
    //     }
    //   }

    // }

    // if (isClearSearch === true) {
    //   url = `?query=&q=All Fields&filters=`;
    // }
    // return url;
  };
  runSearchFromQueryParams(allfilters, appliedfilters, querytext) {
    //console.log(querytext);
    this.setState({ isProcessing: true, isLoaded: false });
    const filterService = new FilterService();
    const query = querytext;
    const inFilters = appliedfilters;
    // const [filters] = filterService.expandFilterSet(allfilters, inFilters)
    const [filters] = filterService.expandFilterSet(
      this.state.filters,
      inFilters
    );
    const filterSelected = inFilters;
    let selectedFiltered = this.state.selectedFilters.concat(filters);
    selectedFiltered = [...new Set(selectedFiltered)];
    this.setState(
      {
        appliedFilters: inFilters,
        selectedFilters: selectedFiltered,
        // selectedFilters: filters
      },
      () => {
        this.runSearch(query, filterSelected);
      }
    );
  }
  generateArrayString = (array) => {
    let arrStr = "";
    array.map((item, index) => {
      if (index === array.length - 1) {
        arrStr += item;
      } else {
        arrStr += item + ";";
      }
      return null;
    });
    return arrStr;
  };
  runSearch(querytext, filterSelected) {
    this.setState({
      isProcessing: true,
      globalCollapseAll: false /* , globalSelectAll: false */,
      collapsedSearchIDs: [],
    });
    //var url = LocationOrigin + process.env.REACT_APP_IMS_QP_API + 'runSearch'
    var url =
      LocationOrigin + process.env.REACT_APP_IMS_API_PREFIX + "qp/runSearch";
    let filterStr = this.generateArrayString(filterSelected);
    var config;
    let query = querytext;
    let isBoolean = false;
    const queryParams = new URLSearchParams(this.props.location.search);
    /*Check for filededSearch values*/
    const params = queryParams.get("query");
    const fcount = queryParams.get("fcount");
    const f1 = queryParams.get("f1");
    const sbQuery = queryParams.get("sbQuery");
    const isDateRange = queryParams.get("isDateRange");
    let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'));
    /*Check for sbQuery*/
    if (sbQuery !== null) {
      query = sbQuery;
    }
    /*Check for boolean operator in Query String Start*/
    isBoolean = queryParams.get("isBoolean");
    let booleanCounter = (query.match(/ OR | AND | NOT | NEAR /g) || []).length;
    if (isBoolean !== null || booleanCounter >= 4) {
      isBoolean = true;
    } else if (booleanCounter >= 1 && query.split(" ").length <= 10) {
      isBoolean = true;
    } else {
      isBoolean = false;
    }
    /*Check for boolean operator in Query String End*/
    if (params !== "" && f1 === "majorTopicsSynonyms") {
      this.setState({
        showMajorTopicSearch: true,
      });
    } else if (
      (params !== "" && f1 === undefined) ||
      f1 === null ||
      f1 === ""
    ) {
      this.setState({
        showMajorTopicSearch: true,
      });
    } else if (params !== "" && f1 !== "majorTopicsSynonyms") {
      this.setState({
        showMajorTopicSearch: false,
      });
    }
    if (f1 === "majorTopicsSynonyms") {
      this.setState({
        majorTopicSearchState: false,
        showMajorTopicSearch: true,
      });
    }
    let val, checkFieldVal = false;
    if (fcount > 0) {
      let arr = {
        query: query,
        filters: filterStr,
        function: localStorage.getItem("function").replace(/"/g, ""),
        doctypepref: localStorage.getItem("doctypepref"),
        userproducts: localStorage.getItem("userproducts"),
        region: localStorage.getItem("region"),
        username: localStorage
          .getItem("username")
          .toLowerCase()
          .replace(/"/g, ""),
        contentpreference: localStorage
          .getItem("contentpreference")
          .replace(/"/g, ""),
        from:
          this.state.currentPage * this.state.itemsPerPage -
          this.state.itemsPerPage,
        size: parseInt(this.state.itemsPerPage),
        sortBy: this.state.sortBy,
        sortField: this.state.sortField,
        fcount: parseInt(fcount),
        boolean: isBoolean,
        accessGroup: (userAccessGroup.includes("MISEHCP")) ? "MISE" : "",
      };
      for (let i = 1; i <= fcount; i++) {
        let key = queryParams.get(`f${i}`);
        let value = queryParams.get(`fval${i}`);
        arr["f" + i] = key;
        arr["fval" + i] = value;
        // if (
        //   key === "congressName" ||
        //   key === "protocolIDs" ||
        //   key === "docTypeSynonyms"
        // ) {
        checkFieldVal = true;
        // }
      }
      if (checkFieldVal) {
        arr["matchPhrase"] = true;
      }
      val = arr;
      config = {
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: val,
      };
    } else {
      config = {
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          query: query,
          filters: filterStr,
          function: localStorage.getItem("function").replace(/"/g, ""),
          doctypepref: localStorage.getItem("doctypepref"),
          userproducts: localStorage.getItem("userproducts"),
          region: localStorage.getItem("region"),
          username: localStorage
            .getItem("username")
            .toLowerCase()
            .replace(/"/g, ""),
          contentpreference: localStorage
            .getItem("contentpreference")
            .replace(/"/g, ""),
          from:
            this.state.currentPage * this.state.itemsPerPage -
            this.state.itemsPerPage,
          size: parseInt(this.state.itemsPerPage),
          sortBy: this.state.sortBy,
          sortField: this.state.sortField,
          boolean: isBoolean,
          accessGroup: (userAccessGroup.includes("MISEHCP")) ? "MISE" : "",
        },
      };
    }
    if (isDateRange !== null) {
      let rangeField = queryParams.get("rangeField");
      let toRange = queryParams.get("toRange");
      let fromRange = queryParams.get("fromRange");
      config.data["rangeField"] = rangeField;
      config.data["isDateRange"] = true;
      if (toRange !== null) {
        config.data["toRange"] = toRange;
      }
      if (fromRange !== null) {
        config.data["fromRange"] = fromRange;
      }
    }
    // let citationSelectStatus = [];
    if (queryParams.get("fval1") !== "") {
      axios(config)
        .then((response) => {
          var processed = processFilters(response.data.body);
          const filterService = new FilterService();
          const [filters] = filterService.expandFilterSet(
            processed,
            this.state.appliedFilters
          );
          let selectedFiltered = this.state.selectedFilters.concat(filters);
          // selectedFiltered = [...new Set(selectedFiltered)];
          selectedFiltered = this.state.appliedFilters.concat(filters);
          this.setState(
            {
              documentCount: response.data.body.hits.total.value,
              response: response.data.body.hits.hits,
              filters: processed,
              isProcessing: false,
              isLoaded: true,
              collapseFilter: false,
              selectedFilters: selectedFiltered,
            },
            () => {
              if (
                this.state.globalSelectAll &&
                (this.state.citationSelectStatus.length.toString() ===
                  this.state.itemsPerPage ||
                  this.state.citationSelectStatus.length ===
                  this.state.documentCount)
              ) {
                this.setState({ globalSelectAll: true });
              } else {
                this.setState({ globalSelectAll: false });
              }
              this.handleAllFilters(
                this.state.filters,
                this.state.appliedFilters,
                this.state.selectedFilters,
                this.state.response,
                this.state.documentCount,
                querytext
              );
            }
          );
          // VIEW SELECTED LOGIC
          let data = this.state.selectedResults;
          let citationSelectStatus = this.state.citationSelectStatus;
          let markedUrl = this.markedDocumentsUrl;
          var sessiondata = sessionStorage.getItem("selectedResultsDetails");
          // for (let index = 0; index < this.state.documentCount; index++) {
          if (localStorage.getItem("selectedResults") === null) {
            // console.log(this.props.location.search,this.state.prevUrl,window.location.search)
            if (window.location.search !== this.state.prevUrl) {
              data = [];
              citationSelectStatus = [];
              markedUrl = [];
            }
            /* else {
                        citationSelectStatus.push(false)
                    } */
          }
          if (
            localStorage.getItem("selectedResults") !== null &&
            sessiondata !== null
          ) {
            // let totalPages = Math.ceil(this.state.documentCount / this.state.itemsPerPage)
            // console.log(this.state.itemsPerPage, totalPages,localStorage.getItem('selectedResults'), Math.max.apply(null,localStorage.getItem('selectedResults').split(',')))
            // else{
            // if (window.location.search != this.state.prevUrl) {
            if (
              decodeURI(this.props.location.search) !==
              decodeURI(this.state.prevUrl)
            ) {
              data = [];
              citationSelectStatus = [];
              localStorage.removeItem("selectedResults");
              localStorage.removeItem("ItemsPerPage");
              localStorage.removeItem("ActivePage");
              localStorage.removeItem("markedResultsUrl");
              sessionStorage.clear();
            }
            // else {
            //     localStorage.getItem('selectedResults').split(',').map((x, key) => {
            //         let i = parseInt(x)
            //         citationSelectStatus[i - 1] = true;
            //         if (citationSelectStatus[i - 1]) {
            //             data = JSON.parse(sessiondata)
            //             // data.push(JSON.parse(sessiondata))
            //             // data.push({
            //             //     detail: this.state.response[i - 1]._source,
            //             //     itemTeaser: this.state.response[i - 1].highlight.body,
            //             //     index: i
            //             // })
            //         }
            //         else {
            //             data.splice(i - 1, 1)
            //         }
            //     })
            // }
          }
          // }
          // console.log(data,citationSelectStatus)
          this.markedDocumentsUrl = markedUrl;
          this.setState({
            citationSelectStatus: citationSelectStatus,
            selectedResults: data,
            recentlyUsedFilters: JSON.parse(
              localStorage.getItem("recentlyUsedFilters")
            ),
          });
        })
        .catch(function (error) {
          //console.log(error);
        })
        .finally(() => {
          this.setState({ isProcessing: false });
        });
    } else {
      const filters = [];
      let selectedFiltered = this.state.selectedFilters.concat(filters);
      selectedFiltered = this.state.appliedFilters.concat(filters);
      this.setState(
        {
          documentCount: 0,
          response: [],
          filters: filters,
          isProcessing: false,
          isLoaded: true,
          collapseFilter: false,
          selectedFilters: selectedFiltered,
        },
        () => {
          if (
            this.state.globalSelectAll &&
            (this.state.citationSelectStatus.length.toString() ===
              this.state.itemsPerPage ||
              this.state.citationSelectStatus.length ===
              this.state.documentCount)
          ) {
            this.setState({ globalSelectAll: true });
          } else {
            this.setState({ globalSelectAll: false });
          }
          this.handleAllFilters(
            this.state.filters,
            this.state.appliedFilters,
            this.state.selectedFilters,
            this.state.response,
            this.state.documentCount,
            querytext
          );
        }
      );
    }
  }
  handleClearFilters = () => {
    //console.log('Clear Filters from Breadcrumbs')
    this.setState(
      {
        selectedFilters: [],
        appliedFilters: [],
        selectedParentFilterArr: [],
        filters: [],
        globalSelectAll: false,
        selectedResults: [],
        citationSelectStatus: [],
      },
      () => {
        let params = queryString.parse(this.props.location.search);
        const newUrl = this.buildUrl(
          params.query,
          params.q,
          params.f1,
          this.state.appliedFilters,
          false
        );
        this.props.history.push(newUrl);
        this.runSearchFromQueryParams(
          this.state.allFilters,
          this.state.appliedFilters,
          this.state.searchKeyword
        );
      }
    );
  };
  handleRemoveAppliedFilter = (facet) => {
    let parentFacetName = facet.split("/")[0];
    let arr = this.state.selectedParentFilterArr;
    const index = arr.indexOf(parentFacetName);
    if (index > -1) {
      arr.splice(index, 1);
    }
    this.setState(
      {
        appliedFilters: this.state.appliedFilters.filter(
          (filter) => !filter.startsWith(facet)
        ),
        selectedParentFilterArr: arr,
        selectedFilters: this.state.selectedFilters.filter(
          (filter) => filter && !filter.startsWith(facet)
        ),
        globalSelectAll: false,
        selectedResults: [],
        citationSelectStatus: [],
      },
      () => {
        let params = queryString.parse(this.props.location.search);
        const newUrl = this.buildUrl(
          params.query,
          params.q,
          params.f1,
          this.state.appliedFilters,
          false
        );
        this.props.history.push(newUrl);
        this.runSearchFromQueryParams(
          this.state.allFilters,
          this.state.appliedFilters,
          this.state.searchKeyword
        );
      }
    );
  };
  handleClearSearch = () => {
    this.setState(
      {
        selectedFilters: [],
        appliedFilters: [],
        filters: [],
        selectedParentFilterArr: [],
        searchKeyword: "",
        isClearSearch: true,
        currentPage: 1,
        globalSelectAll: false,
        selectedResults: [],
        citationSelectStatus: [],
      },
      () => {
        //let params = queryString.parse(this.props.location.search);
        const newUrl = this.buildUrl(
          this.state.searchKeyword,
          "All Fields",
          "",
          this.state.appliedFilters,
          true
        );
        this.props.history.push(newUrl);
        this.runSearchFromQueryParams(
          this.state.allFilters,
          this.state.appliedFilters,
          this.state.searchKeyword
        );
      }
    );
  };
  handleMajorTopicSearch = (url) => {
    this.props.history.replace(url);
    window.location.reload();
    /* this.setState({
            showMajorTopicSearch:true,
            majorTopicSearchState:!this.state.majorTopicSearchState
        }) */
  };
  handelResults = () => {
    this.setState({ showResultType: !this.state.showResultType });
  };
  handleChangeInDropdown = (event) => {
    event.preventDefault();
    this.setState({
      resultDropdownValue: event.target.attributes.keyvalue.value,
      showResultType: !this.state.showResultType,
    });
  };
  handelSearchResultClose = () => {
    this.setState({ showResultType: false });
  };
  conversionOfFieldType = (item) => {
    if (item === "docType") return "Document Type";
    else if (item === "protocolIDs" || item === "harmonizedProtocolIDs") return "Protocol Number";
    else if (item === "genericName") return "Generic Name";
    else if (item === "congressName") return "Congress Name";
    else return "Journal Name";
  };
  showFieldedSearch = () => {
    let queryParams = new URLSearchParams(this.props.location.search);
    let limistArr = [];
    let fieldedSearch = false;
    let fcount = queryParams.get("fcount");
    let docTitle = queryParams.get("docTitle");
    let authors = queryParams.get("authors");
    let majorTopicsSynonyms = queryParams.get("majorTopicsSynonyms");
    let fromRange = queryParams.get("fromYear");
    fieldedSearch = queryParams.get("FS");
    for (let i = 0; i < fcount; i++) {
      let key = queryParams.get(`f${i + 1}`);
      let value = queryParams.get(`fval${i + 1}`);
      limistArr[i] = { key: key, value: value };
    }
    if (
      fieldedSearch &&
      ((docTitle !== null && docTitle !== " ") ||
        (authors !== " " && authors !== null) ||
        (majorTopicsSynonyms !== " " && majorTopicsSynonyms !== null) ||
        fromRange !== null ||
        fcount > 0)
    ) {
      return (
        <div className="filter-selection-display-wrapper">
          <div className="applied-filter-header-section">
            <span className="applied-filter-header-text">Fielded Search</span>
          </div>
          <div className="filter-display-item-wrapper">
            <div className="facet-item-wrapper">
              <span className="facet-inner-wrapper">
                {docTitle !== " " && docTitle !== null && (
                  <>
                    <span className="fs-parent-field-wrapper">Title</span>
                    <span className="subfilter-wrapper">
                      <span className="subfilter">
                        <span className="subfilter-text">{docTitle}</span>
                      </span>
                    </span>
                  </>
                )}
                {authors !== " " && authors !== null && (
                  <>
                    <span className="fs-parent-field-wrapper">Authors</span>
                    <span className="subfilter-wrapper">
                      <span className="subfilter">
                        <span className="subfilter-text">{authors}</span>
                      </span>
                    </span>
                  </>
                )}
                {majorTopicsSynonyms !== " " && majorTopicsSynonyms !== null && (
                  <>
                    <span className="fs-parent-field-wrapper">
                      Major Topics
                    </span>
                    <span className="subfilter-wrapper">
                      <span className="subfilter">
                        <span className="subfilter-text">
                          {majorTopicsSynonyms}
                        </span>
                      </span>
                    </span>
                  </>
                )}
                {fromRange !== null && (
                  <>
                    <span className="fs-parent-field-wrapper">
                      Publication Year
                    </span>
                    <span className="subfilter-wrapper">
                      <span className="subfilter">
                        <span className="subfilter-text">{fromRange}</span>
                      </span>
                    </span>
                  </>
                )}
                {fcount > 0 && (
                  <>
                    {limistArr.map((item, index) => {
                      return (
                        <>
                          <span className="fs-parent-field-wrapper">
                            {this.conversionOfFieldType(item.key)}
                          </span>
                          <span className="subfilter-wrapper">
                            <span className="subfilter">
                              <span className="subfilter-text">
                                {item.value}
                              </span>
                            </span>
                          </span>
                        </>
                      );
                    })}
                  </>
                )}
              </span>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };
  handleCollapseAllGlobal = () => {
    let { response, collapsedSearchIDs } = this.state;
    if (!this.state.globalCollapseAll) {
      collapsedSearchIDs = response.map((x) => x._source.searchID);
    } else {
      collapsedSearchIDs = [];
    }
    this.setState({
      globalCollapseAll: !this.state.globalCollapseAll,
      collapsedSearchIDs: collapsedSearchIDs,
    });
  };
  handleSelectAll = () => {
    //console.log('Change reflecting till result');
    this.setState(
      {
        globalSelectAll: !this.state.globalSelectAll,
      },
      () => {
        let startIndex =
          this.state.currentPage * this.state.itemsPerPage -
          this.state.itemsPerPage +
          1;
        var count = startIndex;
        let endIndex = this.state.itemsPerPage;
        if (this.state.globalSelectAll) {
          this.markedDocumentsUrl = [];
          this.setState({ selectedResults: [] }, () => {
            for (let i = 1; i <= endIndex; i++) {
              //console.log(i,this.state.response[i-1])
              if (this.state.response[i - 1] !== undefined) {
                this.handleCitationSelectionGlobal(
                  count,
                  this.state.response[i - 1]._source,
                  this.state.response[i - 1].highlight === undefined ||
                    this.state.response[i - 1].highlight?.body[0] ===
                    "Empty File" ||
                    this.state.response[i - 1].highlight?.body[0] ===
                    "Not an empty file"
                    ? []
                    : this.state.response[i - 1].highlight.body,
                  i
                );
                count++;
              }
            }
          });
        } else {
          for (let i = 1; i <= endIndex; i++) {
            if (this.state.response[i - 1] !== undefined) {
              // console.log(count)
              this.handleCitationUnSelection(
                count,
                this.state.response[i - 1]._source,
                this.state.response[i - 1].highlight === undefined ||
                  this.state.response[i - 1].highlight?.body[0] ===
                  "Empty File" ||
                  this.state.response[i - 1].highlight?.body[0] ===
                  "Not an empty file"
                  ? []
                  : this.state.response[i - 1].highlight.body
              );
              count++;
            }
          }
        }
      }
    );
  };
  handleGlobalEmailPopUp = (status) => {
    this.setState({ globalEmailPopUp: status });
  };
  handleEmailPopUpClose = () => {
    this.setState({ globalEmailPopUp: false });
  };
  handleOnClickReadingList = (status) => {
    this.setState({ readingListPopup: status });
  };
  handleCloseReadingList = () => {
    this.setState({ readingListPopup: false });
  };
  handleGlobalBookmark = (status) => {
    this.setState({ bookmarkPopUp: status });
  };
  handleCloseBookmark = () => {
    this.setState({ bookmarkPopUp: false });
  };
  handleUnpin = async (item) => {
    let params = new URLSearchParams(this.props.location.search);
    let rlID = localStorage.getItem("rLID");
    let apiService = new ApiServices();
    let res;
    let docIdsNow = "";
    let readListDocIds = localStorage.getItem("readListDocIds");
    if (readListDocIds) {
      docIdsNow = readListDocIds.split(';').filter(x => x !== item.searchID && x).join(";");
    }
    // let regex = new RegExp("(" + item.searchID + ";)", "g");  
    // let docIdsNow = localStorage.getItem("readListDocIds").replace(regex, "");
    // docIdsNow =
    //   docIdsNow.substring(docIdsNow.length - 1) === ";"
    //     ? docIdsNow.substring(0, docIdsNow.lastIndexOf(";"))
    //     : docIdsNow;
    let resSelect = await apiService.selectDocFromReadingList(rlID);
    for (const itm of resSelect) {
      if (itm.doc_id.split(";").length > 1) {
        res = await apiService.updateDocIdFromReadingList(
          docIdsNow,
          rlID,
          itm.doc_id
        );
      } else {
        res = await apiService.deleteDocIdFromReadingList(item.searchID, rlID);
      }
    }
    if (res) {
      this.setState({
        showSuccess: true,
      });
      let f1 = params.get("f1");
      let url = `/viewReadingList?query=&q=All%20Fields&filters=`;
      if (f1 === "searchID") {
        url += `&f1=searchID&fval1=${docIdsNow}&fcount=1&rName=${localStorage.getItem(
          "rName"
        )}&rID=${rlID}`;
        this.props.history.push(url);
        this.runSearchFromQueryParams(
          this.state.allFilters,
          this.state.appliedFilters,
          this.state.searchKeyword
        );
      }
    }
    localStorage.setItem("readListDocIds", docIdsNow);
  };
  handleSuccessClose = (status) => {
    this.setState({
      showSuccess: status,
    });
  };
  errorAlertMessage = (message) => {
    this.setState({
      alertMessageContent: message,
      alertMessageModelColor: "#de1327",
      alertMessageShowModal: true
    })
  }

  closeAlertMessage = () => {
    this.setState({
      alertMessageContent: "",
      alertMessageModelColor: "",
      alertMessageShowModal: false
    })
  }
  render() {
    const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
    let params = new URLSearchParams(this.props.location.search);
    let query = params.get("query");
    /* const filters = queryParams.get('filters') 
        let params = queryString.parse(this.props.location.search);*/
    // var category;
    let rlName = params.get("rName");
    var filters = params.get("filters");
    if (params.get("q") === undefined) {
      category = "All Fields";
    } else {
      category = params.get("q");
    }
    if (filters === "" || filters === undefined || filters === null) {
      filters = "";
    }
    if (this.state.width < 600) {
      return (
        <div>
          <ErrorBoundary>
            {this.state.userExists ? (
              <>
                <div className="resultsInnerWrapper">
                  <NavigationMenu
                    pathName={window.location.pathname}
                    customWidth={this.state.width}
                    customHeight={this.state.height}
                  />
                  <div className="header-wrapper">
                    <HeaderSearch
                      history={this.props.history}
                      dropdownValue={category}
                      searchKeyword={query}
                      appliedFilters={this.state.appliedFilters}
                      handleChangeInSearch={this.handleChangeInSearch}
                      handleClearSearch={this.handleClearSearch}
                      customWidth={this.state.width}
                      customHeight={this.state.height}
                      isCitationSelected={this.state.citationSelectStatus}
                      isClearSearch={this.state.isClearSearch}
                      csPath={this.state.csPath}
                      csproduct={this.state.csproduct}
                    />
                  </div>
                  <div className="results-contents-wrapper">
                    <div className="imsResultsMenuWrapper-mobile">
                      <ResponsiveFilterComponent
                        pathName={window.location.pathname}
                        customWidth={this.state.width}
                        customHeight={this.state.height}
                        allFilters={this.state.allFilters}
                        filters={this.state.filters}
                        appliedFilters={this.state.appliedFilters}
                        handleOnChangeInfilter={this.handleOnChangeInfilter}
                        selectedParentFilterArr={
                          this.state.selectedParentFilterArr
                        }
                        handleChangeInSearch={this.handleChangeInSearch}
                        selectedFilters={this.state.selectedFilters}
                        querytext={this.state.searchKeyword}
                        documentCount={this.state.documentCount}
                        response={this.state.response}
                        isProcessing={this.state.isProcessing}
                        handelSelectedCitation={this.getSelectedCitationDetails}
                        handleCollapseAllGlobal={this.handleCollapseAllGlobal}
                        globalCollapseAll={this.state.globalCollapseAll}
                        collapseFilterRes={this.state.collapseFilter}
                        handleGlobalBookmark={this.handleGlobalBookmark}
                        globalSelectAll={this.state.globalSelectAll}
                        handleSelectAll={this.handleSelectAll}
                        handleGlobalEmailPopUp={this.handleGlobalEmailPopUp}
                        csPath={this.state.csPath}
                        csproduct={this.state.csproduct}
                        handleOnClickReadingList={this.handleOnClickReadingList}
                        recentlyUsedFilters={this.state.recentlyUsedFilters}
                      />
                      <div
                        className="imsResultsMenuHeader-mobile"
                        style={{ height: "auto" }}
                      >
                        <span style={{ wordBreak: "break-word" }}>Reading List: {rlName.toUpperCase()}</span>
                      </div>
                      <div className="resultsContentWrapper resultsContentWrapper-mobile">
                        {this.state.isLoaded ? (
                          <>
                            {/* {this.state.response.length > 0 &&
                                                    <> */}
                            {this.resultListCount(
                              this.state.itemsPerPage,
                              this.state.currentPage
                            )}
                            {this.state.appliedFilters.length > 0 ? (
                              <FilterDisplay
                                allFilters={this.state.allFilters}
                                appliedFilters={this.state.appliedFilters}
                                handleClearFilters={this.handleClearFilters}
                                handleRemoveAppliedFilter={
                                  this.handleRemoveAppliedFilter
                                }
                                customWidth={this.state.width}
                              />
                            ) : null}
                            <div className="results-wrapper">
                              {this.resultList(
                                this.state.response,
                                this.state.itemsPerPage,
                                this.state.currentPage
                              )}
                            </div>
                            {/* <Pagination metadata={this.state.documentCount} handlePageChange={this.handlePageChange} itemsPerPage={this.state.itemsPerPage} page={this.state.currentPage} customWidth={this.state.width} customHeight={this.state.height}/> */}
                          </>
                        ) : (
                          <div style={{ textAlign: "center", padding: "10%" }}>
                            <img
                              src={imsLoader}
                              alt="Loading Data"
                              style={{ width: "10%" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ margin: "0% 1%", marginTop: "auto" }}>
                  <Footer
                    customWidth={this.state.width}
                    customHeight={this.state.height}
                  />
                </div>
                {this.state.globalEmailPopUp && (
                  <GlobalSearchFeaturePopUpModal
                    showModal={this.state.globalEmailPopUp}
                    showEmailModal={true}
                    handleClose={this.handleEmailPopUpClose}
                    selectedResults={this.state.selectedResults}
                    customWidth={this.state.width}
                    customHeight={this.state.height}
                  />
                )}
                {this.state.bookmarkPopUp && (
                  <BookmarkPopUp
                    showModal={this.state.bookmarkPopUp}
                    handleClose={this.handleCloseBookmark}
                    customWidth={this.state.width}
                  />
                )}
                {this.state.readingListPopup && (
                  <ReadingListPopup
                    showModal={this.state.readingListPopup}
                    handleClose={this.handleCloseReadingList}
                    width={this.state.width}
                    selectedResults={this.state.selectedResults}
                  />
                )}
                {this.state.showSuccess && (
                  <>
                    <SuccessReadingList
                      display={this.state.showSuccess}
                      handleClose={(status) => this.handleSuccessClose(status)}
                      data={this.state.successMessage}
                      width={this.state.width}
                    />
                    <div className="savemodal-outer-wrapper"></div>
                  </>
                )}
              </>
            ) : (
              <div style={{ textAlign: "center", padding: "10%" }}>
                <img
                  src={imsLoader}
                  alt="Loading Data"
                  style={{ width: "20%" }}
                />
              </div>
            )}
            {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
          </ErrorBoundary>
        </div>
      );
    } else if (this.state.width >= 600 && this.state.width <= 1000) {
      return (
        <div>
          <ErrorBoundary>
            {this.state.userExists ? (
              <>
                <div className="resultsInnerWrapper">
                  <NavigationMenu
                    pathName={window.location.pathname}
                    customWidth={this.state.width}
                    customHeight={this.state.height}
                  />
                  <div className="header-wrapper">
                    <HeaderSearch
                      history={this.props.history}
                      dropdownValue={category}
                      searchKeyword={query}
                      appliedFilters={this.state.appliedFilters}
                      handleChangeInSearch={this.handleChangeInSearch}
                      handleClearSearch={this.handleClearSearch}
                      customWidth={this.state.width}
                      customHeight={this.state.height}
                      isCitationSelected={this.state.citationSelectStatus}
                      isClearSearch={this.state.isClearSearch}
                      csPath={this.state.csPath}
                      csproduct={this.state.csproduct}
                    />
                  </div>
                  <ResponsiveFilterComponent
                    pathName={window.location.pathname}
                    customWidth={this.state.width}
                    customHeight={this.state.height}
                    allFilters={this.state.allFilters}
                    filters={this.state.filters}
                    appliedFilters={this.state.appliedFilters}
                    handleOnChangeInfilter={this.handleOnChangeInfilter}
                    selectedParentFilterArr={this.state.selectedParentFilterArr}
                    handleChangeInSearch={this.handleChangeInSearch}
                    selectedFilters={this.state.selectedFilters}
                    querytext={this.state.searchKeyword}
                    documentCount={this.state.documentCount}
                    response={this.state.response}
                    isProcessing={this.state.isProcessing}
                    handelSelectedCitation={this.getSelectedCitationDetails}
                    handleCollapseAllGlobal={this.handleCollapseAllGlobal}
                    globalCollapseAll={this.state.globalCollapseAll}
                    collapseFilterRes={this.state.collapseFilter}
                    globalSelectAll={this.state.globalSelectAll}
                    handleSelectAll={this.handleSelectAll}
                    handleGlobalEmailPopUp={this.handleGlobalEmailPopUp}
                    handleGlobalBookmark={this.handleGlobalBookmark}
                    csPath={this.state.csPath}
                    csproduct={this.state.csproduct}
                    handleOnClickReadingList={this.handleOnClickReadingList}
                    recentlyUsedFilters={this.state.recentlyUsedFilters}
                  />
                  <div className="results-contents-wrapper">
                    <div className="imsResultsMenuWrapper-tablet">
                      <div className="imsResultsMenuHeader-tablet">
                        <span
                          className="imsResultsMenuHeader-tablet-active"
                          style={{ width: "100%", wordBreak: "break-word" }}
                        >
                          Reading List: {rlName.toUpperCase()}
                        </span>
                      </div>
                      <div className="resultsContentWrapper resultsContentWrapper-tablet">
                        {this.showFieldedSearch()}
                        {this.state.appliedFilters.length > 0 ? (
                          <FilterDisplay
                            allFilters={this.state.allFilters}
                            appliedFilters={this.state.appliedFilters}
                            handleClearFilters={this.handleClearFilters}
                            handleRemoveAppliedFilter={
                              this.handleRemoveAppliedFilter
                            }
                            customWidth={this.state.width}
                          />
                        ) : null}
                        {this.state.isLoaded ? (
                          <>
                            {/* {this.state.response.length > 0 &&
                                                    <> */}
                            {this.resultListCount(
                              this.state.itemsPerPage,
                              this.state.currentPage
                            )}
                            {/* </>
                                                } */}
                            <div className="results-wrapper">
                              {this.resultList(
                                this.state.response,
                                this.state.itemsPerPage,
                                this.state.currentPage
                              )}
                            </div>
                            <Pagination
                              metadata={this.state.documentCount}
                              handlePageChange={this.handlePageChange}
                              itemsPerPage={this.state.itemsPerPage}
                              page={this.state.currentPage}
                              customWidth={this.state.width}
                              customHeight={this.state.height}
                            />
                          </>
                        ) : (
                          <div style={{ textAlign: "center", padding: "10%" }}>
                            <img
                              src={imsLoader}
                              alt="Loading Data"
                              style={{ width: "10%" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ margin: "0% 1%", marginTop: "auto" }}>
                  <Footer
                    customWidth={this.state.width}
                    customHeight={this.state.height}
                  />
                </div>
                {this.state.globalEmailPopUp && (
                  <GlobalSearchFeaturePopUpModal
                    showModal={this.state.globalEmailPopUp}
                    showEmailModal={true}
                    handleClose={this.handleEmailPopUpClose}
                    selectedResults={this.state.selectedResults}
                    customWidth={this.state.width}
                    customHeight={this.state.height}
                  />
                )}
                {this.state.bookmarkPopUp && (
                  <BookmarkPopUp
                    showModal={this.state.bookmarkPopUp}
                    handleClose={this.handleCloseBookmark}
                    customWidth={this.state.width}
                  />
                )}
                {this.state.readingListPopup && (
                  <ReadingListPopup
                    showModal={this.state.readingListPopup}
                    handleClose={this.handleCloseReadingList}
                    width={this.state.width}
                    selectedResults={this.state.selectedResults}
                  />
                )}
                {this.state.showSuccess && (
                  <>
                    <SuccessReadingList
                      display={this.state.showSuccess}
                      handleClose={(status) => this.handleSuccessClose(status)}
                      data={this.state.successMessage}
                      width={this.state.width}
                    />
                    <div className="savemodal-outer-wrapper"></div>
                  </>
                )}
              </>
            ) : (
              <div style={{ textAlign: "center", padding: "10%" }}>
                <img
                  src={imsLoader}
                  alt="Loading Data"
                  style={{ width: "20%" }}
                />
              </div>
            )}
            {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
          </ErrorBoundary>
        </div>
      );
    } else if (this.state.width > 1000) {
      return (
        <div>
          <ErrorBoundary>
            {this.state.userExists ? (
              <>
                <div className="resultsInnerWrapper">
                  <NavigationMenu
                    pathName={window.location.pathname}
                    customWidth={this.state.width}
                    customHeight={this.state.height}
                  />
                  <div className="header-wrapper">
                    <HeaderSearch
                      history={this.props.history}
                      dropdownValue={category}
                      searchKeyword={query}
                      appliedFilters={this.state.appliedFilters}
                      handleChangeInSearch={this.handleChangeInSearch}
                      handleClearSearch={this.handleClearSearch}
                      customWidth={this.state.width}
                      customHeight={this.state.height}
                      handelSelectedCitation={this.getSelectedCitationDetails}
                      isCitationSelected={this.state.selectedResults}
                      handleCollapseAllGlobal={this.handleCollapseAllGlobal}
                      globalCollapseAll={this.state.globalCollapseAll}
                      globalSelectAll={this.state.globalSelectAll}
                      handleSelectAll={this.handleSelectAll}
                      selectedResults={this.state.selectedResults}
                      csPath={this.state.csPath}
                      csproduct={this.state.csproduct}
                      documentsUrl={this.documentsUrl}
                      markedDocumentsUrl={this.markedDocumentsUrl}
                    />
                  </div>
                  <div className="results-contents-wrapper">
                    <div
                      className={
                        this.state.showFilters
                          ? "filterMenuWrapper filterMenuWrapperExpanded"
                          : "filterMenuWrapper"
                      }
                      style={{ float: "left" }}
                    >
                      {this.state.showFilters ? (
                        <>
                          <div className="filterMenuHeader">
                            <span className="filter-menu-icon-wrapper">
                              <img src={filterIconWhite} alt="" />
                            </span>
                            <span>Filters</span>
                            <span
                              style={{ float: "right" }}
                              onClick={(e) =>
                                this.setState({
                                  showFilters: !this.state.showFilters,
                                })
                              }
                            >
                              <img src={collapseFilterWhite} alt="" />
                            </span>
                          </div>
                          <div className="filter-menu-content">
                            {this.state.isLoaded ? (
                              <SearchFilter
                                allFilters={this.state.allFilters}
                                filters={this.state.filters}
                                appliedFilters={this.state.appliedFilters}
                                handleOnChangeInfilter={
                                  this.handleOnChangeInfilter
                                }
                                selectedParentFilterArr={
                                  this.state.selectedParentFilterArr
                                }
                                handleChangeInSearch={this.handleChangeInSearch}
                                selectedFilters={this.state.selectedFilters}
                                querytext={this.state.searchKeyword}
                                documentCount={this.state.documentCount}
                                response={this.state.response}
                                isProcessing={this.state.isProcessing}
                                customWidth={this.state.width}
                                csPath={this.state.csPath}
                                csproduct={this.state.csproduct}
                                recentlyUsedFilters={
                                  this.state.recentlyUsedFilters
                                }
                              />
                            ) : (
                              <div
                                style={{ textAlign: "center", padding: "10%" }}
                              >
                                <img
                                  src={imsLoader}
                                  alt="Loading Filters"
                                  style={{ width: "20%" }}
                                />
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="result-filter-expand-wrapper"
                            onClick={(e) =>
                              this.setState({
                                showFilters: !this.state.showFilters,
                              })
                            }
                          >
                            <img src={expandFilterWhite} alt="" />
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      className={
                        this.state.showFilters
                          ? "imsResultsMenuWrapper imsResultsMenuCollapse"
                          : "imsResultsMenuWrapper"
                      }
                      style={{ float: "right", borderBottom: "none" }}
                    >
                      <div
                        className="imsResultsMenuHeader"
                        style={{
                          width: "99%",
                          background: "#2D86B5",
                          height: "auto",
                        }}
                      >
                        <span style={{ wordBreak: "break-word" }}>Reading List: {rlName.toUpperCase()}</span>
                      </div>
                      <div className="resultsContentWrapper">
                        {this.showFieldedSearch()}
                        {this.state.appliedFilters.length > 0 ? (
                          <FilterDisplay
                            allFilters={this.state.allFilters}
                            appliedFilters={this.state.appliedFilters}
                            handleClearFilters={this.handleClearFilters}
                            handleRemoveAppliedFilter={
                              this.handleRemoveAppliedFilter
                            }
                            customWidth={this.state.width}
                          />
                        ) : null}
                        {this.state.isLoaded ? (
                          <>
                            {this.state.response.length > 0 && (
                              <>
                                {this.resultListCount(
                                  this.state.itemsPerPage,
                                  this.state.currentPage
                                )}
                              </>
                            )}
                            <div className="results-wrapper">
                              {this.resultList(
                                this.state.response,
                                this.state.itemsPerPage,
                                this.state.currentPage
                              )}
                            </div>
                            <Pagination
                              metadata={this.state.documentCount}
                              handlePageChange={this.handlePageChange}
                              itemsPerPage={this.state.itemsPerPage}
                              page={this.state.currentPage}
                              customWidth={this.state.width}
                              customHeight={this.state.height}
                            />
                          </>
                        ) : (
                          <div style={{ textAlign: "center", padding: "10%" }}>
                            <img
                              src={imsLoader}
                              alt="Loading Data"
                              style={{ width: "10%" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ margin: "0% 1%", marginTop: "auto" }}>
                  <Footer
                    customWidth={this.state.width}
                    customHeight={this.state.height}
                  />
                </div>
                {this.state.showSuccess && (
                  <>
                    <SuccessReadingList
                      display={this.state.showSuccess}
                      handleClose={(status) => this.handleSuccessClose(status)}
                      data={this.state.successMessage}
                      width={this.state.width}
                    />
                    <div className="savemodal-outer-wrapper"></div>
                  </>
                )}
              </>
            ) : (
              <div style={{ textAlign: "center", padding: "10%" }}>
                <img
                  src={imsLoader}
                  alt="Loading Data"
                  style={{ width: "20%" }}
                />
              </div>
            )}
            {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
          </ErrorBoundary>
        </div>
      );
    }
  }
}
export default ReadingList;
