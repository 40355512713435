import { useEffect, useState, useRef } from "react";
import ReactAudioPlayer from 'react-audio-player';
import sampleAudio from '../../assets/SampleAudioTest.mp3';
import './AudioContent.css';
import {
    PauseButton,
    PlayButton,
    SquareIcon,
    ExportBlueIcon, SaveIcon, FileBlueIcon, arrowIconBlue
} from "../../assets";
import ApiServices from "../../utils/apiServices";

const AudioContent = (props) => {
    //const [isPlaying ,setIspalying] = useState(false);
    const [trackProgress, setTrackProgress] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [legacyFolderData, setLegacyFolderData] = useState([]);
    const [legacyDocumentData, setLegacyDocumentData] = useState([]);
    const [displayNew, setDisplayNew] = useState(false);
    const [newTextFileClick,setNewTextFileClick] = useState(false);
    const [newLexiconFileClick,setNewLexiconFileClick] = useState(false);
    useEffect(async () => {
        let service = new ApiServices();
        let res1 = await service.getAllDocDetails();
        let res2 = await service.getAllFolderDetails();
        if (res1) {
            setLegacyDocumentData(res1)
        }
        if (res2) {
            setLegacyFolderData(res2)
        }
    })

    const [folderStructure, setFolderStructure] = useState(props.foldername);
    const [fileSelected, setFileSelected] = useState(props.selectedDocument);

    const audioSrc = useRef(new Audio(sampleAudio));
    const intervalRef = useRef();
    const isReady = useRef(false);
    const { duration } = audioSrc.current;
    //---------------------function------------------
    const startTimer = () => {
        // Clear any timers already running
        clearInterval(intervalRef.current);

        intervalRef.current = setInterval(() => {
            if (audioSrc.current.ended) {
                setTrackProgress(0)
            } else {
                setTrackProgress(audioSrc.current.currentTime);
            }
        }, [1000]);
    };

    const onScrub = (value) => {
        // Clear any timers already running
        clearInterval(intervalRef.current);
        audioSrc.current.currentTime = value;
        setTrackProgress(audioSrc.current.currentTime);
    };

    const onScrubEnd = () => {
        // If not already playing, start
        if (!isPlaying) {
            setIsPlaying(true);
        }
        startTimer();
    };
    useEffect(() => {
        if (isPlaying) {
            audioSrc.current.play();
            startTimer();
        } else {
            audioSrc.current.pause();
        }
    }, [isPlaying]);

    useEffect(() => {
        // Pause and clean up on unmount
        return () => {
            audioSrc.current.pause();
            clearInterval(intervalRef.current);
        };
    }, []);

    const onPlayClick = (bool) => {
        setIsPlaying(true);
        audioSrc.current.play();
    }
    const onPauseClick = (bool) => {
        setIsPlaying(false);
        audioSrc.current.pause();
    }
    return (<>
        <div className="page-layout">
            <div className="ms-Stack container-337" style={{ width: "100%" }}>
                <div className="fixedPaneContainer-162" style={{ width: "200px" }}>
                    <div className="file-tree-panel-pnpi7ihwoh">
                        <span className="sc-dnqmqq jteVcr nav-callout-wrapper-i4cd0385ia">
                            <div>
                                <nav className="ms-Nav selected-root-parent root-300">
                                    <div className="ms-Nav-group is-expanded">
                                        <button className="ms-Nav-chevronButton chevronButton-304">
                                            <img className='Icon-482' src={arrowIconBlue}></img>
                                            All Files
                                        </button>
                                        <div>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </span>
                    </div>
                </div>
                <div tabIndex="0" className="css-338"></div>
                <div className="ms-StackItem flexiblePane-340">
                    <div className="ms-StackItem css-262" style={{ height: "100%" }}>
                        <div className="ms-StackItem css-341">
                            <div className="banner"></div>
                            <div style={{ height: "37px", display: "flex" }}>{folderStructure.map((value) => {
                                return (
                                    <div className="container-top"><button className="button-123"
                                    >{value}</button><span>{'>'}</span></div>
                                )
                            })}</div>
                            <div className="main wrapper">
                                <div className="ms-Stack editor-panel">
                                    <div className="tuning-command-bar-ur8d2yznta flex-row">
                                        <span class="button-bar">
                                            <div style={{ position: "relative" }}>
                                                <div className="ms-FocusZone">
                                                    <div className="ms-OverflowSet ms-CommandBar-primaryCommand primarySet-192">
                                                        <div className="ms-OverflowSet-item item-193">
                                                            <button style={{ backgroundColor: "white" }} onClick={() => setDisplayNew(true)}
                                                                onMouseLeave={() => setDisplayNew(false)}>
                                                                <span className="ms-Button-flexContainer flexContainer-177">
                                                                    <img className="icon-345" src={FileBlueIcon}></img>
                                                                    <span className="ms-Button-textContainer textContainer-178">
                                                                        <span className="ms-Button-label label-346" id="id__334">File</span>
                                                                    </span>
                                                                    {(displayNew) ?
                                                                        <svg width="16px" height="14px" style={{ marginTop: "2px" }} viewBox="0 0 1024.00 1024.00" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#3276c3" transform="rotate(180)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="45.056"></g><g id="SVGRepo_iconCarrier"><path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="#3276c3"></path></g></svg>
                                                                        // <img src={arrowIconBlue} alt="" style={{ transform: "rotate(180deg)" }} />
                                                                        :
                                                                        <svg width="16px" height="14px" style={{ marginTop: "2px" }} viewBox="0 0 1024.00 1024.00" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#3276c3"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="#3276c3"></path></g></svg>
                                                                        // <img src={arrowIconBlue} />
                                                                    }
                                                                </span>
                                                                {(displayNew) && <ul className="TSS-sub-wrapper-menu">
                                                    <li onClick={() => setNewTextFileClick(true)}>New Text File</li>
                                                    <li onClick={() => { setNewLexiconFileClick(true) }}>New Lexicon File</li>
                                                </ul>}
                                                            </button>
                                                        </div>
                                                        <div className="ms-OverflowSet-item item-193">
                                                            <button style={{ backgroundColor: "white" }}>
                                                                <span className="ms-Button-flexContainer flexContainer-177">
                                                                    <img className="icon-345" src={SaveIcon}></img>
                                                                    <span className="ms-Button-textContainer textContainer-178">
                                                                        <span className="ms-Button-label label-346" id="id__334">Save
                                                                        </span>
                                                                        <span className="css-534" aria-hidden="true"></span>
                                                                    </span>
                                                                    <img className="" src={arrowIconBlue}></img>
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className="ms-OverflowSet-item item-193">
                                                            <button style={{ backgroundColor: "white" }}>
                                                                <span className="ms-Button-flexContainer flexContainer-177">
                                                                    <img className="icon-345" src={ExportBlueIcon}></img>
                                                                    <span className="ms-Button-textContainer textContainer-178">
                                                                        <span className="ms-Button-label label-346" id="id__334">Export</span>
                                                                    </span>
                                                                    <img src=""></img>
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className="ms-OverflowSet-item item-193">
                                                            <button style={{ backgroundColor: "white" }}>
                                                                <span className="ms-Button-flexContainer flexContainer-177">
                                                                    <img src=""></img>
                                                                    <span className="ms-Button-textContainer textContainer-178">
                                                                        <span className="ms-Button-label label-346" id="id__334">...</span>
                                                                    </span>
                                                                    <img src=""></img>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                    <div className="audio-player">
                                        <div className="audio-player-wrapper">
                                            <div className="synthesis-audio-player-container"
                                                style={{ display: "flex" }}>
                                                {isPlaying ? (
                                                    <button
                                                        type="button"
                                                        className="circlebutton"
                                                        onClick={() => onPauseClick(true)}
                                                        aria-label="Pause"
                                                    ><span className="flexContainer-177">
                                                            <img src={PauseButton} />
                                                        </span>
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="circlebutton"
                                                        onClick={() => onPlayClick(false)}
                                                        aria-label="Play"
                                                    >
                                                        <span className="flexContainer-177">
                                                            <img src={PlayButton} />
                                                        </span>
                                                    </button>
                                                )}
                                                <div style={{ display: "flex" }}>
                                                    <button
                                                        type="button"
                                                        className="circlebutton"
                                                        aria-label="Pause"
                                                        onClick={() => onPauseClick(false)}
                                                    >
                                                        <span className="flexContainer-177"><img src={SquareIcon} /></span>
                                                    </button>
                                                    < div className="play-bar-wrap">
                                                        <span>{trackProgress}</span>
                                                        <input
                                                            type="range"
                                                            value={trackProgress}
                                                            step="1"
                                                            min="0"
                                                            max={duration ? duration : `${duration}`}
                                                            className="progress"
                                                            onChange={(e) => onScrub(e.target.value)}
                                                            onMouseUp={() => onScrubEnd()}
                                                            onKeyUp={() => onScrubEnd()}
                                                            style={{
                                                                backgroundColor: "#c7e0f4",
                                                                minWidth: "269px",
                                                                marginTop: "5px",
                                                                display: "inline-block"
                                                            }}
                                                        />
                                                        <span>{duration}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-StackItem content-editor-wrapper css-393">
                                        <div className="ms-Stack container-394">
                                            <div className="ms-StackItem flexiblePane-396">
                                                <div className="content-editor theme-tuning multi-color">
                                                    <div className="editor-view-text notranslate">
                                                        <textarea style={{ width: "100%", minHeight: "300px" }}>{fileSelected.doctext}</textarea></div>
                                                </div>
                                            </div>
                                            <div tabindex="0" className="css-517"></div>
                                            <div className="fixedPaneContainer-162 consoleWrapper-164" style={{ height: "80px" }}>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="tuning-footer-98cfl5vjew"><div class="counter"><label>Total characters:</label><b><span >63</span> / 20,000</b></div><div className="counter"><span id="qs-billable-chars"><span>Billable characters</span>: </span><b><span>N/A</span> </b></div></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default AudioContent;