import RecentlyAddedUpdatedBar from './RecentlyAddedUpdatedBar'
import PropTypes from 'prop-types'
import React,{useEffect} from 'react'
import ReactTooltip from "react-tooltip"

export default function RecentlyAddedUpdatedBarDiagram(props) {
  const {
    barWidth,
    years,
    yearSelection,
    sliderRange
  } = props

  useEffect(() => {
    ReactTooltip.rebuild();
}, [props.yearSelection])
  /**
   * Calculate the maximum documents available from the search result set
   * This will be used to normalize the data set so extreme low and high values are displayed proportionally 
   */
  const maxDocCount = Math.max.apply(Math, years.map(function (year) { return year.doc_count }))

  /**
   * Calculate the height of a bar after normalizing the data
   *
   * @param {Number} docCount 
   * @return {Number}
   */
  const getBarHeight = docCount => {
    if (docCount === 0) {
      return docCount
    }

    const normalizedHeight = (docCount / maxDocCount) * 100

    // If the value of the normalized value is too small, it will not be visable to the user.
    // This returns a standard representation of a low document count
    if (normalizedHeight < 5) {
      return 6
    }

    return normalizedHeight
  }

  /**
   * Checks if the current year exists in the the search result set 
   * 
   * @param {String} currentYear
   * @returns {Boolean} 
   */
  const yearExists = currentYear => years.some(y => y.key === currentYear)

  /**
   * Determine if the current year exists in the the search result set
   * if so get the corresponding document count.
   * 
   * @param {string} currentYear 
   * @returns {Number} 
   */
  const getDocumentCount = currentYear => {
    let docCount = 0

    if (yearExists(currentYear)) {
      const currYear = years.find(year => year.key === currentYear)
      docCount = currYear.doc_count
    }
    return docCount
  }

  /**
   * Determine if the current year is in the user's selected search range
   *
   * @param {string} currentYear
   * @returns {Boolean}
   */
  const isYearInSelectionRange = currentYear => {
    return currentYear >= yearSelection.min && currentYear < yearSelection.max
  }

  return (
    <>
      <svg className="svg-bar-container" style={{ "width": "100%" }}>
        <defs>
          <linearGradient id="myGradient" gradientTransform="rotate(90)">
            <stop offset="0%" stop-color="#81D4FF" />
            <stop offset="55%" stop-color="#4EAEE0" />
            <stop offset="100%" stop-color="#065882" />
          </linearGradient>
        </defs>
        {
          sliderRange.map((year, index) => {
            const docCount = getDocumentCount(year)
              let yearTooltip =`${year}`.replace("Recently Added_Updated/","")
            return (
              <RecentlyAddedUpdatedBar
                barWidth={barWidth + '%'}
                barHeight={getBarHeight(docCount) + '%'}
                color={"#dcdcdc"}
                isYearInSelectionRange={isYearInSelectionRange(index)}
                docCount={docCount}
                key={index}
                x={barWidth * index + '%'}
                y={100 - getBarHeight(docCount) + '%'}
                year={yearTooltip}
              />
            )
          })
        }
      </svg>
      <div className="slider_extent_label_section">
        <div className="slider_extent_label_left">One Year Ago</div>
        <div className="slider_extent_label_right">Yesterday</div>
      </div>
      <ReactTooltip id="recentlyAddedUpdatedBars"  />
    </>
  )
}

RecentlyAddedUpdatedBarDiagram.propTypes = {
  barWidth: PropTypes.number.isRequired,
  years: PropTypes.arrayOf(
    PropTypes.object
  ).isRequired,
  yearSelection: PropTypes.object.isRequired,
  sliderRange: PropTypes.arrayOf(
    PropTypes.number
  ).isRequired,
}
