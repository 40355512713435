/* eslint-disable array-callback-return */
import { Modal } from "react-bootstrap";
import {
  popCancelBttn,
  imsLoader
} from "../../assets";
import { Component } from "react";
import Button from "../Button";
import "./GlobalSearchFeaturesPopUp.css";
import "./PinToReadingListPopUp.css";
import SuccessReadingList from "./SucessReadingList";
import ApiServices from "../../utils/apiServices";
import RenameCommandNamePopup from "./RenameCommandNamePopup";
import { snippetLongString } from "../../utils/utilityServices";
import AlertMessagePopUpModal from "./AlertMessagePopUpModal";
import ConfirmMessagePopUpModal from "./ConfirmMessagePopUpModal"

class CommandListPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPop: false,
      isPin: true,
      isManage: false,
      isUserCommand: true,
      isTemplateCommand: false,
      userApiResponse: [],
      templateApiResponse: [],
      commandName: "",
      commandDescription: "",
      commandText: "",
      commandId: -1,
      docID: "",
      isLoaded: false,
      showSuccess: false,
      showRenamePop: false,
      itemtoBeRenamedName: "",
      itemtoBeRenamedID: "",
      successMessage: "",
      error: "",
      nodeName: "",
      alertMessageModelColor: "",
      alertMessageContent: "",
      alertMessageShowModal: false,
      isInsertSuccess: false,
      confirmMessageContent: '',
      confirmMessageShowModal: false,
      confirmMessageId: -1,
      selectedUserCommands: [],
      selectedTemplateCommands: [],
      isEditViewButtonClicked: false,
    };
  }
  async componentDidMount() {
    let wwid = JSON.parse(localStorage.getItem("wwid"));
    let service = new ApiServices();
    this.setState({
      showPop: this.props.showModal,
      commandText: this.props.commandText,
      isUserCommand: this.props.isUserCommand,
      isTemplateCommand: this.props.isTemplateCommand,
    });
    let apiRes = await service.getUserCommandLists(wwid);
    let templateapiRes = await service.getTemplateCommandLists()
    this.setState(
      {
        userApiResponse: apiRes,
        templateApiResponse: templateapiRes,
        isLoaded: true,
        isEditViewButtonClicked: false,
      }
    );
    if (this.props.isFromGlobalFeaturesMenu) {
      this.setState({
        isPin: false,
        isManage: true,
      })
    }
  }

  showConfirmMessage = (message, id) => {
    this.setState({
      confirmMessageContent: message,
      confirmMessageShowModal: true,
      confirmMessageId: id
    })
  }

  confirmHandler = async () => {
    const { confirmMessageId } = this.state;
    let username = JSON.parse(localStorage.getItem('username'))
    let wwid = JSON.parse(localStorage.getItem("wwid"));
    if (username) {
      username = username.toLowerCase();
    }
    let service = new ApiServices();

    await service.deleteUserCommandLists(confirmMessageId, wwid);
    this.setState(
      {
        isLoaded: false,
        confirmMessageContent: "",
        confirmMessageShowModal: false,
        confirmMessageId: -1
      },
      async () => {
        let apiRes = await service.getUserCommandLists(wwid);
        this.setState({
          userApiResponse: apiRes,
          isLoaded: true,
        });
      }
    );
  }


  closeConfirmMessage = () => {
    this.setState({
      confirmMessageContent: "",
      confirmMessageShowModal: false,
      confirmMessageIndex: -1
    })
  }
  errorAlertMessage = (message) => {
    this.setState({
      alertMessageContent: message,
      alertMessageModelColor: "#de1327",
      alertMessageShowModal: true
    })
  }

  closeAlertMessage = () => {
    this.setState({
      alertMessageContent: "",
      alertMessageModelColor: "",
      alertMessageShowModal: false
    })
  }

  handleInputCommandName = (e) => {
    e.target.value = snippetLongString(e.target.value);
    this.setState({
      commandName: e.target.value,
    });
  };
  handleInputCommandDescription = (e) => {
    this.setState({
      commandDescription: e.target.value,
    });
  };
  handleInputCommandText = (e) => {
    this.setState({

      commandText: e.target.value,
    });
  };
  onclickAddtoCommand = () => {
    let { selectedTemplateCommands, selectedUserCommands, userApiResponse, templateApiResponse } = this.state;
    if (selectedTemplateCommands.length === 0 && selectedUserCommands.length === 0) {
      this.errorAlertMessage("Please select either my command or template command to proceed.")
    } else {
      if (selectedTemplateCommands.length + selectedUserCommands.length > 5) {
        this.errorAlertMessage("Kindly select maximum five commands to proceed.")
      } else {
        let selectCommands = "";
        selectedUserCommands.map((item) => {
          let _commentDetails = userApiResponse.find(x => x.id === item);
          if (_commentDetails) {
            selectCommands += selectCommands ? `\n\n${_commentDetails.command_text}` : _commentDetails.command_text
          }
        })
        selectedTemplateCommands.map((item) => {
          let _commentDetails = templateApiResponse.find(x => x.id === item);
          if (_commentDetails) {
            selectCommands += selectCommands ? `\n\n${_commentDetails.command_text}` : _commentDetails.command_text
          }
        })
        this.props.handleAddToCommandText(selectCommands)
      }
    }
  }

  onclickProceed = async () => {
    // let username = JSON.parse(localStorage.getItem("username")).toLowerCase();
    let wwid = JSON.parse(localStorage.getItem("wwid"));

    let service = new ApiServices();
    if (this.handleValidation()) {

      let { commandName, commandDescription, commandText, commandId } = this.state;
      if (commandId === -1) {
        if (commandName !== "" && commandDescription !== "" && commandText !== "") {
          await service.insertCommandList(
            wwid,
            commandName,
            commandDescription,
            commandText);

          this.setState({
            successMessage:
              "The command was saved successfully.",
          });
        }
      } else {

        await service.updateCommand(
          commandId,
          commandName,
          commandDescription,
          commandText);

        this.setState({
          successMessage:
            "The command was updated successfully.",
        });
      }


      this.setState({
        showSuccess: true,
        isInsertSuccess: true,
        commandId: -1
      });
    }
  };
  handleOnClickView = async (nID) => {
    let { userApiResponse } = this.state;
    let _commentDetails = userApiResponse.find(x => x.id === nID);
    if (_commentDetails) {
      this.setState({
        commandName: _commentDetails.command_name,
        commandDescription: _commentDetails.command_description,
        commandText: _commentDetails.command_text,
        commandId: _commentDetails.id,
        isInsertSuccess: false,
        isManage: false,
        isPin: true,
        error: "",
        isEditViewButtonClicked: true,
      })
    }
  };
  handleRename = (id, name) => {
    this.setState({
      showRenamePop: true,
      itemtoBeRenamedName: name,
      itemtoBeRenamedID: id,
    });
  };
  handleRenameClose = (status) => {
    this.setState(
      {
        showRenamePop: status,
        isLoaded: false,
      },
      async () => {
        // let username = JSON.parse(
        //   localStorage.getItem("username")
        // ).toLowerCase();
        let wwid = JSON.parse(localStorage.getItem("wwid"));
        let service = new ApiServices();
        let apiRes = await service.getUserCommandLists(wwid);
        this.setState({
          userApiResponse: apiRes,
          isLoaded: true,
        });
      }
    );
  };
  handleRemove = async (id) => {
    this.showConfirmMessage("Are you sure you want to delete this command?", id)
  };
  handleSuccessClose = (status) => {
    this.setState({
      showSuccess: status,
      //showPop: false,
      isManage: true,
      isPin: false,
      isLoaded: false
    },
      async () => {
        // let username = JSON.parse(
        //   localStorage.getItem("username")
        // ).toLowerCase();
        let wwid = JSON.parse(localStorage.getItem("wwid"));
        let service = new ApiServices();
        let apiRes = await service.getUserCommandLists(wwid);
        this.setState(
          {
            userApiResponse: apiRes,
            isLoaded: true
          }
        );
      });
    //this.props.handleClose(false);
  };
  handleOnFocusInput = (e) => {
    this.setState({ nodeName: e.target.nodeName });
  };
  handleValidation() {
    let errors = {};
    let formIsValid = true;
    // let commandList = this.state.apiResponse.map((data) =>
    //   data.command_name.toLowerCase()
    // );
    let commandList = this.state.userApiResponse.filter(x => x.id !== this.state.commandId).map(data => data.command_name.toLowerCase())
    if (
      this.state.commandName.length <= 0 //&&
      //this.state.nodeName === "INPUT"
    ) {
      formIsValid = false;
      errors["pinCommandList"] = `Please enter a valid name to proceed.`;
    }
    else if (commandList.includes(this.state.commandName.toLowerCase())) {
      formIsValid = false;
      errors[
        "pinCommandList"
      ] = `You already have a command with entered title. Please enter a new title.`;
    }
    else if (
      this.state.commandDescription.length <= 0 //&&
      //this.state.nodeName === "INPUT"
    ) {
      formIsValid = false;
      errors["pinCommandList"] = `Please enter a valid description to proceed.`;
    }
    else if (
      this.state.commandText.length <= 0 //&&
      //this.state.nodeName === "TEXTAREA"
    ) {
      formIsValid = false;
      errors["pinCommandList"] = `Please enter a valid command to proceed.`;
    }
    else if (
      this.state.commandName.length <= 0 &&
      this.state.commandDescription.length <= 0 &&
      this.state.commandText.length <= 0
    ) {
      formIsValid = false;
      errors["pinCommandList"] = `Please enter all the fields to proceed.`
    }
    this.setState({ error: errors });
    return formIsValid;
  }
  handleSelectUserCommand = (e, cid) => {
    let { selectedUserCommands } = this.state;
    if (e.target.checked) {
      selectedUserCommands.push(cid)
    } else {
      selectedUserCommands = selectedUserCommands.filter(x => x !== cid);
    }
    this.setState({ selectedUserCommands: selectedUserCommands });

  }
  handleSelectTemplateCommand = (e, cid) => {
    let { selectedTemplateCommands } = this.state;
    if (e.target.checked) {
      selectedTemplateCommands.push(cid)
    } else {
      selectedTemplateCommands = selectedTemplateCommands.filter(x => x !== cid);
    }
    this.setState({ selectedTemplateCommands: selectedTemplateCommands });
  }

  render() {
    const { alertMessageModelColor, alertMessageContent, alertMessageShowModal, commandId, selectedUserCommands, selectedTemplateCommands } = this.state;
    const { confirmMessageContent, confirmMessageShowModal, isEditViewButtonClicked } = this.state;
    let isInsertSuccess
    if (isEditViewButtonClicked) { isInsertSuccess = false }
    else
      isInsertSuccess = this.state.isInsertSuccess || this.props.isFromGlobalFeaturesMenu;
    return (
      <>
        <Modal
          show={this.state.showPop}
          size="xl"
          centered
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="popup-transition"
          className="z-index-authoring"
          backdropClassName="z-index-authoring-backdrop"
          scrollable={true}
        >
          <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
            <div className="popup-cancel-btn-wrapper">
              <img
                src={popCancelBttn}
                alt=""
                onClick={(e) => {
                  this.setState({ showPop: false });
                  this.props.handleClose(false);
                }}
              />
            </div>
            <div>
              <Modal.Header
                className="gsfModalHeader"
                style={{ justifyContent: "left" }}
              >
                <div
                  style={{
                    textAlign: "center",
                    width: "60%",
                    paddingTop: "1%",
                  }}
                >
                  <Modal.Title>
                    <div className="actionItem-headerPart" style={isInsertSuccess ? { justifyContent: "flex-start" } : {}}>
                      {!isInsertSuccess && (<>
                        <div
                          onClick={(e) => {
                            this.setState({ isPin: true, isManage: false });
                          }}
                          className={
                            this.state.isPin
                              ? "active-features-button"
                              : "features-buttons"
                          }
                        >
                          {commandId === -1 ? <span>Create Command</span> : <span>View/Edit Command</span>}
                        </div>
                        <div className="action-button-saperation-line"></div>
                      </>
                      )}

                      <div
                        onClick={(e) => {
                          this.setState({ isPin: false, isManage: true });
                        }}
                        className={
                          this.state.isManage
                            ? "active-features-button"
                            : "features-buttons"
                        }
                      >
                        <span>View/Manage Commands</span>
                      </div>
                    </div>
                  </Modal.Title>
                </div>
              </Modal.Header>
              <Modal.Body scrollable={true} style={{ height: "65vh" }}>
                {this.state.isPin && (
                  <div>
                    {this.state.isLoaded ? (
                      <>

                        <div>
                          Command Name:
                        </div>
                        <input
                          type="text"
                          placeholder="Type here"
                          value={this.state.commandName}
                          className="reading-list-input-box"
                          onFocus={this.handleOnFocusInput}
                          onChange={this.handleInputCommandName}
                        />
                        <br />
                        <span className="length-info">Name with a maximum of 100 characters.</span>
                        <div>
                          Command Description:
                        </div>
                        <input
                          type="text"
                          placeholder="Add a Command Description"
                          value={this.state.commandDescription}
                          className="reading-list-input-box"
                          onFocus={this.handleOnFocusInput}
                          onChange={this.handleInputCommandDescription}
                        />

                        <div>
                          Command:
                        </div>
                        <textarea className="reading-list-textarea-box"
                          placeholder="Replace the word <Generic name> with the word <Product Name> and use the past tense when summarizing this text"
                          value={this.state.commandText}
                          onFocus={this.handleOnFocusInput}
                          onChange={this.handleInputCommandText} />

                        <div className="errorInvalidName">
                          {this.state.error["pinCommandList"]}
                        </div>
                      </>
                    ) : (
                      <div style={{ textAlign: "center", padding: "10%" }}>
                        <img
                          src={imsLoader}
                          alt="Loading Data"
                          style={{ width: "10%" }}
                        />
                      </div>
                    )}
                  </div>
                )}
                {this.state.isManage && (
                  <div>
                    {this.state.isLoaded ? (
                      <>
                        <span
                          onClick={(e) => {
                            this.setState({ isUserCommand: true, isTemplateCommand: false });
                          }}
                          className={
                            this.state.isUserCommand
                              ? "reading-list-action-text"
                              : "reading-list-deaction-text"
                          }
                          style={{ cursor: "pointer", marginLeft: "15px" }}
                          title="My Commands"
                        >
                          My Commands
                        </span>
                        <span className="action-items-separate-divider"></span>
                        <span
                          onClick={(e) => {
                            this.setState({ isUserCommand: false, isTemplateCommand: true });
                          }}
                          className={
                            this.state.isTemplateCommand
                              ? "reading-list-action-text"
                              : "reading-list-deaction-text"
                          }
                          style={{ cursor: "pointer" }}
                          title="Template Commands"
                        >
                          Template Commands
                        </span>
                        {this.state.isUserCommand && <>
                          {this.state.userApiResponse.length > 0 ? (
                            <span>
                              <table className="reading-list-view-wrapper comment-list-view-wrapper">
                                <tr>
                                  <th style={{ width: "5%" }}></th>
                                  <th style={{ width: "22.5%" }}>Command Name</th>
                                  <th style={{ width: "22.5%" }}>Command Description</th>
                                  <th style={{ width: "22.5%" }}>Command</th>
                                  <th style={{ textAlign: "center" }}>Actions</th>
                                </tr>
                                <tbody>
                                  {this.state.userApiResponse.map((item, index) => {
                                    return (
                                      <tr key={item.id} style={index % 2 === 0 ? { background: "#e7e0e0" } : { background: "#eaeaee" }} >
                                        <td style={{ width: "5%", textAlign: "center" }}>
                                          <input type="checkbox" name="Labels" class="k-checkbox" style={{ margin: 0 }} onChange={e => this.handleSelectUserCommand(e, item.id)} checked={selectedUserCommands.includes(item.id)} />
                                        </td>
                                        <td style={{ width: "22.5%" }}>{item.command_name}</td>
                                        <td style={{ width: "22.5%" }}>{item.command_description}</td>
                                        <td style={{ width: "22.5%" }}>{item.command_text}</td>
                                        <td
                                          style={{
                                            cursor: "pointer",
                                            wordBreak: "normal",
                                            textAlign: "center"
                                          }}
                                        >
                                          <span
                                            onClick={(e) =>
                                              this.handleOnClickView(item.id)
                                            }
                                            title="View/Edit"
                                            className="reading-list-action-text"
                                          >
                                            View/Edit
                                          </span>
                                          <span className="action-items-separate-divider"></span>
                                          <span
                                            onClick={(e) =>
                                              this.handleRename(
                                                item.id,
                                                item.command_name
                                              )
                                            }
                                            title="Rename"
                                            className="reading-list-action-text"
                                          >
                                            Rename
                                          </span>
                                          <span className="action-items-separate-divider"></span>
                                          <span
                                            title="Remove"
                                            className="reading-list-action-text"
                                            onClick={(e) =>
                                              this.handleRemove(
                                                item.id
                                              )
                                            }
                                          >
                                            Remove
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </span>
                          ) : (
                            <span style={{ marginLeft: "15px" }}>
                              <br />
                              <br />
                              {" "}
                              You currently do not have any commands created.
                            </span>
                          )}</>}
                        {this.state.isTemplateCommand && <>
                          {this.state.templateApiResponse.length > 0 ? (
                            <span>
                              <table className="reading-list-view-wrapper comment-list-view-wrapper">
                                <tr>
                                  <th style={{ width: "5%" }}></th>
                                  <th style={{ width: "25%" }}>Command Name</th>
                                  <th style={{ width: "35%" }}>Command Description</th>
                                  <th style={{ width: "35%" }}>Command</th>
                                </tr>
                                <tbody>
                                  {this.state.templateApiResponse.map((item, index) => {
                                    return (
                                      <tr key={item.id} style={index % 2 === 0 ? { background: "#e7e0e0" } : { background: "#eaeaee" }}>
                                        <td style={{ width: "5%", textAlign: "center" }}>
                                          <input type="checkbox" name="Labels" class="k-checkbox" style={{ margin: 0 }} onChange={e => this.handleSelectTemplateCommand(e, item.id)} checked={selectedTemplateCommands.includes(item.id)} />
                                        </td>
                                        <td style={{ width: "25%" }}>{item.command_name}</td>
                                        <td style={{ width: "35%" }}>{item.command_description}</td>
                                        <td style={{ width: "35%" }}>{item.command_text}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </span>
                          ) : (
                            <span style={{ marginLeft: "15px" }}>
                              <br />
                              <br />
                              {" "}
                              You currently do not have any commands created.
                            </span>
                          )}</>}
                      </>
                    ) : (
                      <div style={{ textAlign: "center", padding: "10%" }}>
                        <img
                          src={imsLoader}
                          alt="Loading Data"
                          style={{ width: "10%" }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </Modal.Body>
              {this.state.isPin ? (
                <div className="gsf-popup-button-wrapper command-list-button-wrapper">
                  <Button
                    text="Cancel"
                    customColor="#0074B0"
                    action={() => {
                      this.setState({ showPop: false });
                      this.props.handleClose(false);
                    }}
                  />

                  <Button
                    text="Save My Command"
                    customColor="#2EB000"
                    action={this.onclickProceed}
                  />
                </div>
              ) : (
                <div className="reading-list-button-wrapper command-list-button-wrapper">
                  <Button
                    text="Add to Command"
                    customColor="#2EB000"
                    action={this.onclickAddtoCommand}
                  />
                  <Button
                    text="Cancel"
                    customColor="#0074B0"
                    action={() => {
                      this.setState({ showPop: false });
                      this.props.handleClose(false);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          {this.state.showSuccess && (
            <>
              <SuccessReadingList
                display={this.state.showSuccess}
                handleClose={(status) => this.handleSuccessClose(status)}
                data={this.state.successMessage}
                width={this.props.width}
              />
              <div className="savemodal-outer-wrapper"></div>
            </>
          )}
          {this.state.showRenamePop && (
            <>
              <RenameCommandNamePopup
                showModal={this.state.showRenamePop}
                handleClose={(status) => this.handleRenameClose(status)}
                cName={this.state.itemtoBeRenamedName}
                cID={this.state.itemtoBeRenamedID}
                width={this.props.width}
                apiResponse={this.state.userApiResponse}
              />
              <div className="savemodal-outer-wrapper"></div>
            </>
          )}
        </Modal>
        {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
        {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
      </>
    );
  }
}
export default CommandListPopUp;