/* eslint-disable no-useless-escape */
import { Modal } from "react-bootstrap";
import { popCancelBttn, imsLoader } from "../../assets";
import { Component } from "react";
import Button from "../Button";
import './GlobalSearchFeaturesPopUp.css'
import './BookmarkPopUp.css'
import ApiServices from "../../utils/apiServices";
import MobileViewButton from "../Button/MobileViewButton";
import RenameBookmarkPopup from "./RenameBookmarkPopUp";
import trackingService from "../../services/trackingService";
import { snippetLongString } from "../../utils/utilityServices"
import ConfirmMessagePopUpModal from "../PopUp/ConfirmMessagePopUpModal"
import moment from 'moment';

class BookmarkPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreateBookmark: true,
      isManage: false,
      apiResponse: [],
      bookmarkName: '',
      error: '',
      isContinue: false,
      searchQuery: '',
      showRenamePop: false,
      itemtoBeRenamedName: '',
      itemtoBeRenamedID: '',
      itemtoBeRenamedSearch: '',
      confirmMessageContent: '',
      confirmMessageShowModal: false,
      confirmMessageIndex: -1,
    };
  }
  async componentDidMount() {
    let username = JSON.parse(localStorage.getItem('username')).toLowerCase();
    let service = new ApiServices();
    let apiRes = await service.getBookmarkById(username);
    apiRes = apiRes.sort(function (a, b) { return new Date(b.added_on).getTime() - new Date(a.added_on).getTime() })
    this.setState({
      apiResponse: apiRes,
      searchQuery: window.location.search,
      isLoaded: true
    })
    if (this.props.isfromUserProfile) {
      this.setState({ isCreateBookmark: false, isManage: true })
    }
  }

  showConfirmMessage = (message, index) => {
    this.setState({
      confirmMessageContent: message,
      confirmMessageShowModal: true,
      confirmMessageIndex: index
    })
  }

  confirmHandler = async () => {
    const { confirmMessageIndex } = this.state;
    let username = JSON.parse(localStorage.getItem('username')).toLowerCase();
    let bookmarkName = this.state.apiResponse[confirmMessageIndex].bookmark_name;
    let service = new ApiServices();
    await service.deleteBookmark(username, bookmarkName)
    this.setState({
      isLoaded: false,
      confirmMessageContent: "",
      confirmMessageShowModal: false,
      confirmMessageIndex: -1
    }, async () => {
      let apiRes = await service.getBookmarkById(username);
      apiRes = apiRes.sort(function (a, b) { return new Date(b.added_on).getTime() - new Date(a.added_on).getTime() })
      this.setState({
        apiResponse: apiRes,
        isLoaded: true
      })
    })
  }

  closeAlertMessage = () => {
    this.setState({
      confirmMessageContent: "",
      confirmMessageShowModal: false,
      confirmMessageIndex: -1
    })
  }
  viewBookmark = (index) => {
    let url;
    if (window.location.pathname === '/ReferencesUsed') {
      url = `${window.location.origin}/ReferencesUsed${this.state.apiResponse[index].search_property}`
      window.open(url, '_blank');
    }
    else {
      url = `${window.location.origin}/results${this.state.apiResponse[index].search_property}`
    }
    // url = `${window.location.origin}/results${this.state.apiResponse[index].search_property}`
    window.open(url, '_blank');
  }
  shareBookmark = (index) => {
    let downloadurl = window.location.pathname === '/ReferencesUsed' ? `/ReferencesUsed${this.state.apiResponse[index].search_property}` : `/results${this.state.apiResponse[index].search_property}`
    let mailUrl = window.location.origin + downloadurl
    let bookmarkName = this.state.apiResponse[index].bookmark_name
    bookmarkName = encodeURIComponent(bookmarkName);
    let mailbody = `Bookmark Name: ${bookmarkName}%0D%0DURL:${mailUrl.replace(/&/g, '\%26').replace(/%20/g, '\+')}%0D%0DTo create your own bookmark, follow these instructions:%0D%0D
    1. Click on the link above to run the bookmark on iMedical Search.%0D 
    2. Click the 'Bookmarks' icon from the search results screen.%0D 
    3. For the Bookmark Name option, enter a name of your choosing for the bookmark.%0D 
    4. Click the Save my bookmark button.%0D%0DTo manage all your bookmarks, access the same 'Bookmarks' icon from a search results screen.  
`
    window.open(`mailto:?Subject=iMS Search - '${bookmarkName}' Bookmark&body=${mailbody}`)
  }
  deleteBookmark = async (index) => {
    this.showConfirmMessage("Are you sure to delete this Bookmark?", index);
  }
  handleInput = (e) => {
    e.target.value = snippetLongString(e.target.value);
    this.setState({
      bookmarkName: e.target.value
    })
  }
  handleValidation() {
    let errors = {};
    let formIsValid = true;
    let bookmark = this.state.apiResponse.map(data => data.bookmark_name.toLowerCase())
    if (this.state.bookmarkName.length <= 0) {
      formIsValid = false;
      errors["bookmarkName"] = 'Please enter a valid name to proceed.';
    }
    if (bookmark.includes(this.state.bookmarkName.toLowerCase())) {
      formIsValid = false;
      errors["bookmarkName"] = `Bookmark Name already exists in your list. Please give a different name other than '${this.state.bookmarkName}'`;
    }
    this.setState({ error: errors });
    return formIsValid;
  }
  onSaveBookmark = async () => {
    let service = new ApiServices();
    let username = JSON.parse(localStorage.getItem('username')).toLowerCase();
    let bookmarkName = this.state.bookmarkName;
    let searchProp = this.state.searchQuery;
    let currentDate = new Date();
    if (this.handleValidation()) {
      this.setState({ isContinue: true })
      await service.createBookmark(username, bookmarkName, searchProp, this.dateFormate(currentDate))
      this.handelAddBookmark();
    }
  }
  dateFormate = (date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()}`
  }
  handleViewManageBookmark = () => {
    let username = JSON.parse(localStorage.getItem('username')).toLowerCase();
    let service = new ApiServices();
    this.setState({
      isCreateBookmark: false,
      isManage: true,
      isLoaded: false
    }, async () => {
      let apiRes = await service.getBookmarkById(username);
      apiRes = apiRes.sort(function (a, b) { return new Date(b.added_on).getTime() - new Date(a.added_on).getTime() })
      this.setState({
        apiResponse: apiRes,
        isLoaded: true
      })
    })
  }
  handleRename = (id, name, property) => {
    this.setState({
      showRenamePop: true,
      itemtoBeRenamedName: name,
      itemtoBeRenamedID: id,
      itemtoBeRenamedSearch: property
    })
  }
  handleRenameClose = (status) => {
    this.setState({
      showRenamePop: status,
      isLoaded: false
    }, async () => {
      let username = JSON.parse(localStorage.getItem('username')).toLowerCase()
      let service = new ApiServices();
      let apiRes = await service.getBookmarkById(username);
      apiRes = apiRes.sort(function (a, b) { return new Date(b.added_on).getTime() - new Date(a.added_on).getTime() })
      this.setState({
        apiResponse: apiRes,
        isLoaded: true
      })
    })
  }
  handelAddBookmark = () => {
    let params = new URLSearchParams(this.state.searchQuery)
    let query = params.get('query');
    let filter = params.get('filter');
    const ts = new trackingService();
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      localStorage.getItem('companyname'),
      `Create Bookmark`,
      "",
      window.location.href,
      query,
      filter
    );
  }
  render() {
    const { confirmMessageContent, confirmMessageShowModal } = this.state;
    if (this.props.customWidth < 600) {
      return (
        <Modal
          show={this.props.showModal}
          size="lg md sm xs"
          centered
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="popup-transition-mob-tab"
          scrollable={true}
        >
          <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
            <div>
              <Modal.Header className="gsfModalHeader" style={{ justifyContent: "left" }}>
                <div style={{ textAlign: "center", width: "100%" }}>
                  <Modal.Title>
                    <div className="actionItem-headerPart-mobile">
                      {this.props.isfromUserProfile ? (
                        <>
                          <div className="alert-active-features-button">
                            <span>View/Manage Bookmarks</span>
                          </div>
                        </>) : (
                        <>
                          <div onClick={e => { this.setState({ isCreateBookmark: true, isManage: false }) }} className={this.state.isCreateBookmark ? "alert-active-features-button" : "alert-features-buttons"}>
                            <span>Create Bookmark</span>
                          </div>
                          <div className="action-button-saperation-line"></div>
                          <div onClick={this.handleViewManageBookmark} className={this.state.isManage ? "alert-active-features-button" : "alert-features-buttons"}>
                            <span>View/Manage Bookmarks</span>
                          </div>
                        </>
                      )}
                    </div>
                  </Modal.Title>
                </div>
              </Modal.Header>
              <Modal.Body scrollable={true} style={{ maxHeight: "60vh" }}>
                {this.state.isCreateBookmark &&
                  (
                    this.state.isContinue ?
                      <>
                        <span className="noAlertText-mobile">Your bookmark has been saved successfully.</span>
                        <br />
                        <span className="saved-bookmark-text-mobile">Please go to 'View/Manage Bookmarks' tab to view all your saved ones.</span>
                      </>
                      :
                      <div>
                        <span className="create-bookmark-field">Give a name to your bookmark:</span>
                        <br />
                        <input type="text" placeholder="Type here" value={this.state.bookmarkName} className="create-bookmark-input-box" onChange={this.handleInput} />
                        <br />
                        <span className="length-info">Name with a maximum of 100 characters.</span>
                        <div className="errorInvalidName">{this.state.error["bookmarkName"]}</div>
                      </div>
                  )
                }
                {this.state.isManage &&
                  <div>
                    {this.state.isLoaded ? <>
                      {this.state.apiResponse.length > 0 ?
                        <span>
                          <table className="reading-list-view-wrapper">
                            <tr>
                              <th style={{ color: "#333", fontSize: "14.5px" }}>Bookmark Name</th>
                              <th style={{ textAlign: "center", color: "#333", fontSize: "14.5px" }}>Actions</th>
                            </tr>
                            <tbody>
                              {this.state.apiResponse.map((item, index) => {
                                return (<tr key={item.bookmark_id}>
                                  <td>{item.bookmark_name}</td>
                                  <td style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", width: "100%", flexDirection: "column" }}><span onClick={() => this.viewBookmark(index)} title="View" className="reading-list-action-text">View</span><span title="Share" className="reading-list-action-text" onClick={() => this.shareBookmark(index)}>Share</span><span title="Rename" className="reading-list-action-text" onClick={e => this.handleRename(item.bookmark_id, item.bookmark_name, item.search_property)}>Rename</span><span onClick={e => this.deleteBookmark(index)} title="Delete" className="reading-list-action-text">Delete</span></td>
                                </tr>)
                              })}
                            </tbody>
                          </table>
                        </span>
                        : <><span className="noAlertText-mobile">No Bookmarks.</span>
                          <br />
                          <span className="no-bookmark-text-mobile">It looks like you don't have any bookmarks yet. To create a bookmark, please run a search and click on 'Bookmark' icon from the results screen.</span>
                        </>}
                    </> :
                      <div style={{ textAlign: "center", padding: "10%" }}>
                        <img src={imsLoader} alt="Loading Data" style={{ width: "10%" }} />
                      </div>
                    }
                  </div>
                }
                {/* {this.state.isManage &&
                  <div>
                    {this.state.isLoaded ? <>
                      {this.state.apiResponse.length > 0 ?
                        <span>
                          <table className="reading-list-view-wrapper">
                            <tr>
                              <th style={{ color: "#333", fontSize: "14.5px" }}>Bookmark Name</th>
                              <th style={{ textAlign: "center", color: "#333", fontSize: "14.5px" }}>Actions</th>
                            </tr>
                            <tbody>
                              {this.state.apiResponse.map((item, index) => {
                                return (<tr key={item.bookmark_id}>
                                  <td>{item.bookmark_name}</td>
                                  <td className="actionItems-td-mobile">
                                    <div className="actionItems-mobile">
                                      <span onClick={() => this.viewBookmark(index)} title="View" className="reading-list-action-text">View</span>
                                      <span title="Share" className="reading-list-action-text" onClick={() => this.shareBookmark(index)}>Share</span>
                                    </div>
                                    <div className="actionItems-mobile">
                                      <span title="Rename" className="reading-list-action-text" onClick={e => this.handleRename(item.bookmark_id, item.bookmark_name, item.search_property)}>Rename</span>
                                      <span onClick={e => this.deleteBookmark(index)} title="Delete" className="reading-list-action-text">Delete</span>
                                    </div>
                                  </td>
                                </tr>)
                              })}
                            </tbody>
                          </table>
                        </span>
                        : <><span className="noAlertText-mobile">No Bookmarks.</span>
                          <br />
                          <span className="no-bookmark-text-mobile">It looks like you don't have any bookmarks yet. To create a bookmark, please run a search and click on 'Bookmark' icon from the results screen.</span>
                        </>}
                    </> :
                      <div style={{ textAlign: "center", padding: "10%" }}>
                        <img src={imsLoader} alt="Loading Data" style={{ width: "10%" }} />
                      </div>
                    }
                  </div>
                } */}
              </Modal.Body>
              {this.state.isCreateBookmark ?
                this.state.isContinue ?
                  <div className="reading-list-button-wrapper-mobile">
                    <MobileViewButton text="Close" customColor="#0074B0" action={() => {
                      this.setState({ showPop: false });
                      this.props.handleClose(false);
                    }} />
                  </div>
                  :
                  <div className="gsf-popup-button-wrapper-mobile">
                    <div className="welcomeNxtBtn-mobile">
                      <MobileViewButton text="Save my bookmark" customColor="#2EB000" action={this.onSaveBookmark} />
                    </div>
                    {/* } */}
                    <div className="welcomeNxtBtn-mobile">
                      <MobileViewButton text="Cancel" customColor="#0074B0" action={() => {
                        this.setState({ showPop: false });
                        this.props.handleClose(false);
                      }} />
                    </div>

                  </div>
                :
                <div className="reading-list-button-wrapper-mobile">
                  <div className="welcomeNxtBtn-mobile">
                    <MobileViewButton text="Close" customColor="#0074B0" action={() => {
                      this.setState({ showPop: false });
                      this.props.handleClose(false);
                    }} />
                  </div>
                </div>
              }
            </div>
          </div>
          {this.state.showRenamePop &&
            <>
              <RenameBookmarkPopup showModal={this.state.showRenamePop} handleClose={(status) => this.handleRenameClose(status)} bookmarkName={this.state.itemtoBeRenamedName} bookmarkId={this.state.itemtoBeRenamedID} searchProperty={this.state.itemtoBeRenamedSearch} apiResponse={this.state.apiResponse} customWidth={this.props.customWidth} />
              <div className="savemodal-outer-wrapper"></div>
            </>
          }
          {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeAlertMessage()} handleConfirm={() => this.confirmHandler()} />}
        </Modal>
      );
    }
    else if (this.props.customWidth < 1000) {
      return (
        <Modal
          show={this.props.showModal}
          size="lg md sm xs"
          centered
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="popup-transition-mob-tab"
          scrollable={true}
        >
          <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
            <div>
              <Modal.Header className="gsfModalHeader" style={{ justifyContent: "center" }}>
                <div style={{ textAlign: "center", width: "100%", paddingTop: "1%" }}>
                  <Modal.Title>
                    <div className="actionItem-headerPart">
                      {this.props.isfromUserProfile ? (
                        <>
                          <div className="alert-active-features-button">
                            <span>View/Manage Bookmarks</span>
                          </div>
                        </>) : (
                        <>
                          <div onClick={e => { this.setState({ isCreateBookmark: true, isManage: false }) }} className={this.state.isCreateBookmark ? "alert-active-features-button" : "alert-features-buttons"}>
                            <span>Create Bookmark</span>
                          </div>
                          <div className="action-button-saperation-line"></div>
                          <div onClick={this.handleViewManageBookmark} className={this.state.isManage ? "alert-active-features-button" : "alert-features-buttons"}>
                            <span>View/Manage Bookmarks</span>
                          </div>
                        </>
                      )}
                    </div>
                  </Modal.Title>
                </div>
              </Modal.Header>
              <Modal.Body scrollable={true} style={{ maxHeight: "40vh" }}>
                {this.state.isCreateBookmark &&
                  (
                    this.state.isContinue ?
                      <>
                        <span className="noAlertText-mobile">Your bookmark has been saved successfully.</span>
                        <br />
                        <span className="saved-bookmark-text-mobile">Please go to 'View/Manage Bookmarks' tab to view all your saved ones.</span>
                      </>
                      :
                      <div>
                        <span className="create-bookmark-field">Give a name to your bookmark:</span>
                        <br />
                        <input type="text" placeholder="Type here" value={this.state.bookmarkName} className="create-bookmark-input-box" onChange={this.handleInput} />
                        <br />
                        <span className="length-info">Name with a maximum of 100 characters.</span>
                        <div className="errorInvalidName">{this.state.error["bookmarkName"]}</div>
                      </div>
                  )
                }
                {this.state.isManage &&
                  <div>
                    {this.state.isLoaded ? <>
                      {this.state.apiResponse.length > 0 ?
                        <span>
                          <table className="manage-personal-alerts-wrapper">
                            <tr>
                              <th style={{ width: "20%" }}>Bookmark Name</th>
                              {/* <th style={{ width: "20%", textAlign: "center" }} >Alert Frequency</th> */}
                              <th style={{ width: "20%", textAlign: "center" }}>Date Saved</th>
                              <th style={{ width: "20%", textAlign: "center" }}>Actions</th>
                            </tr>
                            <tbody>
                              {this.state.apiResponse.map((item, index) => {
                                return (
                                  <tr key={item.bookmark_id}>
                                    <td>{item.bookmark_name}</td>
                                    <td style={{ textAlign: "center" }}>{moment(item.added_on).format("D-MMM-YYYY")}</td>
                                    <td style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", width: "100%" }}><span onClick={() => this.viewBookmark(index)} title="View" className="reading-list-action-text">View</span><span className="action-items-separate-divider"></span><span title="Share" className="reading-list-action-text" onClick={() => this.shareBookmark(index)}>Share</span><span className="action-items-separate-divider"></span><span title="Rename" className="reading-list-action-text" onClick={e => this.handleRename(item.bookmark_id, item.bookmark_name, item.search_property)}>Rename</span><span className="action-items-separate-divider"></span><span onClick={e => this.deleteBookmark(index)} title="Delete" className="reading-list-action-text">Delete</span></td>
                                  </tr>)
                              })}
                            </tbody>
                          </table>
                        </span>
                        : <><span className="noAlertText-mobile">No Bookmarks.</span>
                          <br />
                          <span className="no-bookmark-text-mobile">It looks like you don't have any bookmarks yet. To create a bookmark, please run a search and click on 'Bookmark' icon from the results screen.</span>
                        </>}
                    </> :
                      <div style={{ textAlign: "center", padding: "10%" }}>
                        <img src={imsLoader} alt="Loading Data" style={{ width: "10%" }} />
                      </div>
                    }
                  </div>
                }
              </Modal.Body>
              {this.state.isCreateBookmark ?
                this.state.isContinue ?
                  <div className="reading-list-button-wrapper-tablet">
                    <Button text="Close" customColor="#0074B0" action={() => {
                      this.setState({ showPop: false });
                      this.props.handleClose(false);
                    }} />
                  </div>
                  :
                  <div className="gsf-popup-button-wrapper-tablet">
                    <div className="welcomeNxtBtn">
                      <Button text="Cancel" customColor="#0074B0" action={() => {
                        this.setState({ showPop: false });
                        this.props.handleClose(false);
                      }} />
                    </div>
                    <div className="welcomeNxtBtn">
                      <Button text="Save my bookmark" customColor="#2EB000" action={this.onSaveBookmark} />
                    </div>
                  </div>
                :
                <div className="reading-list-button-wrapper-tablet">
                  <Button text="Close" customColor="#0074B0" action={() => {
                    this.setState({ showPop: false });
                    this.props.handleClose(false);
                  }} />
                </div>
              }
            </div>
          </div>
          {this.state.showRenamePop &&
            <>
              <RenameBookmarkPopup showModal={this.state.showRenamePop} handleClose={(status) => this.handleRenameClose(status)} bookmarkName={this.state.itemtoBeRenamedName} bookmarkId={this.state.itemtoBeRenamedID} searchProperty={this.state.itemtoBeRenamedSearch} apiResponse={this.state.apiResponse} customWidth={this.props.customWidth} />
              <div className="savemodal-outer-wrapper"></div>
            </>
          }
          {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeAlertMessage()} handleConfirm={() => this.confirmHandler()} />}
        </Modal>
      );
    }
    else {
      return (
        <Modal
          show={this.props.showModal}
          size="lg md sm xs"
          centered
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="popup-transition"
          scrollable={true}
        >
          <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
            <div className="popup-cancel-btn-wrapper">
              <img
                src={popCancelBttn}
                alt=""
                onClick={e => {
                  this.setState({ showPop: false });
                  this.props.handleClose(false);
                }}
              />
            </div>
            <div>
              <Modal.Header className="gsfModalHeader" style={{ justifyContent: "left" }}>
                <div style={{ textAlign: "center", width: "60%", paddingTop: "1%" }}>
                  <Modal.Title>
                    <div className={!this.props.isfromUserProfile ? "actionItem-headerPart" : "actionItem-headerPart-single"}>
                      {this.props.isfromUserProfile ? (
                        <>
                          <div className="alert-active-features-button">
                            <span>View/Manage Bookmarks</span>
                          </div>
                        </>) : (
                        <>
                          <div onClick={e => { this.setState({ isCreateBookmark: true, isManage: false }) }} className={this.state.isCreateBookmark ? "alert-active-features-button" : "alert-features-buttons"}>
                            <span>Create Bookmark</span>
                          </div>
                          <div className="action-button-saperation-line"></div>
                          <div onClick={this.handleViewManageBookmark} className={this.state.isManage ? "alert-active-features-button" : "alert-features-buttons"}>
                            <span>View/Manage Bookmarks</span>
                          </div>
                        </>
                      )}
                    </div>
                  </Modal.Title>
                </div>
              </Modal.Header>
              <Modal.Body scrollable={true} style={{ maxHeight: "40vh", padding: " 1rem 3rem" }}>
                {this.state.isCreateBookmark &&
                  (
                    this.state.isContinue ?
                      <>
                        <span className="no-bookmark-heading">Your bookmark has been saved successfully.</span>
                        <br />
                        <span className="no-bookmark-text">Please go to 'View/Manage Bookmarks' tab to view all your saved ones.</span>
                      </>
                      :
                      <div>
                        <span className="create-bookmark-field">Give a name to your bookmark:</span>
                        <br />
                        <input type="text" placeholder="Type here" value={this.state.bookmarkName} className="create-bookmark-input-box" onChange={this.handleInput} />
                        <br />
                        <span className="length-info">Name with a maximum of 100 characters.</span>
                        <div className="errorInvalidName">{this.state.error["bookmarkName"]}</div>
                      </div>
                  )
                }
                {this.state.isManage &&
                  <div>
                    {this.state.isLoaded ? <>
                      {this.state.apiResponse.length > 0 ?
                        <span>
                          <table className="manage-personal-alerts-wrapper">
                            <tr>
                              <th style={{ width: "20%" }}>Bookmark Name</th>
                              {/* <th style={{ width: "20%", textAlign: "center" }} >Alert Frequency</th> */}
                              <th style={{ width: "20%", textAlign: "center" }}>Date Saved</th>
                              <th style={{ width: "20%", textAlign: "center" }}>Actions</th>
                            </tr>
                            <tbody>
                              {this.state.apiResponse.map((item, index) => {
                                return (
                                  <tr key={item.bookmark_id}>
                                    <td>{item.bookmark_name}</td>
                                    <td style={{ textAlign: "center" }}>{moment(item.added_on).format("D-MMM-YYYY")}</td>
                                    <td style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", width: "100%" }}><span onClick={() => this.viewBookmark(index)} title="View" className="reading-list-action-text">View</span><span className="action-items-separate-divider"></span><span title="Share" className="reading-list-action-text" onClick={() => this.shareBookmark(index)}>Share</span><span className="action-items-separate-divider"></span><span title="Rename" className="reading-list-action-text" onClick={e => this.handleRename(item.bookmark_id, item.bookmark_name, item.search_property)}>Rename</span><span className="action-items-separate-divider"></span><span onClick={e => this.deleteBookmark(index)} title="Delete" className="reading-list-action-text">Delete</span></td>
                                  </tr>)
                              })}
                            </tbody>
                          </table>
                        </span>
                        : <><span className="no-bookmark-heading">No Bookmarks.</span>
                          <br />
                          <span className="no-bookmark-text">It looks like you don't have any bookmarks yet. To create a bookmark, please run a search and click on 'Bookmark' icon from the results screen.</span>
                        </>}
                    </> :
                      <div style={{ textAlign: "center", padding: "10%" }}>
                        <img src={imsLoader} alt="Loading Data" style={{ width: "10%" }} />
                      </div>
                    }
                  </div>
                }
              </Modal.Body>
              {this.state.isCreateBookmark ?
                this.state.isContinue ?
                  <div className="reading-list-button-wrapper">
                    <Button text="Close" customColor="#0074B0" action={() => {
                      this.setState({ showPop: false });
                      this.props.handleClose(false);
                    }} />
                  </div>
                  :
                  <div className="bookmark-popup-button-wrapper">
                    <Button text="Cancel" customColor="#0074B0" action={() => {
                      this.setState({ showPop: false });
                      this.props.handleClose(false);
                    }} />
                    <Button text="Save my bookmark" customColor="#2EB000" action={this.onSaveBookmark} />
                  </div>
                :
                <div className="reading-list-button-wrapper">
                  <Button text="Close" customColor="#0074B0" action={() => {
                    this.setState({ showPop: false });
                    this.props.handleClose(false);
                  }} />
                </div>
              }
            </div>
          </div>
          {this.state.showRenamePop &&
            <>
              <RenameBookmarkPopup showModal={this.state.showRenamePop} handleClose={(status) => this.handleRenameClose(status)} bookmarkName={this.state.itemtoBeRenamedName} bookmarkId={this.state.itemtoBeRenamedID} searchProperty={this.state.itemtoBeRenamedSearch} apiResponse={this.state.apiResponse} customWidth={this.props.customWidth} />
              <div className="savemodal-outer-wrapper"></div>
            </>
          }
          {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeAlertMessage()} handleConfirm={() => this.confirmHandler()} />}
        </Modal>
      );
    }
  }
}
export default BookmarkPopUp;
