/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from 'react'
import '../WNFY/WNFYSection'
import "./NlpFeature.css"
import { dropdownArrowBlack, prdtNavButton, dropdownArroworage, prdtNavwhiteButton } from "../../assets/index"
import { isEmptyObject } from 'jquery'
import axios from 'axios'
import { useLocation } from 'react-router-dom';
import NlpDocumentPreview from '../Preview/NlpDocumentPreview'
import PinToReadingListPopUp from '../PopUp/PinToReadingListPopUp'
import { imsLoader } from "../../assets"
import NlpDocumentCard from "./NlpDocumentCard"
import { nlpDocumnetTypeMapping, LocationOrigin } from '../../utils/utilityServices';
function NlpFeature(props) {
    const [showOption, setShowOption] = useState(false)
    const [showOption1, setShowOption1] = useState(false)
    const [documentsResult, setDocumentsResults] = useState({})
    const [collapse, setCollapse] = useState(false)
    const [docType, setDocType] = useState([])
    const [nlpData, setNlpData] = useState({})
    const [showPreview, setPreview] = useState(false)
    const [showReadingList, setShowRL] = useState(false)
    const [previewClick, setPreviewClick] = useState({})
    const [searchIdPreview, setSearchIdPreview] = useState({})
    const [searchIdPin, setSearchIdPin] = useState("")
    const [highlightVal, setHighlightVal] = useState('')
    const [highlightVals, setHighlightVals] = useState([])
    const [isProcessing, setProcessing] = useState(false);
    const [selectedDocType, setSelectedDocType] = useState('');
    const [pageNumber, setPageNumber] = useState(1)
    const [isHoverShowMore, setIsHoverShowMore] = useState(false);
    const location = useLocation();
    const scrolledDiv = useRef();
    const numberOfTabShowForWeb = 4;
    const numberOfTabShowForTablet = 3;
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        let query = queryParams.get('query')
        if (query) {
            runSearch();
        } else {
            setDocumentsResults({})
            setDocType([])
        }
    }, [location])
    const generateArrayString = (array) => {
        let arrStr = ''
        array.map((item, index) => {
            if (index === array.length - 1) {
                arrStr += item
            }
            else {
                arrStr += item + ";"
            }
            return null
        })
        return arrStr
    }
    const runSearch = () => {
        let strDocumentTypes = localStorage.getItem('doctypepref').replace(/"/g, '');
        let documentTypes = [];
        if (strDocumentTypes) {
            documentTypes = strDocumentTypes.split(';');
        }
        setProcessing(true)
        //var url = 'https://awsaidnval000f.jnj.com:5003/main_call'
        //var url = 'https://awsaidnval000f.jnj.com:5003/nlp_api'
        //var url = 'https://awsaidnval000f.jnj.com:5003/nlp_api';

        var url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_NLP}nlp_results`
        let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'));
        var config;
        let isBoolean = false
        const queryParams = new URLSearchParams(location.search)
        let isHighlightAPI = queryParams.get('isHighlightAPI');
        if (isHighlightAPI) {
            url = "https://awsaidnval000f.jnj.com:5004/nlp_highlight";
        }
        /*Check for filededSearch values*/
        let filterStr = "";
        let strSelectedFilter = queryParams.get('filters');
        let selectedFilter = [];
        if (strSelectedFilter) {
            selectedFilter = strSelectedFilter.split(";")
            selectedFilter = selectedFilter.filter(x => !x.startsWith('Recently Added_Updated')).map(z => z.trim())
        } else {
            Object.keys(nlpDocumnetTypeMapping).map(key => {
                if (documentTypes.find(x => x.trim() === key)) {
                    selectedFilter.push(nlpDocumnetTypeMapping[key]);
                }
            })
        }


        filterStr = generateArrayString(selectedFilter);
        let query = queryParams.get('query')
        const fcount = queryParams.get('fcount')
        const sbQuery = queryParams.get('sbQuery');
        const isDateRange = queryParams.get('isDateRange');
        /*Check for sbQuery*/
        if (sbQuery !== null) {
            query = sbQuery
        }
        /*Check for boolean operator in Query String Start*/
        isBoolean = queryParams.get('isBoolean');
        let booleanCounter;
        if (query) {
            booleanCounter = (query.match(/ OR | AND | NOT | NEAR /g) || []).length;
        }
        if (isBoolean !== null || booleanCounter >= 4) {
            isBoolean = true
        }
        else if (booleanCounter >= 1 && query.split(' ').length <= 10) {
            isBoolean = true
        }
        else {
            isBoolean = false
        }
        /*Check for boolean operator in Query String End*/

        let val, checkFieldVal = false
        if (fcount > 0) {
            let arr = {
                "query": query,
                "filters": filterStr,
                "function": localStorage.getItem('function').replace(/"/g, ''),
                "doctypepref": localStorage.getItem('doctypepref').replace(/"/g, ''),
                "userproducts": localStorage.getItem('userproducts'),
                "region": localStorage.getItem('region'),
                "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                "contentpreference": localStorage.getItem('contentpreference').replace(/"/g, ''),
                "from": 0,
                "size": 25,
                // "sortBy": this.state.sortBy,
                // "sortField": this.state.sortField,
                "fcount": parseInt(fcount),
                "defaultOperator": "or",
                accessGroup: (userAccessGroup.includes("MISEHCP")) ? "MISE" : "",
            }
            for (let i = 1; i <= fcount; i++) {
                let key = queryParams.get(`f${i}`)
                let value = queryParams.get(`fval${i}`)
                arr["f" + i] = key
                arr["fval" + i] = value
                // if (key === 'congressName' || key === 'protocolIDs' || key === 'docTypeSynonyms') {
                checkFieldVal = true
                // }
            }
            if (checkFieldVal) {
                arr["matchPhrase"] = true
            }
            val = arr
            config = {
                method: 'POST',
                url: url,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: val
            }
        }
        else {
            config = {
                method: 'POST',
                url: url,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    "query": query,
                    "filters": filterStr,
                    "function": localStorage.getItem('function').replace(/"/g, ''),
                    "doctypepref": localStorage.getItem('doctypepref').replace(/"/g, ''),
                    "userproducts": localStorage.getItem('userproducts'),
                    "region": localStorage.getItem('region'),
                    "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                    "contentpreference": localStorage.getItem('contentpreference').replace(/"/g, ''),
                    "source": "docTitle,documentNumber,downloadUrl,docType,searchID,year,fileName",
                    "from": 0,
                    "size": 25,
                    "sortBy": "",
                    "sortField": "",
                    "defaultOperator": "or",
                    accessGroup: (userAccessGroup.includes("MISEHCP")) ? "MISE" : "",
                }
            }
        }
        if (isDateRange !== null) {
            let rangeField = queryParams.get('rangeField');
            let toRange = queryParams.get('toRange');
            let fromRange = queryParams.get('fromRange');
            if (rangeField && (toRange || fromRange)) {
                config.data["rangeField"] = rangeField;
                config.data["isDateRange"] = true
            }
            if (toRange !== null) {
                config.data["toRange"] = toRange
            }
            if (fromRange !== null) {
                config.data["fromRange"] = fromRange
            }
        }
        // let citationSelectStatus = [];
        axios(config)
            .then((response) => {
                setNlpData(response.data)
                let document_type_first = [...Object.keys(response.data)]
                document_type_first.sort();
                setDocumentsResults(response.data[document_type_first[0]])
                setDocType(Object.keys(response.data).filter(x => !isEmptyObject(response.data[x])))
                setSelectedDocType(document_type_first[0])
            })
            .catch(function (error) {
                console.log(error);
                setDocumentsResults({})
                setDocType([])
            })
            .finally(async () => {
                setProcessing(false)
            });
    }
    function handleClick(props) {
        setSelectedDocType(props);
        setDocumentsResults(nlpData[props]);
        scrolledDiv?.current?.scrollTo(0, 0);
    }
    function handlePreviewClose(status) {
        setPreview(false)
        props.handleNFade(status)
    }
    function handleClosePinToReadingList(status) {
        setShowRL(status)
    }
    const handleAndFade = (clicked, highlight) => {
        let temp = [];
        const div = document.createElement('div');
        div.innerHTML = highlight
        const spans = div.getElementsByTagName('span');
        if (spans && spans.length > 0) {
            for (let span = 0; span < spans.length; span++) {
                temp.push(spans[span].innerHTML);
            }
            setHighlightVal(temp.join('').replace(/<(?:.|\n)*?>/gm, ' ').replace(/<(?:.|\n)*?>/gm, ' ').replace(/\s{2,}/g, ' ').trim())
        } else if (highlight) {
            setHighlightVal(highlight.replace(/<(?:.|\n)*?>/gm, ' ').replace(/<(?:.|\n)*?>/gm, ' ').replace(/\s{2,}/g, ' ').trim())
        } else {
            setHighlightVal("");
        }

        // setHighlightVal(tep.join('').replace(/<(?:.|\n)*?>/gm, ' '))
        props.handleNFade(clicked)
    }
    const setPreviewHandel = (item) => {
        if (item.sectionAnswers) {
            let highlightVals = item.sectionAnswers.map((highlightVal) => {
                let temp = [];
                const div = document.createElement('div');
                div.innerHTML = highlightVal.sectionAnswer
                const spans = div.getElementsByTagName('span');
                for (let span = 0; span < spans.length; span++) {
                    temp.push(spans[span].innerHTML);
                }
                let _pageNumberList = (highlightVal.PageNumberList && highlightVal.PageNumberList.length > 0) ? highlightVal.PageNumberList.slice(-2) : [];
                let lastIndex = _pageNumberList.length - 1;
                let _pageNumber = (_pageNumberList.length > 0) ? _pageNumberList[lastIndex] : highlightVal.PageNumber;
                return {
                    highlightVal: temp.join('').replace(/<(?:.|\n)*?>/gm, ' ').replace(/<(?:.|\n)*?>/gm, ' ').replace(/\s{2,}/g, ' ').trim(),
                    PageNumber: _pageNumber,
                    ansHighlight: temp.join('').replace(/<\/?(h6)\b[^<>]*>/g, "").trim(),
                    PageNumberList: (_pageNumberList.length > 0) ? _pageNumberList : []
                }
            })
            setHighlightVals(highlightVals);
        }
        setPreviewClick(item.docTitle);
        setSearchIdPreview(item.searchID);
        handleAndFade(true, item.sectionAnswer);
        setPageNumber(item.PageNumber);
        setPreview(true);

    }
    const setShowRLHandel = (item) => {
        setShowRL(true);
        setSearchIdPin(item.searchID)
    }

    if (props.customWidth >= 600 && props.customWidth <= 1000) {
        const numberOfTabShow = numberOfTabShowForTablet - 1;
        return (
            <div className="nlp-full-wrapper" style={{ marginBottom: (isEmptyObject(documentsResult[0])) ? "0px" : "", padding: (isEmptyObject(documentsResult[0])) ? "0px" : "" }}>
                {isProcessing ? <div style={{ textAlign: "center", marginBottom: "10px" }}>
                    <img src={imsLoader} alt="Loading Filters" style={{ width: "10%" }} />
                </div> : <>
                    {documentsResult && !isEmptyObject(documentsResult[0]) &&
                        <div className="nlp-navigation-feature-wrapper" style={{ marginTop: "-5px" }}>
                            <div className="nlp-feature-text-btn" >
                                <span className="nlp-nav-text" style={{ cursor: "pointer" }} onClick={() => setCollapse(!collapse)}>Featured Results</span>
                                <div className="nlp-feature-dropdown-btn-1">
                                    <img
                                        className="nlp-drp-btn"
                                        src={prdtNavButton}
                                        alt="nlp-dropdownIcon"
                                        onClick={() => setCollapse(!collapse)}
                                        style={{
                                            transform: collapse
                                                ? "matrix(0, 1, 1, 0, 0, 0)"
                                                : null,
                                            cursor: "pointer"
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="nlp-dynamic-data-filed-wrapper" style={{ marginLeft: "20px" }}>
                                {docType.map((type, index) => {
                                    let len = docType.length
                                    if ((index > numberOfTabShow && showOption)) {
                                        return (
                                            <div className='nlp-doctype-nav-filed' style={{ marginTop: "3px" }}>
                                                <span className="nlp-data-dy" style={{ color: (type === selectedDocType) ? "rgb(245 127 32)" : null }}
                                                    onClick={() => handleClick(type)}>{type}</span>
                                                {((len > numberOfTabShow && index + 1 !== len)) && <div className='nlp-navDoc-seprate'></div>}
                                            </div>
                                        )
                                    }
                                    else if (index <= numberOfTabShow && !showOption) {
                                        return (
                                            <div className='nlp-doctype-nav-filed' style={{ marginTop: "3px" }}>
                                                <span className="nlp-data-dy" style={{ color: (type === selectedDocType) ? "rgb(245 127 32)" : null }}
                                                    onClick={() => handleClick(type)}>{type}</span>
                                                {((len > numberOfTabShow && index < numberOfTabShow) || (len <= numberOfTabShow && index + 1 !== len)) && <div className='nlp-navDoc-seprate'></div>}
                                            </div>

                                        )
                                    }
                                }
                                )}
                            </div>
                            {(docType.length > numberOfTabShowForTablet) && <div className={showOption ?
                                "nlp-feature-expand-icon-wrapper-active nlp-feature-expand-icon-wrapper" :
                                "nlp-feature-expand-icon-wrapper-highlight nlp-feature-expand-icon-wrapper"
                            }
                                onClick={(docType.length > numberOfTabShowForTablet) ? () => setShowOption(!showOption) : () => { }} >
                                <img src={prdtNavButton} alt="" style={{ transform: (showOption && docType.length > numberOfTabShowForTablet) ? "matrix(-1, 0, 0, -1, 0, -1)" : "matrix(1, 0, 0, 1, 0, -1)" }} />
                            </div>}
                        </div>
                    }

                    {documentsResult && !isEmptyObject(documentsResult[0]) &&
                        <div className="nlp-showingDetailsOfSearch" style={{ height: showOption1 ? "540px" : null, display: collapse === true ? "none" : '', marginTop: "0px", marginBottom: "-7px", width: "100%" }} >
                            <div className="nlp-for-left-right-wapper" ref={scrolledDiv} style={{ maxHeight: showOption1 ? "522px" : "221px" }}>
                                {<div className='nlp-dynamic-data-left'>
                                    {
                                        Object.keys(documentsResult).map((data, index) => {
                                            let lengths = Object.keys(documentsResult).length
                                            if (index <= (selectedDocType === 'All' ? 8 : 2)) {
                                                return (
                                                    <NlpDocumentCard key={`NlpDocumentCard-${index}`} item={documentsResult[index]} lengths={lengths} index={index} setPreviewHandel={setPreviewHandel} setShowRLHandel={setShowRLHandel} customWidth={props.customWidth} selectedDocType={selectedDocType} isLastItem={((index === (selectedDocType === 'All' ? 8 : 2)) || index === lengths - 1)} />
                                                )
                                            }
                                        })
                                    }
                                </div>}
                                {showPreview &&
                                    <NlpDocumentPreview highlightVal={highlightVal} highlightVals={highlightVals} nlpflag={true} docTitle={previewClick} searchID={searchIdPreview} searchKeyword={""} synonyms={[]} showPreview={showPreview} handleClose={handlePreviewClose} />
                                }
                                {showReadingList &&
                                    <PinToReadingListPopUp showModal={showReadingList} handleClose={handleClosePinToReadingList} docID={searchIdPin} width={props.customWidth} />
                                }
                            </div>


                            <div className="nlp-right-corner-btn-wrapper">
                                <div className="nlp-feature-dropdown-btn" onClick={() => setShowOption1(!showOption1)} onMouseEnter={() => { setIsHoverShowMore(true) }} onMouseOut={() => { setIsHoverShowMore(false) }}>
                                    {isHoverShowMore ? <img
                                        className="nlp-img-style"
                                        src={dropdownArroworage}
                                        alt="dropdownIcon"
                                        style={{
                                            transform: showOption1
                                                ? "matrix(-1, 0, 0, -1, 0, 0)"
                                                : "matrix(1, 0, 0, 1, 0, 0)",
                                            marginBottom: showOption1 ? "-2px" : "-15px",
                                            width: "34px"
                                        }}
                                    /> : <img
                                        className="nlp-img-style"
                                        src={dropdownArrowBlack}
                                        alt="dropdownIcon"
                                        style={{
                                            transform: showOption1
                                                ? "matrix(-1, 0, 0, -1, 0, 0)"
                                                : "matrix(1, 0, 0, 1, 0, 0)",
                                            marginBottom: showOption1 ? "-2px" : "-15px",
                                            width: "34px"
                                        }}
                                    />}
                                </div>
                            </div>
                        </div>
                    }
                </>}</div>
        )
    }
    else if (props.customWidth > 1000) {
        const numberOfTabShow = numberOfTabShowForWeb - 1;
        return (
            <div className="nlp-full-wrapper" style={{ marginBottom: (isEmptyObject(documentsResult[0])) ? "0px" : "", padding: (isEmptyObject(documentsResult[0])) ? "0px" : "" }}>
                {isProcessing ? <div style={{ textAlign: "center", marginBottom: "10px" }}>

                    <img src={imsLoader} alt="Loading Filters" style={{ width: "10%" }} />

                </div> : <>
                    {documentsResult && !isEmptyObject(documentsResult[0]) &&
                        <div className="nlp-navigation-feature-wrapper" style={{ marginTop: "" }}>
                            <div className="nlp-feature-text-btn nlp-feature-text-btn-desktop" >
                                <span className="nlp-nav-text" style={{ cursor: "pointer" }} onClick={() => setCollapse(!collapse)}>Featured Results</span>
                                <div className="nlp-feature-dropdown-btn-1" onClick={() => setShowOption1(!showOption1)} onMouseEnter={() => { setIsHoverShowMore(true) }} onMouseOut={() => { setIsHoverShowMore(false) }}>
                                    <img
                                        className="nlp-drp-btn"
                                        src={prdtNavwhiteButton}
                                        alt="nlp-dropdownIcon"
                                        onClick={() => setCollapse(!collapse)}
                                        style={{
                                            transform: collapse
                                                ? "matrix(0, 1, 1, 0, 0, 0)"
                                                : null,
                                            cursor: "pointer"
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="nlp-dynamic-data-filed-wrapper" >
                                {docType.map((type, index) => {
                                    let len = docType.length
                                    if ((index > numberOfTabShow && showOption)) {
                                        return (
                                            <div className='nlp-doctype-nav-filed' style={{ marginTop: "3px" }}>
                                                <span className="nlp-data-dy" style={{ color: (type === selectedDocType) ? "rgb(245 127 32)" : null }}
                                                    onClick={() => handleClick(type)}>{type}</span>
                                                {((len > numberOfTabShow && index + 1 !== len)) && <div className='nlp-navDoc-seprate'></div>}
                                            </div>
                                        )
                                    }
                                    else if (index <= numberOfTabShow && !showOption) {
                                        return (
                                            <div className='nlp-doctype-nav-filed' style={{ marginTop: "3px" }}>
                                                <span className="nlp-data-dy" style={{ color: (type === selectedDocType) ? "rgb(245 127 32)" : null }}
                                                    onClick={() => handleClick(type)}>{type}</span>
                                                {((len > numberOfTabShow && index < numberOfTabShow) || (len <= numberOfTabShow && index + 1 !== len)) && <div className='nlp-navDoc-seprate'></div>}

                                            </div>

                                        )
                                    }
                                }
                                )}
                            </div>
                            {(docType.length > numberOfTabShowForWeb) && <div className={showOption ?
                                "nlp-feature-expand-icon-wrapper-active nlp-feature-expand-icon-wrapper" :
                                "nlp-feature-expand-icon-wrapper-highlight nlp-feature-expand-icon-wrapper"}
                                onClick={(docType.length > numberOfTabShowForWeb) ? () => setShowOption(!showOption) : () => { }} >
                                <img src={prdtNavButton} alt="" style={{ transform: (showOption && docType.length > numberOfTabShowForWeb) ? "matrix(-1, 0, 0, -1, 0, -1)" : "matrix(1, 0, 0, 1, 0, -1)" }} />
                            </div>}
                        </div>
                    }

                    {documentsResult && !isEmptyObject(documentsResult[0]) &&
                        <div className="nlp-showingDetailsOfSearch" style={{ height: showOption1 ? "540px" : null, display: collapse === true ? "none" : '' }} >
                            <div className="nlp-for-left-right-wapper" ref={scrolledDiv} id="stop" style={{ maxHeight: showOption1 ? "522px" : "221px" }}>
                                {<div className='nlp-dynamic-data-left'>
                                    {
                                        Object.keys(documentsResult).map((data, index) => {
                                            let lengths = Object.keys(documentsResult).length
                                            if (index <= (selectedDocType === 'All' ? 8 : 2)) {
                                                return (
                                                    <NlpDocumentCard key={`NlpDocumentCard-${index}`} item={documentsResult[index]} lengths={lengths} index={index} setPreviewHandel={setPreviewHandel} setShowRLHandel={setShowRLHandel} customWidth={props.customWidth} selectedDocType={selectedDocType} isLastItem={((index === (selectedDocType === 'All' ? 8 : 2)) || index === lengths - 1)} />
                                                )
                                            }
                                        })
                                    }
                                </div>}
                                {showPreview &&
                                    <NlpDocumentPreview highlightVal={highlightVal} highlightVals={highlightVals} nlpflag={true} docTitle={previewClick} searchID={searchIdPreview} searchKeyword={""} synonyms={[]} showPreview={showPreview} handleClose={handlePreviewClose} pageNumber={pageNumber} />
                                }
                                {showReadingList &&
                                    <PinToReadingListPopUp showModal={showReadingList} handleClose={handleClosePinToReadingList} docID={searchIdPin} width={props.customWidth} />
                                }
                            </div>


                            <div className="nlp-right-corner-btn-wrapper">
                                <div className="nlp-feature-dropdown-btn" onClick={() => setShowOption1(!showOption1)} onMouseEnter={() => { setIsHoverShowMore(true) }} onMouseOut={() => { setIsHoverShowMore(false) }} >
                                    {isHoverShowMore ? <img
                                        className="nlp-img-style"
                                        src={dropdownArroworage}
                                        alt="dropdownIcon"
                                        style={{
                                            transform: showOption1
                                                ? "matrix(-1, 0, 0, -1, 0, 0)"
                                                : "matrix(1, 0, 0, 1, 0, 0)",
                                            marginBottom: showOption1 ? "-2px" : "-15px",
                                            width: "34px"
                                        }}
                                    /> : <img
                                        className="nlp-img-style"
                                        src={dropdownArrowBlack}
                                        alt="dropdownIcon"
                                        style={{
                                            transform: showOption1
                                                ? "matrix(-1, 0, 0, -1, 0, 0)"
                                                : "matrix(1, 0, 0, 1, 0, 0)",
                                            marginBottom: showOption1 ? "-2px" : "-15px",
                                            width: "34px"
                                        }}
                                    />}
                                </div>
                            </div>
                        </div>
                    }
                </>}</div>

        )
    }


}
export default NlpFeature;