import React, { Component, createRef } from "react";
import { iconUserChatbot } from '../../assets/index';
import { sanitize } from 'dompurify';
class AuthoringUserSummery extends Component {
    constructor(props) {
        super(props);
        this.messagesEndRef = createRef()
        this.state = {
            isShowMore: false,
        }
    }
    snippetLongString = (snippet) => {
        if (snippet.length > 150) {
            let text = "";
            let spanIndex = snippet.indexOf('<span class=');
            if (spanIndex !== -1) {
                text = snippet.substring(spanIndex, (spanIndex + 150))//cuts to spanIndex+150
                let last = text.lastIndexOf(" ")//gets last space (to avoid cutting the middle of a word)
                text = text.substring(0, last)//cuts from last space (to avoid cutting the middle of a word)
            } else {
                text = snippet.substring(0, 150)//cuts to 70
                let last = text.lastIndexOf(" ")//gets last space (to avoid cutting the middle of a word)
                text = text.substring(0, last)//cuts from last space (to avoid cutting the middle of a word)
            }
            //text = text + ` ...`//adds (...) at the end to show that it's cut
            return text
        }
        return snippet;
    }
    render() {
        let { isShowMore } = this.state;
        let { conversation } = this.props;
        let text = "";
        if (!isShowMore) {
            text = this.snippetLongString(conversation.message);
        }
        else {
            text = conversation.message;
        }
        return (<div className="message my-message" >
            <img className="img-circle medium-image" src={iconUserChatbot} alt='avatar' />
            <div className="message-body">
                <div className="message-body-inner">
                    <div className="message-text">
                        <div dangerouslySetInnerHTML={{ __html: sanitize(text) }} />
                    </div>
                    <div className="message-info" style={{ height:"25px"}}>
                        <h5>{conversation.messageTimeInText} </h5>
                        {conversation.message.length > 150 &&
                            <div className="message-show-more-less">{isShowMore ?
                                <span onClick={() => { this.setState({ isShowMore: false }) }}>Show Less</span> :
                                <span onClick={() => { this.setState({ isShowMore: true }) }}>Show More</span>}</div>}
                    </div>
                </div>
            </div>
            <br />
        </div>)
    }

}
export default AuthoringUserSummery;