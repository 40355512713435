/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from "react";
import HeaderWithBanner from "../../components/header/HeaderWithBanner";
import Footer from '../../components/footer/Footer';
import { imsLoader } from '../../assets'
import { popCancelBttn } from "../../assets";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import NavigationMenu from "../../components/Navigation/NavigationMenu";
import Services from "../../utils/services";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import '../../../node_modules/react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import '../../../node_modules/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import './NotebookView.css';
import ApiServices from "../../utils/apiServices";
import DocumentProcessing from '../../components/PopUp/DocumentProcessing';
import * as FileSaver from 'file-saver';
import { Type } from 'react-bootstrap-table2-editor';
import cellEditFactory from 'react-bootstrap-table2-editor';
import HTMLNotebook from '../../components/Notebook/HTMLNotebook';
import ReactDOMServer from "react-dom/server"
import { Modal } from "react-bootstrap";
import Button from "../../components/Button";
import NotebookEditCell from '../../components/Notebook/NotebookEditCell';
import ConfirmMessagePopUpModal from "../../components/PopUp/ConfirmMessagePopUpModal"
import AlertMessagePopUpModal from "../../components/PopUp/AlertMessagePopUpModal"
import { Link } from "react-router-dom";
import { tabletojson } from 'tabletojson';

class NotebookView extends Component {
    userid;
    userExists;
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            userExists: false,
            width: window.innerWidth,
            height: window.innerHeight,
            selectedResults: [],
            selectedRawDataResults: [],
            notebookName: "",
            orderByIndex: [],
            columns: [
                {
                    dataField: 'id',
                    text: 'id',
                    hidden: true,
                    className: 'td-checkbox'
                },
                {
                    dataField: 'order_index',
                    text: 'Order Index',
                    editable: false,
                    formatter: (value, row, index) => { return this.onHandleOrderFormatter(value, row, index, 0) },
                    style: { textAlign: "center!important" },
                    headerStyle: (colum, colIndex) => {
                        return {
                            width: '35px', textAlign: 'center', position: "sticky", top: "0px",
                            boxShadow: "inset 0 2px 0 #808080, inset 0 -2px 0 #808080 ",
                            backgroundColor: "#fff"
                        };
                    }
                },
                {
                    dataField: 'original_text',
                    text: 'Original Text',
                    editor: {
                        type: Type.TEXTAREA,
                    },
                    headerStyle: (colum, colIndex) => {
                        return {
                            width: '80px', position: "sticky", top: "0px",
                            boxShadow: "inset 0 2px 0 #808080, inset 0 -2px 0 #808080 ",
                            backgroundColor: "#fff"
                        };
                    },
                    formatter: (value, row, index) => { return this.onHandleNotesFormatter(value, row, index, 1) },
                    editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => {
                        return (
                            <NotebookEditCell {...editorProps} value={value} row={row} oldValue={row.notes} rowIndex={rowIndex} onUpdateNotesCell={this.onUpdateNotesCell} onCancelNotesCell={this.onCancelNotesCell} columnIndex={columnIndex} column={column} />
                        )
                    }
                },
                {
                    dataField: 'summarized_text',
                    text: 'Summarized Text',
                    editor: {
                        type: Type.TEXTAREA,
                    },
                    headerStyle: (colum, colIndex) => {
                        return {
                            width: '80px', position: "sticky", top: "0px",
                            boxShadow: "inset 0 2px 0 #808080, inset 0 -2px 0 #808080 ",
                            backgroundColor: "#fff"
                        };
                    },
                    formatter: (value, row, index) => { return this.onHandleNotesFormatter(value, row, index, 2) },
                    editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => {
                        if (row.command_type === "CreateTable") {
                            this.table.cellEditContext.escapeEditing();
                        }
                        return (
                            <NotebookEditCell {...editorProps} value={value} row={row} oldValue={row.notes} rowIndex={rowIndex} onUpdateNotesCell={this.onUpdateNotesCell} onCancelNotesCell={this.onCancelNotesCell} columnIndex={columnIndex} column={column} />
                        )
                    }
                },
                {
                    dataField: 'reference',
                    text: 'Reference',
                    editor: {
                        type: Type.TEXTAREA,
                    },
                    headerStyle: (colum, colIndex) => {
                        return {
                            width: '80px', position: "sticky", top: "0px",
                            boxShadow: "inset 0 2px 0 #808080, inset 0 -2px 0 #808080 ",
                            backgroundColor: "#fff"
                        };
                    },
                    formatter: (value, row, index) => { return this.onHandleNotesFormatter(value, row, index, 3) },
                    editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => {
                        return (
                            <NotebookEditCell {...editorProps} value={value} row={row} oldValue={row.notes} rowIndex={rowIndex} onUpdateNotesCell={this.onUpdateNotesCell} onCancelNotesCell={this.onCancelNotesCell} columnIndex={columnIndex} column={column} />
                        )
                    }
                },
                {
                    dataField: 'table_summarized_html',
                    text: 'Table',
                    editor: {
                        type: Type.TEXTAREA,
                    },
                    headerStyle: (colum, colIndex) => {
                        return {
                            width: '150px', position: "sticky", top: "0px",
                            boxShadow: "inset 0 2px 0 #808080, inset 0 -2px 0 #808080 ",
                            backgroundColor: "#fff"
                        };
                    },
                    // headerClasses:"table-summarized-html-cell-header",
                    formatter: (value, row, index) => { return this.onHandleNotesFormatter(value, row, index, 4) },
                    editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => {
                        if (row.command_type === "CreateTable" && columnIndex === 2) {
                            return (<></>);
                        } else if (row.command_type === "CreateTable" && columnIndex === 4) {
                            return (<div className="table-message-text message-text" dangerouslySetInnerHTML={{ __html: value }}>
                            </div>);
                        }
                        else if (row.command_type !== "CreateTable" && columnIndex === 4) {
                            return (<></>);
                        }
                        this.table.cellEditContext.escapeEditing();
                        return (
                            <NotebookEditCell {...editorProps} value={value} row={row} oldValue={row.notes} rowIndex={rowIndex} onUpdateNotesCell={this.onUpdateNotesCell} onCancelNotesCell={this.onCancelNotesCell} columnIndex={columnIndex} column={column} />
                        )
                    }
                },
                {
                    dataField: 'notes',
                    text: 'Notes',
                    editor: {
                        type: Type.TEXTAREA,
                    },
                    headerStyle: (colum, colIndex) => {
                        return {
                            width: '80px', position: "sticky", top: "0px",
                            boxShadow: "inset 0 2px 0 #808080, inset 0 -2px 0 #808080 ",
                            backgroundColor: "#fff"
                        };
                    },
                    formatter: (value, row, index) => { return this.onHandleNotesFormatter(value, row, index, 5) },
                    editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => {
                        return (
                            <NotebookEditCell {...editorProps} value={value} row={row} oldValue={row.notes} rowIndex={rowIndex} onUpdateNotesCell={this.onUpdateNotesCell} onCancelNotesCell={this.onCancelNotesCell} columnIndex={columnIndex} column={column} />
                        )
                    }
                },
                {
                    dataField: 'command_type',
                    text: 'Command Type',
                    editable: false,
                    headerStyle: (column, columnIndex) => {
                        return {
                            width: '80px', position: "sticky", top: "0px",
                            boxShadow: "inset 0 2px 0 #808080, inset 0 -2px 0 #808080 ",
                            backgroundColor: "#fff"
                        }
                    },
                    formatter: (value, row, index) => { return this.onHandleNotesFormatter(value, row, index, 6) },
                },
                {
                    dataField: 'command_text',
                    text: 'Command Text',
                    editable: false,
                    headerStyle: (column, columnIndex) => {
                        return {
                            width: '80px', position: "sticky", top: "0px",
                            boxShadow: "inset 0 2px 0 #808080, inset 0 -2px 0 #808080 ",
                            backgroundColor: "#fff"
                        }
                    },
                    formatter: (value, row, index) => { return this.onHandleNotesFormatter(value, row, index, 7) },

                },
                {
                    dataField: 'doc_title',
                    text: 'Document Link',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return {
                            width: '80px', position: "sticky", top: "0px",
                            boxShadow: "inset 0 2px 0 #808080, inset 0 -2px 0 #808080 ",
                            backgroundColor: "#fff"
                        };
                    },
                    formatter: (value, row, index) => {
                        return (<Link target="_blank" to={row.download_url} className="quick-action-item">
                            {row.doc_title}
                        </Link>)
                    },
                }

            ],
            docProcessing: false,
            isLoaded: false,
            saveFormat: "Word",
            showPop: false,
            selected: [],
            showDeletePopup: false,
            errorLabel: false,
            confirmMessageContent: '',
            confirmMessageShowModal: false,
            confirmMessageIndex: -1,
            alertMessageModelColor: "",
            alertMessageContent: "",
            alertMessageShowModal: false,
            isExportOriginalText: false
        }
    }
    onHandleOrderFormatter = (value, row, rowIndex, columnIndex) => {
        let { selectedResults } = this.state;
        return (<>
            <select style={{ minWidth: "40px", fontSize: "14px", padding: "2px" }} value={value} onChange={(e) => this.onHandleOrderChange(e, value, row)}>
                {selectedResults.map((item, itemIndex) => {
                    return (<option value={itemIndex + 1} selected={itemIndex + 1 === value}>{itemIndex + 1}</option>)
                })}
            </select>
        </>)
    }
    onHandleNotesFormatter = (value, row, rowIndex, columnIndex) => {
        if (row.command_type === "CreateTable" && columnIndex === 2) {
            return (<></>);
        } else if (columnIndex === 2) {
            return (<>
                <div className="table-message-text message-text" dangerouslySetInnerHTML={{ __html: value }}>
                </div>
                <div className="nbv-notes-cell-btn">
                    <a onClick={(e) => { this.onHandleNotesCellEdit(rowIndex, columnIndex) }}
                        title={value ? "Edit" : "Add"}
                        className="nbv-notes-cell-action-text">
                        {value ? "Edit" : "Add"}
                    </a>
                </div>
            </>);
        }
        else if (row.command_type === "CreateTable" && columnIndex === 4) {

            return (<div className="table-message-text message-text" dangerouslySetInnerHTML={{ __html: value }}>
            </div>);
        }
        else if (row.command_type !== "CreateTable" && columnIndex === 4) {
            return (<></>);
        }
        else {
            if (columnIndex === 6 || columnIndex === 7) {
                return (
                    <div className="nbv-notes-cell">
                        <div className="nbv-notes-cell-value">
                            <span>
                                {value}
                            </span>
                        </div>
                    </div>
                )
            }
            else {
                return (<>
                    <div className="nbv-notes-cell">
                        <div className="nbv-notes-cell-value">
                            <span>
                                {value}
                            </span>
                        </div>
                        <div className="nbv-notes-cell-btn">
                            <a onClick={(e) => { this.onHandleNotesCellEdit(rowIndex, columnIndex) }}
                                title={value ? "Edit" : "Add"}
                                className="nbv-notes-cell-action-text">
                                {value ? "Edit" : "Add"}
                            </a>
                        </div>
                    </div>
                </>)
            }
        }
    }
    onHandleOrderChange = async (event, oldValue, row) => {
        let { orderByIndex } = this.state;
        let newValue = parseInt(event.target.value);
        if (newValue > oldValue) {
            let isItemFound = false;
            for (let i = 0; i < orderByIndex.length; i++) {
                if (isItemFound && i < newValue) {
                    orderByIndex[i].order_index = orderByIndex[i].order_index - 1;
                }
                if (orderByIndex[i].id === row.id) {
                    orderByIndex[i].order_index = newValue;
                    isItemFound = true;
                }
            }
        } else if (newValue < oldValue) {
            let isItemFound = false;
            for (let i = orderByIndex.length - 1; i >= 0; i--) {
                if (isItemFound && i + 1 <= oldValue && i + 1 >= newValue) {
                    orderByIndex[i].order_index = orderByIndex[i].order_index + 1;
                }
                if (orderByIndex[i].id === row.id) {
                    orderByIndex[i].order_index = newValue;
                    isItemFound = true;
                }
            }
        }
        let param = orderByIndex.map(x => { return `'{"id": ${x.id}, "order_index": ${x.order_index}}'` }).join(`,`);
        const service = new ApiServices();
        var query = `call "iMS".phm_ims_update_notebook_order_json(array [${param}]::json[])`;
        this.setState({ isLoaded: false })
        await service.updateDbService(query)
            .then((response) => { })
            .catch(function (error) {
                //console.log(error);
            })
            .finally(async () => {
                await this.getNotebookItemsList();
            })
    }
    onHandleNotesCellEdit = (rowIndex, columnIndex) => {
        this.table.cellEditContext.startEditing(rowIndex, columnIndex);  // rowindex, columnindex
    }
    onUpdateNotesCell = async (oldValue, newValue, row, column) => {
        if (oldValue !== newValue) {
            await this.updateNotebookNotes(row.id, newValue, column)
        }
    }
    onCancelNotesCell = () => {
        this.table.cellEditContext.escapeEditing();
    }
    updateNotebookNotes = async (id, notes, column) => {
        this.setState({ isLoaded: false })
        let service = new ApiServices();
        await service.updateNotebookItems(id, notes, column)
            .then((response) => { })
            .catch(function (error) {
                //console.log(error);
            })
            .finally(async () => {
                await this.getNotebookItemsList();
            })
    }


    async componentDidMount() {
        this.userid = JSON.parse(localStorage.getItem('username'));
        this.userExists = localStorage.getItem('userExists');
        if (this.userExists) {
            const queryParams = new URLSearchParams(this.props.location.search);
            const notebookName = queryParams.get("notebookName");
            this.setState({
                userExists: this.userExists,
                notebookName: notebookName,
            })
            await this.getNotebookItemsList();

            window.addEventListener('resize', this.updateDimensions);
        }
        else {
            console.log('Access Denied');
            this.props.history.push('/');
        }
    }

    getNotebookItemsList = async () => {
        let service = new ApiServices();
        const queryParams = new URLSearchParams(this.props.location.search);
        const notebookId = queryParams.get("notebookId");
        let apiRes = await service.getNotebookItemsList(notebookId);

        let orderIndex = apiRes.map(x => { return { id: x.id, order_index: x.order_index } });
        orderIndex = orderIndex.sort((a, b) => parseFloat(a.order_index) - parseFloat(b.order_index));
        this.setState({ selectedResults: apiRes, isLoaded: true, selected: [], orderByIndex: orderIndex }, async () => {
            if (apiRes.length === 0) {
                this.errorAlertMessage("You do not have any items in this notebook.")
            }
        })
    }

    componentWillUnmount() {
        let login = new Services();
        login.logService(`Notebook View Page successfully unmounted for ${this.userid.toLowerCase()}`, 'info');
        window.removeEventListener('resize', this.updateDimensions);
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight })
    }
    customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} Results
        </span>
    );
    generateDownloadTimestamp = () => {
        const now = new Date()
        return now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate() + "_" + now.getHours() + "-" + now.getMinutes() + "-" + now.getSeconds()
    }
    handleCreateDraft = async () => {
        this.setState({ showPop: true, saveFormat: "Word", isExportOriginalText: false });
    }
    onClickProceed = async () => {
        let { notebookName, selectedResults, saveFormat, selected, isExportOriginalText } = this.state;
        let _notebookItems = Object.assign([], selectedResults);
        if (selected && selected.length > 0) {
            _notebookItems = _notebookItems.filter(x => selected.includes(x.id));
        }
        this.setState({ docProcessing: true })
        let service = new ApiServices();
        if (saveFormat === "Word") {
            let fileName = `${notebookName}_${this.generateDownloadTimestamp()}.docx`;
            const html = ReactDOMServer.renderToString(<HTMLNotebook notebookName={this.state.notebookName} selected={selected} notebookItems={this.state.selectedResults} isExportOriginalText={this.state.isExportOriginalText} />);
            await service.prepareWordDocument(html, 'Word').then((res) => {
                const data = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                FileSaver.saveAs(data, fileName);
            })
                .catch((error) => {
                    console.log(error);

                }).finally(() => {
                    this.setState({ docProcessing: false, showPop: false, saveFormat: "Word" })
                });
        }
        else if (saveFormat === "Pdf") {
            let fileName = `${notebookName}_${this.generateDownloadTimestamp()}.pdf`;
            const html = ReactDOMServer.renderToString(<HTMLNotebook selected={selected} notebookName={this.state.notebookName} notebookItems={this.state.selectedResults} isExportOriginalText={this.state.isExportOriginalText} />);
            await service.prepareWordDocument(html, 'PDF').then((res) => {
                const data = new Blob([res.data], { type: 'application/pdf' });
                FileSaver.saveAs(data, fileName);
            }).catch((error) => {
                console.log(error);

            }).finally(() => {
                this.setState({ docProcessing: false, showPop: false, saveFormat: "Word" })
            });
        }
        else {
            let csvData = [];
            let columns = [];
            let fileName = `${notebookName}_${this.generateDownloadTimestamp()}.xlsx`;
            let tables = {};
            let worksheetName = "Notebook";
            _notebookItems.sort((a, b) => parseFloat(a.order_index) - parseFloat(b.order_index)).map((item, index) => {
                try {
                    let _row = {}
                    if (isExportOriginalText) {
                        _row = {
                            'Sl No': index + 1,
                            'Original Text': item.original_text ? item.original_text : "",
                            'Summarized Text': item.summarized_text ? item.summarized_text : "",
                            'Reference': item.reference ? item.reference : "",
                            'Notes': item.notes ? item.notes : "",
                        };
                    } else {
                        _row = {
                            'Sl No': index + 1,
                            'Summarized Text': item.summarized_text ? item.summarized_text : "",
                            'Reference': item.reference ? item.reference : "",
                            'Notes': item.notes ? item.notes : "",
                        };
                    }
                    if (item.command_type === "CreateTable") {
                        const converted = tabletojson.convert(item.table_summarized_html);
                        if (converted.length > 0) {
                            tables[`Sl No ${index + 1} Table`] = converted[0]
                            _row.Table = { text: `Sl No ${index + 1} Table`, hyperlink: `#\'Sl No ${index + 1} Table\'!A1` };
                        } else {
                            _row.Table = "";
                        }
                    } else {
                        _row.Table = "";
                    }
                    csvData.push(_row)
                } catch (error) {
                    console.log("error", error);
                }
                return null;
            })
            if (isExportOriginalText) {
                columns = [
                    { header: "Sl No", key: "Sl No", width: 10 },
                    { header: "Original Text", key: "Original Text", width: 40 },
                    { header: "Summarized Text", key: "Summarized Text", width: 40 },
                ];
            } else {
                columns = [
                    { header: "Sl No", key: "Sl No", width: 10 },
                    { header: "Summarized Text", key: "Summarized Text", width: 40 },
                ];
            }
            if (Object.keys(tables).length > 0) {
                columns.push({ header: "Table", key: "Table", width: 30 })
            }
            columns.push({ header: "Reference", key: "Reference", width: 40 })
            columns.push({ header: "Notes", key: "Notes", width: 30 })
            await service.prepareExcelDocument(csvData, columns, worksheetName, tables).then((res) => {
                const data = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                FileSaver.saveAs(data, fileName);
            }).catch((error) => {
                console.log(error);

            }).finally(() => {
                this.setState({ docProcessing: false, showPop: false, saveFormat: "Word" })
            });
        }
    }
    handleOnChangeSaveFormat = (event) => {
        this.setState({ saveFormat: event.target.value })
    }
    handleOnChangeExportOriginalText = (event) => {
        let { isExportOriginalText } = this.state;
        this.setState({ isExportOriginalText: !isExportOriginalText });
    }
    renderModelPopup() {
        const { saveFormat, showPop, isExportOriginalText } = this.state;
        return (<Modal
            show={showPop}
            size="md"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="popup-transition"
            className="z-index-create-notebook"
            backdropClassName="z-index-create-notebook-backdrop"
            scrollable={true}
        >
            <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
                <div className="popup-cancel-btn-wrapper">
                    <img src={popCancelBttn} alt="" onClick={(e) => { this.setState({ showPop: false }) }} />
                </div>
                <div>
                    <Modal.Header className="gsfModalHeader" style={{ justifyContent: "center" }}>
                        <div style={{ textAlign: "center", width: "100%", paddingTop: "1%" }}>
                            <Modal.Title>
                                Create Draft for Notebook
                            </Modal.Title>
                        </div>
                    </Modal.Header>
                    <Modal.Body scrollable={true}>
                        <div>
                            <div>
                                <div>
                                    <span className="gsf-popup-inputHeading">Format :</span><br />
                                    <div className="nbv-popup-inputContainer">
                                        <div className="gsf-popup-inputPart">
                                            <input type="radio" className="gsf-popup-radioInput k-radio" name="formathtml" value="Word" onChange={this.handleOnChangeSaveFormat} checked={saveFormat === 'Word'} />
                                            <label style={{ color: saveFormat === 'Word' ? "#333" : "#63666A" }}>Word</label>
                                        </div>
                                        <div className="gsf-popup-inputPart">
                                            <input type="radio" className="gsf-popup-radioInput k-radio" name="formatExcel" value="Excel" onChange={this.handleOnChangeSaveFormat} checked={saveFormat === 'Excel'} />
                                            <label style={{ color: saveFormat === 'Excel' ? "#333" : "#63666A" }}>Excel</label>
                                        </div>
                                        <div className="gsf-popup-inputPart">
                                            <input type="radio" className="gsf-popup-radioInput k-radio" name="formatpdf" value="Pdf" onChange={this.handleOnChangeSaveFormat} checked={saveFormat === 'Pdf'} />
                                            <label style={{ color: saveFormat === 'Pdf' ? "#333" : "#63666A" }}>Pdf</label>
                                        </div>
                                    </div>
                                    <div className="nbv-popup-inputContainer-checkbox">
                                        <div className="gsf-popup-inputPart-checkbox">
                                            <input type="checkbox" name="ExportOriginalText" value="Export Original Text" onChange={this.handleOnChangeExportOriginalText} checked={isExportOriginalText} />
                                            &nbsp;
                                            <label style={{ color: isExportOriginalText ? "#333" : "#63666A", cursor: "pointer" }} onClick={this.handleOnChangeExportOriginalText}> Export Original Text</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {
                        <div className="nbv-popup-button-wrapper">
                            <Button text="Cancel" customColor="#0074B0" action={() => { this.setState({ showPop: false }) }} />
                            <Button text="Proceed" customColor="#2EB000" action={this.onClickProceed} />
                        </div>
                    }
                </div>
            </div>
        </Modal>)
    }
    handleSelectRow = (row, isSelect) => {
        if (isSelect) {
            this.setState(() => ({
                selected: [...this.state.selected, row.id],
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter(x => x !== row.id),
            }));
        }
    }
    handleSelectAllRow = (isSelect, rows) => {
        const ids = rows.map(r => r.id);
        if (isSelect) {
            this.setState(() => ({
                selected: ids,
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter((el) => !ids.includes(el)), //[],
            }));
        }
    }
    handleButtonDelete = () => {
        if (this.state.selected.length > 0) {
            this.showConfirmMessage("Are you sure to delete item(s)?");
        }
        else {
            this.errorAlertMessage(
                "None of the items are selected"
            );
        }
    }

    showConfirmMessage = (message) => {
        this.setState({
            confirmMessageContent: message,
            confirmMessageShowModal: true,
        })
    }

    confirmHandler = async () => {
        let { orderByIndex, selected } = this.state;
        this.setState(
            {
                confirmMessageContent: "",
                confirmMessageShowModal: false,
                confirmMessageIndex: -1
            },
            async () => {
                const service = new ApiServices();
                var query = `call "iMS".phm_ims_deletesummeries(array [${this.state.selected}])`;
                this.setState({ isLoaded: false })
                await service.updateDbService(query)
                    .then(async (response) => {
                        orderByIndex = orderByIndex.filter(x => !selected.includes(x.id)).map((x, i) => { return { ...x, order_index: x.order_index = i + 1 } });
                        let param = orderByIndex.map(x => { return `'{"id": ${x.id}, "order_index": ${x.order_index}}'` }).join(`,`);
                        query = `call "iMS".phm_ims_update_notebook_order_json(array [${param}]::json[])`;
                        this.setState({ isLoaded: false })
                        await service.updateDbService(query)
                            .then((response) => { })
                            .catch(function (error) {
                                //console.log(error);
                            })
                    })
                    .catch(function (error) {
                        //console.log(error);
                    })
                    .finally(async () => {
                        await this.getNotebookItemsList();
                    })
            }
        );
    }

    closeConfirmMessage = () => {
        this.setState({
            confirmMessageContent: "",
            confirmMessageShowModal: false,
            confirmMessageIndex: -1
        })
    }
    successAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#00c12c",
            alertMessageShowModal: true
        })
    }

    closeAlertMessage = () => {
        this.setState({
            alertMessageContent: "",
            alertMessageModelColor: "",
            alertMessageShowModal: false
        })
    }
    errorAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#de1327",
            alertMessageShowModal: true
        })
    }
    render() {
        // const { ExportCSVButton } = CSVExport;
        let { notebookName, docProcessing, selectedResults } = this.state;
        const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: this.customTotal,
            disablePageTitle: true,
            totalSize: this.state.selectedResults.length,
            sizePerPageList: [{
                text: '10', value: 10
            },
            {
                text: '15', value: 15
            },
            {
                text: '20', value: 20
            },
            {
                text: 'All', value: this.state.selectedResults.length
            }] // A numeric array is also available. the purpose of above example is custom the text
        };
        const selectRow = {
            mode: 'checkbox',
            clickToSelect: false,
            onSelect: this.handleSelectRow,
            onSelectAll: this.handleSelectAllRow,
            selected: this.state.selected
        };
        const { confirmMessageContent, confirmMessageShowModal } = this.state;
        const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
        return (
            <div>
                {/* {this.state.isLoaded && <HTMLNotebook selected={this.state.selected} notebookName={this.state.notebookName} notebookItems={this.state.selectedResults} />} */}
                <ErrorBoundary>
                    {this.state.userExists ? <>
                        <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                        <div className="header-wrapper">
                            <HeaderWithBanner />
                        </div>

                        <div className="admin-data-table-wrapper excel-data-table-wrapper excel-data-table-wrapper-notbook">
                            <div className="excel-data-function-button-wrapper">
                                <div
                                    className="imsResultsMenuHeaderNotebook"
                                    style={{
                                        width: "100%",
                                        background: "#2D86B5",
                                        height: "auto",
                                    }}
                                >
                                    <span style={{ wordBreak: "break-word" }}>Notebook: {notebookName.toUpperCase()}</span>
                                </div>
                                {/* <ExportCSV csvData={this.state.selectedResults} selectedResults={this.state.selectedRawDataResults} fileName={"iMS_Results_Download_" + this.generateDownloadTimestamp()} /> */}
                            </div>
                            <div className="notebook-data-function-button-wrapper">
                                <div className="admin-console-button-container">
                                    <Button text="Delete" customColor={(this.state.selected && this.state.selected.length > 0) ? '#FF8000' : '#B2B2B2'} isDisabled={!(this.state.selected && this.state.selected.length > 0)} action={this.handleButtonDelete} />
                                </div>
                            </div>
                            {this.state.isLoaded ? <>
                                <div>
                                    <BootstrapTable
                                        ref={x => this.table = x}
                                        keyField='id'
                                        data={this.state.selectedResults}
                                        columns={this.state.columns}
                                        bordered={true}
                                        className="notebook-table"
                                        pagination={paginationFactory(options)}
                                        wrapperClasses="table-responsive table-responsive-min-width notebook-table-checkbox-cell"
                                        rowClasses="text-nowrap"
                                        selectRow={selectRow}
                                        cellEdit={cellEditFactory(
                                            {
                                                mode: 'dbclick',
                                                // blurToSave: true,
                                                afterSaveCell: async (oldValue, newValue, row, column) => {
                                                    if (oldValue !== newValue) {
                                                        await this.updateNotebookNotes(row.id, newValue)
                                                    }
                                                }
                                            }
                                        )}>
                                    </BootstrapTable>
                                </div>
                                <div
                                    className="imsResultsMenuHeaderNotebook"
                                    style={{
                                        width: "100%",
                                        background: "#2D86B5",
                                        height: "auto",
                                        marginTop: "10px"
                                    }}
                                >
                                    <button className="btnCreateDraft" disabled={selectedResults.length === 0} type="button" onClick={e => this.handleCreateDraft()}>Create Draft</button>
                                </div>
                            </> : (
                                <div style={{ textAlign: "center", padding: "10%" }}>
                                    <img
                                        src={imsLoader}
                                        alt="Loading Data"
                                        style={{ width: "10%" }}
                                    />
                                </div>
                            )}

                        </div>
                        <div style={{ margin: "0 1.5%" }}>
                            <Footer customWidth={this.state.width} customHeight={this.state.height} />
                        </div></>
                        :
                        <div style={{ textAlign: "center", padding: "10%" }}>
                            <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
                        </div>
                    }
                    {docProcessing &&
                        <>
                            <DocumentProcessing showModal={docProcessing} color="#0074B0" documentProcessingClassName="document-processing-contentPopUp" backdropClassName="document-processing-contentPopUp-backdrop" />
                            <div className="savemodal-outer-wrapper"></div>
                        </>
                    }
                    {this.renderModelPopup()}
                    {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
                    {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
                </ErrorBoundary>
            </div>

        )
    }
}
export default NotebookView;