/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { Component, createRef } from "react";
import { withRouter } from 'react-router-dom';
import "./JanssenScienceChatBotMessenger.css";
import { faviconImed, imsLoader, quickActionDropDownBlue, jsblue } from '../../assets'
import moment from "moment";
import axios from 'axios'
import { LocationOrigin, greetings, sectionAnsSeparator, MISEHCP, AllContent, AllContentFilter, MISEHCPFilter } from '../../utils/utilityServices';
import JanssenScienceAssistantAns from './JanssenScienceAssistantAns';
import JanssenScienceRecommendationDocuments from './JanssenScienceRecommendationDocuments';
import { v4 as uuidv4 } from 'uuid';
import ApiServices from "../../utils/apiServices";
import trackingService from "../../services/trackingService";

const iMSAssistantName = 'iMS Assistant';
const userName = 'User';
const ReactLike = 'Like';
const ReactUnlike = 'Unlike';

class JanssenScienceChatBotMessenger extends Component {

    constructor(props) {
        super(props);
        this.messagesEndRef = createRef()
        var guid = uuidv4();
        this.state = {
            chatConversation: [],
            newMessage: '',
            isLastMsgSentByUser: false,
            isProcessing: false,
            dtDropDownCollapsed: false,
            selectedDocumentType: [],
            recommendationDocuments: [],
            rdDropDownCollapsed: false,
            resetRDDropDownCollapsed: false,
            sessionID: guid,
            lastFeedbackID: '',
            lastDocumentInfo: {},
            lastQuestion: '',
            isShowMore: false,
            customWidth: props.customWidth,
            customHeight: props.customHeight,
        };
    }
    componentDidMount = () => {
        let _chatConversation = [];
        _chatConversation.push({ from: iMSAssistantName, IsSucceed: true, messages: ["Hey there!", "Can I help you find a document or answer a question?"], messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {} })
        this.setState({ chatConversation: _chatConversation });
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.customWidth !== nextProps.customWidth || this.state.customHeight !== nextProps.customHeight) {
            this.setState({
                customWidth: nextProps.customWidth,
                customHeight: nextProps.customHeight
            })
        }
    }
    customNodeCreator = (character, textNode) => {
        this.scrollToBottom();
        return document.createTextNode(character);
    }
    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
    handleInput = (e) => {
        this.setState({
            newMessage: e.target.value,
        });
    };

    handleOnClickSend = async () => {
        if (this.state.newMessage && this.state.newMessage.trim()) {
            await this.sendMsgFromUser();
        }
    }

    handleKeyDown = async (event) => {
        if (['Enter'].includes(event.key) && !event.shiftKey && this.state.newMessage && this.state.newMessage.trim()) {
            event.preventDefault();
            event.returnValue = false;
            await this.sendMsgFromUser();
        }
    }

    sendMsgFromUser = async () => {
        let { isLastMsgSentByUser } = this.state;
        const newMessage = this.state.newMessage;
        let _chatConversation = this.state.chatConversation;
        if (isLastMsgSentByUser && _chatConversation[_chatConversation.length - 1].from === userName) {
            _chatConversation[_chatConversation.length - 1].messages.push(this.state.newMessage.trim());
        } else {
            _chatConversation.push({ from: userName, IsSucceed: true, messages: [this.state.newMessage.trim()], messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, documentInfo: {} })
        }

        this.setState({
            chatConversation: _chatConversation,
            newMessage: '',
            isLastMsgSentByUser: true,
            dtDropDownCollapsed: false,
            rdDropDownCollapsed: false
        },
            async () => {
                if (!greetings.find(x => x.toLowerCase() === newMessage.toLowerCase())) {
                    await this.runiMSChat(newMessage);
                }
            })
    }
    generateArrayString = (array) => {
        let arrStr = ''
        array.map((item, index) => {
            if (index === array.length - 1) {
                arrStr += item
            }
            else {
                arrStr += item + ";"
            }
            return null
        })
        return arrStr
    }
    onRestartConversation = () => {
        let _chatConversation = [];
        _chatConversation.push({ from: iMSAssistantName, IsSucceed: true, messages: ["Hey there!", "Can I help you find a document or answer a question?"], messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {} })
        var guid = uuidv4();
        this.setState({ chatConversation: _chatConversation, selectedDocumentType: [], sessionID: guid, recommendationDocuments: [], dtDropDownCollapsed: false, rdDropDownCollapsed: false, });
    }
    
    indexData = (question, doctypepref, filters, answers, sectionanswer, searchid, documentnumber, doctitle, downloadurl, sessionid, recommendations, isGetAnyAns, isSuccess, promptTokens=0, completionTokens=0) => {
        let assistantinfo = {
            "question": question,
            "doctypepref": doctypepref,
            "filters": filters,
            "answers": answers,
            "sectionanswer": sectionanswer,
            "searchid": searchid,
            "documentnumber": documentnumber,
            "doctitle": doctitle,
            "downloadurl": downloadurl,
            "sessionid": sessionid,
            "recommendations": recommendations,
            "isGetAnyAns": isGetAnyAns,
            "isSuccess": isSuccess,
            "promptTokens": promptTokens,
            "completionTokens": completionTokens
        }
        const ts = new trackingService();
        ts.indexTrackingData(localStorage.getItem('userdisplayName'),
            localStorage.getItem('wwid'),
            localStorage.getItem('username'),
            localStorage.getItem('function'),
            localStorage.getItem('region'),
            localStorage.getItem('userproducts'),
            localStorage.getItem('doctypepref'),
            localStorage.getItem('sid'),
            localStorage.getItem('deviceType'),
            localStorage.getItem('deviceOS'),
            localStorage.getItem('browserName'),
            localStorage.getItem('browserVersion'),
            localStorage.getItem('jnjDepartment'),
            localStorage.getItem('companyname'),
            `Conducted Question`,
            '',
            window.location.href, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
            assistantinfo);
    }
    runiMSChat = (question) => {
        this.setState({ isProcessing: true })
        let { selectedDocumentType, sessionID } = this.state;
        //var url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_NLP_ASSISTANT}js_assistant`
        var url = `https://predev-ims.jnj.com${process.env.REACT_APP_IMS_API_NLP_ASSISTANT}js_assistant`
        
        // if (window.location.origin === 'https://predev-ims.jnj.com') {
        //     url = `${window.location.origin}${process.env.REACT_APP_IMS_API_NLP_ASSISTANT}js_assistant`
        // }
        // url = `https://predev-ims.jnj.com/api/nlpassistant/js_assistant`;
        //url = `${'https://predev-ims.jnj.com'}${process.env.REACT_APP_IMS_API_NLP_CHAT}ims_chat`;
        //var url = `https://awsaidnval000f.jnj.com:5010/api/nlp/ims_chat`;
        var config;
        let query = question;
        let filterStr = "";
        let selectedFilter = [];
 
        selectedFilter.push("Document Type/Scientific Response Documents/Scientific Responses/Consumer");
        let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'))
        filterStr = this.generateArrayString(selectedFilter);
        config = {
            method: 'POST',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                "query": query.trim(),
                "filters": filterStr,
                "function": userAccessGroup.includes("JanssenScience")?'Janssen Science Consumer PI': localStorage.getItem('function').replace(/"/g, ''),
                "doctypepref": localStorage.getItem('doctypepref').replace(/"/g, ''),
                "userproducts": localStorage.getItem('userproducts'),
                "region": localStorage.getItem('region'),
                "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                "contentpreference": localStorage.getItem('contentpreference').replace(/"/g, ''),
                "source": "docTitle,documentNumber,downloadUrl,docType,searchID,year,fileName",
                "from": 0,
                "size": 25,
                "sortBy": "",
                "sortField": "",
                "defaultOperator": "or",
                "sessionID": sessionID,
                //"isLabel": selectedDocumentType.includes("Labels") && selectedDocumentType.length === 1,
                "isLabel": true,
            }
        }
        let _chatConversation = this.state.chatConversation;
        let _recommendationDocuments = [], trackingRecommendation = [];
        axios(config)
            .then((response) => {
                let promptTokens = 0, completionTokens = 0;
                let responseData = response.data
                let OpenAIToken = responseData['OpenAI Token'];
                if (OpenAIToken && OpenAIToken.prompt_tokens)
                    promptTokens = OpenAIToken.prompt_tokens;
                if (OpenAIToken && OpenAIToken.completion_tokens)
                    completionTokens = OpenAIToken.completion_tokens;
                //responseData.Message = 'Error in parsing OpenAI API response unexpected EOF while parsing (<string>, line 1). Check 6069ce1c-2595-11ee-a3bb-12c51042ccc1 id in the logs for more details.';
                if (responseData['Related Documnet'] && responseData['Related Documnet'].length > 0) {
                    _recommendationDocuments = responseData['Related Documnet'];
                    trackingRecommendation = Object.assign([], _recommendationDocuments)
                    trackingRecommendation.forEach(key => {
                        key.docID = key.docID ? `${key.docID}` : key.docID;
                        key.connID = key.connID ? `${key.connID}` : key.connID;
                        key.csID = key.csID ? `${key.csID}` : key.csID;
                    });
                }
                if (responseData.Message) {
                    _chatConversation.push({
                        from: iMSAssistantName, IsSucceed: false, messages: [responseData.Message.trim()], messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, documentInfo: {
                            "sectionAnswers": [
                                {
                                    "sectionAnswer": responseData.Message,
                                    "PageNumber": "No",
                                    "PageNumberList": [
                                        1
                                    ],
                                    "SectionData": responseData.Message
                                }
                            ],
                            "downloadUrl": "",
                            "year": "",
                            "docTitle": "",
                            "searchID": "",
                            "csID": "",
                            "connID": "",
                            "docID": "",
                            "documentNumber": ""
                        }, question: question
                    })
                    this.indexData(question,
                        localStorage.getItem('doctypepref').replace(/"/g, ''),
                        filterStr,
                        responseData.Message,
                        responseData.Message,
                        "",
                        "",
                        "",
                        "",
                        sessionID,
                        trackingRecommendation, false, true, promptTokens, completionTokens);
                }
                else {
                    if (responseData['All'] && responseData['All'] && responseData['All']['0']) {
                        let answer = responseData['All']['0'];
                        if (answer.sectionAnswers && answer.sectionAnswers.length > 0) {
                            let sectionAnswer = answer.sectionAnswers[0];
                            let mainAnswer = "";
                            if (sectionAnswer && sectionAnswer.sectionAnswer) {
                                try {
                                    let splitMainAnswer = sectionAnswer.sectionAnswer.trim().split(/[.]/g);
                                    var regex = new RegExp(/[0-9a-fA-F]{32}\_[0-9]/g);
                                    splitMainAnswer.filter(y => y).map(x => {
                                        let result = x.match(regex);
                                        if (!result) {
                                            mainAnswer += `${x}.`;
                                        }
                                    })
                                    mainAnswer = mainAnswer.trim();
                                } catch (error) {
                                    console.log(error)
                                    mainAnswer = sectionAnswer.sectionAnswer.trim();
                                }

                            }
                            let downloadUrl = answer.downloadUrl;
                            if (answer.enclosureDocID && answer.enclosureDocID.trim()) {
                                downloadUrl += `&enclosureID=${parseInt(answer.enclosureDocID)}`
                            }
                            _chatConversation.push({ from: iMSAssistantName, IsSucceed: true, messages: [`<div class="mainAnsWrapper"><div class="mainAns">${mainAnswer}</div> <div class="mainUrl"><a target="_blank"  href="${downloadUrl}">${answer.docTitle}</a></div><div class="mainUrl"><span>${answer.documentNumber}</span></div></div>`], messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, documentInfo: answer, question: question })
                            this.indexData(question,
                                localStorage.getItem('doctypepref').replace(/"/g, ''),
                                filterStr,
                                mainAnswer,
                                sectionAnswer.SectionData,
                                answer.searchID,
                                answer.documentNumber,
                                answer.docTitle,
                                answer.downloadUrl,
                                sessionID,
                                trackingRecommendation, true, true, promptTokens, completionTokens)
                        } else {
                            _chatConversation.push({
                                from: iMSAssistantName, IsSucceed: false, messages: [`We don't know the answer.<br>Can you try new questions.`], messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, documentInfo: {
                                    "sectionAnswers": [
                                        {
                                            "sectionAnswer": "We don't know the answer. Can you try new questions.",
                                            "PageNumber": "No",
                                            "PageNumberList": [
                                                1
                                            ],
                                            "SectionData": "We don't know the answer. Can you try new questions."
                                        }
                                    ],
                                    "downloadUrl": "",
                                    "year": "",
                                    "docTitle": "",
                                    "searchID": "",
                                    "csID": "",
                                    "connID": "",
                                    "docID": "",
                                    "documentNumber": ""
                                }, question: question
                            })
                            this.indexData(question,
                                localStorage.getItem('doctypepref').replace(/"/g, ''),
                                filterStr,
                                "We don't know the answer. Can you try new questions.",
                                "We don't know the answer. Can you try new questions.",
                                "",
                                "",
                                "",
                                "",
                                sessionID,
                                trackingRecommendation, false, true, promptTokens, completionTokens);
                        }
                    } else {
                        _chatConversation.push({
                            from: iMSAssistantName, IsSucceed: false, messages: [`We don't know the answer.<br>Can you try new questions.`], messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, documentInfo: {
                                "sectionAnswers": [
                                    {
                                        "sectionAnswer": `We don't know the answer. Can you try new questions.`,
                                        "PageNumber": "No",
                                        "PageNumberList": [
                                            1
                                        ],
                                        "SectionData": `We don't know the answer. Can you try new questions.`
                                    }
                                ],
                                "downloadUrl": "",
                                "year": "",
                                "docTitle": "",
                                "searchID": "",
                                "csID": "",
                                "connID": "",
                                "docID": "",
                                "documentNumber": ""
                            }, question: question
                        })
                        this.indexData(question,
                            localStorage.getItem('doctypepref').replace(/"/g, ''),
                            filterStr,
                            "We don't know the answer. Can you try new questions.",
                            "We don't know the answer. Can you try new questions.",
                            "",
                            "",
                            "",
                            "",
                            sessionID,
                            trackingRecommendation, false, true, promptTokens, completionTokens);
                    }
                }
                this.setState({
                    chatConversation: _chatConversation,
                    isLastMsgSentByUser: false,
                    recommendationDocuments: _recommendationDocuments
                });
            })
            .catch((error)=> {
                _recommendationDocuments = [];
                _chatConversation.push({ from: iMSAssistantName, IsSucceed: false, messages: [`Service is unavailable at this time, Kindly try after some time.`], messageTimeInText: `${moment().format("hh:mm a")}`, messageTime: { moment }, documentInfo: {} })
                this.indexData(question,
                    localStorage.getItem('doctypepref').replace(/"/g, ''),
                    filterStr,
                    "We don't know the answer. Can you try new questions.",
                    "We don't know the answer. Can you try new questions.",
                    "",
                    "",
                    "",
                    "",
                    sessionID,
                    [], false, false,0,0);
                console.log("error", error);
            })
            .finally(async () => {
                this.setState({
                    chatConversation: _chatConversation,
                    isLastMsgSentByUser: false,
                    isProcessing: false,
                    recommendationDocuments: _recommendationDocuments,
                    resetRDDropDownCollapsed: false
                })
            });
    }
    handleOnClickDocumentType = () => {
        const { dtDropDownCollapsed } = this.state;
        this.setState({ dtDropDownCollapsed: !dtDropDownCollapsed, rdDropDownCollapsed: false })
    }
    handleOnClickRecommendationsDocument = () => {

        const { rdDropDownCollapsed } = this.state;
        this.setState({ rdDropDownCollapsed: !rdDropDownCollapsed, resetRDDropDownCollapsed: true, dtDropDownCollapsed: false })
    }
    handleSelectedDocumentType = (event) => {
        let { selectedDocumentType } = this.state;
        if (event.target.checked) {
            this.setState({
                selectedDocumentType: selectedDocumentType.concat([event.target.value])
            });
        } else {
            this.setState({
                selectedDocumentType: selectedDocumentType.filter(x => x !== event.target.value)
            })
        }
    }
    handleClickDocumentType = (dt) => {
        let { selectedDocumentType } = this.state;
        if (!selectedDocumentType.includes(dt)) {
            this.setState({
                selectedDocumentType: selectedDocumentType.concat([dt])
            });
        } else {
            this.setState({
                selectedDocumentType: selectedDocumentType.filter(x => x !== dt)
            })
        }
    }
    handleCollapseDTDropdown = () => {
        this.setState({ dtDropDownCollapsed: false, rdDropDownCollapsed: false });
    }
    handleLatestQuestionAnswerInfo = (feedbackId, documentInfo, question) => {
        this.setState({ lastFeedbackID: feedbackId, lastDocumentInfo: documentInfo, lastQuestion: question });
    }
    pushRecommendations = async (_searchID, _reaction) => {
        let { recommendationDocuments, lastFeedbackID } = this.state;
        let assistantRecommendations = [];
        recommendationDocuments.map((item) => {
            let { docTitle, documentNumber, downloadUrl, searchID, section_output } = item;
            let reaction = 2;
            docTitle = docTitle.replace(/'/g, "''");
            documentNumber = documentNumber.replace(/'/g, "''");
            downloadUrl = downloadUrl.replace(/'/g, "''");
            let sectionTexts = [];
            section_output.map((highlightVal) => {
                sectionTexts.push(highlightVal.section_text)
            })
            let section_text = sectionTexts.join(sectionAnsSeparator);
            section_text = section_text.replace(/'/g, "''");
            if (searchID === _searchID && _reaction === ReactLike) {
                reaction = 0;
            }
            else if (searchID === _searchID && _reaction === ReactUnlike) {
                reaction = 1;
            }
            searchID = searchID.replace(/'/g, "''");

            assistantRecommendations.push(`('${docTitle}', 
            '${downloadUrl}', 
            '${searchID}', 
            '${section_text}', '${documentNumber}', now(), '${lastFeedbackID}',${reaction})`)
        })
        if (assistantRecommendations && assistantRecommendations.length > 0) {
            let service = new ApiServices();
            let dbQuery = assistantRecommendations.join(',');
            await service.pushRecommendations(dbQuery)
        }
    }
    handleIsShowMore = (_isShowMore) => {
        this.setState({ isShowMore: _isShowMore }, () => {
            this.RecommendationEnd.scrollIntoView({ behavior: "smooth" });
        });
    }

    renderChatConversation = () => {
        let { chatConversation, sessionID, recommendationDocuments, lastFeedbackID, lastDocumentInfo, lastQuestion } = this.state;
        return (<React.Fragment key={`renderChatConversation`}> {chatConversation.map((conversation, conversationIndex) => {
            if (conversation.from === iMSAssistantName) {
                return (<React.Fragment key={`${iMSAssistantName}-${conversationIndex}`}>
                    {conversation.messages.map((message, messageIndex) => {
                        return (<div key={`${iMSAssistantName}-${conversationIndex}-${messageIndex}`} className="cbm-m-b cbm-msg-left cbm-msg-avator-bubble vis imscb-clubbing-first">
                            <div className="cbm-clear">
                                {messageIndex === 0 && <div className="cbm-text-e vis cbm-conversation-name">
                                    <span className="cbm-ol-status n-vis">
                                        <span className="cbm-ol-icon" title="">
                                        </span>
                                        &nbsp;
                                    </span>
                                    {conversation.from}
                                </div>}
                                <JanssenScienceAssistantAns customWidth={this.state.customWidth} customHeight={this.state.customHeight} lastFeedbackID={lastFeedbackID} lastDocumentInfo={lastDocumentInfo} lastQuestion={lastQuestion} message={message} isSucceed={conversation.IsSucceed} conversationIndex={conversationIndex} scrollToBottom={this.scrollToBottom} documentInfo={conversation.documentInfo} question={conversation.question} sessionID={sessionID} recommendationDocuments={(chatConversation.length == conversationIndex + 1) ? recommendationDocuments : []} handleLatestQuestionAnswerInfo={this.handleLatestQuestionAnswerInfo} />
                            </div>
                        </div>)
                    })}
                    <div key={`${iMSAssistantName}-${conversationIndex}`} className="cbm-m-b cbm-msg-left cbm-msg-avator-bubble vis imscb-clubbing-last">
                        <div className="cbm-clear">
                            <div className="blk-lg-12">
                                <div className="cbm-msg-avator blk-lg-3">
                                    <img src={faviconImed} alt="iMS Assistant profile image" />
                                </div>
                                <div className="imscb-conversation-container-right ">
                                    <div className="cbm-msg-left-muted cbm-text-light cbm-text-xs cbm-t-xs cbm-timestamp-5-81847467-1675234605379 vis">
                                        <span className="cbm-created-at-time notranslate">{conversation.messageTimeInText}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ React.Fragment>)

            } else {
                return (<>
                    {conversation.messages.map((message, messageIndex) => {
                        return (
                            <div key={`${userName}-${conversationIndex}-${messageIndex}`} tabindex="-1" name="message" data-msgdelivered="" data-msgsent="false" data-msgtype="5" data-msgtime="1675318541106" data-msgcontent="sozu2h" data-msgkey="sozu2h" data-contact="FvOrsE4xD3FhZrzapzeTznrluP0ALSnV" className="cbm-m-b cbm-msg-right cbm-msg-avator-bubble vis 5-81847467-1675318542644">
                                <div className="cbm-clear">
                                    <div className=" n-vis cbm-conversation-name">
                                        <span className="cbm-ol-status n-vis"><span className="cbm-ol-icon" title=""></span>&nbsp;</span>
                                    </div>
                                    <div className="blk-lg-12">
                                        <div className="imscb-conversation-container-right ">
                                            <div className="cbm-msg-box vis imscb-custom-widget-background-color">
                                                <div className="cbm-msg-text cbm-msg-content notranslate" tabindex="-1">
                                                    <div>{message}</div>
                                                </div>
                                            </div>
                                            {messageIndex === (conversation.messages.length - 1) && <div className="cbm-msg-right-muted cbm-text-light cbm-text-xs cbm-t-xs cbm-timestamp-sozu2h vis">
                                                <span className="cbm-created-at-time notranslate">{conversation.messageTimeInText}</span>
                                                <span className="cbm-message-status notranslate cbm-read-icon" aria-hidden="false" title="delivered and read">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.06103 10.90199" width="24" height="24" className="cbm-message-status notranslate cbm-read-icon" focusable="false" aria-hidden="true" title="delivered and read">
                                                        <path fill="#859479" d="M16.89436.53548l-.57-.444a.434.434 0 0 0-.609.076l-6.39 8.2a.38.38 0 0 1-.577.039l-.427-.388a.381.381 0 0 0-.578.038l-.451.576a.5.5 0 0 0 .043.645l1.575 1.51a.38.38 0 0 0 .577-.039l7.483-9.6a.436.436 0 0 0-.076-.609z" className="cbm-delivery-report--delivered-read">
                                                        </path>
                                                        <path fill="#859479" d="M12.00236.53548l-.57-.444a.434.434 0 0 0-.609.076l-6.39 8.2a.38.38 0 0 1-.577.039l-2.614-2.558a.435.435 0 0 0-.614.007l-.505.516a.435.435 0 0 0 .007.614l3.887 3.8a.38.38 0 0 0 .577-.039l7.483-9.6A.435.435 0 0 0 12.00109.536l-.00073-.00052z" className="cbm-delivery-report--sent"></path>
                                                        <path fill="#859479" d="M9.75 7.713H8.244V5.359a.5.5 0 0 0-.5-.5H7.65a.5.5 0 0 0-.5.5v2.947a.5.5 0 0 0 .5.5h.094l.003-.001.003.002h2a.5.5 0 0 0 .5-.5v-.094a.5.5 0 0 0-.5-.5zm0-5.263h-3.5c-1.82 0-3.3 1.48-3.3 3.3v3.5c0 1.82 1.48 3.3 3.3 3.3h3.5c1.82 0 3.3-1.48 3.3-3.3v-3.5c0-1.82-1.48-3.3-3.3-3.3zm2 6.8a2 2 0 0 1-2 2h-3.5a2 2 0 0 1-2-2v-3.5a2 2 0 0 1 2-2h3.5a2 2 0 0 1 2 2v3.5z" className="cbm-delivery-report--pending"></path>
                                                    </svg>
                                                </span>
                                            </div>}

                                        </div>
                                    </div>
                                </div>
                            </div>)
                    })}

                </>)
            }


        })}
            <div style={{ float: "left", clear: "both" }}
                ref={(el) => { this.messagesEnd = el; }}>
            </div>
        </ React.Fragment>)

    }
    renderRecommendation = () => {
        let { recommendationDocuments, lastFeedbackID, lastDocumentInfo, lastQuestion, sessionID, isShowMore } = this.state;
        let totalDocuments = (isShowMore || recommendationDocuments.length < 7) ? recommendationDocuments.length : 7;
        return (<div className="rd-document-info-wrapper">
            <div className="rd-document-info-wrapper-items">
                {recommendationDocuments.filter((x, i) => (isShowMore || i < 7)).map((document, documentIndex) => {
                    return (<JanssenScienceRecommendationDocuments key={`recommendation-documents-${documentIndex}`} documentInfo={document}
                        documentIndex={documentIndex + 1} totalDocuments={totalDocuments}
                        feedbackID={lastFeedbackID} customWidth={this.state.customWidth} customHeight={this.state.customHeight}
                        mainAnswerDocumentInfo={lastDocumentInfo} question={lastQuestion} sessionID={sessionID}
                        pushRecommendations={this.pushRecommendations} originalRecommendations={recommendationDocuments} />);
                })}
                {recommendationDocuments.length > 7 && <>{isShowMore ?
                    <>
                        <span className='cbm-show-more-less' onClick={() => this.handleIsShowMore(false)}>
                            Show Less
                        </span>
                    </>
                    :
                    <>
                        <span className='cbm-show-more-less' onClick={() => this.handleIsShowMore(true)}>
                            Show More
                        </span>
                    </>
                }
                </>
                }
            </div>
            <div style={{ float: "left", clear: "both" }}
                ref={(el) => { this.RecommendationEnd = el; }}>
            </div>
        </div>)

    }
    renderDocumentTypeFilter = () => {
        let { selectedDocumentType } = this.state;
        return (<div className="cbm-tile-wrapper">
            <div className="cbm-tooltiptext cbm-tooltip-bottom">
                <ul className="ul-access-group" style={{ paddingLeft: "0px", marginBottom: "0px" }}>
                    {
                        (() => {
                            return (
                                <>
                                    <li>
                                        <input type="checkbox" name="Labels" class="k-checkbox" style={{ margin: 0 }} onChange={e => this.handleClickDocumentType("Labels")} checked={selectedDocumentType.includes('Labels')} />
                                        <span for="Labels" className="cbm-tablet" onClick={() => this.handleClickDocumentType("Labels")} >Labels</span>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="Scientific Responses" class="k-checkbox" style={{ margin: 0 }} onChange={e => this.handleClickDocumentType("Scientific Responses")} checked={selectedDocumentType.includes('Scientific Responses')} />
                                        <span for="Scientific Responses" className="cbm-tablet" onClick={() => this.handleClickDocumentType("Scientific Responses")} >Scientific Responses</span>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="Slide Decks" class="k-checkbox" style={{ margin: 0 }} onChange={e => this.handleClickDocumentType("Slide Decks")} checked={selectedDocumentType.includes('Slide Decks')} />
                                        <span for="Slide Decks" className="cbm-tablet" onClick={() => this.handleClickDocumentType("Slide Decks")} >Slide Decks</span>
                                    </li>
                                    <li>
                                        <input type="checkbox" name="Posters" class="k-checkbox" style={{ margin: 0 }} onChange={e => this.handleClickDocumentType("Posters")} checked={selectedDocumentType.includes('Posters')} />
                                        <span for="Posters" className="cbm-tablet" onClick={() => this.handleClickDocumentType("Posters")} >Posters</span>
                                    </li>
                                </>
                            )
                        })()
                    }
                </ul>
            </div>
        </div>)
    }
    render() {
        let { newMessage, isProcessing, dtDropDownCollapsed,
            rdDropDownCollapsed, recommendationDocuments, resetRDDropDownCollapsed } = this.state;
        if (this.props.customWidth > 1000) {
            return (
                <div className="cbm-sidebox cbm-box cbm-fade imscb-iframe-sidebox-border-radius popup-enabled in" role="main" aria-live="polite" aria-hidden="false">
                    <div className="cbm-box-backdrop cbm-fade in" style={{ height: "595px" }}></div>
                    <div className="cbm-box-dialog cbm-box-sm cbm-right cbm-bg-white">
                        <div className="cbm-sidebox-content cbm-box-content vis">
                            <div id="cbm-conversation-header" className="cbm-conversation-header cbm-truncate n-vis"></div>
                            <div className="cbm-box-top cbm-title" style={{ backgroundColor: "#ffffff" }} onClick={() => { this.handleCollapseDTDropdown() }}>
                                <div className="imscb-header-container">
                                    <div className="imscb-conversation-header-title-container">
                                        <div id="cbm-tab-individual" className="cbm-row vis">
                                            <div className="cbm-name-status-container cbm-box-title cbm-truncate">
                                                <div>
                                                    <div className="cbm-agent-image-container vis">
                                                        <div className="cbm-agent-image-svg-container n-vis"></div>
                                                        {/* <img className="cbm-agent-image" src={faviconImed} alt="iMS profile image" style={{ backgroundColor: '#ffffff' }} /> */}
                                                        <img className="cbm-agent-image" src={jsblue} alt="iMS profile image" style={{ backgroundColor: '#ffffff' }} />
                                                        {/* <span className="cbm-agent-status-indicator imscb-custom-widget-border-color vis cbm-status--online"></span> */}
                                                    </div>
                                                </div>
                                                {/* <div>
                                                    <div id="cbm-tab-title" className="cbm-tab-title cbm-truncate cbm-display-name cbm-title-width-with-faq-close-btn cbm-tab-title-w-status" title="iMS Assistant">iMS Assistant</div>
                                                </div> */}
                                            </div>
                                        </div>

                                    </div>

                                    <div className="imscb-faq-and-close-btn-container cbm-flexi">
                                        <div className="blk-lg-2 cbm-close-btn">
                                            <button type="button" id="imscb-chat-widget-close-button" className="cbm-box-close cbm-close-sidebox move-right" data-dismiss="mckbox" aria-label="Close" onClick={e => { this.props.CloseChatBotMessenger() }}>
                                                <svg focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#d8d8d8" height="24" viewBox="0 0 24 24" width="24">
                                                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cbm-box-body" onClick={() => this.handleCollapseDTDropdown()}>
                                <div id="cbm-message-cell" className="cbm-tab-cell cbm-message-cell">
                                    <div className="cbm-conversation" tabindex="-1" role="presentation">
                                        <div className="cbm-message-inner cbm-group-inner" id="cbm-message-conversation" tabindex="0" aria-live="assertive" aria-atomic="false" role="alert">
                                            {/* Start Chat Box */}
                                            {this.renderChatConversation()}
                                            {isProcessing && <div style={{ textAlign: "center", marginBottom: "10px" }}>
                                                <img src={imsLoader} alt="Loading Filters" style={{ width: "10%" }} />
                                            </div>}
                                            {/* End Chat Box */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="cbm-sidebox-ft" className="cbm-box-ft vis">
                                {dtDropDownCollapsed && this.renderDocumentTypeFilter()}
                                {resetRDDropDownCollapsed && (
                                    <div className="cbm-rd-tile-wrapper" style={rdDropDownCollapsed ? { display: "block" } : { display: "none" }}>
                                        <div className="cbm-tooltiptext cbm-tooltip-bottom cbm-rd-tooltip-bottom">
                                            <div className="cbm-rd-header">
                                                <div className="cbm-rd-header-title">
                                                    <label><b>Recommendations</b></label>
                                                </div>
                                                <div className="cbm-rd-header-close">
                                                    <button type="button" id="imscb-chat-widget-close-button" className="cbm-rd-box-close cbm-close-sidebox move-right" data-dismiss="mckbox" aria-label="Close" onClick={e => { this.handleOnClickRecommendationsDocument() }}>
                                                        <svg focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#d8d8d8" height="24" viewBox="0 0 24 24" width="24">
                                                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>

                                            {this.renderRecommendation()}
                                        </div>
                                    </div>
                                )}
                                <div class="cbm-csat-rd-dropdown-wrapper" style={ { justifyContent: "flex-end" }}>
                                    {/* {userAccessGroup.includes(AllContent) && <div class="cbm-csat-dt-dropdown-wrapper">
                                        <div className="cbm-csat-dt-dropdown-wrapper-header" onClick={() => this.handleOnClickDocumentType()}>
                                            <div className="cbm-csat-dt-dropdown-items-main-text">
                                                Document Types&nbsp;
                                                <img src={quickActionDropDownBlue} alt="dropdownIcon" style={{ transform: !dtDropDownCollapsed ? "matrix(-1, 0, 0, -1, 0, 0)" : null, margin: "0px 0px 2px" }} />
                                            </div>
                                        </div>
                                    </div>} */}
                                    {recommendationDocuments && recommendationDocuments.length > 0 && <div class="cbm-csat-rd-dropdown-wrapper">
                                        <div className="cbm-csat-rd-dropdown-wrapper-header" onClick={() => this.handleOnClickRecommendationsDocument()}>
                                            <div className="cbm-csat-rd-dropdown-items-main-text">
                                                Recommendations&nbsp;
                                                <img src={quickActionDropDownBlue} alt="dropdownIcon" style={{ transform: !rdDropDownCollapsed ? "matrix(-1, 0, 0, -1, 0, 0)" : null, margin: "0px 0px 2px" }} />
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                                <div className="cbm-box-form cbm-row vis">
                                    <div className="blk-lg-12">
                                        <form id="cbm-msg-form" className="vertical cbm-msg-form vis">
                                            <div className="cbm-form-group last last-child">
                                                <label className="sr-only placeholder-text control-label" for="cbm-textbox">Message</label>
                                                <div id="cbm-textbox-container" className="cbm-textbox-container">
                                                    <div className="cbm-blk-2 cbm-text-box-panel move-right vis" id="send-button-wrapper">
                                                        <div className="cbm-blk-12">
                                                            <button type="button" id="cbm-msg-sbmt" className="cbm-btn cbm-btn-text-panel" title="Send Message" aria-disabled="false" aria-label="Send Message" tabindex="0" onClick={(e) =>
                                                                this.handleOnClickSend()
                                                            }>
                                                                <span className="cbm-icon-send">
                                                                    <svg focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                                                                        <path className="imscb-custom-widget-fill" fill="#5553B7" fillRule="evenodd" d="M7.878 7.858l-6.032.584-1.588 5.076a.787.787 0 0 0 1.097.944l13.22-6.401a.752.752 0 0 0 0-1.353L1.364.302a.787.787 0 0 0-1.096.944l1.587 5.077 6.021.584a.475.475 0 0 1 0 .946l.002.005z"></path>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <textarea id="cbm-autosuggest-search-input" data-provide="typeahead" placeholder="Type your message..." className="cbm-blk-8 cbm-text cbm-typeahead imscb-auto-expand" autoComplete="off" rows="1" data-autoresize="" onFocus={() => { this.handleCollapseDTDropdown() }} form="cbm-msg-form" method="post" type="text" aria-label="Select option" tabindex="0" value={newMessage} onChange={this.handleInput} onKeyDown={this.handleKeyDown}> </textarea>
                                                    <input id="cbm-autosuggest-metadata" type="hidden" name="city-detail" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="cbm-csat-text-1 blk-lg-12">
                                    {isProcessing ? <><span>Have other queries? </span> <a className="disable-restart-conversation" style={{ cursor: "not-allowed", textDecoration: "none" }} >Restart this conversation</a></> :
                                        <><span>Have other queries? </span> <a onClick={this.onRestartConversation} >Restart this conversation</a></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            );
        } else {
            return (<></>);
        }
    }
}
export default withRouter(JanssenScienceChatBotMessenger);