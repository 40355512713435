import { Component } from "react";
import CountrySelection from "./CountrySelection";
import ApiServices from "../../utils/apiServices";
import { imsLoader, location, listIcon, locationIconBlack } from "../../assets";
import Button from '../../components/Button';
import '../Pages/Registration.css';
import MobileViewButton from "../../components/Button/MobileViewButton";
import AlertMessagePopUpModal from "../../components/PopUp/AlertMessagePopUpModal";

class LocationSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            availableRegions: [],
            availableRegionsName: [],
            availableCountries: [],
            selectedCountry: [],
            selectedRegion: [],
            ischecked: true,
            gdprAcceptance: 'yes',
            profession: '',
            regionChecked: '',
            products: [],
            contentPreference: '',
            documentPreference: [],
            selectedAreas: [],
            selectedClasses: [],
            areaId: [],
            classId: [],
            productId: [],
            history: this.props.history,
            isLoaded: false,
            showCountries: false,
            alertMessageModelColor: "",
            alertMessageContent: "",
            alertMessageShowModal: false,
        };
        //console.log(this.props);
    }
    async componentDidMount() {
        //console.log(this.props);
        const api = new ApiServices();
        var response = await api.getAllRegions();
        if (response) {
            this.setState({
                availableRegions: response,
                isLoaded: true
            });
            this.state.availableRegions.map((item, index) => {
                this.state.availableRegionsName.push(item.region)
                return null;
            })
        }
        this.setState({
            selectedRegion: this.props.selectedRegion,
            gdprAcceptance: this.props.gdprAcceptance,
            profession: this.props.profession,
            products: this.props.products,
            contentPreference: this.props.contentPreference,
            documentPreference: this.props.documentPreference,
            selectedAreas: this.props.selectedAreas,
            selectedClasses: this.props.selectedClasses
        });
        if (this.props.selectedRegion.length > 0) {
            //console.log(typeof(this.props.selectedRegion))
            var len = this.props.selectedRegion.length
            if (this.state.selectedRegion.includes('Global')) {
                this.setState({
                    regionChecked: this.props.selectedRegion[0]
                });
            }
            else {
                this.setState({
                    regionChecked: this.props.selectedRegion[len - 1]
                });
            }
        }
        if (this.props.selectedCountry.length > 0) {
            this.setState({
                selectedCountry: this.props.selectedCountry
            })
        }
        if (this.props.areaId.length > 0) {
            this.setState({
                areaId: this.props.areaId
            });
        }
        if (this.props.classId.length > 0) {
            this.setState({
                classId: this.props.classId
            });
        }
        if (this.props.productId.length > 0) {
            this.setState({
                productId: this.props.productId
            });
        }
        //console.log(response);
        await this.getAllCountries();
    }
    async getAllCountries(){
        const api = new ApiServices();
        var response = await api.getAllCountries();
        //console.log(response)
        if (response) {
            this.setState({
                availableCountries: response,
            });
        }
    }
    errorAlertMessage = (message) => {
        this.setState({
          alertMessageContent: message,
          alertMessageModelColor: "#de1327",
          alertMessageShowModal: true
        })
      }
      closeAlertMessage = () => {
        this.setState({
          alertMessageContent: "",
          alertMessageModelColor: "",
          alertMessageShowModal: false
        })
      }
   
    handleLocPrev = () => {
        this.props.onClickPreviousToWlc(this.state.selectedRegion, this.state.selectedCountry, this.state.gdprAcceptance, this.state.history);
    }
    handleLocNxt = () => {
        //console.log(this.state);
        if (this.state.selectedRegion.length <= 0) {
            this.errorAlertMessage('Please select your Region(s) to proceed.');
        }
        else if (this.state.selectedRegion.includes('EMEA') || this.state.selectedRegion.includes('EMEA - MEWA, Israel & CIS')) {
            this.props.onSelectRegionEmea(this.state.selectedRegion, this.state.selectedCountry, this.state.gdprAcceptance, this.state.profession, this.state.products, this.state.contentPreference, this.state.documentPreference, this.state.selectedAreas, this.state.selectedClasses, this.state.history, this.state.areaId, this.state.classId, this.state.productId);
        }
        else {
            this.props.onClickNextToFunc(this.state.selectedRegion, this.state.selectedCountry, this.state.gdprAcceptance, this.state.profession, this.state.products, this.state.contentPreference, this.state.documentPreference, this.state.selectedAreas, this.state.selectedClasses, this.state.history, this.state.areaId, this.state.classId, this.state.productId);
        }
    }
    handleSelectedRegion = (event) => {

        if (event.target.checked === true) {
            if (event.target.value === 'Global') {
                //console.log('Global Selection');
                if (this.state.selectedRegion.length === 0) {
                    this.setState({
                        selectedRegion: this.state.availableRegionsName,
                        regionChecked: event.target.value
                    }, () => {
                        this.regions(this.state.selectedRegion)
                        this.handleChangeInRegion()
                    });
                }
                else if (this.state.selectedRegion.length > 0) {
                    //console.log(this.state.selectedRegion);
                    this.setState({
                        selectedRegion: []
                    }, () => {
                        this.setState({
                            selectedRegion: this.state.availableRegionsName,
                            regionChecked: event.target.value
                        }, () => {
                            this.regions(this.state.selectedRegion)
                            this.handleChangeInRegion()
                        })
                    });
                    //this.state.selectedRegion = [];
                }
            }
            else {
                //console.log('Single Selection');
                this.setState({
                    selectedRegion: this.state.selectedRegion.concat([event.target.value]),
                    regionChecked: event.target.value
                }, this.handleChangeInRegion);
                if (this.state.selectedRegion.length === this.state.availableRegionsName.length - 2) {
                    //console.log(this.state.selectedRegion);
                    this.setState({
                        selectedRegion: []
                    }, () => {
                        this.setState({
                            selectedRegion: this.state.availableRegionsName,
                            regionChecked: event.target.value
                        }, () => {
                            this.regions(this.state.selectedRegion)
                            this.handleChangeInRegion()
                        })
                    })
                    /*  this.setState({
                         selectedRegion : this.state.availableRegionsName,
                         regionChecked: event.target.value},this.regions(this.state.selectedRegion)); */
                }
            }
        }
        else {
            if (event.target.value === 'Global') {
                this.setState({
                    selectedRegion: [],
                    regionChecked: '',
                    selectedCountry:[],
                }, this.handleChangeInRegion);
            }
            else {
                const{availableCountries}=this.state;
                var arr = [...this.state.selectedRegion];
                var idx = arr.indexOf(event.target.value);
                var idx2 = arr.indexOf('Global');
                var _selectedCountry=[...this.state.selectedCountry];
                let unselectedRegionCountries= availableCountries.filter(x=>x.region===event.target.value).map(z=>z.country);
                _selectedCountry=_selectedCountry.filter(x=>!unselectedRegionCountries.includes(x));
                if (idx !== -1) {
                    arr.splice(idx, 1);
                    if (idx2 !== -1) {
                        arr.splice(idx2, 1);
                    }
                    this.setState({
                        selectedRegion: arr,
                        regionChecked: '',
                        selectedCountry:_selectedCountry,
                    }, this.handleChangeInRegion)
                }
            }
        }
        //console.log(this.state.selectedRegion);
    }
    handleSelectedCountry = (selectedCountry) => {
        if (!this.state.selectedRegion.includes(this.state.regionChecked)) {
            this.setState({
                selectedRegion: this.state.selectedRegion.concat([this.state.regionChecked]),
                selectedCountry: selectedCountry
            }, this.handleChangeInRegion);
            //this.state.selectedRegion.push(this.state.regionChecked);
        }else{
            this.setState({
                selectedCountry: selectedCountry
            }, this.handleChangeInRegion);
        }
        if (this.state.selectedRegion.length === this.state.availableRegionsName.length - 2) {
            //console.log(this.state.selectedRegion);
            this.setState({
                selectedRegion: []
            }, () => {
                this.setState({
                    selectedRegion: this.state.availableRegionsName,
                    
                }, () => {
                    this.regions(this.state.selectedRegion)
                    this.handleChangeInRegion()
                })
            })
        }
    }
    handleChangeInRegion = () => {
        this.props.onChangeinRegion(this.state.selectedRegion, this.state.selectedCountry)
    }
    regions = (regionListDate) => {
        if (regionListDate.length > 0) {
            return (
                regionListDate.map((item, index) => {
                    //console.log(this.state.selectedRegion);
                    if (this.state.selectedRegion.includes(item.region) || this.state.selectedRegion.includes('Global')) {
                        // console.log('Inside IF');
                        return (
                            <div className="regionDiv" key={index}>
                                {/* <img src={rightArrow} alt="arrow" className="locationRightArrow" /> */}
                                <input type="checkbox" name="userRegion" value={item.region} onChange={this.handleSelectedRegion} defaultChecked={this.state.ischecked} key={item.region + Math.random()}
                                    className="k-checkbox"
                                // className="locationCheckbox"
                                />
                                <span
                                    onClick={() => this.handleShowCountries(item.region)}
                                    // className="regionTxt"
                                    className={!this.state.ischecked ? "regionTxt" : "regionTxtChecked"}
                                >{item.region}</span>
                            </div>
                        )
                    }
                    else {
                        // console.log('Inside Else');
                        return (
                            <div className="regionDiv" key={index}>
                                {/* <img src={rightArrow} alt="arrow" className="locationRightArrow" /> */}
                                <input type="checkbox" name="userRegion" value={item.region} onChange={this.handleSelectedRegion} defaultChecked={!this.state.ischecked} key={item.region + Math.random()}
                                    className="k-checkbox"
                                // className="locationCheckbox"
                                />
                                <span onClick={() => this.handleShowCountries(item.region)} className="regionTxt" >{item.region}</span>
                            </div>
                        )
                    }
                })
            )
        }
    }
    handleShowCountries = (region) => {
        this.setState({ showCountries: true, regionChecked: region })
    }
    render() {
        const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
        if (this.props.customWidth < 600) {
            return (
                <>
                <div className="registrationInnerWrapper">
                    {/* <div className="welcomePageTitle">
                    </div> */}
                    <div className="locationSelectContent">
                        <div className="registrationContainer">
                            <div className="locationTitlePart">
                                <img src={locationIconBlack} alt="location-iocn" />
                                <div className="locationTitle">Select Your Location
                                </div>
                            </div>
                            <div className="locationContent">
                                <p>
                                    <b className="locationTextTitle">Streamline your searches</b><br />
                                    <div className="locationText">
                                        <img src={listIcon} alt="bullets" className="contentList" />
                                        Start building your user profile by choosing the geographic area(s) where you work. iMedical Search will use your selections to prioritize the content delivered to you – personalizing your content and saving you time!
                                    </div>
                                </p>
                                <p>
                                    <b>First, select the region(s) where you have product responsibilities.</b>
                                    <span className="locationtexts">This may be different from the location where you are physically located.</span>
                                </p>
                            </div>
                            {/* </div> */}
                            {this.state.isLoaded ?
                                <div className="regionContainer">
                                    <div className="regionInnerWrapper">
                                        {/* <div className="topCircle"></div> */}
                                        {this.regions(this.state.availableRegions)}
                                    </div>
                                    {this.state.selectedCountry.length > 0 || this.state.regionChecked !== '' || this.state.showCountries ?
                                        <>
                                            <div className="region-countries-saperation"></div>
                                            <CountrySelection regionChecked={this.state.regionChecked}
                                                selectedRegion={this.state.selectedRegion}
                                                selectedCountry={this.state.selectedCountry}
                                                onSelectCountries={this.handleSelectedCountry} customWidth={this.props.customWidth} customHeight={this.props.customHeight} />
                                        </>
                                        : null
                                    }
                                    <div><br /></div>
                                    <div className="gsf-popup-button-wrapper-mobile">
                                        <div className="welcomeNxtBtn-mobile">
                                            <MobileViewButton text='Continue' customColor='#2EB000' action={this.handleLocNxt} className="continueButton" />
                                        </div>
                                        {/* <div className="welcomeNxtBtn">
                                            <Button text='Previous' customColor='#FF8000' action={this.handleLocPrev} />
                                        </div> */}
                                        <div className="welcomeNxtBtn-mobile">
                                            <MobileViewButton text='Cancel' customColor='#0074B0' action={() => { this.props.history.push('/') }} />
                                        </div>
                                    </div>
                                </div>
                                : <div style={{ textAlign: "center" }} >
                                    <img src={imsLoader} alt="Loading Data" style={{ width: "7%", marginTop: "5%" }}></img>
                                </div>}
                        </div>
                    </div>
                    {/* <div><br /></div> */}
                    {/* <div className="welcomeNxtBtn">
                        <input type="submit" value="Continue" onClick={this.handleLocNxt}></input>
                    </div>
                    <div className="welcomeNxtBtn">
                        <input type="submit" value="Previous" onClick={this.handleLocPrev}></input>
                    </div>
                    <div className="welcomeNxtBtn">
                        <input type="submit" value="Cancel" onClick={() => { this.props.history.push('/') }}></input>
                    </div> */}
                </div>
                {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={()=>this.closeAlertMessage()} />}
                </>
            );
        }
        else if (this.props.customWidth < 1000) {
            return (
                <>
                <div className="registrationInnerWrapper">
                    {/* <div className="welcomePageTitle">
                    </div> */}
                    <div className="locationSelectContent">
                        <div className="registrationContainer">
                            <div className="locationTitlePart">
                                <img src={locationIconBlack} alt="location-iocn" />
                                <div className="locationTitle">Select Your Location
                                </div>
                            </div>
                            <div className="locationContent">
                                <p>
                                    <b className="locationTextTitle">Streamline your searches</b><br />
                                    <div className="locationText">
                                        <img src={listIcon} alt="bullets" className="contentList" />
                                        Start building your user profile by choosing the geographic area(s) where you work. iMedical Search will use your selections to prioritize the content delivered to you – personalizing your content and saving you time!
                                    </div>
                                </p>
                                <p>
                                    <b>First, select the region(s) where you have product responsibilities.</b>
                                    <span className="locationtexts">This may be different from the location where you are physically located.</span>
                                </p>
                            </div>
                            {/* </div> */}
                            {this.state.isLoaded ?
                                <div className="regionContainer">
                                    <div className="regionInnerWrapper">
                                        {/* <div className="topCircle"></div> */}
                                        {this.regions(this.state.availableRegions)}
                                    </div>
                                    {this.state.selectedCountry.length > 0 || this.state.regionChecked !== '' || this.state.showCountries ?
                                        <>
                                            <div className="region-countries-saperation"></div>
                                            <CountrySelection regionChecked={this.state.regionChecked}
                                                selectedRegion={this.state.selectedRegion}
                                                selectedCountry={this.state.selectedCountry}
                                                onSelectCountries={this.handleSelectedCountry} customWidth={this.props.customWidth} customHeight={this.props.customHeight} />
                                        </>
                                        : null
                                    }
                                    <div><br /></div>
                                    <div className="buttonContainer" style={{ clear: "both", paddingRight: "50px" }}>
                                        <div className="welcomeNxtBtn">
                                            <Button text='Continue' customColor='#2EB000' action={this.handleLocNxt} className="continueButton" />
                                        </div>
                                        {/* <div className="welcomeNxtBtn">
                                            <Button text='Previous' customColor='#FF8000' action={this.handleLocPrev} />
                                        </div> */}
                                        <div className="welcomeNxtBtn">
                                            <Button text='Cancel' customColor='#0074B0' action={() => { this.props.history.push('/') }} />
                                        </div>
                                    </div>
                                </div>
                                : <div style={{ textAlign: "center" }} >
                                    <img src={imsLoader} alt="Loading Data" style={{ width: "7%", marginTop: "5%" }}></img>
                                </div>}
                        </div>
                    </div>
                    {/* <div><br /></div> */}
                    {/* <div className="welcomeNxtBtn">
                        <input type="submit" value="Continue" onClick={this.handleLocNxt}></input>
                    </div>
                    <div className="welcomeNxtBtn">
                        <input type="submit" value="Previous" onClick={this.handleLocPrev}></input>
                    </div>
                    <div className="welcomeNxtBtn">
                        <input type="submit" value="Cancel" onClick={() => { this.props.history.push('/') }}></input>
                    </div> */}
                </div>
                {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={()=>this.closeAlertMessage()} />}
                </>
            );
        }
        else {
            return (
                <>
                <div className="registrationInnerWrapper">
                    {/* <div className="welcomePageTitle">
                </div> */}
                    <div className="locationSelectContent">
                        <div className="registrationContainer">
                            <div className="locationTitlePart">
                                <img src={location} alt="location-iocn" />
                                <div className="locationTitle">Select Your Location
                                </div>
                            </div>
                            <div className="locationContent">
                                <p>
                                    <b className="locationTextTitle">Streamline your searches</b><br />
                                    <div className="locationText">
                                        <img src={listIcon} alt="bullets" className="contentList" />
                                        Start building your user profile by choosing the geographic area(s) where you work. iMedical Search will use your selections to prioritize the content delivered to you – personalizing your content and saving you time!
                                    </div>
                                </p>
                                <p>
                                    <b>First, select the region(s) where you have product responsibilities.</b>
                                    <span className="locationtexts">This may be different from the location where you are physically located.</span>
                                </p>
                            </div>
                        </div>
                        {this.state.isLoaded ?
                            <div className="regionContainer">
                                <div className="regionInnerWrapper">
                                    {/* <div className="topCircle"></div> */}
                                    {this.regions(this.state.availableRegions)}
                                </div>
                                {this.state.selectedCountry.length > 0 || this.state.regionChecked !== '' || this.state.showCountries ?
                                    <CountrySelection regionChecked={this.state.regionChecked}
                                        selectedRegion={this.state.selectedRegion}
                                        selectedCountry={this.state.selectedCountry}
                                        onSelectCountries={this.handleSelectedCountry} /> : null
                                }
                                <div><br /></div>
                                <div className="buttonContainer" style={{ clear: "both", paddingRight: "50px" }}>
                                    <div className="welcomeNxtBtn">
                                        <Button text='Continue' customColor='#2EB000' action={this.handleLocNxt} className="continueButton" />
                                    </div>
                                    {/* <div className="welcomeNxtBtn">
                                        <Button text='Previous' customColor='#FF8000' action={this.handleLocPrev} />
                                    </div> */}
                                    <div className="welcomeNxtBtn">
                                        <Button text='Cancel' customColor='#0074B0' action={() => { this.props.history.push('/') }} />
                                    </div>
                                </div>
                            </div>
                            : <div style={{ textAlign: "center" }} >
                                <img src={imsLoader} alt="Loading Data" style={{ width: "7%", marginTop: "5%" }}></img>
                            </div>}
                    </div>
                    {/* <div><br /></div> */}
                    {/* <div className="welcomeNxtBtn">
                    <input type="submit" value="Continue" onClick={this.handleLocNxt}></input>
                </div>
                <div className="welcomeNxtBtn">
                    <input type="submit" value="Previous" onClick={this.handleLocPrev}></input>
                </div>
                <div className="welcomeNxtBtn">
                    <input type="submit" value="Cancel" onClick={() => { this.props.history.push('/') }}></input>
                </div> */}
                </div>
                {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={()=>this.closeAlertMessage()} />}
                </>
            );
        }
    }
}
export default LocationSelection;