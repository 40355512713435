/* eslint-disable array-callback-return */
/* eslint-disable no-useless-constructor */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/no-redundant-roles */
import { Component } from "react";
// import Typewriter from 'typewriter-effect';
import NlpDocumentPreview from '../Preview/NlpDocumentPreview';
import { Modal } from "react-bootstrap";
import { isEmptyObject } from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';
import { sectionAnsSeparator } from "../../utils/utilityServices";
import ApiServices from "../../utils/apiServices";
import trackingService from "../../services/trackingService";

const ReactLike = 'Like';
const ReactUnlike = 'Unlike';
const ReactNone = 'None';

class JanssenScienceAssistantAns extends Component {

    constructor(props) {
        super(props);
        var guid = uuidv4();
        this.state = {
            isShowPreviewButton: false,
            showPreviewModel: false,
            showPreview: false,
            highlightVals: [],
            reaction: ReactNone,
            isShowMore: false,
            feedbackID: `AF_${guid}`,
            customWidth: props.customWidth,
            customHeight: props.customHeight,
        }
    }
    componentDidMount = () => {
        let { feedbackID } = this.state;
        let { documentInfo, question } = this.props;
        this.props.handleLatestQuestionAnswerInfo(feedbackID, documentInfo, question);
    }
    customNodeCreator = (character, textNode) => {
        this.props.scrollToBottom();
        return document.createTextNode(character);
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.customWidth !== nextProps.customWidth || this.state.customHeight !== nextProps.customHeight) {
            this.setState({
                customWidth: nextProps.customWidth,
                customHeight: nextProps.customHeight
            })
        }
    }
    onPreviewClick = () => {
        let { documentInfo } = this.props;
        let highlightVals = documentInfo.sectionAnswers.map((highlightVal) => {
            let temp = [];
            let _pageNumberList = (highlightVal.PageNumberList && highlightVal.PageNumberList.length > 0) ? highlightVal.PageNumberList.slice(-2) : [];
            let lastIndex = _pageNumberList.length - 1;
            let _pageNumber = (_pageNumberList.length > 0) ? _pageNumberList[lastIndex] : highlightVal.PageNumber;
            const div = document.createElement('div');
            let _sectionData = highlightVal.SectionData
            if (_sectionData) {
                _sectionData = _sectionData.replace(/<br>·/g, '<br>');
            }
            div.innerHTML = _sectionData;
            const spans = div.getElementsByTagName('span');
            if (spans && spans.length > 0) {
                for (let span = 0; span < spans.length; span++) {
                    temp.push(spans[span].innerHTML);
                }
                return {
                    highlightVal: temp.join('').replace(/<(?:.|\n)*?>/gm, ' ').replace(/<(?:.|\n)*?>/gm, ' ').replace(/\s{2,}/g, ' ').trim(),
                    PageNumber: _pageNumber,
                    ansHighlight: temp.join('').replace(/<\/?(h6)\b[^<>]*>/g, "").trim(),
                    PageNumberList: (_pageNumberList.length > 0) ? _pageNumberList : []
                }
            }
            else {
                return {
                    highlightVal: _sectionData.replace(/<(?:.|\n)*?>/gm, ' ').replace(/<(?:.|\n)*?>/gm, ' ').replace(/\s{2,}/g, ' ').trim(),
                    PageNumber: _pageNumber,
                    ansHighlight: _sectionData.replace(/<\/?(h6)\b[^<>]*>/g, "").trim(),
                    PageNumberList: (_pageNumberList.length > 0) ? _pageNumberList : []
                }
            }

        })
        document.body.classList.add('custome-modal-open');
        this.setState({ showPreviewModel: true, highlightVals: highlightVals },
            () => {
                this.setState({ showPreview: true })
            })
    }
    handlePreviewClose = async () => {

        this.setState({ showPreviewModel: false, showPreview: false },
            async () => {
                setTimeout(() => {
                    document.body.classList.remove('custome-modal-open');
                }, 1000);
            })
    }
    renderModelPopup() {
        const { showPreviewModel, showPreview, highlightVals } = this.state;
        let { documentInfo, recommendationDocuments, sessionID, lastFeedbackID, lastDocumentInfo, lastQuestion } = this.props;
        return (<Modal
            show={showPreviewModel}
            size="xl"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="answering-popup-transition"
            className="z-index-preview"
            backdropClassName="z-index-preview-backdrop"
            scrollable={false}
        >
            <Modal.Body>
                <div style={{ overflow: "auto" }}>
                    <NlpDocumentPreview highlightVals={highlightVals} customWidth={this.state.customWidth} customHeight={this.state.customHeight} downloadUrl={documentInfo.downloadUrl}
                        nlpflag={true} docTitle={documentInfo.docTitle} sessionID={sessionID}
                        lastFeedbackID={lastFeedbackID} lastDocumentInfo={lastDocumentInfo} lastQuestion={lastQuestion}
                        searchID={documentInfo.searchID} searchKeyword={""} synonyms={[]} showPreview={showPreview} handleClose={this.handlePreviewClose} recommendationDocuments={recommendationDocuments} />
                </div>
            </Modal.Body>
        </Modal>)
    }
    handleOnClickReaction = async (_reaction) => {
        let previousReaction = JSON.parse(JSON.stringify(this.state.reaction));
        this.setState({ reaction: _reaction }, async () => {
            let { docTitle, documentNumber, downloadUrl, sectionAnswers, searchID } = this.props.documentInfo;
            let { question, sessionID } = this.props;
            let { feedbackID } = this.state;
            let { reaction } = this.state;
            let wwid = JSON.parse(localStorage.getItem('wwid'));
            let service = new ApiServices();
            if (sectionAnswers && sectionAnswers.length > 0) {
                let sectionAnswer = sectionAnswers[0];
                let mainAnswer = "", sectionanswer = "";
                if (sectionAnswer && sectionAnswer.sectionAnswer) {
                    mainAnswer = sectionAnswer.sectionAnswer.trim();
                    sectionanswer = sectionAnswer.SectionData.trim();
                    await service.insertUpdateAssistantFeedback(
                        feedbackID,
                        question,
                        mainAnswer,
                        documentNumber,
                        docTitle,
                        downloadUrl,
                        reaction,
                        previousReaction,
                        searchID,
                        sectionanswer,
                        sessionID,
                        wwid
                    ).then(async (response) => {
                        if (previousReaction === ReactNone) {
                            await this.pushRecommendations();
                        }
                    });
                }
            }
        });
    }
    pushRecommendations = async () => {
        let { feedbackID } = this.state;
        let { recommendationDocuments } = this.props;
        let assistantRecommendations = [];
        recommendationDocuments.map((item) => {
            let { docTitle, documentNumber, downloadUrl, searchID, section_output } = item;
            docTitle = docTitle.replace(/'/g, "''");
            documentNumber = documentNumber.replace(/'/g, "''");
            downloadUrl = downloadUrl.replace(/'/g, "''");
            let sectionTexts = [];
            section_output.map((highlightVal) => {
                sectionTexts.push(highlightVal.section_text)
            })
            let section_text = sectionTexts.join(sectionAnsSeparator);
            section_text = section_text.replace(/'/g, "''");
            searchID = searchID.replace(/'/g, "''");
            assistantRecommendations.push(`('${docTitle}', 
            '${downloadUrl}', 
            '${searchID}', 
            '${section_text}', '${documentNumber}', now(), '${feedbackID}',2)`)
        })
        if (assistantRecommendations && assistantRecommendations.length > 0) {
            let service = new ApiServices();
            let dbQuery = assistantRecommendations.join(',');
            await service.pushRecommendations(dbQuery)
        }
    }
    handleIsShowMore = (_isShowMore) => {
        this.setState({ isShowMore: _isShowMore });
    }
    indexData = (docTitle, searchID, url) => {
        const ts = new trackingService();
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'),
            localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'),
            localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'),
            localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'),
            localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'),
            `Preview Answering Document`, '',
            window.location.href,
            '',
            '',
            docTitle, '', '', searchID, url, '', '', `Preview Answering Document`, '', '', '', '', '', '', '', '');
    }
    render() {
        const { message, isSucceed } = this.props;
        const { reaction, isShowMore } = this.state;
        return (
            <>
                <div className="blk-lg-12 bkl-lg-12-assistant-ans">
                    <div className="imscb-conversation-container-right ">
                        <div className="cbm-msg-box vis imscb-custom-widget-background-color-secondary">
                            <div className="cbm-msg-text cbm-msg-content notranslate cbm-msg-text-wrapper" tabindex="-1">
                                {isSucceed ? <div className="message-text" dangerouslySetInnerHTML={{ __html: message }} /> :
                                    <>{message.includes('Could not get answer to the question') ?
                                        <div className="message-text" dangerouslySetInnerHTML={{ __html: 'Could not get answer to the question. Please click on Recommendations to view relevant documents that can answer the question.' }} /> :
                                        <>{isShowMore ?
                                            <>
                                                <div className="message-text" dangerouslySetInnerHTML={{ __html: message }} />
                                                <span className='nlp-show-more-less' onClick={() => this.handleIsShowMore(false)}>
                                                    Hide details
                                                </span>
                                            </>
                                            :
                                            <>
                                                <div className="message-text">Sorry, something went wrong.</div>
                                                <span className='nlp-show-more-less' onClick={() => this.handleIsShowMore(true)}>
                                                    See details
                                                </span>
                                            </>

                                        }
                                        </>}
                                    </>}

                                {/* {conversationIndex === 0 ? <>{message}</> : <Typewriter
                                    onInit={(typewriter) => {
                                        typewriter.typeString(message)
                                            .callFunction(() => {
                                                if (!isEmptyObject(this.props.documentInfo)) {
                                                    this.setState({ isShowPreviewButton: true });
                                                }
                                            })
                                            .start();
                                    }}
                                    options={{
                                        onCreateTextNode: this.customNodeCreator,
                                        delay: 0
                                    }}
                                />} */}

                                {((!isEmptyObject(this.props.documentInfo)) && isSucceed) && <button
                                    className="cbm-preview-button"
                                    onClick={(e) => { this.onPreviewClick(); this.indexData(this.props.documentInfo.docTitle, this.props.documentInfo.searchID, this.props.documentInfo.downloadUrl); }}>
                                    Preview
                                    {/* <Typewriter
                                        onInit={(typewriter) => {
                                            typewriter.typeString('Preview')
                                                .start();
                                        }}
                                        options={{
                                            onCreateTextNode: this.customNodeCreator,
                                            delay: 40
                                        }}
                                    /> */}


                                </button>}
                            </div>
                        </div>
                    </div>
                    {((!isEmptyObject(this.props.documentInfo)) && isSucceed) && <div className="imscb-conversation-container-right-reaction">
                        <button title="Like" class={reaction === ReactLike ? "btn btn-like-green" : "btn"} onClick={e => {
                            reaction === ReactLike ? this.handleOnClickReaction(ReactNone) : this.handleOnClickReaction(ReactLike)
                        }}><FontAwesomeIcon fixedWidth icon="thumbs-up" /></button>
                        <button title="Dislike" class={reaction === ReactUnlike ? "btn btn-unlike-red" : "btn"} onClick={e => {
                            reaction === ReactUnlike ? this.handleOnClickReaction(ReactNone) : this.handleOnClickReaction(ReactUnlike)
                        }}><FontAwesomeIcon fixedWidth icon="thumbs-down" /></button>
                    </div>}
                </div>
                {this.renderModelPopup()}
            </>
        )
    }
}
export default JanssenScienceAssistantAns;