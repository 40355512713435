import { Component } from "react";
import ApiServices from "../../utils/apiServices";
import { imsLoader, documents, documentIconBlack } from "../../assets";
import Button from '../../components/Button';
import MobileViewButton from "../../components/Button/MobileViewButton";

class DocumentTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCountry: [],
            selectedRegion: [],
            gdprAcceptance: '',
            profession: '',
            //availableAreas:[],
            products: [],
            contentPreference: '',
            documentPreference: [],
            allDocTypes: [],
            selectedClasses: [],
            selectedAreas: [],
            areaId: [],
            classId: [],
            productId: [],
            ischecked: false,
            showModal: false,
            isLoaded: false,
            history: this.props.history
        }
    }
    async componentDidMount() {
        const service = new ApiServices();
        var res = await service.getAllDocuments();
        //console.log(res);
        if (res) {
            this.setState({
                allDocTypes: res,
                selectedCountry: this.props.selectedCountry,
                selectedRegion: this.props.selectedRegion,
                gdprAcceptance: this.props.gdprAcceptance,
                profession: this.props.profession,
                products: this.props.products,
                contentPreference: this.props.contentPreference,
                documentPreference: this.props.documentPreference,
                selectedAreas: this.props.selectedAreas,
                selectedClasses: this.props.selectedClasses,
                showModal: this.props.showModal,
                isLoaded: true
            })
            if (this.props.areaId.length > 0) {
                this.setState({
                    areaId: this.props.areaId
                });
            }
            if (this.props.classId.length > 0) {
                this.setState({
                    classId: this.props.classId
                });
            }
            if (this.props.productId.length > 0) {
                this.setState({
                    productId: this.props.productId
                });
            }
        }

    }
    handleDocNxt = () => {
        //console.log('Inside Review')
        this.setState({
            showModal: true
        }, this.props.onClickNextToReview(this.state.selectedRegion, this.state.selectedCountry, this.state.gdprAcceptance, this.state.profession, this.state.products, this.state.contentPreference, this.state.documentPreference, this.state.selectedAreas, this.state.selectedClasses, true, this.state.history, this.state.areaId, this.state.classId, this.state.productId))
        //this.render(<ReviewModal/>);;
    }
    handleDocPrv = () => {
        //console.log(this.state.documentPreference);
        this.props.onClickPreviousToProducts(this.state.selectedRegion, this.state.selectedCountry, this.state.gdprAcceptance, this.state.profession, this.state.products, this.state.contentPreference, this.state.documentPreference, this.state.selectedAreas, this.state.selectedClasses, this.state.history, this.state.areaId, this.state.classId, this.state.productId);
    }
    handleDocumentSelect = (event) => {
        if (event.target.checked) {
            this.setState({
                documentPreference: this.state.documentPreference.concat([event.target.value])
            }, this.handleChangeinDocs)
        }
        else {
            var arr = [...this.state.documentPreference];
            var idx = arr.indexOf(event.target.value);
            if (idx !== -1) {
                arr.splice(idx, 1)
            }
            this.setState({
                documentPreference: arr
            }, this.handleChangeinDocs)
        }
    }
    allTypes = (documents) => {
        //console.log(documents);
        if (documents.length > 0) {
            return (
                documents.map((item, index) => {
                    //console.log(item.doctype);
                    if (this.state.documentPreference.includes(item.doctype)) {
                        return (
                            <div className="docDiv" key={index}>
                                {/* <img src={rightArrow} alt="arrow" className="documentsRightArrow" /> */}
                                <input type="checkbox" name="docs" className="docchckbox" value={item.doctype} key={item.id + Math.random()} onChange={this.handleDocumentSelect} checked={!this.state.ischecked} class="k-checkbox" />
                                <span className={this.state.ischecked ? "docchckboxName" : "docchckboxNameChecked"}>
                                    {item.doctype}
                                </span>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div className="docDiv" key={index}>
                                {/* <img src={rightArrow} alt="arrow" className="documentsRightArrow" /> */}
                                <input type="checkbox" name="docs" className="docchckbox" value={item.doctype} key={item.id + Math.random()} onChange={this.handleDocumentSelect} checked={this.state.ischecked} class="k-checkbox" />
                                <span className="docchckboxName">
                                    {item.doctype}
                                </span>
                            </div>
                        )
                    }
                })
            )
        }
    }
    handleChangeinDocs = () => {
        this.props.onChangeInDocPref(this.state.documentPreference)
    }
    render() {
        if (this.props.customWidth < 1000) {
            return (
                <div className="registrationInnerWrapper">
                    {/* <div className="welcomePageTitle">
                        </div> */}
                    {this.state.isLoaded ?
                        <>
                            <div className="documentSelectContent">
                                <div className="documentsTitlePart">
                                    <img src={documentIconBlack} alt="documents-icon" />
                                    <div className="productTitle">Select Your Document Types</div>
                                </div>
                                {/* <br /> */}
                                <div style={{ fontSize: "15px" }}>

                                    <b>
                                        Prioritize the content that is most useful to you!
                                    </b>
                                </div>
                                <p className="documentsContent">
                                    <br />
                                    Select the document types that are of most interest to you from the list below. This will ensure that your preferred content will be more prominent in your iMedical Search results. However, please note that your document preferences will not prevent you from seeing other types of content in your searches.
                                </p>
                                {/* </div>
                            <div className="documentSelectContent"> */}
                                <div style={{ padding: "1% 0 2% 0" }}>
                                    <p>
                                        {/* <br /> */}
                                        <b>
                                            Select your preferred document type(s) (choose as many as you like). If you do not have a preference, please skip this step.
                                        </b>
                                    </p>
                                    <div className="docTypesOuterWrapper">
                                        {/* <div className="topCircle"></div> */}
                                        {this.allTypes(this.state.allDocTypes)}
                                    </div>
                                    <br />
                                    {/* <br /> */}
                                    <div className="documentsContent">
                                        After making your selections, click the Continue button to submit your registration to the Global Search administration team for approval. You will receive an email once your registration is approved. Please note you will only have access to public ‘open’ content while your registration is being processed
                                    </div>
                                </div>
                                {this.props.customWidth < 600 ?
                                    <div className="gsf-popup-button-wrapper-mobile">
                                        <div className="welcomeNxtBtn-mobile">
                                            <MobileViewButton text='Continue' customColor='#2EB000' action={this.handleDocNxt} className="continueButton" />
                                        </div>
                                        <div className="welcomeNxtBtn-mobile">
                                            <MobileViewButton text='Previous' customColor='#FF8000' action={this.handleDocPrv} />
                                        </div>
                                        <div className="welcomeNxtBtn-mobile">
                                            <MobileViewButton text='Cancel' customColor='#0074B0' action={() => { this.props.history.push('/') }} />
                                        </div>
                                    </div>
                                    :
                                    <div className="buttonContainer">
                                        <div className="welcomeNxtBtn">
                                            <Button text='Continue' customColor='#2EB000' action={this.handleDocNxt} className="continueButton" />
                                        </div>
                                        <div className="welcomeNxtBtn">
                                            <Button text='Previous' customColor='#FF8000' action={this.handleDocPrv} />
                                        </div>
                                        <div className="welcomeNxtBtn">
                                            <Button text='Cancel' customColor='#0074B0' action={() => { this.props.history.push('/') }} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </>
                        :
                        <div style={{ textAlign: "center" }}>
                            <img src={imsLoader} alt="Loading Data" style={{ width: "12%", marginTop: "5%" }} />
                        </div>
                    }
                </div>
            )
        }
        else {
            return (
                <div className="registrationInnerWrapper">
                    {/* <div className="welcomePageTitle">
                    </div> */}
                    {this.state.isLoaded ?
                        <>
                            <div className="documentSelectContent">
                                <div className="documentsTitlePart">
                                    <img src={documents} alt="documents-icon" />
                                    <div className="productTitle">Select Your Document Types</div>
                                </div>
                                {/* <br /> */}
                                <div style={{ fontSize: "15px" }}>

                                    <b>
                                        Prioritize the content that is most useful to you!
                                    </b>
                                </div>
                                <p className="documentsContent">
                                    <br />
                                    Select the document types that are of most interest to you from the list below. This will ensure that your preferred content will be more prominent in your iMedical Search results. However, please note that your document preferences will not prevent you from seeing other types of content in your searches.
                                </p>
                            </div>
                            <div className="documentSelectContent">
                                <div style={{ padding: "1% 0 2% 0" }}>
                                    <p>
                                        {/* <br /> */}
                                        <b>
                                            Select your preferred document type(s) (choose as many as you like). If you do not have a preference, please skip this step.
                                        </b>
                                    </p>
                                    <div className="docTypesOuterWrapper">
                                        {/* <div className="topCircle"></div> */}
                                        {this.allTypes(this.state.allDocTypes)}
                                    </div>
                                    <br />
                                    {/* <br /> */}
                                    <div className="documentsContent">
                                        After making your selections, click the Continue button to submit your registration to the Global Search administration team for approval. You will receive an email once your registration is approved. Please note you will only have access to public ‘open’ content while your registration is being processed
                                    </div>
                                </div>
                                <div className="buttonContainer">
                                    <div className="welcomeNxtBtn">
                                        <Button text='Continue' customColor='#2EB000' action={this.handleDocNxt} className="continueButton" />
                                    </div>
                                    <div className="welcomeNxtBtn">
                                        <Button text='Previous' customColor='#FF8000' action={this.handleDocPrv} />
                                    </div>
                                    <div className="welcomeNxtBtn">
                                        <Button text='Cancel' customColor='#0074B0' action={() => { this.props.history.push('/') }} />
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <div style={{ textAlign: "center" }}>
                            <img src={imsLoader} alt="Loading Data" style={{ width: "12%", marginTop: "5%" }} />
                        </div>
                    }
                </div>
            )
        }
    }
}
export default DocumentTypes;