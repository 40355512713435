import { Component } from "react";
import MIAsReferencesDocumentData from "./MIAsReferencesDocumentData";
import { collapseIconNew, expandIconNew } from "../../assets";
import MIAsReferencesActionBar from "./MIAsReferencesActionBar";

class MIAsReferencesDocumentCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            actionBarCollapsed: false,
            documentIndex: this.props.count,
            item: [],
        }

    }
    componentDidMount() {
        this.setState({
            item: this.props.item,
            collapsed : this.props.globalCollapseAll
        })
        //console.log(this.props.item);
        //console.log(this.state.item)
    }
    componentDidUpdate(prevProps){
        if(this.props.globalCollapseAll !== prevProps.globalCollapseAll){
            this.setState({collapsed:this.props.globalCollapseAll})
        }
    }
    componentWillReceiveProps () {
        if (this.props.collapsedAll !== undefined) {
            let data = this.props.collapsedAll;
            let index = this.props.resultsIndex;
            if (data[index]) {
                this.setState({ collapsed: data[index] })
            }
            else {
                this.setState({ collapsed: data[index] })
            }
        }
    }
    handleExpandCollapse = () => {
        const collapsed= !this.state.collapsed;
        this.setState({ collapsed: !this.state.collapsed })
        if(window.location.pathname === '/ReferencesUsed'){
            this.props.manageCollapseAndExpand(collapsed,this.props.searchID)
        }
        else if (window.location.pathname === '/viewmarked') {
            this.props.handlecollapsed()
        }
    }
    handelCitationSelect = (documentIndex, item, itemTeaser) => {
        // console.log(documentIndex)
        this.props.handleCitationSelection(documentIndex, item, itemTeaser);
    }
    handleUnpin=(item)=>{
        this.props.handleUnpin(item);
    }
    citationUrl = (url) => {
        this.props.citationUrl(url)
    }
    render() {
        return (
            <div className="resultsDetailsDivWrapper">
                <div /* style={{ width: this.props.customWidth <= 600 ? (this.props.customWidth < 450 ? "7%" : "5%") : "4%", float: "left", paddingTop: this.props.customWidth <= 800 ? (this.props.customWidth <= 600 ? "1%" : "0.8%") : "0.6%" }} */ className="resultDiv-left-outerwrapper">
                    <span className="resultsDivLeftWrapper">
                        <input type="checkbox" value={this.state.item.docTitle} key={this.state.item.docID + Math.random()} onChange={() => this.handelCitationSelect(this.state.documentIndex, this.props.item, this.props.itemTeaser)} checked={this.props.isChecked} style={{cursor:"pointer",width:"14px",height:"14px"}} /* class="k-checkbox" */ />
                        <img src={this.state.collapsed ? expandIconNew : collapseIconNew} alt="expand/collapse-icon" width={14} height={14} onClick={this.handleExpandCollapse} style={{marginTop:this.props.customWidth < 600 ?"35%":null, cursor:"pointer"}} />
                    </span>
                </div>
                <div className="citation-outer-wrapper">
                    <div style={{ float: "left", width: "100%" }}>
                        <MIAsReferencesDocumentData documentIndex={this.state.documentIndex} item={this.props.item} collapsed={this.state.collapsed} appliedFilters={this.props.appliedFilters} searchKeyword={this.props.searchKeyword} pageNumber={this.props.pageNumber} pageSize={this.props.pageSize} itemTeaser={this.props.itemTeaser} customWidth={this.props.customWidth} isCollapseAll={this.props.isCollapseAll} csPath={this.props.csPath} csproduct={this.props.csproduct} citationUrl={this.citationUrl} />
                    </div>
                </div>
                <div className="results-div-action-bar-wrapper">
                    <MIAsReferencesActionBar actionBarCollapsed={this.state.actionBarCollapsed} item={this.props.item} appliedFilters={this.props.appliedFilters} searchKeyword={this.props.searchKeyword} pageNumber={this.props.pageNumber} pageSize={this.props.pageSize} handleSelect={this.handleToggleSelect} customWidth={this.props.customWidth} isCollapseAll={this.props.collapsed} collapsed={this.state.collapsed} csPath={this.props.csPath} csproduct={this.props.csproduct} handleUnpin={this.handleUnpin}/>
                </div>
            </div>


        )
    }

}
export default MIAsReferencesDocumentCard;