/* eslint-disable import/no-anonymous-default-export */
import { desArrow } from '../../assets'
import { useState } from 'react';
import GenerateArrayStrings, { getVarType } from '../../components/GenerateArrayStrings'

const formattedStringArr = (array, fn) => {
    var result = [];
    for (var i = 0; i < array.length; i++) {
        var mapping = fn(array[i]);
        result = result.concat(mapping);
    }
    return result;
}
const divider = () => {
    return (
        <div className="document-descriptor-divider"></div>
    )
}
const textFormatter = (data) => {
    const replacedString = data.replaceAll("%%##%%##", '%%##').replaceAll("%%##", '\n');
    //console.log(newString)
    const updatedText = formattedStringArr(replacedString.split('\n'), function (part) {
        return [part, <div>{divider()}</div>];
    });
    updatedText.pop()
    return (
        <div>
            {updatedText}
        </div>
    )
}
const renderForPubmed = (descriptorsTitle, data) => {
    let dataDescriptors = '';
    if (getVarType(data) === 'Array') {
        dataDescriptors = GenerateArrayStrings(data)
    }
    else {
        dataDescriptors = data;
    }
    return (
        <div>
            <div className="document-descriptor-title">
                <b><label>{descriptorsTitle}: </label></b>
            </div>
            <span>{dataDescriptors}</span>
        </div>
    )
}

export default function (props) {

    const [isOpen, setOpenDescriptor] = useState(false);
    const {
        docItemDescription, meshHeading, publicationType, substances, isPubMedHtml
    } = props
    if (window.location.pathname !== '/pubMedResults' && !isPubMedHtml) {
        return (
            <div className="document-descriptor-wrapper">
                <div className="document-descriptor-header-wrapper" onClick={e => setOpenDescriptor(!isOpen)}>
                    <img src={desArrow} alt='Document Item Description' className={isOpen ? "document-descriptor-img-rotate" : "document-descriptor-img"} onClick={e => setOpenDescriptor(!isOpen)} />
                    <span> Document Item Description</span>
                </div>
                {isOpen &&
                    <div className="document-descriptor-text-wrapper">
                        {textFormatter(docItemDescription)}
                    </div>
                }
            </div>
        )
    } else {
        return (
            <div className="document-descriptor-wrapper" onClick={e => setOpenDescriptor(!isOpen)}>
                <div className="document-descriptor-header-wrapper">
                    <img src={desArrow} alt='Document Item Description' className={isOpen ? "document-descriptor-img-rotate" : "document-descriptor-img"} />
                    <span> Document Item Description</span>
                </div>
                {isOpen &&
                    <>
                        <div className="document-descriptor-text-wrapper">
                            {(publicationType && (getVarType(publicationType) !== 'Array' || (getVarType(publicationType) === 'Array' && publicationType.length > 0))) &&
                                <>
                                    {renderForPubmed('Publication type', publicationType)}
                                </>
                            }

                            {(meshHeading && (getVarType(meshHeading) !== 'Array' || (getVarType(meshHeading) === 'Array' && meshHeading.length > 0))) &&
                                <>
                                    {(publicationType && (getVarType(publicationType) !== 'Array' || (getVarType(publicationType) === 'Array' && publicationType.length > 0)))
                                        && divider()
                                    }
                                    {renderForPubmed('MeSH terms', meshHeading)}
                                </>
                            }
                            {(substances && (getVarType(substances) !== 'Array' || (getVarType(substances) === 'Array' && substances.length > 0))) &&
                                <>
                                    {(meshHeading && (getVarType(meshHeading) !== 'Array' || (getVarType(meshHeading) === 'Array' && meshHeading.length > 0))) && divider()}
                                    {renderForPubmed('Substances', substances)}
                                </>
                            }
                        </div>
                    </>
                }

            </div>
        )
    }

}