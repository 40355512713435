/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Modal } from "react-bootstrap";
// import { useHistory } from "react-router-dom";
import { popCancelBttn, saveBlueIcon, printBlueIcon, emailBlueIcon, downloadBlueIcon, saveWhiteIcon, printWhiteIcon, emailWhiteIcon, downloadWhiteIcon, IMSLogo } from "../../assets";
import React, { useEffect, useState } from "react";
import Button from "../Button";
import DisclaimerPop from "./DisclaimerPop";
import SavePopUpModal from "./GSFSaveSubModal";
import DocumentProcessing from './DocumentProcessing'
import EmailProcessingPopUpModal from "./EmailProcessing";
import DownloadProcessingPopUpModal from "./DownloadProcessing";
import './GlobalSearchFeaturesPopUp.css'
// import jsPDF from "jspdf";
// import * as html2canvas from "html2canvas";
import * as FileSaver from 'file-saver';
import ReactDOMServer from "react-dom/server"
import MIAsReferencesHtmlView from "../../content/Pages/MIAsREferencesHtmlView";
import MobileViewButton from "../Button/MobileViewButton";
import { v4 as uuidv4 } from 'uuid';
import ApiServices from '../../utils/apiServices';
import axios from "axios";
import trackingService from "../../services/trackingService";
import { useReactToPrint } from "react-to-print";
import { LocationOrigin } from "../../utils/utilityServices";
import { getVarType } from "../GenerateArrayStrings"
import AlertMessagePopUpModal from "./AlertMessagePopUpModal";
import moment from 'moment';
// const chunkSize = 1048576 * 3;//its 3MB, increase the number measure in mb
const chunkSize = 50000;//its 3MB, increase the number measure in mb
// const chunkSize = 5
export default function MIAsRefGlobalSearchFeaturePopUpModal(props) {
    const {
        height,
        showModal,
        handleClose,
        selectedResults,
        customWidth,
        documentsUrl,
        markedDocumentsUrl,
        itemsPerPage
    } = props
    const [showPop, setShow] = useState(showModal)
    const [showDisclaimer, setDisclaimer] = useState(false);
    //To create the pdf html file 
    // const [pdfHtml, setPdf] = useState(false)
    // const [showPrintPopUp, setShowPrintPopUp] = useState(false)
    const [isSaveModal, setIsSaveModal] = useState();
    const [isPrintModal, setIsPrintModal] = useState();
    const [isEmailModal, setIsEmailModal] = useState();
    const [isDownloadModal, setDownloadModal] = useState();
    const [saveResult, setSaveResult] = useState('Marked Results');
    const [saveFields, seSavetFields] = useState('Full Citation');
    const [saveFormat, setSaveFormat] = useState('Excel');
    const [printResult, setPrintResult] = useState('Marked Results');
    const [printFields, setPrintFields] = useState('Title Only');
    const [emailResult, setEmailResult] = useState('Marked Results');
    const [emailFields, setEmailFields] = useState('Title Only');
    const [downloadResult, setDownloadResult] = useState('Marked Results');
    const [showSaveToExcel, setSaveToExcel] = useState(false);
    const [docProcessing, setDocProcessing] = useState(false);
    const [emailProcessing, setEmailProcessing] = useState(false);
    const [isDownloadProceed, setIsDownloadProceed] = useState(false);
    const [downloadData, setDownloadData] = useState([]);
    const [downloadZipProcessing, setDownloadZipProcessing] = useState(false);
    const [uniqueId, setUniqueId] = useState(0)
    const componentRef = React.useRef(null);
    // var uniqueId = Math.floor(100000 + Math.random() * 900000);

    // const [showProgress, setShowProgress] = useState(false)
    // let [counter, setCounter] = useState(1)
    const [fileToBeUpload, setFileToBeUpload] = useState({})
    let [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0)
    let [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize)
    // const [progress, setProgress] = useState(0)
    // const [fileGuid, setFileGuid] = useState("")
    const [fileSize, setFileSize] = useState(0)
    const [chunkCount, setChunkCount] = useState(0)

    // const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    // const fileExtension = '.xlsx';
    const [alertMessageModelColor, setAlertMessageModelColor] = useState("")
    const [alertMessageContent, setAlertMessageContent] = useState("")
    const [alertMessageShowModal, setAlertMessageShowModal] = useState(false)
    const errorAlertMessage = (message) => {
        setAlertMessageContent(message)
        setAlertMessageModelColor("#de1327")
        setAlertMessageShowModal(true)
    }
    const closeAlertMessage = () => {
        setAlertMessageContent("")
        setAlertMessageModelColor("")
        setAlertMessageShowModal(false)
    }
    let counter = 1;
    useEffect(() => {
        if (fileSize > 0) {
            fileUpload(counter);
        }
    }, [fileToBeUpload])
    async function getFileContext(wwid, username, html, currentDate, searchId) {
        resetChunkProperties();
        setFileSize(html.length)
        const _totalCount = html.length % chunkSize === 0 ? html.length / chunkSize : Math.floor(html.length / chunkSize) + 1;
        setChunkCount(_totalCount)
        setFileToBeUpload(html)
    };
    const resetChunkProperties = () => {
        // setShowProgress(true)
        // setProgress(0)
        // setCounter(1)
        setBeginingOfTheChunk(0)
        setEndOfTheChunk(chunkSize)
    }
    const fileUpload = async () => {
        // setCounter(counter + 1);
        var guid = uuidv4();
        let currentDate = generateDownloadTimestamp()
        let wwid = JSON.parse(localStorage.getItem('wwid'))
        let username = JSON.parse(localStorage.getItem('username'));
        let searchId = `${guid}_${currentDate}`
        setEmailProcessing(true);
        // if (counter <= chunkCount) {
        for (counter; counter <= chunkCount; counter = counter + 1) {
            var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
            var payload = {
                "wwid": wwid,
                "username": username,
                "htmldocument": chunk.replace(/"/g, '\\"'),
                "date_added": currentDate,
                "date_modified": currentDate,
                "search_id": searchId,
            }
            //   uploadChunk(chunk)
            // uploadChunk(payload,()=>{
            //     fileToBeUpload.append(payload)
            // })
            // beginingOfTheChunk = endOfTheChunk
            // endOfTheChunk = endOfTheChunk + chunkSize
            // let chunkData = uploadChunk(payload)
            // if (chunkData.length > 0) {
            //     beginingOfTheChunk = endOfTheChunk
            //     endOfTheChunk = endOfTheChunk + chunkSize
            //     continue;
            // }

            try {
                const response = await axios.post(
                    `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}db/emlst`,
                    payload,
                    {
                        headers: { "Content-Type": "application/json" },
                    }
                );
                const data = response.data;
                // console.log("data", data)

                if (data.rowCount === 1) {
                    // setBeginingOfTheChunk(endOfTheChunk);
                    // setEndOfTheChunk(endOfTheChunk + chunkSize);
                    // if (counter == chunkCount) {
                    if (counter <= chunkCount) {
                        console.log("Process is complete, counter", counter);
                        //   await uploadCompleted(chunk);
                    }
                } else {
                    console.log("Error Occurred:", data);
                }
            } catch (error) {
                console.log("error", error);
            }
            beginingOfTheChunk = endOfTheChunk
            endOfTheChunk = endOfTheChunk + chunkSize
            if (counter === chunkCount) {
                setEmailProcessing(false);
                handleEmail(searchId)
            }
        }
    }
    //     const uploadChunk = async (chunk) => {
    //         // wwid, username, html.replace(/"/g, '\\"'), currentDate, currentDate, searchId
    //         let titleOnlyEmail = false
    //         if (emailFields === 'Title Only') {
    //             titleOnlyEmail = true
    //         }
    //         const html = ReactDOMServer.renderToString(<MIAsReferencesHtmlView selectedResults={selectedResults} titleOnly={titleOnlyEmail} citationType={emailResult} />);
    //         var guid = uuidv4();
    //         let currentDate = generateDownloadTimestamp()
    //         let wwid = JSON.parse(localStorage.getItem('wwid'))
    //         let username = JSON.parse(localStorage.getItem('username'));
    //         let searchId = `${guid}_${currentDate}`
    //         let payload = {
    //             "wwid": wwid,
    //             "username": username,
    //             "htmldocument": html.replace(/"/g, '\\"'),
    //             "date_added": currentDate,
    //             "date_modified": currentDate,
    //             "search_id": searchId,
    //         }
    //         try {
    //             const response = await axios.post(
    //                 "https://predev-ims.jnj.com/api/db/emlst",
    //                 chunk,
    //                 {
    //                     // params: payload,
    //                     //   params: {

    //                     //     // id: counter,
    //                     //     // fileName: fileGuid,
    //                     //   },
    //                     headers: { "Content-Type": "application/json" },
    //                 }
    //             );
    //             const data = response.data;
    //             // console.log("data", data)
    //             //   if (data.isSuccess) {
    //             if (data.rowCount == 1) {
    // // console.log(counter)


    //                 // setBeginingOfTheChunk(endOfTheChunk);
    //                 // setEndOfTheChunk(endOfTheChunk + chunkSize);
    //                 // console.log(endOfTheChunk, endOfTheChunk + chunkSize,)
    //                 // counter++
    //                 // if (counter == chunkCount) {
    //                     if (counter <= chunkCount) {

    //                     console.log("Process is complete, counter", counter);
    //                     return response
    //                     // count++
    //                     // counter++
    //                     //   await uploadCompleted(chunk);
    //                     // chunk.append(...chunk)
    //                     // append(...data)
    //                 } /* else {
    //               var percentage = (counter / chunkCount) * 100;
    //             //   setProgress(percentage);
    //             } */
    //             } else {
    //                 console.log("Error Occurred:", data);
    //             }
    //         } catch (error) {
    //             console.log("error", error);
    //         }
    //     };

    useEffect(() => {
        if (selectedResults.length > 0) {
            setSaveResult('Marked Results');
            setEmailResult('Marked Results');
            setDownloadResult('Marked Results');
            setPrintResult('Marked Results')
        }
        else {
            setSaveResult('Displayed Results');
            setEmailResult('Displayed Results');
            setDownloadResult('Displayed Results');
            setPrintResult('Displayed Results')
        }
    }, [selectedResults])
    const generateDownloadTimestamp = () => {
        const now = new Date()
        return now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate() + "_" + now.getHours() + "-" + now.getMinutes() + "-" + now.getSeconds()
    }
    const downloadTxtFile = () => {
        const element = document.createElement("a");
        const file = new Blob([document.getElementById('pdf-doc-to-print').value], { type: 'text/plain;charset=utf-8' });
        element.href = URL.createObjectURL(file);
        let txtfileName = "iMS_Results_Download_" + generateDownloadTimestamp() + ".txt"
        element.download = txtfileName;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }
    // const MyDocument = () => {
    //     return (
    //         <a href="">Link</a>
    //         // <div><a href="">Link</a></div>
    //         // <Document>
    //         //     <Page size="A4" style={styles.page}>
    //         //         <View>
    //         //             <div className="bannerImage">
    //         //                 <div className="headerTitle" style={{ alignItems: "center", textAlign: "center" }}>
    //         //                     <img src={IMSLogo} alt="iMedical Search" style={{ width: "20%", paddingTop: "2%" }} />
    //         //                 </div>
    //         //             </div>
    //         //         </View>
    //         //     </Page>
    //         // </Document>
    //     );
    // }
    // const savePDF = () => {
    //     setDocProcessing(true);
    //     const fileName = "iMS_Results_Download_" + generateDownloadTimestamp() + ".pdf"
    //     const doc = new jsPDF('p', 'mm');
    //     // let titleOnly = false
    //     // if (saveFields === 'Title Only') {
    //     //     titleOnly = true
    //     // }
    //     // doc = ReactDOMServer.renderToString(<MIAsReferencesHtmlView selectedResults={selectedResults} titleOnly={titleOnlyEmail} citationType={emailResult} />);
    //     const elem = document.getElementById('pdf-doc-to-print')
    //     html2canvas(elem, {
    //         onclone: function (clonedDoc) {
    //             clonedDoc.getElementById('pdf-doc-to-print').style.display = 'block';
    //         }
    //     }).then((canvas) => {
    //         const data = canvas.toDataURL('image/png');
    //         var position = 0;
    //         var imgWidth = 210;
    //         var pageHeight = 295;
    //         var imgHeight = canvas.height * imgWidth / canvas.width;
    //         var heightLeft = imgHeight;
    //         doc.addImage(data, 'PNG', 0, position, imgWidth, imgHeight);
    //         heightLeft -= pageHeight;
    //         //doc.add
    //         while (heightLeft >= 0) {
    //             position = heightLeft - imgHeight;
    //             doc.addPage();
    //             doc.addImage(data, 'PNG', 0, position, imgWidth, imgHeight);
    //             heightLeft -= pageHeight;
    //         }
    //         // doc.html(ReactDOMServer.renderToString(<MIAsReferencesHtmlView selectedResults={selectedResults} titleOnly={titleOnly} citationType={saveResult} />), {
    //         //     callback: function (doc) {
    //         //          doc.save(fileName);
    //         //       }
    //         //   });
    //         doc.save(fileName)
    //         setDocProcessing(false)
    //     })
    // }
    useEffect(() => {
        if (props.showSaveModal) {
            setIsSaveModal(props.showSaveModal)
            // setIsEmailModal(false)
            // setIsPrintModal(false)
            // setDownloadModal(false)
        }
        // else{
        //     setIsSaveModal(false)
        // }
        else if (props.showPrintModal) {
            setIsPrintModal(props.showPrintModal)
            // setIsSaveModal(false)
            // setIsEmailModal(false)
            // setDownloadModal(false)
        }
        else if (props.showEmailModal) {
            setIsEmailModal(props.showEmailModal)
            // setIsSaveModal(false)
            // setIsPrintModal(false)
            // setDownloadModal(false)
        }
        else if (props.showDownloadModal) {
            setDownloadModal(props.showDownloadModal)
            // setIsSaveModal(false)
            // setIsPrintModal(false)
            // setIsEmailModal(false)
        }
        if (selectedResults.length <= 0) {
            //console.log('No Selected Results')
            setSaveResult('Displayed Results')
        }
        //console.log(props.showSaveModal, props.showPrintModal, props.showEmailModal, props.showDownloadModal,selectedResults)
    }, [])
    useEffect(() => {
        if (isSaveModal && saveFormat === 'Excel') {
            seSavetFields('Full Citation')
        }
    }, [saveFormat])
    // useEffect(() => {
    //     setIsPrintProceed(true)
    // }, [isPrintProceed])
    const onSaveClick = () => {
        setIsSaveModal(true);
        setIsPrintModal(false);
        setIsEmailModal(false);
        setDownloadModal(false);
    }
    const onPrintClick = () => {
        setIsSaveModal(false);
        setIsPrintModal(true);
        setIsEmailModal(false);
        setDownloadModal(false);
    }
    const onEmailClick = () => {
        setIsSaveModal(false);
        setIsPrintModal(false);
        setIsEmailModal(true);
        setDownloadModal(false);
    }
    const onDownloadClick = () => {
        setIsSaveModal(false);
        setIsPrintModal(false);
        setIsEmailModal(false);
        setDownloadModal(true);
    }
    const handleOnChangeSaveResult = (event) => {
        setSaveResult(event.target.value)
    }
    const handleOnChangeSaveFieldstoInclude = (event) => {
        seSavetFields(event.target.value)
    }
    const handleOnChangeSaveFormat = (event) => {
        setSaveFormat(event.target.value)
    }
    const handleOnChangePrintResult = (event) => {
        setPrintResult(event.target.value)
    }
    const handleOnChangePrintFieldstoInclude = (event) => {
        setPrintFields(event.target.value)
    }
    const handleOnChangeEmailResult = (event) => {
        setEmailResult(event.target.value)
    }
    const handleOnChangeEmailFieldstoInclude = (event) => {
        setEmailFields(event.target.value)
    }
    const handleOnChangeDownloadResult = (event) => {
        setDownloadResult(event.target.value)
    }
    const onClickProceed = async () => {
        let width = 0.80 * window.innerWidth
        let height = 0.85 * window.innerHeight
        let left = 0.10 * window.innerWidth
        let top = 0.10 * window.innerHeight
        let titleOnly = false
        let titleOnlyEmail = false
        let displayResults = JSON.parse(localStorage.getItem('displayedResults'))
        if (saveFields === 'Title Only') {
            titleOnly = true
        }
        if (emailFields === 'Title Only') {
            titleOnlyEmail = true
        }
        if (isSaveModal) {
            if (saveFormat === "Excel") {
                if ((selectedResults && selectedResults.length > 0) || displayResults) {
                    setSaveToExcel(true);
                }
                else {
                     errorAlertMessage("No content to save as excel")
                }
            }
            else if (saveFormat === "Pdf") {
                if ((selectedResults && selectedResults.length > 0) || displayResults) {
                    setDocProcessing(true);
                    const fileName = "iMS_Results_Download_" + generateDownloadTimestamp() + ".pdf"
                    const html = ReactDOMServer.renderToString(<MIAsReferencesHtmlView selectedResults={selectedResults} citationType={saveResult} titleOnly={titleOnly} />);
                    let service = new ApiServices();
                    await service.preparePDFDocument(html).then((res) => {
                        const data = new Blob([res.data], { type: 'application/pdf' });
                        FileSaver.saveAs(data, fileName);
                        setDocProcessing(false)
                    }).catch((error) => {
                        console.log(error);
                        setDocProcessing(false)
                    });
                } else {
                     errorAlertMessage("No content to save as pdf")
                }
                //savePDF();
            }
            else if (saveFormat === "HTML") {
                if ((selectedResults && selectedResults.length > 0) || displayResults) {
                    let newWindow = window.open(window.location.origin + '/MIAsRefDownload', '_blank', `width=${width},height=${height},top=${top},left=${left},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no`)
                    newWindow.citationType = saveResult;
                    newWindow.selectedResults = selectedResults;
                    newWindow.titleOnly = titleOnly;
                }
                else {
                     errorAlertMessage("No content to save as html")
                }
            }
            else {
                downloadTxtFile()
            }
            // if (saveFormat !== "Excel") {
            handelSaveExportDocsTypes(saveFormat)
            // }
        }
        if (isPrintModal) {
            if ((selectedResults && selectedResults.length > 0) || displayResults) {
                handelPrintDocs()
                handlePrint()
            }
            else {
                 errorAlertMessage("No content available to Print")
            }
        }
        if (isEmailModal) {
            if ((selectedResults && selectedResults.length > 0) || displayResults) {
                const html = ReactDOMServer.renderToString(<MIAsReferencesHtmlView selectedResults={selectedResults} titleOnly={titleOnlyEmail} citationType={emailResult} />);
                // var result = html.replace(/"/g, "'").replace(/>\s+|\s+</g, function(m) {
                //     return m.trim();
                // });

                // console.log(html.replace(/"/g, '\\"'))
                // console.log(html.replace(/"/g, "'").replace(/>\s+</g, "><")) //replace(/"/g, "'")   replace(/"/g, '\'')
                var guid = uuidv4();
                let currentDate = generateDownloadTimestamp()
                // console.log(`${guid}_${currentDate}`)
                let service = new ApiServices();
                let wwid = JSON.parse(localStorage.getItem('wwid'))
                // let wwid = parseInt(localStorage.getItem('wwid').replace(/"/g, ''));
                let username = JSON.parse(localStorage.getItem('username'));
                let searchId = `${guid}_${currentDate}`
                let res = await service.getEmailDocumentById(username);
                let date = [];
                let newdate = [];
                let expiryDate = [];
                // var date = new Date(res[0].date_added.split('_')[0])
                // var newDate = new Date(date.setMonth(date.getMonth() + 1));
                // let expiryDate = new Date(new Date(newDate).setMonth(new Date(newDate).getMonth() + 2))
                // const convertStringToByteArray = (str) => {
                //     String.prototype.encodeHex = function () {
                //         var bytes = [];
                //         for (var i = 0; i < this.length; ++i) {
                //             bytes.push(this.charCodeAt(i));
                //         }
                //         return bytes;
                //     }

                //     var byteArray = str.encodeHex();
                //     // console.log(byteArray)
                //     return byteArray
                // }
                // WORKING START DELETE
                for (let index = 0; index < res.length; index++) {
                    // date = date.concat(dateFormate(new Date(res[index].date_added.split('_')[0])))
                    date = new Date(res[index].date_added.split('_')[0]);
                    newdate = new Date(date.setMonth(date.getMonth() + 1));
                    // expiryDate=new Date(new Date(newdate).setDate(new Date(newdate).getDate() + 2))
                    expiryDate = new Date(new Date(newdate).setMonth(new Date(newdate).getMonth() + 2))
                    if (expiryDate <= new Date()) {
                        await service.deleteEmailDocumentBy(username, res[index].search_id)
                    }
                    // else{
                    //     console.log(expiryDate,new Date())
                    // }
                }
                // WORKING END DELETE
                // WORKING START
                getFileContext(wwid, username, html, currentDate, searchId);
                handelEmailDocsTypes(searchId);
                // WORKING END



                // console.log(res,expiryDate,newdate,expiryDate<=new Date())
                // if(expiryDate>=new Date()){
                //     console.log("hi")
                // await service.deleteEmailDocumentBy(wwid,res[0].search_id)
                //    let resData = await service.deleteEmailDocumentBy(wwid,res[0].search_id)
                //    console.log(resData)
                // }
                // async () => {
                // WORKING
                // let data = await service.insertEmailDocument(wwid, username, html.replace(/"/g, '\\"'), currentDate, currentDate, searchId);
                // console.log(data, "xx")
                // }

                // handleEmail(searchId)
            }
            else {
                 errorAlertMessage("No content available to share (Email)")
            }
        }
        if (isDownloadModal) {
            // console.log(documentsUrl)
            let urls;
            if (downloadResult === 'Marked Results') {
                urls = markedDocumentsUrl;
            }
            else {
                urls = documentsUrl;
            }
            if (urls && urls.length > 0 && ((selectedResults && selectedResults.length > 0) || displayResults)) {
                let noOfDocumentPerZip = 25;
                let postBodys = [];
                for (let i = 1; i <= Math.ceil(urls.length / noOfDocumentPerZip); i++) {
                    let url = urls.slice(((i - 1) * noOfDocumentPerZip), (((i - 1) * noOfDocumentPerZip) + noOfDocumentPerZip));
                    postBodys.push(url);
                    // console.log(url, postBodys);
                }
                // uniqueId = Math.floor(100000 + Math.random() * 900000);
                setUniqueId(Math.floor(100000 + Math.random() * 900000))
                setIsDownloadProceed(true)
                setDownloadData(postBodys)
            }
            else {
                 errorAlertMessage("No file to download")
            }
        }
    }
    const proceedDownload = (data) => {
        let displayName = JSON.parse(localStorage.getItem("userdisplayName")).replace(/\s/g, '');
        //let currentDate = new Date().toString("dMMMyyyy");
        let currentDate = moment().format("DMMMYYYY");
        if (data.length > 0) {
            return (
                <>
                    {
                        data.map((urls, index) => {
                            return (
                                <div>
                                    {`${displayName}_${currentDate + uniqueId}_${index + 1}.zip `}
                                    <span className="download-zip-link" onClick={() => downloadZip(index)}>Download</span><br />
                                </div>
                            )
                        })
                    }
                </>
            )
        }
    }
    const downloadZip = async (index) => {
        let res;
        let body = {
            urls: downloadData[index]
        }
        let displayName = JSON.parse(localStorage.getItem("userdisplayName")).replace(/\s/g, '');
        //let currentDate = new Date().toString("dMMMyyyy");
        let currentDate = moment().format("DMMMYYYY");
        const fileName = `${displayName}_${currentDate + uniqueId}_${index + 1}.zip`;
        let service = new ApiServices();
        setDownloadZipProcessing(true)
        handelDownloadDocs(fileName)
        await service.downloadZipFile(body)
           /*  await axios.post(
                // `${LocationOrigin}${process.env.REACT_APP_IMS_API_URL}?dbquery=${dbquery}`,
                'http://localhost:8080/download',
                body,
                {
                    responseType: 'blob',
                }
            ) */.then((response) => {
            res = response.data
            if (res) {
                setDownloadZipProcessing(false)
            }
            const data = new Blob([res], { type: 'application/zip' });
            FileSaver.saveAs(data, fileName);
            //this.logService(`API call successful for dbquery:${dbquery}`,'info')
        }).catch((error) => {
            console.log(error)
            setDownloadZipProcessing(false)
            // this.logService(`API call failed for dbquery:${dbquery}`,'error')
        })
    }
    const downloadAll = () => {
        let userId = localStorage.getItem('username').toLowerCase().replace(/"/g, '').split('@')[0]
        let newWindow = window.open(window.location.origin + `/docs?UserId=${userId}`, '_blank');
        newWindow.citationType = downloadResult;
        newWindow.selectedResults = markedDocumentsUrl;
        newWindow.displayResults = documentsUrl;
        handelDownloadAllDocs()
    }
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,//reactToPrintContent,
        documentTitle: `iMS_Documents_${generateDownloadTimestamp()}`,
        // onBeforeGetContent: handleOnBeforeGetContent,
        // onBeforePrint: handleBeforePrint,
        // onAfterPrint: handleAfterPrint,
        removeAfterPrint: true
    });
    let titleOnlyPrint = false
    if (printFields === 'Title Only') {
        titleOnlyPrint = true
    }
    const onViewClick = () => {
        let titleOnly = false
        if (saveFields === 'Title Only') {
            titleOnly = true
        }
        let newWindow = window.open(window.location.origin + '/Excel-View', '_blank')
        newWindow.citationType = saveResult;
        newWindow.selectedResults = selectedResults;
        newWindow.titleOnly = titleOnly;
        // handelSaveExportDocsTypes(saveFormat)
    }
    const onClickDownloadExcel = async () => {
        let csvData = [];
        let results;
        if (saveResult === 'Marked Results') {
            results = selectedResults
        }
        else {
            results = JSON.parse(localStorage.getItem('displayedResults'))
        }
        results.map((item, index) => {
            try {
                csvData.push({
                    'Sl No': item.index,
                    // 'Sl No': index + 1,
                    Authors: (item.detail.authors && item.detail.authors.length > 0) ? getVarType(item.detail.authors) === 'Array' ? item.detail.authors.join("; ") : item.detail.authors : "",
                    Owner: item.detail.owner === "Migration Specialist" ? "" : item.detail.owner,
                    Title: item.detail.docTitle,
                    'Link to Document': {
                        text: 'Link',
                        hyperlink: `${URLConstructor(item.detail)}`
                    },
                    'Document Type': item.detail.docType !== undefined && item.detail.docType.length > 0 ? item.detail.docType.join("; ") : "",
                    'Document Number': item.detail.documentNumber,
                    Product: item.detail.product !== undefined && item.detail.product.length > 0 ? item.detail.product.join("; ") : "",
                    'MSL Therapeutic Area': item.detail.mslTherapeuticArea !== undefined && item.detail.mslTherapeuticArea.length > 0 ? item.detail.mslTherapeuticArea : '',
                    Source: item.detail.domain,
                    Congress: item.detail.congressName !== undefined && item.detail.congressName.length > 0 ? item.detail.congressName.join("; ") : "",
                    'Protocol ID': item.detail.protocolIDs !== undefined && item.detail.protocolIDs.length > 0 ? item.detail.protocolIDs.join("; ") : "",
                    'Phase Of Study': item.detail.phaseOfStudy !== undefined && item.detail.phaseOfStudy.length > 0 ? item.detail.phaseOfStudy.join("; ") : "",
                    Language: item.detail.languages,
                    Country: item.detail.countries !== undefined && item.detail.countries.length > 0 ? item.detail.countries.join("; ") : "",
                    Version: item.detail.majorVersionNumber,
                    'Last Updated': item.detail.lastUpdated !== undefined ? moment(item.detail.lastUpdated).format("D-MMM-YYYY") : "",
                    'Issued Date': item.detail.approvedDate !== undefined ? moment(item.detail.approvedDate).format("D-MMM-YYYY") : "",
                    'Modified Date': item.detail.modifiedDate !== undefined ? moment(item.detail.modifiedDate).format("D-MMM-YYYY") : "",
                    'PubMed ID': item.detail.pmID,
                    'Content Source': item.detail.csName,
                    'Key Takeaways': item.detail.keyTakeaways
                })
            } catch (error) {
                console.log("error", error);
            }
            return null;
        })
        let columns = [
            { header: "Sl No", key: "Sl No", width: 10 },
            { header: "Authors", key: "Authors", width: 36 },
            { header: "Owner", key: "Owner", width: 25 },
            { header: "Title", key: "Title", width: 36 },
            { header: "Link to Document", key: "Link to Document", width: 16 },
            { header: "Document Type", key: "Document Type", width: 15 },
            { header: "Document Number", key: "Document Number", width: 18 },
            { header: "Product", key: "Product", width: 33 },
            { header: "MSL Therapeutic Area", key: "MSL Therapeutic Area", width: 20 },
            { header: "Source", key: "Source", width: 10 },
            { header: "Congress", key: "Congress", width: 10 },
            { header: "Protocol ID", key: "Protocol ID", width: 10 },
            { header: "Phase Of Study", key: "Phase Of Study", width: 15 },
            { header: "Language", key: "Language", width: 10 },
            { header: "Country", key: "Country", width: 13 },
            { header: "Version", key: "Version", width: 10 },
            { header: "Last Updated", key: "Last Updated", width: 12 },
            { header: "Issued Date", key: "Issued Date", width: 12 },
            { header: "Modified Date", key: "Modified Date", width: 12 },
            { header: "PubMed ID", key: "PubMed ID", width: 11 },
            { header: "Content Source", key: "Content Source", width: 25 },
            { header: "Key Takeaways", key: "Key Takeaways", width: 45 },

        ];

        setDocProcessing(true);
        const fileName = `iMS_Results_Download_${generateDownloadTimestamp()}.xlsx`;
        let service = new ApiServices();
        await service.prepareExcelDocument(csvData, columns).then((res) => {
            const data = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(data, fileName);
            setDocProcessing(false)
        }).catch((error) => {
            console.log(error);
            setDocProcessing(false)
        });
    }

    const URLConstructor = (item) => {
        let url = window.origin
        if (item.domain === 'RIMdocs' || item.domain === 'RIMdocs_LL' || item.domain === 'CTGov') {
            url += `/DocURL?ID=${item.docID}&ref=excel&cs=${item.domain}`
            // url += `/DocURL?ID=${item.docTitle}&ref=excel&cs=${item.domain}`
        }
        else if (item.domain === 'SKM' || item.domain === 'IROs' || item.domain === "MIAs") {
            url += `/DocURL?ID=${item.docID}&ref=excel&cs=${item.domain}&connID=${item.connID}&csID=${item.csID}&fileType=${item.fileExtension}&fileName=${item.docTitle}`
        }
        else {
            url += `/DocURL?ID=${item.docID}&ref=excel&cs=${item.domain}&connID=${item.connID}&csID=${item.csID}&fileType=${item.fileExtension}&docVer=${item.majorVersionNumber}&fileName=${item.docTitle}`
            if (item.a_status === "Approved Needs Review" || item.status === "Approved Needs Review") {
                url = url + `&docState=anr`
            }
        }
        return url
    }
    const handleEmail = (searchId) => {
        // let url = 
        // // url = URLConstructor(item,'email')
        // indexdata(item,url,appliedFilters,searchKeyword,pageNumber,pageSize,'Email')
        let downloadurl = `/iMS_Emails?id=${searchId}`
        let mailUrl = window.location.origin + downloadurl
        // let kmUrl = 'http://teamsna.jnj.com/jom/omjsasystemssupport/omjsasupport/Lists/Service%20Center%20202010/NewForm.aspx';
        // kmUrl = encodeURIComponent(kmUrl);
        // if(item.docTitle.includes('&')){
        //    title=item.docTitle.replaceAll('&','%26')
        // }
        let mailbody = `${mailUrl}%0D%0DNote:-This url is valid for 60 days.%0D%0DFor access to iMedical Search, please log onto ${window.location.origin}`
        window.open(`mailto:?Subject=Search results from iMedical Search&body=${mailbody}`)
    }
    const handelEmailDocsTypes = (searchId) => {
        let downloadurl = `/iMS_Emails?id=${searchId}`
        const ts = new trackingService();
        ts.indexTrackingData(
            localStorage.getItem("userdisplayName"),
            localStorage.getItem("wwid"),
            localStorage.getItem("username"),
            localStorage.getItem("function"),
            localStorage.getItem("region"),
            localStorage.getItem("userproducts"),
            localStorage.getItem("doctypepref"),
            localStorage.getItem("sid"),
            localStorage.getItem("deviceType"),
            localStorage.getItem("deviceOS"),
            localStorage.getItem("browserName"),
            localStorage.getItem("browserVersion"),
            localStorage.getItem("jnjDepartment"),
            localStorage.getItem('companyname'),
            'Email List',
            window.location.origin + downloadurl,
            window.location.href,
            props.querytext,
            props.appliedFilters
        );
    }
    const handelSaveExportDocsTypes = (formate) => {
        const ts = new trackingService();
        ts.indexTrackingData(
            localStorage.getItem("userdisplayName"),
            localStorage.getItem("wwid"),
            localStorage.getItem("username"),
            localStorage.getItem("function"),
            localStorage.getItem("region"),
            localStorage.getItem("userproducts"),
            localStorage.getItem("doctypepref"),
            localStorage.getItem("sid"),
            localStorage.getItem("deviceType"),
            localStorage.getItem("deviceOS"),
            localStorage.getItem("browserName"),
            localStorage.getItem("browserVersion"),
            localStorage.getItem("jnjDepartment"),
            localStorage.getItem('companyname'),
            `Save Document - ${formate}`,
            "",
            window.location.href,
            props.querytext,
            props.appliedFilters
        );
    }
    const handelDownloadDocs = (filename) => {
        const ts = new trackingService();
        ts.indexTrackingData(
            localStorage.getItem("userdisplayName"),
            localStorage.getItem("wwid"),
            localStorage.getItem("username"),
            localStorage.getItem("function"),
            localStorage.getItem("region"),
            localStorage.getItem("userproducts"),
            localStorage.getItem("doctypepref"),
            localStorage.getItem("sid"),
            localStorage.getItem("deviceType"),
            localStorage.getItem("deviceOS"),
            localStorage.getItem("browserName"),
            localStorage.getItem("browserVersion"),
            localStorage.getItem("jnjDepartment"),
            localStorage.getItem('companyname'),
            `Download Document - ${filename}`,
            "",
            window.location.href,
            props.querytext,
            props.appliedFilters
        );
    }
    const handelDownloadAllDocs = () => {
        const ts = new trackingService();
        ts.indexTrackingData(
            localStorage.getItem("userdisplayName"),
            localStorage.getItem("wwid"),
            localStorage.getItem("username"),
            localStorage.getItem("function"),
            localStorage.getItem("region"),
            localStorage.getItem("userproducts"),
            localStorage.getItem("doctypepref"),
            localStorage.getItem("sid"),
            localStorage.getItem("deviceType"),
            localStorage.getItem("deviceOS"),
            localStorage.getItem("browserName"),
            localStorage.getItem("browserVersion"),
            localStorage.getItem("jnjDepartment"),
            localStorage.getItem('companyname'),
            `Download All Document`,
            "",
            window.location.href,
            props.querytext,
            props.appliedFilters
        );
    }
    const handelPrintDocs = () => {
        const ts = new trackingService();
        ts.indexTrackingData(
            localStorage.getItem("userdisplayName"),
            localStorage.getItem("wwid"),
            localStorage.getItem("username"),
            localStorage.getItem("function"),
            localStorage.getItem("region"),
            localStorage.getItem("userproducts"),
            localStorage.getItem("doctypepref"),
            localStorage.getItem("sid"),
            localStorage.getItem("deviceType"),
            localStorage.getItem("deviceOS"),
            localStorage.getItem("browserName"),
            localStorage.getItem("browserVersion"),
            localStorage.getItem("jnjDepartment"),
            localStorage.getItem('companyname'),
            `Print Document`,
            "",
            window.location.href,
            props.querytext,
            props.appliedFilters
        );
    }
    if (customWidth < 600) {
        return (
            <Modal
                show={showPop}
                size="lg md sm xs"
                centered
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="popup-transition-mob-tab"
                scrollable={true}
            >
                <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
                    {/* <div className="popup-cancel-btn-wrapper">
                            <img src={popCancelBttn} alt="" onClick={(e) => { setShow(false); handleClose(false) }} />
                        </div> */}
                    <div /* style={{ padding: "0 5%" }} */>
                        <Modal.Header className="gsfModalHeader" style={{ justifyContent: "center" }}>
                            <div style={{ textAlign: "center", width: "100%", paddingTop: "1%" }}>
                                <Modal.Title>
                                    <div className="actionItem-headerPart">
                                        {/* <div onClick={onSaveClick} className={isSaveModal ? "active-features-button" : "features-buttons"}>
                                                <img src={isSaveModal ? saveWhiteIcon : saveBlueIcon} alt="save-icon" width={17} height={17} className="vs-img" />
                                                <img src={saveWhiteIcon} alt="save-icon" width={17} height={17} className="vs-img-hov" />
                                                <span>Save</span>
                                            </div>
                                            <div className="action-button-saperation-line"></div>
                                            <div onClick={onPrintClick} className={isPrintModal ? "active-features-button" : "features-buttons"}>
                                                <img src={isPrintModal ? printWhiteIcon : printBlueIcon} alt="print-icon" width={15} height={15} className="vs-img" />
                                                <img src={printWhiteIcon} alt="save-icon" width={15} height={15} className="vs-img-hov" />
                                                <span>Print</span>
                                            </div>
                                            <div className="action-button-saperation-line"></div> */}
                                        {/* <div onClick={onEmailClick} className={isEmailModal ? "active-features-button" : "features-buttons"}> */}
                                        <div className="active-features-button-mobile">
                                            <img src={isEmailModal ? emailWhiteIcon : emailBlueIcon} alt="email-icon" width={20} height={20} className="vs-img" />
                                            <img src={emailWhiteIcon} alt="save-icon" width={20} height={20} className="vs-img-hov" />
                                            <span>Email</span>
                                        </div>
                                        {/* <div className="action-button-saperation-line"></div>
                                            <div onClick={onDownloadClick} className={isDownloadModal ? "active-features-button" : "features-buttons"}>
                                                <img src={isDownloadModal ? downloadWhiteIcon : downloadBlueIcon} alt="download-icon" width={18} height={18} className="vs-img" />
                                                <img src={downloadWhiteIcon} alt="save-icon" width={18} height={18} className="vs-img-hov" />
                                                <span>Download</span>
                                            </div> */}
                                    </div>
                                </Modal.Title>
                            </div>
                        </Modal.Header>
                        <Modal.Body scrollable={true} style={{ height: height }}>
                            <div>
                                <span className="cs-modalNote">PLEASE NOTE: iMedicalSearch content is for internal use ONLY, not to be shared externally. See the <a className="gsf-dsclmr-link" onClick={(e) => { setDisclaimer(true) }}>full disclaimer</a>.</span>
                                <br />
                                <br />
                                {
                                    isEmailModal &&
                                    <div className="email-popup-inputContainer-mobile">
                                        <div>
                                            <span className="gsf-popup-inputHeading">Results To Email :</span><br />
                                            <div className="gsf-popup-inputContainer-mobile">
                                                <div className="gsf-popup-inputPart-mobile">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="markedResults" value="Marked Results" onChange={selectedResults.length <= 0 ? null : handleOnChangeEmailResult} checked={emailResult === 'Marked Results'} style={{ cursor: selectedResults.length <= 0 ? "no-drop" : "pointer" }} />
                                                    <label style={{ color: emailResult === 'Marked Results' ? "#333" : "#63666A" }}>Marked Results</label>
                                                </div>
                                                <div className="gsf-popup-inputPart-mobile">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="displayedResults" value="Displayed Results" onChange={handleOnChangeEmailResult} checked={emailResult === 'Displayed Results'} />
                                                    <label style={{ color: emailResult === 'Displayed Results' ? "#333" : "#63666A" }}>Displayed Results</label>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="partition-line" />
                                        <div>
                                            <span className="gsf-popup-inputHeading">Fields To Include :</span><br />
                                            <div className="gsf-popup-inputContainer-mobile">
                                                <div className="gsf-popup-inputPart-mobile">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="titleOnly" value="Title Only" onChange={handleOnChangeEmailFieldstoInclude} checked={emailFields === 'Title Only'} />
                                                    <label style={{ color: emailFields === 'Title Only' ? "#333" : "#63666A" }}>Title Only</label>
                                                </div>
                                                <div className="gsf-popup-inputPart-mobile">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="fullCitation" value="Full Citation" onChange={handleOnChangeEmailFieldstoInclude} checked={emailFields === 'Full Citation'} />
                                                    <label style={{ color: emailFields === 'Full Citation' ? "#333" : "#63666A" }}>Full Citation</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Modal.Body>
                        <div className="gsf-popup-button-wrapper-mobile">
                            <div className="welcomeNxtBtn-mobile">
                                <MobileViewButton text="Proceed" customColor="#2EB000" action={onClickProceed} />

                            </div>
                            <div className="welcomeNxtBtn-mobile">
                                <MobileViewButton text="Cancel" customColor="#0074B0" action={() => { setShow(false); handleClose(false) }} />
                            </div>
                        </div>
                    </div>
                </div>
                {showDisclaimer &&
                    <>
                        <DisclaimerPop display={showDisclaimer} handleClose={(status) => { setDisclaimer(status) }} />
                        <div className="savemodal-outer-wrapper"></div>
                    </>
                }
                {emailProcessing &&
                    <>
                        <EmailProcessingPopUpModal showModal={emailProcessing} color="#0074B0" />
                        <div className="savemodal-outer-wrapper"></div>
                    </>
                }
                {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => closeAlertMessage()} />}
            </Modal>
        )
    }
    else if (customWidth < 1000) {
        return (
            <Modal
                show={showPop}
                size="lg md sm xs"
                centered
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="popup-transition-mob-tab"
                scrollable={true}
            >
                <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
                    {/* <div className="popup-cancel-btn-wrapper">
                        <img src={popCancelBttn} alt="" onClick={(e) => { setShow(false); handleClose(false) }} />
                    </div> */}
                    <div /* style={{ padding: "0 5%" }} */>
                        <Modal.Header className="gsfModalHeader" style={{ justifyContent: "center" }}>
                            <div style={{ textAlign: "center", width: "100%", paddingTop: "1%" }}>
                                <Modal.Title>
                                    <div className="actionItem-headerPart">
                                        {/* <div onClick={onSaveClick} className={isSaveModal ? "active-features-button" : "features-buttons"}>
                                            <img src={isSaveModal ? saveWhiteIcon : saveBlueIcon} alt="save-icon" width={17} height={17} className="vs-img" />
                                            <img src={saveWhiteIcon} alt="save-icon" width={17} height={17} className="vs-img-hov" />
                                            <span>Save</span>
                                        </div>
                                        <div className="action-button-saperation-line"></div>
                                        <div onClick={onPrintClick} className={isPrintModal ? "active-features-button" : "features-buttons"}>
                                            <img src={isPrintModal ? printWhiteIcon : printBlueIcon} alt="print-icon" width={15} height={15} className="vs-img" />
                                            <img src={printWhiteIcon} alt="save-icon" width={15} height={15} className="vs-img-hov" />
                                            <span>Print</span>
                                        </div>
                                        <div className="action-button-saperation-line"></div> */}
                                        {/* <div onClick={onEmailClick} className={isEmailModal ? "active-features-button" : "features-buttons"}> */}
                                        <div className="active-features-button-tablet">
                                            <img src={isEmailModal ? emailWhiteIcon : emailBlueIcon} alt="email-icon" width={20} height={20} className="vs-img" />
                                            <img src={emailWhiteIcon} alt="save-icon" width={20} height={20} className="vs-img-hov" />
                                            <span>Email</span>
                                        </div>
                                        {/* <div className="action-button-saperation-line"></div>
                                        <div onClick={onDownloadClick} className={isDownloadModal ? "active-features-button" : "features-buttons"}>
                                            <img src={isDownloadModal ? downloadWhiteIcon : downloadBlueIcon} alt="download-icon" width={18} height={18} className="vs-img" />
                                            <img src={downloadWhiteIcon} alt="save-icon" width={18} height={18} className="vs-img-hov" />
                                            <span>Download</span>
                                        </div> */}
                                    </div>
                                </Modal.Title>
                            </div>
                        </Modal.Header>
                        <Modal.Body scrollable={true} style={{ height: height }}>
                            <div>
                                <span className="cs-modalNote">PLEASE NOTE: iMedicalSearch content is for internal use ONLY, not to be shared externally. See the <a className="gsf-dsclmr-link" onClick={(e) => { setDisclaimer(true) }}>full disclaimer</a>.</span>
                                <br />
                                <br />
                                {
                                    isEmailModal &&
                                    <div>
                                        <div>
                                            <span className="gsf-popup-inputHeading">Results To Email :</span><br />
                                            <div className="gsf-popup-inputContainer" style={{ width: "50%", marginBottom: "10px" }}>
                                                <div className="gsf-popup-inputPart">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="markedResults" value="Marked Results" onChange={selectedResults.length <= 0 ? null : handleOnChangeEmailResult} checked={emailResult === 'Marked Results'} style={{ cursor: selectedResults.length <= 0 ? "no-drop" : "pointer" }} />
                                                    <label style={{ color: emailResult === 'Marked Results' ? "#333" : "#63666A" }}>Marked Results</label>
                                                </div>
                                                <div className="gsf-popup-inputPart">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="displayedResults" value="Displayed Results" onChange={handleOnChangeEmailResult} checked={emailResult === 'Displayed Results'} />
                                                    <label style={{ color: emailResult === 'Displayed Results' ? "#333" : "#63666A" }}>Displayed Results</label>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="partition-line" />
                                        <div>
                                            <span className="gsf-popup-inputHeading">Fields To Include :</span><br />
                                            <div className="gsf-popup-inputContainer" style={{ width: "45.5%" }}>
                                                <div className="gsf-popup-inputPart">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="titleOnly" value="Title Only" onChange={handleOnChangeEmailFieldstoInclude} checked={emailFields === 'Title Only'} />
                                                    <label style={{ color: emailFields === 'Title Only' ? "#333" : "#63666A" }}>Title Only</label>
                                                </div>
                                                <div className="gsf-popup-inputPart">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="fullCitation" value="Full Citation" onChange={handleOnChangeEmailFieldstoInclude} checked={emailFields === 'Full Citation'} />
                                                    <label style={{ color: emailFields === 'Full Citation' ? "#333" : "#63666A" }}>Full Citation</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Modal.Body>
                        <div className="gsf-popup-button-wrapper-tablet">
                            <div className="welcomeNxtBtn">
                                <Button text="Cancel" customColor="#0074B0" action={() => { setShow(false); handleClose(false) }} />
                            </div>
                            <div className="welcomeNxtBtn">
                                <Button text="Proceed" customColor="#2EB000" action={onClickProceed} />
                            </div>
                        </div>
                    </div>
                </div>
                {showDisclaimer &&
                    <>
                        <DisclaimerPop display={showDisclaimer} handleClose={(status) => { setDisclaimer(status) }} />
                        <div className="savemodal-outer-wrapper"></div>
                    </>
                }
                {emailProcessing &&
                    <>
                        <EmailProcessingPopUpModal showModal={emailProcessing} color="#0074B0" />
                        <div className="savemodal-outer-wrapper"></div>
                    </>
                }
                {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => closeAlertMessage()} />}
            </Modal>
        )
    }
    else {
        return (
            <Modal
                show={showPop}
                size="lg md sm xs"
                centered
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="popup-transition"
                scrollable={true}
            >
                <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
                    <div className="popup-cancel-btn-wrapper">
                        <img src={popCancelBttn} alt="" onClick={(e) => { setShow(false); handleClose(false) }} />
                    </div>
                    {/* pdfHtml && */
                        <div id="pdf-doc-to-print" style={{ display: "none", width: "100%" }}>
                            <div className="bannerImage">
                                <div className="headerTitle" style={{ alignItems: "center", textAlign: "center" }}>
                                    <img src={IMSLogo} alt="iMedical Search" style={{ width: "20%", paddingTop: "2%" }} />
                                </div>
                            </div>
                            <MIAsReferencesHtmlView selectedResults={selectedResults} citationType={saveResult} titleOnly={saveFields} />
                        </div>
                    }
                    <div /* style={{ padding: "0 5%" }} */>
                        <Modal.Header className="gsfModalHeader" style={{ justifyContent: "center" }}>
                            <div style={{ textAlign: "center", width: "100%", paddingTop: "1%" }}>
                                <Modal.Title>
                                    <div className="actionItem-headerPart">
                                        <div onClick={onSaveClick} className={isSaveModal ? "active-features-button" : "features-buttons"}>
                                            <img src={isSaveModal ? saveWhiteIcon : saveBlueIcon} alt="save-icon" width={17} height={17} className="vs-img" />
                                            <img src={saveWhiteIcon} alt="save-icon" width={17} height={17} className="vs-img-hov" />
                                            <span>Save</span>
                                        </div>
                                        <div className="action-button-saperation-line"></div>
                                        <div onClick={onPrintClick} className={isPrintModal ? "active-features-button" : "features-buttons"}>
                                            <img src={isPrintModal ? printWhiteIcon : printBlueIcon} alt="print-icon" width={15} height={15} className="vs-img" />
                                            <img src={printWhiteIcon} alt="save-icon" width={15} height={15} className="vs-img-hov" />
                                            <span>Print</span>
                                        </div>
                                        <div className="action-button-saperation-line"></div>
                                        <div onClick={onEmailClick} /* className="features-buttons" */ className={isEmailModal ? "active-features-button" : "features-buttons"}>
                                            <img src={isEmailModal ? emailWhiteIcon : emailBlueIcon} alt="email-icon" width={20} height={20} className="vs-img" />
                                            <img src={emailWhiteIcon} alt="save-icon" width={20} height={20} className="vs-img-hov" />
                                            <span>Email</span>
                                        </div>
                                        <div className="action-button-saperation-line"></div>
                                        <div onClick={onDownloadClick} className={isDownloadModal ? "active-features-button" : "features-buttons"}>
                                            <img src={isDownloadModal ? downloadWhiteIcon : downloadBlueIcon} alt="download-icon" width={18} height={18} className="vs-img" />
                                            <img src={downloadWhiteIcon} alt="save-icon" width={18} height={18} className="vs-img-hov" />
                                            <span>Download</span>
                                        </div>
                                    </div>
                                </Modal.Title>
                            </div>
                            {/* <div className="popup-cancel-btn-wrapper">
                                        <img src={popCancelBttn} alt="" onClick={(e) => { setShow(false); handleClose(false) }} />
                                    </div> */}
                        </Modal.Header>
                        <Modal.Body scrollable={true} style={{ height: height }}>
                            <div>
                                {
                                    !isDownloadProceed &&
                                    <>
                                        <span className="cs-modalNote">PLEASE NOTE: iMedicalSearch content is for internal use ONLY, not to be shared externally. See the <a className="gsf-dsclmr-link" onClick={(e) => { setDisclaimer(true) }}>full disclaimer</a>.</span>
                                        <br />
                                        <br />
                                    </>
                                }
                                {/* {props.children} */}
                                {
                                    isSaveModal &&
                                    <div>
                                        <div>
                                            <span className="gsf-popup-inputHeading">Results To Save :</span><br />
                                            <div className="gsf-popup-inputContainer" style={{ width: "50%", marginBottom: "10px" }}>
                                                <div className="gsf-popup-inputPart" >
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="markedResults" value="Marked Results" onChange={selectedResults.length <= 0 ? null : handleOnChangeSaveResult} checked={saveResult === 'Marked Results'} /* disabled={selectedResults.length<=0} */ style={{ cursor: selectedResults.length <= 0 ? "no-drop" : "pointer" }} />
                                                    <label style={{ color: saveResult === 'Marked Results' ? "#333" : "#63666A" }}>Marked Results</label>
                                                </div>
                                                <div className="gsf-popup-inputPart">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="displayedResults" value="Displayed Results" onChange={handleOnChangeSaveResult} checked={saveResult === 'Displayed Results'} />
                                                    <label style={{ color: saveResult === 'Displayed Results' ? "#333" : "#63666A" }}>Displayed Results</label>
                                                </div>
                                            </div>
                                            {
                                                window.location.pathname !== '/ViewSelectedReferencesUsed' ?
                                                    <span>({itemsPerPage} items currently displayed) <span onClick={() => { setShow(false); handleClose(false) }} className="saveModalHyperlink">Click here to change 'Items per page'</span>, then re-click on the 'Save/Export' icon.</span>
                                                    :
                                                    <span>Please select 'Items per page' to save/export data.</span>
                                            }
                                        </div>
                                        <hr className="partition-line" />
                                        <div>
                                            <span className="gsf-popup-inputHeading">Fields To Include :</span><br />
                                            <div className="gsf-popup-inputContainer" style={{ width: "45.5%" }}>
                                                <div className="gsf-popup-inputPart">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="titleOnly" value="Title Only" onChange={saveFormat === 'Excel' ? null : handleOnChangeSaveFieldstoInclude} checked={saveFields === 'Title Only'} style={{ cursor: saveFormat === 'Excel' ? "no-drop" : null }} />
                                                    <label style={{ color: (saveFields !== 'Title Only' || saveFormat === 'Excel') ? "#63666A" : "#333" }}>Title Only</label>
                                                </div>
                                                <div className="gsf-popup-inputPart">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="fullCitation" value="Full Citation" onChange={saveFormat === 'Excel' ? null : handleOnChangeSaveFieldstoInclude} checked={saveFields === 'Full Citation'} style={{ cursor: saveFormat === 'Excel' ? "no-drop" : null }} />
                                                    <label style={{ color: (saveFields !== 'Full Citation' || saveFormat === 'Excel') ? "#63666A" : "#333" }}>Full Citation</label>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="partition-line" />
                                        <div>
                                            <span className="gsf-popup-inputHeading">Format :</span><br />
                                            <div className="gsf-popup-inputContainer" style={{ width: "75%" }}>
                                                <div className="gsf-popup-inputPart">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="formatExcel" value="Excel" onChange={handleOnChangeSaveFormat} checked={saveFormat === 'Excel'} />
                                                    <label style={{ color: saveFormat === 'Excel' ? "#333" : "#63666A" }}>Excel</label>
                                                </div>
                                                <div className="gsf-popup-inputPart">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="formatpdf" value="Pdf" onChange={handleOnChangeSaveFormat} checked={saveFormat === 'Pdf'} />
                                                    <label style={{ color: saveFormat === 'Pdf' ? "#333" : "#63666A" }}>Pdf</label>
                                                </div>
                                                {/* <div className="gsf-popup-inputPart">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="formattxt" value="Txt" onChange={handleOnChangeSaveFormat} checked={saveFormat === 'Txt'} />
                                                    <label style={{ color: saveFormat === 'Txt' ? "#333" : "#63666A" }}>Txt</label>
                                                </div> */}
                                                <div className="gsf-popup-inputPart">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="formathtml" value="HTML" onChange={handleOnChangeSaveFormat} checked={saveFormat === 'HTML'} />
                                                    <label style={{ color: saveFormat === 'HTML' ? "#333" : "#63666A" }}>HTML</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                }
                                {
                                    isPrintModal &&
                                    <div>
                                        <div>
                                            <span className="gsf-popup-inputHeading">Results To Print :</span><br />
                                            <div className="gsf-popup-inputContainer" style={{ width: "50%", marginBottom: "10px" }}>
                                                <div className="gsf-popup-inputPart">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="markedResults" value="Marked Results" onChange={selectedResults.length <= 0 ? null : handleOnChangePrintResult} checked={printResult === 'Marked Results'} style={{ cursor: selectedResults.length <= 0 ? "no-drop" : "pointer" }} />
                                                    <label style={{ color: printResult === 'Marked Results' ? "#333" : "#63666A" }}>Marked Results</label>
                                                </div>
                                                <div className="gsf-popup-inputPart">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="displayedResults" value="Displayed Results" onChange={handleOnChangePrintResult} checked={printResult === 'Displayed Results'} />
                                                    <label style={{ color: printResult === 'Displayed Results' ? "#333" : "#63666A" }}>Displayed Results</label>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="partition-line" />
                                        <div>
                                            <span className="gsf-popup-inputHeading">Fields To Include :</span><br />
                                            <div className="gsf-popup-inputContainer" style={{ width: "45.5%" }}>
                                                <div className="gsf-popup-inputPart">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="titleOnly" value="Title Only" onChange={handleOnChangePrintFieldstoInclude} checked={printFields === 'Title Only'} />
                                                    <label style={{ color: printFields === 'Title Only' ? "#333" : "#63666A" }}>Title Only</label>
                                                </div>
                                                <div className="gsf-popup-inputPart">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="fullCitation" value="Full Citation" onChange={handleOnChangePrintFieldstoInclude} checked={printFields === 'Full Citation'} />
                                                    <label style={{ color: printFields === 'Full Citation' ? "#333" : "#63666A" }}>Full Citation</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style={{ display: "none" }}// This make ComponentToPrint show   only while printing
                                        >
                                            <MIAsReferencesHtmlView ref={componentRef} selectedResults={selectedResults} titleOnly={titleOnlyPrint} citationType={printResult} />
                                        </div>
                                    </div>
                                }
                                {
                                    isEmailModal &&
                                    <div>
                                        <div>
                                            <span className="gsf-popup-inputHeading">Results To Email :</span><br />
                                            <div className="gsf-popup-inputContainer" style={{ width: "50%", marginBottom: "10px" }}>
                                                <div className="gsf-popup-inputPart">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="markedResults" value="Marked Results" onChange={selectedResults.length <= 0 ? null : handleOnChangeEmailResult} checked={emailResult === 'Marked Results'} style={{ cursor: selectedResults.length <= 0 ? "no-drop" : "pointer" }} />
                                                    <label style={{ color: emailResult === 'Marked Results' ? "#333" : "#63666A" }}>Marked Results</label>
                                                </div>
                                                <div className="gsf-popup-inputPart">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="displayedResults" value="Displayed Results" onChange={handleOnChangeEmailResult} checked={emailResult === 'Displayed Results'} />
                                                    <label style={{ color: emailResult === 'Displayed Results' ? "#333" : "#63666A" }}>Displayed Results</label>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="partition-line" />
                                        <div>
                                            <span className="gsf-popup-inputHeading">Fields To Include :</span><br />
                                            <div className="gsf-popup-inputContainer" style={{ width: "45.5%" }}>
                                                <div className="gsf-popup-inputPart">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="titleOnly" value="Title Only" onChange={handleOnChangeEmailFieldstoInclude} checked={emailFields === 'Title Only'} />
                                                    <label style={{ color: emailFields === 'Title Only' ? "#333" : "#63666A" }}>Title Only</label>
                                                </div>
                                                <div className="gsf-popup-inputPart">
                                                    <input type="radio" className="gsf-popup-radioInput k-radio" name="fullCitation" value="Full Citation" onChange={handleOnChangeEmailFieldstoInclude} checked={emailFields === 'Full Citation'} />
                                                    <label style={{ color: emailFields === 'Full Citation' ? "#333" : "#63666A" }}>Full Citation</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    isDownloadModal &&
                                    <div>
                                        <ul>
                                            {/* <li>Documents selected for downloading are zipped in multiples of 5 per file to improve speed of downloading</li> */}
                                            <li>Files marked: {selectedResults.length}</li>
                                        </ul>
                                        {
                                            isDownloadProceed ?
                                                <div className="downloadZip-outer-wrapper">
                                                    <div className="downloadZip-wrapper">
                                                        <span className="fileName-txt">File Name {` `}</span>
                                                        <div>
                                                            {proceedDownload(downloadData)}
                                                        </div>
                                                    </div>
                                                    <div className="downloadAll-zip-link" onClick={downloadAll}>Download All</div>
                                                </div>
                                                :
                                                <div>
                                                    <span className="gsf-popup-inputHeading">Results To Download :</span><br />
                                                    <div className="gsf-popup-inputContainer" style={{ width: "50%", marginBottom: "10px" }}>
                                                        <div className="gsf-popup-inputPart">
                                                            <input type="radio" className="gsf-popup-radioInput k-radio" name="markedResults" value="Marked Results" onChange={selectedResults.length <= 0 ? null : handleOnChangeDownloadResult} checked={downloadResult === 'Marked Results'} style={{ cursor: selectedResults.length <= 0 ? "no-drop" : "pointer" }} />
                                                            <label style={{ color: downloadResult === 'Marked Results' ? "#333" : "#63666A" }}>Marked Results</label>
                                                        </div>
                                                        <div className="gsf-popup-inputPart">
                                                            <input type="radio" className="gsf-popup-radioInput k-radio" name="displayedResults" value="Displayed Results" onChange={handleOnChangeDownloadResult} checked={downloadResult === 'Displayed Results'} />
                                                            <label style={{ color: downloadResult === 'Displayed Results' ? "#333" : "#63666A" }}>Displayed Results</label>
                                                        </div>
                                                    </div>
                                                </div>
                                        }

                                    </div>
                                }
                            </div>
                        </Modal.Body>
                        {
                            isDownloadProceed ?
                                <div className="reading-list-button-wrapper">
                                    <Button text="Cancel" customColor="#0074B0" action={() => { setShow(false); handleClose(false) }} />
                                </div>
                                :
                                <div className="gsf-popup-button-wrapper">
                                    <Button text="Cancel" customColor="#0074B0" action={() => { setShow(false); handleClose(false) }} />
                                    <Button text="Proceed" customColor="#2EB000" action={onClickProceed} />
                                </div>
                        }

                    </div>
                </div>
                {showDisclaimer &&
                    <>
                        <DisclaimerPop display={showDisclaimer} handleClose={(status) => { setDisclaimer(status) }} />
                        <div className="savemodal-outer-wrapper"></div>
                    </>
                }
                {showSaveToExcel && /* console.log(selectedResults), */
                    <>
                        <SavePopUpModal showModal={showSaveToExcel} handleClose={(status) => { setSaveToExcel(status) }} color="#0074B0" onViewClick={onViewClick} selectedResults={selectedResults} onClickDownload={onClickDownloadExcel} />
                        <div className="savemodal-outer-wrapper"></div>
                    </>
                }
                {docProcessing &&
                    <>
                        <DocumentProcessing showModal={docProcessing} color="#0074B0" />
                        <div className="savemodal-outer-wrapper"></div>
                    </>
                }
                {emailProcessing &&
                    <>
                        <EmailProcessingPopUpModal showModal={emailProcessing} color="#0074B0" />
                        <div className="savemodal-outer-wrapper"></div>
                    </>
                }
                {downloadZipProcessing &&
                    <>
                        <DownloadProcessingPopUpModal showModal={downloadZipProcessing} color="#0074B0" />
                        <div className="savemodal-outer-wrapper"></div>
                    </>
                }
                {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => closeAlertMessage()} />}
            </Modal>
        )
    }
}