/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

class NotebookEditCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
        }
    }
    componentDidMount() {
        const { value } = this.props;
        this.setState({ value: value });
    }

    handleSummery = (e) => {
        this.setState({ value: e.target.value })
    }

    render() {
        const { column, row, } = this.props;
        const { value } = this.state;
        let oldValue = row[column.dataField];
        return (
            <div className="nbv-notes-edit">
                <div className="nbv-notes-cell-txt">
                    <textarea
                        value={value}
                        onChange={(e) => this.handleSummery(e)}
                    ></textarea>
                </div>
                <div className="nbv-notes-edit-cell-btn">
                    <a
                        onClick={(e) => { this.props.onUpdateNotesCell(oldValue, value, row, column) }}
                        title="Save"
                        className="nbv-notes-cell-action-text"
                    >
                        Save
                    </a>
                    <span className="action-items-separate-divider"></span>
                    <a
                        onClick={(e) => { this.props.onCancelNotesCell() }}
                        title="Cancel"
                        className="nbv-notes-cell-action-cancel"
                    >
                        Cancel
                    </a>
                </div>
            </div>
        )
    }
}
export default NotebookEditCell;
