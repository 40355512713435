import RecentlyAddedUpdatedBarDiagram from '../../components/RecentlyAddedUpdatedHistogram/RecentlyAddedUpdatedBarDiagram';
import PropTypes from 'prop-types'
import React from 'react'
import RecentlyAddedUpdatedRangeSlider from '../../components/RecentlyAddedUpdatedHistogram/RecentlyAddedUpdatedRangeSlider'
import './FilterHistogramRecentlyAddedUpdated.css'


export default function RecentlyAddedUpdatedFilterHistogram(props) {
  const {
    handleRecentlyAddedUpdated,
    gotoPage,
    years,
    yearSelection,
  } = props

  if (years && years.length > 0) {
    const rangeMax = 6;
    let rangeMin =0;

    if (rangeMax === rangeMin) {
      rangeMin -= 1
    }
    
    const sliderRange = years.map(x=>x.key);

    /**
      Determine the width of a bar. Used to create offsets for the rangeslider so the location of each selection dot
      is in the middle of the bar
    **/
    const barWidth = 100 / sliderRange.length
    return (
      <>
        <div className="recently-added-updated-bar-diagram">
          <RecentlyAddedUpdatedBarDiagram
            barWidth={barWidth}
            sliderRange={sliderRange}
            years={years}
            yearSelection={yearSelection}
          />
        </div>
        <div className="recently-added-updated-slider-content">
          <RecentlyAddedUpdatedRangeSlider
            handleRecentlyAddedUpdated={handleRecentlyAddedUpdated}
            offSet={barWidth / 2}
            rangeMax={rangeMax}
            rangeMin={rangeMin}
            gotoPage={gotoPage}
            years={years}
            yearSelection={yearSelection}
          />
        </div>
      </>
    )
  }
  else {
    return null
  }
}
RecentlyAddedUpdatedFilterHistogram.propTypes = {
  handleYearSelection: PropTypes.func.isRequired,
  gotoPage: PropTypes.func.isRequired,
  years: PropTypes.arrayOf(
    PropTypes.object
  ).isRequired,
  yearSelection: PropTypes.object.isRequired
}
