import { Component } from "react";
import Footer from "../../components/footer/Footer";
import HeaderWithBanner from "../../components/header/HeaderWithBanner";
import NavigationMenu from "../../components/Navigation/NavigationMenu";
import './FooterLinks.css';

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        // document.title = 'Contact Us - iMedical Search'
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight })
    }
    render() {
        if (this.state.width < 600) {
            return (
                <>
                    <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                    <div className="contactWrapper">
                        <div className="contactContentWrapper">
                            <div className="contactContent mobile-helpContentWrapper">
                                <div className="upperCard">
                                    <span className="contactHeader-mobile">Get In Touch</span>
                                    <p>
                                        <span>
                                            We're here to help you, so feel free to contact the support group below to address your need, issue or question.<br /> <br /> If your issue is not support related or you don't know where turn to get the help you need, please don't hesitate to contact me. I'd be happy to point you in the right direction.
                                        </span>
                                        <br />
                                        <br />
                                        <a href="mailto:JFrain1@its.jnj.com">
                                            <span className="contactusLink">
                                                Jim Frain- &nbsp;
                                                {/* Jim Frain- Global Medical Owner Of iMedical Search */}
                                            </span>
                                        </a>
                                        <span>
                                            Associate Director, Search Solutions <br />
                                            Global Business Owner, iMedical Search <br />
                                            Digital Medical Innovations <br />
                                            Janssen Scientific Affairs LLC
                                        </span>
                                    </p>
                                    <p>
                                        <br />
                                    </p>
                                    {/* <span className="contactHeader-mobile">
                                        Connect with Our Knowledge Experts<br />
                                    </span>
                                    <div className="mobile-helpContentList">
                                        <div className="linked-items">
                                            <span style={{ fontSize: "15px" }}>
                                                iMedical Search team : &nbsp;
                                            </span>
                                            <a href="mailto:ims@its.jnj.com" className="knowledgeExpertsLinks">
                                                <span style={{ fontSize: "15px" }}>
                                                    Send us an E-Mail at iMS Mailbox
                                                </span>
                                            </a>
                                        </div>
                                        <div className="linked-items">
                                            <span style={{ fontSize: "15px" }}>
                                                Scientific knowledge Management: &nbsp;
                                            </span>
                                            <a href="mailto:navigator@its.jnj.com" className="knowledgeExpertsLinks">
                                                <span style={{ fontSize: "15px" }}>
                                                    Send an E-Mail to SKM Team
                                                </span>
                                            </a>
                                        </div>
                                        <div className="linked-items">
                                            <span style={{ fontSize: "15px" }}>
                                                Global Medical affairs immunology Support (Leiden Team) : &nbsp;
                                            </span>
                                            <a href="mailto:janssen.immunology@its.jnj.com" className="knowledgeExpertsLinks">
                                                <span style={{ fontSize: "15px" }}>
                                                    Send an E-Mail to the GMA immunology Support Team
                                                </span>
                                            </a>
                                        </div>
                                        <div className="linked-items">
                                            <span>
                                                Global Medical Information Contact list : &nbsp;
                                                <a href="https://ims.jnj.com/Documents/GMIT%20Contact%20List%203Dec18%20-%20Password%20Locked%20for%20iMKS.xlsx" className="knowledgeExpertsLinks">Download
                                                </a>
                                            </span>
                                        </div>
                                        <div className="linked-items">
                                            <span>
                                                Global Product labeling Coe Contact list : &nbsp;
                                                <a href="https://jnj.sharepoint.com/:x:/r/teams/glcoe/_layouts/15/Doc.aspx?sourcedoc=%7B2A3A9B51-76CE-4464-A5ED-4D2DAD0D9675%7D&file=03.0%20GLCoE%20Product%20Contact%20List-JUL%2016%202019.xls&action=default&mobileredirect=true" target="_blank" rel="noreferrer" className="knowledgeExpertsLinks">View
                                                </a>
                                            </span>
                                        </div>
                                        <div className="linked-items">
                                            <span>
                                                Global Regulatory Affairs products Contact list : &nbsp;
                                                <a href="https://jnj.sharepoint.com/sites/PHM-GCSP-RND/PharmGRA/findpeople/Lists/Contact%20List/Alphabetically.aspx" target="_blank" rel="noreferrer" className="knowledgeExpertsLinks">View
                                                </a>
                                            </span>
                                        </div>
                                        <p>
                                            <span>
                                                <br />
                                            </span>
                                        </p>
                                    </div> */}
                                    <p>
                                        <span className="contactHeader-mobile">
                                            Connect With Our Technical Support
                                            <br />
                                        </span>
                                    </p>
                                    <span style={{ fontSize: "15px" }} className="contactusContent">
                                        Need help with using iMedical Search (iMS) or experiencing an error message?
                                    </span> <br /><br />
                                    <a href="mailto:ims@its.jnj.com">
                                        <span style={{ fontSize: "15px" }} className="contactusLink">
                                            Please, send an email to our support mailbox.
                                        </span>
                                    </a>
                                    <p>
                                        <br />
                                    </p>
                                    <p>
                                        <span className="contactHeader-mobile">
                                            How We Use Your Information
                                        </span>
                                    </p>
                                    <span style={{ fontSize: "15px" }} className="contactusContent">
                                        To obtain a report of your own activity, or for questions on the processing of information in relation to these tools
                                    </span><br /><br />
                                    <a href="mailto:ims@its.jnj.com">
                                        <span style={{ fontSize: "15px" }} className="contactusLink">
                                            Please contact ims@its.jnj.com
                                        </span>
                                    </a>
                                    <p>
                                        <span>
                                            <br />
                                        </span>
                                    </p>
                                </div>
                                <h1>
                                    <br />
                                </h1>
                            </div>
                        </div>
                        <Footer customWidth={this.state.width} customHeight={this.state.height} />
                    </div>
                </>
            )
        }
        else if (this.state.width < 1000) {
            return (
                <>
                    <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                    <HeaderWithBanner />
                    <div className="contactWrapper">
                        <div className="contactContentWrapper">
                            <div className="contactContent">
                                <div className="upperCard">
                                    <span className="contactHeader-tablet">Get In Touch</span>
                                    <p style={{ marginTop: "20px" }}>
                                        <span>
                                            We're here to help you, so feel free to contact the support group below to address your need, issue or question. If your issue is not support related or you don't know where turn to get the help you need, please don't hesitate to contact me. I'd be happy to point you in the right direction.
                                        </span>
                                        <br />
                                        <br />
                                        <a href="mailto:JFrain1@its.jnj.com">
                                            <span className="contactusLink">
                                                Jim Frain- &nbsp;
                                                {/* Jim Frain- Global Medical Owner Of iMedical Search */}
                                            </span>
                                        </a>
                                        <span>
                                            Associate Director, Search Solutions <br />
                                            Global Business Owner, iMedical Search <br />
                                            Digital Medical Innovations <br />
                                            Janssen Scientific Affairs LLC
                                        </span>
                                    </p>
                                    <p>
                                        <br />
                                    </p>
                                    {/* <span className="contactHeader-tablet">
                                        Connect with Our Knowledge Experts<br />
                                    </span>
                                    <div className="contactContentPart-tablet">
                                        <ul style={{ listStyleType: "none" }} className="listing">
                                            <div className="contactus-lists">
                                                <img src={listIcon} alt="List" className="listIcon-tablet" />
                                                <li className="listingContent">
                                                    <span style={{ fontSize: "15px" }}>
                                                        iMedical Search team : &nbsp;
                                                    </span>
                                                    <a href="mailto:ims@its.jnj.com" className="knowledgeExpertsLinks">
                                                        <span style={{ fontSize: "15px" }}>
                                                            Send us an E-Mail at iMS Mailbox
                                                        </span>
                                                    </a>
                                                </li>
                                            </div>
                                            <div className="contactus-lists">
                                                <img src={listIcon} alt="List" className="listIcon-tablet" />
                                                <li className="listingContent">
                                                    <span style={{ fontSize: "15px" }}>
                                                        Scientific knowledge Management: &nbsp;
                                                    </span>
                                                    <a href="mailto:navigator@its.jnj.com" className="knowledgeExpertsLinks">
                                                        <span style={{ fontSize: "15px" }}>
                                                            Send an E-Mail to SKM Team
                                                        </span>
                                                    </a>
                                                </li>
                                            </div>
                                            <div className="contactus-lists">
                                                <img src={listIcon} alt="List" className="listIcon-tablet" />
                                                <li className="listingContent">
                                                    <span style={{ fontSize: "15px" }}>
                                                        Global Medical affairs immunology Support (Leiden Team) : &nbsp;
                                                    </span>
                                                    <a href="mailto:janssen.immunology@its.jnj.com" className="knowledgeExpertsLinks">
                                                        <span style={{ fontSize: "15px" }}>
                                                            Send an E-Mail to the GMA immunology Support Team
                                                        </span>
                                                    </a>
                                                </li>
                                            </div>
                                            <div className="contactus-lists">
                                                <img src={listIcon} alt="List" className="listIcon-tablet" />
                                                <li className="listingContent">
                                                    <span>
                                                        Global Medical Information Contact list : &nbsp;
                                                        <a href="https://ims.jnj.com/Documents/GMIT%20Contact%20List%203Dec18%20-%20Password%20Locked%20for%20iMKS.xlsx" className="knowledgeExpertsLinks">Download
                                                        </a>
                                                    </span>
                                                </li>
                                            </div>
                                            <div className="contactus-lists">
                                                <img src={listIcon} alt="List" className="listIcon-tablet" />
                                                <li className="listingContent">
                                                    <span>
                                                        Global Product labeling Coe Contact list : &nbsp;
                                                        <a href="https://jnj.sharepoint.com/:x:/r/teams/glcoe/_layouts/15/Doc.aspx?sourcedoc=%7B2A3A9B51-76CE-4464-A5ED-4D2DAD0D9675%7D&file=03.0%20GLCoE%20Product%20Contact%20List-JUL%2016%202019.xls&action=default&mobileredirect=true" target="_blank" rel="noreferrer" className="knowledgeExpertsLinks">View
                                                        </a>
                                                    </span>
                                                </li>
                                            </div>
                                            <div className="contactus-lists">
                                                <img src={listIcon} alt="List" className="listIcon-tablet" />
                                                <li className="listingContent">
                                                    <span>
                                                        Global Regulatory Affairs products Contact list : &nbsp;
                                                        <a href="https://jnj.sharepoint.com/sites/PHM-GCSP-RND/PharmGRA/findpeople/Lists/Contact%20List/Alphabetically.aspx" target="_blank" rel="noreferrer" className="knowledgeExpertsLinks">View
                                                        </a>
                                                    </span>
                                                </li>
                                            </div>
                                            <p>
                                                <span>
                                                    <br />
                                                </span>
                                            </p>
                                        </ul>
                                    </div> */}
                                    <p>
                                        <span className="contactHeader-tablet">
                                            Connect With Our Technical Support
                                            <br />
                                        </span>
                                    </p>
                                    <span style={{ fontSize: "15px" }} className="contactusContent">
                                        Need help with using iMedical Search (iMS) or experiencing an error message?
                                    </span> <br /><br />
                                    <a href="mailto:ims@its.jnj.com">
                                        <span style={{ fontSize: "15px" }} className="contactusLink">
                                            Please, send an email to our support mailbox.
                                        </span>
                                    </a>
                                    <p>
                                        <br />
                                    </p>
                                    <p>
                                        <span className="contactHeader-tablet">
                                            How We Use Your Information
                                        </span>
                                    </p>
                                    <span style={{ fontSize: "15px" }} className="contactusContent">
                                        To obtain a report of your own activity, or for questions on the processing of information in relation to these tools
                                    </span><br /><br />
                                    <a href="mailto:ims@its.jnj.com">
                                        <span style={{ fontSize: "15px" }} className="contactusLink">
                                            Please contact ims@its.jnj.com
                                        </span>
                                    </a>
                                    <p>
                                        <span>
                                            <br />
                                        </span>
                                    </p>
                                </div>
                                <h1>
                                    <br />
                                    <br />
                                </h1>
                            </div>
                        </div>
                        <Footer customWidth={this.state.width} customHeight={this.state.height} />
                    </div>
                </>
            )
        }
        else {
            return (
                <>
                    <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                    <HeaderWithBanner />
                    <div className="contactWrapper">
                        <div className="contactContentWrapper">
                            <div className="contactContent">
                                <div className="upperCard">
                                    <span className="contactHeader">Get In Touch</span>
                                    <p style={{ marginTop: "20px" }}>
                                        <span>
                                            We're here to help you, so feel free to contact the support group below to address your need, issue or question. If your issue is not support related or you don't know where turn to get the help you need, please don't hesitate to contact me. I'd be happy to point you in the right direction.
                                        </span>
                                        <br />
                                        <br />
                                        <a href="mailto:JFrain1@its.jnj.com">
                                            <span className="contactusLink">
                                                {/* Jim Frain- Associate Director, Search Solutions, Global Business Owner, iMedical Search, Scientific Exchange Solutions, Janssen Scientific Affairs LLC */}
                                                Jim Frain- &nbsp;
                                                {/* Jim Frain- Global Medical Owner Of iMedical Search */}
                                            </span>
                                        </a>
                                        <span>
                                            Associate Director, Search Solutions <br />
                                            Global Business Owner, iMedical Search <br />
                                            Digital Medical Innovations <br />
                                            Janssen Scientific Affairs LLC
                                        </span>
                                    </p>
                                </div>
                                <div className="lowerCard">
                                    {/* <div className="contactBG"> */}
                                    {/* <span className="contactHeader">
                                        Connect with Our Knowledge Experts<br />
                                    </span>
                                    <div className="contactContentPart">
                                        <ul style={{ listStyleType: "none" }} className="listing">
                                            <li className="listingContent">
                                                <img src={listIcon} alt="List" className="listIcon" />
                                                <span style={{ fontSize: "15px" }}>
                                                    iMedical Search team : &nbsp;
                                                </span>
                                                <a href="mailto:ims@its.jnj.com" className="knowledgeExpertsLinks">
                                                    <span style={{ fontSize: "15px" }}>
                                                        Send us an E-Mail at iMS Mailbox
                                                    </span>
                                                </a>
                                            </li>
                                            <li className="listingContent">
                                                <img src={listIcon} alt="List" className="listIcon" />
                                                <span style={{ fontSize: "15px" }}>
                                                    Scientific knowledge Management: &nbsp;
                                                </span>
                                                <a href="mailto:navigator@its.jnj.com" className="knowledgeExpertsLinks">
                                                    <span style={{ fontSize: "15px" }}>
                                                        Send an E-Mail to SKM Team
                                                    </span>
                                                </a>
                                            </li>
                                            <li className="listingContent">
                                                <img src={listIcon} alt="List" className="listIcon" />
                                                <span style={{ fontSize: "15px" }}>
                                                    Global Medical affairs immunology Support (Leiden Team) : &nbsp;
                                                </span>
                                                <a href="mailto:janssen.immunology@its.jnj.com" className="knowledgeExpertsLinks">
                                                    <span style={{ fontSize: "15px" }}>
                                                        Send an E-Mail to the GMA immunology Support Team
                                                    </span>
                                                </a>
                                            </li>
                                            <li className="listingContent">
                                                <img src={listIcon} alt="List" className="listIcon" />
                                                <span>
                                                    Global Medical Information Contact list : &nbsp;
                                                    <a href="https://ims.jnj.com/Documents/GMIT%20Contact%20List%203Dec18%20-%20Password%20Locked%20for%20iMKS.xlsx" className="knowledgeExpertsLinks">Download
                                                    </a>
                                                </span>
                                            </li>
                                            <li className="listingContent">
                                                <img src={listIcon} alt="List" className="listIcon" />
                                                <span>
                                                    Global Product labeling Coe Contact list : &nbsp;
                                                    <a href="https://jnj.sharepoint.com/:x:/r/teams/glcoe/_layouts/15/Doc.aspx?sourcedoc=%7B2A3A9B51-76CE-4464-A5ED-4D2DAD0D9675%7D&file=03.0%20GLCoE%20Product%20Contact%20List-JUL%2016%202019.xls&action=default&mobileredirect=true" target="_blank" rel="noreferrer" className="knowledgeExpertsLinks">View
                                                    </a>
                                                </span>
                                            </li>
                                            <li className="listingContent">
                                                <img src={listIcon} alt="List" className="listIcon" />
                                                <span>
                                                    Global Regulatory Affairs products Contact list : &nbsp;
                                                    <a href="https://jnj.sharepoint.com/sites/PHM-GCSP-RND/PharmGRA/findpeople/Lists/Contact%20List/Alphabetically.aspx" target="_blank" rel="noreferrer" className="knowledgeExpertsLinks">View
                                                    </a>
                                                </span>
                                            </li>
                                            <p>
                                                <span>
                                                    <br />
                                                </span>
                                            </p>
                                        </ul>
                                    </div> */}
                                    <p>
                                        <span className="contactHeader">
                                            Connect With Our Technical Support
                                            {/* Connect with Technical Support &nbsp; */}
                                            <br />
                                        </span>
                                    </p>
                                    <span style={{ fontSize: "15px" }} className="contactusContent">
                                        Need help with using iMedical Search (iMS) or experiencing an error message?
                                    </span> <br /><br />
                                    <a href="mailto:ims@its.jnj.com">
                                        <span style={{ fontSize: "15px" }} className="contactusLink">
                                            {/* Please, Send An Email To Our Support Mailbox */}
                                            Please, send an email to our support mailbox.
                                        </span>
                                    </a>
                                    <p>
                                        <br />
                                    </p>
                                    <p>
                                        <span className="contactHeader">
                                            How We Use Your Information
                                        </span>
                                    </p>
                                    <span style={{ fontSize: "15px" }} className="contactusContent">
                                        To obtain a report of your own activity, or for questions on the processing of information in relation to these tools
                                    </span><br /><br />
                                    <a href="mailto:ims@its.jnj.com">
                                        <span style={{ fontSize: "15px" }} className="contactusLink">
                                            Please contact ims@its.jnj.com
                                        </span>
                                    </a>
                                    <p>
                                        <span>
                                            <br />
                                        </span>
                                    </p>
                                    {/* </div> */}
                                </div>
                                <h1>
                                    <br />
                                    <br />
                                </h1>
                            </div>
                        </div>
                        <Footer customWidth={this.state.width} customHeight={this.state.height} />
                    </div>
                </>
            )
        }
    }
}
export default ContactUs;