import { Component } from "react";
import DownloadDocumentResults from "../DownloadDocumentResults/DownloadDocumentResults";
import '../Pages/Download.css';
import Header from "../../components/header/Header";
class PDFCitationDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedResults: [],
            collapsed: false
        }
    }
    componentWillMount() {

        // if (window.citationType === 'Marked Results') {
        //     this.setState({
        //         selectedResults: window.selectedResults
        //     })
        // }
        // else {
        //     console.log('Read Displayed Results');
        //     this.setState({
        //         selectedResults: JSON.parse(localStorage.getItem('displayedResults'))
        //     })
        // }
        // if (window.titleOnly !== undefined && window.titleOnly === true) {
        //     this.setState({
        //         collapsed: true
        //     })
        // }
        // else {
        //     this.setState({
        //         collapsed: false
        //     })
        // }


        if (this.props.citationType === 'Marked Results') {
            this.setState({
                selectedResults: this.props.selectedResults
            })
        }
        else {
            console.log('Read Displayed Results');
            this.setState({
                selectedResults: JSON.parse(localStorage.getItem('displayedResults'))
            })
        }
        if (this.props.titleOnly !== undefined && this.props.titleOnly === true) {
            this.setState({
                collapsed: true
            })
        }
        else {
            this.setState({
                collapsed: false
            })
        }

    }
    resultList = (results) => {
        if (this.state.selectedResults.length > 0) {
            return (
                <>
                    {this.state.selectedResults.map((item, index) => {
                        return (
                            <>
                                <div key={`download-result-id-${index}`} id={`download-result-id-${index}`}>
                                    {
                                        <div className="resultDetailsDiv">
                                            <DownloadDocumentResults item={item.detail} count={item.index} itemTeaser={item.itemTeaser} customWidth={this.state.width} collapsed={this.state.collapsed} />
                                            <div className="resultsDivFooter">
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        )
                    })
                    }
                </>
            )
        }
    }
    render() {
        return (
            <div className="download-results-page-wrapper">
                <Header />
                <div className="download-results-page-contents-wrapper">
                    {this.resultList(this.state.selectedResults)}
                </div>
            </div>
        )
        // return(
        
        //     <div className="download-results-page-contents-wrapper">
        //     {this.resultList(this.state.selectedResults)}
        //     </div>
            
        // )
    }
}
export default PDFCitationDetails