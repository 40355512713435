/* eslint-disable no-useless-constructor */
import { Component } from "react";
import { sanitize } from 'dompurify';

const normalFontSize = "13px"
const titleFontSize = "15px"
class HTMLNotebook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notebookItems: [],
        }
    }
    componentWillMount() {
        if (this.props.notebookItems) {
            let { notebookItems } = this.props;
            let _notebookItems = Object.assign([], notebookItems);
            if (this.props.selected && this.props.selected.length > 0) {
                _notebookItems = _notebookItems.filter(x => this.props.selected.includes(x.id));
            }
            _notebookItems = _notebookItems.sort((a, b) => parseFloat(a.order_index) - parseFloat(b.order_index))
            let _references = Object.assign([], _notebookItems).filter(x => x.reference).map(x => x.reference).filter(this.onlyUnique).map(x => x.trim());
            this.setState({ notebookItems: _notebookItems, referencesItems: _references });
        }
    }
    onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
    }
    renderSummarizedText = () => {
        const { notebookItems, referencesItems } = this.state;
        if (notebookItems.filter(x => x.command_type !== "CreateTable").length > 0) {
            return (<>
                <div style={{ marginBottom: "5px" }}>
                    <div
                        style={{
                            width: "100%",
                            background: "#0070C0",
                            textAlign: "left",
                            padding: "4pt 4pt 4pt 4pt"
                        }}
                        className="nb-main-title"
                    >
                        <p style={{
                            wordBreak: "break-word",
                            fontWeight: "bold",
                            fontSize: titleFontSize,
                            color: "#ffffff",
                            margin: "3px",
                            fontFamily: "Verdana",
                            display: "inline",
                        }}>
                            Summarized Text
                        </p>
                    </div>
                    <ul>
                        {notebookItems.filter(x => x.summarized_text).map((item, index) => {
                            let referenceIndex = -1, summarizedText = '';
                            if (item.reference) {
                                referenceIndex = referencesItems.findIndex(x => x === item.reference.trim())
                            }
                            if (item.summarized_text) {
                                summarizedText = item.summarized_text.trim().replace(/\r\n/g, '<br />').replace(/\n/g, '<br />');
                                if (summarizedText.length !== 0 && summarizedText[summarizedText.length - 1] !== '.') {
                                    summarizedText += '.';
                                }
                            }
                            if (referenceIndex !== -1) {
                                summarizedText += `<sup>${referenceIndex + 1}</sup>`;
                            }
                            return (<li style={{ textAlign: "left", fontSize: normalFontSize, fontFamily: "Verdana", marginBottom: "3px" }} key={`SummarizedText${index}`} dangerouslySetInnerHTML={{ __html: sanitize(summarizedText) }}></li>)
                        })}
                    </ul>
                </div>
            </>)
        } else {
            return (<></>);
        }
    }
    renderOriginalText = () => {
        const { notebookItems, referencesItems } = this.state;
        return (<>
            <div style={{ marginBottom: "5px" }}>
                <div
                    style={{
                        width: "100%",
                        background: "#0070C0",
                        textAlign: "left",
                        padding: "4pt 4pt 4pt 4pt"
                    }}
                >
                    <p style={{
                        wordBreak: "break-word",
                        fontWeight: "bold",
                        fontSize: titleFontSize,
                        color: "#ffffff",
                        margin: "0px",
                        fontFamily: "Verdana"
                    }}>Original Text</p>
                </div>
                {notebookItems.map((item, index) => {
                    let referenceIndex = -1, originalText = '';
                    if (item.reference) {
                        referenceIndex = referencesItems.findIndex(x => x === item.reference.trim())
                    }
                    if (item.original_text) {
                        originalText = item.original_text.trim().replace(/\r\n/g, '<br />').replace(/\n/g, '<br />');
                        if (originalText.length !== 0 && originalText[originalText.length - 1] !== '.') {
                            originalText += '.';
                        }
                    }
                    if (referenceIndex !== -1) {
                        originalText += `<sup>${referenceIndex + 1}</sup>`
                    }
                    return (<p style={{ textAlign: "left", fontSize: normalFontSize, marginLeft: "5px", marginRight: "5px", marginBottom: "2px", fontFamily: "Verdana" }} key={`OriginalText${index}`} dangerouslySetInnerHTML={{ __html: sanitize(originalText) }}></p>)
                })}
            </div>
        </>)

    }
    renderReferenceText = () => {
        const { referencesItems } = this.state;
        return (<>
            <div style={{ marginBottom: "5px" }}>
                <div
                    style={{
                        width: "100%",
                        background: "#0070C0",
                        textAlign: "left",
                        padding: "4pt 4pt 4pt 4pt"
                    }}
                >
                    <p style={{
                        wordBreak: "break-word",
                        fontWeight: "bold",
                        fontSize: titleFontSize,
                        color: "#ffffff",
                        margin: "0px",
                        fontFamily: "Verdana"
                    }}>References</p>
                </div>
                <ol>
                    {referencesItems.map((item, index) => {
                        item = item.replace(/\r\n/g, '<br />').replace(/\n/g, '<br />');
                        return (<li style={{ textAlign: "left", fontSize: normalFontSize, fontFamily: "Verdana", marginBottom: "3px" }} key={`Reference${index}`} dangerouslySetInnerHTML={{ __html: sanitize(item) }}></li>)
                    })}
                </ol>
            </div>
        </>)

    }
    renderNotes = () => {
        const { notebookItems } = this.state;
        return (<>
            <div style={{ marginBottom: "5px" }}>
                <div
                    style={{
                        width: "100%",
                        background: "#0070C0",
                        textAlign: "left",
                        padding: "4pt 4pt 4pt 4pt"
                    }}
                >
                    <p style={{
                        wordBreak: "break-word",
                        fontWeight: "bold",
                        fontSize: titleFontSize,
                        color: "#ffffff",
                        margin: "0px",
                        fontFamily: "Verdana"
                    }}>Notes</p>
                </div>
                <ul>
                    {notebookItems.filter(x => x.notes).map((item, index) => {
                        let notes = item.notes.replace(/\r\n/g, '<br />').replace(/\n/g, '<br />');
                        return (<li style={{ textAlign: "left", fontSize: normalFontSize, fontFamily: "Verdana", marginBottom: "3px" }} key={`Notes${index}`} dangerouslySetInnerHTML={{ __html: sanitize(notes) }}></li>)
                    })}
                </ul>

            </div>
        </>)
    }
    renderTableFigure = () => {
        const { notebookItems } = this.state;
        if (notebookItems.filter(x => x.command_type === "CreateTable").length > 0) {
            return (<>
                <div style={{ marginBottom: "5px" }}>
                    <div
                        style={{
                            width: "100%",
                            background: "#0070C0",
                            textAlign: "left",
                            padding: "4pt 4pt 4pt 4pt"
                        }}
                    >
                        <p style={{
                            wordBreak: "break-word",
                            fontWeight: "bold",
                            fontSize: titleFontSize,
                            color: "#ffffff",
                            margin: "0px",
                            fontFamily: "Verdana"
                        }}>Tables and Figures</p>
                    </div>
                    {notebookItems.filter(x => x.command_type === "CreateTable").map((item, index) => {
                        let tableSummarizedHTML = item.table_summarized_html.replaceAll('<table>', '<table cellpadding="5" cellspacing="0"  bordercolor="#A9A9A9" border="1" style="width:100%">')
                        return (<><div className="message-text" dangerouslySetInnerHTML={{ __html: sanitize(tableSummarizedHTML) }} />
                            {(index < notebookItems.length - 2) && <br />}
                        </>)
                    })}
                </div>
            </>)
        } else {
            return (<></>);
        }
    }
    render() {
        let { isExportOriginalText } = this.props;
        return (
            <div style={{ width: "100%" }}>
                <div style={{ width: "100%", textAlign: "center", fontSize: "18px", fontFamily: "Verdana" }}><b>{this.props.notebookName}</b></div>
                {this.renderSummarizedText()}
                {isExportOriginalText && this.renderOriginalText()}
                {this.renderTableFigure()}
                {this.renderReferenceText()}
                {this.renderNotes()}
            </div>
        );
    }

}
export default HTMLNotebook;