/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import trackingService from '../../services/trackingService';
// import ActionBar from "../../components/ActionBar";

class MIAsReferencesDocumentData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: [],
            documentIndex: [],
            collapsed: false,
            appliedFilters: [],
            searchKeyword: '',
            pageSize: "",
            pageNumber: "",
            actionBarCollapsed: false,
            isCollapseAll: false,
        }
        this.width = "";
    }
    componentDidMount() {
        this.setState({
            item: this.props.item,
            documentIndex: this.props.documentIndex,
            collapsed: this.props.collapsed,
            appliedFilters: this.props.appliedFilters,
            searchKeyword: this.props.searchKeyword,
            pageSize: this.props.pageSize,
            pageNumber: this.props.pageNumber,
            isCollapseAll: this.props.isCollapseAll
        })
        if (this.props.customWidth < 1000) {
            this.width = "4%"
        }
    }
    componentDidUpdate = (prop) => {
        if (this.props.collapsed !== this.state.collapsed) {
            this.setState({ collapsed: this.props.collapsed })
        }
    }
    indexData = event => {
        var title = [event.target.getAttribute('data-title')];
        var products = [event.target.getAttribute('data-products')];
        var docnumber = [event.target.getAttribute('data-docnumber')];
        var docversion = [event.target.getAttribute('data-version')];
        var contentsource = [event.target.getAttribute('data-contentsource')];
        var docID = [event.target.getAttribute('data-docid')];
        var docType = [event.target.getAttribute('data-doctype')];
        var docUrl = [event.target.getAttribute('data-docurl')]
        // var doclastModified = [event.target.getAttribute('data-doclastmodified')]
        const ts = new trackingService();
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), 'View OR Download Document', 'Direct', 'Reference Used', this.state.searchKeyword, this.state.appliedFilters, title, docversion, docnumber, docID, docUrl, products, docType, 'View Document', this.props.csPath !== null ? this.props.csPath : '', this.props.csproduct !== null ? this.props.csproduct : '', contentsource, this.props.pageNumber, this.props.pageSize, '1900-01-01T00:00:00Z');
    }
    documentUrl = (url) => {
        this.props.citationUrl(url)
    }
    render() {
        let url = `/api/dcs-spo/download?connID=${this.state.item.connID}&csID=${this.state.item.csID}&docID=${this.state.item.docID}&fileType=${this.state.item.fileExtension}&fileName=${this.state.item.docTitle}`
        this.documentUrl(url)
        return (
            <div>
                <div className="results-title-action-outer-wrapper">
                    <span className="results-div-docTitle-wrapper">
                        <span style={{ color: "#0074B0", fontSize: "14px", fontWeight: "bold" }}>{this.state.documentIndex}. </span>
                        <span>
                            <a id="docdata" href={window.location.origin + url} target="_blank" onClick={this.indexData} data-title={this.state.item.docTitle} data-products={this.state.item.tempJNJDrugs} data-docnumber={this.state.item.domain} data-contentsource={this.state.item.domain} data-docid={this.state.item.searchID} data-doctype={this.state.item.docType} data-docurl={url} data-doclastmodified={this.state.item.createdDate}>
                                {this.state.item.docTitle}
                            </a>
                        </span>
                    </span>
                </div>
            </div>

        )
    }
}
export default MIAsReferencesDocumentData;