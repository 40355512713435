import { Component } from "react";
import { listIcon, brushIcon, brushIconBlack } from "../../assets";
import Button from "../../components/Button";
import PrivacyStatementPopUp from "../../components/PopUp/PrivacyStatementPopUp";
import MobileViewButton from "../../components/Button/MobileViewButton";

class GDPRAcceptance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: [],
      selectedRegion: [],
      gdprAcceptance: "Yes",
      profession: "",
      products: [],
      contentPreference: "",
      documentPreference: [],
      selectedAreas: [],
      selectedClasses: [],
      areaId: [],
      classId: [],
      productId: [],
      history: this.props.history,
      showPrivacy: false,
    };
    //console.log(this.props);
  }
  componentDidMount() {
    this.setState({
      selectedCountry: this.props.selectedCountry,
      selectedRegion: this.props.selectedRegion,
      gdprAcceptance: this.props.gdprAcceptance,
      profession: this.props.profession,
      products: this.props.products,
      contentPreference: this.props.contentPreference,
      documentPreference: this.props.documentPreference,
      selectedAreas: this.props.selectedAreas,
      selectedClasses: this.props.selectedClasses,
    });
    if (this.props.areaId.length > 0) {
      this.setState({
        areaId: this.props.areaId,
      });
    }
    if (this.props.classId.length > 0) {
      this.setState({
        classId: this.props.classId,
      });
    }
    if (this.props.productId.length > 0) {
      this.setState({
        productId: this.props.productId,
      });
    }
    //console.log(this.state);
  }
  handleGDPRNxt = () => {
    //console.log(this.state);
    this.props.onClickGDPRNext(
      this.state.selectedRegion,
      this.state.selectedCountry,
      this.state.gdprAcceptance,
      this.state.profession,
      this.state.products,
      this.state.contentPreference,
      this.state.documentPreference,
      this.state.selectedAreas,
      this.state.selectedClasses,
      this.state.history,
      this.state.areaId,
      this.state.classId,
      this.state.productId
    );
  };
  handleGDPRPrev = () => {
    //console.log(this.state);
    this.props.onClickGDPRPrev(
      this.state.selectedRegion,
      this.state.selectedCountry,
      this.state.gdprAcceptance,
      this.state.profession,
      this.state.products,
      this.state.contentPreference,
      this.state.documentPreference,
      this.state.selectedAreas,
      this.state.selectedClasses,
      this.state.history,
      this.state.areaId,
      this.state.classId,
      this.state.productId
    );
  };
  handleOnChange = (event) => {
    this.setState(
      {
        gdprAcceptance: event.target.value,
      },
      this.handleChangeInGdpr
    );
  };
  handleChangeInGdpr = () => {
    this.props.onChangeGdpr(this.state.gdprAcceptance);
  };
  render() {
    return (
      <div className="registrationInnerWrapper">
        {/* <div className="welcomePageTitle">
                </div> */}
        <div className="gdprAcceptanceContent">
          <div className="gdprTitlePart">
            <img
              src={this.props.customWidth < 1000 ? brushIconBlack : brushIcon}
              alt="brush-iocn"
            />
            <div className="gdprTitle">
              Opt In for a more personalized user experience
            </div>
          </div>
          <strong className="locationTextTitle" id="sub-heading">
            Get the most relevant content more easily and efficiently
          </strong>
          <p></p>
          <p className="contentText">
            iMedical Search performs user activity tracking to help understand
            what you – and your colleagues – are searching for, enabling it to
            more effectively deliver information that is most relevant to you
          </p>
          <p></p>
          <strong>By opting in to the user tracking system, you will:</strong>
          <ul
            style={{
              listStyleType: "none",
              color: "black",
              padding: this.props.customWidth < 600 && "0px",
            }}
            className="userTrackingListing"
          >
            <li>
              <img src={listIcon} alt="List" className="listIcon" />
              Receive more focused results and recommendations tailored to your
              search requirements.
            </li>
            <li>
              <img src={listIcon} alt="List" className="listIcon" />
              Push the most relevant content to the top of your search results.
            </li>
          </ul>
          <p>
            Please make your selection below and click{" "}
            <span className="continueText">'Continue'.</span>
          </p>
          <p
            className="selectionContainer"
            style={{ padding: this.props.customWidth < 600 && "0px" }}
          >
            <input
              type="radio"
              className=""
              name="yesToGdpr"
              value="Yes"
              onChange={this.handleOnChange}
              checked={this.state.gdprAcceptance === "Yes"}
              class="k-radio"
            />
            <span className="radioTextWrapper">
              Yes, I would like to opt in to user activity tracking for a more
              personalized, relevant search experience
            </span>
            <br />
            <input
              type="radio"
              className=""
              name="noToGdpr"
              value="No"
              onChange={this.handleOnChange}
              checked={this.state.gdprAcceptance === "No"}
              class="k-radio"
            />
            <span className="radioTextWrapper">
              No, I would like to opt out of user activity tracking. I
              understand that by opting out, I will not receive personalized
              recommendations, and content in my search results will not be
              prioritized based on my previous activity within iMedical Search
            </span>
            <br />
          </p>
          <br />
          <p className="gdprLinktoPrivacy">
            <span
              onClick={(e) => {
                this.setState({ showPrivacy: true });
              }}
            >
              Review the iMedical Search Privacy Statement.
            </span>
          </p>
          {/* <div><br /></div> */}
          {/* <div><br /></div> */}
          {this.props.customWidth < 600 ? (
            <div className="gsf-popup-button-wrapper-mobile">
              <div className="welcomeNxtBtn-mobile">
                <MobileViewButton
                  text="Continue"
                  customColor="#2EB000"
                  action={this.handleGDPRNxt}
                  className="continueButton"
                />
              </div>
              <div className="welcomeNxtBtn-mobile">
                <MobileViewButton
                  text="Previous"
                  customColor="#FF8000"
                  action={this.handleGDPRPrev}
                />
              </div>
              <div className="welcomeNxtBtn-mobile">
                <MobileViewButton
                  text="Cancel"
                  customColor="#0074B0"
                  action={() => {
                    this.props.history.push("/");
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="buttonContainer">
              <div className="welcomeNxtBtn">
                <Button
                  text="Continue"
                  customColor="#2EB000"
                  action={this.handleGDPRNxt}
                  className="continueButton"
                />
              </div>
              <div className="welcomeNxtBtn">
                <Button
                  text="Previous"
                  customColor="#FF8000"
                  action={this.handleGDPRPrev}
                />
              </div>
              <div className="welcomeNxtBtn">
                <Button
                  text="Cancel"
                  customColor="#0074B0"
                  action={() => {
                    this.props.history.push("/");
                  }}
                />
              </div>
            </div>
          )}
          {this.state.showPrivacy && (
            <PrivacyStatementPopUp
              display={this.state.showPrivacy}
              handleClose={(status) => {
                this.setState({ showPrivacy: status });
              }}
            />
          )}
        </div>
      </div>
    );
  }
}
export default GDPRAcceptance;
