import { Component } from "react";
import ApiServices from "../../utils/apiServices";
import { imsLoader } from "../../assets";
import '../Pages/Registration.css';

class CountrySelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countries: [],
            regionChecked: '',
            selectedCountry: [],
            prevselectedCountry: [],
            selectedRegion: [],
            ischecked: true,
            isLoaded: this.props.regionChecked === 'Global' || this.props.regionChecked === 'North America-United States' || this.props.regionChecked === 'North America-Canada' || this.props.regionChecked === 'AP-Japan' ? true : false
        }
        //console.log(this.props);
    }
    async componentDidMount() {
        const api = new ApiServices();
        var response = await api.getAllCountries();
        //console.log(response)
        if (response) {
            this.setState({
                countries: response,
                regionChecked: this.props.regionChecked,
                selectedRegion: this.props.selectedRegion,
                isLoaded: true
                //selectedCountry:this.props.selectedCountry
            });
            if (this.props.selectedCountry.length > 0) {
                this.setState({
                    selectedCountry: this.props.selectedCountry
                })
            }
        }
    }
    isEqual(a, b) {
        if(!a || !b){
            return false
          }else
        return a.join() === b.join();
    }
    componentWillReceiveProps(receProps) {
        if (!this.isEqual(receProps.selectedCountry, this.state.selectedCountry)) {
            this.setState({
                selectedCountry: receProps.selectedCountry
            })
        }
    }
    componentDidUpdate = (prevprop) => {
        if (this.props.regionChecked.length <= 0) {
            if (this.props.regionChecked !== prevprop.regionChecked) {
                this.setState({ selectedCountry: [] })
            }
        }
    }
    handleChangeInSelectedCountries = () => {
        this.props.onSelectCountries(this.state.selectedCountry);
    }
    handleSelectedCountry = (event) => {
        if (event.target.checked === true) {
            this.setState({
                selectedCountry: this.state.selectedCountry.concat([event.target.value])
            }, this.handleChangeInSelectedCountries);
        }
        else {
            var arr = [...this.state.selectedCountry];
            var idx = arr.indexOf(event.target.value);
            if (idx !== -1) {
                arr.splice(idx, 1);
                this.setState({
                    selectedCountry: arr
                }, this.handleChangeInSelectedCountries);
            }
            //console.log(this.state.selectedCountry);
            //this.handleChangeInSelectedCountries();

        }
        //console.log(this.state.selectedCountry);
    }
    // countries = (countriesListDate) => {
    //     //var v1= this.props.regionChecked;
    //     if (countriesListDate.length > 0) {
    //         return (
    //             countriesListDate.map((item, index) => {
    //                 if (item.country != null && index < (countriesListDate.length / 2)) {
    //                     if (this.state.selectedCountry.includes(item.country)) {
    //                         if (this.props.customWidth < 1000) {
    //                             return (
    //                                 <div className="cntryDiv-tablet" key={index}>
    //                                     {/* <img src={rightArrow} alt="arrow" className="locationRightArrow" /> */}
    //                                     <input type="checkbox" name="userCountries" value={item.country} onChange={this.handleSelectedCountry} defaultChecked={true} key={item.country + Math.random()} class="k-checkbox"
    //                                     // className="locationCheckbox"
    //                                     />
    //                                     <span className="regionTxt-tablet" >{item.country}</span>
    //                                 </div>
    //                             )
    //                         }
    //                         else {
    //                             return (
    //                                 <div className="cntryDiv" key={index}>
    //                                     {/* <img src={rightArrow} alt="arrow" className="locationRightArrow" /> */}
    //                                     <input type="checkbox" name="userCountries" value={item.country} onChange={this.handleSelectedCountry} defaultChecked={true} key={item.country + Math.random()} class="k-checkbox"
    //                                     // className="locationCheckbox"
    //                                     />
    //                                     <span className="regionTxt" >{item.country}</span>
    //                                 </div>
    //                             )
    //                         }
    //                     }
    //                     else {
    //                         if (this.props.customWidth < 1000) {
    //                             return (
    //                                 <div className="cntryDiv-tablet" key={index}>
    //                                     {/* <img src={rightArrow} alt="arrow" className="locationRightArrow" /> */}
    //                                     <input type="checkbox" name="userCountries" value={item.country} onChange={this.handleSelectedCountry} defaultChecked={false} key={item.country + Math.random()} class="k-checkbox" /* class="k-checkbox" */
    //                                     // className="locationCheckbox"
    //                                     />
    //                                     <span className="regionTxt-tablet" >{item.country}</span>
    //                                 </div>
    //                             )
    //                         }
    //                         else {
    //                             return (
    //                                 <div className="cntryDiv" key={index}>
    //                                     {/* <img src={rightArrow} alt="arrow" className="locationRightArrow" /> */}
    //                                     <input type="checkbox" name="userCountries" value={item.country} onChange={this.handleSelectedCountry} defaultChecked={false} key={item.country + Math.random()} class="k-checkbox" /* class="k-checkbox" */
    //                                     // className="locationCheckbox"
    //                                     />
    //                                     <span className="regionTxt" >{item.country}</span>
    //                                 </div>
    //                             )
    //                         }
    //                     }
    //                 }
    //             })
    //         )
    //     }
    // }
    // countriesPart = (countriesListDate) => {
    //     //var v1= this.props.regionChecked;
    //     if (countriesListDate.length > 0) {
    //         return (
    //             countriesListDate.map((item, index) => {
    //                 if (item.country != null && index >= (countriesListDate.length / 2)) {
    //                     if (this.state.selectedCountry.includes(item.country)) {
    //                         if (this.props.customWidth < 1000) {
    //                             return (
    //                                 <div className="cntryDiv-tablet" key={index}>
    //                                     {/* <img src={rightArrow} alt="arrow" className="locationRightArrow" /> */}
    //                                     <input type="checkbox" name="userCountries" value={item.country} onChange={this.handleSelectedCountry} defaultChecked={true} key={item.country + Math.random()} class="k-checkbox"
    //                                     // className="locationCheckbox"
    //                                     />
    //                                     <span className="regionTxt-tablet" >{item.country}</span>
    //                                 </div>
    //                             )
    //                         }
    //                         else {
    //                             return (
    //                                 <div className="cntryDiv" key={index}>
    //                                     {/* <img src={rightArrow} alt="arrow" className="locationRightArrow" /> */}
    //                                     <input type="checkbox" name="userCountries" value={item.country} onChange={this.handleSelectedCountry} defaultChecked={true} key={item.country + Math.random()} class="k-checkbox"
    //                                     // className="locationCheckbox"
    //                                     />
    //                                     <span className="regionTxt" >{item.country}</span>
    //                                 </div>
    //                             )
    //                         }
    //                     }
    //                     else {
    //                         if (this.props.customWidth < 1000) {
    //                             return (
    //                                 <div className="cntryDiv-tablet" key={index}>
    //                                     {/* <img src={rightArrow} alt="arrow" className="locationRightArrow" /> */}
    //                                     <input type="checkbox" name="userCountries" value={item.country} onChange={this.handleSelectedCountry} defaultChecked={false} key={item.country + Math.random()} class="k-checkbox" /* class="k-checkbox" */
    //                                     // className="locationCheckbox"
    //                                     />
    //                                     <span className="regionTxt-tablet" >{item.country}</span>
    //                                 </div>
    //                             )
    //                         }
    //                         else {
    //                             return (
    //                                 <div className="cntryDiv" key={index}>
    //                                     {/* <img src={rightArrow} alt="arrow" className="locationRightArrow" /> */}
    //                                     <input type="checkbox" name="userCountries" value={item.country} onChange={this.handleSelectedCountry} defaultChecked={false} key={item.country + Math.random()} class="k-checkbox" /* class="k-checkbox" */
    //                                     // className="locationCheckbox"
    //                                     />
    //                                     <span className="regionTxt" >{item.country}</span>
    //                                 </div>
    //                             )
    //                         }
    //                     }
    //                 }
    //             })
    //         )
    //     }
    // }
    countriesListing = (countriesListDate) => {
        if (countriesListDate.length > 0) {
            if (this.props.customWidth < 600) {
                return (
                    countriesListDate.map((item, index) => {
                        if (item.country != null) {
                            let data = countriesListDate.length % 2 === 0 ? countriesListDate.splice(0, 20) : countriesListDate.splice(0, 21)
                            return (
                                <div className="countrylistpartion">
                                    {data.map((item, index) => {
                                        if (this.state.selectedCountry.includes(item.country)) {
                                            return (
                                                <div className="cntryDiv-tablet" key={index}>
                                                    <input type="checkbox" name="userCountries" value={item.country} onChange={this.handleSelectedCountry} defaultChecked={true} key={item.country + Math.random()} class="k-checkbox" style={{ marginTop: "1%" }} />
                                                    <span className="regionTxt-tablet" >{item.country}</span>
                                                </div>
                                            )
                                        }
                                        else {
                                            return (
                                                <div className="cntryDiv-tablet" key={index}>
                                                    <input type="checkbox" name="userCountries" value={item.country} onChange={this.handleSelectedCountry} defaultChecked={false} key={item.country + Math.random()} class="k-checkbox" style={{ marginTop: "1%" }} />
                                                    <span className="regionTxt-tablet" >{item.country}</span>
                                                </div>
                                            )

                                        }
                                    })
                                    }
                                </div>
                            )
                        }
                        return null;
                    })
                )
            } else {
                return (
                    countriesListDate.map((item, index) => {
                        if (item.country != null) {
                            let data = countriesListDate.length % 2 === 0 ? countriesListDate.splice(0, 10) : countriesListDate.splice(0, 11)
                            return (
                                <div className="countrylistpartion">
                                    {data.map((item, index) => {
                                        if (this.state.selectedCountry.includes(item.country)) {
                                            return (
                                                <div className="cntryDiv-tablet" key={index}>
                                                    <input type="checkbox" name="userCountries" value={item.country} onChange={this.handleSelectedCountry} defaultChecked={true} key={item.country + Math.random()} class="k-checkbox" style={{ marginTop: "1%" }} />
                                                    <span className="regionTxt-tablet" >{item.country}</span>
                                                </div>
                                            )
                                        }
                                        else {
                                            return (
                                                <div className="cntryDiv-tablet" key={index}>
                                                    <input type="checkbox" name="userCountries" value={item.country} onChange={this.handleSelectedCountry} defaultChecked={false} key={item.country + Math.random()} class="k-checkbox" style={{ marginTop: "1%" }} />
                                                    <span className="regionTxt-tablet" >{item.country}</span>
                                                </div>
                                            )

                                        }
                                    })
                                    }
                                </div>
                            )
                        }
                        return null;
                    })
                )
            }
        }
    }
    render() {
        var v2;
        var list;
        v2 = this.props.regionChecked;
        //console.log(this.state.selectedCountry);
        list = this.state.countries.filter(function (countryList) {
            return countryList.country != null && countryList.region === v2
        })
        // console.log(list)
        return (
            <div>
                {this.state.isLoaded ?
                    <div>
                        {list.length > 0 ?
                            <div className="countryWrapper">
                                <div className="countryText">
                                    <strong style={{ fontSize: "16px" }}>Next, select the specific country(ies) where you have product responsibilities.<br /></strong><span className="locationChildText">This may be different from the location where you are physically located.</span><br /><br />
                                    <div className="countryListWrapper">
                                        <div className="countryPartDiv" id="countryPartDiv">
                                            {this.countriesListing(list)}
                                        </div>
                                        {/* <div className="countriesListing">
                                            <div>
                                                //<div className="topCircle"></div>
                                                <div className="countryPartDiv" id="countryPartDiv">
                                                    {this.countries(list)}
                                                </div>
                                            </div>
                                            <div className="countriesSecondCol">
                                                //<div className="topCircle"></div>
                                                <div className="countryPartDiv" id="countryPartDiv">
                                                    {this.countriesPart(list)}
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <p className="locationChildText">Click 'Continue' to further personalize your search experience.</p>
                                </div>
                            </div> : null}
                    </div> :
                    <div style={{ textAlign: "center" }}>
                        <img src={imsLoader} alt="Loading Data" style={{ width: "7%" }} />
                    </div>}
            </div>
        )
    }
}
export default CountrySelection;