import { Component } from "react";
import RawValuesDocumentData from "./RawValuesDocumentData";


class RawValuesDocumentCard extends Component{
    constructor(props){
        super(props);
        this.state={
            collapsed:false,
            actionBarCollapsed:false,
            documentIndex:this.props.count,
            item:[]
        }
        
    }
    // componentWillMount(){
    //     const script = document.createElement("script");
    //     script.src = "https://content.readcube.com/injectable-button-assets/readcube-button.min.js";
    //     script.async = true;
    //     document.body.appendChild(script);
    // }
    componentDidMount(){
        //console.log(this.props)
        this.setState({
            item:this.props.item
        })
 
        //console.log(this.props.item);
        //console.log(this.state.item)
    }
    render(){
            return(
                <div className="resultsDetailsDivWrapper">
                    <div className="citation-outer-wrapper">
                        <div style={{float:"left",width:"100%"}}>
                            <RawValuesDocumentData documentIndex={this.state.documentIndex} item={this.props.item} score={this.props.score} appliedFilters={this.props.appliedFilters} searchKeyword={this.props.searchKeyword} pageNumber={this.props.pageNumber} pageSize={this.props.pageSize}/>
                        </div>
                    </div>
                    {/* <div className="raw-values-data-title">
                        {!this.state.collapsed?
                        <span title="Collapse Details" onClick={this.handleToggleSelect} style={{cursor:"pointer"}}>Hide All</span>:
                        <span title="Expand Details" onClick={this.handleToggleSelect} style={{cursor:"pointer"}}>Show All</span>}
                    </div> */}
                </div>
            
        
        )
    }

}
export default RawValuesDocumentCard;