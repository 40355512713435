import React, { Component } from "react";
import { IMSLogo, dropdownArrowBlack, crossIcon, magnifyingGlass } from '../../assets'
import { Link } from 'react-router-dom';
import GenerateArrayStrings from "../GenerateArrayStrings";

class HeaderForRawValues extends Component {
    constructor(props) {
        super(props);
        /* this.inputBoxRef = React.createRef(); */
        this.state = {
            openPopUp: false,
            showNav: false,
            infoPopUp: false,
            dropdownValue: 'All Fields',
            searchKeyword: '',
            appliedFilters: [], //Filters that are applied for while search is happening
            showOptions: false,
            showSuggestions: false,
            keyBoardEvent: false,
            keyEventName: '',
            fieldedValue: ''
        }
    }
    componentDidMount() {
        //console.log(this.props.searchKeyword);
        if (this.props.dropdownValue !== '' && this.props.searchKeyword !== '') {
            this.setState({
                dropdownValue: this.props.dropdownValue,
                searchKeyword: this.props.searchKeyword,
                appliedFilters: this.props.appliedFilters
            })
        }
        //window.addEventListener('blur',this.handleCloseSuggestions)
    }
    componentWillUnmount() {
        //window.removeEventListener('blur',this.handleCloseSuggestions)
    }
    handleNav = () => {
        this.setState({
            showNav: !this.state.showNav
        })
    }
    handleClose = () => {
        this.setState({
            showNav: false
        })
    }
    handleCategoryDropdown = () => {
        this.setState({
            showOptions: !this.state.showOptions
        })
    }
    handleChangeInDropdown = (event) => {
        //console.log(event.target.attributes.keyValue.value);
        let fieldedVal;
        event.preventDefault();
        if (event.target.attributes.keyvalue.value === 'All Fields') {
            fieldedVal = ''
        }
        else if (event.target.attributes.keyvalue.value === 'Author') {
            fieldedVal = 'authors'
        }
        else if (event.target.attributes.keyvalue.value === 'Congress') {
            fieldedVal = 'fullCnSf'
        }
        else if (event.target.attributes.keyvalue.value === 'Document Type') {
            fieldedVal = 'docTypeSynonyms'
        }
        else if (event.target.attributes.keyvalue.value === 'Title') {
            fieldedVal = 'docTitle'
        }
        else if (event.target.attributes.keyvalue.value === 'Publication Year') {
            fieldedVal = 'strYear'
        }
        else if (event.target.attributes.keyvalue.value === 'Journal Name') {
            fieldedVal = 'journalName'
        }
        //const newUrl = this.buildUrl(this.state.searchKeyword,fieldedVal,this.props.appliedFilters)
        this.setState({
            dropdownValue: event.target.attributes.keyvalue.value,
            showOptions: !this.state.showOptions,
            fieldedValue: fieldedVal
        })
    }
    handleChangeInSearch = (event) => {
        /* let filters = this.props.location.search
        console.log(filters) */
        this.setState({
            searchKeyword: event.target.value,
            showSuggestions: true
        })
    }
    handleCancelSearchKeyword = () => {
        this.setState({
            searchKeyword: '',
            showSuggestions: false
        })
    }
    buildUrl = (searchKeyword, dropdownValue, fieldedVal, filters) => {
        //console.log(this.props.location.search)
        let filterStr = GenerateArrayStrings(filters)
        if (searchKeyword === undefined || dropdownValue === undefined || filters === undefined) {
            return `/rawvalues?query=&q=&filters=`;
        }
        else if (dropdownValue === 'All Fields') {
            return `/rawvalues?query=${searchKeyword}&q=All Fields&filters=${filterStr}`
        }
        else if (searchKeyword === '') {
            return `/rawvalues?query=&q=All Fields&filters=${filterStr}`
        }
        else {
            return `/rawvalues?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}&f1=${fieldedVal}&fval1=${searchKeyword}&fcount=1`;
        }
    }
    handleKeyDown = (event) => {
        //console.log(this.props.appliedFilters)
        const newUrl = this.buildUrl(event.target.value, this.state.dropdownValue, this.state.fieldedValue, this.props.appliedFilters);
        if (['Enter'].includes(event.key)) {
            this.setState({
                showSuggestions: false
            });
            this.props.handleChangeInSearch(this.state.searchKeyword);
            this.props.history.push(newUrl);
            //const ts = new trackingService();
            //ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), 'Conducted Search', '', window.location.href, this.state.searchKeyword, this.props.appliedFilters);
        }
        if (['ArrowDown'].includes(event.key) || ['ArrowUp'].includes(event.key)) {
            //console.log('Arrow Action')
            this.setState({
                keyBoardEvent: true,
                keyEventName: event.key
            })
        }
    }
    handleOnClickSearchIcon = () => {
        /* let filters = this.props.location.search */
        //console.log(this.state)
        if (this.state.searchKeyword === '') {
            this.setState({
                dropdownValue: 'All Fields'
            })
        }
        const newUrl = this.buildUrl(this.state.searchKeyword, this.state.dropdownValue, this.state.fieldedValue, this.props.appliedFilters);
        this.props.handleChangeInSearch(this.state.searchKeyword);
        this.props.history.push(newUrl);
        //const ts = new trackingService();
        //ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), 'Conducted Search', '', window.location.href, this.state.searchKeyword, this.props.appliedFilters);
        this.setState({
            keyBoardEvent: false,
            keyEventName: '',
            showSuggestions: false,
        })
    }
    handleOnChange = (query, fieldParam) => {
        let fieldVal, dropdownValue;
        if (fieldParam === 'all Authors') {
            fieldVal = 'authors'
            dropdownValue = 'Author'
        }
        else if (fieldParam === 'all Congresses') {
            fieldVal = 'fullCnSf'
            dropdownValue = 'Congress'
        }
        else if (fieldParam === 'all Titles') {
            fieldVal = 'docTitle'
            dropdownValue = 'Title'
        }
        else if (fieldParam === 'all Document Types') {
            fieldVal = 'docTypeSynonyms'
            dropdownValue = 'Document Type'
        }
        else if (fieldParam === 'all Journals') {
            fieldVal = 'journalName'
            dropdownValue = 'Journal Name'
        }
        else if (fieldParam === 'all Publication Years') {
            fieldVal = 'strYear'
            dropdownValue = 'Publication Year'
        }
        else if (fieldParam === null) {
            fieldVal = this.state.fieldedValue
            dropdownValue = this.state.dropdownValue
            console.log(fieldVal)
        }
        else {
            fieldVal = ''
            dropdownValue = 'All Fields'
        }
        this.setState({
            searchKeyword: query,
            showSuggestions: false,
            fieldedValue: fieldVal,
            dropdownValue: dropdownValue
        }, () => {
            this.handleOnClickSearchIcon()
        })
    }
    handleCategoryClose = () => {
        this.setState({
            showOptions: false
        })
    }
    handleCloseSuggestions = (event) => {
        this.setState({
            showSuggestions: false
        })
        /* if(this.inputBoxRef && !this.inputBoxRef.current.contains(event.target)){
            this.setState({
                showSuggestions : false
            })
        } */
    }
    render() {
        return (
            <div className="headerWithBannerWrapper">
                <div className="bannerImage">
                    <Link to="/">
                        <span><img src={IMSLogo} alt="" style={{ marginBottom:"0.5%" }} /></span>
                    </Link>
                    <div className="search-input-wrapper">
                        <div className="dropdown searchSelect" onMouseLeave={this.handleCategoryClose}>
                            <button className="btn" onClick={this.handleCategoryDropdown}>
                                <span>{this.state.dropdownValue}</span>
                                <span>
                                    <img src={dropdownArrowBlack} alt="dropdownIcon" style={{ transform: this.state.showOptions ? "matrix(-1, 0, 0, -1, 0, 0)" : null, margin: this.state.showOptions ? "0px 0px 14px 0px" : "5px 0px 0px 0px" }} />
                                </span>
                            </button>
                            {this.state.showOptions ?
                                <ul className="allFieldsListing">
                                    {this.state.dropdownValue === "All Fields" ? null :
                                        <li keyvalue="All Fields" onClick={this.handleChangeInDropdown}>All Fields</li>
                                    }
                                    {this.state.dropdownValue === "Author" ? null :
                                        <li keyvalue="Author" onClick={this.handleChangeInDropdown}>Author</li>
                                    }
                                    {this.state.dropdownValue === "Congress" ? null :
                                        <li keyvalue="Congress" onClick={this.handleChangeInDropdown}>Congress</li>
                                    }
                                    {this.state.dropdownValue === "Document Type" ? null :
                                        <li keyvalue="Document Type" onClick={this.handleChangeInDropdown}>Document Type</li>
                                    }
                                    {this.state.dropdownValue === "Title" ? null :
                                        <li keyvalue="Title" onClick={this.handleChangeInDropdown}>Title</li>
                                    }
                                    {this.state.dropdownValue === "Publication Year" ? null :
                                        <li keyvalue="Publication Year" onClick={this.handleChangeInDropdown}>Publication Year</li>
                                    }
                                    {this.state.dropdownValue === "Journal Name" ? null :
                                        <li keyvalue="Journal Name" onClick={this.handleChangeInDropdown}>Journal Name</li>
                                    }
                                </ul> : null}
                        </div>
                        <div className="input-box-wrapper">
                            <input name="searchBar" placeholder="What are you looking for?" className="input-search-box" title="What are you looking for?" value={this.state.searchKeyword} onChange={this.handleChangeInSearch} onKeyDown={this.handleKeyDown} />
                            
                                <span className="icon-wrapper">
                                    {this.state.searchKeyword &&
                                        <img
                                            src={crossIcon}
                                            className="input-search-cancel-icon"
                                            alt="clear"
                                            title="Click here to clear the search terms"
                                            onClick={this.handleCancelSearchKeyword}
                                        />
                                    }
                                    <span className="search-icon-wrapper-webView" onClick={this.handleOnClickSearchIcon}>
                                    <img
                                        src={magnifyingGlass}
                                        className="magnifyingGlass-mobileView"
                                        alt="search"
                                        title="Search"
                                    />
                                    </span>
                                </span>
                                {/* <img src={searchIcon} className="search-icon-wrapper" alt="search" title="search" onClick={this.handleOnClickSearchIcon} />
                                {this.state.searchKeyword &&
                                <img src={crossIcn} className="input-search-cancel-icon" alt="clear" title="Click here to clear the search terms" onClick={this.handleCancelSearchKeyword}/>
                                }
                                <FontAwesomeIcon 
                                className='search-icon-wrapper'
                                icon="search"
                                fixedWidth
                                color='#979797'
                                title="Search" 
                                onClick={this.handleOnClickSearchIcon}
                                /> */}
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default HeaderForRawValues;