import React from 'react'
import PropTypes from 'prop-types'

export default function PageItems (props) {
    const { itemsPerPage,handleChangeSelectItems,customWidth } = props
    return (
      <div className="pagination-select-outer-wrapper">
          <span className="pagination-select-label">Items Per Page:&nbsp;</span>
          <span style={{width:customWidth ? "35%" : "30%"}}>
            <select style={{outline:"none"}} className="pagination-select-wrapper" onChange={e=>handleChangeSelectItems(e.target.value)} defaultValue={itemsPerPage}>
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>75</option>
                <option>100</option>
                <option>200</option>
                <option>400</option>
            </select>
          </span>
      </div>
    )
}
PageItems.propTypes = {
    itemsPerPage:PropTypes.string.isRequired,
    handleChangeSelectItems: PropTypes.func
}

