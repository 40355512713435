import { Component } from "react";
import HeaderWithBanner from "../../components/header/HeaderWithBanner";
import Footer from '../../components/footer/Footer';
import { imsLoader } from '../../assets'
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import NavigationMenu from "../../components/Navigation/NavigationMenu";
import Services from "../../utils/services";
import { Link } from "react-router-dom";
import ProductAlertsPopup from "../../components/PopUp/ProductAlertsPopUp";
import NotifyMePopUp from "../../components/PopUp/NotifyMePopUp";
import "./ManageAlerts.css"
import CCDSUSLabelingAlertsPopUp from "../../components/PopUp/CCDSUSLabelingAlertsPopUp";

class ManageAlerts extends Component {
    userid;
    userExists;
    constructor(props) {
        super(props);
        this.state = {
            userExists: false,
            width: window.innerWidth,
            height: window.innerHeight,
            showProductAlerts: false,
            showCCDSAndUSLabelingAlerts: false,
            showPersonalAlerts: false
        }
    }
    async componentDidMount() {
        document.title = 'Manage Alerts';
        this.userid = JSON.parse(localStorage.getItem('username'));
        this.userExists = localStorage.getItem('userExists');
        let service = new Services();
        service.logService(`Manage alerts page successfully mounted for ${this.userid.toLowerCase()}`, 'info')
        if (this.userExists) {
            this.setState({
                userExists: this.userExists
            })
            window.addEventListener('resize', this.updateDimensions);
        }
        else {
            console.log('Access Denied');
            this.props.history.push('/');
        }
    }
    componentWillUnmount() {
        let service = new Services();
        window.removeEventListener('resize', this.updateDimensions);
        service.logService(`Manage alerts page successfully unmounted for ${this.userid.toLowerCase()}`, 'info')
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight })
    }
    render() {
        if (this.state.width < 600) {
            return (
                <ErrorBoundary>
                    {this.state.userExists ?
                        <>
                            <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                            <div className="header-wrapper">
                                {this.state.width > 600 && <HeaderWithBanner />}
                            </div>
                            <div className="manage-alerts-wrapper">
                                <h2>Manage Alerts</h2>
                                <hr />
                                <div>
                                    <div className="manage-alerts-list-mobile">
                                        <Link className="manage-alerts-link" onClick={() => this.setState({ showPersonalAlerts: true })}>Manage your own individually-created search alerts</Link>
                                        <br />
                                        <span>Manage the alerts you’ve created based on the search criteria you’ve chosen.</span>
                                    </div>
                                    <div className="manage-alerts-list-mobile">
                                        <Link className="manage-alerts-link" to="/ManageAlerts" onClick={() => this.setState({ showProductAlerts: true })}>Sign up for and manage "pre-packaged" product alerts</Link>
                                        <br />
                                        <span>These product alerts are pre-created for you so there is no need for you to create them yourself. Simply select the products of interest from the list. The alerts will retrieve any content that is focused on that product. You can unsubscribe at any time by returning to this selection.</span>
                                    </div>
                                    <div className="manage-alerts-list-mobile">
                                        <Link className="manage-alerts-link"  to="/ManageAlerts" onClick={() => this.setState({ showCCDSAndUSLabelingAlerts: true })}>Sign up for and manage "pre-packaged" CCDS and US Labeling alerts</Link>
                                        <br />
                                        <span>These labeling alerts are pre-created for you so there is no need for you to create them yourself. Simply select the label type of interest from the list. The alerts will retrieve any labels from the selected categories. You can unsubscribe at any time by returning to this selection.</span>
                                    </div>
                                    <div className="manage-alerts-list-mobile">
                                        <Link className="manage-alerts-link" to={{ pathname: "https://jnjenewsstand.infodesk.com/pages.html?id=0" }} target="_blank">Manage your industry/news alerts through the JNJ eNews Stand</Link>
                                        <br />
                                        <span>The JNJ eNews Stand service enables you to subscribe to a wide variety of industry and news publications. The JNJ eNews Stand will appear in a new window, where you can un/subscribe at any time.</span>
                                    </div>
                                </div>
                            </div>
                            <p>
                                <br />
                            </p>
                            <p>
                                <br />
                            </p>
                            <div style={{ margin: "0 1.5%", background: "#ffffff" }} class={this.state.height > 750 ? "fixed-bottom" : null}>
                                <Footer customWidth={this.state.width} customHeight={this.state.height} />
                            </div>
                            <ProductAlertsPopup showProductAlerts={this.state.showProductAlerts} handleClose={() => this.setState({ showProductAlerts: false })} customWidth={this.state.width} />
                            <CCDSUSLabelingAlertsPopUp showCCDSAndUSLabelingAlerts={this.state.showCCDSAndUSLabelingAlerts} handleClose={()=>this.setState({showCCDSAndUSLabelingAlerts:false})} customWidth={this.state.width}/>
                            <NotifyMePopUp showPersonalAlerts={this.state.showPersonalAlerts} handleClose={() => this.setState({ showPersonalAlerts: false })} customWidth={this.state.width} />
                        </>
                        :
                        <div style={{ textAlign: "center", padding: "10%" }}>
                            <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
                        </div>
                    }
                </ErrorBoundary>
            )
        }
        else {
            return (
                <ErrorBoundary>
                    {this.state.userExists ?
                        <>
                            <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                            <div className="header-wrapper">
                                {this.state.width > 600 && <HeaderWithBanner />}
                            </div>
                            <div className="manage-alerts-wrapper">
                                <h2>Manage Alerts</h2>
                                <ul>
                                    <li className="manage-alerts-list">
                                        <Link className="manage-alerts-link" onClick={() => this.setState({ showPersonalAlerts: true })}>Manage your own individually-created search alerts</Link>
                                        <br />
                                        <span>Manage the alerts you’ve created based on the search criteria you’ve chosen.</span>
                                    </li>
                                    <li className="manage-alerts-list">
                                        <Link className="manage-alerts-link" to="/ManageAlerts" onClick={() => this.setState({ showProductAlerts: true })}>Sign up for and manage "pre-packaged" product alerts</Link>
                                        <br />
                                        <span>These product alerts are pre-created for you so there is no need for you to create them yourself. Simply select the products of interest from the list. The alerts will retrieve any content that is focused on that product. You can unsubscribe at any time by returning to this selection.</span>
                                    </li>
                                    <li className="manage-alerts-list">
                                        <Link className="manage-alerts-link" to="/ManageAlerts" onClick={() => this.setState({ showCCDSAndUSLabelingAlerts: true })}>Sign up for and manage "pre-packaged" CCDS and US Labeling alerts</Link>
                                        <br />
                                        <span>These labeling alerts are pre-created for you so there is no need for you to create them yourself. Simply select the label type of interest from the list. The alerts will retrieve any labels from the selected categories. You can unsubscribe at any time by returning to this selection.</span>
                                    </li>
                                    <li className="manage-alerts-list">
                                        <Link className="manage-alerts-link" to={{ pathname: "https://jnjenewsstand.infodesk.com/pages.html?id=0" }} target="_blank">Manage your industry/news alerts through the JNJ eNews Stand</Link>
                                        <br />
                                        <span>The JNJ eNews Stand service enables you to subscribe to a wide variety of industry and news publications. The JNJ eNews Stand will appear in a new window, where you can un/subscribe at any time.</span>
                                    </li>
                                </ul>
                            </div>
                            <p>
                                <br />
                            </p>
                            <p>
                                <br />
                            </p>
                            <div style={{ margin: "0 1.5%", background: "#ffffff" }} class={this.state.height > 750 ? "fixed-bottom" : null}>
                                <Footer customWidth={this.state.width} customHeight={this.state.height} />
                            </div>
                            <ProductAlertsPopup showProductAlerts={this.state.showProductAlerts} handleClose={() => this.setState({ showProductAlerts: false })} customWidth={this.state.width} />
                            <CCDSUSLabelingAlertsPopUp showCCDSAndUSLabelingAlerts={this.state.showCCDSAndUSLabelingAlerts} handleClose={() => this.setState({ showCCDSAndUSLabelingAlerts: false })} customWidth={this.state.width} />
                            <NotifyMePopUp showPersonalAlerts={this.state.showPersonalAlerts} handleClose={() => this.setState({ showPersonalAlerts: false })} customWidth={this.state.width} />
                        </>
                        :
                        <div style={{ textAlign: "center", padding: "10%" }}>
                            <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
                        </div>
                    }
                </ErrorBoundary>
            )
        }
    }
}

export default ManageAlerts;