import React, { Component, createContext } from 'react';
import threadingService from "../../services/threadingService";
import ApiServices from "../../utils/apiServices";
import {
    UserData
} from "../../auth/Authorization";
export const GenAIAnsweringContext = createContext({});
let ts = new threadingService();
class GenAIAnsweringContextProvider extends Component {

    constructor(props) {
        let isCollapsedSidebar = localStorage.getItem('isCollapsedSidebar');
        if (isCollapsedSidebar) {
            isCollapsedSidebar = JSON.parse(isCollapsedSidebar)
        }
        super(props);
        this.state = {
            isCollapsedSpin: false,
            isCollapsedWindowPreviewInfo: false,
            latestAnswerThreads: [],
            isLibraryCollectionProcessing: false,
            libraryCollectionDocumentCount: 0,
            libraryCollectionResponse: [],
            libraryCollectionItemsPerPage: "10",
            libraryCollectionCurrentPage: "1",
            isLibraryThreadProcessing: false,
            libraryThreadDocumentCount: 0,
            libraryThreadResponse: [],
            libraryThreadCollectionResponse: [],
            libraryThreadSession_ids: [],
            libraryThreadItemsPerPage: "10",
            libraryThreadCurrentPage: "1",
            librarySearchKeyword: "",
            sessionId: "",
            redirectFrom: `/recommended`,
        }
    }
    componentDidMount = async () => {
        await this.getLatestAnswerThread();
    }

    updateRedirectFrom = (redirectFrom) => {
        this.setState({ redirectFrom: redirectFrom })
    }
    menuExpand = () => {
        this.setState({ isCollapsedSpin: true });
    }
    menuCollapse = () => {
        this.setState({ isCollapsedSpin: false });
    }
    menuWindowPreviewInfoExpand = () => {
        this.setState({ isCollapsedWindowPreviewInfo: true });
    }
    menuWindowPreviewInfoCollapse = () => {
        this.setState({ isCollapsedWindowPreviewInfo: false });
    }
    updateLibrarySearchKeyword = async (searchKeyword) => {
        this.setState({ librarySearchKeyword: searchKeyword });
    }
    updateLibraryThreadProcessing = (isLibraryThreadProcessing) => {
        this.setState({ isLibraryThreadProcessing: isLibraryThreadProcessing });
    }
    updateLibraryCollectionProcessing = (isLibraryCollectionProcessing) => {
        this.setState({ isLibraryCollectionProcessing: isLibraryCollectionProcessing });
    }
    getLatestAnswerThread = async () => {
        // let wwid = JSON.parse(localStorage.getItem('wwid'));
        if (UserData && UserData.sub) {
            let response = await ts.getLatestAnswerThreadData(UserData.sub);
            if (response && response.data.length > 0) {
                this.setState({ latestAnswerThreads: response.data });
            } else {
                this.setState({ latestAnswerThreads: [] });
            }
        }

    }
    updatelibraryCollectionCurrentPage = async (currentPage) => {
        this.setState({ libraryCollectionCurrentPage: currentPage })
    }
    updatelibraryThreadCurrentPage = async (currentPage) => {
        this.setState({ libraryThreadCurrentPage: currentPage })
    }
    updateSessionId = async (sessionId) => {
        this.setState({ sessionId: sessionId })
    }
    getAnswerCollectionsDataByUser = async () => {
        this.setState({ isLibraryCollectionProcessing: true })
        await this.getCollectionCountById();
        let service = new ApiServices();
        if (UserData && UserData.sub) {
            let from = parseInt(this.state.libraryCollectionCurrentPage) * parseInt(this.state.libraryCollectionItemsPerPage) - parseInt(this.state.libraryCollectionItemsPerPage);
            let size = parseInt(this.state.libraryCollectionItemsPerPage)
            let response = await service.getAllCollectionById(UserData.sub, from, size, this.state.librarySearchKeyword)
            if (response) {
                this.setState(
                    {
                        libraryCollectionResponse: response,
                        isLibraryCollectionProcessing: false,
                    })
            } else {
                this.setState(
                    {

                        libraryCollectionResponse: [],
                        isLibraryCollectionProcessing: false,
                    })
            }
        } else {
            this.setState(
                {

                    libraryCollectionResponse: [],
                    isLibraryCollectionProcessing: false,
                })
        }
    }
    getCollectionCountById = async () => {
        // this.setState({ isLibraryCollectionProcessing: true })
        let service = new ApiServices();
        if (UserData && UserData.sub) {
            let response = await service.getCollectionCountById(UserData.sub, this.state.librarySearchKeyword)
            if (response && response.length > 0) {
                this.setState(
                    {
                        libraryCollectionDocumentCount: parseInt(response[0].collections_count),
                    })
            } else {
                this.setState(
                    {

                        libraryCollectionDocumentCount: 0,
                    })
            }
        } else {
            this.setState(
                {
                    libraryCollectionDocumentCount: 0,
                })
        }
    }
    getAnswerThreadDataByUser = async () => {
        this.setState({ isLibraryThreadProcessing: true })
        const ts = new threadingService();
        if (UserData && UserData.sub) {
            let from = parseInt(this.state.libraryThreadCurrentPage) * parseInt(this.state.libraryThreadItemsPerPage) - parseInt(this.state.libraryThreadItemsPerPage);
            let size = parseInt(this.state.libraryThreadItemsPerPage)
            let response = await ts.getAnswerThreadDataByUser(UserData.sub, from, size, this.state.librarySearchKeyword)
            if (response && response.data) {
                let session_ids = response.data.hits.hits.map(x => x._source.answeringinfo.session_id)
                if (session_ids.length > 0) {
                    await this.getAnswerCollectionBySessionId(session_ids);
                }
                this.setState(
                    {
                        libraryThreadDocumentCount: response.data.hits.total.value,
                        libraryThreadResponse: response.data.hits.hits,
                        libraryThreadSession_ids: response.data.hits.hits.map(x => x._source.answeringinfo.session_id),
                        isLibraryThreadProcessing: false,
                    })
            } else {
                this.setState(
                    {
                        libraryThreadDocumentCount: 0,
                        libraryThreadResponse: [],
                        isLibraryThreadProcessing: false,
                        libraryThreadSession_ids: []
                    })
            }
        } else {
            this.setState(
                {
                    libraryThreadDocumentCount: 0,
                    libraryThreadResponse: [],
                    isLibraryThreadProcessing: false,
                    libraryThreadSession_ids: []
                })
        }
    }
    getAnswerCollectionBySessionId = async (session_ids) => {
        // this.setState({ isProcessing: true })

        let service = new ApiServices();
        if (UserData && UserData.sub) {
            let response = await service.getAnswerCollectionBySessionId(session_ids)
            if (response) {
                this.setState(
                    {
                        libraryThreadCollectionResponse: response,

                    })
            } else {
                this.setState(
                    {

                        libraryThreadCollectionResponse: [],

                    })
            }
        } else {
            this.setState(
                {

                    libraryThreadCollectionResponse: [],

                })
        }
    }
    deleteIndexBySessionId = async () => {
        let { sessionId } = this.state;
        if (sessionId) {
            let response = await ts.deleteIndexBySessionId(sessionId);
        }
    }
    deleteIndexOnLoad = async (sessionId) => {
        if (sessionId) {
            let response = await ts.deleteIndexBySessionId(sessionId);
        }
    }
    deleteLatestAnswerThread = async (id) => {
        let { latestAnswerThreads } = this.state;
        latestAnswerThreads = latestAnswerThreads.filter(x => x._source.answeringinfo.session_id !== id);
        this.setState({ latestAnswerThreads: latestAnswerThreads });
    }
    render() {
        return (
            <GenAIAnsweringContext.Provider value={{
                ...this.state,
                menuExpand: this.menuExpand,
                menuCollapse: this.menuCollapse,
                getLatestAnswerThread: this.getLatestAnswerThread,
                getAnswerCollectionsDataByUser: this.getAnswerCollectionsDataByUser,
                getAnswerThreadDataByUser: this.getAnswerThreadDataByUser,
                updatelibraryCollectionCurrentPage: this.updatelibraryCollectionCurrentPage,
                updatelibraryThreadCurrentPage: this.updatelibraryThreadCurrentPage,
                updateLibraryThreadProcessing: this.updateLibraryThreadProcessing,
                deleteIndexBySessionId: this.deleteIndexBySessionId,
                updateLibrarySearchKeyword: this.updateLibrarySearchKeyword,
                updateLibraryCollectionProcessing: this.updateLibraryCollectionProcessing,
                updateSessionId: this.updateSessionId,
                deleteIndexOnLoad: this.deleteIndexOnLoad,
                updateRedirectFrom: this.updateRedirectFrom,
                deleteLatestAnswerThread: this.deleteLatestAnswerThread,
                menuWindowPreviewInfoExpand:this.menuWindowPreviewInfoExpand,
                menuWindowPreviewInfoCollapse:this.menuWindowPreviewInfoCollapse
            }}>
                {this.props.children}
            </GenAIAnsweringContext.Provider>
        );
    }
}

export default GenAIAnsweringContextProvider;