import { useState } from "react";
import * as FileSaver from 'file-saver';
import Button from '../Button';
import ApiServices from '../../utils/apiServices';
import DocumentProcessing from  './../PopUp/DocumentProcessing';
import  { getVarType } from "../../components/GenerateArrayStrings"
import moment from 'moment';
export const ExportCSV = ({csvData, fileName,selectedResults}) => {
    const [docProcessing, setDocProcessing] = useState(false);
    
    const exportToExcel = async(fileName) => {

        let csvData = [];
        selectedResults.map((item, index) => {
            csvData.push({
                'Sl No': item.index,
                // 'Sl No': index + 1,
                Authors: (item.detail.authors && item.detail.authors.length > 0) ? getVarType(item.detail.authors) === 'Array' ? item.detail.authors.join("; ") : item.detail.authors : "",
                Owner: item.detail.owner === "Migration Specialist" ? "" : item.detail.owner,
                Title: item.detail.docTitle,
                'Link to Document': {text: 'Link',
                hyperlink: `${URLConstructor(item.detail)}`},
                'Document Type': item.detail.docType !== undefined && item.detail.docType.length > 0 ? item.detail.docType.join("; "): "",
                'Document Number': item.detail.documentNumber,
                Product:  item.detail.product !== undefined && item.detail.product.length > 0 ? item.detail.product.join("; "): "",
                'MSL Therapeutic Area': item.detail.mslTherapeuticArea !== undefined &&  item.detail.mslTherapeuticArea.length > 0 ? item.detail.mslTherapeuticArea : '',
                Source: item.detail.domain,
                Congress: item.detail.congressName !== undefined && item.detail.congressName.length > 0 ? item.detail.congressName.join("; ") : "",
                'Protocol ID': item.detail.protocolIDs !== undefined && item.detail.protocolIDs.length > 0 ? item.detail.protocolIDs.join("; "): "",
                'Phase Of Study': item.detail.phaseOfStudy !== undefined && item.detail.phaseOfStudy.length > 0 ? item.detail.phaseOfStudy.join("; ") : "",
                Language: item.detail.languages,
                Country: item.detail.countries !== undefined && item.detail.countries.length > 0 ? item.detail.countries.join("; ") : "",
                Version: item.detail.majorVersionNumber,
                Date: item.detail.year,
                'Last Updated': item.detail.lastUpdated !== undefined ? moment(item.detail.lastUpdated).format("D-MMM-YYYY") : "",
                'Issued Date': item.detail.approvedDate !== undefined ? moment(item.detail.approvedDate).format("D-MMM-YYYY") : "",
                'Modified Date': item.detail.modifiedDate !== undefined ? moment(item.detail.modifiedDate).format("D-MMM-YYYY") : "",
                'PubMed ID': item.detail.pmID,
                'Content Source': item.detail.csName,
                'Key Takeaways': item.detail.keyTakeaways,
                'Clinical Trial Name': item.detail.ctNames !== undefined && item.detail.ctNames.length > 0 ? item.detail.ctNames.join("; ") : "",
            })
            return null;
        })
        let columns = [
            { header: "Sl No", key: "Sl No", width: 10 },
            { header: "Authors", key: "Authors", width: 36 },
            { header: "Owner", key: "Owner", width: 25 },
            { header: "Title", key: "Title", width: 36 },
            { header: "Link to Document", key: "Link to Document", width: 16 },
            { header: "Document Type", key: "Document Type", width: 15 },
            { header: "Document Number", key: "Document Number", width: 18 },
            { header: "Product", key: "Product", width: 33 },
            { header: "MSL Therapeutic Area", key: "MSL Therapeutic Area", width: 20 },
            { header: "Source", key: "Source", width: 10 },
            { header: "Congress", key: "Congress", width: 10 },
            { header: "Protocol ID", key: "Protocol ID", width: 10 },
            { header: "Phase Of Study", key: "Phase Of Study", width: 15 },
            { header: "Language", key: "Language", width: 10 },
            { header: "Country", key: "Country", width: 13 },
            { header: "Version", key: "Version", width: 10 },
            { header: "Date", key: "Date", width: 7 },
            { header: "Last Updated", key: "Last Updated", width: 12 },
            { header: "Issued Date", key: "Issued Date", width: 12 },
            { header: "Modified Date", key: "Modified Date", width: 12 },
            { header: "PubMed ID", key: "PubMed ID", width: 11 },
            { header: "Content Source", key: "Content Source", width: 25 },
            { header: "Key Takeaways", key: "Key Takeaways", width: 45 },
            { header: "Clinical Trial Name", key: "Clinical Trial Name", width: 20 },

        ];

        setDocProcessing(true);
        let service = new ApiServices();
        await service.prepareExcelDocument(csvData,columns).then((res) => {
            const data = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(data, fileName);
            setDocProcessing(false)
        }).catch((error) => {
            console.log(error);
            setDocProcessing(false)
        });

     
    }

    const URLConstructor = (item) => {
        let url = window.origin
        let { orderedBy, orderedFor } = item;
        if (!orderedBy) orderedBy = '';
        if (!orderedFor) orderedFor = '';
        if (item.domain === 'RIMdocs' || item.domain === 'RIMdocs_LL' || item.domain === 'CTGov') {
            url += `/DocURL?ID=${item.docID}&ref=excel&cs=${item.domain}&orderedBy=${orderedBy}&orderedFor=${orderedFor}`
            // url += `/DocURL?ID=${item.docTitle}&ref=excel&cs=${item.domain}`
        }
        else if (item.domain === 'SKM' || item.domain === 'IROs' || item.domain === "MIAs") {
            url += `/DocURL?ID=${item.docID}&ref=excel&cs=${item.domain}&connID=${item.connID}&csID=${item.csID}&fileType=${item.fileExtension}&fileName=${item.docTitle}&orderedBy=${orderedBy}&orderedFor=${orderedFor}`
        }
        else {
            url += `/DocURL?ID=${item.docID}&ref=excel&cs=${item.domain}&connID=${item.connID}&csID=${item.csID}&fileType=${item.fileExtension}&docVer=${item.majorVersionNumber}&fileName=${item.docTitle}&orderedBy=${orderedBy}&orderedFor=${orderedFor}`
            if (item.a_status === "Approved Needs Review" || item.status === "Approved Needs Review") {
                url = url + `&docState=anr`
            }
        }
        return url
    }

    return (
        <>
        {docProcessing &&
            <>
                <DocumentProcessing showModal={docProcessing} color="#0074B0" />
                <div className="savemodal-outer-wrapper"></div>
            </>
        }
        <Button text="Export Excel" customColor="#2EB000" action={()=>{exportToExcel(fileName)}}/>
        </>
    )
}