/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-useless-escape */
import GenerateArrayStrings from "../GenerateArrayStrings";

export default function(props){
    const {
        searchKeyword,
        item
    } = props
    let allTermsMatch = false;
    let mixedTermsMatch = false;
    if(searchKeyword !== ''){
        let keywordArr = searchKeyword.split(/ OR /g);
        for (let k=0; k<keywordArr.length; k++){
            let allTempTermsMatch = false;
            let mixedTempTermsMatch = false;
            var majorTS = GenerateArrayStrings(item.majorTopicsSynonyms).replace(/[\/\+\_\-]/g, " ").replace(/[\,\:\(\)\[\]\=\>\<\≥\≤\'\’]/g, "");
            var minorTS = GenerateArrayStrings(item.minorTopicsSynonyms).replace(/[\/\+\_\-]/g, " ").replace(/[\,\:\(\)\[\]\=\>\<\≥\≤\'\’]/g, "") + majorTS;
            if(!(majorTS.toLowerCase().indexOf(keywordArr[k].toLowerCase()) > -1)){
                let noiseArray = ['a','an','the','it','in','and','of','for','or','with','near','not','are','AND','NEAR','OR'];
                let keywordWithoutNoise = keywordArr[k].replace(new RegExp('\\b('+noiseArray.join('|')+')\\b', 'g'), '').replace(/[\/\+\_\-]/g, " ");
                let keyTitle = keywordWithoutNoise.split(' ');
				let arrTitle = keyTitle.filter(function (el) {
								return el !== "";
                            });
                for(var i=0; i<arrTitle.length; i++)
                {
                    var part = arrTitle[i].replace(/[\,\:\(\)\[\]\=\>\<\≥\≤\'\’]/g, "");
                    if(part !== '*' && part.length > 1)
                    {
                        var regexpression = new RegExp("\\b"+ part +"(\\s)?|\\b\\w+(_|-)+" + part +"(\\s)?", "i"); 
                        if(!regexpression.test(majorTS) && allTempTermsMatch)
                        {
                            allTempTermsMatch = false;
                        }
                        //8-Aug-2020: change in orange yellow logic
                        else if(regexpression.test(minorTS) || regexpression.test(majorTS) )
                        {												
                            mixedTempTermsMatch = true;
                        }
                        else if(regexpression.test(minorTS)){
                            mixedTempTermsMatch = true;
                        }	
                    }											
                }
                if(allTempTermsMatch)
                {
                    allTermsMatch = true;
                }
                if(mixedTempTermsMatch)
                {
                    mixedTermsMatch = true;
                }            
            }
            else
            {
                allTermsMatch = true;
            }
        }
    }
    return(
        <div>
        { searchKeyword !== '' &&  
            <div className="relevance-score-wrapper">
                {allTermsMatch &&
                <>
                    <div className="high-relevance-color-box" title="All search terms are Major Topics in this document"></div>
                    <span className="relevance-highlight-text">HIGH RELEVANCE SCORE</span>
                </>
                }
                {mixedTermsMatch &&
                <>
                    <div className="medium-relevance-color-box" title="Some search terms are Major Topics in this document"></div>
                    <span className="relevance-highlight-text">MEDIUM RELEVANCE SCORE</span>
                </>
                }
                {!allTermsMatch && !mixedTermsMatch &&
                <>
                    <div className="low-relevance-color-box" title="No search terms are Major Topics in this document"></div>
                    <span className="relevance-highlight-text">LOW RELEVANCE SCORE</span>
                </>
                }
            </div>
        }
        </div>
    )
}