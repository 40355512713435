/* eslint-disable array-callback-return */
import config from '../../config/config';
import { Link } from 'react-router-dom';
import ApiServices from '../../utils/apiServices';
import { Component } from 'react';
import { imsLoader, rightSideArrow } from '../../assets';
import trackingService from "../../services/trackingService";
import Footer from '../footer/Footer';

export default class C19ByProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            arrayOfDocTypes: [],
            selectedDefault: '',
            selectedProduct: '',
            isLoaded: false,
            width: window.innerWidth,
            height: window.innerHeight,
            startIndex: 0,
        }
    }
    async componentDidMount() {
        let service = new ApiServices();
        let email = JSON.parse(localStorage.getItem('username')).toLowerCase();
        let wwid = JSON.parse(localStorage.getItem('wwid'));
        let isProductsAvailable;
        let res = await service.getUserProductAndWwid(wwid);
        let apiresponse = await service.getDefaultProduct(email);
        if (res) {
            isProductsAvailable = res[0].products
        }
        if (apiresponse.length > 0) {
            this.setState({
                selectedDefault: apiresponse[0].default_product,
                selectedProduct: apiresponse[0].default_product
            })
        }
        if (isProductsAvailable !== null && isProductsAvailable.length > 0) {
            this.setState({
                products: isProductsAvailable.split(';'),
                isLoaded: true
            }, () => {
                if (this.state.selectedProduct === '') {
                    this.setState({
                        selectedProduct: this.state.products[0]
                    })
                }
            })
        }
        else {
            this.setState({
                isLoaded: true
            })
        }
        this.setState({
            arrayOfDocTypes: config.c19Products
        })
    }
    generateListOfDocTypes = (arrayOfDoc) => {
        if (arrayOfDoc.length > 0) {
            return (
                arrayOfDoc.map((items, index) => {
                    let url;
                    if (this.state.selectedProduct !== '') {
                        url = `/results?query=${this.state.selectedProduct}&q=All%20Fields&filters=` + items.filter + `&sbQuery=${this.state.selectedProduct}&f1=tempJNJDrugs&fval1=${this.state.selectedProduct}&fcount=1&cspath=Covid-19 Content by Product-${items.title}&csproduct=${this.state.selectedProduct}`
                    }
                    else {
                        url = `/results?query=${this.state.selectedProduct}&q=All%20Fields&filters=` + items.filter
                    }
                    if (items.property === 'parentLink')
                        return (
                            <li key={index}><Link to={url} target="_blank" onClick={() => this.handelDocsTypesLinks(items.filter, items.title)}>{items.title}</Link></li>
                        )
                    else {
                        return (
                            <li key={index} style={{ marginLeft: "1%" }} onClick={() => this.handelDocsTypesLinks(items.filter, items.title)}><Link to={url} target="_blank">{items.title}</Link></li>
                        )
                    }
                })
            )
        }
    }
    handelDocsTypesLinks = (filter, title) => {
        const ts = new trackingService();
        ts.indexTrackingData(
            localStorage.getItem("userdisplayName"),
            localStorage.getItem("wwid"),
            localStorage.getItem("username"),
            localStorage.getItem("function"),
            localStorage.getItem("region"),
            localStorage.getItem("userproducts"),
            localStorage.getItem("doctypepref"),
            localStorage.getItem("sid"),
            localStorage.getItem("deviceType"),
            localStorage.getItem("deviceOS"),
            localStorage.getItem("browserName"),
            localStorage.getItem("browserVersion"),
            localStorage.getItem("jnjDepartment"),
            localStorage.getItem('companyname'),
            `Visited ${this.state.selectedProduct} for ${title}`,
            "",
            window.location.href,
            this.state.selectedProduct,
            filter,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            `Covid-19 Content by Product-${title}`,
            this.state.selectedProduct
        );
    }
    getWidth = (diff) => {
        let calWidth;
        switch (diff) {
            case 1: calWidth = 20;
                break;
            case 2: calWidth = 30;
                break;
            case 3: calWidth = 40;
                break;
            case 4: calWidth = 60;
                break;
            case 5: calWidth = 90;
                break;
            case 6: calWidth = 100;
                break;
            default: calWidth = 100;
                break;
        }
        return calWidth
    }
    selectProduct = (product, startIdx, endIdx) => {
        if (product.length > 0) {
            return (
                <>
                    {startIdx > 0 &&
                        <div className="wnfy-select-nav-button-wrapper"><div className="wnfy-result-navigate-arrow" onClick={e => this.handleOnClickLeft()}><img src={rightSideArrow} alt="" style={{ transform: "rotate(180deg)" }} /></div></div>
                    }
                    {/* {diff < 6 &&
                        <div style={{ flex: "auto" }}></div>
                    } */}
                    <div className="cs-modal-product-wrapper" /* style={{ width: `${calcwidth}%` }} */>
                        {product.map((item, index) => {
                            if (index >= startIdx && index <= endIdx) {
                                return (

                                    <div className={item === this.state.selectedProduct ? "cs-select-product-wrapper cs-product-selected" : "cs-select-product-wrapper"} onClick={this.handleSelectProduct} key={index} id={`${item}`}>
                                        <div id={`${item}`}>{item}</div>
                                    </div>

                                )
                            }
                        })
                        }
                    </div>
                    {/* {diff < 6 &&
                        <div style={{ flex: "auto" }}></div>
                    } */}
                    {startIdx < product.length && endIdx < product.length - 1 &&
                        <div className="wnfy-select-nav-button-wrapper" ><div className="wnfy-result-navigate-arrow" onClick={e => this.handleOnClickRight()}><img src={rightSideArrow} alt="" /></div></div>
                    }
                </>
            )
        }
    }
    handleSelectProduct = (event) => {
        event.preventDefault();
        if (event) {
            this.setState({
                selectedProduct: event.target.id
            })
        }
    }
    handleOnClickRight = () => {
        if (this.state.width <= 1360 && this.state.width >= 1200) {
            this.setState({ startIndex: this.state.startIndex + 6 })
        }
        else if (this.state.width <= 1200 && this.state.width >= 1000) {
            this.setState({ startIndex: this.state.startIndex + 5 })
        }
        else if (this.state.width <= 1000 && this.state.width >= 750) {
            this.setState({ startIndex: this.state.startIndex + 4 })
        }
        else if (this.state.width <= 750 && this.state.width >= 550) {
            this.setState({ startIndex: this.state.startIndex + 3 })
        }
        else if (this.state.width <= 550 && this.state.width >= 450) {
            this.setState({ startIndex: this.state.startIndex + 2 })
        }
        else if (this.state.width <= 450) {
            this.setState({ startIndex: this.state.startIndex + 1 })
        }
        else {
            this.setState({ startIndex: this.state.startIndex + 7 })
        }
        // this.setState({
        //     startIndex: this.state.startIndex + 7
        // })
    }
    handleOnClickLeft = () => {
        if (this.state.width <= 1360 && this.state.width >= 1200) {
            this.setState({ startIndex: this.state.startIndex - 6 })
        }
        else if (this.state.width <= 1200 && this.state.width >= 1000) {
            this.setState({ startIndex: this.state.startIndex - 5 })
        }
        else if (this.state.width <= 1000 && this.state.width >= 750) {
            this.setState({ startIndex: this.state.startIndex - 4 })
        }
        else if (this.state.width <= 750 && this.state.width >= 550) {
            this.setState({ startIndex: this.state.startIndex - 3 })
        }
        else if (this.state.width <= 550 && this.state.width >= 450) {
            this.setState({ startIndex: this.state.startIndex - 2 })
        }
        else if (this.state.width <= 450) {
            this.setState({ startIndex: this.state.startIndex - 1 })
        }
        else {
            this.setState({ startIndex: this.state.startIndex - 7 })
        }
        // this.setState({
        //     startIndex: this.state.startIndex - 7
        // })
    }
    productList = () => {
        let startIdx = this.state.startIndex;
        // let endIdx = startIdx + 6;
        let endIdx;
        if (this.state.width <= 1360 && this.state.width >= 1200) {
            endIdx = startIdx + 5;
        }
        else if (this.state.width <= 1200 && this.state.width >= 1000) {
            endIdx = startIdx + 4;
        }
        else if (this.state.width <= 1000 && this.state.width >= 750) {
            endIdx = startIdx + 3;
        }
        else if (this.state.width <= 750 && this.state.width >= 550) {
            endIdx = startIdx + 2;
        }
        else if (this.state.width <= 550 && this.state.width >= 450) {
            endIdx = startIdx + 1;
        }
        else if (this.state.width <= 450) {
            endIdx = startIdx + 0;
        }
        else {
            endIdx = startIdx + 6;
        }
        return (
            <div className="cs-product-wrapper">
                <div className="cs-product-inner-wrapper">
                    {this.selectProduct(this.state.products, startIdx, endIdx)}
                </div>
            </div>
        )
    }
    // selectProduct = (product) => {
    //     if (product.length > 0) {
    //         return (
    //             product.map((item, index) => {
    //                 return (
    //                     <div key={index}>
    //                         <div className={item === this.state.selectedProduct ? "c19-select-product-wrapper c19-product-selected" : "c19-select-product-wrapper"} onClick={this.handleSelectProduct}>
    //                             <div id={`${item}`}>{item}</div>
    //                         </div>
    //                     </div>
    //                 )
    //             })
    //         )
    //     }
    // }
    // handleSelectProduct = (event) => {
    //     event.preventDefault();
    //     if (event) {
    //         this.setState({
    //             selectedProduct: event.target.id
    //         })
    //     }
    // }
    render() {
        return (
            <div className="wrapper-class-for-content-shortcuts">
                {this.state.isLoaded ?
                    <>
                        <div className="cs-modalHeader" style={{ justifyContent: "center", display: this.state.products.length <= 0 && "none", margin: "0 0 1% 0" }}>
                            <div style={{ textAlign: "center" }}>
                                <div class="modal-title h4">
                                    {/* <small className="cs-modalTitle">Select Product</small> */}
                                    {this.state.products.length > 0 && this.productList()}
                                </div>
                            </div>
                        </div>
                        {/* <div className="content-shortcut-all-product-wrapper">
                        {this.selectProduct(this.state.products)}   
                    </div>  */}
                        <p>
                            If you close the tile with a product other than your default selected, your default product will be selected when you re-open the tile. <span style={{ color: "red" }}>PLEASE NOTE: your default product selection will not impact the ranking of search results; it will only impact your shortcuts.</span>
                        </p>
                        <ul className="c19-content-list">
                            {this.generateListOfDocTypes(this.state.arrayOfDocTypes)}
                        </ul>
                        <p>
                            <b style={{ color: "red", fontSize: "12px" }}>PLEASE NOTE: One or more of the categories listed above may retrieve zero results for the product you selected. This is because 1) content within those categories has not yet been made available for that product, or 2) the content is restricted/firewalled due to your Location and/or Function.</b>
                        </p>
                    </>
                    :
                    <div style={{ textAlign: "center" }}>
                        <img src={imsLoader} alt=""></img>
                        <div>Please Wait....</div>
                    </div>
                }
                <div>
                    <Footer />
                </div>
            </div>
        )
    }
};