/* eslint-disable array-callback-return */
import { Component } from "react";
import axios from "axios";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import { imsLoader, prdtNavButton } from "../../assets";
import Footer from "../footer/Footer";
import NavigationMenu from "../Navigation/NavigationMenu";
import DocumentCard from "../../content/DocumentResults/DocumentCard"
import PageItems from "../Paginate/PageItems"
import Pagination from "../Paginate/Pagination"
import ApiServices from "../../utils/apiServices";
import GlobalFeatures from "../GlobalSearchFeatures/GlobalFeatures";
import Services from "../../utils/services";
import HomeTilesProductWrapper from "../HomeTilesProductWrapper/HomeTilesProductWrapper";
import GlobalSearchFeaturePopUpModal from "../PopUp/GlobalSearchFeaturesPopUpModal";
import ReadingListPopup from "../PopUp/ReadingListPopup";
import { LocationOrigin } from "../../utils/utilityServices";
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal";

class WOAVPage extends Component {
    userid;
    documentsUrl = [];
    markedDocumentsUrl = [];
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isProcessing: true,
            selectedProduct: '',
            responseArr: [],
            products: [],
            appliedFilters: [],
            itemsPerPage: "25",
            currentPage: "1",
            documentCount: 0,
            startIndex: 0,
            width: window.innerWidth,
            height: window.innerHeight,
            citationSelectStatus: [],
            selectedResults: [],
            globalCollapseAll: false,
            globalSelectAll: false,
            readingListPopup: false,
            globalEmailPopUp: false,
            sortByValue: 'Date,Newest',
            sortBy: "desc",
            sortField: "modifiedDate",
            collapsedSearchIDs: [],
            alertMessageModelColor: "",
            alertMessageContent: "",
            alertMessageShowModal: false,
        }
    }
    async componentDidMount() {
        let isProductsAvailable;
        let service = new ApiServices();
        let logservice = new Services();
        let selectedProduct = sessionStorage.getItem("selectedProduct")
        this.userid = JSON.parse(localStorage.getItem('username'));
        logservice.logService(`What other's are viewing page successfully mounted for ${this.userid.toLowerCase()}`, 'info');
        let wwid = JSON.parse(localStorage.getItem('wwid'));
        let res = await service.getUserProductAndWwid(wwid);
        if (res) {
            isProductsAvailable = res[0].products
        }
        if (isProductsAvailable !== null && isProductsAvailable.length > 0) {
            this.setState({
                products: isProductsAvailable.split(';')
            }, () => {
                if (this.state.selectedProduct === '') {
                    if (selectedProduct === null) {
                        this.setState({
                            selectedProduct: this.state.products[0]
                        }, () => {
                            this.runWnfyApi();
                        })
                    }
                    else {
                        this.setState({
                            selectedProduct: selectedProduct
                        }, () => {
                            this.runWnfyApi();
                        })
                    }
                }
            })
        }
        window.addEventListener('resize', this.updateDimensions);
        // VIEW SELECTED LOGIC
        let data = this.state.selectedResults;
        let citationSelectStatus = this.state.citationSelectStatus;
        let markedUrl = this.markedDocumentsUrl
        var sessiondata = sessionStorage.getItem("selectedResultsDetails");
        if (localStorage.getItem('selectedResults') === null) {
            citationSelectStatus.push(false)
            markedUrl = [];
        }
        if (localStorage.getItem('selectedResults') !== null && sessiondata !== null) {
            localStorage.getItem('selectedResults').split(',').map((x, key) => {
                let i = parseInt(x)
                citationSelectStatus[i - 1] = true;
                if (citationSelectStatus[i - 1]) {
                    data = JSON.parse(sessiondata)
                    markedUrl = JSON.parse(localStorage.getItem('markedResultsUrl'))
                }
                else {
                    data.splice(i - 1, 1)
                    markedUrl.splice(i - 1, 1)
                }
            })
            this.markedDocumentsUrl = markedUrl
            this.setState({ citationSelectStatus: citationSelectStatus, selectedResults: data })
        }
    }
    componentWillUnmount() {
        let service = new Services();
        window.removeEventListener('resize', this.updateDimensions);
        service.logService(`What other's are viewing page successfully unmounted for ${this.userid.toLowerCase()}`, 'info')
    }
    errorAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#de1327",
            alertMessageShowModal: true
        })
    }
    closeAlertMessage = () => {
        this.setState({
            alertMessageContent: "",
            alertMessageModelColor: "",
            alertMessageShowModal: false
        })
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    getWidth = (diff) => {
        let calWidth;
        switch (diff) {
            case 1: calWidth = 20;
                break;
            case 2: calWidth = 30;
                break;
            case 3: calWidth = 40;
                break;
            case 4: calWidth = 60;
                break;
            case 5: calWidth = 90;
                break;
            case 6: calWidth = 100;
                break;
            case 7: calWidth = 100;
                break;
            default: calWidth = 100;
                break;
        }
        return calWidth
    }
    selectProduct = (product, startIdx, endIdx) => {
        var diff = endIdx - startIdx;
        var calcwidth = this.getWidth(product.length - startIdx);
        if (product.length > 0) {
            return (
                <>
                    {startIdx > 0 &&
                        <div className="wnfy-select-nav-button-wrapper"><div className="wnfy-result-navigate-arrow" onClick={e => this.handleOnClickLeft()}><img src={prdtNavButton} alt="" style={{ transform: "rotate(180deg)" }} /></div></div>
                    }
                    {diff < 7 &&
                        <div style={{ flex: "auto" }}></div>
                    }
                    <div className={product.length < 8 ? "wnfy-product-wrapper wnfy-product-wrapper-with-margin" : "wnfy-product-wrapper"} style={{ width: `${calcwidth}%` }}>
                        {product.map((item, index) => {
                            if (index >= startIdx && index <= endIdx) {
                                return (

                                    <div className={item === this.state.selectedProduct ? "wnfy-select-product-wrapper wnfy-product-selected" : "wnfy-select-product-wrapper"} onClick={e => { this.handleSelectProductWithName(e, item) }} key={index}>
                                        <div id={`${item}`}>{item}</div>
                                    </div>

                                )
                            }
                        })
                        }
                    </div>
                    {diff < 7 &&
                        <div style={{ flex: "auto" }}></div>
                    }
                    {startIdx < product.length && endIdx < product.length - 1 &&
                        <div className="wnfy-select-nav-button-wrapper" ><div className="wnfy-result-navigate-arrow" onClick={e => this.handleOnClickRight()}><img src={prdtNavButton} alt="" /></div></div>
                    }
                </>
            )
        }
    }
    handleSelectProduct = (event) => {
        event.preventDefault();
        if (event) {
            this.setState({
                selectedProduct: event.target.id,
                citationSelectStatus: [],
                selectedResults: [],
                globalCollapseAll: false,
                sortBy: "desc",
                sortField: "modifiedDate",
                sortByValue: 'Date,Newest',
                collapsedSearchIDs: [],
            }, () => { this.runWnfyApi() })
            this.markedDocumentsUrl = [];
        }
        localStorage.removeItem('selectedResults')
        localStorage.removeItem('markedResultsUrl')
        sessionStorage.clear();
    }
    handleSelectProductWithName = (event, productName) => {
        event.preventDefault();
        if (event) {
            this.setState({
                selectedProduct: productName,
                citationSelectStatus: [],
                selectedResults: [],
                globalCollapseAll: false,
                sortBy: "desc",
                sortField: "modifiedDate",
                sortByValue: 'Date,Newest',
                collapsedSearchIDs: [],
            }, () => { this.runWnfyApi() })
            this.markedDocumentsUrl = [];
        }
        localStorage.removeItem('selectedResults')
        localStorage.removeItem('markedResultsUrl')
        sessionStorage.clear();
    }
    handleOnClickRight = () => {
        this.setState({
            startIndex: this.state.startIndex + 8
        })
    }
    handleOnClickLeft = () => {
        this.setState({
            startIndex: this.state.startIndex - 8
        })
    }
    productList = () => {
        let startIdx = this.state.startIndex;
        let endIdx = startIdx + 7;
        return (
            <div className="wnfy-results-product-wrapper">
                <div className="wnfy-results-product-inner-wrapper">
                    {this.selectProduct(this.state.products, startIdx, endIdx)}
                </div>
            </div>
        )
    }
    citationUrl = (url, index, item, displayedResultsUrl) => {
        if (item.domain === "CTGov") {
            displayedResultsUrl[index] = url;
        }
        else {
            displayedResultsUrl[index] = window.location.origin + url;
            // displayedResultsUrl[index] = "https://predev-ims.jnj.com" + url;
        }
        this.documentsUrl = displayedResultsUrl;
    }
    manageCollapseAndExpand = (iscollapsed, searchID) => {
        let { collapsedSearchIDs, responseArr } = this.state;
        let responseSearchIDs = responseArr.map(x => x._source.searchID);
        if (iscollapsed) {
            collapsedSearchIDs.push(searchID);
        } else {
            collapsedSearchIDs = collapsedSearchIDs.filter(x => x !== searchID);
        }
        let tempGlobalCollapseAll = responseSearchIDs.every(x => collapsedSearchIDs.includes(x));
        this.setState({ collapsedSearchIDs: collapsedSearchIDs, globalCollapseAll: tempGlobalCollapseAll });
    }
    resultsList = (results, itemsPerPage, currentPage) => {
        let { collapsedSearchIDs } = this.state;
        // window.scrollTo({ behavior: 'smooth', top: '0px' });
        var startIndex;
        startIndex = currentPage * itemsPerPage - itemsPerPage
        var count = startIndex;
        let displayedResults = []
        let displayedResultsUrl = []
        if (results.length > 0) {
            return (
                <>
                    {results.map((item, index) => {
                        count++;
                        displayedResults.push({
                            detail: item._source,
                            index: index + 1,
                        })
                        let responseSearchIDs = item._source.searchID;
                        let globalCollapseAll = collapsedSearchIDs.some(x => x === responseSearchIDs);
                        return (
                            <>
                                <div key={`result-id-${index}`} id={`result-id-${index}`}>
                                    {this.calculateMonthDiff(item._source.modifiedDate)}
                                    {
                                        <div className="resultDetailsDiv">
                                            <DocumentCard item={item._source} count={count} appliedFilters={this.state.appliedFilters} searchKeyword={this.state.searchKeyword} pageNumber={this.state.currentPage} pageSize={this.state.itemsPerPage} customWidth={this.state.width} handleCitationSelection={(i, item) => this.handleCitationSelection(i, item, index)} isChecked={this.state.citationSelectStatus[count - 1]}
                                                //globalCollapseAll={this.state.globalCollapseAll} 
                                                globalCollapseAll={globalCollapseAll}
                                                citationUrl={(url) => this.citationUrl(url, index, item._source, displayedResultsUrl)}
                                                manageCollapseAndExpand={this.manageCollapseAndExpand} />
                                            <div className="wnfy-margin-between-citations">
                                            </div>
                                        </div>

                                    }
                                </div>

                            </>
                        )
                    })
                    }
                    {localStorage.setItem('displayedResults', JSON.stringify(displayedResults))}
                </>
            )
        }
        else {
            localStorage.removeItem("displayedResults");
            return (
                <div className="results-no-search-content">
                    <span>Nothing here matches your search</span><br /><br />
                    <span>Suggestions</span>
                    <li>Make sure all words are spelled correctly</li>
                    <li>Try different search terms</li>
                    <li>Try more general search terms</li>
                    <li>Try fewer search terms</li>
                </div>

            )
        }
    }
    handleChangeSelectSortBy = (e) => {
        if (e.target.value === 'Date,Newest') {
            this.setState({ sortBy: "desc", sortField: "modifiedDate" })
        }
        else if (e.target.value === 'Date,Oldest') {
            this.setState({ sortBy: "asc", sortField: "modifiedDate" })
        }
        else if (e.target.value === 'Document Type, Ascending' || e.target.value === 'DocType, Asc') {
            this.setState({ sortBy: "asc", sortField: "docType" })
        }
        else if (e.target.value === 'Document Type, Descending' || e.target.value === 'DocType, Desc') {
            this.setState({ sortBy: "desc", sortField: "docType" })
        }
        else {
            this.setState({ sortBy: "", sortField: "" })
        }
        this.setState({ sortByValue: e.target.value, citationSelectStatus: [], selectedResults: [], globalSelectAll: false, globalCollapseAll: false, collapsedSearchIDs: [], }, () => {
            this.runWnfyApi()
        })
    }
    resultListCount = (itemsPerPage, currentPage) => {
        var endIndex, startIndex;
        startIndex = currentPage * itemsPerPage - itemsPerPage
        endIndex = currentPage * itemsPerPage
        return (
            <div className="wnfy-results-count-wrapper">
                {this.state.documentCount > 0 &&
                    <span className="showingResultsCountContainer" /* style={{display:"flex",width:"200px"}} */>
                        {endIndex <= this.state.documentCount ?
                            <div className="results-number-show-wrapper">
                                Showing {startIndex + 1}-{endIndex} of {this.state.documentCount} {this.state.width > 1000 && "Results"}
                            </div> :
                            <div className="results-number-show-wrapper">
                                Showing {startIndex + 1}-{this.state.documentCount} of {this.state.documentCount} {this.state.width > 1000 && "Results"}
                            </div>
                        }
                    </span>
                }
                <span style={{ marginRight: "0.5%", display: "flex", width: this.state.width <= 630 ? "160px" : this.state.width >= 630 && this.state.width <= 683 ? "171px" : "180px" }}>
                    <PageItems handleChangeSelectItems={this.handleChangeSelectItems}
                        itemsPerPage={this.state.itemsPerPage} customWidth={this.state.width} />
                </span>
                {
                    this.state.width >= 1000 ?
                        <div className="sort-by-wrapper">
                            <span className="sort-by-text">Sort By:</span>
                            <select className="sort-by-select-wrapper" onChange={this.handleChangeSelectSortBy} defaultValue={this.state.sortByValue} value={this.state.sortByValue}>
                                <option>Relevance</option>
                                <option>Date,Newest</option>
                                <option>Date,Oldest</option>
                                <option>Document Type, Ascending</option>
                                <option>Document Type, Descending</option>
                            </select>
                        </div>
                        :
                        this.state.width >= 600 &&
                        <div className="sort-by-wrapper">
                            <span className="wnfy-sort-by-text">Sort By:</span>
                            <select className="sort-by-select-wrapper" onChange={this.handleChangeSelectSortBy} defaultValue={this.state.sortByValue}>
                                <option>Relevance</option>
                                <option>Date,Newest</option>
                                <option>Date,Oldest</option>
                                <option>DocType, Asc</option>
                                <option>DocType, Desc</option>
                            </select>
                        </div>
                }
            </div>
        )
    }
    handleChangeSelectItems = (items) => {
        let checkSelectStatus = this.state.citationSelectStatus.filter((item) => item);
        if (this.state.globalSelectAll && (checkSelectStatus.length.toString() === this.state.itemsPerPage || this.state.citationSelectStatus.length === this.state.documentCount)) {
            this.setState({ citationSelectStatus: [], selectedResults: [] })
        }
        this.setState({
            itemsPerPage: items,
            globalCollapseAll: false,
            globalSelectAll: false,
            collapsedSearchIDs: [],
            selectedResults: [],
            citationSelectStatus: [],

        }, () => {
            let totalPages = Math.ceil(this.state.documentCount / this.state.itemsPerPage)
            if (totalPages < this.state.currentPage) {
                this.setState({
                    currentPage: totalPages
                }, () => {
                    let setSelectStatus = false
                    let startIndex = this.state.currentPage * this.state.itemsPerPage - this.state.itemsPerPage
                    let endIndex = this.state.currentPage * this.state.itemsPerPage
                    let citationStatusForCurrentPage = this.state.citationSelectStatus.slice(startIndex, endIndex)
                    //console.log(startIndex,endIndex,citationStatusForCurrentPage)
                    setSelectStatus = citationStatusForCurrentPage.some(e => e === true)
                    //console.log(setSelectStatus)
                    if (setSelectStatus) {
                        this.setState({
                            globalSelectAll: true
                        })
                    }
                    else {
                        this.setState({
                            globalSelectAll: false
                        })
                    }
                    this.runWnfyApi()
                })
            }
            else {
                this.runWnfyApi()
            }
        })
    }
    calculateMonthDiff = (modifiedDate) => {
        var dates;
        var d2 = new Date();
        var d1 = new Date(modifiedDate);
        var diff = Math.abs(d2.getTime() - d1.getTime());
        dates = Math.ceil(diff / (1000 * 3600 * 24));
        if (dates <= 7) {
            return <div className="wnfy-results-time-cal-wrapper"><span>This Week</span></div>
        }
        else if (dates > 7 && dates <= 14) {
            return <div className="wnfy-results-time-cal-wrapper"><span>One Week Ago</span></div>
        }
        else if (dates > 14 && dates <= 21) {
            return <div className="wnfy-results-time-cal-wrapper"><span>2 Weeks Ago</span></div>
        }
        else if (dates > 21 && dates <= 28) {
            return <div className="wnfy-results-time-cal-wrapper"><span>3 Weeks Ago</span></div>
        }
        else {
            return <div className="wnfy-results-time-cal-wrapper"><span>One Month Ago</span></div>
        }
    }
    handlePageChange = (currentPage) => {
        window.scrollTo({ behavior: 'smooth', top: '0px' });
        //console.log(currentPage)
        let checkSelectStatus = this.state.citationSelectStatus.filter(item => item)
        if (
            this.state.globalSelectAll &&
            (checkSelectStatus.length.toString() ===
                this.state.itemsPerPage ||
                this.state.citationSelectStatus.length === this.state.documentCount)
        ) {
            this.setState({ citationSelectStatus: [], selectedResults: [] });
        }
        this.setState({
            currentPage: currentPage,
            response: [],
            isProcessing: true,
            isLoaded: false,
            globalCollapseAll: false,
            globalSelectAll: false,
            collapsedSearchIDs: [],
        }, () => {
            let setSelectStatus = false
            let startIndex = this.state.currentPage * this.state.itemsPerPage - this.state.itemsPerPage
            let endIndex = this.state.currentPage * this.state.itemsPerPage
            let citationStatusForCurrentPage = this.state.citationSelectStatus.slice(startIndex, endIndex)
            //console.log(startIndex,endIndex,citationStatusForCurrentPage)
            setSelectStatus = citationStatusForCurrentPage.some(e => e === true)
            //console.log(setSelectStatus)
            if (setSelectStatus) {
                this.setState({
                    globalSelectAll: true
                })
            }
            else {
                this.setState({
                    globalSelectAll: false
                })
            }
            this.runWnfyApi();
        })
    }
    handleCitationSelection = (index, item, key) => {
        let a = this.state.citationSelectStatus;
        a[index - 1] = !this.state.citationSelectStatus[index - 1];
        let data = this.state.selectedResults;
        let urls = this.markedDocumentsUrl;
        if (this.state.citationSelectStatus[index - 1]) {
            data.push({
                detail: item,
                index: index
            })
            urls.push(this.documentsUrl[key])
        }
        else {
            const i = data.map(x => (x.index)).indexOf(index);
            if (i > -1) {
                data.splice(i, 1); // 2nd parameter means remove one item only
                urls.splice(i, 1);
            }
            // data.splice(index - 1, 1)
        }
        this.markedDocumentsUrl = urls;
        this.setState({
            citationSelectStatus: a,
            selectedResults: data,
        })
        let checkSelectStatus = a.filter(item => item)
        if (checkSelectStatus.length === parseInt(this.state.itemsPerPage)) {
            this.setState({ globalSelectAll: true })
        }
        else if (checkSelectStatus.length === this.state.responseArr.length) {
            this.setState({ globalSelectAll: true })
        }
        else {
            this.setState({ globalSelectAll: false })
        }
    }
    handelViewSelected = () => {
        if (this.state.selectedResults.length > 0) {
            let selectedResultsDetails = this.state.selectedResults;
            sessionStorage.setItem("selectedResultsDetails", JSON.stringify(selectedResultsDetails));
            sessionStorage.setItem("selectedProduct", this.state.selectedProduct)
            let selectedResultsIndex = this.state.selectedResults.map(x => x.index)
            localStorage.setItem('selectedResults', selectedResultsIndex)
            localStorage.setItem('markedResultsUrl', JSON.stringify(this.markedDocumentsUrl))
            this.props.history.push({ pathname: "/viewmarked", search: window.location.search, state: { selectedResults: this.state.selectedResults } });
        }
        else {
            this.errorAlertMessage("There are no documents selected")
        }
    }
    runWnfyApi = () => {
        this.setState({ isProcessing: true, isLoaded: false, globalCollapseAll: false, collapsedSearchIDs: [],/* , globalSelectAll: false */ });
        let _selectedProduct = this.state.selectedProduct;
        if (_selectedProduct && _selectedProduct.includes('/')) {
            _selectedProduct = _selectedProduct.split('/')[0];
            _selectedProduct = _selectedProduct.trim();
        }

        if (_selectedProduct && _selectedProduct.includes('[')) {
            _selectedProduct = _selectedProduct.split('[')[0];
            _selectedProduct = _selectedProduct.trim();
        }
        let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'));
        var url = LocationOrigin + process.env.REACT_APP_IMS_API_PREFIX + 'qp/whatOthersAreViewing';
        let config = {
            method: 'POST',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                "query": "",
                "filters": "",
                "function": localStorage.getItem('function') ? localStorage.getItem('function').replace(/"/g, '') : "",
                "doctypepref": localStorage.getItem('doctypepref'),
                "userproducts": `${_selectedProduct}`,
                "region": localStorage.getItem('region'),
                "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                "contentpreference": localStorage.getItem('contentpreference').replace(/"/g, ''),
                "from": this.state.currentPage * this.state.itemsPerPage - this.state.itemsPerPage,
                "size": parseInt(this.state.itemsPerPage),
                "sortBy": this.state.sortBy,
                "sortField": this.state.sortField,
                accessGroup: (userAccessGroup.includes("MISEHCP")) ? "MISE" : "",
            }
        }
        axios(config)
            .then((response) => {
                this.setState({
                    isProcessing: false,
                    responseArr: response.data.body.hits.hits,
                    documentCount: response.data.body.hits.total.value,
                    isLoaded: true
                })
                if (this.state.globalSelectAll && (this.state.citationSelectStatus.length.toString() === this.state.itemsPerPage || this.state.citationSelectStatus.length === this.state.documentCount)) {
                    this.setState({ globalSelectAll: true })
                }
                else {
                    this.setState({ globalSelectAll: false })
                }
            })
            .catch(function (error) {
                //console.log(error);
            })
            .finally(() => {
                this.setState({ isProcessing: false, isLoaded: true })
            })
        this.setState({ isLoaded: true })
    }
    handleCollapseAllGlobal = () => {
        let { responseArr, collapsedSearchIDs } = this.state;
        if (!this.state.globalCollapseAll) {
            collapsedSearchIDs = responseArr.map(x => x._source.searchID);
        } else {
            collapsedSearchIDs = [];
        }
        this.setState({
            globalCollapseAll: !this.state.globalCollapseAll,
            collapsedSearchIDs: collapsedSearchIDs
        });
    }
    handleCitationSelectionGlobal = (index, item, key) => {
        let a = this.state.citationSelectStatus;
        a[index - 1] = true;
        let data = this.state.selectedResults;
        let urls = this.markedDocumentsUrl;
        if (this.state.citationSelectStatus[index - 1]) {
            data.push({
                detail: item,
                index: index,
            })
            urls.push(this.documentsUrl[key - 1])
        }
        else {
            const i = data.map(x => (x.index)).indexOf(index);
            if (i > -1) {
                data.splice(i, 1); // 2nd parameter means remove one item only
                urls.splice(i, 1);
            }
        }
        //console.log(a);
        this.markedDocumentsUrl = urls;
        this.setState({
            citationSelectStatus: a,
            selectedResults: data,
        })
    }
    handleCitationUnSelection = (index, item, itemTeaser) => {
        let a = this.state.citationSelectStatus;
        a[index - 1] = false;
        let data = this.state.selectedResults;
        let urls = this.markedDocumentsUrl;
        /* if (this.state.citationSelectStatus[index - 1]) {
            data.push({
                detail: item,
                itemTeaser: itemTeaser,
                index: index,
            })
        }
        else { */
        const i = data.map(x => (x.index)).indexOf(index);
        if (i > -1) {
            data.splice(i, 1); // 2nd parameter means remove one item only
            urls.splice(i, 1);
        }
        /* } */
        this.markedDocumentsUrl = urls;
        this.setState({
            citationSelectStatus: a,
            selectedResults: data,
        })
    }
    handleSelectAll = () => {
        //console.log('Change reflecting till result');
        this.setState({
            globalSelectAll: !this.state.globalSelectAll
        }, () => {
            let startIndex = this.state.currentPage * this.state.itemsPerPage - this.state.itemsPerPage + 1
            var count = startIndex;
            let endIndex = parseInt(this.state.itemsPerPage)
            if (this.state.globalSelectAll) {
                this.markedDocumentsUrl = [];
                this.setState({ selectedResults: [] }, () => {
                    for (let i = 1; i <= endIndex; i++) {
                        //console.log(i,this.state.responseArr[i-1])
                        if (this.state.responseArr[i - 1] !== undefined) {
                            this.handleCitationSelectionGlobal(count, this.state.responseArr[i - 1]._source, i)
                            count++;
                        }
                    }
                });
            }
            else {
                for (let i = 1; i <= endIndex; i++) {
                    if (this.state.responseArr[i - 1] !== undefined) {
                        this.handleCitationUnSelection(count, this.state.responseArr[i - 1]._source)
                        count++;
                    }
                }
            }
        })
    }
    handleGlobalEmailPopUp = (status) => {
        this.setState({ globalEmailPopUp: status })
    }
    handleEmailPopUpClose = () => {
        this.setState({ globalEmailPopUp: false })
    }
    handleOnClickReadingList = (status) => {
        this.setState({ readingListPopup: status })
    }
    handleCloseReadingList = () => {
        this.setState({ readingListPopup: false })
    }
    render() {
        const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
        if (this.state.width < 600) {
            return (
                <>
                    <ErrorBoundary>
                        <NavigationMenu customWidth={this.state.width} customHeight={this.state.height} />
                        <div className="resultsInnerWrapper">
                            {this.state.isLoaded ?
                                <div className="wnfy-results-contents-wrapper">
                                    <>
                                        <div className="wnfy-results-title-wrapper">
                                            <span className="wnfy-results-title-text">What Others Are Viewing</span>
                                        </div>
                                        <HomeTilesProductWrapper customWidth={this.state.width} products={this.state.products} handleSelectProduct={this.handleSelectProductWithName} selectedProduct={this.state.selectedProduct} handelSelectedCitation={this.handelViewSelected} handleCollapseAllGlobal={this.handleCollapseAllGlobal} globalCollapseAll={this.state.globalCollapseAll} globalSelectAll={this.state.globalSelectAll} handleSelectAll={this.handleSelectAll} handleGlobalEmailPopUp={this.handleGlobalEmailPopUp} handleOnClickReadingList={this.handleOnClickReadingList} />
                                        <Pagination metadata={this.state.documentCount} handlePageChange={this.handlePageChange} itemsPerPage={this.state.itemsPerPage} page={this.state.currentPage} customWidth={this.state.width} customHeight={this.state.height} />
                                        <div className="wnfyResultsContentWrapper">
                                            {!this.state.isProcessing ?
                                                <>
                                                    <div className="wnfy-results-list-wrapper">
                                                        {this.resultsList(this.state.responseArr, this.state.itemsPerPage, this.state.currentPage)}
                                                    </div>
                                                    {/* <Pagination metadata={this.state.documentCount} handlePageChange={this.handlePageChange} itemsPerPage={this.state.itemsPerPage} page={this.state.currentPage} /> */}
                                                </>
                                                :
                                                <div style={{ textAlign: "center", margin: "auto" }}>
                                                    <img src={imsLoader} alt="Loading Data" style={{ width: "20%", paddingTop: "5%" }} />
                                                </div>
                                            }
                                        </div>
                                    </>
                                </div>
                                :
                                <div style={{ textAlign: "center", margin: "auto" }}>
                                    <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
                                </div>
                            }
                        </div>
                        <div style={{ margin: "0% 1%", marginTop: "auto" }}>
                            <Footer customWidth={this.state.width} customHeight={this.state.height} />
                        </div>
                        {this.state.globalEmailPopUp &&
                            <GlobalSearchFeaturePopUpModal showModal={this.state.globalEmailPopUp} showEmailModal={true} handleClose={this.handleEmailPopUpClose} selectedResults={this.state.selectedResults} customWidth={this.state.width} customHeight={this.state.height} />
                        }
                        {this.state.readingListPopup &&
                            <ReadingListPopup showModal={this.state.readingListPopup} handleClose={this.handleCloseReadingList} width={this.state.width} selectedResults={this.state.selectedResults} />
                        }
                    </ErrorBoundary>
                    {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
                </>
            )
        }
        else if (this.state.width < 1000 && this.state.width >= 600) {
            return (
                <>
                    <ErrorBoundary>
                        <NavigationMenu customWidth={this.state.width} customHeight={this.state.height} />
                        <div className="resultsInnerWrapper">
                            {this.state.isLoaded ?
                                <div className="wnfy-results-contents-wrapper">
                                    <>
                                        <div className="wnfy-results-title-wrapper">
                                            <span className="wnfy-results-title-text">What Others Are Viewing</span>
                                            {(this.state.responseArr.length > 0 && this.state.width >= 600) &&
                                                <>
                                                    {this.resultListCount(this.state.itemsPerPage, this.state.currentPage)}
                                                </>
                                            }
                                        </div>


                                        <HomeTilesProductWrapper customWidth={this.state.width} products={this.state.products} handleSelectProduct={this.handleSelectProductWithName} selectedProduct={this.state.selectedProduct} handelSelectedCitation={this.handleSelectProductWithName} handleCollapseAllGlobal={this.handleCollapseAllGlobal} globalCollapseAll={this.state.globalCollapseAll} globalSelectAll={this.state.globalSelectAll} handleSelectAll={this.handleSelectAll} handleGlobalEmailPopUp={this.handleGlobalEmailPopUp} handleOnClickReadingList={this.handleOnClickReadingList} />
                                        {/* {this.state.width < 600 &&<Pagination metadata={this.state.documentCount} handlePageChange={this.handlePageChange} itemsPerPage={this.state.itemsPerPage} page={this.state.currentPage} customWidth={this.state.width} customHeight={this.state.height}/>} */}
                                        <div className="wnfyResultsContentWrapper">
                                            {!this.state.isProcessing ?
                                                <>
                                                    <div className="wnfy-results-list-wrapper">
                                                        {this.resultsList(this.state.responseArr, this.state.itemsPerPage, this.state.currentPage)}
                                                    </div>
                                                    <Pagination metadata={this.state.documentCount} handlePageChange={this.handlePageChange} itemsPerPage={this.state.itemsPerPage} page={this.state.currentPage} customWidth={this.state.width} customHeight={this.state.height} />
                                                </>
                                                :
                                                <div style={{ textAlign: "center", margin: "auto" }}>
                                                    <img src={imsLoader} alt="Loading Data" style={{ width: "20%", paddingTop: "5%" }} />
                                                </div>
                                            }
                                        </div>
                                    </>
                                </div>
                                :
                                <div style={{ textAlign: "center", margin: "auto" }}>
                                    <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
                                </div>
                            }
                        </div>
                        <div style={{ margin: "0% 1%", marginTop: "auto" }}>
                            <Footer customWidth={this.state.width} customHeight={this.state.height} />
                        </div>
                        {this.state.globalEmailPopUp &&
                            <GlobalSearchFeaturePopUpModal showModal={this.state.globalEmailPopUp} showEmailModal={true} handleClose={this.handleEmailPopUpClose} selectedResults={this.state.selectedResults} customWidth={this.state.width} customHeight={this.state.height} />
                        }
                        {this.state.readingListPopup &&
                            <ReadingListPopup showModal={this.state.readingListPopup} handleClose={this.handleCloseReadingList} width={this.state.width} selectedResults={this.state.selectedResults} />
                        }
                    </ErrorBoundary>
                    {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
                </>
            )
        }
        else {
            return (
                <>
                    <ErrorBoundary>
                        <NavigationMenu customWidth={this.state.width} customHeight={this.state.height} />
                        <div className="resultsInnerWrapper">
                            <div className="header-wrapper">
                                <div className="wnfy-results-global-features-wrapper">
                                    <GlobalFeatures handelViewSelected={this.handelViewSelected} handleCollapseAllGlobal={this.handleCollapseAllGlobal} globalCollapseAll={this.state.globalCollapseAll} globalSelectAll={this.state.globalSelectAll} handleSelectAll={this.handleSelectAll} selectedResults={this.state.selectedResults} documentsUrl={this.documentsUrl} markedDocumentsUrl={this.markedDocumentsUrl} itemsPerPage={this.state.itemsPerPage} />
                                </div>
                                {this.state.products.length > 0 && this.productList()}
                            </div>
                            {this.state.isLoaded ?
                                <div className="wnfy-results-contents-wrapper">
                                    <>
                                        <div className="wnfy-results-title-wrapper">
                                            <span className="wnfy-results-title-text">What Others Are Viewing</span>
                                            {this.state.responseArr.length > 0 &&
                                                <>
                                                    {this.resultListCount(this.state.itemsPerPage, this.state.currentPage)}
                                                </>
                                            }
                                        </div>
                                        <div style={{ background: "#F4F4F4", height: "7px" }}></div>
                                        <div className="wnfyResultsContentWrapper">
                                            {!this.state.isProcessing ?
                                                <>
                                                    <div className="wnfy-results-list-wrapper">
                                                        {this.resultsList(this.state.responseArr, this.state.itemsPerPage, this.state.currentPage)}
                                                    </div>
                                                    <Pagination metadata={this.state.documentCount} handlePageChange={this.handlePageChange} itemsPerPage={this.state.itemsPerPage} page={this.state.currentPage} customWidth={this.state.width} customHeight={this.state.height} />
                                                </>
                                                :
                                                <div style={{ textAlign: "center", margin: "auto" }}>
                                                    <img src={imsLoader} alt="Loading Data" style={{ width: "20%", paddingTop: "5%" }} />
                                                </div>
                                            }
                                        </div>
                                    </>
                                </div>
                                :
                                <div style={{ textAlign: "center", margin: "auto" }}>
                                    <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
                                </div>
                            }
                        </div>
                        <div style={{ margin: "0% 1%", marginTop: "auto" }}>
                            <Footer customWidth={this.state.width} customHeight={this.state.height} />
                        </div>
                    </ErrorBoundary>
                    {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
                </>
            )
        }
    }
}
export default WOAVPage;