import InputRange from 'react-input-range'
import PropTypes from 'prop-types'
import React from 'react'

export default function RangeSlider(props) {
  const {
    handleYearSelection,
    rangeMax,
    rangeMin,
    gotoPage,
    offSet,
    yearSelection,
  } = props

  const handleSelection = (vals) => {
    handleYearSelection(vals)
  }

  const modifiedStyles = {
    "marginLeft": offSet + "%",
    "marginRight": offSet + "%"
  }


  return (
    <>
      <div className="input-range__track background-track" />
      <div className="input-range-container" style={modifiedStyles}>
        <InputRange
          allowSameValues={true}
          formatLabel={value => `${value}`}
          maxValue={rangeMax}
          minValue={rangeMin}
          onChange={value => handleSelection(value)}
          onChangeComplete={_e => gotoPage('1')}
          step={1}
          value={yearSelection}
        />
      </div>
      <div className="year-range-display-container">
        <div className="selected-year-range-display">
          <span>From </span>
          <span>{yearSelection.min}</span>
          <span> to </span>
          <span>{yearSelection.max}</span>
        </div>
      </div>
    </>
  )
}

RangeSlider.propTypes = {
  handleYearSelection: PropTypes.func.isRequired,
  rangeMax: PropTypes.number.isRequired,
  rangeMin: PropTypes.number.isRequired,
  gotoPage: PropTypes.func.isRequired,
  offSet: PropTypes.number.isRequired,
  yearSelection: PropTypes.object.isRequired,
}
