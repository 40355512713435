import { Component } from "react";
import FilterMenuNew from '../FilterComponent/FilterMenuNew';
import FilterService from '../../services/FilterService';
import FilterSelectModalNew from '../FilterComponent/FilterSelectModalNew';
import {imsLoader} from '../../assets';
import trackingService from '../../services/trackingService';

class FilterTest extends Component {
    constructor(props){
        super(props);
        this.state={
            filterCategory: null,
            filters:{},
            isFilterModalOpen: false,
            isPopupModalOpen:false,
            isProcessing:true,
            allFilters:[],
            appliedFilters: [], // Filters that are applied to this search (set when running a search)
            selectedFilters: [], // Filters that are "selected" in the filter menu (set when running a search)
            renderfilters:[],
            documents: [], 
            ignoredFilters: [],
            openFilters:[],
            document:[],
            documentCount:0,
            selectedParentFilterArr:[],
            selectedParent:'',
            query:''
        }
        //console.log(this.props);
    }
    componentDidMount(){
      //console.log(this.props.allFilters)
        this.setState({
          query : this.props.querytext,
          documentCount : this.props.documentCount,
          document : this.props.response,
          allFilters : this.props.allFilters
        },()=>{
          //console.log('filters have some values')
          if(this.state.filters !== null){
            this.setState({
              isProcessing:this.props.isProcessing
            })
          }
        })
        if(this.props.selectedFilters.length >0){
            this.setState({selectedFilters:this.props.selectedFilters})
          
        }
        if(this.props.filters !== null){
          this.setState({
            filters:this.props.filters
          })
        }
        if(this.props.appliedFilters.length > 0){
          this.setState({
            appliedFilters: this.props.appliedFilters,
            renderfilters : this.props.appliedFilters
          })
        }
        if(this.props.selectedParentFilterArr.length > 0){
          this.setState({
            selectedParentFilterArr: this.props.selectedParentFilterArr
          })
        }
    }
  
  handleApplyFilters=()=> {
    //console.log(this.props.query);
    //console.log(this.state.selectedFilters)
    this.setState(state => {
      return {
        appliedFilters: [...state.renderfilters],
        selectedFilters:[...state.selectedFilters],
        isFilterModalOpen: false,
        selectedParent:''
      }
    }, () => {
      this.handleSubmit()
    })
  }
  handleCloseFilterSelect=()=> {
    this.setState({
      isFilterModalOpen: false,
      selectedParent:''
    })
  }
  handleChangeFilter=(filter, isChecked)=> {
    //console.log(this.state.selectedFilters)
    const {
        filters,
        selectedFilters,
        renderfilters
      } = this.state
  
      const filterService = new FilterService()
      let newFilters,updatedFilters,tempFilters
      if (isChecked) {
        newFilters = filterService.addChildLeavesToFilterSet(
          filters,
          filter,
          selectedFilters
        )
        const parentNode = filterService.isFilterInFilterSet(filter,newFilters)
        if(!parentNode){
          //console.log(parentNode)
          renderfilters.push(filter)
          newFilters.push(filter)
        }
        else{
          updatedFilters = filterService.addChildLeavesToFilterSet(
            filters,
            filter,
            renderfilters
          )
          this.setState({ renderfilters : updatedFilters})
        }

      } else {
          const hasFilterAndChildren = filterService.doesFilterSetHaveAllChildLeaves(
            filters,
            filter,
            selectedFilters
          )
          if (hasFilterAndChildren) {
            newFilters = filterService.removeChildLeavesFromFilterSet(
              filters,
              filter,
              selectedFilters
            )
          } else {
            newFilters = filterService.addChildLeavesToFilterSet(
              filters,
              filter,
              selectedFilters
            )
          }
          /*Check if the unchecked value is a parentNode*/
          if(newFilters.length > 0){
            const parentLevel = filterService.findLevelOfSelectedFilter(filter)
            if(parentLevel <= 2){
              const parent = filterService.findParentAlreadyExists(
                filter,
                renderfilters
              )
              if(parent){
                let removeChildFilter
                tempFilters = filterService.removeChildLeavesFromFilterSet(
                  filters,
                  filter,
                  renderfilters
                )
                if(tempFilters.length > 0){
                  removeChildFilter = tempFilters.filter(item=>!item.startsWith(filter))
                  updatedFilters = filterService.addFilterToFilterSet(
                    filter,
                    removeChildFilter
                  )
                }
                else{
                  updatedFilters = filterService.addFilterToFilterSet(
                    filter,
                    tempFilters
                  )
                }
              }
              else{
                tempFilters = filterService.removeFilterFromFilterSet(
                  filter,
                  renderfilters
                )
                updatedFilters = filterService.removeChildLeavesFromFilterSet(
                  filters,
                  filter,
                  tempFilters
                )
              }
            }
            else{
              updatedFilters = newFilters
            }
          }
          else{
            updatedFilters = filterService.removeChildLeavesFromFilterSet(
              filters,
              filter,
              renderfilters
            )
          }
          if(newFilters.length === 0){
            updatedFilters = []
          }
          this.setState({renderfilters:updatedFilters})
        }
        this.setState({ selectedFilters: newFilters })
      //console.log(this.state.selectedFilters)
  }
  handleToggleFilter=(filter)=> {
    const {
        openFilters
      } = this.state
  
      const filterService = new FilterService()
      let newOpenFilters
  
      if (filterService.isFilterInFilterSet(filter, openFilters)) {
        newOpenFilters = filterService.removeFilterFromFilterSet(filter, openFilters)
      } else {
        newOpenFilters = filterService.addFilterToFilterSet(filter, openFilters)
      }
  
      this.setState({ openFilters: newOpenFilters })
  }
  handleChangeFilterCategory=(filterCategory)=> {
    this.setState({
      filterCategory,
      selectedParent:filterCategory,
      //selectedFilters: this.state.appliedFilters,
      isFilterModalOpen: true
    })
  }
  handleClearFilters=()=> {
    this.setState({
      appliedFilters: [],
      appliedProduct: '',
      selectedFilters: [],
      renderfilters:[]
    }, () => {
      this.handleSubmit();
    })
  }
  handleSubmit=()=>{
      const ts = new trackingService();
      ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), 'Applied Filters', '', window.location.href, this.props.querytext, this.state.appliedFilters, '', '', '', '', '', '', '', '', '');
      this.props.handleOnChangeInfilter(this.state.filters,this.state.appliedFilters,this.state.selectedFilters,this.state.document,this.state.documentCount,this.state.query);
  }
  render(){
    //console.log(this.props.appliedFilters)
      return(
          <>
          {/* <div className="filter-container scrollable contained">
            <div className="filter-item clickable" title="Recently Added/Updated">
                <div className="filter-text">
                Recently Added/Updated
                </div> 
                <FontAwesomeIcon 
                className='filter-chevron'
                icon="chevron-right"
                fixedWidth
                color='#979797' />
            </div>
          </div> */}
          {this.props.isProcessing? 
          <div style={{textAlign:"center",padding:"10%"}}>
              <img src={imsLoader} alt="Loading Filters" style={{width:"20%"}}/>
          </div>
          :
          <FilterMenuNew
            filterCategory={this.state.filterCategory}
            handleApply={this.handleApplyFilters}
            handleCloseFilterSelect={this.handleCloseFilterSelect}
            handleSelectFilter={this.handleChangeFilter}
            handleToggleFilter={this.handleToggleFilter}
            isOpen={this.state.isFilterModalOpen}
            openFilters={this.state.openFilters}
            selectedFilters={this.state.selectedFilters}
            appliedFilters={this.state.appliedFilters}
            selectedParentFilterArr={this.state.selectedParentFilterArr}
            selectedParent={this.state.selectedParent}
            filters={this.state.filters}
            handleChangeFilterCategory={this.handleChangeFilterCategory}
            handleClearFilters={this.handleClearFilters}
            Component={FilterSelectModalNew}
            popupActive={this.state.isPopupModalOpen}
            />
          }
          </>
      )
  }
}
export default FilterTest;