import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
/* import Divider from './Divider' */
import FilterSelectModalNew from './FilterSelectModalNew';

export default function FilterMenuNew (props) {
  const {
    appliedFilters,
    filters,
    //fullDocRange,
    //handleOpenProductSelection,
    handleChangeFilterCategory,
    //handleClearFilters,
    filterCategory,
    isOpen,
    handleApply,
    handleCloseFilterSelect,
    handleSelectFilter,
    handleToggleFilter,
    openFilters,
    selectedFilters,
    selectedParentFilterArr,
    selectedParent,
    // handleYearSelection,
    //metadata,
    // gotoPage,
    //sourceType,
    // years,
    // yearSelection,
    popupActive
  } = props
  /**
   * Handle the click event of the product selection link
   *
   * @param  {Object} event
   * @param  {String} key
   */
  /* const openProductSelection = () => {
    handleOpenProductSelection()
  } */

  /**
   * Handle the click event of one of the filter categories links.
   *
   * @param  {Object} event
   * @param  {String} key
   */
  const changeFilterCategory = (event, key) => {
    event.preventDefault()
    handleChangeFilterCategory(key)
  }
  const handleRecentFilter = (event,key)=>{
    changeFilterCategory(event,key);
  }
  /**
   * Handle the click event of the clear-filters button.
   *
   * @param  {Object} event
   */
  // const clearFilters = event => {
  //   event.preventDefault()
  //   handleClearFilters()
  // }

  /**
   * Conditionally render the clear-all-filters button as necessary.
   *
   * @return {Object|null}
   */
  // const renderClearAllAppliedFilters = () => {
  //   return (
  //     <button
  //       className='filter-popup-button clickable' 
  //       disabled={appliedFilters.length < 1}
  //       onClick={clearFilters}>
  //         <div className='filter-popup-button-text'>Clear All Filters</div>
  //     </button>
  //   )
  // }

  /* const renderProductFilter = () => {
    return (
      <div className='filter-item clickable'
           onClick={openProductSelection}>
        <div className='filter-text'>
          Products
        </div>
        <FontAwesomeIcon className='filter-chevron' icon="chevron-right" fixedWidth color='#979797' />
      </div>
    )
  } */

  const orderedFilters = Object.values(filters).sort((a, b) => {
    if (a.order < b.order) {
      return -1
    }
    if (a.order > b.order) {
      return 1
    }
    return 0
  })
  //console.log(popupActive);
  return (
    <>
        {orderedFilters.length > 1 ? 
          <>
            {/* <Divider /> */}
            <div className='filter-container scrollable contained'>
                {popupActive ? null :
                <FilterSelectModalNew
                  appliedFilters={appliedFilters}
                  filterCategory={filterCategory}
                  filters={filters}
                  handleApply={handleApply}
                  handleCloseFilterSelect={handleCloseFilterSelect}
                  handleSelectFilter={handleSelectFilter}
                  handleToggleFilter={handleToggleFilter}
                  isOpen={isOpen}
                  openFilters={openFilters}
                  selectedFilters={selectedFilters}
                />
                }
                {
                  <div className="filter-item clickable" title="Recently Added/Updated" onClick={e=>handleRecentFilter(e,'Recently Added/Updated')}>
                    <div className="filter-text">
                    Recently Added/Updated
                    </div> 
                        <FontAwesomeIcon 
                        className='filter-chevron'
                        icon="chevron-right"
                        fixedWidth
                        color='#979797' />
                  </div>
                }
              {
                orderedFilters.map(filter => {
                  return (
                    <div className='filter-item clickable'
                        key={filter.key}
                        onClick={e => changeFilterCategory(e, filter.key)} title={filter.key}> 
                      <div className={selectedParent === filter.label ? 'select-filter-item' : (selectedParentFilterArr.includes(filter.label) ?'filter-text-selected':'filter-text')}>
                        {filter.label === 'Document Subtypes'?
                          <>{filter.label}<br/><span style={{fontSize:"11px"}}>(Submission-Related Documents Only)</span></>:
                          <>{filter.label}</>
                        }
                      </div>
                      <FontAwesomeIcon 
                        className='filter-chevron'
                        icon="chevron-right"
                        fixedWidth
                        color='#979797' />
                    </div>
                  )
                })
              }
              <div className="filter-clear-button-wrapper">
                 { /* renderClearAllAppliedFilters() */ } 
              </div>
            </div>
          </>
        : 
          <div className='filter-container'>
            <div>No refiners available.</div>
          </div>
        }

        
      
    </>
  )
}

FilterMenuNew.propTypes = {
  appliedFilters: PropTypes.arrayOf(
    PropTypes.string
  ).isRequired,
  filters: PropTypes.object.isRequired,
  handleChangeFilterCategory: PropTypes.func.isRequired,
  //handleClearFilters: PropTypes.func.isRequired
}
