import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import threadingService from "../../services/threadingService";
import { UserData } from "../../auth/Authorization";
import Pagination from "../../components/Paginate/Pagination";
import { imsLoader } from "../../assets";
import GenAILibraryThreadItem from "./GenAILibraryThreadItem";
import { GenAIAnsweringContext } from './GenAIAnsweringContextProvider';
import ConfirmMessagePopUpModal from "./../PopUp/ConfirmMessagePopUpModal"
import ApiServices from "../../utils/apiServices";
import trackingService from "../../services/trackingService";
import "./index.css"

class GenAILibraryThreadsList extends Component {
    static contextType = GenAIAnsweringContext;
    constructor(props) {
        super(props);
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            itemsPerPage: "10",
            currentPage: "1",
            documentCount: 0,
            isProcessing: false,
            openedContextMenuId: this.props.openedContextMenuId,
            confirmMessageContent: '',
            confirmMessageShowModal: false,
            confirmMessageId: -1,

        }
    }
    componentDidMount = async () => {
        await this.context.getAnswerThreadDataByUser();
    }
    handleOnOpenedContextMenuClick = (_id) => {
        this.setState({ openedContextMenuId: _id })
        this.props.handleDeleteAllThreadshideMenu();
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.openedContextMenuId !== this.state.openedContextMenuId) {
            this.setState({ openedContextMenuId: nextProps.openedContextMenuId })
        }
    }
    updateThreadAndCollection = async () => {
        try {
            this.context.getAnswerThreadDataByUser();
            await this.context.getAnswerCollectionsDataByUser();
        } catch (error) {
            console.log(error)
        }
    }
    handleOndeleteAnswerThreadIndexTrackingData = (sessionid) => {
        let genAiAnswer = {

            "session_id": sessionid,
        }
        const ts = new trackingService();
        ts.indexTrackingData(
            localStorage.getItem("userdisplayName"),
            localStorage.getItem("wwid"),
            localStorage.getItem("username"),
            localStorage.getItem("function"),
            localStorage.getItem("region"),
            localStorage.getItem("userproducts"),
            localStorage.getItem("doctypepref"),
            localStorage.getItem("sid"),
            localStorage.getItem("deviceType"),
            localStorage.getItem("deviceOS"),
            localStorage.getItem("browserName"),
            localStorage.getItem("browserVersion"),
            localStorage.getItem("jnjDepartment"),
            localStorage.getItem("companyname"),
            "Delete Thread GenAIAnswers2.0",
            "",
            window.location.href, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
            {}, {}, genAiAnswer
        );
    }
    deleteAnswerThreadDataBySessionId = async (sessionid) => {
        this.context.updateLibraryThreadProcessing(true);
        const ts = new threadingService();
        if (UserData && UserData.sub) {
            await ts.deleteAnswerThreadDataBySessionId(UserData.sub, sessionid)
                .then(async (response) => {
                    let service = new ApiServices();
                    await service.deleteThreadById(sessionid);
                    this.handleOndeleteAnswerThreadIndexTrackingData(sessionid);
                })
                .catch((error) => {
                    console.log(error);
                    this.context.updateLibraryThreadProcessing(false);
                }).finally(() => {
                    this.setState(
                        {
                            isProcessing: false,
                        }, () => {
                            setTimeout(async () => {
                                await this.context.getLatestAnswerThread();
                                setTimeout(async () => {
                                    await this.context.getLatestAnswerThread();
                                    setTimeout(async () => {
                                        await this.context.getLatestAnswerThread();
                                    }, 1000);
                                }, 1000);
                                await this.updateThreadAndCollection();
                                this.context.updateLibraryThreadProcessing(false);
                            }, 600);

                        })
                });
        } else {
            this.setState(
                {
                    isProcessing: false,
                }, () => {
                    this.context.updateLibraryThreadProcessing(false);
                })
        }
    }

    showConfirmMessage = (message, id) => {
        this.setState({
            confirmMessageContent: message,
            confirmMessageShowModal: true,
            confirmMessageId: id
        })
    }

    confirmHandler = async () => {
        this.setState(
            {

                confirmMessageContent: "",
                confirmMessageShowModal: false,
                // confirmMessageId: -1
            },
            async () => {
                await this.deleteAllAnswerThreadDataByUser();
                this.setState({ confirmMessageId: -1 });
            }
        );
    }

    closeConfirmMessage = () => {
        this.setState({
            confirmMessageContent: "",
            confirmMessageShowModal: false,
            confirmMessageIndex: -1
        })
    }

    renderModeThreadHistory() {
        let { libraryThreadResponse, libraryThreadCollectionResponse } = this.context;
        return (<>
            {
                libraryThreadResponse.map((threadItem, threadIndex) => {
                    let threadCollections = libraryThreadCollectionResponse.filter(x => x.session_id == threadItem._source.answeringinfo.session_id);
                    return (<GenAILibraryThreadItem
                        handleOnOpenedContextMenuClick={this.handleOnOpenedContextMenuClick}
                        deleteAnswerThreadDataBySessionId={this.deleteAnswerThreadDataBySessionId}
                        openedContextMenuId={this.state.openedContextMenuId}
                        source={threadItem._source}
                        threadCollections={threadCollections}
                        key={`Thread-History${threadItem._id}`}
                        updateThreadAndCollection={this.updateThreadAndCollection} />)
                })
            }
        </>)
    }
    render() {
        let { isProcessing, } = this.state;
        const { confirmMessageContent, confirmMessageShowModal } = this.state;
        let { libraryThreadDocumentCount, libraryThreadItemsPerPage, libraryThreadCurrentPage, libraryThreadResponse, isLibraryThreadProcessing } = this.context;
        if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.props.customWidth > 1000) {
            return (<div className="gen-ai-answering-library-threads-list-wrapper">
                {(isProcessing || isLibraryThreadProcessing) ?
                    <div style={{ textAlign: "center", marginBottom: "10px" }}>
                        <img src={imsLoader} alt="Loading Filters" style={{ width: "10%", marginTop: "200px" }} />
                    </div> :
                    libraryThreadResponse.length > 0 ? <>
                        <div className="gen-ai-answering-library-threads-lists">
                            <div>
                                {this.renderModeThreadHistory()}
                            </div>
                        </div>

                        <Pagination
                            metadata={libraryThreadDocumentCount}
                            handlePageChange={this.handlePageChange}
                            itemsPerPage={libraryThreadItemsPerPage}
                            page={parseInt(libraryThreadCurrentPage)}
                            customWidth={this.state.width}
                            customHeight={this.state.height}
                        />
                    </> : <div className="gen-ai-answering-library-threads-list">
                        <div className="gen-ai-answering-library-threads-list-no-threads">No threads yet</div>
                    </div>
                }
                {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
            </div>
            )
        }
    }

    handleNextClick = () => {
        try {
            if (
                this.state.currentPage <
                Math.ceil(this.state.documentCount / this.state.itemsPerPage)
            ) {
                // if (this.state.currentPage <= 1) {
                this.setState(
                    {
                        currentPage: parseInt(this.state.currentPage) + 1,
                    },
                    () => {
                        this.handlePageChange(this.state.currentPage);
                    }
                );
            }
        } catch (error) {
            this.setState({ error: error });
        }
    };
    handlePreviousClick = () => {
        try {
            if (this.state.currentPage >= 2) {
                this.setState(
                    {
                        currentPage: this.state.currentPage - 1,
                    },
                    () => {
                        this.handlePageChange(this.state.currentPage);
                    }
                );
            }
        } catch (error) {
            this.setState({ error: error });
        }
    };
    navigateToLastPage = () => {
        try {
            this.setState(
                {
                    currentPage: Math.ceil(
                        this.state.documentCount / this.state.itemsPerPage
                    ),
                },
                () => {
                    this.handlePageChange(this.state.currentPage);
                }
            );
        } catch (error) {
            this.setState({ error: error });
        }
    };
    navigateToFirstPage = () => {
        try {
            this.setState(
                {
                    currentPage: 1,
                },
                () => {
                    this.handlePageChange(this.state.currentPage);
                }
            );
        } catch (error) {
            this.setState({ error: error });
        }
    };
    handlePageChange = (currentPage) => {
        try {
            window.scrollTo({ behavior: "smooth", top: "0px" });
            this.setState(
                {
                    currentPage: currentPage,
                },
                async () => {
                    await this.context.updatelibraryThreadCurrentPage(currentPage);
                    await this.context.getAnswerThreadDataByUser();
                }
            );

        } catch (error) {
            this.setState({ error: error });
        }
    };

}
export default withRouter(GenAILibraryThreadsList);
