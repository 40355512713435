import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import { Popover } from 'react-tiny-popover';
import FilterService from '../../services/FilterService';
import RecentlyUpdated from './RecentlyFilter';

export default function FilterSelectModalNew (props) {
  const {
    appliedFilters,
    filterCategory,
    filters,
    handleApply,
    handleCloseFilterSelect,
    handleSelectFilter,
    handleToggleFilter,
    isOpen,
    openFilters,
    selectedFilters
  } = props
  const parsedAppliedFilters = appliedFilters.filter(value => value.startsWith(filterCategory))
  const parsedSelectedFilters = selectedFilters.filter(value => value.startsWith(filterCategory))
  //console.log(parsedSelectedFilters)
  //console.log(props.selectedFilters)
  //console.log(props.appliedFilters)

  /**
   * Handle the click event of the apply-filters button.
   *
   * @param  {Object} event
   */
  const applyFilters = event => {
    event.preventDefault()
    handleApply()
  }

  /**
   * Determine if the apply-changes button should be disabled.
   *
   * @return {Boolean}
   */
  const isApplyChangesButtonDisabled = () => {
    if(parsedSelectedFilters.length > 0 || parsedAppliedFilters.length > 0)
      return false
    else
      return true  
  }

  /**
   * Render the filter tree content.
   *
   * @return {Object}
   */
  const renderContent = () => {
    return (
      <>
        { renderFilters(filters[filterCategory].children) }
      </>
    )
  }

  /**
   * Render a single filter.
   *
   * @param  {Object} filter
   * @return {Object}
   */
  const renderFilter = (filter) => {
    const filterService = new FilterService();

    return (
      <div key={filter.key} className="filter-popup-item">
        <div className='filter-popup-item-button'>
          <button className={`filter-popup-item-caret clickable ${Object.keys(filter.children).length ? '' : 'hide-caret'}`}
                  onClick={e => { handleToggleFilter(filter.key) }}>
           {
            filterService.isFilterInFilterSet(filter.key, openFilters) ?
              <FontAwesomeIcon fixedWidth icon="caret-down" /> :
              <FontAwesomeIcon fixedWidth icon="caret-right" />
            }
          </button>
          <label className='filter-popup-item-label clickable'>
            <input
              checked={filterService.doesFilterSetHaveAnyChildLeaves(filters, filter.key, selectedFilters)}
              onChange={(e) => { handleSelectFilter(filter.key, e.target.checked) }}
              type="checkbox"
            />
            <div style={{marginTop:"2px"}}>{ filter.label } ({ filter.count })</div>
          </label>
        </div>
        <div className="filter-popup-subitem">
          { filterService.isFilterInFilterSet(filter.key, openFilters) ? renderFilters(filter.children) : null }
        </div>
      </div>
    )
  }

  /**
   * Render a collection of filters.
   *
   * @param  {Array}  filters
   * @return {Object}
   */
  const renderFilters = (filters) => {
    return Object.entries(filters).map(([path, filter]) => {
      return renderFilter(filter)
    })
  }

  /**
   * Render a message when no filters are available to show.
   *
   * @return {Object}
   */
  const renderNoContent = () => {
    return (
      <div>
        No filters available.
      </div>
    )
  }
  const renderHeaderContent = () => {
    return(
      <div className='filter-category'>
        {filterCategory === 'Document Subtypes'?
        <div className="filter-category-text">{filterCategory} (Submission-Related Documents Only)</div>:
        <div className="filter-category-text">{filterCategory} </div>}
        <div className='filter-times'>
          <button onClick={handleCloseFilterSelect}>
            <FontAwesomeIcon icon="times" fixedWidth color='#979797' className='filter-icon-times'/>
          </button>
        </div>
      </div>
    )
  }

  if (!isOpen) {
    // Popover has issues hiding itself
    // https://github.com/alexkatz/react-tiny-popover/issues/69
    return ""
  }

  return (
    <div>
      <Popover
        isOpen={true}
        positions={['right','top']} // preferred position
        align={'start'}
        reposition={false} 
        content={(positions,align) => (
          <div className='filter-modal-wrapper'>
              {renderHeaderContent()}
            <div className='filter-modal-body'>
              
              <button 
                className='apply-filter'
                disabled={isApplyChangesButtonDisabled()}
                onClick={applyFilters}
                title="Apply Changes">
                  Apply Changes
              </button>
              
              <div className="filter-sub-category-wrapper">
                { filterCategory && filters[filterCategory] !== undefined ? renderContent() : (filterCategory === 'Recently Added/Updated' ? <RecentlyUpdated/>:renderNoContent()) }
              </div>
            </div>
          </div>
        )}>
          <div />
      </Popover>
    </div>

     

  )
}

FilterSelectModalNew.propTypes = {
  appliedFilters: PropTypes.arrayOf(
    PropTypes.string
  ).isRequired,
  filterCategory: PropTypes.string,
  filters: PropTypes.object.isRequired,
  handleApply: PropTypes.func.isRequired,
  handleCloseFilterSelect: PropTypes.func.isRequired,
  handleSelectFilter: PropTypes.func.isRequired,
  handleToggleFilter: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  openFilters: PropTypes.arrayOf(
    PropTypes.string
  ).isRequired,
  selectedFilters: PropTypes.arrayOf(
    PropTypes.string
  ).isRequired
}
