import { Modal } from "react-bootstrap";
import { useState } from "react";
import Button from "../Button";
import './GlobalSearchFeaturesPopUp.css'

export default function DublicateBookmarkPopUp(props) {
    const {
        height,
        color,
        showModal,
        handleClose,
        bookmarkName
    } = props
    const [showPop, setShow] = useState(showModal)
    return (

        <Modal
            show={showPop}
            size="lg md sm xs"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="savePopUp"
            scrollable={true}
        >
            <div>
                <div style={{ padding: "0 5%" }}>
                    <Modal.Header >
                        <div style={{ textAlign: "left" }}>
                            <Modal.Title>
                                <span className="saveModal-heading" style={{ color: color }}>Bookmark '{bookmarkName}' already exists in your list.</span>
                            </Modal.Title>
                        </div>
                    </Modal.Header>
                    <Modal.Body scrollable={true} style={{ height: height }}>
                        <div className="dublicateModal-text">
                            Please consider having <b>unique names</b> for an easy identification.
                        </div>
                    </Modal.Body>
                    <div className="bookmarkModal-buttonsContainer">
                        {/* <Button text="View" customColor="#2EB000" action={() => { setShow(false); handleClose(false); onViewClick() }} />
                        <Button text="Download" customColor="#2EB000" action={() => { setShow(false); handleClose(false); onClickDownload() }} /> */}
                        <Button text="Close" customColor="#0074B0" action={() => { setShow(false); handleClose(false) }} />
                    </div>
                </div>
            </div>
        </Modal>
    )
}