/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
import { Component } from "react";
import FilterMenu from "./FilterMenu";
import FilterService from "../../services/FilterService";
import FilterSelectModal from "./FilterSelectModal";
import { imsLoader } from "../../assets";
//import axios from "axios";
import trackingService from "../../services/trackingService";

class SearchFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterCategory: null,
      filters: {},
      isFilterModalOpen: false,
      isPopupModalOpen: false,
      isProcessing: true,
      allFilters: [],
      appliedFilters: [], // Filters that are applied to this search (set when running a search)
      selectedFilters: [], // Filters that are "selected" in the filter menu (set when running a search)
      renderfilters: [],
      documents: [],
      ignoredFilters: [],
      openFilters: [],
      document: [],
      documentCount: 0,
      selectedParentFilterArr: [],
      selectedParent: "",
      query: "",
      appliedFiltersForOnlyiMS: [],
      filterYears: [],
      fromYear: "",
      toYear: "",
      fromRecentlyAddedUpdated: "",
      toRecentlyAddedUpdated: "",
    };
    //console.log(this.props);
  }
  //console.log(this.props);

  componentDidMount() {
    //console.log(this.props.allFilters)
    this.setState(
      {
        query: this.props.querytext,
        documentCount: this.props.documentCount,
        document: this.props.response,
        allFilters: this.props.allFilters,
      },
      () => {
        //console.log('filters have some values')
        if (this.state.filters !== null) {
          this.setState({
            isProcessing: this.props.isProcessing,
          });
        }
      }
    );
    if (this.props.selectedFilters.length > 0) {
      this.setState({ selectedFilters: this.props.selectedFilters });
    }
    if (this.props.filters !== null) {
      this.setState({
        filters: this.props.filters,
      });
    }
    if (this.props.appliedFilters.length > 0) {
      this.setState({
        appliedFilters: this.props.appliedFilters,
        renderfilters: this.props.appliedFilters,
      });
    }
    if (this.props.selectedParentFilterArr.length > 0) {
      this.setState({
        selectedParentFilterArr: this.props.selectedParentFilterArr,
      });
    }
    if (
      this.props.appliedFiltersForOnlyiMS &&
      this.props.appliedFiltersForOnlyiMS.length > 0
    ) {
      this.setState({
        appliedFiltersForOnlyiMS: this.props.appliedFiltersForOnlyiMS,
      });
    }
    if (this.props.filterYears && this.props.filterYears.length > 0) {
      this.setState({
        filterYears: this.props.filterYears,
      });
    }
    if (this.props.fromYear !== null) {
      this.setState({
        fromYear: this.props.fromYear,
      });

      if (this.props.toYear !== null) {
        this.setState({
          toYear: this.props.toYear,
        });
      }
      if (this.props.fromRecentlyAddedUpdated !== null) {
        this.setState({
          fromRecentlyAddedUpdated: this.props.fromRecentlyAddedUpdated,
        });
      }
      if (this.props.toRecentlyAddedUpdated !== null) {
        this.setState({
          toRecentlyAddedUpdated: this.props.toRecentlyAddedUpdated,
        });
      }
    }
    if (this.props.toYear !== null) {
      this.setState({
        toYear: this.props.toYear,
      });
    }
  }

  componentWillReceiveProps(receiveProps) {
    this.setState(
      {
        query: receiveProps.querytext,
        documentCount: receiveProps.documentCount,
        document: receiveProps.response,
        allFilters: receiveProps.allFilters,
        filters: receiveProps.filters,
      },
      () => {
        if (this.state.filters !== null) {
          this.setState({
            isProcessing: receiveProps.isProcessing,
          });
        }
      }
    );
  }

  handleApplyFilters = () => {
    //console.log(this.props.query);
    //console.log(this.state.selectedFilters)

    this.setState(
      (state) => {
        return {
          appliedFilters: [...state.renderfilters],
          selectedFilters: [...state.selectedFilters],
          isFilterModalOpen: false,
          selectedParent: "",
        };
      },
      () => {
        let getUseFilters = JSON.parse(
          localStorage.getItem("recentlyUsedFilters")
        );
        let recentlyUsedFilters =
          getUseFilters === undefined || getUseFilters === null
            ? []
            : getUseFilters;

        for (let index = 0; index < this.state.appliedFilters.length; index++) {
          const element = this.state.appliedFilters[index];
          let key = element?.split("/")[0];
          let newArr = [];
          for (let index = 0; index < recentlyUsedFilters.length; index++) {
            const element = recentlyUsedFilters[index];
            Object.keys(element).map((item) => {
              newArr.push(item);
            });
          }
          newArr = newArr.filter((item) => item === key);
          if (newArr.length < 5) {
            if (recentlyUsedFilters.some((item) => item[key] === element)) {
              recentlyUsedFilters.push({
                [key]: element,
              });
              let idx = recentlyUsedFilters.findIndex(
                (p) => p[key] === element
              );
              recentlyUsedFilters.splice(idx, 1);
            } else {
              recentlyUsedFilters.push({
                [key]: element,
              });
            }
          } else {
            let filtered = [];
            recentlyUsedFilters.filter((x) => {
              Object.keys(x)
                .filter(
                  (filterkey) => filterkey === key /* newArr.includes(key) */
                )
                .forEach((data) => {
                  filtered.push({ [key]: x[data] });
                });
            });
            if (filtered.some((item) => item[key] === element)) {
              filtered.push({
                [key]: element,
              });
              let idx = filtered.findIndex((p) => p[key] === element);
              filtered.splice(idx, 1);
            } else {
              filtered.shift();
              filtered.push({
                [key]: element,
              });
            }
            let otherFilter = recentlyUsedFilters.filter(
              (data) => data[key] === undefined
            );
            recentlyUsedFilters = [...filtered, ...otherFilter];
          }
        }

        let uniqData = [];
        recentlyUsedFilters.map((item) => {
          if (
            uniqData.findIndex(
              (c) => JSON.stringify(c) === JSON.stringify(item)
            ) === -1
          ) {
            uniqData.push(item);
          }
        });
        localStorage.setItem("recentlyUsedFilters", JSON.stringify(uniqData));
        this.handleSubmit();
      }
    );
  };
  removeDuplicateObjectFromArray(array, key) {
    var check = new Set();
    return array.filter((obj) => !check.has(obj[key]) && check.add(obj[key]));
  }
  handleCloseFilterSelect = () => {
    let appliedFilters = this.state.appliedFilters;
    if (appliedFilters.length === 0) {
      this.setState({
        selectedFilters: [],
        renderfilters: [],
      });
    } else {
      let childsWithParents = [];
      let { filters } = this.state;
      appliedFilters.forEach((element) => {
        const filterService = new FilterService();
        childsWithParents = [
          ...childsWithParents,
          ...filterService.getChildKeysOfFilter(filters, element),
        ];
      });
      childsWithParents = [...appliedFilters, ...childsWithParents];
      this.setState({
        selectedFilters: childsWithParents,
        renderfilters: appliedFilters,
      });
    }
    this.setState(
      {
        isFilterModalOpen: false,
        isPopupModalOpen: true,
      },
      () => this.setState({ selectedParent: "" })
    );
  };
  handleChangeFilter = (filter, isChecked) => {
    let { filters, selectedFilters, renderfilters } = this.state;
    const filterService = new FilterService();
    let newFilters, updatedFilters, tempFilters;
    if (isChecked) {
      newFilters = filterService.addChildLeavesToFilterSet(
        filters,
        filter,
        selectedFilters
      );
      const parentNode = filterService.isFilterInFilterSet(filter, newFilters);
      // console.log(parentNode,newFilters)
      if (!parentNode) {
        newFilters = filterService.getChildKeysOfFilter(filters, filter);
        newFilters = selectedFilters.concat(newFilters);
        newFilters.push(filter);
        if (
          filterService.getImmediateChild(
            filterService.getChildKeysOfFilter(
              filters,
              filterService.getParentOfFilter(filter)
            ),
            filterService.getParentOfFilter(filter)
          ).length === 1
        ) {
          renderfilters.push(filter);
        } else {
          //let filterArr = filter.split("/");
          let parentfilter, childcheck, nextNewFilters, immediateChildCheck;
          // if (filterArr.length > 2) {
          //   for (let index = 0; index < filterArr.length - 2; index++) {
          //     // const element = filterArr[index];
          //     if (filterArr.length > 3) {
          //       filterArr.pop()
          //     }
          //     let element = filterArr.join('/')
          //     // parentfilter = filterService.getParentOfFilter(element)
          //     parentfilter = filter.split("/").length > 2
          //       ? filterService.getParentOfFilter(element)
          //       : filter;
          //     childcheck = filterService.getChildKeysOfFilter(filters, parentfilter)
          //     immediateChildCheck = filterService.getImmediateChild(
          //       childcheck,
          //       parentfilter
          //     );
          //     nextNewFilters = newFilters.filter((el) => childcheck.includes(el));
          //     nextNewFilters.push(element)
          //   }
          // }
          // else {
          //  WORKING WITH IMMEDIATE CHILD
          parentfilter =
            filter.split("/").length > 2
              ? filterService.getParentOfFilter(filter)
              : filter;
          childcheck = filterService.getChildKeysOfFilter(
            filters,
            parentfilter
          );
          immediateChildCheck = filterService.getImmediateChild(
            childcheck,
            parentfilter
          );
          // newFilters.push(filter);
          // let filterChildren = filterService.getChildKeysOfFilter(filters, filter)
          nextNewFilters = newFilters.filter((el) => childcheck.includes(el));
          // }
          let commanElements = filterService.findCommonElements(
            nextNewFilters,
            immediateChildCheck
          );
          if (commanElements) {
            // if (childcheck.length === nextNewFilters.length) {
            let newrenderfilter = renderfilters.filter(
              (item) => !item.includes(parentfilter)
            );
            let updatdNewRenderFilter = newrenderfilter.concat(parentfilter);
            this.setState({ renderfilters: updatdNewRenderFilter });
          } else {
            renderfilters.push(filter);
          }
        }
        // renderfilters.push(filter)
        // newFilters.push(filter)
      } else {
        // if (filterService.getChildKeysOfFilter(filters, filterService.getParentOfFilter(filter)).length === 1) {
        if (
          filterService.getImmediateChild(
            filterService.getChildKeysOfFilter(
              filters,
              filterService.getParentOfFilter(filter)
            ),
            filterService.getParentOfFilter(filter)
          ).length === 1
        ) {
          updatedFilters = filterService.addChildLeavesToFilterSet(
            filters,
            filter,
            renderfilters
          );
          this.setState({ renderfilters: updatedFilters });
        } else {
          // let filterArr = filter.split("/");
          let parentfilter, childcheck, nextNewFilters, immediateChildCheck;
          // if (filterArr.length > 2) {
          //   for (let index = 0; index < filterArr.length - 2; index++) {
          //     // const element = filterArr[index];
          //     if (filterArr.length > 3) {
          //       filterArr.pop()
          //     }
          //     let element = filterArr.join('/')
          //     // parentfilter = filterService.getParentOfFilter(element)
          //     parentfilter = filter.split("/").length > 2
          //       ? filterService.getParentOfFilter(element)
          //       : filter;
          //     childcheck = filterService.getChildKeysOfFilter(filters, parentfilter)
          //     immediateChildCheck = filterService.getImmediateChild(
          //       childcheck,
          //       parentfilter
          //     );
          //     nextNewFilters = newFilters.filter((el) => childcheck.includes(el));
          //     nextNewFilters.push(element)
          //   }
          // }
          // else {
          //  WORKING WITH IMMEDIATE CHILD
          parentfilter =
            filter.split("/").length > 2
              ? filterService.getParentOfFilter(filter)
              : filter;
          childcheck = filterService.getChildKeysOfFilter(
            filters,
            parentfilter
          );
          immediateChildCheck = filterService.getImmediateChild(
            childcheck,
            parentfilter
          );
          nextNewFilters = newFilters.filter((el) => childcheck.includes(el));
          // }
          let commanElements = filterService.findCommonElements(
            nextNewFilters,
            immediateChildCheck
          );
          if (commanElements) {
            // if (childcheck.length === nextNewFilters.length) {
            let newrenderfilter = renderfilters.filter(
              (item) => !item.includes(parentfilter)
            );
            let updatdNewRenderFilter = newrenderfilter.concat(parentfilter);
            this.setState({ renderfilters: updatdNewRenderFilter });
          } else {
            updatedFilters = filterService.addChildLeavesToFilterSet(
              filters,
              filter,
              renderfilters
            );
            this.setState({ renderfilters: updatedFilters });
          }
        }
      }
    } else {
      const hasFilterAndChildren =
        filterService.doesFilterSetHaveAllChildLeaves(
          filters,
          filter,
          selectedFilters
        );
      if (hasFilterAndChildren) {
        newFilters = filterService.removeChildLeavesFromFilterSet(
          filters,
          filter,
          selectedFilters
        );
        let filterChildren = filterService.getChildKeysOfFilter(
          filters,
          filter
        );
        newFilters = newFilters.filter((el) => !filterChildren.includes(el));
        // newFilters = newFilters.filter(i => i.split('/').length === filter.split('/').length || i.split('/').length < filter.split('/').length)
      } else {
        newFilters = filterService.addChildLeavesToFilterSet(
          filters,
          filter,
          selectedFilters
        );
      }
      /*Check if the unchecked value is a parentNode*/
      if (newFilters.length > 0) {
        const parentLevel = filterService.findLevelOfSelectedFilter(filter);
        if (parentLevel <= 2) {
          const parent = filterService.findParentAlreadyExists(
            filter,
            renderfilters
          );
          if (parent) {
            let removeChildFilter;
            tempFilters = filterService.removeChildLeavesFromFilterSet(
              filters,
              filter,
              renderfilters
            );
            if (tempFilters.length > 0) {
              removeChildFilter = tempFilters.filter(
                (item) => !item.startsWith(filter)
              );
              updatedFilters = filterService.addFilterToFilterSet(
                filter,
                removeChildFilter
              );
            } else {
              updatedFilters = filterService.addFilterToFilterSet(
                filter,
                tempFilters
              );
            }
          } else {
            tempFilters = filterService.removeFilterFromFilterSet(
              filter,
              renderfilters
            );
            updatedFilters = filterService.removeChildLeavesFromFilterSet(
              filters,
              filter,
              tempFilters
            );
            newFilters = newFilters.filter((x) => x !== filter);
          }
        } else {
          const parentNode = filterService.isFilterInFilterSet(
            filter,
            newFilters
          );
          filterService.removeChildLeavesFromFilterSet(
            filters,
            filter,
            renderfilters
          );
          // console.log(x)
          if (parentNode) {
            var idx1 = newFilters.indexOf(filter);
            var idx = renderfilters.indexOf(filter);
            if (idx1 !== -1) newFilters.splice(idx1, 1);
            if (idx !== -1) renderfilters.splice(idx, 1);
            updatedFilters = renderfilters;
          } else {
            newFilters = filterService.removeChildLeavesFromFilterSet(
              filters,
              filter,
              selectedFilters
            );
            updatedFilters = newFilters;
          }
          updatedFilters = newFilters.filter(
            (i) =>
              i.split("/").length === filter.split("/").length ||
              i.split("/").length < filter.split("/").length ||
              i.split("/").length > filter.split("/").length
          );
          // updatedFilters = newFilters
          // updatedFilters = filterService.removeChildLeavesFromFilterSet(
          //   filters,
          //   filter,
          //   renderfilters
          // )
          // let parent = filterService.getParentOfFilter(filter)
          let parent = filter;
          let parentArr = parent.split("/");
          // const parent = filterService.findParentAlreadyExists(
          //   filter,
          //   renderfilters
          // );
          for (let i = 0; i < parentArr.length; i++) {
            parent = filterService.getParentOfFilter(parent);
            if (parent) {
              updatedFilters = updatedFilters.filter((x) => x !== parent);
              newFilters = newFilters.filter((x) => x !== parent);
            }
          }
          let merged = filterService.getChildKeysForUpdatedFilters(
            updatedFilters,
            filters
          );
          updatedFilters = updatedFilters.filter(
            (el) => /* !xyz.includes( el ) && */ !merged.includes(el)
          );
        }
      } else {
        updatedFilters = filterService.removeChildLeavesFromFilterSet(
          filters,
          filter,
          renderfilters
        );
      }
      if (newFilters.length === 0) {
        updatedFilters = [];
      }
      this.setState({ renderfilters: updatedFilters });
    }
    this.setState({ selectedFilters: newFilters });
    // console.log(this.state.selectedFilters)
    // console.log(newFilters,"xx",updatedFilters)
  };
  handleToggleFilter = (filter) => {
    const { openFilters } = this.state;

    const filterService = new FilterService();
    let newOpenFilters;

    if (filterService.isFilterInFilterSet(filter, openFilters)) {
      newOpenFilters = filterService.removeFilterFromFilterSet(
        filter,
        openFilters
      );
    } else {
      newOpenFilters = filterService.addFilterToFilterSet(filter, openFilters);
    }

    this.setState({ openFilters: newOpenFilters });
  };
  handleChangeFilterCategory = (filterCategory) => {
    this.setState({
      filterCategory,
      selectedParent: filterCategory,
      //selectedFilters: this.state.appliedFilters,
      isFilterModalOpen: true,
    });
    if (this.state.isPopupModalOpen) {
      this.setState({ isPopupModalOpen: false });
    }
  };
  handleClearFilters = () => {
    this.setState(
      {
        appliedFilters: [],
        appliedProduct: "",
        selectedFilters: [],
        renderfilters: [],
      },
      () => {
        this.handleSubmit();
      }
    );
  };
  handleResetFilters = () => {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/recommended"
    ) {
      this.setState({
        selectedFilters: [],
        renderfilters: this.state.renderfilters.filter(
          (filter) => filter && !filter.startsWith(this.state.filterCategory)
        ),
        appliedFilters: this.state.appliedFilters.filter(
          (filter) => filter && !filter.startsWith(this.state.filterCategory)
        ),
      });
    } else {
      this.setState(
        {
          selectedFilters: [],
          renderfilters: this.state.renderfilters.filter(
            (filter) => filter && !filter.startsWith(this.state.filterCategory)
          ),
          appliedFilters: this.state.appliedFilters.filter(
            (filter) => filter && !filter.startsWith(this.state.filterCategory)
          ),
        },
        () => this.handleSubmit()
      );
    }
  };
  handleSubmit = () => {
    // let params = new URLSearchParams(this.props.history.location.search)
    // let csPath = params.get('cspath');
    // let csproduct = params.get('csproduct');
    const ts = new trackingService();
    if (window.location.pathname !== "/pubMedResults") {
      ts.indexTrackingData(
        localStorage.getItem("userdisplayName"),
        localStorage.getItem("wwid"),
        localStorage.getItem("username"),
        localStorage.getItem("function"),
        localStorage.getItem("region"),
        localStorage.getItem("userproducts"),
        localStorage.getItem("doctypepref"),
        localStorage.getItem("sid"),
        localStorage.getItem("deviceType"),
        localStorage.getItem("deviceOS"),
        localStorage.getItem("browserName"),
        localStorage.getItem("browserVersion"),
        localStorage.getItem("jnjDepartment"),
        localStorage.getItem("companyname"),
        "Applied Filters",
        "",
        window.location.href,
        this.props.querytext,
        this.state.appliedFilters,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        this.props.csPath !== null ? this.props.csPath : "",
        this.props.csproduct !== null ? this.props.csproduct : ""
      );
    } else {
      ts.indexTrackingData(
        localStorage.getItem("userdisplayName"),
        localStorage.getItem("wwid"),
        localStorage.getItem("username"),
        localStorage.getItem("function"),
        localStorage.getItem("region"),
        localStorage.getItem("userproducts"),
        localStorage.getItem("doctypepref"),
        localStorage.getItem("sid"),
        localStorage.getItem("deviceType"),
        localStorage.getItem("deviceOS"),
        localStorage.getItem("browserName"),
        localStorage.getItem("browserVersion"),
        localStorage.getItem("jnjDepartment"),
        localStorage.getItem("companyname"),
        "Applied PubMed Filters",
        "",
        window.location.href,
        this.props.querytext,
        this.state.appliedFilters,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        this.props.csPath !== null ? this.props.csPath : "",
        this.props.csproduct !== null ? this.props.csproduct : ""
      );
    }
    this.props.handleOnChangeInfilter(
      this.state.filters,
      this.state.appliedFilters,
      this.state.selectedFilters,
      this.state.document,
      this.state.documentCount,
      this.state.query
    );
  };
  render() {
    //console.log(this.props.appliedFilters)
    return (
      <>
        {this.props.isProcessing ? (
          <div style={{ textAlign: "center", padding: "10%" }}>
            <img
              src={imsLoader}
              alt="Loading Filters"
              style={{ width: "20%" }}
            />
          </div>
        ) : (
          <FilterMenu
            filterCategory={this.state.filterCategory}
            handleApply={this.handleApplyFilters}
            handleCloseFilterSelect={this.handleCloseFilterSelect}
            handleSelectFilter={this.handleChangeFilter}
            handleToggleFilter={this.handleToggleFilter}
            isOpen={this.state.isFilterModalOpen}
            openFilters={this.state.openFilters}
            selectedFilters={this.state.selectedFilters}
            appliedFilters={this.state.appliedFilters}
            selectedParentFilterArr={this.state.selectedParentFilterArr}
            selectedParent={this.state.selectedParent}
            filters={this.state.filters}
            handleChangeFilterCategory={this.handleChangeFilterCategory}
            handleClearFilters={this.handleClearFilters}
            Component={FilterSelectModal}
            popupActive={this.state.isPopupModalOpen}
            handleResetFilters={this.handleResetFilters}
            customWidth={this.props.customWidth}
            appliedFiltersForOnlyiMS={this.state.appliedFiltersForOnlyiMS}
            filterYears={this.state.filterYears}
            fromYear={this.state.fromYear}
            toYear={this.state.toYear}
            handleYearSelection={this.props.handleYearSelection}
            handlePageChange={this.props.handlePageChange}
            recentlyUsedFilters={this.props.recentlyUsedFilters}
            fromRecentlyAddedUpdated={this.state.fromRecentlyAddedUpdated}
            toRecentlyAddedUpdated={this.state.toRecentlyAddedUpdated}
            gotoPageRecentlyAddedUpdated={
              this.props.gotoPageRecentlyAddedUpdated
            }
            isError={this.props.isError}
            renderedFiltered={this.state.renderfilters}
          />
        )}
      </>
    );
  }
}
export default SearchFilter;
