import { Modal } from "react-bootstrap";
import { popCancelBttn } from "../../assets";
import { useState } from "react";
import Button from "../Button";

const convertToHtml = (data)=>{
    return <div dangerouslySetInnerHTML={{__html:data}}></div>
}
export default function PopUpModal(props){
    const {
        height,
        color,
        header,
        content,
        showModal,
        icon,
        handleClose,
        btnText,
        btnPosition,
        btnColor
        }=props  
    const [showPop,setShow] = useState(showModal)    
    return(
        <Modal
        show={showPop}
        size="lg md sm xs"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="popup-transition"
        className="z-index-popup-transition"
        backdropClassName="z-index-popup-transition-backdrop"
        scrollable={true}
        >
        <div style={{borderLeft:`5px solid ${color}`}}>
            <div className="popup-cancel-btn-wrapper">
                <img src={popCancelBttn} alt="" onClick={(e)=>{setShow(false);handleClose(false)}}/>
            </div>
            <div style={{padding:"0 5%"}}>
                <Modal.Header style={{justifyContent:"center",borderBottomColor:color}}>
                    <div style={{textAlign:"center"}}>
                    <Modal.Title>
                        {icon ?
                        <div>
                            <img src={icon} alt="" className="popup-header-icon-wrapper"/>
                        </div>
                        :
                        <div className="popup-header-icon-wrapper"></div>}
                        <span style={{color:color}}>{header}</span>   
                    </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body scrollable={true} style={{height:height}}>
                    <div>
                        {convertToHtml(content)}
                    </div>
                </Modal.Body>
                <div style={{textAlign:btnPosition,padding:"3% 0"}}>
                    <Button text={btnText} customColor={btnColor} action={()=>{setShow(false);handleClose(false)}}/>
                </div>
            </div>
        </div>
        </Modal>
    )
}