/* eslint-disable no-useless-escape */
import { Component } from "react";
import { rightSideArrow, imsLoader, rightArrowBlue } from "../../assets";
import axios from "axios";
import { Link } from "react-router-dom";
import "./History.css";
import { LocationOrigin } from "../../utils/utilityServices";
import trackingService from "../../services/trackingService";

class QueryHistorySection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryHistoryShowContent: false,
      isProcessing: true,
      queryHistoryResult: [],
    };
  }

  async componentDidMount() {
    await this.fetchQueryHistory();
    let outer = document.querySelector("#qhHeaderSection");
    if (this.props.customWidth >= 600) {
      outer.addEventListener("click", (event) => {
        if (event.target.id === "qhHeaderSection") {
          this.setState({
            queryHistoryShowContent: !this.state.queryHistoryShowContent,
          });
        }
      });
      this.setState({ queryHistoryShowContent: true });
    } else {
      this.setState({ queryHistoryShowContent: false });
    }
  }
  async fetchQueryHistory() {
    this.setState({ isProcessing: true });
    let wwid = JSON.parse(localStorage.getItem("wwid"));
    var url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}analytics/qh?wwid=${wwid}`;
    if (this.props.customWidth > 1000) {
      url += `&size=10`
    }
    let config = {
      method: "GET",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then((response) => {
        this.setState({
          queryHistoryResult: response.data,
        });
      })
      .catch(function (error) {
        //console.log(error);
      })
      .finally(() => {
        this.setState({ isProcessing: false });
      });
  }
  snippetLongString = (snippet) => {
    if (snippet.length > 15) {
      let text = snippet.substring(0, 15)//cuts to 70
      text = text.substring(0, 15)//cuts from last space (to avoid cutting the middle of a word)
      text = text + `...`//adds (...) at the end to show that it's cut
      return text
    }
    return snippet;
  }
  renderQueryHistoryTitle = (text) => {
    if(text){
      text=text.replace(/Recently Added_Updated\//g,'Recently Added/Updated/')
    }
    if (text && text.includes('ǂ$±@')) {
      let splitTests = text.split('ǂ$±@');
      return `(Reading List: ${splitTests[0]}): ${splitTests[1]}`
    } else {
      return text;
    }
  }
  renderQueryHistoryText = (text) => {
    if(text){
      text=text.replace(/Recently Added_Updated\//g,'Recently Added/Updated/')
    }
    if (text && text.includes('ǂ$±@')) {
      let splitTests = text.split('ǂ$±@');
      return `${this.snippetLongString(splitTests[0])}: ${splitTests[1]}`
    } else {
      return text;
    }
  }
  RenderQueryHistoryList = () => {
    const { queryHistoryResult } = this.state;
    if (queryHistoryResult.length > 0) {
      return (
        <ul className="resultHistorysul">
          {queryHistoryResult.map((item, index) => {
            if (item.length > 1) {
              let url = item[1];
              var baseUrlPattern = /^https?:\/\/[a-z\:0-9.\-]+/;
              var result = "";
              var match = baseUrlPattern.exec(url);
              if (match != null) {
                result = match[0];
              }
              if (result.length > 0) {
                url = url.replace(result, "");
              }
              item[1] = url;
            }
            return (
              <li key={`QueryHistory-${index}`} className="resultHistorysli">
                {item.length > 1 ? (
                  <>
                    <Link
                      id={`PopupQueryHistory-${index}`}
                      title={this.renderQueryHistoryTitle(item[0])}
                      to={item[1]}
                      target="_blank"
                      onClick={() => {
                        this.handleClick(item[0], item[1]);
                      }}
                      style={{
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        width: "100%",
                        minHeight: "20px",
                      }}
                    >
                      {/* {item[0]} */}
                      {this.renderQueryHistoryText(item[0])}
                    </Link>
                  </>
                ) : (
                  <>
                    <Link
                      id={`PopupQueryHistory-${index}`}
                      title={item.keyword}
                      onClick={() => {
                        this.handleClick(item.keyword);
                      }}
                      to={`/results?query=${item.keyword}&q=All Fields&filters=`}
                      target="_blank"
                      style={{
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        width: "100%",
                        minHeight: "20px",
                      }}
                    >
                      {item.keyword}
                    </Link>
                  </>
                )}
              </li>
            );
          })}
        </ul>
      );
    } else {
      return (
        <ul className="resultHistorysul">
          <li className="resultHistorysli">No queries in history.</li>
        </ul>
      );
    }
  };
  handleClick = (itm, url) => {
    const ts = new trackingService();
    itm = itm.replace(/(")/g, '\\"');
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      localStorage.getItem("companyname"),
      // "Search History Component",
      "Conducted Search",
      "Search History Component",
      url,
      // "/results" + window.location.search,
      // this.state.searchKeyword,
      itm,
      this.props.appliedFilters,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      this.props.csPath !== null ? this.props.csPath : "",
      this.props.csproduct !== null ? this.props.csproduct : ""
    );
  };
  handleRVnQHMobile = () => {
    this.setState({
      queryHistoryShowContent: !this.state.queryHistoryShowContent,
    });
  };
  render() {
    if (this.props.customWidth < 600) {
      return (
        <div className="wnfy-outer-wrapper">
          <div className="wnfy-inner-wrapper">
            <div
              className="rv-qh-header-section-mobile"
              onClick={this.handleRVnQHMobile}
            /* onClick={(e) => {
            this.setState({
              queryHistoryShowContent: !this.state.queryHistoryShowContent,
            });
          }} */
            >
              <span>
                <div className="wnfy-main-header">
                  Search History &nbsp;&nbsp;
                </div>
              </span>
              <span className="wnfy-expand-wrapper">
                {this.state.queryHistoryShowContent ? (
                  <img
                    src={rightArrowBlue}
                    alt=""
                    width={10}
                    height={10}
                    style={{ transform: "rotate(-90deg)" }}
                  />
                ) : (
                  <img
                    src={rightArrowBlue}
                    alt=""
                    width={10}
                    height={10}
                    style={{ transform: "rotate(90deg)" }}
                  />
                )}
              </span>
            </div>
            {this.state.queryHistoryShowContent && (
              <div className="rv-qh-content-section">
                {!this.state.isProcessing ? (
                  this.RenderQueryHistoryList()
                ) : (
                  <span className="wnfy-content-loader">
                    <img src={imsLoader} width="25" height="25" alt="" />
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      );
    } else if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
      return (
        <>
          <div className="qh-outer-wrapper">
            <div className="rv-qh-inner-wrapper">
              <div
                id="qhHeaderSection"
                className="rv-qh-header-section" /* onClick={e => { this.setState({ queryHistoryShowContent: !this.state.queryHistoryShowContent }) }} */
              >
                <span className="wnfy-main-header">
                  Search History &nbsp;&nbsp;
                </span>
                <span className="rv-view-more-wrapper">
                  {!this.state.queryHistoryShowContent ? (
                    <img
                      src={rightSideArrow}
                      alt=""
                      width={11}
                      height={14}
                      style={{ transform: "rotate(90deg)" }}
                      onClick={(e) => {
                        this.setState({ queryHistoryShowContent: true });
                      }}
                    />
                  ) : (
                    <img
                      src={rightSideArrow}
                      alt=""
                      width={11}
                      height={14}
                      style={{ transform: "rotate(-90deg)" }}
                      onClick={(e) => {
                        this.setState({ queryHistoryShowContent: false });
                      }}
                    />
                  )}
                </span>
              </div>
              {this.state.queryHistoryShowContent && (
                <div className="rv-qh-content-section">
                  {!this.state.isProcessing ? (
                    this.RenderQueryHistoryList()
                  ) : (
                    <span className="wnfy-content-loader">
                      <img src={imsLoader} width="25" height="25" alt="" />
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      );
    } else if (this.props.customWidth > 1000) {
      return (
        <>
          <div className="qh-outer-wrapper">
            <div className="rv-qh-inner-wrapper">
              <div
                id="qhHeaderSection"
                className="rv-qh-header-section-desktop" /* onClick={e => { this.setState({ queryHistoryShowContent: !this.state.queryHistoryShowContent }) }} */
              >
                <span className="wnfy-main-header-desktop">
                  Search History &nbsp;&nbsp;
                </span>
                <span className="rv-view-more-wrapper">
                  {/* {!this.state.queryHistoryShowContent ? (
                    <img
                      src={rightSideArrow}
                      alt=""
                      width={11}
                      height={14}
                      style={{ transform: "rotate(90deg)" }}
                      onClick={(e) => {
                        this.setState({ queryHistoryShowContent: true });
                      }}
                    />
                  ) : (
                    <img
                      src={rightSideArrow}
                      alt=""
                      width={11}
                      height={14}
                      style={{ transform: "rotate(-90deg)" }}
                      onClick={(e) => {
                        this.setState({ queryHistoryShowContent: false });
                      }}
                    />
                  )} */}
                </span>
              </div>
              {this.state.queryHistoryShowContent && (
                <div className="rv-qh-content-section">
                  {!this.state.isProcessing ? (
                    this.RenderQueryHistoryList()
                  ) : (
                    <span className="wnfy-content-loader">
                      <img src={imsLoader} width="25" height="25" alt="" />
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      );
    }
  }
}
export default QueryHistorySection;
