/* eslint-disable array-callback-return */
import { Component } from "react";
import PubMedHeaderSearch from "../../components/header/PubMedHeaderSearch";
import Footer from "../../components/footer/Footer";
import DocumentCard from "../DocumentResults/DocumentCard";
import queryString from "query-string";
import SearchFilter from "../FilterComponent/SearchFilter";
import axios from "axios";
import FilterService from "../../services/FilterService";
import PageItems from "../../components/Paginate/PageItems";
import Pagination from "../../components/Paginate/Pagination";
import { processPubMedFilters } from "../FilterComponent/ProcessFilter";
import FilterDisplay from "../../components/FilterDisplay/FilterDisplay";
import Services from "../../utils/services";
import ApiServices from "../../utils/apiServices";
import {
  imsLoader,
  filterIconWhite,
  collapseFilterWhite,
  expandFilterWhite,
  clearSearchMobView,
  ActionsArrowIcon,
  pubMedIicon,
  pubMedIcon,
  pubMedNIH
} from "../../assets";
// import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import PubMedNavigationMenu from "../../components/Navigation/PubMedNavigationMenu";
import "./PubMedResults.css";
import ResponsiveFilterComponent from "../ResponsiveFilterComponent/ResponsiveFilterComponent";
import GlobalSearchFeaturePopUpModal from "../../components/PopUp/GlobalSearchFeaturesPopUpModal";
import NotifyMePopUp from "../../components/PopUp/NotifyMePopUp";
import BookmarkPopUp from "../../components/PopUp/BookmarkpopUp";
import ReadingListPopup from "../../components/PopUp/ReadingListPopup";
import { PubMedFilters, LocationOrigin } from "../../utils/utilityServices";
import trackingService from "../../services/trackingService";

var category;
class PubMedResults extends Component {
  userid;
  userExists;
  globalCollapseAll = false;
  documentsUrl = [];
  markedDocumentsUrl = [];
  constructor(props) {
    super(props);
    this.state = {
      response: [],
      username: "",
      userExists: false,
      collapsed: false,
      searchKeyword: "",
      dropdownValue: "",
      allFilters: {},
      documents: [],
      documentCount: 0,
      appliedFilters: [], //Filters that are applied for while search is happening
      selectedFilters: [],
      itemsPerPage: "25",
      currentPage: "1",
      presentDocRendered: "0",
      isLoaded: false,
      isProcessing: true,
      filters: {},
      f1: "",
      fval1: "",
      fieldedSearchArr: {},
      selectedParentFilterArr: [],
      showMajorTopicSearch: false,
      majorTopicSearchState: true,
      showFilters: true,
      width: window.innerWidth,
      height: window.innerHeight,
      citationSelectStatus: [],
      // selectedDocumentIndex: 0,
      isClearSearch: false,
      showResultType: false,
      resultDropdownValue: "PubMed Search Results",
      selectedResults: [],
      prevUrl: "",
      globalCollapseAll: false,
      collapseFilter: false,
      globalSelectAll: false,
      displayedResults: [],
      globalEmailPopUp: false,
      globalNotifyMePopUp: false,
      csPath: "",
      csproduct: "",
      readingListPopup: false,
      bookmarkPopUp: false,
      showSearch: false,
      sortByValue: "Relevance",
      sortBy: "",
      sortField: "",
      showInfo: false,
      appliedFiltersForOnlyiMS: [],
      filterYears: [],
      fromYear: "",
      toYear: "",
      collapsedSearchIDs: [],
      error: "",
      errorExpand: false,
    };
  }
  async componentDidMount() {
    try {
      document.title = "PubMed Search Results";
      /* if(this.state.width<1000){
            this.setState({
                showFilters : false
            })
        } */
      this.userid = JSON.parse(localStorage.getItem("username"));
      this.wwid = JSON.parse(localStorage.getItem('wwid'));
      this.userExists = localStorage.getItem("userExists");
      var itemsPerPage = localStorage.getItem("ItemsPerPage");
      var activePage = localStorage.getItem("ActivePage");
      let service = new Services();
      let apiService = new ApiServices();
      service.logService(
        `PubMedResults page successfully mounted for ${this.userid.toLowerCase()}`,
        "info"
      );
      if (this.userExists) {
        const currentDate = new Date();
        let currentYear = currentDate.getFullYear();
        this.setState({
          userExists: this.userExists,
          fromYear: currentYear - 2,
          toYear: currentYear,
        });
        /*To detect if user is coming from View Selected Page with some selected data*/
        if (sessionStorage.getItem("selectedResultsDetails") === null) {
          this.setState({
            globalSelectAll: false,
          });
        } else {
          if (
            JSON.parse(sessionStorage.getItem("selectedResultsDetails"))
              .length > 0
          ) {
            this.setState({
              globalSelectAll: true,
            });
          } else {
            this.setState({
              globalSelectAll: false,
            });
          }
        }
        if (itemsPerPage !== null) {
          this.setState({
            itemsPerPage: itemsPerPage,
          });
        }
        if (activePage !== null) {
          this.setState({
            currentPage: activePage,
          });
        }
        if (this.props.location.search === "") {
          this.props.history.replace(
            "/pubMedResults?query=&q=All%20Fields&filters="
          );
        }
        /*Get Approval Status of user and assign localStorage Values*/
        let approvedState = await apiService.getUserStatus(
          this.wwid
        );
        let apiRes = await apiService.getUserApprovedRegionRole(
          this.wwid
        );
        if (approvedState.status === "Accepted") {
          localStorage.setItem("region", approvedState.regions);
          localStorage.setItem(
            "function",
            JSON.stringify(approvedState.profession)
          );
        } else {
          if (apiRes) {
            localStorage.setItem("region", apiRes.region);
            localStorage.setItem("function", JSON.stringify(apiRes.function));
          } else {
            localStorage.setItem("region", approvedState.regions);
            localStorage.setItem("function", JSON.stringify("Commercial"));
          }
        }
        /*Get the localStorage values to getAllFilters POST Call*/
        if (localStorage.getItem("contentpreference") == null) {
          let res = await apiService.getContentPreference(
            this.userid.toLowerCase()
          );
          localStorage.setItem(
            "contentpreference",
            JSON.stringify(res.contentprefernce)
          );
        }
        let reqBody = {
          function: localStorage.getItem("function").replace(/"/g, ""),
          doctypepref: localStorage.getItem("doctypepref"),
          userproducts: localStorage.getItem("userproducts"),
          region: localStorage.getItem("region"),
          username: localStorage
            .getItem("username")
            .toLowerCase()
            .replace(/"/g, ""),
          contentpreference: localStorage
            .getItem("contentpreference")
            .replace(/"/g, ""),
        };
        let response = await service.getAllFilters(reqBody);
        if (response) {
          this.setState({ allFilters: {} });
          let processedFilters = processPubMedFilters(response.data.body);
          this.setState({
            allFilters: processedFilters,
            isProcessing: false,
          });
        }
        const queryParams = new URLSearchParams(this.props.location.search);
        const params = queryParams.get("query");
        const filters = queryParams.get("filters");
        const f1 = queryParams.get("f1");
        let csPath = queryParams.get("cspath");
        let csproduct = queryParams.get("csproduct");

        if (params !== "" && f1 === "majorTopicsSynonyms") {
          this.setState({
            showMajorTopicSearch: true,
          });
        } else if (
          (params !== "" && f1 === undefined) ||
          f1 === null ||
          f1 === ""
        ) {
          this.setState({
            showMajorTopicSearch: true,
          });
        } else if (params !== "" && f1 !== "majorTopicsSynonyms") {
          this.setState({
            showMajorTopicSearch: false,
          });
        }
        if (f1 === "majorTopicsSynonyms") {
          this.setState({
            majorTopicSearchState: false,
            showMajorTopicSearch: true,
          });
        }
        if (this.props.location.state) {
          this.setState(
            {
              appliedFilters: this.props.location.state.appliedFilters,
              /* f1:f1,
                    fval1:fval1 */
            },
            () => {
              this.setState({
                selectedParentFilterArr: this.generateSelectParentArray(
                  this.state.appliedFilters
                ),
              });
              if (params !== "" || params !== undefined || params !== null) {
                this.runSearch(params, this.state.appliedFilters);
              }
            }
          );
        } else if (filters) {
          let bifurcateFilters = this.bifurcateAppliedFilters(
            filters.split(";")
          );
          this.setState(
            {
              //appliedFilters: filters.split(';')
              appliedFilters: bifurcateFilters.appliedFiltersForBoth,
              appliedFiltersForOnlyiMS:
                bifurcateFilters.appliedFiltersForOnlyiMS,
              /* f1:f1,
                    fval1:fval1 */
            },
            () => {
              this.setState({
                selectedParentFilterArr: this.generateSelectParentArray(
                  this.state.appliedFilters
                ),
              });
              if (params !== "" || params !== undefined || params !== null) {
                this.runSearch(params, this.state.appliedFilters);
              }
            }
          );
        } else {
          this.setState(
            {
              appliedFilters: [],
              /* f1:f1,
                    fval1:fval1 */
            },
            () => {
              this.setState({
                selectedParentFilterArr: this.generateSelectParentArray(
                  this.state.appliedFilters
                ),
              });
              if (params !== "" || params !== undefined || params !== null) {
                this.runSearch(params, this.state.appliedFilters);
              }
            }
          );
        }
        /*Check for selectedFilter*/
        if (this.props.location.state) {
          this.setState({
            selectedFilters: this.props.location.state.selectedFilters,
          });
        } else if (filters) {
          this.setState({
            selectedFilters: filters.split(";"),
          });
        } else {
          this.setState({
            selectedFilters: [],
          });
        }
        this.setState({
          searchKeyword: params === null ? "" : params.query,
          dropdownValue: params === null ? "All Fields" : params.q,
          csPath: csPath,
          csproduct: csproduct,
        });
        window.addEventListener("resize", this.updateDimensions);
      } else {
        console.log("Access Denied");
        this.props.history.push("/");
      }

      this.setState({ prevUrl: window.location.search });

      // VIEW SELECTED LOGIC
      let data = this.state.selectedResults;
      let citationSelectStatus = this.state.citationSelectStatus;
      let markedUrl = this.markedDocumentsUrl;
      var sessiondata = sessionStorage.getItem("selectedResultsDetails");
      // for (let index = 0; index < this.state.documentCount; index++) {
      if (localStorage.getItem("selectedResults") === null) {
        // console.log(this.props.location.search,this.state.prevUrl,window.location.search)
        if (window.location.search !== this.state.prevUrl) {
          data = [];
          citationSelectStatus = [];
          markedUrl = [];
        }
        /* else {
                citationSelectStatus.push(false)
            } */
      }
      if (
        localStorage.getItem("selectedResults") !== null &&
        sessiondata !== null
      ) {
        // let totalPages = Math.ceil(this.state.documentCount / this.state.itemsPerPage)
        // console.log(this.state.itemsPerPage, totalPages,localStorage.getItem('selectedResults'), Math.max.apply(null,localStorage.getItem('selectedResults').split(',')))
        // else{
        // if (window.location.search != this.state.prevUrl) {
        if (this.props.location.search !== this.state.prevUrl) {
          data = [];
          citationSelectStatus = [];
          localStorage.removeItem("selectedResults");
          localStorage.removeItem("ItemsPerPage");
          localStorage.removeItem("ActivePage");
          localStorage.removeItem("markedResultsUrl");
          sessionStorage.clear();
        } else {
          localStorage
            .getItem("selectedResults")
            .split(",")
            .map((x, key) => {
              let i = parseInt(x);
              citationSelectStatus[i - 1] = true;
              if (citationSelectStatus[i - 1]) {
                data = JSON.parse(sessiondata);
                markedUrl = JSON.parse(
                  localStorage.getItem("markedResultsUrl")
                );
                // data.push(JSON.parse(sessiondata))
                // data.push({
                //     detail: this.state.response[i - 1]._source,
                //     itemTeaser: this.state.response[i - 1].highlight.body,
                //     index: i
                // })
              } else {
                data.splice(i - 1, 1);
                markedUrl.splice(i - 1, 1);
              }
            });
        }
      }
      // }
      // console.log(data,citationSelectStatus)
      this.markedDocumentsUrl = markedUrl;
      this.setState({
        citationSelectStatus: citationSelectStatus,
        selectedResults: data,
      });
    } catch (error) {
      this.setState({ error: error });
    }
  }
  componentWillUnmount() {
    try {
      let service = new Services();
      window.removeEventListener("resize", this.updateDimensions);
      service.logService(
        `PubMedResults page successfully unmounted for ${this.userid.toLowerCase()}`,
        "info"
      );
    } catch (error) {
      this.setState({ error: error });
    }
  }
  updateDimensions = () => {
    try {
      this.setState(
        { width: window.innerWidth, height: window.innerHeight },
        () => {
          /* if(this.state.width < 1200){
                  this.setState({
                      showFilters : false
                  })
              }
              else{
                  this.setState({
                      showFilters : true
                  }) 
              } */
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };

  handleDocCount = (presentCount) => {
    try {
      this.setState({
        presentDocRendered: presentCount,
      });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleNextClick = () => {
    try {
      if (
        this.state.currentPage <
        Math.ceil(this.state.documentCount / this.state.itemsPerPage)
      ) {
        // if (this.state.currentPage <= 1) {
        this.setState(
          {
            currentPage: parseInt(this.state.currentPage) + 1,
          },
          () => {
            this.handlePageChange(this.state.currentPage);
          }
        );
      }
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handlePreviousClick = () => {
    try {
      if (this.state.currentPage >= 2) {
        this.setState(
          {
            currentPage: this.state.currentPage - 1,
          },
          () => {
            this.handlePageChange(this.state.currentPage);
          }
        );
      }
    } catch (error) {
      this.setState({ error: error });
    }
  };
  navigateToLastPage = () => {
    try {
      this.setState(
        {
          currentPage: Math.ceil(
            this.state.documentCount / this.state.itemsPerPage
          ),
        },
        () => {
          this.handlePageChange(this.state.currentPage);
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  navigateToFirstPage = () => {
    try {
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.handlePageChange(this.state.currentPage);
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleChangeSelectSortBy = (e) => {
    try {
      if (
        e.target.value === "Year (oldest to newest)" ||
        e.target.value === "Year Asc"
      ) {
        this.setState({ sortBy: "asc", sortField: "mpYear" });
      } else if (
        e.target.value === "Year (newest to oldest)" ||
        e.target.value === "Year Desc"
      ) {
        this.setState({ sortBy: "desc", sortField: "mpYear" });
      } else {
        this.setState({ sortBy: "", sortField: "" });
      }
      this.setState(
        {
          sortByValue: e.target.value,
          citationSelectStatus: [],
          selectedResults: [],
          globalSelectAll: false,
          globalCollapseAll: false,
          collapsedSearchIDs: [],
        },
        () => {
          this.runSearchFromQueryParams(
            this.state.allFilters,
            this.state.appliedFilters,
            this.state.searchKeyword
          );
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  resultListCount = (itemsPerPage, currentPage) => {
    try {
      var endIndex, startIndex;
      startIndex = currentPage * itemsPerPage - itemsPerPage;
      endIndex = currentPage * itemsPerPage;
      // var count = startIndex;
      // const totalPages = Math.ceil(this.state.documentCount / itemsPerPage);
      // const previousOff = currentPage == 1;
      // const nextOff = currentPage == totalPages;
      if (this.state.width < 600) {
        return (
          <div
            className="results-count-wrapper"
            style={{
              justifyContent:
                this.state.response.length > 0 ? "space-between" : "flex-end",
            }}
          >
            {
              this.state.response.length > 0 && (
                <Pagination
                  metadata={this.state.documentCount}
                  handlePageChange={this.handlePageChange}
                  itemsPerPage={this.state.itemsPerPage}
                  page={this.state.currentPage}
                  customWidth={this.state.width}
                  customHeight={this.state.height}
                />
              )
              // <div className="resultListCount-container">
              //     {this.state.documentCount > 0 &&
              //         <span className="paginationContainer-mobile">
              //             <span className="startEndIndexText-mobile" onClick={this.navigateToFirstPage}>
              //                 {previousOff ? null : 1}
              //             </span>
              //             {
              //                 previousOff ? <img src={prdtNavButton} alt="leftArrowIcon" className="paginationArrowIcon-mobile" style={{ transform: "matrix(-1, 0, 0, -1, 0, 0)" }} /> : <img src={leftArrowMobileIcon} alt="leftArrowIcon" className="paginationArrowIcon-mobile" onClick={this.handlePreviousClick} style={{ cursor: "pointer" }} />
              //             }
              //             <span className="currentResultText-mobile">
              //                 {currentPage}
              //             </span>
              //             {
              //                 nextOff ? <img src={prdtNavButton} alt="rightArrowIcon" className="paginationArrowIcon-mobile" /> : <img src={rightArrowMobileIcon} alt="rightArrowIcon" className="paginationArrowIcon-mobile" onClick={this.handleNextClick} style={{ cursor: "pointer" }} />
              //             }
              //             <span className="startEndIndexText-mobile" onClick={this.navigateToLastPage}>
              //                 {nextOff ? null : totalPages}
              //             </span>
              //         </span>
              //     }
              // </div>
            }
            <div
              className="clearSearchContainer"
              onClick={this.handleClearSearch}
            >
              <img
                src={clearSearchMobView}
                alt="clearSearch"
                className="clearSearchIcon" /* onClick={this.handleClearSearch} */
              />
              {/* <span className="clearSearchText">Clear Search</span> */}
            </div>
          </div>
        );
      } else {
        return (
          <div className="results-count-wrapper">
            {this.state.width > 1000 && (
              <div class="please-note-content">
                <div class="pn-text updated-red-text">
                  {" "}
                  {new Date().getFullYear() - this.state.fromYear === 2
                    ? `PLEASE NOTE: Displaying the most recent 3 years of PubMed Results.`
                    : `PLEASE NOTE: Displaying the PubMed Results from ${this.state.fromYear} to ${this.state.toYear}.`}
                </div>
              </div>
            )}
            {/* {
                        this.state.width < 1000 &&
                        <div className="clearSearchContainer" onClick={this.handleClearSearch}>
                            <img src={clearSearchMobView} alt="clearSearch" className="clearSearchIcon" />
                            <span className="clearSearchText">Clear Search</span>
                        </div>
                    } */}
            {this.state.width < 1000 && (
              <div class="please-note-content">
                <div class="pn-text updated-red-text">
                  {new Date().getFullYear() - this.state.fromYear === 2
                    ? `PLEASE NOTE: Displaying the most recent 3 years of PubMed Results.`
                    : `PLEASE NOTE: Displaying the PubMed Results from ${this.state.fromYear} to ${this.state.toYear}.`}
                </div>

                <div className="global-feature-pubmed">
                  <div className="global-feature-pubmedicon-wrapper">
                    <img
                      src={pubMedIcon}
                      alt=""
                      className="global-feature-pubmedicon"
                    />
                  </div>
                  <div className="global-feature-pubmednih-wrapper">
                    <img src={pubMedNIH} alt="" />
                  </div>
                </div>
              </div>
            )}
            {this.state.response.length > 0 && (
              <div className="resultListCount-container">
                {this.state.documentCount > 0 && (
                  <span
                    className="showingResultsCountContainer" /* style={{ display: "flex", width: "200px" }} */
                  >
                    {endIndex <= this.state.documentCount ? (
                      <div className="results-number-show-wrapper">
                        Showing {(startIndex + 1).toLocaleString("en-us")}-
                        {endIndex.toLocaleString("en-us")} of{" "}
                        {this.state.documentCount.toLocaleString("en-us")}{" "}
                        Results
                      </div>
                    ) : (
                      <div className="results-number-show-wrapper">
                        Showing {(startIndex + 1).toLocaleString("en-us")}-
                        {this.state.documentCount.toLocaleString("en-us")} of{" "}
                        {this.state.documentCount.toLocaleString("en-us")}{" "}
                        Results
                      </div>
                    )}
                  </span>
                )}
                <span
                  style={{
                    marginRight: "0.5%",
                    display: "flex",
                    width: "180px",
                  }}
                >
                  <PageItems
                    handleChangeSelectItems={this.handleChangeSelectItems}
                    itemsPerPage={this.state.itemsPerPage}
                    customWidth={this.state.width}
                  />
                </span>
                {this.state.width >= 1000 ? (
                  <div className="sort-by-wrapper">
                    <span className="sort-by-text">Sort By:</span>
                    <select
                      className="sort-by-select-wrapper"
                      onChange={this.handleChangeSelectSortBy}
                      defaultValue={this.state.sortByValue}
                      value={this.state.sortByValue}
                    >
                      <option value="Relevance">Relevance</option>
                      <option>Year (oldest to newest)</option>
                      <option>Year (newest to oldest)</option>
                    </select>
                  </div>
                ) : (
                  this.state.width >= 600 && (
                    <div className="sort-by-wrapper">
                      <span className="sort-by-text">Sort By:</span>
                      <select
                        className="sort-by-select-wrapper"
                        onChange={this.handleChangeSelectSortBy}
                        defaultValue={this.state.sortByValue}
                        value={this.state.sortByValue}
                      >
                        <option value="Relevance">Relevance</option>
                        <option>Year Asc</option>
                        <option>Year Desc</option>
                      </select>
                    </div>
                  )
                )}
              </div>
            )}
            {this.state.width < 1000 && (
              <div
                className="clearSearchContainer"
                onClick={this.handleClearSearch}
              >
                <img
                  src={clearSearchMobView}
                  alt="clearSearch"
                  className="clearSearchIcon"
                />
                {this.state.width >= 805 && (
                  <span className="clearSearchText">Clear Search</span>
                )}
              </div>
            )}
          </div>
        );
      }
    } catch (error) {
      this.setState({ error: error });
    }
  };
  getSelectedCitationDetails = () => {
    try {
      //console.log(this.state.citationSelectStatus)
      //console.log(this.state.selectedResults.sort(function (a, b) { return a.index - b.index }))
      if (this.state.selectedResults.length > 0) {
        let selectedResultsDetails = this.state.selectedResults;
        sessionStorage.setItem(
          "selectedResultsDetails",
          JSON.stringify(selectedResultsDetails)
        );
        let selectedResultsIndex = this.state.selectedResults.map(
          (x) => x.index
        );
        localStorage.setItem("selectedResults", selectedResultsIndex);
        localStorage.setItem(
          "markedResultsUrl",
          JSON.stringify(this.markedDocumentsUrl)
        );
        localStorage.setItem("ItemsPerPage", this.state.itemsPerPage);
        localStorage.setItem("ActivePage", this.state.currentPage);
        this.props.history.push({
          pathname: "/viewmarked",
          state: { selectedResults: this.state.selectedResults },
        });
      } else {
        alert("There are no documents selected");
      }
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleCitationSelection = (index, item, itemTeaser, key) => {
    try {
      let a = this.state.citationSelectStatus;
      a[index - 1] = !this.state.citationSelectStatus[index - 1];
      let data = this.state.selectedResults;
      let urls = this.markedDocumentsUrl;
      if (this.state.citationSelectStatus[index - 1]) {
        data.push({
          detail: item,
          itemTeaser: itemTeaser,
          index: index,
        });
        urls.push(this.documentsUrl[key]);
      } else {
        const i = data.map((x) => x.index).indexOf(index);
        if (i > -1) {
          data.splice(i, 1); // 2nd parameter means remove one item only
          urls.splice(i, 1);
        }
      }
      this.markedDocumentsUrl = urls;
      this.setState({
        citationSelectStatus: a,
        selectedResults: data,
      });
      let checkSelectStatus = a.filter((item) => item);
      if (checkSelectStatus.length === parseInt(this.state.itemsPerPage)) {
        this.setState({ globalSelectAll: true });
      } else if (checkSelectStatus.length === this.state.response.length) {
        this.setState({ globalSelectAll: true });
      } else {
        this.setState({ globalSelectAll: false });
      }
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleCitationSelectionGlobal = (index, item, itemTeaser, key) => {
    try {
      let a = this.state.citationSelectStatus;
      //console.log(a, index)
      a[index - 1] = true;
      let data = this.state.selectedResults;
      let urls = this.markedDocumentsUrl;
      if (this.state.citationSelectStatus[index - 1]) {
        //console.log("selectAll-1")
        data.push({
          detail: item,
          itemTeaser: itemTeaser,
          index: index,
        });
        urls.push(this.documentsUrl[key - 1]);
      } else {
        //console.log("selectAll-2")
        const i = data.map((x) => x.index).indexOf(index);
        if (i > -1) {
          data.splice(i, 1); // 2nd parameter means remove one item only
          urls.splice(i, 1);
        }
      }
      // console.log(a,data);
      this.markedDocumentsUrl = urls;
      this.setState({
        citationSelectStatus: a,
        selectedResults: data,
      });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleCitationUnSelection = (index, item, itemTeaser) => {
    try {
      let a = this.state.citationSelectStatus;
      a[index - 1] = false;
      let data = this.state.selectedResults;
      let urls = this.markedDocumentsUrl;
      /* if (this.state.citationSelectStatus[index - 1]) {
            data.push({
                detail: item,
                itemTeaser: itemTeaser,
                index: index,
            })
        }
        else { */
      const i = data.map((x) => x.index).indexOf(index);
      if (i > -1) {
        data.splice(i, 1); // 2nd parameter means remove one item only
        urls.splice(i, 1);
      }
      /* } */
      // console.log(a,data)
      // let selectedResultsDetails = data;
      // sessionStorage.setItem("selectedResultsDetails", JSON.stringify(selectedResultsDetails));
      // let selectedResultsIndex = data.map(x => x.index)
      // localStorage.setItem('selectedResults', selectedResultsIndex)
      this.markedDocumentsUrl = urls;
      this.setState({
        citationSelectStatus: a,
        selectedResults: data,
      });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  citationUrl = (url, index, item, displayedResultsUrl) => {
    try {
      if (item.domain === "CTGov") {
        displayedResultsUrl[index] = url;
      } else {
        displayedResultsUrl[index] = window.location.origin + url;
        // displayedResultsUrl[index] = "https://predev-ims.jnj.com" + url;
      }
      this.documentsUrl = displayedResultsUrl;
    } catch (error) {
      this.setState({ error: error });
    }
  };
  manageCollapseAndExpand = (iscollapsed, PMID) => {
    try {
      let { collapsedSearchIDs, response } = this.state;
      let responseSearchIDs = response.map((x) => x._source.PMID);

      if (iscollapsed) {
        collapsedSearchIDs.push(PMID);
      } else {
        collapsedSearchIDs = collapsedSearchIDs.filter((x) => x !== PMID);
      }
      let tempGlobalCollapseAll = responseSearchIDs.every((x) =>
        collapsedSearchIDs.includes(x)
      );
      this.setState({
        collapsedSearchIDs: collapsedSearchIDs,
        globalCollapseAll: tempGlobalCollapseAll,
      });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  resultList = (results, itemsPerPage, currentPage) => {
    try {
      let { collapsedSearchIDs } = this.state;
      //window.scrollTo({ behavior: 'smooth', top: '0px' });
      var startIndex;
      startIndex = currentPage * itemsPerPage - itemsPerPage;
      var count = startIndex;
      let displayedResults = [];
      let displayedResultsUrl = [];
      if (results.length > 0) {
        return (
          <>
            {results.map((item, index) => {
              count++;
              // console.log(item.highlight)
              if (
                item.highlight === undefined ||
                item.highlight?.body[0] === "Empty File" ||
                item.highlight?.body[0] === "Not an empty file"
              ) {
                displayedResults.push({
                  detail: item._source,
                  itemTeaser: [],
                  index: index + 1,
                });
                // item.highlight = { 'body': ['Test body until indexed value is available'] }
              } else {
                displayedResults.push({
                  detail: item._source,
                  itemTeaser: item.highlight.body,
                  index: index + 1,
                });
              }
              let responseSearchIDs = item._source.PMID;
              let globalCollapseAll = collapsedSearchIDs.some(
                (x) => x === responseSearchIDs
              );
              return (
                <>
                  <div key={`result-id-${index}`} id={`result-id-${index}`}>
                    {
                      <div className="resultDetailsDiv">
                        <DocumentCard
                          item={item._source}
                          count={count}
                          appliedFilters={this.state.appliedFilters}
                          searchKeyword={this.state.searchKeyword}
                          pageNumber={this.state.currentPage}
                          pageSize={this.state.itemsPerPage}
                          itemTeaser={
                            item.highlight === undefined ||
                            item.highlight?.body[0] === "Empty File" ||
                            item.highlight?.body[0] === "Not an empty file"
                              ? []
                              : item.highlight.body
                          }
                          handleCitationSelection={(i, item, itemTeaser) =>
                            this.handleCitationSelection(
                              i,
                              item,
                              itemTeaser,
                              index
                            )
                          }
                          isChecked={this.state.citationSelectStatus[count - 1]}
                          customWidth={this.state.width}
                          //globalCollapseAll={this.state.globalCollapseAll}
                          globalCollapseAll={globalCollapseAll}
                          csPath={this.state.csPath}
                          csproduct={this.state.csproduct}
                          citationUrl={(url) =>
                            this.citationUrl(
                              url,
                              index,
                              item._source,
                              displayedResultsUrl
                            )
                          }
                          manageCollapseAndExpand={this.manageCollapseAndExpand}
                        />
                        <div className="resultsDivFooter"></div>
                      </div>
                    }
                  </div>
                </>
              );
            })}
            {localStorage.setItem(
              "displayedResults",
              JSON.stringify(displayedResults)
            )}
          </>
        );
      } else {
        localStorage.removeItem("displayedResults");
        return (
          <div className="results-no-search-content">
            <span>Nothing here matches your search</span>
            <br />
            <br />
            <span>Suggestions</span>
            <li>
              <b>Adjust the 'Results by Year' slider</b> (if applicable)
            </li>
            <li>Make sure all words are spelled correctly</li>
            <li>Try different search terms</li>
            <li>Try more general search terms</li>
            <li>Try fewer search terms</li>
          </div>
        );
      }
    } catch (error) {
      this.setState({ error: error });
    }
    // this.setState({displayedResults:displayedResults})
  };
  handleAllFilters = (
    filters,
    appliedfilters,
    selectedFilters,
    response,
    docCount,
    query
  ) => {
    try {
      this.setState({
        filters: filters,
        appliedFilters: appliedfilters,
        selectedFilters: selectedFilters,
        response: response,
        documentCount: docCount,
        searchKeyword: query,
      });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleChangeInSearch = (query) => {
    try {
      this.setState(
        {
          searchKeyword: query,
          currentPage: "1",
          globalSelectAll: false,
          globalCollapseAll: false,
          selectedResults: [],
          citationSelectStatus: [],
          collapsedSearchIDs: [],
        },
        () => {
          if (this.state.error !== "") {
            this.setState({ error: "" });
          }
          this.runSearchFromQueryParams(
            this.state.allFilters,
            this.state.appliedFilters,
            query
          );
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handlePageChange = (currentPage) => {
    try {
      window.scrollTo({ behavior: "smooth", top: "0px" });
      let checkSelectStatus = this.state.citationSelectStatus.filter(
        (item) => item
      );
      if (
        this.state.globalSelectAll &&
        (checkSelectStatus.length.toString() === this.state.itemsPerPage ||
          this.state.citationSelectStatus.length === this.state.documentCount)
      ) {
        this.setState({ citationSelectStatus: [], selectedResults: [] });
      }
      this.setState(
        {
          currentPage: currentPage,
          response: [],
          isProcessing: true,
          isLoaded: false,
          globalCollapseAll: false,
          globalSelectAll: false,
          collapsedSearchIDs: [],
        },
        () => {
          let setSelectStatus = false;
          let startIndex =
            this.state.currentPage * this.state.itemsPerPage -
            this.state.itemsPerPage;
          let endIndex = this.state.currentPage * this.state.itemsPerPage;
          let citationStatusForCurrentPage =
            this.state.citationSelectStatus.slice(startIndex, endIndex);
          //console.log(startIndex,endIndex,citationStatusForCurrentPage)
          setSelectStatus = citationStatusForCurrentPage.some(
            (e) => e === true
          );
          //console.log(setSelectStatus)
          if (setSelectStatus) {
            this.setState({
              globalSelectAll: true,
            });
          } else {
            this.setState({
              globalSelectAll: false,
            });
          }
          this.runSearchFromQueryParams(
            this.state.allFilters,
            this.state.appliedFilters,
            this.state.searchKeyword
          );
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  gotoPage = (currentPage) => {
    try {
      this.setState(
        {
          currentPage: currentPage,
          response: [],
          isProcessing: true,
          isLoaded: true,
          globalCollapseAll: false,
          collapsedSearchIDs: [],
        },
        () => {
          let setSelectStatus = false;
          let startIndex =
            this.state.currentPage * this.state.itemsPerPage -
            this.state.itemsPerPage;
          let endIndex = this.state.currentPage * this.state.itemsPerPage;
          let citationStatusForCurrentPage =
            this.state.citationSelectStatus.slice(startIndex, endIndex);
          //console.log(startIndex,endIndex,citationStatusForCurrentPage)
          setSelectStatus = citationStatusForCurrentPage.some(
            (e) => e === true
          );
          //console.log(setSelectStatus)
          if (setSelectStatus) {
            this.setState({
              globalSelectAll: true,
            });
          } else {
            this.setState({
              globalSelectAll: false,
            });
          }
          this.runSearchFromQueryParams(
            this.state.allFilters,
            this.state.appliedFilters,
            this.state.searchKeyword
          );
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  generateSelectParentArray = (appliedfilters) => {
    try {
      let parentFacet = [];
      appliedfilters.forEach((filter) => {
        const filterParts = filter.split("/");
        const filterTop = filterParts[0];
        parentFacet.push(filterTop);
      });
      return parentFacet;
    } catch (error) {
      this.setState({ error: error });
    }
  };

  bifurcateAppliedFilters(appliedFilters) {
    try {
      let appliedFiltersForOnlyiMS = [];
      let appliedFiltersForBoth = [];
      appliedFilters.map((filter) => {
        const filterParts = filter.split("/");
        const filterTop = filterParts[0];
        if (
          Object.keys(PubMedFilters).findIndex(
            (x) => x.toLowerCase() === filterTop.toLowerCase()
          ) > -1
        ) {
          appliedFiltersForBoth.push(filter);
        } else {
          appliedFiltersForOnlyiMS.push(filter);
        }
      });
      return {
        appliedFiltersForOnlyiMS: appliedFiltersForOnlyiMS,
        appliedFiltersForBoth: appliedFiltersForBoth,
      };
    } catch (error) {
      this.setState({ error: error });
    }
  }

  handleOnChangeInfilter = (
    filters,
    appliedfilters,
    selectedFilters,
    response,
    doc,
    query
  ) => {
    try {
      let params = queryString.parse(this.props.location.search);
      //var filters = appliedfilters;
      filters = appliedfilters;
      let selectedFacets = this.generateSelectParentArray(appliedfilters);
      const newUrl = this.buildUrl(
        params.query,
        params.q,
        params.f1,
        filters,
        false
      );
      this.props.history.push({
        pathname: "/pubMedResults",
        search: newUrl,
        state: {
          selectedFilters: selectedFilters,
          allFilters: this.state.allFilters,
          appliedFilters: appliedfilters,
        },
      });
      this.setState(
        {
          currentPage: "1",
          selectedParentFilterArr: selectedFacets,
          collapseFilter: true,
          selectedFilters: selectedFilters,
          globalSelectAll: false,
          selectedResults: [],
          citationSelectStatus: [],
        },
        () => {
          this.runSearchFromQueryParams(
            this.state.allFilters,
            appliedfilters,
            this.state.searchKeyword
          );
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleChangeSelectItems = (items) => {
    try {
      //console.log(this.state.citationSelectStatus);
      let checkSelectStatus = this.state.citationSelectStatus.filter(
        (item) => item
      );
      if (
        this.state.globalSelectAll &&
        (checkSelectStatus.length.toString() === this.state.itemsPerPage ||
          this.state.citationSelectStatus.length === this.state.documentCount)
      ) {
        this.setState({ citationSelectStatus: [], selectedResults: [] });
      }
      this.setState(
        {
          itemsPerPage: items,
          globalCollapseAll: false,
          globalSelectAll: false,
          collapsedSearchIDs: [], 
          selectedResults: [],
          citationSelectStatus: [],
        },
        () => {
          let totalPages = Math.ceil(
            this.state.documentCount / this.state.itemsPerPage
          );
          if (totalPages < this.state.currentPage) {
            this.setState(
              {
                currentPage: totalPages,
              },
              () => {
                let setSelectStatus = false;
                let startIndex =
                  this.state.currentPage * this.state.itemsPerPage -
                  this.state.itemsPerPage;
                let endIndex = this.state.currentPage * this.state.itemsPerPage;
                let citationStatusForCurrentPage =
                  this.state.citationSelectStatus.slice(startIndex, endIndex);
                //console.log(startIndex,endIndex,citationStatusForCurrentPage)
                setSelectStatus = citationStatusForCurrentPage.some(
                  (e) => e === true
                );
                //console.log(setSelectStatus)
                if (setSelectStatus) {
                  this.setState({
                    globalSelectAll: true,
                  });
                } else {
                  this.setState({
                    globalSelectAll: false,
                  });
                }
                this.runSearchFromQueryParams(
                  this.state.allFilters,
                  this.state.appliedFilters,
                  this.state.searchKeyword
                );
              }
            );
          } else {
            this.runSearchFromQueryParams(
              this.state.allFilters,
              this.state.appliedFilters,
              this.state.searchKeyword
            );
          }
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleYearSelection = (newYears) => {
    try {
      this.setState(
        {
          fromYear: newYears.min,
          toYear: newYears.max,
        },
        () =>
          this.setState({
            globalSelectAll: false,
            selectedResults: [],
            citationSelectStatus: [],
          })
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  buildFieldedUrl = (searchKeyword, filters) => {
    try {
      let filterStr = this.generateArrayString(filters);
      let queryParams = new URLSearchParams(this.props.location.search);
      let params = queryParams.get("query");
      let q = queryParams.get("q");
      let fcount = queryParams.get("fcount");
      let andFields = queryParams.get("andFields");
      let orFields = queryParams.get("orFields");
      let docTitle = queryParams.get("docTitle");
      let authors = queryParams.get("authors");
      let majorTopicsSynonyms = queryParams.get("majorTopicsSynonyms");
      let fromYear = queryParams.get("fromYear");
      let toYear = queryParams.get("toYear");
      let DS = queryParams.get("DS");
      params = encodeURIComponent(params);
      let url = `?query=${params}&q=${q}&filters=${filterStr}`;
      if (fcount > 0) {
        for (let i = 1; i <= fcount; i++) {
          let key = queryParams.get(`f${i}`);
          let value = queryParams.get(`fval${i}`);
          url += `&f${i}=${key}&fval${i}=${encodeURIComponent(value)}`;
        }
        url += `&fcount=${fcount}`;
        if (DS) {
          let DSArr0 = DS.split("ǂ")[0]; //DSArr0 store Dropdown select value
          let DSArr1 = DS.split("ǂ")[1]; //DSArr1 store Search value
          DSArr1 = encodeURIComponent(DSArr1);
          DS = DSArr0.concat("ǂ", DSArr1);
          url += `&DS=${DS}`;
        }
      }
      if (andFields !== "" && andFields !== null) {
        url += `&andFields=${andFields}`;
      }
      if (orFields !== "" && orFields !== null) {
        url += `&orFields=${orFields}`;
      }
      if (docTitle !== "" && docTitle !== null) {
        url += `&docTitle=${docTitle}`;
      }
      if (authors !== "" && authors !== null) {
        url += `&authors=${authors}`;
      }
      if (majorTopicsSynonyms !== "" && majorTopicsSynonyms !== null) {
        url += `&majorTopicsSynonyms=${majorTopicsSynonyms}`;
      }
      if (fromYear !== "" && fromYear !== null) {
        url += `&fromYear=${fromYear}`;
      }
      if (toYear !== "" && toYear !== null) {
        url += `&toYear=${toYear}`;
      }
      url += `&FS=true`;
      return url;
    } catch (error) {
      this.setState({ error: error });
    }
  };
  buildUrl = (
    searchKeyword,
    dropdownValue,
    fieldedVal,
    filters,
    isClearSearch,
    addDateRange = true
  ) => {
    try {
      searchKeyword = encodeURIComponent(searchKeyword);
      let filterStr = this.generateArrayString(filters);
      let params = new URLSearchParams(this.props.location.search);
      let sbQuery = params.get("sbQuery");
      let isDateRange = params.get("isDateRange");
      let fieldedSearch = params.get("FS");
      let csPath = params.get("cspath");
      let csproduct = params.get("csproduct");
      let fcount = params.get("fcount");
      let queryType = params.get("queryType");
      let isClickOnSuggestion = params.get("isClickOnSuggestion");
      let url;
      if (fieldedSearch) {
        url = this.buildFieldedUrl(searchKeyword, filters);
      } else {
        if (fieldedVal === "") {
          url = `?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}`;
        } else if (
          (dropdownValue === "All Fields" && fieldedVal === "") ||
          fieldedVal === undefined
        ) {
          url = `?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}`;
        } else {
          //url = `?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}&f1=${fieldedVal}&fval1=${searchKeyword}&fcount=1`;
          url = `?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}`;
          if (fcount) {
            for (let i = 1; i <= fcount; i++) {
              let key = params.get(`f${i}`);
              let value = params.get(`fval${i}`);
              url += `&f${i}=${key}&fval${i}=${encodeURIComponent(value)}`;
            }
            url += `&fcount=${fcount}`;
          }
        }
        if (sbQuery !== null && searchKeyword !== "") {
          url = url + `&sbQuery=${sbQuery}`;
        }
        if (csPath !== null && csproduct !== null && searchKeyword !== "") {
          url = url + `&cspath=${csPath}&csproduct=${csproduct}`;
        }
        if (isDateRange !== null && addDateRange) {
          let rangeField = params.get("rangeField");
          let toRange = params.get("toRange");
          let fromRange = params.get("fromRange");
          if (isDateRange && rangeField && (toRange || fromRange)) {
            url = url + `&isDateRange=true&rangeField=${rangeField}`;
          }
          if (toRange !== null) {
            url += `&toRange=${toRange}`;
          }
          if (fromRange !== null) {
            url += `&fromRange=${fromRange}`;
          }
        }
      }
      if (queryType) {
        url += `&queryType=alerts`;
      }
      if (isClickOnSuggestion) {
        url += `&isClickOnSuggestion=true`;
      }
      if (isClearSearch === true) {
        url = `?query=&q=All Fields&filters=`;
      }
      return url;
    } catch (error) {
      this.setState({ error: error });
    }
  };
  runSearchFromQueryParams(allfilters, appliedfilters, querytext) {
    try {
      //console.log(querytext);
      this.setState({ isProcessing: true, isLoaded: false });
      const filterService = new FilterService();
      const query = querytext;
      const inFilters = appliedfilters;
      // const [filters] = filterService.expandFilterSet(allfilters, inFilters)
      const [filters] = filterService.expandFilterSet(
        this.state.filters,
        inFilters
      );
      const filterSelected = inFilters;
      let selectedFiltered = this.state.selectedFilters.concat(filters);
      selectedFiltered = [...new Set(selectedFiltered)];
      this.setState(
        {
          appliedFilters: inFilters,
          selectedFilters: selectedFiltered,
          // selectedFilters: filters
        },
        () => {
          this.runSearch(query, filterSelected);
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  }
  generateArrayString = (array) => {
    try {
      let arrStr = "";
      array.map((item, index) => {
        if (index === array.length - 1) {
          arrStr += item;
        } else {
          arrStr += item + ";";
        }
        return null;
      });
      return arrStr;
    } catch (error) {
      this.setState({ error: error });
    }
  };
  runSearch(querytext, filterSelected) {
    try {
      this.setState({
        isProcessing: true,
        globalCollapseAll: false /* , globalSelectAll: false */,
        collapsedSearchIDs: [],
      });
      var url =
        LocationOrigin +
        process.env.REACT_APP_IMS_API_PREFIX +
        "qp/runPubMedSearch";
      let filterStr = this.generateArrayString(filterSelected);
      var config;
      var data = {};
      let query = querytext;
      let isBoolean = false;
      const queryParams = new URLSearchParams(this.props.location.search);
      /*Check for filededSearch values*/
      const params = queryParams.get("query");
      const fcount = queryParams.get("fcount");
      const f1 = queryParams.get("f1");
      const isDateRange = queryParams.get("isDateRange");
      /*Check for sbQuery*/
      // if (sbQuery !== null) {
      //   query = sbQuery;
      // }
      /*Check for boolean operator in Query String Start*/
      isBoolean = queryParams.get("isBoolean");
      let booleanCounter;
      if (query) {
        booleanCounter = (query.match(/ OR | AND | NOT | NEAR /g) || []).length;
      }
      if (isBoolean !== null || booleanCounter >= 4) {
        isBoolean = true;
      } else if (booleanCounter >= 1 && query.split(" ").length <= 10) {
        isBoolean = true;
      } else {
        isBoolean = false;
      }
      /*Check for boolean operator in Query String End*/
      if (params !== "" && f1 === "majorTopicsSynonyms") {
        this.setState({
          showMajorTopicSearch: true,
        });
      } else if (
        (params !== "" && f1 === undefined) ||
        f1 === null ||
        f1 === ""
      ) {
        this.setState({
          showMajorTopicSearch: true,
        });
      } else if (params !== "" && f1 !== "majorTopicsSynonyms") {
        this.setState({
          showMajorTopicSearch: false,
        });
      }
      if (f1 === "majorTopicsSynonyms") {
        this.setState({
          majorTopicSearchState: false,
          showMajorTopicSearch: true,
        });
      }
      if (fcount > 0) {
        data = {
          query: query,
          filters: filterStr,
          from:
            this.state.currentPage * this.state.itemsPerPage -
            this.state.itemsPerPage,
          size: parseInt(this.state.itemsPerPage),
          sortBy: this.state.sortBy,
          sortField: this.state.sortField,
          fromYear: this.state.fromYear,
          toYear: this.state.toYear,
        };
        config = {
          method: "GET",
          url: url,
          headers: {
            "Content-Type": "application/json",
          },
        };
      } else {
        config = {
          method: "GET",
          url: url,
          headers: {
            "Content-Type": "application/json",
          },
        };
        data = {
          query: query,
          filters: filterStr,
          from:
            this.state.currentPage * this.state.itemsPerPage -
            this.state.itemsPerPage,
          size: parseInt(this.state.itemsPerPage),
          sortBy: this.state.sortBy,
          sortField: this.state.sortField,
          fromYear: this.state.fromYear,
          toYear: this.state.toYear,
        };
      }
      if (isDateRange !== null) {
        let rangeField = queryParams.get("rangeField");
        let toRange = queryParams.get("toRange");
        let fromRange = queryParams.get("fromRange");
        data["rangeField"] = rangeField;
        data["isDateRange"] = true;
        if (toRange !== null) {
          data["toRange"] = toRange;
        }
        if (fromRange !== null) {
          data["fromRange"] = fromRange;
        }
      }
      const queryparams = new URLSearchParams(data).toString();
      config.url = `${url}?${queryparams}`;
      axios(config)
        .then((response) => {
          var processed = processPubMedFilters(response.data.body);
          // var processedSilterYears = this.handelfilterYears(
          //   response.data.body.yearBuckets
          // );
          const filterService = new FilterService();
          const [filters] = filterService.expandFilterSet(
            processed,
            this.state.appliedFilters
          );
          let selectedFiltered = this.state.selectedFilters.concat(filters);
          // selectedFiltered = [...new Set(selectedFiltered)]
          selectedFiltered = this.state.appliedFilters.concat(filters);
          this.setState(
            {
              documentCount: response.data.body.hits.total.value,
              response: response.data.body.hits.hits,
              filters: processed,
              isProcessing: false,
              isLoaded: true,
              collapseFilter: false,
              filterYears: response.data.body.yearBuckets.sort(
                (a, b) => parseFloat(a.key) - parseFloat(b.key)
              ),
              selectedFilters: selectedFiltered,
              //filterYears: processedSilterYears
            },
            () => {
              if (
                this.state.globalSelectAll &&
                (this.state.citationSelectStatus.length.toString() ===
                  this.state.itemsPerPage ||
                  this.state.citationSelectStatus.length ===
                    this.state.documentCount)
              ) {
                this.setState({ globalSelectAll: true });
              } else {
                this.setState({ globalSelectAll: false });
              }
              this.handleAllFilters(
                this.state.filters,
                this.state.appliedFilters,
                this.state.selectedFilters,
                this.state.response,
                this.state.documentCount,
                querytext
              );
            }
          );
          // VIEW SELECTED LOGIC
          let data = this.state.selectedResults;
          let citationSelectStatus = this.state.citationSelectStatus;
          let markedUrl = this.markedDocumentsUrl;
          var sessiondata = sessionStorage.getItem("selectedResultsDetails");
          // for (let index = 0; index < this.state.documentCount; index++) {
          if (localStorage.getItem("selectedResults") === null) {
            // console.log(this.props.location.search,this.state.prevUrl,window.location.search)
            if (window.location.search !== this.state.prevUrl) {
              data = [];
              citationSelectStatus = [];
              markedUrl = [];
            }
            /* else {
                          citationSelectStatus.push(false)
                      } */
          }
          if (
            localStorage.getItem("selectedResults") !== null &&
            sessiondata !== null
          ) {
            // let totalPages = Math.ceil(this.state.documentCount / this.state.itemsPerPage)
            // console.log(this.state.itemsPerPage, totalPages,localStorage.getItem('selectedResults'), Math.max.apply(null,localStorage.getItem('selectedResults').split(',')))
            // else{
            // if (window.location.search != this.state.prevUrl) {
            if (
              decodeURI(this.props.location.search) !==
              decodeURI(this.state.prevUrl)
            ) {
              data = [];
              citationSelectStatus = [];
              localStorage.removeItem("selectedResults");
              localStorage.removeItem("ItemsPerPage");
              localStorage.removeItem("ActivePage");
              localStorage.removeItem("markedResultsUrl");
              sessionStorage.clear();
            }
            // else {
            //     localStorage.getItem('selectedResults').split(',').map((x, key) => {
            //         let i = parseInt(x)
            //         citationSelectStatus[i - 1] = true;
            //         if (citationSelectStatus[i - 1]) {
            //             data = JSON.parse(sessiondata)
            //             // data.push(JSON.parse(sessiondata))
            //             // data.push({
            //             //     detail: this.state.response[i - 1]._source,
            //             //     itemTeaser: this.state.response[i - 1].highlight.body,
            //             //     index: i
            //             // })
            //         }
            //         else {
            //             data.splice(i - 1, 1)
            //         }
            //     })
            // }
          }
          // }
          // console.log(data,citationSelectStatus)
          this.markedDocumentsUrl = markedUrl;
          this.setState({
            citationSelectStatus: citationSelectStatus,
            selectedResults: data,
          });
        })
        .catch((error) => {
          this.setState({ error: error });
        })
        .finally(() => {
          this.setState({ isProcessing: false, isLoaded: true });
        });
    } catch (error) {
      this.setState({ error: error });
    }
  }
  handelfilterYears = (yearBuckets) => {
    try {
      yearBuckets = yearBuckets.sort(
        (a, b) => parseFloat(a.key) - parseFloat(b.key)
      );
      let { fromYear, toYear } = this.state;
      if (yearBuckets.length === 0) {
        for (let i = fromYear; i <= toYear; i++) {
          yearBuckets.push({ key: i, doc_count: 0 });
        }
      } else if (yearBuckets[0].key > fromYear) {
        let remeningYearBuckets = [];
        let lastYear =
          yearBuckets[yearBuckets.length - 1].key > toYear
            ? yearBuckets[yearBuckets.length - 1].key
            : toYear;
        for (let i = fromYear; i <= lastYear; i++) {
          if (yearBuckets.findIndex((x) => x.key === i) === -1) {
            remeningYearBuckets.push({ key: i, doc_count: 0 });
          }
        }
        yearBuckets = yearBuckets.concat(remeningYearBuckets);
      } else if (yearBuckets[yearBuckets.length - 1].key < toYear) {
        let remeningYearBuckets = [];
        let firstYear =
          yearBuckets[0].key < fromYear ? yearBuckets[0].key : fromYear;
        for (let i = firstYear; i <= toYear; i++) {
          if (yearBuckets.findIndex((x) => x.key === i) === -1) {
            remeningYearBuckets.push({ key: i, doc_count: 0 });
          }
        }
        yearBuckets = yearBuckets.concat(remeningYearBuckets);
      } else {
        let remeningYearBuckets = [];
        for (
          let i = yearBuckets[0].key;
          i <= yearBuckets[yearBuckets.length - 1].key;
          i++
        ) {
          if (yearBuckets.findIndex((x) => x.key === i) === -1) {
            remeningYearBuckets.push({ key: i, doc_count: 0 });
          }
        }
        yearBuckets = yearBuckets.concat(remeningYearBuckets);
      }

      return yearBuckets.sort((a, b) => parseFloat(a.key) - parseFloat(b.key));
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleClearFilters = () => {
    try {
      const currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      //console.log('Clear Filters from Breadcrumbs')
      this.setState(
        {
          selectedFilters: [],
          appliedFilters: [],
          selectedParentFilterArr: [],
          appliedFiltersForOnlyiMS: [],
          filters: [],
          fromYear: currentYear - 2,
          toYear: currentYear,
          globalSelectAll: false,
          selectedResults: [],
          citationSelectStatus: [],
        },
        () => {
          let params = queryString.parse(this.props.location.search);
          const newUrl = this.buildUrl(
            params.query,
            params.q,
            params.f1,
            this.state.appliedFilters,
            false,
            false
          );
          this.props.history.push(newUrl);
          this.runSearchFromQueryParams(
            this.state.allFilters,
            this.state.appliedFilters,
            this.state.searchKeyword
          );
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleRemoveAppliedFilter = (facet) => {
    try {
      let parentFacetName = facet.split("/")[0];
      let arr = this.state.selectedParentFilterArr;
      const index = arr.indexOf(parentFacetName);
      if (index > -1) {
        arr.splice(index, 1);
      }
      this.setState(
        {
          appliedFilters: this.state.appliedFilters.filter(
            (filter) => !filter.startsWith(facet)
          ),
          selectedParentFilterArr: arr,
          selectedFilters: this.state.selectedFilters.filter(
            (filter) => filter && !filter.startsWith(facet)
          ),
          globalSelectAll: false,
          selectedResults: [],
          citationSelectStatus: [],
        },
        () => {
          let params = queryString.parse(this.props.location.search);
          const newUrl = this.buildUrl(
            params.query,
            params.q,
            params.f1,
            this.state.appliedFilters,
            false
          );
          this.props.history.push(newUrl);
          this.runSearchFromQueryParams(
            this.state.allFilters,
            this.state.appliedFilters,
            this.state.searchKeyword
          );
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleClearSearch = () => {
    try {
      const currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      this.setState(
        {
          selectedFilters: [],
          appliedFilters: [],
          filters: [],
          selectedParentFilterArr: [],
          searchKeyword: "",
          isClearSearch: true,
          currentPage: 1,
          appliedFiltersForOnlyiMS: [],
          fromYear: currentYear - 2,
          toYear: currentYear,
          globalSelectAll: false,
          selectedResults: [],
          citationSelectStatus: [],
        },
        () => {
          //let params = queryString.parse(this.props.location.search);
          const newUrl = this.buildUrl(
            this.state.searchKeyword,
            "All Fields",
            "",
            this.state.appliedFilters,
            true
          );
          this.props.history.push(newUrl);
          this.runSearchFromQueryParams(
            this.state.allFilters,
            this.state.appliedFilters,
            this.state.searchKeyword
          );
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleMajorTopicSearch = (url) => {
    try {
      this.props.history.replace(url);
      window.location.reload();
      /* this.setState({
            showMajorTopicSearch:true,
            majorTopicSearchState:!this.state.majorTopicSearchState
        }) */
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handelResults = () => {
    try {
      this.setState({ showResultType: !this.state.showResultType });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleChangeInDropdown = (event) => {
    try {
      event.preventDefault();
      this.setState({
        resultDropdownValue: event.target.attributes.keyvalue.value,
        showResultType: !this.state.showResultType,
      });
      if (event.target.attributes.keyvalue.value === "iMS Search Results") {
        this.handleOnImsTabClick();
      }
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handelSearchResultClose = () => {
    try {
      this.setState({ showResultType: false });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  conversionOfFieldType = (item) => {
    try {
      if (item === "docType") return "Document Type";
      else if (item === "protocolIDs" || item === "harmonizedProtocolIDs") return "Protocol Number";
      else if (item === "genericName") return "Generic Name";
      else if (item === "congressName") return "Congress Name";
      else return "Journal Name";
    } catch (error) {
      this.setState({ error: error });
    }
  };

  handleCollapseAllGlobal = () => {
    try {
      let { response, collapsedSearchIDs } = this.state;
      if (!this.state.globalCollapseAll) {
        collapsedSearchIDs = response.map((x) => x._source.PMID);
      } else {
        collapsedSearchIDs = [];
      }
      this.setState({
        globalCollapseAll: !this.state.globalCollapseAll,
        collapsedSearchIDs: collapsedSearchIDs,
      });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleSelectAll = () => {
    try {
      //console.log('Change reflecting till result');
      this.setState(
        {
          globalSelectAll: !this.state.globalSelectAll,
        },
        () => {
          let startIndex =
            this.state.currentPage * this.state.itemsPerPage -
            this.state.itemsPerPage +
            1;
          var count = startIndex;
          let endIndex = this.state.itemsPerPage;
          if (this.state.globalSelectAll) {
            this.markedDocumentsUrl = [];
            this.setState({ selectedResults: [] }, () => {
              for (let i = 1; i <= endIndex; i++) {
                //console.log(i,this.state.response[i-1])
                if (this.state.response[i - 1] !== undefined) {
                  this.handleCitationSelectionGlobal(
                    count,
                    this.state.response[i - 1]._source,
                    this.state.response[i - 1].highlight === undefined ||
                      this.state.response[i - 1].highlight?.body[0] ===
                        "Empty File" ||
                      this.state.response[i - 1].highlight?.body[0] ===
                        "Not an empty file"
                      ? []
                      : this.state.response[i - 1].highlight.body,
                    i
                  );
                  count++;
                }
              }
            });
          } else {
            for (let i = 1; i <= endIndex; i++) {
              if (this.state.response[i - 1] !== undefined) {
                // console.log(count)
                this.handleCitationUnSelection(
                  count,
                  this.state.response[i - 1]._source,
                  this.state.response[i - 1].highlight === undefined ||
                    this.state.response[i - 1].highlight?.body[0] ===
                      "Empty File" ||
                    this.state.response[i - 1].highlight?.body[0] ===
                      "Not an empty file"
                    ? []
                    : this.state.response[i - 1].highlight.body
                );
                count++;
              }
            }
          }
        }
      );
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleGlobalEmailPopUp = (status) => {
    try {
      this.setState({ globalEmailPopUp: status });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleEmailPopUpClose = () => {
    try {
      this.setState({ globalEmailPopUp: false });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleGlobalNotifyMePopUp = (status) => {
    try {
      this.setState({ globalNotifyMePopUp: status });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleNotifyMePopUpClose = () => {
    try {
      this.setState({ globalNotifyMePopUp: false });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleGlobalBookmark = (status) => {
    try {
      this.setState({ bookmarkPopUp: status });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleCloseBookmark = () => {
    try {
      this.setState({ bookmarkPopUp: false });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleOnClickReadingList = (status) => {
    try {
      this.setState({ readingListPopup: status });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleCloseReadingList = () => {
    try {
      this.setState({ readingListPopup: false });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  handleOnImsTabClick = () => {
    try {
      let queryStringparams = queryString.parse(this.props.location.search);
      let { appliedFilters, appliedFiltersForOnlyiMS } = this.state;
      appliedFilters = appliedFilters.concat(appliedFiltersForOnlyiMS);
      const pubMedUrl = this.buildUrl(
        queryStringparams.query,
        queryStringparams.q,
        queryStringparams.f1,
        appliedFilters,
        false
      );
      let str = this.createSearchCriteriaString(`${pubMedUrl}`);
      str = str.replace(/(")/g, '\\"');
      const ts = new trackingService();
      
      ts.indexTrackingData(
        localStorage.getItem("userdisplayName"),
        localStorage.getItem("wwid"),
        localStorage.getItem("username"),
        localStorage.getItem("function"),
        localStorage.getItem("region"),
        localStorage.getItem("userproducts"),
        localStorage.getItem("doctypepref"),
        localStorage.getItem("sid"),
        localStorage.getItem("deviceType"),
        localStorage.getItem("deviceOS"),
        localStorage.getItem("browserName"),
        localStorage.getItem("browserVersion"),
        localStorage.getItem("jnjDepartment"),
        localStorage.getItem("companyname"),
        "Conducted Search",
        "",
        `/results${pubMedUrl}`,
        str,
        this.state.appliedFiltersForOnlyiMS.concat(this.state.appliedFilters),
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        this.props.csPath !== null ? this.props.csPath : "",
        this.props.csproduct !== null ? this.props.csproduct : ""
      );
      this.props.history.push(`/results${pubMedUrl}`);
    } catch (error) {
      this.setState({ error: error });
    }
  };
  createSearchCriteriaString(url) {
    const queryParams = new URLSearchParams(url);
    const params = queryParams.get("query");
    const filters = queryParams.get("filters");
    const q = queryParams.get("q");
    const fcount = queryParams.get("fcount");
    const majorTopicsSynonyms = queryParams.get("majorTopicsSynonyms");
    const authors = queryParams.get("authors");
    const docTitle = queryParams.get("docTitle");
    let str = "";
    let fieldedSearch = queryParams.get("FS");
    let limistArr = [];
    let f1 = "", fval1 = "";

    if (window.location.pathname !== "/ReferencesUsed") {
      if (fcount && fcount > 0) {
        f1 = queryParams.get("f1");
        fval1 = queryParams.get("fval1");
      }
      if ((f1 && (f1 === "tempJNJDrugs" || f1 === "taIRO")) &&
        params !== "" &&
        filters !== "" &&
        q !== "All Fields" &&
        !fieldedSearch
      ) {
        if (f1 === "tempJNJDrugs") {
          str = `(Products: ${fval1}): ${q}: ${params}: ${filters}`;
        } else {
          str = `(Therapeutic Area: ${fval1}): ${q}: ${params}: ${filters}`;
        }
      }
      else if ((f1 && (f1 === "tempJNJDrugs" || f1 === "taIRO")) && params !== "" &&
        filters !== "" &&
        q === "All Fields" &&
        !fieldedSearch) {
        if (f1 === "tempJNJDrugs") {
          str = `(Products: ${fval1}): ${params}: ${filters}`;
        } else {
          str = `(Therapeutic Area: ${fval1}): ${params}: ${filters}`;
        }
      }
      else if ((f1 && (f1 === "tempJNJDrugs" || f1 === "taIRO")) &&
        params !== "" &&
        filters === "" &&
        q === "All Fields" &&
        !fieldedSearch
      ) {
        if (f1 === "tempJNJDrugs") {
          str = `(Products: ${fval1}): ${params}`;
        } else {
          str = `(Therapeutic Area: ${fval1}): ${params}`;
        }
      } else if ((f1 && (f1 === "tempJNJDrugs" || f1 === "taIRO")) &&
        params !== "" &&
        filters === "" &&
        q !== "All Fields" &&
        !fieldedSearch
      ) {
        if (f1 === "tempJNJDrugs") {
          str = `(Products: ${fval1}): ${q}: ${params}`;
        } else {
          str = `(Therapeutic Area: ${fval1}): ${q}: ${params}`;
        }
      }
      else if (
        params !== "" &&
        filters !== "" &&
        q !== "All Fields" &&
        !fieldedSearch
      ) {
        str = `${q}: ${params}: ${filters}`;
      }
      else if (
        params !== "" &&
        filters !== "" &&
        q === "All Fields" &&
        !fieldedSearch
      ) {
        str = `${params}: ${filters}`;
      } else if (
        params !== "" &&
        filters === "" &&
        q === "All Fields" &&
        !fieldedSearch
      ) {
        str = `${params}`;
      } else if (
        params !== "" &&
        filters === "" &&
        q !== "All Fields" &&
        !fieldedSearch
      ) {
        str = `${q}: ${params}`;
      } else if (fieldedSearch) {
        // Publication Year
        let toRange = queryParams.get("toYear");
        let fromRange = queryParams.get("fromYear");
        let PublicationYear = "";
        if (toRange) {
          toRange = parseInt(toRange);
        }
        if (fromRange) {
          fromRange = parseInt(fromRange);
        }
        let strYear = limistArr.find((x) => x.key === "strYear");
        if (strYear) {
          let strYearValue = parseInt(strYear.value);
          if (
            toRange &&
            fromRange &&
            fromRange <= strYearValue &&
            toRange >= strYearValue
          ) {
            PublicationYear = `${fromRange} To ${toRange}`;
          } else if (
            toRange &&
            fromRange &&
            (fromRange > strYearValue || toRange < strYearValue)
          ) {
            PublicationYear = `${fromRange} To ${toRange} AND ${strYearValue}`;
          } else if (toRange && strYearValue) {
            PublicationYear = `${toRange} and earlier AND ${strYearValue}`;
          } else if (fromRange && strYearValue) {
            PublicationYear = `${fromRange} and later AND ${strYearValue}`;
          } else if (strYearValue) {
            PublicationYear = `${strYearValue}`;
          }
        } else if (toRange && fromRange) {
          PublicationYear = `${fromRange} To ${toRange}`;
        } else if (toRange) {
          PublicationYear = `${toRange} and earlier`;
        } else if (fromRange) {
          PublicationYear = `${fromRange} and later`;
        }
        limistArr = limistArr.filter((x) => x.key !== "strYear");
        ///////
        let fcount = queryParams.get("fcount");
        for (let i = 0; i < fcount; i++) {
          let key = queryParams.get(`f${i + 1}`);
          let value = queryParams.get(`fval${i + 1}`);
          limistArr[i] = { key: key, value: value };
        }

        str = params ? `(${params}) ${filters ? ":" + filters : ""}` : "";

        if (!params && params === "" && filters !== "" && filters !== null) {
          str = str + `${filters ? filters : ""}`;
        }

        str = str + (docTitle ? ` (Title: ${docTitle})` : "");
        str = str + (authors ? ` (Authors: ${authors})` : "");
        str =
          str +
          (majorTopicsSynonyms
            ? ` (Major Topics: ${majorTopicsSynonyms})`
            : "");
        str =
          str +
          (PublicationYear ? ` (Publication Year: ${PublicationYear})` : "");
        if (fcount > 0 && limistArr.length > 0) {
          limistArr.map((item, index) => {
            str =
              str +
              (item.key
                ? ` (${this.conversionOfFieldType(item.key)}: ${item.value})`
                : "");
          });
        }
      }

      if (window.supportingDocsSearchStrategyDescription) {
        let searchDes = window.supportingDocsSearchStrategyDescription;
        if (str) {
          str = searchDes + `, ${str}`;
        }
      }
      return str;
    }
  }
  onMouseEnterInfo = () => {
    try {
      this.setState({
        showInfo: true,
      });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  onMouseLeaveInfo = () => {
    try {
      this.setState({
        showInfo: false,
      });
    } catch (error) {
      this.setState({ error: error });
    }
  };
  createErrorHtml = () => {
    if (this.state.error.response) {
      let JSON = this.state.error.response.data;

      return (
        <>
          <p>
            <b>ERROR: </b>
            {JSON.ERROR}
            <br />
            <b>MESSAGE: </b>
            {JSON.MESSAGE}
            <br />
            <b>REQUEST-ID: </b>
            {JSON["REQUEST-ID"]}
            <br />
            Please contact{" "}
            <a
              href={`mailto:imks.jnj.com?subject= iMS Query Error - ${JSON["REQUEST-ID"]}`}
            >
              iMedical Search Support Team
            </a>
          </p>
        </>
      );
    } else {
      return this.state.error.message;
    }
  };
  render() {
    try {
      let params = new URLSearchParams(this.props.location.search);
      let query = params.get("query");
      /* const filters = queryParams.get('filters') 
        let params = queryString.parse(this.props.location.search);*/
      // var category;
      var filters = params.get("filters");
      if (params.get("q") === undefined) {
        category = "All Fields";
      } else {
        category = params.get("q");
      }
      if (filters === "" || filters === undefined || filters === null) {
        filters = "";
      }
      if (this.state.width < 600) {
        return (
          <div>
            <ErrorBoundary>
              {this.state.userExists ? (
                <>
                  <div className="resultsInnerWrapper">
                    <PubMedNavigationMenu
                      pathName={window.location.pathname}
                      customWidth={this.state.width}
                      customHeight={this.state.height}
                    />
                    <div className="header-wrapper">
                      <PubMedHeaderSearch
                        history={this.props.history}
                        dropdownValue={category}
                        searchKeyword={query}
                        appliedFilters={this.state.appliedFilters}
                        handleChangeInSearch={this.handleChangeInSearch}
                        handleClearSearch={this.handleClearSearch}
                        customWidth={this.state.width}
                        customHeight={this.state.height}
                        isCitationSelected={this.state.citationSelectStatus}
                        isClearSearch={this.state.isClearSearch}
                        csPath={this.state.csPath}
                        csproduct={this.state.csproduct}
                      />
                    </div>
                    <div className="results-contents-wrapper">
                      <div className="imsResultsMenuWrapper-mobile">
                        {this.state.error === "" ? (
                          <ResponsiveFilterComponent
                            pathName={window.location.pathname}
                            customWidth={this.state.width}
                            customHeight={this.state.height}
                            allFilters={this.state.allFilters}
                            filters={this.state.filters}
                            appliedFilters={this.state.appliedFilters}
                            handleOnChangeInfilter={this.handleOnChangeInfilter}
                            selectedParentFilterArr={
                              this.state.selectedParentFilterArr
                            }
                            handleChangeInSearch={this.handleChangeInSearch}
                            selectedFilters={this.state.selectedFilters}
                            querytext={this.state.searchKeyword}
                            documentCount={this.state.documentCount}
                            response={this.state.response}
                            isProcessing={this.state.isProcessing}
                            handelSelectedCitation={
                              this.getSelectedCitationDetails
                            }
                            handleCollapseAllGlobal={
                              this.handleCollapseAllGlobal
                            }
                            globalCollapseAll={this.state.globalCollapseAll}
                            collapseFilterRes={this.state.collapseFilter}
                            globalSelectAll={this.state.globalSelectAll}
                            handleSelectAll={this.handleSelectAll}
                            handleGlobalEmailPopUp={this.handleGlobalEmailPopUp}
                            handleGlobalNotifyMePopUp={
                              this.handleGlobalNotifyMePopUp
                            }
                            handleGlobalBookmark={this.handleGlobalBookmark}
                            handleOnClickReadingList={
                              this.handleOnClickReadingList
                            }
                            csPath={this.state.csPath}
                            csproduct={this.state.csproduct}
                            appliedFiltersForOnlyiMS={
                              this.state.appliedFiltersForOnlyiMS
                            }
                            filterYears={this.state.filterYears}
                            fromYear={this.state.fromYear}
                            toYear={this.state.toYear}
                            handleYearSelection={this.handleYearSelection}
                            handlePageChange={this.gotoPage}
                          />
                        ) : (
                          <ResponsiveFilterComponent
                            pathName={window.location.pathname}
                            customWidth={this.state.width}
                            customHeight={this.state.height}
                            allFilters={[]}
                            filters={[]}
                            appliedFilters={[]}
                            handleOnChangeInfilter={this.handleOnChangeInfilter}
                            selectedParentFilterArr={[]}
                            handleChangeInSearch={this.handleChangeInSearch}
                            selectedFilters={[]}
                            querytext={this.state.searchKeyword}
                            documentCount={0}
                            response={[]}
                            isProcessing={this.state.isProcessing}
                            handelSelectedCitation={
                              this.getSelectedCitationDetails
                            }
                            handleCollapseAllGlobal={
                              this.handleCollapseAllGlobal
                            }
                            globalCollapseAll={this.state.globalCollapseAll}
                            collapseFilterRes={this.state.collapseFilter}
                            globalSelectAll={this.state.globalSelectAll}
                            handleSelectAll={this.handleSelectAll}
                            handleGlobalEmailPopUp={this.handleGlobalEmailPopUp}
                            handleGlobalNotifyMePopUp={
                              this.handleGlobalNotifyMePopUp
                            }
                            handleGlobalBookmark={this.handleGlobalBookmark}
                            handleOnClickReadingList={
                              this.handleOnClickReadingList
                            }
                            csPath={this.state.csPath}
                            csproduct={this.state.csproduct}
                            appliedFiltersForOnlyiMS={[]}
                            filterYears={[]}
                            fromYear={""}
                            toYear={""}
                            handleYearSelection={this.handleYearSelection}
                            handlePageChange={this.gotoPage}
                            isError={true}
                          />
                        )}
                        <div
                          className="imsResultsMenuHeader-mobile"
                          onClick={this.handelResults}
                          onMouseLeave={this.handelSearchResultClose}
                        >
                          <span>
                            {/* iMS Search Results */}
                            {this.state.resultDropdownValue}
                          </span>
                          <img
                            src={ActionsArrowIcon}
                            alt="ActionsArrowIcon"
                            className="iMSPubMedResults-dropdownIcon-mobile"
                            style={{
                              transform: this.state.showResultType
                                ? "matrix(-1, 0, 0, -1, 0, 0)"
                                : null,
                            }}
                          />
                          {this.state.showResultType && (
                            <ul className="iMSPubMedResults-dropdownContainer-mobile">
                              {this.state.resultDropdownValue ===
                              "iMS Search Results" ? null : (
                                <li
                                  className="iMSPubMedResults-dropdownText-mobile"
                                  keyvalue="iMS Search Results"
                                  onClick={this.handleChangeInDropdown}
                                >
                                  iMS Search Results
                                </li>
                              )}
                              {this.state.resultDropdownValue ===
                              "PubMed Search Results" ? null : (
                                <li
                                  className="iMSPubMedResults-dropdownText-mobile"
                                  keyvalue="PubMed Search Results"
                                  onClick={this.handleChangeInDropdown}
                                >
                                  PubMed Search Results
                                </li>
                              )}
                              {/* <span className="iMSPubMedResults-dropdownText-mobile">iMS Results</span>
                                                    <span className="iMSPubMedResults-dropdownText-mobile">PubMed Results</span> */}
                            </ul>
                          )}
                          {/* <span>
                                        PubMed Search Results
                                    </span> */}
                        </div>
                        <div className="resultsContentWrapper resultsContentWrapper-mobile">
                          {this.state.isLoaded ? (
                            <>
                              {/* {this.state.response.length > 0 &&
                                                    <> */}
                              {this.state.error === ""
                                ? this.resultListCount(
                                    this.state.itemsPerPage,
                                    this.state.currentPage
                                  )
                                : ""}
                              {this.state.appliedFilters.length > 0 ? (
                                <FilterDisplay
                                  allFilters={this.state.allFilters}
                                  appliedFilters={this.state.appliedFilters}
                                  handleClearFilters={this.handleClearFilters}
                                  handleRemoveAppliedFilter={
                                    this.handleRemoveAppliedFilter
                                  }
                                  customWidth={this.state.width}
                                />
                              ) : null}
                              <div className="results-wrapper">
                                {this.state.error === "" ? (
                                  this.resultList(
                                    this.state.response,
                                    this.state.itemsPerPage,
                                    this.state.currentPage
                                  )
                                ) : (
                                  <>
                                    {(() => {
                                      let service = new Services();
                                      service.logService(
                                        this.state.error.stack,
                                        "error"
                                      );
                                    })()}

                                    <div style={{ margin: "2rem 2rem" }}>
                                      <h2>Sorry, something went wrong</h2>
                                      <p
                                        style={{
                                          fontSize: "0.9rem",
                                          marginTop: "30px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          this.setState({
                                            errorExpand:
                                              !this.state.errorExpand,
                                          });
                                        }}
                                      >
                                        {this.state.errorExpand
                                          ? `SHOW LESS`
                                          : `SHOW DETAILS`}
                                      </p>
                                      {this.state.errorExpand ? (
                                        <p
                                          style={{
                                            backgroundColor: "#f8d4d4",
                                            borderColor: "#f5a6a7",
                                          }}
                                        >
                                          {this.createErrorHtml()}
                                          {/* {JSON.stringify(
                                            this.state.error.response
                                              ? this.state.error.response.data
                                              : this.state.error.message
                                          )
                                            .replaceAll("{", "")
                                            .replaceAll("}", "")} */}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                              {/* <Pagination metadata={this.state.documentCount} handlePageChange={this.handlePageChange} itemsPerPage={this.state.itemsPerPage} page={this.state.currentPage} customWidth={this.state.width} customHeight={this.state.height}/> */}
                            </>
                          ) : (
                            <div
                              style={{ textAlign: "center", padding: "10%" }}
                            >
                              <img
                                src={imsLoader}
                                alt="Loading Data"
                                style={{ width: "10%" }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ margin: "0% 1%", marginTop: "auto" }}>
                    <Footer
                      customWidth={this.state.width}
                      customHeight={this.state.height}
                    />
                  </div>
                  {this.state.globalEmailPopUp && (
                    <GlobalSearchFeaturePopUpModal
                      showModal={this.state.globalEmailPopUp}
                      showEmailModal={true}
                      handleClose={this.handleEmailPopUpClose}
                      selectedResults={this.state.selectedResults}
                      customWidth={this.state.width}
                      customHeight={this.state.height}
                    />
                  )}
                  {this.state.globalNotifyMePopUp && (
                    <NotifyMePopUp
                      showPersonalAlerts={this.state.globalNotifyMePopUp}
                      handleClose={this.handleNotifyMePopUpClose}
                      customWidth={this.state.width}
                    />
                  )}
                  {this.state.bookmarkPopUp && (
                    <BookmarkPopUp
                      showModal={this.state.bookmarkPopUp}
                      handleClose={this.handleCloseBookmark}
                      customWidth={this.state.width}
                    />
                  )}
                  {this.state.readingListPopup && (
                    <ReadingListPopup
                      showModal={this.state.readingListPopup}
                      handleClose={this.handleCloseReadingList}
                      width={this.state.width}
                      selectedResults={this.state.selectedResults}
                    />
                  )}
                </>
              ) : (
                <div style={{ textAlign: "center", padding: "10%" }}>
                  <img
                    src={imsLoader}
                    alt="Loading Data"
                    style={{ width: "20%" }}
                  />
                </div>
              )}
            </ErrorBoundary>
          </div>
        );
      } else if (this.state.width >= 600 && this.state.width <= 1000) {
        return (
          <div>
            <ErrorBoundary>
              {this.state.userExists ? (
                <>
                  <div className="resultsInnerWrapper">
                    <PubMedNavigationMenu
                      pathName={window.location.pathname}
                      customWidth={this.state.width}
                      customHeight={this.state.height}
                    />
                    <div className="header-wrapper">
                      <PubMedHeaderSearch
                        history={this.props.history}
                        dropdownValue={category}
                        searchKeyword={query}
                        appliedFilters={this.state.appliedFilters}
                        handleChangeInSearch={this.handleChangeInSearch}
                        handleClearSearch={this.handleClearSearch}
                        customWidth={this.state.width}
                        customHeight={this.state.height}
                        isCitationSelected={this.state.citationSelectStatus}
                        isClearSearch={this.state.isClearSearch}
                        csPath={this.state.csPath}
                        csproduct={this.state.csproduct}
                      />
                    </div>
                    {this.state.error === "" ? (
                      <ResponsiveFilterComponent
                        pathName={window.location.pathname}
                        customWidth={this.state.width}
                        customHeight={this.state.height}
                        allFilters={this.state.allFilters}
                        filters={this.state.filters}
                        appliedFilters={this.state.appliedFilters}
                        handleOnChangeInfilter={this.handleOnChangeInfilter}
                        selectedParentFilterArr={
                          this.state.selectedParentFilterArr
                        }
                        handleChangeInSearch={this.handleChangeInSearch}
                        selectedFilters={this.state.selectedFilters}
                        querytext={this.state.searchKeyword}
                        documentCount={this.state.documentCount}
                        response={this.state.response}
                        isProcessing={this.state.isProcessing}
                        handelSelectedCitation={this.getSelectedCitationDetails}
                        handleCollapseAllGlobal={this.handleCollapseAllGlobal}
                        globalCollapseAll={this.state.globalCollapseAll}
                        collapseFilterRes={this.state.collapseFilter}
                        globalSelectAll={this.state.globalSelectAll}
                        handleSelectAll={this.handleSelectAll}
                        handleGlobalEmailPopUp={this.handleGlobalEmailPopUp}
                        handleGlobalNotifyMePopUp={
                          this.handleGlobalNotifyMePopUp
                        }
                        handleGlobalBookmark={this.handleGlobalBookmark}
                        handleOnClickReadingList={this.handleOnClickReadingList}
                        csPath={this.state.csPath}
                        csproduct={this.state.csproduct}
                        appliedFiltersForOnlyiMS={
                          this.state.appliedFiltersForOnlyiMS
                        }
                        filterYears={this.state.filterYears}
                        fromYear={this.state.fromYear}
                        toYear={this.state.toYear}
                        handleYearSelection={this.handleYearSelection}
                        handlePageChange={this.gotoPage}
                      />
                    ) : (
                      <ResponsiveFilterComponent
                        pathName={window.location.pathname}
                        customWidth={this.state.width}
                        customHeight={this.state.height}
                        allFilters={[]}
                        filters={[]}
                        appliedFilters={[]}
                        handleOnChangeInfilter={this.handleOnChangeInfilter}
                        selectedParentFilterArr={[]}
                        handleChangeInSearch={this.handleChangeInSearch}
                        selectedFilters={[]}
                        querytext={this.state.searchKeyword}
                        documentCount={0}
                        response={[]}
                        isProcessing={this.state.isProcessing}
                        handelSelectedCitation={this.getSelectedCitationDetails}
                        handleCollapseAllGlobal={this.handleCollapseAllGlobal}
                        globalCollapseAll={this.state.globalCollapseAll}
                        collapseFilterRes={this.state.collapseFilter}
                        globalSelectAll={this.state.globalSelectAll}
                        handleSelectAll={this.handleSelectAll}
                        handleGlobalEmailPopUp={this.handleGlobalEmailPopUp}
                        handleGlobalNotifyMePopUp={
                          this.handleGlobalNotifyMePopUp
                        }
                        handleGlobalBookmark={this.handleGlobalBookmark}
                        handleOnClickReadingList={this.handleOnClickReadingList}
                        csPath={this.state.csPath}
                        csproduct={this.state.csproduct}
                        appliedFiltersForOnlyiMS={[]}
                        filterYears={[]}
                        fromYear={""}
                        toYear={""}
                        handleYearSelection={this.handleYearSelection}
                        handlePageChange={this.gotoPage}
                        isError={true}
                      />
                    )}
                    <div className="results-contents-wrapper">
                      <div className="imsResultsMenuWrapper-tablet">
                        <div className="imsResultsMenuHeader-tablet">
                          <span
                            className="imsResultsMenuHeader-tablet-deactive"
                            onClick={(e) => this.handleOnImsTabClick()}
                          >
                            iMS Search Results
                          </span>
                          <span className="pubmedResultsMenuHeader-tablet-active">
                            PubMed Search Results
                          </span>
                        </div>
                        <div className="resultsContentWrapper resultsContentWrapper-tablet">
                          {this.state.appliedFilters.length > 0 ? (
                            <FilterDisplay
                              allFilters={this.state.allFilters}
                              appliedFilters={this.state.appliedFilters}
                              handleClearFilters={this.handleClearFilters}
                              handleRemoveAppliedFilter={
                                this.handleRemoveAppliedFilter
                              }
                              customWidth={this.state.width}
                            />
                          ) : null}
                          {this.state.isLoaded ? (
                            <>
                              {/* {this.state.response.length > 0 &&
                                                    <> */}
                              {this.state.error === ""
                                ? this.resultListCount(
                                    this.state.itemsPerPage,
                                    this.state.currentPage
                                  )
                                : ""}
                              {/* </>
                                                } */}
                              <div className="results-wrapper">
                                {this.state.error === "" ? (
                                  this.resultList(
                                    this.state.response,
                                    this.state.itemsPerPage,
                                    this.state.currentPage
                                  )
                                ) : (
                                  <>
                                    {(() => {
                                      let service = new Services();
                                      service.logService(
                                        this.state.error.stack,
                                        "error"
                                      );
                                    })()}

                                    <div style={{ margin: "2rem 2rem" }}>
                                      <h2>Sorry, something went wrong</h2>
                                      <p
                                        style={{
                                          fontSize: "0.9rem",
                                          marginTop: "30px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          this.setState({
                                            errorExpand:
                                              !this.state.errorExpand,
                                          });
                                        }}
                                      >
                                        {this.state.errorExpand
                                          ? `SHOW LESS`
                                          : `SHOW DETAILS`}
                                      </p>
                                      {this.state.errorExpand ? (
                                        <p
                                          style={{
                                            backgroundColor: "#f8d4d4",
                                            borderColor: "#f5a6a7",
                                          }}
                                        >
                                          {this.createErrorHtml()}
                                          {/* {JSON.stringify(
                                            this.state.error.response
                                              ? this.state.error.response.data
                                              : this.state.error.message
                                          )
                                            .replaceAll("{", "")
                                            .replaceAll("}", "")} */}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                              {this.state.error === "" ? (
                                <Pagination
                                  metadata={this.state.documentCount}
                                  handlePageChange={this.handlePageChange}
                                  itemsPerPage={this.state.itemsPerPage}
                                  page={this.state.currentPage}
                                  customWidth={this.state.width}
                                  customHeight={this.state.height}
                                />
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            <div
                              style={{ textAlign: "center", padding: "10%" }}
                            >
                              <img
                                src={imsLoader}
                                alt="Loading Data"
                                style={{ width: "10%" }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ margin: "0% 1%", marginTop: "auto" }}>
                    <Footer
                      customWidth={this.state.width}
                      customHeight={this.state.height}
                    />
                  </div>
                  {this.state.globalEmailPopUp && (
                    <GlobalSearchFeaturePopUpModal
                      showModal={this.state.globalEmailPopUp}
                      showEmailModal={true}
                      handleClose={this.handleEmailPopUpClose}
                      selectedResults={this.state.selectedResults}
                      customWidth={this.state.width}
                      customHeight={this.state.height}
                    />
                  )}
                  {this.state.globalNotifyMePopUp && (
                    <NotifyMePopUp
                      showPersonalAlerts={this.state.globalNotifyMePopUp}
                      handleClose={this.handleNotifyMePopUpClose}
                      customWidth={this.state.width}
                    />
                  )}
                  {this.state.bookmarkPopUp && (
                    <BookmarkPopUp
                      showModal={this.state.bookmarkPopUp}
                      handleClose={this.handleCloseBookmark}
                      customWidth={this.state.width}
                    />
                  )}
                  {this.state.readingListPopup && (
                    <ReadingListPopup
                      showModal={this.state.readingListPopup}
                      handleClose={this.handleCloseReadingList}
                      width={this.state.width}
                      selectedResults={this.state.selectedResults}
                    />
                  )}
                </>
              ) : (
                <div style={{ textAlign: "center", padding: "10%" }}>
                  <img
                    src={imsLoader}
                    alt="Loading Data"
                    style={{ width: "20%" }}
                  />
                </div>
              )}
            </ErrorBoundary>
          </div>
        );
      } else if (this.state.width > 1000) {
        return (
          <div>
            <ErrorBoundary>
              {this.state.userExists ? (
                <>
                  <div className="resultsInnerWrapper">
                    <PubMedNavigationMenu
                      pathName={window.location.pathname}
                      customWidth={this.state.width}
                      customHeight={this.state.height}
                    />
                    <div className="header-wrapper">
                      <PubMedHeaderSearch
                        history={this.props.history}
                        dropdownValue={category}
                        searchKeyword={query}
                        appliedFilters={this.state.appliedFilters}
                        handleChangeInSearch={this.handleChangeInSearch}
                        handleClearSearch={this.handleClearSearch}
                        customWidth={this.state.width}
                        customHeight={this.state.height}
                        handelSelectedCitation={this.getSelectedCitationDetails}
                        isCitationSelected={this.state.selectedResults}
                        handleCollapseAllGlobal={this.handleCollapseAllGlobal}
                        globalCollapseAll={this.state.globalCollapseAll}
                        globalSelectAll={this.state.globalSelectAll}
                        handleSelectAll={this.handleSelectAll}
                        selectedResults={this.state.selectedResults}
                        csPath={this.state.csPath}
                        csproduct={this.state.csproduct}
                        documentsUrl={this.documentsUrl}
                        markedDocumentsUrl={this.markedDocumentsUrl}
                        itemsPerPage={this.state.itemsPerPage}
                      />
                    </div>
                    <div className="results-contents-wrapper">
                      <div
                        className={
                          this.state.showFilters
                            ? "filterMenuWrapper filterMenuWrapperExpanded"
                            : "filterMenuWrapper"
                        }
                        style={{ float: "left" }}
                      >
                        {this.state.showFilters ? (
                          <>
                            <div className="filterMenuHeader">
                              <span className="filter-menu-icon-wrapper">
                                <img src={filterIconWhite} alt="" />
                              </span>
                              <span>Filters</span>
                              <span
                                style={{ float: "right" }}
                                onClick={(e) =>
                                  this.setState({
                                    showFilters: !this.state.showFilters,
                                  })
                                }
                              >
                                <img src={collapseFilterWhite} alt="" />
                              </span>
                            </div>
                            <div className="filter-menu-content">
                              {this.state.isLoaded ? (
                                this.state.error === "" ? (
                                  <SearchFilter
                                    allFilters={this.state.allFilters}
                                    filters={this.state.filters}
                                    appliedFilters={this.state.appliedFilters}
                                    handleOnChangeInfilter={
                                      this.handleOnChangeInfilter
                                    }
                                    selectedParentFilterArr={
                                      this.state.selectedParentFilterArr
                                    }
                                    handleChangeInSearch={
                                      this.handleChangeInSearch
                                    }
                                    selectedFilters={this.state.selectedFilters}
                                    querytext={this.state.searchKeyword}
                                    documentCount={this.state.documentCount}
                                    response={this.state.response}
                                    isProcessing={this.state.isProcessing}
                                    csPath={this.state.csPath}
                                    csproduct={this.state.csproduct}
                                    customWidth={this.state.width}
                                    appliedFiltersForOnlyiMS={
                                      this.state.appliedFiltersForOnlyiMS
                                    }
                                    key={`filter-menu-content-${this.state.filters.length}`}
                                    filterYears={this.state.filterYears}
                                    fromYear={this.state.fromYear}
                                    toYear={this.state.toYear}
                                    handleYearSelection={
                                      this.handleYearSelection
                                    }
                                    handlePageChange={this.gotoPage}
                                  />
                                ) : (
                                  <SearchFilter
                                    allFilters={[]}
                                    filters={[]}
                                    appliedFilters={[]}
                                    handleOnChangeInfilter={
                                      this.handleOnChangeInfilter
                                    }
                                    selectedParentFilterArr={[]}
                                    handleChangeInSearch={
                                      this.handleChangeInSearch
                                    }
                                    selectedFilters={[]}
                                    querytext={this.state.searchKeyword}
                                    documentCount={0}
                                    response={[]}
                                    isProcessing={this.state.isProcessing}
                                    csPath={this.state.csPath}
                                    csproduct={this.state.csproduct}
                                    customWidth={this.state.width}
                                    appliedFiltersForOnlyiMS={[]}
                                    key={`filter-menu-content-${this.state.filters.length}`}
                                    filterYears={[]}
                                    fromYear={""}
                                    toYear={""}
                                    handleYearSelection={
                                      this.handleYearSelection
                                    }
                                    handlePageChange={this.gotoPage}
                                    isError={true}
                                  />
                                )
                              ) : (
                                <div
                                  style={{
                                    textAlign: "center",
                                    padding: "10%",
                                  }}
                                >
                                  <img
                                    src={imsLoader}
                                    alt="Loading Filters"
                                    style={{ width: "20%" }}
                                  />
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="result-filter-expand-wrapper"
                              onClick={(e) =>
                                this.setState({
                                  showFilters: !this.state.showFilters,
                                })
                              }
                            >
                              <img src={expandFilterWhite} alt="" />
                            </div>
                          </>
                        )}
                      </div>
                      <div
                        className={
                          this.state.showFilters
                            ? "imsResultsMenuWrapper imsResultsMenuCollapse"
                            : "imsResultsMenuWrapper"
                        }
                        style={{ float: "right", borderBottom: "none" }}
                      >
                        <div
                          className="imsResultsMenuHeader imsResultsMenuHeader-deactive"
                          onClick={(e) => this.handleOnImsTabClick()}
                        >
                          <span>iMS Search Results</span>
                        </div>
                        <div className="pubmedResultsMenuHeader pubmedResultsMenuHeader-active">
                          <span>
                            PubMed Search Results
                            {/* <img src={iIcon} alt="i-icon" style={{ margin: "0 0 1% 1%" }} width={18} height={18}
                                                    onMouseEnter={this.onMouseEnterInfo}
                                                    onMouseLeave={this.onMouseLeaveInfo}
                                                    className='cs-tile-i-icon' /> */}
                            {/* <svg aria-hidden="true" onMouseEnter={this.onMouseEnterInfo}
                                                    onMouseLeave={this.onMouseLeaveInfo} style={{marginLeft:"5px"}} focusable="false" data-prefix="fas" data-icon="info-circle" class="svg-inline--fa fa-info-circle fa-w-16 tab-bar-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" currentItem="false">
                                                        <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
                                                        </svg> */}
                            <img
                              src={pubMedIicon}
                              alt="i-icon"
                              style={{ margin: "2px 0 0 0", float: "right" }}
                              width={18}
                              height={18}
                              onMouseEnter={this.onMouseEnterInfo}
                              onMouseLeave={this.onMouseLeaveInfo}
                              className="cs-tile-i-icon"
                            />
                          </span>
                        </div>
                        {this.state.showInfo && (
                          <div className="pubmed-tile-wrapper">
                            <div className="pubmed-tooltip-uparrow"></div>
                            <span className="tooltiptext pubmed-tooltip-bottom">
                              iMS provides access to the full collection of
                              PubMed®. PubMed® comprises more than 30 million
                              citations for biomedical literature from MEDLINE,
                              life science journals, and online books. Citations
                              may include links to full-text content from PubMed
                              Central and publisher web sites.
                            </span>
                          </div>
                        )}
                        {/* <div className="global-search-features-wrapper">
                                    <div className="clear-search-wrapper">
                                        <button className="clear-search-button" onClick={evt=>this.handleClearSearch()}>Clear Search</button>
                                    </div>    
                                </div>*/}
                        {/* this.state.searchKeyword != "" && this.state.showMajorTopicSearch &&
                                <div className="results-page-major-search-wrapper">
                                    <MajorTopic searchKeyword={this.state.searchKeyword} 
                                    majorTopicSearchState={this.state.majorTopicSearchState}
                                    dropdownValue={category}
                                    appliedFilters={this.state.appliedFilters}
                                    onClickRefiner={this.handleMajorTopicSearch}/>
                                </div>
                                 */}
                        <div className="resultsContentWrapper">
                          {this.state.appliedFilters.length > 0 ? (
                            <FilterDisplay
                              allFilters={this.state.allFilters}
                              appliedFilters={this.state.appliedFilters}
                              handleClearFilters={this.handleClearFilters}
                              handleRemoveAppliedFilter={
                                this.handleRemoveAppliedFilter
                              }
                              customWidth={this.state.width}
                            />
                          ) : null}
                          {this.state.isLoaded ? (
                            <>
                              {this.state.response.length > 0 && (
                                <>
                                  {this.state.error === ""
                                    ? this.resultListCount(
                                        this.state.itemsPerPage,
                                        this.state.currentPage
                                      )
                                    : ""}
                                </>
                              )}
                              <div className="results-wrapper">
                                {this.state.error === "" ? (
                                  this.resultList(
                                    this.state.response,
                                    this.state.itemsPerPage,
                                    this.state.currentPage
                                  )
                                ) : (
                                  <>
                                    {(() => {
                                      let service = new Services();
                                      service.logService(
                                        this.state.error.stack,
                                        "error"
                                      );
                                    })()}

                                    <div style={{ margin: "2rem 2rem" }}>
                                      <h2>Sorry, something went wrong</h2>
                                      <p
                                        style={{
                                          fontSize: "0.9rem",
                                          marginTop: "30px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          this.setState({
                                            errorExpand:
                                              !this.state.errorExpand,
                                          });
                                        }}
                                      >
                                        {this.state.errorExpand
                                          ? `SHOW LESS`
                                          : `SHOW DETAILS`}
                                      </p>
                                      {this.state.errorExpand ? (
                                        <p
                                          style={{
                                            backgroundColor: "#f8d4d4",
                                            borderColor: "#f5a6a7",
                                          }}
                                        >
                                          {this.createErrorHtml()}
                                          {/* {JSON.stringify(
                                            this.state.error.response
                                              ? this.state.error.response.data
                                              : this.state.error.message
                                          )
                                            .replaceAll("{", "")
                                            .replaceAll("}", "")} */}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                              {this.state.error === "" ? (
                                <Pagination
                                  metadata={this.state.documentCount}
                                  handlePageChange={this.handlePageChange}
                                  itemsPerPage={this.state.itemsPerPage}
                                  page={this.state.currentPage}
                                  customWidth={this.state.width}
                                  customHeight={this.state.height}
                                />
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            <div
                              style={{ textAlign: "center", padding: "10%" }}
                            >
                              <img
                                src={imsLoader}
                                alt="Loading Data"
                                style={{ width: "10%" }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ margin: "0% 1%", marginTop: "auto" }}>
                    <Footer
                      customWidth={this.state.width}
                      customHeight={this.state.height}
                    />
                  </div>{" "}
                </>
              ) : (
                <div style={{ textAlign: "center", padding: "10%" }}>
                  <img
                    src={imsLoader}
                    alt="Loading Data"
                    style={{ width: "20%" }}
                  />
                </div>
              )}
            </ErrorBoundary>
          </div>
        );
      }
    } catch (error) {
      this.setState({ error: error });
    }
  }
}
export default PubMedResults;
