import React, { Component, createRef } from "react";
import { imsLoader, robotHeadChatgpt, iconUserChatbot, expandAllBlueIcon } from '../../assets/index';
import axios from "axios";
import NotebookListPopUp from "../PopUp/NotebookListPopUp"
import CommandListPopUp from "../PopUp/CommandListPopUp"
import { LocationOrigin, WordsMaxLength } from "../../utils/utilityServices";
import './AuthoringSummery.css'
import moment from "moment";
import { sanitize } from 'dompurify';
import ExpandContentPopUpModal from '../PopUp/ExpandContentPopUpModal';
import ExpandAllFieldsContentPopUpModal from '../PopUp/ExpandAllFieldsContentPopUpModal';
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal";
import trackingService from "../../services/trackingService";
import Services from "../../utils/services";
import ConfirmMessagePopUpModal from "./../PopUp/ConfirmMessagePopUpModal"
import AuthoringUserSummery from "./AuthoringUserSummery"

// import Typewriter from 'typewriter-effect';
const summarizeResult = 'SummarizeResult';
const userQuestion = 'UserQuestion';
const ShowWordCountForCommandType = ['Translate', 'CreateTable'];

class AuthoringSummery extends Component {

    constructor(props) {
        super(props);
        this.messagesEndRef = createRef()
        this.state = {
            isProcessing: false,
            orignalText: '',
            summarizeText: '',
            lastCommandType: 'UserInstructions',
            showNotebookList: false,
            commandList: false,
            commandText: '',
            commandType: 'UserInstructions',
            chatConversation: [],
            sessionID: '',
            notesText: '',
            expandNotes: false,
            expandSourceText: false,
            expandAllFields: false,
            expandCommand: false,
            alertMessageModelColor: "",
            alertMessageContent: "",
            alertMessageShowModal: false,
            savingSummarizeText: '',
            savingCommandType: 'UserInstructions',
            savingOrignalText: '',
            selectAllText: false,
            isProcessingSelectAllText: false,
            showCommandList: false,
            isUserCommand: true,
            isTemplateCommand: false,
            isFromGlobalFeaturesMenu: false,
            isShowMore: false,
            savingCommandText: '',
            isConfirm: false,
            confirmMessageContent: '',
            confirmMessageShowModal: false,
            confirmMessageIndex: -1,
            isClearAllFields: true,
            previousOrignalText: "",
        }
    }
    async componentDidMount() {
        const { summeryText, commandText, sessionID } = this.props;
        if (summeryText) {
            this.setState({ orignalText: summeryText })
        }
        if (summeryText) {
            this.setState({ commandText: commandText })
        }
        if (sessionID) {
            this.setState({ sessionID: sessionID })
        }

    }
    componentWillReceiveProps(nextProps) {
        if (this.state.orignalText !== nextProps.summeryText) {
            this.setState({ orignalText: nextProps.summeryText }, () => { this.sourceTextInput.focus(); })
        }
        if (this.state.commandText !== nextProps.commandText) {
            this.setState({ commandText: nextProps.commandText })
        }
        if (this.state.sessionID !== nextProps.sessionID) {
            this.setState({ sessionID: nextProps.sessionID })
        }
        if (nextProps.isAddActionButton) {
            this.setState({
                selectAllText: false,
            })
        }
    }

    handleSummery = (e) => {
        this.setState({ orignalText: e.target.value })
        this.props.handleSummery(e.target.value);
    }
    showConfirmMessage = (message) => {
        this.setState({
            confirmMessageContent: message,
            confirmMessageShowModal: true,
        })
    }
    confirmHandler = async () => {
        this.setState(
            {
                confirmMessageContent: "",
                confirmMessageShowModal: false,
                confirmMessageIndex: -1,
                isConfirm: true
            },
            async () => {
                this.onSummeryClick();
            }
        );
    }
    closeConfirmMessage = () => {
        this.setState({
            confirmMessageContent: "",
            confirmMessageShowModal: false,
            confirmMessageIndex: -1
        })
    }
    indexData = (item, orignaltext, summarizetext, commandtext, commandtype, previousSummarizeText, sessionid, requestTokenCount, responseTokenCount, multiCommands, answersList) => {
        let { orderedBy, orderedFor } = item;
        if (!orderedBy) orderedBy = '';
        if (!orderedFor) orderedFor = '';
        let docVersion = '', docNumber = ''
        if (item.domain === 'RIMdocs' || item.domain === 'RIMdocs_LL') {
            docVersion = item.r_version_label;
            docNumber = item.jj_number;
        }
        else if (item.domain === 'SKM') {
            docNumber = item.domain;
        }
        else if (item.domain === 'CTGov') {
            docNumber = item.docID;
        }
        else {
            docVersion = item.majorVersionNumber;
            docNumber = item.documentNumber;
        }
        let url = '';
        if (item.domain === 'RIMdocs' || item.domain === 'RIMdocs_LL' || item.domain === 'CTGov') {
            url = `/DocURL?ID=${item.docID}&cs=${item.domain}&orderedBy=${orderedBy}&orderedFor=${orderedFor}`
        }
        else if (item.domain === 'SKM' || item.domain === "MIAs") {
            url = `/DocURL?ID=${item.docID}&cs=${item.domain}&connID=${item.connID}&csID=${item.csID}&fileType=${item.fileExtension}&fileName=${item.docTitle}&orderedBy=${orderedBy}&orderedFor=${orderedFor}`
        }
        else {
            url = `/DocURL?ID=${item.docID}&cs=${item.domain}&connID=${item.connID}&csID=${item.csID}&fileType=${item.fileExtension}&docVer=${item.majorVersionNumber}&fileName=${item.docTitle}&orderedBy=${orderedBy}&orderedFor=${orderedFor}`
            if (item.a_status === "Approved Needs Review" || item.status === "Approved Needs Review") {
                url = url + `&docState=anr`
            }
        }
        if (item.enclosureDocID) {
            url += `&enclosureID=${item.enclosureDocID}`
        }
        let newUrl = window.origin + url;
        let authoringinfo = {
            "orignaltext": orignaltext,
            "summarizetext": summarizetext,
            "commandtext": commandtext,
            "commandtype": commandtype,
            "sessionid": sessionid,
            "previousSummarizeText": previousSummarizeText,
            "requestTokenCount": requestTokenCount,
            "responseTokenCount": responseTokenCount,
            "multiCommands": multiCommands,
            "answersList": answersList,
        }
        const ts = new trackingService();
        ts.indexTrackingData(localStorage.getItem('userdisplayName'),
            localStorage.getItem('wwid'),
            localStorage.getItem('username'),
            localStorage.getItem('function'),
            localStorage.getItem('region'),
            localStorage.getItem('userproducts'),
            localStorage.getItem('doctypepref'),
            localStorage.getItem('sid'),
            localStorage.getItem('deviceType'),
            localStorage.getItem('deviceOS'),
            localStorage.getItem('browserName'),
            localStorage.getItem('browserVersion'),
            localStorage.getItem('jnjDepartment'),
            localStorage.getItem('companyname'),
            `Conducted Authoring`,
            '',
            window.location.href, '', '', item.docTitle, docVersion, docNumber, item.searchID, newUrl, item.tempJNJDrugs, item.docType, 'Conducted Authoring', '', '', item.domain, '', '', item.createdDate, orderedBy, orderedFor,
            {}, authoringinfo);
    }
    numberOfWords = (str) => {
        if (str) {
            const words = str.match(/\S+/g);
            if (words && words.length !== 0) {
                return words.length;
            }
            else {
                return 0;
            }
        } else {
            return 0;
        }
    }
    onSummeryClick = async (e) => {
        let { orignalText, commandText, chatConversation, commandType, sessionID, summarizeText, isConfirm, isClearAllFields, previousOrignalText } = this.state;
        this.setState({ savingCommandText: commandText })
        let { sourceItem } = this.props;
        if (orignalText) {
            let _commandType = commandType;
            let tokenLength = this.numberOfWords(orignalText);
            if (_commandType === 'UserInstructions' && !commandText) {
                this.errorAlertMessage("User Command Required, Please enter a command.");
            } else if (_commandType === 'Translate' && !commandText) {
                this.errorAlertMessage("Please enter language to the command box.");
            } else if (!isConfirm && ShowWordCountForCommandType.includes(commandType) && tokenLength > WordsMaxLength) {
                this.showConfirmMessage(`The input text is more than ${WordsMaxLength} words. Please use a shorter text by hitting cancel button and updating the text or the text can be ${_commandType} upto max limit by clicking on OK button`);
            }
            else if (_commandType === 'UserInstructions' && commandText && commandText.trim() && commandText.trim().split(/\n\n/g).length > 5) {
                this.errorAlertMessage("Kindly select maximum five commands to proceed.");
            } else {
                if (_commandType === 'UserInstructions' && (window.location.origin === 'https://predev-ims.jnj.com' || window.location.origin === 'http://localhost:3000')) {
                    let commandTexts = commandText.trim().split(/\n\n/g);
                    this.setState({ chatConversation: chatConversation, isProcessing: true }, () => {
                        this.scrollToBottom();
                    });

                    let _orignalText = orignalText.replace(/[\r\n]/g, " ").trim();
                    if (ShowWordCountForCommandType.includes(commandType)) {
                        _orignalText = orignalText.replace(/[\r\n]/g, " ").trim().split(' ').filter((x, i) => i < WordsMaxLength).join(' ')
                    }
                    let config = {}
                    var url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_NLP_SUMMARIZE}summarize_api2`
                    if (window.location.origin === 'https://predev-ims.jnj.com') {
                        url = `${window.location.origin}${process.env.REACT_APP_IMS_API_NLP_SUMMARIZE}summarize_api2`
                    }
                    //url = `https://predev-ims.jnj.com${process.env.REACT_APP_IMS_API_NLP_SUMMARIZE}summarize_api2`
                    config = {
                        method: 'POST',
                        url: url,
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: {
                            "orignalText": _orignalText.replace(/[\r\n]/g, " ").trim(),
                            "commandType": commandType,
                            "commandText": '',
                            "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                            "sessionID": sessionID,
                            "wwid": JSON.parse(localStorage.getItem('wwid')),
                            "previousSummarizeText": '',
                            "searchID": "",
                            "isClearAllFields": isClearAllFields,
                            "isNewOrignalText": (previousOrignalText !== orignalText.replace(/[\r\n]/g, " ").trim().trimEnd().trimStart()),
                            "multiCommands": commandTexts
                        }
                    }
                    // this.scrollToBottom();
                    await axios(config).then((response) => {
                        let _summarizeText = "", requestTokenCount = 0, responseTokenCount = 0;
                        if (response.data.answersList && response.data.answersList.length > 0) {
                            if (response.data.requestTokenCount)
                                requestTokenCount = response.data.requestTokenCount;
                            if (response.data.responseTokenCount)
                                responseTokenCount = response.data.responseTokenCount;

                            this.setState({ lastCommandType: _commandType }, () => {

                                commandTexts.map((citem, cindex) => {
                                    let _message = "";
                                    _message = citem ? `${citem}\n${orignalText}` : `${orignalText}`;
                                    _message = _message.trim().replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/\n/g, '<br/>')
                                    chatConversation.push({ from: userQuestion, message: _message, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, IsSucceed: true })
                                    _summarizeText = response.data.answersList[cindex] ? response.data.answersList[cindex].trim() : ""
                                    _summarizeText = _summarizeText.replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/\n/g, '<br/>')
                                    let finalSummery = JSON.parse(JSON.stringify(_summarizeText))
                                    chatConversation.push({ from: summarizeResult, message: finalSummery, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, commandText: citem, orignalText: orignalText, IsSucceed: true })
                                })

                            })
                            // this.indexData(_orignalText.replace(/[\r\n]/g, " ").trim(), "", commandText, commandType, summarizeText, sessionID, requestTokenCount, responseTokenCount, commandTexts, response.data.answersList);
                            this.indexData(sourceItem, _orignalText.replace(/[\r\n]/g, " ").trim(), "", commandText, commandType, "", sessionID, requestTokenCount, responseTokenCount, commandTexts, response.data.answersList);
                        } else {
                            commandTexts.map((citem, cindex) => {
                                let _message = "";
                                _message = citem ? `${citem}\n${orignalText}` : `${orignalText}`;
                                _message = _message.trim().replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/\n/g, '<br/>')
                                chatConversation.push({ from: userQuestion, message: _message, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, IsSucceed: true })
                                chatConversation.push({ from: summarizeResult, message: `We don't know the answer.<br>Kindly try after some time.`, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, commandText: citem, orignalText: orignalText, IsSucceed: false })
                            })

                        }
                        this.setState({
                            chatConversation: chatConversation,
                            summarizeText: _summarizeText
                        });
                    }).catch((error) => {
                        commandTexts.map((citem, cindex) => {
                            let _message = "";
                            _message = citem ? `${citem}\n${orignalText}` : `${orignalText}`;
                            _message = _message.trim().replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/\n/g, '<br/>')
                            chatConversation.push({ from: userQuestion, message: _message, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, IsSucceed: true })
                            chatConversation.push({ from: summarizeResult, message: `Service is unavailable at this time, Kindly try after some time.`, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, commandText: citem, orignalText: orignalText, IsSucceed: false })
                        })
                        this.setState({ chatConversation: chatConversation });
                        console.log(error)
                    }).finally(() => {
                        this.setState({ isProcessing: false, isConfirm: false, isClearAllFields: false, previousOrignalText: orignalText.replace(/[\r\n]/g, " ").trim().trimEnd().trimStart(), firstCall: false }, () => {
                            this.scrollToBottom();
                        });
                    });
                } else {
                    let _message = commandText ? `${commandText}\n${orignalText}` : `${orignalText}`;
                    _message = _message.trim().replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/\n/g, '<br/>')
                    chatConversation.push({ from: userQuestion, message: _message, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType })
                    this.setState({ chatConversation: chatConversation, isProcessing: true }, () => {
                        this.scrollToBottom();
                    });
                    var url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_NLP_SUMMARIZE}summarize_api2`
                    if (window.location.origin === 'https://predev-ims.jnj.com') {
                        url = `${window.location.origin}${process.env.REACT_APP_IMS_API_NLP_SUMMARIZE}summarize_api2`
                    }
                    //url = `https://predev-ims.jnj.com${process.env.REACT_APP_IMS_API_NLP_SUMMARIZE}summarize_api2`
                    let _orignalText = orignalText.replace(/[\r\n]/g, " ").trim();
                    if (ShowWordCountForCommandType.includes(commandType)) {
                        _orignalText = orignalText.replace(/[\r\n]/g, " ").trim().split(' ').filter((x, i) => i < WordsMaxLength).join(' ')
                    }
                    let config = {
                        method: 'POST',
                        url: url,
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: {
                            "orignalText": _orignalText.replace(/[\r\n]/g, " ").trim(),
                            "commandType": commandType,
                            "commandText": commandText,
                            "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                            "sessionID": sessionID,
                            "wwid": JSON.parse(localStorage.getItem('wwid')),
                            "previousSummarizeText": summarizeText.replace(/[\r\n]/g, " ").trim(),
                            "searchID": sourceItem.searchID,
                            "isClearAllFields": isClearAllFields,
                            "isNewOrignalText": (previousOrignalText !== orignalText.replace(/[\r\n]/g, " ").trim().trimEnd().trimStart()),
                            "multiCommands": [],
                        }
                    }
                    // this.scrollToBottom();
                    await axios(config).then((response) => {
                        let _summarizeText = "", requestTokenCount = 0, responseTokenCount = 0;
                        if (_commandType === "CreateTable") {
                            if (response.data.summarizeTable && response.data.summarizeTable.length > 0) {
                                this.setState({ lastCommandType: _commandType }, () => {
                                    let summarizeTables = response.data.summarizeTable;
                                    if (response.data.requestTokenCount)
                                        requestTokenCount = response.data.requestTokenCount;
                                    if (response.data.responseTokenCount)
                                        responseTokenCount = response.data.responseTokenCount;

                                    if (summarizeTables.length === 1) {
                                        _summarizeText = summarizeTables[0];
                                        chatConversation.push({ from: summarizeResult, message: summarizeTables[0], messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, orignalText: orignalText, IsSucceed: true });
                                        this.indexData(sourceItem, _orignalText.replace(/[\r\n]/g, " ").trim(), _summarizeText, commandText, commandType, summarizeText, sessionID, requestTokenCount, responseTokenCount, [], []);
                                    }
                                })
                            } else {
                                chatConversation.push({ from: summarizeResult, message: `We don't know the answer.<br>Kindly try after some time.`, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, orignalText: orignalText, IsSucceed: false })
                            }
                        } else {
                            if (response.data.summarizeText) {
                                _summarizeText = response.data.summarizeText ? response.data.summarizeText.trim() : ""
                                if (response.data.requestTokenCount)
                                    requestTokenCount = response.data.requestTokenCount;
                                if (response.data.responseTokenCount)
                                    responseTokenCount = response.data.responseTokenCount;

                                this.setState({ lastCommandType: _commandType }, () => {
                                    _summarizeText = _summarizeText.replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/\n/g, '<br/>')
                                    chatConversation.push({ from: summarizeResult, message: _summarizeText, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, orignalText: orignalText, IsSucceed: true })
                                })
                                this.indexData(sourceItem, _orignalText.replace(/[\r\n]/g, " ").trim(), _summarizeText, commandText, commandType, summarizeText, sessionID, requestTokenCount, responseTokenCount, [], []);
                            } else {
                                chatConversation.push({ from: summarizeResult, message: `We don't know the answer.<br>Kindly try after some time.`, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, orignalText: orignalText, IsSucceed: false })
                            }
                        }
                        this.setState({
                            chatConversation: chatConversation,
                            summarizeText: (_commandType === "Summarize" || _commandType === "UserInstructions" || _commandType === "Translate" || _commandType === "QnA") ? (response.data.summarizeText ? response.data.summarizeText.trim() : "") : _summarizeText
                        });
                    }).catch((error) => {
                        chatConversation.push({ from: summarizeResult, message: `Service is unavailable at this time, Kindly try after some time.`, messageTimeInText: `${moment().format("MMM DD, hh:mm a")}`, messageTime: { moment }, documentInfo: {}, commandType: _commandType, orignalText: orignalText, IsSucceed: false })
                        this.setState({ chatConversation: chatConversation });
                        console.log(error)
                    }).finally(() => {
                        this.setState({ isProcessing: false, isConfirm: false, isClearAllFields: false, previousOrignalText: orignalText.replace(/[\r\n]/g, " ").trim().trimEnd().trimStart() }, () => {
                            this.scrollToBottom();
                        });
                    });
                }
            }
        }
    }
    handleComment = (e) => {
        this.setState({ commandText: e.target.value })
        this.props.handleComment(e.target.value);
    }
    handleAddToCommandText = (commandText) => {
        this.setState({ commandText: commandText, showCommandList: false })
        this.props.handleComment(commandText);
    }
    onResetClick = () => {
        this.setState({
            orignalText: '',
            summarizeText: '',
            commandText: '',
            commandType: 'UserInstructions',
            chatConversation: [],
            notesText: '',
            selectAllText: false,
            isClearAllFields: true,
            previousOrignalText: ""
        });
        this.props.handleComment('');
        this.props.handleSummery('');
        this.props.handleSessionID();
    }
    onNotebookClick = () => {
        let { summarizeText, lastCommandType, orignalText } = this.state;
        if (summarizeText) {
            this.setState({
                savingSummarizeText: summarizeText,
                savingCommandType: lastCommandType,
                savingOrignalText: orignalText,
                showNotebookList: true
            });
        }
    }
    onExpandAllFieldsClick = () => {
        this.setState({ expandAllFields: true });
    }
    handleOnClickcommandTextExpand = () => {
        this.setState({ expandCommand: true })
    }
    handleCommandTextExpand = (commandText) => {
        this.setState({ commandText: commandText, expandCommand: false })
        this.props.handleComment(commandText);
    }
    handleCloseNotebookList = () => {
        this.setState({ showNotebookList: false });
    }
    handleCloseCommandList = () => {
        this.setState({
            showCommandList: false,
            isUserCommand: true,
            isTemplateCommand: false,
            isFromGlobalFeaturesMenu: false,
        });
    }
    customNodeCreator = (character, textNode) => {
        this.scrollToBottom();
        return document.createTextNode(character);
    }
    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
    handleChangeCommandType = (e) => {
        try {
            this.setState(
                {
                    commandType: e.target.value
                });
        } catch (error) {
        }
    };
    handleNotes = (e) => {
        this.setState({ notesText: e.target.value })
    }
    handleOnClickSourceTextExpand = () => {
        this.setState({ expandSourceText: true })
    }
    handleOnClicNotesExpand = () => {
        this.setState({ expandNotes: true })
    }
    handleSourceTextExpand = (orignalText) => {
        this.setState({ orignalText: orignalText, expandSourceText: false })
        this.props.handleSummery(orignalText);
    }
    handleAllFieldsContentExpand = (orignalText, notesText) => {
        this.setState({ orignalText: orignalText, notesText: notesText, expandAllFields: false })
        this.props.handleSummery(orignalText);
    }
    handleNotesExpand = (notesText) => {
        this.setState({ notesText: notesText, expandNotes: false })
    }

    handleAddToNotebook = (summarizeText, commandType, orignalText) => {
        if (commandType === "Summarize" || commandType === "UserInstructions" || commandType === "Translate" || commandType === "QnA") {
            summarizeText = summarizeText.replace(/<br\/>/g, '\r\n').replace(/&gt;/g, '>').replace(/&lt;/g, '<');
        }

        this.setState({
            savingSummarizeText: summarizeText,
            savingCommandType: commandType,
            savingOrignalText: orignalText,
            showNotebookList: true
        })
    }
    handleClickOnMyCommand = () => {
        this.setState({
            isUserCommand: true,
            isTemplateCommand: false,
            showCommandList: true,
            isFromGlobalFeaturesMenu: true
        })

    }
    handleClickOnTemplateCommand = () => {
        this.setState({
            isUserCommand: false,
            isTemplateCommand: true,
            showCommandList: true,
            isFromGlobalFeaturesMenu: true
        })

    }
    handleAddToCommand = () => {
        let { commandText } = this.state;
        if (commandText) {
            this.setState({
                showCommandList: true,
                isUserCommand: true,
                isTemplateCommand: false,
                isFromGlobalFeaturesMenu: false
            })
        }
    }
    handleAddToNotes = (notesText) => {
        notesText = notesText.replace(/<br\/>/g, '\r\n');
        this.setState({ notesText: notesText })
    }
    snippetLongString = (snippet) => {
        if (snippet.length > 150) {
            let text = "";
            let spanIndex = snippet.indexOf('<span class=');
            if (spanIndex !== -1) {
                text = snippet.substring(spanIndex, (spanIndex + 150))//cuts to spanIndex+150
                let last = text.lastIndexOf(" ")//gets last space (to avoid cutting the middle of a word)
                text = text.substring(0, last)//cuts from last space (to avoid cutting the middle of a word)
            } else {
                text = snippet.substring(0, 150)//cuts to 70
                let last = text.lastIndexOf(" ")//gets last space (to avoid cutting the middle of a word)
                text = text.substring(0, last)//cuts from last space (to avoid cutting the middle of a word)
            }
            //text = text + ` ...`//adds (...) at the end to show that it's cut
            return text
        }
        return snippet;
    }
    renderChatConversation = () => {
        let { chatConversation, isProcessing, isShowMore } = this.state;
        //onClick={() => this.handleCollapseDTDropdown()}
        return (
            <>
                <div className="chat-body">
                    {chatConversation.map((conversation, conversationIndex) => {
                        if (conversation.from === userQuestion) {
                            return (
                                <AuthoringUserSummery key={`my-message-${conversationIndex}`} conversation={conversation} />
                            )

                        } else {
                            return (
                                <div className="message info" key={`summery-message-${conversationIndex}`}>
                                    {/* <img alt='avatar' className="img-circle medium-image" src="https://bootdey.com/img/Content/avatar/avatar1.png" /> */}
                                    <img alt='avatar' className="img-circle medium-image" src={robotHeadChatgpt} />
                                    <div className="message-body">
                                        <div className="message-text" dangerouslySetInnerHTML={{ __html: sanitize(conversation.message) }} />
                                        {/* <div className="message-text">
                                            <Typewriter
                                                onInit={(typewriter) => {
                                                    typewriter.typeString(conversation.message)
                                                        .start();
                                                }}
                                                options={{
                                                    onCreateTextNode: this.customNodeCreator,
                                                    delay: 40
                                                }}
                                            />
                                        </div> */}
                                        {/* <hr /> */}
                                        <div className="message-info message-info-add-to-notes">
                                            <h5>{conversation.messageTimeInText} </h5>
                                            {/* {(conversation.IsSucceed && conversation.commandType !== 'CreateTable') && <span title="Add to Notes" className="add-to-notes-action-text" onClick={() => this.handleAddToNotes(conversation.message)}>Add to Notes</span>} */}
                                            {(conversation.IsSucceed) && <span title="Add to Notebook" className="add-to-notebook-action-text" onClick={() => this.handleAddToNotebook(conversation.message, conversation.commandType, conversation.orignalText)}>Add to Notebook</span>}
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            )
                        }

                    })
                    }

                    <div style={{ width: "100%" }}
                        ref={(el) => { this.messagesEnd = el; }}>
                        {isProcessing && <div style={{ textAlign: "center", marginBottom: "10px" }}>
                            <img src={imsLoader} alt="Loading Filters" style={{ width: "10%" }} />
                        </div>}
                    </div>
                </div>

            </>
        )
    }

    errorAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#de1327",
            alertMessageShowModal: true
        })
    }
    closeAlertMessage = () => {
        this.setState({
            alertMessageContent: "",
            alertMessageModelColor: "",
            alertMessageShowModal: false
        })
    }
    onAllTextClick = async () => {
        let { selectAllText } = this.state;
        let resp = [];
        if (!selectAllText) {
            this.props.handleIsAddActionButton();
            this.setState({
                selectAllText: true,
                isProcessingSelectAllText: true
            })
            var url = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}PreviewService/Content?strID=${this.props.searchID}`;
            let service = new Services();
            await axios.get(url).then((response) => {
                resp = response.data;
                this.setState({ orignalText: resp.PagesContent })
                this.props.handleSummery(resp.PagesContent);
            }).catch((error) => {
                service.logService(`API call error for ${url}: ${error}`, 'error')
            }).finally(() => {
                this.setState({ isProcessingSelectAllText: false })
            })
        }
        else {
            this.setState({
                selectAllText: false,
                orignalText: ""
            })
            this.props.handleSummery("");
        }
    }
    renderAllText = () => {
        let { isProcessingSelectAllText, selectAllText, orignalText } = this.state;
        if (selectAllText && isProcessingSelectAllText) {
            return (
                <div style={{ textAlign: "center", marginBottom: "10px" }}>
                    <img src={imsLoader} alt="Loading Filters" style={{ width: "10%" }} />
                </div>
            )
        }
        else {
            return (
                <textarea className="txt-authoring-summarize" ref={(input) => { this.sourceTextInput = input; }} placeholder="Enter the source text here." name="exportText" value={orignalText} onChange={(e) => this.handleSummery(e)} />
            )
        }
    }

    render() {
        const { orignalText, summarizeText, showNotebookList, commandText, commandType,
            lastCommandType, notesText, expandNotes, expandSourceText, expandAllFields, showCommandList, isFromGlobalFeaturesMenu, isTemplateCommand, isUserCommand, sessionID, expandCommand } = this.state;
        const { savingSummarizeText, savingCommandType, savingOrignalText, selectAllText } = this.state;
        const { alertMessageModelColor, alertMessageContent, alertMessageShowModal, isProcessing } = this.state;
        const { savingCommandText } = this.state;
        const { confirmMessageContent, confirmMessageShowModal } = this.state;
        let totalWords = this.numberOfWords(orignalText);
        return (<div className="authoring-wrapper">
            <div className="authoring-summery-wrapper">
                <div className="authoring-summery-text-to-edit-wrapper">
                    <div className="authoring-text-to-edit-label">
                        <span>Source Text</span>
                        <div className="authoring-all-text-select">
                            <input type="checkbox" title="All Text File" className="all-text-checkbox" onChange={e => {
                                this.onAllTextClick()
                            }} checked={selectAllText} /><span>Use Full Document</span>
                        </div>
                        {ShowWordCountForCommandType.includes(commandType) && <div className={totalWords < WordsMaxLength ? "summarize-count" : "summarize-count summarize-count-color"}>
                            <span className="current">{totalWords}</span>
                            <span className="maximum">/{WordsMaxLength} Words &nbsp;</span>
                        </div>
                        }
                        <button title="Source Text Expand" class="btn-like-expandAll" onClick={e => {
                            this.handleOnClickSourceTextExpand()
                        }}><img src={expandAllBlueIcon} alt="Expand" />
                        </button>
                    </div>
                    <div className="authoring-text-to-edit-panel">
                        {this.renderAllText()}
                    </div>
                </div>
                <div className="authoring-summery-comment-send-save-notebook-wrapper">
                    <div className="authoring-command-label">
                        <span>Command</span>
                        <button title="Command Expand" class="btn-like-expandAll" onClick={e => {
                            this.handleOnClickcommandTextExpand()
                        }}><img src={expandAllBlueIcon} alt="Expand" />
                        </button>
                    </div>
                    <div className="authoring-command-wrapper-panel">
                        <div className="authoring-command-dropdown-panel">
                            <select
                                className="authoring-command-dropdown"
                                onChange={this.handleChangeCommandType}
                                value={commandType}>
                                <option value="UserInstructions">User Instructions​</option>
                                <option value="CreateTable">Create Table​</option>
                                <option value="Summarize">Summarize</option>
                                <option value="Translate">Translate</option>
                                {/* <option value="QnA">QnA</option> */}
                            </select>
                        </div>
                        <div className="authoring-summery-comment-send">
                            <div className="authoring-comment-panel">
                                <textarea name="commandText" value={commandText} placeholder="Type Additional Commands here." onChange={(e) => this.handleComment(e)} />
                                <span title="Save Command" className="add-to-command-action-text add-to-command-action-save" onClick={() => this.handleAddToCommand()}>Save Command</span>
                            </div>
                            <div className="authoring-send-panel">
                                <button className="btnNotebook" type="button" onClick={(e) => this.onSummeryClick()}  >Submit</button>
                                <span title="My Commands" className="add-to-command-user-template" onClick={() => this.handleClickOnMyCommand()}>My Commands</span>
                                <span title="Template Commands" className="add-to-command-user-template" onClick={() => this.handleClickOnTemplateCommand()}>Template Commands</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="authoring-summery-result-wrapper">
                    <div className="authoring-result-label">
                        <span>Results</span>
                    </div>
                    <div className="authoring-result-panel">
                        {this.renderChatConversation()}
                    </div>
                </div>
                <div className="authoring-summery-note-wrapper">
                    <div className="authoring-note-label">
                        <span>Notes</span>
                        <button title="Notes Expand" class="btn-like-expandAll" onClick={e => {
                            this.handleOnClicNotesExpand()
                        }}><img src={expandAllBlueIcon} alt="Expand" />
                        </button>
                    </div>
                    <div className="authoring-summery-note-send">
                        <textarea name="notesText" placeholder="Enter your custom notes here." value={notesText} onChange={(e) => this.handleNotes(e)} />
                    </div>
                </div>
                <div className="authoring-summery-comment-save-notebook">
                    <div className="authoring-summery-btn-restart">
                        <button disabled={isProcessing} type="button" className="btnReset" onClick={(e) => this.onResetClick()} title="Clear All Fields">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 11.724 13.727">
                                <g id="ic_forward_30_24px" transform="translate(0.25 0.57)">
                                    <path id="ic_forward_30_24px-2" data-name="ic_forward_30_24px" d="M4,8.744a5.417,5.417,0,0,0,5.612,5.163,5.417,5.417,0,0,0,5.612-5.163h-1.4a4.063,4.063,0,0,1-4.209,3.872A4.063,4.063,0,0,1,5.4,8.744,4.063,4.063,0,0,1,9.612,4.872V7.454l3.507-3.227L9.612,1V3.581A5.417,5.417,0,0,0,4,8.744Z" transform="translate(-4 -1)" fill="#ffffff" stroke="#0074B0" stroke-width="0.5" />
                                </g>
                            </svg>
                            <span>Clear All Fields</span>
                        </button>
                    </div>
                    <div className="ai-authoring-summery-btn-expand-notebook">
                        <button className="btnExpandAllFields" type="button" onClick={(e) => this.onExpandAllFieldsClick()}>Expand All Fields</button>
                        <button className="btnNotebook" type="button" onClick={(e) => this.onNotebookClick()}>Save to Notebook</button>
                    </div>
                </div>
            </div>
            {showNotebookList &&
                <NotebookListPopUp showModal={showNotebookList} sourceItem={this.props.sourceItem} summeryText={savingOrignalText}
                    summarizeText={savingSummarizeText} handleClose={this.handleCloseNotebookList} sessionID={sessionID}
                    notesText={notesText} width={this.props.customWidth} commandType={savingCommandType} commandText={savingCommandText} />
            }
            {showCommandList && <CommandListPopUp showModal={showCommandList} handleClose={this.handleCloseCommandList}
                width={this.props.customWidth} commandText={commandText} handleAddToCommandText={this.handleAddToCommandText}
                isUserCommand={isUserCommand} isTemplateCommand={isTemplateCommand} isFromGlobalFeaturesMenu={isFromGlobalFeaturesMenu}
            />}
            {expandNotes && <ExpandContentPopUpModal placeholder='Enter your custom notes here.' content={notesText} showModal={expandNotes} handleSaveContent={this.handleNotesExpand} />}
            {expandSourceText && <ExpandContentPopUpModal placeholder='Enter the source text here.' content={orignalText} showModal={expandSourceText} handleSaveContent={this.handleSourceTextExpand} />}
            {expandAllFields && <ExpandAllFieldsContentPopUpModal sourceText={orignalText} notesText={notesText} resultsText={summarizeText} showModal={expandAllFields} handleSaveContent={this.handleAllFieldsContentExpand} lastCommandType={lastCommandType} />}
            {expandCommand && <ExpandContentPopUpModal placeholder='Enter your command here.' content={commandText} showModal={expandCommand} handleSaveContent={this.handleCommandTextExpand} />}
            {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
            {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
        </div>)
    }
}
export default AuthoringSummery;