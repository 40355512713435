import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import GenAISearchHome from "../../components/GenAIAnswering2.0/GenAISearchHome";

class GenAISearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
        }
        if (((props && !props.match.params)|| (props && props.match.params && !props.match.params.id)) && (props && props.location && !props.location.state) ||
            (props && props.location && props.location.state && !props.location.state.sessionID) ||
            (props && props.location && props.location.state && !props.location.state.newQuestion) ) {
            props.history.push('/genAIAnswering');
        }
    }
    componentDidMount() {
        let userExists = localStorage.getItem('userExists');
        if (!userExists) {
            console.log('Access Denied');
            this.props.history.push('/');
        }
        let _function = JSON.parse(localStorage.getItem('function'));
        if (_function !== "Medical") {
            console.log('Access Denied');
            this.props.history.push('/');
        }
        else {
            window.addEventListener("resize", this.updateDimensions);
        }
    }
    updateDimensions = () => {
        try {
            this.setState(
                { width: window.innerWidth, height: window.innerHeight });
        } catch (error) {
            this.setState({ error: error });
        }
    };

    render() {
        if (this.state.width >= 600 && this.state.width <= 1000) {
            return (<GenAISearchHome customWidth={this.state.width} customHeight={this.state.height} />)
        } else if (this.state.width > 1000) {
            return (<GenAISearchHome customWidth={this.state.width} customHeight={this.state.height} />)
        }
    }
}
export default withRouter(GenAISearch);