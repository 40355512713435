import { Component } from "react";
import TTSHome from "../../components/TTS/TTSHome";


class TTSManageFiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
        }
    }
    componentDidMount() {
        let userExists = localStorage.getItem('userExists');
        if (!userExists) {
            console.log('Access Denied');
            this.props.history.push('/');
        }
    }
    updateDimensions = () => {
        try {
            this.setState(
                { width: window.innerWidth, height: window.innerHeight });
        } catch (error) {
            this.setState({ error: error });
        }
    };

    render() {
        if (this.state.width >= 600 && this.state.width <= 1000) {
            return (<TTSHome customWidth={this.state.width} customHeight={this.state.height} />)
        } else if (this.state.width > 1000) {
            return (<TTSHome customWidth={this.state.width} customHeight={this.state.height} />)
        }
        else{
            return <></>
        }
    }
}
export default TTSManageFiles    