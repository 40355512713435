import { Component } from "react";
import { globalCollapseAllIcon, globalSelectAllIcon, globalViewSelectedIcon, globalShareIcon, globalSaveExportIcon, globalPrintIcon, globalBookmarkIcon, globalDownloadIcon, globalNotificationIcon, globalReadingListIcon, globalExpandAllIcon, globalDeSelectAllIcon, pubMedIcon, pubMedNIH, notebook, iconAuthoring } from "../../assets";
import GlobalSearchFeaturePopUpModal from "../PopUp/GlobalSearchFeaturesPopUpModal";
import ReadingListPopup from "../PopUp/ReadingListPopup";
import NotebookListPopUp from "../PopUp/NotebookListPopUp";
import NotifyMePopUp from "../../components/PopUp/NotifyMePopUp";
import BookmarkPopUp from "../PopUp/BookmarkpopUp";
import MIAsRefGlobalSearchFeaturePopUpModal from "../PopUp/MIAsRefGlobalSearchFeaturesPopUpModal";
import { Modal } from "react-bootstrap";
import AuthoringGPT from '../Authoring/AuthoringGPT';
import { Authoring } from './../../utils/utilityServices'
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import trackingService from "../../services/trackingService";

class GlobalFeatures extends Component {
    constructor(props) {
        super(props);
        this.emailPopUp = false;
        this.state = {
            collapsed: false,
            isChecked: false,
            showGSFModal: false,
            showSavePopUp: false,
            showEmailPopUp: false,
            showReadingListPop: false,
            showPersonalAlerts: false,
            showBookmarkPopUp: false,
            showPrintPopUp: false,
            showDownloadPopUp: false,
            showNotebookPop: false,
            showAuthoringGPTPop: false,
        }
    }
    componentDidMount() {
        this.setState({
            collapsed: this.props.globalCollapseAll,
            isChecked: this.props.globalSelectAll
        })
    }
    onClickViewSelected = () => {
        this.props.handelViewSelected()
    }
    onExpandCollapseAll = () => {
        this.setState({
            collapsed: !this.state.collapsed
        })
        this.props.handleCollapseAllGlobal();
    }
    onSelectAll = () => {
        this.setState({
            isChecked: !this.state.isChecked
        })
        this.props.handleSelectAll()
    }
    handleSaveExport = () => {
        this.setState({
            showGSFModal: true,
            showSavePopUp: true,
            showEmailPopUp: false,
            showPrintPopUp: false,
            showDownloadPopUp: false
        })
    }
    handlePopUpClose = (status) => {
        this.setState({
            showGSFModal: false,
            showSavePopUp: false,
            showEmailPopUp: false,
            showPrintPopUp: false,
            showDownloadPopUp: false
        })
    }
    handleShareEmail = () => {
        this.setState({
            showGSFModal: true,
            showSavePopUp: false,
            showEmailPopUp: true,
            showPrintPopUp: false,
            showDownloadPopUp: false
        })
    }
    handlePrint = () => {
        this.setState({
            showGSFModal: true,
            showSavePopUp: false,
            showEmailPopUp: false,
            showPrintPopUp: true,
            showDownloadPopUp: false
        })
    }
    handleDownload = () => {
        this.setState({
            showGSFModal: true,
            showSavePopUp: false,
            showEmailPopUp: false,
            showPrintPopUp: false,
            showDownloadPopUp: true
        })
    }
    onClickViewReadingList = () => {
        this.setState({
            showReadingListPop: true
        })
        if (this.props.customWidth < 1000) {
            this.props.handleReadingList(true);
        }
    }
    onClickViewNotebook = () => {
        this.setState({
            showNotebookPop: true
        })
    }

    handleCloseReadingList = (status) => {
        this.setState({
            showReadingListPop: status
        })
    }
    handleCloseNotebookList = (status) => {
        this.setState({
            showNotebookPop: false
        })
    }
    handleCloseAuthoringGPT = (status) => {
        this.setState({
            showAuthoringGPTPop: false
        })
    }
    handleShareEmailMob = () => {
        this.props.handleEmailPopUp(true)
    }
    handleEmailPopUpClose = () => {
        this.emailPopUp = false
    }
    handleNotifyMeMob = () => {
        this.props.handleNotifyMePopUp(true)
    }
    componentDidUpdate() {
        //console.log(this.props.globalSelectAll)
        if (this.props.globalCollapseAll !== this.state.collapsed) {
            this.setState({
                collapsed: this.props.globalCollapseAll
            })
        }
        if (this.props.globalSelectAll !== this.state.isChecked) {
            this.setState({
                isChecked: this.props.globalSelectAll
            })
        }
    }
    onClickNotifyMe = () => {
        this.setState({
            showPersonalAlerts: true
        })
    }
    onClickBookmark = () => {
        this.setState({
            showBookmarkPopUp: true
        })
        if (this.props.customWidth < 1000) {
            this.props.handleBookmark(true);
        }
    }
    renderAuthoringGPTModelPopup = () => {
        let { showAuthoringGPTPop } = this.state;
        return (<Modal
            show={showAuthoringGPTPop}
            size="xl"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="authoring-popup-transition"
            className="z-index-preview"
            backdropClassName="z-index-preview-backdrop"
            scrollable={false}>
            <Modal.Body>
                <AuthoringGPT showPreview={showAuthoringGPTPop} customWidth={this.props.customWidth} handleClose={this.handleCloseAuthoringGPT} />
            </Modal.Body>
        </Modal>)
    }
    indexData = (shareoption) => {
        let newUrl = window.location.href;
        const ts = new trackingService();
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), `${shareoption}`, '', window.location.href, "", "", "", "", "", "", newUrl, "", "", `${shareoption}`, "", "", "", "", "", "");
    }
    render() {
        let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'));
        if (this.props.customWidth < 1000) {
            return <div className='actionItems-dropdownContainer'>
                <table className='actionItems-table'>
                    <tbody>
                        <tr className='actionItem-rows'>
                            <td className={window.location.pathname !== '/pubMedResults' ? 'actionItem-data' : 'actionItem-data actionItem-data-pubmed'} onClick={this.onExpandCollapseAll}>
                                {this.state.collapsed ?
                                    <div className="global-feature-tile-img-hov" >
                                        <img src={globalExpandAllIcon} alt="" />
                                    </div>
                                    :
                                    <div className="global-feature-tile-img-tab-mob" id="expandCollapse" >
                                        <img src={globalCollapseAllIcon} alt="" />
                                    </div>
                                }
                                {/* <img src={collapseAll} alt="collapseAll" className='actionItems-icons' /> */}
                                {/* <br /> */}
                                <span className='actionItems-text'>{this.state.collapsed ? 'Expand All' : 'Collapse All'}</span>
                            </td>
                            <td className={window.location.pathname !== '/pubMedResults' ? 'actionItem-data' : 'actionItem-data actionItem-data-pubmed'} onClick={this.onSelectAll}>
                                {this.state.isChecked ?
                                    <div className="global-feature-tile-img-hov" >
                                        <img src={globalDeSelectAllIcon} alt="" />
                                    </div>
                                    :
                                    <div className="global-feature-tile-img-tab-mob">
                                        <img src={globalSelectAllIcon} alt="" />
                                    </div>}
                                {/* <img src={selectAll} alt="selectAll" className='actionItems-icons' /> */}
                                {/* <br /> */}
                                <span className='actionItems-text'>{this.state.isChecked ? 'De-Select All' : 'Select All'}</span>
                            </td>
                            {window.location.pathname !== '/pubMedResults' ?
                                <td className='actionItem-data' onClick={this.onClickViewSelected}>
                                    <div className="global-feature-tile-img-tab-mob">
                                        <img src={globalViewSelectedIcon} alt="" />
                                    </div>
                                    {/* <img src={viewSelected} alt="viewSelected" className='actionItems-icons' /> */}
                                    {/* <br /> */}
                                    <span className='actionItems-text'>View Selected</span>
                                </td> : <td className='actionItem-data actionItem-data-pubmed' onClick={this.handleShareEmailMob}>
                                    <div className="global-feature-tile-img-tab-mob">
                                        <img src={globalShareIcon} alt="" />
                                    </div>
                                    {/* <img src={share} alt="share" className='actionItems-icons' /> */}
                                    {/* <br /> */}
                                    <span className='actionItems-text'>Share</span>
                                </td>
                            }
                        </tr>
                        {window.location.pathname !== '/pubMedResults' &&
                            <tr className='actionItem-rows'>

                                <td className='actionItem-data' onClick={this.handleShareEmailMob}>
                                    <div className="global-feature-tile-img-tab-mob">
                                        <img src={globalShareIcon} alt="" />
                                    </div>
                                    {/* <img src={share} alt="share" className='actionItems-icons' /> */}
                                    {/* <br /> */}
                                    <span className='actionItems-text'>Share</span>
                                </td>
                                {/* <td className='actionItem-data'>
                                <img src={print} alt="print" className='actionItems-icons' />
                                <br />
                                <span className='actionItems-text'>Print</span>
                            </td> */}
                                {window.location.pathname !== '/ReferencesUsed' && <td className='actionItem-data' onClick={this.onClickViewReadingList}>
                                    <div className="global-feature-tile-img-tab-mob">
                                        <img src={globalReadingListIcon} alt="" />
                                    </div>
                                    {/* <img src={readingList} alt="readingList" className='actionItems-icons' /> */}
                                    {/* <br /> */}
                                    <span className='actionItems-text'>Reading List</span>
                                </td>}

                                {(window.location.pathname !== '/WhatsNewForYou' && window.location.pathname !== '/WhatOthersAreViewing' && window.location.pathname !== '/pubMedResults' && window.location.pathname !== '/ReferencesUsed') &&
                                    <td className='actionItem-data' onClick={this.onClickBookmark}>
                                        <div className="global-feature-tile-img-tab-mob">
                                            <img src={globalBookmarkIcon} alt="" />
                                        </div>
                                        {/* <img src={bookmark} alt="bookmark" className='actionItems-icons' /> */}
                                        {/* <br /> */}
                                        <span className='actionItems-text'>Bookmark</span>
                                    </td>
                                }
                            </tr>
                        }
                        {(window.location.pathname !== '/pubMedResults' && window.location.pathname !== '/ReferencesUsed') && <tr className='actionItem-rows'>
                            {(window.location.pathname !== '/WhatsNewForYou' && window.location.pathname !== '/WhatOthersAreViewing' && window.location.pathname !== '/pubMedResults' && window.location.pathname !== '/viewReadingList') &&
                                <td className='actionItem-data' onClick={this.handleNotifyMeMob}>
                                    <div className="global-feature-tile-img-tab-mob">
                                        <img src={globalNotificationIcon} alt="" />
                                    </div>
                                    {/* <img src={notifyMe} alt="notifyMe" className='actionItems-icons' /> */}
                                    {/* <br /> */}
                                    <span className='actionItems-text'>Alert</span>
                                </td>
                            }
                            {/* <td className='actionItem-data' onClick={this.onClickViewReadingList}>
                                <div className="global-feature-tile-img-tab-mob">
                                    <img src={globalReadingListIcon} alt="" />
                                </div>
                                <span className='actionItems-text'>Reading List</span>
                            </td> */}
                            {/* {window.location.pathname === '/nlpresults' ?
                                <td className='actionItem-data' onClick={this.onClickViewNotebook}>
                                    <div className="global-feature-tile-img-tab-mob">
                                        <img src={notebook} alt="" />
                                    </div>
                                    <span className='actionItems-text'>Notebook</span>
                                </td> : <td className='actionItem-data'></td>} */}


                        </tr>}
                    </tbody>
                </table>
                {
                    this.state.showReadingListPop &&
                    <ReadingListPopup showModal={this.state.showReadingListPop} handleClose={this.handleCloseReadingList} width={this.props.customWidth} selectedResults={this.props.selectedResults} />
                }
                {this.state.showNotebookPop &&
                    <NotebookListPopUp showModal={this.state.showNotebookPop} handleClose={this.handleCloseNotebookList} width={this.props.customWidth} isFromGlobalFeaturesMenu={true} />
                }
                {/* {console.log({this.state.showGSFModal)} */}
                {/* {this.emailPopUp && */}
                {window.location.pathname !== '/ReferencesUsed' ? this.emailPopUp &&
                    <GlobalSearchFeaturePopUpModal showModal={this.emailPopUp} showEmailModal={true} handleClose={this.handleEmailPopUpClose} selectedResults={this.props.selectedResults} customWidth={this.props.customWidth} customHeight={this.props.customHeight} />
                    : this.emailPopUp &&
                    <GlobalSearchFeaturePopUpModal showModal={this.emailPopUp} showEmailModal={true} handleClose={this.handleEmailPopUpClose} selectedResults={this.props.selectedResults} customWidth={this.props.customWidth} customHeight={this.props.customHeight} />
                }
                {/* {(this.emailPopUp && window.location.pathname === '/ReferencesUsed') &&
                    <GlobalSearchFeaturePopUpModal showModal={this.emailPopUp} showEmailModal={true} handleClose={this.handleEmailPopUpClose} selectedResults={this.props.selectedResults} customWidth={this.props.customWidth} customHeight={this.props.customHeight} />
                } */}
            </div>
        }
        else {
            return <div className="global-features-wrapper">
                <div className={(window.location.pathname !== '/pubMedResults') ? "global-features-inner-wrapper" : "global-features-inner-wrapper global-features-inner-pubmed-wrapper"}>
                    <div className="global-feature-tile" onClick={this.onExpandCollapseAll}>
                        {this.state.collapsed ?
                            <div className="global-feature-tile-img-hov" >
                                <img src={globalExpandAllIcon} alt="" />
                            </div>
                            :
                            <div className="global-feature-tile-img" id="expandCollapse" /* style={{background: this.state.collapsed?"#FF8000":"rgba(255, 255, 255, 0.51)"}} */>
                                <img src={globalCollapseAllIcon} alt="" />
                            </div>
                        }
                        <div className="global-feature-tile-text">{this.state.collapsed ? 'Expand All' : 'Collapse All'}</div>
                    </div>
                    <div className="global-feature-tile" onClick={this.onSelectAll}>
                        {this.state.isChecked ?
                            <div className="global-feature-tile-img-hov" >
                                <img src={globalDeSelectAllIcon} alt="" />
                            </div>
                            :
                            <div className="global-feature-tile-img">
                                <img src={globalSelectAllIcon} alt="" />
                            </div>}
                        <div className="global-feature-tile-text">{this.state.isChecked ? 'De-Select All' : 'Select All'}</div>
                    </div>
                    {(window.location.pathname !== '/pubMedResults') &&
                        <div className="global-feature-tile" onClick={this.onClickViewSelected}>
                            <div className="global-feature-tile-img">
                                <img src={globalViewSelectedIcon} alt="" />
                            </div>
                            <div className="global-feature-tile-text">View Selected</div>
                        </div>
                    }
                    <div className="global-feature-tile" onClick={this.handleShareEmail}>
                        <div className="global-feature-tile-img">
                            <img src={globalShareIcon} alt="" />
                        </div>
                        <div className="global-feature-tile-text">Share</div>
                    </div>
                    <div className="global-feature-tile" onClick={this.handleSaveExport}>
                        <div className="global-feature-tile-img">
                            <img src={globalDownloadIcon} alt="" />
                        </div>
                        <div className="global-feature-tile-text">Save/Export</div>
                    </div>
                    <div className="global-feature-tile" onClick={this.handlePrint}>
                        <div className="global-feature-tile-img">
                            <img src={globalPrintIcon} alt="" />
                        </div>
                        <div className="global-feature-tile-text">Print</div>
                    </div>
                    {(window.location.pathname !== '/WhatsNewForYou' && window.location.pathname !== '/WhatOthersAreViewing' && window.location.pathname !== '/pubMedResults' && window.location.pathname !== '/ReferencesUsed') &&
                        <div className="global-feature-tile" onClick={this.onClickBookmark}>
                            <div className="global-feature-tile-img">
                                <img src={globalBookmarkIcon} alt="" />
                            </div>
                            <div className="global-feature-tile-text">Bookmark</div>
                        </div>
                    }
                    {(window.location.pathname !== '/pubMedResults') &&
                        <div className="global-feature-tile" onClick={this.handleDownload}>
                            <div className="global-feature-tile-img">
                                <img src={globalSaveExportIcon} alt="" />
                            </div>
                            <div className="global-feature-tile-text">Download</div>
                        </div>
                    }
                    {(window.location.pathname !== '/WhatsNewForYou' && window.location.pathname !== '/WhatOthersAreViewing' && window.location.pathname !== '/pubMedResults' && window.location.pathname !== '/ReferencesUsed' && window.location.pathname !== '/viewReadingList') &&
                        <div className="global-feature-tile" onClick={this.onClickNotifyMe}>
                            <div className="global-feature-tile-img">
                                <img src={globalNotificationIcon} alt="" />
                            </div>
                            <div className="global-feature-tile-text">Alert</div>
                        </div>
                    }
                    {(window.location.pathname !== '/pubMedResults' && window.location.pathname !== '/ReferencesUsed') &&
                        <div className="global-feature-tile" onClick={this.onClickViewReadingList}>
                            <div className="global-feature-tile-img">
                                <img src={globalReadingListIcon} alt="" />
                            </div>
                            <div className="global-feature-tile-text">Reading List</div>
                        </div>
                    }
                    {(window.location.pathname !== '/pubMedResults' && userAccessGroup.includes(Authoring)) &&
                        <div className="global-feature-tile global-feature-authoring-tile " >
                            <Link target="_blank" to={"/Authoring"} onClick={e => this.indexData("Global Authoring")} >
                                <div className="global-feature-tile-img">
                                    <img src={iconAuthoring} alt="" />
                                </div>
                                <div className="global-feature-tile-text">Authoring</div>
                            </Link>
                        </div>
                    }
                    {(window.location.pathname !== '/pubMedResults' && userAccessGroup.includes(Authoring)) &&
                        <div className="global-feature-tile" onClick={this.onClickViewNotebook}>
                            <div className="global-feature-tile-img">
                                <img src={notebook} alt="" />
                            </div>
                            <div className="global-feature-tile-text">Notebook</div>
                        </div>
                    }
                    {(window.location.pathname === '/pubMedResults') &&
                        <div className="global-feature-pubmed">
                            <div className="global-feature-pubmedicon-wrapper">
                                <img src={pubMedIcon} alt="" className="global-feature-pubmedicon" />
                                <p>Vai i Medical</p>
                                <p>Search</p>
                            </div>
                            <div className="global-feature-pubmednih-wrapper">
                                <p>Made Possible By</p>
                                <img src={pubMedNIH} alt="" />
                            </div>
                        </div>
                    }
                </div>
                {/* {this.state.showGSFModal && */}
                {window.location.pathname !== '/ReferencesUsed' ? this.state.showGSFModal &&
                    <GlobalSearchFeaturePopUpModal showModal={this.state.showGSFModal} showSaveModal={this.state.showSavePopUp} showPrintModal={this.state.showPrintPopUp} showEmailModal={this.state.showEmailPopUp} showDownloadModal={this.state.showDownloadPopUp} handleClose={this.handlePopUpClose} selectedResults={this.props.selectedResults} customWidth={this.props.customWidth} customHeight={this.props.customHeight} querytext={this.props.querytext} appliedFilters={this.props.appliedFilters} documentsUrl={this.props.documentsUrl} markedDocumentsUrl={this.props.markedDocumentsUrl} itemsPerPage={this.props.itemsPerPage} />
                    : this.state.showGSFModal &&
                    <MIAsRefGlobalSearchFeaturePopUpModal showModal={this.state.showGSFModal} showSaveModal={this.state.showSavePopUp} showPrintModal={this.state.showPrintPopUp} showEmailModal={this.state.showEmailPopUp} showDownloadModal={this.state.showDownloadPopUp} handleClose={this.handlePopUpClose} selectedResults={this.props.selectedResults} customWidth={this.props.customWidth} customHeight={this.props.customHeight} querytext={this.props.querytext} appliedFilters={this.props.appliedFilters} documentsUrl={this.props.documentsUrl} markedDocumentsUrl={this.props.markedDocumentsUrl} itemsPerPage={this.props.itemsPerPage} />
                }
                {/* {this.state.showSavePopUp &&
                    <GlobalSearchFeaturePopUpModal showModal={this.state.showSavePopUp} showSaveModal={true} handleClose={this.handlePopUpClose} selectedResults={this.props.selectedResults} />
                } */}
                {/* {this.state.showEmailPopUp &&
                    <GlobalSearchFeaturePopUpModal showModal={this.state.showEmailPopUp} showEmailModal={true} handleClose={this.handlePopUpClose} selectedResults={this.props.selectedResults} />
                } */}
                {
                    this.state.showReadingListPop &&
                    <ReadingListPopup showModal={this.state.showReadingListPop} handleClose={this.handleCloseReadingList} width={this.props.customWidth} selectedResults={this.props.selectedResults} />
                }
                {this.state.showNotebookPop &&
                    <NotebookListPopUp showModal={this.state.showNotebookPop} handleClose={this.handleCloseNotebookList} width={this.props.customWidth} isFromGlobalFeaturesMenu={true} />
                }
                {
                    this.state.showPersonalAlerts &&
                    <NotifyMePopUp showPersonalAlerts={this.state.showPersonalAlerts} handleClose={() => this.setState({ showPersonalAlerts: false })} customWidth={this.props.customWidth} />
                }
                {
                    this.state.showBookmarkPopUp &&
                    <BookmarkPopUp showModal={this.state.showBookmarkPopUp} handleClose={() => this.setState({ showBookmarkPopUp: false })} customWidth={this.props.customWidth} />
                }
                {this.state.showAuthoringGPTPop && <>{this.renderAuthoringGPTModelPopup()}</>}
            </div>
        }
    }
}
export default withRouter(GlobalFeatures);