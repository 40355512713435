import { Component } from "react";
import JanssenScienceHeaderWithBanner from "../../components/header/JanssenScienceHeaderWithBanner";
import JanssenScienceChatBotSection from "../../components/JanssenScienceChatBot/JanssenScienceChatBotSection";
import Services from "../../utils/services";
class JanssenScience extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
        }
    }
    componentDidMount() {
        window.scrollTo({ behavior: "smooth", top: "0px" });
        this.userid = JSON.parse(localStorage.getItem("username"));
        this.wwid = JSON.parse(localStorage.getItem('wwid'));
        this.userExists = localStorage.getItem("userExists");
        let service = new Services();
        service.logService(
          `JanssenScience page successfully mounted for ${this.userid.toLowerCase()}`,
          "info"
        );
    }

    componentWillUnmount() {
        try {
          let service = new Services();
          window.removeEventListener("resize", this.updateDimensions);
          service.logService(
            `JanssenScience page successfully unmounted for ${this.userid.toLowerCase()}`,
            "info"
          );
        } catch (error) {
          this.setState({ error: error });
        }
      }
    updateDimensions = () => {
        try {
          this.setState(
            { width: window.innerWidth, height: window.innerHeight },
            () => {
              /* if(this.state.width < 1200){
                                      this.setState({
                                          showFilters : false
                                      })
                                  }
                                  else{
                                      this.setState({
                                          showFilters : true
                                      }) 
                                  } */
            }
          );
        } catch (error) {
          this.setState({ error: error });
        }
      };
    render() {
        return (
            <div className="janssenscience-page-wrapper">
                <JanssenScienceHeaderWithBanner />
                <div className="janssenscience-page-contents-wrapper">
                <JanssenScienceChatBotSection customWidth={this.state.width}
              customHeight={this.state.height} /> 
               </div>
            </div>
        )
    }
}
export default JanssenScience