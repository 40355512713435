/* eslint-disable import/no-anonymous-default-export */
import { desArrow, infoIcn } from '../../assets'
import { useState } from 'react';

const formattedStringArr = (array, fn) => {
    var result = [];
    for (var i = 0; i < array.length; i++) {
        var mapping = fn(array[i]);
        result = result.concat(mapping);
    }
    return result;
}
const divider = () => {
    return (
        <div className="document-descriptor-divider"></div>
    )
}
const textFormatter = (data) => {
    const replacedString = data.replaceAll("%%##%%##", '%%##').replaceAll("%%##", '\n');
    //console.log(newString)
    const updatedText = formattedStringArr(replacedString.split('\n'), function (part) {
        return [part, <div>{divider()}</div>];
    });
    updatedText.pop()
    return (
        <div>
            {updatedText}
        </div>
    )
}
export default function (props) {

    const [isOpen, setOpenDescriptor] = useState(false);
    const {
        keyTakeaways
    } = props
    return (
        <div className="document-descriptor-wrapper">
            <div className="document-descriptor-header-wrapper" onClick={e => setOpenDescriptor(!isOpen)}>
                <img src={desArrow} alt='desArrow' className={isOpen ? "document-descriptor-img-rotate" : "document-descriptor-img"} onClick={e => setOpenDescriptor(!isOpen)} />
                <span> Key Takeaways</span>
                <img src={infoIcn} alt='infoIcn' className="keyTakeaways-info-icn" title="The content included in the Key Takeaways field is created by the US Medical Information and EMEA Scientific Knowledge Management teams to support consistent messaging around important published scientific evidence." />
            </div>
            {isOpen &&
                <div className="document-descriptor-text-wrapper">
                    {textFormatter(keyTakeaways)}
                </div>
            }
        </div>
    )
}