import { Modal } from "react-bootstrap";
import './GlobalSearchFeaturesPopUp.css'
import { imsLoader } from "../../assets";

export default function SavePopUpModal(props) {
    const {
        showModal,
        color,
        documentProcessingClassName,
        backdropClassName
    } = props
    return (
        <Modal
            show={showModal}
            size="lg md sm xs"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="savePopUp"
            className={documentProcessingClassName ? documentProcessingClassName : ""}
            scrollable={true}
            backdropClassName={backdropClassName ? backdropClassName : ""}>
            <div style={{ padding: "0 5%" }}>
                <div style={{ fontSize: "14px", textAlign: "center" }}>
                    <Modal.Body>
                        <img src={imsLoader} alt="" width={100} height={100} />
                        <div className="saveModal-heading" style={{ color: color }}>Please wait while your document is getting downloaded</div>
                    </Modal.Body>
                </div>
            </div>
        </Modal>
    )
}