import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { crossIcon } from "../../assets";

class GenAIAnsweringDisclaimer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: props.showPopup,
        }
    }
    render() {
        return (<><Modal
            show={this.state.showPopup}
            size="md sm xs"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="savePopUp"
            className="authoring-disclaimer-model"
            backdropClassName="document-processing-newthread-backdrop"
            scrollable={true}>
            <div style={{ padding: "0 0%" }}>
                <div style={{ fontSize: "14px",padding:"5px" }}>
                    <Modal.Body className="authoring-model-body-disclaimer">
                        <div onClick={e => this.props.handleDisclaimerClose()} className="authoring-model-body-disclaimer-cross" >
                            <img src={crossIcon} width={12} height={12} alt="Cross" />
                        </div>
                        <div className="authoring-model-body-content">
                            <span>
                                <b>As you engage with our Gen AI, please adhere to the following:</b>
                                <ul>
                                    <li>Gen AI should not be used to generate or distribute content violating our Code of Business Conduct, Privacy Policies, Information Asset Protection Policies (IAPPs), Open-Source Licensing Policy, and other relevant policies.</li>
                                    <li>You are reminded that no personal information or GxP data should be uploaded onto this platform.
                                    </li>
                                    <li>Gen AI's output is not a substitute for professional or legal advice. All generated content should be reviewed and substantiated against known risks, including inaccuracy, bias, and accountability.
                                    </li>
                                    <li>We encourage you to review our <a className="authoring-model-body-content-link" href="https://jnj.sharepoint.com/sites/LawCenter/JJ%20Online%20Engagement%20Policy/Guidelines%20on%20the%20Responsible%20Use%20of%20Generative%20AI%20at%20Johnson%20and%20Johnson_FINAL.pdf"target="_blank" >Guidelines on the Responsible Use of Generative AI.</a>
                                    </li>
                                </ul>
                                <span>Your interaction with Gen AI signifies your understanding and agreement to these terms.</span>

                            </span>
                        </div>

                    </Modal.Body>
                </div>
            </div>
        </Modal></>)
    }

}
export default withRouter(GenAIAnsweringDisclaimer);