import { Component } from "react";
import {
  viewMoreArrow,
  rightSideArrow,
  iIcon,
  imsLoader,
  rightArrowBlue,
  iIconWhite,
  viewMoreArrowBlue
} from "../../assets";
import axios from "axios";
import GenerateArrayStrings from "../GenerateArrayStrings";
import { Link } from "react-router-dom";
import "../WNFY/WNFYPage.css";
import ApiServices from "../../utils/apiServices";
import { LocationOrigin } from "../../utils/utilityServices";
import DocumentPreview from "../Preview/DocumentPreview";
import trackingService from "../../services/trackingService";
import QueryHistorySection from '../History/QueryHistorySection'
import moment from 'moment';
import { uniq } from 'underscore'

class WOAVSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContent: false,
      showInfo: false,
      isProcessing: true,
      responseArr: [],
      previewData1: ``,
      previewData2: ``,
      previewData3: ``,
      showPreview: false,
      previewData: {},
      previewMsg: "Doc preview is loading...",
    };
  }
  async componentWillMount() {
    let userFunc = localStorage.getItem("function");
    if (userFunc) {
      userFunc = userFunc.replace(/"/g, "");
    }
    let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'));
    var url =
      LocationOrigin +
      process.env.REACT_APP_IMS_API_PREFIX +
      "qp/whatOthersAreViewing";
    let config = {
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: "",
        filters: "",
        function: userFunc,
        doctypepref: localStorage.getItem("doctypepref"),
        userproducts: localStorage.getItem("userproducts"),
        region: localStorage.getItem("region"),
        username: localStorage
          .getItem("username")
          .toLowerCase()
          .replace(/"/g, ""),
        contentpreference: localStorage
          .getItem("contentpreference")
          .replace(/"/g, ""),
        from: 0,
        size: 3,
        sortBy: "desc",
        sortField: "modifiedDate",
        accessGroup: (userAccessGroup.includes("MISEHCP")) ? "MISE" : "",
      },
    };
    axios(config)
      .then((response) => {
        this.setState(
          {
            responseArr: response.data.body.hits.hits,
          },
          async () => {
            let apiService = new ApiServices();
            let preview1, preview2, preview3;
            if (this.state.responseArr[0] !== undefined) {
              preview1 = await apiService.getPreviewData(
                this.state.responseArr[0]._source.searchID
              );
            }
            if (this.state.responseArr[1] !== undefined) {
              preview2 = await apiService.getPreviewData(
                this.state.responseArr[1]._source.searchID
              );
            }
            if (this.state.responseArr[2] !== undefined) {
              preview3 = await apiService.getPreviewData(
                this.state.responseArr[2]._source.searchID
              );
            }
            //console.log(preview)
            this.setState(
              {
                previewData1: btoa(
                  new Uint8Array(preview1).reduce(function (data, byte) {
                    return data + String.fromCharCode(byte);
                  }, "")
                ),
                previewData2: btoa(
                  new Uint8Array(preview2).reduce(function (data, byte) {
                    return data + String.fromCharCode(byte);
                  }, "")
                ),
                previewData3: btoa(
                  new Uint8Array(preview3).reduce(function (data, byte) {
                    return data + String.fromCharCode(byte);
                  }, "")
                ),
                isProcessing: false,
              },
              () => this.setState({ previewMsg: "Doc Preview not available" })
            );
          }
        );
        if (this.props.customWidth < 600) {
          this.setState({
            showContent: false,
          });
        } else {
          this.setState({
            showContent: true,
          });
        }
      })
      .catch(function (error) {
        //console.log(error);
      })
      .finally(() => {
        this.setState({ isProcessing: false });
      });
  }
  componentDidMount = () => {
    let outer = document.querySelector("#woavHeaderSection");
    if (this.props.customWidth >= 600) {
      outer.addEventListener("click", (event) => {
        if (event.target.id === "woavHeaderSection") {
          this.setState({ showContent: !this.state.showContent });
        }
        else if (event && event.target && event.target.classList && event.target.classList.contains('woav-click-header-section')) {
          this.setState({ showContent: !this.state.showContent });
        }
      });
      // outer1.forEach(x=>{


      // })
    }
  };
  indexData = (item, url) => {
    var newurl = new URL(url);
    var downloadUrl = newurl.pathname + newurl.search;
    var docNumber;
    var version = "";
    if (
      item.domain === "SKM" ||
      item.domain === "IROs" ||
      item.domain === "MIAs"
    ) {
      docNumber = item.domain;
    } else if (item.domain === "RIMdocs" || item.domain === "RIMdocs_LL") {
      docNumber = item.jj_number;
      version = item.r_version_label;
    } else {
      if (
        item.domain === "SRD" ||
        item.domain === "SR" ||
        item.domain === "SRAP" ||
        item.domain === "GCSR" ||
        item.domain === "SFM"
      ) {
        version = item.majorVersionNumber;
      }
      docNumber = item.documentNumber;
    }
    var title = item.docTitle;
    var products = item.tempJNJDrugs;
    var docnumber = docNumber;
    var docversion = version;
    var contentsource = item.domain;
    var docID = item.searchID;
    var docType = item.docType;
    var docUrl = downloadUrl; //item.downloadUrl;
    var doclastModified = item.createdDate;
    if (JSON.stringify(doclastModified) === "[null]") {
      doclastModified = "";
    }
    const ts = new trackingService();
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      localStorage.getItem("companyname"),
      "View OR Download Document",
      "Direct",
      "What Others Are Viewing Component",
      "",
      "",
      title,
      docversion,
      docnumber,
      docID,
      docUrl,
      products,
      docType,
      "View Document",
      "",
      "",
      contentsource,
      "",
      "",
      doclastModified
    );
  };
  formatPreviews = (item, previewData) => {
    let url;
    if (item.domain === "RIMdocs" || item.domain === "RIMdocs_LL") {
      url =
        LocationOrigin +
        process.env.REACT_APP_IMS_API_PREFIX +
        `download/RIMdocs?strID=${item.docID}`;
      // LocationOrigin + url = process.env.REACT_APP_IMS_API_PREFIX + `download/RIMdocs?strID=${item.docTitle}`
    } else if (item.domain === "SKM" || item.domain === "MIAsReferences" || item.domain === "IROs") {
      url =
        LocationOrigin +
        process.env.REACT_APP_IMS_API_PREFIX +
        `dcs-spo/download?connID=${item.connID}&csID=${item.csID}&docID=${item.docID}&fileType=${item.fileExtension}&fileName=${encodeURIComponent(item.docTitle.toString().replace(/\n/g, " "))}`;
    }
    else if (item.domain === "MIAs" || item.domain === "PERC") {
      url = LocationOrigin + item.downloadUrl;
    }
    else if (item.domain === "CTGov") {
      url = `https://clinicaltrials.gov/ct2/show/${item.docID}`;
      // url = `https://clinicaltrials.gov/ct2/show/${item.docTitle}`;
    } else {
      if (item.fileExtension !== "pdf") {
        url =
          LocationOrigin +
          process.env.REACT_APP_IMS_API_PREFIX +
          `dcs-vv/download?connID=${item.connID}&csID=${item.csID}&docID=${item.docID}&fileType=${item.fileExtension}&docVer=${item.majorVersionNumber}&fileName=${encodeURIComponent(item.docTitle.toString().replace(/\n/g, " "))}`;
      } else {
        url =
          LocationOrigin +
          process.env.REACT_APP_IMS_API_PREFIX +
          `dcs-vv/download?connID=${item.connID}&csID=${item.csID}&docID=${item.docID}&fileType=${item.fileExtension}&fileName=${encodeURIComponent(item.docTitle.toString().replace(/\n/g, " "))}`;
      }
      if (
        item.a_status === "Approved Needs Review" ||
        item.status === "Approved Needs Review"
      ) {
        url = url + `&docState=anr`;
      }
    }
    if (this.props.customWidth > 1000) {
      return (<div className="wnfy-preview-wrapper">
        {previewData ? (
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            onClick={() => this.indexData(item, url)}>
            <img
              src={`data:image/png;base64,${previewData}`}
              alt="Preview Data"
              style={{
                width: "100%",
                height: "100%",
                cursor: "pointer"
              }}
            //onClick={(e) => this.onPreviewClick(this.state.responseArr[0]._source)}
            />
          </a>
        ) : (
          <>
            {this.state.previewMsg}
            {/* Doc Preview not available */}
          </>
        )}
      </div>)
    }

  }
  renderRIMdocsCompoundNameOrProductGenericName = (item) => {
    if (this.props.customWidth > 1000) {
      if (item.compoundName !== undefined && item.compoundName.length > 0) {
        return (
          <div
            className="PHM-MetaDataProperty"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              width: "100%",
              minHeight: "18px",
              wordBreak: "break-all",
            }}
            title={GenerateArrayStrings(uniq(item.compoundName.filter(x => x)))}
          >
            {Array.isArray(item.compoundName) ? (
              <span>
                {GenerateArrayStrings(uniq(item.compoundName.filter(x => x)))}
              </span>
            ) : (
              <span>
                {item.compoundName}
              </span>
            )}
          </div>
        )
      } else if (item.product_generic_name !== undefined && item.product_generic_name.length > 0) {
        return (
          <div
            className="PHM-MetaDataProperty"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              width: "100%",
              minHeight: "18px",
              wordBreak: "break-all",
            }}
            title={GenerateArrayStrings(uniq(item.product_generic_name.filter(x => x)))}>
            {Array.isArray(item.product_generic_name) ? (
              <span>
                {GenerateArrayStrings(uniq(item.product_generic_name.filter(x => x)))}
              </span>
            ) : (
              <span>
                {item.product_generic_name}
              </span>
            )}
          </div>
        )
      } else {
        return (<></>);
      }

    } else {
      if (item.compoundName !== undefined && item.compoundName.length > 0) {
        return (
          <div
            className="PHM-MetaDataProperty"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              width: "100%",
              minHeight: "18px",
              wordBreak: "break-all",
            }}
            title={GenerateArrayStrings(uniq(item.compoundName.filter(x => x)))}
          >
            <label>Generic Name:&nbsp;</label>
            {Array.isArray(item.compoundName) ? (
              <b style={{ color: "#0074B0" }}>
                {GenerateArrayStrings(uniq(item.compoundName.filter(x => x)))}
              </b>
            ) : (
              <b style={{ color: "#0074B0" }}>
                {item.compoundName}
              </b>
            )}
          </div>
        )
      } else if (item.product_generic_name !== undefined && item.product_generic_name.length > 0) {
        return (
          <div
            className="PHM-MetaDataProperty"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              width: "100%",
              minHeight: "18px",
              wordBreak: "break-all",
            }}
            title={GenerateArrayStrings(uniq(item.product_generic_name.filter(x => x)))}
          >
            <label>Generic Name:&nbsp;</label>
            {Array.isArray(item.product_generic_name) ? (
              <b style={{ color: "#0074B0" }}>
                {GenerateArrayStrings(uniq(item.product_generic_name.filter(x => x)))}
              </b>
            ) : (
              <b style={{ color: "#0074B0" }}>
                {item.product_generic_name}
              </b>
            )}
          </div>
        )
      } else {
        return (<></>);
      }
    }
  }
  formatResults = (item) => {
    let url;
    if (item.domain === "RIMdocs" || item.domain === "RIMdocs_LL") {
      url =
        LocationOrigin +
        process.env.REACT_APP_IMS_API_PREFIX +
        `download/RIMdocs?strID=${item.docID}`;
      // url = LocationOrigin + process.env.REACT_APP_IMS_API_PREFIX + `download/RIMdocs?strID=${item.docTitle}`
    } else if (item.domain === "SKM" || item.domain === "MIAs" || item.domain === "MIAsReferences" || item.domain === "PERC" || item.domain === "IROs") {
      url =
        LocationOrigin +
        process.env.REACT_APP_IMS_API_PREFIX +
        `dcs-spo/download?connID=${item.connID}&csID=${item.csID}&docID=${item.docID}&fileType=${item.fileExtension}&fileName=${item.docTitle}`;
    } else if (item.domain === "CTGov") {
      url = `https://clinicaltrials.gov/ct2/show/${item.docID}`;
      // url = `https://clinicaltrials.gov/ct2/show/${item.docTitle}`;
    } else {
      if (item.fileExtension !== "pdf") {
        url =
          LocationOrigin +
          process.env.REACT_APP_IMS_API_PREFIX +
          `dcs-vv/download?connID=${item.connID}&csID=${item.csID}&docID=${item.docID}&fileType=${item.fileExtension}&docVer=${item.majorVersionNumber}&fileName=${encodeURIComponent(item.docTitle.toString().replace(/\n/g, " "))}`;
      } else {
        url =
          LocationOrigin +
          process.env.REACT_APP_IMS_API_PREFIX +
          `dcs-vv/download?connID=${item.connID}&csID=${item.csID}&docID=${item.docID}&fileType=${item.fileExtension}&fileName=${encodeURIComponent(item.docTitle.toString().replace(/\n/g, " "))}`;
      }
      if (
        item.a_status === "Approved Needs Review" ||
        item.status === "Approved Needs Review"
      ) {
        url = url + `&docState=anr`;
      }
    }
    if (this.props.customWidth > 1000) {
      return (
        <div className="wnfy-content-citation-wrapper">
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              width: "100%",
              minHeight: "40px",
            }}
            onClick={() => this.indexData(item, url)}
          >
            <span title={item.docTitle}>{item.docTitle}</span>
          </a>
          {item.docType !== undefined && item.docType.length > 0 && (
            <div
              className="PHM-MetaDataProperty"
              style={{
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                width: "100%",
                minHeight: "18px",
              }}
              title={GenerateArrayStrings(item.docType)}
            >
              {Array.isArray(item.docType) ? (
                <b>{GenerateArrayStrings(item.docType)}</b>
              ) : (
                <b>{item.docType}</b>
              )}
            </div>
          )}
          {item.domain === "RIMdocs" || item.domain === "RIMdocs_LL"
            ? this.renderRIMdocsCompoundNameOrProductGenericName(item)
            : item.genericName !== undefined &&
            item.genericName.length > 0 && (
              <div
                className="PHM-MetaDataProperty"
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  width: "100%",
                  minHeight: "18px",
                }}
                title={GenerateArrayStrings(item.genericName)}
              >
                {Array.isArray(item.genericName) ? (
                  <span>
                    {GenerateArrayStrings(item.genericName)}
                  </span>
                ) : (
                  <span>{item.genericName}</span>
                )}
              </div>
            )}
          {item.modifiedDate && <div>
            <span>{moment(item.modifiedDate).format("D-MMM-YYYY")}</span>
          </div>}
          <div className="wnfy-button-wrapper wnfy-button-wrapper-preview">
            {this.props.customWidth > 1000 && (
              <button
                className="wnfy-button"
                onClick={(e) => this.onPreviewClick(item)}
              >
                Preview
              </button>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="wnfy-content-citation-wrapper">
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              width: "100%",
              minHeight: "40px",
            }}
            onClick={() => this.indexData(item, url)}
          >
            <span title={item.docTitle}>{item.docTitle}</span>
          </a>
          {item.domain === "RIMdocs" || item.domain === "RIMdocs_LL"
            ? item.product_code && (
              <div
                className="PHM-MetaDataProperty"
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  width: "100%",
                  minHeight: "18px",
                }}
                title={item.product_code}
              >
                <label>Primary Compound ID:&nbsp;</label>
                <b>{item.product_code}</b>
              </div>
            )
            : item.status && (
              <div
                className="PHM-MetaDataProperty"
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  width: "100%",
                  minHeight: "18px",
                }}
                title={item.status}
              >
                <label>Status:&nbsp;</label>
                <b>{item.status}</b>
              </div>
            )}
          {item.domain === "RIMdocs" || item.domain === "RIMdocs_LL"
            ? this.renderRIMdocsCompoundNameOrProductGenericName(item)
            : item.genericName !== undefined &&
            item.genericName.length > 0 && (
              <div
                className="PHM-MetaDataProperty"
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  width: "100%",
                  minHeight: "18px",
                }}
                title={GenerateArrayStrings(item.genericName)}
              >
                <label>Generic Name:&nbsp;</label>
                {Array.isArray(item.genericName) ? (
                  <b style={{ color: "#0074B0" }}>
                    {GenerateArrayStrings(item.genericName)}
                  </b>
                ) : (
                  <b style={{ color: "#0074B0" }}>{item.genericName}</b>
                )}
              </div>
            )}
          {item.docType !== undefined && item.docType.length > 0 && (
            <div
              className="PHM-MetaDataProperty"
              style={{
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                width: "100%",
                minHeight: "18px",
              }}
              title={GenerateArrayStrings(item.docType)}
            >
              <label>Document Type:&nbsp;</label>
              {Array.isArray(item.docType) ? (
                <b>{GenerateArrayStrings(item.docType)}</b>
              ) : (
                <b>{item.docType}</b>
              )}
            </div>
          )}
          {item.modifiedDate && <div>
            <label>Modified Date:&nbsp;</label>
            <span>{moment(item.modifiedDate).format("D-MMM-YYYY")}</span>
          </div>}

          <div className="wnfy-button-wrapper wnfy-button-wrapper-preview">
            <button
              className="wnfy-button"
              onClick={(e) => {
                this.indexData(item, url);
                window.open(url, "");
              }}
            >
              Read More
            </button>
            {this.props.customWidth > 1000 && (
              <button
                className="wnfy-button"
                onClick={(e) => this.onPreviewClick(item)}
              >
                Preview
              </button>
            )}
          </div>
        </div>
      );
    }
  };
  onPreviewClick = (item) => {
    this.setState({ showPreview: true, previewData: item });
  };
  handlePreviewClose = (status) => {
    this.setState({ showPreview: false, previewData: {} });
  };
  onMouseEnterInfo = () => {
    this.setState({
      showInfo: true,
    });
  };
  onMouseLeaveInfo = () => {
    this.setState({
      showInfo: false,
    });
  };
  render() {
    let userProducts = localStorage.getItem("userproducts");
    let { showPreview, previewData } = this.state;
    if (this.props.customWidth < 600) {
      return (
        <div className="wnfy-outer-wrapper">
          <div className="wnfy-inner-wrapper">
            <div
              className="wnfy-header-section-mobile"
              onClick={(e) => {
                this.setState({ showContent: !this.state.showContent });
              }}
            >
              <span>
                <div className="wnfy-main-header">
                  What Others Are Viewing &nbsp;&nbsp;
                </div>
                {/* <div
                  style={{
                    color: "#0074B0",
                    textDecoration: "none",
                    fontStyle: "italic",
                  }}
                  className="wnfy-header-text"
                > */}
                <div
                  style={{
                    textDecoration: "none",
                    fontStyle: "italic",
                  }}
                  className="wnfy-header-text"
                >
                  (Content Of Interest Of Your Colleagues)
                </div>
              </span>
              <span className="wnfy-expand-wrapper">
                {this.state.showContent ? (
                  <img
                    src={rightArrowBlue}
                    alt=""
                    width={10}
                    height={10}
                    style={{ transform: "rotate(-90deg)" }}
                  />
                ) : (
                  <img
                    src={rightArrowBlue}
                    alt=""
                    width={10}
                    height={10}
                    style={{ transform: "rotate(90deg)" }}
                  />
                )}
              </span>
            </div>
            {localStorage.getItem("userproducts") !== "" ? (
              <>
                {this.state.showContent && (
                  <>
                    {this.state.responseArr !== undefined &&
                      this.state.responseArr.length > 0 ? (
                      <div className="wnfy-content-section">
                        {this.state.responseArr[0] !== undefined && (
                          <div className="wnfy-tile-wrapper">
                            {!this.state.isProcessing ? (
                              <>
                                <div className="wnfy-citation-wrapper">
                                  {this.formatResults(
                                    this.state.responseArr[0]._source
                                  )}
                                </div>
                              </>
                            ) : (
                              <span className="wnfy-content-loader">
                                <img
                                  src={imsLoader}
                                  width="25"
                                  height="25"
                                  alt=""
                                />
                              </span>
                            )}
                          </div>
                        )}
                        {this.state.responseArr[1] !== undefined && (
                          <div className="wnfy-tile-wrapper">
                            {!this.state.isProcessing ? (
                              <>
                                <div className="wnfy-citation-wrapper">
                                  {this.formatResults(
                                    this.state.responseArr[1]._source
                                  )}
                                </div>
                              </>
                            ) : (
                              <span className="wnfy-content-loader">
                                <img
                                  src={imsLoader}
                                  width="25"
                                  height="25"
                                  alt=""
                                />
                              </span>
                            )}
                          </div>
                        )}
                        {this.state.responseArr[2] !== undefined && (
                          <div className="wnfy-tile-wrapper">
                            {!this.state.isProcessing ? (
                              <>
                                <div className="wnfy-citation-wrapper">
                                  {this.formatResults(
                                    this.state.responseArr[2]._source
                                  )}
                                </div>
                              </>
                            ) : (
                              <span className="wnfy-content-loader">
                                <img
                                  src={imsLoader}
                                  width="25"
                                  height="25"
                                  alt=""
                                />
                              </span>
                            )}
                          </div>
                        )}
                        <div
                          className="wnfy-footer-section"
                          style={{
                            visibility: userProducts === "" && "hidden",
                          }}
                        >
                          <span
                            className="wnfy-view-more-header-text"
                            onClick={(e) => {
                              window.open(
                                "/WhatOthersAreViewing",
                                "",
                                `width=${window.innerWidth},height=${window.innerHeight},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no`
                              );
                            }}
                          >
                            View More
                          </span>
                          <img
                            src={viewMoreArrow}
                            alt="right-arrow"
                            width={11}
                            height={14}
                            onClick={(e) => {
                              window.open(
                                "/WhatOthersAreViewing",
                                "",
                                `width=${window.innerWidth},height=${window.innerHeight},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no`
                              );
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="wnfy-no-product-content-section">
                        There are no new items that match your preferences.
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="wnfy-no-product-content-section">
                You don't have any product preference selected. Click{" "}
                {JSON.parse(localStorage.getItem("russianuser")) ? (
                  <Link
                    to="/register-role"
                    style={{ textDecoration: "none", color: "#1076bc" }}
                  >
                    here
                  </Link>
                ) : (
                  <Link
                    to={{
                      pathname: "/registration",
                      query: { toProducts: true },
                    }}
                    style={{ textDecoration: "none", color: "#1076bc" }}
                  >
                    here
                  </Link>
                )}{" "}
                to select your product preference.
              </div>
            )}
          </div>
        </div>
      );
    }
    else if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
      return (
        <div className="wnfy-outer-wrapper">
          <div className="wnfy-inner-wrapper">
            <div
              id="woavHeaderSection"
              className={
                this.props.customWidth >= 1365
                  ? "wnfy-header-section"
                  : "wnfy-header-section wnfy-header-display-flex-section"
              } /* onClick={e => { this.setState({ showContent: !this.state.showContent }) }} */
            >
              {this.props.customWidth >= 1365 ? (
                <>
                  <span className="wnfy-main-header">
                    What Others Are Viewing{" "}
                  </span>
                  {/* <span
                    style={{ color: "#0074B0", textDecoration: "none" }}
                    className="wnfy-header-text"
                  >
                    (Content Of Interest Of Your Colleagues)
                  </span> */}
                  <span
                    style={{ textDecoration: "none" }}
                    className="wnfy-header-text-desktop"
                  >
                    (Content Of Interest Of Your Colleagues)
                  </span>
                </>
              ) : (
                <>
                  <span className="wnfy-main-header">
                    What Others Are Viewing <br />
                    {/* <span
                      style={{ color: "#0074B0", textDecoration: "none" }}
                      className="wnfy-header-text"
                    > */}
                    <span
                      style={{ textDecoration: "none" }}
                      className="wnfy-header-text"
                    >
                      (Content Of Interest Of Your Colleagues)
                    </span>
                  </span>
                </>
              )}

              <span className="wnfy-view-more-wrapper">
                <div
                  className="viewMoreContainer"
                  style={{ visibility: userProducts === "" && "hidden" }}
                >
                  <span
                    className="wnfy-view-more-header-text"
                    onClick={(e) => {
                      window.open(
                        "/WhatOthersAreViewing",
                        "",
                        `width=${window.innerWidth},height=${window.innerHeight},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no`
                      );
                    }}
                  >
                    View More
                  </span>
                  <img
                    src={viewMoreArrow}
                    alt="right-arrow"
                    width={11}
                    height={14}
                    onClick={(e) => {
                      window.open(
                        "/WhatOthersAreViewing",
                        "",
                        `width=${window.innerWidth},height=${window.innerHeight},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no`
                      );
                    }}
                  />
                </div>
                <img
                  src={iIcon}
                  alt="right-arrow"
                  style={{ height: "18px", width: "18px" }}
                  onMouseEnter={this.onMouseEnterInfo}
                  onMouseLeave={this.onMouseLeaveInfo}
                />
                {!this.state.showContent ? (
                  <img
                    src={rightSideArrow}
                    alt=""
                    width={11}
                    height={14}
                    style={{ transform: "rotate(90deg)" }}
                    onClick={(e) => {
                      this.setState({ showContent: true });
                    }}
                  />
                ) : (
                  <img
                    src={rightSideArrow}
                    alt=""
                    width={11}
                    height={14}
                    style={{ transform: "rotate(-90deg)" }}
                    onClick={(e) => {
                      this.setState({ showContent: false });
                    }}
                  />
                )}
              </span>
            </div>
            {this.state.showInfo && (
              <div className="info-icon-wrapper">
                <span className="tooltiptext tooltip-bottom">
                  This tile lists content that has been frequently viewed or
                  downloaded by those with the same preferred products as you.
                  Each item will appear for 5 days in this tile, but can be
                  accessed afterwards via the “View more” link at the top of the
                  tile.
                </span>
              </div>
            )}
            {localStorage.getItem("userproducts") !== "" ? (
              <>
                {this.state.showContent && (
                  <>
                    {this.state.responseArr !== undefined &&
                      this.state.responseArr.length > 0 ? (
                      <div className="wnfy-content-section">
                        {this.state.responseArr[0] !== undefined && (
                          <div
                            className="wnfy-tile-wrapper"
                            style={{
                              margin:
                                this.props.customWidth >= 1000 && "0 1% 0 0",
                            }}
                          >
                            {!this.state.isProcessing ? (
                              <>
                                <div className="wnfy-preview-wrapper">
                                  {this.state.previewData1 !== `` ? (
                                    <img
                                      alt="Preview Data"
                                      src={`data:image/png;base64,${this.state.previewData1}`}
                                      style={{ width: "100%", height: "100%" }}
                                    />
                                  ) : (
                                    <>
                                      {this.state.previewMsg}
                                      {/* Doc Preview not available */}
                                    </>
                                  )}
                                </div>
                                <div className="wnfy-citation-wrapper">
                                  {this.formatResults(
                                    this.state.responseArr[0]._source
                                  )}
                                </div>
                              </>
                            ) : (
                              <span className="wnfy-content-loader">
                                <img
                                  src={imsLoader}
                                  width="25"
                                  height="25"
                                  alt=""
                                />
                              </span>
                            )}
                          </div>
                        )}
                        {this.state.responseArr[1] !== undefined && (
                          <div
                            className="wnfy-tile-wrapper"
                            style={{
                              margin: this.props.customWidth >= 1000 && "0 1%",
                            }}
                          >
                            {!this.state.isProcessing ? (
                              <>
                                <div className="wnfy-preview-wrapper">
                                  {this.state.previewData2 !== `` ? (
                                    <img
                                      alt="Preview Data"
                                      src={`data:image/png;base64,${this.state.previewData2}`}
                                      style={{ width: "100%", height: "100%" }}
                                    />
                                  ) : (
                                    <>{this.state.previewMsg}</>
                                  )}
                                </div>
                                <div className="wnfy-citation-wrapper">
                                  {this.formatResults(
                                    this.state.responseArr[1]._source
                                  )}
                                </div>
                              </>
                            ) : (
                              <span className="wnfy-content-loader">
                                <img
                                  src={imsLoader}
                                  width="25"
                                  height="25"
                                  alt=""
                                />
                              </span>
                            )}
                          </div>
                        )}
                        {this.state.responseArr[2] !== undefined && (
                          <div
                            className="wnfy-tile-wrapper"
                            style={{
                              margin:
                                this.props.customWidth >= 1000 && "0 0 0 1%",
                            }}
                          >
                            {!this.state.isProcessing ? (
                              <>
                                <div className="wnfy-preview-wrapper">
                                  {this.state.previewData3 !== `` ? (
                                    <img
                                      alt="Preview Data"
                                      src={`data:image/png;base64,${this.state.previewData3}`}
                                      style={{ width: "100%", height: "100%" }}
                                    />
                                  ) : (
                                    <>{this.state.previewMsg}</>
                                  )}
                                </div>
                                <div className="wnfy-citation-wrapper">
                                  {this.formatResults(
                                    this.state.responseArr[2]._source
                                  )}
                                </div>
                              </>
                            ) : (
                              <span className="wnfy-content-loader">
                                <img
                                  src={imsLoader}
                                  width="25"
                                  height="25"
                                  alt=""
                                />
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="wnfy-no-product-content-section">
                        There are no new items that match your preferences.
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="wnfy-no-product-content-section">
                You don't have any product preference selected. Click{" "}
                {JSON.parse(localStorage.getItem("russianuser")) ? (
                  <Link
                    to="/register-role"
                    style={{ textDecoration: "none", color: "#1076bc" }}
                  >
                    here
                  </Link>
                ) : (
                  <Link
                    to={{
                      pathname: "/registration",
                      query: { toProducts: true },
                    }}
                    style={{ textDecoration: "none", color: "#1076bc" }}
                  >
                    here
                  </Link>
                )}{" "}
                to select your product preference.
              </div>
            )}
            {showPreview && previewData && (
              <DocumentPreview
                fileName={previewData.fileName}
                docTitle={previewData.docTitle}
                searchID={previewData.searchID}
                searchKeyword={""}
                synonyms={[]}
                /*searchID='1a08dc3a4077c024d071f88b33f97c22' */ showPreview={
                  showPreview
                }
                handleClose={this.handlePreviewClose}
              />
            )}
          </div>
        </div>
      );
    }
    else if (this.props.customWidth > 1000) {
      return (
        <div className="wnfy-outer-wrapper">
          <div className="wnfy-inner-wrapper-desktop">
            <div className="wnfy-wrapper-desktop">
              <div
                id="woavHeaderSection"
                className={
                  this.props.customWidth >= 1365
                    ? "wnfy-header-section-desktop woav-click-header-section"
                    : "wnfy-header-section-desktop wnfy-header-display-flex-section woav-click-header-section"
                } /* onClick={e => { this.setState({ showContent: !this.state.showContent }) }} */
              >
                {this.props.customWidth >= 1365 ? (
                  <>
                    <span className="wnfy-main-header-desktop woav-click-header-section">
                      What Others Are Viewing{" "}
                    </span>
                    {/* <span
                    style={{ color: "#0074B0", textDecoration: "none" }}
                    className="wnfy-header-text"
                  >
                    (Content Of Interest Of Your Colleagues)
                  </span> */}
                  </>
                ) : (
                  <>
                    <span className="wnfy-main-header-desktop woav-click-header-section">
                      What Others Are Viewing <br />
                      {/* <span
                      style={{ color: "#0074B0", textDecoration: "none" }}
                      className="wnfy-header-text"
                    > */}
                    </span>
                  </>
                )}

                <span className="wnfy-view-more-wrapper">
                  <div
                    className="viewMoreContainer"
                    style={{ visibility: userProducts === "" && "hidden" }}
                  >
                    <span
                      className="wnfy-view-more-header-text-desktop"
                      onClick={(e) => {
                        window.open(
                          "/WhatOthersAreViewing",
                          "",
                          `width=${window.innerWidth},height=${window.innerHeight},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no`
                        );
                      }}
                    >
                      View More
                    </span>
                    <div class="wnfy-view-more-right-arrow-icon-wrapper">
                      <img
                        src={viewMoreArrowBlue}
                        alt="right-arrow"
                        width={11}
                        height={14}
                        onClick={(e) => {
                          window.open(
                            "/WhatOthersAreViewing",
                            "",
                            `width=${window.innerWidth},height=${window.innerHeight},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no`
                          );
                        }}
                      /></div>
                  </div>
                  <img
                    src={iIconWhite}
                    alt="right-arrow"
                    style={{ height: "18px", width: "18px" }}
                    onMouseEnter={this.onMouseEnterInfo}
                    onMouseLeave={this.onMouseLeaveInfo}
                  />
                  {/* {!this.state.showContent ? (
                  <img
                    src={rightSideArrow}
                    alt=""
                    width={11}
                    height={14}
                    style={{ transform: "rotate(90deg)" }}
                    onClick={(e) => {
                      this.setState({ showContent: true });
                    }}
                  />
                ) : (
                  <img
                    src={rightSideArrow}
                    alt=""
                    width={11}
                    height={14}
                    style={{ transform: "rotate(-90deg)" }}
                    onClick={(e) => {
                      this.setState({ showContent: false });
                    }}
                  />
                )} */}
                </span>
              </div>
              {this.state.showInfo && (
                <div className="info-icon-wrapper">
                  <span className="tooltiptext tooltip-bottom">
                    This tile lists content that has been frequently viewed or
                    downloaded by those with the same preferred products as you.
                    Each item will appear for 5 days in this tile, but can be
                    accessed afterwards via the “View more” link at the top of the
                    tile.
                  </span>
                </div>
              )}
              {localStorage.getItem("userproducts") !== "" ? (
                <>
                  {this.state.showContent && (
                    <>
                      {this.state.responseArr !== undefined &&
                        this.state.responseArr.length > 0 ? (
                        <div className="wnfy-content-section">
                          {this.state.responseArr[0] !== undefined && (
                            <div
                              className="wnfy-tile-wrapper-desktop wnfy-tile-wrapper-border"
                              style={{
                                margin:
                                  this.props.customWidth >= 1000 && "5px 1% 0 0",
                              }}
                            >
                              {!this.state.isProcessing ? (
                                <>
                                  {this.formatPreviews(this.state.responseArr[0]._source, this.state.previewData1)}
                                  <div className="wnfy-citation-wrapper">
                                    {this.formatResults(
                                      this.state.responseArr[0]._source
                                    )}
                                  </div>
                                </>
                              ) : (
                                <span className="wnfy-content-loader">
                                  <img
                                    src={imsLoader}
                                    width="25"
                                    height="25"
                                    alt=""
                                  />
                                </span>
                              )}
                            </div>
                          )}
                          {this.state.responseArr[1] !== undefined && (
                            <div
                              className="wnfy-tile-wrapper-desktop wnfy-tile-wrapper-border"
                              style={{
                                margin: this.props.customWidth >= 1000 && "5px 0 0 1%",
                              }}
                            >
                              {!this.state.isProcessing ? (
                                <>
                                  {this.formatPreviews(this.state.responseArr[1]._source, this.state.previewData2)}
                                  <div className="wnfy-citation-wrapper">
                                    {this.formatResults(
                                      this.state.responseArr[1]._source
                                    )}
                                  </div>
                                </>
                              ) : (
                                <span className="wnfy-content-loader">
                                  <img
                                    src={imsLoader}
                                    width="25"
                                    height="25"
                                    alt=""
                                  />
                                </span>
                              )}
                            </div>
                          )}
                          {this.state.responseArr[2] !== undefined && (
                            <div
                              className="wnfy-tile-wrapper-desktop"
                              style={{
                                margin:
                                  this.props.customWidth >= 1000 && "5px 0 0 1%",
                              }}
                            >
                              {!this.state.isProcessing ? (
                                <>
                                  {this.formatPreviews(this.state.responseArr[2]._source, this.state.previewData3)}
                                  <div className="wnfy-citation-wrapper">
                                    {this.formatResults(
                                      this.state.responseArr[2]._source
                                    )}
                                  </div>
                                </>
                              ) : (
                                <span className="wnfy-content-loader">
                                  <img
                                    src={imsLoader}
                                    width="25"
                                    height="25"
                                    alt=""
                                  />
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="wnfy-no-product-content-section" style={{ height: '264px' }}>
                          There are no new items that match your preferences.
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <div className="wnfy-no-product-content-section">
                  You don't have any product preference selected. Click{" "}
                  {JSON.parse(localStorage.getItem("russianuser")) ? (
                    <Link
                      to="/register-role"
                      style={{ textDecoration: "none", color: "#1076bc" }}
                    >
                      here
                    </Link>
                  ) : (
                    <Link
                      to={{
                        pathname: "/registration",
                        query: { toProducts: true },
                      }}
                      style={{ textDecoration: "none", color: "#1076bc" }}
                    >
                      here
                    </Link>
                  )}{" "}
                  to select your product preference.
                </div>
              )}
              {showPreview && previewData && (
                <DocumentPreview
                  fileName={previewData.fileName}
                  docTitle={previewData.docTitle}
                  searchID={previewData.searchID}
                  searchKeyword={""}
                  synonyms={[]}
                /*searchID='1a08dc3a4077c024d071f88b33f97c22' */ showPreview={
                    showPreview
                  }
                  handleClose={this.handlePreviewClose}
                />
              )}
            </div>
          </div>
          <div className="queryHistory-outer-wrapper">
            <QueryHistorySection customWidth={this.props.customWidth} customHeight={this.props.customHeight} />
          </div>
        </div>
      );
    }
  }
}
export default WOAVSection;
