/* eslint-disable import/no-anonymous-default-export */
export default function(array){
    let arrStr=''
    //console.log(array)
    array.map((item,index)=>{
        if(index === array.length-1){
            arrStr+=item
        }
        else{
            arrStr+=item+"; "
        }
        return null
    })
    //console.log(arrStr)
    return arrStr
} 


const typeofMap = {
    'undefined' : 'Undefined',
    'boolean'   : 'Boolean',
    'number'    : 'Number',
    'string'    : 'String',
    'function'  : 'Function',
          
    'Undefined' : 'Undefined',
    'Null'      : 'Null',
    'Boolean'   : 'Boolean',
    'Number'    : 'Number',
    'String'    : 'String',
    'Function'  : 'Function',
    'Array'     : 'Array'
  };
      
  export const getVarType =  ( data )=> {
    var type, type_str;
    if ( data === null ) { return 'Null'; }
    if ( data === undefined ) { return 'Undefined'; }
      
    type     = typeof( data );
    type_str = typeofMap[ type ];
    if ( type_str ) { return type_str; }
      
    type = {}.toString.call( data ).slice( 8, -1 );
    return typeofMap[ type ]
      || ( data.propertyIsEnumerable(0) && data.length !== undefined
        ? 'Array' : 'Object'
      );
  }