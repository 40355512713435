/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { previewIcon, pushPinIcon, quickActionPinMobileIcon } from "../../assets/index"
import NlpSectionAnswerCard from "./NlpSectionAnswerCard"
function NlpDocumentCard(props) {
    const { item, index, customWidth, isLastItem } = props;
    const [isShowMore, setIsShowMore] = useState(false)
    useEffect(() => {
        setIsShowMore(false);
    }, [props.selectedDocType])
    const snippetLongString = (snippet) => {
        if (snippet.length > 300) {
            let text = "";
            let spanIndex = snippet.indexOf('<span class=');
            if (spanIndex !== -1) {
                text = snippet.substring(spanIndex, (spanIndex + 300))//cuts to spanIndex+300
                let last = text.lastIndexOf(" ")//gets last space (to avoid cutting the middle of a word)
                text = text.substring(0, last)//cuts from last space (to avoid cutting the middle of a word)
            } else {
                text = snippet.substring(0, 300)//cuts to 70
                let last = text.lastIndexOf(" ")//gets last space (to avoid cutting the middle of a word)
                text = text.substring(0, last)//cuts from last space (to avoid cutting the middle of a word)
            }
            //text = text + ` ...`//adds (...) at the end to show that it's cut
            return text
        }
        return snippet;
    }
    const convertToHtml = (item) => {
        let snipeerHtml = ""
        if (!isShowMore) {
            snipeerHtml = snippetLongString(item);
        } else {
            snipeerHtml = item;
        }
        // return (<div dangerouslySetInnerHTML={{ __html: item }}></div>)
        return (<div className='npl-document-content-wrapper'><div dangerouslySetInnerHTML={{ __html: snipeerHtml }}></div>
            {(item.length > 300) && <>{isShowMore ? <span className='nlp-show-more-less' onClick={() => setIsShowMore(!isShowMore)}>
                Show Less
            </span> : <span className='nlp-show-more-less' onClick={() => setIsShowMore(!isShowMore)}>
                Show More
            </span>}</>}

        </div>)
    }
    const renderSectionAnswer = (items) => {
        if (items) {
            return (<div style={{ display: "block" }}>
                {
                    items.map((ans, index) => {
                        return <NlpSectionAnswerCard sectionAnswer={ans.sectionAnswer} key={`renderSectionAnswer-${index}`} />
                    })
                }
            </div>)
        } else {
            return (<></>);
        }
    }

    if (customWidth >= 600 && customWidth <= 1000) {
        return (<div className="nlp-left-for-data">
            <div className='nlp-citation-details'>
                <div className="nlp-results-title-action-outer-wrapper-1">
                    <span className="nlp-results-div-docTitle-wrapper">
                        <span style={{ color: "#0074B0", fontSize: "14px", fontWeight: "bold", whiteSpace: "nowrap" }}>{index + 1}. </span>
                        <span>
                            <Link style={{ color: "#0074B0", fontSize: "14px", fontWeight: "bold" }} target="_blank" className="nlp-doc-title-link" to={{ pathname: item.downloadUrl }}>
                                {item.docTitle}
                            </Link>
                        </span>
                    </span><br />
                    <div id={'1'} className="nlp-citation-results-meta-property-wrapper">
                        <div className="nlp-PHM-MetaDataProperty-1">
                            {item.sectionAnswer && <div style={{ display: "block" }}>
                                <div className="nlp-for-first-line">{convertToHtml(item.sectionAnswer.replace(/(\<br[\s]*\/?\>[\s]*)+/g, '<br>'))}</div>
                            </div>}
                            {/* <div style={{ display: "block" }}>
                                <div className="nlp-for-first-line">{convertToHtml(item.sectionAnswer.replace(/(\<br[\s]*\/?\>[\s]*)+/g, '<br>'))}</div>
                            </div> */}
                            {item.sectionAnswers && renderSectionAnswer(item.sectionAnswers)}
                        </div>
                        <div className="nlp-PHM-MetaDataProperty-2" >
                            <div>
                                <label>Document No:&nbsp;</label>
                                <b>
                                    {item.documentNumber}
                                </b>
                            </div>
                        </div>
                        <div style={{ display: "block" }}>
                            <label>Year:&nbsp;</label>
                            <b>
                                {item.year}
                            </b>
                        </div>
                    </div>
                </div>
                <div className="nlp-right-for-icon">
                    <div className='nlp-parent-of-result-feature-icon-wrapper'>
                        <div className="nlp-result-feature-icon-wrapper-pin">
                            <img src={quickActionPinMobileIcon} alt="" title="Pin to Reading List" className='nlp-pin-img' width={18} height={13} />
                            <div className="nlp-action-items-title" onClick={() => { props.setShowRLHandel(item) }}>
                                Pin
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='nlp-resultsDevFooter-1' style={{ display: isLastItem ? 'none' : '' }}></div>
        </div>)
    }
    else if (customWidth > 1000) {
        return (<div className="nlp-left-for-data">
            <div className='nlp-citation-details'>
                <div className="nlp-results-title-action-outer-wrapper-1">
                    <span className="nlp-results-div-docTitle-wrapper">
                        <span style={{ color: "#0074B0", fontSize: "14px", fontWeight: "bold", whiteSpace: "nowrap" }}>{index + 1}. </span>
                        <span>
                            <Link style={{ color: "#0074B0", fontSize: "14px", fontWeight: "bold" }} target="_blank" className="nlp-doc-title-link" to={{ pathname: item.downloadUrl }}>
                                {item.docTitle}
                            </Link>
                        </span>
                    </span><br />
                    <div id={'1'} className="nlp-citation-results-meta-property-wrapper">
                        <div className="nlp-PHM-MetaDataProperty-1">
                            {item.sectionAnswer && <div style={{ display: "block" }}>
                                <div className="nlp-for-first-line">{convertToHtml(item.sectionAnswer.replace(/(\<br[\s]*\/?\>[\s]*)+/g, '<br>'))}</div>
                            </div>}
                            {item.sectionAnswers && renderSectionAnswer(item.sectionAnswers)}
                            {/* <div style={{ display: "block" }}>

                                <div className="nlp-for-first-line">{convertToHtml(item.sectionAnswer.replace(/(\<br[\s]*\/?\>[\s]*)+/g, '<br>'))}</div>
                            </div> */}
                        </div>
                        <div className="nlp-PHM-MetaDataProperty-2">
                            <div>
                                <label>Document No:&nbsp;</label>
                                <b>
                                    {item.documentNumber}
                                </b>
                            </div>
                        </div>
                        <div style={{ display: "block" }}>
                            <label>Year:&nbsp;</label>
                            <b>
                                {item.year}
                            </b>
                        </div>
                    </div>
                </div>
                <div className="nlp-right-for-icon">
                    <div className='nlp-parent-of-result-feature-icon-wrapper'>

                        <div className="nlp-result-feature-icon-wrapper" onClick={() => (props.setPreviewHandel(item))}>
                            <img src={previewIcon} alt="" title="Preview" width={18} height={13} />
                            <div className="nlp-action-items-title" onClick={() => (props.setPreviewHandel(item))}>
                                Preview
                            </div>
                        </div>


                        <div className="nlp-result-feature-icon-wrapper-pin">
                            <img src={pushPinIcon} alt="" title="Pin to Reading List" className='nlp-pin-img' width={18} height={13} />
                            <div className="nlp-action-items-title" onClick={() => { props.setShowRLHandel(item) }}>
                                Pin
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='nlp-resultsDevFooter-1' style={{ display: isLastItem ? 'none' : '' }}></div>
        </div>)

    }


}
export default NlpDocumentCard;