import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import threadingService from "../../services/threadingService";
import { UserData } from "../../auth/Authorization";
import Pagination from "../Paginate/Pagination";
import { imsLoader } from "../../assets";
import GenAILibraryCollectionItem from "./GenAILibraryCollectionItem";
import { GenAIAnsweringContext } from './GenAIAnsweringContextProvider';
import ConfirmMessagePopUpModal from "../PopUp/ConfirmMessagePopUpModal"
import "./index.css"

class GenAILibraryCollectionsList extends Component {
    static contextType = GenAIAnsweringContext;
    constructor(props) {
        super(props);
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            itemsPerPage: "10",
            currentPage: "1",
            documentCount: 0,
            isProcessing: false,
            openedContextMenuId: this.props.openedContextMenuId,
            confirmMessageContent: '',
            confirmMessageShowModal: false,
            confirmMessageId: -1,

        }
    }
    componentDidMount = async () => {
        await this.context.getAnswerCollectionsDataByUser();
    }
    handleOnOpenedContextMenuClick = (_id) => {
        this.setState({ openedContextMenuId: _id })
        this.props.handleDeleteAllThreadshideMenu();
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.openedContextMenuId !== this.state.openedContextMenuId) {
            this.setState({ openedContextMenuId: nextProps.openedContextMenuId })
        }
    }

    showConfirmMessage = (message, id) => {
        this.setState({
            confirmMessageContent: message,
            confirmMessageShowModal: true,
            confirmMessageId: id
        })
    }

    confirmHandler = async () => {
        this.setState(
            {

                confirmMessageContent: "",
                confirmMessageShowModal: false,
                // confirmMessageId: -1
            },
            async () => {
                this.setState({ confirmMessageId: -1 });
            }
        );
    }

    closeConfirmMessage = () => {
        this.setState({
            confirmMessageContent: "",
            confirmMessageShowModal: false,
            confirmMessageIndex: -1
        })
    }

    renderModeCollectionHistory() {
        let { libraryCollectionResponse } = this.context
        return (<>
            {
                libraryCollectionResponse.map((collectionItem, collectionIndex) => {
                    return (<GenAILibraryCollectionItem
                        collectionItem={collectionItem}
                        key={`Collection-History${collectionItem.collection_id}`} />)
                })
            }
        </>)
    }
    render() {
        let { isProcessing } = this.state;
        let { libraryCollectionResponse, libraryCollectionDocumentCount,libraryCollectionCurrentPage } = this.context;
        const { confirmMessageContent, confirmMessageShowModal } = this.state;
        if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.props.customWidth > 1000) {
            return (<div className="gen-ai-answering-library-collections-list-wrapper">
                {(isProcessing || this.context.isLibraryCollectionProcessing) ?
                    <div style={{ textAlign: "center", marginBottom: "10px" }}>
                        <img src={imsLoader} alt="Loading Filters" style={{ width: "10%", marginTop: "200px" }} />
                    </div> :
                    libraryCollectionResponse.length > 0 ? <>
                        <div className="gen-ai-answering-library-collections-lists">
                            <div>
                                {this.renderModeCollectionHistory()}
                            </div>
                        </div>

                        <Pagination
                            metadata={libraryCollectionDocumentCount}
                            handlePageChange={this.handlePageChange}
                            itemsPerPage={this.state.itemsPerPage}
                            page={parseInt(libraryCollectionCurrentPage)}
                            customWidth={this.state.width}
                            customHeight={this.state.height}
                        />
                    </> : <div className="gen-ai-answering-library-collections-list">
                        <div className="gen-ai-answering-library-collections-list-no-threads">No collections yet</div>
                    </div>
                }
                {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
            </div>
            )
        }
    }

    handleNextClick = () => {
        let { libraryCollectionDocumentCount } = this.context;
        try {
            if (
                this.state.currentPage <
                Math.ceil(libraryCollectionDocumentCount / this.state.itemsPerPage)
            ) {
                // if (this.state.currentPage <= 1) {
                this.setState(
                    {
                        currentPage: parseInt(this.state.currentPage) + 1,
                    },
                    () => {
                        this.handlePageChange(this.state.currentPage);
                    }
                );
            }
        } catch (error) {
            this.setState({ error: error });
        }
    };
    handlePreviousClick = () => {
        try {
            if (this.state.currentPage >= 2) {
                this.setState(
                    {
                        currentPage: this.state.currentPage - 1,
                    },
                    () => {
                        this.handlePageChange(this.state.currentPage);
                    }
                );
            }
        } catch (error) {
            this.setState({ error: error });
        }
    };
    navigateToLastPage = () => {
        let { libraryCollectionDocumentCount } = this.context;
        try {
            this.setState(
                {
                    currentPage: Math.ceil(
                        libraryCollectionDocumentCount / this.state.itemsPerPage
                    ),
                },
                () => {
                    this.handlePageChange(this.state.currentPage);
                }
            );
        } catch (error) {
            this.setState({ error: error });
        }
    };
    navigateToFirstPage = () => {
        try {
            this.setState(
                {
                    currentPage: 1,
                },
                () => {
                    this.handlePageChange(this.state.currentPage);
                }
            );
        } catch (error) {
            this.setState({ error: error });
        }
    };
    handlePageChange = (currentPage) => {
        try {
            window.scrollTo({ behavior: "smooth", top: "0px" });
            this.setState(
                {
                    currentPage: currentPage,
                },
                async () => {
                    await this.context.updatelibraryCollectionCurrentPage(currentPage)
                    await this.context.getAnswerCollectionsDataByUser();
                }
            );

        } catch (error) {
            this.setState({ error: error });
        }
    };

}
export default withRouter(GenAILibraryCollectionsList);
