import PropTypes from 'prop-types'
import React from "react"

export default function Bar(props) {
  const { 
    barWidth, 
    barHeight, 
    color, 
    docCount,
    x,
    y, 
    year,
    isYearInSelectionRange
  } = props
  
  return (
    <g>
      <rect 
        data-for="bars"
        data-tip={year + ": "+ docCount}
        fill={isYearInSelectionRange?"url('#myGradient')" : color}
        height={barHeight}
        stroke="white"
        width={barWidth} 
        x={x}
        y={y}
      />
    </g>
  )
}

Bar.propTypes ={
  barWidth: PropTypes.string.isRequired,
  barHeight: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  docCount:PropTypes.number.isRequired,
  x: PropTypes.string.isRequired,
  y:PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
}