import { Component } from "react";
import TherapeuraticArea from "./Products/TherapeuraticArea";
import { products, productIconBlack, viewMoreArrow, arrowIconBlack } from '../../assets';
import Button from '../../components/Button';
import MobileViewButton from "../../components/Button/MobileViewButton";
import AlertMessagePopUpModal from "../../components/PopUp/AlertMessagePopUpModal"
class ProductPreference extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCountry: [],
            selectedRegion: [],
            gdprAcceptance: '',
            profession: '',
            allProducts: [],
            //selectedProducts:[],
            selectedClasses: [],
            selectedAreas: [],
            products: [],
            contentPreference: "Yes",
            documentPreference: [],
            selectedArea: '',
            areaId: [],
            classId: [],
            productId: [],
            history: this.props.history,
            alertMessageModelColor: "",
            alertMessageContent: "",
            alertMessageShowModal: false,
        }
    }
    componentDidMount() {
        //console.log(this.props);
        this.setState({
            /* allProducts:res.data, */
            selectedCountry: this.props.selectedCountry,
            selectedRegion: this.props.selectedRegion,
            gdprAcceptance: this.props.gdprAcceptance,
            profession: this.props.profession,
            /* products: this.props.products, */
            contentPreference: this.props.contentPreference,
            documentPreference: this.props.documentPreference

        });
        if (this.props.selectedAreas.length > 0) {
            this.setState({
                selectedAreas: this.props.selectedAreas
            })
        }
        if (this.props.selectedClasses.length > 0) {
            this.setState({
                selectedClasses: this.props.selectedClasses
            })
        }
        if (this.props.products.length > 0) {
            this.setState({
                products: this.props.products
            })
        }
        if (this.props.areaId.length > 0) {
            this.setState({
                areaId: this.props.areaId
            });
        }
        if (this.props.classId.length > 0) {
            this.setState({
                classId: this.props.classId
            });
        }
        if (this.props.productId.length > 0) {
            this.setState({
                productId: this.props.productId
            });
        }

    }
    errorAlertMessage = (message) => {
        this.setState({
          alertMessageContent: message,
          alertMessageModelColor: "#de1327",
          alertMessageShowModal: true
        })
      }
    
      closeAlertMessage = () => {
        this.setState({
          alertMessageContent: "",
          alertMessageModelColor: "",
          alertMessageShowModal: false
        })
      }
    
    handleProductNxt = () => {
        //console.log(this.state);
        if (this.state.products.length > 30) {
            this.errorAlertMessage(`You have selected ${this.state.products.length} products, Please limit your product selection within 30`);
        }
        else {
            this.props.onClickNextOfDocument(this.state.selectedRegion, this.state.selectedCountry, this.state.gdprAcceptance, this.state.profession, this.state.products, this.state.contentPreference, this.state.documentPreference, this.state.selectedAreas, this.state.selectedClasses, this.state.history, this.state.areaId, this.state.classId, this.state.productId);
        }
    }
    handleProductPrv = () => {
        if (this.state.products.length > 30) {
            this.errorAlertMessage(`You have selected ${this.state.products.length} products, Please limit your product selection within 30`);
        }
        else {
            this.props.onClickPreviousToFunc(this.state.selectedRegion, this.state.selectedCountry, this.state.gdprAcceptance, this.state.profession, this.state.products, this.state.contentPreference, this.state.documentPreference, this.state.selectedAreas, this.state.selectedClasses, this.state.history, this.state.areaId, this.state.classId, this.state.productId);
        }
    }
    handleOnChange = (event) => {
        this.setState({
            contentPreference: event.target.value
        }, this.handleChangeInProduct);
    }
    handleSelectAreas = (selectedAreas, selectedClasses, products, areaId, classId, productId) => {
        this.setState({
            selectedAreas: selectedAreas,
            selectedClasses: selectedClasses,
            products: products,
            areaId: areaId,
            classId: classId,
            productId: productId
        }, this.handleChangeInProduct)
        //console.log(this.state.selectedAreas,this.state.selectedClasses,this.state.products);
    }
    handleChangeInProduct = () => {
        this.props.onChangeInProducts(this.state.products, this.state.productId, this.state.selectedClasses, this.state.classId, this.state.selectedAreas, this.state.areaId, this.state.contentPreference)
    }
    render() {
        const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
        if (this.props.customWidth < 1000) {
            return (
                <>
                <div className="registrationInnerWrapper">
                    {/* <div className="welcomePageTitle">
                    </div> */}
                    <div className="productSelectContent">
                        <div className="productsTitlePart">
                            <img src={productIconBlack} alt="products-iocn" />
                            <div className="productTitle">Product Preferences</div>
                        </div>
                        {/* <div className="registrationSub-heading">
                        <b className="locationTextTitle">Streamline your searches</b>
                        </div> */}
                        <p>
                            iMedical Search will personalize your search results based on your therapeutic area and product preferences. Please follow the 2 steps below.
                        </p>
                        <div className="stepPart">
                            <div className="steparrow">
                                <img src={arrowIconBlack} alt="step-arrow" width="12" height="12" />
                            </div>
                            <div style={{ fontWeight: "bold", fontStyle: "italic", fontSize: "15px", color: "#333" }} className="steps">
                                Step 1
                            </div>
                        </div>
                        <div className="step1-content">
                            Select your therapeutic area(s) of interest, then select your preferred products (if you have responsibility for all therapeutic areas, or simply have no particular preferences, you don't need to select any products). You may choose up to 30 products.
                        </div>
                        <br />
                        <div className="note-content">
                            PLEASE NOTE: Not all products searchable via iMedical Search (iMS) are listed below. This list includes only those products where there is enough content accessible via iMS to take advantage of the features and functionality that are driven by your Product Preferences.
                        </div>
                        {/* </div>
                    <div className="productSelectContent"> */}
                        <div className="TherapeuraticContainer">
                            <TherapeuraticArea selectedAreas={this.state.selectedAreas}
                                selectedClasses={this.state.selectedClasses}
                                products={this.state.products}
                                areaId={this.state.areaId}
                                classId={this.state.classId}
                                productId={this.state.productId}
                                onSelectAreas={this.handleSelectAreas}
                                customWidth={this.props.customWidth}
                                customHeight={this.props.customHeight} />
                        </div>
                        {/* <br /> */}
                        <div className="stepPart">
                            <div className="steparrow">
                                <img src={arrowIconBlack} alt="step-arrow" width="12" height="12" />
                            </div>
                            <div style={{ fontWeight: "bold", fontStyle: "italic", fontSize: "15px", color: "#333" }} className="steps">
                                Step 2
                            </div>
                        </div>
                        <div className="step1-content">
                            Select one of the two options below:
                        </div>
                        <div className="step2-input">
                            <input type="radio" className="" name="fullContent" value="Yes" onChange={this.handleOnChange} checked={this.state.contentPreference === 'Yes'} style={{ lineHeight: "Normal" }} class="k-radio" />
                            <span className="" style={{ margin: "10px 15px" }}>I want to include the full collection of content, but want content related to my product preferences <span style={{ textDecoration: "underline" }}>boosted</span> in the search results</span><br />

                            <input type="radio" className="" name="preferredContent" value="No" onChange={this.handleOnChange} checked={this.state.contentPreference === 'No'} style={{ lineHeight: "Normal" }} class="k-radio" />
                            <span className="" style={{ margin: "10px 15px" }}>I want to include <span style={{ textDecoration: "underline" }}>only</span> the content that specifically relates to my preferred products when I search</span>
                        </div>
                        <div><br /></div>
                        {this.props.customWidth < 600 ?
                            <div className="gsf-popup-button-wrapper-mobile">
                                <div className="welcomeNxtBtn-mobile">
                                    <MobileViewButton text='Continue' customColor='#2EB000' action={this.handleProductNxt} className="continueButton" />
                                </div>
                                <div className="welcomeNxtBtn-mobile">
                                    <MobileViewButton text='Previous' customColor='#FF8000' action={this.handleProductPrv} />
                                </div>
                                <div className="welcomeNxtBtn-mobile">
                                    <MobileViewButton text='Cancel' customColor='#0074B0' action={() => { this.props.history.push('/') }} />
                                </div>
                            </div>
                            :
                            <div className="buttonContainer">
                                <div className="welcomeNxtBtn">
                                    <Button text='Continue' customColor='#2EB000' action={this.handleProductNxt} className="continueButton" />
                                </div>
                                <div className="welcomeNxtBtn">
                                    <Button text='Previous' customColor='#FF8000' action={this.handleProductPrv} />
                                </div>
                                <div className="welcomeNxtBtn">
                                    <Button text='Cancel' customColor='#0074B0' action={() => { this.props.history.push('/') }} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={()=>this.closeAlertMessage()} />}
                </>
            )
        }
        else {
            return (
                <>
                <div className="registrationInnerWrapper">
                    {/* <div className="welcomePageTitle">
                </div> */}
                    <div className="productSelectContent">
                        <div className="productsTitlePart">
                            <img src={products} alt="products-iocn" />
                            <div className="productTitle">Product Preferences</div>
                        </div>
                        {/* <div className="registrationSub-heading">
                    <b className="locationTextTitle">Streamline your searches</b>
                    </div> */}
                        <p>
                            iMedical Search will personalize your search results based on your therapeutic area and product preferences. Please follow the 2 steps below.
                        </p>
                        <div className="stepPart">
                            <div className="steparrow">
                                <img src={viewMoreArrow} alt="step-arrow" width="12" height="12" />
                            </div>
                            <div style={{ fontWeight: "bold", fontStyle: "italic", fontSize: "15px", color: "#333" }} className="steps">
                                Step 1
                            </div>
                        </div>
                        <div className="step1-content">
                            Select your therapeutic area(s) of interest, then select your preferred products (if you have responsibility for all therapeutic areas, or simply have no particular preferences, you don't need to select any products). You may choose up to 30 products.
                        </div>
                        <br />
                        <div className="note-content">
                            PLEASE NOTE: Not all products searchable via iMedical Search (iMS) are listed below. This list includes only those products where there is enough content accessible via iMS to take advantage of the features and functionality that are driven by your Product Preferences.
                        </div>
                    </div>
                    <div className="productSelectContent">
                        <div className="TherapeuraticContainer">
                            <TherapeuraticArea selectedAreas={this.state.selectedAreas}
                                selectedClasses={this.state.selectedClasses}
                                products={this.state.products}
                                areaId={this.state.areaId}
                                classId={this.state.classId}
                                productId={this.state.productId}
                                onSelectAreas={this.handleSelectAreas} />
                        </div>
                        {/* <br /> */}
                        <div className="stepPart">
                            <div className="steparrow">
                                <img src={viewMoreArrow} alt="step-arrow" width="12" height="12" />
                            </div>
                            <div style={{ fontWeight: "bold", fontStyle: "italic", fontSize: "15px", color: "#333" }} className="steps">
                                Step 2
                            </div>
                        </div>
                        <div className="step1-content">
                            Select one of the two options below:
                        </div>
                        <div className="step2-input">
                            <input type="radio" className="" name="fullContent" value="Yes" onChange={this.handleOnChange} checked={this.state.contentPreference === 'Yes'} style={{ lineHeight: "Normal" }} class="k-radio" />
                            <span className="" style={{ margin: "10px 15px" }}>I want to include the full collection of content, but want content related to my product preferences <span style={{ textDecoration: "underline" }}>boosted</span> in the search results</span><br />

                            <input type="radio" className="" name="preferredContent" value="No" onChange={this.handleOnChange} checked={this.state.contentPreference === 'No'} style={{ lineHeight: "Normal" }} class="k-radio" />
                            <span className="" style={{ margin: "10px 15px" }}>I want to include <span style={{ textDecoration: "underline" }}>only</span> the content that specifically relates to my preferred products when I search</span>
                        </div>
                        <div><br /></div>
                        <div className="buttonContainer">
                            <div className="welcomeNxtBtn">
                                <Button text='Continue' customColor='#2EB000' action={this.handleProductNxt} className="continueButton" />
                            </div>
                            <div className="welcomeNxtBtn">
                                <Button text='Previous' customColor='#FF8000' action={this.handleProductPrv} />
                            </div>
                            <div className="welcomeNxtBtn">
                                <Button text='Cancel' customColor='#0074B0' action={() => { this.props.history.push('/') }} />
                            </div>
                        </div>
                    </div>
                </div>
                {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={()=>this.closeAlertMessage()} />}
                </>
            )
        }
    }
}
export default ProductPreference;