const config = {
    ssoURL: 'http://awsaidnva1146:5985/',
    IMS_API_PREFIX: 'https://predev-ims.jnj.com/api/',
    IMS_ANALYTICS_API_URL: 'https://predev-ims.jnj.com/api/analytics/',
    IMS_API_URL: 'https://predev-ims.jnj.com/api/db/runquery',
    SESSION_TIMEOUT: '1800',
    c19Products: [
        { 'title': 'Copy Review Pieces', 'property': 'parentLink', 'filter': 'Document Type/Copy Review Pieces/Convention Items/Convention Panel;Document Type/Copy Review Pieces/Convention Items/Event Support Materials;Document Type/Copy Review Pieces/Letters - e-Mails - Newsletters;Document Type/Copy Review Pieces/Presentations;Document Type/Copy Review Pieces/Press or Media Materials/PR Materials;Document Type/Copy Review Pieces/Press or Media Materials/Press releases and message maps;Document Type/Copy Review Pieces/Press or Media Materials/Social Media Programs;Document Type/Copy Review Pieces/Press or Media Materials/Social media postings, media backgrounders and fact sheets;Document Type/Copy Review Pieces/Training Materials;Disease States/Infectious Diseases/Coronavirus' },
        { 'title': 'Medical Literature', 'property': 'parentLink', 'filter': 'Document Type/Medical Literature/Abstracts;Document Type/Medical Literature/Oral Presentations;Document Type/Medical Literature/Posters;Document Type/Medical Literature/Publications;Disease States/Infectious Diseases/Coronavirus' },
        { 'title': 'Medical Literature Compilations', 'property': 'parentLink', 'filter': 'Document Type/Medical Literature Compilations/Bibliographies;Document Type/Medical Literature Compilations/Literature Awareness Bulletins;Document Type/Medical Literature Compilations/Publication Alerts;Document Type/Medical Literature Compilations/SKM EMEA Highlighted Data;Disease States/Infectious Diseases/Coronavirus' },
        { 'title': 'Scientific Responses', 'property': 'parentLink', 'filter': 'Document Type/Scientific Response Documents/Scientific Responses;Disease States/Infectious Diseases/Coronavirus' },
        { 'title': 'Slide Decks', 'property': 'parentLink', 'filter': 'Document Type/Slide Decks /Core Slide Decks;Document Type/Slide Decks/North America/Proactive Decks for Dissemination;Document Type/Slide Decks/North America/Proactive Decks for Presentation;Document Type/Slide Decks/North America/Reactive Decks for Dissemination;Document Type/Slide Decks/North America/Reactive Decks for Presentation;Disease States/Infectious Diseases/Coronavirus' },
        { 'title': 'Proactive Decks for Dissemination', 'property': 'childLink', 'filter': 'Document Type/Slide Decks/North America/Proactive Decks for Dissemination;Disease States/Infectious Diseases/Coronavirus' },
        { 'title': 'Reactive Decks for Dissemination', 'property': 'childLink', 'filter': 'Document Type/Slide Decks/North America/Reactive Decks for Dissemination;Disease States/Infectious Diseases/Coronavirus' },
        { 'title': 'Reactive Decks for Presentation', 'property': 'childLink', 'filter': 'Document Type/Slide Decks/North America/Reactive Decks for Presentation;Disease States/Infectious Diseases/Coronavirus' },
        { 'title': 'Submission Related Documents', 'property': 'parentLink', 'filter': 'Document Type/Submission-Related Documents;Disease States/Infectious Diseases/Coronavirus' },
        { 'title': 'Clinical Study Reports', 'property': 'childLink', 'filter': 'Document Type/Submission-Related Documents/Clinical Study Reports;Disease States/Infectious Diseases/Coronavirus' },
        { 'title': `Investigator's Brochures`, 'property': 'childLink', 'filter': `Document Type/Submission-Related Documents/Investigator's Brochures;Disease States/Infectious Diseases/Coronavirus` },
        { 'title': 'Protocols', 'property': 'childLink', 'filter': 'Document Type/Submission-Related Documents/Protocols;Disease States/Infectious Diseases/Coronavirus' },
        { 'title': 'Training, Congress and Internal Materials', 'property': 'parentLink', 'filter': 'Disease States/Infectious Diseases/Coronavirus;Document Type/Training, Congress and Internal Materials' }
    ],
    srcsDocuments: [
        { 'title': 'Clinical Study Reports', 'filter': 'Document Type/Submission-Related Documents/Clinical Study Reports' },
        { 'title': 'Integrated Safety Summaries', 'filter': 'Document Type/Submission-Related Documents/Integrated Summary of Safety Reports' },
        { 'title': 'Investigator Brochures', 'filter': "Document Type/Submission-Related Documents/Investigator's Brochures" },
        { 'title': 'Protocols', 'filter': 'Document Type/Submission-Related Documents/Protocols' },
        { 'title': 'PSURs', 'filter': 'Document Type/Submission-Related Documents/Periodic Safety Update Reports' },
        { 'title': 'Risk Management Plan-Core', 'filter': 'Document Type/Clinical Safety Documents/Risk Management Plan-Core' },
        { 'title': 'Risk Management Plan-EU', 'filter': 'Document Type/Clinical Safety Documents/Risk Management Plan-EU' },
        { 'title': 'Risk Management Plan-Progress Reports', 'filter': 'Document Type/Clinical Safety Documents/Risk Management Plan-Progress Reports' },
        { 'title': 'Statistical Analysis Plans', 'filter': 'Document Type/Submission-Related Documents/Statistical Analysis Plans' },
    ],
    etDocuments: [
        { 'title': 'Core Slide Decks', 'filter': 'Document Type/Slide Decks/Core Slide Decks' },
        { 'title': 'FAQs (Q&A)', 'filter': 'Document Type/Training, Congress and Internal Materials/Q%26A' },
        { 'title': 'Medical Education Event Materials', 'filter': 'Document Type/Medical Education Event Materials' },
        { 'title': 'Training', 'filter': 'Document Type/Training, Congress and Internal Materials/Training' },
        { 'title': 'Objection Handlers', 'filter': 'Document Type/Training, Congress and Internal Materials/Objection Handler' },
        { 'title': 'Videos', 'filter': 'Document Type/Training, Congress and Internal Materials/Videos' },
    ],
    routesofAandD: [
        { 'title': 'Medical Literature (Congress Materials)', 'filter': 'Document Type/Medical Literature/Abstracts;Document Type/Medical Literature/Oral Presentations;Document Type/Medical Literature/Posters' },
        { 'title': 'Medical Literature (Publications)', 'filter': 'Document Type/Medical Literature/Publications' },
        { 'title': 'Scientific Responses', 'filter': 'Document Type/Scientific Response Documents' },
        { 'title': 'Slide Decks', 'filter': 'Document Type/Slide Decks' },
        { 'title': 'Submission-Related Documents', 'filter': 'Document Type/Submission-Related Documents' },
        { 'title': 'Training Materials', 'filter': 'Document Type/Training, Congress and Internal Materials' },
    ],
    labelingDocuments: [
        { 'title': 'Labeling Documents - Core, EU, US', 'property': 'parentLink', 'partition': 'leftPart', 'filter': 'Document Type/Labeling Documents - Core, EU, US' },
        { 'title': 'Company Core Data Sheets (CCDS)', 'property': 'childLink', 'partition': 'leftPart', 'filter': 'Document Type/Labeling Documents - Core, EU, US/Core Labeling/Company Core Data Sheets' },
        { 'title': 'Company Core Safety Information (CCSI)', 'property': 'childLink', 'partition': 'leftPart', 'filter': 'Document Type/Labeling Documents - Core, EU, US/Core Labeling/Company Core Safety Information' },
        { 'title': 'Core Patient Infomation Leaflet (CPIL)', 'property': 'childLink', 'partition': 'leftPart', 'filter': 'Document Type/Labeling Documents - Core, EU, US/Core Labeling/Core Patient Information Leaflet' },
        { 'title': 'EU PI', 'property': 'childLink', 'partition': 'leftPart', 'filter': 'Document Type/Labeling Documents - Core, EU, US/EU Labeling (CP, MRP, DCP only)/EUPI' },
        { 'title': 'EU Patient Labeling', 'property': 'childLink', 'partition': 'leftPart', 'filter': 'Document Type/Labeling Documents - Core, EU, US/EU Labeling (CP, MRP, DCP only)/Patient Labeling' },
        { 'title': 'EU SmPC', 'property': 'childLink', 'partition': 'leftPart', 'filter': 'Document Type/Labeling Documents - Core, EU, US/EU Labeling (CP, MRP, DCP only)/SmPC' },
        { 'title': 'US PI', 'property': 'childLink', 'partition': 'leftPart', 'filter': 'Document Type/Labeling Documents - Core, EU, US/US Labeling/Physician Insert' },
        { 'title': 'US Patient Labeling', 'property': 'childLink', 'partition': 'leftPart', 'filter': 'Document Type/Labeling Documents - Core, EU, US/US Labeling/Patient Labeling' },
        { 'title': 'Labeling Documents - Rest of World', 'property': 'parentLink', 'partition': 'rightPart', 'filter': 'Document Type/Labeling Documents - Rest of World' },
        { 'title': 'ASPAC Labeling', 'property': 'childLink', 'partition': 'rightPart', 'filter': 'Document Type/Labeling Documents - Rest of World/ASPAC Labeling' },
        { 'title': 'EMEA Labeling', 'property': 'childLink', 'partition': 'rightPart', 'filter': 'Document Type/Labeling Documents - Rest of World/EMEA Labeling' },
        { 'title': 'LATAM Labeling', 'property': 'childLink', 'partition': 'rightPart', 'filter': 'Document Type/Labeling Documents - Rest of World/LATAM Labeling' },
        { 'title': 'North America Labeling', 'property': 'childLink', 'partition': 'rightPart', 'filter': 'Document Type/Labeling Documents - Rest of World/North America Labeling' },
        { 'title': 'World Health Organization Labeling', 'property': 'childLink', 'partition': 'rightPart', 'filter': 'Document Type/Labeling Documents - Rest of World/World Health Organization Labeling' }
    ],
    kmlDocuments: [
        { 'title': 'Key Medical Literature - JNJ Products (last 3 months)', 'filter': 'Document Type/Medical Literature' },
        { 'title': 'Key Medical Literature - Competitor Products (last 3 months)', 'filter': '' },
        { 'title': 'Congress Holding Pages (collections of congress materials from key congresses)', 'filter': 'Document Type/Medical Literature Compilations/SKM EMEA Highlighted Data' },
        { 'title': 'Post-Congress Reports', 'filter': 'Document Type/Training, Congress and Internal Materials/Congress Materials %26 Reports' },
        { 'title': 'Publication Alerts', 'filter': 'Document Type/Medical Literature Compilations/Publication Alerts' },
        { 'title': 'SKM EMEA Highlighted Data', 'filter': 'Document Type/Medical Literature Compilations/SKM EMEA Highlighted Data' },
        { 'title': 'Publication Plans', 'filter': 'Document Type/Medical Literature Compilations/SKM EMEA Highlighted Data' },
    ],
    scdProducts: [
        { 'title': 'Scientific Responses', 'property': 'parentLink', 'filter': 'Document Type/Scientific Response Documents/Scientific Responses;Regions/United States;Regions/Canada;Regions/EMEA;Regions/ASPAC;Regions/LATAM' },
        { 'title': 'FAQs (Q&A)', 'property': 'parentLink', 'filter': 'Document Type/Training, Congress and Internal Materials/Q%26A' },
        { 'title': 'Slide Decks', 'property': 'parentLink', 'filter': 'Document Type/Slide Decks' },
        { 'title': 'Core Slide Decks', 'property': 'childLink', 'filter': 'Document Type/Slide Decks/Core Slide Decks' },
        { 'title': 'Evidence and Access Slide Decks', 'property': 'childLink', 'filter': 'Document Type/Slide Decks' },
        { 'title': 'Proactive Decks for Dissemination', 'property': 'childLink', 'filter': 'Document Type/Slide Decks/North America/Proactive Decks for Dissemination' },
        { 'title': 'Proactive Decks for Presentation', 'property': 'childLink', 'filter': 'Document Type/Slide Decks/North America/Proactive Decks for Presentation' },
        { 'title': 'Reactive Decks for Dissemination', 'property': 'childLink', 'filter': 'Document Type/Slide Decks/North America/Reactive Decks for Dissemination' },
        { 'title': 'Reactive Decks for Presentation', 'property': 'childLink', 'filter': 'Document Type/Slide Decks/North America/Reactive Decks for Presentation' },
        { 'title': 'USPIs', 'property': 'parentLink', 'filter': 'Document Type/Labeling Documents - Core, EU, US/US Labeling/Physician Insert' },
        { 'title': 'EUPIs/SmPCs', 'property': 'parentLink', 'filter': 'Document Type/Labeling Documents - Core, EU, US/EU Labeling (CP, MRP, DCP only)/EUPI;Document Type/Labeling Documents - Core, EU, US/EU Labeling (CP, MRP, DCP only)/SmPC' }
    ],
    copyReviewPieces: [
        { 'title': 'Copy Review Pieces', 'property': 'parentLink', 'filter': 'Document Type/Copy Review Pieces' },
        { 'title': 'Presentations', 'property': 'childLink', 'filter': 'Document Type/Copy Review Pieces/Presentations' },
        { 'title': 'Training Materials', 'property': 'childLink', 'filter': 'Document Type/Copy Review Pieces/Training Materials' },
        { 'title': 'Convention Items', 'property': 'childLink', 'filter': 'Document Type/Copy Review Pieces/Convention Items' },
        { 'title': 'Letters-eMails-Newsletters', 'property': 'childLink', 'filter': 'Document Type/Copy Review Pieces/Letters - e-Mails - Newsletters' },
        { 'title': 'Press/Media Materials', 'property': 'childLink', 'filter': 'Document Type/Copy Review Pieces/Press or Media Materials' }
    ],
    tandDashboards: [
        { 'title': 'Alzheimer’s Disease', 'area': 'CNS', 'link': 'https://signal4ci.com/dashboard.php?id=47' },
        { 'title': 'Bipolar Disorder', 'area': 'CNS', 'link': 'https://signal4ci.com/dashboard.php?id=48' },
        { 'title': 'Depression', 'area': 'CNS', 'link': 'https://signal4ci.com/dashboard.php?id=5' },
        { 'title': 'Depression/Mood (US BI Team)', 'area': 'CNS', 'link': 'https://signal4ci.com/dashboard.php?id=828' },
        { 'title': 'Schizophrenia', 'area': 'CNS', 'link': 'https://signal4ci.com/dashboard.php?id=50' },
        { 'title': 'Cardiovascular (US BI Team)', 'area': 'CVM', 'link': 'https://signal4ci.com/dashboard.php?id=157' },
        { 'title': 'Diabetes Type 1', 'area': 'CVM', 'link': 'https://signal4ci.com/dashboard.php?id=871' },
        { 'title': 'Diabetes Type 2', 'area': 'CVM', 'link': 'https://signal4ci.com/dashboard.php?id=871' },
        { 'title': 'Diabetes Type 2 (US BI Team)', 'area': 'CVM', 'link': 'https://signal4ci.com/dashboard.php?id=164' },
        { 'title': 'Diabetic Nephropathy', 'area': 'CVM', 'link': 'https://signal4ci.com/dashboard.php?id=20' },
        { 'title': 'Nonalcoholic Steatohepatitis', 'area': 'CVM', 'link': 'https://signal4ci.com/dashboard.php?id=196' },
        { 'title': 'Obesity', 'area': 'CVM', 'link': 'https://signal4ci.com/dashboard.php?id=871' },
        { 'title': 'Pulmonary Arterial Hypertension', 'area': 'CVM', 'link': 'https://signal4ci.com/dashboard.php?id=806' },
        { 'title': 'Coronavirus', 'area': 'Infectious Disease', 'link': 'https://signal4ci.com/dashboard.php?id=1234' },
        { 'title': 'Hepatitis B Virus', 'area': 'Infectious Disease', 'link': 'https://signal4ci.com/dashboard.php?id=74' },
        { 'title': 'Hepatitis C Virus', 'area': 'Infectious Disease', 'link': 'https://signal4ci.com/dashboard.php?id=75' },
        { 'title': 'HIV', 'area': 'Infectious Disease', 'link': 'https://signal4ci.com/dashboard.php?id=14' },
        { 'title': 'Influenza', 'area': 'Infectious Disease', 'link': 'https://signal4ci.com/dashboard.php?id=13' },
        { 'title': 'Respiratory Syncytial Virus', 'area': 'Infectious Disease', 'link': 'https://signal4ci.com/dashboard.php?id=12' },
        { 'title': 'Ankylosing Spondylitis', 'area': 'Immunology', 'link': 'https://signal4ci.com/dashboard.php?id=34' },
        { 'title': 'Inflammatory Bowel Disease (IBD)', 'area': 'Immunology', 'link': 'https://signal4ci.com/dashboard.php?id=123' },
        { 'title': 'Lupus/Lupus Nephritis', 'area': 'Immunology', 'link': 'https://signal4ci.com/dashboard.php?id=41' },
        { 'title': 'Psoriasis', 'area': 'Immunology', 'link': 'https://signal4ci.com/dashboard.php?id=42' },
        { 'title': 'Psoriatic Arthritis', 'area': 'Immunology', 'link': 'https://signal4ci.com/dashboard.php?id=43' },
        { 'title': 'Rheumatoid Arthritis', 'area': 'Immunology', 'link': 'https://signal4ci.com/dashboard.php?id=44' },
        { 'title': 'Acute Lymphocytic Leukemia (ALL)', 'area': 'Oncology', 'link': 'https://signal4ci.com/dashboard.php?id=97' },
        { 'title': 'Acute Myelogenous Leukemia/Myelodysplastic Syndromes (AML/MDS)', 'area': 'Oncology', 'link': 'https://signal4ci.com/dashboard.php?id=52' },
        { 'title': 'Colorectal Cancer', 'area': 'Oncology', 'link': 'https://signal4ci.com/dashboard.php?id=871' },
        { 'title': 'Myeloproliferative Neoplasms (MPN)', 'area': 'Oncology', 'link': 'https://signal4ci.com/dashboard.php?id=871' },
        { 'title': 'Multiple Myeloma', 'area': 'Oncology', 'link': 'https://signal4ci.com/dashboard.php?id=60' },
        { 'title': 'NHL: Diffuse Large B Cell Lymphoma', 'area': 'Oncology', 'link': 'https://signal4ci.com/dashboard.php?id=64' },
        { 'title': 'Non-Small Cell Lung Cancer', 'area': 'Oncology', 'link': 'https://signal4ci.com/dashboard.php?id=69' },
        { 'title': 'Prostate Cancer', 'area': 'Oncology', 'link': 'https://signal4ci.com/dashboard.php?id=6' },
        { 'title': 'CV/Metabolism: GBI', 'area': 'TA Dashboards', 'link': 'https://signal4ci.com/dashboard.php?id=17' },
        { 'title': 'Hematologic Malignancies: GBI', 'area': 'TA Dashboards', 'link': 'https://signal4ci.com/dashboard.php?id=871' },
        { 'title': 'Immunology: GBI', 'area': 'TA Dashboards', 'link': 'https://signal4ci.com/dashboard.php?id=16' },
        { 'title': 'Immuno-Oncology: GBI', 'area': 'TA Dashboards', 'link': 'https://signal4ci.com/dashboard.php?id=46' },
        { 'title': 'Infectious Disease & Vaccines: GBI', 'area': 'TA Dashboards', 'link': 'https://signal4ci.com/dashboard.php?id=15' },
        { 'title': 'Neuroscience: GBI', 'area': 'TA Dashboards', 'link': 'https://signal4ci.com/dashboard.php?id=4' },
        { 'title': 'Oncology: GBI', 'area': 'TA Dashboards', 'link': 'https://signal4ci.com/dashboard.php?id=18' },
        { 'title': 'Ophthalmology (Retina): J Retina', 'area': 'TA Dashboards', 'link': 'https://signal4ci.com/dashboard.php?id=871' },
        { 'title': 'Thrombosis', 'area': 'TA Dashboards', 'link': 'https://signal4ci.com/dashboard.php?id=79' },
    ],
    iMedicalInsightsReports: [
        { 'title': 'Lung Cancer', 'partition': 'leftPart', 'infoTitle': 'This IRO covers the lung cancer landscape with a focus on RYBREVANT.' },
        { 'title': 'Vaccines', 'partition': 'leftPart', 'infoTitle': 'This IRO covers the portfolio of vaccines but is currently focused on the JANSSEN COVID-19 VACCINE.' },
        { 'title': 'Prostate Cancer', 'partition': 'leftPart', 'infoTitle': 'This IRO covers the prostate cancer landscape with a focus on ERLEADA.' },
        { 'title': 'Multiple Myeloma', 'partition': 'leftPart', 'infoTitle': 'This IRO covers the multiple myeloma disease state and focuses on DARZALEX and DARZALEX FASPRO.' },
        { 'title': 'CAR-T', 'partition': 'leftPart', 'infoTitle': 'This IRO covers cilta-cel (BCMA CAR-T). Focusing first on Multiple Myeloma CAR-T, however, as more products are available it will cover them all.' },
        { 'title': 'Pulmonary Hypertension', 'partition': 'leftPart', 'infoTitle': 'This IRO covers Pulmonary Hypertension with a focus on OPSUMIT and UPTRAVI.' },
        { 'title': 'Dermatology/Rheumatology', 'partition': 'leftPart', 'infoTitle': 'This IRO covers TREMFYA with a focus on both Dermatology and Rheumatology indications.' },
        { 'title': 'Multiple Sclerosis', 'partition': 'rightPart', 'infoTitle': 'This IRO covers multiple sclerosis with a focus on PONVORY.' },
        { 'title': 'Mood', 'partition': 'rightPart', 'infoTitle': 'This IRO covers the mood aspect of neuroscience with a focus on SPRAVATO.' },
        { 'title': 'HIV', 'partition': 'rightPart', 'infoTitle': 'This IRO covers the HIV disease state with a focus on our darunavir products (SYMTUZA, PRESZISTA, and PREZCOBIX).' },
        { 'title': 'Gastroenterology', 'partition': 'rightPart', 'infoTitle': 'This IRO covers STELARA with a focus on Gastroenterology indications.' },
        { 'title': 'Schizophrenia', 'partition': 'rightPart', 'infoTitle': 'This IRO covers Schizophrenia and focuses on INVEGA SUSTENNA, INVEGA TRINZA, and INVEGA HAFYERA.' },
        { 'title': 'Cardiovascular', 'partition': 'rightPart', 'infoTitle': 'This IRO covers the cardiovascular aspect of CVMR with a focus on XARELTO.' },
        { 'title': 'Diversity, Equity, and Inclusion', 'partition': 'rightPart', 'infoTitle': 'This cross-therapeutic area  covers diversity and inclusion in clinical trials across the Janssen portfolio with a focus on field representation' }
    ]
}
export default config;