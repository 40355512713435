import React, { Component, createRef } from "react";
import { withRouter } from 'react-router-dom';
import "./index.css"
import GenAISidebar from "./GenAISidebar"
import GenAISearchIndex from "./GenAISearchIndex"

class GenAISearchHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
        }
    }

    render() {
        if (this.props.customWidth >= 600 && this.props.customWidth <= 1000) {
            return (<></>)
        }
        else if (this.props.customWidth > 1000) {
            return (<div className="gen-ai-answering-container">
                <GenAISidebar customWidth={this.state.customWidth} customHeight={this.state.customHeight} />
                <GenAISearchIndex customWidth={this.state.customWidth} customHeight={this.state.customHeight} />
            </div>)
        }
    }
}
export default withRouter(GenAISearchHome);