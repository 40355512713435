/* eslint-disable no-useless-escape */
import { Link } from "react-router-dom";
import trackingService from "../../services/trackingService";
import "./History.css";
function QueryHistory(props) {

  
  const snippetLongString = (snippet) => {
    if (snippet.length > 30) {
      let text = snippet.substring(0, 30)//cuts to 70
      text = text.substring(0, 30)//cuts from last space (to avoid cutting the middle of a word)
      text = text + `...`//adds (...) at the end to show that it's cut
      return text
    }
    return snippet;
  }
  const renderQueryHistoryTitle = (text) => {
    if(text){
      text=text.replace(/Recently Added_Updated\//g,'Recently Added/Updated/')
    }
    if (text && text.includes('ǂ$±@')) {
      let splitTests = text.split('ǂ$±@');
      return `(Reading List: ${splitTests[0]}): ${splitTests[1]}`
    } else {
      return text;
    }
  }
  const renderQueryHistoryText = (text) => {
    if(text){
      text=text.replace(/Recently Added_Updated\//g,'Recently Added/Updated/')
    }
    if (text && text.includes('ǂ$±@')) {
      let splitTests = text.split('ǂ$±@');
      return `${snippetLongString(splitTests[0])}: ${splitTests[1]}`
    } else {
      return text;
    }
  }
  const RenderList = () => {
    const { Result } = props;
    const handleClick = (itm, url) => {
      const ts = new trackingService();
      itm = itm.replace(/(")/g, '\\"');
      ts.indexTrackingData(
        localStorage.getItem("userdisplayName"),
        localStorage.getItem("wwid"),
        localStorage.getItem("username"),
        localStorage.getItem("function"),
        localStorage.getItem("region"),
        localStorage.getItem("userproducts"),
        localStorage.getItem("doctypepref"),
        localStorage.getItem("sid"),
        localStorage.getItem("deviceType"),
        localStorage.getItem("deviceOS"),
        localStorage.getItem("browserName"),
        localStorage.getItem("browserVersion"),
        localStorage.getItem("jnjDepartment"),
        localStorage.getItem("companyname"),
        // "Search History Component",
        "Conducted Search",
        "Search History Component",
        url,
        // "/results" + window.location.search,
        // this.state.searchKeyword,
        itm,
        props.appliedFilters,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        props.csPath !== null ? props.csPath : "",
        props.csproduct !== null ? props.csproduct : ""
      );
    };
    if (Result.length > 0) {
      return (
        <ul className="ul-query-history">
          {Result.map((item, index) => {
            if (item.length > 1) {
              let url = item[1];
              var baseUrlPattern = /^https?:\/\/[a-z\:0-9.\-]+/;
              var result = "";
              var match = baseUrlPattern.exec(url);
              if (match != null) {
                result = match[0];
              }
              if (result.length > 0) {
                url = url.replace(result, "");
              }
              item[1] = url;
            }
            return (
              <li key={`QueryHistory-${index}`} className="resultHistorysDiv">
                {item.length > 1 ? (
                  <>
                    <Link
                      id={`PopupQueryHistory-${index}`}
                      title={renderQueryHistoryTitle(item[0])}
                      // title={item[0]}
                      to={item[1]}
                      target="_blank"
                      onClick={() => {
                        handleClick(item[0], item[1]);
                      }}
                      style={{
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        width: "100%",
                        minHeight: "20px",
                      }}
                    >
                      {/* {item[0]} */}
                      {renderQueryHistoryText(item[0])}
                    </Link>
                  </>
                ) : (
                  <>
                    <Link
                      id={`PopupQueryHistory-${index}`}
                      title={item.keyword}
                      to={`/results?query=${item.keyword}&q=All Fields&filters=`}
                      target="_blank"
                      onClick={() => {
                        handleClick(item.keyword);
                      }}
                      style={{
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        width: "100%",
                        minHeight: "20px",
                      }}
                    >
                      {item.keyword}
                    </Link>
                  </>
                )}
              </li>
            );
          })}
        </ul>
      );
    } else {
      return (
        <ul>
          <li>No queries in history.</li>
        </ul>
      );
    }
  };

  return (
    <>
      <b style={{ color: "#333" }}> Search History: </b>
      {RenderList()}
    </>
  );
}

export default QueryHistory;
