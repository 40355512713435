/* eslint-disable array-callback-return */
import { Component } from "react";
import HeaderWithBanner from "../../components/header/HeaderWithBanner";
import Footer from '../../components/footer/Footer';
import { imsLoader, saveBlueIcon, printBlueIcon, emailBlueIcon, downloadBlueIcon, saveWhiteIcon, printWhiteIcon, emailWhiteIcon, downloadWhiteIcon, backToAllResults, collapseAllIcon, deleteIcon, leftArrowWhite, collapseAllIconWhite, deleteWhite } from '../../assets'
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import NavigationMenu from "../../components/Navigation/NavigationMenu";
import GlobalSearchFeaturePopUpModal from "../../components/PopUp/GlobalSearchFeaturesPopUpModal";
import DocumentCard from "../DocumentResults/DocumentCard";
import Services from "../../utils/services";
import './ViewSelectedResults.css';

class ViewSelectedResults extends Component {
    userid;
    userExists;
    documentsUrl = [];
    markedDocumentsUrl = [];
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            userExists: false,
            width: window.innerWidth,
            height: window.innerHeight,
            selectedCitation: [],
            documentIndex: [],
            itemTeaser: [],
            showSaveModal: false,
            showPrintModal: false,
            showEmailModal: false,
            showDownloadModal: false,
            showGSFModal: false,
            citationSelectStatus: [],
            selectedResults: [],
            isCollapseAll: false,
            collapsedAll: [],
            displayedResults: []
        }
    }
    componentDidMount() {
        document.title = 'iMedical Search: View Marked Results';
        this.userid = JSON.parse(localStorage.getItem('username'));
        this.userExists = localStorage.getItem('userExists');
        let service = new Services();
        service.logService(`View selectes page successfully mounted for ${this.userid.toLowerCase()}`, 'info')
        let citationSelectStatus = [];
        let selectedCitation = [];
        let documentIndex = [];
        let itemTeaser = [];
        let selectedResults = [];
        let collapsedAll = [];
        if (this.userExists) {
            this.setState({
                userExists: this.userExists
            })
            if (this.props.location.state?.selectedResults !== undefined) {
                localStorage.getItem('selectedResults').split(',').map((x, i) => {
                    selectedCitation.push(this.props.location.state.selectedResults[i].detail);
                    documentIndex.push(this.props.location.state.selectedResults[i].index);
                    itemTeaser.push(this.props.location.state.selectedResults[i].itemTeaser);
                    selectedResults.push(this.props.location.state.selectedResults[i]);
                    citationSelectStatus.push(true);
                    this.markedDocumentsUrl = JSON.parse(localStorage.getItem('markedResultsUrl'))
                    this.setState({
                        selectedCitation: selectedCitation,
                        documentIndex: documentIndex,
                        itemTeaser: itemTeaser,
                        selectedResults: selectedResults,
                        citationSelectStatus: citationSelectStatus
                    })
                })
            }
            for (let index = 0; index < this.props.location.state.selectedResults.length; index++) {
                collapsedAll.push(false)
            }
            this.setState({ collapsedAll: collapsedAll })
            window.addEventListener('resize', this.updateDimensions);
        }
        else {
            console.log('Access Denied');
            this.props.history.push('/');
        }
        // let citationSelectStatus = [];
        // // console.log(localStorage.getItem('selectedResults'),this.props.location.state.selectedResults.map(x => x.index))
        // // let data = this.state.selectedResults;
        // let selectedCitation = [];
        // let documentIndex = [];
        // let itemTeaser = [];
        // let selectedResults = [];
        // if (this.props.location.state?.selectedResults !== undefined) {
        //     localStorage.getItem('selectedResults').split(',').map((x, i) => {
        //         selectedCitation.push(this.props.location.state.selectedResults[i].detail);
        //         documentIndex.push(this.props.location.state.selectedResults[i].index);
        //         itemTeaser.push(this.props.location.state.selectedResults[i].itemTeaser);
        //         selectedResults.push(this.props.location.state.selectedResults[i]);
        //         citationSelectStatus.push(true)
        //         this.setState({
        //             selectedCitation: selectedCitation,
        //             documentIndex: documentIndex,
        //             itemTeaser: itemTeaser,
        //             selectedResults: selectedResults,
        //             citationSelectStatus: citationSelectStatus
        //         }/* , () => {
        //                     for (let index = 0; index < this.state.selectedCitation.length; index++) {
        //                         citationSelectStatus.push(true)
        //                     }
        //                     this.setState({ citationSelectStatus: citationSelectStatus })
        //                 } */)
        //     })
        // }
        // let collapsedAll = [];
        // for (let index = 0; index < this.props.location.state.selectedResults.length; index++) {
        //     collapsedAll.push(false)
        // }
        // this.setState({ collapsedAll: collapsedAll })
    }
    // componentWillReceiveProps() {
    //     let citationSelectStatus = [];
    //     if (this.props.location.state?.selectedResults !== undefined) {
    //         // console.log(this.props.location.state.selectedResults)
    //         this.setState({
    //             selectedCitation: this.props.location.state.selectedResults.map(x => x.detail),
    //             documentIndex: this.props.location.state.selectedResults.map(x => x.index),
    //             itemTeaser: this.props.location.state.selectedResults.map(x => x.itemTeaser)
    //         }, () => {
    //             for (let index = 0; index < this.state.selectedCitation.length; index++) {
    //                 citationSelectStatus.push(true)
    //             }
    //             this.setState({ citationSelectStatus: citationSelectStatus })
    //         })
    //     }
    //     // console.log(this.state.selectedResults)
    // }
    // componentDidUpdate = (previousProps) => {
    //     // console.log(this.state.selectedResults,previousProps)
    //     let citationSelectStatus = [];
    //     if (this.state.selectedResults.length > 0 && this.state.selectedResults.length !== this.props.location.state.selectedResults.length) {
    //         this.setState({
    //             selectedCitation: this.state.selectedResults.map(x => x.detail),
    //             documentIndex: this.state.selectedResults.map(x => x.index),
    //             itemTeaser: this.state.selectedResults.map(x => x.itemTeaser)
    //         }, () => {
    //             for (let index = 0; index < this.state.selectedCitation.length; index++) {
    //                 citationSelectStatus.push(true)
    //             }
    //             this.setState({ citationSelectStatus: citationSelectStatus })
    //         })
    //     }
    // }
    componentWillUnmount() {
        let service = new Services();
        service.logService(`View selected page successfully unmounted for ${this.userid.toLowerCase()}`, 'info')
        window.removeEventListener('resize', this.updateDimensions);
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight })
    }
    generateDocumentTeaser = (teaserData) => {
        let str = ''
        if (teaserData.length > 0) {
            teaserData.map((item, index) => {
                str += item + "..."
            })
        }
        //console.log(str)
        //console.log(this.convertToHtml(str))
        return str;
    }
    handleCitationSelection = (index, item, itemTeaser, key) => {
        // console.log(index, item, itemTeaser,key,this.state.citationSelectStatus)
        let a = this.state.citationSelectStatus;
        a[key] = !this.state.citationSelectStatus[key];
        // a[index - 1] = !this.state.citationSelectStatus[index - 1];
        let data = this.state.selectedResults;
        // let data = this.props.location.state.selectedResults;
        let urls = this.markedDocumentsUrl;
        if (this.state.citationSelectStatus[key]) {
            data.push({
                detail: item,
                itemTeaser: itemTeaser,
                index: index
            })
            urls.push(this.documentsUrl[key])
        }
        else {
            const i = data.map(x => (x.index)).indexOf(index);
            if (i > -1) {
                data.splice(i, 1); // 2nd parameter means remove one item only
                urls.splice(i, 1);
            }
            // data.splice(index - 1, 1)
        }
        // let selectedResultsDetails = this.state.selectedResults;
        sessionStorage.setItem("selectedResultsDetails", JSON.stringify(data));
        let selectedResultsIndex = data.map(x => x.index)
        localStorage.setItem('selectedResults', selectedResultsIndex)
        localStorage.setItem('markedResultsUrl', JSON.stringify(urls))
        this.markedDocumentsUrl = urls;
        // console.log(data,a)
        this.setState({
            citationSelectStatus: a,
            selectedResults: data,
        })
    }
    citationUrl = (url, index, item) => {
        let a = this.documentsUrl;
        if (item.domain === "CTGov") {
            a[index] = url;
        }
        else {
            a[index] = window.location.origin + url;
            // a[index] = "https://predev-ims.jnj.com" + url;
        }
        this.documentsUrl = a;
    }
    convertToHtml = (item) => {
        return (<div dangerouslySetInnerHTML={{ __html: item }}></div>)
    }
    selectedResults = (results, itemTeaser, documentIndex) => {
        let displayedResults = []
        if (results?.length > 0) {
            return (
                <>
                    {results.map((details, index) => {
                        // if (itemTeaser[index].length <= 0) {
                        if (itemTeaser[index] === undefined) {
                            displayedResults.push({
                                detail: details,
                                itemTeaser: [],
                                // index: index+1
                                index: documentIndex[index],
                            })
                        }
                        else {
                            displayedResults.push({
                                detail: details,
                                itemTeaser: itemTeaser[index],
                                // index: index+1
                                index: documentIndex[index],
                            })
                        }
                        return (
                            <>
                                <div key={`result-id-${index}`} id={`result-id-${index}`}>
                                    {
                                        <div className="resultDetailsDiv">
                                            <DocumentCard item={details} count={documentIndex[index]} 
                                            appliedFilters={[]}
                                            /* itemTeaser={itemTeaser[index].length <= 0 ? [] : itemTeaser[index]} */ 
                                            itemTeaser={itemTeaser[index] === undefined ? [] : itemTeaser[index]} 
                                            handleCitationSelection={(i, item, itemTeaser, key) => this.handleCitationSelection(i, item, itemTeaser, index)} 
                                            isChecked={this.state.citationSelectStatus[index]} /* isCollapseAll={this.state.isCollapseAll} */ collapsedAll={this.state.collapsedAll} customWidth={this.state.width} handlecollapsed={() => this.handlecollapsed(index)} resultsIndex={index} citationUrl={(url) => this.citationUrl(url, index, details)} />
                                            <div className="resultsDivFooter">
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        )
                    })}
                    {localStorage.setItem('displayedResults', JSON.stringify(displayedResults))}
                </>
            )
        }
    }
    handleBackToResult = () => {
        this.handleClearSelection();
        this.props.history.goBack();
    }
    handlecollapsed = (index) => {
        let collapsedAll = this.state.collapsedAll;
        collapsedAll[index] = !this.state.collapsedAll[index];
        // console.log(collapsedAll)
        this.setState({ collapsedAll: collapsedAll })
        if (collapsedAll.every((val, i, arr) => val === true/* arr[0] */)) {
            this.setState({ isCollapseAll: true })
        }
        else {
            this.setState({ isCollapseAll: false })
        }
    }
    handleCollapseAll = () => {
        let collapsedAll = this.state.collapsedAll;
        // let data = this.props.location.state.selectedResults;
        for (let index = 0; index < collapsedAll.length; index++) {
            // collapsedAll[index] = !this.state.collapsedAll[index];
            if (this.state.isCollapseAll) {
                collapsedAll[index] = false
            }
            else {
                collapsedAll[index] = true
            }

            if (collapsedAll[index]) {
                this.setState({ isCollapseAll: true })
            }
            else {
                this.setState({ isCollapseAll: false })
            }
        }
        this.setState({ collapsedAll: collapsedAll })
        // this.setState({ isCollapseAll: !this.state.isCollapseAll })
    }
    handleClearSelection = () => {
        localStorage.removeItem('selectedResults')
        localStorage.removeItem('markedResultsUrl')
        sessionStorage.clear();
        this.markedDocumentsUrl = [];
        this.documentsUrl = [];
        this.setState({ citationSelectStatus: [], selectedResults: [] })
    }
    componentDidUpdate() {
        // console.log(this.state,"xx", this.props)
    }
    render() {
        return (
            <div>
                <ErrorBoundary>
                    {this.state.userExists ? <>
                        <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                        <div className="header-wrapper">
                            {this.state.width > 600 && <HeaderWithBanner />}
                        </div>
                        {
                            this.state.width < 600 ?
                                <div className="view-selected-top-bottom-container-mobile">
                                    {/* <div className="links-container"> */}
                                    <div className="features-buttons-mobile" onClick={this.handleBackToResult}>
                                        <img src={backToAllResults} alt="BackToAllResults" className="vs-img" width={15} height={15} />
                                        {/* <img src={leftArrowWhite} alt="BackToAllResults" className="vs-img-hov" width={15} height={15} /> */}
                                        <span>Back to All Results</span>
                                    </div>
                                    <div className="features-buttons-mobile" onClick={this.handleCollapseAll}>
                                        <img src={collapseAllIcon} alt="collapseAllIcon" className="vs-img" />
                                        {/* <img src={collapseAllIconWhite} alt="collapseAllIcon" className="vs-img-hov" /> */}
                                        <span>{this.state.isCollapseAll ? "Expand All" : "Collapse All"}</span>
                                    </div>
                                    <div className="features-buttons-mobile" onClick={this.handleClearSelection}>
                                        <img src={deleteIcon} alt="deleteIcon" className="vs-img" width={11} height={15} />
                                        {/* <img src={deleteWhite} alt="deleteIcon" className="vs-img-hov" width={11} height={15} /> */}
                                        <span>Clear Selection</span>
                                    </div>
                                    {/* <span className="vs-links" onClick={this.handleCollapseAll}>{this.state.isCollapseAll ? "Expand All" : "Collapse All"}</span>
                                <div className="links-sepration-blue"></div>
                                <Link className="vs-links" onClick={this.handleBackToResult}>Back to All Results</Link>
                                <div className="links-sepration-blue"></div>
                                <Link className="vs-links" onClick={this.handleClearSelection}>Clear Selection</Link> */}
                                    {/* </div>
                                    <div className="links-sepration-blue"></div>
                                    <div className="buttons-container"> */}
                                    {/* <div className="features-buttons" onClick={(e) => { this.setState({ showGSFModal: true, showSaveModal: true, showPrintModal: false, showEmailModal: false, showDownloadModal: false }) }} >
                                            <img src={saveBlueIcon} alt="save-icon" width={17} height={17} className="vs-img" //onMouseOver={e=>(e.currentTarget.src=saveWhiteIcon)} onMouseOut={e=>(e.currentTarget.src=saveBlueIcon)} />
                                            <img src={saveWhiteIcon} alt="save-icon" width={17} height={17} className="vs-img-hov" />
                                            <span>Save</span>
                                        </div> 
                                        <div className="features-buttons"  //onClick={(e) => { this.setState({ showGSFModal: true, showPrintModal: true, showSaveModal: false, showEmailModal: false, showDownloadModal: false }) }} >
                                            <img src={printBlueIcon} alt="print-icon" width={15} height={15} className="vs-img" />
                                            <img src={printWhiteIcon} alt="save-icon" width={15} height={15} className="vs-img-hov" />
                                            <span>Print</span>
                                        </div> */}
                                    <div className="features-buttons-mobile" onClick={(e) => { this.setState({ showGSFModal: true, showEmailModal: true, showSaveModal: false, showPrintModal: false, showDownloadModal: false }) }}>
                                        <img src={emailBlueIcon} alt="email-icon" width={20} height={20} className="vs-img" />
                                        {/* <img src={emailWhiteIcon} alt="save-icon" width={20} height={20} className="vs-img-hov" /> */}
                                        <span>Email</span>
                                    </div>
                                    {/* <div className="features-buttons" //onClick={(e) => { this.setState({ showGSFModal: true, showDownloadModal: true, showSaveModal: false, showPrintModal: false, showEmailModal: false }) }}>
                                            <img src={downloadBlueIcon} alt="download-icon" width={18} height={18} className="vs-img" />
                                            <img src={downloadWhiteIcon} alt="save-icon" width={18} height={18} className="vs-img-hov" />
                                            <span>Download</span>
                                        </div> */}
                                    {/* </div> */}
                                </div>
                                :
                                this.state.width < 1000 ?
                                    <div className="view-selected-top-bottom-container">
                                        {/* <div className="links-container"> */}
                                        <div className="features-buttons" onClick={this.handleBackToResult}>
                                            <img src={backToAllResults} alt="BackToAllResults" className="vs-img" width={15} height={15} />
                                            <img src={leftArrowWhite} alt="BackToAllResults" className="vs-img-hov" width={15} height={15} />
                                            <span>Back to All Results</span>
                                        </div>
                                        <div className="features-buttons" onClick={this.handleCollapseAll}>
                                            <img src={collapseAllIcon} alt="collapseAllIcon" className="vs-img" />
                                            <img src={collapseAllIconWhite} alt="collapseAllIcon" className="vs-img-hov" />
                                            <span>{this.state.isCollapseAll ? "Expand All" : "Collapse All"}</span>
                                        </div>
                                        <div className="features-buttons" onClick={this.handleClearSelection}>
                                            <img src={deleteIcon} alt="deleteIcon" className="vs-img" width={11} height={15} />
                                            <img src={deleteWhite} alt="deleteIcon" className="vs-img-hov" width={11} height={15} />
                                            <span>Clear Selection</span>
                                        </div>
                                        {/* <span className="vs-links" onClick={this.handleCollapseAll}>{this.state.isCollapseAll ? "Expand All" : "Collapse All"}</span>
                                <div className="links-sepration-blue"></div>
                                <Link className="vs-links" onClick={this.handleBackToResult}>Back to All Results</Link>
                                <div className="links-sepration-blue"></div>
                                <Link className="vs-links" onClick={this.handleClearSelection}>Clear Selection</Link> */}
                                        {/* </div>
                                    <div className="links-sepration-blue"></div>
                                    <div className="buttons-container"> */}
                                        {/* <div className="features-buttons" onClick={(e) => { this.setState({ showGSFModal: true, showSaveModal: true, showPrintModal: false, showEmailModal: false, showDownloadModal: false }) }} >
                                            <img src={saveBlueIcon} alt="save-icon" width={17} height={17} className="vs-img" //onMouseOver={e=>(e.currentTarget.src=saveWhiteIcon)} onMouseOut={e=>(e.currentTarget.src=saveBlueIcon)} />
                                            <img src={saveWhiteIcon} alt="save-icon" width={17} height={17} className="vs-img-hov" />
                                            <span>Save</span>
                                        </div> 
                                        <div className="features-buttons"  //onClick={(e) => { this.setState({ showGSFModal: true, showPrintModal: true, showSaveModal: false, showEmailModal: false, showDownloadModal: false }) }} >
                                            <img src={printBlueIcon} alt="print-icon" width={15} height={15} className="vs-img" />
                                            <img src={printWhiteIcon} alt="save-icon" width={15} height={15} className="vs-img-hov" />
                                            <span>Print</span>
                                        </div> */}
                                        <div className="features-buttons" onClick={(e) => { this.setState({ showGSFModal: true, showEmailModal: true, showSaveModal: false, showPrintModal: false, showDownloadModal: false }) }}>
                                            <img src={emailBlueIcon} alt="email-icon" width={20} height={20} className="vs-img" />
                                            <img src={emailWhiteIcon} alt="save-icon" width={20} height={20} className="vs-img-hov" />
                                            <span>Email</span>
                                        </div>
                                        {/* <div className="features-buttons" //onClick={(e) => { this.setState({ showGSFModal: true, showDownloadModal: true, showSaveModal: false, showPrintModal: false, showEmailModal: false }) }}>
                                            <img src={downloadBlueIcon} alt="download-icon" width={18} height={18} className="vs-img" />
                                            <img src={downloadWhiteIcon} alt="save-icon" width={18} height={18} className="vs-img-hov" />
                                            <span>Download</span>
                                        </div> */}
                                        {/* </div> */}
                                    </div>
                                    :
                                    <div className="view-selected-top-bottom-container">
                                        <div className="links-container">
                                            <div className="features-buttons" onClick={this.handleBackToResult}>
                                                <img src={backToAllResults} alt="BackToAllResults" className="vs-img" width={15} height={15} />
                                                <img src={leftArrowWhite} alt="BackToAllResults" className="vs-img-hov" width={15} height={15} />
                                                <span>Back to All Results</span>
                                            </div>
                                            <div className="features-buttons" onClick={this.handleCollapseAll}>
                                                <img src={collapseAllIcon} alt="collapseAllIcon" className="vs-img" />
                                                <img src={collapseAllIconWhite} alt="collapseAllIcon" className="vs-img-hov" />
                                                <span>{this.state.isCollapseAll ? "Expand All" : "Collapse All"}</span>
                                            </div>
                                            <div className="features-buttons" onClick={this.handleClearSelection}>
                                                <img src={deleteIcon} alt="deleteIcon" className="vs-img" width={11} height={15} />
                                                <img src={deleteWhite} alt="deleteIcon" className="vs-img-hov" width={11} height={15} />
                                                <span>Clear Selection</span>
                                            </div>
                                            {/* <span className="vs-links" onClick={this.handleCollapseAll}>{this.state.isCollapseAll ? "Expand All" : "Collapse All"}</span>
                                <div className="links-sepration-blue"></div>
                                <Link className="vs-links" onClick={this.handleBackToResult}>Back to All Results</Link>
                                <div className="links-sepration-blue"></div>
                                <Link className="vs-links" onClick={this.handleClearSelection}>Clear Selection</Link> */}
                                        </div>
                                        <div className="links-sepration-blue"></div>
                                        <div className="buttons-container">
                                            <div className="features-buttons" onClick={(e) => { this.setState({ showGSFModal: true, showSaveModal: true, showPrintModal: false, showEmailModal: false, showDownloadModal: false }) }} >
                                                <img src={saveBlueIcon} alt="save-icon" width={17} height={17} className="vs-img" /* onMouseOver={e=>(e.currentTarget.src=saveWhiteIcon)} onMouseOut={e=>(e.currentTarget.src=saveBlueIcon)} */ />
                                                <img src={saveWhiteIcon} alt="save-icon" width={17} height={17} className="vs-img-hov" />
                                                <span>Save</span>
                                            </div>
                                            <div className="features-buttons" onClick={(e) => { this.setState({ showGSFModal: true, showPrintModal: true, showSaveModal: false, showEmailModal: false, showDownloadModal: false }) }}>
                                                <img src={printBlueIcon} alt="print-icon" width={15} height={15} className="vs-img" />
                                                <img src={printWhiteIcon} alt="save-icon" width={15} height={15} className="vs-img-hov" />
                                                <span>Print</span>
                                            </div>
                                            <div className="features-buttons" onClick={(e) => { this.setState({ showGSFModal: true, showEmailModal: true, showSaveModal: false, showPrintModal: false, showDownloadModal: false }) }}>
                                                <img src={emailBlueIcon} alt="email-icon" width={20} height={20} className="vs-img" />
                                                <img src={emailWhiteIcon} alt="save-icon" width={20} height={20} className="vs-img-hov" />
                                                <span>Email</span>
                                            </div>
                                            <div className="features-buttons" onClick={(e) => { this.setState({ showGSFModal: true, showDownloadModal: true, showSaveModal: false, showPrintModal: false, showEmailModal: false }) }}>
                                                <img src={downloadBlueIcon} alt="download-icon" width={18} height={18} className="vs-img" />
                                                <img src={downloadWhiteIcon} alt="save-icon" width={18} height={18} className="vs-img-hov" />
                                                <span>Download</span>
                                            </div>
                                        </div>
                                    </div>
                        }
                        {/* <div className="view-selected-top-bottom-container">
                        //     <div className="links-container">
                        //         <div className="features-buttons" onClick={this.handleBackToResult}>
                        //             <img src={backToAllResults} alt="BackToAllResults" className="vs-img" width={15} height={15} />
                        //             <img src={leftArrowWhite} alt="BackToAllResults" className="vs-img-hov" width={15} height={15} />
                        //             <span>Back to All Results</span>
                        //         </div>
                        //         <div className="features-buttons" onClick={this.handleCollapseAll}>
                        //             <img src={collapseAllIcon} alt="collapseAllIcon" className="vs-img" />
                        //             <img src={collapseAllIconWhite} alt="collapseAllIcon" className="vs-img-hov" />
                        //             <span>{this.state.isCollapseAll ? "Expand All" : "Collapse All"}</span>
                        //         </div>
                        //         <div className="features-buttons" onClick={this.handleClearSelection}>
                        //             <img src={deleteIcon} alt="deleteIcon" className="vs-img" width={11} height={15} />
                        //             <img src={deleteWhite} alt="deleteIcon" className="vs-img-hov" width={11} height={15} />
                        //             <span>Clear Selection</span>
                        //         </div> */}
                        {/* <span className="vs-links" onClick={this.handleCollapseAll}>{this.state.isCollapseAll ? "Expand All" : "Collapse All"}</span>
                        //         <div className="links-sepration-blue"></div>
                        //         <Link className="vs-links" onClick={this.handleBackToResult}>Back to All Results</Link>
                        //         <div className="links-sepration-blue"></div>
                        //         <Link className="vs-links" onClick={this.handleClearSelection}>Clear Selection</Link> */}
                        {/* //     </div>
                        //     <div className="links-sepration-blue"></div>
                        //     <div className="buttons-container">
                        //         <div className="features-buttons" onClick={(e) => { this.setState({ showGSFModal: true, showSaveModal: true, showPrintModal: false, showEmailModal: false, showDownloadModal: false }) }} >
                        //             <img src={saveBlueIcon} alt="save-icon" width={17} height={17} className="vs-img" //onMouseOver={e=>(e.currentTarget.src=saveWhiteIcon)} onMouseOut={e=>(e.currentTarget.src=saveBlueIcon)} />
                        //             <img src={saveWhiteIcon} alt="save-icon" width={17} height={17} className="vs-img-hov" />
                        //             <span>Save</span>
                        //         </div>
                        //         <div className="features-buttons" //onClick={(e) => { this.setState({ showGSFModal: true, showPrintModal: true, showSaveModal: false, showEmailModal: false, showDownloadModal: false }) }} >
                        //             <img src={printBlueIcon} alt="print-icon" width={15} height={15} className="vs-img" />
                        //             <img src={printWhiteIcon} alt="save-icon" width={15} height={15} className="vs-img-hov" />
                        //             <span>Print</span>
                        //         </div>
                        //         <div className="features-buttons" //onClick={(e) => { this.setState({ showGSFModal: true, showEmailModal: true, showSaveModal: false, showPrintModal: false, showDownloadModal: false }) }}>
                        //             <img src={emailBlueIcon} alt="email-icon" width={20} height={20} className="vs-img" />
                        //             <img src={emailWhiteIcon} alt="save-icon" width={20} height={20} className="vs-img-hov" />
                        //             <span>Email</span>
                        //         </div>
                        //         <div className="features-buttons" //</>onClick={(e) => { this.setState({ showGSFModal: true, showDownloadModal: true, showSaveModal: false, showPrintModal: false, showEmailModal: false }) }}>
                        //             <img src={downloadBlueIcon} alt="download-icon" width={18} height={18} className="vs-img" />
                        //             <img src={downloadWhiteIcon} alt="save-icon" width={18} height={18} className="vs-img-hov" />
                        //             <span>Download</span>
                        //         </div>
                        //     </div>
                         </div> */}
                        <div className="view-selected-wrapper">
                            {this.selectedResults(this.state.selectedCitation, this.state.itemTeaser, this.state.documentIndex)}
                            <p>
                                <br />
                            </p>
                            <p>
                                <br />
                            </p>
                        </div>
                        <div style={{ margin: "0 1.5%" }}>
                            <Footer customWidth={this.state.width} customHeight={this.state.height} />
                        </div>
                        {
                            this.state.showGSFModal &&
                            <GlobalSearchFeaturePopUpModal showModal={this.state.showGSFModal} handleClose={(status) => { this.setState({ showGSFModal: status }) }} showSaveModal={this.state.showSaveModal} showPrintModal={this.state.showPrintModal} showEmailModal={this.state.showEmailModal} showDownloadModal={this.state.showDownloadModal} selectedResults={this.state.selectedResults} customWidth={this.state.width} customHeight={this.state.height} documentsUrl={this.documentsUrl} markedDocumentsUrl={this.markedDocumentsUrl} />
                        }
                    </>
                        :
                        <div style={{ textAlign: "center", padding: "10%" }}>
                            <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
                        </div>
                    }
                </ErrorBoundary>
            </div>

        )
    }
}
export default ViewSelectedResults;