import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
/* import Divider from './Divider' */
import FilterSelectModal from "./FilterSelectModal";
import {
  prdtNavButton,
  productNavButtonBlack,
} from "../../assets";
import "./FilterComponent.css";
import FilterHistogram from "./FilterHistogram";
import "./FilterHistogram.css";

export default function FilterMenu(props) {
  const {
    appliedFilters,
    filters,
    //fullDocRange,
    //handleOpenProductSelection,
    handleChangeFilterCategory,
    // handleClearFilters,
    filterCategory,
    isOpen,
    handleApply,
    handleCloseFilterSelect,
    handleSelectFilter,
    handleToggleFilter,
    openFilters,
    selectedFilters,
    selectedParentFilterArr,
    selectedParent,
    handleResetFilters,
    customWidth,
    recentlyUsedFilters,
    renderedFiltered,
    // handleYearSelection,
    //metadata,
    // gotoPage,
    //sourceType,
    // years,
    // yearSelection,
    popupActive,
    appliedFiltersForOnlyiMS,
    filterYears,
    fromYear,
    toYear,
    handleYearSelection,
    handlePageChange,
    fromRecentlyAddedUpdated,
    toRecentlyAddedUpdated,
    gotoPageRecentlyAddedUpdated,
    isError,
  } = props;

  const [yearSelection, setYearSelection] = useState({ min: 0, max: 0 });
  useEffect(() => {
    let tempYearSelection = {
      min: fromYear ? fromYear : 0,
      max: toYear ? toYear : 0,
    };
    setYearSelection(tempYearSelection);
    //}, [])
  }, [fromYear, toYear]);

  /**
   * Handle the click event of the product selection link
   *
   * @param  {Object} event
   * @param  {String} key
   */
  /* const openProductSelection = () => {
    handleOpenProductSelection()
  } */

  /**
   * Handle the click event of one of the filter categories links.
   *
   * @param  {Object} event
   * @param  {String} key
   */
  const changeFilterCategory = (event, key) => {
    event.preventDefault();
    handleChangeFilterCategory(key);
  };

  /**
   * Handle the click event of the clear-filters button.
   *
   * @param  {Object} event
   */
  // const clearFilters = (event) => {
  //   event.preventDefault();
  //   handleClearFilters();
  // };

  /**
   * Conditionally render the clear-all-filters button as necessary.
   *
   * @return {Object|null}
   */
  // const renderClearAllAppliedFilters = () => {
  //   return (
  //     <button
  //       className="filter-popup-button clickable"
  //       disabled={appliedFilters.length < 1}
  //       onClick={clearFilters}
  //     >
  //       <div className="filter-popup-button-text">Clear All Filters</div>
  //     </button>
  //   );
  // };
  const renderOnlyiMSFilter = () => {
    return appliedFiltersForOnlyiMS.map((filter) => {
      let replaceFilter = `${filter}`.replace(
        "Recently Added_Updated",
        "Recently Added/Updated"
      );
      return (
        <div class="ignored-filter-wrapper">
          <span class="ignored-text">{replaceFilter}</span>
        </div>
      );
    });
  };

  /* const renderProductFilter = () => {
    return (
      <div className='filter-item clickable'
           onClick={openProductSelection}>
        <div className='filter-text'>
          Products
        </div>
        <FontAwesomeIcon className='filter-chevron' icon="chevron-right" fixedWidth color='#979797' />
      </div>
    )
  } */
  const handleYear = (newYears) => {
    setYearSelection(newYears);
    handleYearSelection(newYears);
  };

  const gotoPage = (pageNumber) => {
    setTimeout(() => {
      handlePageChange(pageNumber);
    }, 500);
  };

  const renderFilterHistogram = () => {
    if (filterYears.length > 0 && toYear && fromYear && !isError) {
      let maxYear =
        filterYears[filterYears.length - 1].key > toYear
          ? filterYears[filterYears.length - 1].key
          : toYear;
      let minYear =
        filterYears[0].key < fromYear ? filterYears[0].key : fromYear;
      let fullDocRange = { maxYear: maxYear, minYear: minYear };
      return (
        <FilterHistogram
          customWidth={customWidth}
          fullDocRange={fullDocRange}
          years={filterYears}
          yearSelection={yearSelection}
          handleYearSelection={handleYear}
          gotoPage={gotoPage}
        />
      );
    } else {
      return <></>;
    }
  };
  let filterYearDocCount = filterYears
    .map((x) => x.doc_count)
    .reduce((partialSum, a) => partialSum + a, 0);
  const orderedFilters = Object.values(filters).sort((a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  });
  //console.log(popupActive);

  return (
    <>
      {orderedFilters.length > 0 ? (
        <>
          {/* <Divider /> */}
          {customWidth < 1000 ? (
            <>
              {customWidth < 600 ? (
                <div className="filter-container-mobile contained">
                  {!isOpen && (
                    <div style={{ width: "100%" }}>
                      {window.location.pathname === "/pubMedResults" &&
                        renderFilterHistogram()}
                      {orderedFilters.map((filter) => {
                        if (
                          filter.label === "Recently Added_Updated" &&
                          window.location.pathname === "/viewReadingList"
                        ) {
                          return "";
                        }
                        return (
                          <div
                            className={
                              selectedParent === filter.label
                                ? "select-filter-item filter-item clickable"
                                : selectedParentFilterArr.includes(filter.label)
                                ? "filter-text-selected filter-item clickable"
                                : "filter-item clickable"
                            }
                            key={filter.key}
                            onClick={(e) => changeFilterCategory(e, filter.key)}
                            title={
                              filter.key === "Recently Added_Updated"
                                ? "Recently Added/Updated"
                                : filter.key
                            }
                          >
                            <div className="filter-text">
                              {filter.label === "Recently Added_Updated" ? (
                                <>Recently Added/Updated</>
                              ) : filter.label === "Document Subtypes" ? (
                                <>
                                  {filter.label}
                                  <br />
                                  <span style={{ fontSize: "11px" }}>
                                    (Submission-Related Documents Only)
                                  </span>
                                </>
                              ) : (
                                <>{filter.label}</>
                              )}
                            </div>
                            {selectedParent === filter.label ? (
                              <div className="filter-expand-icon-wrapper">
                                <img src={prdtNavButton} alt="" />
                              </div>
                            ) : selectedParentFilterArr.includes(
                                filter.label
                              ) ? (
                              <div className="filter-expand-icon-wrapper">
                                <img src={productNavButtonBlack} alt="" />
                              </div>
                            ) : (
                              <div className="filter-expand-icon-wrapper">
                                <img src={prdtNavButton} alt="" />
                              </div>
                            )}
                          </div>
                        );
                      })}
                      {appliedFiltersForOnlyiMS &&
                        appliedFiltersForOnlyiMS.length > 0 &&
                        window.location.pathname === "/pubMedResults" && (
                          <div className="filter-only-ims-filters-wrapper">
                            <div className="ignored-ims-filter-wrapper">
                              <span class="ignored-header-text">
                                Missing Filters (iMS only)
                              </span>
                              <div className="filter-text">
                                {renderOnlyiMSFilter()}
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  )}
                  <div style={{ width: "100%" }}>
                    {popupActive ? null : (
                      <FilterSelectModal
                        appliedFilters={appliedFilters}
                        filterCategory={filterCategory}
                        filters={filters}
                        handleApply={handleApply}
                        handleCloseFilterSelect={handleCloseFilterSelect}
                        handleSelectFilter={handleSelectFilter}
                        handleToggleFilter={handleToggleFilter}
                        isOpen={isOpen}
                        openFilters={openFilters}
                        selectedFilters={selectedFilters}
                        handleResetFilters={handleResetFilters}
                        customWidth={customWidth}
                        recentlyUsedFilters={recentlyUsedFilters}
                        fromRecentlyAddedUpdated={fromRecentlyAddedUpdated}
                        toRecentlyAddedUpdated={toRecentlyAddedUpdated}
                        gotoPageRecentlyAddedUpdated={
                          gotoPageRecentlyAddedUpdated
                        }
                        renderedFiltered={renderedFiltered}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div className="filter-container-tablet scrollable contained">
                  <div
                    style={{ width: "50%", height: "450px", overflowY: "auto" }}
                  >
                    {window.location.pathname === "/pubMedResults" &&
                      renderFilterHistogram()}
                    {orderedFilters.map((filter) => {
                      if (
                        filter.label === "Recently Added_Updated" &&
                        (window.location.pathname === "/viewReadingList" ||
                          window.location.pathname === "/rawvalues")
                      ) {
                        return "";
                      }
                      return (
                        <div
                          className={
                            selectedParent === filter.label
                              ? "select-filter-item filter-item clickable"
                              : selectedParentFilterArr.includes(filter.label)
                              ? "filter-text-selected filter-item clickable"
                              : "filter-item clickable"
                          }
                          key={filter.key}
                          onClick={(e) => changeFilterCategory(e, filter.key)}
                          title={
                            filter.key === "Recently Added_Updated"
                              ? "Recently Added/Updated"
                              : filter.key
                          }
                        >
                          <div className="filter-text">
                            {filter.label === "Recently Added_Updated" ? (
                              <>Recently Added/Updated</>
                            ) : filter.label === "Document Subtypes" ? (
                              <>
                                {filter.label}
                                <br />
                                <span style={{ fontSize: "11px" }}>
                                  (Submission-Related Documents Only)
                                </span>
                              </>
                            ) : (
                              <>{filter.label}</>
                            )}
                          </div>

                          {selectedParent === filter.label ? (
                            <div className="filter-expand-icon-wrapper">
                              <img src={prdtNavButton} alt="" />
                            </div>
                          ) : selectedParentFilterArr.includes(filter.label) ? (
                            <div className="filter-expand-icon-wrapper">
                              <img src={productNavButtonBlack} alt="" />
                            </div>
                          ) : (
                            <div className="filter-expand-icon-wrapper">
                              <img src={prdtNavButton} alt="" />
                            </div>
                          )}
                        </div>
                      );
                    })}
                    {appliedFiltersForOnlyiMS &&
                      appliedFiltersForOnlyiMS.length > 0 &&
                      window.location.pathname === "/pubMedResults" && (
                        <div className="filter-only-ims-filters-wrapper">
                          <div className="ignored-ims-filter-wrapper">
                            <span class="ignored-header-text">
                              Missing Filters (iMS only)
                            </span>
                            <div className="filter-text">
                              {renderOnlyiMSFilter()}
                            </div>
                          </div>
                        </div>
                      )}
                  </div>

                  <div style={{ width: "55%" }}>
                    {popupActive ? null : (
                      <FilterSelectModal
                        appliedFilters={appliedFilters}
                        filterCategory={filterCategory}
                        filters={filters}
                        handleApply={handleApply}
                        handleCloseFilterSelect={handleCloseFilterSelect}
                        handleSelectFilter={handleSelectFilter}
                        handleToggleFilter={handleToggleFilter}
                        isOpen={isOpen}
                        openFilters={openFilters}
                        selectedFilters={selectedFilters}
                        handleResetFilters={handleResetFilters}
                        customWidth={customWidth}
                        recentlyUsedFilters={recentlyUsedFilters}
                        fromRecentlyAddedUpdated={fromRecentlyAddedUpdated}
                        toRecentlyAddedUpdated={toRecentlyAddedUpdated}
                        gotoPageRecentlyAddedUpdated={
                          gotoPageRecentlyAddedUpdated
                        }
                        renderedFiltered={renderedFiltered}
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="filter-container scrollable contained">
              {/* {popupActive ? null :
                <FilterSelectModal
                  appliedFilters={appliedFilters}
                  filterCategory={filterCategory}
                  filters={filters}
                  handleApply={handleApply}
                  handleCloseFilterSelect={handleCloseFilterSelect}
                  handleSelectFilter={handleSelectFilter}
                  handleToggleFilter={handleToggleFilter}
                  isOpen={isOpen}
                  openFilters={openFilters}
                  selectedFilters={selectedFilters}
                  handleResetFilters={handleResetFilters}
                  customWidth={customWidth}
                />
                } */}
              {window.location.pathname === "/pubMedResults" &&
                renderFilterHistogram()}
              {orderedFilters.map((filter) => {
                if (
                  filter.label === "Recently Added_Updated" &&
                  (window.location.pathname === "/viewReadingList" ||
                    window.location.pathname === "/rawvalues")
                ) {
                  return "";
                }
                return (
                  <div
                    className={
                      selectedParent === filter.label
                        ? "select-filter-item filter-item clickable"
                        : selectedParentFilterArr.includes(filter.label)
                        ? "filter-text-selected filter-item clickable"
                        : "filter-item clickable"
                    }
                    key={filter.key}
                    onClick={(e) => changeFilterCategory(e, filter.key)}
                    title={
                      filter.key === "Recently Added_Updated"
                        ? "Recently Added/Updated"
                        : filter.key
                    }
                  >
                    <div className="filter-text">
                      {filter.label === "Recently Added_Updated" ? (
                        <>{"Recently Added/Updated"}</>
                      ) : filter.label === "Document Subtypes" ? (
                        <>
                          {filter.label}
                          <br />
                          <span style={{ fontSize: "11px" }}>
                            (Submission-Related Documents Only)
                          </span>
                        </>
                      ) : (
                        <>{filter.label}</>
                      )}
                    </div>
                    {/* <FontAwesomeIcon 
                        className='filter-chevron'
                        icon="chevron-right"
                        fixedWidth
                        color='#979797' /> */}
                      {selectedParent === filter.label ?
                        <div className="filter-expand-icon-wrapper-desktop">
                          <img src={prdtNavButton} alt="" />
                        </div> : (selectedParentFilterArr.includes(filter.label) ?
                          <div className="filter-expand-icon-wrapper-desktop">
                            <img src={productNavButtonBlack} alt="" />
                          </div> :
                          <div className="filter-expand-icon-wrapper-desktop">
                            <img src={prdtNavButton} alt="" />
                          </div>)}
                      {(popupActive || selectedParent !== filter.label) ? null :
                        <FilterSelectModal
                          appliedFilters={appliedFilters}
                          filterCategory={filterCategory}
                          filters={filters}
                          handleApply={handleApply}
                          handleCloseFilterSelect={handleCloseFilterSelect}
                          handleSelectFilter={handleSelectFilter}
                          handleToggleFilter={handleToggleFilter}
                          isOpen={isOpen}
                          openFilters={openFilters}
                          selectedFilters={selectedFilters}
                          handleResetFilters={handleResetFilters}
                          customWidth={customWidth}
                          recentlyUsedFilters={recentlyUsedFilters}
                          fromRecentlyAddedUpdated={fromRecentlyAddedUpdated}
                          toRecentlyAddedUpdated={toRecentlyAddedUpdated}
                          gotoPageRecentlyAddedUpdated={gotoPageRecentlyAddedUpdated}
                          renderedFiltered={renderedFiltered}
                        />
                      }
                    </div>
                  )
                })
              }
              <div className="filter-clear-button-wrapper">
                {/* renderClearAllAppliedFilters() */}
              </div>
              {appliedFiltersForOnlyiMS &&
                appliedFiltersForOnlyiMS.length > 0 &&
                window.location.pathname === "/pubMedResults" && (
                  <div className="filter-only-ims-filters-wrapper">
                    <div className="ignored-ims-filter-wrapper">
                      <span class="ignored-header-text">
                        Missing Filters (iMS only)
                      </span>
                      <div className="filter-text">{renderOnlyiMSFilter()}</div>
                    </div>
                  </div>
                )}
            </div>
          )}
        </>
      ) : (
        <div className="filter-container">
          {window.location.pathname === "/pubMedResults" &&
          filterYearDocCount > 0 ? (
            renderFilterHistogram()
          ) : (
            <div style={{ padding: "10px 18px" }}>No refiners available.</div>
          )}
        </div>
      )}
    </>
  );
}

FilterMenu.propTypes = {
  appliedFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  filters: PropTypes.object.isRequired,
  handleChangeFilterCategory: PropTypes.func.isRequired,
  // handleClearFilters: PropTypes.func.isRequired,
  handleResetFilters: PropTypes.func,
};
