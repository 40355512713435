import { Component } from "react";
import HeaderWithBanner from "../../components/header/HeaderWithBanner";
import DownloadDocumentResults from "../DownloadDocumentResults/DownloadDocumentResults";
import './Download.css';

class Download extends Component{

 constructor(props){
     super(props);
     this.state={
        selectedResults:[],
        collapsed: false,
        isPubMedHtml: false
     }
 }   
 componentDidMount(){
     //console.log(this.props.citationStatus)
     /* if(this.props){
        if(this.props.selectedResults.length>0){
            this.setState({
                selectedResults : this.props.selectedResults
            })
        }
        else{
           console.log('Read Displayed Results');
          this.setState({
              selectedResults : JSON.parse(localStorage.getItem('displayedResults'))
          }) 
       }
       if(this.props.citationStatus === 'Title Only'){
           this.setState({
               collapsed : true
           }) 
        }
        else{
           this.setState({
               collapsed : false
           }) 
        }
     } */
     if(window.citationType === 'Marked Results'){
        this.setState({
            selectedResults : window.selectedResults
        })
     }
     else{
        console.log('Read Displayed Results');
       this.setState({
           selectedResults : JSON.parse(localStorage.getItem('displayedResults'))
       })  
     }
     if(window.titleOnly !== undefined && window.titleOnly === true){
        this.setState({
            collapsed : true
        })
     }
     else{
        this.setState({
            collapsed : false
        })  
     }
     if(window.isPubMedHtml){
        this.setState({
            isPubMedHtml : true
        })
     }


 }
 resultList=(results)=>{
     //console.log(this.state.collapsed)
    //window.scrollTo({ behavior: 'smooth', top: '0px' });
    // var count = 0
    if (this.state.selectedResults && this.state.selectedResults.length > 0) {
        return (
            <>
                {this.state.selectedResults.map((item, index) => {
                    // count++;
                    return (
                        <>
                            <div key={`download-result-id-${index}`} id={`download-result-id-${index}`}>
                                {
                                    <div className="resultDetailsDiv">
                                        <DownloadDocumentResults item={item.detail} count={item.index} itemTeaser={item.itemTeaser} customWidth={this.state.width} collapsed={this.state.collapsed} isPubMedHtml={this.state.isPubMedHtml} isFromDownloadHtml={true}/>
                                        <div className="resultsDivFooter">
                                        </div>
                                    </div>
                                }
                            </div>
                        </>
                    )
                })
                }
            </>
        )
    }
 }
 render(){
     return(
         <div className="download-results-page-wrapper">
         <HeaderWithBanner/>
         <div className="download-results-page-contents-wrapper">
            {this.resultList(this.state.selectedResults)}
         </div>
         </div>
     )
 }
}
export default Download