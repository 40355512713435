/* eslint-disable array-callback-return */
import { Component } from "react";
import { Link } from "react-router-dom";
import { rightSideArrow, popCancelBttn, iIcon } from "../../assets";
import ApiServices from "../../utils/apiServices";
import trackingService from "../../services/trackingService";
import "./ContentShortcuts.css";

const convertToHtml = (data) => {
  return <div dangerouslySetInnerHTML={{ __html: data }}></div>;
};
class KeyCongressLiteratureDocumentsTile extends Component {
  constructor(props) {
    super(props);
    this.service = new ApiServices();
    this.state = {
      showCSModal: false,
      products: [],
      selectedProduct: "",
      congressNames: [],
      showInfo: false,
      startIndex: 0,
    };
  }
  // async componentDidMount() {
  //     let email = JSON.parse(localStorage.getItem('username')).toLowerCase();
  //     let isProductsAvailable;
  //     let res = await this.service.getUserProductAndWwid(email);
  //     if (res) {
  //         isProductsAvailable = res[0].products
  //     }
  //     if (isProductsAvailable !== null && isProductsAvailable?.length > 0) {
  //         this.setState({
  //             products: isProductsAvailable.split(';'),
  //             isLoaded: true
  //         }, () => {
  //             if (this.state.selectedProduct === '') {
  //                 this.setState({
  //                     selectedProduct: this.state.products[0]
  //                 }, async () => {
  //                     let congressName = await this.service.getCongressforProduct(this.state.selectedProduct);
  //                     if (congressName?.length > 0) {
  //                         this.setState({ congressNames: congressName })
  //                     }
  //                 })
  //             }
  //         })
  //     }
  //     else {
  //         this.setState({
  //             isLoaded: true
  //         })
  //     }
  // }
  componentWillReceiveProps() {
    if (this.props.products?.length !== this.state.products.length) {
      if (
        this.props.products?.length > 0 &&
        this.props.selectedProduct.length > 0
      ) {
        this.setState(
          {
            products: this.props.products,
            selectedProduct: this.props.selectedProduct,
          },
          async () => {
            let congressName = await this.service.getCongressforProduct(
              this.state.selectedProduct
            );
            if (congressName?.length > 0) {
              this.setState({ congressNames: congressName });
            }
          }
        );
      }
    }
  }
  componentDidUpdate = () => {
    if (this.props.showKCLCSModal !== this.state.showCSModal) {
      this.setState({ showCSModal: this.props.showKCLCSModal });
    }
  };
  getWidth = (diff) => {
    let calWidth;
    switch (diff) {
      case 1:
        calWidth = 20;
        break;
      case 2:
        calWidth = 30;
        break;
      case 3:
        calWidth = 40;
        break;
      case 4:
        calWidth = 60;
        break;
      case 5:
        calWidth = 90;
        break;
      case 6:
        calWidth = 100;
        break;
      default:
        calWidth = 100;
        break;
    }
    return calWidth;
  };
  selectProduct = (product, startIdx, endIdx) => {
    if (product.length > 0) {
      return (
        <>
          {startIdx > 0 && (
            <div className="wnfy-select-nav-button-wrapper">
              <div
                className="wnfy-result-navigate-arrow"
                onClick={(e) => this.handleOnClickLeft()}
              >
                <img
                  src={rightSideArrow}
                  alt=""
                  style={{ transform: "rotate(180deg)" }}
                />
              </div>
            </div>
          )}
          {/* {diff < 6 &&
                        <div style={{ flex: "auto" }}></div>
                    } */}
          <div
            className="cs-modal-product-wrapper" /* style={{ width: `${calcwidth}%` }} */
          >
            {product.map((item, index) => {
              if (index >= startIdx && index <= endIdx) {
                return (
                  <div
                    className={
                      item === this.state.selectedProduct
                        ? "cs-select-product-wrapper cs-product-selected"
                        : "cs-select-product-wrapper"
                    }
                    onClick={this.handleSelectProduct}
                    key={index}
                    id={`${item}`}
                  >
                    <div id={`${item}`}>{item}</div>
                  </div>
                );
              }
            })}
          </div>
          {/* {diff < 6 &&
                        <div style={{ flex: "auto" }}></div>
                    } */}
          {startIdx < product.length && endIdx < product.length - 1 && (
            <div className="wnfy-select-nav-button-wrapper">
              <div
                className="wnfy-result-navigate-arrow"
                onClick={(e) => this.handleOnClickRight()}
              >
                <img src={rightSideArrow} alt="" />
              </div>
            </div>
          )}
        </>
      );
    }
  };
  handleSelectProduct = (event) => {
    event.preventDefault();
    if (event) {
      this.setState(
        {
          selectedProduct: event.target.id,
        },
        async () => {
          let congressName = await this.service.getCongressforProduct(
            this.state.selectedProduct
          );
          this.setState({ congressNames: congressName });
        }
      );
    }
  };
  handleOnClickRight = () => {
    if (this.props.customWidth <= 1360 && this.props.customWidth >= 1200) {
      this.setState({ startIndex: this.state.startIndex + 6 });
    } else if (
      this.props.customWidth <= 1200 &&
      this.props.customWidth >= 1000
    ) {
      this.setState({ startIndex: this.state.startIndex + 5 });
    } else if (
      this.props.customWidth <= 1000 &&
      this.props.customWidth >= 750
    ) {
      this.setState({ startIndex: this.state.startIndex + 4 });
    } else if (this.props.customWidth <= 750 && this.props.customWidth >= 550) {
      this.setState({ startIndex: this.state.startIndex + 3 });
    } else if (this.props.customWidth <= 550 && this.props.customWidth >= 450) {
      this.setState({ startIndex: this.state.startIndex + 2 });
    } else if (this.props.customWidth <= 450) {
      this.setState({ startIndex: this.state.startIndex + 1 });
    } else {
      this.setState({ startIndex: this.state.startIndex + 7 });
    }
    // this.setState({
    //     startIndex: this.state.startIndex + 7
    // })
  };
  handleOnClickLeft = () => {
    if (this.props.customWidth <= 1360 && this.props.customWidth >= 1200) {
      this.setState({ startIndex: this.state.startIndex - 6 });
    } else if (
      this.props.customWidth <= 1200 &&
      this.props.customWidth >= 1000
    ) {
      this.setState({ startIndex: this.state.startIndex - 5 });
    } else if (
      this.props.customWidth <= 1000 &&
      this.props.customWidth >= 750
    ) {
      this.setState({ startIndex: this.state.startIndex - 4 });
    } else if (this.props.customWidth <= 750 && this.props.customWidth >= 550) {
      this.setState({ startIndex: this.state.startIndex - 3 });
    } else if (this.props.customWidth <= 550 && this.props.customWidth >= 450) {
      this.setState({ startIndex: this.state.startIndex - 2 });
    } else if (this.props.customWidth <= 450) {
      this.setState({ startIndex: this.state.startIndex - 1 });
    } else {
      this.setState({ startIndex: this.state.startIndex - 7 });
    }
    // this.setState({
    //     startIndex: this.state.startIndex - 7
    // })
  };
  productList = () => {
    let startIdx = this.state.startIndex;
    // let endIdx = startIdx + 6;
    let endIdx;
    if (this.props.customWidth <= 1360 && this.props.customWidth >= 1200) {
      endIdx = startIdx + 5;
    } else if (
      this.props.customWidth <= 1200 &&
      this.props.customWidth >= 1000
    ) {
      endIdx = startIdx + 4;
    } else if (
      this.props.customWidth <= 1000 &&
      this.props.customWidth >= 750
    ) {
      endIdx = startIdx + 3;
    } else if (this.props.customWidth <= 750 && this.props.customWidth >= 550) {
      endIdx = startIdx + 2;
    } else if (this.props.customWidth <= 550 && this.props.customWidth >= 450) {
      endIdx = startIdx + 1;
    } else if (this.props.customWidth <= 450) {
      endIdx = startIdx + 0;
    } else {
      endIdx = startIdx + 6;
    }
    return (
      <div className="cs-product-wrapper">
        <div className="cs-product-inner-wrapper">
          {this.selectProduct(this.state.products, startIdx, endIdx)}
        </div>
      </div>
    );
  };
  generateListOfCongressNames = (arrayOfCogName) => {
    if (arrayOfCogName.length > 0) {
      return arrayOfCogName.map((items, index) => {
        let url;
        if (this.state.selectedProduct !== "") {
          url = `/results?query=${this.state.selectedProduct}&q=All%20Fields&filters=${items.congresspath};Document Type/Medical Literature/Abstracts;Document Type/Medical Literature/Oral Presentations;Document Type/Medical Literature/Posters;Publication Year/2023;Publication Year/2022&f1=tempJNJDrugs&fval1=${this.state.selectedProduct}&fcount=1&cspath=Key Congress Literature Documents-${items.congressname}&csproduct=${this.state.selectedProduct}`;
        } else {
          url =
            `/results?query=${this.state.selectedProduct}&q=All%20Fields&filters=` +
            items.filter;
        }
        return (
          <li key={index} className="cs-modalListing">
            <Link
              to={url}
              target="_blank"
              className="cs-modalLink"
              onClick={() =>
                this.handelCongressDocsTypesLinks(
                  items.congresspath,
                  items.congressname
                )
              }
            >
              {items.congressname}
            </Link>
          </li>
        );
      });
    }
  };
  generateCongressHoldingPages = () => {
    let url;
    if (this.state.selectedProduct !== "") {
      url = `/results?query=${this.state.selectedProduct}&q=All%20Fields&filters=Document Type/Medical Literature Compilations/SKM EMEA Highlighted Data&f1=tempJNJDrugs&fval1=${this.state.selectedProduct}&f2=docTitle&fval2=Holding AND (Page OR Poster OR Posters)&fcount=2&cspath=Key Congress Literature Documents-Congress Holding Pages (collections of congress materials from key congresses)&csproduct=${this.state.selectedProduct}`;
    } else {
      url = `/results?query=${this.state.selectedProduct}&q=All%20Fields&filters=Document Type/Medical Literature Compilations/SKM EMEA Highlighted Data`;
    }
    return (
      <li className="cs-modalListing">
        <Link
          to={url}
          target="_blank"
          className="cs-modalLink"
          onClick={this.handelDocsTypesLinks}
        >
          Congress Holding Pages (collections of congress materials from key
          congresses)
        </Link>
      </li>
    );
  };
  handelCongressDocsTypesLinks = (congresspath, title) => {
    const ts = new trackingService();
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      localStorage.getItem("companyname"),
      // "Link visited from Key Congress Literature Documents tile",
      `Visited ${this.state.selectedProduct} for ${title}`,
      "",
      window.location.href,
      this.state.selectedProduct,
      `${congresspath};Document Type/Medical Literature/Abstracts;Document Type/Medical Literature/Oral Presentations;Document Type/Medical Literature/Posters;Publication Year/2023;Publication Year/2022`,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      `Key Congress Literature Documents-${title}`,
      this.state.selectedProduct
    );
  };
  handelDocsTypesLinks = () => {
    const ts = new trackingService();
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      localStorage.getItem("companyname"),
      // "Link visited from Key Congress Literature Documents tile",
      `Visited ${this.state.selectedProduct} for Congress Holding Pages (collections of congress materials from key congresses)`,
      "",
      window.location.href,
      this.state.selectedProduct,
      "Document Type/Medical Literature Compilations/SKM EMEA Highlighted Data",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "Key Congress Literature Documents-Congress Holding Pages (collections of congress materials from key congresses)",
      this.state.selectedProduct
    );
  };
  onMouseEnterInfo = () => {
    this.setState({
      showInfo: true,
    });
  };
  onMouseLeaveInfo = () => {
    this.setState({
      showInfo: false,
    });
  };
  showDetails = () => {
    const ts = new trackingService();
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      localStorage.getItem("companyname"),
      "Expanded Key Congress Literature Documents tile under content shortcuts",
      "",
      window.location.href
      //   this.state.searchKeyword,
      //   this.props.appliedFilters
    );
    this.setState({ showCSModal: !this.state.showCSModal }, () => {
      this.props.handelKCLCardDetail(this.state.showCSModal);
    });
  };
  handleCloseModal = () => {
    this.setState({ showCSModal: false }, () => {
      this.props.handelKCLCardDetail(this.state.showCSModal);
    });
  };
  render() {
    var privacyHtml = `
        <div class="cs-modalNote">
            <p>PLEASE NOTE: One or more of the congresses listed may retrieve zero results for the product you selected. This is because content for those congress is or has not yet been made available for that product for the year 2022 - 2023.</p>
        </div>`;
    return (
      <div className="contentShortcutInnerWapper">
        <div
          className="cardContainer"
          onClick={this.showDetails}
          style={{
            borderTop: this.state.showCSModal ? "5px solid #0074B0" : "none",
          }}
        >
          <div
            className="cardInnerWapper"
            style={this.state.showCSModal ? { borderBottom: "none" } : {}}
          >
            <div
              className="card-leftPart cs-left-r3-tile-"
              style={
                this.props.customWidth >= 1000
                  ? { textAlign: "center", position: "absolute", width: "31%" }
                  : {}
              }
            >
              <small>
              <span className="cs-name-bold">Key Congress Literature Documents</span> <br /> (Posters/Abstracts/Oral
                Presentations: 2022-2023)
              </small>
            </div>
            <div
              className="card-iconsPart"
              style={
                this.props.customWidth >= 1000 ? { marginLeft: "auto" } : {}
              }
            >
              <img
                src={iIcon}
                alt="i-icon"
                width={18}
                height={18}
                onMouseEnter={this.onMouseEnterInfo}
                onMouseLeave={this.onMouseLeaveInfo}
                className="cs-tile-i-icon"
              />
              <div className="card-rightPart">
                <img
                  src={rightSideArrow}
                  alt="down-arrow"
                  className="cs-downArrow"
                  style={{
                    transform: this.state.showCSModal ? "rotate(270deg)" : null,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.showInfo && (
          <div className="cs-tile-wrapper">
            <div className="cs-tooltip-uparrow"></div>
            <span className="tooltiptext cs-tooltip-bottom">
              This tile provides direct links to congress literature (posters,
              abstracts, and oral presentations) for major congresses of your
              product of interest. Please use filters to access the full list of
              congresses for your product.
            </span>
          </div>
        )}
        {this.state.showCSModal && (
          <div
            className="cs-modalInner cs-modalInner-left-pop-"
            style={
              this.props.customWidth >= 1000
                ? { position: "absolute", width: "100%", left: "0.1%" }
                : {}
            }
          >
            <div>
              <div class="popup-cancel-btn-wrapper">
                <img
                  src={popCancelBttn}
                  alt=""
                  onClick={this.handleCloseModal}
                />
              </div>
              <div>
                <div
                  className="cs-modalHeader"
                  style={{
                    justifyContent: "center",
                    display: this.state.products.length <= 0 && "none",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <div class="modal-title h4">
                      <small className="cs-modalTitle">Select Product</small>
                      {this.state.products.length > 0 && this.productList()}
                    </div>
                  </div>
                </div>
                <div
                  class="modal-body"
                  style={{ width: this.state.products.length <= 0 && "95%" }}
                >
                  <div>
                    {convertToHtml(privacyHtml)}
                    {this.state.congressNames.length > 0 &&
                      this.state.products.length > 0 && (
                        <p class="congressText">
                          The following have been selected as the major
                          congresses for the chosen product for the Publication
                          Year 2022 - 2023. For a complete list of congresses
                          for all years, please use the <b>Congress Names</b>{" "}
                          filter.
                        </p>
                      )}
                  </div>
                  <div className="cs-modalList">
                    <ul style={{ listStyle: "none" }}>
                      {this.generateListOfCongressNames(
                        this.state.congressNames
                      )}
                      {this.generateCongressHoldingPages()}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default KeyCongressLiteratureDocumentsTile;
