/* eslint-disable array-callback-return */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-anonymous-default-export */
import { quickActionPinMobileIcon, quickActionShareMobileIcon, previewIcon, pushPinIcon, shareIcon, downloadIcon, quickActionIcon, supportingDocumentMobTabIcon, supportingDocumentWebIcon, quickActionDropDown, iconAuthoringBlue } from "../../assets"
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import trackingService from "../../services/trackingService";
import GenerateArrayStrings from '../GenerateArrayStrings';
import SharePopup from "../SharePopup";
import './ActionBar.css';
import PinToReadingListPopUp from "../PopUp/PinToReadingListPopUp";
import DocumentPreview from "../Preview/DocumentPreview";
import AiNotebookDocumentPreview from "../Preview/AiNotebookDocumentPreview";
import { Modal } from "react-bootstrap";
import { Authoring } from "../../utils/utilityServices";

const indexData = (item, url, appliedFilters, searchKeyword, pageNumber, pageSize, shareoption, csPath, csProduct) => {
    let newUrl = window.origin + url;
    const ts = new trackingService();
    if (window.location.pathname !== '/pubMedResults') {
        let { orderedBy, orderedFor } = item;
        if (!orderedBy) orderedBy = '';
        if (!orderedFor) orderedFor = '';
        if (item.domain === 'RIMdocs' || item.domain === 'RIMdocs_LL') {
            ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), `${shareoption} Document`, '', window.location.href, searchKeyword, appliedFilters, item.docTitle, item.r_version_label, item.jj_number, item.searchID, newUrl, item.tempJNJDrugs, item.docType, `${shareoption} Document`, csPath, csProduct, item.domain, pageNumber, pageSize, item.createdDate, orderedBy, orderedFor);
        }
        else if (item.domain === 'SKM') {
            ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), `${shareoption} Document`, '', window.location.href, searchKeyword, appliedFilters, item.docTitle, '', item.domain, item.searchID, newUrl, item.tempJNJDrugs, item.docType, `${shareoption} Document`, csPath, csProduct, item.domain, pageNumber, pageSize, item.createdDate, orderedBy, orderedFor);
        }
        else if (item.domain === 'CTGov') {
            ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), `${shareoption} Document`, '', window.location.href, searchKeyword, appliedFilters, item.docTitle, '', item.docID, item.searchID, newUrl, item.tempJNJDrugs, item.docType, `${shareoption} Document`, csPath, csProduct, item.domain, pageNumber, pageSize, item.createdDate, orderedBy, orderedFor);
        }
        else {
            ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), `${shareoption} Document`, '', window.location.href, searchKeyword, appliedFilters, item.docTitle, item.majorVersionNumber, item.documentNumber, item.searchID, newUrl, item.tempJNJDrugs, item.docType, `${shareoption} Document`, csPath, csProduct, item.domain, pageNumber, pageSize, item.createdDate, orderedBy, orderedFor);
        }
    }
    else {
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'), localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'), localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'), localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'), localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'), `${shareoption} Document`, '', window.location.href, searchKeyword, appliedFilters, item.title, item.majorVersionNumber, item.documentNumber, item.searchID, newUrl, item.tempJNJDrugs, item.docType, `${shareoption} Document`, csPath, csProduct, 'PubMed', pageNumber, pageSize, item.createdDate);
    }
}
const handleSupportingDocs = (url) => {

    const queryParams = new URLSearchParams(document.location.search);
    const params = queryParams.get("query");
    const filters = queryParams.get("filters");
    const q = queryParams.get("q");
    const majorTopicsSynonyms = queryParams.get("majorTopicsSynonyms");
    const authors = queryParams.get("authors");
    const docTitle = queryParams.get("docTitle");
    let str = "";
    let fieldedSearch = queryParams.get("FS");
    let limistArr = [];
    let searchDes = "";
    if (window.location.pathname !== "/ReferencesUsed") {
        if (
            params !== "" &&
            filters !== "" &&
            q !== "All Fields" &&
            !fieldedSearch
        ) {
            searchDes = `${q}: ${params}: ${filters}`;
        } else if (
            params === "" &&
            filters !== "" &&
            q === "All Fields" &&
            !fieldedSearch
        ) {
            searchDes = `${filters}`;
        } else if (
            params !== "" &&
            filters !== "" &&
            q === "All Fields" &&
            !fieldedSearch
        ) {
            searchDes = `${params}: ${filters}`;
        } else if (
            params !== "" &&
            filters === "" &&
            q === "All Fields" &&
            !fieldedSearch
        ) {
            searchDes = `${params}`;
        } else if (
            params !== "" &&
            filters === "" &&
            q !== "All Fields" &&
            !fieldedSearch
        ) {
            searchDes = `${q}: ${params}`;
        } else if (fieldedSearch) {
            // Publication Year
            let toRange = queryParams.get("toYear");
            let fromRange = queryParams.get("fromYear");
            let PublicationYear = "";
            if (toRange) {
                toRange = parseInt(toRange);
            }
            if (fromRange) {
                fromRange = parseInt(fromRange);
            }
            let strYear = limistArr.find((x) => x.key === "strYear");
            if (strYear) {
                let strYearValue = parseInt(strYear.value);
                if (
                    toRange &&
                    fromRange &&
                    fromRange <= strYearValue &&
                    toRange >= strYearValue
                ) {
                    PublicationYear = `${fromRange} To ${toRange}`;
                } else if (
                    toRange &&
                    fromRange &&
                    (fromRange > strYearValue || toRange < strYearValue)
                ) {
                    PublicationYear = `${fromRange} To ${toRange} AND ${strYearValue}`;
                } else if (toRange && strYearValue) {
                    PublicationYear = `${toRange} and earlier AND ${strYearValue}`;
                } else if (fromRange && strYearValue) {
                    PublicationYear = `${fromRange} and later AND ${strYearValue}`;
                } else if (strYearValue) {
                    PublicationYear = `${strYearValue}`;
                }
            } else if (toRange && fromRange) {
                PublicationYear = `${fromRange} To ${toRange}`;
            } else if (toRange) {
                PublicationYear = `${toRange} and earlier`;
            } else if (fromRange) {
                PublicationYear = `${fromRange} and later`;
            }
            limistArr = limistArr.filter((x) => x.key !== "strYear");
            let fcount = queryParams.get("fcount");
            for (let i = 0; i < fcount; i++) {
                let key = queryParams.get(`f${i + 1}`);
                let value = queryParams.get(`fval${i + 1}`);
                limistArr[i] = { key: key, value: value };
            }
            str = params ? `(${params}${filters ? `: ${filters})` : ")"}` : "";
            str = str + (docTitle ? ` (Title: ${docTitle})` : "");
            str = str + (authors ? ` (Authors: ${authors})` : "");
            str =
                str +
                (majorTopicsSynonyms
                    ? ` (Major Topics: ${majorTopicsSynonyms})`
                    : "");
            str =
                str +
                (PublicationYear ? ` (Publication Year: ${PublicationYear})` : "");
            if (fcount > 0 && limistArr.length > 0) {
                limistArr.map((item, index) => {
                    str =
                        str +
                        (item.key
                            ? ` (${this.conversionOfFieldType(item.key)}: ${item.value})`
                            : "");
                });
            }
            searchDes = str;
        }

    }
    let width = 0.80 * window.innerWidth
    let height = 0.85 * window.innerHeight
    let left = 0.10 * window.innerWidth
    let top = 0.10 * window.innerHeight
    let newWindow = window.open(url, '', `width=${width},height=${height},top=${top},left=${left},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no`)
    newWindow.supportingDocsSearchStrategyDescription = searchDes;
    newWindow.isSupportingDocs = true;
    //OpenWindow(url)
}
const handleMIAsDocs = (refurl) => {
    let url = `/ReferencesUsed?query=${refurl}`;
    window.open(url, '', `width=${window.innerWidth},height=${window.innerHeight},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no`)
}
export default function (props) {
    const {
        item,
        appliedFilters,
        searchKeyword,
        pageNumber,
        pageSize,
        customWidth,
        collapsed,
        csPath,
        csproduct,
        handleUnpin,
        synonymsForDocumentPreview
    } = props
    const [isOpen, setOpenState] = useState(false)
    const [qacollapsed, setqaCollapsed] = useState(false)
    const [showActions, setShowActions] = useState(false)
    const [showReadingList, setShowRL] = useState(false)
    const [showPreview, setPreview] = useState(false)
    const [showAuthoring, setAuthoring] = useState(false)
    let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'))
    let url, supportingDocUrl, docIds, referencesUrl
    if (item.supportingDocIDs) {
        docIds = GenerateArrayStrings(item.supportingDocIDs)
        supportingDocUrl = `?query=&q=All%20Fields&filters=&f1=docID&fval1=${docIds}&fcount=1`
    }
    if (item.domain === "MIAs") {
        if (item.spoWebURL) {
            referencesUrl = item.spoWebURL.split('/')
            referencesUrl.pop();
            referencesUrl.push('references used')
            referencesUrl = referencesUrl.join('/')
        }
    }
    if (window.location.pathname !== '/pubMedResults') {
        let { orderedBy, orderedFor } = item;
        if (!orderedBy) orderedBy = '';
        if (!orderedFor) orderedFor = '';
        if (item.domain === 'RIMdocs' || item.domain === 'RIMdocs_LL' || item.domain === 'CTGov') {
            url = `/DocURL?ID=${item.docID}&cs=${item.domain}&orderedBy=${orderedBy}&orderedFor=${orderedFor}`
            // url = `/DocURL?ID=${item.docTitle}&cs=${item.domain}`
        }
        else if (item.domain === 'SKM' || item.domain === "MIAs") {
            url = `/DocURL?ID=${item.docID}&cs=${item.domain}&connID=${item.connID}&csID=${item.csID}&fileType=${item.fileExtension}&fileName=${item.docTitle}&orderedBy=${orderedBy}&orderedFor=${orderedFor}`
        }
        else {
            url = `/DocURL?ID=${item.docID}&cs=${item.domain}&connID=${item.connID}&csID=${item.csID}&fileType=${item.fileExtension}&docVer=${item.majorVersionNumber}&fileName=${item.docTitle}&orderedBy=${orderedBy}&orderedFor=${orderedFor}`
            if (item.a_status === "Approved Needs Review" || item.status === "Approved Needs Review") {
                url = url + `&docState=anr`
            }
        }
        if (item.enclosureDocID) {
            url += `&enclosureID=${item.enclosureDocID}`
        }
    }
    else {
        url = `/DocURLPubMed?sid=PMS&pmid=${item.PMID}`
    }
    useEffect(() => {
        if (collapsed) {
            setqaCollapsed(true)
        }
        else {
            setqaCollapsed(false)
        }
    }, [collapsed])
    function handleShowActions() {
        setShowActions(false)
    }
    function handleCollapsedQuickAction() {
        setqaCollapsed(!qacollapsed)
    }
    function handleClosePinToReadingList(status) {
        setShowRL(status)
    }
    function onDownloadClick() {
        indexData(item, url, appliedFilters, searchKeyword, pageNumber, pageSize, 'Download', csPath !== null ? csPath : '', csproduct !== null ? csproduct : '')
    }
    function onPubMedDownloadClick() {
        indexData(item, url, appliedFilters, searchKeyword, pageNumber, pageSize, 'Download PubMed', csPath !== null ? csPath : '', csproduct !== null ? csproduct : '')
    }
    function onShareClick() {
        setOpenState(!isOpen);
        if (window.location.pathname !== '/pubMedResults') {
            indexData(item, url, appliedFilters, searchKeyword, pageNumber, pageSize, 'Share', '', '')
        }
        else {
            indexData(item, url, appliedFilters, searchKeyword, pageNumber, pageSize, 'Share PubMed', '', '')
        }
    }
    function handlePreviewClose(status) {
        setPreview(false);
        setAuthoring(false);
    }
    if (customWidth < 1000) {
        return (
            <div className="action-items-mobile-wrapper" >
                <img src={quickActionIcon} alt="" width="35" height="35" onClick={e => setShowActions(!showActions)} />
                {showActions &&
                    <div className="action-items-mobile-inner-wrapper" onMouseLeave={handleShowActions}>
                        {window.location.pathname === '/viewReadingList' ?
                            window.location.pathname !== '/pubMedResults' &&
                            <div className="action-items-mobile-items-wrapper" onClick={e => { handleUnpin(item); indexData(item, url, appliedFilters, searchKeyword, pageNumber, pageSize, 'Unpin', '', ''); }}><img src={quickActionPinMobileIcon} alt="" width="30" height="30" /><span className="action-item-text-mobile">Unpin</span></div>
                            : window.location.pathname !== '/pubMedResults' &&
                            <div className="action-items-mobile-items-wrapper" onClick={e => { setShowRL(true); indexData(item, url, appliedFilters, searchKeyword, pageNumber, pageSize, 'Pin', '', '') }}><img src={quickActionPinMobileIcon} alt="" width="30" height="30" /><span className="action-item-text-mobile">Pin</span></div>
                        }
                        {window.location.pathname !== '/pubMedResults' && <div className="quick-action-divider-mobile"></div>}
                        {window.location.pathname !== '/pubMedResults' && item.supportingDocIDs !== undefined && item.supportingDocIDs.length > 0 &&
                            <>
                                <div className="action-items-mobile-items-wrapper" onClick={evt => handleSupportingDocs(supportingDocUrl)}><img src={supportingDocumentMobTabIcon} alt="" width="30" height="30" /><span className="action-item-text-mobile">Supporting Documents</span></div>
                                <div className="quick-action-divider-mobile"></div>
                            </>
                        }
                        {window.location.pathname !== '/pubMedResults' && item.domain === "MIAs" &&
                            <>
                                <div className="action-items-mobile-items-wrapper" onClick={evt => handleMIAsDocs(referencesUrl)}><img src={supportingDocumentMobTabIcon} alt="" width="30" height="30" /><span className="action-item-text-mobile">References Used</span></div>
                                <div className="quick-action-divider-mobile"></div>
                            </>
                        }
                        <div className="action-items-mobile-items-wrapper" onClick={onShareClick} ><img src={quickActionShareMobileIcon} alt="" width="30" height="30" /><span className="action-item-text-mobile">Share</span></div>
                    </div>
                }
                {isOpen &&
                    <div>
                        <SharePopup isOpen={isOpen} item={item} appliedFilters={appliedFilters} searchKeyword={searchKeyword} pageNumber={pageNumber} pageSize={pageSize} handleClose={(close) => { setOpenState(close) }} csPath={csPath} csproduct={csproduct} customWidth={customWidth}></SharePopup>
                    </div>
                }
                {showReadingList &&
                    <PinToReadingListPopUp showModal={showReadingList} handleClose={handleClosePinToReadingList} width={customWidth} docID={item.searchID} />
                }
            </div>
        )
    }
    else {
        return (
            <div className="action-items-wrapper">
                <div className="action-items-inner-wrapper-header" onClick={handleCollapsedQuickAction}>
                    <div className="action-items-main-text">
                        Quick Actions &nbsp;
                        <img src={quickActionDropDown} alt="dropdownIcon" style={{ transform: !qacollapsed ? "matrix(-1, 0, 0, -1, 0, 0)" : null, margin: "0px 0px 1.5%" }} />
                    </div>
                </div>
                <div className="quick-action-divider"></div>
                {!qacollapsed &&
                    <>
                        {window.location.pathname !== '/pubMedResults' && <>
                            <div className="action-items-inner-wrapper">
                                <a href="javascript:void(0)" className="quick-action-item" onClick={evt => { setPreview(true); indexData(item, url, appliedFilters, searchKeyword, pageNumber, pageSize, 'Preview', '', '') }}>
                                    <img src={previewIcon} alt="" title="Preview" width={20} height={20} />
                                    <div className="action-items-title">
                                        Preview
                                    </div>
                                </a>
                            </div>
                            <div className="quick-action-divider"></div>
                        </>}
                        {window.location.pathname !== '/pubMedResults' && userAccessGroup.includes(Authoring) && <>
                            <div className="action-items-inner-wrapper">
                                <a href="javascript:void(0)" className="quick-action-item" onClick={evt => { setAuthoring(true); indexData(item, url, appliedFilters, searchKeyword, pageNumber, pageSize, 'Authoring', '', '') }}>
                                    <img src={iconAuthoringBlue} alt="" title="Authoring" width={20} height={20} />
                                    <div className="action-items-title">
                                        Authoring
                                    </div>
                                </a>
                            </div>
                            <div className="quick-action-divider"></div>
                        </>}
                        {window.location.pathname !== '/pubMedResults' && item.supportingDocIDs !== undefined && item.supportingDocIDs.length > 0 &&
                            <>
                                <div className="action-items-inner-wrapper">
                                    <a href="javascript:void(0)" onClick={evt => handleSupportingDocs(supportingDocUrl)} className="quick-action-item">
                                        <img src={supportingDocumentWebIcon} alt="" title="Supporting Documents" />
                                        <div className="action-items-supportingDocument-title">
                                            Supporting Documents
                                        </div>
                                    </a>
                                </div>
                                <div className="quick-action-divider"></div>
                            </>
                        }
                        {window.location.pathname !== '/pubMedResults' && item.domain === "MIAs" &&
                            <>
                                <div className="action-items-inner-wrapper">
                                    <a href="javascript:void(0)" onClick={evt => handleMIAsDocs(referencesUrl)} className="quick-action-item">
                                        <img src={supportingDocumentWebIcon} alt="" title="References Used" />
                                        <div className="action-items-supportingDocument-title">
                                            References Used
                                        </div>
                                    </a>
                                </div>
                                <div className="quick-action-divider"></div>
                            </>
                        }
                        <div className="action-items-inner-wrapper">
                            <a href="javascript:void(0)" onClick={onShareClick} className="quick-action-item">
                                <img src={shareIcon} alt="" title="Share" />
                                <div className="action-items-title">
                                    Share
                                </div>
                            </a>
                        </div>
                        <div className="quick-action-divider"></div>
                        {(window.location.pathname !== '/pubMedResults') ? <>
                            <div className="action-items-inner-wrapper">
                                <Link target="_blank" to={url} onClick={onDownloadClick} className="quick-action-item">
                                    <img src={downloadIcon} alt="" title="Download" />
                                    <div className="action-items-title">
                                        Download
                                    </div>
                                </Link>
                            </div>
                        </> : <>
                            <div className="action-items-inner-wrapper">
                                <Link target="_blank" to={url} onClick={onPubMedDownloadClick} className="quick-action-item">
                                    <img src={downloadIcon} alt="" title="Download" />
                                    <div className="action-items-title">
                                        Download
                                    </div>
                                </Link>
                            </div></>}
                        {(window.location.pathname !== '/pubMedResults') && <div className="quick-action-divider"></div>}
                        {window.location.pathname === '/viewReadingList' ?
                            window.location.pathname !== '/pubMedResults' &&
                            <div className="action-items-inner-wrapper">
                                <a href="javascript:void(0)" className="quick-action-item" onClick={e => { handleUnpin(item); indexData(item, url, appliedFilters, searchKeyword, pageNumber, pageSize, 'Unpin', '', ''); }}>
                                    <img src={pushPinIcon} alt="" title="Unpin from Reading List" />
                                    <div className="action-items-title">
                                        Unpin
                                    </div>
                                </a>
                            </div>
                            : window.location.pathname !== '/pubMedResults' &&
                            <div className="action-items-inner-wrapper">
                                <a href="javascript:void(0)" className="quick-action-item" onClick={e => { setShowRL(true); indexData(item, url, appliedFilters, searchKeyword, pageNumber, pageSize, 'Pin', '', '') }}>
                                    <img src={pushPinIcon} alt="" title="Pin to Reading List" />
                                    <div className="action-items-title">
                                        Pin
                                    </div>
                                </a>
                            </div>
                        }
                        {isOpen &&
                            <div>
                                <SharePopup isOpen={isOpen} item={item} appliedFilters={appliedFilters} searchKeyword={searchKeyword} pageNumber={pageNumber} pageSize={pageSize} handleClose={(close) => { setOpenState(close) }} csPath={csPath} csproduct={csproduct} customWidth={customWidth}></SharePopup>
                            </div>
                        }
                        {showReadingList &&
                            <PinToReadingListPopUp showModal={showReadingList} handleClose={handleClosePinToReadingList} width={customWidth} docID={item.searchID} />
                        }
                        {((showPreview || showAuthoring) && window.location.pathname !== '/pubMedResults') && <>{
                            userAccessGroup.includes(Authoring) ?
                                renderModelPopup() :
                                <DocumentPreview customWidth={customWidth} sourceItem={item} fileName={item.fileName} docTitle={item.docTitle} searchID={item.searchID} searchKeyword={searchKeyword} synonyms={synonymsForDocumentPreview} /*searchID='1a08dc3a4077c024d071f88b33f97c22' */ showPreview={showPreview} handleClose={handlePreviewClose} />}</>
                            // <AiNotebookDocumentPreview customWidth={customWidth} sourceItem={item} fileName={item.fileName} docTitle={item.docTitle} searchID={item.searchID} searchKeyword={searchKeyword} synonyms={synonymsForDocumentPreview} /*searchID='1a08dc3a4077c024d071f88b33f97c22' */ showPreview={showPreview} handleClose={handlePreviewClose} />
                        }
                        {/* {(showPreview && window.location.pathname !== '/nlpresults') &&
                            <DocumentPreview customWidth={customWidth} sourceItem={item} fileName={item.fileName} docTitle={item.docTitle} searchID={item.searchID} searchKeyword={searchKeyword} synonyms={synonymsForDocumentPreview}  showPreview={showPreview} handleClose={handlePreviewClose} />
                        } */}
                    </>
                }
            </div>
        )
    }
    function renderModelPopup() {
        return (<Modal
            show={(showPreview || showAuthoring)}
            size="xl"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="authoring-popup-transition"
            className="z-index-preview"
            backdropClassName="z-index-preview-backdrop"
            scrollable={false}
        >
            <Modal.Body>
                <div style={{ overflow: "auto" }}>
                    <AiNotebookDocumentPreview customWidth={customWidth} sourceItem={item} fileName={item.fileName} docTitle={item.docTitle} searchID={item.searchID} searchKeyword={searchKeyword} synonyms={synonymsForDocumentPreview} /*searchID='1a08dc3a4077c024d071f88b33f97c22' */ showPreview={(showPreview || showAuthoring)} handleClose={handlePreviewClose} isOpenAuthoringTab={showAuthoring} />
                </div>
            </Modal.Body>
        </Modal>)
    }
}