import { Component } from "react";
import axios from "axios";
import Services from "../../utils/services";
import Footer from "../../components/footer/Footer";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import { imsLoader, filterIconWhite, expandFilterWhite, collapseFilterWhite } from "../../assets";
import HeaderForRawValues from "../../components/header/headerForRawValues";
import RawValuesDocumentCard from "../RawValuesDocumentResults/RawValuesDocumentCard";
import SearchFilter from "../FilterComponent/SearchFilter";
import FilterService from "../../services/FilterService"
import PageItems from "../../components/Paginate/PageItems";
import Pagination from "../../components/Paginate/Pagination";
import processFilters from '../FilterComponent/ProcessFilter'
import FilterDisplay from "../../components/FilterDisplay/FilterDisplay";
import queryString from "query-string";
import NavigationMenu from "../../components/Navigation/NavigationMenu";
import { LocationOrigin } from "../../utils/utilityServices";

export default class RawValues extends Component {
    userid;
    userExists;
    constructor(props) {
        super(props);
        this.state = {
            response: [],
            userExists: false,
            username: '',
            collapsed: false,
            showFilters: true,
            searchKeyword: '',
            dropdownValue: '',
            allFilters: {},
            documents: [],
            documentCount: 0,
            appliedFilters: [], //Filters that are applied for while search is happening
            selectedFilters: [],
            itemsPerPage: "25",
            currentPage: "1",
            presentDocRendered: "0",
            isLoaded: false,
            isProcessing: true,
            filters: {},
            f1: '',
            fval1: '',
            fieldedSearchArr: {},
            selectedParentFilterArr: [],
            width: window.innerWidth,
            height: window.innerHeight,
            sortByValue: 'Relevance',
            sortBy: "",
            sortField: "",
        }
    }
    static getDerivedStateFromProps(props, state) {
        let params = new URLSearchParams(props.location.search);
        let query = params.get('query');
        let q = params.get('q');
        if (query !== '') {
            return {
                searchKeyword: query,
                dropdownValue: q
            }
        }
        return {
            searchKeyword: '',
            dropdownValue: q
        }
    }
    async componentDidMount() {
        document.title = 'Raw Values';
        let service = new Services();
        this.userid = JSON.parse(localStorage.getItem('username'));
        this.userExists = localStorage.getItem('userExists');
        service.logService(`Raw Values succesfully mounted for ${this.userid.toLowerCase()}`, 'info');
        if (this.userExists) {
            this.setState({
                username: this.userid.toLowerCase(),
                userExists: this.userExists
            })
            if (this.props.location.search === '') {
                this.props.history.replace('/rawvalues?query=&q=All%20Fields&filters=')
            }
            /*Get the localStorage values to getAllFilters POST Call*/
            let reqBody = {
                "function": localStorage.getItem('function').replace(/"/g, ''),
                "doctypepref": localStorage.getItem('doctypepref'),
                "userproducts": localStorage.getItem('userproducts'),
                "region": localStorage.getItem('region'),
                "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                "contentpreference": localStorage.getItem('contentpreference').replace(/"/g, '')
            }
            let response = await service.getAllFilters(reqBody)
            if (response) {
                this.setState({ allFilters: {} })
                let processedFilters = processFilters(response.data.body)
                this.setState({
                    allFilters: processedFilters,
                    isProcessing: false
                })
            }
            let params = new URLSearchParams(this.props.location.search);
            let filters = params.get('filters');
            let query = params.get('query');
            if (filters !== '') {
                this.setState({
                    appliedFilters: filters.split(';'),
                    selectedFilters: filters.split(';')
                }, () => {
                    this.setState({
                        selectedParentFilterArr: this.generateSelectParentArray(this.state.appliedFilters)
                    })
                    if (query !== '' || query !== undefined || query !== null) {
                        this.runSearch(query, this.state.appliedFilters)
                    }
                })
            }
            else {
                this.setState({
                    appliedFilters: []
                }, () => {
                    this.setState({
                        selectedParentFilterArr: this.generateSelectParentArray(this.state.appliedFilters)
                    })
                    if (query !== '' || query !== undefined || query !== null) {
                        this.runSearch(query, this.state.appliedFilters)
                    }
                })
            }
            window.addEventListener('resize', this.updateDimensions);
        }
        else {
            console.log('Access Denied');
            this.props.history.push('/');
        }
    }
    resultList = (results, itemsPerPage, currentPage) => {
        // window.scrollTo({ behavior: 'smooth', top: '0px' });
        var startIndex;
        startIndex = currentPage * itemsPerPage - itemsPerPage
        //endIndex = currentPage * itemsPerPage
        var count = startIndex;
        //console.log(count);
        if (results.length > 0) {
            return (
                <>
                    {results.map((item, index) => {
                        count++;
                        return (
                            <>
                                <div key={index}>
                                    {
                                        <div className="resultDetailsDiv" style={{ width: "95%" }}>
                                            <RawValuesDocumentCard item={item._source} score={item._score} count={count} appliedFilters={this.state.appliedFilters} searchKeyword={this.state.searchKeyword} pageNumber={this.state.currentPage} pageSize={this.state.itemsPerPage} />
                                            <div className="resultsDivFooter">
                                            </div>
                                        </div>

                                    }
                                </div>

                            </>
                        )
                    })
                    }
                </>
            )
        }
        else {
            return (
                <div className="results-count-wrapper">
                    <div className="results-no-search-content">
                        <span>Nothing here matches your search</span><br /><br />
                        <span>Suggestions</span>
                        <li>Make sure all words are spelled correctly</li>
                        <li>Try different search terms</li>
                        <li>Try more general search terms</li>
                        <li>Try fewer search terms</li>
                    </div>
                </div>
            )
        }
    }
    handleDocCount = (presentCount) => {
        this.setState({
            presentDocRendered: presentCount
        })
    }
    handleAllFilters = (filters, appliedfilters, selectedFilters, response, docCount, query) => {
        this.setState({
            filters: filters,
            appliedFilters: appliedfilters,
            selectedFilters: selectedFilters,
            response: response,
            documentCount: docCount,
            searchKeyword: query
        })
    }
    handleChangeInSearch = (query) => {
        this.setState({
            searchKeyword: query,
            currentPage: "1"
        }, () => {
            this.runSearchFromQueryParams(this.state.allFilters, this.state.appliedFilters, query)
        })
    }
    generateSelectParentArray = (appliedfilters) => {
        let parentFacet = [];
        appliedfilters.forEach(filter => {
            const filterParts = filter.split('/')
            const filterTop = filterParts[0]
            parentFacet.push(filterTop)
        });
        return parentFacet;
    }
    handlePageChange = (currentPage) => {
        window.scrollTo({ behavior: 'smooth', top: '0px' });
        //console.log(currentPage)
        this.setState({
            currentPage: currentPage,
            response: [],
            isProcessing: true,
            isLoaded: false
        }, () => {
            this.runSearchFromQueryParams(this.state.allFilters, this.state.appliedFilters, this.state.searchKeyword)
        })
    }
    handleOnChangeInfilter = (filters, appliedfilters, selectedFilters, response, doc, query) => {
        let params = new URLSearchParams(this.props.location.search);
        //var filters = appliedfilters;
        filters = appliedfilters;
        let paramquery = params.get('query');
        let q = params.get('q');
        let selectedFacets = this.generateSelectParentArray(appliedfilters);
        const newUrl = this.buildUrl(paramquery, q, filters);
        this.props.history.push({ pathname: '/rawvalues', search: newUrl, state: { selectedFilters: selectedFilters, allFilters: this.state.allFilters, appliedFilters: appliedfilters } });
        this.setState({
            currentPage: "1",
            selectedParentFilterArr: selectedFacets,
            selectedFilters: selectedFilters,
        }, () => {
            this.runSearchFromQueryParams(this.state.allFilters, appliedfilters, this.state.searchKeyword);
        })
    }
    handleChangeSelectItems = (items) => {
        //console.log(items);
        this.setState({
            itemsPerPage: items
        }, () => {
            let totalPages = Math.ceil(this.state.documentCount / this.state.itemsPerPage)
            if (totalPages < this.state.currentPage) {
                this.setState({
                    currentPage: totalPages
                }, () => {
                    this.runSearchFromQueryParams(this.state.allFilters, this.state.appliedFilters, this.state.searchKeyword)
                })
            }
            else {
                this.runSearchFromQueryParams(this.state.allFilters, this.state.appliedFilters, this.state.searchKeyword)
            }
        })
    }
    buildUrl = (searchKeyword, dropdownValue, filters) => {
        //console.log(filters);
        let filterStr = this.generateArrayString(filters)
        let query = searchKeyword
        if (searchKeyword === undefined) {
            query = ''
        }
        return `?query=${query}&q=${dropdownValue}&filters=${filterStr}`
    }
    runSearchFromQueryParams(allfilters, appliedfilters, querytext) {
        //console.log(querytext);
        this.setState({ isProcessing: true, isLoaded: false })
        const filterService = new FilterService();
        const query = querytext
        const inFilters = appliedfilters
        // const [filters] = filterService.expandFilterSet(allfilters, inFilters)
        const [filters] = filterService.expandFilterSet(this.state.filters, inFilters)
        const filterSelected = inFilters
        let selectedFiltered = this.state.selectedFilters.concat(filters)
        selectedFiltered = [...new Set(selectedFiltered)]
        this.setState({
            appliedFilters: inFilters,
            selectedFilters: selectedFiltered
            // selectedFilters: filters
        }, () => {
            this.runSearch(query, filterSelected)
        })
    }
    generateArrayString = (array) => {
        let arrStr = ''
        array.map((item, index) => {
            if (index === array.length - 1) {
                arrStr += item
            }
            else {
                arrStr += item + ";"
            }
            return null
        })
        return arrStr
    }
    handleClearFilters = () => {
        //console.log('Clear Filters from Breadcrumbs')
        this.setState({
            selectedFilters: [],
            appliedFilters: [],
            selectedParentFilterArr: [],
            filters: []
        }, () => {
            let params = queryString.parse(this.props.location.search);
            const newUrl = this.buildUrl(params.query, params.q, this.state.appliedFilters);
            this.props.history.push(newUrl);
            this.runSearchFromQueryParams(this.state.allFilters, this.state.appliedFilters, this.state.searchKeyword)
        })
    }
    handleRemoveAppliedFilter = (facet) => {
        let parentFacetName = facet.split('/')[0];
        let arr = this.state.selectedParentFilterArr
        const index = arr.indexOf(parentFacetName)
        if (index > -1) {
            arr.splice(index, 1)
        }
        this.setState({
            appliedFilters: this.state.appliedFilters.filter(filter => !filter.startsWith(facet)),
            selectedParentFilterArr: arr,
            selectedFilters: this.state.selectedFilters.filter(filter => filter && !filter.startsWith(facet)),
        }, () => {
            let params = queryString.parse(this.props.location.search);
            const newUrl = this.buildUrl(params.query, params.q, this.state.appliedFilters);
            this.props.history.push(newUrl);
            this.runSearchFromQueryParams(this.state.allFilters, this.state.appliedFilters, this.state.searchKeyword)
        })
    }
    runSearch = (querytext, filterSelected) => {
        this.setState({ isProcessing: true })
        var url = LocationOrigin + process.env.REACT_APP_IMS_API_PREFIX + 'qp/runSearchRaw';
        let filterStr = this.generateArrayString(filterSelected);
        let userAccessGroup = JSON.parse(localStorage.getItem('accessgroup'));
        // let from = this.state.currentPage * this.state.itemsPerPage - this.state.itemsPerPage
        var apiConfig;
        //url+=`?query=${querytext}&filters=${filterStr}&from=${from}&size=${this.state.itemsPerPage}`
        apiConfig = {
            method: 'POST',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                "query": querytext,
                "filters": filterStr,
                "function": localStorage.getItem('function').replace(/"/g, ''),
                "doctypepref": localStorage.getItem('doctypepref'),
                "userproducts": localStorage.getItem('userproducts'),
                "region": localStorage.getItem('region'),
                "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                "contentpreference": localStorage.getItem('contentpreference').replace(/"/g, ''),
                "from": this.state.currentPage * this.state.itemsPerPage - this.state.itemsPerPage,
                "size": parseInt(this.state.itemsPerPage),
                "sortBy": this.state.sortBy,
                "sortField": this.state.sortField,
                accessGroup: (userAccessGroup.includes("MISEHCP")) ? "MISE" : "",
            }
        }
        axios(apiConfig)
            .then((response) => {
                var processed = processFilters(response.data.body)
                const filterService = new FilterService();
                const [filters] = filterService.expandFilterSet(processed, this.state.appliedFilters)
                let selectedFiltered = this.state.selectedFilters.concat(filters)
                // selectedFiltered = [...new Set(selectedFiltered)]
                selectedFiltered = this.state.appliedFilters.concat(filters)
                this.setState({
                    documentCount: response.data.body.hits.total.value,
                    response: response.data.body.hits.hits,
                    filters: processed,
                    isProcessing: false,
                    isLoaded: true,
                    selectedFilters: selectedFiltered
                }, () => {
                    this.handleAllFilters(this.state.filters, this.state.appliedFilters, this.state.selectedFilters, this.state.response, this.state.documentCount, querytext);
                })

            })
            .catch(function (error) {
                //console.log(error);
            })
            .finally(() => {
                this.setState({ isProcessing: false })
            })
    }
    componentWillUnmount() {
        let service = new Services();
        window.removeEventListener('resize', this.updateDimensions);
        service.logService(`Raw Values succesfully unmounted for ${this.userid.toLowerCase()}`, 'info')
    }
    handleChangeSelectSortBy = (e) => {
        if (e.target.value === 'Date,Newest') {
            this.setState({ sortBy: "desc", sortField: "modifiedDate" })
        }
        else if (e.target.value === 'Date,Oldest') {
            this.setState({ sortBy: "asc", sortField: "modifiedDate" })
        }
        else if (e.target.value === 'Document Type, Ascending') {
            this.setState({ sortBy: "asc", sortField: "docType" })
        }
        else if (e.target.value === 'Document Type, Descending') {
            this.setState({ sortBy: "desc", sortField: "docType" })
        }
        else {
            this.setState({ sortBy: "", sortField: "" })
        }
        this.setState({ sortByValue: e.target.value }, () => {
            this.runSearchFromQueryParams(this.state.allFilters, this.state.appliedFilters, this.state.searchKeyword)
        })
    }
    resultListCount = (itemsPerPage, currentPage) => {
        var endIndex, startIndex;
        startIndex = currentPage * itemsPerPage - itemsPerPage
        endIndex = currentPage * itemsPerPage
        // var count = startIndex;
        return (
            <div className="results-count-wrapper" style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                {this.state.documentCount > 0 &&
                    <span className="showingResultsCountContainer" style={{ width: "291px", justifyContent: "flex-end" }}>
                        {endIndex <= this.state.documentCount ?
                            <div className="results-number-show-wrapper">
                                Showing {startIndex + 1}-{endIndex} of {this.state.documentCount} Results
                            </div> :
                            <div className="results-number-show-wrapper">
                                Showing {startIndex + 1}-{this.state.documentCount} of {this.state.documentCount} Results
                            </div>
                        }
                    </span>
                }
                <span style={{ marginRight: "0.5%", display: "flex", width: "180px" }}>
                    <PageItems handleChangeSelectItems={this.handleChangeSelectItems}
                        itemsPerPage={this.state.itemsPerPage} customWidth={this.state.width} />
                </span>
                {/* {
                                this.state.width >= 1000 && */}
                <div className="sort-by-wrapper" style={{ alignItems: "stretch" }}>
                    <span className="sort-by-text">Sort By:</span>
                    <select className="sort-by-select-wrapper" onChange={this.handleChangeSelectSortBy} defaultValue={this.state.sortByValue} style={{ height: "auto" }}>
                        <option>Relevance</option>
                        <option>Date,Newest</option>
                        <option>Date,Oldest</option>
                        <option>Document Type, Ascending</option>
                        <option>Document Type, Descending</option>
                    </select>
                </div>
                {/* } */}
            </div>
        )
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight })
    }
    render() {
        return (
            <>
                <ErrorBoundary>
                    {this.state.userExists ?
                        <>
                            <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                            <HeaderForRawValues history={this.props.history} dropdownValue={this.state.dropdownValue} searchKeyword={this.state.searchKeyword} appliedFilters={this.state.appliedFilters} handleChangeInSearch={this.handleChangeInSearch} />
                            <div className="resultsInnerWrapper">
                                <div className="results-contents-wrapper" style={{ margin: "1% 1.5% 0 1.5%" }}>
                                    <div className={this.state.showFilters ? "filterMenuWrapper filterMenuWrapperExpanded" : "filterMenuWrapper"} style={{ float: "left" }}>
                                        {this.state.showFilters ?
                                            <>
                                                <div className="filterMenuHeader" style={{ marginTop: "0" }}>
                                                    <span className="filter-menu-icon-wrapper"><img src={filterIconWhite} alt="" /></span>
                                                    <span>Filters</span>
                                                    <span style={{ float: "right" }} onClick={e => this.setState({ showFilters: !this.state.showFilters })}><img src={collapseFilterWhite} alt="" /></span>
                                                </div>
                                                <div className="filter-menu-content">
                                                    {this.state.isLoaded ?
                                                        <SearchFilter allFilters={this.state.allFilters}
                                                            filters={this.state.filters}
                                                            appliedFilters={this.state.appliedFilters}
                                                            handleOnChangeInfilter={this.handleOnChangeInfilter}
                                                            selectedParentFilterArr={this.state.selectedParentFilterArr}
                                                            handleChangeInSearch={this.handleChangeInSearch}
                                                            selectedFilters={this.state.selectedFilters}
                                                            querytext={this.state.searchKeyword}
                                                            documentCount={this.state.documentCount}
                                                            response={this.state.response}
                                                            customWidth={this.state.width}
                                                            isProcessing={this.state.isProcessing} /> :
                                                        <div style={{ textAlign: "center", padding: "10%" }}>
                                                            <img src={imsLoader} alt="Loading Filters" style={{ width: "20%" }} />
                                                        </div>
                                                    }

                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="result-filter-expand-wrapper" onClick={e => this.setState({ showFilters: !this.state.showFilters })}><img src={expandFilterWhite} alt="" /></div>
                                            </>
                                        }
                                    </div>
                                    <div className={this.state.showFilters ? "imsResultsMenuWrapper imsResultsMenuCollapse" : "imsResultsMenuWrapper"} style={{ float: "right", borderBottom: "none" }} >
                                        {/* <div className="imsResultsMenuHeader" style={{width:"100%",float:"left",background:"#5e8ebd",marginTop:"0"}}>  
                            </div> */}
                                        <div className="resultsContentWrapper">
                                            {this.state.appliedFilters.length > 0 ?
                                                <FilterDisplay
                                                    allFilters={this.state.allFilters}
                                                    appliedFilters={this.state.appliedFilters}
                                                    handleClearFilters={this.handleClearFilters}
                                                    handleRemoveAppliedFilter={this.handleRemoveAppliedFilter} />
                                                : null}

                                            {this.state.isLoaded ? <>
                                                {this.state.response.length > 0 &&
                                                    <>
                                                        {this.resultListCount(this.state.itemsPerPage, this.state.currentPage)}
                                                    </>
                                                }
                                                {this.state.response.length > 0 &&
                                                    this.resultList(this.state.response, this.state.itemsPerPage, this.state.currentPage)
                                                }
                                                <Pagination metadata={this.state.documentCount} handlePageChange={this.handlePageChange} itemsPerPage={this.state.itemsPerPage} page={this.state.currentPage} />
                                            </>
                                                : <div style={{ textAlign: "center", padding: "10%" }}>
                                                    <img src={imsLoader} alt="Loading Data" style={{ width: "10%" }} />
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ margin: "0 1.5%" }}>
                                <Footer customWidth={this.state.width} customHeight={this.state.height} />
                            </div>
                        </>
                        :
                        <div style={{ textAlign: "center", padding: "10%" }}>
                            <img src={imsLoader} alt="Loading Data" style={{ width: "10%" }} />
                        </div>
                    }
                </ErrorBoundary>
            </>
        )
    }
}