/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { imsLoader, crossIcon } from "../../assets";
import './GenAIAnsweringPreview.css'
import { LocationOrigin } from '../../utils/utilityServices';
import axios from "axios";
class GenAIAnsweringPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previewURL: '',
            apiURL: '',
            selectedSource: props.selectedSource,
            selectedPageNumber: props.selectedPageNumber,
            isProcessing: true,
            PageNumber: props.selectedPageNumber[0],
            isMultiFiles: props.isMultiFiles,
            attachedFiles: props.attachedFiles,
        }

    }
    componentDidMount = async () => {
        let { isMultiFiles } = this.state;
        let apiURL = "";
        if (isMultiFiles) {
            let { attachedFiles, selectedSource } = this.state;
            let filtersFile = attachedFiles.filter((singleFile => {
                if(selectedSource.document_guid && singleFile.guid){
                    return singleFile.guid === selectedSource.document_guid; 
                }else{
                    return singleFile.name === selectedSource.document_title;
                }
            }))
            if (filtersFile.length > 0) {
                let singleFile = filtersFile[0];
                var url = URL.createObjectURL(singleFile);
                this.setState({ previewURL: url, isProcessing: false })
            } else {
                this.setState({ previewURL: "", isProcessing: false })
            }
        } else {
            if (decodeURIComponent(this.props.selectedSource.download_url).includes("csID=29")) {
                //var href = new URL(`${window.location.origin}${this.props.selectedSource.download_url}`);
                var href = new URL(`https://ims.jnj.com${this.props.selectedSource.download_url}`);
                href.searchParams.set('connID', '7');
                href.searchParams.set('csID', '50');
                href.searchParams.set('fileType', 'pdf');
                apiURL = href.toString();
            } else {
                //apiURL = `${window.location.origin}${this.props.selectedSource.download_url}`
                apiURL = `https://ims.jnj.com${this.props.selectedSource.download_url}`
            }
            if (this.props.selectedSource.enclosureDocID && this.props.selectedSource.enclosureDocID.trim()) {
                apiURL += `&enclosureID=${this.props.selectedSource.enclosureDocID}`
            }
            this.setState({ apiURL: apiURL }, async () => {
                await this.fetchDocument();
            })
        }
    }

    fetchDocument = async () => {
        const { apiURL } = this.state;
        await axios.get(
            apiURL,
            {
                responseType: "blob",
            }
        ).then((response) => {
            const data = new Blob([response.data], { type: 'application/pdf' });
            var url = window.URL.createObjectURL(data)
            this.setState({ previewURL: url, isProcessing: false })
        }).catch((error) => {
            console.log(error);
            this.setState({ previewURL: "", isProcessing: false })
        });
    }
    handlePreviewClose = () => {
        this.props.handleClose(false)
    }
    render() {
        let { selectedSource, isProcessing, PageNumber, previewURL } = this.state;
        return (<div className="gen-ai-document-preview-wrapper">
            <div className="gen-ai-document-preview-title-wrapper">
                <a target="_blank" href={selectedSource.download_url} title={selectedSource.document_title} className="gen-ai-document-preview-title-a" style={{ flex: "1" }}>{selectedSource.document_title}</a>
                <span>
                    <button onClick={e => this.handlePreviewClose()} style={{ cursor: "pointer", marginLeft: "10px" }}><img src={crossIcon} width={10} height={10} alt="Cross" /></button>
                </span>
            </div>
            {isProcessing ? <div style={{ textAlign: "center", marginBottom: "10px" }}>
                <img src={imsLoader} alt="Loading Filters" style={{ width: "10%", marginTop: "200px" }} />
            </div> : <>
                {previewURL ? <div className="gen-ai-document-preview-panel">
                    <object data={`${previewURL}#page=${PageNumber}`} width="100%" height="100%">
                        <p>Unable to display PDF file. </p>
                    </object>
                </div> : <div className="gen-ai-document-preview-error-panel">
                    <p>Unable to display PDF file. </p>
                </div>}
            </>
            }

        </div>)
    }
}
export default GenAIAnsweringPreview;