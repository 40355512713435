import { Component } from "react";
import Footer from "../../components/footer/Footer";

class PrivacyPolicy extends Component{
    componentDidMount(){
        window.scrollTo(0,0);
        document.title='iMedical Search - Privacy Statement';
    }
render(){
    return(
            <div>
                <div className="pp-outer-wrapper">
                    <div className="pp-content-wrapper">
                        <div className="pp-header-wrapper">
                            iMedical Search - Privacy ​Statement​​​
                        </div>
                        <div className="pp-block-wrapper">
                            <p>Welcome to iMedical Search (https://imks.jnj.com). This site is owned by Janssen Scientific Affairs, LLC. This Privacy Statement is designed to ​tell you about our practices regarding collection, use, and disclosure of information that you may provide via this website</p>
                            
                            <p>
                            The personal data collected by this site (including but not limited to user logins, search history, and downloads) is intended for use by Janssen Scientific Affairs, LLC for the purposes of ensuring the application provides its users with highly relevant search results through a more personalized and targeted search experience.
                            </p>

                            <p>
                            Appropriate privacy and security protections are in place to prevent disclosure of your personal data except to those employees and third parties with a business need for access to your information.
                            </p>

                            <p>
                            Personal data collected through this system may be disclosed to other Johnson & Johnson Operating Companies, located in the United States or elsewhere, for the purposes described above. In addition, personal data may be disclosed to third parties, located in the United States and/or any other country, but only:
                             </p>   
                            <div style={{borderLeft:"5px solid #eee"}}>
                                <p>
                                    <ol type="i">
                                        <li>
                                        to contractors we use to support our business and other vendors who provide business support and technical services. Such vendors are obligated to treat employee personal data in accordance with this Privacy Statement.
                                        </li>
                                        <li>
                                        where required by applicable laws, court orders, or government regulations, or pursuant to a valid request of a governmental agency.
                                        </li>
                                    </ol>
                                </p>
                            </div>
                             <p>
                             In addition, we will make full use of data that is not in personally identifiable form.
                             </p>

                             <p>
                             Individual users may access their profile preferences at any time to update or change information. 
                             </p>
                             
                             <div>
                                 <h2 style={{color:"#0072C6",lineHeight:"1.4",fontSize:"30px"}}>How to Contact Us​​​​</h2>
                                 <p>To obtain a report of your own activity, or for questions on the processing of information in relation to these tools, please contact <a href="mailto:imks@its.jnj.com" style={{color: "#1076bc",cursor: "pointer"}}>imks@its.jnj.com</a>. </p>
                             </div>
                             <div>
                                 <h2 style={{color:"#0072C6",lineHeight:"1.4",fontSize:"30px"}}>Changes to This Privacy Statement​​</h2>
                                 <p>
                                 If this Privacy Statement changes, the revised statement will be posted on this site. This Privacy Statement was last updated on <span style={{textDecoration:"underline"}}>November 22, 2016. </span></p>
                             </div>
                        </div>
                    </div>
                 </div>
                 <div style={{margin:"2% 1.5% 0% 1.5%" }}>
                    <Footer/>
                 </div>    
            </div>    
        )
    }   
}
export default PrivacyPolicy;