import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

export default function GenAIThreadHistory(props) {
    const [isMouseHoverState, setMouseHoverState] = useState(false)
    const { source, handleDeleteThreadClick } = props;
    return (<div className="gen-ai-answering-menuitem-thread-history-item" onMouseEnter={e => setMouseHoverState(true)} onMouseLeave={e => setMouseHoverState(false)}>
        {isMouseHoverState ? <>
            <svg aria-hidden="false" focusable="false" data-prefix="far" data-icon="layer-group" className="gen-ai-answering-menuitem-thread-history-item-thread svg-inline--fa fa-layer-group fa-fw animate scale-90 duration-300 ease-in-out group-hover:scale-100 group-hover:opacity-100" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M288 0c-8.5 0-17 1.7-24.8 5.1L53.9 94.8C40.6 100.5 32 113.5 32 128s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2L312.8 5.1C305 1.7 296.5 0 288 0zm-5.9 49.2C284 48.4 286 48 288 48s4 .4 5.9 1.2L477.7 128 293.9 206.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 128 282.1 49.2zM53.9 222.8C40.6 228.5 32 241.5 32 256s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2l-31.2-13.4L430 235.5 477.7 256 293.9 334.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 256 146 235.5 85.1 209.4 53.9 222.8zm0 128C40.6 356.5 32 369.5 32 384s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2l-31.2-13.4L430 363.5 477.7 384 293.9 462.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 384 146 363.5 85.1 337.4 53.9 350.8z"></path></svg>
            &nbsp;&nbsp;</> :
            <>&nbsp;</>
        }
        <Link to={`/genAISearch/${source.answeringinfo.session_id}`} title={source.answeringinfo.question} className="gen-ai-answering-menuitem-thread-link" >
            {source.answeringinfo.question}
        </Link>
        {isMouseHoverState ? <>
            <svg onClick={e => { handleDeleteThreadClick(source.answeringinfo.session_id); }} aria-hidden="true" focusable="false" data-prefix="far" data-icon="trash" className="gen-ai-answering-menuitem-thread-history-item-delete svg-inline--fa fa-trash fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M177.1 48h93.7c2.7 0 5.2 1.3 6.7 3.6l19 28.4h-145l19-28.4c1.5-2.2 4-3.6 6.7-3.6zM354.2 80L317.5 24.9C307.1 9.4 289.6 0 270.9 0H177.1c-18.7 0-36.2 9.4-46.6 24.9L93.8 80H80.1 32 24C10.7 80 0 90.7 0 104s10.7 24 24 24H35.6L59.6 452.7c2.5 33.4 30.3 59.3 63.8 59.3H324.6c33.5 0 61.3-25.9 63.8-59.3L412.4 128H424c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8H367.9 354.2zm10.1 48L340.5 449.2c-.6 8.4-7.6 14.8-16 14.8H123.4c-8.4 0-15.3-6.5-16-14.8L83.7 128H364.3z"></path></svg>
        </> :
            <>&nbsp;</>
        }
    </div>)
}