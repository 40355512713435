/* eslint-disable array-callback-return */
export default class FilterService {
  /**
   * Add the leaves that are the descendents of the provided filter, or just add
   * the filter itself if it is a leaf, to the provided filter set.
   *
   * @param  {Object} filters
   * @param  {String} key
   * @param  {Array}  filterSet
   * @return {Array}
   */
  addChildLeavesToFilterSet(filters, key, filterSet) {
    if (this.doesFilterHaveChildren(filters, key)) {
      return this.createFilterSet(
        ...filterSet,
        ...this.getChildLeavesOfFilter(filters, key)
      )
    } else {
      return this.addFilterToFilterSet(key, filterSet)
    }
  }

  /**
   * Add the provided filter to the provided filter set.
   *
   * @param  {String} key
   * @param  {Array}  filterSet
   * @return {Array}
   */
  addFilterToFilterSet(key, filterSet) {
    return this.createFilterSet(
      ...filterSet,
      key
    )
  }

  /**
   * Determine if the provided filter sets contain the exact same filters.
   *
   * @param  {Array<String>} firstSet
   * @param  {Array<String>} secondSet
   * @return {Boolean}
   */
  areFilterSetsEqual(firstSet, secondSet) {
    //debugger;
    return firstSet.length === secondSet.length &&
      JSON.stringify(firstSet.sort()) === JSON.stringify(secondSet.sort())
  }

  /**
   * Optimize the provided filter set such that the highest level of the tree is
   * used to represent its leaves if all the leaves are in the provided filter
   * set. In this way multiple leaves can be represented by one node. If one or
   * more leaves is missing from the filter set then the parent node cannot be
   * used.
   *
   * @param  {Object} filters
   * @param  {Array}  filterSet
   * @return {Array}
   */
  condenseFilterSet(filters, filterSet) {
    let finalSet = this.createFilterSet(...filterSet)

    const walkNodes = node => {
      if (this.doesFilterSetHaveAllChildLeaves(filters, node.key, finalSet)) {
        finalSet = this.createFilterSet(
          ...this.removeChildLeavesFromFilterSet(filters, node.key, finalSet),
          node.key
        )
      } else {
        Object.values(node.children).forEach(walkNodes)
      }
    }

    Object.values(filters).forEach(walkNodes)

    return finalSet
  }

  /**
   * Create a filter set from the provided values. The returned filter set will
   * not contain any duplicates and will be sorted.
   *
   * @param  {...String} values
   * @return {Array<String>}
   */
  createFilterSet(...values) {
    return Array.from(new Set(values))
  }

  /**
   * Iterate through a set of filters to ensure that the given filter exists
   * 
   * @param {Array<String>} allFilters 
   * @param {String} fullFilter 
   */
  filterExists(allFilters, fullFilter) {
    const [filter, ...filterParts] = fullFilter.split("/")
    const filterData = allFilters[filter]

    if (filterData && filterParts.length < 1) {
      // Node that may or may not have children but we've exhausted the filter
      return true
    } else if (filterData) {
      if (Object.keys(filterData.children).length <= 0) {
        // There's more to the filter but there's no children to search through
        return false
      } else {
        // There's still more filter to inspect and children to look through
        return this.filterExists(filterData.children, filterParts.join("/"))
      }
    } else {
      // The filter we're looking at does not exist in all filters
      return false
    }
  }

  /**
   * The reverse of condensing a filter set. De-optimize the provided filter set
   * such that higher levels of the tree in the filter set are replaced with
   * their leaves. Individual leaves are left without any alteration.
   *
   * @param  {Object} filters All filters
   * @param  {Array}  filterSet Filters from query params
   * @return {Array} An array where the first item is the revised filter set and the second key is the list of ignored filters
   */
  expandFilterSet(filters, filterSet) {
    const ignoredFilters = []
    // Remove any filters that don't exist in the full list of filters
    filterSet = filterSet.filter(filter => {
      if (this.filterExists(filters, filter)) {
        return true
      }
      // Save the ignored filter for later use
      ignoredFilters.push(filter)
      return false
    })

    const cleanIgnored = Array.from(new Set(ignoredFilters))

    let finalSet = this.createFilterSet(...filterSet)

    filterSet.forEach(node => {
      if (this.doesFilterHaveChildren(filters, node)) {
        finalSet = this.createFilterSet(
          ...this.removeFilterFromFilterSet(node, finalSet),
          ...this.getChildLeavesOfFilter(filters, node)
        )
      }
    })
    return [finalSet.sort(), cleanIgnored.sort()]
  }

  /**
   * Determine if the provided filter set has all the leaves of the provided
   * filter. If the provided filter is a leaf and has no children, then the
   * method will return true.
   *
   * @param  {Object}  filters
   * @param  {String}  key
   * @param  {Array}   filterSet
   * @return {Boolean}
   */
  doesFilterSetHaveAllChildLeaves(filters, key, filterSet) {
    if (this.doesFilterHaveChildren(filters, key)) {
      const childLeaves = this.getChildLeavesOfFilter(filters, key)
      return childLeaves.every(item => filterSet.includes(item))
    } else {
      return this.isFilterInFilterSet(key, filterSet)
    }
  }

  /**
   * Determine if the provided filter set has one or more leaves of the provided
   * filter. If the provided filter is a leaf and has no children, then the
   * method will return true.
   *
   * @param  {Object}  filters
   * @param  {String}  key
   * @param  {Array}   filterSet
   * @return {Boolean}
   */
  doesFilterSetHaveAnyChildLeaves(filters, key, filterSet) {
    if (this.doesFilterHaveChildren(filters, key)) {
      const childLeaves = this.getChildLeavesOfFilter(filters, key)
      //console.log('checked true')
      return childLeaves.some(item => filterSet.includes(item))
    } else {
      //console.log('checked false')
      return this.isFilterInFilterSet(key, filterSet)
    }
  }

  /**
   * Determine if the provided filter has any children.
   *
   * @param  {Object}  filters
   * @param  {String}  key
   * @return {Boolean}
   */
  doesFilterHaveChildren(filters, key) {
    const filter = this.findFilterByKey(filters, key)
    return filter ? !!Object.keys(filter.children).length : false
  }

  /**
   * Find a filter object in the provided filter tree by the provided key.
   *
   * @throws {Error} If any of the parents of the filter are missing from the tree.
   * @throws {Error} If the filter is missing from the tree.
   *
   * @param  {Object} filters
   * @param  {String} key
   * @return {Object}
   */
  findFilterByKey(filters, key) {
    if (key) {
      const parts = key.split('/')
      const lastPart = parts.pop()

      let tmp = filters

      parts.forEach(part => {
        if (!tmp[part]) {
          return false
        }

        tmp = tmp[part].children
      })

      if (!tmp[lastPart]) {
        return false
      }


      return tmp[lastPart]
    }
  }

  /**
   * Convert a filter tree into a flat array of all the keys container in the
   * tree at all levels.
   *
   * @param  {Object} filters
   * @return {Array<String>}
   */
  getAllKeysFromFilters(filters) {
    const keys = []

    Object.values(filters).forEach(filter => {
      keys.push(
        filter.key,
        ...this.getChildKeysOfFilter(filters, filter.key)
      )
    })

    return keys
  }

  /**
   * Retrive the keys of all the leaves belonging to the provided filter.
   *
   * @param  {Object} filters
   * @param  {String} key
   * @return {Array<String>}
   */
  getChildLeavesOfFilter(filters, key) {
    const childKeys = []
    const parent = this.findFilterByKey(filters, key)

    const walkChildren = (children) => {
      Object.values(children).forEach(child => {
        if (Object.keys(child.children).length) {
          walkChildren(child.children)
        } else {
          childKeys.push(child.key)
        }
      })
    }

    walkChildren(parent.children)

    return childKeys
  }

  /**
   * Create a flat array of keys of all the children belonging to the filter
   * identified by the provided key from the provided filter tree.
   *
   * @param  {Object} filters
   * @param  {String} key
   * @return {Array<String>}
   */
  getChildKeysOfFilter(filters, key) {
    const childKeys = []
    const parent = this.findFilterByKey(filters, key)

    const walkChildren = (children) => {
      if (children) {
        Object.values(children).forEach(child => {
          childKeys.push(child.key)
          walkChildren(child.children)
        })
      }
    }

    walkChildren(parent.children)

    return childKeys
  }

  /**
   * Determine if the provided filter is in the provided filter set.
   *
   * @param  {String}  key
   * @param  {Array}   filterSet
   * @return {Boolean}
   */
  isFilterInFilterSet(key, filterSet) {
    return filterSet.includes(key)
  }

  /**
   * Remove the child leaves of the provided filter from the provided filter
   * set. If the provided filter is a leaf, then remove it from the provided
   * filter set.
   *
   * @param  {Object} filters
   * @param  {String} key
   * @param  {Array}  filterSet
   * @return {Array}
   */
  removeChildLeavesFromFilterSet(filters, key, filterSet) {
    if (this.doesFilterHaveChildren(filters, key)) {
      const childLeaves = this.getChildLeavesOfFilter(filters, key)
      return this.createFilterSet(
        ...filterSet.filter(item => !childLeaves.includes(item))
      )
    } else {
      return this.createFilterSet(
        ...filterSet.filter(item => item !== key)
      )
    }
  }

  /**
   * Remove the provided filter from the provided filter set.
   *
   * @param  {String} key
   * @param  {Array}  filterSet
   * @return {Array}
   */
  removeFilterFromFilterSet(key, filterSet) {
    return this.createFilterSet(
      ...filterSet.filter(value => value !== key)
    )
  }

  /**
   * Remove keys from the provided filter set that have no corresponding filter
   * in the provided filter tree. Returns a new filter set.
   *
   * @param  {Object} filters
   * @param  {Array<String>} filterSet
   * @return {Array<String>}
   */
  removeMissingFiltersFromFilterSet(filters, filterSet) {
    const filterKeys = this.getAllKeysFromFilters(filters)

    return this.createFilterSet(
      ...filterSet.filter(filter => filterKeys.includes(filter))
    )
  }
  findParentAlreadyExists(key, filterSet) {
    let isSuperset = false
    filterSet.forEach(filter => {
      if (filter.startsWith(key)) {
        let arrLen = this.findLevelOfSelectedFilter(filter)
        if (arrLen > 2) {
          isSuperset = true
        }
      }
    })
    return isSuperset
  }
  findLevelOfSelectedFilter(key) {
    let arr = key.split('/')
    return arr.length
  }
  getParentOfFilter(key) {
    let items = key.split('/');
    items.pop();
    return items.join('/');

  }
  getChildKeysForUpdatedFilters(filterSet, filters) {
    let arr = [];
    filterSet.map(item => {
      let nestedChild = this.getChildKeysOfFilter(filters, item)
      if (nestedChild.length > 0) {
        arr.push(nestedChild)
      }
    })
    let merged = [].concat.apply([], arr);
    return merged;
  }
  // CHECKS IF THERE IS COMMAN VALUES BETWEEN TWO ARRAYS
  findCommonElements(arr1, arr2) {
    return arr2.every(ai => arr1.includes(ai))
  }
  // GET IMMEDIATE CHILD
  getImmediateChild(childcheck, parentFilter) {
    let immediatechild = childcheck.filter(item => {
      if (item) {
        let childarr = item.split("/")
        // let yy = "Drug Dosing And Administration/Drug Administration Route/Parenteral Drug Delivery".split("/")
        let parentarr = parentFilter.split("/")
        return childarr.length === parentarr.length + 1
      }
    })
    return immediatechild
  }
  getImmediateChildNew(childcheck, parentFilter) {
    return childcheck.filter(item => item.startsWith(parentFilter + "/")).filter(item => item.split("/").filter(x => x).length - 1 === parentFilter.split("/").filter(x => x).length)
  }
}