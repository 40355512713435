import { Component } from "react";
import Header from "../../components/header/Header";
import MIAsReferencesHtmlDocumentResults from "../DownloadDocumentResults/MIAsReferencesHtmlDocumentResults";
import './Download.css';

class MIAsReferencesHtmlView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedResults: [],
            collapsed: false
        }
    }
    componentWillMount() {
        //console.log(this.props.citationStatus)
        /* if(this.props){
           if(this.props.selectedResults.length>0){
               this.setState({
                   selectedResults : this.props.selectedResults
               })
           }
           else{
              console.log('Read Displayed Results');
             this.setState({
                 selectedResults : JSON.parse(localStorage.getItem('displayedResults'))
             }) 
          }
          if(this.props.citationStatus === 'Title Only'){
              this.setState({
                  collapsed : true
              }) 
           }
           else{
              this.setState({
                  collapsed : false
              }) 
           }
        } */
        //  console.log(this.props.selectedResults,this.props.titleOnly)
        if (this.props.citationType === 'Marked Results') {
            this.setState({
                // selectedResults : window.selectedResults
                selectedResults: this.props.selectedResults
            })
        }
        else {
            console.log('Read Displayed Results');
            this.setState({
                selectedResults: JSON.parse(localStorage.getItem('displayedResults'))
            })
        }
        //  if(window.titleOnly !== undefined && window.titleOnly === true){
        if (this.props.titleOnly !== undefined && this.props.titleOnly === true) {
            this.setState({
                collapsed: true
            })
        }
        else {
            this.setState({
                collapsed: false
            })
        }
    }
    componentDidUpdate(prev) {
        if (this.props.citationType !== prev.citationType || this.props.titleOnly !== prev.titleOnly) {
            if (this.props.citationType === 'Marked Results') {
                this.setState({
                    // selectedResults : window.selectedResults
                    selectedResults: this.props.selectedResults
                })
            }
            else {
                console.log('Read Displayed Results');
                this.setState({
                    selectedResults: JSON.parse(localStorage.getItem('displayedResults'))
                })
            }
            //  if(window.titleOnly !== undefined && window.titleOnly === true){
            if (this.props.titleOnly !== undefined && this.props.titleOnly === true) {
                this.setState({
                    collapsed: true
                })
            }
            else {
                this.setState({
                    collapsed: false
                })
            }
        }
    }
    resultList = (results) => {
        //console.log(this.state.collapsed)
        //window.scrollTo({ behavior: 'smooth', top: '0px' });
        // var count = 0
        if (this.state.selectedResults && this.state.selectedResults.length > 0) {
            return (
                <>
                    {this.state.selectedResults.map((item, index) => {
                        // count++;
                        return (
                            <>
                                <div key={`download-result-id-${index}`} id={`download-result-id-${index}`}>
                                    {
                                        <div className="resultDetailsDiv">
                                            <MIAsReferencesHtmlDocumentResults item={item.detail} count={item.index} itemTeaser={item.itemTeaser} customWidth={this.state.width} collapsed={this.state.collapsed} />
                                            <div className="resultsDivFooter">
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        )
                    })
                    }
                </>
            )
        }
    }
    render() {
        return (
            <div className="download-results-page-wrapper">
                <div style={{ textAlign: "center" }}>
                    <Header />
                </div>
                <div className="download-results-page-contents-wrapper">
                    {this.resultList(this.state.selectedResults)}
                </div>
            </div>
        )
    }
}
export default MIAsReferencesHtmlView