/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
import { Modal } from "react-bootstrap";
import { popCancelBttn, imsLoader } from "../../assets";
import { Component } from "react";
import Button from "../Button";
import "./GlobalSearchFeaturesPopUp.css";
import "./NotifyMePopUp.css";
import ApiServices from "../../utils/apiServices";
import MobileViewButton from "../Button/MobileViewButton";
import trackingService from "../../services/trackingService";
import {snippetLongString} from "../../utils/utilityServices"
import ConfirmMessagePopUpModal from "../PopUp/ConfirmMessagePopUpModal"
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal"
import moment from 'moment';

class NotifyMePopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreateAlert: true,
      isManage: false,
      apiResponse: [],
      frequencyValue: [],
      alertName: "",
      personalAlertFrequency: "Daily",
      error: "",
      isContinue: false,
      searchDes: "",
      searchKeyword: "",
      searchQuery: "",
      alertSaved: false,
      confirmMessageContent: '',
      confirmMessageShowModal: false,
      confirmMessageIndex: -1,
      alertMessageModelColor: "",
      alertMessageContent: "",
      alertMessageShowModal: false,
    };
  }
  async componentDidMount() {
    let service = new ApiServices();
    let username = JSON.parse(localStorage.getItem("username")).toLowerCase();
    let personalAlerts = await service.getProductsAlertsById(username);
    let apiRes = personalAlerts?.filter(
      (data, i) => data.isproduct_alert === 0
    );
    apiRes = apiRes.sort(function (a, b) {
      return (
        new Date(b.date_modified).getTime() -
        new Date(a.date_modified).getTime()
      );
    });
    let feqArr = [];
    if (apiRes?.length > 0) {
      for (let index = 0; index < apiRes.length; index++) {
        feqArr[index] = apiRes[index].alert_frequency;
      }
    }
    this.setState({
      apiResponse: apiRes,
      frequencyValue: feqArr,
      isLoaded: true,
    });
    if (window.location.pathname === "/ManageAlerts") {
      this.setState({ isCreateAlert: false, isManage: true });
    }
  }
  showConfirmMessage = (message, index) => {
    this.setState({
      confirmMessageContent: message,
      confirmMessageShowModal: true,
      confirmMessageIndex: index
    })
  }

  confirmHandler = async () => {
    const { confirmMessageIndex } = this.state;
    let username = JSON.parse(localStorage.getItem('username')).toLowerCase();
    let searchName = this.state.apiResponse[confirmMessageIndex].search_name;
    let service = new ApiServices();
    await service.deletePersonalAlerts(username, searchName);
    this.setState(
      {
        isLoaded: false,
        confirmMessageContent: "",
        confirmMessageShowModal: false,
        confirmMessageIndex: -1
      },
      async () => {
        let personalAlerts = await service.getProductsAlertsById(username);
        let apiRes = personalAlerts.filter(
          (data, i) => data.isproduct_alert === 0
        );
        apiRes = apiRes.sort(function (a, b) {
          return (
            new Date(b.date_modified).getTime() -
            new Date(a.date_modified).getTime()
          );
        });
        let feqArr = [];
        for (let index = 0; index < apiRes.length; index++) {
          feqArr[index] = apiRes[index].alert_frequency;
        }
        this.setState({
          frequencyValue: feqArr,
          apiResponse: apiRes,
          isLoaded: true,
        });
      }
    );
  }

  closeConfirmMessage = () => {
    this.setState({
      confirmMessageContent: "",
      confirmMessageShowModal: false,
      confirmMessageIndex: -1
    })
  }
  successAlertMessage = (message) => {
    this.setState({
        alertMessageContent: message,
        alertMessageModelColor: "#00c12c",
        alertMessageShowModal: true
    })
}

  closeAlertMessage = () => {
    this.setState({
      alertMessageContent: "",
      alertMessageModelColor: "",
      alertMessageShowModal: false
    })
  }
  conversionOfFieldType = (item) => {
    if (item === "docType" || item === "docTypeSynonyms")
      return "Document Type";
    else if (item === "protocolIDs" || item === "harmonizedProtocolIDs") return "Protocol Number";
    else if (item === "genericName") return "Generic Name";
    else if (item === "congressName" || item === "fullCnSf")
      return "Congress Name";
    else if (item === "strYear") return "Publication Year";
    else if (item === "authors") return "Author";
    else if (item === "docTitle") return "Title";
    else return "Journal Name";
  };
  componentWillMount() {
    const queryParams = new URLSearchParams(document.location.search);
    const params = queryParams.get("query");
    const filters = queryParams.get("filters");
    const q = queryParams.get("q");
    const majorTopicsSynonyms = queryParams.get("majorTopicsSynonyms");
    const authors = queryParams.get("authors");
    const docTitle = queryParams.get("docTitle");
    const fcount = queryParams.get("fcount");
    let str = "";
    let fieldedSearch = queryParams.get("FS");
    let limistArr = [];
    let f1 = "", fval1 = "";
    if (window.location.pathname !== "/ReferencesUsed") {
      if (fcount && fcount > 0) {
        f1 = queryParams.get("f1");
        fval1 = queryParams.get("fval1");
      }
      if ((f1 && (f1 === "tempJNJDrugs" || f1 === "taIRO")) &&
        params !== "" &&
        filters !== "" &&
        q !== "All Fields" &&
        !fieldedSearch
      ) {
        if (f1 === "tempJNJDrugs") {
          str = `(Products: ${fval1}): ${q}: ${params}: ${filters}`;
          this.setState({ searchDes: `(Products: ${fval1}): ${q}: ${params}: ${filters}` });
        } else {
          str = `(Therapeutic Area: ${fval1}): ${q}: ${params}: ${filters}`;
          this.setState({ searchDes: `(Therapeutic Area: ${fval1}): ${q}: ${params}: ${filters}` });
        }
      }
      else if ((f1 && (f1 === "tempJNJDrugs" || f1 === "taIRO")) && params !== "" &&
        filters !== "" &&
        q === "All Fields" &&
        !fieldedSearch) {
        if (f1 === "tempJNJDrugs") {
          str = `(Products: ${fval1}): ${params}: ${filters}`;
          this.setState({ searchDes: `(Products: ${fval1}): ${params}: ${filters}` });
        } else {
          str = `(Therapeutic Area: ${fval1}): ${params}: ${filters}`;
          this.setState({ searchDes: `(Therapeutic Area: ${fval1}): ${params}: ${filters}` });
        }
      }
      else if ((f1 && (f1 === "tempJNJDrugs" || f1 === "taIRO")) &&
        params !== "" &&
        filters === "" &&
        q === "All Fields" &&
        !fieldedSearch
      ) {
        if (f1 === "tempJNJDrugs") {
          str = `(Products: ${fval1}): ${params}`;
          this.setState({ searchDes: `(Products: ${fval1}): ${params}` });
        } else {
          str = `(Therapeutic Area: ${fval1}): ${params}`;
          this.setState({ searchDes: `(Therapeutic Area: ${fval1}): ${params}` });
        }
      } else if ((f1 && (f1 === "tempJNJDrugs" || f1 === "taIRO")) &&
        params !== "" &&
        filters === "" &&
        q !== "All Fields" &&
        !fieldedSearch
      ) {
        if (f1 === "tempJNJDrugs") {
          str = `(Products: ${fval1}): ${q}: ${params}`;
          this.setState({ searchDes: `(Products: ${fval1}): ${q}: ${params}` });
        } else {
          str = `(Therapeutic Area: ${fval1}): ${q}: ${params}`;
          this.setState({ searchDes: `(Therapeutic Area: ${fval1}): ${q}: ${params}` });
        }
      }
      else if (
        params !== "" &&
        filters !== "" &&
        q !== "All Fields" &&
        !fieldedSearch
      ) {
        str = `${q}: ${params}: ${filters}`;
        this.setState({ searchDes: `${q}: ${params}: ${filters}` });
      }
      else if (
        params === "" &&
        filters !== "" &&
        q === "All Fields" &&
        !fieldedSearch
      ) {
        str = `${filters}`;
        this.setState({ searchDes: `${filters}` });
      } else if (
        params !== "" &&
        filters !== "" &&
        q === "All Fields" &&
        !fieldedSearch
      ) {
        str = `${params}: ${filters}`;
        this.setState({ searchDes: `${params}: ${filters}` });
      } else if (
        params !== "" &&
        filters === "" &&
        q === "All Fields" &&
        !fieldedSearch
      ) {
        str = `${params}`;
        this.setState({ searchDes: `${params}` });
      } else if (
        params !== "" &&
        filters === "" &&
        q !== "All Fields" &&
        !fieldedSearch
      ) {
        str = `${q}: ${params}`;
        this.setState({ searchDes: `${q}: ${params}` });
      } else if (fieldedSearch) {
        // Publication Year
        let toRange = queryParams.get("toYear");
        let fromRange = queryParams.get("fromYear");
        let PublicationYear = "";
        if (toRange) {
          toRange = parseInt(toRange);
        }
        if (fromRange) {
          fromRange = parseInt(fromRange);
        }
        let strYear = limistArr.find((x) => x.key === "strYear");
        if (strYear) {
          let strYearValue = parseInt(strYear.value);
          if (
            toRange &&
            fromRange &&
            fromRange <= strYearValue &&
            toRange >= strYearValue
          ) {
            PublicationYear = `${fromRange} To ${toRange}`;
          } else if (
            toRange &&
            fromRange &&
            (fromRange > strYearValue || toRange < strYearValue)
          ) {
            PublicationYear = `${fromRange} To ${toRange} AND ${strYearValue}`;
          } else if (toRange && strYearValue) {
            PublicationYear = `${toRange} and earlier AND ${strYearValue}`;
          } else if (fromRange && strYearValue) {
            PublicationYear = `${fromRange} and later AND ${strYearValue}`;
          } else if (strYearValue) {
            PublicationYear = `${strYearValue}`;
          }
        } else if (toRange && fromRange) {
          PublicationYear = `${fromRange} To ${toRange}`;
        } else if (toRange) {
          PublicationYear = `${toRange} and earlier`;
        } else if (fromRange) {
          PublicationYear = `${fromRange} and later`;
        }
        limistArr = limistArr.filter((x) => x.key !== "strYear");
        ///////
        let fcount = queryParams.get("fcount");
        for (let i = 0; i < fcount; i++) {
          let key = queryParams.get(`f${i + 1}`);
          let value = queryParams.get(`fval${i + 1}`);
          limistArr[i] = { key: key, value: value };
        }

        str = params ? `(${params}): ${filters ? filters : ""}` : "";

        if (!params && params === "" && filters !== "" && filters !== null) {
          str = str + `${filters ? filters : ""}`;
        }

        str = str + (docTitle ? ` (Title: ${docTitle})` : "");
        str = str + (authors ? ` (Authors: ${authors})` : "");
        str =
          str +
          (majorTopicsSynonyms
            ? ` (Major Topics: ${majorTopicsSynonyms})`
            : "");
        str =
          str +
          (PublicationYear ? ` (Publication Year: ${PublicationYear})` : "");
        if (fcount > 0 && limistArr.length > 0) {
          limistArr.map((item, index) => {
            str =
              str +
              (item.key
                ? ` (${this.conversionOfFieldType(item.key)}: ${item.value})`
                : "");
          });
        }
        this.setState({
          searchDes: str,
        });
      } else {
        this.setState({ searchDes: "" });
      }

      if (window.supportingDocsSearchStrategyDescription) {
        let searchDes = window.supportingDocsSearchStrategyDescription;
        if (str) {
          searchDes += `, ${str}`;
        }
        this.setState({ searchDes: searchDes });
      }
      this.setState({
        searchKeyword: params,
        searchQuery: window?.location.search,
      });
    } else {
      this.setState({
        searchKeyword: "",
        searchQuery: window?.location.search,
        searchDes: params,
      });
    }
  }
  // async componentWillReceiveProps() {
  //   let wwid = JSON.parse(localStorage.getItem('wwid'))
  //   let service = new ApiServices;
  //   let personalAlerts = await service.getProductsAlertsById(wwid);
  //   let apiRes = personalAlerts.filter((data, i) => data.isproduct_alert === 0);
  //   apiRes = apiRes.sort(function (a, b) { return new Date(b.date_modified).getTime() - new Date(a.date_modified).getTime() })
  //   let feqArr = [];
  //   if (apiRes?.length > 0) {
  //     for (let index = 0; index < apiRes.length; index++) {
  //       feqArr[index] = apiRes[index].alert_frequency;
  //     }
  //     this.setState({
  //       frequencyValue: feqArr,
  //       apiResponse: apiRes,
  //       // isLoaded: true
  //     })
  //   }
  // }
  async componentDidUpdate(prevProp) {
    let service = new ApiServices();
    let username = JSON.parse(localStorage.getItem("username")).toLowerCase();
    if (prevProp.showPersonalAlerts !== this.props.showPersonalAlerts) {
      let personalAlerts = await service.getProductsAlertsById(username);
      let apiRes = personalAlerts.filter(
        (data, i) => data.isproduct_alert === 0
      );
      apiRes = apiRes.sort(function (a, b) {
        return (
          new Date(b.date_modified).getTime() -
          new Date(a.date_modified).getTime()
        );
      });
      let feqArr = [];
      let feqValue = apiRes.map((data, i) => data.alert_frequency);
      var is_same =
        this.state.frequencyValue.length === feqValue.length &&
        this.state.frequencyValue.every(function (element, index) {
          return element === feqValue[index];
        });
      // if (apiRes?.length > 0) {
      if (is_same || apiRes?.length !== this.state.apiResponse.length) {
        for (let index = 0; index < apiRes.length; index++) {
          feqArr[index] = apiRes[index].alert_frequency;
        }
        this.setState({
          frequencyValue: feqArr,
          apiResponse: apiRes,
          // isLoaded: true
        });
      }
    }
  }
  handleAlertFrequency = async (e, index) => {
    let service = new ApiServices();
    let username = JSON.parse(localStorage.getItem("username")).toLowerCase();
    let action = this.state.frequencyValue;
    action[index] = e.target.value;
    this.setState({ frequencyValue: action });
    // console.log(action, action[index], this.state.apiResponse[index].search_name)
    if (action[index] === "None") {
      await service.updatePersonalAlerts(
        0,
        -1,
        username,
        this.state.apiResponse[index].search_name
      );
    } else {
      await service.updatePersonalAlerts(
        1,
        action[index],
        username,
        this.state.apiResponse[index].search_name
      );
    }
    this.successAlertMessage("Alert Frequency is updated.");
  };
  shareAlerts = (index) => {
    let downloadurl =
      window.location.pathname === "/ReferencesUsed"
        ? `/ReferencesUsed${this.state.apiResponse[index].search_property}`
        : `/results${this.state.apiResponse[index].search_property}`;
    let mailUrl = window.location.origin + downloadurl;
    let searchName = this.state.apiResponse[index].search_name;
    let encodeMailUrl = encodeURIComponent(mailUrl);
    searchName = encodeURIComponent(searchName);
    let mailbody = `Search Name: ${searchName}%0D%0DURL:${encodeMailUrl}%0D%0DTo create your own alert from the search above, follow these instructions:%0D%0D
  1. Click on the link above to run the search on iMS .%0D
  2. Click on "Alert" icon from the search results screen. %0D
  3. For the Search Name option, enter a name of your choosing for the alert. %0D
  4. For the Alerts Frequency option, select the frequency with which you want to receive the alert. %0D
  5. Click the Proceed button to save the alert.%0D%0DTo manage your alerts, access the same Create/Manage Alert icon from a search results screen. 
`;
    window.open(`mailto:?Subject=iMS Search&body=${mailbody}`);
  };
  deletePersonalAlerts = async (index) => {
    this.showConfirmMessage("Are you sure to delete this Saved Search?",index);
  };
  handleInput = (e) => {
    e.target.value=  snippetLongString(e.target.value);
    this.setState({
      alertName: e.target.value,
    });
  };
  handlePersonalAlertFeq = (e) => {
    this.setState({ personalAlertFrequency: e.target.value });
  };
  handleValidation() {
    let errors = {};
    let formIsValid = true;
    let personalAlert = this.state.apiResponse.map((data) => data.search_name.toLowerCase());
    if (this.state.alertName.length <= 0) {
      formIsValid = false;
      errors["searchName"] = "Please insert value.";
    }
    if (personalAlert.includes(this.state.alertName.toLowerCase())) {
      formIsValid = false;
      errors["searchName"] = "Strategy Name already present.";
    }
    this.setState({ error: errors });
    return formIsValid;
  }
  onclickContinue = () => {
    if (this.handleValidation()) {
      this.setState({ isContinue: true });
      document.getElementById("personalAlertName").readOnly = true;
    }
  };
  dateFormate = (date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toLocaleString(
      "en-US",
      {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }
    )}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()}`;
  };
  onclickProceed = async () => {
    let service = new ApiServices();
    let wwid = JSON.parse(localStorage.getItem("wwid"));
    let username = JSON.parse(localStorage.getItem("username")).toLowerCase();
    let currentDate = new Date();
    if (wwid === 0) {
      var jjedsres = await service.getJJEDSdetails(username.split("@")[0]);
      wwid = jjedsres.uid;
    }
    let alertFeq = this.state.personalAlertFrequency;
    let searchProp = this.state.searchQuery;
    let alertName = this.state.alertName;
    let searchKeyword = this.state.searchKeyword;
    if (this.handleValidation()) {
      await service.createAlert(
        wwid,
        searchKeyword,
        alertName,
        searchProp,
        alertFeq,
        username,
        this.dateFormate(currentDate),
        0,
        0
      );
      this.handelAddAlert();
      this.setState({ alertSaved: true });
      // this.props.handleClose(false);
    }
  };
  handelAddAlert = () => {
    let params = new URLSearchParams(this.state.searchQuery);
    let query = params.get("query");
    let filter = params.get("filter");
    const ts = new trackingService();
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      localStorage.getItem("companyname"),
      `Create Alert`,
      "",
      window.location.href,
      query,
      filter
    );
  };
  handleManagePersonalAlerts = () => {
    let username = JSON.parse(localStorage.getItem("username")).toLowerCase();
    let service = new ApiServices();
    this.setState(
      {
        isCreateAlert: false,
        isManage: true,
        isLoaded: false,
      },
      async () => {
        let personalAlerts = await service.getProductsAlertsById(username);
        let apiRes = personalAlerts?.filter(
          (data, i) => data.isproduct_alert === 0
        );
        apiRes = apiRes.sort(function (a, b) {
          return (
            new Date(b.date_modified).getTime() -
            new Date(a.date_modified).getTime()
          );
        });
        let feqArr = [];
        if (apiRes?.length > 0) {
          for (let index = 0; index < apiRes.length; index++) {
            feqArr[index] = apiRes[index].alert_frequency;
          }
        }
        this.setState({
          apiResponse: apiRes,
          frequencyValue: feqArr,
          isLoaded: true,
        });
      }
    );
  };
  render() {
    const { confirmMessageContent, confirmMessageShowModal } = this.state;
    const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
    if (this.props.customWidth < 600) {
      return (
        <Modal
          show={this.props.showPersonalAlerts}
          size="lg md sm xs"
          centered
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="popup-transition-mob-tab"
          scrollable={true}
        >
          <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
            <div>
              <Modal.Header
                className="gsfModalHeader"
                style={{ justifyContent: "left" }}
              >
                <div style={{ textAlign: "center", width: "100%" }}>
                  <Modal.Title>
                    <div
                      className="actionItem-headerPart-mobile"
                      style={{
                        height:
                          window.location.pathname !== "/ManageAlerts"
                            ? null
                            : "auto",
                      }}
                    >
                      {window.location.pathname !== "/ManageAlerts" && (
                        <>
                          <div
                            onClick={(e) => {
                              this.setState({
                                isCreateAlert: true,
                                isManage: false,
                              });
                            }}
                            className={
                              this.state.isCreateAlert
                                ? "alert-active-features-button-mobile"
                                : "alert-features-buttons-mobile"
                            }
                          >
                            <span>Create Alert</span>
                          </div>
                          {/* <div className="action-button-saperation-line-mobile"></div> */}
                        </>
                      )}
                      <div
                        onClick={this.handleManagePersonalAlerts}
                        /* onClick={e => { this.setState({ isCreateAlert: false, isManage: true }) }} */ className={
                          this.state.isManage
                            ? "alert-active-features-button-mobile"
                            : "alert-features-buttons-mobile"
                        }
                      >
                        <span>Manage Personal Alerts</span>
                      </div>
                    </div>
                  </Modal.Title>
                </div>
              </Modal.Header>
              <Modal.Body
                scrollable={true}
                style={{
                  maxHeight: "60vh",
                  padding: this.state.isManage ? "1rem 0" : null,
                }}
              >
                {this.state.isCreateAlert &&
                  (this.state.alertSaved ? (
                    <>
                      <span className="noAlertText-mobile">
                        Your Alert has been saved successfully.
                      </span>
                      <br />
                      <span className="saved-bookmark-text-mobile">
                        Please go to 'Manage Personal Alerts' tab to view all
                        your saved ones.
                      </span>
                    </>
                  ) : (
                    <div>
                      <span className="create-alert-fields">
                        Search Strategy Description
                      </span>
                      <br />
                      <textarea
                        className="create-alert-text-area"
                        readOnly
                        value={this.state.searchDes}
                      ></textarea>
                      <br />
                      <span className="create-alert-fields">
                        Search Name
                      </span>{" "}
                      <br />
                      <input
                        type="text"
                        placeholder="Type here"
                        value={this.state.alertName}
                        className="create-alert-input-box-mobile"
                        onChange={this.handleInput}
                        id="personalAlertName"
                      />
                      <br />
                      <span className="length-info">Name with a maximum of 100 characters.</span>
                      <div className="errorInvalidUrl-mobile">
                        {this.state.error["searchName"]}
                      </div>
                      <br />
                      <span className="create-alert-fields">
                        Alerts Frequency
                      </span>
                      <br />
                      {this.state.isContinue ? (
                        <span className="personal-alert-feq-value">
                          {this.state.personalAlertFrequency}
                        </span>
                      ) : (
                        <>
                          <input
                            type="radio"
                            id="daily"
                            name="alert_feq"
                            value="Daily"
                            class="k-radio"
                            style={{ margin: "0 10px 4px 10px" }}
                            title="Daily = Every Morning"
                            onChange={this.handlePersonalAlertFeq}
                            checked={
                              this.state.personalAlertFrequency === "Daily"
                            }
                          />
                          <label for="daily">Daily</label>
                          <br />
                          <input
                            type="radio"
                            id="weekly"
                            name="alert_feq"
                            value="Weekly"
                            class="k-radio"
                            style={{ margin: "0 10px 4px 10px" }}
                            title="Weekly = Every Sunday Morning"
                            onChange={this.handlePersonalAlertFeq}
                            checked={
                              this.state.personalAlertFrequency === "Weekly"
                            }
                          />
                          <label for="weekly">Weekly</label>
                          <br />
                          <input
                            type="radio"
                            id="monthly"
                            name="alert_feq"
                            value="Monthly"
                            title="Monthly = The first of every month"
                            class="k-radio"
                            style={{ margin: "0 10px 4px 10px" }}
                            onChange={this.handlePersonalAlertFeq}
                            checked={
                              this.state.personalAlertFrequency === "Monthly"
                            }
                          />
                          <label for="monthly">Monthly</label>
                        </>
                      )}
                    </div>
                  ))}
                {this.state.isManage && (
                  <div>
                    {this.state.isLoaded ? (
                      <>
                        {this.state.apiResponse.length > 0 ? (
                          <span>
                            <table className="reading-list-view-wrapper">
                              <tr>
                                <th
                                  style={{ color: "#333", fontSize: "14.5px" }}
                                >
                                  Search Name
                                </th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    color: "#333",
                                    fontSize: "14.5px",
                                  }}
                                >
                                  Alert Frequency
                                </th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    color: "#333",
                                    fontSize: "14.5px",
                                  }}
                                >
                                  Actions
                                </th>
                              </tr>
                              <tbody>
                                {this.state.apiResponse.map((item, index) => {
                                  return (
                                    <tr key={item.search_name}>
                                      <td>{item.search_name}</td>
                                      <td style={{ textAlign: "center" }}>
                                        <select
                                          name={`prod${index + 1}`}
                                          id={`prod${index + 1}`}
                                          onChange={(e) =>
                                            this.handleAlertFrequency(e, index)
                                          }
                                          value={
                                            this.state.frequencyValue[index]
                                          }
                                        >
                                          <option value="None">None</option>
                                          <option value="Daily">Daily</option>
                                          <option value="Weekly">Weekly</option>
                                          <option value="Monthly">
                                            Monthly
                                          </option>
                                        </select>
                                      </td>
                                      <td
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          cursor: "pointer",
                                          width: "100%",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <a
                                          href={
                                            window.location.pathname ===
                                            "/ReferencesUsed"
                                              ? `${window.location.origin}/ReferencesUsed${item.search_property}`
                                              : `${window.location.origin}/results${item.search_property}`
                                          }
                                          title="View"
                                          className="reading-list-action-text"
                                          target="_blank"
                                        >
                                          View
                                        </a>
                                        <span
                                          onClick={(e) =>
                                            this.deletePersonalAlerts(index)
                                          }
                                          title="Delete"
                                          className="reading-list-action-text"
                                        >
                                          Delete
                                        </span>
                                        <span
                                          title="Share"
                                          className="reading-list-action-text"
                                          onClick={() =>
                                            this.shareAlerts(index)
                                          }
                                        >
                                          Share
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </span>
                        ) : (
                          <span className="noAlertText-mobile">
                            {" "}
                            No item found.
                          </span>
                        )}
                      </>
                    ) : (
                      <div style={{ textAlign: "center", padding: "10%" }}>
                        <img
                          src={imsLoader}
                          alt="Loading Data"
                          style={{ width: "10%" }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </Modal.Body>
              {this.state.isCreateAlert ? (
                this.state.alertSaved ? (
                  <div className="reading-list-button-wrapper-mobile">
                    <MobileViewButton
                      text="Close"
                      customColor="#0074B0"
                      action={() => {
                        this.setState({ showPop: false });
                        this.props.handleClose(false);
                      }}
                    />
                  </div>
                ) : (
                  <div className="gsf-popup-button-wrapper-mobile">
                    {/* //<div className="welcomeNxtBtn-mobile">
                    <MobileViewButton text="Cancel" customColor="#0074B0" action={() => {
                      this.setState({ showPop: false });
                      this.props.handleClose(false);
                    }} />
                  </div>// */}
                    {/* {
                    this.state.isContinue ?
                      <div className="welcomeNxtBtn-mobile">
                        <MobileViewButton text="Proceed" customColor="#2EB000" action={this.onclickProceed} />
                      </div>
                      :
                      <div className="welcomeNxtBtn-mobile">
                        <MobileViewButton text="Continue" customColor="#2EB000" action={this.onclickContinue} />
                      </div>
                  } */}
                    <div className="welcomeNxtBtn-mobile">
                      <MobileViewButton
                        text="Proceed"
                        customColor="#2EB000"
                        action={this.onclickProceed}
                      />
                    </div>
                    <div className="welcomeNxtBtn-mobile">
                      <MobileViewButton
                        text="Cancel"
                        customColor="#0074B0"
                        action={() => {
                          this.setState({ showPop: false });
                          this.props.handleClose(false);
                        }}
                      />
                    </div>
                  </div>
                )
              ) : (
                <div className="reading-list-button-wrapper-mobile">
                  <div className="welcomeNxtBtn-mobile">
                    <MobileViewButton
                      text="Close"
                      customColor="#0074B0"
                      action={() => {
                        this.setState({ showPop: false });
                        this.props.handleClose(false);
                      }}
                    />
                  </div>
                </div>
              )}
              {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
              {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
            </div>
          </div>
        </Modal>
      );
    } else if (this.props.customWidth < 1000) {
      return (
        <Modal
          show={this.props.showPersonalAlerts}
          size="lg md sm xs"
          centered
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="popup-transition-mob-tab"
          scrollable={true}
        >
          <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
            <div>
              <Modal.Header
                className="gsfModalHeader"
                style={{ justifyContent: "center" }}
              >
                <div
                  style={{
                    textAlign: "center",
                    width:
                      window.location.pathname === "/ManageAlerts"
                        ? "40%"
                        : "100%",
                    paddingTop: "1%",
                  }}
                >
                  <Modal.Title>
                    <div className="actionItem-headerPart">
                      {window.location.pathname !== "/ManageAlerts" && (
                        <>
                          <div
                            onClick={(e) => {
                              this.setState({
                                isCreateAlert: true,
                                isManage: false,
                              });
                            }}
                            className={
                              this.state.isCreateAlert
                                ? "alerts-active-features-button-tablet"
                                : "alerts-features-buttons-tablet"
                            }
                          >
                            <span>Create Alert</span>
                          </div>
                          <div className="action-button-saperation-line"></div>
                        </>
                      )}
                      <div
                        onClick={this.handleManagePersonalAlerts}
                        /* onClick={e => { this.setState({ isCreateAlert: false, isManage: true }) }} */ className={
                          this.state.isManage
                            ? "alerts-active-features-button-tablet"
                            : "alerts-features-buttons-tablet"
                        }
                        style={{
                          width:
                            window.location.pathname === "/ManageAlerts"
                              ? "100%"
                              : null,
                        }}
                      >
                        <span>Manage Personal Alerts</span>
                      </div>
                    </div>
                  </Modal.Title>
                </div>
              </Modal.Header>
              <Modal.Body scrollable={true} style={{ maxHeight: "40vh" }}>
                {this.state.isCreateAlert &&
                  (this.state.alertSaved ? (
                    <>
                      <span className="noAlertText-mobile">
                        Your Alert has been saved successfully.
                      </span>
                      <br />
                      <span className="saved-bookmark-text-mobile">
                        Please go to 'Manage Personal Alerts' tab to view all
                        your saved ones.
                      </span>
                    </>
                  ) : (
                    <div>
                      <span className="create-alert-fields">
                        Search Strategy Description
                      </span>
                      <br />
                      <textarea
                        className="create-alert-text-area"
                        readOnly
                        value={this.state.searchDes}
                      ></textarea>
                      <br />
                      <div className="searchNameRow">
                        <span className="searchText-tablet">Search Name</span>
                        <div className="create-alert-input-container-tablet">
                          <input
                            type="text"
                            placeholder="Type here"
                            value={this.state.alertName}
                            className="create-alert-input-box-tablet"
                            onChange={this.handleInput}
                            id="personalAlertName"
                          />
                          <br />
                          <span className="length-info">Name with a maximum of 100 characters.</span>
                          <div className="errorInvalidUrl-tablet">
                            {this.state.error["searchName"]}
                          </div>
                        </div>
                      </div>
                      <br />
                      {this.state.error.length === undefined && (
                        <div style={{ margin: "10px" }}></div>
                      )}
                      <span className="create-alert-fields">
                        Alerts Frequency
                      </span>
                      {this.state.isContinue ? (
                        <span className="personal-alert-feq-value">
                          {this.state.personalAlertFrequency}
                        </span>
                      ) : (
                        <>
                          <input
                            type="radio"
                            id="daily"
                            name="alert_feq"
                            value="Daily"
                            class="k-radio"
                            style={{ margin: "0 10px 4px 50px" }}
                            title="Daily = Every Morning"
                            onChange={this.handlePersonalAlertFeq}
                            checked={
                              this.state.personalAlertFrequency === "Daily"
                            }
                          />
                          <label for="daily">Daily</label>
                          <input
                            type="radio"
                            id="weekly"
                            name="alert_feq"
                            value="Weekly"
                            class="k-radio"
                            style={{ margin: "0 10px 4px 50px" }}
                            title="Weekly = Every Sunday Morning"
                            onChange={this.handlePersonalAlertFeq}
                            checked={
                              this.state.personalAlertFrequency === "Weekly"
                            }
                          />
                          <label for="weekly">Weekly</label>
                          <input
                            type="radio"
                            id="monthly"
                            name="alert_feq"
                            value="Monthly"
                            title="Monthly = The first of every month"
                            class="k-radio"
                            style={{ margin: "0 10px 4px 50px" }}
                            onChange={this.handlePersonalAlertFeq}
                            checked={
                              this.state.personalAlertFrequency === "Monthly"
                            }
                          />
                          <label for="monthly">Monthly</label>
                        </>
                      )}
                    </div>
                  ))}
                {this.state.isManage && (
                  <div>
                    {this.state.isLoaded ? (
                      <>
                        {this.state.apiResponse.length > 0 ? (
                          <span>
                            <table className="manage-personal-alerts-wrapper">
                              <tr>
                                <th style={{ width: "20%" }}>Search Name</th>
                                <th
                                  style={{ width: "20%", textAlign: "center" }}
                                >
                                  Alert Frequency
                                </th>
                                <th
                                  style={{ width: "20%", textAlign: "center" }}
                                >
                                  Date Saved
                                </th>
                                <th
                                  style={{ width: "20%", textAlign: "center" }}
                                >
                                  Actions
                                </th>
                              </tr>
                              <tbody>
                                {this.state.apiResponse.map((item, index) => {
                                  return (
                                    <tr key={item.search_name}>
                                      <td>{item.search_name}</td>
                                      <td style={{ textAlign: "center" }}>
                                        <select
                                          name={`prod${index + 1}`}
                                          id={`prod${index + 1}`}
                                          onChange={(e) =>
                                            this.handleAlertFrequency(e, index)
                                          }
                                          value={
                                            this.state.frequencyValue[index]
                                          }
                                        >
                                          <option value="None">None</option>
                                          <option value="Daily">Daily</option>
                                          <option value="Weekly">Weekly</option>
                                          <option value="Monthly">
                                            Monthly
                                          </option>
                                        </select>
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {moment(item.date_modified).format("D-MMM-YYYY")}
                                      </td>
                                      <td
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          cursor: "pointer",
                                          width: "100%",
                                        }}
                                      >
                                        <a
                                          href={
                                            window.location.pathname ===
                                            "/ReferencesUsed"
                                              ? `${window.location.origin}/ReferencesUsed${item.search_property}`
                                              : `${window.location.origin}/results${item.search_property}`
                                          }
                                          title="View"
                                          className="reading-list-action-text"
                                          target="_blank"
                                        >
                                          View
                                        </a>
                                        <span className="action-items-separate-divider"></span>
                                        <span
                                          onClick={(e) =>
                                            this.deletePersonalAlerts(index)
                                          }
                                          title="Delete"
                                          className="reading-list-action-text"
                                        >
                                          Delete
                                        </span>
                                        <span className="action-items-separate-divider"></span>
                                        <span
                                          title="Share"
                                          className="reading-list-action-text"
                                          onClick={() =>
                                            this.shareAlerts(index)
                                          }
                                        >
                                          Share
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </span>
                        ) : (
                          <span className="noAlertText-mobile">
                            {" "}
                            No item found.
                          </span>
                        )}
                      </>
                    ) : (
                      <div style={{ textAlign: "center", padding: "10%" }}>
                        <img
                          src={imsLoader}
                          alt="Loading Data"
                          style={{ width: "10%" }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </Modal.Body>
              {this.state.isCreateAlert ? (
                this.state.alertSaved ? (
                  <div className="reading-list-button-wrapper-tablet">
                    <Button
                      text="Close"
                      customColor="#0074B0"
                      action={() => {
                        this.setState({ showPop: false });
                        this.props.handleClose(false);
                      }}
                    />
                  </div>
                ) : (
                  <div className="gsf-popup-button-wrapper-tablet">
                    <div className="welcomeNxtBtn">
                      <Button
                        text="Cancel"
                        customColor="#0074B0"
                        action={() => {
                          this.setState({ showPop: false });
                          this.props.handleClose(false);
                        }}
                      />
                    </div>
                    {/* {
                    this.state.isContinue ?
                      <div className="welcomeNxtBtn">
                        <Button text="Proceed" customColor="#2EB000" action={this.onclickProceed} />
                      </div>
                      :
                      <div className="welcomeNxtBtn">
                        <Button text="Continue" customColor="#2EB000" action={this.onclickContinue} />
                      </div>
                  } */}
                    <div className="welcomeNxtBtn">
                      <Button
                        text="Proceed"
                        customColor="#2EB000"
                        action={this.onclickProceed}
                      />
                    </div>
                  </div>
                )
              ) : (
                <div className="reading-list-button-wrapper-tablet">
                  <Button
                    text="Close"
                    customColor="#0074B0"
                    action={() => {
                      this.setState({ showPop: false });
                      this.props.handleClose(false);
                    }}
                  />
                </div>
              )}
              {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
              {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
            </div>
          </div>
        </Modal>
      );
    } else {
      return (
        <Modal
          show={this.props.showPersonalAlerts}
          size="lg md sm xs"
          centered
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="popup-transition"
          scrollable={true}
        >
          <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
            <div className="popup-cancel-btn-wrapper">
              <img
                src={popCancelBttn}
                alt=""
                onClick={(e) => {
                  this.setState({ showPop: false });
                  this.props.handleClose(false);
                }}
              />
            </div>
            <div>
              <Modal.Header
                className="gsfModalHeader"
                style={{ justifyContent: "left" }}
              >
                <div
                  style={{
                    textAlign: "center",
                    width:
                      window.location.pathname === "/ManageAlerts"
                        ? "30%"
                        : "60%",
                    paddingTop: "1%",
                  }}
                >
                  <Modal.Title>
                    <div className="actionItem-headerPart">
                      {window.location.pathname !== "/ManageAlerts" && (
                        <>
                          <div
                            onClick={(e) => {
                              this.setState({
                                isCreateAlert: true,
                                isManage: false,
                              });
                            }}
                            className={
                              this.state.isCreateAlert
                                ? "alert-active-features-button"
                                : "alert-features-buttons"
                            }
                          >
                            <span>Create Alert</span>
                          </div>
                          <div className="action-button-saperation-line"></div>
                        </>
                      )}
                      <div
                        onClick={this.handleManagePersonalAlerts}
                        /* onClick={e => { this.setState({ isCreateAlert: false, isManage: true }) }} */ className={
                          this.state.isManage
                            ? "alert-active-features-button"
                            : "alert-features-buttons"
                        }
                        style={{
                          width:
                            window.location.pathname === "/ManageAlerts"
                              ? "100%"
                              : null,
                        }}
                      >
                        <span>Manage Personal Alerts</span>
                      </div>
                    </div>
                  </Modal.Title>
                </div>
              </Modal.Header>
              <Modal.Body scrollable={true} style={{ maxHeight: "40vh" }}>
                {this.state.isCreateAlert &&
                  (this.state.alertSaved ? (
                    <>
                      <span className="noAlertText-mobile">
                        Your Alert has been saved successfully.
                      </span>
                      <br />
                      <span className="saved-bookmark-text-mobile">
                        Please go to 'Manage Personal Alerts' tab to view all
                        your saved ones.
                      </span>
                    </>
                  ) : (
                    <div>
                      <span className="create-alert-fields">
                        Search Strategy Description
                      </span>
                      <br />
                      <textarea
                        className="create-alert-text-area"
                        readOnly
                        value={this.state.searchDes}
                      ></textarea>
                      <br />
                      <span className="create-alert-fields">Search Name</span>
                      <input
                        type="text"
                        placeholder="Type here"
                        value={this.state.alertName}
                        className="create-alert-input-box"
                        onChange={this.handleInput}
                        id="personalAlertName"
                      />
                      <br />
                      <span className="length-info">Name with a maximum of 100 characters.</span>
                      <div className="errorInvalidUrl">
                        {this.state.error["searchName"]}
                      </div>
                      <br />
                      <span className="create-alert-fields">
                        Alerts Frequency
                      </span>
                      {this.state.isContinue ? (
                        <span className="personal-alert-feq-value">
                          {this.state.personalAlertFrequency}
                        </span>
                      ) : (
                        <>
                          <input
                            type="radio"
                            id="daily"
                            name="alert_feq"
                            value="Daily"
                            class="k-radio"
                            style={{ margin: "0 10px 4px 50px" }}
                            title="Daily = Every Morning"
                            onChange={this.handlePersonalAlertFeq}
                            checked={
                              this.state.personalAlertFrequency === "Daily"
                            }
                          />
                          <label for="daily">Daily</label>
                          <input
                            type="radio"
                            id="weekly"
                            name="alert_feq"
                            value="Weekly"
                            class="k-radio"
                            style={{ margin: "0 10px 4px 50px" }}
                            title="Weekly = Every Sunday Morning"
                            onChange={this.handlePersonalAlertFeq}
                            checked={
                              this.state.personalAlertFrequency === "Weekly"
                            }
                          />
                          <label for="weekly">Weekly</label>
                          <input
                            type="radio"
                            id="monthly"
                            name="alert_feq"
                            value="Monthly"
                            title="Monthly = The first of every month"
                            class="k-radio"
                            style={{ margin: "0 10px 4px 50px" }}
                            onChange={this.handlePersonalAlertFeq}
                            checked={
                              this.state.personalAlertFrequency === "Monthly"
                            }
                          />
                          <label for="monthly">Monthly</label>
                        </>
                      )}
                    </div>
                  ))}
                {this.state.isManage && (
                  <div>
                    {this.state.isLoaded ? (
                      <>
                        {this.state.apiResponse.length > 0 ? (
                          <span>
                            <table className="manage-personal-alerts-wrapper">
                              <tr>
                                <th style={{ width: "20%" }}>Search Name</th>
                                <th
                                  style={{ width: "20%", textAlign: "center" }}
                                >
                                  Alert Frequency
                                </th>
                                <th
                                  style={{ width: "20%", textAlign: "center" }}
                                >
                                  Date Saved
                                </th>
                                <th
                                  style={{ width: "20%", textAlign: "center" }}
                                >
                                  Actions
                                </th>
                              </tr>
                              <tbody>
                                {this.state.apiResponse.map((item, index) => {
                                  return (
                                    <tr key={item.search_name}>
                                      <td>{item.search_name}</td>
                                      <td style={{ textAlign: "center" }}>
                                        <select
                                          name={`prod${index + 1}`}
                                          id={`prod${index + 1}`}
                                          onChange={(e) =>
                                            this.handleAlertFrequency(e, index)
                                          }
                                          value={
                                            this.state.frequencyValue[index]
                                          }
                                        >
                                          <option value="None">None</option>
                                          <option value="Daily">Daily</option>
                                          <option value="Weekly">Weekly</option>
                                          <option value="Monthly">
                                            Monthly
                                          </option>
                                        </select>
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {moment(item.date_modified).format("D-MMM-YYYY")}
                                      </td>
                                      <td
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          cursor: "pointer",
                                          width: "100%",
                                        }}
                                      >
                                        <a
                                          href={
                                            window.location.pathname ===
                                            "/ReferencesUsed"
                                              ? `${window.location.origin}/ReferencesUsed${item.search_property}`
                                              : `${window.location.origin}/results${item.search_property}`
                                          }
                                          /* style={{ color: "#333", textDecoration: "none" }} */ title="View"
                                          className="reading-list-action-text"
                                          target="_blank">
                                          View
                                        </a>
                                        <span className="action-items-separate-divider"></span>
                                        <span
                                          onClick={(e) =>
                                            this.deletePersonalAlerts(index)
                                          }
                                          title="Delete"
                                          className="reading-list-action-text"
                                        >
                                          Delete
                                        </span>
                                        <span className="action-items-separate-divider"></span>
                                        <span
                                          title="Share"
                                          className="reading-list-action-text"
                                          onClick={() =>
                                            this.shareAlerts(index)
                                          }
                                        >
                                          Share
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </span>
                        ) : (
                          <span className="noAlertText"> No item found.</span>
                        )}
                      </>
                    ) : (
                      <div style={{ textAlign: "center", padding: "10%" }}>
                        <img
                          src={imsLoader}
                          alt="Loading Data"
                          style={{ width: "10%" }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </Modal.Body>
              {this.state.isCreateAlert ? (
                this.state.alertSaved ? (
                  <div className="reading-list-button-wrapper">
                    <Button
                      text="Close"
                      customColor="#0074B0"
                      action={() => {
                        this.setState({ showPop: false });
                        this.props.handleClose(false);
                      }}
                    />
                  </div>
                ) : (
                  <div className="gsf-popup-button-wrapper">
                    <Button
                      text="Cancel"
                      customColor="#0074B0"
                      action={() => {
                        this.setState({ showPop: false });
                        this.props.handleClose(false);
                      }}
                    />

                    {/* {
                    this.state.isContinue ?
                      <Button text="Proceed" customColor="#2EB000" action={this.onclickProceed} />
                      :
                      <Button text="Continue" customColor="#2EB000" action={this.onclickContinue} />
                  } */}
                    <Button
                      text="Proceed"
                      customColor="#2EB000"
                      action={this.onclickProceed}
                    />
                  </div>
                )
              ) : (
                <div className="reading-list-button-wrapper">
                  <Button
                    text="Close"
                    customColor="#0074B0"
                    action={() => {
                      this.setState({ showPop: false });
                      this.props.handleClose(false);
                    }}
                  />
                </div>
              )}
              {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
              {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
            </div>
          </div>
        </Modal>
      );
    }
  }
}
export default NotifyMePopUp;
