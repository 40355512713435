/* eslint-disable import/no-anonymous-default-export */
import { desArrow, infoIcn } from '../../assets'
import { useState } from 'react';
import { Link } from "react-router-dom";

export default function (props) {

    const [isOpen, setOpenDescriptor] = useState(false);
    const {
        enclosureDocID, enclosureTitle, downloadURL, item, orderedFor, orderedBy
    } = props
    return (
        <div className="document-descriptor-wrapper">
            <div className="document-descriptor-header-wrapper" onClick={e => setOpenDescriptor(!isOpen)}>
                <img src={desArrow} alt='desArrow' className={isOpen ? "document-descriptor-img-rotate" : "document-descriptor-img"} onClick={e => setOpenDescriptor(!isOpen)} />
                <span> Enclosure</span>
                <img src={infoIcn} alt='infoIcn' className="keyTakeaways-info-icn" title="The content included in the Enclosure field" />
            </div>
            {isOpen &&
                <div className="document-descriptor-text-wrapper">
                    <Link
                        id={`docdata${enclosureDocID}`}
                        to={downloadURL}
                        target="_blank"
                        onClick={props.indexData}
                        data-title={item.docTitle}
                        data-products={item.tempJNJDrugs}
                        data-docnumber={item.documentNumber}
                        data-contentsource={item.domain}
                        data-docid={item.searchID}
                        data-doctype={item.docType}
                        data-docurl={downloadURL}
                        data-doclastmodified={item.createdDate}
                        data-orderedby={orderedBy}
                        data-orderedfor={orderedFor}
                    >
                        {enclosureTitle}
                    </Link>
                </div>
            }
        </div>
    )
}