import { Component } from "react";
import {Link} from "react-router-dom";
import {jsblue} from "../../assets";

class JanssenScienceHeaderWithBanner extends Component{
    isRussian;
    constructor(props){
        super(props);
        this.state={
            openPopUp : false,
            showNav:false
        }
        this.isRussian = JSON.parse(localStorage.getItem('russianuser'));
    }
    handleOnHover = () =>{
        this.setState({
            openPopUp : true
        })
    }
    handleOnHoverLeave =()=>{
        this.setState({
            openPopUp : false
        })
    }
    handleNav =()=>{
        this.setState({
            showNav:!this.state.showNav
        })
    }
    handleClose=()=>{
        this.setState({
            showNav:false
        })
    }
    render(){
        return(
            <div className="headerWithBannerWrapper">
                <div className="bannerImage">
                <div className="headerTitle">
                    <Link to="/">
                        <img src={jsblue} alt="iMedical Search" style={{width:"20%",paddingTop:"2%"}}/>
                    </Link>
                </div>
                </div>
            </div>
        )
    }
}
export default JanssenScienceHeaderWithBanner;