/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Footer from "../../components/footer/Footer";
import { IMSLogo, imsLoader } from "../../assets"
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import { filterMapping } from "../../utils/utilityServices";


export default function HandleResults(props) {
    const [isProcessing] = useState(true);
    const [width]=useState(window.innerWidth);
    const [height]=useState(window.innerHeight);
    useEffect(() => {
        let newFilters = "",queryString="";
        let searchParams = "?query=&q=All Fields";
        let searchEncodeURI = decodeURIComponent(props.location.search);
        searchEncodeURI = decodeURIComponent(searchEncodeURI);
        searchEncodeURI = decodeURIComponent(searchEncodeURI);
        searchEncodeURI = decodeURIComponent(searchEncodeURI);
        let strHashEncodeURI = decodeURIComponent(props.location.hash);
        strHashEncodeURI = decodeURIComponent(strHashEncodeURI);
        strHashEncodeURI = decodeURIComponent(strHashEncodeURI);
        const queryParams = new URLSearchParams(searchEncodeURI);
        if (strHashEncodeURI) {
            newFilters = parseJSONFilters(strHashEncodeURI);
            queryString = parseJSONQueryString(strHashEncodeURI);
        }
        
        // eslint-disable-next-line array-callback-return
        Object.keys(filterMapping).map((key) => {
            var regex = new RegExp(key, 'g');
            newFilters = newFilters.replace(regex, filterMapping[key])
        })
        newFilters = newFilters.replace(/\/1Scientific Responses \(All but PH TA\)/g, "/Scientific Responses");
        newFilters = newFilters.replace(/\/Scientific Responses \(All but PH TA\)/g, "/Scientific Responses");
        newFilters = newFilters.replace(/Content Source\/ERIS Labeling \(RIMdocs\)/g, "Document Type/Labeling Documents - Core, EU, US;Document Type/Labeling Documents - Rest of World");
        newFilters = newFilters.replace(/\/Integrated Read-Outs \(IROs\) - iMedical Insights/g, "/iMedical Insights");
        let query = queryParams.get("k");
        let sbQuery = queryParams.get("sb");
        if (queryString === "*") queryString = "";
        if (query === "*") query = "";
        if (sbQuery === "*") sbQuery = "";
        if (queryString) {
            searchParams = parseQuery(queryString, sbQuery)
        } else if (query) {
            searchParams = `?query=${query}&q=All Fields;`
        } else if (sbQuery) {
            searchParams = `?query=${sbQuery}&q=All Fields;`
        }

        searchParams += `&filters=${newFilters}`
        window.location.href = `/results${searchParams}`;
    }, []);
    const parseQuery = (query, sbQuery) => {
        let searchParams = "?query=&q=All Fields";
        if (query.indexOf("mpfulltitle") > -1) {
            searchParams = `?query=${sbQuery}&q=Title&f1=docTitle&fval1=${sbQuery}&fcount=1`
        }
        else if (query.indexOf("mpauthors") > -1) {
            searchParams = `?query=${sbQuery}&q=Author&f1=authors&fval1=${sbQuery}&fcount=1`
        }
        else if (query.indexOf("mpfullcnsf") > -1) {
            searchParams = `?query=${sbQuery}&q=Congress&f1=fullCnSf&fval1=${sbQuery}&fcount=1`
        }
        else if (query.indexOf("mpdoctypesynonyms") > -1) {
            searchParams = `?query=${sbQuery}&q=Document Type&f1=docTypeSynonyms&fval1=${sbQuery}&fcount=1`
        }
        else if (query.indexOf("mpcongresspublicationyear") > -1) {
            searchParams = `?query=${sbQuery}&q=Publication Year&f1=strYear&fval1=${sbQuery}&fcount=1`
        }
        else if (query.indexOf("mpjournalname") > -1) {
            searchParams = `?query=${sbQuery}&q=Journal Name&f1=journalName&fval1=${sbQuery}&fcount=1`
        }
        else if (query.indexOf(`(EAD OR "Evidence and Access")`) > -1) {
            let proName = query.replace("mpProducts:", "");
            proName= proName.replace(` AND (EAD OR "Evidence and Access")`, "");
            searchParams = `?query=${proName}&q=All Fields&f1=tempJNJDrugs&fval1=${proName}&fcount=1&sbQuery=${proName} (EAD OR "Evidence and Access")&isBoolean=true&cspath=Scientific Communication Documents-Evidence and Access Slide Decks&csproduct=Xarelto`
        }
        else if ((query.match(new RegExp("mpProducts:", "g")) || []).length === 1) {
            query = query.replace("mpProducts:", "");
            searchParams = `?query=${query}&q=All Fields&f1=tempJNJDrugs&fval1=${query}&fcount=1`
        }
        else {
            searchParams = `?query=${query}&q=All Fields;`
        }
        return searchParams;
    }

    const parseJSONQueryString = (strQueries) => {
        let strSearchQuery = "";
        if (strQueries.indexOf("#") > -1) {
            if (strQueries && strQueries[0] === '#') {
                strQueries = strQueries.substring(1);
            }
            let strSplitedQueries = strQueries.split('#');
            strQueries = strSplitedQueries[0];
        }
        strQueries = strQueries.replace("Default=", "");

        let d = JSON.parse(strQueries);
        if (d.k) {
            strSearchQuery = d.k;
        }
        return strSearchQuery;
    }
    const parseJSONFilters = (strFilters) => {

        let strSearchFilter = "";
        if (strFilters.indexOf("#") > -1) {
            if (strFilters && strFilters[0] === '#') {
                strFilters = strFilters.substring(1);
            }
            let strSplitedFilters = strFilters.split('#');
            strFilters = strSplitedFilters[0];
        }
        strFilters = strFilters.replace("Default=", "");
        if(strFilters){
            let d = JSON.parse(strFilters);

            if (d.r != null) {
                // eslint-disable-next-line array-callback-return
                d.r.map((data) => {
                    for (let i = 0; i < (data["t"]).length; i++) {
                        if (!strSearchFilter) {
                            strSearchFilter = data["n"] + "/" + decode(data["t"][i]);
                        }
                        else {
                            strSearchFilter = strSearchFilter + ">" + data["n"] + "/" + decode(data["t"][i]);
                        }
                    }
                })
            }
        }
        return strSearchFilter;
    }
    const decode = (encodeStr) => {
        encodeStr = encodeStr.replace(/["ǂ?]/g, '');
        var hex = encodeStr.toString();
        var str = '';
        for (var n = 0; n < hex.length; n += 2) {
            str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
        }
        return str;
    }
 
    return (
        <>
            <ErrorBoundary>
                <div className="document-tab-outer-wrapper">
                    <div className="document-tab-wrapper">
                        <div className="document-header-wrapper">
                            <a>
                                <img src={IMSLogo} alt="" title="iMedical Search" />
                            </a>
                        </div>
                        {isProcessing ? <div style={{ textAlign: "center", padding: "10%" }}>
                            <img src={imsLoader} alt="Loading Filters" style={{ width: "10%" }} />
                        </div> : <div className="document-tab-inner-wrapper">
                           
                            <div>
                                <span style={{ fontSize: "12px" }}>
                                    Want to go to iMedical Search (iMS) Home Page? <Link to="/" style={{ cursor: "pointer", color: "#1076bc" }} target="_blank"> Click here </Link>to proceed.
                                </span><br />
                                {/* <span style={{fontSize:"12px"}}>You may close this tab after your document is downloaded.</span> */}
                            </div>
                            <div className="document-tab-disclaimer-text">
                                <b style={{ color: "red" }}>Disclaimer:</b> The purpose of iMS is to provide Scientific Affairs, Medical Affairs, and R&D personnel worldwide the ability to easily access and share key scientific content <b><i>internally.</i></b> External discussion, presentation, or dissemination of this content as well as use for validated processes must be compliant with local laws, regulations, and company policies and procedures.<br /> <b>Please Note:</b> iMS connects directly to the primary sources of content (e.g. RIMdocs, Veeva Vault) and crawls these content sources once nightly to ensure that you’re accessing only <b>current Approved Effective</b> versions of the documents.
                            </div>
                        </div>}

                        <div className="document-tab-inner-wrapper">
                            <div className="document-tab-content-upper-card">
                                <span style={{ color: "#1076bc", fontSize: "20px" }}><b>Thank you.</b> Soon, you will be sent to the results page.</span><br />
                            </div>
                            <div>
                                <span style={{ fontSize: "12px" }}>
                                    Click <Link to="/" style={{ cursor: "pointer", color: "#1076bc" }} target="_blank">here</Link> to go to iMedical Search - Home page.
                                </span>
                            </div>
                            <div className="document-tab-disclaimer-text">
                                <b style={{ color: "red" }}>Disclaimer:</b> The purpose of iMS is to provide Scientific Affairs, Medical Affairs, and R&D personnel worldwide the ability to easily access and share key scientific content <b><i>internally.</i></b> External discussion, presentation, or dissemination of this content as well as use for validated processes must be compliant with local laws, regulations, and company policies and procedures.<br /> <b>Please Note:</b> iMS connects directly to the primary sources of content (e.g. RIMdocs, Veeva Vault) and crawls these content sources once nightly to ensure that you’re accessing only <b>current Approved Effective</b> versions of the documents.
                            </div>
                        </div>
                    </div>
                    <div style={{ margin: "0% 1.5%" }}>
                        <Footer 
                        customWidth={width}
                        customHeight={height}/>
                    </div>
                </div>
            </ErrorBoundary>
        </>
    )
}