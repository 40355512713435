import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./index.css"
import { GenAIAnsweringContext } from './GenAIAnsweringContextProvider';
import { Popover } from 'react-tiny-popover';
import { processGenAIDocumentTypeFilters, DocumentTypeFilters, GenAI2DocTypes } from '../../utils/documentTypeUtilityServices'
import FilterService from '../../services/FilterService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../content/FilterComponent/FilterComponent.css';
import { resetIcon, popCancelBttn, cancelRedIcon, subfilterExpandButtonMobile, imsLoader } from '../../assets'
import { isEqual, sortBy, clone } from 'underscore'

class GenAIDocumentTypesFilterItem extends Component {
    static contextType = GenAIAnsweringContext;
    constructor(props) {
        super(props);
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            doctype: props.doctype,
            filters: processGenAIDocumentTypeFilters(DocumentTypeFilters),
            filterCategory: props.doctype,
            selectedFilters: props.selectedFilters,
            openFilters: [props.doctype, `${props.doctype}/${props.doctype}`],
            checkedState: [],
            isOpen: false,
            isOpenInPopup:props.isOpenInPopup
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.selectedFilters && this.props.selectedFilters && !isEqual(sortBy(nextProps.selectedFilters), sortBy(this.props.selectedFilters))) {
            this.setState({ selectedFilters: nextProps.selectedFilters });
        } 
        // if (nextProps.selectedFilters.length !== this.props.selectedFilters.length) {
        //     this.setState({ selectedFilters: nextProps.selectedFilters });
        // }
        if (nextProps.openedDocumentType !== this.state.filterCategory) {
            this.setState({ isOpen: false });
        }
        if (nextProps.isOpenInPopup !== this.props.isOpenInPopup) {
            this.setState({ isOpenInPopup: nextProps.isOpenInPopup });
        }
    }

    keyExists = (obj, key) => {
        if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
            return false;
        }
        else if (obj.hasOwnProperty(key)) {
            return true;
        }
        else if (Array.isArray(obj)) {
            for (let i = 0; i < obj.length; i++) {
                const result = this.keyExists(obj[i], key);
                if (result) {
                    return result;
                }
            }
        }
        else {
            for (const k in obj) {
                const result = this.keyExists(obj[k], key);
                if (result) {
                    return result;
                }
            }
        }

        return false;
    };
    /**
   * Render the filter tree content.
   *
   * @return {Object}
   */
    renderContent = () => {
        let { filters, filterCategory } = this.state;
        return (
            <>
                {this.renderFilters(filters[filterCategory].children)}
            </>
        )
    }

    /**
   * Render a single filter.
   *
   * @param  {Object} filter
   * @return {Object}
   */
    renderFilter = (filter) => {
        let { filters, selectedFilters, openFilters } = this.state;
        const filterService = new FilterService();
        let ischecked = filterService.doesFilterSetHaveAnyChildLeaves(filters, filter.key, selectedFilters);
        return (
            <div key={filter.key} className="document-type-filter-popup-item">
                <div className='document-type-filter-popup-item-button'>
                    <button className={`document-type-filter-popup-item-caret clickable ${Object.keys(filter.children).length ? '' : 'hide-caret'}`}
                        onClick={e => {
                            this.handleToggleFilter(filter.key)}}>
                        {
                            filterService.isFilterInFilterSet(filter.key, openFilters) ?
                                <FontAwesomeIcon fixedWidth icon="caret-down" color="#646464" /> :
                                <FontAwesomeIcon fixedWidth icon="caret-right" color="#646464" />
                        }
                    </button>


                    <label className='document-type-filter-popup-item-label clickable' for={filter.key} htmlFor={filter.key} >
                        <input key={filter.key + Math.random()}
                            checked={ischecked}
                            // checked={filterService.doesFilterSetHaveAnyChildLeaves(filters, filter.key, selectedFilters)}
                            onChange={(e) => { this.props.handleChangeFilter(filter.key, e.target.checked) }}
                            type="checkbox"
                            id={filter.key}
                            className="document-type-filter-popup-checkbox"
                        />
                        <div style={{ marginTop: "2px" }} className="document-type-filter-name-text" for={filter.key} htmlFor={filter.key} onClick={(e) => {
                            this.props.handleChangeFilter(filter.key, !ischecked)
                        }}>{filter.label} </div>
                    </label>
                </div>
                <div className="document-type-filter-popup-subitem">
                    {filterService.isFilterInFilterSet(filter.key, openFilters) ? this.renderFilters(filter.children) : null}
                </div>
            </div>
        )
    }

    /**
   * Render a collection of filters.
   *
   * @param  {Array}  filters
   * @return {Object}
   */
    renderFilters = (filters) => {
        let { filterCategory } = this.state;
        if (filterCategory === 'Publication Year') {
            return Object.entries(filters).reverse().map(([path, filter]) => {
                return this.renderFilter(filter)
            })
        }
        else {
            return Object.entries(filters).map(([path, filter]) => {
                return this.renderFilter(filter)
            })
        }
    }

    /**
     * Render a message when no filters are available to show.
     *
     * @return {Object}
     */
    renderNoContent = () => {
        return (
            <div>
                No filters available.
            </div>
        )
    }


    handleToggleFilter = (filter) => {
        const { openFilters } = this.state;

        const filterService = new FilterService();
        let newOpenFilters;

        if (filterService.isFilterInFilterSet(filter, openFilters)) {
            newOpenFilters = filterService.removeFilterFromFilterSet(
                filter,
                openFilters
            );
        } else {
            newOpenFilters = filterService.addFilterToFilterSet(filter, openFilters);
        }

        this.setState({ openFilters: newOpenFilters });
    };
    handleCloseFilterSelect = () => {
        this.setState({
            isOpen: false
        })
    };
    renderHeaderContent = () => {
        let { filterCategory, customWidth, checkedState } = this.state;
        return (
            <div className='document-type-filter-category'>
                {filterCategory === 'Recently Added_Updated' ? <>{'Recently Added/Updated'}</> :
                    filterCategory === 'Document Subtypes' ?
                        <div className="document-type-filter-category-text">{filterCategory} (Submission-Related Documents Only)</div> :
                        <div className="document-type-filter-category-text">{filterCategory} </div>}
                <span style={{ display: "flex" }}>
                    {customWidth < 800 ?
                        <div className="reset-select-document-type-filter-image-wrapper" title="Reset Selections">
                            <img src={resetIcon} alt="" width="12" height="12" onClick={(e) => {
                                checkedState.map((data, i) => {
                                    if (data) {
                                        checkedState[i] = false
                                    }
                                })
                                this.setState({ checkedState: checkedState })
                                // this.resetFilters(e)
                            }} />
                        </div>
                        :
                        <button className="reset-select-document-type-filter-wrapper" title="Reset Selections" onClick={(e) => {
                            checkedState.map((data, i) => {
                                if (data) {
                                    checkedState[i] = false
                                }
                            })
                            this.setState({ checkedState: checkedState })
                            // this.resetFilters(e)
                        }
                        }>
                            Reset Selections <img src={resetIcon} alt="" style={{ paddingBottom: "3%" }} />
                        </button>
                    }
                    {/* <button className="reset-select-document-type-filter-wrapper" title="Reset Selections" onClick={resetFilters}>
        Reset Selections <img src={resetIcon} alt="" style={{paddingBottom:"3%"}}/>
        </button> */}
                    <div className='document-type-filter-times'>
                        {customWidth < 800 ?
                            <div onClick={this.handleCloseFilterSelect} style={{ padding: "0 5px", cursor: "pointer" }}>
                                <img src={customWidth <= 600 ? subfilterExpandButtonMobile : cancelRedIcon} alt="" width="22" height="22" />
                            </div> :
                            <button onClick={this.handleCloseFilterSelect}>
                                <img src={popCancelBttn} width="22" height="22" alt="" />
                            </button>
                        }
                        {/* <button onClick={handleCloseFilterSelect}>
            <img src={popCancelBttn} width="22" height="22" alt=""/>
          </button> */}
                    </div>
                </span>
            </div>
        )
    }
    /**
   * Determine if the apply-changes button should be disabled.
   *
   * @return {Boolean}
   */
    isApplyChangesButtonDisabled = () => {
        let { filterCategory, appliedFilters, selectedFilters } = this.state;
        const parsedAppliedFilters = appliedFilters.filter(value => value && value.startsWith(filterCategory))
        const parsedSelectedFilters = selectedFilters.filter(value => value && value.startsWith(filterCategory))

        if (parsedSelectedFilters.length > 0 || parsedAppliedFilters.length > 0 || window.location.pathname !== '/' || window.location.pathname !== '/recommended')
            return false
        else
            return true
    }
    renderAllFilters = () => {
        let { filters, filterCategory, customWidth, applyFilters, isOpen, isOpenInPopup } = this.state;
        return (
            <div className='filterSubPopUp1'>
                <Popover
                    //isOpen={true}
                    isOpen={isOpen}
                    positions={['top']} // preferred position
                    //  positions={['bottom','left']} 
                    // align={'center'}
                    align={'center'}
                    reposition={false}
                    // onClickOutside={(e) => {
                    //     if (!(e && e.target && e.target.classList && e.target.classList.value && e.target.classList.value.includes('document-type-filter-popup-checkbox'))) {
                    //         this.handleCloseFilterSelect()
                    //     }
                    // }}
                    containerStyle={filterCategory == "Slide Decks" ? isOpenInPopup?{ top: "190px", left: "25%", verticalAlign: "middle", zIndex: "1705" }: { top: "190px", left: "25%", verticalAlign: "middle", zIndex: "1700" } : isOpenInPopup? { top: "190px", left: "10%", verticalAlign: "middle", zIndex: "1705" }: { top: "190px", left: "10%", verticalAlign: "middle", zIndex: "1600" }}
                    content={(positions, align) => (
                        <div className={filterCategory === 'Publication Year' ? 'document-type-filter-modal-wrapper document-type-filter-modal-wrapper-publication-filter' : 'document-type-filter-modal-wrapper'} style={{ marginTop: customWidth <= 1220 && "100px", width: filterCategory === 'Recently Added_Updated' && "400px" }}>
                            {/* {this.renderHeaderContent()} */}
                            <div className='document-type-filter-modal-body'>
                                {/* <button
                                    className='apply-filter'
                                    disabled={this.isApplyChangesButtonDisabled()}
                                    onClick={applyFilters}
                                    title="Apply Changes">
                                    Apply Changes
                                </button> */}
                                <div className='document-type-filter-times'>
                                    {customWidth < 800 ?
                                        <div onClick={this.handleCloseFilterSelect} style={{ padding: "0 5px", cursor: "pointer" }}>
                                            <img src={customWidth <= 600 ? subfilterExpandButtonMobile : cancelRedIcon} alt="" width="22" height="22" />
                                        </div> :
                                        <button onClick={this.handleCloseFilterSelect}>
                                            <img src={popCancelBttn} width="22" height="22" alt="" />
                                        </button>
                                    }
                                    {/* <button onClick={handleCloseFilterSelect}>
            <img src={popCancelBttn} width="22" height="22" alt=""/>
          </button> */}
                                </div>
                                <div className='document-type-filter-modal-body-inner-wrapper'>
                                    <div className={filterCategory === 'Publication Year' ? 'document-type-filter-sub-category-wrapper document-type-filter-sub-category-wrapper-publication-year' : 'document-type-filter-sub-category-wrapper'}>
                                        {filterCategory && filters[filterCategory] !== undefined ? this.renderContent() : this.renderNoContent()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}>
                    <div />
                </Popover>
            </div>
        )
    }
    handleDocumentType = () => {
        let { filterCategory } = this.state;
        this.setState({ isOpen: true });
        this.props.updateOpenedDocumentType(filterCategory)
    }
    render() {

        let { doctype,  isOpen, selectedFilters } = this.state;
        return (<React.Fragment >
            {(doctype === "Scientific Response" || doctype === "Slide Decks" || doctype === "Labels") ? <>
                <div onClick={() => this.handleDocumentType()} className={(selectedFilters.findIndex(x => x.startsWith(doctype)) > -1) ? "gen-ai-answering-main-focus-item gen-ai-answering-main-focus-selected" : "gen-ai-answering-main-focus-item"} >
                    <span for={doctype}>{doctype}</span>
                </div>
                {this.renderAllFilters()}
            </> :
                <div onClick={() => this.props.handleClickDocumentType(doctype)} className={(selectedFilters.findIndex(x => x.startsWith(GenAI2DocTypes[doctype])) > -1) ? "gen-ai-answering-main-focus-item gen-ai-answering-main-focus-selected" : "gen-ai-answering-main-focus-item"} >
                    <span for={doctype} onClick={() => this.props.handleClickDocumentType(doctype)} >{doctype}</span>
                </div>}

        </React.Fragment>)
    }

}
export default withRouter(GenAIDocumentTypesFilterItem);