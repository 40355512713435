/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
import { Component } from "react";
import MIAsReferencesHeader from "../../components/header/MIAsReferencesHeader";
import Footer from "../../components/footer/Footer";
import MIAsReferencesDocumentCard from "../DocumentResults/MIAsReferencesDocumentCard";
import axios from "axios";
import PageItems from "../../components/Paginate/PageItems";
import Pagination from "../../components/Paginate/Pagination";
import Services from "../../utils/services";
import ApiServices from "../../utils/apiServices";
import { imsLoader} from "../../assets";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import NavigationMenu from "../../components/Navigation/NavigationMenu";
import './Results.css';
import MIAsRefGlobalSearchFeaturePopUpModal from "../../components/PopUp/MIAsRefGlobalSearchFeaturesPopUpModal";
import ReadingListPopup from "../../components/PopUp/ReadingListPopup";
import SuccessReadingList from "../../components/PopUp/SucessReadingList";
import BookmarkPopUp from "../../components/PopUp/BookmarkpopUp";
import NotifyMePopUp from "../../components/PopUp/NotifyMePopUp";
import { LocationOrigin } from "../../utils/utilityServices";
import AlertMessagePopUpModal from "../../components/PopUp/AlertMessagePopUpModal";

//var category;
class ReferencesUsedMIAs extends Component {
    userid;
    userExists;
    globalCollapseAll = false;
    documentsUrl = [];
    markedDocumentsUrl = [];
    constructor(props) {
        super(props);
        this.state = {
            response: [],
            username: '',
            userExists: false,
            collapsed: false,
            searchKeyword: '',
            dropdownValue: '',
            allFilters: {},
            documents: [],
            documentCount: 0,
            appliedFilters: [], //Filters that are applied for while search is happening
            selectedFilters: [],
            itemsPerPage: "25",
            currentPage: "1",
            presentDocRendered: "0",
            isLoaded: false,
            isProcessing: true,
            filters: {},
            f1: '',
            fval1: '',
            fieldedSearchArr: {},
            selectedParentFilterArr: [],
            showMajorTopicSearch: false,
            majorTopicSearchState: true,
            width: window.innerWidth,
            height: window.innerHeight,
            citationSelectStatus: [],
            // selectedDocumentIndex: 0,
            isClearSearch: false,
            showResultType: false,
            resultDropdownValue: 'iMS Search Results',
            selectedResults: [],
            prevUrl: '',
            globalCollapseAll: true,
            collapseFilter: false,
            globalSelectAll: false,
            displayedResults: [],
            globalEmailPopUp: false,
            csPath: '',
            csproduct: '',
            readingListPopup: false,
            successMessage: 'The result was successfully removed from Reading list(s).',
            showSuccess: false,
            sortByValue: 'Title, Ascending',
            sortBy: "asc",
            sortField: "docTitle",
            bookmarkPopUp: false,
            globalNotifyMePopUp: false,
            collapsedSearchIDs: [],
            alertMessageModelColor: "",
            alertMessageContent: "",
            alertMessageShowModal: false,
        }
    }
    async componentDidMount() {
        document.title = 'References Used for MIAs - iMedical Search';
        this.userid = JSON.parse(localStorage.getItem('username'));
        this.wwid = JSON.parse(localStorage.getItem('wwid'));
        this.userExists = localStorage.getItem('userExists');
        var itemsPerPage = localStorage.getItem('ItemsPerPage');
        var activePage = localStorage.getItem('ActivePage');
        let service = new Services();
        let apiService = new ApiServices();
        service.logService(`References Used for MIAs page successfully mounted for ${this.userid.toLowerCase()}`, 'info');
        if (this.userExists) {
            this.setState({
                userExists: this.userExists
            })

            /*To detect if user is coming from View Selected Page with some selected data*/
            if (sessionStorage.getItem('selectedResultsDetailsMIAs') === null) {
                this.setState({
                    globalSelectAll: false
                })
            }
            else {
                if (JSON.parse(sessionStorage.getItem('selectedResultsDetailsMIAs')).length > 0) {
                    this.setState({
                        globalSelectAll: true
                    })
                }
                else {
                    this.setState({
                        globalSelectAll: false
                    })
                }
            }
            if (itemsPerPage !== null) {
                this.setState({
                    itemsPerPage: itemsPerPage
                })
            }
            if (activePage !== null) {
                this.setState({
                    currentPage: activePage
                })
            }
            if (this.props.location.search === '') {
                this.props.history.replace('/results?query=&q=All%20Fields&filters=')
            }
            /*Get Approval Status of user and assign localStorage Values*/
            let approvedState = await apiService.getUserStatus(this.wwid);
            let apiRes = await apiService.getUserApprovedRegionRole(this.wwid);
            if (approvedState.status === 'Accepted') {
                localStorage.setItem('region', approvedState.regions);
                localStorage.setItem('function', JSON.stringify(approvedState.profession));
            }
            else {
                if (apiRes) {
                    localStorage.setItem('region', apiRes.region);
                    localStorage.setItem('function', JSON.stringify(apiRes.function));
                }
                else {
                    localStorage.setItem('region', approvedState.regions);
                    localStorage.setItem('function', JSON.stringify('Commercial'));
                }
            }
            // References Used for MIAs API call
            this.runMIAsRefApi();
            window.addEventListener('resize', this.updateDimensions);
        }
        else {
            console.log('Access Denied');
            this.props.history.push('/');
        }

        this.setState({ prevUrl: window.location.search })

        // VIEW SELECTED LOGIC
        let data = this.state.selectedResults;
        let citationSelectStatus = this.state.citationSelectStatus;
        let markedUrl = this.markedDocumentsUrl
        var sessiondata = sessionStorage.getItem("selectedResultsDetailsMIAs");
        // for (let index = 0; index < this.state.documentCount; index++) {
        if (localStorage.getItem('selectedResults') === null) {
            // console.log(this.props.location.search,this.state.prevUrl,window.location.search)
            if (window.location.search !== this.state.prevUrl) {
                data = [];
                citationSelectStatus = [];
                markedUrl = [];
            }

        }
        if (localStorage.getItem('selectedResults') !== null && sessiondata !== null) {

            if (this.props.location.search !== this.state.prevUrl) {
                data = [];
                citationSelectStatus = [];
                localStorage.removeItem('selectedResults')
                localStorage.removeItem('ItemsPerPage')
                localStorage.removeItem('ActivePage')
                localStorage.removeItem('markedResultsUrl')
                sessionStorage.clear();
            }
            else {
                localStorage.getItem('selectedResults').split(',').map((x, key) => {
                    let i = parseInt(x)
                    citationSelectStatus[i - 1] = true;
                    if (citationSelectStatus[i - 1]) {
                        data = JSON.parse(sessiondata)
                        markedUrl = JSON.parse(localStorage.getItem('markedResultsUrl'))
                    }
                    else {
                        data.splice(i - 1, 1)
                        markedUrl.splice(i - 1, 1)
                    }
                })
            }
        }
        // }
        // console.log(data,citationSelectStatus)
        this.markedDocumentsUrl = markedUrl
        this.setState({ citationSelectStatus: citationSelectStatus, selectedResults: data })
    }
    errorAlertMessage = (message) => {
        this.setState({
          alertMessageContent: message,
          alertMessageModelColor: "#de1327",
          alertMessageShowModal: true
        })
      }
      closeAlertMessage = () => {
        this.setState({
          alertMessageContent: "",
          alertMessageModelColor: "",
          alertMessageShowModal: false
        })
      }
    componentWillUnmount() {
        let service = new Services();
        window.removeEventListener('resize', this.updateDimensions);
        service.logService(`References Used for MIAs page successfully unmounted for ${this.userid.toLowerCase()}`, 'info')
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    runMIAsRefApi = () => {
        this.setState({ isProcessing: true, isLoaded: false });
        var url = LocationOrigin + process.env.REACT_APP_IMS_API_PREFIX + 'qp/runMIAsReferences';
        const queryParams = new URLSearchParams(this.props.location.search)
        const params = queryParams.get('query')
        let config = {
            method: 'POST',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                "from": this.state.currentPage * this.state.itemsPerPage - this.state.itemsPerPage,
                "query": params,
                "size": parseInt(this.state.itemsPerPage),
                "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                "sortField": this.state.sortField,
                "sortBy": this.state.sortBy
                // "query": "",
                // "filters": "",
                // "function": localStorage.getItem('function').replace(/"/g, ''),
                // "doctypepref": localStorage.getItem('doctypepref'),
                // "userproducts": `${this.state.selectedProduct}`,
                // "region": localStorage.getItem('region'),
                // "username": localStorage.getItem('username').toLowerCase().replace(/"/g, ''),
                // "contentpreference": localStorage.getItem('contentpreference').replace(/"/g, ''),
                // "from": this.state.currentPage * this.state.itemsPerPage - this.state.itemsPerPage,
                // "size": parseInt(this.state.itemsPerPage),
                // "sortBy": this.state.sortBy,
                // "sortField": this.state.sortField,
            }
        }
        axios(config)
            .then((response) => {
                this.setState({
                    isProcessing: false,
                    response: response.data.body.hits.hits,
                    documentCount: response.data.body.hits.total.value,
                    isLoaded: true
                }, () => {

                    let collapsedSearchIDs = response.data.body.hits.hits.map(x => x._id);
                    if (this.state.globalSelectAll && (this.state.citationSelectStatus.length.toString() === this.state.itemsPerPage || this.state.citationSelectStatus.length === this.state.documentCount)) {
                        this.setState({ globalSelectAll: true, globalCollapseAll: true, collapsedSearchIDs: collapsedSearchIDs })
                    }
                    else {
                        this.setState({ globalSelectAll: false, globalCollapseAll: true, collapsedSearchIDs: collapsedSearchIDs })
                    }
                })
                // VIEW SELECTED LOGIC
                let data = this.state.selectedResults;
                let citationSelectStatus = this.state.citationSelectStatus;
                let markedUrl = this.markedDocumentsUrl
                var sessiondata = sessionStorage.getItem("selectedResultsDetailsMIAs");
                // for (let index = 0; index < this.state.documentCount; index++) {
                if (localStorage.getItem('selectedResults') === null) {
                    // console.log(this.props.location.search,this.state.prevUrl,window.location.search)
                    if (window.location.search !== this.state.prevUrl) {
                        data = [];
                        citationSelectStatus = [];
                        markedUrl = [];
                    }
                    /* else {
                        citationSelectStatus.push(false)
                    } */
                }
                if (localStorage.getItem('selectedResults') !== null && sessiondata !== null) {
                    // let totalPages = Math.ceil(this.state.documentCount / this.state.itemsPerPage)
                    // console.log(this.state.itemsPerPage, totalPages,localStorage.getItem('selectedResults'), Math.max.apply(null,localStorage.getItem('selectedResults').split(',')))
                    // else{
                    // if (window.location.search != this.state.prevUrl) {
                    if (decodeURI(this.props.location.search) !== decodeURI(this.state.prevUrl)) {
                        data = [];
                        citationSelectStatus = [];
                        localStorage.removeItem('selectedResults')
                        localStorage.removeItem('ItemsPerPage')
                        localStorage.removeItem('ActivePage')
                        localStorage.removeItem('markedResultsUrl')
                        sessionStorage.clear();
                    }
                    // else {
                    //     localStorage.getItem('selectedResults').split(',').map((x, key) => {
                    //         let i = parseInt(x)
                    //         citationSelectStatus[i - 1] = true;
                    //         if (citationSelectStatus[i - 1]) {
                    //             data = JSON.parse(sessiondata)
                    //             // data.push(JSON.parse(sessiondata))
                    //             // data.push({
                    //             //     detail: this.state.response[i - 1]._source,
                    //             //     itemTeaser: this.state.response[i - 1].highlight.body,
                    //             //     index: i
                    //             // })
                    //         }
                    //         else {
                    //             data.splice(i - 1, 1)
                    //         }
                    //     })
                    // }
                }
                // }
                // console.log(data,citationSelectStatus)
                this.markedDocumentsUrl = markedUrl
                this.setState({ citationSelectStatus: citationSelectStatus, selectedResults: data })
            })
            .catch(function (error) {
                //console.log(error);
            })
            .finally(() => {
                this.setState({ isProcessing: false, isLoaded: true })
            })
        // this.setState({ isLoaded: true })
    }
    handleChangeSelectSortBy = (e) => {
        if (e.target.value === 'Title, Descending') {
            this.setState({ sortBy: "desc", sortField: "docTitle" })
        }
        else {
            this.setState({ sortBy: "asc", sortField: "docTitle" })
        }
        this.setState({ sortByValue: e.target.value, citationSelectStatus: [], selectedResults: [], globalSelectAll: false }, () => {
            this.runMIAsRefApi();
        })
    }
    resultListCount = (itemsPerPage, currentPage) => {
        var endIndex, startIndex;
        startIndex = currentPage * itemsPerPage - itemsPerPage
        endIndex = currentPage * itemsPerPage
        // var count = startIndex;
        // const totalPages = Math.ceil(this.state.documentCount / itemsPerPage);
        // const previousOff = currentPage == 1;
        // const nextOff = currentPage == totalPages;
        if (this.state.width < 600) {
            return (
                <div className="results-count-wrapper" style={{ justifyContent: this.state.response.length > 0 ? "space-between" : "flex-end" }}>
                    {this.state.response.length > 0 &&
                        <Pagination metadata={this.state.documentCount} handlePageChange={this.handlePageChange} itemsPerPage={this.state.itemsPerPage} page={this.state.currentPage} customWidth={this.state.width} customHeight={this.state.height} />
                    }
                </div>
            )
        }
        else {
            return (
                <div className="results-count-wrapper">

                    {/* {
                        this.state.width < 1000 &&
                        <div className="clearSearchContainer" onClick={this.handleClearSearch}>
                            <img src={clearSearchMobView} alt="clearSearch" className="clearSearchIcon" />
                            <span className="clearSearchText">Clear Search</span>
                        </div>
                    } */}
                    {this.state.response.length > 0 &&
                        <div className="resultListCount-container">
                            {this.state.documentCount > 0 &&
                                <span className="showingResultsCountContainer" /* style={{ display: "flex", width: "200px" }} */>
                                    {endIndex <= this.state.documentCount ?
                                        <div className="results-number-show-wrapper">
                                            Showing {startIndex + 1}-{endIndex} of {this.state.documentCount} {this.state.width > 1000 && "Results"}
                                        </div> :
                                        <div className="results-number-show-wrapper">
                                            Showing {startIndex + 1}-{this.state.documentCount} of {this.state.documentCount} {this.state.width > 1000 && "Results"}
                                        </div>
                                    }
                                </span>
                            }
                            <span style={{ marginRight: "0.5%", display: "flex", width: this.state.width <= 630 ? "160px" : "180px" }}>
                                <PageItems handleChangeSelectItems={this.handleChangeSelectItems}
                                    itemsPerPage={this.state.itemsPerPage} customWidth={this.state.width} />
                            </span>
                            {this.state.width >= 1000 ?
                                <div className="sort-by-wrapper">
                                    <span className="sort-by-text">Sort By:</span>
                                    <select className="sort-by-select-wrapper" onChange={this.handleChangeSelectSortBy} defaultValue={this.state.sortByValue}>
                                        <option>Title, Ascending</option>
                                        <option>Title, Descending</option>
                                    </select>
                                </div>
                                :
                                this.state.width >= 600 &&
                                <div className="sort-by-wrapper">
                                    <span className="sort-by-text">Sort By:</span>
                                    <select className="sort-by-select-wrapper" onChange={this.handleChangeSelectSortBy} defaultValue={this.state.sortByValue}>
                                        <option>Title, Ascending</option>
                                        <option>Title, Descending</option>
                                    </select>
                                </div>
                            }
                        </div>
                    }
                </div>
            )
        }
    }
    getSelectedCitationDetails = () => {
        //console.log(this.state.citationSelectStatus)
        //console.log(this.state.selectedResults.sort(function (a, b) { return a.index - b.index }))
        if (this.state.selectedResults.length > 0) {
            let selectedResultsDetailsMIAs = this.state.selectedResults;
            sessionStorage.setItem("selectedResultsDetailsMIAs", JSON.stringify(selectedResultsDetailsMIAs));
            let selectedResultsIndex = this.state.selectedResults.map(x => x.index)
            localStorage.setItem('selectedResults', selectedResultsIndex)
            localStorage.setItem('markedResultsUrl', JSON.stringify(this.markedDocumentsUrl))
            localStorage.setItem('ItemsPerPage', this.state.itemsPerPage)
            localStorage.setItem('ActivePage', this.state.currentPage)
            this.props.history.push({ pathname: "/ViewSelectedReferencesUsed", state: { selectedResults: this.state.selectedResults } });
        }
        else {
            this.errorAlertMessage("There are no documents selected")
        }
    }
    handleCitationSelection = (index, item, itemTeaser, key) => {
        let a = this.state.citationSelectStatus;
        a[index - 1] = !this.state.citationSelectStatus[index - 1];
        let data = this.state.selectedResults;
        let urls = this.markedDocumentsUrl;
        if (this.state.citationSelectStatus[index - 1]) {
            data.push({
                detail: item,
                itemTeaser: itemTeaser,
                index: index,
            })
            urls.push(this.documentsUrl[key])
        }
        else {
            const i = data.map(x => (x.index)).indexOf(index);
            if (i > -1) {
                data.splice(i, 1); // 2nd parameter means remove one item only
                urls.splice(i, 1);
            }
        }
        this.markedDocumentsUrl = urls;
        this.setState({
            citationSelectStatus: a,
            selectedResults: data,
        })
        let checkSelectStatus = a.filter(item => item)
        if (checkSelectStatus.length === parseInt(this.state.itemsPerPage)) {
            this.setState({ globalSelectAll: true })
        }
        else if (checkSelectStatus.length === this.state.response.length) {
            this.setState({ globalSelectAll: true })
        }
        else {
            this.setState({ globalSelectAll: false })
        }
    }
    handleCitationSelectionGlobal = (index, item, itemTeaser, key) => {
        let a = this.state.citationSelectStatus;
        //console.log(a, index)
        a[index - 1] = true;
        let data = this.state.selectedResults;
        let urls = this.markedDocumentsUrl;
        if (this.state.citationSelectStatus[index - 1]) {
            //console.log("selectAll-1")
            data.push({
                detail: item,
                itemTeaser: itemTeaser,
                index: index,
            })
            urls.push(this.documentsUrl[key - 1])
        }
        else {
            //console.log("selectAll-2")
            const i = data.map(x => (x.index)).indexOf(index);
            if (i > -1) {
                data.splice(i, 1); // 2nd parameter means remove one item only
                urls.splice(i, 1);
            }
        }
        // console.log(a,data);
        this.markedDocumentsUrl = urls;
        this.setState({
            citationSelectStatus: a,
            selectedResults: data,
        })
    }
    handleCitationUnSelection = (index, item, itemTeaser) => {
        let a = this.state.citationSelectStatus;
        a[index - 1] = false;
        let data = this.state.selectedResults;
        let urls = this.markedDocumentsUrl;
        /* if (this.state.citationSelectStatus[index - 1]) {
            data.push({
                detail: item,
                itemTeaser: itemTeaser,
                index: index,
            })
        }
        else { */
        const i = data.map(x => (x.index)).indexOf(index);
        if (i > -1) {
            data.splice(i, 1); // 2nd parameter means remove one item only
            urls.splice(i, 1);
        }
        /* } */
        // console.log(a,data)
        // let selectedResultsDetailsMIAs = data;
        // sessionStorage.setItem("selectedResultsDetailsMIAs", JSON.stringify(selectedResultsDetailsMIAs));
        // let selectedResultsIndex = data.map(x => x.index)
        // localStorage.setItem('selectedResults', selectedResultsIndex)
        this.markedDocumentsUrl = urls;
        this.setState({
            citationSelectStatus: a,
            selectedResults: data,
        })
    }
    citationUrl = (url, index, item, displayedResultsUrl) => {
        if (item.domain === "CTGov") {
            displayedResultsUrl[index] = url;
        }
        else {
            displayedResultsUrl[index] = window.location.origin + url;
            // displayedResultsUrl[index] = "https://predev-ims.jnj.com" + url;
        }
        this.documentsUrl = displayedResultsUrl;
    }
    manageCollapseAndExpand = (iscollapsed, searchID) => {
        let { collapsedSearchIDs, response } = this.state;
        let responseSearchIDs = response.map(x => x._id);

        if (iscollapsed) {
            collapsedSearchIDs.push(searchID);
        } else {
            collapsedSearchIDs = collapsedSearchIDs.filter(x => x !== searchID);
        }
        let tempGlobalCollapseAll = responseSearchIDs.every(x => collapsedSearchIDs.includes(x));
        // let tempGlobalCollapseAll = responseSearchIDs.length === collapsedSearchID.length;


        this.setState({ collapsedSearchIDs: collapsedSearchIDs, globalCollapseAll: tempGlobalCollapseAll });
    }
    resultList = (results, itemsPerPage, currentPage) => {
        let { collapsedSearchIDs } = this.state;
        // window.scrollTo({ behavior: 'smooth', top: '0px' });
        var startIndex;
        startIndex = currentPage * itemsPerPage - itemsPerPage
        // endIndex = currentPage * itemsPerPage
        var count = startIndex;
        let displayedResults = []
        let displayedResultsUrl = []
        if (results.length > 0) {
            return (
                <>
                    {results.map((item, index) => {
                        count++;
                        //console.log(item._source)
                        if (item.highlight === undefined || item.highlight?.body[0] === "Empty File" || item.highlight?.body[0] === "Not an empty file") {
                            displayedResults.push({
                                detail: item._source,
                                itemTeaser: [],
                                index: index + 1,
                            })
                            // item.highlight = { 'body': ['Test body until indexed value is available'] }
                        }
                        else {
                            displayedResults.push({
                                detail: item._source,
                                itemTeaser: item.highlight.body,
                                index: index + 1,
                            })
                        }
                        let responseSearchIDs = item._id;
                        let globalCollapseAll = collapsedSearchIDs.some(x => x === responseSearchIDs);
                        return (
                            <>
                                <div key={`result-id-${index}`} id={`result-id-${index}`}>
                                    {
                                        <div className="resultDetailsDiv">
                                            <MIAsReferencesDocumentCard item={item._source} count={count} appliedFilters={this.state.appliedFilters} searchKeyword={this.state.searchKeyword} pageNumber={this.state.currentPage} pageSize={this.state.itemsPerPage} itemTeaser={(item.highlight === undefined || item.highlight?.body[0] === "Empty File" || item.highlight?.body[0] === "Not an empty file") ? [] : item.highlight.body} handleCitationSelection={(i, item, itemTeaser) => this.handleCitationSelection(i, item, itemTeaser, index)} isChecked={this.state.citationSelectStatus[count - 1]} customWidth={this.state.width}
                                                //globalCollapseAll={this.state.globalCollapseAll} 
                                                globalCollapseAll={globalCollapseAll}
                                                csPath={this.state.csPath} csproduct={this.state.csproduct} handleUnpin={this.handleUnpin} citationUrl={(url) => this.citationUrl(url, index, item._source, displayedResultsUrl)}
                                                manageCollapseAndExpand={this.manageCollapseAndExpand}
                                                searchID={responseSearchIDs} />
                                            <div className="resultsDivFooter">
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        )
                    })
                    }
                    {localStorage.setItem('displayedResults', JSON.stringify(displayedResults))}
                </>
            )

        }
        else {
            localStorage.removeItem("displayedResults");
            return (
                <div className="results-no-search-content">
                    <span>Nothing here matches your search</span><br /><br />
                    <span>Suggestions</span>
                    <li>Make sure all words are spelled correctly</li>
                    <li>Try different search terms</li>
                    <li>Try more general search terms</li>
                    <li>Try fewer search terms</li>
                </div>

            )
        }
        //this.setState({displayedResults:displayedResults})

    }
    handlePageChange = (currentPage) => {
        window.scrollTo({ behavior: 'smooth', top: '0px' });
        let checkSelectStatus = this.state.citationSelectStatus.filter(item => item)
        if (
            this.state.globalSelectAll &&
            (checkSelectStatus.length.toString() ===
                this.state.itemsPerPage ||
                this.state.citationSelectStatus.length === this.state.documentCount)
        ) {
            this.setState({ citationSelectStatus: [], selectedResults: [] });
        }
        this.setState({
            currentPage: currentPage,
            response: [],
            isProcessing: true,
            isLoaded: false,
            globalCollapseAll: true,
            globalSelectAll: false
        }, () => {
            let setSelectStatus = false
            let startIndex = this.state.currentPage * this.state.itemsPerPage - this.state.itemsPerPage
            let endIndex = this.state.currentPage * this.state.itemsPerPage
            let citationStatusForCurrentPage = this.state.citationSelectStatus.slice(startIndex, endIndex)
            //console.log(startIndex,endIndex,citationStatusForCurrentPage)
            setSelectStatus = citationStatusForCurrentPage.some(e => e === true)
            //console.log(setSelectStatus)
            if (setSelectStatus) {
                this.setState({
                    globalSelectAll: true
                })
            }
            else {
                this.setState({
                    globalSelectAll: false
                })
            }
            this.runMIAsRefApi();
        })
    }
    generateSelectParentArray = (appliedfilters) => {
        let parentFacet = [];
        appliedfilters.forEach(filter => {
            const filterParts = filter.split('/')
            const filterTop = filterParts[0]
            parentFacet.push(filterTop)
        });
        return parentFacet;
    }
    handleChangeSelectItems = (items) => {
        //console.log(this.state.citationSelectStatus);
        let checkSelectStatus = this.state.citationSelectStatus.filter((item) => item);
        if (this.state.globalSelectAll && (checkSelectStatus.length.toString() === this.state.itemsPerPage || this.state.citationSelectStatus.length === this.state.documentCount)) {
            this.setState({ citationSelectStatus: [], selectedResults: [] })
        }
        this.setState({
            itemsPerPage: items,
            // globalCollapseAll: false,
            globalSelectAll: false
        }, () => {
            let totalPages = Math.ceil(this.state.documentCount / this.state.itemsPerPage)
            if (totalPages < this.state.currentPage) {
                this.setState({
                    currentPage: totalPages
                }, () => {
                    let setSelectStatus = false
                    let startIndex = this.state.currentPage * this.state.itemsPerPage - this.state.itemsPerPage
                    let endIndex = this.state.currentPage * this.state.itemsPerPage
                    let citationStatusForCurrentPage = this.state.citationSelectStatus.slice(startIndex, endIndex)
                    //console.log(startIndex,endIndex,citationStatusForCurrentPage)
                    setSelectStatus = citationStatusForCurrentPage.some(e => e === true)
                    //console.log(setSelectStatus)
                    if (setSelectStatus) {
                        this.setState({
                            globalSelectAll: true
                        })
                    }
                    else {
                        this.setState({
                            globalSelectAll: false
                        })
                    }
                    this.runMIAsRefApi();
                })
            }
            else {
                this.runMIAsRefApi();
            }
        })
    }
    buildFieldedUrl = (searchKeyword, filters) => {
        let filterStr = this.generateArrayString(filters)
        let queryParams = new URLSearchParams(this.props.location.search)
        let params = queryParams.get('query')
        let q = queryParams.get('q')
        let fcount = queryParams.get('fcount')
        let andFields = queryParams.get('andFields')
        let orFields = queryParams.get('orFields')
        let docTitle = queryParams.get('docTitle')
        let authors = queryParams.get('authors')
        let majorTopicsSynonyms = queryParams.get('majorTopicsSynonyms')
        let fromYear = queryParams.get('fromYear')
        let toYear = queryParams.get('toYear')
        let url = `?query=${params}&q=${q}&filters=${filterStr}`;
        if (fcount > 0) {
            for (let i = 1; i <= fcount; i++) {
                let key = queryParams.get(`f${i}`)
                let value = queryParams.get(`fval${i}`)
                url += `&f${i}=${key}&fval${i}=${value}`
            }
            url += `&fcount=${fcount}`
        }
        if (andFields !== '' && andFields !== null) {
            url += `&andFields=${andFields}`
        }
        if (orFields !== '' && orFields !== null) {
            url += `&orFields=${orFields}`
        }
        if (docTitle !== '' && docTitle !== null) {
            url += `&docTitle=${docTitle}`
        }
        if (authors !== '' && authors !== null) {
            url += `&authors=${authors}`
        }
        if (majorTopicsSynonyms !== '' && majorTopicsSynonyms !== null) {
            url += `&majorTopicsSynonyms=${majorTopicsSynonyms}`
        }
        if (fromYear !== '' && fromYear !== null) {
            url += `&fromYear=${fromYear}`
        }
        if (toYear !== '' && toYear !== null) {
            url += `&toYear=${toYear}`
        }
        url += `&FS=true`
        return url;
    }
    buildUrl = (searchKeyword, dropdownValue, fieldedVal, filters, isClearSearch) => {
        let filterStr = this.generateArrayString(filters)
        let params = new URLSearchParams(this.props.location.search)
        let sbQuery = params.get('sbQuery');
        let isDateRange = params.get('isDateRange');
        let fieldedSearch = params.get('FS');
        let csPath = params.get('cspath');
        let csproduct = params.get('csproduct');
        let url;
        if (fieldedSearch) {
            url = this.buildFieldedUrl(searchKeyword, filters)
        }
        else {
            if (fieldedVal === '') {
                url = `?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}`
            }
            else if (dropdownValue === 'All Fields' && fieldedVal === '' || fieldedVal === undefined) {
                url = `?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}`
            }
            else {
                url = `?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}&f1=${fieldedVal}&fval1=${searchKeyword}&fcount=1`;
            }
            if (sbQuery !== null && searchKeyword !== '') {
                url = url + `&sbQuery=${sbQuery}`
            }
            if (csPath !== null && csproduct !== null && searchKeyword !== '') {
                url = url + `&cspath=${csPath}&csproduct=${csproduct}`
            }
            if (isDateRange !== null) {
                let rangeField = params.get('rangeField');
                let toRange = params.get('toRange');
                let fromRange = params.get('fromRange');
                url = url + `&isDateRange=true&rangeField=${rangeField}`
                if (toRange !== null) {
                    url += `&toRange=${toRange}`
                }
                if (fromRange !== null) {
                    url += `&fromRange=${fromRange}`
                }
            }
        }
        if (isClearSearch === true) {
            url = `?query=&q=All Fields&filters=`
        }
        return url;
    }
    generateArrayString = (array) => {
        let arrStr = ''
        array.map((item, index) => {
            if (index === array.length - 1) {
                arrStr += item
            }
            else {
                arrStr += item + ";"
            }
            return null
        })
        return arrStr
    }
    handelResults = () => {
        this.setState({ showResultType: !this.state.showResultType })
    }
    handleChangeInDropdown = (event) => {
        event.preventDefault();
        this.setState({
            resultDropdownValue: event.target.attributes.keyvalue.value,
            showResultType: !this.state.showResultType
        });
    }
    handelSearchResultClose = () => {
        this.setState({ showResultType: false })
    }
    conversionOfFieldType = (item) => {
        if (item === 'docType')
            return 'Document Type';
        else if (item === "protocolIDs" || item === "harmonizedProtocolIDs")
            return 'Protocol Number';
        else if (item === 'genericName')
            return 'Generic Name';
        else if (item === 'congressName')
            return 'Congress Name'
        else
            return 'Journal Name'
    }
    showFieldedSearch = () => {
        let queryParams = new URLSearchParams(this.props.location.search);
        let limistArr = [];
        let fieldedSearch = false;
        let fcount = queryParams.get('fcount')
        let docTitle = queryParams.get('docTitle')
        let authors = queryParams.get('authors')
        let majorTopicsSynonyms = queryParams.get('majorTopicsSynonyms')
        let fromRange = queryParams.get('fromYear');
        fieldedSearch = queryParams.get('FS');
        for (let i = 0; i < fcount; i++) {
            let key = queryParams.get(`f${i + 1}`)
            let value = queryParams.get(`fval${i + 1}`)
            limistArr[i] = { 'key': key, 'value': value }
        }
        if (fieldedSearch && (docTitle !== null && docTitle !== ' ' || authors !== ' ' && authors !== null || majorTopicsSynonyms !== ' ' && majorTopicsSynonyms !== null || fromRange !== null || fcount > 0)) {
            return (
                <div className="filter-selection-display-wrapper">
                    <div className="applied-filter-header-section">
                        <span className="applied-filter-header-text">Fielded Search</span>
                    </div>
                    <div className="filter-display-item-wrapper">
                        <div className="facet-item-wrapper">
                            <span className="facet-inner-wrapper">
                                {docTitle !== ' ' && docTitle !== null &&
                                    <>
                                        <span className="fs-parent-field-wrapper">Title</span>
                                        <span className="subfilter-wrapper">
                                            <span className="subfilter">
                                                <span className="subfilter-text">{docTitle}</span>
                                            </span>
                                        </span>
                                    </>
                                }
                                {authors !== ' ' && authors !== null &&
                                    <>
                                        <span className="fs-parent-field-wrapper">Authors</span>
                                        <span className="subfilter-wrapper">
                                            <span className="subfilter">
                                                <span className="subfilter-text">{authors}</span>
                                            </span>
                                        </span>
                                    </>
                                }
                                {majorTopicsSynonyms !== ' ' && majorTopicsSynonyms !== null &&
                                    <>
                                        <span className="fs-parent-field-wrapper">Major Topics</span>
                                        <span className="subfilter-wrapper">
                                            <span className="subfilter">
                                                <span className="subfilter-text">{majorTopicsSynonyms}</span>
                                            </span>
                                        </span>
                                    </>
                                }
                                {fromRange !== null &&
                                    <>
                                        <span className="fs-parent-field-wrapper">Publication Year</span>
                                        <span className="subfilter-wrapper">
                                            <span className="subfilter">
                                                <span className="subfilter-text">{fromRange}</span>
                                            </span>
                                        </span>
                                    </>
                                }
                                {fcount > 0 &&
                                    <>
                                        {limistArr.map((item, index) => {
                                            return (
                                                <>
                                                    <span className="fs-parent-field-wrapper">{this.conversionOfFieldType(item.key)}</span>
                                                    <span className="subfilter-wrapper">
                                                        <span className="subfilter">
                                                            <span className="subfilter-text">{item.value}</span>
                                                        </span>
                                                    </span>
                                                </>
                                            )
                                        })
                                        }
                                    </>
                                }
                            </span>
                        </div>
                    </div>
                </div>
            )
        }
        return null;
    }
    handleCollapseAllGlobal = () => {
        let { response, collapsedSearchIDs } = this.state;
        if (!this.state.globalCollapseAll) {
            collapsedSearchIDs = response.map(x => x._id);
        } else {
            collapsedSearchIDs = [];
        }
        this.setState({
            globalCollapseAll: !this.state.globalCollapseAll,
            collapsedSearchIDs: collapsedSearchIDs
        });
    }
    handleSelectAll = () => {
        //console.log('Change reflecting till result');
        this.setState({
            globalSelectAll: !this.state.globalSelectAll
        }, () => {
            let startIndex = this.state.currentPage * this.state.itemsPerPage - this.state.itemsPerPage + 1
            var count = startIndex;
            let endIndex = this.state.itemsPerPage
            if (this.state.globalSelectAll) {
                this.markedDocumentsUrl = [];
                this.setState({ selectedResults: [] }, () => {
                    for (let i = 1; i <= endIndex; i++) {
                        //console.log(i,this.state.response[i-1])
                        if (this.state.response[i - 1] !== undefined) {
                            this.handleCitationSelectionGlobal(count, this.state.response[i - 1]._source, (this.state.response[i - 1].highlight === undefined || this.state.response[i - 1].highlight?.body[0] === "Empty File" || this.state.response[i - 1].highlight?.body[0] === "Not an empty file") ? [] : this.state.response[i - 1].highlight.body, i)
                            count++;
                        }
                    }
                })
            }
            else {
                for (let i = 1; i <= endIndex; i++) {
                    if (this.state.response[i - 1] !== undefined) {
                        // console.log(count)
                        this.handleCitationUnSelection(count, this.state.response[i - 1]._source, (this.state.response[i - 1].highlight === undefined || this.state.response[i - 1].highlight?.body[0] === "Empty File" || this.state.response[i - 1].highlight?.body[0] === "Not an empty file") ? [] : this.state.response[i - 1].highlight.body)
                        count++;
                    }
                }
            }
        })
    }
    handleGlobalEmailPopUp = (status) => {
        this.setState({ globalEmailPopUp: status })
    }
    handleEmailPopUpClose = () => {
        this.setState({ globalEmailPopUp: false })
    }
    handleOnClickReadingList = (status) => {
        this.setState({ readingListPopup: status })
    }
    handleCloseReadingList = () => {
        this.setState({ readingListPopup: false })
    }
    handleSuccessClose = (status) => {
        this.setState({
            showSuccess: status
        })
    }
    handleGlobalBookmark = (status) => {
        this.setState({ bookmarkPopUp: status })
    }
    handleCloseBookmark = () => {
        this.setState({ bookmarkPopUp: false })
    }
    handleGlobalNotifyMePopUp = (status) => {
        this.setState({ globalNotifyMePopUp: status })
    }
    handleNotifyMePopUpClose = () => {
        this.setState({ globalNotifyMePopUp: false })
    }
    render() {
        const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
        // let params = new URLSearchParams(this.props.location.search)
        // let query = params.get('query')
        // /* const filters = queryParams.get('filters') 
        // let params = queryString.parse(this.props.location.search);*/
        // // var category;
        // let rlName = params.get('rName');
        // var filters = params.get('filters');
        // if (params.get('q') === undefined) {
        //     category = 'All Fields'
        // }
        // else {
        //     category = params.get('q')
        // }
        // if (filters === '' || filters === undefined || filters === null) {
        //     filters = ''
        // }
        if (this.state.width < 600) {
            return (
                <div>
                    <ErrorBoundary>
                        {this.state.userExists ? <>
                            <div className="resultsInnerWrapper">
                                <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                                <div className="header-wrapper">
                                    <MIAsReferencesHeader customWidth={this.state.width} customHeight={this.state.height} handelSelectedCitation={this.getSelectedCitationDetails} isCitationSelected={this.state.citationSelectStatus} handleCollapseAllGlobal={this.handleCollapseAllGlobal} globalCollapseAll={this.state.globalCollapseAll} globalSelectAll={this.state.globalSelectAll} handleSelectAll={this.handleSelectAll} csPath={this.state.csPath} csproduct={this.state.csproduct} handleGlobalEmailPopUp={this.handleGlobalEmailPopUp} handleOnClickReadingList={this.handleOnClickReadingList} handleGlobalBookmark={this.handleGlobalBookmark} handleGlobalNotifyMePopUp={this.handleGlobalNotifyMePopUp} />
                                </div>
                                <div className="results-contents-wrapper">
                                    <div className="imsResultsMenuWrapper-mobile">
                                        <div className="imsResultsMenuHeader-mobile" >
                                            <span>
                                                References Used
                                            </span>
                                        </div>
                                        <div className="resultsContentWrapper resultsContentWrapper-mobile">
                                            {this.state.isLoaded ? <>
                                                {/* {this.state.response.length > 0 &&
                                                    <> */}
                                                {this.resultListCount(this.state.itemsPerPage, this.state.currentPage)}
                                                <div className="results-wrapper">
                                                    {this.resultList(this.state.response, this.state.itemsPerPage, this.state.currentPage)}
                                                </div>
                                                {/* <Pagination metadata={this.state.documentCount} handlePageChange={this.handlePageChange} itemsPerPage={this.state.itemsPerPage} page={this.state.currentPage} customWidth={this.state.width} customHeight={this.state.height}/> */}
                                            </>
                                                : <div style={{ textAlign: "center", padding: "10%" }}>
                                                    <img src={imsLoader} alt="Loading Data" style={{ width: "10%" }} />
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ margin: "0% 1%", marginTop: "auto" }}>
                                <Footer customWidth={this.state.width} customHeight={this.state.height} />
                            </div>
                            {this.state.globalEmailPopUp &&
                                <MIAsRefGlobalSearchFeaturePopUpModal showModal={this.state.globalEmailPopUp} showEmailModal={true} handleClose={this.handleEmailPopUpClose} selectedResults={this.state.selectedResults} customWidth={this.state.width} customHeight={this.state.height} />
                            }
                            {
                                this.state.bookmarkPopUp &&
                                <BookmarkPopUp showModal={this.state.bookmarkPopUp} handleClose={this.handleCloseBookmark} customWidth={this.state.width} />
                            }
                            {
                                this.state.globalNotifyMePopUp &&
                                <NotifyMePopUp showPersonalAlerts={this.state.globalNotifyMePopUp} handleClose={this.handleNotifyMePopUpClose} customWidth={this.state.width} />
                            }
                            {this.state.readingListPopup &&
                                <ReadingListPopup showModal={this.state.readingListPopup} handleClose={this.handleCloseReadingList} width={this.state.width} selectedResults={this.state.selectedResults} />
                            }
                            {this.state.showSuccess &&
                                <>
                                    <SuccessReadingList display={this.state.showSuccess} handleClose={(status) => this.handleSuccessClose(status)} data={this.state.successMessage} width={this.state.width} />
                                    <div className="savemodal-outer-wrapper"></div>
                                </>
                            }
                        </>
                            :
                            <div style={{ textAlign: "center", padding: "10%" }}>
                                <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
                            </div>
                        }
                    </ErrorBoundary>
                    {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={()=>this.closeAlertMessage()} />}
                </div>
            )
        }
        else if (this.state.width >= 600 && this.state.width <= 1000) {
            return (
                <div>
                    <ErrorBoundary>
                        {this.state.userExists ? <>
                            <div className="resultsInnerWrapper">
                                <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                                <div className="header-wrapper">
                                    <MIAsReferencesHeader customWidth={this.state.width} customHeight={this.state.height} handelSelectedCitation={this.getSelectedCitationDetails} isCitationSelected={this.state.citationSelectStatus} handleCollapseAllGlobal={this.handleCollapseAllGlobal} globalCollapseAll={this.state.globalCollapseAll} globalSelectAll={this.state.globalSelectAll} handleSelectAll={this.handleSelectAll} csPath={this.state.csPath} csproduct={this.state.csproduct} handleGlobalEmailPopUp={this.handleGlobalEmailPopUp} handleOnClickReadingList={this.handleOnClickReadingList} handleGlobalBookmark={this.handleGlobalBookmark} handleGlobalNotifyMePopUp={this.handleGlobalNotifyMePopUp} />
                                </div>
                                <div className="results-contents-wrapper">
                                    <div className="imsResultsMenuWrapper-tablet">
                                        <div className="imsResultsMenuHeader-tablet">
                                            <span className="imsResultsMenuHeader-tablet-active" style={{ width: "100%" }}>
                                                References Used
                                            </span>
                                        </div>
                                        <div className="resultsContentWrapper resultsContentWrapper-tablet">
                                            {this.state.isLoaded ? <>
                                                {/* {this.state.response.length > 0 &&
                                                    <> */}
                                                {this.resultListCount(this.state.itemsPerPage, this.state.currentPage)}
                                                {/* </>
                                                } */}
                                                <div className="results-wrapper">
                                                    {this.resultList(this.state.response, this.state.itemsPerPage, this.state.currentPage)}
                                                </div>
                                                <Pagination metadata={this.state.documentCount} handlePageChange={this.handlePageChange} itemsPerPage={this.state.itemsPerPage} page={this.state.currentPage} customWidth={this.state.width} customHeight={this.state.height} />
                                            </>
                                                : <div style={{ textAlign: "center", padding: "10%" }}>
                                                    <img src={imsLoader} alt="Loading Data" style={{ width: "10%" }} />
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ margin: "0% 1%", marginTop: "auto" }}>
                                <Footer customWidth={this.state.width} customHeight={this.state.height} />
                            </div>
                            {this.state.globalEmailPopUp &&
                                <MIAsRefGlobalSearchFeaturePopUpModal showModal={this.state.globalEmailPopUp} showEmailModal={true} handleClose={this.handleEmailPopUpClose} selectedResults={this.state.selectedResults} customWidth={this.state.width} customHeight={this.state.height} />
                            }
                            {
                                this.state.bookmarkPopUp &&
                                <BookmarkPopUp showModal={this.state.bookmarkPopUp} handleClose={this.handleCloseBookmark} customWidth={this.state.width} />
                            }
                            {
                                this.state.globalNotifyMePopUp &&
                                <NotifyMePopUp showPersonalAlerts={this.state.globalNotifyMePopUp} handleClose={this.handleNotifyMePopUpClose} customWidth={this.state.width} />
                            }
                            {this.state.readingListPopup &&
                                <ReadingListPopup showModal={this.state.readingListPopup} handleClose={this.handleCloseReadingList} width={this.state.width} selectedResults={this.state.selectedResults} />
                            }
                            {this.state.showSuccess &&
                                <>
                                    <SuccessReadingList display={this.state.showSuccess} handleClose={(status) => this.handleSuccessClose(status)} data={this.state.successMessage} width={this.state.width} />
                                    <div className="savemodal-outer-wrapper"></div>
                                </>
                            }
                        </>
                            :
                            <div style={{ textAlign: "center", padding: "10%" }}>
                                <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
                            </div>
                        }
                    </ErrorBoundary>
                    {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={()=>this.closeAlertMessage()} />}
                </div>
            )
        }
        else if (this.state.width > 1000) {
            return (
                <div>
                    <ErrorBoundary>
                        {this.state.userExists ? <>
                            <div className="resultsInnerWrapper">
                                <NavigationMenu pathName={window.location.pathname} customWidth={this.state.width} customHeight={this.state.height} />
                                <div className="header-wrapper">
                                    <MIAsReferencesHeader customWidth={this.state.width} customHeight={this.state.height} handelSelectedCitation={this.getSelectedCitationDetails} isCitationSelected={this.state.selectedResults} handleCollapseAllGlobal={this.handleCollapseAllGlobal} globalCollapseAll={this.state.globalCollapseAll} globalSelectAll={this.state.globalSelectAll} handleSelectAll={this.handleSelectAll} selectedResults={this.state.selectedResults} csPath={this.state.csPath} csproduct={this.state.csproduct} documentsUrl={this.documentsUrl} markedDocumentsUrl={this.markedDocumentsUrl} itemsPerPage={this.state.itemsPerPage} />
                                    {/* <HeaderSearch history={this.props.history} dropdownValue={category} searchKeyword={query} appliedFilters={this.state.appliedFilters} handleChangeInSearch={this.handleChangeInSearch} handleClearSearch={this.handleClearSearch} customWidth={this.state.width} customHeight={this.state.height} handelSelectedCitation={this.getSelectedCitationDetails} isCitationSelected={this.state.selectedResults} handleCollapseAllGlobal={this.handleCollapseAllGlobal} globalCollapseAll={this.state.globalCollapseAll} globalSelectAll={this.state.globalSelectAll} handleSelectAll={this.handleSelectAll} selectedResults={this.state.selectedResults} csPath={this.state.csPath} csproduct={this.state.csproduct} documentsUrl={this.documentsUrl} markedDocumentsUrl={this.markedDocumentsUrl} /> */}
                                </div>
                                <div className="results-MIAs-contents-wrapper">
                                    <div className="imsResultsMenuWrapper" style={{ width: "auto", borderBottom: "none" }}>
                                        <div className="imsResultsMenuHeader" style={{ width: "99%", background: "#2D86B5", fontSize: "22px", height: "auto" }}>
                                            <span>
                                                References Used
                                            </span>
                                        </div>
                                        <div className="resultsContentWrapper">
                                            {/* {this.showFieldedSearch()} */}
                                            {/* {this.state.appliedFilters.length > 0 ?
                                            <FilterDisplay
                                                allFilters={this.state.allFilters}
                                                appliedFilters={this.state.appliedFilters}
                                                handleClearFilters={this.handleClearFilters}
                                                handleRemoveAppliedFilter={this.handleRemoveAppliedFilter}
                                                customWidth={this.state.width} />
                                            : null} */}
                                            {this.state.isLoaded ? <>
                                                {this.state.response.length > 0 &&
                                                    <>
                                                        {this.resultListCount(this.state.itemsPerPage, this.state.currentPage)}
                                                    </>
                                                }
                                                <div className="results-wrapper">
                                                    {this.resultList(this.state.response, this.state.itemsPerPage, this.state.currentPage)}
                                                </div>
                                                <Pagination metadata={this.state.documentCount} handlePageChange={this.handlePageChange} itemsPerPage={this.state.itemsPerPage} page={this.state.currentPage} customWidth={this.state.width} customHeight={this.state.height} />
                                            </>
                                                : <div style={{ textAlign: "center", padding: "10%" }}>
                                                    <img src={imsLoader} alt="Loading Data" style={{ width: "10%" }} />
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ margin: "0% 1%", marginTop: "auto" }}>
                                <Footer customWidth={this.state.width} customHeight={this.state.height} />
                            </div>
                        </>
                            :
                            <div style={{ textAlign: "center", padding: "10%" }}>
                                <img src={imsLoader} alt="Loading Data" style={{ width: "20%" }} />
                            </div>
                        }
                    </ErrorBoundary>
                    {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={()=>this.closeAlertMessage()} />}
                </div>
            )
        }
    }
}
export default ReferencesUsedMIAs;