import BarDiagram from './../../components/Histogram/BarDiagram';
import PropTypes from 'prop-types'
import React from 'react'
import RangeSlider from './../../components/Histogram/RangeSlider'
import "./FilterHistogram.css"


/**
 * Creates a list of all years available to display on the histogram based on the slider range
 *
 * @param {Number} lowerBound 
 * @param {Number} upperBound 
 */
const createRangeList = (lowerBound, upperBound) => {
  let result = []
  for (var i = lowerBound; i <= upperBound; i += 1) {
    result.push(i)
  }

  return result
}

export default function FilterHistogram(props) {
  const {
    handleYearSelection,
    gotoPage,
    fullDocRange,
    years,
    yearSelection,
  } = props

  if (years && years.length > 0) {
    const rangeMax = fullDocRange.maxYear
    let rangeMin = Math.min(yearSelection.min, years[0].key)

    if (rangeMax === rangeMin) {
      rangeMin -= 1
    }
    const sliderRange = createRangeList(rangeMin, rangeMax)

    /**
      Determine the width of a bar. Used to create offsets for the rangeslider so the location of each selection dot
      is in the middle of the bar
    **/
    const barWidth = 100 / sliderRange.length
    return (
      <>
        <div className="histogram-description">
          Results by Year
        </div>
        <div className="bar-diagram">
          <BarDiagram
            barWidth={barWidth}
            sliderRange={sliderRange}
            years={years}
            yearSelection={yearSelection}
          />
        </div>
        <div className="slider-content">
          <RangeSlider
            handleYearSelection={handleYearSelection}
            offSet={barWidth / 2}
            rangeMax={rangeMax}
            rangeMin={rangeMin}
            gotoPage={gotoPage}
            years={years}
            yearSelection={yearSelection}
          />
        </div>
      </>
    )
  }
  else {
    return null
  }
}
FilterHistogram.propTypes = {
  handleYearSelection: PropTypes.func.isRequired,
  gotoPage: PropTypes.func.isRequired,
  years: PropTypes.arrayOf(
    PropTypes.object
  ).isRequired,
  yearSelection: PropTypes.object.isRequired
}
