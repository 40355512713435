/* eslint-disable array-callback-return */
import { Component } from "react";
import { Link } from "react-router-dom";
import { rightSideArrow, popCancelBttn, iIcon } from "../../assets";
import ApiServices from "../../utils/apiServices";
import config from "../../config/config";
import trackingService from "../../services/trackingService";
import "./ContentShortcuts.css";

const convertToHtml = (data) => {
  return <div dangerouslySetInnerHTML={{ __html: data }}></div>;
};
class AdministrationAndDosagesTile extends Component {
  constructor(props) {
    super(props);
    this.service = new ApiServices();
    this.state = {
      showCSModal: false,
      products: [],
      dosageType: [],
      selectedDefault: "",
      selectedProduct: "",
      selectedDosage: "",
      selectedDosageType: [],
      showInfo: false,
      showDocsType: false,
      arrayOfDocTypes: [],
      startIndex: 0,
    };
  }
  eliminateDuplicates = (arr) => {
    var i,
      len = arr.length,
      out = [],
      obj = {};

    for (i = 0; i < len; i++) {
      obj[arr[i]] = 0;
    }
    for (i in obj) {
      out.push(i);
    }
    return out;
  };
  componentWillReceiveProps() {
    if (this.props.products?.length !== this.state.products.length) {
      if (
        this.props.products?.length > 0 &&
        this.props.selectedProduct.length > 0
      ) {
        this.setState(
          {
            products: this.props.products,
            selectedProduct: this.props.selectedProduct,
          },
          async () => {
            let dosagesVal = await this.service.getDosagesforProduct(
              this.state.selectedProduct
            );
            if (dosagesVal?.length > 0) {
              if (
                dosagesVal
                  .map((x) => x.dosagetype)
                  .every((val, i, arr) => val === arr[0])
              ) {
                let dosType = dosagesVal.map((x) => x.dosagetype);
                const x = this.eliminateDuplicates(dosType);
                this.setState({ selectedDosageType: x });
              }
              this.setState({ dosageType: dosagesVal });
            }
          }
        );
      }
    }
  }
  componentDidMount() {
    // let email = JSON.parse(localStorage.getItem('username')).toLowerCase();
    // let isProductsAvailable;
    // let res = await this.service.getUserProductAndWwid(email);
    // if (res) {
    //     isProductsAvailable = res[0].products
    // }
    // if (isProductsAvailable !== null && isProductsAvailable?.length > 0) {
    //     this.setState({
    //         products: isProductsAvailable.split(';'),
    //         isLoaded: true
    //     }, () => {
    //         if (this.state.selectedProduct === '') {
    //             this.setState({
    //                 selectedProduct: this.state.products[0]
    //             }, async () => {
    //                 let dosagesVal = await this.service.getDosagesforProduct(this.state.selectedProduct);
    //                 if (dosagesVal?.length > 0) {
    //                     if (dosagesVal.map(x => x.dosagetype).every((val, i, arr) => val === arr[0])) {
    //                         let dosType = dosagesVal.map(x => x.dosagetype)
    //                         const x = this.eliminateDuplicates(dosType);
    //                         this.setState({ selectedDosageType: x })
    //                     }
    //                     this.setState({ dosageType: dosagesVal })
    //                 }
    //             })
    //         }
    //     })
    // }
    // else {
    //     this.setState({
    //         isLoaded: true
    //     })
    // }
    this.setState({
      arrayOfDocTypes: config.routesofAandD,
    });
  }
  componentDidUpdate = () => {
    if (this.props.showAnDCSModal !== this.state.showCSModal) {
      this.setState({ showCSModal: this.props.showAnDCSModal });
    }
  };
  getWidth = (diff) => {
    let calWidth;
    switch (diff) {
      case 1:
        calWidth = 20;
        break;
      case 2:
        calWidth = 30;
        break;
      case 3:
        calWidth = 40;
        break;
      case 4:
        calWidth = 60;
        break;
      case 5:
        calWidth = 90;
        break;
      case 6:
        calWidth = 100;
        break;
      default:
        calWidth = 100;
        break;
    }
    return calWidth;
  };
  selectProduct = (product, startIdx, endIdx) => {
    if (product.length > 0) {
      return (
        <>
          {startIdx > 0 && (
            <div className="wnfy-select-nav-button-wrapper">
              <div
                className="wnfy-result-navigate-arrow"
                onClick={(e) => this.handleOnClickLeft()}
              >
                <img
                  src={rightSideArrow}
                  alt=""
                  style={{ transform: "rotate(180deg)" }}
                />
              </div>
            </div>
          )}
          {/* {diff < 6 &&
                        <div style={{ flex: "auto" }}></div>
                    } */}
          <div
            className="cs-modal-product-wrapper" /* style={{ width: `${calcwidth}%` }} */
          >
            {product.map((item, index) => {
              if (index >= startIdx && index <= endIdx) {
                return (
                  <div
                    className={
                      item === this.state.selectedProduct
                        ? "cs-select-product-wrapper cs-product-selected"
                        : "cs-select-product-wrapper"
                    }
                    onClick={this.handleSelectProduct}
                    key={index}
                    id={`${item}`}
                  >
                    <div id={`${item}`}>{item}</div>
                  </div>
                );
              }
            })}
          </div>
          {/* {diff < 6 &&
                        <div style={{ flex: "auto" }}></div>
                    } */}
          {startIdx < product.length && endIdx < product.length - 1 && (
            <div className="wnfy-select-nav-button-wrapper">
              <div
                className="wnfy-result-navigate-arrow"
                onClick={(e) => this.handleOnClickRight()}
              >
                <img src={rightSideArrow} alt="" />
              </div>
            </div>
          )}
        </>
      );
    }
  };
  handleSelectProduct = (event) => {
    event.preventDefault();
    if (event) {
      this.setState(
        {
          selectedProduct: event.target.id,
        },
        async () => {
          let dosagesVal = await this.service.getDosagesforProduct(
            this.state.selectedProduct
          );
          let dosType = dosagesVal.map((x) => x.dosagetype);
          const x = this.eliminateDuplicates(dosType);
          this.setState({ selectedDosageType: x });
          this.setState({ dosageType: dosagesVal, showDocsType: false, selectedDosage: "" });
        }
      );
    }
  };
  handleOnClickRight = () => {
    if (this.props.customWidth <= 1360 && this.props.customWidth >= 1200) {
      this.setState({ startIndex: this.state.startIndex + 6 });
    } else if (
      this.props.customWidth <= 1200 &&
      this.props.customWidth >= 1000
    ) {
      this.setState({ startIndex: this.state.startIndex + 5 });
    } else if (
      this.props.customWidth <= 1000 &&
      this.props.customWidth >= 750
    ) {
      this.setState({ startIndex: this.state.startIndex + 4 });
    } else if (this.props.customWidth <= 750 && this.props.customWidth >= 550) {
      this.setState({ startIndex: this.state.startIndex + 3 });
    } else if (this.props.customWidth <= 550 && this.props.customWidth >= 450) {
      this.setState({ startIndex: this.state.startIndex + 2 });
    } else if (this.props.customWidth <= 450) {
      this.setState({ startIndex: this.state.startIndex + 1 });
    } else {
      this.setState({ startIndex: this.state.startIndex + 7 });
    }
    // this.setState({
    //     startIndex: this.state.startIndex + 7
    // })
  };
  handleOnClickLeft = () => {
    if (this.props.customWidth <= 1360 && this.props.customWidth >= 1200) {
      this.setState({ startIndex: this.state.startIndex - 6 });
    } else if (
      this.props.customWidth <= 1200 &&
      this.props.customWidth >= 1000
    ) {
      this.setState({ startIndex: this.state.startIndex - 5 });
    } else if (
      this.props.customWidth <= 1000 &&
      this.props.customWidth >= 750
    ) {
      this.setState({ startIndex: this.state.startIndex - 4 });
    } else if (this.props.customWidth <= 750 && this.props.customWidth >= 550) {
      this.setState({ startIndex: this.state.startIndex - 3 });
    } else if (this.props.customWidth <= 550 && this.props.customWidth >= 450) {
      this.setState({ startIndex: this.state.startIndex - 2 });
    } else if (this.props.customWidth <= 450) {
      this.setState({ startIndex: this.state.startIndex - 1 });
    } else {
      this.setState({ startIndex: this.state.startIndex - 7 });
    }
    // this.setState({
    //     startIndex: this.state.startIndex - 7
    // })
  };
  productList = () => {
    let startIdx = this.state.startIndex;
    // let endIdx = startIdx + 6;
    let endIdx;
    if (this.props.customWidth <= 1360 && this.props.customWidth >= 1200) {
      endIdx = startIdx + 5;
    } else if (
      this.props.customWidth <= 1200 &&
      this.props.customWidth >= 1000
    ) {
      endIdx = startIdx + 4;
    } else if (
      this.props.customWidth <= 1000 &&
      this.props.customWidth >= 750
    ) {
      endIdx = startIdx + 3;
    } else if (this.props.customWidth <= 750 && this.props.customWidth >= 550) {
      endIdx = startIdx + 2;
    } else if (this.props.customWidth <= 550 && this.props.customWidth >= 450) {
      endIdx = startIdx + 1;
    } else if (this.props.customWidth <= 450) {
      endIdx = startIdx + 0;
    } else {
      endIdx = startIdx + 6;
    }
    return (
      <div className="cs-product-wrapper">
        <div className="cs-product-inner-wrapper">
          {this.selectProduct(this.state.products, startIdx, endIdx)}
        </div>
      </div>
    );
  };
  generateListofDosageType = (arrayOfDosage, i) => {
    if (arrayOfDosage.length > 0) {
      if (this.state.selectedDosageType.length > 0) {
        if (i === 0) {
          return arrayOfDosage
            .filter(
              (val) => val.dosagetype === this.state.selectedDosageType[0]
            )
            .map((items, index) => {
              return (
                <div key={index} className="dosagesSelection">
                  <input
                    type="radio"
                    id={`${items.dosage}`}
                    class="k-radio"
                    value={items.dosage}
                    name="dosageRadioButton"
                    key={items.dosage + Math.random()}
                    onChange={this.handleChangeInDosageType}
                    checked={this.state.selectedDosage === items.dosage}
                  />
                  <div className="dosagesText">{items.dosage}</div>
                </div>
              );
            });
        }
        if (i === 1) {
          return arrayOfDosage
            .filter(
              (val) => val.dosagetype === this.state.selectedDosageType[1]
            )
            .map((items, index) => {
              return (
                <div key={index} className="dosagesSelection">
                  <input
                    type="radio"
                    id={`${items.dosage}`}
                    class="k-radio"
                    value={items.dosage}
                    name="dosageRadioButton"
                    key={items.dosage + Math.random()}
                    onChange={this.handleChangeInDosageType}
                    checked={this.state.selectedDosage === items.dosage}
                  />
                  <div className="dosagesText">{items.dosage}</div>
                </div>
              );
            });
        }
        if (i === 2) {
          return arrayOfDosage
            .filter(
              (val) => val.dosagetype === this.state.selectedDosageType[1]
            )
            .map((items, index) => {
              return (
                <div key={index} className="dosagesSelection">
                  <input
                    type="radio"
                    id={`${items.dosage}`}
                    class="k-radio"
                    value={items.dosage}
                    name="dosageRadioButton"
                    key={items.dosage + Math.random()}
                    onChange={this.handleChangeInDosageType}
                    checked={this.state.selectedDosage === items.dosage}
                  />
                  <div className="dosagesText">{items.dosage}</div>
                </div>
              );
            });
        }
        if (i === 3) {
          return arrayOfDosage
            .filter(
              (val) => val.dosagetype === this.state.selectedDosageType[1]
            )
            .map((items, index) => {
              return (
                <div key={index} className="dosagesSelection">
                  <input
                    type="radio"
                    id={`${items.dosage}`}
                    class="k-radio"
                    value={items.dosage}
                    name="dosageRadioButton"
                    key={items.dosage + Math.random()}
                    onChange={this.handleChangeInDosageType}
                    checked={this.state.selectedDosage === items.dosage}
                  />
                  <div className="dosagesText">{items.dosage}</div>
                </div>
              );
            });
        }
        if (i === 4) {
          return arrayOfDosage
            .filter(
              (val) => val.dosagetype === this.state.selectedDosageType[1]
            )
            .map((items, index) => {
              return (
                <div key={index} className="dosagesSelection">
                  <input
                    type="radio"
                    id={`${items.dosage}`}
                    class="k-radio"
                    value={items.dosage}
                    name="dosageRadioButton"
                    key={items.dosage + Math.random()}
                    onChange={this.handleChangeInDosageType}
                    checked={this.state.selectedDosage === items.dosage}
                  />
                  <div className="dosagesText">{items.dosage}</div>
                </div>
              );
            });
        }
        if (i === 5) {
          return arrayOfDosage
            .filter(
              (val) => val.dosagetype === this.state.selectedDosageType[1]
            )
            .map((items, index) => {
              return (
                <div key={index} className="dosagesSelection">
                  <input
                    type="radio"
                    id={`${items.dosage}`}
                    class="k-radio"
                    value={items.dosage}
                    name="dosageRadioButton"
                    key={items.dosage + Math.random()}
                    onChange={this.handleChangeInDosageType}
                    checked={this.state.selectedDosage === items.dosage}
                  />
                  <div className="dosagesText">{items.dosage}</div>
                </div>
              );
            });
        }
        if (i === 6) {
          return arrayOfDosage
            .filter(
              (val) => val.dosagetype === this.state.selectedDosageType[1]
            )
            .map((items, index) => {
              return (
                <div key={index} className="dosagesSelection">
                  <input
                    type="radio"
                    id={`${items.dosage}`}
                    class="k-radio"
                    value={items.dosage}
                    name="dosageRadioButton"
                    key={items.dosage + Math.random()}
                    onChange={this.handleChangeInDosageType}
                    checked={this.state.selectedDosage === items.dosage}
                  />
                  <div className="dosagesText">{items.dosage}</div>
                </div>
              );
            });
        }
      }
    }
  };
  handleChangeInDosageType = (event) => {
    event.preventDefault();
    if (event) {
      this.setState({
        selectedDosage: event.target.id,
        showDocsType: true,
      });
    }
  };
  generateDosageType = (dosageTypeArr) => {
    if (dosageTypeArr.length > 0) {
      return dosageTypeArr.map((items, index) => {
        return (
          <div key={index} className="dosagesTypes">
            <span>{items}</span>
            {this.generateListofDosageType(this.state.dosageType, index)}
          </div>
        );
      });
    }
  };
  generateListOfDocTypes = (arrayOfDoc) => {
    if (arrayOfDoc.length > 0) {
      return arrayOfDoc.map((items, index) => {
        let url;
        if (this.state.selectedProduct !== "") {
          url =
            `/results?query=${this.state.selectedProduct}&q=All%20Fields&filters=` +
            items.filter +
            `&f1=tempJNJDrugs&fval1=${this.state.selectedProduct
            }&fcount=1&sbQuery=${this.state.selectedProduct
            } (${this.state.selectedDosage.replace(/\s/g, "")} OR "${this.state.selectedDosage
            }")&isBoolean=true&cspath=Routes of Administration and Dosages-${items.title
            }&csproduct=${this.state.selectedProduct}`;
        } else {
          url =
            `/results?query=${this.state.selectedProduct}&q=All%20Fields&filters=` +
            items.filter;
        }
        return (
          <li key={index} className="cs-modalListing">
            <Link
              to={url}
              target="_blank"
              className="cs-modalLink"
              onClick={() =>
                this.handelDocsTypesLinks(items.filter, url, items.title)
              }
            >
              {items.title}
            </Link>
          </li>
        );
      });
    }
  };
  handelDocsTypesLinks = (filter, url, title) => {
    const ts = new trackingService();
    // let newUrl = url.replaceAll('"', "'");
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      localStorage.getItem("companyname"),
      // "Link visited from Routes of Administration and Dosages tile",//+window.origin + newUrl,
      `Visited ${this.state.selectedProduct + " " + this.state.selectedDosage
      } for ${title}`,
      "",
      window.location.href,
      this.state.selectedProduct,
      filter,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      `Routes of Administration and Dosages-${title}`,
      this.state.selectedProduct
    );
  };
  onMouseEnterInfo = () => {
    this.setState({
      showInfo: true,
    });
  };
  onMouseLeaveInfo = () => {
    this.setState({
      showInfo: false,
    });
  };
  showDetails = () => {
    const ts = new trackingService();
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      localStorage.getItem("companyname"),
      "Expanded Routes of Administration and Dosages tile under content shortcuts",
      "",
      window.location.href
      //   this.state.searchKeyword,
      //   this.props.appliedFilters
    );
    this.setState({ showCSModal: !this.state.showCSModal }, () => {
      this.props.handelAnDCardDetail(this.state.showCSModal);
    });
  };
  handleCloseModal = () => {
    this.setState({ showCSModal: false,showDocsType: false, selectedDosage: "" }, () => {
      this.props.handelAnDCardDetail(this.state.showCSModal);
    });
  };
  render() {
    var privacyHtml = `
        <div class="cs-modalNote">
            <p>PLEASE NOTE: The dosages provided below are those commonly searched by iMS users, so could include both approved and unapproved dosage forms as well as administered dosages. Please refer to your local Package Insert for the approved dosage forms and administered dosages.</p>
        </div>`;
    return (
      <div className="contentShortcutInnerWapper">
        <div
          className="cardContainer"
          onClick={this.showDetails}
          style={{
            borderTop: this.state.showCSModal ? "5px solid #0074B0" : "none",
            zIndex: this.state.showCSModal ? "1" : "0",
          }}
        >
          <div
            className="cardInnerWapper"
            style={this.state.showCSModal ? { borderBottom: "none" } : {}}
          >
            <div
              className="card-leftPart cs-middle-r4-tile-"
              style={
                this.props.customWidth >= 1000
                  ? { textAlign: "center", position: "absolute", width: "31%" }
                  : {}
              }
            >
              <small>
                <span className="cs-name-bold">Routes of Administration and Dosages</span> <br /> (Quick access by
                product and document type)
              </small>
            </div>
            <div
              className="card-iconsPart"
              style={
                this.props.customWidth >= 1000 ? { marginLeft: "auto" } : {}
              }
            >
              <img
                src={iIcon}
                alt="i-icon"
                width={18}
                height={18}
                onMouseEnter={this.onMouseEnterInfo}
                onMouseLeave={this.onMouseLeaveInfo}
                className="cs-tile-i-icon"
              />
              <div className="card-rightPart">
                <img
                  src={rightSideArrow}
                  alt="down-arrow"
                  className="cs-downArrow"
                  style={{
                    transform: this.state.showCSModal ? "rotate(270deg)" : null,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.showInfo && (
          <div className="cs-tile-wrapper">
            <div className="cs-tooltip-uparrow"></div>
            <span className="tooltiptext cs-tooltip-bottom">
              This tile contains data about active interventional studies based
              upon your product preferences. Clicking on the Protocol IDs in the
              tables will show you all of the evidence on iMedical Search that’s
              directly related to that study.
            </span>
          </div>
        )}
        {this.state.showCSModal && (
          <div
            className="cs-modalInner"
            style={this.props.customWidth > 1000 ? { marginLeft: "11px" } : {}}
          >
            <div>
              <div class="popup-cancel-btn-wrapper">
                <img
                  src={popCancelBttn}
                  alt=""
                  onClick={this.handleCloseModal}
                />
              </div>
              <div>
                <div
                  className="cs-modalHeader"
                  style={{
                    justifyContent: "center",
                    display: this.state.products.length <= 0 && "none",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <div class="modal-title h4">
                      <small className="cs-modalTitle">Select Product</small>
                      {this.state.products.length > 0 && this.productList()}
                    </div>
                  </div>
                </div>
                <div
                  class="modal-body"
                  style={{ width: this.state.products.length <= 0 && "95%" }}
                >
                  <div>{convertToHtml(privacyHtml)}</div>
                  {
                    this.state.products.length > 0 ? (
                      this.state.dosageType.length > 0 ? (
                        <div className="cs-modalBody-listing">
                          <div className="cs-modalBody-leftPart">
                            <span>Step 1: Select Dosage</span>
                            <br />
                            {this.generateDosageType(
                              this.state.selectedDosageType
                            )}
                          </div>
                          {this.state.showDocsType && (
                            <div className="cs-modalBody-rightPart">
                              <span>Step 2: Select Document Type</span>
                              <br />
                              <div className="docsTypeText">Document Types</div>
                              <div className="cs-modalList">
                                <ul style={{ listStyle: "none" }}>
                                  {this.generateListOfDocTypes(
                                    this.state.arrayOfDocTypes
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="dosageNA">
                          This feature is not available for the product you
                          selected.
                        </div>
                      )
                    ) : (
                      <div className="wnfy-no-product-content-section">
                        You don't have any product preference selected. Click{" "}
                        {JSON.parse(localStorage.getItem("russianuser")) ? (
                          <Link
                            to="/register-role"
                            style={{ textDecoration: "none", color: "#1076bc" }}
                          >
                            here
                          </Link>
                        ) : (
                          <Link
                            to={{
                              pathname: "/registration",
                              query: { toProducts: true },
                            }}
                            style={{ textDecoration: "none", color: "#1076bc" }}
                          >
                            here
                          </Link>
                        )}{" "}
                        to select your product preference.
                      </div>
                    )
                    // <div className="wnfy-no-product-content-section">
                    //     It seems that you don't have any product preference selected. Click {JSON.parse(localStorage.getItem('russianuser')) ? <Link to="/register-role" style={{ textDecoration: "none", color: "#1076bc" }}>here</Link> : <Link to="/registration" style={{ textDecoration: "none", color: "#1076bc" }}>here</Link>} to select your product preference. This link will take you to the registration pages, where you can navigate to <strong>Product</strong> page to change your preference.
                    // </div>
                  }
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default AdministrationAndDosagesTile;
