import React, { Component, createRef } from "react";
import { withRouter } from 'react-router-dom';
import { Sidebar } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { GenAIAnsweringContext } from './GenAIAnsweringContextProvider';
import OpenWindow from '../OpenWindow';
import GenerateArrayStrings from "../GenerateArrayStrings";
import { DocmentTypeForProtocolNo } from '../../utils/utilityServices';

class GenAIPreviewSidebar extends Component {
    static contextType = GenAIAnsweringContext
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
        }
    }
    extractNCTId = (protocolIdsArr) => {
        var pidNCTArray = [];
        var pidStream = "";
        var nctStream = "";
        let nctId = protocolIdsArr.filter((elm) => elm.startsWith("NCT"));
        let pIds = protocolIdsArr.filter((elm) => !elm.startsWith("NCT"));
        if (pIds.length > 0) {
            pidStream = pIds.toString().split(",").join(";");
        }
        if (nctId.length > 0) {
            nctStream = nctId.toString().split(",").join(";");
        }
        pidNCTArray.push(pidStream);
        pidNCTArray.push(nctStream);
        return pidNCTArray;
    };
    handleProtocolID = (protocolIDs) => {
        let ids = GenerateArrayStrings(protocolIDs);
        let queryIds = protocolIDs.join(" OR ");
        let url =
            window.location.origin +
            `/results?query=${queryIds}&sbQuery=&q=All%20Fields&filters=&f1=protocolIDs&fval1=${ids}&fcount=1`;
        OpenWindow(url);
    }
    renderSources = () => {
        let { selectedSource, DisplayString, isRecommendation } = this.props;
        let { context_id, context, document_title, section_id, download_url, doc_number, contexts, enclosureDocID } = selectedSource;
        let { protocolIDs, ctNames, docType } = selectedSource;
        let _protocolIDs = [], _ctNames = [];
        if (protocolIDs) {
            _protocolIDs = protocolIDs.split(', ');
        }
        if (ctNames) {
            _ctNames = ctNames.replace('[, ]', '').split(', ');
        }
        let extractedIds = this.extractNCTId(_protocolIDs);
        if (enclosureDocID && enclosureDocID.trim()) {
            download_url += `&enclosureID=${enclosureDocID}`
        }
        if ((selectedSource.contexts && selectedSource.contexts.length > 0)) {
            selectedSource.contexts = selectedSource.contexts.sort((a, b) => a.context_id - b.context_id);
        }
        return (<div className="gen-ai-answering-preview-window-source-main">

            <div className="gen-ai-answering-preview-window-source-item-title-wrapper gen-ai-answering-result-source-margin">
                <Link
                    className="gen-ai-answering-preview-window-source-item-title"
                    id="docdata"
                    to={{ pathname: download_url }}
                    target="_blank"
                    title={`${document_title.replace(/_/g, " ").replace(/-/g, " ")}`}
                >
                    {document_title.replace(/_/g, " ").replace(/-/g, " ")}
                </Link>

            </div>
            <div className="gen-ai-answering-preview-window-source-item-docnumber gen-ai-answering-result-source-margin">
            <b>{doc_number}</b>
            </div>
            {docType &&
                <div className="gen-ai-answering-result-source-item-docnumber gen-ai-answering-result-source-margin">
                    {docType}
                </div>}
            {(extractedIds && extractedIds.length > 1 && extractedIds[0]&& DocmentTypeForProtocolNo.includes(docType)) && <div className="gen-ai-answering-result-source-item-protocol-preview-sidebar gen-ai-answering-result-source-margin">
                <div className="gen-ai-answering-result-source-item-citation-lable"><b>Protocol No:</b>&nbsp;          <a
                    href="javascript:void(0)"
                    title="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab"
                    onClick={(evt) =>
                        this.handleProtocolID(
                            extractedIds[0].split(';')
                        )
                    }>
                    {GenerateArrayStrings((_ctNames.concat(extractedIds[0].split(';'))).filter(x => x))}
                </a></div>
            </div>}
            {(selectedSource.contexts && selectedSource.contexts.length > 0) &&
                <div className="gen-ai-answering-preview-window-source-item-citations gen-ai-answering-result-source-margin">
                    <div className="gen-ai-answering-preview-window-source-item-citation-lable">
                        {isRecommendation ? <b>Recommendations:</b> : <b>Citations:</b>}
                    </div>
                    <div className="gen-ai-answering-preview-window-source-item-citation-number">
                        {
                            selectedSource.contexts.map((conItem, consIndex) => {
                                if (isRecommendation) {
                                    return (<div className="gen-ai-answering-preview-window-source-citation-item">
                                        <label key={`gen-ai-answering-result-main-answer-label-${consIndex}`} onClick={() => this.props.handleclickCitation(conItem.page_number[0])} className="gen-ai-answering-preview-window-source-label">{consIndex + 1}</label>
                                        &nbsp;Page {conItem.page_number.join(', ')}
                                    </div>)
                                } else {
                                    let _displayStringFilter = DisplayString.filter(x => x.source_no.includes(conItem.context_id));
                                    if (_displayStringFilter.length > 0) {
                                        let _displayStringSingle = _displayStringFilter[0];
                                        let displayIndex = _displayStringSingle.source_no.findIndex(x => x === conItem.context_id);
                                        return (<div className="gen-ai-answering-preview-window-source-citation-item">
                                            <label key={`gen-ai-answering-result-main-answer-label-${consIndex}`} onClick={() => this.props.handleclickCitation(_displayStringSingle.page_number[displayIndex][0])} className="gen-ai-answering-preview-window-source-label">{conItem.context_id}</label>
                                            &nbsp;Page {_displayStringSingle.page_number[displayIndex].join(', ')}
                                        </div>)
                                    } else {
                                        return (<label key={`gen-ai-answering-result-main-answer-label-${consIndex}`} className="gen-ai-answering-preview-window-source-label">{conItem.context_id}</label>)
                                    }
                                }
                            })
                        }
                    </div>
                </div>}
        </div>)
    }
    getSourceFileName(singleFile) {
        let filename = singleFile.split('.').slice(0, -1).join('.');
        let fileExtention = singleFile.substring(singleFile.lastIndexOf('.') + 1, singleFile.length) || singleFile;
        filename = this.snippetLongStringForKeyword(filename);
        return `${filename} .${fileExtention}`;;
    }

    snippetLongStringForKeyword = (snippet, strLength = 70) => {
        if (snippet.length > strLength) {
            let text = snippet.substring(0, strLength)//cuts to 70
            text = text + `...`//adds (...) at the end to show that it's cut
            return text
        }
        return snippet;
    }
    renderSourcesMultiFiles = () => {
        let { selectedSource, DisplayString } = this.props;
        if ((selectedSource.contexts && selectedSource.contexts.length > 0)) {
            selectedSource.contexts = selectedSource.contexts.sort((a, b) => a.context_id - b.context_id);
        }

        return (<div className="gen-ai-answering-preview-window-source-main">
            <div className="gen-ai-answering-preview-window-source-item-title-wrapper">
                <span
                    className="gen-ai-answering-result-source-item-title"
                    title={`${selectedSource.document_title}`}>
                    {this.getSourceFileName(selectedSource.document_title.replace(/_/g, " ").replace(/-/g, " "))}
                </span>
            </div>
            {(selectedSource.contexts && selectedSource.contexts.length > 0) && <div className="gen-ai-answering-preview-window-source-item-citations">
                <div className="gen-ai-answering-preview-window-source-item-citation-lable"><b>Citations:</b></div>
                <div className="gen-ai-answering-preview-window-source-item-citation-number">
                    {
                        selectedSource.contexts.map((conItem, consIndex) => {
                            let _displayStringFilter = DisplayString.filter(x => x.context_id && x.context_id.includes(conItem.context_id));
                            if (_displayStringFilter.length > 0) {
                                let _displayStringSingle = _displayStringFilter[0];
                                let displayIndex = _displayStringSingle.context_id.findIndex(x => x === conItem.context_id);
                                return (<div className="gen-ai-answering-preview-window-source-citation-item">
                                    <label key={`gen-ai-answering-result-main-answer-label-${consIndex}`} onClick={() => this.props.handleclickCitation([_displayStringSingle.page_number[displayIndex]][0])} className="gen-ai-answering-preview-window-source-label">{conItem.context_id}</label>
                                    &nbsp;Page {_displayStringSingle.page_number[displayIndex].join(', ')}
                                </div>)
                            } else {
                                return (<label key={`gen-ai-answering-result-main-answer-label-${consIndex}`} className="gen-ai-answering-preview-window-source-label">{conItem.context_id}</label>)
                            }
                        })
                    }
                </div>
            </div>}
        </div>)
    }
    render() {
        let { collapsed } = this.state;
        let { isMultiFiles } = this.props;
        return (<>
            <Sidebar className="gen-ai-answering-window-preview-sidebar" collapsed={collapsed} >
                <div className="gen-ai-answering-window-preview-header">
                    <div className="gen-ai-answering-window-preview-right">
                        {!collapsed ?
                            <>
                                {isMultiFiles ? this.renderSourcesMultiFiles() : this.renderSources()}
                            </> : <></>}
                    </div>
                    <div className="gen-ai-answering-window-preview-left">
                        {collapsed ?
                            <>
                                <div className="gen-ai-answering-menuitem-arrow-left-to-line" onClick={() => { this.setState({ collapsed: false }); this.context.menuWindowPreviewInfoCollapse(); }}>

                                    <svg className="svg-icon window-preview-open-close" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M512 64c247.424 0 448 200.576 448 448s-200.576 448-448 448S64 759.424 64 512 264.576 64 512 64z m-19.2 198.656l-3.008 2.656-222.304 222.08-2.24 2.016-2.656 3.008a31.552 31.552 0 0 0-6.144 13.824L256 509.984v4l0.448 3.744c0.64 3.68 1.952 7.296 3.936 10.624l2.208 3.2 2.656 3.04 2.24 1.984 222.304 222.112 3.008 2.656a31.776 31.776 0 0 0 46.752-41.28l-2.176-3.2-2.688-3.04L332.8 512l201.92-201.824 2.656-2.976c8-10.24 8.8-24.32 2.4-35.328l-2.368-3.552-2.688-3.008-2.976-2.656a31.776 31.776 0 0 0-38.912 0z m192 0l-3.008 2.656-222.304 222.08-2.24 2.016-2.656 3.008a31.552 31.552 0 0 0-6.144 13.824l-0.448 3.744v4l0.448 3.744c0.64 3.68 1.952 7.296 3.936 10.624l2.208 3.2 2.656 3.04 2.24 1.984 222.304 222.112 3.008 2.656a31.776 31.776 0 0 0 46.752-41.28l-2.176-3.2-2.688-3.04L524.8 512l201.92-201.824 2.656-2.976c8-10.24 8.8-24.32 2.4-35.328l-2.368-3.552-2.688-3.008-2.976-2.656a31.776 31.776 0 0 0-38.912 0z" /></svg>
                                </div>
                            </> :
                            <>
                                <div className="gen-ai-answering-menuitem-arrow-left-to-line" onClick={() => { this.setState({ collapsed: true }); this.context.menuWindowPreviewInfoExpand(); }}>
                                    <svg className="svg-icon window-preview-open-close" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M512 64c247.424 0 448 200.576 448 448s-200.576 448-448 448S64 759.424 64 512 264.576 64 512 64z m19.2 198.656a31.776 31.776 0 0 0-38.912 0l-2.976 2.656-2.688 3.008-2.368 3.52a31.776 31.776 0 0 0 2.4 35.36l2.656 2.976L691.2 512l-201.92 201.824-2.688 3.008-2.176 3.2a31.744 31.744 0 0 0 46.752 41.312l3.008-2.656 222.304-222.112 2.24-1.984 2.656-3.04 2.208-3.2a31.584 31.584 0 0 0 3.936-10.624l0.448-3.744v-4l-0.448-3.744a31.552 31.552 0 0 0-6.144-13.824l-2.656-3.008-2.24-2.016-222.304-222.08-3.008-2.656z m-192 0a31.776 31.776 0 0 0-38.912 0l-2.976 2.656-2.688 3.008-2.368 3.52A31.776 31.776 0 0 0 294.656 307.2l2.656 2.976L499.2 512l-201.92 201.824-2.688 3.008-2.176 3.2a31.744 31.744 0 0 0 46.752 41.312l3.008-2.656 222.304-222.112 2.24-1.984 2.656-3.04 2.208-3.2a31.584 31.584 0 0 0 3.936-10.624l0.448-3.744v-4l-0.448-3.744a31.552 31.552 0 0 0-6.144-13.824l-2.656-3.008-2.24-2.016-222.304-222.08-3.008-2.656z" /></svg>

                                </div>
                            </>
                        }
                    </div>
                </div>
            </Sidebar>
        </>
        )
    }
}
export default withRouter(GenAIPreviewSidebar);