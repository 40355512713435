/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import GenAIAnsweringPreview from "../Preview/GenAIAnsweringPreview"
import GenerateArrayStrings from "../GenerateArrayStrings";
import OpenWindow from '../OpenWindow';
import trackingService from "../../services/trackingService";
import { DocmentTypeForProtocolNo } from '../../utils/utilityServices';
class GenAIRecommendation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            selectedSource: null,
            selectedPageNumber: [],
            showPreviewModel: false,
            eventid: props.eventid,
        }
    }
    renderModelPopup() {
        const { showPreviewModel, selectedSource, selectedPageNumber } = this.state;
        return (<Modal
            show={showPreviewModel}
            size="xl"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="answering-popup-transition"
            className="z-index-preview"
            backdropClassName="z-index-preview-backdrop"
            scrollable={false}>
            <Modal.Body>
                <div style={{ overflow: "auto" }}>
                    <GenAIAnsweringPreview selectedSource={selectedSource} selectedPageNumber={selectedPageNumber} handleClose={this.handlePreviewClose} />
                </div>
            </Modal.Body>
        </Modal>)
    }
    handleClickOnSourceNumber = (singleSource, pageNumbers) => {
        pageNumbers = pageNumbers.sort((a, b) => a - b);
        const { eventid } = this.state;
        let isMultiFiles = false;
        let prviewObject = {
            selectedSource: singleSource,
            selectedPageNumber: pageNumbers,
            attachedFiles: [],
            isMultiFiles: isMultiFiles,
            DisplayString: [],
            isRecommendation: true,
        }
        let _id = "";
        if (isMultiFiles && singleSource.document_guid) {
            _id = singleSource.document_guid
        } else if (singleSource.searchID) {
            _id = singleSource.searchID
        }
        if (eventid && _id) {
            let width = 0.99 * window.innerWidth
            let height = 1.05 * window.innerHeight
            let left = 0.05 * window.innerWidth
            let top = 0.05 * window.innerHeight
            let windowPreview = window.open(`/genAIAnsweringPreview/${eventid}?docid=${_id}`, '', `width=${width},height=${height},top=${top},left=${left},toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no`)
            windowPreview[`${eventid}-${_id}`] = prviewObject;
        }
    }
    handlePreviewClose = async () => {

        this.setState({ showPreviewModel: false },
            async () => {
                setTimeout(() => {
                    document.body.classList.remove('custome-modal-open');
                }, 1000);
            })
    }
    extractNCTId = (protocolIdsArr) => {
        var pidNCTArray = [];
        var pidStream = "";
        var nctStream = "";
        let nctId = protocolIdsArr.filter((elm) => elm.startsWith("NCT"));
        let pIds = protocolIdsArr.filter((elm) => !elm.startsWith("NCT"));
        if (pIds.length > 0) {
            pidStream = pIds.toString().split(",").join(";");
        }
        if (nctId.length > 0) {
            nctStream = nctId.toString().split(",").join(";");
        }
        pidNCTArray.push(pidStream);
        pidNCTArray.push(nctStream);
        return pidNCTArray;
    };
    handleProtocolID = (protocolIDs) => {
        let ids = GenerateArrayStrings(protocolIDs);
        let queryIds = protocolIDs.join(" OR ");
        let url =
            window.location.origin +
            `/results?query=${queryIds}&sbQuery=&q=All%20Fields&filters=&f1=protocolIDs&fval1=${ids}&fcount=1`;
        OpenWindow(url);
    }
    indexDocumentPreview = (docTitle, searchID, url, docNumber) => {
        let { sessionID, Question, questionType, threadID, eventid } = this.props;
        let assistantinfo = {
            "session_id": sessionID,
            "thread_id": threadID,
            "question_type": questionType,
            "question": Question,
            "ismultifiles": false,
            "eventid": eventid
        }
        const ts = new trackingService();
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'),
            localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'),
            localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'),
            localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'),
            localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'),
            `Preview Answering2.0 Recommendations Document`, '',
            window.location.href,
            '',
            '',
            docTitle, '', docNumber, searchID, url, '', '', `Preview Answering2.0 Recommendations Document`, '', '', '', '', '', '', '', '', {}, {}, assistantinfo);
    };
    indexDownloadData = (event) => {
        var docTitle = [event.target.getAttribute("data-title")];
        var searchID = [event.target.getAttribute("data-docid")];
        var url = [event.target.getAttribute("data-docurl")];
        var docNumber = [event.target.getAttribute("data-docNumber")];
        let { sessionID, Question, questionType, threadID, eventid } = this.props;
        let assistantinfo = {
            "session_id": sessionID,
            "thread_id": threadID,
            "question_type": questionType,
            "question": Question,
            "eventid": eventid
        }
        const ts = new trackingService();
        ts.indexTrackingData(localStorage.getItem('userdisplayName'), localStorage.getItem('wwid'),
            localStorage.getItem('username'), localStorage.getItem('function'), localStorage.getItem('region'),
            localStorage.getItem('userproducts'), localStorage.getItem('doctypepref'), localStorage.getItem('sid'),
            localStorage.getItem('deviceType'), localStorage.getItem('deviceOS'), localStorage.getItem('browserName'),
            localStorage.getItem('browserVersion'), localStorage.getItem('jnjDepartment'), localStorage.getItem('companyname'),
            `View OR Download Answering2.0 Recommendations Document`, '',
            window.location.href,
            '',
            '',
            docTitle, '', docNumber, searchID, url, '', '', `View OR Download Answering2.0 Recommendations Document`, '', '', '', '', '', '', '', '', {}, {}, assistantinfo);
    };
    render() {
        let { documentIndex, totalDocuments } = this.props;
        let { document_title, download_url, doc_number, enclosureDocID, docType, searchID } = this.props.documentInfo;
        let { protocolIDs, ctNames, ctNameID } = this.props.documentInfo;
        let _protocolIDs = [], _ctNames = [];
        if (protocolIDs) {
            _protocolIDs = protocolIDs.split(', ');
        }
        if (ctNames) {
            _ctNames = ctNames.replace('[, ]', '').split(', ');
        }
        let extractedIds = this.extractNCTId(_protocolIDs);
        if (enclosureDocID && enclosureDocID.trim()) {
            download_url += `&enclosureID=${enclosureDocID}`
        }
        let pageNumber = [];
        if (this.props.documentInfo.page_number) {
            pageNumber = this.props.documentInfo.page_number;
        } else if (this.props.documentInfo.contexts && this.props.documentInfo.contexts.length > 0) {
            pageNumber = this.props.documentInfo.contexts[0].page_number;
        }
        return (<>
            <div className="gen-ai-answering-result-recommendation-item" key={`gen-ai-answering-result-recommendation-item${documentIndex}`}>
                <div className="gen-ai-answering-result-recommendation-item-title-wrapper">
                    <Link
                        className="gen-ai-answering-result-recommendation-item-title"
                        id="docdata"
                        to={{ pathname: download_url }}
                        target="_blank"
                        onClick={this.indexDownloadData}
                        data-title={document_title}
                        data-docid={searchID}
                        data-docurl={download_url}
                        data-docNumber={doc_number}
                        title={`${documentIndex}. ${document_title.replace(/_/g, " ").replace(/-/g, " ")}`}
                    >
                        {documentIndex}. {document_title.replace(/_/g, " ").replace(/-/g, " ")}
                    </Link>

                </div>
                <div className="gen-ai-answering-result-recommendation-item-docnumber">
                    <b>{doc_number}</b>
                </div>
                {docType &&
                    <div className="gen-ai-answering-result-source-item-docType">
                        {docType}
                    </div>}
                {(extractedIds && extractedIds.length > 1 && extractedIds[0] && DocmentTypeForProtocolNo.includes(docType)) && <div className="gen-ai-answering-result-source-item-protocol-recommendation">
                    <div className="gen-ai-answering-result-source-item-citation-lable"><b>Protocol No:</b>&nbsp;
                        <a href="javascript:void(0)"
                            title="Click this field to retrieve all evidence from iMS assigned these Protocol IDs/Numbers. This will appear in a new browser tab"
                            onClick={(evt) =>
                                this.handleProtocolID(
                                    extractedIds[0].split(';')
                                )
                            }>
                            {GenerateArrayStrings((_ctNames.concat(extractedIds[0].split(';'))).filter(x => x))}
                        </a></div>
                </div>}
                <div className="gen-ai-answering-result-recommendation-item-preview-panel" onClick={(e) => { this.indexDocumentPreview(document_title, searchID, download_url, doc_number); this.handleClickOnSourceNumber(this.props.documentInfo, pageNumber) }}>
                    <span className="gen-ai-answering-result-recommendation-item-preview-link">Preview</span>

                </div>
            </div>
            {totalDocuments !== documentIndex && <hr className="gen-ai-answering-result-recommendation-item-hr"></hr>}
            {this.renderModelPopup()}
        </>
        )
    }
}
export default GenAIRecommendation;