import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./index.css"
import { GenAIAnsweringContext } from './GenAIAnsweringContextProvider';
import { sanitize } from 'dompurify';

class GenAICombinedSummary extends Component {
    static contextType = GenAIAnsweringContext;
    constructor(props) {
        super(props);
    }
    render() {
        let { combinedSummaryMultiFiles } = this.props;
        if (!Array.isArray(combinedSummaryMultiFiles)) {
            return (<div className="gen-ai-answering-result-combined-summary-wrapper-span">
                <span dangerouslySetInnerHTML={{ __html: sanitize(`${this.props.combinedSummaryMultiFiles}`) }} />
            </div>)
        } else {
            return (<div className="gen-ai-answering-result-combined-summary-wrapper-span">
                {
                    combinedSummaryMultiFiles.map(x => {

                        return (<div className="gen-ai-answering-result-combined-summary-wrapper-span">
                            <span dangerouslySetInnerHTML={{ __html: sanitize(x) }} />
                        </div>)
                    })
                }
            </div>)
        }
    }
}
export default withRouter(GenAICombinedSummary);