/* eslint-disable no-regex-spaces */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import { imsLoader, paginationLeftArrowMobile, paginationRightArrowMobile, crossIcon, magnifyingGlass, paginationDisabledRightArrowMobile, paginationDisabledLeftArrowMobile, viewMoreArrowWhite } from "../../assets";
import ApiServices from "../../utils/apiServices";
import { UtilityServices, DocumentPreviewOrderBy, DocumentPreviewSearch, LocationOrigin } from "../../utils/utilityServices";
import './AiNotebookDocumentPreview.css'
import DocumentPreviewChart from './DocumentPreviewChart';
// import AiNotebookSummery from './AiNotebookSummery'
import AuthoringSummery from '../Authoring/AuthoringSummery';
import { v4 as uuidv4 } from 'uuid';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import AlertMessagePopUpModal from "../PopUp/AlertMessagePopUpModal";
import { ToastContainer, toast } from 'react-toastify';
import { Zoom } from 'react-toastify';
import AuthoringDisclaimer from '../Authoring/AuthoringDisclaimer'
import 'react-toastify/dist/ReactToastify.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const colorCode = UtilityServices.getDocumentPreviewKeywordColorCode();
const excludeKeywords = UtilityServices.getExcludeKeywords();


const onLoad = () => <div className="doc-preview-loader-wrapper"><img src={imsLoader} width={100} height={100} alt="Loader" /></div>;
const onNoItem = () => <div className="doc-preview-loader-wrapper">No Preview Available</div>
class AiNotebookDocumentPreview extends Component {
    constructor(props) {
        super(props);
        var guid = uuidv4();
        let _showDisclaimerPopup = true;
        let strShowDisclaimerPopup = localStorage.getItem('showPreviewAuthoringDisclaimer');
        if (strShowDisclaimerPopup) {
            _showDisclaimerPopup = !JSON.parse(strShowDisclaimerPopup);
        }
        this.state = {
            numPages: 1,
            pageNumber: 1,
            isLoaded: true,
            documentSearchKeyword: props.searchKeyword ? props.searchKeyword : "",
            searchKeyword: '',
            searchKeywords: [],
            pageContent: [],
            pageCountResult: [],
            colors: [],
            series: [],
            categories: [],
            updateKey: false,
            strKeyword: '',
            orderBy: DocumentPreviewOrderBy.Hits,
            isShowSearchPanel: true,
            sliderValue: 49,
            scale: 1.0,
            synonyms: props.synonyms ? Object.assign([], props.synonyms) : [],
            isNotFount: false,
            fileExtension: "pdf",
            exportText: '',
            summeryText: '',
            commandText: '',
            sessionID: guid,
            txtPageNumber: 1,
            selectedTab: '0',
            alertMessageModelColor: "",
            alertMessageContent: "",
            alertMessageShowModal: false,
            isAddActionButton: false,
            isSearchStarted: false,
            showDisclaimerPopup: props.isOpenAuthoringTab ? _showDisclaimerPopup : false,
        }
    }
    async componentDidMount() {
        if (this.props.isOpenAuthoringTab) {
            this.setState({ selectedTab: '1' })
        }

        if (this.props.fileName) {
            let fileExtension = this.props.fileName.split(".").pop();
            this.setState({
                fileExtension: fileExtension ? fileExtension : this.state.fileExtension
            })
        }

        let apiService = new ApiServices();
        let res = await apiService.getPreview(this.props.searchID);
        if (res) {
            this.setState({
                numPages: parseInt(res.page_count),
                isLoaded: true,
            }, async () => { await this.getSearchKeywordCountForPreview() });
        }
    }
    async getSearchKeywordCountForPreview() {
        let apiService = new ApiServices();
        let resSearchKeywordCount = await apiService.getSearchKeywordCountForPreview(this.props.searchID);
        if (resSearchKeywordCount) {
            this.setState({
                pageContent: resSearchKeywordCount
            }, async () => {
                await this.handleSynonymsAndKeywords();
            });
        }
    }

    //#region handle events
    handleOnClick = (status) => {
        if (status === 'increment' && parseInt(this.state.pageNumber) < this.state.numPages) {
            this.setState({
                pageNumber: this.state.pageNumber + 1,
                txtPageNumber: this.state.pageNumber + 1,
            })
        }
        else if (status === 'decrement' && parseInt(this.state.pageNumber) > 1) {
            this.setState({
                pageNumber: this.state.pageNumber - 1,
                txtPageNumber: this.state.pageNumber - 1,
            })
        }
    }

    handlePreviewClose = () => {
        this.props.handleClose(false)
    }

    handleChangeInSearch = (e) => {
        this.setState({
            searchKeyword: e.target.value,
            isNotFount: false
        })
    }

    handleKeyDown = async (e) => {
        if (["Enter"].includes(e.key)) {
            this.handleSearchKeywords();
        }
    }
    handleSynonymsAndKeywords = async () => {
        let { searchKeywords, synonyms, documentSearchKeyword } = this.state;
        synonyms.map((synonym) => {
            let searchWordCount = 0;
            this.state.pageContent.map((page, pageIndex) => {
                let pageText = page.page_text.replace(/[\r\n]/g, " ").replace(/\s+/g, " ");;
                searchWordCount += this.countOccurences(pageText.toLowerCase(), synonym.toLowerCase());
            })
            let isAvailableForAddinKeywords = searchKeywords.findIndex(i => i.keyword.toLowerCase().includes(synonym.toLowerCase())) === -1;

            if (searchWordCount > 0 && isAvailableForAddinKeywords) {
                let remainingColor = colorCode.filter(x => !searchKeywords.map(x => x.color).includes(x));
                let color = '#0d6efd'
                if (remainingColor.length > 0) {
                    color = remainingColor[0];
                }
                searchKeywords.push({ keyword: synonym, color: color, isSynonym: true });
            }
        })
        if (searchKeywords.length === 0 && documentSearchKeyword) {
            let searchKeywordArray = documentSearchKeyword.split(' ');
            searchKeywordArray.map(async (skwItem, skwIndex) => {
                if (skwItem &&
                    !excludeKeywords.includes(skwItem.toLowerCase())
                    &&
                    searchKeywords.findIndex(c => c.keyword.toLowerCase().includes(skwItem.toLowerCase())) === -1) {
                    let remainingColor = colorCode.filter(x => !searchKeywords.map(x => x.color).includes(x));
                    let color = '#0d6efd'
                    if (remainingColor.length > 0) {
                        color = remainingColor[0];
                    }
                    searchKeywords.push({ keyword: skwItem, color: color, isSynonym: false });

                }
            })
        }
        this.setState({
            searchKeywords: searchKeywords,
            searchKeyword: '',
        }, async () => { await this.onSearchKeyword() });
    }
    handleSearchKeywords = async () => {
        let { searchKeywords, searchKeyword, synonyms } = this.state;
        if (searchKeyword) {
            // let searchKeywordArray = searchKeyword.split(' ');

            // searchKeywordArray.map(async (skwItem, skwIndex) => {
            //     if (skwItem &&
            //         !excludeKeywords.includes(skwItem.toLowerCase()) &&
            //         searchKeywords.findIndex(c => c.keyword.toLowerCase().includes(skwItem.toLowerCase())) === -1) {
            //         let remainingColor = colorCode.filter(x => !searchKeywords.map(x => x.color).includes(x));
            //         let color = '#0d6efd'
            //         if (remainingColor.length > 0) {
            //             color = remainingColor[0];
            //         }
            //         searchKeywords.push({ keyword: skwItem, color: color, isSynonym: false });

            //     }
            // })
            synonyms.push(this.removeExtraSpace(searchKeyword));
            this.setState({
                searchKeywords: searchKeywords,
                synonyms: synonyms,
                //searchKeyword: ''
            }, async () => {
                await this.handleSynonymsAndKeywords();
                //await this.onSearchKeyword()
            });
        }
    }

    handleOnClickSearchIcon = async (e) => {
        this.setState({
            isNotFount: false,
        })

        this.handleSearchKeywords();
    }

    handleCancelSearchKeyword = (e) => {
        this.setState({
            searchKeyword: ''
        })
    }

    handleKewordRemove = async (keyword) => {
        let { searchKeywords, synonyms, documentSearchKeyword } = this.state;
        searchKeywords = searchKeywords.filter(c => c.keyword !== keyword);
        synonyms = synonyms.filter(c => c !== keyword);
        let searchKeywordArray = documentSearchKeyword.split(' ');
        searchKeywordArray = searchKeywordArray.filter(c => c !== keyword);
        documentSearchKeyword = searchKeywordArray.join(" ");
        this.setState({
            searchKeywords: searchKeywords,
            synonyms: synonyms,
            documentSearchKeyword: documentSearchKeyword
        }, async () => {
            //await this.handleSynonymsAndKeywords();
            await this.onSearchKeyword()
        });
    }
    handleClearAllKeyWords = () => {
        this.setState({
            searchKeywords: [],
            synonyms: [],
            documentSearchKeyword: "",
            isSearchStarted: false,
        }, async () => {
            //await this.handleSynonymsAndKeywords();
            await this.onSearchKeyword()
        });
    }
    onOrderByValueChange = async (event) => {
        this.setState({
            orderBy: event.target.value
        }, async () => { await this.onSearchKeyword() });
    }

    handleSearchAndCollapseOnClick = async (clickBtnFor) => {
        if (clickBtnFor === DocumentPreviewSearch.Search) {
            this.setState({
                isShowSearchPanel: !this.state.isShowSearchPanel
            });
        }
        else {
            this.setState({
                isShowSearchPanel: false
            });
        }
    }

    handleStackBarOnClick = (pageNumber) => {
        this.setState({
            pageNumber: pageNumber,
            txtPageNumber: pageNumber
        })
    }

    handleZoomSliderOnChange = (value) => {
        let scale = (value / 10) * 0.2;
        this.setState({
            sliderValue: value,
            scale: scale
        })
    }
    onRangeValueChange = (event) => {
        let sliderValue = parseInt(event.target.value);
        let scale = (sliderValue / 10) * 0.2;
        this.setState({
            sliderValue: sliderValue,
            scale: scale
        });
    }

    handleZoomButtonOnClick = (status) => {
        let { sliderValue } = this.state;

        if (status === 'increment' && sliderValue === 49) {
            sliderValue += 11;
        }
        else if (status === 'decrement' && sliderValue === 49) {
            sliderValue -= 9;
        }
        else if (status === 'increment' && sliderValue === 35) {
            sliderValue += 5;
        }
        else if (status === 'decrement' && sliderValue === 35) {
            sliderValue -= 5;
        }
        else if (status === 'increment' && sliderValue !== 200) {

            sliderValue += 10;
        }
        else if (status === 'decrement' && sliderValue !== 10) {
            sliderValue -= 10;
        }
        let scale = (sliderValue / 10) * 0.2;
        this.setState({
            sliderValue: sliderValue,
            scale: scale
        })

    };

    handleFitToPageWidthButtonOnClick = (status) => {
        this.setState({
            sliderValue: 49,
            scale: 1
        })

    }
    handleFitFullPageButtonOnClick = (status) => {
        this.setState({
            sliderValue: 35,
            scale: 0.70
        })
    }
    //#endregion 

    //#region utility methods
    onSearchKeyword = async () => {
        const { orderBy } = this.state;
        let { searchKeywords, searchKeyword } = this.state;
        let pageCountResult = [];
        let isNotFount = false;
        this.state.pageContent.map((page, pageIndex) => {
            let pageText = page.page_text.replace(/[\r\n]/g, " ").replace(/\s+/g, " ");;
            let pageTextSplitArray = pageText.split(' ');
            let count = 0;
            let result = {};

            searchKeywords.map((searchWord, searchWordIndex) => {
                let searchWordCount = 0;
                if (searchWord.isSynonym) {
                    searchWordCount = this.countOccurences(pageText.toLowerCase(), searchWord.keyword.toLowerCase());
                    count += searchWordCount;
                } else {
                    pageTextSplitArray.map((i, index) => {
                        if (i.toLowerCase().includes(searchWord.keyword.toLowerCase())) {
                            count++;
                            searchWordCount++;
                        }
                    })
                }
                result[searchWord.keyword] = searchWordCount;
            })
            result = { ...result, pageNumber: page.page_number, totalCount: count }
            pageCountResult.push(result)
        })
        if (orderBy === DocumentPreviewOrderBy.Hits) {
            pageCountResult = pageCountResult.sort((a, b) => parseFloat(b.totalCount) - parseFloat(a.totalCount));
        } else {
            pageCountResult = pageCountResult.sort((a, b) => parseFloat(a.pageNumber) - parseFloat(b.pageNumber));
        }

        searchKeywords = searchKeywords.filter((searchWord, searchWordIndex) => {
            let searchWordCountArray = pageCountResult.map(x => x[searchWord.keyword]);
            const sum = searchWordCountArray.reduce((partialSum, a) => partialSum + a, 0);
            return sum !== 0;
        })
        const categories = pageCountResult.filter(x => x.totalCount > 0).map(c => `p${c.pageNumber}`);
        let series = [];
        searchKeywords.map((searchWord, searchWordIndex) => {
            let data = pageCountResult.filter(x => x.totalCount > 0).map(x => x[searchWord.keyword]);
            series.push({ name: searchWord.keyword, data: data })
        })
        const colors = searchKeywords.map(c => c.color);
        let strKeyword = searchKeywords.map(c => `${encodeURIComponent(c.keyword)}ǂ$${c.color.replace("#", "")}`).join("±@");
        if (searchKeyword) {
            let searchKeywordArray = searchKeyword.split(' ');
            isNotFount = !searchKeywordArray.some(x => searchKeywords.map((searchWord) => searchWord.keyword).includes(x))
        }
        if (searchKeywords.length > 0) {
            this.setState({ isSearchStarted: true })
        }

        await this.setState({
            pageCountResult: pageCountResult,
            categories: categories,
            series: series,
            colors: colors,
            searchKeywords: searchKeywords,
            updateKey: !this.state.updateKey,
            strKeyword: strKeyword,
            isNotFount: isNotFount,
            searchKeyword: ""
        })

    }

    snippetLongString = (snippet) => {
        if (snippet.length > 70) {
            let text = snippet.substring(0, 70)//cuts to 70
            let last = text.lastIndexOf(" ")//gets last space (to avoid cutting the middle of a word)
            text = text.substring(0, last)//cuts from last space (to avoid cutting the middle of a word)
            text = text + ` ...`//adds (...) at the end to show that it's cut
            return text
        }
        return snippet;
    }
    removeExtraSpace = s => s.trim().split(/ +/).join(" ");
    snippetLongStringForKeyword = (snippet) => {
        if (snippet.length > 47) {
            let text = snippet.substring(0, 47)//cuts to 70
            text = text + `...`//adds (...) at the end to show that it's cut
            return text
        }
        return snippet;
    }
    countOccurences = (string, word) => {
        string = string.trim();
        const wordArray = word.trim().split(/\s+/);
        if (wordArray.length === 1) {
            let stringArray = string.trim().split(/[ ,]+/).filter(x => x === word);
            return stringArray.length;
        } else {
            let isStartwith = string.startsWith(`${word} `) ? 1 : 0;
            let isEndsWith = string.endsWith(` ${word}`) ? 1 : 0;
            return string.split(` ${word} `).length - 1 + isStartwith + isEndsWith;
        }
    }
    //#endregion

    //#region render methods
    renderSearchKeyworkResult = () => {
        const { pageCountResult, searchKeywords } = this.state;
        return searchKeywords.map((searchWord, searchWordIndex) => {
            let searchWordCountArray = pageCountResult.map(x => x[searchWord.keyword]);
            const sum = searchWordCountArray.reduce((partialSum, a) => partialSum + a, 0);
            return (<>
                <div className="doc-preview-keword-title" key={`doc-preview-keword-title${searchWordIndex}`}>
                    <span onClick={e => this.handleKewordRemove(searchWord.keyword)} style={{ cursor: "pointer" }}><img src={crossIcon} width={10} height={10} alt="Cross" /> </span>
                    <span title={searchWord.keyword.toLowerCase()} style={{ flex: "1", marginLeft: "5px" }}>{this.snippetLongStringForKeyword(searchWord.keyword.toLowerCase())} ({sum})</span>
                    <div className="doc-preview-keword-color-box" style={{ background: `${searchWord.color}` }} />
                </div>
            </>);
        })
    }

    renderSearchKeyworkChart = () => {
        const { searchKeywords, categories, series, colors, updateKey } = this.state;
        return (
            <>{(categories && series && colors && searchKeywords.length > 0 && categories.length > 0) &&
                <DocumentPreviewChart key={`DocumentPreviewChart-${updateKey}`}
                    categories={categories} series={series} colors={colors} handleStackBarOnClick={this.handleStackBarOnClick} />}</>
        )
    }
    //#endregion
    setDocPreviewInnerWrapperHeight = () => {
        const { fileExtension } = this.state;
        if (fileExtension === "pptx" || fileExtension === "ppt") {
            return { height: "390px" }
        } else {
            return { height: "475px" };
        }
    }


    getSelectedTextFromPreview = async () => {
        let selectedSummery = "";
        let range = window.getSelection().getRangeAt(0);
        if (range) {
            let selectionContents = range.cloneContents();
            let previousLayerTop = 0;

            let selectionContentsChildNodes = selectionContents.childNodes;

            for (let i = 0; i < selectionContentsChildNodes.length; i++) {
                let spanItem = selectionContentsChildNodes[i];
                if (spanItem.classList.contains("textLayer")) {
                    let masterSpanItemChildNodes = spanItem.childNodes;
                    for (let p = 0; p < masterSpanItemChildNodes.length; p++) {
                        let masterSpanChildItem = masterSpanItemChildNodes[p];
                        if (masterSpanChildItem.classList.contains("markedContent")) {
                            let spanItemChildNodes = masterSpanChildItem.childNodes;
                            for (let j = 0; j < spanItemChildNodes.length; j++) {
                                let spanNestedItem = spanItemChildNodes[j];
                                if (spanNestedItem.hasAttribute('role')) {

                                    if (spanNestedItem.tagName === 'SPAN') {
                                        let currentLayerTop = parseFloat(spanNestedItem.style.top);
                                        //console.log(previousLayerTop, currentLayerTop);
                                        let textContent = spanNestedItem.textContent;
                                        let topDifference = currentLayerTop - previousLayerTop;
                                        if (topDifference < 0) {
                                            topDifference = topDifference * -1;
                                        }
                                        if (i !== 0 && !((topDifference) < 5)) {
                                            selectedSummery = selectedSummery.trim();
                                            //selectedSummery += `\r\n`
                                            if (selectedSummery && !selectedSummery.endsWith('-')) {
                                                selectedSummery += ' ';
                                            } else if (selectedSummery && selectedSummery.endsWith('-')) {
                                                selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                                                //selectedSummery = selectedSummery.substring(selectedSummery.length - 1, 1)
                                            }
                                            // if (textContent)
                                            //     textContent = textContent.trimStart();
                                        }
                                        selectedSummery += textContent;
                                        previousLayerTop = parseFloat(spanNestedItem.style.top);
                                    } else if (spanNestedItem.tagName === 'BR') {
                                        if (selectedSummery && !selectedSummery.endsWith('-')) {
                                            selectedSummery += ' ';
                                        } else if (selectedSummery && selectedSummery.endsWith('-')) {
                                            selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                                        }
                                    }
                                }
                            }
                        } else if (masterSpanChildItem.hasAttribute('role')) {
                            if (masterSpanChildItem.tagName === 'SPAN') {
                                let currentLayerTop = parseFloat(masterSpanChildItem.style.top);
                                //console.log(previousLayerTop, currentLayerTop);
                                let textContent = masterSpanChildItem.textContent;
                                let topDifference = currentLayerTop - previousLayerTop;
                                if (topDifference < 0) {
                                    topDifference = topDifference * -1;
                                }
                                if (i !== 0 && !((topDifference) < 5)) {
                                    selectedSummery = selectedSummery.trim();
                                    //selectedSummery += `\r\n`
                                    if (selectedSummery && !selectedSummery.endsWith('-')) {
                                        selectedSummery += ' ';
                                    } else if (selectedSummery && selectedSummery.endsWith('-')) {
                                        selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                                        //selectedSummery = selectedSummery.substring(selectedSummery.length - 1, 1)
                                    }
                                    // if (textContent)
                                    //     textContent = textContent.trimStart();
                                }
                                selectedSummery += textContent;
                                previousLayerTop = parseFloat(masterSpanChildItem.style.top);
                            } else if (masterSpanChildItem.tagName === 'BR') {
                                if (selectedSummery && !selectedSummery.endsWith('-')) {
                                    selectedSummery += ' ';
                                } else if (selectedSummery && selectedSummery.endsWith('-')) {
                                    selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                                }
                            }
                        }
                    }
                }
                else if (spanItem.classList.contains("markedContent")) {
                    let spanItemChildNodes = spanItem.childNodes;
                    for (let j = 0; j < spanItemChildNodes.length; j++) {
                        let spanNestedItem = spanItemChildNodes[j];
                        if (spanNestedItem.hasAttribute('role')) {
                            if (spanNestedItem.tagName === 'SPAN') {
                                let currentLayerTop = parseFloat(spanNestedItem.style.top);
                                let textContent = spanNestedItem.textContent;
                                let topDifference = currentLayerTop - previousLayerTop;
                                if (topDifference < 0) {
                                    topDifference = topDifference * -1;
                                }
                                if (i !== 0 && !((topDifference) < 5)) {
                                    selectedSummery = selectedSummery.trim();
                                    //selectedSummery += `\r\n`
                                    if (selectedSummery && !selectedSummery.endsWith('-')) {
                                        selectedSummery += ' ';
                                    } else if (selectedSummery && selectedSummery.endsWith('-')) {
                                        selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                                        //selectedSummery = selectedSummery.substring(selectedSummery.length - 1, 1)
                                    }
                                    // if (textContent)
                                    //     textContent = textContent.trimStart();
                                }
                                selectedSummery += textContent;
                                previousLayerTop = parseFloat(spanNestedItem.style.top);
                            } else if (spanNestedItem.tagName === 'BR') {
                                if (selectedSummery && !selectedSummery.endsWith('-')) {
                                    selectedSummery += ' ';
                                } else if (selectedSummery && selectedSummery.endsWith('-')) {
                                    selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                                }
                            }
                        }
                    }
                } else if (spanItem.hasAttribute('role')) {
                    if (spanItem.tagName === 'SPAN') {
                        let currentLayerTop = parseFloat(spanItem.style.top);
                        let textContent = spanItem.textContent;
                        let topDifference = currentLayerTop - previousLayerTop;
                        if (topDifference < 0) {
                            topDifference = topDifference * -1;
                        }
                        if (i !== 0 && !((topDifference) < 5)) {
                            selectedSummery = selectedSummery.trim();
                            //selectedSummery += `\r\n`
                            if (selectedSummery && !selectedSummery.endsWith('-')) {
                                selectedSummery += ' ';
                            } else if (selectedSummery && selectedSummery.endsWith('-')) {
                                selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                                //selectedSummery = selectedSummery.substring(selectedSummery.length - 1, 1)
                            }
                            // if (textContent)
                            //     textContent = textContent.trimStart();
                        }
                        selectedSummery += textContent;
                        previousLayerTop = parseFloat(spanItem.style.top);
                    } else if (spanItem.tagName === 'BR') {
                        if (selectedSummery && !selectedSummery.endsWith('-')) {
                            selectedSummery += ' ';
                        } else if (selectedSummery && selectedSummery.endsWith('-')) {
                            selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                        }
                    }
                }
            }
        }
        return selectedSummery;
    }


    _handleCopy = async () => {
        let selectedSummery = "";
        let range = window.getSelection().getRangeAt(0);
        if (range) {
            let selectionContents = range.cloneContents();
            let previousLayerTop = 0;

            let selectionContentsChildNodes = selectionContents.childNodes;

            for (let i = 0; i < selectionContentsChildNodes.length; i++) {
                let spanItem = selectionContentsChildNodes[i];
                if (spanItem.classList.contains("textLayer")) {
                    let masterSpanItemChildNodes = spanItem.childNodes;
                    for (let p = 0; p < masterSpanItemChildNodes.length; p++) {
                        let masterSpanChildItem = masterSpanItemChildNodes[p];
                        if (masterSpanChildItem.classList.contains("markedContent")) {
                            let spanItemChildNodes = masterSpanChildItem.childNodes;
                            for (let j = 0; j < spanItemChildNodes.length; j++) {
                                let spanNestedItem = spanItemChildNodes[j];
                                if (spanNestedItem.hasAttribute('role')) {

                                    if (spanNestedItem.tagName === 'SPAN') {
                                        let currentLayerTop = parseFloat(spanNestedItem.style.top);
                                        let textContent = spanNestedItem.textContent;
                                        let topDifference = currentLayerTop - previousLayerTop;
                                        if (topDifference < 0) {
                                            topDifference = topDifference * -1;
                                        }
                                        if (i !== 0 && !((topDifference) < 5)) {
                                            selectedSummery = selectedSummery.trim();
                                            //selectedSummery += `\r\n`
                                            if (selectedSummery && !selectedSummery.endsWith('-')) {
                                                selectedSummery += ' ';
                                            } else if (selectedSummery && selectedSummery.endsWith('-')) {
                                                selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                                                //selectedSummery = selectedSummery.substring(selectedSummery.length - 1, 1)
                                            }
                                            // if (textContent)
                                            //     textContent = textContent.trimStart();
                                        }
                                        selectedSummery += textContent;
                                        previousLayerTop = parseFloat(spanNestedItem.style.top);
                                    } else if (spanNestedItem.tagName === 'BR') {
                                        if (selectedSummery && !selectedSummery.endsWith('-')) {
                                            selectedSummery += ' ';
                                        } else if (selectedSummery && selectedSummery.endsWith('-')) {
                                            selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                                        }
                                    }
                                }
                            }
                        } else if (masterSpanChildItem.hasAttribute('role')) {
                            if (masterSpanChildItem.tagName === 'SPAN') {
                                let currentLayerTop = parseFloat(masterSpanChildItem.style.top);
                                let textContent = masterSpanChildItem.textContent;
                                let topDifference = currentLayerTop - previousLayerTop;
                                if (topDifference < 0) {
                                    topDifference = topDifference * -1;
                                }
                                if (i !== 0 && !((topDifference) < 5)) {
                                    selectedSummery = selectedSummery.trim();
                                    //selectedSummery += `\r\n`
                                    if (selectedSummery && !selectedSummery.endsWith('-')) {
                                        selectedSummery += ' ';
                                    } else if (selectedSummery && selectedSummery.endsWith('-')) {
                                        selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                                        //selectedSummery = selectedSummery.substring(selectedSummery.length - 1, 1)
                                    }
                                    // if (textContent)
                                    //     textContent = textContent.trimStart();
                                }
                                selectedSummery += textContent;
                                previousLayerTop = parseFloat(masterSpanChildItem.style.top);
                            } else if (masterSpanChildItem.tagName === 'BR') {
                                if (selectedSummery && !selectedSummery.endsWith('-')) {
                                    selectedSummery += ' ';
                                } else if (selectedSummery && selectedSummery.endsWith('-')) {
                                    selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                                }
                            }
                        }
                    }
                }
                else if (spanItem.classList.contains("markedContent")) {
                    let spanItemChildNodes = spanItem.childNodes;
                    for (let j = 0; j < spanItemChildNodes.length; j++) {
                        let spanNestedItem = spanItemChildNodes[j];
                        if (spanNestedItem.hasAttribute('role')) {
                            if (spanNestedItem.tagName === 'SPAN') {
                                let currentLayerTop = parseFloat(spanNestedItem.style.top);
                                let textContent = spanNestedItem.textContent;
                                let topDifference = currentLayerTop - previousLayerTop;
                                if (topDifference < 0) {
                                    topDifference = topDifference * -1;
                                }
                                if (i !== 0 && !((topDifference) < 5)) {
                                    selectedSummery = selectedSummery.trim();
                                    //selectedSummery += `\r\n`
                                    if (selectedSummery && !selectedSummery.endsWith('-')) {
                                        selectedSummery += ' ';
                                    } else if (selectedSummery && selectedSummery.endsWith('-')) {
                                        selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                                        //selectedSummery = selectedSummery.substring(selectedSummery.length - 1, 1)
                                    }
                                    // if (textContent)
                                    //     textContent = textContent.trimStart();
                                }
                                selectedSummery += textContent;
                                previousLayerTop = parseFloat(spanNestedItem.style.top);
                            } else if (spanNestedItem.tagName === 'BR') {
                                if (selectedSummery && !selectedSummery.endsWith('-')) {
                                    selectedSummery += ' ';
                                } else if (selectedSummery && selectedSummery.endsWith('-')) {
                                    selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                                }
                            }
                        }
                    }
                } else if (spanItem.hasAttribute('role')) {
                    if (spanItem.tagName === 'SPAN') {
                        let currentLayerTop = parseFloat(spanItem.style.top);
                        let textContent = spanItem.textContent;
                        let topDifference = currentLayerTop - previousLayerTop;
                        if (topDifference < 0) {
                            topDifference = topDifference * -1;
                        }
                        if (i !== 0 && !((topDifference) < 5)) {
                            selectedSummery = selectedSummery.trim();
                            //selectedSummery += `\r\n`
                            if (selectedSummery && !selectedSummery.endsWith('-')) {
                                selectedSummery += ' ';
                            } else if (selectedSummery && selectedSummery.endsWith('-')) {
                                selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                                //selectedSummery = selectedSummery.substring(selectedSummery.length - 1, 1)
                            }
                            // if (textContent)
                            //     textContent = textContent.trimStart();
                        }
                        selectedSummery += textContent;
                        previousLayerTop = parseFloat(spanItem.style.top);
                    } else if (spanItem.tagName === 'BR') {
                        if (selectedSummery && !selectedSummery.endsWith('-')) {
                            selectedSummery += ' ';
                        } else if (selectedSummery && selectedSummery.endsWith('-')) {
                            selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                        }
                    }
                }
            }
        }
        if (selectedSummery) {
            let _summeryText = this.state.summeryText ? `${this.state.summeryText}\r\n\r\n${selectedSummery.trim()}` : selectedSummery.trim();
            this.setState({ summeryText: _summeryText });
            navigator.clipboard.writeText('')
            toast.success('Add/Append text successful!');
        }
    }

    _handleReplace = async () => {
        let selectedSummery = "";
        let range = window.getSelection().getRangeAt(0);
        if (range) {
            let selectionContents = range.cloneContents();
            let previousLayerTop = 0;

            let selectionContentsChildNodes = selectionContents.childNodes;

            for (let i = 0; i < selectionContentsChildNodes.length; i++) {
                let spanItem = selectionContentsChildNodes[i];
                if (spanItem.classList.contains("textLayer")) {
                    let masterSpanItemChildNodes = spanItem.childNodes;
                    for (let p = 0; p < masterSpanItemChildNodes.length; p++) {
                        let masterSpanChildItem = masterSpanItemChildNodes[p];
                        if (masterSpanChildItem.classList.contains("markedContent")) {
                            let spanItemChildNodes = masterSpanChildItem.childNodes;
                            for (let j = 0; j < spanItemChildNodes.length; j++) {
                                let spanNestedItem = spanItemChildNodes[j];
                                if (spanNestedItem.hasAttribute('role')) {

                                    if (spanNestedItem.tagName === 'SPAN') {
                                        let currentLayerTop = parseFloat(spanNestedItem.style.top);
                                        let textContent = spanNestedItem.textContent;
                                        let topDifference = currentLayerTop - previousLayerTop;
                                        if (topDifference < 0) {
                                            topDifference = topDifference * -1;
                                        }
                                        if (i !== 0 && !((topDifference) < 5)) {
                                            selectedSummery = selectedSummery.trim();
                                            //selectedSummery += `\r\n`
                                            if (selectedSummery && !selectedSummery.endsWith('-')) {
                                                selectedSummery += ' ';
                                            } else if (selectedSummery && selectedSummery.endsWith('-')) {
                                                selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                                                //selectedSummery = selectedSummery.substring(selectedSummery.length - 1, 1)
                                            }
                                            // if (textContent)
                                            //     textContent = textContent.trimStart();
                                        }
                                        selectedSummery += textContent;
                                        previousLayerTop = parseFloat(spanNestedItem.style.top);
                                    } else if (spanNestedItem.tagName === 'BR') {
                                        if (selectedSummery && !selectedSummery.endsWith('-')) {
                                            selectedSummery += ' ';
                                        } else if (selectedSummery && selectedSummery.endsWith('-')) {
                                            selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                                        }
                                    }
                                }
                            }
                        } else if (masterSpanChildItem.hasAttribute('role')) {
                            if (masterSpanChildItem.tagName === 'SPAN') {
                                let currentLayerTop = parseFloat(masterSpanChildItem.style.top);
                                let textContent = masterSpanChildItem.textContent;
                                let topDifference = currentLayerTop - previousLayerTop;
                                if (topDifference < 0) {
                                    topDifference = topDifference * -1;
                                }
                                if (i !== 0 && !((topDifference) < 5)) {
                                    selectedSummery = selectedSummery.trim();
                                    //selectedSummery += `\r\n`
                                    if (selectedSummery && !selectedSummery.endsWith('-')) {
                                        selectedSummery += ' ';
                                    } else if (selectedSummery && selectedSummery.endsWith('-')) {
                                        selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                                        //selectedSummery = selectedSummery.substring(selectedSummery.length - 1, 1)
                                    }
                                    // if (textContent)
                                    //     textContent = textContent.trimStart();
                                }
                                selectedSummery += textContent;
                                previousLayerTop = parseFloat(masterSpanChildItem.style.top);
                            } else if (masterSpanChildItem.tagName === 'BR') {
                                if (selectedSummery && !selectedSummery.endsWith('-')) {
                                    selectedSummery += ' ';
                                } else if (selectedSummery && selectedSummery.endsWith('-')) {
                                    selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                                }
                            }
                        }
                    }
                }
                else if (spanItem.classList.contains("markedContent")) {
                    let spanItemChildNodes = spanItem.childNodes;
                    for (let j = 0; j < spanItemChildNodes.length; j++) {
                        let spanNestedItem = spanItemChildNodes[j];
                        if (spanNestedItem.hasAttribute('role')) {
                            if (spanNestedItem.tagName === 'SPAN') {
                                let currentLayerTop = parseFloat(spanNestedItem.style.top);
                                let textContent = spanNestedItem.textContent;
                                let topDifference = currentLayerTop - previousLayerTop;
                                if (topDifference < 0) {
                                    topDifference = topDifference * -1;
                                }
                                if (i !== 0 && !((topDifference) < 5)) {
                                    selectedSummery = selectedSummery.trim();
                                    //selectedSummery += `\r\n`
                                    if (selectedSummery && !selectedSummery.endsWith('-')) {
                                        selectedSummery += ' ';
                                    } else if (selectedSummery && selectedSummery.endsWith('-')) {
                                        selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                                        //selectedSummery = selectedSummery.substring(selectedSummery.length - 1, 1)
                                    }
                                    // if (textContent)
                                    //     textContent = textContent.trimStart();
                                }
                                selectedSummery += textContent;
                                previousLayerTop = parseFloat(spanNestedItem.style.top);
                            } else if (spanNestedItem.tagName === 'BR') {
                                if (selectedSummery && !selectedSummery.endsWith('-')) {
                                    selectedSummery += ' ';
                                } else if (selectedSummery && selectedSummery.endsWith('-')) {
                                    selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                                }
                            }
                        }
                    }
                } else if (spanItem.hasAttribute('role')) {
                    if (spanItem.tagName === 'SPAN') {
                        let currentLayerTop = parseFloat(spanItem.style.top);
                        let textContent = spanItem.textContent;
                        let topDifference = currentLayerTop - previousLayerTop;
                        if (topDifference < 0) {
                            topDifference = topDifference * -1;
                        }
                        if (i !== 0 && !((topDifference) < 5)) {
                            selectedSummery = selectedSummery.trim();
                            //selectedSummery += `\r\n`
                            if (selectedSummery && !selectedSummery.endsWith('-')) {
                                selectedSummery += ' ';
                            } else if (selectedSummery && selectedSummery.endsWith('-')) {
                                selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                                //selectedSummery = selectedSummery.substring(selectedSummery.length - 1, 1)
                            }
                            // if (textContent)
                            //     textContent = textContent.trimStart();
                        }
                        selectedSummery += textContent;
                        previousLayerTop = parseFloat(spanItem.style.top);
                    } else if (spanItem.tagName === 'BR') {
                        if (selectedSummery && !selectedSummery.endsWith('-')) {
                            selectedSummery += ' ';
                        } else if (selectedSummery && selectedSummery.endsWith('-')) {
                            selectedSummery = selectedSummery.substring(0, selectedSummery.length - 1)
                        }
                    }
                }
            }
        }
        if (selectedSummery) {
            this.setState({ summeryText: selectedSummery.trim() });
            navigator.clipboard.writeText('')
            toast.success('Replace text successful!');
        }
    }

    handleCopy = () => {
        document.execCommand('copy', true);
        navigator.clipboard.readText()
            .then(
                cliptext => {
                    if (cliptext) {
                        let _cliptext = cliptext.replace(/[\r\n]/g, " ").replace(/[\n]/g, " ").replace(/-  /g, "").replace(/- /g, "")
                        let _summeryText = this.state.summeryText ? `${this.state.summeryText}\r\n\r\n${_cliptext.trim()}` : _cliptext.trim();
                        this.setState({ summeryText: _summeryText });
                        navigator.clipboard.writeText('')
                        this.setState({ isAddActionButton: true });
                        toast.success('Add/Append text successful!');
                        //this.successAlertMessage('Add/Append text successful!');
                    }
                }, err => console.log(err));
    }
    handleSelectAll = () => {
        const textLayers = document.querySelector("div.react-pdf__Page__textContent")
        if (window.getSelection) {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(textLayers);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }
    handleReplace = () => {
        document.execCommand('copy', true);
        navigator.clipboard.readText()
            .then(
                cliptext => {
                    if (cliptext) {
                        let _cliptext = cliptext.replace(/[\r\n]/g, " ").replace(/[\n]/g, " ").replace(/-  /g, "").replace(/- /g, "")
                        let _summeryText = _cliptext.trim();
                        this.setState({ summeryText: _summeryText });
                        navigator.clipboard.writeText('')
                        this.setState({ isAddActionButton: true });
                        toast.success('Replace text successful!');
                    }
                }, err => console.log(err));
    }
    handleIsAddActionButton = () => {
        this.setState({ isAddActionButton: false });
    }
    successAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#00c12c",
            alertMessageShowModal: true,
        })
    }
    errorAlertMessage = (message) => {
        this.setState({
            alertMessageContent: message,
            alertMessageModelColor: "#de1327",
            alertMessageShowModal: true
        })
    }
    closeAlertMessage = () => {
        this.setState({
            alertMessageContent: "",
            alertMessageModelColor: "",
            alertMessageShowModal: false
        })
    }
    handleSummery = (_summeryText) => {
        this.setState({ summeryText: _summeryText })
    }
    handleComment = (_commandText) => {
        this.setState({ commandText: _commandText })
    }
    handleSessionID = () => {
        var guid = uuidv4();
        this.setState({ sessionID: guid })
    }
    onDocumentClick = (event, page) => {
        //const textLayers = document.querySelectorAll("div.react-pdf__Page__textContent > span");
        // textLayers.forEach(layer => {
        //     // if (layer.innerHTML.length === 0) {
        //     //     layer.innerHTML = ` `
        //     // }
        //     // if (layer.innerHTML && !layer.innerHTML.trim().endsWith('-')) {
        //     //     layer.innerHTML = `${layer.innerHTML} `;
        //     // }
        // });
        // for (let i = 0; i < textLayers.length; i++) {
        //     let layer = textLayers[i];
        //     let currentLayerTop = parseFloat(textLayers[i].style.top);
        //     let previousLayerTop = 0;
        //     if (i - 1 >= 0) {
        //         let previousLayer = textLayers[i - 1];
        //         previousLayerTop = parseFloat(previousLayer.style.top);
        //         if (currentLayerTop !== previousLayerTop && !previousLayer.innerHTML.trim().endsWith('-')) {
        //             layer.innerHTML = ` ${layer.innerHTML}`;
        //         }
        //     }
        // }
    }
    handleKeyPress = (event) => {
        if (event.key === "Enter") {
            this.handleGotoPageClick();
        }
        else if (event.key && isNaN(event.key)) {
            this.setState({ txtPageNumber: this.state.pageNumber })
            return false;
        }
    }
    handlePageNumberChange = (event) => {
        if (isNaN(event.target.value)) {
            this.setState({ txtPageNumber: this.state.pageNumber })
        } else {
            this.setState({ txtPageNumber: event.target.value ? parseInt(event.target.value) : event.target.value })
        }
    }
    handleGotoPageClick = () => {
        let { txtPageNumber, numPages, pageNumber } = this.state;
        if (txtPageNumber === pageNumber) {
            txtPageNumber = txtPageNumber + 1;
        }

        if (!txtPageNumber) {
            this.setState({
                txtPageNumber: this.state.pageNumber
            })
        }
        else if (txtPageNumber <= 0) {
            this.setState({
                pageNumber: 1,
                txtPageNumber: 1,
            })
        }
        else if (txtPageNumber <= numPages) {
            this.setState({
                pageNumber: txtPageNumber,
                txtPageNumber: txtPageNumber,
            })
        } else {
            this.setState({
                pageNumber: numPages,
                txtPageNumber: numPages,
            })
        }
    }
    handleOnTabClick = (evt) => {
        let _showDisclaimerPopup = true;
        let strShowDisclaimerPopup = localStorage.getItem('showPreviewAuthoringDisclaimer');
        if (strShowDisclaimerPopup) {
            _showDisclaimerPopup = !JSON.parse(strShowDisclaimerPopup);
        }
        var tabId = evt.target.id
        if (tabId.split('-')[0] === '1' || tabId.split('-')[0] === '0') {
            this.setState({
                selectedTab: tabId.split('-')[0],
                showDisclaimerPopup: _showDisclaimerPopup
            })
        }
    }
    handleDisclaimerClose = async () => {
        let strShowDisclaimerPopup = localStorage.getItem('showPreviewAuthoringDisclaimer');
        if (strShowDisclaimerPopup && !JSON.parse(strShowDisclaimerPopup)) {
            let service = new ApiServices();
            let wwid = JSON.parse(localStorage.getItem("wwid"));
            await service.UpdateDisclaimerStatus(wwid, 'isshowpreviewauthoringdisclaimer', true);
        }
        this.setState({ showDisclaimerPopup: false });
        localStorage.setItem('showPreviewAuthoringDisclaimer', JSON.stringify(true));
    }
    render() {
        const { pageCountResult, strKeyword, orderBy, isShowSearchPanel,
            sliderValue, scale, isNotFount, fileExtension, summeryText, commandText, sessionID, txtPageNumber, selectedTab, } = this.state;
        const { alertMessageModelColor, alertMessageContent, alertMessageShowModal, isAddActionButton, showDisclaimerPopup } = this.state;
        return (<>
            {/* // <div className="ai-notebook-doc-preview-wrapper" style={{ width: !isShowSearchPanel && "54.55%", height: (fileExtension === "pptx" || fileExtension === "ppt") && "500px", top: (fileExtension === "pptx" || fileExtension === "ppt") && "100px" }}> */}
            <div className="ai-notebook-doc-preview-wrapper">
                <div className="ai-notebook-doc-preview-wrapper-right" style={{ width: !isShowSearchPanel && "100%" }}>
                    {this.props.showPreview &&
                        <>
                            <div className="ai-doc-preview-title-wrapper" style={{ display: "flex", flexDirection: "column" }}>
                                <a target="_blank" href={this.props.sourceItem.downloadUrl}><span title={`${this.props.docTitle}`}>{this.props.docTitle.replace(/_/g, " ").replace(/-/g, " ")}</span></a>
                                <div className="doc-preview-clear-search" style={selectedTab === '0' ? { visibility: "hidden" } : {}}><button className="btnClearAllSearches" title="Clear Highlighting" type="button" onClick={this.handleClearAllKeyWords}>Clear Highlighting</button></div>
                            </div>

                            <div className={(fileExtension && (fileExtension === "pptx" || fileExtension === "ppt")) ? "doc-preview-inner-wrapper-notebook-ppt" : "doc-preview-inner-wrapper-notebook"}
                            //style={this.setDocPreviewInnerWrapperHeight()}
                            >
                                {/* width="515" height="275" */}
                                <Document file={`${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}PreviewService/Preview?strID=${this.props.searchID}&strPageNum=${this.state.pageNumber}&strKeyword=${strKeyword}`} loading={onLoad} error={onNoItem} >
                                    {fileExtension && (fileExtension === "pptx" || fileExtension === "ppt") ?
                                        <Page pageNumber={1} scale={scale} width="640" height="200" renderMode="canvas"
                                        // onRenderSuccess={this.onDocumentClick}
                                        /> :
                                        <Page pageNumber={1} scale={scale} width="600" height="200" renderMode="canvas"
                                        //  onRenderSuccess={this.onDocumentClick} 
                                        />}
                                </Document>
                            </div>
                            {this.state.numPages >= 1 &&
                                <div className="ai-doc-preview-controle-wrapper">
                                    <div className="doc-preview-pagination-wrapper">
                                        {this.state.pageNumber === 1 ?
                                            <img src={paginationDisabledLeftArrowMobile} alt="" style={{ cursor: "not-allowed" }} width={17} height={17} /> :
                                            <img src={paginationLeftArrowMobile} alt="" onClick={e => this.handleOnClick('decrement')} width={17} height={17} />
                                        }
                                        <span className="doc-preview-pagination-page-number">Page {this.state.pageNumber} of {this.state.numPages}</span>
                                        {this.state.pageNumber === this.state.numPages ?
                                            <img src={paginationDisabledRightArrowMobile} alt="" style={{ cursor: "not-allowed" }} width={17} height={17} /> :
                                            <img src={paginationRightArrowMobile} alt="" onClick={e => this.handleOnClick('increment')} width={17} height={17} />
                                        }
                                        <div className="pagenumber-input-box-wrapper">
                                            <input type="number" min="1" max={this.state.numPages} onChange={this.handlePageNumberChange} onKeyPress={this.handleKeyPress} className="txtPageNumber" title="Go to Page" value={txtPageNumber} />
                                            <span className="pagenumber-icon-wrapper">
                                                <span className="pagenumber-icon-wrapper-webView" onClick={this.handleGotoPageClick}>
                                                    <img
                                                        src={viewMoreArrowWhite}
                                                        style={{ width: '50%' }}
                                                        alt="Skip to Page"
                                                        title="Skip to Page"
                                                    />
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="doc-preview-zoom-wrapper">
                                        <button className="btnReplaceText" type="button" style={selectedTab === '0' ? { visibility: "hidden" } : {}} onClick={e => this.handleSelectAll()}>Select Page</button>
                                        <button className="btnReplaceText" type="button" style={selectedTab === '0' ? { visibility: "hidden" } : {}} onClick={e => this.handleReplace()}>Replace Text</button>
                                        <button className="btnAddText" type="button" style={selectedTab === '0' ? { visibility: "hidden" } : {}} onClick={e => this.handleCopy()}>Add/Append Text</button>
                                        <button className="documentAiViewerbutton showSearch" title={isShowSearchPanel ? "Full Screen View" : "Exit Full Screen View"} onClick={e => this.handleSearchAndCollapseOnClick(DocumentPreviewSearch.Search)}></button>
                                        <button className="documentAiViewerbutton zoomFitWidth" title="Fit to Page Width" onClick={e => this.handleFitToPageWidthButtonOnClick('decrement')}></button>
                                        <button className="documentAiViewerbutton zoomFitPage" title="Fit Full Page" onClick={e => this.handleFitFullPageButtonOnClick('decrement')}></button>
                                        <span className="documentzoomPercentagebutton">{sliderValue}%</span>
                                        <button className="documentAiViewerbutton zoomOut" onClick={e => this.handleZoomButtonOnClick('decrement')}></button>
                                        <div className="doc-preview-zoom-inner">
                                            <input type="range" value={sliderValue} onChange={e => this.onRangeValueChange(e)} min="10" max="200" step="10" className="slider" id="myRange" />
                                        </div>
                                        <button className="documentAiViewerbutton zoomIn" onClick={e => this.handleZoomButtonOnClick('increment')}></button>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
                {isShowSearchPanel && <div className="ai-notebook-doc-preview-wrapper-left">
                    <Tabs
                        defaultTab={selectedTab.toString()}
                        selectedindex={selectedTab}
                        onClick={this.handleOnTabClick}
                    >
                        <TabList className="tablist-item-notebook">
                            <Tab tabFor="0" className={selectedTab === '0' ? "tab-list-item-notebook  tab-list-item-notebook-active" : "tab-list-item-notebook"}>Search</Tab>
                            <Tab tabFor="1" className={selectedTab === '1' ? "tab-list-item-notebook tab-list-item-notebook-active" : "tab-list-item-notebook"}>Authoring</Tab>
                            <span style={{ cursor: "pointer", float: "right" }}>
                                {selectedTab === '1' && <>
                                    <a className="authoring-model-body-content-link" onClick={() => { this.setState({ showDisclaimerPopup: true }) }} >Gen Ai Adherence Policy</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;</>}
                                <img onClick={e => this.handlePreviewClose()} src={crossIcon} width={10} height={10} alt="Cross" /></span>
                        </TabList>

                        <TabPanel tabId="0" className='tabpanel-list-item-notebook'>
                            <> <div className="doc-preview-input-box-title-wrapper"><span style={{ flex: "1" }}>Search inside</span></div>
                                <div className="doc-preview-input-box-wrapper">
                                    <input name="searchBar" placeholder="Search..." className="doc-preview-input-search-box" value={this.state.searchKeyword} onChange={this.handleChangeInSearch} onKeyDown={this.handleKeyDown} />
                                    <span className="doc-preview-icon-wrapper">
                                        {this.state.searchKeyword &&
                                            <img
                                                src={crossIcon}
                                                className="doc-preview-input-search-cancel-icon"
                                                alt="clear"
                                                title="Click here to clear the search terms"
                                                onClick={this.handleCancelSearchKeyword}
                                            />
                                        }
                                        <span className="doc-preview-search-icon-wrapper-webView" onClick={this.handleOnClickSearchIcon}>
                                            <img
                                                src={magnifyingGlass}
                                                className="magnifyingGlass-mobileView"
                                                alt="search"
                                                title="Search"
                                            />
                                        </span>
                                    </span>
                                    {isNotFount && <span className="doc-preview-warning-msg">There is no result found.</span>}
                                </div>
                                <div className="doc-preview-page-count-result">
                                    {pageCountResult && <>
                                        <div className="doc-preview-search-results-title" style={{ marginBottom: "5px" }}><span style={{ flex: "1" }}>Search results:</span>
                                            <div className="doc-preview-clear-search-bar"><button className="btnClearAllSearches" title="Clear Highlighting" type="button" style={{ visibility: "visible" }} onClick={this.handleClearAllKeyWords}>Clear Highlighting</button></div>
                                        </div>
                                        <div className="doc-preview-page-count-results-wrapper" style={{ maxHeight: (fileExtension === "pptx" || fileExtension === "ppt") ? "350px" : "440px" }}>
                                            <div className="doc-preview-page-count-results">
                                                {this.renderSearchKeyworkResult()}
                                                <div className="doc-preview-page-count-chart">
                                                    <p className="doc-preview-search-results-split"></p>
                                                    <div className="doc-preview-OrderBy-wrapper">
                                                        <label>Order by: </label>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                value={DocumentPreviewOrderBy.Hits}
                                                                checked={orderBy === DocumentPreviewOrderBy.Hits}
                                                                onChange={this.onOrderByValueChange}
                                                            />
                                                            {DocumentPreviewOrderBy.Hits}
                                                        </label>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                value={DocumentPreviewOrderBy.Pages}
                                                                checked={orderBy === DocumentPreviewOrderBy.Pages}
                                                                onChange={this.onOrderByValueChange}
                                                            />
                                                            {DocumentPreviewOrderBy.Pages}
                                                        </label>
                                                    </div>
                                                    {this.renderSearchKeyworkChart()}
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                </div>
                            </>
                        </TabPanel>
                        <TabPanel tabId="1" className='tabpanel-list-item-notebook'>
                            <AuthoringSummery handleSummery={this.handleSummery} handleComment={this.handleComment} sessionID={sessionID} summeryText={summeryText} commandText={commandText} sourceItem={this.props.sourceItem} customWidth={this.props.customWidth} handleSessionID={this.handleSessionID} searchID={this.props.searchID} isAddActionButton={isAddActionButton} handleIsAddActionButton={this.handleIsAddActionButton} />
                        </TabPanel>
                    </Tabs>
                </div>}
            </div>
            {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
            {showDisclaimerPopup && <AuthoringDisclaimer showPopup={showDisclaimerPopup} handleDisclaimerClose={this.handleDisclaimerClose} />}
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="dark"
                className="notebook-preview-toast"
                transition={Zoom} />
        </>)
    }
}
export default AiNotebookDocumentPreview;